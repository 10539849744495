import { handleWaitForClonePageAtomServiceToThemeAtomService } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { call, retry, SagaReturnType } from 'redux-saga/effects';
import { importPageTemplateToThemeService } from 'services/ThemeService/Logic/importPageTemplateToThemeService';

interface HandleClonePageAtomServiceToThemeAtomService {
  pageAtomCommandIds: string[];
  eventId: string;
  entityType: EntityType;
}

export function* handleClonePageAtomServiceToThemeAtomService({
  pageAtomCommandIds,
  eventId,
  entityType,
}: HandleClonePageAtomServiceToThemeAtomService) {
  const newCommandIds: string[] = [];
  for (const pageAtomCommandId of pageAtomCommandIds) {
    yield retry(3, 1000, importPageTemplateToThemeService, { commandId: pageAtomCommandId, entityType, eventId });
    const { pageCommandId, statusSync }: SagaReturnType<typeof handleWaitForClonePageAtomServiceToThemeAtomService> = yield call(
      handleWaitForClonePageAtomServiceToThemeAtomService,
      'Clone các thành phần từ page atom service sang theme atom service',
    );
    if (statusSync === 'success' && pageCommandId) {
      newCommandIds.push(pageCommandId);
    }
  }

  return newCommandIds;
}
