import * as CategoryController from './CategoryController';
import * as PageController from './PageController';
import * as TagController from './TagController';
import * as UseCaseController from './UseCaseController';

export const adminApi = {
  category: CategoryController,
  page: PageController,
  tag: TagController,
  use_case: UseCaseController,
};
