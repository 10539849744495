import { MyModal } from '@wiloke/ui';
import { useState } from 'react';
import { FontAwesome, View } from 'wiloke-react-core';

interface ModalNotifyAFileCanDownloadProps {
  isVisible: boolean;
  onOk: () => void;
  onCancel: () => void;
}
export const ModalNotifyAFileCanDownload = ({ onCancel, onOk, isVisible }: ModalNotifyAFileCanDownloadProps) => {
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  return (
    <>
      <MyModal
        headerText="Có một file mới có thể tải xuống"
        isVisible={isVisible}
        onOk={onOk}
        onCancel={() => setOpenModalConfirm(true)}
        okText="Tải xuống"
        cancelText="Bỏ qua"
      >
        <View css={{ padding: '8px 4px' }}>
          <View color="primary" css={{ fontWeight: 500, fontSize: '18px', textDecoration: 'underline' }}>
            Tại sao lại có thông báo này?
          </View>
          <View tagName="ul">
            <View tagName="li">
              Đề phòng quá trình đồng bộ gặp lỗi, một file được đề xuất tải xuống để công sức "Tiền xử lý" của bạn không đổ sông đổ bể
            </View>
            <View tagName="li">Có thứ để gõ đầu "Developers" dễ dàng hơn</View>
          </View>
        </View>
      </MyModal>

      <MyModal
        isVisible={openModalConfirm}
        onCancel={() => {
          onCancel();
          setOpenModalConfirm(false);
        }}
        onOk={() => {
          onOk();
          setOpenModalConfirm(false);
        }}
        cancelText="Vẫn bỏ qua"
        okText="Tải xuống"
        headerText="Cảnh báo"
        disabledCloseButton
      >
        <View css={{ fontSize: '18px', fontWeight: 500, marginBottom: '16px' }}>
          <FontAwesome type="far" name="meh" css={{ marginRight: '8px' }} />
          Thật luôn?
        </View>
        <View color="danger" css={{ textDecoration: 'underline', fontWeight: 500, fontSize: '16px' }}>
          Hậu quả mà bạn có thể sẽ gánh chịu
        </View>
        <View tagName="ul">
          <View tagName="li">Công sức "Tiền xử lý" của bạn sẽ đổ sông đổ bể nếu quá trình đồng bộ sắp tới gặp lỗi</View>
          <View tagName="li">
            Bạn không thể nhớ được hết các thao tác bạn đã thực hiện trong quá trình sử dụng chức năng "Sync bằng file". Vì vậy khi báo bug với
            "Developers" khả năng cao là bạn sẽ bị gõ đầu ngược lại do không đủ căn cứ để chứng minh lỗi là của "Developers".{' '}
            <View tagName="span" color="danger" css={{ textDecoration: 'underline', fontWeight: 500 }}>
              Tột cùng của sự cay cú sẽ tới nếu lỗi này làm công việc của bạn được gán mác "Chậm Deadline"
            </View>
          </View>
        </View>
        <View color="danger" css={{ fontSize: '16px', textDecoration: 'underline', fontWeight: 500, textAlign: 'center' }}>
          Cảnh báo này là cảnh báo cuối cùng dành cho bạn. Đừng trả giá vì sự ngu dốt của người khác
        </View>
      </MyModal>
    </>
  );
};
