import { getCacheValue, setCacheValue, getUniqId } from '../../../CacheShopifyFieldId';
import { ISCHEMA_SettingCollectionPicker } from '../@types/ISCHEMA_SettingCollectionPicker';
import { ISETTING_SettingCollectionPicker } from '../@types/ISETTING_SettingCollectionPicker';

export const toShopifyFieldId = ({ field, parentField, section }: ISCHEMA_SettingCollectionPicker | ISETTING_SettingCollectionPicker) => {
  const theoryShopifyFieldId = parentField ? `${parentField.name}___${field.name}` : field.name;
  if (theoryShopifyFieldId.length >= 25) {
    const valueInCache = getCacheValue({ theoryShopifyFieldId, section });
    if (valueInCache) {
      return valueInCache;
    } else {
      const newKey = `VEDA_collectionPicker_${getUniqId('collectionPicker')}`;
      setCacheValue({ theoryShopifyFieldId, section }, newKey);
      return newKey;
    }
  }
  return theoryShopifyFieldId;
};
