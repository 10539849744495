import {
  ErrorOption,
  getAllJsOfPage,
  getAllJsOfTheme,
  getCssFromAddonScss,
  getCssFromSectionsScss_PageScss_SectionsInlinesCss,
  getCssFromThemeSettings,
  getHtmlFilesOfAddon,
  getHtmlFilesOfPage,
  getHtmlFilesOfTheme,
  getJsOfAddon,
  getVendorsOfPage,
  getVendorsOfTheme,
} from 'generate/utils/getFilesForSave';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { defaultGeneralSettings } from 'store/global/generalSettings/slice';
import { defaultVendors } from 'store/reducers/reducerVendors';
import {
  allGeneralSettingsSelector,
  allGlobalJsSelector,
  allGlobalScssSelector,
  allVendorsSelector,
  pagesSelector,
  themeAddonsSelector,
  themeHeaderFooterSelector,
  themeSettingsSelector,
  themeVersionSelector,
} from 'store/selectors';
import { PageData, Result, ThemeTranslationsResponse } from 'types/Result';
import getPageInfo from 'utils/functions/getInfo';
import { isThemeBuilder } from 'utils/validateBuilderMode';
import { getAddonSections } from './utils/getAddonSections';
import { getEnableSections } from './utils/getEnableSections';
import { getHeaderFooterSections } from './utils/getHeaderFooterSections';
import { getMainSections } from './utils/getMainSections';

const useResultForPreview = () => {
  const pages = useSelector(pagesSelector);
  const allGeneralSettings = useSelector(allGeneralSettingsSelector);
  const allVendors = useSelector(allVendorsSelector);
  const allGlobalScss = useSelector(allGlobalScssSelector);
  const allGlobalJs = useSelector(allGlobalJsSelector);
  const themeAddons = useSelector(themeAddonsSelector);
  const {
    globalJs: globalJsOfTheme,
    globalScss: globalScssOfTheme,
    vendors: vendorsOfTheme,
    layoutSettings,
    cssVariables,
    generalSettings: themeGeneralSettings,
    globalTranslations,
    colorSchemes: { colorSchemes },
  } = useSelector(themeSettingsSelector);
  const { footers, headers } = useSelector(themeHeaderFooterSelector);

  const themeVersion = useSelector(themeVersionSelector);

  const themeId = getPageInfo('themeId');
  const [isExtracting, setIsExtracting] = useState(false);

  /**
   * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
   */
  // const getResultOfAtomicCss = (_: string): Promise<Result['pages'][string]['atomicCss']> => {
  //   return Promise.resolve<Result['pages'][string]['atomicCss']>('');
  //   // return new Promise(resolve => {
  //   //   const pmOff = pmParent.on('@atomicCss/fulfill', ({ uniqId, atomicCss }) => {
  //   //     if (uniqId === pageId) {
  //   //       pmOff();
  //   //       resolve(atomicCss);
  //   //     }
  //   //   });
  //   //   pmParent.emit('@atomicCss/request', { uniqId: pageId });
  //   // });
  // };

  // Trường hợp builderType='page' -> header và footer như 1 section bình thường
  const getResultOfPage = async (errorOption: ErrorOption): Promise<Result['pages']> => {
    const pageId = getPageInfo('id');
    const pages_ = { [pageId]: pages.data[pageId] };
    const resultInArray = await Promise.all(
      Object.entries(pages_).map(async ([pageId, page]) => {
        const vendors = allVendors[pageId] ?? defaultVendors;
        const globalScss = allGlobalScss[pageId] ?? '';
        const globalJs = allGlobalJs[pageId] ?? '';
        const generalSettings = allGeneralSettings[pageId] ?? defaultGeneralSettings;
        const mainSections = getMainSections(page.sections);
        const mainSectionsEnable = mainSections.filter(section => section.enable);

        /**
         * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
         */
        // const atomicCss = await getResultOfAtomicCss(pageId);
        const atomicCss = '';
        const htmlFiles = [
          ...getHtmlFilesOfPage({
            sectionsEnable_notIncludeAddonSections_includeMegamenuSections: mainSectionsEnable,
            errorOption,
          }),
        ];
        const js = getAllJsOfPage({
          variant: 'Toàn bộ page - bao gồm các sections, page settings',
          globalJs,
          sectionsEnable_notIncludeAddonSections_includeMegamenuSections: mainSectionsEnable,
        });
        const css = await getCssFromSectionsScss_PageScss_SectionsInlinesCss({
          variant: 'Toàn bộ page - bao gồm các sections, page settings',
          globalScss,
          sectionsEnable_notIncludeAddonSections_includeMegamenuSections: mainSectionsEnable,
          atomicCssForAdminNDev: atomicCss, // Mặc định preview sẽ chèn atomic css vào file global css của page vì nó không quan trọng
        });

        const result: PageData = {
          data: {
            page: {
              ...page,
              sections: mainSections,
            },
            pageSettings: {
              generalSettings,
              vendors,
              globalScss,
              globalJs,
            },
          },
          files: [...getVendorsOfPage({ vendors }), ...css, ...js, ...htmlFiles],

          /**
           * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
           */
          // atomicCss,
        };
        return result;
      }),
    );

    return resultInArray.reduce<Result['pages']>(
      (obj, item) => ({
        ...obj,
        ...(!!item.data.page.sections.length ? { [item.data.page.commandId]: item } : {}),
      }),
      {},
    );
  };

  const getResultOfTheme = async (errorOption: ErrorOption): Promise<Result['theme']> => {
    const pageId = getPageInfo('id');

    /**
     * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
     */
    // const atomicCss = await getResultOfAtomicCss(pageId);
    const atomicCss = '';
    if (isThemeBuilder()) {
      const pageData = pages.data[pageId];
      const { headerSections, footerSections } = getHeaderFooterSections(pageData.sections);
      const headerSectionsEnable = getEnableSections(headerSections);
      const footerSectionsEnable = getEnableSections(footerSections);
      const js = getAllJsOfTheme({
        variant: 'Toàn bộ theme - bao gồm header, footer, theme settings',
        headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: headerSectionsEnable ?? [],
        footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: footerSectionsEnable ?? [],
        globalJs: globalJsOfTheme,
        themeGeneralSettings,
      });
      // NOTE: @tuong -> Tách ra 2 headers, footers riêng vì có 2 fileType (css headers..., css footers...) => Tại thời điểm comment này được viết có thể gộp vào làm 1 nhưng vì có thể có sự thay đổi trong luồng ghi file (vị trí file, ...) nên viết thế này sẽ dễ thay đổi hơn
      const [htmlFilesInPreloader, ...htmlFilesInHeader] = getHtmlFilesOfTheme({
        headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: headerSectionsEnable ?? [],
        footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: [],
        themeGeneralSettings,
        errorOption,
        variant: 'Toàn bộ theme - bao gồm header, footer, theme settings',
      });
      const [, ...htmlFilesInFooter] = getHtmlFilesOfTheme({
        headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: [],
        footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: footerSectionsEnable ?? [],
        themeGeneralSettings,
        errorOption,
        variant: 'Toàn bộ theme - bao gồm header, footer, theme settings',
      });
      const css = await getCssFromThemeSettings({
        footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: footerSectionsEnable ?? [],
        headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: headerSectionsEnable ?? [],
        globalScss: globalScssOfTheme,
        themeGeneralSettings,
        variant: 'Toàn bộ theme - bao gồm header, footer, theme settings',
        target: 'Trích xuất kết quả sync lên shopify hoặc Lấy css để hiển thị trong builder',
        atomicCssForAdminNDev: atomicCss, // Mặc định preview sẽ chèn atomic css vào file global css của page vì nó không quan trọng
      });
      const vendors = getVendorsOfTheme({
        vendors: vendorsOfTheme,
        themeGeneralSettings,
        cssVariables,
        layout: layoutSettings,
        target: 'Trích xuất kết quả sync lên shopify hoặc Lấy css để hiển thị trong builder',
        colorSchemes,
        themeVersion,
      });
      return {
        themeSettings: {
          generalSettings: themeGeneralSettings,
          cssVariables,
          layoutSettings,
          globalTranslations: globalTranslations as ThemeTranslationsResponse,
          colorSchemes,
        },
        addons: themeAddons.data,
        header: headerSections,
        footer: footerSections,
        themeId: themeId,
        themeName: '',
        vendors: vendorsOfTheme,
        globalJs: globalJsOfTheme,
        globalScss: globalScssOfTheme,
        files: [...js, ...css, ...htmlFilesInHeader, ...htmlFilesInFooter, htmlFilesInPreloader, ...vendors],
      };
    } else {
      const js = getAllJsOfTheme({
        variant: '1 phần theme - bao gồm theme settings',
        globalJs: globalJsOfTheme,
        themeGeneralSettings,
      });
      const [htmlFilesInPreloader] = getHtmlFilesOfTheme({
        themeGeneralSettings,
        variant: '1 phần theme - bao gồm theme settings',
      });
      const css = await getCssFromThemeSettings({
        globalScss: globalScssOfTheme,
        themeGeneralSettings,
        variant: '1 phần theme - bao gồm theme settings',
        atomicCssForAdminNDev: atomicCss, // Mặc định preview sẽ chèn atomic css vào file global css của page vì nó không quan trọng
        target: 'Trích xuất kết quả sync lên shopify hoặc Lấy css để hiển thị trong builder',
      });
      const vendors = getVendorsOfTheme({
        vendors: vendorsOfTheme,
        themeGeneralSettings,
        cssVariables,
        layout: layoutSettings,
        target: 'Trích xuất kết quả sync lên shopify hoặc Lấy css để hiển thị trong builder',
        colorSchemes,
        themeVersion,
      });
      return {
        themeSettings: {
          generalSettings: themeGeneralSettings,
          cssVariables,
          layoutSettings,
          globalTranslations: globalTranslations as ThemeTranslationsResponse,
          colorSchemes,
        },
        addons: themeAddons.data,
        header: headers,
        footer: footers,
        themeId: themeId,
        themeName: '',
        vendors: vendorsOfTheme,
        globalJs: globalJsOfTheme,
        globalScss: globalScssOfTheme,
        files: [...js, ...css, htmlFilesInPreloader, ...vendors],
      };
    }
  };

  const getResultOfAddon = async (errorOption: ErrorOption): Promise<Result['filesOfAddons']> => {
    const pageId = getPageInfo('id');
    const page = pages.data[pageId];
    const { sections } = page;
    const addonSections = getAddonSections(sections);
    const addonSectionsEnable = getEnableSections(addonSections);
    const addonFilesOfPage = await Promise.all(
      addonSectionsEnable.map(async addonSection => {
        const isEnablePosition = themeAddons.data.find(addon => addon.sectionId === addonSection.id)?.positionEnabled;
        const [addonHtmlFile] = getHtmlFilesOfAddon({ addonSection, errorOption });
        const cssFile = await getCssFromAddonScss({ addonSection });
        const jsFile = getJsOfAddon({ addonSection });
        return {
          css: cssFile,
          js: jsFile,
          liquid: addonHtmlFile,
          id: addonSection.id,
          type: isEnablePosition ? 'addon enable position' : 'addon disable position',
        } as Result['filesOfAddons'][number];
      }),
    );

    const addons = addonFilesOfPage.flat();
    return Promise.resolve(addons.flat());
  };

  const getResult = async (errorOption: ErrorOption): Promise<Result> => {
    setIsExtracting(true);
    try {
      const result: Result = {
        builderType: isThemeBuilder() ? 'theme' : 'page',
        pages: await getResultOfPage(errorOption),
        theme: await getResultOfTheme(errorOption),
        filesOfAddons: await getResultOfAddon(errorOption),
      };
      return result;
    } finally {
      setIsExtracting(false);
    }
  };

  return { getResult, isExtracting };
};

export default useResultForPreview;
