import { SaveForBuilderType } from 'containers/BuilderPage/store/saveForBuilder/actions';
import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { sectionIdCodeVisibleSelector, pageSectionsSelector, sectionEdittingIdSelector } from 'store/selectors';
import { PreviewImage } from 'types/Page';
import { AdminSection, PageSectionType } from 'types/Sections';
import { isSectionMegamenu } from 'utils/functions/checkSectionType';

interface Actions {
  type: 'setInfoSection';
  payload: Partial<State>;
}
interface State {
  methodType: SaveForBuilderType;
  label: string;
  image: PreviewImage;
  category: { name: string; description: string };
  createdCategory: { name: string; description: string };
  type: PageSectionType;
  changelog: string;
  version: string;
}

const defaultState: State = {
  methodType: 'create',
  category: { description: '', name: '' },
  createdCategory: { description: '', name: '' },
  image: { src: '', width: 0, height: 0 },
  label: '',
  type: 'default',
  changelog: '',
  version: '',
};

const myReducer = (state: State, action: Actions) => {
  switch (action.type) {
    case 'setInfoSection': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export const useSaveInfoSection = () => {
  const sectionIdCodeVisible = useSelector(sectionIdCodeVisibleSelector);
  const sectionIdEditing = useSelector(sectionEdittingIdSelector);

  const sections = useSelector(pageSectionsSelector);
  const section = sections.find(section => section.id === (sectionIdCodeVisible || sectionIdEditing) && !isSectionMegamenu(section.type)) as
    | AdminSection
    | undefined;

  const [state, dispatch] = useReducer(myReducer, {
    ...defaultState,
    category: { name: section?.category?.name ?? '', description: section?.category?.description ?? '' },
    type: section?.type ?? defaultState.type,
    version: section?.currentVersion ?? defaultState.version,
    label: section?.label ?? defaultState.label,
    image: section?.image ?? defaultState.image,
  });

  const { category, changelog, createdCategory, image, label, methodType, type, version } = state;

  useEffect(() => {
    if (!!section) {
      dispatch({
        type: 'setInfoSection',
        payload: {
          label: section.label,
          type: section.type,
        },
      });

      if (section.image) {
        dispatch({
          type: 'setInfoSection',
          payload: {
            image: section.image,
          },
        });
      }

      if (section?.category?.name) {
        dispatch({
          type: 'setInfoSection',
          payload: {
            category: {
              name: section.category.name ?? '',
              description: section.category.description ?? '',
            },
          },
        });
      }
      if (section?.currentVersion) {
        dispatch({
          type: 'setInfoSection',
          payload: {
            version: section.currentVersion,
          },
        });
      }
    }
  }, [section]);

  return {
    methodType,
    label,
    image,
    createdCategory,
    type,
    category,
    section,
    changelog,
    version,
    dispatch,
  };
};
