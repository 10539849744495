import { Section } from './@types/Section';

type Key = Section['data']['schema']['blocks'][number]['type'] | Section['data']['schema']['settings'][number]['type'];

const counter: Record<Key, number> = {
  animate: 0,
  animateInOut: 0,
  array: 0,
  articlePicker: 0,
  blogPicker: 0,
  collectionPicker: 0,
  color: 0,
  date: 0,
  divider: 0,
  flexOrder: 0,
  font: 0,
  shopifyFont: 0,
  hidden: 0,
  icon: 0,
  image: 0,
  linkPicker: 0,
  navigation: 0,
  object: 0,
  productPicker: 0,
  products: 0,
  radioGroup: 0,
  responsive: 0,
  richText: 0,
  select: 0,
  slider: 0,
  space: 0,
  styleBox: 0,
  switch: 0,
  tags: 0,
  text: 0,
  textarea: 0,
  textReadOnly: 0,
  video: 0,
  color_scheme: 0,
  uploadCSV: 0,
};

export const getUniqId = (type: Key) => {
  counter[type]++;
  return counter[type];
};

export const reset = () => {
  Object.keys(counter).forEach(key => {
    const key_ = key as Key;
    counter[key_] = 0;
  });
};

// shopify field id được sử dụng tại "Shopify Settings" và "Shopfiy Liquid" nên cần cache
// Một số field có "name" dài quá mức cho phép của shopify -> Random về 1 chuỗi N ký tự -> Cần cache lại để giống hash
const cache: Map<string, string> = new Map();

interface GetCacheKey {
  theoryShopifyFieldId: string;
  section: Section;
}

const getCacheKey_ = ({ section, theoryShopifyFieldId }: GetCacheKey) => {
  if ('parentCommandId' in section && section.parentCommandId) {
    return `${theoryShopifyFieldId}___${section.parentCommandId}`;
  }
  return theoryShopifyFieldId;
};

export const getCacheValue = (params: GetCacheKey) => {
  return cache.get(getCacheKey_(params));
};

export const setCacheValue = (params: GetCacheKey, value: string) => {
  cache.set(getCacheKey_(params), value);
};

export const clearCache = () => {
  cache.clear();
  reset();
};
