import { handleWaitForSocketOfSyncShopifyFulfill } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { SagaReturnType, call, retry } from 'redux-saga/effects';
import { shopifyConnectionService } from 'services/ShopifyConnection';
import { handleSaveInBuilderPage } from 'services/ShopifyConnection/handleSaveInBuilderPage';

export interface SyncDisableVedaHeaderFooter {
  disableVedaHeaderFooterParams: ReturnType<typeof handleSaveInBuilderPage>['disableVedaHeaderFooterParams'];
}

export interface SyncDisableVedaHeaderFooterResult {
  statusSyncDisableVedaHeaderFooter: SyncFulfillStatus;
  isNeedIgnoreReportError: boolean;
}

export function* syncDisableVedaHeaderFooter({ disableVedaHeaderFooterParams }: SyncDisableVedaHeaderFooter) {
  yield retry(3, 1000, shopifyConnectionService.restoreThemeLayoutAndDeleteVedaHeaderFooter, {
    eventId: disableVedaHeaderFooterParams.eventId,
    eventType: disableVedaHeaderFooterParams.eventType,
  });
  const socketSyncDisableVedaHeaderFooter: SagaReturnType<typeof handleWaitForSocketOfSyncShopifyFulfill> = yield call(
    handleWaitForSocketOfSyncShopifyFulfill,
    'Restore lại layout của theme / xoá header và footer của veda',
  );
  return {
    isNeedIgnoreReportError: socketSyncDisableVedaHeaderFooter.isNeedIgnoreReportError,
    statusSyncDisableVedaHeaderFooter: socketSyncDisableVedaHeaderFooter.statusSync,
  } as SyncDisableVedaHeaderFooterResult;
}
