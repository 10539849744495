import { css, Styles, Theme } from 'wiloke-react-core';

export const modalBody = css`
  min-height: 480px;
  width: 650px;
`;

export const modalChild = css`
  height: 100%;
  padding: 40px 30px;
`;

export const container = (variant: 'style1' | 'style2') => ({ fonts }: Theme) => css`
  debug: StepModal-container;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  font-family: ${fonts.secondary};

  padding: ${variant === 'style2' ? '20px' : undefined};
`;

export const item = css`
  debug: StepModal-item;
  padding: 0 10px;
  text-align: center;
  flex: 1;
  position: relative;
`;

export const title = css`
  debug: StepModal-item__title;
  padding: 20px 0;
  font-size: 28px;
  font-weight: 500;
`;

export const steps = css`
  debug: StepModal-steps;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  min-height: 40px;

  .step-modal__dot-container:last-child .step-modal__dot-text {
    padding-right: 0px !important;
    &:after {
      display: none !important;
    }
  }

  .step-modal__dot-container:not(:first-child) {
    padding-left: 20px;
  }
`;

export const dotContainer = ({ colors }: Theme) => css`
  display: flex;
  align-items: center;
  column-gap: 10px;
  position: relative;
  background-color: ${colors.light};
`;

export const dot = (active: boolean) => ({ colors }: Theme) => css`
  debug: StepModal-dot;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 1;
  border: 2px solid ${active ? colors.secondary : colors.gray2};
  color: ${active ? colors.secondary : colors.gray5};
  background-color: ${active ? colors.light : colors.gray2};
`;

export const dotText = ({ colors, fonts }: Theme) => css`
  padding-right: 20px;
  font-family: ${fonts.secondary};
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 9999px;
    height: 1px;
    background-color: ${colors.secondary};

    top: 50%;
    left: 90%;
    transform: translateY(-50%);
  }
`;

export const groupButton = css`
  debug: StepModal-groupButton;
  display: flex;
  margin-top: 20px;
  column-gap: 10px;
`;

export const nextButton = ({ colors }: Theme) => css`
  font-weight: 500;
  user-select: none;
  background-color: ${colors.primary};
  border-radius: 4px;
`;

export const prevButton = ({ colors }: Theme) => css`
  font-weight: 500;
  user-select: none;
  background-color: ${colors.gray2};
  border-radius: 4px;
  color: ${colors.gray8};
`;

export const iconLeft = (disable: boolean) => ({ colors }: Theme): Styles => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '36px',
  height: '36px',
  borderRadius: '50%',
  backgroundColor: colors.light,
  cursor: disable ? 'not-allowed' : 'pointer',
  border: `1px solid ${colors.gray5}`,
  opacity: disable ? 0.6 : 1,
  userSelect: 'none',
  position: 'absolute',
  left: '10px',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1,
});

export const iconRight = (disable: boolean) => ({ colors }: Theme): Styles => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '36px',
  height: '36px',
  borderRadius: '50%',
  backgroundColor: colors.light,
  cursor: disable ? 'not-allowed' : 'pointer',
  border: `1px solid ${colors.gray5}`,
  opacity: disable ? 0.6 : 1,
  userSelect: 'none',
  position: 'absolute',
  right: '10px',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1,
});

export const containerStyle2 = css`
  display: flex;
  height: 100%;
`;

export const extraNode = ({ colors }: Theme) => css`
  width: 760px;
  max-width: 760px;
  min-width: 760px;
  height: 100%;
  background-color: ${colors.gray2};
  padding: 20px;
`;
