import { MyModal } from '@wiloke/ui';
import { CheckProgress } from 'components/CircleProgress';
import { useSelector } from 'react-redux';
import { restoreToDefaultSelector, useSetVisibleModalAfterRestore } from 'store/reducers/sliceRestoreToDefault';
import { authSelector } from 'store/selectors';
import { i18n } from 'translation';
import { composeShopName } from 'utils/functions/composeShopName';
import { Styles, Text, Theme } from 'wiloke-react-core';

const bodyModalStyles = ({ colors }: Theme): Styles => ({
  minWidth: '600px',
  width: '600px',
  color: colors.gray8,
  height: '400px',
  textAlign: 'center',
});

export const ModalAfterRestore = () => {
  const { shopName } = useSelector(authSelector);
  const { visibleModalAfterRestore } = useSelector(restoreToDefaultSelector);

  const setVisibleModalAfterRestore = useSetVisibleModalAfterRestore();

  const redirectSettingsApp = () => {
    const url = `https://admin.shopify.com/store/${composeShopName(shopName)}/settings/apps`;
    window.open(url, '_blank');
    setVisibleModalAfterRestore(false);
  };

  return (
    <MyModal
      onCancel={() => {
        setVisibleModalAfterRestore(false);
      }}
      cancelText=""
      okText=""
      isVisible={visibleModalAfterRestore}
      headerText={i18n.t('adminDashboard.danger_zone_page.restore_veda')}
      bodyCss={bodyModalStyles}
    >
      <CheckProgress css={{ marginTop: '30px', marginBottom: '30px', width: '100px', height: '100px' }} />
      <Text css={{ padding: '0 50px' }} fontFamily="secondary" size={16} color="dark">
        {i18n.t('adminDashboard.danger_zone_page.modal_after_restore')}{' '}
        <Text tagName="span" size={16} color="primary" css={{ cursor: 'pointer', fontWeight: '500' }} onClick={redirectSettingsApp}>
          {i18n.t('general.link')}
        </Text>
        .
      </Text>
    </MyModal>
  );
};
