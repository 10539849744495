import { BusinessIcon } from 'components/PlanBadge/Icons/BusinessIcon';
import { PlanIconProps } from 'components/PlanBadge/types';
import { FC } from 'react';
import { View } from 'wiloke-react-core';
import * as styles from './styles';

export const BusinessBadge: FC<PlanIconProps> = ({ css }) => {
  return (
    <View css={[styles.container, css]} radius={4} backgroundColor="tertiary" color="light" fontFamily="tertiary">
      <BusinessIcon />
      <View tagName="span" css={styles.text}>
        BUSINESS
      </View>
    </View>
  );
};
