import { SectionCategoryTag } from 'types/Sections';
import { createAsyncAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';

export const createCategoryOfThemeProduct = createAsyncAction([
  '@ThemeManager/createCategoryOfThemeProduct/request',
  '@ThemeManager/createCategoryOfThemeProduct/success',
  '@ThemeManager/createCategoryOfThemeProduct/failure',
])<{ name: string; description: string }, SectionCategoryTag, undefined>();

export const getCategoriesOfThemeProduct = createAsyncAction([
  '@ThemeManager/getCategoriesOfThemeProduct/request',
  '@ThemeManager/getCategoriesOfThemeProduct/success',
  '@ThemeManager/getCategoriesOfThemeProduct/failure',
])<undefined, SectionCategoryTag[], undefined>();

export const deleteCategoryOfThemeProduct = createAsyncAction([
  '@PageManager/deleteCategoryOfThemeProduct/request',
  '@PageManager/deleteCategoryOfThemeProduct/success',
  '@PageManager/deleteCategoryOfThemeProduct/failure',
])<{ commandId: string }, { commandId: string }, { commandId: string }>();

export const updateCategoryOfThemeProduct = createAsyncAction([
  '@PageManager/updateCategoryOfThemeProduct/request',
  '@PageManager/updateCategoryOfThemeProduct/success',
  '@PageManager/updateCategoryOfThemeProduct/failure',
])<{ commandId: string; description: string; name: string }, { commandId: string; description: string; name: string }, { commandId: string }>();

export const useCreateCategoryOfThemeProduct = createDispatchAsyncAction(createCategoryOfThemeProduct);
export const useGetCategoriesOfThemeProduct = createDispatchAsyncAction(getCategoriesOfThemeProduct);
export const useDeleteCategoryOfThemeProduct = createDispatchAsyncAction(deleteCategoryOfThemeProduct);
export const useUpdateCategoryOfThemeProduct = createDispatchAsyncAction(updateCategoryOfThemeProduct);
