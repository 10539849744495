import { getUsers } from './getUsers/getUsers';
import { updateRoleUser } from './updateRole/updateRoleUser';
import { updatePlan } from './updatePlan/updatePlan';
import { updatePlanInfo } from './updatePlan/updatePlanInfo';

export const supportApi = {
  getUsers,
  updateRoleUser,
  updatePlan,
  updatePlanInfo,
};
