import { css, Theme } from 'wiloke-react-core';

export const iconWrap = ({ colors }: Theme) => css`
  debug: TopBar_iconWrap;
  background-color: ${colors.light};
  min-width: 46px;
  height: 46px;
  min-height: 46px;
  max-height: 46px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const icon = css`
  debug: TopBar_icon;
  width: 46px;
  vertical-align: middle;
`;

export const itemFull = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const selectTheme = css`
  :global {
    #veda-root .sync-theme-error .ant-select-selector {
      padding-top: 2px !important;
      padding-bottom: 2px !important;
      border-radius: 4px 0px 0px 4px !important;
      border-right: unset !important;
    }

    #veda-root .sync-theme-error .ant-select {
      max-width: 164px !important;
    }

    #veda-root .sync-theme-error .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 35px !important;
      padding-top: 2px !important;
      padding-bottom: 2px !important;
    }
  }
`;
