import { notification } from 'antd';
import configureApp from 'configureApp';
import { ModalUpdateShopifyThemeId } from 'containers/ModalUpdateShopifyThemeId/ModalUpdateShopifyThemeId';
import { useEffect, useState } from 'react';
import { put, select, take } from 'redux-saga/effects';
import { decodeIdOfLiquidFile } from 'services/ShopifyConnection/utils';
import fetchAPI from 'utils/functions/fetchAPI';
import getPageInfo from 'utils/functions/getInfo';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { v4 } from 'uuid';
import { getActionType } from 'wiloke-react-core/utils';
import { OnConnect, OnDisconnect } from '../../../hooks/useSocket/types';
import { selectSyncThemeByResultOfExtractThemeToFileForSync } from '../selectors';
import { setEventId, setStreamSocket, useResetSocketState, useSetEventId, useSetStreamSocket } from '../store/actions';
import { AxiosResponse } from 'axios';

interface FirstConnectionMessage {
  firstConnection: true;
}

export interface SyncThemeByResultOfExtractThemeToFileForSyncMessage {
  // Trả về 1 message undefined để báo hiệu kết nối thành công
  firstConnection: false;
  // Khi bắn về Socket, a có bổ sung errorCode. Nếu code Status là 404 thì e hiện popup yêu cầu update theme Id nhé. Cái này áp dụng cho mọi api sync.
  errorCode?: 'SyncThemeNotFound';
  pageCommandId: string;
  message: string;
  log?: string;
  id?: string;
  step: 'PROCESSING' | 'SUCCESS' | 'END';
  status: 'SUCCESS' | 'ERROR';
  previewUrls?: string[] | null;
  eventId: string;
  /** Đặt như thế này để dễ kiểm soát (vì mỗi chỗ là 1 api khác nhau) */
  eventType:
    | 'Đồng bộ theme / Ghi page'
    | 'Đồng bộ theme / Ghi global (sinh ra từ themeSettings)'
    | 'Đồng bộ theme / Ghi header'
    | 'Đồng bộ theme / Ghi footer'
    | 'Đồng bộ theme / Ghi addon enable position'
    | 'Đồng bộ theme / Ghi addon disable position'
    | 'Đồng bộ theme / Ghi các addon disable position vừa tạo xong vào file theme.liquid'
    | 'Đồng bộ theme / Ghi file atomic css'
    | 'Đồng bộ theme / Ghi file raw atomic css'
    | 'Đồng bộ theme / Sync translation'
    | 'Đồng bộ theme / Ghi file configure theme';
}

let eventSource: EventSource | null = null;
let eventIdInteracting: string = v4();
let eventSourceListenerMessageEvent: ((event: MessageEvent) => void) | null = null;
let eventSourceListenerSuccessEvent: ((event: Event) => void) | null = null;
let eventSourceListenerErrorEvent: ((event: Event) => void) | null = null;

const closeSocket = async () => {
  if (eventSource) {
    if (eventSourceListenerMessageEvent) {
      eventSource.removeEventListener('message', eventSourceListenerMessageEvent);
      eventSourceListenerMessageEvent = null;
    }
    if (eventSourceListenerSuccessEvent) {
      eventSource.removeEventListener('open', eventSourceListenerSuccessEvent);
      eventSourceListenerSuccessEvent = null;
    }
    if (eventSourceListenerErrorEvent) {
      eventSource.removeEventListener('error', eventSourceListenerErrorEvent);
      eventSourceListenerErrorEvent = null;
    }
    eventSource?.close();
    eventSource = null;
  }
  try {
    const response: AxiosResponse<{ eventId?: string }> = await fetchAPI.request({
      url: '/' + configureApp.endpoint['shopify-connections'] + `/sync/tracking`,
      method: 'DELETE',
      params: {
        eventId: eventIdInteracting,
      },
    });
    eventIdInteracting = response.data.eventId ?? 'Cần override bằng v4';
  } finally {
    if (eventIdInteracting === 'Cần override bằng v4') {
      eventIdInteracting = v4();
    }
  }
};

const createSocket = async () => {
  const { shopName } = getUserInfo();
  await closeSocket();
  eventSource = new EventSource(configureApp.eventSourceForSyncShopify + `/sync/tracking?shopName=${shopName}&eventId=${eventIdInteracting}`);
};

export const useSocketForSyncThemeByResultOfExtractThemeToFileForSync = () => {
  const [statusSocketConnection, setStatusSocketConnection] = useState<Status>(eventSource?.OPEN ? 'success' : 'idle');

  const resetAllState = useResetSocketState();
  const setEventId = useSetEventId();
  const setStreamSocket = useSetStreamSocket();

  const handleDisconnect = async ({ cb }: OnDisconnect, isConnectAction: boolean) => {
    resetAllState();
    await closeSocket();
    if (!isConnectAction) {
      setStatusSocketConnection('idle');
    }
    cb?.();
  };

  const handleMessageSocketOfForceSyncShopify = (data: SyncThemeByResultOfExtractThemeToFileForSyncMessage) => {
    if (data.errorCode === 'SyncThemeNotFound') {
      ModalUpdateShopifyThemeId.open({
        type: getPageInfo('themeId') ? 'Theme' : 'Session',
        isErrorShopifyThemeRemoved: true,
        themeCommandId: getPageInfo('themeId'),
        error: data.message,
      });
    }
    if (data.step.toUpperCase() === 'END' && data.status.toUpperCase() === 'ERROR') {
      setStreamSocket({ socketData: data, status: 'failure', eventType: data.eventType });
      notification.error({
        message: 'Quá trình đồng bộ gặp lỗi',
        description: data.message,
      });
    }
    if (data.step.toUpperCase() === 'END' && data.status.toUpperCase() === 'SUCCESS') {
      setStreamSocket({ socketData: data, status: 'success', eventType: data.eventType });
      notification.success({
        message: 'Đồng bộ thành công một phần',
        description: data.message,
      });
    }
    if (data.step.toUpperCase() === 'PROCESSING' && data.status.toUpperCase() === 'ERROR') {
      setStreamSocket({ socketData: data, status: 'failure', eventType: data.eventType });
      notification.error({
        message: 'Quá trình đồng bộ gặp lỗi',
        description: data.message,
      });
    }
    if (data.step.toUpperCase() === 'PROCESSING' && data.status.toUpperCase() !== 'ERROR') {
      setStreamSocket({ socketData: data, status: 'loading', eventType: data.eventType });
      notification.info({
        message: 'Quá trình đồng bộ đang diễn ra',
        description: data.message,
      });
    }
  };

  const handleError = async (isConnectAction: boolean, onError: OnConnect['onError']) => {
    await handleDisconnect({}, isConnectAction);
    onError?.();
    setStatusSocketConnection('failure');
  };

  const handleSuccess = async (onSuccess: OnConnect['onSuccess'], onError: OnConnect['onError']) => {
    try {
      setEventId(eventIdInteracting);
      setStatusSocketConnection('success');
      eventSourceListenerMessageEvent = event => {
        const data: SyncThemeByResultOfExtractThemeToFileForSyncMessage | FirstConnectionMessage = JSON.parse(event.data);
        console.log('useSocketForSyncThemeByResultOfExtractThemeToFileForSync.ts', { data, eventIdInteracting });
        if (data && 'firstConnection' in data && data.firstConnection === false && 'eventId' in data && eventIdInteracting === data.eventId) {
          console.log('Event accepted: ', { data, eventIdInteracting });
          handleMessageSocketOfForceSyncShopify(data as SyncThemeByResultOfExtractThemeToFileForSyncMessage);
        }
      };
      eventSource?.addEventListener('message', eventSourceListenerMessageEvent);
      onSuccess?.(eventIdInteracting);
    } catch {
      await handleError?.(true, onError);
    }
  };

  const handleConnect_ = async ({ onSuccess, onError }: OnConnect) => {
    // Nếu page "iframe" và "preview" hoặc socket trước đó đang connect và thành công ngay khi gọi function này thì không connect socket
    if (['/iframe', '/preview'].includes(window.location.pathname)) {
      setStatusSocketConnection('success');
      return;
    }
    setStatusSocketConnection('loading');
    try {
      await createSocket();
      eventSourceListenerSuccessEvent = () => {
        handleSuccess(onSuccess, onError);
        setStatusSocketConnection('success');
      };
      eventSourceListenerErrorEvent = async event => {
        if ('readyState' in event && event.readyState !== EventSource.CLOSED) {
          await handleError(false, onError);
          setStatusSocketConnection('failure');
        }
      };
      eventSource?.addEventListener('error', eventSourceListenerErrorEvent);
      eventSource?.addEventListener('open', eventSourceListenerSuccessEvent);
    } catch {
      await handleError(false, onError);
      setStatusSocketConnection('failure');
    }
  };

  const handleConnect = async (callbacks: OnConnect) => {
    setStatusSocketConnection('loading');
    if (statusSocketConnection === 'success') {
      handleDisconnect(
        {
          cb: async () => await handleConnect_(callbacks),
        },
        true,
      );
    } else {
      await handleConnect_(callbacks);
    }
  };

  useEffect(() => {
    return () => {
      if (statusSocketConnection === 'success') {
        handleDisconnect({}, false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    statusSocketConnection,
    connect: handleConnect,
    disconnect: async (callbacks: OnDisconnect) => {
      await handleDisconnect(callbacks, false);
      return;
    },
  };
};

const fulfillStatus: SyncFulfillStatus[] = ['success', 'failure'];

interface RTHandleWaitForSocketSyncThemeByResultOfExtractThemeToFileForSync {
  statusSync: SyncFulfillStatus;
  sectionCommandId: undefined;
  isLostConnection: boolean;
  isNeedIgnoreReportError: boolean;
  previewUrl: string | undefined;
  message: string | undefined;
}
/** Saga ĐỢI và lắng nghe socket sync shopify hoàn thành */
export function* handleWaitForSocketOfSyncThemeByResultOfExtractThemeToFileForSync(
  eventType: SyncThemeByResultOfExtractThemeToFileForSyncMessage['eventType'],
) {
  const { socket }: ReturnType<typeof selectSyncThemeByResultOfExtractThemeToFileForSync> = yield select(
    selectSyncThemeByResultOfExtractThemeToFileForSync,
  );
  const { streams, eventId } = socket;
  const socketData = streams[eventType].socketData;
  /** NOTE: @tuong -> Trong file cũng có đoạn check tương tự -> Nếu thay đổi phải xem xét việc update cả những cái bên dưới */
  /** NOTE: @tuong -> function có sự liên quan đến "watchSyncToShopify.ts" -> Nếu thay đổi phải xem xét file đó */
  if (fulfillStatus.includes(streams[eventType].status as SyncFulfillStatus)) {
    /**
     * NOTE: Thay vì làm 1 flow để reset cờ trạng thái của socket thì cứ thành công hoặc thất bại sẽ reset luôn
     * Câu hỏi 1: Tại sao làm như vậy
     * Trả lời: Tại thời điểm comment này được viết mọi chuyện xảy ra như sau
      - Các api sync shopify đều sử dụng socket
      - Sync từng phần để đảm bảo rate limit và giảm thiểu rủi ro (sync thiếu cái A thì chết app, ...)
      - Tại từng chặng sync sẽ phải check trạng thái sync trước đó phải "SUCCESS" hoặc "FAILURE" thì mới được tiếp tục chặng tiếp theo
      - 1 "flow saga" để chịu trách nhiệm reset cờ trạng thái của các chặng khi chặng đó đã hoàn thành (tức "SUCCESS" hoặc "FAILURE")
      - Trước đó mọi thứ đều OK. Nhưng tại thời điểm comment này được viết nó xảy ra lỗi. Ngữ cảnh như sau:
        + Thực hiện xoá 1 page bất kì -> action đổi cờ trạng thái chặng sync "SUCCESS" hoặc "FAILURE" và action reset cờ trạng thái cùa chặng sync xảy ra gần như là đồng thời (chưa đến 1ms) -> Bằng 1 cách nào đó function này không được thực thi khi cờ trạng thái sync chuyển "SUCCESS" hoặc "FAILURE" mà chỉ nhận được cờ trạng thái "SẴN SÀNG" -> các api sync bị treo do chặng sync trước đó không thông báo là đã "SUCCESS" hoặc "FAILURE" -> LỖI CỰC KÌ NGHIỆM TRỌNG
        + @tuong đề nghị một cách sửa như sau:
          -> Bỏ '1 "flow saga" để chịu trách nhiệm reset cờ trạng thái của các chặng khi chặng đó đã hoàn thành (tức "SUCCESS" hoặc "FAILURE")'
          -> Thay vào đó đổi cờ trạng thái chặng sync ngay tại function này -> Điều này làm cho việc cờ trạng thái chặng sync chỉ phụ thuộc vào function này -> Mọi thứ đồng bộ -> Sẽ là OK
     */
    yield put(setStreamSocket({ socketData: undefined, status: 'idle', eventType }));
    return {
      statusSync: streams[eventType].status,
      sectionCommandId: socketData && 'id' in socketData ? decodeIdOfLiquidFile(socketData.id) : undefined,
      isLostConnection: false,
      isNeedIgnoreReportError: socketData?.errorCode === 'SyncThemeNotFound',
      previewUrl: socketData?.previewUrls?.[0],
      message: socketData?.message,
    } as RTHandleWaitForSocketSyncThemeByResultOfExtractThemeToFileForSync;
  }
  if (!eventId) {
    return {
      statusSync: 'failure',
      sectionCommandId: undefined,
      isLostConnection: true,
      isNeedIgnoreReportError: socketData?.errorCode === 'SyncThemeNotFound',
      previewUrl: undefined,
      message: "Không có 'eventId'",
    } as RTHandleWaitForSocketSyncThemeByResultOfExtractThemeToFileForSync;
  }
  while (true) {
    yield take([getActionType(setStreamSocket), getActionType(setEventId)]);
    const { socket }: ReturnType<typeof selectSyncThemeByResultOfExtractThemeToFileForSync> = yield select(
      selectSyncThemeByResultOfExtractThemeToFileForSync,
    );
    const { streams, eventId } = socket;
    const socketData = streams[eventType].socketData;
    if (fulfillStatus.includes(streams[eventType].status as SyncFulfillStatus)) {
      yield put(setStreamSocket({ socketData: undefined, status: 'idle', eventType }));

      return {
        statusSync: streams[eventType].status,
        sectionCommandId: socketData && 'id' in socketData ? decodeIdOfLiquidFile(socketData.id) : undefined,
        isLostConnection: false,
        isNeedIgnoreReportError: socketData?.errorCode === 'SyncThemeNotFound',
        previewUrl: socketData?.previewUrls?.[0],
        message: socketData?.message,
      } as RTHandleWaitForSocketSyncThemeByResultOfExtractThemeToFileForSync;
    }
    if (!eventId) {
      return {
        statusSync: 'failure',
        sectionCommandId: undefined,
        isLostConnection: true,
        isNeedIgnoreReportError: socketData?.errorCode === 'SyncThemeNotFound',
        previewUrl: undefined,
      } as RTHandleWaitForSocketSyncThemeByResultOfExtractThemeToFileForSync;
    }
  }
}
