import { PageSection } from 'types/Sections';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';

interface GetSectionsOfPageAtom {
  sectionCommandIds: string[];
  timestamp?: number;
}

export const getSectionsOfPageClientUseAtomTemplate = async ({ sectionCommandIds, timestamp }: GetSectionsOfPageAtom): Promise<PageSection[]> => {
  const { lastCache } = getUserInfo();

  const responses = await pageApis.atom.userApi.section.getSectionsOfAtom({
    commandIds: sectionCommandIds,
    cacheVersion: lastCache,
    sessionId: timestamp,
  });
  return responses.reduce<PageSection[]>((res, info) => {
    if (info) {
      return res.concat(info);
    }
    return res;
  }, []);
};
