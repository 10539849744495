import { AxiosResponse } from 'axios';
import { BE_ThemeClient } from 'services/ThemeService/VedaApplication/types';
import fetchAPI from 'utils/functions/fetchAPI';
import { clientBaseUrl } from '../const';

interface UpdateClient {
  commandId: string;
  /** Dữ liệu sẽ được apply vào record */
  shopifyThemeId: string;
}

interface ResponseSuccess {
  message: string;
  info: BE_ThemeClient;
}

export const updateShopifyThemeId = async ({ shopifyThemeId, commandId }: UpdateClient) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${clientBaseUrl}/${commandId}/shopify-theme-id`,
    method: 'PUT',
    data: {
      shopifyThemeId,
    },
  });
  return response.data;
};
