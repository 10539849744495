window.Twig.extend(Twig => {
  Twig.exports.extendTag({
    type: 'render',
    regex: /render/,
    next: [],
    open: true,
    compile: function(token: any) {
      return token;
    },
    parse: function(token) {
      const { match } = token ?? {};
      return {
        output: `{% ${match.input} %}`,
        chain: false,
      };
    },
  });
});

/**
 * TODO: Có thể làm được bằng cách lấy về content file r thế vào (lưu ý cần lưu lại những file đã request) nhưng liệu có cần thiết và vì phải đợi request nên lần đầu vào app sẽ lâu hơn
 * @link https://shopify.dev/api/liquid/tags/theme-tags#render
 */
export const render = (liquid: string) => {
  return liquid;
};
