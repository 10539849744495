import { MyModal } from '@wiloke/ui';
import { CheckProgress } from 'components/CircleProgress';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useResetPreviewWithShopifyStatus } from 'store/global/socket/syncShopifyNClonePageAtomServiceToThemeAtomService/actions';
import { socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector } from 'store/selectors';
import { i18n } from 'translation';
import { at } from 'utils/at';
import { Text, View } from 'wiloke-react-core';

// FIXME: Lắp content + UI
export const ModalSyncShopifyForPreviewSuccess = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [previewUrlsState, setPreviewUrlsState] = useState<string[] | undefined | null>(undefined);

  const { previewUrls, statusPreviewWithShopify } = useSelector(socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector);
  const resetPreviewWithShopifyStatus = useResetPreviewWithShopifyStatus();

  const handleCancel = () => {
    setIsVisible(false);
    setPreviewUrlsState(undefined);
  };

  const handleOk = () => {
    const previewUrl = at(previewUrlsState, 0);
    if (previewUrl) {
      window.open(previewUrl);
    }
    handleCancel();
  };

  useEffect(() => {
    if (statusPreviewWithShopify === 'success') {
      resetPreviewWithShopifyStatus();
      const previewUrl = at(previewUrls, 0);
      if (previewUrl) {
        const $anchorEl = document.createElement('a');
        $anchorEl.target = '_blank';
        $anchorEl.href = previewUrl;
        $anchorEl.click();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusPreviewWithShopify]);

  useEffect(() => {
    if (statusPreviewWithShopify === 'success') {
      const previewUrl = at(previewUrls, 0);
      setIsVisible(true);
      setPreviewUrlsState(previewUrl ? [previewUrl] : []);
      resetPreviewWithShopifyStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusPreviewWithShopify]);

  return (
    <MyModal
      isVisible={isVisible}
      headerText={i18n.t('publish_shopify.sync_success')}
      onCancel={handleCancel}
      onOk={handleOk}
      okText={previewUrlsState?.length ? i18n.t('publish_shopify.live_preview') : i18n.t('publish_shopify.continue_editing')}
      cancelText={previewUrlsState?.length ? i18n.t('publish_shopify.continue_editing') : ''}
      depsHeightRecalculation={[isVisible]}
    >
      <View css={{ padding: '12px 6px', textAlign: 'center' }}>
        <CheckProgress css={{ margin: '0 auto 12px auto' }} />
        {previewUrlsState?.map(url => (
          <View key={url}>
            <Text tagName="a" target="blank" href={url}>
              {url}
            </Text>
          </View>
        ))}
      </View>
    </MyModal>
  );
};
