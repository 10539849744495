import { css, Theme } from 'wiloke-react-core';

export const listContainer = ({ colors }: Theme) => css`
  debug: CssPropKeys__listContainer;
  background: ${colors.light};
  box-shadow: 0px 4px 10px rgba(${colors.rgbDark}, 0.1);
  user-select: none;
`;

export const listBody = css`
  debug: CssPropKeys__listBody;
  height: 400px;
  overflow: auto;
`;

export const listItem = (disabled: boolean) => ({ colors }: Theme) => css`
  debug: CssPropKeys__listItem;
  position: relative;
  cursor: ${!disabled ? 'pointer' : 'no-drop'};
  user-select: none;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px 12px;

  &:hover {
    background-color: ${colors.gray1};
  }
`;

export const listItemLabel = css`
  debug: CssPropKeys__listItemLabel;
  margin-left: 8px;
  line-height: 16px;
`;

export const listItemActiveIcon = css`
  debug: CssPropKeys__listItemActiveIcon;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
`;

export const listFooter = css`
  debug: CssPropKeys__listFooter;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const message = css`
  cursor: pointer;
  padding: 15px 10px;

  :global {
    .ant-message-notice-content {
      background-color: #09009b !important;
      border-radius: 6px !important;
      padding: 0 !important;
      color: #efefef;
      font-size: 16px !important;
    }

    .ant-message-custom-content {
      display: flex !important;
      align-items: center !important;
      font-weight: 500 !important;
    }
  }
`;

export const itemResultContainer = css`
  debug: CssPropKeys__itemResultContainer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  user-select: none;
`;

export const itemResultLeft = css`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const itemResultDragIcon = css`
  debug: CssPropKeys__itemResultDragIcon;
  transform: rotate(45deg);
`;

export const itemResultLabel = css`
  debug: CssPropKeys__itemResultLabel;
  margin-left: 8px;
`;

export const itemResultTrashIcon = css`
  debug: CssPropKeys__itemResultTrashIcon;
  cursor: pointer;
  margin-left: 4px;
  padding: 4px;
`;

export const badge = css`
  padding: 3px 8px;
  margin: 2px;
  min-width: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex: none;
  align-self: center;
  max-width: 100%;
`;

export const badgeTitle = css`
  debug: CssPropKeys_badgeTitle;
  flex: 1;
  margin-right: 4px;
`;

export const tagInner = ({ colors }: Theme) => css`
  debug: InputTags_tagInner;

  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
  min-height: 42px;
  border: 1px solid ${colors.gray3};
  border-radius: 4px;
  padding: 2px 8px;
`;

export const input = css`
  debug: InputTags_input;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  appearance: none;

  width: 100%;
  min-width: 10px;
  cursor: auto;
`;
export const inputContainer = css`
  debug: InputTags_inputContainer;

  margin-inline-start: 0;
  position: relative;
  max-width: 100%;
  align-self: center;
`;
