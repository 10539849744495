import { Button, MyModal, Title } from '@wiloke/ui';
import { Progress } from 'antd';
import csvIcon from 'assets/csv.png';
import { DragUploadAntd, DragUploadAntdProps } from 'components/DragUploadAntd';
import Field from 'components/Field';
import { validateEmail } from 'components/LinkPicker/utils/validateEmail';
import { AdvancedIcon } from 'components/PlanBadge';
import TextInput from 'components/TextInput';
import UploadPlaceholder from 'components/UploadPlaceholder';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';
import { VideoCart } from 'containers/ModalInstallTheme/components/VideoCard';
import withDebounce from 'hocs/withDebounce';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useImportFileCSV } from 'store/global/importCSV/actionsImport';
import { importCSVSelector, useSetImportMessage } from 'store/global/importCSV/sliceImportCSV';
import { authSelector } from 'store/selectors';
import { i18n } from 'translation';
import { PLAN_STATIC_DATA, VEDA_FUNCTION_ENUM } from 'utils/constants/PlanEnum';
import { validatePlan } from 'utils/functions/validatePlan';
import { FontAwesome, Image, Space, Styles, Text, Theme, View, useTheme } from 'wiloke-react-core';
export interface UploadCSVFieldProps {
  label: string;
}

const styles = {
  extraNode: ({ colors }: Theme): Styles => ({
    width: '720px',
    maxWidth: '720px',
    minWidth: '720px',
    height: '100%',
    backgroundColor: colors.gray2,
    padding: '60px 20px',
    borderRadius: '6px',
  }),
  bodyCss: (): Styles => ({
    maxWidth: '1400px',
    width: 'calc(100% - 60px)',
    height: 'calc(100% - 250px)',
    margin: '30px 50px',
    minHeight: '660px',
  }),
};

const DebouncedInput = withDebounce(TextInput, 'value', 'onValueChange');

function convertFileSize(fileSizeInBytes: number, targetUnit = 'KB'): string {
  if (fileSizeInBytes < 0) {
    throw new Error('File size must be a non-negative number.');
  }

  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (!units.includes(targetUnit)) {
    throw new Error('Invalid target unit. Please choose from B, KB, MB, GB, TB, PB, EB, ZB, or YB.');
  }

  const targetIndex = units.indexOf(targetUnit);
  const fileSizeInTargetUnit = fileSizeInBytes / Math.pow(1024, targetIndex);

  return fileSizeInTargetUnit.toFixed(2) + ' ' + targetUnit;
}

const UploadCSVField = ({ label }: UploadCSVFieldProps) => {
  const { plan, role } = useSelector(authSelector);
  const { importStatus, message } = useSelector(importCSVSelector);
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();
  const importFileCSV = useImportFileCSV();

  const [visible, setVisible] = useState(false);
  const [fileInfo, setFileInfo] = useState<{ name: string; size: string; content: File | null }>({
    name: '',
    size: '',
    content: null,
  });
  const [email, setEmail] = useState('');
  const [percent, setPercent] = useState(0);
  const [checkEmail, setCheckEmail] = useState(true);
  const { colors } = useTheme();
  const validPlan = validatePlan({ userPlan: plan.planHandle, itemPlan: VEDA_FUNCTION_ENUM.upload_csv, role }) === 'pass';
  const setImportMessage = useSetImportMessage();

  const handleCancel = () => {
    setVisible(false);
    setFileInfo({ name: '', size: '', content: null });
    setPercent(0);
    setEmail('');
    setImportMessage('');
  };

  const handleImportCSV = () => {
    if (fileInfo.content) {
      importFileCSV.request({
        email,
        file: fileInfo.content,
      });
    }
  };

  const handleModalCSV = () => {
    if (validPlan) {
      setVisible(true);
    } else {
      setPlanRequesting({
        handle: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.upload_csv].handle,
        type: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.upload_csv].type,
        pricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.upload_csv].pricePerMonth,
        pricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.upload_csv].pricePerYear,
        title: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.upload_csv].title,
        originalPricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.upload_csv].pricePerMonth,
        originalPricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.upload_csv].pricePerYear,
      });
      setValidateCouponVisible(true);
    }
  };

  const handleUploadFile: DragUploadAntdProps['customRequest'] = ({ file, onSuccess, onError }) => {
    const _file = file as File;

    setPercent(0);

    const inter = setInterval(() => {
      setPercent(prevPercent => {
        const newPercent = prevPercent + 20;
        if (newPercent > 100) {
          clearInterval(inter);
          return 100;
        }
        return newPercent;
      });
    }, 100);

    const reader = new FileReader();
    reader.readAsText(file as File);
    reader.onload = event => {
      const result = event.target?.result;
      if (typeof result === 'string') {
        try {
          setFileInfo({
            name: _file.name,
            size: convertFileSize(_file.size),
            content: _file,
          });
          onSuccess?.('Success');
        } catch {
          onError?.(new Error('Error'));
        }
      } else {
        onError?.(new Error('Error'));
      }
    };
  };

  const handleRemoveCSV = () => {
    setFileInfo({ name: '', size: '', content: null });
  };

  const handleChangeEmail = (value: string) => {
    const check = validateEmail(value);
    setCheckEmail(check);
    if (value === '') {
      setCheckEmail(true);
    }
    setEmail(value);
  };

  return (
    <>
      <View css={{ padding: '10px' }} radius={6} borderColor="gray3" borderStyle="solid" borderWidth={1} backgroundColor="light">
        <Text tagName="h6" css={{ marginBottom: '6px' }}>
          {label || 'Import CSV file'}
        </Text>
        <Button
          radius={4}
          size="extra-small"
          backgroundColor="light"
          borderColor={validPlan ? 'gray3' : 'quaternary'}
          borderStyle="solid"
          borderWidth={1}
          color={validPlan ? 'gray9' : 'quaternary'}
          onClick={handleModalCSV}
        >
          <View css={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
            {!validPlan && (
              <View
                css={{ width: '20px', height: '20px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                backgroundColor="quaternary"
              >
                <AdvancedIcon />
              </View>
            )}

            <View>Upload CSV File</View>
          </View>
        </Button>
      </View>

      <MyModal
        isVisible={visible}
        okText={''}
        cancelText={''}
        onCancel={handleCancel}
        onOk={handleImportCSV}
        headerText="Import Reviews"
        bodyCss={styles.bodyCss}
        contentCss={{ height: '100%' }}
      >
        <View css={{ display: 'flex', gap: '10px', height: '100%' }}>
          <View css={{ width: '100%', padding: '60px' }}>
            <Text
              color="gray9"
              size={24}
              fontFamily="secondary"
              css={{ fontWeight: '500', textTransform: 'capitalize', textAlign: 'center', marginBottom: '20px' }}
            >
              Import Reviews
            </Text>
            <DragUploadAntd
              accept={`.csv`}
              showUploadList={false}
              containerStyle={{ background: 'transparent', padding: 0 }}
              contentStyle={{ padding: '10px' }}
              Content={
                <UploadPlaceholder
                  css={{ borderColor: colors.gray5 }}
                  text="Drag & Drop or Choose file to upload"
                  size="large"
                  description="In CSV format only"
                />
              }
              maxSize={100}
              customRequest={handleUploadFile}
            />

            {!!fileInfo.name && (
              <View css={{ padding: '15px', marginTop: '20px' }} radius={6} backgroundColor="gray2">
                <View css={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <Image src={csvIcon} width={40} />
                  <View css={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', flex: '1' }}>
                    <View>
                      <Text fontFamily="secondary" size={16} css={{ fontWeight: '400' }} color="gray9">
                        {fileInfo.name}
                      </Text>
                      <Text size={13} color="gray6" fontFamily="secondary">
                        {fileInfo.size}
                      </Text>
                    </View>
                    <View>
                      <View css={{ textAlign: 'right', cursor: 'pointer' }} onClick={handleRemoveCSV}>
                        <FontAwesome type="fal" name="times" />
                      </View>
                      <Text size={13} color="gray6" fontFamily="secondary">
                        {percent}%
                      </Text>
                    </View>
                  </View>
                </View>

                <Progress percent={percent} showInfo={false} strokeColor={colors.primary} size="small" status="active" />
              </View>
            )}

            <Field
              label={
                <View fontFamily="secondary" css={{ marginBottom: '3px', fontSize: '16px' }}>
                  Email
                </View>
              }
              css={{ marginTop: '25px' }}
              description={
                !message ? (
                  <View fontFamily="secondary" css={{ fontStyle: 'normal', fontWeight: '300', marginTop: '8px' }}>
                    After all reviews are successfully imported, you'll receive a notification email.
                  </View>
                ) : (
                  <Text
                    size={15}
                    css={{
                      fontStyle: 'normal',
                      fontWeight: '400',
                      marginTop: '8px',
                      lineHeight: '1',
                    }}
                    color="secondary"
                  >
                    {message}
                  </Text>
                )
              }
            >
              <DebouncedInput type="email" required block sizeInput="medium" radius={4} value={email} onValueChange={handleChangeEmail} />

              {!checkEmail && (
                <Text css={{ marginTop: '5px' }} color="danger">
                  {i18n.t('general.invalid_email')}
                </Text>
              )}
            </Field>

            <View css={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '10px', marginTop: '30px' }}>
              <Button
                radius={4}
                size="small"
                backgroundColor="light"
                borderColor="gray3"
                borderStyle="solid"
                borderWidth={1}
                color="gray9"
                css={{ fontSize: '16px', padding: '10px 30px' }}
                fontFamily="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                fontFamily="secondary"
                radius={4}
                size="small"
                disabled={!fileInfo.content || !email}
                css={{ fontSize: '16px', padding: '10px 30px' }}
                onClick={handleImportCSV}
                loading={importStatus === 'loading'}
              >
                Import
              </Button>
            </View>
          </View>

          <View css={styles.extraNode}>
            <Title
              size="medium"
              titleCss={{ fontSize: '24px' }}
              title="Save Time with AliExpress Reviews Import Feature"
              text={
                'This feature will help you easily import real product reviews from Aliexpress. After the reviews are imported, Veda will then display the review content for each product.'
              }
            />
            <Space size={10} />
            <VideoCart
              disabledViewDetail
              description={
                <Text size={14}>
                  To learn more about Veda Fake Reviews feature,{' '}
                  <Text
                    color="primary"
                    colorHover="primary"
                    tagName="a"
                    href="https://help.myshopkit.app/en/docs/veda-landing-page-builder/veda-addons/fake-reviews-6615/"
                    target="_blank"
                    css={{ textDecoration: 'underline' }}
                  >
                    please read this tutorial
                  </Text>
                </Text>
              }
              title=""
              embedId="DFydGQH8tE0"
              href="https://www.youtube.com/watch?v=DFydGQH8tE0"
            />
          </View>
        </View>
      </MyModal>
    </>
  );
};

export default UploadCSVField;
