import { getMegamenusOfSectionInThemeAtom } from 'services/ThemeService/Logic/getMegamenusOfSectionInThemeAtom';
import { getMegamenusOfSectionInThemeDraft } from 'services/ThemeService/Logic/getMegamenusOfSectionInThemeDraft';
import { PageSection } from 'types/Sections';
import { BaseParams } from './@types/BaseParams';
import { getMegamenuCacheKey, getSectionCacheKey } from './utils/CacheKeyControl';
import { pushToCache, readFromCache } from './utils/CacheStorage';

export const getMegamenus_ = async ({ commandIds, entityVariant }: GetMegamenus) => {
  const getMegamenusOfSectionInThemeWithEntityVariant =
    entityVariant === 'Atom' ? getMegamenusOfSectionInThemeAtom : getMegamenusOfSectionInThemeDraft;
  const megaMenus = await getMegamenusOfSectionInThemeWithEntityVariant({ megamenuCommandIds: commandIds });
  const megamenuKeys = await megaMenus.reduce<Promise<string[]>>(async (result, megamenuSection) => {
    const result_ = await result;
    if (megamenuSection.commandId && megamenuSection.enable) {
      const cacheKey = await pushToCache(JSON.stringify(megamenuSection), getMegamenuCacheKey(megamenuSection.commandId));
      return result_.concat(cacheKey);
    }
    return result_;
  }, Promise.resolve([]));
  return megamenuKeys;
};

interface GetMegamenus extends BaseParams {
  commandIds: string[];
}
export const getMegamenus = async ({ commandIds, isRetry, ...params }: GetMegamenus) => {
  if (isRetry) {
    const megamenuKeys = await Promise.all(
      commandIds.map(async commandId => {
        const cacheKey = getSectionCacheKey(commandId);
        const dataFromCache = await readFromCache<PageSection>(cacheKey);
        if (dataFromCache) {
          return cacheKey;
        } else {
          const newCacheKey = await getMegamenus_({ commandIds: [commandId], isRetry, ...params });
          return newCacheKey[0];
        }
      }),
    );
    return megamenuKeys;
  } else {
    return getMegamenus_({ commandIds, isRetry, ...params });
  }
};
