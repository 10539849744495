import { FC } from 'react';
import { i18n } from 'translation';
import { Divider, Text, useTheme, View } from 'wiloke-react-core';
import * as styles from './styles';

export const FooterPlaceholder: FC = () => {
  const { colors } = useTheme();

  return (
    <View css={styles.container} wilokeStyles="parent-hover">
      <View css={styles.text} wilokeStyles="child-fadein-0">
        <Text tagName="h5" fontFamily="primary">
          {i18n.t('general.footer_of_theme')}
        </Text>
      </View>
      <Divider size={1} color="gray3" />
      <View wilokeStyles="child-fadeout-20">
        <svg viewBox="0 0 2080 300" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect style={{ width: '100%', height: '100%' }} fill="white" />
          <rect y="216" height="84" style={{ width: '100%' }} fill={colors.gray2} />
          <path
            d="M486.066 70H113.934C106.239 70 100 73.8056 100 78.5C100 83.1944 106.239 87 113.934 87H486.066C493.761 87 500 83.1944 500 78.5C500 73.8056 493.761 70 486.066 70Z"
            fill={colors.gray2}
          />
          <path
            d="M341.291 110H108.709C103.899 110 100 113.806 100 118.5C100 123.194 103.899 127 108.709 127H341.291C346.101 127 350 123.194 350 118.5C350 113.806 346.101 110 341.291 110Z"
            fill={colors.gray2}
          />
          <path
            d="M196.516 150H103.484C101.56 150 100 153.806 100 158.5C100 163.194 101.56 167 103.484 167H196.516C198.44 167 200 163.194 200 158.5C200 153.806 198.44 150 196.516 150Z"
            fill={colors.gray2}
          />
          <path
            d="M1526.07 70H1153.93C1146.24 70 1140 73.8056 1140 78.5C1140 83.1944 1146.24 87 1153.93 87H1526.07C1533.76 87 1540 83.1944 1540 78.5C1540 73.8056 1533.76 70 1526.07 70Z"
            fill={colors.gray2}
          />
          <path
            d="M1381.29 110H1148.71C1143.9 110 1140 113.806 1140 118.5C1140 123.194 1143.9 127 1148.71 127H1381.29C1386.1 127 1390 123.194 1390 118.5C1390 113.806 1386.1 110 1381.29 110Z"
            fill={colors.gray2}
          />
          <path
            d="M1236.52 150H1143.48C1141.56 150 1140 153.806 1140 158.5C1140 163.194 1141.56 167 1143.48 167H1236.52C1238.44 167 1240 163.194 1240 158.5C1240 153.806 1238.44 150 1236.52 150Z"
            fill={colors.gray2}
          />
          <path
            d="M273.73 250H106.27C102.807 250 100 253.806 100 258.5C100 263.194 102.807 267 106.27 267H273.73C277.193 267 280 263.194 280 258.5C280 253.806 277.193 250 273.73 250Z"
            fill={colors.gray3}
          />
          <path
            d="M1547.5 250H1487.5C1482.81 250 1479 253.806 1479 258.5C1479 263.194 1482.81 267 1487.5 267H1547.5C1552.19 267 1556 263.194 1556 258.5C1556 253.806 1552.19 250 1547.5 250Z"
            fill={colors.gray3}
          />
          <path
            d="M1653.5 250H1593.5C1588.81 250 1585 253.806 1585 258.5C1585 263.194 1588.81 267 1593.5 267H1653.5C1658.19 267 1662 263.194 1662 258.5C1662 253.806 1658.19 250 1653.5 250Z"
            fill={colors.gray3}
          />
          <path
            d="M1759.5 250H1699.5C1694.81 250 1691 253.806 1691 258.5C1691 263.194 1694.81 267 1699.5 267H1759.5C1764.19 267 1768 263.194 1768 258.5C1768 253.806 1764.19 250 1759.5 250Z"
            fill={colors.gray3}
          />
          <path
            d="M1865.5 250H1805.5C1800.81 250 1797 253.806 1797 258.5C1797 263.194 1800.81 267 1805.5 267H1865.5C1870.19 267 1874 263.194 1874 258.5C1874 253.806 1870.19 250 1865.5 250Z"
            fill={colors.gray3}
          />
          <path
            d="M1971.5 250H1911.5C1906.81 250 1903 253.806 1903 258.5C1903 263.194 1906.81 267 1911.5 267H1971.5C1976.19 267 1980 263.194 1980 258.5C1980 253.806 1976.19 250 1971.5 250Z"
            fill={colors.gray3}
          />
        </svg>
      </View>
    </View>
  );
};
