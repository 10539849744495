import { categorizeFonts } from 'generate/utils/categorizeFonts';
import { ThemeSchema } from '../../@types/Shopify/ThemeConfigs/ThemeSchema';
import { ThemeSetting } from '../../@types/Shopify/ThemeConfigs/ThemeSetting';
import { ThemeSettings } from '../@types/ThemeSettings';
import { ISCHEMA_SettingFonts } from './@types/ISCHEMA_SettingFonts';
import { ISETTING_SettingFonts } from './@types/ISETTING_SettingFonts';
import { getSchemaLabel } from './utils/getSchemaLabel';
import { vedaFontToShopifyFieldId } from './utils/vedaFontToShopifyFieldId';
import { vedaFontToShopifyFieldLabel } from './utils/vedaFontToShopifyFieldLabel';

export const SCHEMA_SettingFonts = ({ fonts }: ISCHEMA_SettingFonts): ThemeSchema => {
  const { googleFonts, shopifyFonts } = categorizeFonts({ fonts, uniqueValue: false });
  return {
    name: getSchemaLabel().newLabel,
    settings: [
      ...shopifyFonts.map<ThemeSchema['settings'][number]>(font => {
        return {
          type: 'font_picker',
          default: font.value,
          label: vedaFontToShopifyFieldLabel(font).newLabel,
          id: vedaFontToShopifyFieldId(font),
          placeholder: undefined,
        };
      }),
      ...googleFonts.map<ThemeSchema['settings'][number]>(font => {
        return {
          type: 'text',
          default: font.value,
          label: vedaFontToShopifyFieldLabel(font).newLabel,
          id: vedaFontToShopifyFieldId(font),
          placeholder: undefined,
        };
      }),
    ],
  };
};

export const SETTING_SettingFonts = ({ fonts }: ISETTING_SettingFonts): ThemeSetting => {
  return fonts.reduce<ThemeSetting>((res: ThemeSetting, font: ThemeSettings['cssVariables']['fonts'][number]) => {
    const key = vedaFontToShopifyFieldId(font);
    return { ...res, [key]: font.value };
  }, {});
};
