import { getPartners, createPartner, deletePartner, updatePartner } from 'containers/Admin/PartnerManagement/store/actions';
import { PartnerAPI } from 'types/Partner';
import { ActionTypes, createSlice, handleAction } from 'wiloke-react-core/utils';

type ExtraActions = ActionTypes<typeof getPartners | typeof updatePartner | typeof createPartner | typeof deletePartner>;

interface State {
  partners: PartnerAPI[];

  getStatus: Status;
  createStatus: Status;
  updateStatus: Record<PartnerAPI['id'], Status>;
  deleteStatus: Record<PartnerAPI['id'], Status>;
}

export const slicePartner = createSlice<State, any, ExtraActions>({
  name: '@Admin',
  initialState: {
    partners: [],
    createStatus: 'idle',
    getStatus: 'idle',
    deleteStatus: {},
    updateStatus: {},
  },
  reducers: [],
  extraReducers: [
    // get all
    handleAction('@Admin/getPartner/request', ({ state }) => {
      state.getStatus = 'loading';
    }),
    handleAction('@Admin/getPartner/success', ({ state, action }) => {
      state.getStatus = 'success';
      state.partners = action.payload.data;
    }),
    handleAction('@Admin/getPartner/failure', ({ state }) => {
      state.getStatus = 'failure';
    }),
    // create
    handleAction('@Admin/createPartner/request', ({ state }) => {
      state.createStatus = 'loading';
    }),
    handleAction('@Admin/createPartner/success', ({ state, action }) => {
      state.createStatus = 'success';
      state.partners = state.partners.concat(action.payload.data);
    }),
    handleAction('@Admin/createPartner/failure', ({ state }) => {
      state.createStatus = 'failure';
    }),
    // edit
    handleAction('@Admin/updatePartner/request', ({ state, action }) => {
      state.updateStatus[action.payload.data.id] = 'loading';
    }),
    handleAction('@Admin/updatePartner/success', ({ state, action }) => {
      state.updateStatus[action.payload.data.id] = 'success';
      state.partners = state.partners.map(partner => {
        if (partner.id === action.payload.data.id) {
          return action.payload.data;
        }
        return partner;
      });
    }),
    handleAction('@Admin/updatePartner/failure', ({ state, action }) => {
      state.updateStatus[action.payload.id] = 'failure';
    }),
    // delete
    handleAction('@Admin/deletePartner/request', ({ state, action }) => {
      state.deleteStatus[action.payload.id] = 'loading';
    }),
    handleAction('@Admin/deletePartner/success', ({ state, action }) => {
      state.deleteStatus[action.payload.id] = 'success';
      state.partners = state.partners.filter(partner => partner.id !== action.payload.id);
    }),
    handleAction('@Admin/deletePartner/failure', ({ state, action }) => {
      state.deleteStatus[action.payload.id] = 'failure';
    }),
  ],
});

export const partnerSelector = (state: AppState) => state.partners;
