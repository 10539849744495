import { FontAwesome, View } from 'wiloke-react-core';
import { CarouselProps } from './types';
import * as styles from './styles';
import { useEffect, useRef, useState } from 'react';

interface CarouselProps2<T extends any> extends Omit<CarouselProps<T>, 'title' | 'slideWidth' | 'slideCount' | 'space'> {
  hideArrow?: boolean;
}

const NUM_OF_ITEMS = 1;

export const CarouselStyle2 = <T extends any>({
  interval = 10000,
  animationDuration = 300,
  data,
  hideArrow = false,
  keyExtractor = (_, index) => index,
  renderItem,
}: CarouselProps2<T>) => {
  // Props
  const ANIMATION_DURATION = animationDuration;
  const INTERVAL = interval;

  const [animating, setAnimating] = useState(false);
  const [index, setIndex] = useState(0);
  const carouselEl = useRef<HTMLDivElement | null>(null);
  const slidesEl = useRef<HTMLDivElement | null>(null);
  const nextSlideTimeout = useRef<number | undefined>(undefined);

  const MAX_LENGTH = Math.ceil(data.length / NUM_OF_ITEMS - 1);
  const ITEM_WIDTH = 100 / NUM_OF_ITEMS;
  const REDUNDANT_SLIDE = data.length - MAX_LENGTH * NUM_OF_ITEMS;
  const MAX_X = MAX_LENGTH * NUM_OF_ITEMS * ITEM_WIDTH - (NUM_OF_ITEMS - REDUNDANT_SLIDE) * ITEM_WIDTH;
  const CURRENT_TRANSFORM_WITH_INDEX = -Math.max(0, index !== MAX_LENGTH ? index * ITEM_WIDTH * NUM_OF_ITEMS : MAX_X);

  const renderSlide = (item: T, index: number) => {
    return (
      <View css={{ flexShrink: 0, flexBasis: `${ITEM_WIDTH}%` }} key={keyExtractor(item, index)}>
        {renderItem(item, index)}
      </View>
    );
  };

  const handleClearInterval = () => {
    clearTimeout(nextSlideTimeout.current);
  };

  const handleInterval = () => {
    handleClearInterval();
    nextSlideTimeout.current = window.setTimeout(() => {
      setIndex(index => {
        if (index >= MAX_LENGTH) {
          return 0;
        }
        return index + 1;
      });
      setAnimating(true);
    }, INTERVAL);
  };

  useEffect(() => {
    let timeout: number | undefined;
    if (animating) {
      timeout = window.setTimeout(() => {
        setAnimating(false);
        clearTimeout(timeout);
      }, ANIMATION_DURATION);
    }
    return () => clearTimeout(timeout);
  }, [animating, ANIMATION_DURATION]);

  useEffect(() => {
    handleInterval();
    return () => {
      handleClearInterval();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  return (
    <View css={styles.style2.container}>
      {!hideArrow && (
        <View
          css={[styles.iconLeft(index === 0), styles.style2.iconLeft]}
          onClick={() => {
            if (index === 0) {
              return;
            }
            setIndex(index => {
              return index - 1;
            });
            setAnimating(true);
          }}
        >
          <FontAwesome type="far" size={16} name="angle-left" />
        </View>
      )}

      {!hideArrow && (
        <View
          css={[styles.iconRight(index >= MAX_LENGTH), styles.style2.iconRight]}
          onClick={() => {
            if (index >= MAX_LENGTH) {
              return;
            }
            setIndex(index => {
              return index + 1;
            });
            setAnimating(true);
          }}
        >
          <FontAwesome type="far" size={16} name="angle-right" />
        </View>
      )}

      <View css={{ overflow: 'hidden' }} ref={carouselEl}>
        <View
          ref={slidesEl}
          css={{ display: 'flex', flexWrap: 'nowrap', willChange: 'transform' }}
          style={{
            transition: animating ? `${ANIMATION_DURATION}ms transform` : undefined,
            transform: `translateX(${CURRENT_TRANSFORM_WITH_INDEX}%)`,
          }}
        >
          {data.map(renderSlide)}
        </View>
      </View>
    </View>
  );
};
