import { FC } from 'react';
import { FontAwesome, Space, useTheme, View } from 'wiloke-react-core';
import { CrispChat } from 'containers/CrispChat/CrispChat';
import SelectPage from '../SelectPage/SelectPage';
import ModalSettings from '../Settings/ModalSettings';
import { FullScreen } from './FullScreen';
import { Responsive } from './Responsive';
import RightActions from './RightActions/RightActions';
import * as styles from './styles';
import UndoRedo from './UndoRedo';
import { RenewButton } from './RenewButton';

import { DuplicateThemeBuilder } from 'containers/BuilderPage/components/TopBar/DuplicateThemeBuilder';
import { isThemeBuilder } from 'utils/validateBuilderMode';
import { HackTips } from 'containers/BuilderPage/components/TopBar/HackTips';
import { i18n } from 'translation';

const TopBar: FC = () => {
  const { colors } = useTheme();
  const isTheme = isThemeBuilder();

  return (
    <View css={styles.container}>
      <View css={styles.left}>
        <SelectPage />
        <FullScreen />
        <Space size={10} type="horizontal" />
        <Responsive />
        <Space size={10} type="horizontal" />
        <UndoRedo />
        <Space size={10} type="horizontal" />
        <ModalSettings />
        <Space size={10} type="horizontal" />
        <RenewButton />
        <Space size={10} type="horizontal" />

        <CrispChat>
          <View
            color="light"
            backgroundColor="secondary"
            radius={4}
            css={{
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              height: '35px',
              padding: '2px 8px',
              gap: '5px',
            }}
          >
            <View tagName="span" css={{ backgroundColor: colors.secondary, color: colors.light }}>
              <FontAwesome type="fas" name="comment-dots" size={20} />
            </View>
            <View fontFamily="secondary" css={{ fontSize: '14px', fontWeight: '500' }}>
              {i18n.t('CrispChat.chat_with_us')}
            </View>
          </View>
        </CrispChat>
        <Space size={10} type="horizontal" />
        <HackTips />
        <Space size={10} type="horizontal" />
        {!isTheme && <DuplicateThemeBuilder />}

        {!isTheme && <Space size={10} type="horizontal" />}
      </View>
      <RightActions />
    </View>
  );
};

export default TopBar;
