import { SagaReturnType, put, retry, takeLatest } from 'redux-saga/effects';
import { banOrUnBanAuthor } from '../action';
import { banAndUnBanUserReq } from 'services/UserService/Logic/banOrUnBanUser';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { AxiosError } from 'axios';
import { getActionType } from 'wiloke-react-core/utils';

function* handleAction({ payload: { userId, verifyStatus } }: ReturnType<typeof banOrUnBanAuthor.request>) {
  try {
    const response: SagaReturnType<typeof banAndUnBanUserReq> = yield retry(3, 1000, banAndUnBanUserReq, {
      userId,
      verifyStatus,
    });
    yield put(banOrUnBanAuthor.success({ userId, verifyStatus }));
    notifyAxiosHandler.handleSuccess(response.message);
  } catch (error) {
    notifyAxiosHandler.handleError(error as AxiosError<ErrorData> | Error);
    yield put(banOrUnBanAuthor.failure({ userId }));
  }
}

export function* watchBanOrUnBanAuthor() {
  yield takeLatest(getActionType(banOrUnBanAuthor.request), handleAction);
}
