import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';

interface GetPageAtom {
  commandId: string;
}

export const getPageAtom = async ({ commandId }: GetPageAtom) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const atomResponse = await pageApis.atom.adminApi.page.getAtom({ commandId, cacheVersion: lastCache });
    const productResponse = await pageApis.vedaApplication.adminApi.page.getProductsWithParentCommandIds({
      parentCommandIds: atomResponse.info.commandId,
      cacheVersion: lastCache,
    });
    return {
      ...atomResponse.info,
      vedaProductRepresent: productResponse.info.find(product => product.commandId === atomResponse.info.commandId),
      type: atomResponse.info.type,
    };
  }
  const response = await pageApis.atom.userApi.page.getAtom({ commandId, cacheVersion: lastCache });
  return {
    ...response.info,
    type: response.info.type,
  };
};
