import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { userApis } from '../apis';

interface CreateUpdateParams {
  purchaseCode: string;
  themeProductCommandId: string;
}

export const createUpdatePurchaseCodeService = async ({ purchaseCode, themeProductCommandId }: CreateUpdateParams) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    return userApis.purchaseCode.adminApi.createPurchaseCode({ purchaseCode, themeProductCommandId });
  }
  if (role === 'dev' || role === 'user') {
    return userApis.purchaseCode.userApi.createPurchaseCode({ purchaseCode, themeProductCommandId });
  }
  throw new RoleException();
};
