import { Role } from 'routes/types';
import { ResponseUserPlan } from 'services/AuthService';
import { PLAN_HANDLE } from 'utils/constants/PlanEnum';
import { createAction, createAsyncAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';

export interface LoginResponseSuccess {
  id: number;
  email: string;
  role: Role;
  shopName?: string;
  username: string;
  password: string;
}

export const verifyUser = createAsyncAction(['@Global/verifyUser/request', '@Global/verifyUser/success', '@Global/verifyUser/failure'])<
  { callback?: () => void },
  LoginResponseSuccess,
  undefined
>();

export const verifyUserStorefrontPassword = createAsyncAction([
  '@Global/verifyUserStorefrontPassword/request',
  '@Global/verifyUserStorefrontPassword/success',
  '@Global/verifyUserStorefrontPassword/failure',
])<
  { password: string; id: number; onFulfilled?: () => void; onFailed?: (message: string) => void; showNotification?: boolean },
  { verifySucceeded: boolean; password: string },
  { verifySucceeded: boolean }
>();

export const updateThemeIdOfUser = createAsyncAction([
  '@Global/updateThemeIdOfUser/request',
  '@Global/updateThemeIdOfUser/success',
  '@Global/updateThemeIdOfUser/failure',
])<{ themeId: string; onFulfill?: (newThemeId: string) => void }, { themeId: string }, { themeId: string }>();

export const getUserPlan = createAsyncAction(['@Global/getUserPlan/request', '@Global/getUserPlan/success', '@Global/getUserPlan/failure'])<
  { prevPlan: PLAN_HANDLE; onFulfill?: () => void; onFailed?: () => void },
  { plan: ResponseUserPlan },
  undefined
>();

export const useVerifyUser = createDispatchAsyncAction(verifyUser);

export const updateLastCacheOfVeda = createAction('@updateLastCacheOfVeda', (payload: number) => payload);
export const useVerifyUserStorefrontPassword = createDispatchAsyncAction(verifyUserStorefrontPassword);
export const useUpdateThemeIdOfUser = createDispatchAsyncAction(updateThemeIdOfUser);
export const useGetUserPlan = createDispatchAsyncAction(getUserPlan);
