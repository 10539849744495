import { createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';

interface Actions {
  type: 'setVisibleModalUpdatePlan';
  payload: boolean;
}

interface State {
  visible: boolean;
}

export const sliceModalUpdatePlan = createSlice<State, Actions>({
  name: '@Global',
  initialState: {
    visible: false,
  },
  reducers: [
    handleAction('setVisibleModalUpdatePlan', ({ state, action }) => {
      state.visible = action.payload;
    }),
  ],
});

export const modalUpdatePlanSelector = (state: AppState) => state.modalUpdatePlan;

export const { setVisibleModalUpdatePlan } = sliceModalUpdatePlan.actions;

export const useSetVisibleModalUpdatePlan = createDispatchAction(setVisibleModalUpdatePlan);
