import { megaMenuApiController } from 'services/MegaMenuService';
import { SectionChangelog } from 'types/Sections';
import { VersionSection } from 'types/Version';
import { getUserInfo } from 'utils/functions/getUserInfo';

type RT = Array<{ version: VersionSection | undefined; atomCommandId: string }>;

const adapter = (response: SectionChangelog[]) => {
  const resultObject = response.reduce<Record<string, VersionSection>>((res, record) => {
    const { version, content, versionId, createdDateTimestamp, modifiedDateTimestamp } = record;
    const currentValue = res[versionId] as VersionSection | undefined;
    return {
      ...res,
      [versionId]: {
        id: versionId,
        sectionId: versionId,
        version: currentValue?.version ?? version,
        changelogs: (currentValue?.changelogs ?? []).concat({
          version: version,
          description: content,
          createdDateTimestamp,
          modifiedDateTimestamp,
        }),
      },
    };
  }, {});

  return Object.keys(resultObject).reduce<RT>((res, sectionCommandId) => {
    const sectionChangelogs = resultObject[sectionCommandId];
    sectionChangelogs.changelogs.sort((a, b) => {
      if (!!a.createdDateTimestamp && !!b.createdDateTimestamp) {
        return b.createdDateTimestamp - a.createdDateTimestamp;
      }
      return 0;
    });
    return res.concat({ version: sectionChangelogs, atomCommandId: sectionCommandId });
  }, []);
};

export const getChangelogsOfAtoms = async (sectionCommandIds: string[]): Promise<RT> => {
  if (!sectionCommandIds.length) {
    return [];
  }
  const { role, lastCache } = getUserInfo();
  try {
    if (role === 'admin') {
      const response = await megaMenuApiController.atom.adminApi.changelogs.getListChangelogsOfAtoms({
        sectionCommandIds: sectionCommandIds.join(','),
        cacheVersion: lastCache,
      });
      return adapter(response.info);
    }
    const response = await megaMenuApiController.atom.publishApi.changelogs.getListChangelogsOfAtoms({
      sectionCommandIds: sectionCommandIds.join(','),
      cacheVersion: lastCache,
    });
    return adapter(response.info);
  } catch {
    return [];
  }
};
