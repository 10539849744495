import { sectionApiController } from 'services/SectionService';
import { userApis } from 'services/UserService/apis';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const deleteListMegaMenuOfAtom = async ({ listIds }: { listIds: string[] }) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    const response = await sectionApiController.atom.adminApi.megaMenu.deleteListMegaMenuOfAtom({ listIds });
    await userApis.user.adminApi.updateVedaCacheVersion();
    return response;
  }
  throw new RoleException();
};
