/**
 * Function đảm replace chính xác regexp truyền vào
 * @example
 Input: Mỗi dòng là 1 tham số truyền vào
  {% paginate customer.orders by 3 %} - {{ 'veda.customer.orders.order_number' | t }}
  new RegExp(`${strToRegexpPattern("customer.orders")}(?!\\s*\\|)`, "gm")
  TARGET

 Output:
 {% paginate "TARGET" by 3 %} - {{ 'veda.customer.orders.order_number' | t }}
 */
export const replaceExactRegexpInLiquidCode = (liquid: string, source: RegExp, target: string | Parameters<string['replace']>['1']) => {
  const regexp_ = new RegExp(`(.|\n)${source.source}(.|\n)`, Array.from(new Set(['g', ...source.flags.split('')])).join(''));
  return liquid.replace(regexp_, value => {
    const isStartValid =
      value.startsWith(' ') ||
      value.startsWith('\n') ||
      // Trường hợp collections[product_settings.collection]
      value.startsWith('[');
    const isEndValid =
      value.endsWith(' ') ||
      value.endsWith('\n') ||
      // Trường hợp collections[product_settings.collection]
      value.endsWith(']');

    if (isStartValid && isEndValid) {
      if (typeof target === 'string') {
        return value.replace(source, target);
      }
      return value.replace(source, target);
    } else {
      return value;
    }
  });
};
