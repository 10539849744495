import { addonApiController } from 'services/AddonService';
import { adapterGetCategories } from 'services/AddonService/Adapters/adapterGetCategories';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const getCategoriesOfProduct = async () => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const response = await addonApiController.product.adminApi.category.getCategories({
      type: 'GET FIRST PAGE',
      cacheVersion: lastCache,
    });
    return adapterGetCategories(response.info);
  }
  const response = await addonApiController.product.userApi.category.getCategories({
    type: 'GET FIRST PAGE',
    cacheVersion: lastCache,
  });
  return adapterGetCategories(response.info);
};

export const loadMoreCategoriesOfProduct = async (cursor: string) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const response = await addonApiController.product.adminApi.category.getCategories({
      type: 'LOADMORE',
      cursor,
      cacheVersion: lastCache,
    });
    return adapterGetCategories(response.info);
  }
  const response = await addonApiController.product.userApi.category.getCategories({
    type: 'LOADMORE',
    cursor,
    cacheVersion: lastCache,
  });
  return adapterGetCategories(response.info);
};
