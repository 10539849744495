import { AxiosError } from 'axios';
import { clone } from 'ramda';
import { put, retry, takeLatest } from 'redux-saga/effects';
import { addonService } from 'services/AddonService';
import { setAddonToPages } from 'store/actions/actionPages';
import { setSidebarTabActive } from 'store/actions/actionSidebarTabActive';
import { setThemeAddon } from 'store/global/themeAddons';
import { AdminAddon, ThemeAddon } from 'types/Addons';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { installAdminAddon, setTemplateBoardVisible } from '../../actions';

function* handleInstall({ payload: { commandId, isInstallToPage, onFulfilled } }: ReturnType<typeof installAdminAddon.request>) {
  try {
    const response: Awaited<ReturnType<typeof addonService.addons.installAtomAddon>> = yield retry(
      3,
      1000,
      addonService.addons.installAtomAddon,
      commandId,
    );

    const _response = clone<ThemeAddon>({
      ...response,
      isAddonOfPage: isInstallToPage,
    });

    yield put(setThemeAddon({ addon: _response }));
    yield put(setAddonToPages(_response.body));
    yield put(setSidebarTabActive('add-ons'));
    yield put(setTemplateBoardVisible({ visible: false, navKeys: ['admin', 'Addons'] }));

    yield put(installAdminAddon.success({ commandId }));
    onFulfilled?.(_response as AdminAddon);
  } catch (error) {
    notifyAxiosHandler.handleError(error as AxiosError<ErrorData> | Error);
    yield put(installAdminAddon.failure({ commandId }));
  }
}

export function* watchInstallAdminAddon() {
  yield takeLatest(getActionType(installAdminAddon.request), handleInstall);
}
