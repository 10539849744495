import { FC } from 'react';
import { i18n } from 'translation';
import { Divider, Text, useTheme, View } from 'wiloke-react-core';
import * as styles from './styles';

export const HeaderPlaceholder: FC = () => {
  const { colors } = useTheme();

  return (
    <View css={styles.container} wilokeStyles="parent-hover">
      <View css={styles.text} wilokeStyles="child-fadein-0">
        <Text tagName="h5" fontFamily="primary">
          {i18n.t('general.header_of_theme')}
        </Text>
      </View>
      <View wilokeStyles="child-fadeout-20">
        <svg viewBox="0 0 2080 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect style={{ width: '100%', height: '100%' }} fill={colors.light} />
          <path
            d="M273.73 42H106.27C102.807 42 100 45.8056 100 50.5C100 55.1944 102.807 59 106.27 59H273.73C277.193 59 280 55.1944 280 50.5C280 45.8056 277.193 42 273.73 42Z"
            fill={colors.gray3}
          />
          <path
            d="M1386.5 42H1326.5C1321.81 42 1318 45.8056 1318 50.5C1318 55.1944 1321.81 59 1326.5 59H1386.5C1391.19 59 1395 55.1944 1395 50.5C1395 45.8056 1391.19 42 1386.5 42Z"
            fill={colors.gray3}
          />
          <path
            d="M1492.5 42H1432.5C1427.81 42 1424 45.8056 1424 50.5C1424 55.1944 1427.81 59 1432.5 59H1492.5C1497.19 59 1501 55.1944 1501 50.5C1501 45.8056 1497.19 42 1492.5 42Z"
            fill={colors.gray3}
          />
          <path
            d="M1598.5 42H1538.5C1533.81 42 1530 45.8056 1530 50.5C1530 55.1944 1533.81 59 1538.5 59H1598.5C1603.19 59 1607 55.1944 1607 50.5C1607 45.8056 1603.19 42 1598.5 42Z"
            fill={colors.gray3}
          />
          <path
            d="M1704.5 42H1644.5C1639.81 42 1636 45.8056 1636 50.5C1636 55.1944 1639.81 59 1644.5 59H1704.5C1709.19 59 1713 55.1944 1713 50.5C1713 45.8056 1709.19 42 1704.5 42Z"
            fill={colors.gray3}
          />
          <path
            d="M1810.5 42H1750.5C1745.81 42 1742 45.8056 1742 50.5C1742 55.1944 1745.81 59 1750.5 59H1810.5C1815.19 59 1819 55.1944 1819 50.5C1819 45.8056 1815.19 42 1810.5 42Z"
            fill={colors.gray3}
          />
          <path
            d="M1972.75 41.5C1972.75 36.5781 1968.67 32.5 1963.75 32.5C1958.76 32.5 1954.75 36.5781 1954.75 41.5H1948V62.875C1948 66.0391 1950.46 68.5 1953.62 68.5H1973.88C1976.97 68.5 1979.5 66.0391 1979.5 62.875V41.5H1972.75ZM1963.75 35.875C1966.84 35.875 1969.38 38.4062 1969.38 41.5H1958.12C1958.12 38.4062 1960.59 35.875 1963.75 35.875ZM1976.12 62.875C1976.12 64.1406 1975.07 65.125 1973.88 65.125H1953.62C1952.36 65.125 1951.38 64.1406 1951.38 62.875V44.875H1954.75V47.6875C1954.75 48.6719 1955.45 49.375 1956.44 49.375C1957.35 49.375 1958.12 48.6719 1958.12 47.6875V44.875H1969.38V47.6875C1969.38 48.6719 1970.08 49.375 1971.06 49.375C1971.98 49.375 1972.75 48.6719 1972.75 47.6875V44.875H1976.12V62.875Z"
            fill={colors.gray4}
          />
          <path
            d="M1901.72 65.4766L1893.21 56.9688C1893 56.8281 1892.79 56.6875 1892.58 56.6875H1891.66C1893.84 54.1562 1895.25 50.7812 1895.25 47.125C1895.25 39.1094 1888.64 32.5 1880.62 32.5C1872.54 32.5 1866 39.1094 1866 47.125C1866 55.2109 1872.54 61.75 1880.62 61.75C1884.28 61.75 1887.59 60.4141 1890.19 58.2344V59.1484C1890.19 59.3594 1890.26 59.5703 1890.4 59.7812L1898.91 68.2891C1899.26 68.6406 1899.82 68.6406 1900.1 68.2891L1901.72 66.6719C1902.07 66.3906 1902.07 65.8281 1901.72 65.4766ZM1880.62 58.375C1874.37 58.375 1869.38 53.3828 1869.38 47.125C1869.38 40.9375 1874.37 35.875 1880.62 35.875C1886.81 35.875 1891.88 40.9375 1891.88 47.125C1891.88 53.3828 1886.81 58.375 1880.62 58.375Z"
            fill={colors.gray4}
          />
        </svg>
      </View>
      <Divider size={1} color="gray3" />
    </View>
  );
};
