import { megaMenuApiController } from 'services/MegaMenuService';
import { adapterGetMegaMenus } from 'services/MegaMenuService/Adapters/adapterGetMegaMenus';
import { ProductSection } from 'types/Sections';
import { getCurrentVersion } from 'utils/CacheControl/VedaClientCacheControl';

export const searchMegaMenusClient = async (megaMenuCommandIds: string[]) => {
  const response = await megaMenuApiController.client.clientApi.mega_menus.searchClients({
    commandIds: megaMenuCommandIds,
    cacheVersion: getCurrentVersion().getTime(),
  });
  return adapterGetMegaMenus(response.info) as ProductSection[];
};
