import { handleWaitForSocketOfSyncThemeByResultOfExtractThemeToFileForSync } from 'packages/SyncThemeByResultOfExtractThemeToFileForSync/hooks/useSocketForSyncThemeByResultOfExtractThemeToFileForSync';
import { call, retry, SagaReturnType } from 'redux-saga/effects';
import { handleExtractResultForForceSync, shopifyConnectionService } from 'services/ShopifyConnection';

export interface SyncAddonsDisablePosition {
  addonsDisablePositionParams: ReturnType<typeof handleExtractResultForForceSync>['addonsDisablePositionParams'];
}

export interface SyncAddonsDisablePositionResult {
  statusSyncAddonsDisablePosition: SyncFulfillStatus;
  messageError: string | undefined;
  isNeedIgnoreReportError: boolean;
}

/**
 * @tuong
 * Câu hỏi 1: Tại sao addon lại ghi ra 1 file mới?
   => @tuong: "Addon" là global -> Xét ngữ cảnh "Page A" và "Page B" cùng có 1 "Addon A" -> Vào một ngày đẹp trời "User" vào "Page A" và xoá "Addon A" -> "Page B" cũng cần được xoá nhưng xét bài toán số lượng "Page" lên đến cả trăm -> Regex là rất khó và tốn kèm -> Hack bằng cách tạo "addon" ra 1 file liquid riêng và dùng code để check file tồn tại hay không -> Khi xoá "Addon" thì các "Page" khác cũng không ảnh hưởng
 */
export function* syncAddonsDisablePosition({ addonsDisablePositionParams }: SyncAddonsDisablePosition) {
  try {
    const signalToReplaceAddonInLiquidCode: Awaited<ReturnType<typeof shopifyConnectionService.writeAddonToShopify>> = yield retry(
      3,
      1000,
      shopifyConnectionService.writeAddonToShopify,
      { ...addonsDisablePositionParams, isPreview: false },
    );
    const socketSyncAddonsDisablePosition: SagaReturnType<typeof handleWaitForSocketOfSyncThemeByResultOfExtractThemeToFileForSync> = yield call(
      handleWaitForSocketOfSyncThemeByResultOfExtractThemeToFileForSync,
      'Đồng bộ theme / Ghi addon disable position',
    );
    if (socketSyncAddonsDisablePosition.statusSync === 'failure') {
      return {
        statusSyncAddonsDisablePosition: 'failure',
        messageError: socketSyncAddonsDisablePosition.message,
        isNeedIgnoreReportError: socketSyncAddonsDisablePosition.isNeedIgnoreReportError,
      } as SyncAddonsDisablePositionResult;
    } else {
      const addonFilesAfterSync = signalToReplaceAddonInLiquidCode.map(item => ({
        addonId: item.id,
        fileNameWithoutExtension: item.fileNameWithoutExtension,
      }));
      const content = addonFilesAfterSync
        .map(addonFile => {
          const { addonId, fileNameWithoutExtension } = addonFile;
          return `
            {% capture addon_${addonId}_content %}
              {% render '${fileNameWithoutExtension}' %}
            {% endcapture %}

            {% unless addon_${addonId}_content contains "Could not find asset" %}
              {% render '${fileNameWithoutExtension}' %}
            {% endunless %}
          `;
        })
        .join('\n');
      yield retry(3, 1000, shopifyConnectionService.updateAddonsDisablePositionToShopify, {
        isPreview: false,
        eventType: 'Ghi file khi save ở builder page / Ghi các addon disable position vừa tạo xong vào file theme.liquid',
        content,
        eventId: addonsDisablePositionParams.eventId,
        themeName: addonsDisablePositionParams.themeName,
      });
      return {
        statusSyncAddonsDisablePosition: 'success',
        messageError: undefined,
        isNeedIgnoreReportError: false,
      } as SyncAddonsDisablePositionResult;
    }
  } catch (error) {
    return {
      statusSyncAddonsDisablePosition: 'failure',
      messageError: error && typeof error === 'object' && 'message' in error ? error?.message : error,
      isNeedIgnoreReportError: false,
    } as SyncAddonsDisablePositionResult;
  }
}
