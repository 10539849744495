import { getCacheValue, setCacheValue, getUniqId } from '../../../CacheShopifyFieldId';
import { ISCHEMA_SettingChooseImage } from '../@types/ISCHEMA_SettingChooseImage';
import { Key } from '../@types/SettingChooseImage';

export const toShopifyFieldId = ({ field, parentField, section, isExportForEnvato }: ISCHEMA_SettingChooseImage, key: Key) => {
  const theoryShopifyFieldId = isExportForEnvato
    ? parentField
      ? `${parentField.name}___${field.name}`
      : `${field.name}`
    : parentField
    ? `${parentField.name}___${field.name}__${key}`
    : `${field.name}__${key}`;
  if (theoryShopifyFieldId.length >= 25) {
    const valueInCache = getCacheValue({ theoryShopifyFieldId, section });
    if (valueInCache) {
      return valueInCache;
    } else {
      const newKey = `VEDA_image_${getUniqId('image')}`;
      setCacheValue({ theoryShopifyFieldId, section }, newKey);
      return newKey;
    }
  }
  return theoryShopifyFieldId;
};
