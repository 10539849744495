import { delay } from '@wiloke/functions';
import { ProcessLoaderStatus, ProgressLoader } from '@wiloke/ui';
import { iframeLoadedSelector } from 'containers/BuilderPage/store/iframeLoaded/slice';
import { useSectionIdReRendered } from 'hocs/withDebounce';
import { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSetIframeHover } from 'store/global/iframeHover/slice';
import {
  authSelector,
  fullscreenSelector,
  isDraggingSelector,
  layoutSettingsSelector,
  pageSectionsSelector,
  responsiveSelector,
  sectionEdittingIdSelector,
  sectionIdActiveSelector,
  sectionIdCodeVisibleSelector,
} from 'store/selectors';
import { PageSection } from 'types/Sections';
import { pmParent } from 'utils/functions/postMessage';
import { View } from 'wiloke-react-core';
import { SectionContextMenu } from '../SectionContextMenu/SectionContextMenu';
import IframeSkeleton from './IframeSkeleton';
import * as styles from './styles';

interface IframeProps {
  pageId?: string;
}

const Iframe: FC<IframeProps> = ({ pageId }) => {
  const setIframeHover = useSetIframeHover();

  const isDragging = useSelector(isDraggingSelector);
  const responsive = useSelector(responsiveSelector);
  const fullscreen = useSelector(fullscreenSelector);
  const layoutSettings = useSelector(layoutSettingsSelector);
  const sectionIdCodeVisible = useSelector(sectionIdCodeVisibleSelector);
  const sectionEdittingId = useSelector(sectionEdittingIdSelector);
  const sectionIdActive = useSelector(sectionIdActiveSelector);
  const pageSections = useSelector(pageSectionsSelector);
  const { shopName } = useSelector(authSelector);
  const section = pageSections.find(section => section.id === sectionEdittingId) as PageSection;
  const iframeContainerRef = useRef<HTMLElement | null>(null);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const visible = !!section && !!sectionIdCodeVisible;
  const iframeLoaded = useSelector(iframeLoadedSelector);
  const [scale, setScale] = useState(
    visible ? 1 : iframeContainerRef.current?.offsetWidth ? iframeContainerRef.current.offsetWidth / window.innerWidth : 1,
  );
  const [processLoaderStatus, setProcessLoaderStatus] = useState<ProcessLoaderStatus>('idle');
  const [sectionIdReRendered, setSectionIdReRendered] = useSectionIdReRendered();

  const getScale = () => {
    if (visible || fullscreen) {
      return 1;
    }
    if (!!iframeContainerRef.current?.offsetWidth) {
      if (iframeContainerRef.current.offsetWidth > layoutSettings.containerWidth + 200) {
        return 1;
      }
      return iframeContainerRef.current.offsetWidth / (Math.min(layoutSettings.containerWidth, 1400) + 100);
    }
    return 1;
  };

  useEffect(() => {
    if (sectionIdReRendered != null && sectionIdReRendered === sectionIdActive && !sectionIdCodeVisible) {
      (async () => {
        setProcessLoaderStatus('loading');
        await delay(5000);
        setProcessLoaderStatus('done');
      })();
    }
  }, [sectionIdReRendered, sectionIdActive, sectionIdCodeVisible]);

  useEffect(() => {
    const scale = getScale();
    setScale(scale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, fullscreen]);

  useEffect(() => {
    if (iframeLoaded) {
      iframeRef.current?.contentDocument?.body?.setAttribute('data-scale', scale.toString());
    }
  }, [scale, iframeLoaded]);

  useEffect(() => {
    const off = pmParent.on('@iframeSectionRerendered', async rendered => {
      if (!sectionIdCodeVisible) {
        setProcessLoaderStatus(rendered ? 'done' : 'loading');
        setSectionIdReRendered(undefined);
      }
    });
    return () => {
      off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionIdCodeVisible]);

  // const handleIframeWindowScroll = () => {
  //   const win = iframeRef.current?.contentWindow as Window;
  //   const sectionEls = win.document.querySelectorAll<HTMLElement>('#iframe-root > [data-id');
  //   sectionEls.forEach(sectionEl => {
  //     const sectionTop = offset(sectionEl).top;
  //     if (win.scrollY + win.innerHeight / 2 > sectionTop + sectionEl.offsetHeight / 2) {
  //       console.log(sectionEl.getAttribute('data-id'));
  //     }
  //   });
  // };

  // useEffect(() => {
  //   const win = iframeRef.current?.contentWindow;
  //   if (win) {
  //     win.addEventListener('scroll', handleIframeWindowScroll);
  //     return () => {
  //       win.removeEventListener('scroll', handleIframeWindowScroll);
  //     };
  //   }
  // }, []);

  return (
    <View ref={iframeContainerRef} css={styles.iframe(isDragging, responsive)}>
      <View css={styles.zoom(scale)}>
        <ProgressLoader
          status={processLoaderStatus}
          onDone={() => {
            setProcessLoaderStatus('idle');
          }}
          css={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 999 }}
        />
        <View
          ref={iframeRef}
          tagName="iframe"
          id="iframe-content"
          src={`/iframe?shop=${shopName}&id=${pageId}&previewMode=${window.location.pathname.includes('/builder') ? false : true}`}
          style={{ width: '100%', height: '100%' }}
          onMouseEnter={() => {
            setIframeHover(true);
          }}
          onMouseLeave={() => {
            setIframeHover(false);
          }}
        />
      </View>
      <SectionContextMenu iframeEl={iframeRef.current} />
      <IframeSkeleton />
    </View>
  );
};

export default Iframe;
