import { AxiosResponse } from 'axios';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface ResponseSuccess {
  info: {
    createdDateGMT: string;
    id: number;
    planHandle: string;
    price: string;
    status: string;
    trialDays: number;
    userId: number;
  };
  message: string;
  status: string;
}

export const downgradeToFree = async () => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${baseUrl}/me/plans/downgrade-to-free`,
    method: 'PUT',
  });

  return response.data;
};
