import { setHeaderFooterIdsThemeActive, themeDashboardSelector } from 'containers/Admin/ThemeBuilder/ThemeDashboard/slice/sliceThemeDashboard';
import { all, call, cancel, fork, put, retry, select, take, SagaReturnType } from 'redux-saga/effects';
import { adapterGetAddons } from 'services/AddonService/Adapters/adapterGetAddons';
import { deleteClientAddons } from 'services/AddonService/Logic/Addons';
import { mediaService } from 'services/MediaService';
import { megaMenuService } from 'services/MegaMenuService';
import { createMegamenuOfPageClient } from 'services/PageService/Logic/createMegamenuOfPageClient';
import { createPageClient } from 'services/PageService/Logic/createPageClient';
import { createSectionOfPageClient } from 'services/PageService/Logic/createSectionOfPageClient';
import { updateMegamenuOfPageClient } from 'services/PageService/Logic/updateMegamenuOfPageClient';
import { updatePageClient } from 'services/PageService/Logic/updatePageClient';
import { updateSectionOfPageClient } from 'services/PageService/Logic/updateSectionOfPageClient';
import { sectionService } from 'services/SectionService';
import {
  addMegaMenusToPage,
  removeDeletedSectionAddonMegaMenuCommandIds,
  setPage,
  updateFootersToPage,
  updateHeadersToPage,
  updateMainSectionsToPages,
} from 'store/actions/actionPages';
import { createAddonOfThemeClient } from 'services/ThemeService/Logic/createAddonOfThemeClient';
import { updateAddonOfThemeClient } from 'services/ThemeService/Logic/updateAddonOfThemeClient';
import { updateThemeClient } from 'services/ThemeService/Logic/updateThemeClient';
import { setVendors } from 'store/actions/actionVendors';
import { setGeneralSettingsPage } from 'store/global/generalSettings/slice';
import { setGlobalJs } from 'store/global/globalJs/slice';
import { setGlobalScss } from 'store/global/globalScss/slice';
import { syncToShopify } from 'store/global/socket/syncShopifyNClonePageAtomServiceToThemeAtomService/actions';
import { updateThemeAddonsToPage } from 'store/global/themeAddons/actions';
import { pageSectionsSelector, pagesSelector, themeSettingsSelector } from 'store/selectors';
import { AddonOfTheme_Atom_N_Client } from 'types/Addons';
import { PageOfThemeService, PageType } from 'types/Page';
import { PageSection, SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client } from 'types/Sections';
import { isDefault, isMain } from 'utils/functions/checkSectionType';
import { deepFind } from 'utils/functions/deepFind';
import { ErrorData } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { removeDuplicateByKey } from '@wiloke/functions';
import { ThemeTranslationsResponse } from 'types/Result';
import { Consts } from 'utils/constants/constants';
import { syncPageNotification } from 'store/global/socket/syncShopifyNClonePageAtomServiceToThemeAtomService/watchSyncToShopify';
import { Task } from 'redux-saga';
import { ModalReportAfterError } from 'containers/ModalReportAfterError/ModalReportAfterError';
import { MODAL_REPORT_SAVE_ERROR } from 'containers/ModalReportAfterError/const';
import { i18n } from 'translation';
import { savePageForUser } from './actions';
import { enableProductPageService } from 'services/PageService/Logic/enableProductPage';
import { disableProductPageService } from 'services/PageService/Logic/disableProductPage';
import { AxiosError } from 'axios';
import { setVisibleUpgradePlan } from 'containers/ModalUpgradeOrChangePlanName/sliceUpgradePlan';
import { setIsSaveAddons, setOriginalThemeAddons } from 'store/reducers/sliceOriginThemeAddons';
import { handleGetPageCounter } from 'store/global/pageCounter/sagas/watchGetPageCounter';
import { uniqBy } from 'lodash';
import { isBase64Image } from 'utils/isBase64Image';
import { setResponsive } from 'containers/BuilderPage/store/responsive/slice';
import { handleSaveHeaderOrFooterOfThemeClient } from 'containers/BuilderPage/store/saveForBuilder/watchSaveTheme/ThemeClient/handleSaveThemeClient';
import { createHeaderOrFooterOfThemeClient } from 'services/ThemeService/Logic/createHeaderOrFooterOfThemeClient';
import { updateHeaderOrFooterOfThemeClient } from 'services/ThemeService/Logic/updateHeaderOrFooterOfThemeClient';
import { setThemeFooters, setThemeHeaders } from 'store/global/themeHeaderFooter/slice';
import { setIsSavedTheme, setOriginThemeSettings } from 'store/reducers/sliceOriginThemeSettings';

interface SaveSectionOfPageClient {
  section: PageSection;
  megaMenus: PageSection[];
}

function* handleSaveSectionOfPageClient(section: SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client, variant: 'Create' | 'Update') {
  const pageSections: ReturnType<typeof pageSectionsSelector> = yield select(pageSectionsSelector);

  const megaMenuIds = deepFind(section, 'megaMenuId');
  const megaMenuSections = pageSections.filter(item => megaMenuIds.includes(item.id));

  /** Xử lý lưu mega menu */
  const megamenuResponses: Array<Awaited<ReturnType<typeof createMegamenuOfPageClient | typeof updateMegamenuOfPageClient>>> = yield all(
    megaMenuSections.map(megamenuSection => {
      if (megamenuSection.commandId && variant === 'Update') {
        return retry(3, 1000, updateMegamenuOfPageClient, {
          megamenu: megamenuSection as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client,
        });
      } else {
        return retry(3, 1000, createMegamenuOfPageClient, {
          megamenu: megamenuSection as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client,
        });
      }
    }),
  );

  const megaMenuCommandIds = megamenuResponses.map(megamenuResponse => megamenuResponse.commandId).filter(Boolean);
  /** Xử lý lưu section */
  if (section.commandId && variant === 'Update') {
    const response: Awaited<ReturnType<typeof updateSectionOfPageClient>> = yield retry(3, 1000, updateSectionOfPageClient, {
      section: { ...section, megaMenuCommandIds } as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client,
    });
    return {
      section: response,
      megaMenus: megamenuResponses,
    };
  } else {
    const response: Awaited<ReturnType<typeof createSectionOfPageClient>> = yield retry(3, 1000, createSectionOfPageClient, {
      section: { ...section, megaMenuCommandIds } as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client,
    });
    return {
      section: response,
      megaMenus: megamenuResponses,
    };
  }
}

function* handleOverridePage({ isOverride, commandId, status }: { isOverride: boolean; commandId: string; status: 'enable' | 'disable' }) {
  if (status === 'enable') {
    yield retry(3, 1000, enableProductPageService, { commandId, disableOthers: isOverride });
  }
  if (status === 'disable') {
    yield retry(3, 1000, disableProductPageService, { commandId, disableExceptMe: isOverride });
  }
}

function* handleCreatePageClient({ payload }: ReturnType<typeof savePageForUser.request>) {
  const {
    page,
    settings,
    outputBuilder,
    onFulfill,
    isOverrideIndividualPages,
    isSaveAsLandingPage,
    addons,
    isIgnoreSyncShopify,
    onSuccess,
    onError,
    footers,
    headers,
  } = payload;
  const mainSections = page.sections.filter(section => isMain(section.type) || isDefault(section.type));
  try {
    const { url, id }: SagaReturnType<typeof mediaService.uploadBase64ScreenshotToMyMedia> = isBase64Image(page.image.src)
      ? yield call(mediaService.uploadBase64ScreenshotToMyMedia, { base64: page.image.src })
      : { id: null, url: page.image.src ?? 'https://cdn.shopify.com/s/files/1/0552/5510/5616/files/2-niybpncsmr.jpg?v=1669713781' };

    /** Xử lý lưu section & megamenu */
    // TODO: Utils transform thay vì ép kiểu
    let sectionResponse: PageSection[] = [];
    let megaMenusResponse: PageSection[] = [];

    const responses: SaveSectionOfPageClient[] = yield all(
      mainSections.map(section => {
        return call(handleSaveSectionOfPageClient, section as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client, 'Create');
      }),
    );
    for (const response of responses) {
      sectionResponse = sectionResponse.concat(response.section);
      megaMenusResponse = megaMenusResponse.concat(response.megaMenus).flat();
    }

    yield put(updateMainSectionsToPages({ sections: sectionResponse, pageId: page.commandId }));

    /** Xử lý lưu page */
    const response: Awaited<ReturnType<typeof createPageClient>> = yield retry(3, 1000, createPageClient, {
      page: {
        ...page,
        image: { ...page.image, src: url },
        sections: sectionResponse.length > 0 ? sectionResponse : [],
        enable: true,
        label: settings.generalSettings.label,
      },
      pageSettings: settings,
      imageAtomCommandId: id,
    });

    const { info } = response;

    // TODO: Utils transform thay vì ép kiểu
    const headerResponses: Array<Awaited<
      ReturnType<typeof createHeaderOrFooterOfThemeClient | typeof updateHeaderOrFooterOfThemeClient>
    >> = yield all(headers.map(header => call(handleSaveHeaderOrFooterOfThemeClient, header as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client)));
    const headerCommandIds = headerResponses.map(headerResponse => headerResponse.commandId);
    yield put(setThemeHeaders({ headers: headerResponses }));
    yield put(updateHeadersToPage({ headers: headerResponses }));

    /** Save footer */
    // TODO: Utils transform thay vì ép kiểu
    const footerResponses: Array<Awaited<
      ReturnType<typeof createHeaderOrFooterOfThemeClient | typeof updateHeaderOrFooterOfThemeClient>
    >> = yield all(footers.map(footer => call(handleSaveHeaderOrFooterOfThemeClient, footer as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client)));
    const footerCommandIds = footerResponses.map(footerResponse => footerResponse.commandId);
    yield put(setThemeFooters({ footers: footerResponses }));
    yield put(updateFootersToPage({ footers: footerResponses }));

    // Xử lý addon sau save page để thực hiện gán "belongToPages" cho addons
    const themeSettings: ReturnType<typeof themeSettingsSelector> = yield select(themeSettingsSelector);
    const { themeActivate }: ReturnType<typeof themeDashboardSelector> = yield select(themeDashboardSelector);
    /** Delete addons */
    const { deletedAddonIds }: ReturnType<typeof pagesSelector> = yield select(pagesSelector);
    if (deletedAddonIds.length > 0) {
      for (const deletedAddonId of deletedAddonIds) {
        yield retry(3, 1000, deleteClientAddons, { commandId: deletedAddonId.commandId });
        yield put(removeDeletedSectionAddonMegaMenuCommandIds({ deletedAddonCommandId: deletedAddonId.commandId }));
      }
    }
    /** Save addon */
    const pageSections: ReturnType<typeof pageSectionsSelector> = yield select(pageSectionsSelector);

    // TODO: Utils transform thay vì ép kiểu
    const addonResponses: Array<Awaited<ReturnType<typeof createAddonOfThemeClient | typeof updateAddonOfThemeClient>>> = yield all(
      addons.map(addon => {
        const currentPage = info;
        const isUsedInCurrentPage = pageSections.find(section => section.addonIds?.includes(addon.sectionId));
        const prevBelongToPages = (addon as AddonOfTheme_Atom_N_Client).belongsToPage?.filter(page => page.commandId !== currentPage.commandId) ?? [];

        const currentBelongToPages: AddonOfTheme_Atom_N_Client['belongsToPage'] = (isUsedInCurrentPage ? [info] : []).map(page => {
          // Nếu addon thuộc header, footer thì commandId + label => allPages
          if (!addon.positionEnabled) {
            return {
              commandId: 'allPages',
              label: 'All Pages',
            };
          }
          return {
            commandId: page.commandId,
            label: page.label,
          };
        });
        const finalBelongToPages = uniqBy(prevBelongToPages.concat(currentBelongToPages), 'commandId');
        if (addon.commandId) {
          return retry(3, 1000, updateAddonOfThemeClient, {
            addon: { ...addon, belongsToPage: finalBelongToPages, category: undefined } as AddonOfTheme_Atom_N_Client,
          });
        }
        return retry(3, 1000, createAddonOfThemeClient, {
          addon: { ...addon, belongsToPage: finalBelongToPages, category: undefined } as AddonOfTheme_Atom_N_Client,
        });
      }),
    );
    const addonCommandIds = addonResponses.map(addonResponse => addonResponse.commandId);
    yield put(updateThemeAddonsToPage.success({ addons: adapterGetAddons(addonResponses) }));
    yield put(setIsSaveAddons(true));
    yield put(setOriginalThemeAddons(adapterGetAddons(addonResponses)));
    /** Update theme */
    const themeResponse: SagaReturnType<typeof updateThemeClient> = yield retry(3, 1000, updateThemeClient, {
      theme: {
        commandId: themeActivate.commandId,
        headerSectionCommandIds: headerCommandIds,
        footerSectionCommandIds: footerCommandIds,
        addonCommandIds: addonCommandIds,
        globalJs: themeSettings.globalJs,
        globalScss: themeSettings.globalScss,
        themeSettings: {
          ...themeSettings,
          colorSchemes: themeSettings.colorSchemes.colorSchemes,
          globalTranslations: themeSettings.globalTranslations as ThemeTranslationsResponse,
        },
        vendors: themeSettings.vendors,
      },
    });
    yield put(setHeaderFooterIdsThemeActive({ footerIds: themeResponse.footerSectionCommandIds, headerIds: themeResponse.headerSectionCommandIds }));
    // ================>  Xử lý addon sau save page để thực hiện gán "belongToPages" cho addons <================

    // @ts-ignore
    yield call(handleOverridePage, {
      commandId: info.commandId,
      isOverride: isOverrideIndividualPages,
      status: info.enable ? (info.enable ? 'enable' : 'disable') : 'enable',
    });

    if (info.pageSettings) {
      yield put(setGeneralSettingsPage({ settings: info.pageSettings.generalSettings }));
      yield put(setVendors({ vendors: info.pageSettings.vendors }));
      yield put(setGlobalJs({ js: info.pageSettings.globalJs }));
      yield put(setGlobalScss({ scss: info.pageSettings.globalScss }));
    }

    yield put(
      setPage({
        ...page,
        commandId: info.commandId,
        type: info.type as PageType,
        sections: removeDuplicateByKey([...page.sections, ...sectionResponse], 'id'),
        label: settings.generalSettings.label,
      }),
    );
    yield put(addMegaMenusToPage(megaMenusResponse));

    const outputBuilderAfterResponse: typeof outputBuilder = {
      ...outputBuilder,
      pages: {
        ...outputBuilder.pages,
        [page.commandId]: {
          ...outputBuilder.pages[page.commandId],

          /**
           * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
           */
          // atomicCss: outputBuilder.pages[page.commandId].atomicCss,
          data: {
            ...outputBuilder.pages[page.commandId].data,
            page: {
              ...page,
              type: isSaveAsLandingPage ? 'page' : page.type,
              commandId: response.info.commandId,
              label: settings.generalSettings.label,
            },
          },
          files: outputBuilder.pages[page.commandId].files,
        },
      },
    };

    const pageResponse = {
      ...page,
      ...((response.info as unknown) as PageOfThemeService),
      commandId: response.info.commandId,
    };

    if (isIgnoreSyncShopify) {
      onSuccess?.(pageResponse);
      onFulfill(pageResponse);
    } else {
      yield put(
        syncToShopify.request({
          deletedAddonIds,
          entityVariant: 'Client',
          result: outputBuilderAfterResponse,
          isOverrideIndividualPages,
          isSaveAsLandingPage,
          onSyncFulfill: () => {
            onSuccess?.(pageResponse);
            onFulfill(pageResponse);
          },
        }),
      );
    }

    yield call(handleGetPageCounter, { type: '@Global/getPageCounter/request', payload: undefined });
    yield put(savePageForUser.success(undefined));
    yield put(setResponsive('desktop'));
    yield put(setIsSavedTheme(true));
    yield put(
      setOriginThemeSettings({
        cssVariables: themeResponse.themeSettings.cssVariables,
        generalSettings: themeResponse.themeSettings.generalSettings,
        globalTranslations: themeResponse.themeSettings.globalTranslations,
        layoutSettings: themeResponse.themeSettings.layoutSettings,
        vendors: themeResponse.vendors,
        globalJs: themeResponse.globalJs,
        globalScss: themeResponse.globalScss,
        colorSchemes: themeResponse.themeSettings.colorSchemes,
      }),
    );
  } catch (error) {
    onError?.();
    onFulfill();
    syncPageNotification.done();

    if ((error as AxiosError).isAxiosError && (error as AxiosError).response?.status === 402) {
      yield put(setVisibleUpgradePlan({ visible: true, message: (error as AxiosError<ErrorData>).response?.data.message }));
    } else {
      ModalReportAfterError.getActions(MODAL_REPORT_SAVE_ERROR).report({
        cause: i18n.t('ModalReportAfterError.error_cause.save_result'),
        description: (error as AxiosError).isAxiosError ? (error as AxiosError<ErrorData>).response?.data.message ?? '' : (error as Error).message,
      });
    }

    yield put(savePageForUser.failure(undefined));
  }
}

function* handleUpdatePageClient({ payload }: ReturnType<typeof savePageForUser.request>) {
  const {
    isSaveAsLandingPage,
    page,
    settings,
    outputBuilder,
    onFulfill,
    isOverrideIndividualPages,
    addons,
    isIgnoreSyncShopify,
    onError,
    onSuccess,
    footers,
    headers,
  } = payload;
  const mainSections = page.sections.filter(section => isMain(section.type) || isDefault(section.type));
  try {
    const { url, id }: SagaReturnType<typeof mediaService.uploadBase64ScreenshotToMyMedia> = isBase64Image(page.image.src)
      ? yield call(mediaService.uploadBase64ScreenshotToMyMedia, { base64: page.image.src })
      : { id: null, url: page.image.src ?? 'https://cdn.shopify.com/s/files/1/0552/5510/5616/files/2-niybpncsmr.jpg?v=1669713781' };

    // TODO: Utils transform thay vì ép kiểu
    const headerResponses: Array<Awaited<
      ReturnType<typeof createHeaderOrFooterOfThemeClient | typeof updateHeaderOrFooterOfThemeClient>
    >> = yield all(headers.map(header => call(handleSaveHeaderOrFooterOfThemeClient, header as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client)));
    const headerCommandIds = headerResponses.map(headerResponse => headerResponse.commandId);
    yield put(setThemeHeaders({ headers: headerResponses }));
    yield put(updateHeadersToPage({ headers: headerResponses }));

    /** Save footer */
    // TODO: Utils transform thay vì ép kiểu
    const footerResponses: Array<Awaited<
      ReturnType<typeof createHeaderOrFooterOfThemeClient | typeof updateHeaderOrFooterOfThemeClient>
    >> = yield all(footers.map(footer => call(handleSaveHeaderOrFooterOfThemeClient, footer as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client)));
    const footerCommandIds = footerResponses.map(footerResponse => footerResponse.commandId);
    yield put(setThemeFooters({ footers: footerResponses }));
    yield put(updateFootersToPage({ footers: footerResponses }));

    const { deletedMegaMenuCommandIds, deletedSectionCommandIds }: ReturnType<typeof pagesSelector> = yield select(pagesSelector);

    if (deletedSectionCommandIds.length > 0) {
      for (const deletedSectionCommandId of deletedSectionCommandIds) {
        yield retry(3, 1000, sectionService.sections.deleteClientSections, { commandId: deletedSectionCommandId });
        yield put(
          removeDeletedSectionAddonMegaMenuCommandIds({
            deletedSectionCommandId,
          }),
        );
      }
    }
    if (deletedMegaMenuCommandIds.length > 0) {
      for (const deletedMegaMenuCommandId of deletedMegaMenuCommandIds) {
        yield retry(3, 1000, megaMenuService.mega_menu.deleteClientMegaMenus, { commandId: deletedMegaMenuCommandId });
        yield put(
          removeDeletedSectionAddonMegaMenuCommandIds({
            deletedMegaMenuCommandId,
          }),
        );
      }
    }

    /** Xử lý lưu section & megamenu */
    // TODO: Utils transform thay vì ép kiểu
    let sectionResponse: PageSection[] = [];
    let megaMenusResponse: PageSection[] = [];

    const responses: SaveSectionOfPageClient[] = yield all(
      mainSections.map(section => {
        return call(handleSaveSectionOfPageClient, section as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client, 'Update');
      }),
    );

    for (const response of responses) {
      sectionResponse = sectionResponse.concat(response.section);
      megaMenusResponse = megaMenusResponse.concat(response.megaMenus).flat();
    }

    yield put(updateMainSectionsToPages({ sections: sectionResponse, pageId: page.commandId }));

    /** Xử lý lưu page */
    const response: Awaited<ReturnType<typeof updatePageClient>> = yield retry(3, 1000, updatePageClient, {
      page: {
        ...page,
        image: { ...page.image, src: url },
        sections: sectionResponse.length > 0 ? sectionResponse : [],
        enable: true,
        label: settings.generalSettings.label,
      },
      pageSettings: settings,
      imageAtomCommandId: id,
    });

    const { info } = response;

    // Xử lý addon sau save page để thực hiện gán "belongToPages" cho addons
    const themeSettings: ReturnType<typeof themeSettingsSelector> = yield select(themeSettingsSelector);
    const { themeActivate }: ReturnType<typeof themeDashboardSelector> = yield select(themeDashboardSelector);
    /** Delete addons */
    const { deletedAddonIds }: ReturnType<typeof pagesSelector> = yield select(pagesSelector);
    if (deletedAddonIds.length > 0) {
      for (const deletedAddonId of deletedAddonIds) {
        yield retry(3, 1000, deleteClientAddons, { commandId: deletedAddonId.commandId });
        yield put(removeDeletedSectionAddonMegaMenuCommandIds({ deletedAddonCommandId: deletedAddonId.commandId }));
      }
    }
    /** Save addons */
    const pageSections: ReturnType<typeof pageSectionsSelector> = yield select(pageSectionsSelector);
    // TODO: Utils transform thay vì ép kiểu
    const addonResponses: Array<Awaited<ReturnType<typeof createAddonOfThemeClient | typeof updateAddonOfThemeClient>>> = yield all(
      addons.map(addon => {
        const currentPage = info;
        const isUsedInCurrentPage = pageSections.find(section => section.addonIds?.includes(addon.sectionId));
        const prevBelongToPages = (addon as AddonOfTheme_Atom_N_Client).belongsToPage?.filter(page => page.commandId !== currentPage.commandId) ?? [];
        const currentBelongToPages: AddonOfTheme_Atom_N_Client['belongsToPage'] = (isUsedInCurrentPage ? [info] : []).map(page => ({
          commandId: page.commandId,
          label: page.label,
        }));
        const finalBelongToPages = uniqBy(prevBelongToPages.concat(currentBelongToPages), 'commandId');
        if (addon.commandId) {
          return retry(3, 1000, updateAddonOfThemeClient, {
            addon: { ...addon, belongsToPage: finalBelongToPages, category: undefined } as AddonOfTheme_Atom_N_Client,
          });
        }
        return retry(3, 1000, createAddonOfThemeClient, {
          addon: { ...addon, belongsToPage: finalBelongToPages, category: undefined } as AddonOfTheme_Atom_N_Client,
        });
      }),
    );
    const addonCommandIds = addonResponses.map(addonResponse => addonResponse.commandId);
    yield put(updateThemeAddonsToPage.success({ addons: adapterGetAddons(addonResponses) }));
    yield put(setIsSaveAddons(true));
    yield put(setOriginalThemeAddons(adapterGetAddons(addonResponses)));
    /** Update theme */
    const themeResponse: SagaReturnType<typeof updateThemeClient> = yield retry(3, 1000, updateThemeClient, {
      theme: {
        commandId: themeActivate.commandId,
        headerSectionCommandIds: headerCommandIds,
        footerSectionCommandIds: footerCommandIds,
        addonCommandIds: addonCommandIds,
        globalJs: themeSettings.globalJs,
        globalScss: themeSettings.globalScss,
        themeSettings: {
          ...themeSettings,
          colorSchemes: themeSettings.colorSchemes.colorSchemes,
          globalTranslations: themeSettings.globalTranslations as ThemeTranslationsResponse,
        },
        vendors: themeSettings.vendors,
      },
    });
    yield put(setHeaderFooterIdsThemeActive({ footerIds: themeResponse.footerSectionCommandIds, headerIds: themeResponse.headerSectionCommandIds }));

    // ================>  Xử lý addon sau save page để thực hiện gán "belongToPages" cho addons <================

    // @ts-ignore
    yield call(handleOverridePage, {
      commandId: info.commandId,
      isOverride: isOverrideIndividualPages,
      status: info.enable ? (info.enable ? 'enable' : 'disable') : 'enable',
    });

    if (info.pageSettings) {
      yield put(setGeneralSettingsPage({ settings: info.pageSettings.generalSettings }));
      yield put(setVendors({ vendors: info.pageSettings.vendors }));
      yield put(setGlobalJs({ js: info.pageSettings.globalJs }));
      yield put(setGlobalScss({ scss: info.pageSettings.globalScss }));
    }

    yield put(addMegaMenusToPage(megaMenusResponse));

    const outputBuilderAfterResponse: typeof outputBuilder = {
      ...outputBuilder,
      pages: {
        ...outputBuilder.pages,
        [page.commandId]: {
          ...outputBuilder.pages[page.commandId],
          /**
           * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
           */
          // atomicCss: outputBuilder.pages[page.commandId].atomicCss,
          data: {
            ...outputBuilder.pages[page.commandId].data,
            page: {
              ...page,
              type: isSaveAsLandingPage ? 'page' : page.type,
              commandId: response.info.commandId,
              label: settings.generalSettings.label,
            },
          },
          files: outputBuilder.pages[page.commandId].files,
        },
      },
    };
    const pageResponse = {
      ...page,
      ...((response.info as unknown) as PageOfThemeService),
      commandId: response.info.commandId,
    };

    if (isIgnoreSyncShopify) {
      onSuccess?.(pageResponse);
      onFulfill(pageResponse);
    } else {
      yield put(
        syncToShopify.request({
          deletedAddonIds,
          entityVariant: 'Client',
          result: outputBuilderAfterResponse,
          isOverrideIndividualPages,
          isSaveAsLandingPage,
          onSyncFulfill: () => {
            onSuccess?.(pageResponse);
            onFulfill(pageResponse);
          },
        }),
      );
    }

    yield put(savePageForUser.success(undefined));
    yield put(setResponsive('desktop'));
    yield put(setIsSavedTheme(true));
    yield put(
      setOriginThemeSettings({
        cssVariables: themeResponse.themeSettings.cssVariables,
        generalSettings: themeResponse.themeSettings.generalSettings,
        globalTranslations: themeResponse.themeSettings.globalTranslations,
        layoutSettings: themeResponse.themeSettings.layoutSettings,
        vendors: themeResponse.vendors,
        globalJs: themeResponse.globalJs,
        globalScss: themeResponse.globalScss,
        colorSchemes: themeResponse.themeSettings.colorSchemes,
      }),
    );
  } catch (error) {
    onError?.();
    onFulfill();
    syncPageNotification.done();
    console.log(error);

    if ((error as AxiosError).isAxiosError && (error as AxiosError).response?.status === 402) {
      yield put(setVisibleUpgradePlan({ visible: true, message: (error as AxiosError<ErrorData>).response?.data.message }));
    } else {
      ModalReportAfterError.getActions(MODAL_REPORT_SAVE_ERROR).report({
        cause: i18n.t('ModalReportAfterError.error_cause.save_result'),
        description: (error as AxiosError).isAxiosError ? (error as AxiosError<ErrorData>).response?.data.message ?? '' : (error as Error).message,
      });
    }

    yield put(savePageForUser.failure(undefined));
  }
}

function* handleSavePageClient(params: ReturnType<typeof savePageForUser.request>) {
  const { payload } = params;
  const { addons, onFulfill } = payload;
  yield put(updateThemeAddonsToPage.request({ addons }));

  try {
    syncPageNotification.next({
      title: i18n.t('publish_shopify.sync_page_message.step', { text: '2' }),
      description: i18n.t('general.saving', { text: i18n.t('general.page') }),
    });

    if (payload.data.commandId === Consts.BlankCommandId || payload.type === 'create') {
      yield call(handleCreatePageClient, params);
    } else {
      yield call(handleUpdatePageClient, params);
    }
  } catch (error) {
    onFulfill(undefined);
    syncPageNotification.done();

    if ((error as AxiosError).isAxiosError && (error as AxiosError).response?.status === 402) {
      yield put(setVisibleUpgradePlan({ visible: true, message: (error as AxiosError<ErrorData>).response?.data.message }));
    } else {
      ModalReportAfterError.getActions(MODAL_REPORT_SAVE_ERROR).report({
        cause: i18n.t('ModalReportAfterError.error_cause.save_result'),
        description: (error as AxiosError).isAxiosError ? (error as AxiosError<ErrorData>).response?.data.message ?? '' : (error as Error).message,
      });
    }

    yield put(savePageForUser.failure(undefined));
    yield put(updateThemeAddonsToPage.failure(undefined));
  }
}

let savePageTask: Task | undefined;

export function* watchSavePageClient() {
  while (true) {
    const requestAction: ReturnType<typeof savePageForUser.request> = yield take(getActionType(savePageForUser.request));
    if (!!savePageTask) {
      yield cancel(savePageTask);
    }
    savePageTask = yield fork(handleSavePageClient, requestAction);
  }
}

export function* watchCancelSavePageClient() {
  while (true) {
    const cancelAction: ReturnType<typeof savePageForUser.cancel> = yield take(getActionType(savePageForUser.cancel));
    if (cancelAction.type === '@BuilderPage/savePageForUser/cancel' && !!savePageTask) {
      yield cancel(savePageTask);
    }
  }
}
