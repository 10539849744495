import { Empty } from '@wiloke/ui';
import { FC, MutableRefObject, ReactNode } from 'react';
import { AutoSizer, List, ListRowRenderer, ScrollParams } from 'react-virtualized';
import { css, Styles, useStyleSheet, View, ViewProps } from 'wiloke-react-core';

export interface VirtualListProps {
  listRef?: MutableRefObject<List | null>;
  rowHeight: number;
  rowCount: number;
  containerClassName?: string;
  containerCss?: ViewProps['css'];
  disabledScrollStyle?: boolean;
  rowRender: (index: number) => ReactNode;
  onScroll?: (params: ScrollParams) => void;
}

const listStyles: Styles = {
  '&::-webkit-scrollbar': {
    width: '0',
  },
};

const scrollbar = css`
  --width: 10px;
  --scrollbar-track-color: transparent;
  --scrollbar-thumb-color: transparent;
  --scrollbar-thumb-color-hover: #999;
  --scrollbar-thumb-padding: 2px;
  --scrollbar-radius: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;

  &::-webkit-scrollbar {
    width: var(--width);
  }
  &::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color);
    border-radius: var(--scrollbar-radius);
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: var(--scrollbar-thumb-padding) solid transparent;
    border-radius: var(--scrollbar-radius);
    background-clip: padding-box;
    background-color: var(--scrollbar-thumb-color);
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: var(--scrollbar-thumb-color-hover);
    }
  }
`;

const VirtualList: FC<VirtualListProps> = ({
  rowCount,
  rowHeight,
  rowRender,
  containerClassName = '',
  containerCss = {},
  onScroll,
  listRef,
  disabledScrollStyle = true,
}) => {
  const { styles } = useStyleSheet();

  const _rowRenderer: ListRowRenderer = ({ index, key, style }) => {
    return (
      <View key={key} style={{ ...style, paddingBottom: 10 }}>
        {rowRender(index)}
      </View>
    );
  };

  if (rowCount === 0) {
    return <Empty />;
  }

  return (
    <View className={containerClassName} css={[{ height: '100%', flex: 1 }, containerCss]}>
      <AutoSizer>
        {({ width, height }) => {
          return (
            <List
              ref={listRef}
              onScroll={onScroll}
              width={width}
              height={height}
              rowHeight={rowHeight}
              rowRenderer={_rowRenderer}
              rowCount={Math.ceil(rowCount)}
              className={disabledScrollStyle ? styles(listStyles) : styles(scrollbar)}
            />
          );
        }}
      </AutoSizer>
    </View>
  );
};

export default VirtualList;
