import { handleWaitForSocketOfSyncShopifyFulfill } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { call, retry, SagaReturnType } from 'redux-saga/effects';
import { shopifyConnectionService } from 'services/ShopifyConnection';
import { handleDeletePageInDashboard } from 'services/ShopifyConnection/handleDeletePageInDashboard';

export interface DeletePage {
  deletePageParams: ReturnType<typeof handleDeletePageInDashboard>;
}

export interface DeletePageResult {
  statusDeletePage: SyncFulfillStatus;
  isNeedIgnoreReportError: boolean;
}

export function* deletePage({ deletePageParams }: DeletePage) {
  yield retry(3, 1000, shopifyConnectionService.deletePageInShopify, deletePageParams);
  const socketDeletePage: SagaReturnType<typeof handleWaitForSocketOfSyncShopifyFulfill> = yield call(
    handleWaitForSocketOfSyncShopifyFulfill,
    'Delete ouput builder ngoài dashboard',
  );
  return {
    statusDeletePage: socketDeletePage.statusSync,
    isNeedIgnoreReportError: socketDeletePage.isNeedIgnoreReportError,
  } as DeletePageResult;
}
