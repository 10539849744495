import { at } from './at';

export const methodAtOfArrayPolyfill = () => {
  if (!Array.prototype.at) {
    Array.prototype.at = function(index) {
      const array = Object(this);
      return at(array, index);
    };
  }
};
