import { handleWaitForSocketOfSyncShopifyFulfill } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { all, call, retry, SagaReturnType } from 'redux-saga/effects';
import { handleSaveInBuilderPage, shopifyConnectionService } from 'services/ShopifyConnection';

export interface SyncGlobalOfTheme {
  entityVariant: EntityType;
  themeParams: ReturnType<typeof handleSaveInBuilderPage>['themeParams'];

  /**
   * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
   */
  // atomicCssParams: ReturnType<typeof handleSaveInBuilderPage>['atomicCssParams'];
}

export interface SyncGlobalOfThemeResult {
  statusSyncGlobalOfTheme: SyncFulfillStatus;
  isNeedIgnoreReportError: boolean;

  /**
   * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
   */
  // statusSyncAtomicCss: SyncFulfillStatus;
}

/** Sync global (cái được sinh ra từ themeSettings) và atomic css */
export function* syncGlobalOfTheme({ entityVariant, themeParams }: SyncGlobalOfTheme) {
  // Nếu là client thì tiến hành bắn lên yêu cầu optimize atomic css
  if (entityVariant === 'Client') {
    yield all([
      retry(3, 1000, shopifyConnectionService.writeGlobalOfThemeToShopify, { ...themeParams, isPreview: false }),
      // retry(3, 1000, shopifyConnectionService.writeRawAtomicCssToShopify, {
      //   ...atomicCssParams,
      //   eventType: 'Ghi file raw atomic css khi save ở builder page',
      //   isPreview: false,
      // }),
      // retry(3, 1000, shopifyConnectionService.writeAtomicCssToShopify, { ...atomicCssParams, isPreview: false }),
    ]);
    const [socketSyncGlobalOfTheme]: [
      SagaReturnType<typeof handleWaitForSocketOfSyncShopifyFulfill>,

      /**
       * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
       */
      // SagaReturnType<typeof handleWaitForSocketOfSyncShopifyFulfill>,
    ] = yield all([
      call(handleWaitForSocketOfSyncShopifyFulfill, 'Ghi file khi save ở builder page / Ghi global (sinh ra từ themeSettings)'),

      /**
       * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
       */
      // call(handleWaitForSocketOfSyncShopifyFulfill, 'Ghi file atomic css khi save ở builder page'),
    ]);

    return {
      statusSyncGlobalOfTheme: socketSyncGlobalOfTheme.statusSync,
      isNeedIgnoreReportError: socketSyncGlobalOfTheme.isNeedIgnoreReportError,
      /**
       * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
       */
      // statusSyncAtomicCss: 'success',
    } as SyncGlobalOfThemeResult;
  } else {
    yield retry(3, 1000, shopifyConnectionService.writeGlobalOfThemeToShopify, { ...themeParams, isPreview: false });
    const socketSyncGlobalOfTheme: SagaReturnType<typeof handleWaitForSocketOfSyncShopifyFulfill> = yield call(
      handleWaitForSocketOfSyncShopifyFulfill,
      'Ghi file khi save ở builder page / Ghi global (sinh ra từ themeSettings)',
    );
    return {
      statusSyncGlobalOfTheme: socketSyncGlobalOfTheme.statusSync,
      isNeedIgnoreReportError: socketSyncGlobalOfTheme.isNeedIgnoreReportError,
      /**
       * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
       */
      // statusSyncAtomicCss: 'success',
    } as SyncGlobalOfThemeResult;
  }
}
