import { ComponentType, FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { createGlobalState, useDeepCompareEffect } from 'react-use';
import { sectionIdActiveSelector } from 'store/selectors';

export const useSectionIdReRendered = createGlobalState<string | undefined>(undefined);

const withDebounce = <P extends any>(WrappedComponent: ComponentType<P>, propValue: keyof P, propOnChange: keyof P, timeOut = 200) => {
  const WithDebounce: FC<P & { forceRenderSection?: boolean; setRenderDisabled?: boolean }> = props => {
    const timeIdRef = useRef(-1);
    const [mounted, setMouted] = useState(false);
    const [valueState, setValueState] = useState((props as any)[propValue]);
    const sectionIdActive = useSelector(sectionIdActiveSelector);
    const [, setSectionIdReRendered] = useSectionIdReRendered();

    useDeepCompareEffect(() => {
      if ((props as any)[propValue] !== undefined) {
        setValueState((props as any)[propValue]);
      }
    }, [[(props as any)[propValue]]]);

    useEffect(() => {
      if (mounted) {
        const fn = (props as any)[propOnChange];
        // eslint-disable-next-line
        if (!props?.setRenderDisabled) {
          setSectionIdReRendered(sectionIdActive);
        }
        clearTimeout(timeIdRef.current);
        timeIdRef.current = window.setTimeout(() => {
          if (fn) {
            fn(valueState);
          }
        }, timeOut);
      }
      setMouted(true);
      return () => {
        clearTimeout(timeIdRef.current);
        setMouted(false);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueState]);

    const handleChange = (value: any) => {
      setValueState(value);
    };

    return <WrappedComponent {...props} {...{ [propOnChange]: handleChange, [propValue]: valueState }} />;
  };
  return WithDebounce;
};

export default withDebounce;
