import { megaMenuApiController } from 'services/MegaMenuService';
import { adapterGetMegaMenu } from 'services/MegaMenuService/Adapters/adapterGetMegaMenu';
import { AdminSection } from 'types/Sections';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const getAtomSection = async (commandId: string) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const atomResponse = await megaMenuApiController.atom.adminApi.mega_menu.getAtom({ commandId, cacheVersion: lastCache });
    const productResponse = await megaMenuApiController.product.adminApi.mega_menu.getProductsWithParentCommandIds({
      parentCommandIds: atomResponse.info.commandId,
      cacheVersion: lastCache,
    });
    const withAdapter = adapterGetMegaMenu(atomResponse.info) as AdminSection;
    return {
      ...withAdapter,
      vedaProductRepresent: productResponse.info.find(item => item.parentCommandId === atomResponse.info.commandId),
    };
  }
  if (role === 'dev' || role === 'support') {
    const response = await megaMenuApiController.atom.publishApi.mega_menu.getAtom({ commandId, cacheVersion: lastCache });
    return adapterGetMegaMenu(response.info) as AdminSection;
  }
  throw new RoleException();
};
