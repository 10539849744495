import { VedaLoadingItem } from '@wiloke/ui';
import { Highlight } from 'components/Highlight';
import { FC } from 'react';
import { View, Text, Space, useTheme } from 'wiloke-react-core';

interface StepDownloadThemeProps {
  themeName?: string;
  isDownloading: boolean;
  onDownload: () => void;
  onSkip?: () => void;
}

export const StepDownloadTheme: FC<StepDownloadThemeProps> = ({ themeName, onDownload, onSkip, isDownloading }) => {
  const { colors } = useTheme();
  return (
    <Text color="gray8" size={16}>
      <Highlight query={themeName ?? ''} highlightCss={{ color: colors.primary, fontWeight: '500' }}>
        {`Please download theme ${themeName ?? ''} first.`}
      </Highlight>
      <View>Then, you can install and customize it up!</View>

      <Space size={40} />
      <View>
        {isDownloading ? (
          <VedaLoadingItem size={80} />
        ) : (
          <View css={{ cursor: 'pointer' }} onClick={onDownload}>
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M40 80C17.9324 80 0 62.0676 0 40C0 17.9324 17.9324 0 40 0C62.0676 0 80 17.9324 80 40C80 62.0676 62.0676 80 40 80Z"
                fill="#2AB885"
              />
              <path
                d="M53.8357 51.7891C52.599 51.7891 51.5942 50.7843 51.5942 49.5862C51.5942 48.3495 52.599 47.3447 53.8357 47.3447C56.3478 47.3447 58.3961 45.2964 58.3961 42.7843C58.3961 41.3544 57.7391 40.0403 56.6183 39.1514C55.9227 38.6104 55.5748 37.7215 55.8067 36.8326C55.9613 36.2915 56 35.7891 56 35.2867C56 31.7312 53.1401 28.8326 49.5845 28.8326C48.4637 28.8326 47.3816 29.1418 46.4154 29.6828C45.4492 30.2239 44.2125 29.9534 43.5555 29.0645C41.5845 26.4751 38.6087 25.0065 35.3623 25.0065C29.6811 25.0065 25.0821 29.6056 25.0821 35.2867C25.0821 35.6474 25.1079 36.0597 25.1594 36.5234C25.2367 37.451 24.8116 38.3012 24 38.765C22.5314 39.5379 21.6038 41.0838 21.6038 42.7843C21.6038 45.2964 23.6521 47.3447 26.1642 47.3447C27.4009 47.3447 28.4058 48.3495 28.4058 49.5862C28.4058 50.7843 27.4009 51.7891 26.1642 51.7891C21.1787 51.7891 17.1207 47.7312 17.1207 42.7843C17.1207 39.9631 18.4347 37.335 20.599 35.6345C20.599 35.5057 20.599 35.3898 20.599 35.2867C20.599 27.1321 27.2077 20.5234 35.3623 20.5234C39.4202 20.5234 43.1691 22.1466 45.9517 25.0065C47.1111 24.5814 48.3478 24.3882 49.5845 24.3882C55.5748 24.3882 60.4831 29.2577 60.4831 35.2867C60.4831 35.699 60.4573 36.1241 60.4058 36.5621C61.9903 38.2239 62.8792 40.4268 62.8792 42.7843C62.8792 47.7312 58.8212 51.7891 53.8357 51.7891Z"
                fill="white"
              />
              <path
                d="M48.3092 48.9664C48.0773 48.4254 47.4976 48.0389 46.8792 48.0389H42.3189V39.6138C42.3189 38.3384 41.2754 37.2949 40 37.2949C38.7247 37.2949 37.6812 38.3384 37.6812 39.6138V48.0389H33.1208C32.5024 48.0389 31.9227 48.4254 31.6908 48.9664C31.459 49.5461 31.5363 50.2031 31.9614 50.6283L38.8406 58.2804C39.1498 58.5896 39.5749 58.7828 40 58.7828C40.4251 58.7828 40.8503 58.5896 41.1594 58.2804L48.0387 50.6283C48.4638 50.2031 48.5411 49.5461 48.3092 48.9664Z"
                fill="white"
              />
            </svg>
          </View>
        )}

        <Space size={15} />
        <Text size={16} color="primary" css={{ cursor: 'pointer', fontWeight: '500', textDecoration: 'underline' }} onClick={onDownload}>
          Click here to download theme.
        </Text>
        <Text size={16} color="secondary" css={{ cursor: 'pointer', fontWeight: '500' }} onClick={onSkip}>
          Skip, I have already installed this theme before.
        </Text>
      </View>
    </Text>
  );
};
