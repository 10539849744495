import { watchCreateCustomerPage } from './watchCreateCustomerPage';
import { watchUpdateStatusCustomerPage } from './watchUpdateStatusCustomerPage';
import { watchDeleteCustomerPages } from './watchDeleteCustomerPage';
import { watchGetCustomerPages } from './watchGetCustomerPages';
import { watchLoadMoreCustomerPages } from './watchLoadMoreCustomerPages';
import { watchDuplicateCustomerPage } from 'containers/Admin/PageBuilder/CustomerPages/store/sagas/watchDuplicateCustomerPage';

export const sagasCustomerPages = [
  watchCreateCustomerPage,
  watchUpdateStatusCustomerPage,
  watchDeleteCustomerPages,
  watchGetCustomerPages,
  watchLoadMoreCustomerPages,
  watchDuplicateCustomerPage,
];
