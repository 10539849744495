import { watchPasteStyleFlow } from 'containers/BuilderPage/store/copyStyle/sagas';
import BuilderPage from './BuilderPage';
import { watchUpdateVersionAddonFlow } from './components/UpdateVersion/flows/watchUpdateVersionAddonFlow';
import { watchUpdateVersionMegaMenuFlow } from './components/UpdateVersion/flows/watchUpdateVersionMenuFlow';
import { watchUpdateVersionSectionFlow } from './components/UpdateVersion/flows/watchUpdateVersionSectionFlow';
import { watchAddonPositionStart } from './store/addonPosition/saga';
import {
  watchCancelAtomAddon,
  watchCancelAtomMegaMenu,
  watchCancelDraftAddon,
  watchCancelDraftMegaMenu,
  watchCancelSaveAtomSection,
  watchCancelSaveDraftSection,
  watchCancelSavePageAtom,
  watchCancelSavePageClient,
  watchCancelSavePageDraft,
  watchSaveAtomAddon,
  watchSaveAtomMegaMenu,
  watchSaveAtomSection,
  watchSaveDraftAddon,
  watchSaveDraftMegaMenu,
  watchSaveDraftSection,
  watchSavePageAtom,
  watchSavePageClient,
  watchSavePageDraft,
  watchSaveTheme,
} from './store/saveForBuilder/saga';
import { watchSelectPage } from './store/selectPage/saga';
import { watchDeleteAllSectionsFlow } from './store/toolbarActions/sagaDeleteAllSectionsFlow';
import { watchSectionDeleteFlow } from './store/toolbarActions/sagaDeleteFlow';
import { watchCancelModalDuplicateFlow, watchSectionDuplicateFlow, watchSectionModalDuplicateFlow } from './store/toolbarActions/sagaDuplicateFlow';
import { watchSortSectionsFlow } from './store/toolbarActions/sagaSortSectionsFlow';
import {
  watchCancelSaveHeaderAndFooterInBuilder,
  watchSaveHeaderAndFooterInBuilder,
} from 'containers/BuilderPage/store/saveForBuilder/watchSaveHeaderAndFooterInBuilder';

const sagasBuilderPage = [
  watchAddonPositionStart,
  watchSectionDuplicateFlow,
  watchSectionModalDuplicateFlow,
  watchCancelModalDuplicateFlow,
  watchSectionDeleteFlow,
  watchSelectPage,
  watchSaveAtomSection,
  watchSaveTheme,
  watchSavePageDraft,
  watchSavePageAtom,
  watchSaveAtomMegaMenu,
  watchSaveDraftSection,
  watchSaveDraftAddon,
  watchSaveAtomAddon,
  watchSaveDraftMegaMenu,
  watchSavePageClient,
  watchUpdateVersionSectionFlow,
  watchUpdateVersionAddonFlow,
  watchCancelSaveAtomSection,
  watchCancelAtomAddon,
  watchCancelAtomMegaMenu,
  watchCancelDraftAddon,
  watchCancelDraftMegaMenu,
  watchCancelSaveDraftSection,
  watchCancelSavePageAtom,
  watchCancelSavePageClient,
  watchCancelSavePageDraft,
  watchDeleteAllSectionsFlow,
  watchSortSectionsFlow,
  watchUpdateVersionMegaMenuFlow,
  watchPasteStyleFlow,
  watchSaveHeaderAndFooterInBuilder,
  watchCancelSaveHeaderAndFooterInBuilder,
];

export { BuilderPage, sagasBuilderPage };
export default BuilderPage;
