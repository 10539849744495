import { addonApiController } from 'services/AddonService';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const getDrafts = async ({ categoryId, label }: { categoryId?: string; label?: string }) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    const response = await addonApiController.atom.adminApi.addons.getDraftsOfDev({ type: 'GET FIRST PAGE', categoryId, label });
    return response.info;
  }
  if (role === 'dev' || role === 'support') {
    const response = await addonApiController.atom.devApi.addons.getDraftsOfDev({ type: 'GET FIRST PAGE', categoryId, label });
    return response.info;
  }
  throw new RoleException();
};

export const loadMoreDrafts = async ({ categoryId, cursor, label }: { categoryId?: string; cursor: string; label?: string }) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    const response = await addonApiController.atom.adminApi.addons.getDraftsOfDev({ type: 'LOADMORE', categoryId, lastCursor: cursor, label });
    return response.info;
  }
  if (role === 'dev' || role === 'support') {
    const response = await addonApiController.atom.devApi.addons.getDraftsOfDev({ type: 'LOADMORE', categoryId, lastCursor: cursor, label });
    return response.info;
  }
  throw new RoleException();
};
