import { renewVersion } from 'utils/CacheControl/VedaClientCacheControl';
import { pageApis } from '../apis';

interface DeletePageAtom {
  commandId: string;
}

export const deletePageClient = async ({ commandId }: DeletePageAtom) => {
  const response = await pageApis.vedaApplication.userApi.page.deleteClient({ commandId });
  renewVersion();
  return response;
};
