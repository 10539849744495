import { put, retry, takeLatest } from 'redux-saga/effects';
import { addonService } from 'services/AddonService';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { createAdminAddonsCategory, getAdminAddonsCategory } from '../../actions';

function* handleGet(_: ReturnType<typeof getAdminAddonsCategory.request>) {
  try {
    const response: Awaited<ReturnType<typeof addonService.categories.getCategoriesOfAtom>> = yield retry(
      3,
      1000,
      addonService.categories.getCategoriesOfAtom,
    );

    yield put(getAdminAddonsCategory.success({ data: response }));
  } catch {
    yield put(getAdminAddonsCategory.failure(undefined));
  }
}

export function* watchGetAdminAddonCategory() {
  yield takeLatest(getActionType(getAdminAddonsCategory.request), handleGet);
}

function* createAdminCate({ payload: { description, name } }: ReturnType<typeof createAdminAddonsCategory.request>) {
  try {
    const response: Awaited<ReturnType<typeof addonService.categories.createCategoryOfAtom>> = yield retry(
      3,
      1000,
      addonService.categories.createCategoryOfAtom,
      {
        description,
        name,
      },
    );
    yield put(
      createAdminAddonsCategory.success({
        commandId: response.info.commandId,
        name: response.info.name,
        description: response.info.description,
        count: 0,
      }),
    );
    notifyAxiosHandler.handleSuccess(response.message);
  } catch {
    yield put(createAdminAddonsCategory.failure(undefined));
  }
}

export function* watchCreateAdminAddonCategory() {
  yield takeLatest(getActionType(createAdminAddonsCategory.request), createAdminCate);
}
