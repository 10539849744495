import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BE_PageProduct, RequestGetPagesClient } from 'services/PageService/VedaApplication/types';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface GetProducts extends RequestGetPagesClient {
  type: 'GET FIRST PAGE';
  categoryId?: string;
  saved?: boolean;
  size: number;
  isStickyOnly?: boolean;
  planFilter?: string;
  useCases?: string[];
}

interface LoadmoreProducts extends RequestGetPagesClient {
  type: 'LOADMORE';
  categoryId?: string;
  saved?: boolean;
  cursor: string;
  isStickyOnly?: boolean;
  size: number;
  planFilter?: string;
  useCases?: string[];
}

interface ResponseSuccess {
  message: string;
  info: BE_PageProduct[];
}

/** API để lấy về các page product */
export const getProducts = async ({
  type,
  cacheVersion,
  categoryId,
  pageType,
  enable,
  label,
  saved,
  size,
  isStickyOnly,
  planFilter,
  useCases,
  ...params
}: GetProducts | LoadmoreProducts) => {
  let requestConfig: AxiosRequestConfig = {};
  if (type === 'GET FIRST PAGE') {
    requestConfig = {
      url: `${baseUrl}`,
      params: {
        type: pageType,
        categoryId: categoryId === '' ? undefined : categoryId,
        enable,
        label,
        saved,
        group: 'GENERAL',
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
        size,
        stickyOnly: !!isStickyOnly ? isStickyOnly : undefined,
        planFilter: planFilter === '' ? undefined : planFilter,
        usecases: useCases?.join(','),
      },
    };
  } else {
    const { cursor } = params as LoadmoreProducts;
    requestConfig = {
      url: `${baseUrl}/search`,
      params: {
        after: cursor,
        type: pageType,
        categoryId: categoryId === '' ? undefined : categoryId,
        enable,
        label,
        saved,
        group: 'GENERAL',
        size,
        planFilter: planFilter === '' ? undefined : planFilter,
        usecases: useCases?.join(','),
      },
    };
  }
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request(requestConfig);
  return response.data;
};
