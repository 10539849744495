import { sectionApiController } from 'services/SectionService';
import { adapterGetSection } from 'services/SectionService/Adapters/adapterGetSection';
import { ProductSection } from 'types/Sections';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const installClientSection = async (commandId: string, productSection: ProductSection) => {
  const { lastCache } = getUserInfo();
  const response = await sectionApiController.atom.publishApi.sections.getAtom({ commandId, cacheVersion: lastCache });

  if (response.status === 200) {
    const transformData = adapterGetSection(response.data.info) as ProductSection;

    return {
      data: {
        ...transformData,
        commandId: '',
        parentCommandId: response.data.info.commandId,
        downloadedCount: productSection.downloadedCount,
      },
      message: response.data.message,
    };
  } else {
    return {
      data: undefined,
      message: response.data.message,
    };
  }
};
