import { addonApiController } from 'services/AddonService';
import { ProductAddon } from 'types/Addons';
import { getCurrentVersion } from 'utils/CacheControl/VedaClientCacheControl';

interface GetAddonsOfThemeClient {
  addonCommandIds: string[];
}

export const getAddonsOfThemeClient = async ({ addonCommandIds }: GetAddonsOfThemeClient): Promise<ProductAddon[]> => {
  if (!addonCommandIds.length) {
    return [];
  }
  const responses = await addonApiController.client.clientApi.addon.getClients({
    commandIds: addonCommandIds,
    cacheVersion: getCurrentVersion().getTime(),
  });
  return responses;
};
