import { Highlight } from 'components/Highlight';
import { Empty } from 'antd';
import { FC } from 'react';
import { View, Space, useTheme } from 'wiloke-react-core';

interface StepInstallThemePendingProps {
  themeName?: string;
  onRedirect: () => void;
}

export const StepInstallThemePendingEnvato: FC<StepInstallThemePendingProps> = ({ themeName, onRedirect }) => {
  const { colors } = useTheme();
  return (
    <View css={{ fontSize: '16px' }}>
      <Space size={30} />
      <Empty description="" />
      <Space size={30} />
      <Highlight highlightCss={{ color: colors.primary, fontWeight: '500' }} query={themeName ?? ''}>
        {`Haven't downloaded theme ${themeName ?? ''} from Themeforest?`}
      </Highlight>
      <View>
        Click{' '}
        <View tagName="span" color="primary" css={{ fontWeight: '500', cursor: 'pointer' }} onClick={onRedirect}>
          here
        </View>{' '}
        to known how to download.
      </View>
    </View>
  );
};
