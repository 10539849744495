import { MyModal } from '@wiloke/ui';
import { planSelector, useSetVisibleSubscription } from 'containers/Admin/PlanManagement/store/slicePlan';
import { useSelector } from 'react-redux';
import { ActivityIndicator, FontAwesome, Space, Styles, View } from 'wiloke-react-core';

const modalContent: Styles = {
  textAlign: 'center',
};

export const ModalSubscription = () => {
  const { subscription, visibleSubscription } = useSelector(planSelector);
  const setVisibleSubscription = useSetVisibleSubscription();

  return (
    <MyModal
      bodyCss={{ minHeight: '300px' }}
      isVisible={visibleSubscription}
      depsHeightRecalculation={subscription.message}
      cancelText=""
      okText=""
      onCancel={() => {
        if (subscription.status === 'success') {
          window.location.href = 'https://app.vedabuilder.com/pricing';
          setVisibleSubscription(false);
        } else {
          setVisibleSubscription(false);
        }
      }}
    >
      <View css={modalContent}>
        <Space size={20} />
        {subscription.status === 'loading' ? (
          <ActivityIndicator size={50} />
        ) : subscription.status === 'success' ? (
          <FontAwesome type="fas" name="badge-check" size={100} color="success" />
        ) : (
          <FontAwesome type="fas" name="exclamation-circle" size={100} color="danger" />
        )}

        <Space size={20} />
        <View css={{ wordBreak: 'break-word', fontSize: '16px' }}>{subscription.message}</View>
      </View>
    </MyModal>
  );
};
