import { call } from 'redux-saga/effects';
import { handleSaveInBuilderPage } from 'services/ShopifyConnection';
import { syncTranslation, SyncTranslationResult } from './syncTranslation';

export interface SyncTranslations {
  syncTranslationsParams: ReturnType<typeof handleSaveInBuilderPage>['syncTranslationsParams'];
}

export interface SyncTranslationsResult {
  statusSyncTranslations: SyncFulfillStatus;
  isNeedIgnoreReportError: boolean;
}

export function* syncTranslations({ syncTranslationsParams }: SyncTranslations) {
  for (const syncTranslationParams of syncTranslationsParams) {
    const { statusSyncTranslation, isNeedIgnoreReportError }: SyncTranslationResult = yield call(syncTranslation, { syncTranslationParams });
    if (statusSyncTranslation !== 'success') {
      return {
        statusSyncTranslations: 'failure',
        isNeedIgnoreReportError,
      } as SyncTranslationsResult;
    }
  }
  return { statusSyncTranslations: 'success', isNeedIgnoreReportError: false } as SyncTranslationsResult;
}
