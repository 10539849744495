import { MyModal } from '@wiloke/ui';
import Field from 'components/Field';
import TextInput from 'components/TextInput';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { themeBuilderSelector } from 'store/selectors';
import { Space, View } from 'wiloke-react-core';
import { useSetErrorMessagePurchaseCode, useSetVisible402PurchaseCode, useValidatePurchaseCode } from '../actions';

export const Modal402PurchaseCode = () => {
  const { visible402PurchaseCode, validatePurchaseCodeStatus, message } = useSelector(themeBuilderSelector.templates);

  const setVisible = useSetVisible402PurchaseCode();
  const validatePurchaseCode = useValidatePurchaseCode();

  const [purchaseCode, setPurchaseCode] = useState('');
  const [website, setWebsite] = useState('');
  const setErrorMessagePurchaseCode = useSetErrorMessagePurchaseCode();

  const handleOk = () => {
    if (purchaseCode && website) {
      validatePurchaseCode.request({
        purchaseCode,
        themeImportId: '',
        onFulfill: () => {
          setWebsite('');
          setPurchaseCode('');
        },
      });
    } else {
    }
  };

  return (
    <MyModal
      onCancel={() => {
        setVisible(false);
      }}
      onOk={handleOk}
      headerText="Purchase code"
      isVisible={visible402PurchaseCode}
      isLoading={validatePurchaseCodeStatus === 'loading'}
    >
      <Field label="Website">
        <TextInput value={website} block borderColor="gray3" onValueChange={setWebsite} />
      </Field>

      <Field
        label="Purchase code"
        description={
          <View tagName="a" href="https://help.market.envato.com/hc/en-us/articles/202822600-Where-Is-My-Purchase-Code-" target="_blank">
            How can I get purchase code
          </View>
        }
      >
        <TextInput
          value={purchaseCode}
          block
          borderColor="gray3"
          onValueChange={val => {
            setPurchaseCode(val);
            setErrorMessagePurchaseCode('');
          }}
        />
        <Space size={4} />
        <View color="danger">{message}</View>
      </Field>
    </MyModal>
  );
};
