import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import { SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client } from 'types/Sections';
import { ToRequiredKeys } from 'utils';
import fetchApi from 'utils/functions/fetchAPI';

interface UpdateClient {
  /** Dữ liệu sẽ apply vào record */
  section: SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client;
}

interface ResponseSuccess {
  info: ToRequiredKeys<SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client, 'commandId'>;
  message: string;
}

/** API được sử dụng để client update section */
export const updateClient = async ({ section }: UpdateClient) => {
  const { commandId } = section;
  const response: AxiosResponse<ResponseSuccess> = await fetchApi.request({
    url: `${configureApp.clientBaseUrl}/me/${configureApp.endpoint.sections}/${commandId}`,
    method: 'put',
    data: section,
  });

  return response.data;
};
