import { Reducers } from 'store/configureStore';
import getPageInfo from './functions/getInfo';

export const getShopifyThemeId = (): string | undefined => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { store } = require('store/configureStore');
    const state = store.getState() as Reducers;
    const themeVedaId = state.global.auth.themeVedaId;
    const shopifyThemeId = getPageInfo('shopifyThemeId');
    return shopifyThemeId ?? themeVedaId;
  } catch {
    try {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const { store } = require('store/configureStore');
      const state = store.getState() as Reducers;
      return state.global.auth.themeVedaId;
    } catch {
      try {
        const shopifyThemeId = getPageInfo('shopifyThemeId');
        return shopifyThemeId;
      } catch {
        return undefined;
      }
    }
  }
};
