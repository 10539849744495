import { AxiosError } from 'axios';
import { put, retry, SagaReturnType, select, takeLatest } from 'redux-saga/effects';
import { getActionType } from 'wiloke-react-core/utils';
import { getSavedSections, loadMoreSavedSections, setSavedSectionCategories } from 'containers/ChooseTemplate/store/actions';
import { sectionService } from 'services/SectionService';
import { sectionsSelector } from 'store/selectors';
import { isEmpty } from 'ramda';
import { ProductSection } from 'types/Sections';
import { removeDuplicateByKey, removeDuplicate } from '@wiloke/functions';

function* handleGetSections({ payload: { categories } }: ReturnType<typeof getSavedSections.request>) {
  try {
    const { categories: productSectionCategories }: ReturnType<typeof sectionsSelector.categories> = yield select(sectionsSelector.categories);
    const { categories: savedCategories }: ReturnType<typeof sectionsSelector.savedSections> = yield select(sectionsSelector.savedSections);

    const data: SagaReturnType<typeof sectionService.sections.getFavorites> = yield retry(3, 1000, sectionService.sections.getFavorites, {
      categories,
    });
    if (!isEmpty(data) && !isEmpty(productSectionCategories)) {
      let result: any[] = [];
      for (const section of data) {
        result.push(section.categories);
      }
      result = removeDuplicate(result.flat().filter(Boolean));

      if (!isEmpty(result)) {
        result = removeDuplicateByKey(
          [
            { commandId: 'all', name: 'All', description: 'All', count: 0 },
            ...productSectionCategories.filter(item => result.includes(item.name)),
            ...savedCategories,
          ].filter(Boolean),
          'commandId',
        );

        yield put(setSavedSectionCategories(result));
      }
    }
    yield put(getSavedSections.success({ data: (data as unknown) as ProductSection[], hasNextPage: data.length > 0 ? true : false }));
  } catch (error) {
    const err = error as AxiosError<{ message: string }>;
    const message = err.response?.data?.message || '';
    console.log(err.response);
    yield put(getSavedSections.failure({ message }));
  }
}

function* handleLoadMore({ payload: { categories, cursor } }: ReturnType<typeof loadMoreSavedSections.request>) {
  try {
    const response: SagaReturnType<typeof sectionService.sections.loadMoreFavorites> = yield retry(
      3,
      1000,
      sectionService.sections.loadMoreFavorites,
      {
        categories,
        cursor,
      },
    );

    yield put(loadMoreSavedSections.success({ data: (response as unknown) as ProductSection[], hasNextPage: response.length > 0 ? true : false }));
  } catch (error) {
    yield put(loadMoreSavedSections.failure(undefined));
  }
}

export function* watchGetSavedSections() {
  yield takeLatest(getActionType(getSavedSections.request), handleGetSections);
}

export function* watchLoadMoreSavedSections() {
  yield takeLatest(getActionType(loadMoreSavedSections.request), handleLoadMore);
}
