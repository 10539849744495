import axios, { AxiosResponse } from 'axios';
import { VedaInstruction } from 'types/Instruction';

export const getPageInstructionVideo = async () => {
  interface Success {
    data: VedaInstruction[];
    message: string;
    status: string;
  }

  const response: AxiosResponse<Success> = await axios.get(`https://myshopkit.app/wp-json/ebase/v1/veda-instructions`);

  return response.data;
};
