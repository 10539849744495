import { SagaReturnType, all, put, retry, takeLatest } from 'redux-saga/effects';
import { getActionType } from 'wiloke-react-core/utils';
import { getMetaDataOfRecord as actionGetMetaDataOfRecord } from '../actions';
import { getPageAtomWithSectionAtomCommandId } from 'packages/ListingSectionsUpdatable/services/PageAtom';
import { getThemeAtomWithSectionAtomCommandId } from 'packages/ListingSectionsUpdatable/services/ThemeAtom';
import { SectionInfo } from 'packages/ListingSectionsUpdatable/types/Record';

function* handleGetMetaDataOfRecord({ payload }: ReturnType<typeof actionGetMetaDataOfRecord.request>) {
  const { sectionAtomCommandId } = payload;
  try {
    const [pageServiceResponse, themeServiceResponse]: [
      SagaReturnType<typeof getPageAtomWithSectionAtomCommandId>,
      SagaReturnType<typeof getThemeAtomWithSectionAtomCommandId>,
    ] = yield all([
      retry(3, 1000, getPageAtomWithSectionAtomCommandId, { sectionAtomCommandId, cacheVersion: null }),
      retry(3, 1000, getThemeAtomWithSectionAtomCommandId, { sectionAtomCommandId, cacheVersion: null }),
    ]);

    yield put(
      actionGetMetaDataOfRecord.success({
        sectionAtomCommandId,
        data: {
          sectionsInfo: [...(pageServiceResponse.info ?? []), ...(themeServiceResponse.info ?? [])].reduce<SectionInfo[]>((result, section) => {
            if (section.pageInfo) {
              result.push(section as SectionInfo);
            }
            return result;
          }, []),
        },
      }),
    );
  } catch {
    yield put(actionGetMetaDataOfRecord.failure(undefined));
  }
}

export function* watchGetMetaDataOfRecord() {
  yield takeLatest(getActionType(actionGetMetaDataOfRecord.request), handleGetMetaDataOfRecord);
}
