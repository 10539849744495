import { css, Theme } from 'wiloke-react-core';

export const container = css`
  min-height: 100vh;
  display: flex;
`;
export const body = css`
  padding: 25px;
  min-height: 100%;
`;
export const content = css`
  max-width: 500px;
  margin-top: 20px;
`;
export const menu = ({ colors }: Theme) => css`
  width: 220px;
  background-color: ${colors.light};
  border-right: 1px solid ${colors.gray2};
  min-width: 220px;
  max-width: 220px;
`;
export const anchor = ({ fonts, colors }: Theme) => css`
  display: block;
  padding: 10px 15px;
  background-color: ${colors.light};
  border-bottom: 1px solid ${colors.gray2};
  font-size: 13px;
  font-family: ${fonts.secondary};
  color: ${colors.gray9};
  transition: 0.3s;
  &:hover {
    color: ${colors.primary};
  }
`;

export const box = css`
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  margin: 0 auto 20px;
`;
