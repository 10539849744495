export const AdvancedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 13" fill="none">
      <path
        d="M6.74772 0.983983L8.15044 3.94993L11.2863 4.42647C11.9687 4.53071 12.2421 5.40685 11.7476 5.91069L9.47945 8.21892L10.0144 11.4802C10.1309 12.1901 9.41526 12.7336 8.80425 12.3985L6.00119 10.8572L3.19575 12.3985C2.58474 12.7336 1.86911 12.1901 1.98561 11.4802L2.52292 8.21892L0.252423 5.91069C-0.242095 5.40685 0.0313163 4.53071 0.713655 4.42647L3.84956 3.94993L5.25228 0.983983C5.55898 0.338672 6.44102 0.338672 6.74772 0.983983Z"
        fill="white"
      />
    </svg>
  );
};
