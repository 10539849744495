import { ThemeGeneral } from 'types/Theme';
import { renewVersion } from 'utils/CacheControl/VedaClientCacheControl';
import { handleGlobalTranslationsNColorSchemesInThemeClient } from '../Adapter/handleGlobalTranslationsNColorSchemes';
import { themeApis } from '../apis';

interface ActiveThemeClientService {
  commandId: string;
}

export const activeThemeClientService = async ({ commandId }: ActiveThemeClientService): Promise<ThemeGeneral> => {
  const response = await themeApis.vedaApplication.userApi.theme.activeClient({ commandId });
  renewVersion();
  return handleGlobalTranslationsNColorSchemesInThemeClient(response.info);
};
