import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  colorSchemesSelector,
  cssVariablesSelector,
  pageSectionsSelector,
  sectionIdActiveSelector,
  sectionIdCodeVisibleSelector,
  themeVersionSelector,
} from 'store/selectors';
import { CssColorVariable } from 'types/PresetStyles';
import { getThemeVersion } from 'utils/functions/getThemeVersion';

export const useGetColorsToColorPicker2 = () => {
  const cssVariables = useSelector(cssVariablesSelector);
  const sectionIdActive = useSelector(sectionIdActiveSelector);
  const sectionIdCodeVisible = useSelector(sectionIdCodeVisibleSelector);
  const pageSections = useSelector(pageSectionsSelector);

  const themeVersion = useSelector(themeVersionSelector);
  const themeV2 = getThemeVersion(themeVersion) === '>=2.0.0';

  const sectionActive = pageSections.find(section => {
    return sectionIdCodeVisible ? section.id === sectionIdCodeVisible : section.id === sectionIdActive;
  });
  const { colorSchemes } = useSelector(colorSchemesSelector);

  const schemeOfSection = useMemo(() => {
    return sectionActive?.data.settings.find(setting => setting.type === 'color_scheme');
  }, [sectionActive?.data.settings]);

  const colors: CssColorVariable[] = useMemo(() => {
    if (!sectionActive) {
      return [];
    }
    if (themeV2 && sectionActive && colorSchemes.length) {
      const children = schemeOfSection?.children ?? 'veda-color-default';
      return colorSchemes.find(scheme => scheme.cssClassName === children)?.colors ?? [];
    }
    // Để các khách cũ vẫn chạy được version cũ
    return cssVariables.colors;
  }, [colorSchemes, cssVariables.colors, schemeOfSection?.children, sectionActive, themeV2]);

  return colors;
};
