import { SyncShopifyMessage } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { createAsyncAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';

export const restoreThemeToDefault = createAsyncAction([
  '@Global/restoreThemeToDefault/request',
  '@Global/restoreThemeToDefault/success',
  '@Global/restoreThemeToDefault/failure',
])<{ eventType: SyncShopifyMessage['eventType']; onFulfill?: () => void }, undefined, undefined>();

export const useRestoreThemeToDefault = createDispatchAsyncAction(restoreThemeToDefault);
