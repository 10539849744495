import { checkPurchaseCodeIsExist } from 'containers/Admin/ThemeBuilder/ThemeTemplates/actions';
import { put, retry, takeLatest } from 'redux-saga/effects';

import { checkPurchaseCodeIsExistRequest } from 'services/UserService/Logic/checkPurchaseCodeIsExist';
import { getActionType } from 'wiloke-react-core/utils';

function* handleAction({ payload: { envatoThemeId, onFulfill } }: ReturnType<typeof checkPurchaseCodeIsExist.request>) {
  try {
    yield retry(3, 1000, checkPurchaseCodeIsExistRequest, {
      envatoThemeId,
    });

    yield put(checkPurchaseCodeIsExist.success(undefined));
    onFulfill?.();
  } catch (error) {
    yield put(checkPurchaseCodeIsExist.failure(undefined));
  }
}

export function* watchCheckPurchaseCodeIsExist() {
  yield takeLatest(getActionType(checkPurchaseCodeIsExist.request), handleAction);
}
