import { storage } from '@wiloke/functions';
import { isValidDate } from 'utils/isValidDate';

const LOCAL_STORAGE_KEY = 'veda-client-cache-control';

export const renewVersion = () => {
  const version = Date.now();
  storage.setItem(LOCAL_STORAGE_KEY, version.toString());
  return storage.getItem(LOCAL_STORAGE_KEY);
};

export const getCurrentVersion = () => {
  const value = storage.getItem(LOCAL_STORAGE_KEY);
  if (!!value && isValidDate(Number(value))) {
    try {
      const version = new Date(Number(value));
      return version;
    } catch {
      const version = new Date();
      storage.setItem(LOCAL_STORAGE_KEY, version.getTime().toString());
      return version;
    }
  } else {
    const version = new Date();
    storage.setItem(LOCAL_STORAGE_KEY, version.getTime().toString());
    return version;
  }
};
