import { call, cancel, fork, put, select } from '@redux-saga/core/effects';
import { MODAL_REPORT_INITIALIZATION_SESSION_BUILDER_ERROR } from 'containers/ModalReportAfterError/const';
import { ModalReportAfterError } from 'containers/ModalReportAfterError/ModalReportAfterError';
import { Task } from 'redux-saga';
import { take } from 'redux-saga/effects';
import { megaMenusOfHeaderFooterSelector } from 'store/global/megaMenusOfHeaderFooter';
import { addMultiAddons } from 'store/global/themeAddons';
import { setIsSaveAddons } from 'store/reducers/sliceOriginThemeAddons';
import { themeAddonsSelector, themeHeaderFooterSelector } from 'store/selectors';
import { i18n } from 'translation';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { getActionType } from 'wiloke-react-core/utils';
import { getPage, setThemeAddonsToPages, setThemeHeaderFooterToPages, setMegaMenusOfHeaderFooterToPages } from '../../actions/actionPages';
import { handleGetPageAtom } from './PageAtom/handleGetPageAtom';
import { handleGetPageClient } from './PageClient/handleGetPageClient';
import { handleGetPageDraft } from './PageDraft/handleGetPageDraft';
import { handleGetLiquidSnippets } from 'store/global/globalSnippets/sagas/watchGetLiquidSnippets';

// get page info
function* handleResult(params: ReturnType<typeof getPage.request>) {
  const { variant, onDisconnectSocketEarly, forPreview } = params.payload;
  const { role } = getUserInfo();
  try {
    yield call(handleGetLiquidSnippets, { type: '@Global/getLiquidSnippets/request', payload: {} });
    yield put(setIsSaveAddons(false));
    if (variant === 'Atom') {
      onDisconnectSocketEarly();
      yield call(handleGetPageAtom, params);
      const { footers, headers }: ReturnType<typeof themeHeaderFooterSelector> = yield select(themeHeaderFooterSelector);
      yield put(setThemeHeaderFooterToPages({ footers, headers }));
      return;
    }
    if (variant === 'Draft') {
      onDisconnectSocketEarly();
      yield call(handleGetPageDraft, params);
      const { footers, headers }: ReturnType<typeof themeHeaderFooterSelector> = yield select(themeHeaderFooterSelector);
      yield put(setThemeHeaderFooterToPages({ footers, headers }));
      return;
    }

    if (variant === 'Client') {
      yield call(handleGetPageClient, params);

      if (!forPreview) {
        const { data: addons }: ReturnType<typeof themeAddonsSelector> = yield select(themeAddonsSelector);
        const themeAddonsBody = addons.map(item => item.body).filter(Boolean) ?? [];

        yield put(addMultiAddons({ addons }));
        yield put(setThemeAddonsToPages(themeAddonsBody));
      }

      const { footers, headers }: ReturnType<typeof themeHeaderFooterSelector> = yield select(themeHeaderFooterSelector);
      const { megaMenusOfHeaderFooter }: ReturnType<typeof megaMenusOfHeaderFooterSelector> = yield select(megaMenusOfHeaderFooterSelector);

      yield put(setThemeHeaderFooterToPages({ footers, headers }));
      yield put(setMegaMenusOfHeaderFooterToPages({ megaMenus: megaMenusOfHeaderFooter }));
      return;
    }

    if (role === 'admin') {
      onDisconnectSocketEarly();
      yield call(handleGetPageAtom, params);
      const { footers, headers }: ReturnType<typeof themeHeaderFooterSelector> = yield select(themeHeaderFooterSelector);
      yield put(setThemeHeaderFooterToPages({ footers, headers }));
      return;
    }

    if (role === 'dev') {
      onDisconnectSocketEarly();
      yield call(handleGetPageDraft, params);
      const { footers, headers }: ReturnType<typeof themeHeaderFooterSelector> = yield select(themeHeaderFooterSelector);
      yield put(setThemeHeaderFooterToPages({ footers, headers }));
      return;
    }

    if (role === 'user') {
      yield call(handleGetPageClient, params);

      if (!forPreview) {
        const { data: addons }: ReturnType<typeof themeAddonsSelector> = yield select(themeAddonsSelector);
        const themeAddonsBody = addons.map(item => item.body).filter(Boolean) ?? [];

        yield put(addMultiAddons({ addons }));
        yield put(setThemeAddonsToPages(themeAddonsBody));
      }

      const { footers, headers }: ReturnType<typeof themeHeaderFooterSelector> = yield select(themeHeaderFooterSelector);
      const { megaMenusOfHeaderFooter }: ReturnType<typeof megaMenusOfHeaderFooterSelector> = yield select(megaMenusOfHeaderFooterSelector);

      yield put(setThemeHeaderFooterToPages({ footers, headers }));
      yield put(setMegaMenusOfHeaderFooterToPages({ megaMenus: megaMenusOfHeaderFooter }));
      return;
    }
  } catch (error) {
    ModalReportAfterError.getActions(MODAL_REPORT_INITIALIZATION_SESSION_BUILDER_ERROR).report({
      cause: i18n.t('ModalReportAfterError.error_cause.initialization_session_builder'),
      description: error instanceof Error ? error.message : '',
    });
  }
}

let task: Task | undefined;

export function* watchResult() {
  // yield takeLatest(getActionType(getPage.request), handleResult);
  while (true) {
    const requestAction: ReturnType<typeof getPage.request> = yield take(getActionType(getPage.request));
    if (!!task) {
      yield cancel(task);
    }
    task = yield fork(handleResult, requestAction);
  }
}

export function* watchCancelGetPage() {
  while (true) {
    const cancelAction: ReturnType<typeof getPage.cancel> = yield take(getActionType(getPage.cancel));
    if (cancelAction.type === '@Global/getPage/cancel' && !!task) {
      yield cancel(task);
    }
  }
}
