import { createPartner } from 'containers/Admin/PartnerManagement/store/actions';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { createPartnerAPI } from 'services/UserService/Logic/createPartner';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';

function* handleAction({ payload: { data, onFulfilled } }: ReturnType<typeof createPartner.request>) {
  try {
    const response: SagaReturnType<typeof createPartnerAPI> = yield retry(3, 1000, createPartnerAPI, data);
    yield put(createPartner.success({ data: response.info }));
    notifyAxiosHandler.handleSuccess(response.message);
    onFulfilled?.();
  } catch (error) {
    notifyAxiosHandler.handleError(error as any);
    yield put(createPartner.failure(undefined));
  }
}

export function* watchCreatePartnerApps() {
  yield takeLatest(getActionType(createPartner.request), handleAction);
}
