import { handleWaitForSocketOfSyncThemeByResultOfExtractThemeToFileForSync } from 'packages/SyncThemeByResultOfExtractThemeToFileForSync/hooks/useSocketForSyncThemeByResultOfExtractThemeToFileForSync';
import { call, retry, SagaReturnType } from 'redux-saga/effects';
import { handleExtractResultForForceSync, shopifyConnectionService } from 'services/ShopifyConnection';

export interface SyncThemeSchemaNSettings {
  themeSchemaNSettingsParams: Exclude<ReturnType<typeof handleExtractResultForForceSync>['themeSchemaNSettingsParams'], null>;
}

export interface SyncThemeSchemaNSettingsResult {
  statusSyncThemeSchemaNSettings: SyncFulfillStatus;
  messageError: string | undefined;
  isNeedIgnoreReportError: boolean;
}

/** Sync theme settings VEDA lên shopify editor */
export function* syncThemeSchemaNSettings({ themeSchemaNSettingsParams }: SyncThemeSchemaNSettings) {
  if (themeSchemaNSettingsParams.data) {
    yield retry(3, 1000, shopifyConnectionService.writeThemeSchemaNSettings, themeSchemaNSettingsParams);
    const socketSyncThemeSchemaNSettings: SagaReturnType<typeof handleWaitForSocketOfSyncThemeByResultOfExtractThemeToFileForSync> = yield call(
      handleWaitForSocketOfSyncThemeByResultOfExtractThemeToFileForSync,
      'Đồng bộ theme / Ghi file configure theme',
    );
    return {
      statusSyncThemeSchemaNSettings: socketSyncThemeSchemaNSettings.statusSync,
      messageError: socketSyncThemeSchemaNSettings.statusSync === 'failure' ? socketSyncThemeSchemaNSettings.message : undefined,
      isNeedIgnoreReportError: socketSyncThemeSchemaNSettings.isNeedIgnoreReportError,
    } as SyncThemeSchemaNSettingsResult;
  }
  return {
    statusSyncThemeSchemaNSettings: 'success',
    messageError: undefined,
    isNeedIgnoreReportError: false,
  };
}
