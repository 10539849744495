export const ModalInteractiveTheme = {
  createTheme: 'Create Theme',
  updateTheme: 'Update Theme',
  name: 'Name',
  featured_image: {
    title: 'Featured Image',
    description: 'When the theme is active, there will be a large image and that image is this image',
  },
  screenshot_image_1: {
    title: 'Screen Image 1',
    description:
      "This is an overview image of the theme. Images will be displayed in 'Theme Templates' and thumbnails of the theme drafts in the theme dashboard",
  },
} as const;
