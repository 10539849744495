import { SocketMessage } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { ActionTypes, createReducer, handleAction } from 'wiloke-react-core/utils';
import {
  syncToShopify,
  setEventIdOfSyncShopifyNClonePageAtomServiceToThemeAtomService,
  setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService,
  resetSyncToShopifyStatus,
  resetAllState,
  previewWithShopify,
  resetPreviewWithShopifyStatus,
} from './actions';

interface StreamData {
  status: SyncStatus;
  socketData: SocketMessage | undefined;
}

const defaultStreamData: StreamData = {
  status: 'idle',
  socketData: undefined,
};

interface State {
  eventId: string | undefined;
  statusSyncToShopify: Status;
  statusPreviewWithShopify: Status;
  previewUrls: string[] | null | undefined;
  // @tuong -> "streams" dùng để lắng nghe socket hoàn thành cũng như chứa dữ liệu mà socket trả về
  streams: Record<SocketMessage['eventType'], StreamData>;
}
type Actions = ActionTypes<
  | typeof syncToShopify
  | typeof setEventIdOfSyncShopifyNClonePageAtomServiceToThemeAtomService
  | typeof setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService
  | typeof resetSyncToShopifyStatus
  | typeof resetAllState
  | typeof previewWithShopify
  | typeof resetPreviewWithShopifyStatus
>;

export const initialState: State = {
  eventId: undefined,
  statusSyncToShopify: 'idle',
  statusPreviewWithShopify: 'idle',
  previewUrls: [],
  streams: {
    'Delete ouput builder ngoài dashboard': defaultStreamData,

    'Publish | Unpublish page ngoài dashboard': defaultStreamData,

    'Update shopifyPages ngoài dashboard': defaultStreamData,
    'Update shopifyPages ngoài dashboard/Từ "all" -> 1 vài => Unpublish tất cả': defaultStreamData,

    'Ghi file khi save ở builder page / Ghi global (sinh ra từ themeSettings)': defaultStreamData,
    'Ghi file khi save ở builder page / Ghi header': defaultStreamData,
    'Ghi file khi save ở builder page / Ghi footer': defaultStreamData,
    'Ghi file khi save ở builder page / Ghi page': defaultStreamData,

    'Ghi file khi update page settings ngoài dashboard': defaultStreamData,

    'Ghi file khi update theme settings ngoài dashboard': defaultStreamData,

    'Ghi file atomic css khi save ở builder page': defaultStreamData,
    'Ghi file raw atomic css khi save ở builder page': defaultStreamData,
    'Ghi file atomic css khi publish theme': defaultStreamData,

    'Ghi file khi save ở builder page / Ghi addon enable position': defaultStreamData,
    'Ghi file khi save ở builder page / Ghi addon disable position': defaultStreamData,
    'Ghi file khi save ở builder page / Ghi các addon disable position vừa tạo xong vào file theme.liquid': defaultStreamData,

    'Delete addon khi save ở builder': defaultStreamData,

    'Sync translation': defaultStreamData,

    'Migrate theme': defaultStreamData,

    'Clone các thành phần từ page atom service sang theme atom service': defaultStreamData,

    'Xóa toàn bộ dữ liệu veda': defaultStreamData,

    'Restore lại layout của theme / xoá header và footer của veda': defaultStreamData,

    'Publish header và footer của veda': defaultStreamData,
  },
};

export const reducerSyncShopifyNClonePageAtomServiceToThemeAtomService = createReducer<State, Actions>(initialState, [
  handleAction('@syncShopifyNClonePageAtomServiceToThemeAtomService/resetAllState', () => initialState),
  handleAction(
    '@syncShopifyNClonePageAtomServiceToThemeAtomService/setEventIdOfSyncShopifyNClonePageAtomServiceToThemeAtomService',
    ({ state, action }) => {
      return {
        ...state,
        eventId: action.payload,
      };
    },
  ),
  handleAction(
    '@syncShopifyNClonePageAtomServiceToThemeAtomService/setEventIdOfSyncShopifyNClonePageAtomServiceToThemeAtomService',
    ({ state, action }) => {
      return {
        ...state,
        eventId: action.payload,
      };
    },
  ),
  handleAction(
    '@syncShopifyNClonePageAtomServiceToThemeAtomService/setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService',
    ({ state, action }) => {
      const { eventType, socketData, status } = action.payload;
      return {
        ...state,
        previewUrls: socketData && 'previewUrls' in socketData ? socketData?.previewUrls : state.previewUrls,
        streams: {
          ...state.streams,
          [eventType]: {
            status,
            socketData,
          },
        },
      };
    },
  ),

  // Sync
  handleAction('@syncShopifyNClonePageAtomServiceToThemeAtomService/syncingResult', ({ state }) => {
    return {
      ...state,
      statusSyncToShopify: 'loading',
    };
  }),
  handleAction('@syncShopifyNClonePageAtomServiceToThemeAtomService/syncedResult', ({ state }) => {
    return {
      ...state,
      statusSyncToShopify: 'success',
    };
  }),
  handleAction('@syncShopifyNClonePageAtomServiceToThemeAtomService/syncFailureResult', ({ state }) => {
    return {
      ...state,
      statusSyncToShopify: 'failure',
    };
  }),
  handleAction('@syncShopifyNClonePageAtomServiceToThemeAtomService/resetSyncToShopifyStatus', ({ state }) => {
    return {
      ...state,
      statusSyncToShopify: 'idle',
      previewUrls: [],
    };
  }),

  // Preview
  handleAction('@syncShopifyNClonePageAtomServiceToThemeAtomService/syncingPreview', ({ state }) => {
    return {
      ...state,
      statusPreviewWithShopify: 'loading',
    };
  }),
  handleAction('@syncShopifyNClonePageAtomServiceToThemeAtomService/syncedPreview', ({ state }) => {
    return {
      ...state,
      statusPreviewWithShopify: 'success',
    };
  }),
  handleAction('@syncShopifyNClonePageAtomServiceToThemeAtomService/syncFailurePreview', ({ state }) => {
    return {
      ...state,
      statusPreviewWithShopify: 'failure',
    };
  }),
  handleAction('@syncShopifyNClonePageAtomServiceToThemeAtomService/resetPreviewWithShopifyStatus', ({ state }) => {
    return {
      ...state,
      statusPreviewWithShopify: 'idle',
      previewUrls: [],
    };
  }),
]);
