import { nth } from 'lodash';

const keys = new Set<string>();

export const deleteCache = async (cacheKey: string) => {
  await caches.delete(cacheKey);
  keys.delete(cacheKey);
};

export const clearCache = async () => {
  await Promise.all(Array.from(keys.values()).map(key => caches.delete(key)));
};

export const pushToCache = async (data: string, cacheKey: string) => {
  const cache = await caches.open(cacheKey);
  await cache.put('', new Response(data));
  keys.add(cacheKey);
  // console.log('Items cached:', data);
  return cacheKey;
};

export const readFromCache = async <Result>(cacheKey: string): Promise<Result | undefined> => {
  const cache = await caches.open(cacheKey);
  const keys = await cache.keys();
  const key = nth(keys, 0);
  if (key) {
    const response = await cache.match(key);
    if (response) {
      const data = await response.json();
      // console.log(`Key: ${key.url}, Data:`, data);
      return data;
    }
    return undefined;
  }
  return undefined;
};

export const getAvailableStorage = async () => {
  const estimate = await navigator.storage.estimate();
  const quota = estimate.quota;
  const usage = estimate.usage;
  if (quota && usage) {
    const available = quota - usage;
    return available;
  }
  return undefined;
};

export const getCacheKeys = () => Array.from(keys.values());
