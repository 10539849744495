import { useGetShopifyPageTemplate } from 'containers/Admin/PageBuilder/TemplatesPage/utils/useGetShopifyPageTemplate';
import { CreateArticlePicker, CreateBlogPicker, CreateCollectionPicker, CreateProductPicker } from 'containers/Shopify/ModalCreateShopifyPicker';
import { useSettingsShopifyPicker } from 'containers/Shopify/ModalCreateShopifyPicker/slice';
import { Dispatch, SetStateAction } from 'react';
import { handleExtractResultForForceSync } from 'services/ShopifyConnection';

interface ShopifyPickersProps {
  pageParamsInteracting: ReturnType<typeof handleExtractResultForForceSync>['pagesParams'][number] | undefined;
  setPageParamsInteracting: Dispatch<SetStateAction<ShopifyPickersProps['pageParamsInteracting']>>;
  setFormPreprocessingValues: Dispatch<
    SetStateAction<Record<string, ReturnType<typeof handleExtractResultForForceSync>['pagesParams'][number] | undefined>>
  >;
}

export const ShopifyPickers = ({ pageParamsInteracting, setPageParamsInteracting, setFormPreprocessingValues }: ShopifyPickersProps) => {
  const changeSettingsShopify = useSettingsShopifyPicker();
  const { getShopifyPages } = useGetShopifyPageTemplate();
  return (
    <>
      <CreateProductPicker
        onCancel={() => {
          setPageParamsInteracting(undefined);
          changeSettingsShopify({
            visibleProduct: false,
            slugsProduct: [],
          });
        }}
        onOk={() => {
          if (pageParamsInteracting && pageParamsInteracting.pageType === 'product' && pageParamsInteracting.pageCommandId) {
            const { pageCommandId } = pageParamsInteracting;
            const values = getShopifyPages('product');
            setPageParamsInteracting(undefined);
            setFormPreprocessingValues(state => {
              if (pageCommandId) {
                const defaultData = state[pageCommandId] ?? { ...pageParamsInteracting };
                return {
                  ...state,
                  [pageCommandId]: {
                    ...defaultData,
                    product: {
                      ...defaultData.product,
                      isApplyToAll: false,
                      shopifyPages: values,
                    },
                  },
                };
              }
              return state;
            });
          }
          changeSettingsShopify({
            visibleProduct: false,
            slugsProduct: [],
          });
        }}
      />
      <CreateCollectionPicker
        onCancel={() => {
          setPageParamsInteracting(undefined);
          changeSettingsShopify({
            visibleCollection: false,
            slugsCollection: [],
          });
        }}
        onOk={() => {
          if (pageParamsInteracting && pageParamsInteracting.pageType === 'collection' && pageParamsInteracting.pageCommandId) {
            const { pageCommandId } = pageParamsInteracting;
            const values = getShopifyPages('collection');
            setPageParamsInteracting(undefined);
            setFormPreprocessingValues(state => {
              if (pageCommandId) {
                const defaultData = state[pageCommandId] ?? { ...pageParamsInteracting };
                return {
                  ...state,
                  [pageCommandId]: {
                    ...defaultData,
                    collection: {
                      ...defaultData.collection,
                      isApplyToAll: false,
                      shopifyPages: values,
                    },
                  },
                };
              }
              return state;
            });
          }
          changeSettingsShopify({
            visibleCollection: false,
            slugsCollection: [],
          });
        }}
      />
      <CreateArticlePicker
        onCancel={() => {
          setPageParamsInteracting(undefined);
          changeSettingsShopify({
            visibleArticle: false,
            slugArticle: [],
          });
        }}
        onOk={() => {
          if (pageParamsInteracting && pageParamsInteracting.pageType === 'article' && pageParamsInteracting.pageCommandId) {
            const { pageCommandId } = pageParamsInteracting;
            const values = getShopifyPages('article');
            setPageParamsInteracting(undefined);
            setFormPreprocessingValues(state => {
              if (pageCommandId) {
                const defaultData = state[pageCommandId] ?? { ...pageParamsInteracting };
                return {
                  ...state,
                  [pageCommandId]: {
                    ...defaultData,
                    article: {
                      ...defaultData.article,
                      isApplyToAll: false,
                      shopifyPages: values,
                    },
                  },
                };
              }
              return state;
            });
          }
          changeSettingsShopify({
            visibleArticle: false,
            slugArticle: [],
          });
        }}
      />
      <CreateBlogPicker
        onCancel={() => {
          setPageParamsInteracting(undefined);
          changeSettingsShopify({
            visibleBlog: false,
            slugsBlog: [],
          });
        }}
        onOk={() => {
          if (pageParamsInteracting && pageParamsInteracting.pageType === 'blog' && pageParamsInteracting.pageCommandId) {
            const { pageCommandId } = pageParamsInteracting;
            const values = getShopifyPages('blog');
            setPageParamsInteracting(undefined);
            setFormPreprocessingValues(state => {
              if (pageCommandId) {
                const defaultData = state[pageCommandId] ?? { ...pageParamsInteracting };
                return {
                  ...state,
                  [pageCommandId]: {
                    ...defaultData,
                    blog: {
                      ...defaultData.blog,
                      isApplyToAll: false,
                      shopifyPages: values,
                    },
                  },
                };
              }
              return state;
            });
          }
          changeSettingsShopify({
            visibleBlog: false,
            slugsBlog: [],
          });
        }}
      />
    </>
  );
};
