import { PartnerItem, VedaBlogDTO } from 'services/RecommednApps/types';
import { createAsyncAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';

export const getRecommendApps = createAsyncAction([
  '@Dashboard/getRecommendApps/request',
  '@Dashboard/getRecommendApps/success',
  '@Dashboard/getRecommendApps/failure',
])<undefined, { data: PartnerItem[] }, undefined>();

export const getVedaBlogs = createAsyncAction([
  '@Dashboard/getVedaBlogs/request',
  '@Dashboard/getVedaBlogs/success',
  '@Dashboard/getVedaBlogs/failure',
])<undefined, { data: VedaBlogDTO[] }, undefined>();

export const useGetRecommendApps = createDispatchAsyncAction(getRecommendApps);
export const useGetVedaBlogs = createDispatchAsyncAction(getVedaBlogs);
