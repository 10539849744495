export const publish_shopify = {
  limit_liquid_file_size: 'La taille maximale du fichier Liquid est de %% size %%',
  error_in_code: 'Erreur dans le code',
  error_unknown: {
    message: "Une erreur s'est produite lors du processus de synchronisation",
    description: 'Assurez-vous que votre code respecte les règles établies',
  },
  sync_error: 'Erreur de synchronisation',
  sync_success: 'Synchronisation réussie',
  publishing: 'Enregistrement en cours',
  init_sync: 'Initialisation de la synchronisation',
  init_sync_error: {
    message: "Erreur lors de l'initialisation de la synchronisation",
    description: 'Il y a un problème avec la connexion réseau, veuillez réessayer votre action',
  },
  syncing: 'Synchronisation de %% text %% en cours',
  live_preview: 'Aperçu en direct',
  continue_editing: 'Continuer à modifier',
  socket_timeout: 'Délai de connexion dépassé',
  sync_something_error: 'Erreur de synchronisation de %% text %%',
  theme_settings_or_atomic_css: 'Paramètres du thème ou CSS atomique',
  translation: 'Traduction',
  migrate: 'Migrer %% text %%',
  text_to_shopify: '%% text %% vers Shopify',
  sync_page_message: {
    step: 'Étape %% text %%',
    init: 'Initialisation en cours...',
    saving: 'Enregistrement de %% text %% en cours...',
    syncing_to_shopify: 'Synchronisation de %% text %% vers Shopify en cours...',
  },
  page_not_sync_yet: "Cette page n'a pas encore été synchronisée avec Shopify",
  process_used: "Un autre processus de synchronisation est en cours d'exécution. Veuillez attendre que tout soit terminé",
  syncing_not_response: 'La synchronisation ne répond pas',
  syncing_may_stuck: 'La synchronisation peut être bloquée',
  contact_for_solution: "Veuillez contacter le support pour obtenir de l'aide",
  wait_for_response: 'Attendez la réponse de Veda',
  get_help: "Obtenir de l'aide",
  limit_theme: 'Limite du thème',
  limit_theme_desc:
    'Limite de thème atteinte, votre boutique a atteint la limite de 20 thèmes. Veuillez sélectionner un thème à supprimer et recréer un nouveau thème.',
  section_sync_error: 'La synchronisation de section a rencontré une erreur.',
} as const;
