import { Button } from '@wiloke/ui';
import TextInput from 'components/TextInput';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSetVisibleModalStorePassword } from 'store/global/auth';
import { useVerifyUserStorefrontPassword } from 'store/global/auth/actions';
import { authSelector } from 'store/selectors';
import { composeShopName } from 'utils/functions/composeShopName';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { FontAwesome, Space, Text } from 'wiloke-react-core';

export const CheckStorefrontPassword = () => {
  const { verifyStorefrontPasswordStatus, id } = useSelector(authSelector);

  const { shopName } = getUserInfo();
  const verifyUserStorefrontPassword = useVerifyUserStorefrontPassword();
  const setVisibleModalStorePassword = useSetVisibleModalStorePassword();

  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleChangePassword = (value: string) => {
    setPassword(value);
    setError('');
  };

  const handleSave = () => {
    if (password === '') {
      setError('Password cannot be blank');
    } else {
      verifyUserStorefrontPassword.request({
        password: password.trim(),
        id,
        showNotification: true,
        onFulfilled() {
          setVisibleModalStorePassword(false);
        },
        onFailed(message) {
          setError(message);
        },
      });
    }
  };

  return (
    <>
      <TextInput block value={password} onValueChange={handleChangePassword} placeholder="Your password" />
      {error && (
        <Text color="danger">
          <Space size={5} />
          <FontAwesome type="fas" name="exclamation-circle" color="danger" /> {error}
        </Text>
      )}
      <Space size={5} />
      <Text color="gray6">If your storefront has password protected, enter it here to allow us to show your theme in Veda editor.</Text>
      <Text
        tagName="a"
        href={`https://admin.shopify.com/store/${composeShopName(shopName)}/online_store/preferences?tutorial=unlock`}
        target="_blank"
        fontFamily="secondary"
      >
        Find or turn off your storefront password here
      </Text>
      <Space size={5} />
      <Button loading={verifyStorefrontPasswordStatus === 'loading'} radius={4} size="extra-small" onClick={handleSave}>
        Save password
      </Button>
    </>
  );
};
