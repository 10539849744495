import { userApis } from 'services/UserService/apis';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

interface CreateCategoryOfThemeProduct {
  name: string;
  description: string;
}

export const createCategoryOfThemeProduct = async ({ description, name }: CreateCategoryOfThemeProduct) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    const response = await themeApis.vedaApplication.adminApi.category.createCategory({
      category: { description, name },
    });
    await userApis.user.adminApi.updateVedaCacheVersion();
    return response;
  }
  throw new RoleException();
};
