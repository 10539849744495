import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import { SyncShopifyMessage } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { SyncThemeByResultOfExtractThemeToFileForSyncMessage } from 'packages/SyncThemeByResultOfExtractThemeToFileForSync/hooks/useSocketForSyncThemeByResultOfExtractThemeToFileForSync';
import { i18n } from 'translation';
import fetchAPI from 'utils/functions/fetchAPI';
import { areUndefined } from '../utils';

export interface RestoreThemeLayoutAndDeleteVedaHeaderFooter_BEExpectParameters {
  eventId: string;
  eventType: SyncShopifyMessage['eventType'] | SyncThemeByResultOfExtractThemeToFileForSyncMessage['eventType'];
}

interface RestoreThemeLayoutAndDeleteVedaHeaderFooter_BEResponse {
  message: string;
  info: {
    eventId: string;
    eventType: SyncShopifyMessage['eventType'] | SyncThemeByResultOfExtractThemeToFileForSyncMessage['eventType'];
  };
}

interface RestoreThemeLayoutAndDeleteVedaHeaderFooter extends DeepPartial<RestoreThemeLayoutAndDeleteVedaHeaderFooter_BEExpectParameters> {}

/**
 * Services thực hiện restore lại layout của theme / xoá header và footer của veda
 */
export const restoreThemeLayoutAndDeleteVedaHeaderFooter = async (data: RestoreThemeLayoutAndDeleteVedaHeaderFooter) => {
  if (areUndefined(data.eventId, data.eventType)) {
    throw new Error(i18n.t('general.lack_of_params'));
  }
  const res: AxiosResponse<RestoreThemeLayoutAndDeleteVedaHeaderFooter_BEResponse> = await fetchAPI.request({
    method: 'PUT',
    params: {
      eventId: data.eventId,
      eventType: data.eventType,
    } as Pick<RestoreThemeLayoutAndDeleteVedaHeaderFooter_BEExpectParameters, 'eventId' | 'eventType'>,
    data: data as RestoreThemeLayoutAndDeleteVedaHeaderFooter_BEExpectParameters,
    url: `${configureApp.endpoint['shopify-connections']}/sync/shopify/me/themes/header-footer/theme-default`,
  });
  return res.data.message;
};
