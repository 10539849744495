import { createSlice, handleAction } from 'wiloke-react-core/utils';
import initialState from './initialState';
import { SetThemeVersionAction } from './types';

export const sliceThemeVersion = createSlice<typeof initialState, SetThemeVersionAction>({
  name: '@Global',
  initialState,
  reducers: [
    // Force set all
    handleAction('setThemeVersion', ({ action, state }) => {
      return {
        ...state,
        version: action.payload.version,
      };
    }),
  ],
});

export const { setThemeVersion } = sliceThemeVersion.actions;
