import { Button } from '@wiloke/ui';
import { RecommendCardStyle2 } from 'components/RecommendCard/RecommendCardStyle2';
import { FC, ReactNode } from 'react';
import { i18n } from 'translation';
import { Text, View, ViewProps } from 'wiloke-react-core';
import * as styles from './styles';

export interface RecommendCardProps {
  title: string;
  description: ReactNode;
  image?: string;
  cssContainer?: ViewProps['css'];
  buttonText?: string;
  onClick?: () => void;
}

interface RecommendCardStatic {
  Styles2: typeof RecommendCardStyle2;
}

const RecommendCard: FC<RecommendCardProps> & RecommendCardStatic = ({
  buttonText = i18n.t('adminDashboard.get', { text: i18n.t('adminDashboard.app') }),
  description = '',
  title = '',
  image = '',
  cssContainer,
  onClick,
}) => {
  return (
    <View css={[styles.container, cssContainer]}>
      <View css={styles.appImage}>
        <View css={styles.img}>
          <View
            tagName="img"
            src={image}
            css={{
              height: '100%',
              width: '100%',
              objectFit: 'cover',
            }}
          />
        </View>
      </View>

      <View css={styles.content}>
        <Text fontFamily="secondary" color="gray9" css={styles.title} dangerouslySetInnerHTML={{ __html: title ?? '' }}></Text>
        <View css={styles.descriptionContainer}>
          <Text fontFamily="secondary" numberOfLines={3} css={styles.description} color="gray7">
            {description}
          </Text>
        </View>
        <View css={{ marginTop: '10px' }}>
          <Button
            css={{ padding: '9px 22px', fontSize: '13px' }}
            backgroundColor="secondary"
            color="light"
            radius={4}
            size="extra-small"
            onClick={onClick}
          >
            <Text tagName="span" fontFamily="secondary">
              {buttonText}
            </Text>
          </Button>
        </View>
      </View>
    </View>
  );
};

RecommendCard.Styles2 = RecommendCardStyle2;

export default RecommendCard;
