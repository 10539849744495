import SelectAntd from 'components/SelectAntd/SelectAntd';
import { FC } from 'react';
import { SettingColorScheme } from 'types/Schema';
import { FormSchemaSettingProps } from '../../../type';

export const ColorScheme: FC<FormSchemaSettingProps> = ({ data, colorSchemes, onChange }) => {
  const _data = data as SettingColorScheme;

  return (
    <SelectAntd
      value={_data.children}
      options={colorSchemes.map(item => ({ value: item.cssClassName, label: item.uniqueName }))}
      onChange={value => {
        onChange?.({ children: value });
      }}
    />
  );
};
