import { AxiosError } from 'axios';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { createUpdatePurchaseCodeService } from 'services/UserService/Logic/createUpdatePurchaseCode';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { setVisible402PurchaseCode, validatePurchaseCode } from '../actions';

function* handleValidate({ payload: { purchaseCode, themeImportId, onFulfill, onError } }: ReturnType<typeof validatePurchaseCode.request>) {
  try {
    const response: SagaReturnType<typeof createUpdatePurchaseCodeService> = yield retry(3, 1000, createUpdatePurchaseCodeService, {
      purchaseCode,
      themeProductCommandId: themeImportId,
    });
    yield put(validatePurchaseCode.success(undefined));
    notifyAxiosHandler.handleSuccess(response.message);
    yield put(setVisible402PurchaseCode(false));
    onFulfill?.();
  } catch (error) {
    onError?.((error as AxiosError).isAxiosError ? (error as AxiosError<ErrorData>).response?.data.message ?? '' : (error as Error).message);
    yield put(
      validatePurchaseCode.failure({
        message: (error as AxiosError).isAxiosError ? (error as AxiosError<ErrorData>).response?.data.message ?? '' : (error as Error).message,
      }),
    );
  }
}

export function* watchValidatePurchaseCode() {
  yield takeLatest(getActionType(validatePurchaseCode.request), handleValidate);
}
