import { getCacheValue, setCacheValue, getUniqId } from '../../../CacheShopifyFieldId';
import { ISCHEMA_SettingTextArea } from '../@types/ISCHEMA_SettingTextArea';
import { ISETTING_SettingTextArea } from '../@types/ISETTING_SettingTextArea';

export const toShopifyFieldId = ({ field, parentField, section }: ISCHEMA_SettingTextArea | ISETTING_SettingTextArea) => {
  const theoryShopifyFieldId = parentField ? `${parentField.name}___${field.name}` : field.name;
  if (theoryShopifyFieldId.length >= 25) {
    const valueInCache = getCacheValue({ theoryShopifyFieldId, section });
    if (valueInCache) {
      return valueInCache;
    } else {
      const newKey = `VEDA_textarea_${getUniqId('textarea')}`;
      setCacheValue({ theoryShopifyFieldId, section }, newKey);
      return newKey;
    }
  }
  return theoryShopifyFieldId;
};
