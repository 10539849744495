import { SagaReturnType, put, retry, takeEvery } from 'redux-saga/effects';
import { getActionType } from 'wiloke-react-core/utils';
import { upgradeSection } from '../actions';
import { sectionService } from 'services/SectionService';
import { SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client } from 'types/Sections';
import { message, notification } from 'antd';
import { copyToClipboard } from 'utils/copyToClipboard';
import { getSectionsOfPageAtom } from 'services/PageService/Logic/getSectionsOfPageAtom';
import { getSectionsOfPageInThemeAtom } from 'services/ThemeService/Logic/getSectionsOfPageInThemeAtom';
import { updateSection } from 'store/reducers/utils/updateSection';
import { updateSectionOfPageAtom } from 'services/PageService/Logic/updateSectionOfPageAtom';
import { updateSectionOfPageInThemeAtom } from 'services/ThemeService/Logic/updateSectionofPageInThemeAtom';
import { i18n } from 'translation';

function* handleUpgradeSection({ payload }: ReturnType<typeof upgradeSection.request>) {
  const { sectionAtomCommandId, sectionInfo } = payload;
  try {
    let section: SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client | undefined = undefined;
    const isThemeAtomService = sectionInfo.themeInfo;
    const isPageAtomService = !sectionInfo.themeInfo;
    if (isThemeAtomService) {
      const sectionCurrentResponse: SagaReturnType<typeof getSectionsOfPageInThemeAtom> = yield retry(3, 1000, getSectionsOfPageInThemeAtom, {
        sectionCommandIds: [sectionInfo.commandId],
      });
      section = sectionCurrentResponse[0] as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client;
    } else if (isPageAtomService) {
      const sectionCurrentResponse: SagaReturnType<typeof getSectionsOfPageAtom> = yield retry(3, 1000, getSectionsOfPageAtom, {
        sectionCommandIds: [sectionInfo.commandId],
      });
      section = sectionCurrentResponse[0] as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client;
    }

    if (section) {
      const sectionAtomResponse: SagaReturnType<typeof sectionService.sections.getAtomSection> = yield retry(
        3,
        1000,
        sectionService.sections.getAtomSection,
        sectionAtomCommandId,
      );

      const overridedSectionInfo = updateSection(section, sectionAtomResponse, true) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client;
      let latestVersion: string | undefined = undefined;
      if (isThemeAtomService) {
        const secionLatestResponse: SagaReturnType<typeof updateSectionOfPageInThemeAtom> = yield retry(3, 1000, updateSectionOfPageInThemeAtom, {
          section: overridedSectionInfo,
        });
        latestVersion = secionLatestResponse.currentVersion;
      } else if (isPageAtomService) {
        const secionLatestResponse: SagaReturnType<typeof updateSectionOfPageAtom> = yield retry(3, 1000, updateSectionOfPageAtom, {
          section: overridedSectionInfo,
        });
        latestVersion = secionLatestResponse.currentVersion;
      }
      if (latestVersion) {
        yield put(
          upgradeSection.success({
            sectionAtomCommandId,
            latestSectionInfo: {
              ...sectionInfo,
              currentVersion: latestVersion,
            },
          }),
        );
        notification.success({
          message: 'Upgrade thành công',
          description: isThemeAtomService
            ? `Section "${sectionInfo.label}" trong page "${sectionInfo.pageInfo.label}" đã update thành công`
            : `Section "${sectionInfo.label}" trong page "${sectionInfo.pageInfo.label}" thuộc theme "${sectionInfo.themeInfo?.label}" đã update thành công`,
        });
      } else {
        yield put(upgradeSection.failure({ sectionInfo }));
        const messageErr = `API trả về section không chứa currentVersion: ${sectionAtomCommandId}`;
        notification.error({
          message: i18n.t('general.system_error'),
          description: (
            <div>
              <p>Báo dev kèm lời nhắn: {messageErr}"</p>
              <button
                style={{
                  borderRadius: '4px',
                  backgroundColor: '#2C36DC',
                  color: '#fff',
                  fontWeight: 500,
                  padding: '10px 16px',
                  cursor: 'pointer',
                  outline: 'none',
                  border: 'none',
                }}
                onClick={() => {
                  copyToClipboard(messageErr);
                  message.success('Đã copy');
                }}
              >
                Copy lời nhắn
              </button>
            </div>
          ),
        });
      }
    } else {
      yield put(upgradeSection.failure({ sectionInfo }));
      const messageErr = `API trả về section không tồn tại: ${sectionAtomCommandId}`;
      notification.error({
        message: i18n.t('general.system_error'),
        description: (
          <div>
            <p>Báo dev kèm lời nhắn: {messageErr}"</p>
            <button
              style={{
                borderRadius: '4px',
                backgroundColor: '#2C36DC',
                color: '#fff',
                fontWeight: 500,
                padding: '10px 16px',
                cursor: 'pointer',
                outline: 'none',
                border: 'none',
              }}
              onClick={() => {
                copyToClipboard(messageErr);
                message.success('Đã copy');
              }}
            >
              Copy lời nhắn
            </button>
          </div>
        ),
      });
    }
  } catch (error) {
    yield put(upgradeSection.failure({ sectionInfo }));
    if (error instanceof Error) {
      const messageErr = `${error.message}: ${sectionAtomCommandId}`;
      notification.error({
        message: i18n.t('general.system_error'),
        description: (
          <div>
            <p>Báo dev kèm lời nhắn: {messageErr}"</p>
            <button
              style={{
                borderRadius: '4px',
                backgroundColor: '#2C36DC',
                color: '#fff',
                fontWeight: 500,
                padding: '10px 16px',
                cursor: 'pointer',
                outline: 'none',
                border: 'none',
              }}
              onClick={() => {
                copyToClipboard(messageErr);
                message.success('Đã copy');
              }}
            >
              Copy lời nhắn
            </button>
          </div>
        ),
      });
    }
  }
}

export function* watchUpgradeSection() {
  yield takeEvery(getActionType(upgradeSection.request), handleUpgradeSection);
}
