import { css, Theme } from 'wiloke-react-core';

export const refreshIcon = ({ colors }: Theme) => css`
  debug: ProductPicker-refresh-icon;

  width: 44px;
  height: 44px;
  background-color: ${colors.light};
  border: 1px solid ${colors.gray3};
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const filterContainer = css`
  padding: 0 10px;
  display: flex;
  margin-bottom: 8px;
  column-gap: 10px;
  align-items: center;
`;

export const filterInput = css`
  flex: 1;
  height: 44px;
`;

export const modalContent = css`
  padding: 15px 0 20px;
  height: 100%;
`;

export const loading = css`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
