import { ThemeSchema } from '../../@types/Shopify/ThemeConfigs/ThemeSchema';
import { ThemeSetting } from '../../@types/Shopify/ThemeConfigs/ThemeSetting';
import { ISCHEMA_SettingLayout } from './@types/ISCHEMA_SettingLayout';
import { ISETTING_SettingLayout } from './@types/ISETTING_SettingLayout';
import { getSchemaLabel } from './utils/getSchemaLabel';
import { vedaLayoutPropertyToShopifyFieldId } from './utils/vedaLayoutPropertyToShopifyFieldId';
import { vedaLayoutPropertyToShopifyFieldLabel } from './utils/vedaLayoutPropertyToShopifyFieldLabel';

export const SCHEMA_SettingLayout = ({ layout }: ISCHEMA_SettingLayout): ThemeSchema => {
  const { columnGapX, columnGapY, containerGap, containerWidth } = layout;
  return {
    name: getSchemaLabel().newLabel,
    settings: [
      {
        type: 'number',
        label: vedaLayoutPropertyToShopifyFieldLabel('containerWidth').newLabel,
        id: vedaLayoutPropertyToShopifyFieldId('containerWidth'),
        default: containerWidth,
        placeholder: undefined,
      },
      {
        type: 'number',
        label: vedaLayoutPropertyToShopifyFieldLabel('containerGap').newLabel,
        id: vedaLayoutPropertyToShopifyFieldId('containerGap'),
        default: containerGap,
        placeholder: undefined,
      },
      {
        type: 'number',
        label: vedaLayoutPropertyToShopifyFieldLabel('columnGapY').newLabel,
        id: vedaLayoutPropertyToShopifyFieldId('columnGapY'),
        default: columnGapY,
        placeholder: undefined,
      },
      {
        type: 'number',
        label: vedaLayoutPropertyToShopifyFieldLabel('columnGapX').newLabel,
        id: vedaLayoutPropertyToShopifyFieldId('columnGapX'),
        default: columnGapX,
        placeholder: undefined,
      },
    ],
  };
};

export const SETTING_SettingLayout = ({ layout }: ISETTING_SettingLayout): ThemeSetting => {
  const { columnGapX, columnGapY, containerGap, containerWidth } = layout;
  return {
    [vedaLayoutPropertyToShopifyFieldId('columnGapX')]: columnGapX,
    [vedaLayoutPropertyToShopifyFieldId('columnGapY')]: columnGapY,
    [vedaLayoutPropertyToShopifyFieldId('containerGap')]: containerGap,
    [vedaLayoutPropertyToShopifyFieldId('containerWidth')]: containerWidth,
  };
};
