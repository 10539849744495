import { CssColorVariable, CssFontVariable } from 'types/PresetStyles';
import { Consts } from 'utils/constants/constants';
import { hexToRgb } from '@wiloke/functions';
import { v4 } from 'uuid';
import { LayoutSettings, ColorSchemes } from 'types/Result';
import { vedaLayoutPropertyToShopifyFieldId } from 'services/ShopifyConnection/utils/VedaOutputToShopifyThemeEditor/ThemeSettingsConverter/SettingLayout/utils/vedaLayoutPropertyToShopifyFieldId';
import { categorizeFonts } from './categorizeFonts';
import { vedaFontToShopifyFieldId } from 'services/ShopifyConnection/utils/VedaOutputToShopifyThemeEditor/ThemeSettingsConverter/SettingFonts/utils/vedaFontToShopifyFieldId';
import { vedaColorToShopifyFieldId as vedaColorToShopifyFieldIdOfSettingColorSchemes } from 'services/ShopifyConnection/utils/VedaOutputToShopifyThemeEditor/ThemeSettingsConverter/SettingColorSchemes/SettingColors/utils/vedaColorToShopifyFieldId';
import { Target } from './getFilesForSave/types';
import { ColorMode } from 'services/ShopifyConnection/utils/VedaOutputToShopifyThemeEditor/ThemeSettingsConverter/SettingColors/@types/ColorMode';
import { getCssFont } from 'components/ShopifyFontsField/data';
import { getFirstScheme } from 'services/ShopifyConnection/utils/VedaOutputToShopifyThemeEditor/ThemeSettingsConverter/SettingColorSchemes/@utils/getFirstScheme';
import { vedaColorToShopifyFieldId as vedaColorToShopifyFieldIdOfSettingColors } from 'services/ShopifyConnection/utils/VedaOutputToShopifyThemeEditor/ThemeSettingsConverter/SettingColors/utils/vedaColorToShopifyFieldId';
import { isDarkColor, isLightColor } from 'containers/BuilderPage/components/Settings/ThemeSettings/ThemeColorScheme/@constants/isDarkOrLightColor';
import ColorLib from 'utils/LiquidSyntaxToTwig/libraries/color';

const JsRange = {
  IdStart: '/* _____Start/Id_____ */',
  IdEnd: '/* _____End/Id_____ */',
  ContentStart: '/* _____Start/Content_____ */',
  ContentEnd: '/* _____End/Content_____ */',
};

const createCleanUpFn = (functionName: string) => {
  return `
  if (window.${functionName}Cleanup === undefined) {
    window.${functionName}Cleanup = {
      listeners: [],
      push(listener) {
        this.listeners.push(listener);
      },
      cleanup() {
        this.listeners.forEach(listener => listener());
        this.listeners = [];
      }
    }
  }
  window.${functionName}Cleanup.cleanup();
`;
};

function replaceCleanUpFn(content: string, functionName: string) {
  return content.replace(/(\s)(onCleanup\()/g, `$1window.${functionName}Cleanup.push(`);
}

/**
 * @description Tạo ra content của file javascript cho các section
 */
export const getJs = (id: string, js: string, useCleanup = false) => {
  const functionName = `veda_fn_${id.replaceAll('-', '')}`;
  let cleanupFn = '';
  if (useCleanup) {
    cleanupFn = createCleanUpFn(functionName);
  }
  return `function ${functionName}() {
    ${cleanupFn}
    try {
      const uniqueId = ${JsRange.IdStart}"${id}"${JsRange.IdEnd};
      const containers = document.querySelectorAll(\`[data-id="\${uniqueId}"]:not(${Consts.FakeTags.Addons}, ${Consts.FakeTags.Megamenu})\`);
      containers.forEach(container => {
        if (!container) {
          return;
        }
        veda.plugins.videoCover(container);
        ${JsRange.ContentStart}
        ${useCleanup ? replaceCleanUpFn(js, functionName) : js}
        ${JsRange.ContentEnd}
      });
    } catch (error) {
      console.log(error);
    }
  };\n${functionName}();\n`;
};

/**
 * @description Lấy ra rgb từ 1 mã màu hex: #xxx, rgb() hoặc rgba()
 * @example ```ts
 * const rgb = getRgb('#fff');
 * // Result rgb: '255,255,255'
 * ```
 */
export const getRgb = (color: string) => {
  if (color.includes('#')) {
    const { r, g, b } = hexToRgb(color);
    return `${r}, ${g}, ${b}`;
  }
  if (color.includes('rgb(')) {
    return color.replace(/rgb\(|\)/g, '');
  }
  return color.replace(/(rgba\()(.*(?=,)).*/g, '$2');
};

/**
 * @description Truyền vào 1 mảng color variable và trả về 1 chuỗi css variable
 * tự động phân tích thêm biến freeze và biến rgb
 * @example ```ts
 * const colors = [{ id: '1', name: '--color-primary', value: 'rgba(xxx)' }];
 * const css = getCssColorVariables(colors);
 * // css: '--color-primary:rgba(xxx);--color-primary-freeze:rgba(xxx);--rgb-color-primary:xxx;--rgb-color-primary-freeze:xxx;'
 * ```
 */
interface GetCssColorVariables {
  colors: CssColorVariable[];
  target: Target;
  variant: ColorMode;
}
export const getCssColorVariables = ({ colors, target, variant }: GetCssColorVariables) => {
  /** Lấy ra tên biến css mà anh Long và đội code template đặt */
  const getVariableName = (color: CssColorVariable) => {
    return {
      normal: color.name,
      normalFreeze: `${color.name}-freeze`,
      rgb: color.name.replace(/^--/g, '--rgb-'),
      rgbFreeze: `${color.name.replace(/^--/g, '--rgb-')}-freeze`,
    };
  };

  if (target === 'Trích xuất kết quả sync lên shopify envato') {
    return colors
      .reduce<string[]>((result, color) => {
        const { normal, normalFreeze, rgb, rgbFreeze } = getVariableName(color);
        if (variant === 'light') {
          return result.concat(`
            ${normal}: {{ settings.${vedaColorToShopifyFieldIdOfSettingColors(color, 'light')} }};
            ${normalFreeze}: {{ settings.${vedaColorToShopifyFieldIdOfSettingColors(color, 'light')} }};
            ${rgb}: {{ settings.${vedaColorToShopifyFieldIdOfSettingColors(color, 'light')} | color_to_rgb | replace: 'rgb(' | replace: ')' }};
            ${rgbFreeze}: {{ settings.${vedaColorToShopifyFieldIdOfSettingColors(color, 'light')} | color_to_rgb | replace: 'rgb(' | replace: ')' }};
          `);
        }
        return result.concat(`
          ${normal}: {{ settings.${vedaColorToShopifyFieldIdOfSettingColors(color, 'dark')} }};
          ${rgb}: {{ settings.${vedaColorToShopifyFieldIdOfSettingColors(color, 'dark')} | color_to_rgb | replace: 'rgb(' | replace: ')' }};
        `);
      }, [])
      .join('\n');
  }
  return colors
    .reduce<string[]>((result, color) => {
      const { normal, normalFreeze, rgb, rgbFreeze } = getVariableName(color);
      if (variant === 'light') {
        return result.concat(`
          ${normal}: ${color.light};
          ${normalFreeze}: ${color.light};
          ${rgb}: ${getRgb(color.light)};
          ${rgbFreeze}: ${getRgb(color.light)};
        `);
      }
      return result.concat(`
        ${normal}: ${color.dark};
        ${rgb}: ${getRgb(color.dark)};
      `);
    }, [])
    .join('\n');
};

// Màu Dark -> Light là sáng dần
export const isLighterDirection = ({ colors }: Pick<GetCssColorVariables, 'colors'>) => {
  const darkColorVariable = colors.find(color => isDarkColor(color));
  const lightColorVariable = colors.find(color => isLightColor(color));

  if (darkColorVariable && lightColorVariable) {
    const darkColorVariableBuilder = new ColorLib(darkColorVariable.light);
    const sAttributeAtDarkColorVariableBuilder = darkColorVariableBuilder.saturationl();
    const lAttributeAtDarkColorVariableBuilder = darkColorVariableBuilder.luminosity();

    const lightColorVariableBuilder = new ColorLib(lightColorVariable.light);
    const sAttributeAtLightColorVariableBuilder = lightColorVariableBuilder.saturationl();
    const lAttributeAtLightColorVariableBuilder = lightColorVariableBuilder.luminosity();
    if (lAttributeAtDarkColorVariableBuilder < lAttributeAtLightColorVariableBuilder) {
      return true;
    }
    if (
      lAttributeAtDarkColorVariableBuilder === lAttributeAtLightColorVariableBuilder &&
      sAttributeAtDarkColorVariableBuilder < sAttributeAtLightColorVariableBuilder
    ) {
      return true;
    }
    return false;
  }

  return true;
};

interface GetColorSchemes {
  colorSchemes: ColorSchemes;
  target: Target;
}
export const getColorSchemes = ({ colorSchemes, target }: GetColorSchemes) => {
  const REVERSE_GRAY_COLORS: Record<string, string> = {
    '--color-gray9': '--color-gray1',
    '--color-gray8': '--color-gray2',
    '--color-gray7': '--color-gray3',
    '--color-gray6': '--color-gray4',
    '--color-gray5': '--color-gray5',
    '--color-gray4': '--color-gray6',
    '--color-gray3': '--color-gray7',
    '--color-gray2': '--color-gray8',
    '--color-gray1': '--color-gray9',
    '--color-dark': '--color-light',
    '--color-light': '--color-dark',
  };

  /** Lấy ra tên biến css mà anh Long và đội code template đặt */
  const getVariableName = ({ color, oppositeColor }: { color: CssColorVariable; oppositeColor?: CssColorVariable }) => {
    return {
      normal: color.name,
      normalFreeze: `${(oppositeColor ?? color).name}-freeze`,
      rgb: color.name.replace(/^--/g, '--rgb-'),
      rgbFreeze: `${(oppositeColor ?? color).name.replace(/^--/g, '--rgb-')}-freeze`,
    };
  };

  if (target === 'Trích xuất kết quả sync lên shopify envato') {
    const colorScheme = getFirstScheme(colorSchemes);
    if (colorScheme) {
      const getCssColorVariables = ({ colors, variant }: Pick<GetCssColorVariables, 'colors' | 'variant'>) => {
        const isNeedRevertFreezeColors = !isLighterDirection({ colors }); // Ngược chiều => Dark sáng hơn light
        return colors
          .reduce<string[]>((result, color) => {
            const nameReversed = REVERSE_GRAY_COLORS[color.name];
            const { normal, normalFreeze, rgb, rgbFreeze } = getVariableName({
              color,
              oppositeColor: isNeedRevertFreezeColors ? colors.find(color => color.name === nameReversed) : undefined,
            });
            if (variant === 'light') {
              return result.concat(`
${normal}: {{ scheme.settings.${vedaColorToShopifyFieldIdOfSettingColorSchemes(color, 'light')} }};
${normalFreeze}: {{ scheme.settings.${vedaColorToShopifyFieldIdOfSettingColorSchemes(color, 'light')} }};
${rgb}: {{ scheme.settings.${vedaColorToShopifyFieldIdOfSettingColorSchemes(color, 'light')} | color_to_rgb | replace: 'rgb(' | replace: ')' }};
${rgbFreeze}: {{ scheme.settings.${vedaColorToShopifyFieldIdOfSettingColorSchemes(color, 'light')} | color_to_rgb | replace: 'rgb(' | replace: ')' }};
              `);
            }

            const freezeInDarkMode = isNeedRevertFreezeColors
              ? `
${normalFreeze}: {{ scheme.settings.${vedaColorToShopifyFieldIdOfSettingColorSchemes(color, 'dark')} }};
${rgbFreeze}: {{ scheme.settings.${vedaColorToShopifyFieldIdOfSettingColorSchemes(color, 'dark')} | color_to_rgb | replace: 'rgb(' | replace: ')' }};
                `
              : ``;
            return result
              .concat(
                `
${normal}: {{ scheme.settings.${vedaColorToShopifyFieldIdOfSettingColorSchemes(color, 'dark')} }};
${rgb}: {{ scheme.settings.${vedaColorToShopifyFieldIdOfSettingColorSchemes(color, 'dark')} | color_to_rgb | replace: 'rgb(' | replace: ')' }};
            `,
              )
              .concat(freezeInDarkMode);
          }, [])
          .join('\n');
      };
      const { colors } = colorScheme;
      return `
        {% for scheme in settings.color_schemes %}
          {% assign scheme_classes = scheme.id %}
          {% if forloop.index == 1 -%}
            :root,
          {%- endif %}
          {{ scheme_classes }} {
            ${getCssColorVariables({ colors, variant: 'light' })}
          }
          {{ scheme_classes }}.dark {
            ${getCssColorVariables({ colors, variant: 'dark' })}
          }
        {% endfor %}
      `;
    }
    return '';
  }

  const getCssColorVariables = ({ colors, variant }: Pick<GetCssColorVariables, 'colors' | 'variant'> & { debug: string }) => {
    const isNeedRevertFreezeColors = !isLighterDirection({ colors }); // Ngược chiều => Dark sáng hơn light
    return colors
      .reduce<string[]>((result, color) => {
        const nameReversed = REVERSE_GRAY_COLORS[color.name];
        const { normal, normalFreeze, rgb, rgbFreeze } = getVariableName({
          color,
          oppositeColor: isNeedRevertFreezeColors ? colors.find(color => color.name === nameReversed) : undefined,
        });
        if (variant === 'light') {
          return result.concat(`
            ${normal}: ${color.light};
            ${normalFreeze}: ${color.light};
            ${rgb}: ${getRgb(color.light)};
            ${rgbFreeze}: ${getRgb(color.light)};
          `);
        }
        const freezeInDarkMode = isNeedRevertFreezeColors ? `${normalFreeze}: ${color.dark};\n${rgbFreeze}: ${getRgb(color.dark)};` : ``;
        return result.concat(`
          ${normal}: ${color.dark};
          ${rgb}: ${getRgb(color.dark)};
          ${freezeInDarkMode}
        `);
      }, [])
      .join('\n');
  };
  return colorSchemes
    .reduce<string[]>((result, colorScheme) => {
      const { colors, cssClassName } = colorScheme;
      return result.concat(`
        .${cssClassName} {
          ${getCssColorVariables({ colors, debug: cssClassName, variant: 'light' })}
        }
        .${cssClassName}.dark {
          ${getCssColorVariables({ colors, debug: cssClassName, variant: 'dark' })}
        }
      `);
    }, [])
    .join('\n');
};

/**
 * @description Truyền vào 1 mảng font variable và trả về 1 chuỗi css variable
 * @example ```ts
 * const fonts = [{ id: '1', name: '--font-primary', value: 'Poppins' }, { id: '1', name: '--font-secondary', value: 'Roboto' }];
 * const css = getCssFontVariables(fonts);
 * // css: '--font-primary:Poppins;--font-secondary:Roboto;'
 * ```
 */
interface GetCssFontVariables {
  fonts: CssFontVariable[];
  target: Target;
}
export const getCssFontVariables = ({ fonts, target }: GetCssFontVariables) => {
  if (target === 'Trích xuất kết quả sync lên shopify hoặc Lấy css để hiển thị trong builder') {
    const { googleFonts, shopifyFonts } = categorizeFonts({ fonts, uniqueValue: false });
    const googleFontVariables = googleFonts
      .reduce<string[]>((result, item) => {
        return result.concat(`${item.name}:${item.value};`);
      }, [])
      .join('\n');
    const shopifyFontVariables = shopifyFonts
      .reduce<string[]>((result, item) => {
        return result.concat(`${item.name}:${getCssFont(item)?.family};`);
      }, [])
      .join('\n');
    return [googleFontVariables, shopifyFontVariables].join('\n');
  }
  if (target === 'Trích xuất kết quả sync lên shopify envato') {
    const { googleFonts, shopifyFonts } = categorizeFonts({ fonts, uniqueValue: false });
    const shopifyFontsVariablesCss = shopifyFonts
      .reduce<string[]>((result, font) => {
        const shopifyFieldId = vedaFontToShopifyFieldId(font);
        return result.concat(`${font.name}: {{ settings.${shopifyFieldId}.family }}, {{ settings.${shopifyFieldId}.fallback_families }};`);
      }, [])
      .join('\n');
    const googleFontsVariablesCss = googleFonts
      .reduce<string[]>((result, font) => {
        return result.concat(`${font.name}: ${font.value};`);
      }, [])
      .join('\n');

    return [shopifyFontsVariablesCss, googleFontsVariablesCss].join('\n');
  }
  return '';
};

/**
 * Given a dictionary of key-value pairs, return a CSS linear interpolation
 * @param value - Record<number, number>
 * @returns A string that can be used in a CSS calc() function.
 */
export const cssLinearInterpolation = (value: Record<number, number>) => {
  const keys = Object.keys(value);
  const values = Object.values(value);
  if (keys.length !== 2) {
    throw new Error('linearInterpolation() value must be exactly 2 values');
  }
  // The slope
  const m = (values[1] - values[0]) / (Number(keys[1]) - Number(keys[0]));
  // The y-intercept
  let b = values[0] - m * Number(keys[0]);
  // Determine if the sign should be positive or negative
  let sign = '+';

  if (b < 0) {
    sign = '-';
    b = Math.abs(b);
  }

  return `${m * 100}vw ${sign} ${b}px`;
};

export const replaceTagFake = (tag: string, to = '{% comment %}<!-- Code Here -->{% endcomment %}') => {
  return tag.replace(new RegExp(`<(${Consts.FakeTags.EditCode}|${Consts.FakeTags.AddElement}) \\/>`, 'g'), to);
};

export const optimizeSectionJs = (jsArr: string[]) => {
  const jsContentArr = jsArr.map(item => {
    const idStartPattern = JsRange.IdStart.replace(/\//g, '\\/').replace(/\*/g, '\\*');
    const idEndPattern = JsRange.IdEnd.replace(/\//g, '\\/').replace(/\*/g, '\\*');
    const regexpId = new RegExp(`(.*${idStartPattern}")(id_[\\w-]*)("${idEndPattern}.*)`, 'g');
    return {
      id: item.replace(/\n/g, '').replace(regexpId, '$2'),
      content:
        item
          .split(JsRange.ContentStart)?.[1]
          ?.split(JsRange.ContentEnd)?.[0]
          ?.trim() ?? '',
    };
  });

  const mergeId = jsContentArr.reduce<Record<string, string>>((obj, item) => {
    return {
      ...obj,
      [item.content]: `${obj[item.content] ? `${obj[item.content]},` : ''}"${item.id}"`,
    };
  }, {});

  const result = Object.entries(mergeId).map(([content, ids]) => {
    const functionName = `veda_fn_${v4().replaceAll('-', '')}`;
    const cleanupFn = createCleanUpFn(functionName);
    if (ids.includes(',')) {
      return `function ${functionName} () {
        ${cleanupFn}
        try {
          const uniqueIds = [${ids}];
          console.log({ uniqueIds });
          uniqueIds.forEach(uniqueId => {
            const containers = document.querySelectorAll(\`[data-id="\${uniqueId}"]\`);
            containers.forEach(container => {
              if (!container) {
                return;
              }
              veda.plugins.videoCover(container);
              ${replaceCleanUpFn(content, functionName)}
            });
          });
        } catch(error) {
          console.error(error);
        }
      }\n${functionName}();`;
    }
    return `function ${functionName} () {
      ${cleanupFn}
      try {
        const uniqueId = ${ids};
        const containers = document.querySelectorAll(\`[data-id="\${uniqueId}"]\`);
        containers.forEach(container => {
          if (!container) {
            return;
          }
          veda.plugins.videoCover(container);
          ${replaceCleanUpFn(content, functionName)}
        });
      } catch(error) {
        console.error(error);
      }
    }\n${functionName}();`;
  });
  return result.join('\n');
};

interface GetCssFromThemeSettings {
  layoutSettings: LayoutSettings;
  target: Target;
}
export const getCssFromLayoutSettings = ({ layoutSettings, target }: GetCssFromThemeSettings) => {
  const containerWidth =
    target === 'Trích xuất kết quả sync lên shopify envato'
      ? `{{ ${vedaLayoutPropertyToShopifyFieldId('containerWidth')} }}`
      : layoutSettings.containerWidth;
  const containerGap =
    target === 'Trích xuất kết quả sync lên shopify envato'
      ? `{{ ${vedaLayoutPropertyToShopifyFieldId('containerGap')} }}`
      : layoutSettings.containerGap;
  const columnGapY =
    target === 'Trích xuất kết quả sync lên shopify envato' ? `{{ ${vedaLayoutPropertyToShopifyFieldId('columnGapY')} }}` : layoutSettings.columnGapY;
  const columnGapX =
    target === 'Trích xuất kết quả sync lên shopify envato'
      ? `{{ ${vedaLayoutPropertyToShopifyFieldId('columnGapX')} / 2 }}`
      : layoutSettings.columnGapX / 2;
  return `
  html .container {
    --container-width: ${containerWidth}px;
    --container-gap: ${containerGap}px;
    max-width: ${containerWidth}px;
    padding-left: ${containerGap}px;
    padding-right: ${containerGap}px;
  }
  html .row {
    margin-top: -${columnGapY}px;
    margin-left: -${columnGapX}px;
    margin-right: -${columnGapX}px;
  }
  html .row > * {
    margin-top: ${columnGapY}px;
    padding-left: ${columnGapX}px;
    padding-right: ${columnGapX}px;
  }
  html .veda-grid-auto {
    column-gap: ${columnGapX}px !important;
    row-gap: ${columnGapY}px !important;
  }
`;
};

export const deleteComponentAttr = (html: string) => {
  return html.replace(/component=("|')\w*("|')/g, '');
};

// NOTE: @tuong -> Chức năng "Addon Placholder" cần sự kết hợp của nhiều file -> Cần xem xét việc update tất cả các file khi có sự thay đổi nào đó ở 1 file bất kì
// ["LiquidComponent.tsx", "AddonPosition.tsx", "useAddonsPosition.ts", "reducerPages.ts", "generateHelpers.ts/deleteAddonPlaceholder"]
export const deleteAddonPlaceholder = (html: string) => {
  return html.replace(new RegExp(`<${Consts.FakeTags.AddonsPlaceholder.tagName}>.*<\/${Consts.FakeTags.AddonsPlaceholder.tagName}>`, 'gi'), '');
};
