import { Button, MyModal } from '@wiloke/ui';
import { selectSyncThemeByResultOfExtractThemeToFileForSync } from 'packages/SyncThemeByResultOfExtractThemeToFileForSync/selectors';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { View } from 'wiloke-react-core';

export const ModalNotifySyncActuallyBug = () => {
  const { session } = useSelector(selectSyncThemeByResultOfExtractThemeToFileForSync);
  const { status } = session;

  const [open, setOpen] = useState(false);
  const [counter, setCounter] = useState(0);

  const renderRetryButton = () => {
    return (
      <Button size="extra-small" radius={4} fontFamily="secondary" css={{ fontWeight: 500, cursor: 'initial' }}>
        Thử lại các tiến trình lỗi
      </Button>
    );
  };

  useEffect(() => {
    if (status === 'failure') {
      setCounter(state => state + 1);
    }
    if (status === 'success' || status === 'idle') {
      setCounter(0);
    }
  }, [status]);

  useEffect(() => {
    ModalNotifySyncActuallyBug.getCounter = () => counter;
    if (counter >= ModalNotifySyncActuallyBug.COUNTER_SHOULD_NOTIFY) {
      setOpen(true);
    }
  }, [counter]);

  return (
    <MyModal isVisible={open} headerText="Cảnh báo" cancelText="" okText="Đã hiểu" onCancel={() => setOpen(false)} onOk={() => setOpen(false)}>
      <View css={{ fontWeight: 500, fontSize: '16px', textAlign: 'center', marginBottom: '16px' }}>
        Quá trình đồng bộ đã lỗi{' '}
        <View css={{ textDecoration: 'underline' }} color="danger" tagName="span">
          {counter} lần liên tiếp
        </View>
      </View>
      <View css={{ paddingBottom: '12px' }}>
        Bạn đã thực hiện động bộ{' '}
        <View color="danger" tagName="span" css={{ fontWeight: 500, textDecoration: 'underline' }}>
          liên tục {counter} lần và tất cả đều lỗi
        </View>
        . Thay vì click {renderRetryButton()} trong vô vọng,{' '}
        <View color="danger" tagName="span" css={{ fontWeight: 500, textDecoration: 'underline' }}>
          hãy cầm file được sử dụng trong quá trình đồng bộ và gõ đầu "Developers"
        </View>
        . Sự lựa chọn đó sẽ ok hơn rất nhiều so với việc "Đâm đầu vào tường" mà bạn đã và đang làm
      </View>
    </MyModal>
  );
};

ModalNotifySyncActuallyBug.getCounter = () => 0;
ModalNotifySyncActuallyBug.COUNTER_SHOULD_NOTIFY = 2;
