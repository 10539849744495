import { GlobalCoupon } from 'types/Coupon';
import { clientUrl } from './const';
import fetchAPI from 'utils/functions/fetchAPI';
import { AxiosResponse } from 'axios';

interface ResponseSuccess {
  info?: GlobalCoupon;
  message: string;
}

export const getGlobalDiscount = async () => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${clientUrl}/global`,
  });

  return response.data;
};
