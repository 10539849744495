import { Button } from '@wiloke/ui';
import { useLocalStorage } from 'react-use';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const UserSwitchButton = () => {
  const [switchUser, _setSwitchUser, removeSwitchUser] = useLocalStorage<number>('switch_to_user');
  const [userName, _setUserName, removeUserName] = useLocalStorage<number>('user_name');
  const { role } = getUserInfo();

  if (role === 'admin' && switchUser) {
    return (
      <Button
        backgroundColor="danger"
        color="light"
        onClick={() => {
          removeSwitchUser();
          removeUserName();
          window.location.reload();
        }}
        radius={4}
        css={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: '9999999',
        }}
      >
        Stop tracking user: {userName}
      </Button>
    );
  }

  return null;
};
