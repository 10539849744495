import { PickerType } from 'components/LinkPicker/types';

interface SetSettings {
  type: 'setSettings';
  payload: {
    value: string;
    type: PickerType;
    label?: string;
  };
}

interface SetModalShopifyVisible {
  type: 'setModalShopifyVisible';
  payload: boolean;
}

interface SetModalEmailVisible {
  type: 'setModalEmailVisible';
  payload: boolean;
}

interface SetTypePicker {
  type: 'setPickerType';
  payload: {
    type: PickerType;
  };
}

export type Action = SetSettings | SetTypePicker | SetModalShopifyVisible | SetModalEmailVisible;

export interface State {
  value: string;
  type: PickerType;
  visibleShopify?: boolean;
  visibleEmail?: boolean;
  // dành riêng cho trường hợp onchange shopify modal
  label?: string;
}

export const getDefaultState = (value: string, type: PickerType) => {
  return {
    value,
    type,
  } as State;
};

export const reducerLinkPicker = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setSettings': {
      return {
        value: action.payload.value,
        type: action.payload.type,
        label: action.payload.label,
      };
    }
    case 'setPickerType': {
      return {
        ...state,
        type: action.payload.type,
      };
    }
    case 'setModalShopifyVisible': {
      return {
        ...state,
        visibleShopify: action.payload,
      };
    }
    case 'setModalEmailVisible': {
      return {
        ...state,
        visibleEmail: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
