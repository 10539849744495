import { i18n } from 'translation';
import { LiquidSyntaxToTwigError } from '../Error';
import { toString } from '../utils/toString';

const toUnicode = (character: string) => {
  let partial = character.charCodeAt(0).toString(16);
  while (partial.length !== 4) {
    partial = '0' + partial;
  }
  return '\\u' + partial;
};
const escapseReplacements = {
  '<': '&lt;',
  '>': '&gt;',
  '&': '&amp;',
  '"': '&quot;',
};
const escapeHTML = (text: string) => {
  return text.replace(/[<>&"]/g, function(character) {
    return escapseReplacements[character as keyof typeof escapseReplacements] ?? character;
  });
};

const escapseHTMLByUnicode = (text: string) => {
  const replacements = {
    '<': toUnicode('<'),
    '>': toUnicode('>'),
    '&': toUnicode('&'),
  };
  return text.replace(/[<>&]/g, function(character) {
    return replacements[character as keyof typeof replacements] ?? character;
  });
};

const htmlDecode = (input: string) => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent ?? '';
};

window.Twig.extendFilter('json', value => {
  try {
    let result = JSON.stringify(value);
    if (typeof result === 'string') {
      result = result.replace(/(?<=>)[^<]+(?=<)/g, value => escapeHTML(htmlDecode(value)));
      result = result.replace(/(?<=>)[^<]+(?=<)/g, escapseHTMLByUnicode);
      result = result.replace(/<\w+\b[^>]*>|<\/\w+\b[^>]*>/g, escapseHTMLByUnicode);
    }
    return result;
  } catch (err) {
    if (err instanceof LiquidSyntaxToTwigError) {
      throw err;
    }
    throw new LiquidSyntaxToTwigError(i18n.t('twig_error.filters.json.example', { message: toString(err) }));
  }
});

export const json = (liquid: string) => liquid;
