import { AxiosError } from 'axios';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { appSettingsService } from 'services/AppSettingsService';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { updateAppSettings } from './actions';
import { setAppSettingsVisible } from './slice';

function* handleUpdate({ payload: { data, onFulfill } }: ReturnType<typeof updateAppSettings.request>) {
  try {
    const response: SagaReturnType<typeof appSettingsService.postAppSettings> = yield retry(3, 1000, appSettingsService.postAppSettings, data);
    yield put(updateAppSettings.success({ data: response.info }));
    onFulfill?.();
    notifyAxiosHandler.handleSuccess(response.message);
    yield put(setAppSettingsVisible(false));
  } catch (error) {
    notifyAxiosHandler.handleError(error as AxiosError<ErrorData> | Error);
    yield put(updateAppSettings.failure(undefined));
  }
}

export function* watchUpdateAppSettings() {
  yield takeLatest(getActionType(updateAppSettings.request), handleUpdate);
}

export const sagaAppSettings = [watchUpdateAppSettings];
