import { SyncThemeByResultOfExtractThemeToFileForSyncMessage } from 'packages/SyncThemeByResultOfExtractThemeToFileForSync/hooks/useSocketForSyncThemeByResultOfExtractThemeToFileForSync';
import { handleExtractResultForForceSync } from 'services/ShopifyConnection';
import { createAction, createAsyncAction, createDispatchAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';
import { State } from './reducer';

export const setEventId = createAction('@SyncThemeByResultOfExtractThemeToFileForSync/socket/setEventId', (eventId: string | undefined) => eventId);
export const useSetEventId = createDispatchAction(setEventId);

export const syncThemeByResultOfExtractThemeToFileForSync = createAsyncAction([
  '@SyncThemeByResultOfExtractThemeToFileForSync/socket/syncing',
  '@SyncThemeByResultOfExtractThemeToFileForSync/socket/synced',
  '@SyncThemeByResultOfExtractThemeToFileForSync/socket/syncFailure',
  '@SyncThemeByResultOfExtractThemeToFileForSync/socket/syncCancelled',
])<
  {
    data: ReturnType<typeof handleExtractResultForForceSync>;
    isRetryAction: boolean;
    onSyncFulfill: () => void;
    onSuccess: (params: { previewUrl: string | undefined; fullPreviewUrls: Array<{ pageName: string; url: string }> }) => void;
  },
  undefined,
  undefined,
  undefined
>();
export const useSyncThemeByResultOfExtractThemeToFileForSync = createDispatchAsyncAction(syncThemeByResultOfExtractThemeToFileForSync);

export const setStreamSocket = createAction(
  '@SyncThemeByResultOfExtractThemeToFileForSync/socket/setStreamSocket',
  (payload: {
    eventType: SyncThemeByResultOfExtractThemeToFileForSyncMessage['eventType'];
    status: Status;
    socketData: SyncThemeByResultOfExtractThemeToFileForSyncMessage | undefined;
  }) => payload,
);
export const useSetStreamSocket = createDispatchAction(setStreamSocket);

export const resetSocketState = createAction('@SyncThemeByResultOfExtractThemeToFileForSync/socket/resetSocketState');
export const useResetSocketState = createDispatchAction(resetSocketState);

export const setExpectProcesses = createAction(
  '@SyncThemeByResultOfExtractThemeToFileForSync/session/setExpectProcesses',
  (payload: string[]) => payload,
);

export const pushActualProcesses = createAction(
  '@SyncThemeByResultOfExtractThemeToFileForSync/session/pushActualProcesses',
  (payload: string) => payload,
);

export const setStatusOfSession = createAction(
  '@SyncThemeByResultOfExtractThemeToFileForSync/session/setStatusOfSession',
  (payload: { status: Status; isRetryAction: boolean }) => payload,
);

export const setTemporarySignalToReplaceAddonInLiquidCode = createAction(
  '@SyncThemeByResultOfExtractThemeToFileForSync/session/setTemporarySignalToReplaceAddonInLiquidCode',
  (payload: Exclude<State['session']['temporarySignalToReplaceAddonInLiquidCode'], null>) => payload,
);

export const setTemporaryPreviewUrls = createAction(
  '@SyncThemeByResultOfExtractThemeToFileForSync/session/setTemporaryPreviewUrls',
  (payload: Exclude<State['session']['temporaryPreviewUrls'], null>) => payload,
);
export const setTemporaryRepresentPreviewUrl = createAction(
  '@SyncThemeByResultOfExtractThemeToFileForSync/session/setTemporaryRepresentPreviewUrl',
  (payload: Exclude<State['session']['temporaryRepresentPreviewUrl'], null>) => payload,
);

export const initSessionOfProcesses = createAction('@SyncThemeByResultOfExtractThemeToFileForSync/session/initSessionOfProcesses');
export const useInitSessionOfProcesses = createDispatchAction(initSessionOfProcesses);

export const setModalSyncThemeByResultOfExtractThemeToFileForSync = createAction(
  '@SyncThemeByResultOfExtractThemeToFileForSync/setModalSyncThemeByResultOfExtractThemeToFileForSync',
  (payload: State['modalSyncThemeByResultOfExtractThemeToFileForSync']) => payload,
);
export const useSetModalSyncThemeByResultOfExtractThemeToFileForSync = createDispatchAction(setModalSyncThemeByResultOfExtractThemeToFileForSync);

export const setModalSocketStucking = createAction(
  '@SyncThemeByResultOfExtractThemeToFileForSync/setModalSocketStucking',
  (payload: boolean) => payload,
);
export const useSetModalSocketStucking = createDispatchAction(setModalSocketStucking);

export const setModalPreviewUrls = createAction(
  '@SyncThemeByResultOfExtractThemeToFileForSync/setModalPreviewUrls',
  (payload: State['modalPreviewUrls']) => payload,
);
export const useSetModalPreviewUrls = createDispatchAction(setModalPreviewUrls);
