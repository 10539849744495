import { MyModal } from '@wiloke/ui';
import { useCrispChat } from 'containers/CrispChat/hooks/useCrispChat';
import { FC, useEffect, useRef, useState } from 'react';
import { i18n } from 'translation';
import { View, Text, FontAwesome } from 'wiloke-react-core';

const modalSocketStuckHandlers: Map<string, Record<'start' | 'clear', () => void>> = new Map();

export interface ModalSocketStuckProps {
  type:
    | 'Force sync shopify'
    | 'Sync shopify'
    | 'Import theme'
    | 'Migrate theme on shopify'
    | 'Clone các thành phần từ page atom service sang theme atom service';
  after?: number;
}

interface Static {
  getActions: (
    id: ModalSocketStuckProps['type'],
  ) => {
    start: () => void;
    clear: () => void;
  };
}

export const ModalSocketStuck: FC<ModalSocketStuckProps> & Static = ({ type, after = 5000 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { sendMessage } = useCrispChat();
  const intervalId = useRef<number | undefined>();

  const handleShow = () => {
    clearInterval(intervalId.current);
    intervalId.current = window.setInterval(() => {
      setIsVisible(true);
    }, after);
  };

  const handleClear = () => {
    clearInterval(intervalId.current);
  };

  const handleSendMessage = () => {
    sendMessage({ message: i18n.t('publish_shopify.socket_timeout') });
    setIsVisible(false);
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    modalSocketStuckHandlers.set(type, {
      start: handleShow,
      clear: handleClear,
    });
    return () => {
      modalSocketStuckHandlers.delete(type);
      handleClear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MyModal
      onCancel={handleCancel}
      onOk={handleSendMessage}
      cancelText=""
      okText={i18n.t('publish_shopify.get_help')}
      isVisible={isVisible}
      headerText={i18n.t('publish_shopify.syncing_not_response')}
    >
      <Text tagName="h3" size={18} css={{ marginBottom: '10px' }}>
        {i18n.t('publish_shopify.syncing_may_stuck')}
      </Text>
      <View>
        <View
          colorHover="primary"
          onClick={handleSendMessage}
          css={{ display: 'flex', alignItems: 'center', marginBottom: '6px', cursor: 'pointer' }}
        >
          <FontAwesome size={16} color="success" type="far" name="angle-right" css={{ marginRight: '6px' }} />
          <Text color="inherit" tagName="span">
            {i18n.t('publish_shopify.contact_for_solution')}
          </Text>
        </View>
        <View colorHover="primary" onClick={handleCancel} css={{ display: 'flex', alignItems: 'center', marginBottom: '6px', cursor: 'pointer' }}>
          <FontAwesome size={16} color="success" type="far" name="angle-right" css={{ marginRight: '6px' }} />
          <Text color="inherit" tagName="span">
            {i18n.t('publish_shopify.wait_for_response')}
          </Text>
        </View>
      </View>
    </MyModal>
  );
};

ModalSocketStuck.getActions = (type: ModalSocketStuckProps['type']) => {
  const actions = modalSocketStuckHandlers.get(type);
  if (actions) {
    return actions;
  }
  throw new Error(`ModalReportAfterError -> ${type} not exist`);
};
