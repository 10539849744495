import { ErrorWithCrispChat } from 'components/ErrorWithCrispChat';
import { Component, MouseEventHandler } from 'react';

interface Props {
  onRetry: MouseEventHandler;
  onError: () => void;
}
interface State {
  error: boolean;
}

const IS_DEV = process.env.NODE_ENV === 'development';

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { error: error.name === 'ChunkLoadError' || error.name === 'ModuleNotFoundError' || error.name === 'TimeoutError' };
  }

  componentDidCatch() {
    const { onError } = this.props;
    onError();
  }

  handleClick: MouseEventHandler = e => {
    const { onRetry } = this.props;
    onRetry(e);
    this.setState({ error: false });
  };

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error && IS_DEV) {
      return <ErrorWithCrispChat onClick={this.handleClick} />;
    }

    return children;
  }
}
