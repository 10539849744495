import { Title } from '@wiloke/ui';
import { CarouselStyle2 } from 'components/Carousel/CarouselStyle2';
import { useEffect, useRef, useState } from 'react';
import { FontAwesome, View } from 'wiloke-react-core';
import * as styles from './styles';
import { CarouselProps } from './types';

const CarouselStyle1 = <T extends any>({
  slideWidth,
  slideCount,
  interval = 10000,
  animationDuration = 300,
  space = 10,
  keyExtractor = (_, index) => index,
  data,
  title,
  renderItem,
}: CarouselProps<T>) => {
  // Props
  const DEFAULT_SLIDE_COUNT = slideCount;
  const SLIDE_WIDTH = slideWidth;
  const SPACE = space;
  const ANIMATION_DURATION = animationDuration;
  const INTERVAL = interval;

  const [animating, setAnimating] = useState(false);
  const [index, setIndex] = useState(0);
  const [numberOfItems, setNumberOfItems] = useState(DEFAULT_SLIDE_COUNT);
  const carouselEl = useRef<HTMLDivElement | null>(null);
  const slidesEl = useRef<HTMLDivElement | null>(null);
  const nextSlideTimeout = useRef<number | undefined>(undefined);

  const NUM_OF_ITEMS = numberOfItems;
  const MAX_LENGTH = Math.ceil(data.length / NUM_OF_ITEMS - 1);
  const ITEM_WIDTH = 100 / NUM_OF_ITEMS;
  const REDUNDANT_SLIDE = data.length - MAX_LENGTH * NUM_OF_ITEMS;
  const MAX_X = MAX_LENGTH * NUM_OF_ITEMS * ITEM_WIDTH - SPACE - (NUM_OF_ITEMS - REDUNDANT_SLIDE) * ITEM_WIDTH;
  const CURRENT_TRANSFORM_WITH_INDEX = -Math.max(0, index !== MAX_LENGTH ? index * ITEM_WIDTH * NUM_OF_ITEMS : MAX_X);

  const _renderSlide = (item: T, index: number) => {
    return (
      <View css={{ flexShrink: 0, flexBasis: `${ITEM_WIDTH}%` }} key={keyExtractor(item, index)}>
        {renderItem(item, index)}
      </View>
    );
  };

  const _handleClearInterval = () => {
    clearTimeout(nextSlideTimeout.current);
  };

  const _handleInterval = () => {
    _handleClearInterval();
    nextSlideTimeout.current = window.setTimeout(() => {
      setIndex(index => {
        if (index >= MAX_LENGTH) {
          return 0;
        }
        return index + 1;
      });
      setAnimating(true);
    }, INTERVAL);
  };

  const _handleResize = () => {
    if (carouselEl.current) {
      const containerWidth = (carouselEl.current.clientWidth * (100 - SPACE)) / 100;
      const columnsPerRow = Math.max(1, Math.floor(containerWidth / SLIDE_WIDTH));
      setNumberOfItems(DEFAULT_SLIDE_COUNT ? Math.min(DEFAULT_SLIDE_COUNT, columnsPerRow) : columnsPerRow);
    }
  };

  useEffect(() => {
    let timeout: number | undefined;
    if (animating) {
      timeout = window.setTimeout(() => {
        setAnimating(false);
        clearTimeout(timeout);
      }, ANIMATION_DURATION);
    }
    return () => clearTimeout(timeout);
  }, [animating, ANIMATION_DURATION]);

  useEffect(() => {
    _handleInterval();
    return () => {
      _handleClearInterval();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  useEffect(() => {
    _handleResize();
    window.addEventListener('resize', _handleResize);
    return () => {
      window.removeEventListener('resize', _handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View css={styles.style1.container}>
      <View css={styles.style1.header}>
        <Title title={title} titleCss={{ fontSize: '24px' }} css={[{ marginBottom: '15px' }]} />

        <View css={styles.style1.iconContainer}>
          <View
            css={styles.iconLeft(index === 0)}
            onClick={() => {
              if (index === 0) {
                return;
              }
              setIndex(index => {
                return index - 1;
              });
              setAnimating(true);
            }}
          >
            <FontAwesome type="far" size={16} name="angle-left" />
          </View>

          <View
            css={styles.iconRight(index >= MAX_LENGTH)}
            onClick={() => {
              if (index >= MAX_LENGTH) {
                return;
              }
              setIndex(index => {
                return index + 1;
              });
              setAnimating(true);
            }}
          >
            <FontAwesome type="far" size={16} name="angle-right" />
          </View>
        </View>
      </View>

      <View css={{ overflow: 'hidden' }} ref={carouselEl}>
        <View
          ref={slidesEl}
          css={{ display: 'flex', flexWrap: 'nowrap', willChange: 'transform' }}
          style={{
            transition: animating ? `${ANIMATION_DURATION}ms transform` : undefined,
            transform: `translateX(${CURRENT_TRANSFORM_WITH_INDEX}%)`,
          }}
        >
          {data.map(_renderSlide)}
        </View>
      </View>
    </View>
  );
};

export const Carousel = Object.assign(CarouselStyle1, {
  Style2: CarouselStyle2,
});
