import { HeaderField } from '../../../@types/Shopify/SidebarSettings/HeaderField';
import { Locales } from '../../../@types/Veda/ShopifyLocales';
import { vedaLabelToShopifyFieldLabel } from './utils/vedaLabelToShopifyLabel';
import { ISCHEMA_SettingUploadCSV } from './@types/ISCHEMA_SettingUploadCSV';
import { ISETTING_SettingUploadCSV } from './@types/ISETTING_SettingUploadCSV';
import { ILIQUID_SettingUploadCSV } from './@types/ILIQUID_SettingUploadCSV';

interface RTSettingUploadCSV {
  shopifyField: HeaderField;
  locales: Locales;
}

export const SCHEMA_SettingUploadCSV = ({ field, ...params }: ISCHEMA_SettingUploadCSV): RTSettingUploadCSV => {
  const { newLabel, locales: labelLocales } = vedaLabelToShopifyFieldLabel({ field, ...params });
  return {
    shopifyField: {
      type: 'header',
      content: newLabel,
    },
    locales: labelLocales,
  };
};

export const SETTING_SettingUploadCSV = (_: ISETTING_SettingUploadCSV) => {
  return {};
};

export const LIQUID_SettingUploadCSV = ({ liquid }: ILIQUID_SettingUploadCSV) => liquid;
