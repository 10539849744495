import { put, retry, takeLatest } from 'redux-saga/effects';
import { addonService } from 'services/AddonService';
import { getActionType } from 'wiloke-react-core/utils';
import { getDraftAddons, loadMoreDraftAddons } from '../../actions';

function* handleGetAddons({ payload: { categoryId, label } }: ReturnType<typeof getDraftAddons.request>) {
  try {
    const response: Awaited<ReturnType<typeof addonService.addons.getDrafts>> = yield retry(3, 1000, addonService.addons.getDrafts, {
      categoryId,
      label,
    });
    yield put(getDraftAddons.success({ data: response, hasNextPage: response.length > 0 ? true : false }));
  } catch (error) {
    yield put(getDraftAddons.failure(undefined));
  }
}

export function* watchGetDraftAddons() {
  yield takeLatest(getActionType(getDraftAddons.request), handleGetAddons);
}

function* handleLoadMoreDraftAddons({ payload: { cursor, categoryId, label } }: ReturnType<typeof loadMoreDraftAddons.request>) {
  try {
    const response: Awaited<ReturnType<typeof addonService.addons.loadMoreDrafts>> = yield retry(3, 1000, addonService.addons.loadMoreDrafts, {
      cursor,
      categoryId,
      label,
    });
    yield put(loadMoreDraftAddons.success({ data: response, hasNextPage: response.length > 0 ? true : false }));
  } catch (error) {
    yield put(loadMoreDraftAddons.failure(undefined));
  }
}

export function* watchLoadMoreDraftAddons() {
  yield takeLatest(getActionType(loadMoreDraftAddons.request), handleLoadMoreDraftAddons);
}
