import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { userApis } from '../apis';

interface DeleteParams {
  purchaseCode: string;
  website: string;
}

export const deleteWebsiteOfPurchaseCodeService = async ({ purchaseCode, website }: DeleteParams) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    return userApis.purchaseCode.adminApi.deleteWebsiteOfPurchaseCode({ purchaseCode, website });
  }
  if (role === 'dev' || role === 'user') {
    return userApis.purchaseCode.userApi.deleteWebsiteOfPurchaseCode({ purchaseCode, website });
  }
  throw new RoleException();
};
