import Field from 'components/Field';
import SwitchBeauty from 'components/SwitchBeauty';
import { FC } from 'react';
import { SettingTextEditor } from 'types/Schema';
import { FormSchemaSettingProps } from '../../type';

export const EnableMetafield: FC<FormSchemaSettingProps> = ({ data, onChange }) => {
  const _data = data as SettingTextEditor;

  return (
    <Field label="Enable Meta Fields">
      <SwitchBeauty
        borderColor="gray3"
        checked={_data.enableMetafield}
        onValueChange={val => {
          // @ts-ignore
          onChange?.({ enableMetafield: val });
        }}
      />
    </Field>
  );
};
