import { getCacheValue, setCacheValue, getUniqId } from '../../../CacheShopifyFieldId';
import { ISCHEMA_SettingRadioGroup } from '../@types/ISCHEMA_SettingRadioGroup';
import { ISETTING_SettingRadioGroup } from '../@types/ISETTING_SettingRadioGroup';

export const toShopifyFieldId = ({ field, parentField, section }: ISCHEMA_SettingRadioGroup | ISETTING_SettingRadioGroup) => {
  const theoryShopifyFieldId = parentField ? `${parentField.name}___${field.name}` : field.name;
  if (theoryShopifyFieldId.length >= 25) {
    const valueInCache = getCacheValue({ theoryShopifyFieldId, section });
    if (valueInCache) {
      return valueInCache;
    } else {
      const newKey = `VEDA_radioGroup_${getUniqId('radioGroup')}`;
      setCacheValue({ theoryShopifyFieldId, section }, newKey);
      return newKey;
    }
  }
  return theoryShopifyFieldId;
};
