import { AsyncComponent } from '@wiloke/ui';
import Checkbox from 'components/Checkbox';
import { FontAwesome, Space, Text, View } from 'wiloke-react-core';
import * as styles from './styles';
import { pagesProductSelector } from 'containers/Admin/Management/Pages/store/reducers/slicePagesProduct';
import { useSelector } from 'react-redux';
import { useGetCategoriesOfPageProduct } from 'containers/Admin/Management/Pages/store/actions/actionPagesProduct';
import { PageType } from 'types/Page';
import { useEffect } from 'react';
import Collapse from 'components/Collapse';
import { pageUseCaseSelector } from 'containers/Admin/Management/Pages/store/reducers/slicePageUseCase';
import { useGetPageUseCases } from 'containers/Admin/Management/Pages/store/actions/actionPageUseCase';

interface TemplateFilterProps {
  // page type
  pageType: PageType;
  // category
  categoryId: string;
  onChangeCategory: (id: string) => void;
  // plan
  planFilter: string;
  onChangePlan: (plan: string) => void;

  // use case
  useCase: string;
  onChangeUseCase: (useCase: string) => void;
}

const plans = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Free',
    value: 'free',
  },
  {
    label: 'Premium',
    value: 'premium',
  },
];

export const TemplateFilter = ({
  categoryId,
  onChangeCategory,
  pageType,
  onChangePlan,
  planFilter,
  useCase,
  onChangeUseCase,
}: TemplateFilterProps) => {
  const { categories, getStatus: getCategoriesStatus } = useSelector(pagesProductSelector);
  const { useCases, getStatus: getUseCasesStatus } = useSelector(pageUseCaseSelector);

  const getCategoriesOfPageProduct = useGetCategoriesOfPageProduct();
  const getUseCases = useGetPageUseCases();

  useEffect(() => {
    getCategoriesOfPageProduct.request({ pageType: pageType.toUpperCase() });
    getUseCases.request({ pageType: pageType.toUpperCase() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageType]);

  return (
    <>
      <View css={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
        <Text size={18}>Filtering Options</Text>
        <Text
          tagName="span"
          size={12}
          color="gray9"
          backgroundColor="gray3"
          css={[styles.checkboxGroup.count, { padding: '4px 8px', cursor: 'pointer', borderRadius: '4px' }]}
          onClick={() => {
            onChangeCategory('');
            onChangePlan('all');
            onChangeUseCase('');
          }}
        >
          Clear
        </Text>
      </View>

      <Collapse
        name="price"
        active
        css={styles.collapseContainer}
        renderHeader={(handler, active) => (
          <View onClick={handler} borderColor="gray3" css={styles.collapseHeader}>
            <View>Price</View>
            <FontAwesome size={16} type="far" name={active ? 'angle-up' : 'angle-down'} />
          </View>
        )}
      >
        {plans.map(item => (
          <View key={item.value}>
            <Checkbox
              size="extra-small"
              containerCss={styles.checkboxGroup.container}
              innerCss={styles.checkboxGroup.inner}
              checked={item.value === planFilter}
              onValueChange={val => {
                if (val) {
                  onChangePlan(item.value);
                } else {
                  onChangePlan('');
                }
              }}
            >
              <Text size={14}>{item.label}</Text>
            </Checkbox>
            <Space size={10} />
          </View>
        ))}
      </Collapse>

      <AsyncComponent
        status={getCategoriesStatus}
        Success={
          <Collapse
            active
            name="category"
            css={styles.collapseContainer}
            renderHeader={(handler, active) => (
              <View onClick={handler} borderColor="gray3" css={styles.collapseHeader}>
                <View>Industry</View>
                <FontAwesome size={16} type="far" name={active ? 'angle-up' : 'angle-down'} />
              </View>
            )}
          >
            {categories.map(item => (
              <View key={item.commandId}>
                <Checkbox
                  size="extra-small"
                  checked={item.commandId === categoryId}
                  containerCss={styles.checkboxGroup.container}
                  innerCss={styles.checkboxGroup.inner}
                  onValueChange={val => {
                    if (val) {
                      onChangeCategory(item.commandId);
                    } else {
                      onChangeCategory('');
                    }
                  }}
                >
                  <Text size={14}>{item.description}</Text>
                </Checkbox>
                <Space size={10} />
              </View>
            ))}
          </Collapse>
        }
      />

      <AsyncComponent
        status={getUseCasesStatus}
        isEmpty={useCases.length === 0}
        Empty={<div />}
        Success={
          <Collapse
            active
            name="use_cases"
            css={styles.collapseContainer}
            renderHeader={(handler, active) => (
              <View onClick={handler} borderColor="gray3" css={styles.collapseHeader}>
                <View>Use Cases</View>
                <FontAwesome size={16} type="far" name={active ? 'angle-up' : 'angle-down'} />
              </View>
            )}
          >
            {useCases.map(item => (
              <View key={item.commandId}>
                <Checkbox
                  size="extra-small"
                  checked={item.commandId === useCase}
                  containerCss={styles.checkboxGroup.container}
                  innerCss={styles.checkboxGroup.inner}
                  onValueChange={val => {
                    if (val) {
                      onChangeUseCase(item.commandId);
                    } else {
                      onChangeUseCase('');
                    }
                  }}
                >
                  <Text size={14}>{item.description}</Text>
                </Checkbox>
                <Space size={10} />
              </View>
            ))}
          </Collapse>
        }
      />
    </>
  );
};
