import { ActionTypes, createReducer, handleAction } from 'wiloke-react-core/utils';
import { ThemeInteracting } from '../@types/ThemeInteracting';
import { ActionExportVariant } from '../@types/VariantOfActionExport';
import * as actions from './actions';

interface State {
  modalExtractThemeToFileForSync: ThemeInteracting | undefined;
  themeExtracting: ThemeInteracting | undefined;
  prevThemeExtractFailed:
    | {
        data: ThemeInteracting;
        actionExportVariant: ActionExportVariant;
      }
    | undefined;
  expectProcesses: string[];
  actualProcessed: string[];
}
type Actions = ActionTypes<typeof actions>;

const initialState: State = {
  actualProcessed: [],
  expectProcesses: [],
  modalExtractThemeToFileForSync: undefined,
  prevThemeExtractFailed: undefined,
  themeExtracting: undefined,
};

export const reducerExtractThemeToFileForSync = createReducer<State, Actions>(initialState, [
  handleAction('@ExtractThemeToFileForSync/request', ({ state, action }) => {
    const { dataInteracting } = action.payload;
    return {
      ...state,
      themeExtracting: dataInteracting,
      prevThemeExtractFailed: undefined,
    };
  }),
  handleAction('@ExtractThemeToFileForSync/success', ({ state }) => {
    return {
      ...state,
      themeExtracting: undefined,
    };
  }),
  handleAction('@ExtractThemeToFileForSync/failure', ({ state, action }) => {
    const { dataInteracting, actionExportVariant } = action.payload;
    return {
      ...state,
      themeExtracting: undefined,
      prevThemeExtractFailed: {
        data: dataInteracting,
        actionExportVariant,
      },
    };
  }),
  handleAction('@ExtractThemeToFileForSync/setModalExtract', ({ state, action }) => {
    state.modalExtractThemeToFileForSync = action.payload;
  }),
  handleAction('@ExtractThemeToFileForSync/setExpectProcesses', ({ state, action }) => {
    return {
      ...state,
      expectProcesses: action.payload,
    };
  }),
  handleAction('@ExtractThemeToFileForSync/initSessionOfProcesses', ({ state }) => {
    return {
      ...state,
      actualProcessed: [],
    };
  }),
  handleAction('@ExtractThemeToFileForSync/pushActualProcesses', ({ state, action }) => {
    return {
      ...state,
      actualProcessed: state.actualProcessed.concat(action.payload),
    };
  }),
]);
