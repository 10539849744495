import { File, Vendor } from 'types/Result';

interface GetVendorsOfPage {
  vendors: Vendor[];
}

export const getVendorsOfPage = ({ vendors }: GetVendorsOfPage): [File, File] => {
  let css = `
    {% comment %} vendors css of page {% endcomment %}
  `;
  let js = `
    {% comment %} vendors js of page {% endcomment %}
  `;
  vendors.forEach(item => {
    if (item.css) {
      css += `<link rel="stylesheet" href="${item.css}" />\n`;
    }
    if (item.js) {
      js += `<script src="${item.js}"></script>\n`;
    }
  });
  return [
    {
      content: css,
      type: 'vendors css tổng của page',
      id: 'vendorsCss',
      name: 'vendorsCss',
      section: undefined,
    },
    {
      content: js,
      type: 'vendors js tổng của page',
      id: 'vendorsJs',
      name: 'vendorsJs',
      section: undefined,
    },
  ];
};
