import { Consts } from 'utils/constants/constants';

export const builderPage = {
  back_to_dashboard2: 'Back to dashboard',
  page_under_maintaince: 'Page is be under maintaince',
  page_error: 'Something went wrong',
  get_support: 'Get support',
  modal_login_shopify: {
    okText: 'I logged in',
    description: 'Remember to log in to your Shopify store to see the preview properly',
  },
  shopify_entity_non_exist: {
    title: 'There have been some changes at your shop.',
    collection: 'The collection does not exist or has been changed. Please select before proceeding.',
    product: 'The product does not exist or has been changed. Please select before proceeding.',
    blog: 'The blog does not exist or has been changed. Please select before proceeding.',
  },
  fields: {
    reorder: 'This arrangement only helps you manage the settings below, but does not affect the content of the page.',
  },
  schema: {
    settings: {
      title: 'Settings',
      text: '',
    },
    blocks: {
      title: 'Blocks',
      text: '',
    },
    error: {
      existed: '"%% name %%" is declared in schema',
      is_shopify_variable: '"%% name %%" is shopify variable',
    },
  },
  theme_settings: {
    general: {
      title: 'General',
      text: 'The general setting for current theme',
    },
    colors: {
      title: 'Colors',
      text: 'Manage colors variations',
      content: {
        title: 'Custom colors for theme',
        text: 'You can edit or add colors below',
      },
    },
    color_schemes: {
      title: 'Color Schemes',
      text: 'Manage color schemes',
      content: {
        title: 'Create Your Own Color Schemes',
        text: 'Express your creativity and design unique color schemes for your theme.',
      },
    },
    fonts: {
      title: 'Fonts',
      text: 'Manage all font variations',
      content: {
        title: 'Custom fonts',
        text: 'You can edit or add fonts below',
      },
    },
    layout: {
      title: 'Layout',
      text: 'Manage layout for site',
    },
    vendors: {
      title: 'Vendors',
      text: 'Add External Css & Javascript for theme',
    },
    scss: {
      title: 'SCSS (CSS)',
      text: 'Add global SCSS for theme',
    },
    js: {
      title: 'Javascript',
      text: 'Add global Javascript for theme',
    },
    content: {
      colors: {
        title: 'Custom colors',
        text: 'You can edit or add colors below (Note the variable name used when you edit the CSS code)',
      },
      fonts: {
        title: 'Custom fonts',
        text: 'You can edit or add fonts below (Note the variable name used when you edit the CSS code)',
      },
    },
  },
  page_settings: {
    general: {
      title: 'General',
      text: 'The general setting for current page',
    },
    vendors: {
      title: 'Vendors',
      text: 'Add External Css & Javascript',
    },
    scss: {
      title: 'SCSS (CSS)',
      text: 'Add global SCSS for page',
    },
    js: {
      title: 'Javascript',
      text: 'Add global Javascript for page',
    },
    import: {
      title: 'Import file',
      text: `Import file *.${Consts.AppName} to continue editing the current page`,
      select_options: 'Select options to import',
      error: 'Your file is corrupted',
      upload_successfully: 'Upload successfully',
      upload_failed: 'Upload failed',
      options: {
        page_data: 'Page data',
        page_settings: 'Page settings',
        theme_settings: 'Theme settings',
      },
    },
    general_settings: {
      meta_title_placeholder: 'Meta title will be shown here',
      meta_description_placeholder: 'Meta description will be shown here',
      general: {
        title: 'General',
        text: 'General settings for current page',
        name: 'Page name',
        enable_header_footer: 'Enable Shopify Theme Header & Footer',
      },
      seo: {
        title: 'SEO',
        text: 'Fill out necessary information for search engine listing',
        url_slug: {
          title: 'URL slug',
          text: 'Lorem ipsum dolor sit amet',
        },
        meta_title: {
          title: 'Meta title',
          text: 'Lorem ipsum dolor sit amet',
        },
        meta_description: {
          title: 'Meta description',
          text: 'Lorem ipsum dolor sit amet',
        },
      },
      social_share: {
        title: 'Featured Image',
        text: 'Lorem ipsum dolor sit amet',
      },
    },
  },
  layout_settings: {
    container_width: {
      title: 'Container width',
      text: 'Set the default width of the content area (Default: %% size %%px)',
    },
    container_gap: {
      title: 'Container gap',
      text: 'Set the default column gap (Default: %% size %%px)',
    },
    column_gap_vertical: {
      title: 'Column gap vertical',
      text: 'Set the default space between elements (Default: %% size %%px)',
    },
    column_gap_horizontal: {
      title: 'Column gap horizontal',
      text: 'Set the default space between elements (Default: %% size %%px)',
    },
  },
  theme_general_settings: {
    preloader: {
      title: 'Preloader',
      text: '',
      testing: 'Run for 2 seconds after you click the Ok button.',
    },
    favicon: {
      title: 'Favicon',
      text: '',
    },
  },
  mega_menu: {
    choose_setting: 'Choose setting',
    warning: 'You cannot add children to this item when you enable mega menu',
    icon: 'Icon %% text %%',
    hot_spot: 'Hot spot',
    navigation: 'Navigation',
  },
  save_for_builder: {
    preview_image: 'Preview image',
    page_types: 'Page types',
    add_category: 'Add category',
    please_enter_item: 'Please enter item',
    can_add_multi: 'Can add multi',
    enable_position: 'Enable position',
    addons_type: 'Addons Type',
    logo: 'Logo',
    detail: 'Detail',
    save_page: 'Save page',
    create_new_page: 'Create a new page',
    save_theme: 'Save theme',
    create_new_theme: 'Create a new theme',
    link_preview: 'Link preview',
    create_update_section_page_type:
      'Sections are added depending on what the page type is. For example: When creating an Article page, when selecting the template section, the sections with type product/collection.v.v will not be displayed.',
  },
  choose_template: {
    delete_section: 'Are you sure to delete this section?',
    import_section_failed: 'The data of this file is not a section',
    cannot_use_section: 'This section cannot be used in this type of page - current page type: %% type %%',
    import_addons_failed: 'The data of this file is not a addons',
    import_section_addons_failed: 'The data of this file is not a section of addons',
  },
  delete_addons_to_keep: 'This section contains the following add-ons. Check if you want to remove them from the add-ons tab.',
  duplicate_addons_to_keep: 'This section contains the following add-ons. Click on the checkmark to keep it',
  back_to_dashboard: 'This page has unsaved changes. If you leave now, these changes will be lost.',
  remove_addons_in_sections: 'These add-ons are being used in these sections. Check it if you want to delete add-ons used in that section',
  extracting: 'Result is being extracted',
  saving: 'Saving',
  publish_override: 'Publish and override',
  publish_not_override: 'Publish but do not override',
  publish_ask_text: 'Your data will be updated to shopify. This data will affect other %% pageType %% pages. Do you still want to override?',
  save_and_publish: 'Save and Publish',
  add_more_language: 'Add more language',
  select_language: 'Select the language you want to edit',
  change_file_name: 'Change file name',
  translation_file: 'translation file',
  must_have_section: 'Your template is missing main sections, please add at least 1 main section',
  save_as_draft: 'Save as draft',
  save_to_my_page: 'Save to my page',
  use: 'Use',
  lazy_load_image: 'Lazy load for image',
  coming_soon: 'Coming soon',
  in: 'in',
  out: 'out',
  only_have_one: 'Only add 1',
  cannot_duplicate: 'Cannot duplicate',
  override_content: 'This data will affect other %% pageType %% pages. Do you still want to override?',
  override: 'Override',
  not_override: 'Do not override',
  confirm_override: 'Confirm override',
  network_error: 'Network Error',
  retry: 'Retry',
  compose_email: 'Click icon to compose an email',
  select_url: 'Click icon to select URL',
  send_email: 'Send Email',
  phone_number: 'Phone Number',
  custom_url: 'Custom URL',
  search: 'Search %% text %%',
  link_shopify: 'Link shopify',
  js_hook_state: '// This js is geared towards section templates but only works at builder\n// Can use functions: hiddenFieldAction, redirectTo',
  built_in: 'Built In',
  third_party: 'Third Party',
  atom: 'Atom',
  draft: 'Draft',
  loaded: 'loaded',
  preset: 'Preset %% text %%',
  exit_without_saving: 'Exit without saving',
  save_for: 'Save for %% text %%',
  builder: 'builder',
  dev: 'dev',
  must_create_changelog: 'Must create changelog first',
  increase_version_to_update_section: 'Increase version to update section/addon',
  update_version: {
    deleted_old_fields: '%% text %% will be removed in the newest version',
    added_new_fields: 'New %% text %% wil be added in the newest version',
    updated_fields: '%% text %% will be updated in the newest version',
  },
  data_request: {
    loading: 'Getting data',
    success: 'Get data successful',
    failed: 'Get data failed',
  },
  data_not_selected: '%% text %% not selected yet',
  move_to: {
    previous: 'Move to previous position',
    next: 'Move to next position',
    header: 'Move to header',
    footer: 'Move to footer',
    main: 'Move to main',
    disable: 'Cannot move',
  },
  delete_atom: '%% text %% has been published, deleting in admin means deleting in product',
  select_pages_to_sync: 'Select pages to sync to shopify, the remaining pages are still saved',
  delete_from: 'Delete from %% text %%',
  uninstall: 'Uninstall',
  upgrade_plan: 'Please upgrade to %% function_plan %% to use this function.',
  scroll_to_section: 'Scroll to section',
  buy_now: 'Buy now',
  buy_now_condition: 'Only works in Product page',
  click_to_add_main: 'Click here to add main section',
} as const;
