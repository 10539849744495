import { put, retry, SagaReturnType, takeLatest } from '@redux-saga/core/effects';
import { getActionType } from 'wiloke-react-core/utils';

import { duplicatePageClientAPI } from 'services/PageService/Logic/duplicatePageClient';
import { actionDuplicateProductPage } from '../actions';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { AxiosError } from 'axios';

function* handleCreate({ payload }: ReturnType<typeof actionDuplicateProductPage.request>) {
  const { commandId } = payload;
  try {
    const response: SagaReturnType<typeof duplicatePageClientAPI> = yield retry(3, 1000, duplicatePageClientAPI, { commandId });
    notifyAxiosHandler.handleSuccess(response.message);
    yield put(actionDuplicateProductPage.success({ item: response.info, commandId }));
  } catch (error) {
    notifyAxiosHandler.handleError(error as AxiosError<ErrorData> | Error);
    yield put(actionDuplicateProductPage.failure({ commandId }));
  }
}

export function* watchDuplicateProductPage() {
  yield takeLatest(getActionType(actionDuplicateProductPage.request), handleCreate);
}
