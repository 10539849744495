import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

interface GetPagesOfThemeAtom {
  commandIds: string[];
}

export const getPagesOfThemeAtom = async ({ commandIds }: GetPagesOfThemeAtom) => {
  if (!commandIds.length) {
    return [];
  }
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const response = await themeApis.atom.adminApi.page.getPagesOfAtom({
      commandIds: commandIds.join(','),
      cacheVersion: lastCache,
    });
    return response.info;
  }
  if (role === 'dev' || role === 'support') {
    const response = await themeApis.atom.devApi.page.getPagesOfAtom({
      commandIds: commandIds.join(','),
      cacheVersion: lastCache,
    });
    return response.info;
  }
  throw new RoleException();
};
