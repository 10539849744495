import { Button, MyModal } from '@wiloke/ui';
import { notification } from 'antd';
import ChooseImage from 'components/ChooseImage';
import Field from 'components/Field';
import SelectAntd from 'components/SelectAntd';
import SwitchBeauty from 'components/SwitchBeauty';
import TextInput from 'components/TextInput';
import { useSaveDraftMegaMenu, useSaveDraftSection } from 'containers/BuilderPage/store/saveForBuilder/actions';
import { modalDevSectionVisibleSelector, useSetModalDevSectionVisible } from 'containers/BuilderPage/store/saveForBuilder/slice';
import { useAddDraftCategory, useGetDraftCategories, useLoadMoreDraftCategories } from 'containers/ChooseTemplate/store/actions';
import { draftCategorySelector, transformDraftCategories } from 'containers/ChooseTemplate/store/reducers/sections/draft.reducerCategory';
import withDebounce from 'hocs/withDebounce';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { saveForBuilderSelector } from 'store/selectors';
import { i18n } from 'translation';
import { DevSection, PageSectionType } from 'types/Sections';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { Divider, View } from 'wiloke-react-core';
import { useSaveInfoSection } from './utils/useSaveInfoSection';

const DebounceInput = withDebounce(TextInput, 'value', 'onValueChange');

const MAX_CHARACTER = 25;

export const ModalDevSection: FC = () => {
  const modalSectionVisible = useSelector(modalDevSectionVisibleSelector);
  const { saveSectionStatus } = useSelector(saveForBuilderSelector);
  const {
    addStatus,
    categories: devCategories,
    getStatus: getCateStatus,
    loadMoreStatus: loadMoreCateStatus,
    hasNextPage: hasNextPageCate,
  } = useSelector(draftCategorySelector);
  const categories = useSelector(transformDraftCategories);

  const setModalSectionVisible = useSetModalDevSectionVisible();
  const saveSectionForBuilder = useSaveDraftSection();
  const addCategory = useAddDraftCategory();
  const getCategories = useGetDraftCategories();
  const saveDraftMegaMenu = useSaveDraftMegaMenu();
  const loadMoreCategories = useLoadMoreDraftCategories();

  const { id: userId } = getUserInfo();

  const { label, changelog, category, image, createdCategory, section, methodType, type, dispatch } = useSaveInfoSection();

  useEffect(() => {
    if (modalSectionVisible) {
      getCategories.request(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalSectionVisible]);

  const handleAddCategory = () => {
    if (createdCategory.name && createdCategory.description) {
      addCategory.request({ description: createdCategory.description, name: createdCategory.name });
      dispatch({
        type: 'setInfoSection',
        payload: {
          createdCategory: {
            name: '',
            description: '',
          },
        },
      });
    }
  };

  const handleChangeCategory = (val: string, option: any) => {
    dispatch({
      type: 'setInfoSection',
      payload: {
        category: {
          description: option.children,
          name: val,
        },
      },
    });
  };

  const handleSaveSection = () => {
    const cateId = devCategories.filter(item => item.name === category.name)[0];
    if (section) {
      const newSection: DevSection = {
        ...((section as unknown) as DevSection),
        label,
        image,
        status: methodType === 'create' ? 'draft' : ((section as unknown) as DevSection).status,
        changelog: changelog,
        id: section.id,
        category:
          type !== 'megamenu'
            ? {
                name: cateId ? cateId.name : category.name,
                description: cateId ? cateId.description : category.description,
                commandId: cateId ? cateId.commandId : section?.category?.commandId ?? '',
              }
            : undefined,
        userId: methodType === 'create' ? userId : section.userId ? section.userId : userId,
        type: type,
      };
      if (type === 'megamenu') {
        saveDraftMegaMenu.request({ type: methodType, data: newSection });
      } else {
        saveSectionForBuilder.request({ type: methodType, data: newSection });
      }
    }
  };

  const handleCancel = () => {
    if (type === 'megamenu') {
      saveDraftMegaMenu.cancel();
    } else {
      saveSectionForBuilder.cancel();
    }
    setModalSectionVisible(false);
  };

  const handleChangeMethodType = (value: boolean) => {
    if (!section?.commandId) {
      dispatch({
        type: 'setInfoSection',
        payload: {
          methodType: 'create',
        },
      });
      // TODO: I18n
      notification.error({
        message: 'Không đúng nghiệp vụ',
        description: 'Bạn đang install section để tạo mới chứ không phải update',
      });
    } else {
      dispatch({
        type: 'setInfoSection',
        payload: {
          methodType: value ? 'create' : 'update',
        },
      });
    }
  };

  const handleChangeTitle = (val: string) => {
    if (val.length <= MAX_CHARACTER) {
      dispatch({
        type: 'setInfoSection',
        payload: {
          label: val,
        },
      });
    }
  };

  const handleChangeCreateCategory = (val: string) => {
    dispatch({
      type: 'setInfoSection',
      payload: {
        createdCategory: {
          description: val,
          name: val ? val : '',
        },
      },
    });
  };

  const handleChangeSectionType = (val: any) => {
    dispatch({
      type: 'setInfoSection',
      payload: {
        type: val as PageSectionType,
      },
    });
  };

  const handleSetChangelog = (val: string) => {
    dispatch({
      type: 'setInfoSection',
      payload: {
        changelog: val,
      },
    });
  };

  const handleChangeImage = ({ src, width, height }: { src: string; height: number; width: number }) => {
    dispatch({
      type: 'setInfoSection',
      payload: {
        image: {
          src: src,
          width: width ?? 0,
          height: height ?? 0,
        },
      },
    });
  };

  return (
    <MyModal
      size="medium"
      isLoading={saveSectionStatus === 'loading'}
      headerText={`${i18n.t('general.save', { text: i18n.t('general.section') })}`}
      depsHeightRecalculation={section}
      isVisible={modalSectionVisible}
      onCancel={handleCancel}
      onOk={handleSaveSection}
      okText={i18n.t('general.save', { text: 'to dev database' })}
    >
      <View css={{ margin: 'auto', padding: '20px 0' }}>
        <Field label={i18n.t('general.createNewSection')}>
          <SwitchBeauty
            checked={methodType === 'create'}
            radius={6}
            borderColor="gray3"
            borderWidth={1}
            enableText={i18n.t('general.enable')}
            disableText={i18n.t('general.disable')}
            onValueChange={handleChangeMethodType}
          />
        </Field>

        <Field
          label={
            <View>
              {i18n.t('general.title')}{' '}
              <View tagName="span">
                (
                <View tagName="i">
                  Max Length: {label.length}/{MAX_CHARACTER}
                </View>
                )
              </View>
            </View>
          }
        >
          <DebounceInput value={label} maxLength={MAX_CHARACTER} block sizeInput="medium" onValueChange={handleChangeTitle} />
        </Field>

        {type !== 'megamenu' ? (
          <Field label={i18n.t('general.category')}>
            <SelectAntd
              data={categories}
              value={category?.name}
              onChange={handleChangeCategory}
              filterOption={(input, option) => {
                return ((option?.children as unknown) as string).toLowerCase().includes(input.toLowerCase());
              }}
              showSearch
              dropdownRender={menu => {
                return (
                  <>
                    {menu}
                    <Divider />
                    <View css={{ padding: '8px 0 4px 0', display: 'flex', justifyContent: 'space-between' }}>
                      <View columns={[10, 10, 10]}>
                        <DebounceInput
                          value={createdCategory.description}
                          block
                          placeholder={i18n.t('builderPage.save_for_builder.please_enter_item')}
                          sizeInput="small"
                          css={{ height: '40px', width: '250px' }}
                          onValueChange={handleChangeCreateCategory}
                        />
                      </View>
                      <View columns={[2, 2, 2]}>
                        <Button block radius={4} size="extra-small" loading={addStatus === 'loading'} onClick={handleAddCategory}>
                          {i18n.t('builderPage.save_for_builder.add_category')}
                        </Button>
                      </View>
                    </View>
                  </>
                );
              }}
              loading={loadMoreCateStatus === 'loading'}
              onPopupScroll={e => {
                const { scrollHeight, scrollTop, clientHeight } = e.target as Element;
                const lastCursor = categories.at(-1)?.commandId;
                if (scrollHeight - scrollTop - clientHeight < 300 && getCateStatus === 'success' && lastCursor && hasNextPageCate) {
                  loadMoreCategories.request({ cursor: lastCursor });
                }
              }}
            />
          </Field>
        ) : null}

        <Field label={i18n.t('general.type')}>
          <SelectAntd
            data={[
              {
                value: 'default',
                label: 'Default',
              },
              {
                value: 'megamenu',
                label: 'Mega menu',
              },
            ]}
            value={type}
            onChange={handleChangeSectionType}
          />
        </Field>

        <Field label={i18n.t('general.description')}>
          <DebounceInput value={changelog} block sizeInput="medium" onValueChange={handleSetChangelog} />
        </Field>

        <Field label={i18n.t('builderPage.save_for_builder.preview_image')} width={400}>
          <ChooseImage value={image} onChange={handleChangeImage} mode="popup" />
        </Field>
      </View>
    </MyModal>
  );
};
