import { ScrollBars } from '@wiloke/ui';
import Drawer from 'components/Drawer';
import { useSetResponsive } from 'containers/BuilderPage/store/responsive/slice';
import { useSetPageSelectedInSaveTheme, useSetVisibleModalSaveTheme } from 'containers/BuilderPage/store/saveForBuilder/slice';
import { useSetSelectPage } from 'containers/BuilderPage/store/selectPage/slice';
import { useSetTwigLoading } from 'containers/BuilderPage/store/twigLoading/slice';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { pagesSelector } from 'store/selectors';
import { i18n } from 'translation';
import { Page, PageType } from 'types/Page';
import getPageInfo from 'utils/functions/getInfo';
import { FontAwesome, Text, View } from 'wiloke-react-core';
import * as styles from '../styles';
import { PagesSelection } from './PagesSelection';

const pageTypes: Array<{ label: string; value: PageType }> = [
  {
    value: 'home',
    label: 'Home Page',
  },
  {
    value: 'product',
    label: 'Product Page',
  },
  {
    value: 'collection',
    label: 'Collection Page',
  },
  {
    value: 'article',
    label: 'Article Page',
  },
  {
    value: 'blog',
    label: 'Blog Page',
  },
  {
    value: 'page',
    label: 'Regular Page',
  },
  {
    value: 'account',
    label: 'Account Page',
  },
  {
    value: 'activateAccount',
    label: 'Activate Account Page',
  },
  {
    value: 'addresses',
    label: 'Address Page',
  },

  {
    value: 'cart',
    label: 'Cart Page',
  },
  {
    value: 'collections',
    label: 'Collection Listing Page',
  },
  // {
  //   value: 'giftCard',
  //   label: 'Gift Card Page',
  // },
  {
    value: 'login',
    label: 'Login Page',
  },
  {
    value: 'order',
    label: 'Order Page',
  },
  {
    value: 'pageNotFound',
    label: 'Not Found Page',
  },
  {
    value: 'password',
    label: 'Password Page',
  },

  {
    value: 'register',
    label: 'Register Page',
  },
  {
    value: 'resetPassword',
    label: 'Reset Password Page',
  },
  {
    value: 'search',
    label: 'Search Page',
  },
];

interface DropdownContentProps {
  onChangePage?: () => void;
  canRedirect: boolean;
}

export const DropdownContent: FC<DropdownContentProps> = ({ onChangePage, canRedirect }) => {
  const [drawerActive, setDrawerActive] = useState(false);
  const [pageType, setPageType] = useState<PageType>('home');
  const pages = useSelector(pagesSelector);
  const id = getPageInfo('id');
  const setVisibleSaveTheme = useSetVisibleModalSaveTheme();
  const setPageSelectedInSaveTheme = useSetPageSelectedInSaveTheme();

  const setSelectPage = useSetSelectPage();
  const setTwigLoading = useSetTwigLoading();
  const history = useHistory<'/builder'>();
  const setDeviceResponsive = useSetResponsive();

  const handleChangePage = async (page: Page) => {
    onChangePage?.();
    if (page.commandId !== getPageInfo('id')) {
      if (canRedirect) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.delete('id');
        urlSearchParams.append('id', page.commandId);
        history.push(`/builder?${urlSearchParams.toString()}`, history.location.state);
        setSelectPage(true);
        setTwigLoading(true);
        setDeviceResponsive('desktop');
      } else {
        setVisibleSaveTheme(true);
        setPageSelectedInSaveTheme([page.commandId]);
      }
    }
  };

  return (
    <Drawer
      active={drawerActive}
      height="50%"
      scrollDisabled
      slideLeft={300}
      Content={
        <View>
          <View
            css={styles.dropdownItem(false)}
            onClick={() => {
              setDrawerActive(false);
            }}
          >
            <FontAwesome type="far" name="arrow-left" css={{ marginRight: '6px' }} />
            {i18n.t('general.back')}
          </View>

          <PagesSelection pageType={pageType} onChange={handleChangePage} />
        </View>
      }
    >
      <ScrollBars css={{ height: '400px !important', width: '100%' }}>
        {pageTypes.map(item => (
          <View
            key={item.value}
            css={styles.dropdownItem(pages.data[id].type === item.value)}
            onClick={() => {
              setDrawerActive(true);
              setPageType(item.value);
            }}
          >
            <Text numberOfLines={1}>{item.label}</Text>
            <FontAwesome color={pages.data[id].type === item.value ? 'primary' : 'gray8'} type="far" name="angle-right" size={16} />
          </View>
        ))}
      </ScrollBars>
    </Drawer>
  );
};
