import { debounce, imageUrl } from '@wiloke/functions';
import { AsyncComponent } from '@wiloke/ui';
import { notification } from 'antd';
import { ChooseCard } from 'components/ChooseCard';
import ChooseCardLoading from 'components/ChooseCard/ChooseCardLoading';
import {
  useDeleteMyMedia,
  useFilterMyMedia,
  useGetMyMedia,
  useLoadMoreMyMedia,
  useRemoveBackground,
  useUploadFileToMyMedia,
} from 'components/ChooseImage/actions/actionMyMedia';
import { useChooseImage } from 'components/ChooseImage/context/ChooseImageContext';
import { imageGallerySelector } from 'components/ChooseImage/selector';
import { MyMedia } from 'components/ChooseImage/types/MyMedia';
import { imageUrlToFile } from 'components/ChooseImage/utils/imageUrlToFile';
import { swapPickedImage } from 'components/ChooseImage/utils/swapPickedImage';
import { useOnChangeImage } from 'components/ChooseImage/utils/useSetImage';
import { DragUploadAntdProps, MyUpload } from 'components/DragUploadAntd';
import Field from 'components/Field';
import { IconPopupBox } from 'components/IconPopupBox';
import SingleDatePicker from 'components/SingleDatePicker';
import TextInput from 'components/TextInput';
import UploadPlaceholder from 'components/UploadPlaceholder';
import VirtualList from 'components/VirtualList/VirtualList';
import withDebounce from 'hocs/withDebounce';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { i18n } from 'translation';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { v4 } from 'uuid';
import { FontAwesome, GridSmart, View, ViewportTracking } from 'wiloke-react-core';
import * as styles from '../../styles';
import { COLUMN_GAP, ROW_HEIGHT } from './constants';
import { SettingTabProps } from './Tabs';

const InputDebounce = withDebounce(TextInput, 'value', 'onValueChange', 500);

const MyMedias: FC<SettingTabProps> = () => {
  const { my_media } = useSelector(imageGallerySelector);
  const { data, hasNextPage, statusRequest, statusLoadmore, queueDelete, statusUpload, filterDate, imageName, removeBackgroundStatus } = my_media;
  const getMyMedia = useGetMyMedia();
  const loadmoreMyMedia = useLoadMoreMyMedia();
  const uploadImage = useUploadFileToMyMedia();
  const deleteImage = useDeleteMyMedia();
  const removeBackground = useRemoveBackground();

  const { loading, imageMode, image: inputImage, dispatch } = useChooseImage();
  const { handleChangeImage } = useOnChangeImage();
  const filterMyMedia = useFilterMyMedia();
  const numOfCols = imageMode === 'popup' ? 4 : 2;
  const { role } = getUserInfo();

  useEffect(() => {
    getMyMedia.request({ date: filterDate, name: imageName });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDate, imageName]);

  const handleLoadMore = debounce(() => {
    if (statusLoadmore !== 'loading' && hasNextPage) {
      loadmoreMyMedia.request({ date: filterDate, name: imageName });
    }
  }, 50);

  const handleRemoveBg = ({ url, name }: { url: string; name: string }) => async () => {
    const file = await imageUrlToFile({
      url: url,
      name: name,
    });

    removeBackground.request({
      data: { file },
      name,
      url,
    });
  };

  const handleUpdate: DragUploadAntdProps['customRequest'] = ({ file, onSuccess, onError }) => {
    const _file = file as File;

    notification.info({
      message: i18n.t('general.uploading', { text: _file.name }),
      key: _file.name,
    });
    uploadImage.request({
      file: _file,
      uniqId: v4(),
      onSuccess: () => {
        onSuccess?.(i18n.t('general.success'));
        notification.close(_file.name);
      },
      // TODO: I18n
      onFailure: message => {
        onError?.(new Error(message));
        notification.close(_file.name);
        notification.error({
          message: `Upload failed: ${_file.name}`,
          description: message,
          key: _file.name,
        });
      },
    });
  };

  const handleDelete = (image: MyMedia) => () => {
    if (!!image) {
      deleteImage.request({
        image,
        onFulFill: deletedImage => {
          if (inputImage && inputImage.src === deletedImage.url) {
            dispatch({
              type: 'SetImage',
              payload: {
                image: undefined,
              },
            });
          }
        },
      });
    } else {
      notification.warn({
        message: 'Image does not exist',
      });
    }
  };

  const renderSuccess = () => {
    const rowCount = Math.ceil(data.length / numOfCols) + (hasNextPage ? 2 : 1);
    return (
      <VirtualList
        rowCount={rowCount}
        rowHeight={ROW_HEIGHT}
        rowRender={index => {
          const _data = swapPickedImage(data, inputImage) as MyMedia[];
          const dataSlice = _data.slice(index * numOfCols, index * numOfCols + numOfCols);
          const rowItem = dataSlice.length ? dataSlice : hasNextPage ? Array(numOfCols).fill(undefined) : [];

          return (
            <GridSmart columnWidth={100} columnCount={numOfCols} columnGap={COLUMN_GAP}>
              {rowItem.map((item, index) => {
                if (typeof item === 'undefined' && statusLoadmore !== 'failure') {
                  return (
                    <ViewportTracking key={index} offsetTop={-100} onEnterViewport={handleLoadMore}>
                      <ChooseCardLoading containerStyle={{ height: ROW_HEIGHT - COLUMN_GAP }} />
                    </ViewportTracking>
                  );
                } else {
                  return (
                    <ChooseCard
                      key={item?.url}
                      containerStyle={{ height: ROW_HEIGHT - COLUMN_GAP }}
                      uri={imageUrl(item?.url ?? '', 300)}
                      isRequesting={
                        !!queueDelete.find(queueUrl => queueUrl === item?.url) ||
                        loading[item?.url ?? ''] ||
                        removeBackgroundStatus[item?.url] === 'loading'
                      }
                      onEdit={handleChangeImage({ src: item?.url, height: item?.height ?? 0, width: item?.width ?? 0 })}
                      onDelete={handleDelete(item)}
                      onRemoveBg={role === 'admin' ? handleRemoveBg({ url: item?.url, name: item.name }) : undefined}
                    />
                  );
                }
              })}
            </GridSmart>
          );
        }}
      />
    );
  };

  return (
    <View css={styles.uploadContainer}>
      <MyUpload
        containerCss={{ width: '100%', height: '100%' }}
        showUploadList={false}
        multiple
        accept=".png, .jpeg, .jpg, .gif, .webp, .heic, .svg"
        customRequest={handleUpdate}
        disabled={statusUpload === 'loading'}
        maxSize={5}
        Icon={
          <View css={styles.iconContainer}>
            <UploadPlaceholder css={{ flex: '1' }} loading={statusUpload === 'loading'} />

            <View
              tagName="span"
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                getMyMedia.request({ date: filterDate, name: imageName });
              }}
              css={styles.icon}
            >
              <FontAwesome type="far" name="sync-alt" color="primary" />
            </View>

            <View
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <IconPopupBox
                containerCss={{ marginLeft: '4px', border: 'unset' }}
                popupPlacement="left"
                content={
                  <>
                    <Field label={i18n.t('builderPage.search')} css={styles.field}>
                      <InputDebounce
                        value={imageName ?? ''}
                        block
                        onValueChange={val => {
                          filterMyMedia({
                            name: val,
                          });
                        }}
                      />
                    </Field>
                    <Field label={i18n.t('schema.fieldLabel.date')} css={{ marginBottom: '0px' }}>
                      <SingleDatePicker
                        minDate={null}
                        date={null}
                        onChange={date => {
                          filterMyMedia({
                            date: date ? date.getTime() : undefined,
                          });
                        }}
                      />
                    </Field>
                  </>
                }
              />
            </View>
          </View>
        }
      >
        <AsyncComponent status={statusRequest} isEmpty={data.length === 0} Success={renderSuccess()} />
      </MyUpload>
    </View>
  );
};

export default MyMedias;
