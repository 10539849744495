import { Button, MyModal } from '@wiloke/ui';
import {
  ModalCreateArticle,
  ModalCreateBlog,
  ModalCreateCollection,
  ModalCreateNormalPage,
  ModalCreateProduct,
} from 'containers/Admin/Modals/components/ModalPageBuilder';
import { ResponsivePage } from 'containers/Admin/Modals/components/ModalPreviewPage/ResponsivePage';
import { useChangeModalAdminSettings } from 'containers/Admin/Modals/store';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';
import { modalsSelector } from 'containers/Admin/selector';
import { Device } from 'containers/BuilderPage/store/responsive/slice';

import { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { BE_PageProduct } from 'services/PageService/VedaApplication/types';
import { useResetPageStatus, useSetPages } from 'store/actions/actionPages';
import { authSelector } from 'store/selectors';
import { i18n } from 'translation';
import { PLAN_ENUM, PLAN_STATIC_DATA } from 'utils/constants/PlanEnum';
import { validatePlan } from 'utils/functions/validatePlan';
import { FontAwesome, View } from 'wiloke-react-core';
import * as styles from './styles';

interface ModalPreviewPageProps {
  page: BE_PageProduct;
  onCancel?: () => void;
  onInstall?: () => void;
}

export const ModalPreviewPage: FC<ModalPreviewPageProps> = ({ page, onCancel, onInstall }) => {
  const { createArticle, createBlog, createCollection, createNormalPage, createProduct } = useSelector(modalsSelector);
  const { plan, role } = useSelector(authSelector);

  const setPages = useSetPages();
  const resetPageStatus = useResetPageStatus();
  const changeSettings = useChangeModalAdminSettings();
  const [responsive, setResponsive] = useState<Device>('desktop');
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();

  const handleCancel = () => {
    setPages({});
    resetPageStatus();
    setResponsive('desktop');
    onCancel?.();
  };

  const handleAddPage = () => {
    if (validatePlan({ userPlan: plan.planHandle, itemPlan: page.planHandle ?? PLAN_ENUM.free, role }) === 'pass') {
      if (page.type === 'collection') {
        changeSettings({ createCollection: true });
      } else if (page.type === 'product') {
        changeSettings({ createProduct: true });
      } else if (page.type === 'blog') {
        changeSettings({ createBlog: true });
      } else if (page.type === 'article') {
        changeSettings({ createArticle: true });
      } else {
        changeSettings({ createNormalPage: true });
      }
      onInstall?.();
    } else {
      setPlanRequesting({
        handle: PLAN_STATIC_DATA[page.planHandle ?? PLAN_ENUM.business].handle,
        type: PLAN_STATIC_DATA[page.planHandle ?? PLAN_ENUM.business].type,
        pricePerMonth: PLAN_STATIC_DATA[page.planHandle ?? PLAN_ENUM.business].pricePerMonth,
        pricePerYear: PLAN_STATIC_DATA[page.planHandle ?? PLAN_ENUM.business].pricePerYear,
        title: PLAN_STATIC_DATA[page.planHandle ?? PLAN_ENUM.business].title,
        originalPricePerMonth: PLAN_STATIC_DATA[page.planHandle ?? PLAN_ENUM.business].pricePerMonth,
        originalPricePerYear: PLAN_STATIC_DATA[page.planHandle ?? PLAN_ENUM.business].pricePerYear,
      });
      setValidateCouponVisible(true);
    }
  };

  const handleCancelAddPage = useCallback(() => {
    if (page.type === 'collection') {
      changeSettings({ createCollection: false });
    } else if (page.type === 'product') {
      changeSettings({ createProduct: false });
    } else if (page.type === 'blog') {
      changeSettings({ createBlog: false });
    } else if (page.type === 'article') {
      changeSettings({ createArticle: false });
    } else {
      changeSettings({ createNormalPage: false });
    }
  }, [changeSettings, page.type]);

  return (
    <MyModal
      disabledCloseButton
      scrollDisabled
      headerText={
        <View css={styles.modal.header}>
          <View css={{ display: 'flex', alignItems: 'center', columnGap: '10px', width: '20%' }}>
            <FontAwesome name="long-arrow-left" type="far" onClick={handleCancel} css={{ cursor: 'pointer' }} />
            <View>{page.label}</View>
          </View>
          <View css={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
            <ResponsivePage responsive={responsive} setResponsive={setResponsive} />
          </View>
          <View css={{ width: '20%', display: 'flex', justifyContent: 'flex-end', columnGap: '10px' }}>
            <Button size="extra-small" backgroundColor="gray3" color="gray9" radius={4} onClick={handleCancel}>
              {i18n.t('general.cancel')}
            </Button>
            <Button size="extra-small" radius={4} onClick={handleAddPage}>
              {i18n.t('general.install')}
            </Button>
          </View>
        </View>
      }
      cancelText=""
      okText=""
      size="large"
      isVisible
      onCancel={handleCancel}
      bodyCss={styles.modal.body}
      contentCss={{ padding: '0px' }}
    >
      <View className="modal-page-preview" css={styles.previewContainer(responsive)}>
        <iframe
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
          }}
          src={page.previewUrl}
        />
      </View>

      {createNormalPage && (
        <ModalCreateNormalPage
          bodyCss={styles.modal.body}
          pageType={page.type}
          forceActiveDrawer
          pageInput={page as any}
          onCancel={handleCancelAddPage}
        />
      )}
      {createArticle && <ModalCreateArticle bodyCss={styles.modal.body} forceActiveDrawer pageInput={page as any} onCancel={handleCancelAddPage} />}
      {createBlog && <ModalCreateBlog bodyCss={styles.modal.body} forceActiveDrawer pageInput={page as any} onCancel={handleCancelAddPage} />}
      {createCollection && (
        <ModalCreateCollection bodyCss={styles.modal.body} forceActiveDrawer pageInput={page as any} onCancel={handleCancelAddPage} />
      )}
      {createProduct && <ModalCreateProduct bodyCss={styles.modal.body} forceActiveDrawer pageInput={page as any} onCancel={handleCancelAddPage} />}
    </MyModal>
  );
};
