import { getCurrentVersion } from 'utils/CacheControl/VedaClientCacheControl';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { userApis } from '../apis';

export const getPlansService = async () => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    return userApis.plan.adminApi.getPlans(getCurrentVersion().getTime());
  } else {
    return userApis.plan.userApi.getPlans(getCurrentVersion().getTime());
  }
};
