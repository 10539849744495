import { PartnerAPI } from 'types/Partner';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { userApis } from '../apis';

interface UpdateParams extends PartnerAPI {}

export const updatePartnerAPI = async (data: UpdateParams) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    const response = await userApis.partner.adminApi.updatePartner(data);
    return response;
  }
  throw new RoleException();
};
