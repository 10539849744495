import { PageSection } from 'types/Sections';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';

interface GetSectionsOfPageDraft {
  sectionCommandIds: string[];
}

export const getSectionsOfPageDraft = async ({ sectionCommandIds }: GetSectionsOfPageDraft): Promise<PageSection[]> => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    const response = await pageApis.atom.adminApi.section.getSectionsOfDraft({ commandIds: sectionCommandIds });
    return response.info;
  }
  if (role === 'dev' || role === 'support') {
    const response = await pageApis.atom.devApi.section.getSectionsOfDraft({ commandIds: sectionCommandIds });
    return response.info;
  }
  throw new RoleException();
};
