import ColorLib from 'utils/LiquidSyntaxToTwig/libraries/color';

function colorChannelMixer(colorChannelA: number, colorChannelB: number, amountToMix: number) {
  const channelA = colorChannelA * amountToMix;
  const channelB = colorChannelB * (1 - amountToMix);
  // @ts-ignore
  return parseInt(channelA + channelB);
}

const rgbB: [number, number, number] = [255, 255, 255];
export function getGrayColor(color: string, amountToMix: number) {
  const colorInstance = new ColorLib(color);
  const rgbA = [colorInstance.red(), colorInstance.green(), colorInstance.blue()];
  const r = colorChannelMixer(rgbA[0], rgbB[0], amountToMix);
  const g = colorChannelMixer(rgbA[1], rgbB[1], amountToMix);
  const b = colorChannelMixer(rgbA[2], rgbB[2], amountToMix);
  return 'rgb(' + r + ',' + g + ',' + b + ')';
}
