import { MODAL_ACTIVE_THEME_APP_EXTENSION } from 'containers/ModalActiveThemeAppExtension/consts';
import { ModalActiveThemeAppExtension } from 'containers/ModalActiveThemeAppExtension/ModalActiveThemeAppExtension';
import { ModalUpdateShopifyThemeId } from 'containers/ModalUpdateShopifyThemeId/ModalUpdateShopifyThemeId';
import { call, SagaReturnType } from 'redux-saga/effects';
import getPageInfo from 'utils/functions/getInfo';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { getShopifyThemeId } from 'utils/getShopifyThemeId';
import { shopifyConnectionService } from '..';

export function* checkThemeAppStatus() {
  const { role } = getUserInfo();
  if (role === 'user' || role === 'guest') {
    const response: SagaReturnType<typeof shopifyConnectionService.getThemeAppStatus> = yield call(shopifyConnectionService.getThemeAppStatus, {
      currentShopifyThemeIdActived: getShopifyThemeId() ?? '',
    });
    if (response.variant === 'theme_removed') {
      ModalUpdateShopifyThemeId.open({
        type: getPageInfo('themeId') ? 'Theme' : 'Session',
        isErrorShopifyThemeRemoved: true,
        themeCommandId: getPageInfo('themeId'),
      });
      return false;
    } else if (response.variant === 'unactive_theme_app_extension' && response.isActived === false) {
      ModalActiveThemeAppExtension.getActions(MODAL_ACTIVE_THEME_APP_EXTENSION).open({ themeEditor: response.themeEditor });
      return false;
    }
    return true;
  }
  return true;
}
