import { AdminPageDatabase } from 'services/ResultService/atomTypes';
import { AdminTheme } from 'types/Theme';
import { createSlice, ActionTypes, handleAction, createDispatchAction } from 'wiloke-react-core/utils';
import {
  getTrashPagesOfTheme,
  getTrashThemes,
  loadMoreTrashPagesOfTheme,
  loadMoreTrashThemes,
  restorePageTrashTheme,
  restoreTrashTheme,
} from '../actions/actionThemeTrash';

type ExtraAction = ActionTypes<
  | typeof getTrashPagesOfTheme
  | typeof getTrashThemes
  | typeof loadMoreTrashPagesOfTheme
  | typeof loadMoreTrashThemes
  | typeof restoreTrashTheme
  | typeof restorePageTrashTheme
>;

type Actions =
  | {
      type: 'setSearchKeyTrashTheme';
      payload: string;
    }
  | {
      type: 'setSearchKeyPageTrashTheme';
      payload: string;
    };

interface State {
  theme: {
    data: AdminTheme[];
    getStatus: Status;
    loadMoreStatus: Status;
    hasNextPage: boolean;
    searchKey: string;
    queueRestoring: string[];
  };
  page: {
    data: AdminPageDatabase[];
    getStatus: Status;
    loadMoreStatus: Status;
    hasNextPage: boolean;
    searchKey: string;
    queueRestoring: string[];
  };
}

export const sliceTrashTheme = createSlice<State, Actions, ExtraAction>({
  name: 'ThemeManager',
  initialState: {
    page: {
      data: [],
      getStatus: 'idle',
      loadMoreStatus: 'idle',
      hasNextPage: false,
      searchKey: '',
      queueRestoring: [],
    },
    theme: {
      data: [],
      getStatus: 'idle',
      loadMoreStatus: 'idle',
      hasNextPage: false,
      searchKey: '',
      queueRestoring: [],
    },
  },
  reducers: [
    handleAction('setSearchKeyTrashTheme', ({ state, action }) => {
      state.theme.searchKey = action.payload;
    }),
    handleAction('setSearchKeyPageTrashTheme', ({ state, action }) => {
      state.page.searchKey = action.payload;
    }),
  ],
  extraReducers: [
    // page
    handleAction('@ThemeManager/getTrashPagesOfTheme/request', ({ state }) => {
      state.page.getStatus = 'loading';
    }),
    handleAction('@ThemeManager/getTrashPagesOfTheme/success', ({ state, action }) => {
      const { data, hasNextPage } = action.payload;
      state.page.getStatus = 'success';
      state.page.data = data;
      state.page.hasNextPage = hasNextPage;
    }),
    handleAction('@ThemeManager/getTrashPagesOfTheme/failure', ({ state }) => {
      state.page.getStatus = 'failure';
    }),
    handleAction('@ThemeManager/loadMoreTrashPagesOfTheme/request', ({ state }) => {
      state.page.loadMoreStatus = 'loading';
    }),
    handleAction('@ThemeManager/loadMoreTrashPagesOfTheme/success', ({ state, action }) => {
      const { data, hasNextPage } = action.payload;
      state.page.loadMoreStatus = 'success';
      state.page.data = state.page.data.concat(data);
      state.page.hasNextPage = hasNextPage;
    }),
    handleAction('@ThemeManager/loadMoreTrashPagesOfTheme/failure', ({ state }) => {
      state.page.loadMoreStatus = 'failure';
    }),
    handleAction('@ThemeManager/restorePageTrashTheme/request', ({ state, action }) => {
      state.page.queueRestoring = state.page.queueRestoring.concat(action.payload.commandId);
    }),
    handleAction('@ThemeManager/restorePageTrashTheme/success', ({ state, action }) => {
      state.page.queueRestoring = state.page.queueRestoring.filter(item => item !== action.payload.commandId);
    }),
    handleAction('@ThemeManager/restorePageTrashTheme/failure', ({ state, action }) => {
      state.page.queueRestoring = state.page.queueRestoring.filter(item => item !== action.payload.commandId);
    }),

    // theme
    handleAction('@ThemeManager/getTrashThemes/request', ({ state }) => {
      state.theme.getStatus = 'loading';
    }),
    handleAction('@ThemeManager/getTrashThemes/success', ({ state, action }) => {
      const { data, hasNextPage } = action.payload;
      state.theme.getStatus = 'success';
      state.theme.data = data;
      state.theme.hasNextPage = hasNextPage;
    }),
    handleAction('@ThemeManager/getTrashThemes/failure', ({ state }) => {
      state.theme.getStatus = 'failure';
    }),
    handleAction('@ThemeManager/loadMoreTrashThemes/request', ({ state }) => {
      state.theme.loadMoreStatus = 'loading';
    }),
    handleAction('@ThemeManager/loadMoreTrashThemes/success', ({ state, action }) => {
      const { data, hasNextPage } = action.payload;
      state.theme.loadMoreStatus = 'success';
      state.theme.data = state.theme.data.concat(data);
      state.theme.hasNextPage = hasNextPage;
    }),
    handleAction('@ThemeManager/loadMoreTrashThemes/failure', ({ state }) => {
      state.theme.loadMoreStatus = 'failure';
    }),
    handleAction('@ThemeManager/restoreTrashTheme/request', ({ state, action }) => {
      state.theme.queueRestoring = state.theme.queueRestoring.concat(action.payload.commandId);
    }),
    handleAction('@ThemeManager/restoreTrashTheme/success', ({ state, action }) => {
      state.theme.queueRestoring = state.theme.queueRestoring.filter(item => item !== action.payload.commandId);
    }),
    handleAction('@ThemeManager/restoreTrashTheme/failure', ({ state, action }) => {
      state.theme.queueRestoring = state.theme.queueRestoring.filter(item => item !== action.payload.commandId);
    }),
  ],
});

export const { setSearchKeyPageTrashTheme, setSearchKeyTrashTheme } = sliceTrashTheme.actions;

export const useSetSearchKeyPageTrashTheme = createDispatchAction(setSearchKeyPageTrashTheme);
export const useSetSearchKeyTrashTheme = createDispatchAction(setSearchKeyTrashTheme);

export const themeTrashSelector = (state: AppState) => state.adminDashboard.themeTrash;
