import Collapse from 'components/Collapse';
import { FontAwesome, View } from 'wiloke-react-core';

export const Contstraints = () => {
  return (
    <Collapse
      active
      groupName="constraints"
      name="constraints"
      renderHeader={handler => {
        return (
          <View css={{ display: 'flex', alignItems: 'center', cursor: 'pointer', padding: '8px' }} onClick={handler}>
            <FontAwesome size={16} css={{ marginRight: '8px' }} type="far" name="angle-down" />
            <View tagName="h5">Lưu cho Envato cần đảm bảo một số rằng buộc</View>
          </View>
        );
      }}
    >
      <View tagName="ul">
        <View tagName="li">
          Field chọn ảnh khi đồng bộ lên shopify phải là field chọn ảnh chứ không phải field text như kết quả của "Đồng bộ cho Shopify"
        </View>
        <View tagName="li">Field menu được setting trong tab "Sales channels" &gt; "Online store" &gt; "Navigation" phải hoạt động đúng</View>
        <View tagName="li">Mỗi file nằm trong folder "Sections" phải ít hơn 600 dòng code</View>
      </View>
    </Collapse>
  );
};
