import { shopifyFontsObject } from 'components/ShopifyFontsField/data';
import { isEqual, uniqWith } from 'lodash';
import { CssFontVariable } from 'types/PresetStyles';

interface CategorizeFonts {
  fonts: CssFontVariable[];
  // Uniq để load file fonts
  uniqueValue: boolean;
}
export const categorizeFonts = ({ fonts, uniqueValue }: CategorizeFonts) => {
  const { googleFonts, shopifyFonts } = fonts.reduce<{ googleFonts: CssFontVariable[]; shopifyFonts: CssFontVariable[] }>(
    (result, font) => {
      if (shopifyFontsObject.has(font.value)) {
        return {
          ...result,
          shopifyFonts: result.shopifyFonts.concat(font),
        };
      }
      return {
        ...result,
        googleFonts: result.googleFonts.concat(font),
      };
    },
    {
      googleFonts: [],
      shopifyFonts: [],
    },
  );

  return {
    shopifyFonts: uniqueValue ? uniqWith(shopifyFonts, (a, b) => isEqual(a.value, b.value)) : shopifyFonts,
    googleFonts: uniqueValue ? uniqWith(googleFonts, (a, b) => isEqual(a.value, b.value)) : googleFonts,
  };
};
