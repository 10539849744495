import { clone } from 'ramda';
import { addonApiController } from 'services/AddonService';
import { adapterCreateOrUpdateAddon } from 'services/AddonService/Adapters/adapterCreateOrUpdateAddon';
import { DevAddon } from 'types/Addons';
import { ToPartialKeys } from 'utils';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const createDraft = async (devAddon: ToPartialKeys<DevAddon, 'commandId'>) => {
  const { role } = getUserInfo();
  const devAddon_ = clone(devAddon);
  delete devAddon_.commandId;
  if (role === 'dev') {
    const response = await addonApiController.atom.devApi.addons.createDraftOfDev({ addon: devAddon_ });
    return {
      info: adapterCreateOrUpdateAddon(response.info) as DevAddon,
      message: response.message,
    };
  }
  throw new RoleException();
};
