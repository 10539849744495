import { createAction, createAsyncAction, createDispatchAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';
import { ThemeInteracting } from '../@types/ThemeInteracting';
import { ActionExportVariant } from '../@types/VariantOfActionExport';
import { SectionInfomation } from '../hooks/useGetSections';

export const extractThemeToFileForSync = createAsyncAction([
  '@ExtractThemeToFileForSync/request',
  '@ExtractThemeToFileForSync/success',
  '@ExtractThemeToFileForSync/failure',
])<
  {
    dataInteracting: ThemeInteracting;
    actionExportVariant: ActionExportVariant;
    isRetry: boolean;
    isNeedReduceTotalLinesOfCode: boolean;
    sectionsWithLinkListSetting: SectionInfomation[];
    onSuccess: () => void;
    onFailure: (theme: ThemeInteracting) => void;
  },
  { dataInteracting: ThemeInteracting },
  { dataInteracting: ThemeInteracting; actionExportVariant: ActionExportVariant }
>();
export const useExtractThemeToFileForSync = createDispatchAsyncAction(extractThemeToFileForSync);

export const setExpectProcesses = createAction('@ExtractThemeToFileForSync/setExpectProcesses', (payload: string[]) => payload);

export const initSessionOfProcesses = createAction('@ExtractThemeToFileForSync/initSessionOfProcesses');
export const useInitSessionOfProcesses = createDispatchAction(initSessionOfProcesses);

export const pushActualProcesses = createAction('@ExtractThemeToFileForSync/pushActualProcesses', (payload: string) => payload);

export const setModalExtract = createAction('@ExtractThemeToFileForSync/setModalExtract', (payload: ThemeInteracting | undefined) => payload);
export const useSetModalExtract = createDispatchAction(setModalExtract);
