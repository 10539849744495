import { getPartnerAppCategories } from 'containers/Admin/AppsPartner/store/action';
import { partnerAppSelector } from 'containers/Admin/AppsPartner/store/partnerAppsSlice';
import { put, retry, SagaReturnType, select, takeLatest } from 'redux-saga/effects';
import { recommendAppsService } from 'services/RecommednApps/RecommendApps';
import { PartnerCategory } from 'services/RecommednApps/types';
import { getActionType } from 'wiloke-react-core/utils';

const additional_data: PartnerCategory[] = [
  {
    id: 0,
    count: 0,
    name: 'All',
  },
];

function* handleGet(_: ReturnType<typeof getPartnerAppCategories.request>) {
  try {
    const { getCategoriesStatus, categories }: ReturnType<typeof partnerAppSelector> = yield select(partnerAppSelector);
    if (getCategoriesStatus === 'success') {
      yield put(getPartnerAppCategories.success({ data: categories }));
    } else {
      const response: SagaReturnType<typeof recommendAppsService.getPartnerAppsCategories> = yield retry(
        3,
        1000,
        recommendAppsService.getPartnerAppsCategories,
      );
      yield put(getPartnerAppCategories.success({ data: [...additional_data, ...response] }));
    }
  } catch (error) {
    yield put(getPartnerAppCategories.failure(undefined));
  }
}

export function* watchGetPartnerAppCategories() {
  yield takeLatest(getActionType(getPartnerAppCategories.request), handleGet);
}
