import { v4 } from 'uuid';
import { defaultLanguage, languages } from '../../../../@consts';
import { Locales } from '../../../../@types/Veda/ShopifyLocales';

const localKeyGenByVedaFieldLabel = 'FieldGenByVedaFieldLabel';

const createLocaleKey = () => `_${localKeyGenByVedaFieldLabel}_${v4()}_`;

interface RTGetShopifyFieldLabel {
  newLabel: string;
  locales: Locales;
}
export const getShopifyFieldLabel = (): RTGetShopifyFieldLabel => {
  const localeKey = createLocaleKey();
  const default_translations = { [localeKey]: '' };

  // const newLabel: RTGetShopifyFieldLabel['newLabel'] = `t:${localeKey}`;
  const locales: RTGetShopifyFieldLabel['locales'] = languages.reduce<RTGetShopifyFieldLabel['locales']>(
    (res, language) => {
      return {
        ...res,
        [language]: {
          [localeKey]: 'Logo Height',
        },
      };
    },
    { en: default_translations, vi: default_translations, fr: default_translations },
  );

  // return {
  //   newLabel,
  //   locales,
  // };
  return {
    newLabel: locales[defaultLanguage][localeKey],
    locales,
  };
};
