import NavigateItem from 'components/NavigateItem';
import { Tooltip, useStackNavigator } from '@wiloke/ui';
import { DataBindingFieldNameSelected } from 'containers/BuilderPage/components/DataBindingFieldNameSelected/DataBindingFieldNameSelected';
import { LeftBarParamList } from 'containers/BuilderPage/components/SidebarScreen/SidebarScreen';
import { useSetSettingsVisible } from 'containers/BuilderPage/store/settingsVisible/slice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateSettingsValue } from 'store/actions/actionPages';
import { cssVariablesSelector, sectionIdActiveSelector } from 'store/selectors';
import stringHash from 'string-hash';
import { SchemaSettingField, StyleBoxChildren } from 'types/Schema';
import { pmParent } from 'utils/functions/postMessage';
import { FieldContainerProps } from '../types';
import { CSSPropKey } from 'containers/BuilderPage/components/StyleBox/types';
import { FontAwesome } from 'wiloke-react-core';
import BoxCenter from 'components/BoxCenter';
import { Base64, debounce } from '@wiloke/functions';

export interface StyleBoxFieldProps<T> extends FieldContainerProps<T> {
  label: string;
  name: string;
  summary?: string;
  ignore?: CSSPropKey[];
  isSmall?: boolean;
  prevType?: SchemaSettingField['type'];
}

const ID_CSS_EMPTY = 'css_2416369068';

const StyleBoxField = <T extends string>({
  value,
  name,
  settingId,
  childId,
  grandChildId,
  label,
  summary,
  forceRenderSection,
  ignore = [],
  isSmall = false,
}: StyleBoxFieldProps<T>) => {
  const updateSettingsValue = useUpdateSettingsValue();
  const cssVariables = useSelector(cssVariablesSelector);
  const navigation = useStackNavigator<LeftBarParamList>();
  const setSettingsVisible = useSetSettingsVisible();
  const sectionIdActive = useSelector(sectionIdActiveSelector);

  const handleChange = (cssBase64: string) => {
    const val = Base64.decode(cssBase64);
    const hasAnimateScroll = val.includes('scroll_');
    const cssId = `css_${stringHash(cssBase64)}${hasAnimateScroll ? '_1' : ''}`;
    const value: StyleBoxChildren = cssId === ID_CSS_EMPTY ? { id: '', css: '' } : { id: cssId, css: cssBase64 };
    updateSettingsValue({
      value,
      settingId,
      childId,
      grandChildId,
    });
    if (forceRenderSection) {
      pmParent.emit('@rerender', { sectionId: sectionIdActive, action: 'StyleBoxField.tsx' });
    }
  };

  const handleChangeDebounced = debounce(handleChange, 100);

  useEffect(() => {
    navigation.emit('styleBoxScreen', {
      value,
      label,
      fonts: cssVariables.fonts,
      ignore,
      onChange: handleChangeDebounced,
      onAddOrEditColor: () => setSettingsVisible({ visible: true, keys: ['theme', 'colors'] }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, cssVariables]);

  const handleClick = () => {
    navigation.push('styleBoxScreen', {
      value,
      label,
      fonts: cssVariables.fonts,
      ignore,
      onChange: handleChangeDebounced,
      onAddOrEditColor: () => setSettingsVisible({ visible: true, keys: ['theme', 'colors'] }),
    });
  };

  if (isSmall) {
    return (
      <Tooltip
        portal
        text={label}
        onClick={handleClick}
        css={{
          position: 'absolute',
          top: '-3px',
          right: '-2px',
          zIndex: 9,
        }}
      >
        <BoxCenter size={24}>
          <FontAwesome type="far" name="fill-drip" size={13} color="gray6" colorHover="primary" css={{ transition: '0.3s' }} />
        </BoxCenter>
      </Tooltip>
    );
  }

  return <NavigateItem label={label} summary={summary} AfterLabel={<DataBindingFieldNameSelected fieldName={name} />} onClick={handleClick} />;
};

export default StyleBoxField;
