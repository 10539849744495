import { PAGE_TYPES_WITH_EXPECT_ORDER } from '../../@consts';
import { State } from '../@types';

export const INITIAL_STATE: State = {
  featuredImage: '',
  screenshot1: '',
  name: '',
  pageIds: PAGE_TYPES_WITH_EXPECT_ORDER.reduce<State['pageIds']>((res, pageType) => {
    return {
      ...res,
      [pageType]: [],
    };
  }, {} as State['pageIds']),
  category: { commandId: '', description: '', name: '' },
};
