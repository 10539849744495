import { UserPlan } from 'types/Plan';
import { ActionTypes, createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';
import {
  createUserPlan,
  deleteUserPlan,
  downgradeToFreePlan,
  getUserPlans,
  goToShopifyPayment,
  subscriptionChargeId,
  updateUserPlan,
} from './actions';

type Actions =
  | {
      type: 'setVisibleModalCreatePlan';
      payload: boolean;
    }
  | {
      type: 'setEditPlan';
      payload: UserPlan | undefined;
    }
  | {
      type: 'setVisibleSubscription';
      payload: boolean;
    };

type ExtraActions = ActionTypes<
  | typeof createUserPlan
  | typeof deleteUserPlan
  | typeof getUserPlans
  | typeof updateUserPlan
  | typeof goToShopifyPayment
  | typeof downgradeToFreePlan
  | typeof subscriptionChargeId
>;

interface State {
  createStatus: Status;
  deleteStatus: Record<string, Status>;
  getStatus: Status;
  plans: UserPlan[];
  visible: boolean;
  updateStatus: Record<string, Status>;
  getShopifyUrlStatus: Status;
  editPlan: UserPlan | undefined;
  subscription: {
    status: Status;
    message: string;
  };
  visibleSubscription: boolean;
}

export const slicePlan = createSlice<State, Actions, ExtraActions>({
  name: '@Manager',
  initialState: {
    plans: [],
    createStatus: 'idle',
    deleteStatus: {},
    updateStatus: {},
    getStatus: 'idle',
    visible: false,
    getShopifyUrlStatus: 'idle',
    editPlan: undefined,
    subscription: {
      message: '',
      status: 'idle',
    },
    visibleSubscription: false,
  },
  reducers: [
    handleAction('setVisibleModalCreatePlan', ({ state, action }) => {
      state.visible = action.payload;
    }),
    handleAction('setEditPlan', ({ state, action }) => {
      state.editPlan = action.payload;
    }),
    handleAction('setVisibleSubscription', ({ state, action }) => {
      state.visibleSubscription = action.payload;
    }),
  ],
  extraReducers: [
    handleAction('@Manager/createUserPlan/request', ({ state }) => {
      state.createStatus = 'loading';
    }),
    handleAction('@Manager/createUserPlan/success', ({ state, action }) => {
      state.createStatus = 'success';
      state.plans = state.plans.concat(action.payload);
    }),
    handleAction('@Manager/createUserPlan/failure', ({ state }) => {
      state.createStatus = 'failure';
    }),
    handleAction('@Manager/getUserPlans/request', ({ state }) => {
      state.getStatus = state.plans.length > 0 ? 'success' : 'loading';
    }),
    handleAction('@Manager/getUserPlans/success', ({ state, action }) => {
      state.getStatus = 'success';
      state.plans = action.payload.data;
    }),
    handleAction('@Manager/getUserPlans/failure', ({ state }) => {
      state.getStatus = 'failure';
    }),
    handleAction('@Manager/deleteUserPlan/request', ({ state, action }) => {
      state.deleteStatus[action.payload.commandId] = 'loading';
    }),
    handleAction('@Manager/deleteUserPlan/success', ({ state, action }) => {
      state.deleteStatus[action.payload.commandId] = 'success';
      state.plans = state.plans.filter(item => item.commandId !== action.payload.commandId);
    }),
    handleAction('@Manager/deleteUserPlan/failure', ({ state, action }) => {
      state.deleteStatus[action.payload.commandId] = 'failure';
    }),
    handleAction('@Manager/updateUserPlan/request', ({ state, action }) => {
      state.updateStatus[action.payload.commandId] = 'loading';
    }),
    handleAction('@Manager/updateUserPlan/success', ({ state, action }) => {
      state.updateStatus[action.payload.commandId] = 'success';
      state.plans = state.plans.map(item => {
        if (item.commandId === action.payload.commandId) {
          return action.payload;
        }
        return item;
      });
    }),
    handleAction('@Manager/updateUserPlan/failure', ({ state, action }) => {
      state.updateStatus[action.payload.commandId] = 'failure';
    }),
    handleAction('@Plan/goToShopifyPayment/request', ({ state }) => {
      state.getShopifyUrlStatus = 'loading';
    }),
    handleAction('@Plan/goToShopifyPayment/success', ({ state }) => {
      state.getShopifyUrlStatus = 'success';
    }),
    handleAction('@Plan/goToShopifyPayment/failure', ({ state }) => {
      state.getShopifyUrlStatus = 'failure';
    }),
    handleAction('@Plan/downgradeToFreePlan/request', ({ state }) => {
      state.getShopifyUrlStatus = 'loading';
    }),
    handleAction('@Plan/downgradeToFreePlan/success', ({ state }) => {
      state.getShopifyUrlStatus = 'success';
    }),
    handleAction('@Plan/downgradeToFreePlan/failure', ({ state }) => {
      state.getShopifyUrlStatus = 'failure';
    }),
    handleAction('@Plan/subscriptionChargeId/request', ({ state }) => {
      state.subscription = {
        message: 'Verifying your charge id...',
        status: 'loading',
      };
    }),
    handleAction('@Plan/subscriptionChargeId/success', ({ state, action }) => {
      state.subscription = {
        message: action.payload.message,
        status: 'success',
      };
    }),
    handleAction('@Plan/subscriptionChargeId/failure', ({ state, action }) => {
      state.subscription = {
        message: action.payload.message,
        status: 'failure',
      };
    }),
  ],
});

export const { setVisibleModalCreatePlan, setEditPlan, setVisibleSubscription } = slicePlan.actions;

export const planSelector = (state: AppState) => state.plan;

export const useSetVisibleModalCreatePlan = createDispatchAction(setVisibleModalCreatePlan);
export const useSetEditPlan = createDispatchAction(setEditPlan);
export const useSetVisibleSubscription = createDispatchAction(setVisibleSubscription);
