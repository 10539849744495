import { SchemaSettingField } from '../SectionConvert/@types/Section';
import { SettingBlockObject } from '../SectionConvert/SchemaBlocks/SettingBlockObject/@types/SettingBlockObject';

interface IgnoreToReduceTotalLinesOfCode {
  field: SchemaSettingField;
  parentField: SettingBlockObject | undefined;
}

// const IGNORE_BACKGROUND_SETTINGS_EXCEPT_IMAGE = [
//   'background_type',
//   'background_color',
//   'background_video',
//   'background_fixed',
//   'background_size',
//   'background_overlay_enable',
//   'background_overlay_color',
//   'background_overlay_blur',
// ];
export const ignoreToReduceTotalLinesOfCode = (_: IgnoreToReduceTotalLinesOfCode) => {
  // if (parentField?.name === 'section_settings' && IGNORE_BACKGROUND_SETTINGS_EXCEPT_IMAGE.includes(field.name)) {
  //   return true;
  // }
  return false;
};
