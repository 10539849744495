import { AsyncComponent } from '@wiloke/ui';
import { notification } from 'antd';
import { ErrorWithCrispChat } from 'components/ErrorWithCrispChat';
import NotEnoughWidth from 'components/NotEnoughWidth';
import { useGetCurrentTheme } from 'containers/Admin/ThemeBuilder/ThemeTemplates/actions';
import { ModalSavePageBeforeRedirectHeaderFooter } from 'containers/BuilderPage/components/ModalSaveForBuilder/ModalSavePageBeforeRedirectHeaderFooter';
import { ModalUninstallAddon } from 'containers/BuilderPage/components/ModalUninstallAddon/ModalUninstallAddon';
import { ChooseTemplate } from 'containers/ChooseTemplate';
import { useGetAddonsNav, useGetTemplateCategories } from 'containers/ChooseTemplate/store/actions';
import ModalAddElement from 'containers/ModalAddElement/ModalAddElement';
import { NotFoundPage } from 'containers/NotFoundPage';
import { CreateBlogPicker, CreateCollectionPicker, CreateProductPicker } from 'containers/Shopify/ModalCreateShopifyPicker';
import { useSettingsShopifyPicker } from 'containers/Shopify/ModalCreateShopifyPicker/slice';
import { TourGuideForAddon } from 'containers/TourGuides/TourGuideForAddon';
import { useParentPostMessage } from 'hooks/useParentPostMessage';
import { usePreviewPostMessage } from 'hooks/usePreviewPostMessage';
import { useSocketForImportThemeAtomToClientServiceNPageInThemeClient } from 'hooks/useSocket/useSocketForImportThemeAtomToClientServiceNPageInThemeClient';
import { ModalWaringThemeHeaderFooterNAddon } from 'packages/WarningThemeHeaderFooterNAddon/WarningThemeHeaderFooterNAddon';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Location } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useDeepCompareEffect, useWindowSize } from 'react-use';
import { LocationStates } from 'routes/LocationStates';
import { useGetPage, useUpdateShopifyRepresentPage } from 'store/actions/actionPages';
import { useGetInitialOfLiquidVariables } from 'store/actions/liquid/actionLiquidVariables';
import { redirectedSelector, useSetRedirected } from 'store/global/redirected/slice';
import {
  fullscreenSelector,
  globalMountSelector,
  liquidVariablesSelector,
  pageDataSelector,
  pagesSelector,
  reloadPageSelector,
  sectionIdCodeVisibleSelector,
  themeBuilderSelector,
} from 'store/selectors';
import { i18n } from 'translation';
import {
  ArticlePageLiquidVariable,
  CollectionPageLiquidVariable,
  HomePageLiquidVariable,
  Page,
  PageLiquidVariable,
  ProductPageLiquidVariable,
  RegularPageLiquidVariable,
} from 'types/Page';
import getPageInfo from 'utils/functions/getInfo';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { getEntityVariant } from 'utils/getEntityVariant';
import { isShopifyEntityNonExisted } from 'utils/isShopifyEntityNonExisted';
import { View } from 'wiloke-react-core';
import BuilderPageSkeleton from './BuilderPageSkeleton';
import AddonsTopBar from './components/AddonsTopBar/AddonsTopBar';
import ContentPage from './components/ContentPage/ContentPage';
import { ModalLoginShopifyBeforePreview } from './components/ModalLoginShopifyBeforePreview/ModalLoginShopifyBeforePreview';
import { ModalSaveAdminAddon } from './components/ModalSaveForBuilder/ModalAdminAddons';
import { ModalAdminMegaMenu } from './components/ModalSaveForBuilder/ModalAdminMegaMenu';
import { ModalAdminSection } from './components/ModalSaveForBuilder/ModalAdminSection';
import { ModalSaveDevAddon } from './components/ModalSaveForBuilder/ModalDevAddons';
import { ModalDevMegaMenu } from './components/ModalSaveForBuilder/ModalDevMegaMenu';
import { ModalDevSection } from './components/ModalSaveForBuilder/ModalDevSection';
import { ModalSaveTheme } from './components/ModalSaveForBuilder/ModalSaveTheme';
import { ModalVersion } from './components/ModalVersion/ModalVersion';
import { SidebarScreen } from './components/SidebarScreen/SidebarScreen';
import TopBar from './components/TopBar';
import { useGetShopifyPageTemplate } from './hooks/useGetShopifyPageTemplate';
import { useListenHeaderFooterAddonUpdate } from './hooks/useListenHeaderFooterAddonUpdate';
import { addonsPositionStartSelector } from './store/addonPosition/slice';
import { modalAdminAddonsVisibleSelector, modalDevAddonsVisibleSelector } from './store/saveForBuilder/slice';
import * as styles from './styles';

const BuilderPage: FC = () => {
  const pages = useSelector(pagesSelector);
  const page = useSelector(pageDataSelector) as Page | undefined;
  const getPage = useGetPage();
  const sectionIdCodeVisible = useSelector(sectionIdCodeVisibleSelector);
  const location = useLocation<'/builder'>();

  const fullscreen = useSelector(fullscreenSelector);
  const reloadPage = useSelector(reloadPageSelector);
  const getInitialOfLiquidVariables = useGetInitialOfLiquidVariables();
  const { status: globalMountStatus } = useSelector(globalMountSelector);
  const addonsPositionStart = useSelector(addonsPositionStartSelector);
  const { statusGetInitialOfLiquidVariables } = useSelector(liquidVariablesSelector);
  const { getThemeStatus: getCurrentThemeStatus } = useSelector(themeBuilderSelector.templates);
  const { width } = useWindowSize();
  const getCurrentTheme = useGetCurrentTheme();
  const getCategories = useGetTemplateCategories();
  const getNavAddons = useGetAddonsNav();
  const themeId = getPageInfo('themeId');
  const pageId = getPageInfo('id');
  const shopInParams = getPageInfo('shop');

  const modalDevAddonsVisible = useSelector(modalDevAddonsVisibleSelector);
  const modalAdminAddonsVisible = useSelector(modalAdminAddonsVisibleSelector);

  const updateShopifyRepresentPage = useUpdateShopifyRepresentPage();
  const changeSettingsShopify = useSettingsShopifyPicker();
  const { getShopifyPages, getShopifyPresentPage } = useGetShopifyPageTemplate();

  const setIsRedirect = useSetRedirected();
  const isRedirect = useSelector(redirectedSelector);
  const { role, shopName } = getUserInfo();
  const { connect, disconnect, statusSocketConnection } = useSocketForImportThemeAtomToClientServiceNPageInThemeClient();

  // const setTemplateBoardVisible = useSetTemplateBoardVisible();
  // const isOpenedBefore = useRef(false);
  // const { addonsTour } = useSelector(tourGuideSelector);
  // const setAddonTour = useSetTourGuideAddon();
  // const twigLoading = useSelector(twigLoadingSelector);
  // const iframeLoaded = useSelector(iframeLoadedSelector);

  const handleReselectShopifyRepresentPage = () => {
    if (page) {
      if (page.type === 'blog') {
        notification.error({
          message: i18n.t('builderPage.shopify_entity_non_exist.title'),
          description: i18n.t('builderPage.shopify_entity_non_exist.blog'),
        });
        changeSettingsShopify({
          visibleBlog: true,
        });
      } else if (page.type === 'product') {
        notification.error({
          message: i18n.t('builderPage.shopify_entity_non_exist.title'),
          description: i18n.t('builderPage.shopify_entity_non_exist.product'),
        });
        changeSettingsShopify({
          visibleProduct: true,
        });
      } else if (page.type === 'collection') {
        notification.error({
          message: i18n.t('builderPage.shopify_entity_non_exist.title'),
          description: i18n.t('builderPage.shopify_entity_non_exist.collection'),
        });
        changeSettingsShopify({
          visibleCollection: true,
        });
      }
    }
  };

  const handleUpdateShopifyRepresentPage = () => {
    changeSettingsShopify({
      visibleProduct: false,
      visibleCollection: false,
      visibleBlog: false,
    });
    const shopifyRepresentPage = page ? getShopifyPresentPage(page.type) : undefined;
    const shopifyPages = page ? getShopifyPages(page.type) : undefined;

    if (page && shopifyRepresentPage && shopifyPages) {
      updateShopifyRepresentPage({
        shopifyRepresentPage,
        shopifyPages,
      });
    } else {
      handleReselectShopifyRepresentPage();
    }
  };

  const handleGetTheme = () => {
    connect({
      onSuccess: () => {
        getCurrentTheme.request({
          themeId,
          variant: getEntityVariant(location as Location<keyof LocationStates>),
          onFulfill: () => disconnect({}),
          onDisconnectSocketEarly: () => disconnect({}),
        });
      },
      onError: () => {
        notification.error({
          message: i18n.t('publish_shopify.init_sync_error.message'),
          description: i18n.t('publish_shopify.init_sync_error.description'),
        });
      },
    });
  };

  const handleGetPage = () => {
    const _shopifyPresentPageOfArticlePage = location.state?.shopifyRepresentPage as ArticlePageLiquidVariable | undefined;
    const _shopifyPresentPageOfRegularPage = location.state?.shopifyRepresentPage as RegularPageLiquidVariable | undefined;
    const _shopifyPresentPageOfHomePage = location.state?.shopifyRepresentPage as HomePageLiquidVariable | undefined;
    const _shopifyPresentPageOfOtherPage = location.state?.shopifyRepresentPage as
      | ProductPageLiquidVariable
      | CollectionPageLiquidVariable
      | undefined;

    connect({
      onSuccess: () => {
        getPage.request({
          id: pageId,
          onFulfill: () => {
            disconnect({});
          },
          onDisconnectSocketEarly: () => {
            disconnect({});
          },
          themeAtomCommandId: page && 'themeAtomCommandId' in page ? page.themeAtomCommandId : undefined,
          headerFooterEnabled: location.state?.headerFooterEnabled,
          name: location.state?.label,
          handle: _shopifyPresentPageOfOtherPage?.handle,
          type: location.state?.type,
          shopifyRepresentPage:
            location.state?.type === 'article'
              ? ({
                  blogId: _shopifyPresentPageOfArticlePage?.blogId ?? 0,
                  blogHandle: _shopifyPresentPageOfArticlePage?.blogHandle ?? '',
                  handle: _shopifyPresentPageOfArticlePage?.handle ?? '',
                  itemId: _shopifyPresentPageOfArticlePage?.itemId ? Number(_shopifyPresentPageOfArticlePage?.itemId) : undefined,
                  featuredImg: _shopifyPresentPageOfArticlePage?.featuredImg,
                } as ArticlePageLiquidVariable)
              : location.state?.type === 'page'
              ? ({ handle: _shopifyPresentPageOfRegularPage?.handle ?? '' } as RegularPageLiquidVariable)
              : location.state?.type === 'home'
              ? _shopifyPresentPageOfHomePage
              : ({
                  handle: _shopifyPresentPageOfOtherPage?.handle ?? '',
                  itemId: _shopifyPresentPageOfOtherPage?.itemId ? Number(_shopifyPresentPageOfOtherPage?.itemId) : undefined,
                  featuredImg: _shopifyPresentPageOfOtherPage?.featuredImg,
                } as PageLiquidVariable),
          shopifyPages: location.state?.shopifyPages,
          variant: getEntityVariant(location as Location<keyof LocationStates>),
          isAdminTemplate: location.state?.isAdminTemplate ?? false,
        });
      },
      onError: () => {
        notification.error({
          message: i18n.t('publish_shopify.init_sync_error.message'),
          description: i18n.t('publish_shopify.init_sync_error.description'),
        });
      },
    });
  };

  // lắng nghe thay đổi setting của theme: header, footer, addons
  useListenHeaderFooterAddonUpdate();
  useParentPostMessage();
  usePreviewPostMessage();

  // @tuong -> Khi save page -> tự set lại giá trị cũ -> Tham chiếu thay đổi -> "useEffect" là không đủ
  // TODO: Tìm cách để không phải "deepCompare" vì cái giá phải trả khi so sánh "sections" là khá lớn
  useDeepCompareEffect(() => {
    if (pages.status[pageId] === 'success') {
      getInitialOfLiquidVariables.request({ isRetryAction: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages.status, page?.shopifyRepresentPage, pageId]);

  useEffect(() => {
    if (pages.status[pageId] === 'success' && statusGetInitialOfLiquidVariables === 'failure') {
      getInitialOfLiquidVariables.request({ isRetryAction: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusGetInitialOfLiquidVariables]);

  useEffect(() => {
    if (pages.status[pageId] === 'success') {
      getCategories.request({ search: '', showParentOnly: true });
      getNavAddons.request(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages.status]);

  // TODO: @tuong -> Tại sao lại sử dụng "useDeepCompareEffect" trong khi tất cả là primitive
  useDeepCompareEffect(() => {
    if (globalMountStatus === 'success') {
      if (!!themeId) {
        handleGetTheme();
      } else {
        handleGetPage();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [[globalMountStatus], [themeId], [pageId]]);

  useEffect(() => {
    if (isRedirect && pages.status[pageId] === 'success') {
      setIsRedirect(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, pages.status]);

  useEffect(() => {
    const page = pages.data[pageId];
    if (pages.status[pageId] === 'success' && page && isShopifyEntityNonExisted(page.shopifyRepresentPage)) {
      handleReselectShopifyRepresentPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages.status]);

  // useEffect(() => {
  //   const page = pages.data ? pages.data[pageId] : undefined;
  //   const isCreateAction = pageId === Consts.BlankCommandId || location.state?.isCreate || location.state?.isAdminTemplate;
  //   if (iframeLoaded && !twigLoading && (page?.type === 'product' || (location.state && location.state?.type === 'product')) && !isCreateAction) {
  //     if (!isOpenedBefore.current) {
  //       setTemplateBoardVisible({ visible: true, navKeys: ['addons'] });
  //       setAddonTour({ run: addonsTour.run, stepIndex: 1 });
  //       isOpenedBefore.current = true;
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [twigLoading, iframeLoaded]);

  if (shopName !== shopInParams) {
    return <NotFoundPage />;
  }

  // Retry mỗi khi lỗi
  if (pages.status[pageId] !== 'success' && statusSocketConnection === 'failure') {
    return <button onClick={getCurrentThemeStatus === 'success' ? handleGetPage : handleGetTheme}>Retry</button>;
  }

  if (statusSocketConnection === 'loading') {
    return <BuilderPageSkeleton />;
  }

  return (
    <AsyncComponent
      status={pages.status[pageId]}
      Request={<BuilderPageSkeleton />}
      Success={
        <>
          {width < 1100 ? <NotEnoughWidth /> : null}
          <View css={styles.container}>
            {!sectionIdCodeVisible && !fullscreen && (
              <View css={styles.left}>
                <SidebarScreen />
              </View>
            )}
            <View css={styles.right(!!sectionIdCodeVisible)}>
              {/* Vì lý do liên quan tới undo redo nên ta không thể ẩn bằng condition render */}
              <View css={!sectionIdCodeVisible && !addonsPositionStart.value ? {} : { opacity: 0, height: '0px', overflow: 'hidden' }}>
                <TopBar />
              </View>
              {addonsPositionStart.value && <AddonsTopBar />}
              {!reloadPage && <ContentPage />}
            </View>
          </View>
          <ChooseTemplate />
          <ModalAddElement />
          <ModalVersion />
          <ModalLoginShopifyBeforePreview />
          {modalDevAddonsVisible && <ModalSaveDevAddon />}
          {themeId && <ModalSaveTheme />}
          <ModalSavePageBeforeRedirectHeaderFooter />
          {modalAdminAddonsVisible && <ModalSaveAdminAddon />}

          {role === 'dev' && <ModalDevSection />}
          {role === 'admin' && <ModalAdminSection />}

          <CreateCollectionPicker onOk={handleUpdateShopifyRepresentPage} onCancel={handleReselectShopifyRepresentPage} />
          <CreateProductPicker onOk={handleUpdateShopifyRepresentPage} onCancel={handleReselectShopifyRepresentPage} />
          <CreateBlogPicker onOk={handleUpdateShopifyRepresentPage} onCancel={handleReselectShopifyRepresentPage} />

          {role === 'dev' && !!sectionIdCodeVisible && <ModalDevMegaMenu />}
          {role === 'admin' && !!sectionIdCodeVisible && <ModalAdminMegaMenu />}
          <ModalUninstallAddon />
          {(role === 'dev' || role === 'admin' || role === 'support') && <ModalWaringThemeHeaderFooterNAddon />}
          <TourGuideForAddon />
        </>
      }
      Failure={<ErrorWithCrispChat />}
    />
  );
};

export default BuilderPage;
