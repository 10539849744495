import { Button, Confirm, MyModal, Title, Tooltip } from '@wiloke/ui';
import { getColorTitle } from 'components/ColorPicker2/utils';
import ColorPickerBeauty from 'components/ColorPickerBeauty/ColorPickerBeauty';
import withDebounce from 'hocs/withDebounce';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  useAddNewColorScheme,
  useCancelModalUpdateScheme,
  useDeleteColorScheme,
  useOpenModalUpdateScheme,
  useReverseGrayColorsSchemeInteractingAction,
  useSaveModalUpdateScheme,
  useSetColorSchemes,
  useUpdateColorSchemeInteracting,
  useUpdateColorSchemeName,
} from 'store/global/colorSchemes/slice';
import { authSelector, colorSchemesSelector, documentColorsSelector } from 'store/selectors';
import { i18n } from 'translation';
import { CssColorVariable } from 'types/PresetStyles';
import { ColorScheme, DEFAULT_COLOR_SCHEME_NAMES } from 'types/Result';
import { Divider, FontAwesome, GridSmart, Space, Text, View } from 'wiloke-react-core';
import { getThreshold, isGray1To9 } from './@constants/pSBCThreshold';
import { SCHEMES, SelectColorSchemes } from './components/SelectColorSchemes';
import * as styles from './styles';
import { isDarkColor, isLightColor } from './@constants/isDarkOrLightColor';
import { getGrayColor } from './@utils/getGrayColor';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { notification } from 'antd';
import SwitchBeauty from 'components/SwitchBeauty/SwitchBeauty';
import { isLighterDirection } from 'generate/utils/generateHelpers';
import { validatePlan } from 'utils/functions/validatePlan';
import { PLAN_STATIC_DATA, VEDA_FUNCTION_ENUM } from 'utils/constants/PlanEnum';
import { BusinessIcon } from 'components/PlanBadge';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';
import TextInput from 'components/TextInput';

export interface ThemeColorSchemesProps {}

const ColorPickerBeautyDebounce = withDebounce(ColorPickerBeauty, 'color', 'onChange', 300);

const TextInputDebounce = withDebounce(TextInput, 'value', 'onValueChange', 300);

export const ThemeColorSchemes: FC<ThemeColorSchemesProps> = () => {
  const { colorSchemes, colorSchemeInteracting } = useSelector(colorSchemesSelector);
  const documentColors = useSelector(documentColorsSelector);
  const { plan, role } = useSelector(authSelector);
  const isDisable = validatePlan({ userPlan: plan.planHandle, itemPlan: VEDA_FUNCTION_ENUM.color_scheme, role }) === 'invalid';

  const openModalUpdateScheme = useOpenModalUpdateScheme();
  const cancelModalUpdateScheme = useCancelModalUpdateScheme();
  const saveModalUpdateScheme = useSaveModalUpdateScheme();
  const updateColorSchemeInteracting = useUpdateColorSchemeInteracting();
  const reverseGrayColorsInteracting = useReverseGrayColorsSchemeInteractingAction();
  const setColorSchemes = useSetColorSchemes();
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();
  const addNewColorScheme = useAddNewColorScheme();
  const deleteColorScheme = useDeleteColorScheme();
  const updateColorSchemeName = useUpdateColorSchemeName();

  const renderColor = (scheme: ColorScheme, color: CssColorVariable, index: number) => {
    if (isGray1To9(color) || !colorSchemeInteracting) {
      return null;
    }
    const isLight = isLighterDirection({ colors: colorSchemeInteracting.colors });
    const isNeedDisabled = (isDarkColor(color) || isLightColor(color)) && !isLight;

    return (
      <View key={color.id} css={[styles.tr, { margin: '6px 0px' }]}>
        <View css={[styles.tdItem, { width: '180px', flexShrink: 0 }]}>
          <Text tagName="h6" size={13} numberOfLines={1}>
            {(() => {
              if (color.name === '--color-dark') {
                return isLight ? 'Darkest Color' : 'Lightest Color';
              } else if (color.name === '--color-light') {
                return isLight ? 'Lightest Color' : 'Darkest Color';
              } else {
                return getColorTitle(color.name);
              }
            })()}
          </Text>
          {index > 0 && <Space size={12} />}
        </View>
        <View css={styles.tdItem}>
          <Tooltip
            portal
            css={{ display: 'block !important' }}
            text={isNeedDisabled ? i18n.t('general.disabled_dark_and_light_color_schemes') : undefined}
          >
            <ColorPickerBeautyDebounce
              radiusBox={6}
              borderColor="gray3"
              color={color.light}
              documentColors={documentColors}
              onChangeComplete={value => {
                updateColorSchemeInteracting({
                  colors: scheme.colors.map(item => {
                    if (item.name === color.name) {
                      return {
                        ...item,
                        light: value,
                      };
                    }
                    if (isDarkColor(color) && isGray1To9(item)) {
                      return {
                        ...item,
                        light: getGrayColor(value, getThreshold(item, 'light') ?? 0) ?? value,
                        dark: getGrayColor(value, getThreshold(item, 'dark') ?? 0) ?? value,
                      };
                    }
                    return item;
                  }),
                });
              }}
            />
          </Tooltip>
        </View>
      </View>
    );
  };

  const renderCardScheme = ({ scheme, isPreview, disabled }: { scheme: ColorScheme; isPreview: boolean; disabled: boolean }) => {
    const primaryColors = scheme.colors.slice(0, 4);
    const secondaryColors = scheme.colors.slice(5, -1);
    return (
      <View
        key={scheme.cssClassName}
        radius={4}
        borderWidth={isPreview ? 0 : 1}
        borderStyle="solid"
        borderColor="gray3"
        css={{ padding: isPreview ? undefined : '8px', opacity: disabled ? '0.4' : '1', cursor: disabled ? 'pointer' : 'auto' }}
        onClick={() => {
          if (disabled) {
            setPlanRequesting({
              handle: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].handle,
              type: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].type,
              pricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].pricePerMonth,
              pricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].pricePerYear,
              title: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].title,
              originalPricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].pricePerMonth,
              originalPricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].pricePerYear,
            });
            setValidateCouponVisible(true);
          }
        }}
      >
        {!isPreview && (
          <>
            <View css={styles.titleContainer}>
              <Text tagName="h5">{scheme.uniqueName}</Text>
              <View css={{ display: 'flex', gap: '4px' }}>
                <Tooltip text={i18n.t('general.edit')}>
                  <Button
                    css={{ padding: '4px 6px' }}
                    radius={4}
                    size="extra-small"
                    backgroundColor="gray2"
                    color="gray9"
                    backgroundColorHover="primary"
                    colorHover="light"
                    onClick={() => {
                      if (disabled) {
                        setPlanRequesting({
                          handle: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].handle,
                          type: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].type,
                          pricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].pricePerMonth,
                          pricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].pricePerYear,
                          title: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].title,
                          originalPricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].pricePerMonth,
                          originalPricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].pricePerYear,
                        });
                        setValidateCouponVisible(true);
                      } else {
                        openModalUpdateScheme({ colorSchemeInteracting: scheme });
                      }
                    }}
                  >
                    {disabled ? (
                      <View css={styles.iconDisable} backgroundColor="tertiary">
                        <BusinessIcon />
                      </View>
                    ) : (
                      <FontAwesome name="pencil" type="far" />
                    )}
                  </Button>
                </Tooltip>
                {!DEFAULT_COLOR_SCHEME_NAMES.includes(scheme.cssClassName) && (
                  <Confirm
                    title={i18n.t('general.confirm')}
                    message={`If you delete color scheme "${scheme.uniqueName}", it may affect existing sections. Do you still want to continue?`}
                    onOk={() => {
                      deleteColorScheme(scheme.uniqueName);
                    }}
                    okText={i18n.t('general.confirm')}
                  >
                    <Button css={{ padding: '4px 6px' }} radius={4} size="extra-small" color="light" backgroundColor="danger">
                      <FontAwesome name="trash" type="far" />
                    </Button>
                  </Confirm>
                )}
              </View>
            </View>
            <View backgroundColor="gray3" height={1} css={styles.line} />
          </>
        )}
        <View css={[styles.innerBox]}>
          <View css={styles.colorGrid}>
            {primaryColors.map(({ id, light }) => (
              <View key={id} css={{ backgroundColor: light }} />
            ))}
          </View>
          <View css={styles.colorFlex}>
            {secondaryColors.map(item => (
              <View key={item.id} css={{ backgroundColor: item.light, width: '90px', height: '55px' }} />
            ))}
          </View>
        </View>
      </View>
    );
  };

  const renderModal = () => {
    if (!colorSchemeInteracting) {
      return null;
    }
    return (
      <MyModal
        isVisible
        headerText={colorSchemeInteracting.uniqueName}
        onCancel={() => cancelModalUpdateScheme({})}
        onOk={() => saveModalUpdateScheme({})}
      >
        {renderCardScheme({ scheme: colorSchemeInteracting, isPreview: true, disabled: false })}
        <Space size={8} />
        {role === 'admin' ? (
          <>
            <TextInputDebounce
              block
              disabled={DEFAULT_COLOR_SCHEME_NAMES.includes(colorSchemeInteracting.cssClassName)}
              value={colorSchemeInteracting.uniqueName}
              onValueChange={val => {
                updateColorSchemeName({ uniqueName: val });
              }}
            />
            <Space size={8} />
          </>
        ) : null}
        <View radius={8} borderWidth={1} borderStyle="solid" borderColor="gray3" css={{ padding: '8px 16px' }}>
          <View css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Text tagName="h5">{i18n.t('general.colors')}</Text>
            <SwitchBeauty
              checked={!isLighterDirection({ colors: colorSchemeInteracting.colors })}
              css={{ padding: '0 !important', marginRight: '-10px' }}
              disableText={i18n.t('general.light_colors')}
              enableText={i18n.t('general.dark_colors')}
              borderWidth={0}
              onChange={() => {
                reverseGrayColorsInteracting({
                  cssClassName: colorSchemeInteracting.cssClassName,
                });
              }}
            />
          </View>
          <Divider size={1} css={{ margin: '8px -10px' }} color="gray3" />
          <View css={{ marginLeft: '-6px', marginRight: '-6px' }}>
            {colorSchemeInteracting.colors.map((color, index) => renderColor(colorSchemeInteracting, color, index))}
          </View>
        </View>
      </MyModal>
    );
  };

  return (
    <View>
      <Title
        title={i18n.t('builderPage.theme_settings.color_schemes.content.title')}
        text={
          <>
            <View>{i18n.t('builderPage.theme_settings.color_schemes.content.text')}</View>

            <View
              target="_blank"
              tagName="a"
              href="https://help.myshopkit.app/en/docs/veda-landing-page-builder/frequently-asked-questions/color-schemes/"
            >
              Click to learn how this feature works
            </View>
          </>
        }
        titleCss={{ fontSize: '18px !important' }}
        Right={
          getUserInfo().role === 'admin' ? (
            <View css={{ display: 'flex', gap: '4px' }}>
              <Button
                radius={6}
                backgroundColor="secondary"
                onClick={() => {
                  addNewColorScheme({});
                }}
              >
                Tạo mới
              </Button>

              <Button
                onClick={() => {
                  setColorSchemes({
                    colorSchemes: SCHEMES.map(item => ({
                      colors: item.colors,
                      cssClassName: item.cssClassName,
                      uniqueName: item.name,
                    })),
                  });
                  notification.warn({
                    message: 'Lưu ý',
                    description:
                      'Hành động "Ghi đè toàn bộ bằng các bảng màu mới" chỉ có ý nghĩa tại FE. Cần click "Lưu cài đặt" để lưu dữ liệu lên cơ sở dữ liệu',
                  });
                }}
                radius={6}
              >
                Ghi đè
              </Button>
            </View>
          ) : null
        }
      />
      <Space size={15} />
      <GridSmart columnWidth={300} columnCount={3}>
        {colorSchemes.map((item, index) => {
          const _disabled = index !== 0 && index !== 1 && isDisable;
          return renderCardScheme({ scheme: item, isPreview: false, disabled: _disabled });
        })}
      </GridSmart>
      <Space size={15} />
      <SelectColorSchemes />
      {renderModal()}
    </View>
  );
};
