import { css } from 'wiloke-react-core';

export const container = css`
  debug: ThemeTemplate-Container;

  border-radius: 6px;
  min-height: 800px;
  height: 100%;
  padding: 30px;
`;
