import { MyModal, Tooltip } from '@wiloke/ui';
import Field from 'components/Field/Field';
import SelectAntd from 'components/SelectAntd';
import { useGetThemesShopify, useUpdateThemeShopifyId } from 'containers/Admin/ThemeBuilder/ThemeDashboard/slice/actions';
import { themeDashboardSelector } from 'containers/Admin/ThemeBuilder/ThemeDashboard/slice/sliceThemeDashboard';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useUpdateThemeIdOfUser } from 'store/global/auth/actions';
import { authSelector } from 'store/selectors';
import { i18n } from 'translation';
import { ActivityIndicator, FontAwesome, useStyleSheet, View } from 'wiloke-react-core';
import * as css from './styles';

interface UpdateTheme {
  type: 'Theme';
  isErrorShopifyThemeRemoved: boolean;
  themeCommandId: string;
  error?: string;
}
interface UpdateSession {
  type: 'Session';
  isErrorShopifyThemeRemoved: boolean;
  error?: string;
}

export const ModalUpdateShopifyThemeId = () => {
  const updateThemeShopifyId = useUpdateThemeShopifyId();
  const updateThemeIdOfUser = useUpdateThemeIdOfUser();

  const { themeVedaId, updateThemeIdOfUserStatus, changeThemeButNotSave } = useSelector(authSelector);
  const { updateThemeStatus, getThemesShopifyStatus, shopifyThemes } = useSelector(themeDashboardSelector);

  const [state, setState] = useState<UpdateTheme | UpdateSession | undefined>(undefined);
  const [error, setError] = useState<string | undefined>('');
  const [themeId, setThemeId] = useState(themeVedaId);

  const history = useHistory();
  const getThemesShopify = useGetThemesShopify();
  const { styles } = useStyleSheet();

  useEffect(() => {
    ModalUpdateShopifyThemeId.open = data => {
      setState(data);
    };
  }, []);

  useEffect(() => {
    if (themeVedaId !== '') {
      setThemeId(themeVedaId);
    }
  }, [themeVedaId]);

  const handleClose = () => {
    setState(undefined);
    setError('');
    setThemeId('');
  };

  const handleOk = () => {
    if (themeId === '') {
      setError('Shopify Theme ID cannot be blank');
    } else {
      if (state?.type === 'Theme') {
        updateThemeShopifyId.request({
          commandId: state.themeCommandId,
          shopifyThemeId: themeId.trim(),
          onFulfill: () => {
            const urlSearchParams = new URLSearchParams(window.location.search);
            if (history.location.pathname === '/builder' && urlSearchParams.has('shopifyThemeId')) {
              urlSearchParams.delete('shopifyThemeId');
              urlSearchParams.append('shopifyThemeId', themeId);
              history.replace(`/builder?${urlSearchParams.toString()}` as any);
            }
            handleClose();
          },
          onFailed(message) {
            setError(message);
          },
        });
      } else if (state?.type === 'Session') {
        updateThemeIdOfUser.request({
          themeId: themeId,
          onFulfill: () => {
            handleClose();
          },
        });
      }
    }
  };

  return (
    <MyModal
      isLoading={updateThemeIdOfUserStatus === 'loading' || (state?.type === 'Theme' && updateThemeStatus[state.themeCommandId] === 'loading')}
      onCancel={handleClose}
      onOk={handleOk}
      isVisible={!!state}
      okText={i18n.t('general.update')}
      headerText={
        state?.isErrorShopifyThemeRemoved
          ? i18n.t('ModalUpdateShopifyThemeId.theme_removed_warning_title')
          : i18n.t('ModalUpdateShopifyThemeId.update_shopify_theme_id_title')
      }
    >
      <Field
        className="sync-theme-error"
        label={<View css={{ fontWeight: '400 !important' }}>{i18n.t('ModalUpdateShopifyThemeId.theme_removed_warning_description')}</View>}
        error={error || state?.error}
      >
        <View className="sync-theme-error" css={{ display: 'flex', alignItems: 'center' }}>
          <SelectAntd
            style={{ textAlign: 'left' }}
            className={styles(css.selectTheme)}
            value={themeId}
            options={shopifyThemes.map(item => {
              return {
                label: `${item.name}`,
                value: item.id,
              };
            })}
            placeholder={i18n.t('adminDashboard.duplicate_theme.select')}
            onChange={val => {
              setThemeId(val);
            }}
          />
          <View
            css={css.iconWrap}
            onClick={() => {
              getThemesShopify.request(undefined);
            }}
            style={!changeThemeButNotSave && themeId === themeVedaId ? { borderRadius: '0px 4px 4px 0px' } : undefined}
          >
            <Tooltip portal text={'Re-get shopify themes'} placement="bottom" css={css.itemFull}>
              {getThemesShopifyStatus === 'loading' ? <ActivityIndicator size={16} /> : <FontAwesome type="far" name="sync-alt" size={16} />}
            </Tooltip>
          </View>
        </View>
      </Field>
    </MyModal>
  );
};

ModalUpdateShopifyThemeId.open = (_: UpdateTheme | UpdateSession) => {};
