import { Styles, Theme } from 'wiloke-react-core';

export const style1 = {
  container: (): Styles => ({
    position: 'relative',
  }),
  header: (): Styles => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  }),
  iconContainer: (): Styles => ({
    display: 'flex',
    columnGap: '5px',
  }),
};

export const iconRight = (disable: boolean) => ({ colors }: Theme): Styles => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  background: colors.light,
  cursor: disable ? 'not-allowed' : 'pointer',
  border: `1px solid ${colors.gray2}`,
  opacity: disable ? 0.6 : 1,
  userSelect: 'none',
});

export const iconLeft = (disable: boolean) => ({ colors }: Theme): Styles => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  background: colors.light,
  cursor: disable ? 'not-allowed' : 'pointer',
  border: `1px solid ${colors.gray2}`,
  opacity: disable ? 0.6 : 1,
  userSelect: 'none',
});

export const style2 = {
  container: (): Styles => ({
    position: 'relative',
  }),
  iconRight: (): Styles => ({
    position: 'absolute',
    right: '-15px',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
  }),
  iconLeft: (): Styles => ({
    position: 'absolute',
    left: '-15px',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
  }),
};
