import { PageType, PreviewImage } from 'types/Page';
import { renewVersion } from 'utils/CacheControl/VedaClientCacheControl';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';

interface Params {
  name: string;
  /**  Đây là id của product service.  */
  parentCommandId: string;
  categories: string[];
  image: PreviewImage;
  pageType: PageType;
}

export const savePageToFavorite = async ({ categories, image, name, parentCommandId, pageType }: Params) => {
  const { id } = getUserInfo();
  const response = await pageApis.vedaApplication.userApi.page.saveToFavorite({ userId: id, categories, image, name, parentCommandId, pageType });
  renewVersion();
  return response;
};
