export type DataBoxType = 'image' | 'video';

interface DataBox {
  // Thể loại của box
  type: 'image' | 'video';

  // Tiêu đề của box
  boxTitle: string;

  // Bật/tắt box
  enable: boolean;

  // Tiêu đề của card sẽ nằm ở button
  cardTitle: string;

  // Mô tả ngắn của card sẽ nằm ở button
  cardShortDescription: string;

  // Chữ của button
  buttonText: string;

  /*
    url của video hoặc ảnh
    - Nếu là video thì url là:
      + id của video youtube
      + Hoặc link embed của video youtube
  */
  url: string;

  // Link khi click vào button
  href: string;
}

export const data_box: DataBox = {
  type: 'video',

  // general info
  boxTitle: 'Quick Guides',
  enable: true,
  cardTitle: '',
  cardShortDescription: '',
  buttonText: 'View Full Playlist',

  // detail info by type
  href: 'https://www.youtube.com/playlist?list=PLu7PwLl08TeQqS4zEcWZkZSAsv4PuPWKi',
  url: 'ug44vgKTLeE',
};
