import { AxiosResponse } from 'axios';
import { BE_PageInThemeAtomNDraft } from 'services/ThemeService/Atom/types';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface GetPagesOfDraft {
  /** CommandId của page cần lấy dữ liệu */
  commandIds: string;
}

interface ResponseSuccess {
  message: string;
  info: BE_PageInThemeAtomNDraft[];
}

/** API được sử dụng để lấy về dữ liệu của page - được clone từ page atom - được sử dụng trong theme draft theo commandId */
export const getPagesOfDraft = async ({ commandIds }: GetPagesOfDraft) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${baseUrl}/drafts/search`,
    params: {
      commandIds,
    },
  });
  return response.data;
};
