import { Record } from 'packages/ListingSectionsUpdatable/types/Record';
import { SagaReturnType, put, retry, takeLatest } from 'redux-saga/effects';
import { getAtomSections } from 'services/SectionService/Logic/Sections';
import { getActionType } from 'wiloke-react-core/utils';
import { getRecords } from '../../services/Firebase';
import { getGeneralInfomationOfRecords as actionGetGeneralInfomationOfRecords } from '../actions';

function* handleGetGeneralInfomationOfRecords({ payload }: ReturnType<typeof actionGetGeneralInfomationOfRecords.request>) {
  try {
    const { limit, offset } = payload;
    const firebaseTrackingRecords: SagaReturnType<typeof getRecords> = yield retry(3, 1000, getRecords);
    const recordsWithPaginated = firebaseTrackingRecords.slice(offset, offset + limit);
    const sectionAtomCommandIds = recordsWithPaginated.filter(item => item.type === 'section').map(item => item.atomCommandId);
    const atomSections: SagaReturnType<typeof getAtomSections> = yield retry(3, 1000, getAtomSections, { commandIds: sectionAtomCommandIds });
    const records: Array<Record['generalInfomation']> = recordsWithPaginated.reduce<Array<Record['generalInfomation']>>((result, record) => {
      const sectionAtom = atomSections.find(item => record.atomCommandId === item.commandId);
      if (sectionAtom) {
        result.push({
          firebaseTrackingData: record,
          sectionAtom: sectionAtom,
        });
      }
      return result;
    }, []);

    yield put(
      actionGetGeneralInfomationOfRecords.success({
        recordsPaginated: records,
        totalRecords: firebaseTrackingRecords.length,
      }),
    );
  } catch {
    yield put(actionGetGeneralInfomationOfRecords.failure(undefined));
  }
}

export function* watchGetGeneralInfomationOfRecords() {
  yield takeLatest(getActionType(actionGetGeneralInfomationOfRecords.request), handleGetGeneralInfomationOfRecords);
}
