export const fr = {
  'Section Settings': 'Paramètres De Section',
  'Section Name': 'Nom De Section',
  'Background Fixed': 'Fond Fixe',
  'Background Type': 'Type De Fond',
  'Background Color': 'Couleur De Fond',
  'Background Image': 'Image De Fond',
  'Background Video': 'Vidéo De Fond',
  'Background Size': 'Taille De Fond',
  'Enable Background Overlay': "Activer L'incrustation De Fond",
  'Background Overlay Color': 'Couche De Couleur De Fond',
  'Background Overlay Blur': 'Fond De Couche Flou',
  'Sub Heading': 'Sous-titre',
  Copyright: "Droits d'auteur",
  'New Letter': 'Nouvelle Lettre',
  'Mini Cart': 'Panier Mini',
  'Quick View': 'Vue Rapide',
  'Add To Cart': 'Ajouter Au Panier',
  'Add To Wishlist': 'Ajouter À La Liste De Souhaits',
  'Add To Compare': 'Ajouter À La Comparaison',
  'Aspect Ratio': 'Aspect Ratio',
  Text: 'Texte',
  Content: 'Contenu',
  Padding: 'Rembourrage',
  Margin: 'Marge',
  Button: 'Bouton',
  Buttons: 'Boutons',
  Column: 'Colonne',
  Product: 'Produit',
  Products: 'Produits',
  Collection: 'Collection',
  Collections: 'Collections',
  Heading: 'Titre',
  Design: 'Design',
  Category: 'Catégorie',
  Sortable: 'Triable',
  Navigation: 'Navigation',
  Menu: 'Menu',
  Header: 'En-tête',
  Footer: 'Pied De Page',
  Social: 'Réseau social',
  Socials: 'Réseau social',
  Logo: 'Logo',
  Phone: 'Téléphone',
  Email: 'Email',
  Website: 'Site Internet',
  Time: 'Heure',
  Date: 'Date',
  Subscribe: "S'abonner",
  Dark: 'Foncé',
  Light: 'Clair',
  Enable: 'Activer',
  Disable: 'Désactiver',
  Sale: 'Vente',
  Setting: 'Paramètre',
  Settings: 'Paramètres',
  Color: 'Couleur',
  'Compare Action': 'Action De Comparer',
  'Compare Options Title': 'Titre De Options De Comparer',
  'Cart Action': 'Action De Panier',
  Arrow: 'Flèche',
  Address: 'Adresse',
  Title: 'Titre',
  Description: 'Description',
  Decorate: 'Décorer',
  'View All Button': 'Voir Tous Les Boutons',
  Sidebar: 'Barre latérale',
  Banner: 'Bannière',
  'Enable Categories': 'Activer Les Catégories',
  'Categories Title': 'Titre De Catégories',
  Categories: 'Catégories',
  'Enable Caret': 'Activer Le Curseur',
  'Enable Flash Sale': 'Activer La Vente Flash',
  'Flash Sale Text': 'Texte De Vente Flash',
  'Enable Countdown': 'Activer Le Compte à rebours',
  'Expiration Date': "Date D'expiration",
  'Product Settings': 'Paramètres Du Produit',
  'Enable Review': 'Activer La Révision',
  'Enable Sale': 'Activer La Vente',
  'Sale Design': 'Design De Vente',
  'Product Quantity Displayed': 'Quantité De Produit Affichée',
  'Enable Color Action': "Activer L'Action De Couleur",
  'Add To Cart Action': 'Ajouter Au Panier Action',
  'Wishlist Action': 'Action De Liste De Souhaits',
  'Quick View Action': 'Vue Rapide Action',
  Image: 'Image',
  'Sub Title': 'Sous-titre',
  Position: 'Position',
  Align: 'Aligner',
  Information: 'Information',
  'Logo Image': 'Image De Logo',
  'Title Align': 'Alignement De Titre',
  'Sub Title Align': 'Alignement De Sous-titre',
  'Text Align': 'Alignement De Texte',
  'Description Align': 'Alignement De Description',
  'Account Action': 'Action De Compte',
  'Search Action': 'Action De Recherche',
  Brands: 'Marques',
  Label: 'Étiquette',
  'Popular Post': 'Post Populaire',
  Tags: 'Étiquettes',
  Blog: 'Blog',
  Share: 'Partager',
  Collapse: 'Effondrer',
  'Title Design': 'Design De Titre',
  'Sub Title Design': 'Design De Sous-titre',
  'Text Design': 'Design De Texte',
  'Description Design': 'Design De Description',
  Link: 'Lien',
  'Border Radius': 'Rayon De Bordure',
  'Image Border Radius': "Rayon De Bordure D'image",
  Variant: 'Variante',
  'Section Width': 'Largeur De Section',
  'Container Width': 'Largeur De Conteneur',
  'Container Gap': 'Écart De Conteneur',
  'Column Gap': 'Écart De Colonne',
  Manual: 'Manuel',
  Width: 'Largeur',
  Divider: 'Diviseur',
  'Divider Color': 'Couleur De Diviseur',
  'Divider Design': 'Design De Diviseur',
  'Divider Margin': 'Marge De Diviseur',
  Icon: 'Icône',
  'Enable Icon': "Activer L'icône",
  'Icon Design': "Design D'icône",
  Choose: 'Choisir',
  'Icon Settings': "Paramètres D'icône",
  Price: 'Prix',
  Options: 'Options',
  Name: 'Nom',
  Data: 'Données',
  Availability: 'Disponibilité',
  'In Stock Design': 'Design En Stock',
  'Out of Stock Design': 'Design En Rupture De Stock',
  Testing: 'Test',
  Layout: 'Mise en page',
  Vendor: 'Vendeur',
  Size: 'Taille',
  'Container Design': 'Design De Conteneur',
  'Item Design': "Design D'Article",
  Item: 'Article',
  'Item Column': "Colonne D'Article",
  'Content Width': 'Largeur De Contenu',
  'Icon Position': "Position De L'icône",
  'Content Design': 'Design De Contenu',
  'Row Height': 'Hauteur De Ligne',
  'Button Left': 'Bouton Gauche',
  'Button Right': 'Bouton Droit',
  'Auto Play': 'Lecture Automatique',
  Speed: 'Vitesse',
  Delay: 'Délai',
  'Slider Settings': 'Paramètres De Curseur',
  'Slides Per View': 'Diapositives Par Vue',
  Loop: 'Boucle',
  'Enable Arrow': 'Activer La Flèche',
  'Enable Pagination': 'Activer La Pagination',
  'Advanced Mode': 'Mode Avancé',
  Animate: 'Animer',
  'Product Design': 'Design De Produit',
  Gap: 'Écart',
  'Regular Price': 'Prix ​​Régulier',
  'Sale Price': 'Prix ​​De Vente',
  Height: 'Hauteur',
  Carousel: 'Carrousel',
  Video: 'Vidéo',
  'Play Button Design': 'Jouer Le Design Du Bouton',
  Popup: 'Popup',
  'Popup Design': 'Design De Popup',
  'Close Button': 'Bouton De Fermeture',
  'Close Button Design': 'Design Du Bouton De Fermeture',
  'General Settings': 'Paramètres Généraux',
  'Heading And Content': 'Titre Et Contenu',
  'Content Item': 'Article De Contenu',
  'Heading Position': 'Position De Titre',
  Pagination: 'Pagination',
  'Arrow Variant': 'Variante De Flèche',
  'Arrow Position': 'Position De Flèche',
  'Arrow Align': 'Alignement De Flèche',
  'Arrow Icon': "Icône D'Flèche",
  'Left Arrow Icon': 'Icône De Flèche Gauche',
  'Right Arrow Icon': 'Icône De Flèche Droite',
  Left: 'Gauche',
  Right: 'Droite',
  'Media Type': 'Type De Média',
  'Min Height': 'Hauteur Minimale',
  'Max Height': 'Hauteur Maximale',
  'Min Width': 'Largeur Minimale',
  'Max Width': 'Largeur Maximale',
  Filter: 'Filtre',
  Filters: 'Filtres',
  'Home Page Title': "Titre D'accueil",
  Separator: 'Séparateur',
  'Customer Page Title': 'Titre De La Page Client',
  Component: 'Composant',
  'Tag Name': 'Nom De Balise',
  Space: 'Espace',
  'Reversed Layout': 'Mise En Page Inversée',
  'Sold Out': 'Épuisé',
  'Remove From Wishlist': 'Supprimer De La Liste De Souhaits',
  'Remove From Compare': 'Supprimer De La Comparaison',
  Clear: 'Clair',
  'Show More': 'Montre plus',
  'Show Less': 'Montre moins',
  'Sort by': 'Trier par',
  Quantity: 'Quantité',
  'View Detail': 'Voir le détail',
  Refine: 'Affiner',
  'Filter Name': 'Nom De Filtre',
  'In Stock': 'En stock',
  'Out of Stock': 'En rupture de stock',
  'Accept Button': 'Bouton Accepter',
  'Reject Button': 'Bouton Rejeter',
  Show: 'Spectacle',
  Hide: 'Cacher',
  Language: 'Langue',
  Currency: 'Devise',
  View: 'Vue',
  Dropdown: 'Menu déroulant',
  Message: 'Message',
  'Language And Currency': 'Langue Et Monnaie',
  'Hot Spot': 'Point chaud',
  'Hot Spots': 'Points chauds',
} as const;
