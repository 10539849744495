import { CANCEL } from '@redux-saga/core';
import configureApp from 'configureApp';
import qs from 'qs';
import { Dispatch } from 'redux';
import { Reducers } from 'store/configureStore';
import ConfigureAxios from './ConfigureAxios';

interface RefreshTokenResponse {
  message: string;
  info: {
    id: number;
    token: string;
    refreshToken: string;
  };
}

interface AxiosData {
  accessToken: string;
  refreshToken: string;
}

const axiosConfig = new ConfigureAxios({
  configure: {
    method: 'GET',
    timeout: configureApp.timeout,
    paramsSerializer: qs.stringify,
  },
  setAccessToken() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { store } = require('store/configureStore');
    const {
      global: { auth },
    } = store.getState() as Reducers;
    return `${auth.accessToken}`;
  },
  setRefreshToken() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { store } = require('store/configureStore');
    const {
      global: { auth },
    } = store.getState() as Reducers;
    return `${auth.refreshToken}`;
  },
});

const fetchAPI = axiosConfig.create(CANCEL, () => {
  return {
    baseURL: `${configureApp.baseUrl}/${configureApp.version}/`,
  };
});

axiosConfig.accessToken({
  setCondition(config) {
    const isAppURL = config?.url?.search(/^http/g) === -1;
    return isAppURL;
  },
});

axiosConfig.refreshToken<RefreshTokenResponse, AxiosData>({
  url: `${configureApp.baseUrl}/${configureApp.version}/auth/refresh-token`,
  setRefreshCondition(error) {
    return error.response?.status === 401;
  },
  axiosData(refreshToken, accessToken) {
    return {
      accessToken,
      refreshToken,
    };
  },
  checkIsRefreshingToken(statusRefresh) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { store } = require('store/configureStore');
    const dispatch = store.dispatch as Dispatch;
    dispatch({
      type: '@Global/setRefreshingToken',
      payload: statusRefresh,
    });
  },
  success(dataSuccess) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { store } = require('store/configureStore');
    const dispatch = store.dispatch as Dispatch;
    dispatch({
      type: '@Global/setAccessToken',
      payload: `${dataSuccess.data.info.token}`,
    });
    dispatch({
      type: '@Global/setRefreshToken',
      payload: dataSuccess.data.info.refreshToken,
    });
    dispatch({
      type: '@Global/setVisibleModalUnauthorized',
      payload: false,
    });
  },
  failure() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { store } = require('store/configureStore');
    const dispatch = store.dispatch as Dispatch;
    dispatch({
      type: '@Global/setVisibleModalUnauthorized',
      payload: true,
    });
    dispatch({
      type: '@Global/setRefreshingToken',
      payload: 'failure',
    });
  },
});

export default fetchAPI;
