import { megaMenuApiController } from 'services/MegaMenuService';
import { adapterGetMegaMenus } from 'services/MegaMenuService/Adapters/adapterGetMegaMenus';
import { PageSection } from 'types/Sections';
import { getCurrentVersion } from 'utils/CacheControl/VedaClientCacheControl';

interface GetMegamenusOfSectionInThemeClient {
  megamenuCommandIds: string[];
}

export const getMegamenusOfSectionInThemeClient = async ({ megamenuCommandIds }: GetMegamenusOfSectionInThemeClient): Promise<PageSection[]> => {
  if (!megamenuCommandIds.length) {
    return [];
  }
  const responses = await megaMenuApiController.client.clientApi.mega_menus.getClients({
    commandIds: megamenuCommandIds,
    cacheVersion: getCurrentVersion().getTime(),
  });
  return adapterGetMegaMenus(responses);
};
