export const ModalTour = {
  install_with: 'Install And Customize %% text %%',
  already_know: "I've already known",
  interactive_tutorials: 'Interactive Tutorials',
  tour_theme: {
    step_1: {
      title: 'Browse theme library',
      description: 'Many stunning templates to choose.',
    },
    step_2: {
      title: 'Install your theme',
      description: 'Hover theme to preview & click "Install" your favorite one.',
    },
  },
  install_theme: {
    step_1: {
      label_1: 'Please download theme %% themeName %% first.',
      label_2: 'Then, you can install and customize it up!',
      label_3: 'Click here to download theme.',
      label_4: 'Skip, I have already installed this theme before.',
      label_5: 'Your plan does not support this feature. Please upgrade to a higher plan.',
    },
    step_2: {
      label_1: "You haven't installed the theme %% themeName %% to Shopify.",
      next_step: 'Next Step',
    },
    step_3: {
      label_1: 'Explore Veda Builder to make your theme unique.',
      label_2: 'The import process may take a few minutes, please do not close the browser until the data is imported.',
      customize_now: 'Customize now',
    },
  },
  tour_page: {
    step_1: {
      title: 'Choose page type',
      description: 'Click page type you want to replace/add.',
    },
    step_2: {
      title: 'Create a new page',
      description: 'Click this button to browse stunning page templates.',
    },
    step_3: {
      title: 'Install your page',
      description: 'Hover page to preview & click "Install" your favorite one.',
    },
  },
  tour_addon: {
    step_1: {
      title: 'Add a new addon',
      description: 'Click "Add Add-ons" to open addon library.',
    },
    step_2: {
      title: 'Select addon category',
      description: 'You can switch between built-in and third-party addons.',
    },
    step_3: {
      title: 'Install your addon',
      description: 'Click "Add" to install your favorite addon.',
    },
  },
} as const;
