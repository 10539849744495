import { sectionApiController } from 'services/SectionService';
import { adapterGetCategories } from 'services/SectionService/Adapters/adapterGetCategories';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const getCategoriesOfProduct = async ({ search, showParentOnly }: { search?: string; showParentOnly?: boolean }) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const response = await sectionApiController.product.adminApi.category.getCategories({
      type: 'GET FIRST PAGE',
      cacheVersion: lastCache,
      label: search,
      showParentOnly,
    });
    return adapterGetCategories(response.info);
  }
  const response = await sectionApiController.product.userApi.category.getCategories({
    type: 'GET FIRST PAGE',
    cacheVersion: lastCache,
    label: search,
  });
  return adapterGetCategories(response.info);
};

export const loadMoreCategoriesOfProduct = async (cursor: string, search?: string, showParentOnly?: boolean) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const response = await sectionApiController.product.adminApi.category.getCategories({
      type: 'LOADMORE',
      cursor,
      cacheVersion: lastCache,
      label: search,
      showParentOnly,
    });
    return adapterGetCategories(response.info);
  }
  const response = await sectionApiController.product.userApi.category.getCategories({
    type: 'LOADMORE',
    cursor,
    cacheVersion: lastCache,
    label: search,
    showParentOnly,
  });
  return adapterGetCategories(response.info);
};
