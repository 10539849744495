import { megaMenuApiController } from 'services/MegaMenuService';
import { adapterGetMegaMenus } from 'services/MegaMenuService/Adapters/adapterGetMegaMenus';
import { AdminSection } from 'types/Sections';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const getAtomSections = async (categoryName: string) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const atomResponse = await megaMenuApiController.atom.adminApi.mega_menu.getAtoms({
      type: 'GET FIRST PAGE',
      category: categoryName,
      cacheVersion: lastCache,
    });
    const productResponse = await megaMenuApiController.product.adminApi.mega_menu.getProductsWithParentCommandIds({
      parentCommandIds: atomResponse.info.map(item => item.commandId).join(','),
      cacheVersion: lastCache,
    });
    return adapterGetMegaMenus(atomResponse.info, productResponse.info) as AdminSection[];
  }
  if (role === 'dev' || role === 'support') {
    const response = await megaMenuApiController.atom.publishApi.mega_menu.getAtoms({
      type: 'GET FIRST PAGE',
      category: categoryName,
      cacheVersion: lastCache,
    });
    return adapterGetMegaMenus(response.info) as AdminSection[];
  }
  throw new RoleException();
};

export const loadMoreAtomSections = async (categoryName: string, cursor: string) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const atomResponse = await megaMenuApiController.atom.adminApi.mega_menu.getAtoms({
      type: 'LOADMORE',
      category: categoryName,
      lastCursor: cursor,
      cacheVersion: lastCache,
    });
    const productResponse = await megaMenuApiController.product.adminApi.mega_menu.getProductsWithParentCommandIds({
      parentCommandIds: atomResponse.info.map(item => item.commandId).join(','),
      cacheVersion: lastCache,
    });
    return adapterGetMegaMenus(atomResponse.info, productResponse.info) as AdminSection[];
  }
  if (role === 'dev' || role === 'support') {
    const response = await megaMenuApiController.atom.publishApi.mega_menu.getAtoms({
      type: 'LOADMORE',
      category: categoryName,
      lastCursor: cursor,
      cacheVersion: lastCache,
    });
    return adapterGetMegaMenus(response.info) as AdminSection[];
  }
  throw new RoleException();
};
