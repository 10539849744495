import { createPartner } from './createPartner';
import { deletePartner } from './deletePartner';
import { getPartners } from './getPartners';
import { updatePartner } from './updatePartner';

export const adminApi = {
  createPartner,
  deletePartner,
  getPartners,
  updatePartner,
};
