import { SyncThemeByResultOfExtractThemeToFileForSyncMessage } from 'packages/SyncThemeByResultOfExtractThemeToFileForSync/hooks/useSocketForSyncThemeByResultOfExtractThemeToFileForSync';
import { shopifyConnectionService } from 'services/ShopifyConnection';
import { ActionTypes, createReducer, handleAction } from 'wiloke-react-core/utils';
import * as actions from './actions';

interface StreamData {
  status: SyncStatus;
  socketData: SyncThemeByResultOfExtractThemeToFileForSyncMessage | undefined;
}

const defaultStreamData: StreamData = {
  status: 'idle',
  socketData: undefined,
};

export interface State {
  modalPreviewUrls: Array<{ pageName: string; url: string }> | undefined;
  modalSyncThemeByResultOfExtractThemeToFileForSync: 'Được mở từ bước extract result' | 'Được mở bởi click button' | undefined;
  session: {
    temporarySignalToReplaceAddonInLiquidCode: Awaited<ReturnType<typeof shopifyConnectionService.writeAddonToShopify>> | null;
    temporaryPreviewUrls: Array<{ pageName: string; url: string }>;
    temporaryRepresentPreviewUrl: string | null;
    expectProcesses: string[];
    actualProcessed: string[];
    status: Status;
    isRetryAction: boolean;
  };
  socket: {
    isStucking: boolean;
    eventId: string | undefined;
    statusSyncToShopify: Status;
    // @tuong -> "streams" dùng để lắng nghe socket hoàn thành cũng như chứa dữ liệu mà socket trả về
    streams: Record<SyncThemeByResultOfExtractThemeToFileForSyncMessage['eventType'], StreamData>;
  };
}
type Actions = ActionTypes<typeof actions>;

const initialState: State = {
  modalPreviewUrls: undefined,
  modalSyncThemeByResultOfExtractThemeToFileForSync: undefined,
  session: {
    temporarySignalToReplaceAddonInLiquidCode: null,
    temporaryPreviewUrls: [],
    temporaryRepresentPreviewUrl: null,
    actualProcessed: [],
    expectProcesses: [],
    status: 'idle',
    isRetryAction: false,
  },
  socket: {
    isStucking: false,
    eventId: undefined,
    statusSyncToShopify: 'idle',
    streams: {
      'Đồng bộ theme / Ghi addon disable position': defaultStreamData,
      'Đồng bộ theme / Ghi addon enable position': defaultStreamData,
      'Đồng bộ theme / Ghi các addon disable position vừa tạo xong vào file theme.liquid': defaultStreamData,
      'Đồng bộ theme / Ghi file atomic css': defaultStreamData,
      'Đồng bộ theme / Ghi file raw atomic css': defaultStreamData,
      'Đồng bộ theme / Ghi footer': defaultStreamData,
      'Đồng bộ theme / Ghi global (sinh ra từ themeSettings)': defaultStreamData,
      'Đồng bộ theme / Ghi header': defaultStreamData,
      'Đồng bộ theme / Ghi page': defaultStreamData,
      'Đồng bộ theme / Sync translation': defaultStreamData,
      'Đồng bộ theme / Ghi file configure theme': defaultStreamData,
    },
  },
};

export const reducerSyncThemeByResultOfExtractThemeToFileForSync = createReducer<State, Actions>(initialState, [
  // Socket
  handleAction('@SyncThemeByResultOfExtractThemeToFileForSync/socket/resetSocketState', ({ state }) => {
    state.socket = initialState.socket;
  }),
  handleAction('@SyncThemeByResultOfExtractThemeToFileForSync/socket/setEventId', ({ state, action }) => {
    state.socket.eventId = action.payload;
  }),
  handleAction('@SyncThemeByResultOfExtractThemeToFileForSync/socket/syncing', ({ state }) => {
    state.socket.statusSyncToShopify = 'loading';
  }),
  handleAction('@SyncThemeByResultOfExtractThemeToFileForSync/socket/synced', ({ state }) => {
    state.socket.statusSyncToShopify = 'success';
  }),
  handleAction('@SyncThemeByResultOfExtractThemeToFileForSync/socket/syncFailure', ({ state }) => {
    state.socket.statusSyncToShopify = 'failure';
  }),
  handleAction('@SyncThemeByResultOfExtractThemeToFileForSync/socket/syncCancelled', ({ state }) => {
    state.socket = initialState.socket;
  }),
  handleAction('@SyncThemeByResultOfExtractThemeToFileForSync/socket/setStreamSocket', ({ state, action }) => {
    const { eventType, socketData, status } = action.payload;
    state.socket.streams = {
      ...state.socket.streams,
      [eventType]: {
        status,
        socketData,
      },
    };
  }),

  // Session
  handleAction('@SyncThemeByResultOfExtractThemeToFileForSync/session/setExpectProcesses', ({ state, action }) => {
    state.session.expectProcesses = action.payload;
  }),
  handleAction('@SyncThemeByResultOfExtractThemeToFileForSync/session/setStatusOfSession', ({ state, action }) => {
    const { status, isRetryAction } = action.payload;
    state.session.status = status;
    state.session.isRetryAction = isRetryAction;
  }),
  handleAction('@SyncThemeByResultOfExtractThemeToFileForSync/session/setTemporarySignalToReplaceAddonInLiquidCode', ({ state, action }) => {
    state.session.temporarySignalToReplaceAddonInLiquidCode = action.payload;
  }),
  handleAction('@SyncThemeByResultOfExtractThemeToFileForSync/session/setTemporaryPreviewUrls', ({ state, action }) => {
    state.session.temporaryPreviewUrls = action.payload;
  }),
  handleAction('@SyncThemeByResultOfExtractThemeToFileForSync/session/setTemporaryRepresentPreviewUrl', ({ state, action }) => {
    state.session.temporaryRepresentPreviewUrl = action.payload;
  }),
  handleAction('@SyncThemeByResultOfExtractThemeToFileForSync/session/initSessionOfProcesses', ({ state }) => {
    state.session = initialState['session'];
  }),
  handleAction('@SyncThemeByResultOfExtractThemeToFileForSync/session/pushActualProcesses', ({ state, action }) => {
    state.session.actualProcessed.push(action.payload);
  }),
  // Modal
  handleAction('@SyncThemeByResultOfExtractThemeToFileForSync/setModalSocketStucking', ({ state, action }) => {
    state.socket.isStucking = action.payload;
  }),
  handleAction('@SyncThemeByResultOfExtractThemeToFileForSync/setModalPreviewUrls', ({ state, action }) => {
    state.modalPreviewUrls = action.payload;
  }),
  handleAction('@SyncThemeByResultOfExtractThemeToFileForSync/setModalSyncThemeByResultOfExtractThemeToFileForSync', ({ state, action }) => {
    state.modalSyncThemeByResultOfExtractThemeToFileForSync = action.payload;
  }),
]);
