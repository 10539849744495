import { addonApiController } from 'services/AddonService';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const getProduct = async (commandId: string) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const response = await addonApiController.product.adminApi.addons.getProduct({ commandId, cacheVersion: lastCache });
    return response;
  }
  const response = await addonApiController.product.userApi.addons.getProduct({ commandId, cacheVersion: lastCache });
  return response;
};
