import { MyModal } from '@wiloke/ui';
import IframeWrapper from 'components/IframeWrapper';
import { useDowngradeToFreePlan, useSubscriptionChargeId } from 'containers/Admin/PlanManagement/store/actions';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';
import { useSetVisibleSubscription } from 'containers/Admin/PlanManagement/store/slicePlan';
import { Dashboard } from 'containers/Dashboard';
import qs from 'qs';
import { FC, useEffect, useState } from 'react';
import { i18n } from 'translation';
import { Consts } from 'utils/constants/constants';
import { PLAN_HANDLE } from 'utils/constants/PlanEnum';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pmLanding } from 'utils/functions/postMessage';
import { checkDowngradePlan } from 'utils/functions/validatePlan';
import { View } from 'wiloke-react-core';
import { ModalSubscription } from './ModalSubscription';

const IFRAME_URL = 'https://vedabuilder.com/pricing-for-veda-builder';
// const IFRAME_URL = 'http://localhost:8000/pricing-for-veda-builder';

const defaultState = {
  handle: '',
  pricePerMonth: '',
  pricePerYear: '',
  title: '',
  type: 'yearly',

  originalPricePerMonth: '',
  originalPricePerYear: '',
};

export const PricingPage: FC = () => {
  const { plan } = getUserInfo();

  const downgradeFree = useDowngradeToFreePlan();
  const subscriptionChargeId = useSubscriptionChargeId();

  const setVisibleSubscription = useSetVisibleSubscription();
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();

  const [visibleCheckDowngrade, setVisibleCheckDowngrade] = useState(false);
  const [planInfo, setPlanInfo] = useState<typeof defaultState>(defaultState);

  useEffect(() => {
    if (location.search) {
      const charge_id = qs.parse(location.search, { ignoreQueryPrefix: true, comma: true })['charge_id'];
      const coupon = qs.parse(location.search, { ignoreQueryPrefix: true, comma: true })['coupon'];
      const close = qs.parse(location.search, { ignoreQueryPrefix: true, comma: true })['close'];
      const affiliateId = qs.parse(location.search, { ignoreQueryPrefix: true, comma: true })['affiliateId'];

      if (charge_id) {
        subscriptionChargeId.request({
          chargeId: charge_id.toString(),
          coupon: coupon ? coupon.toString() : '',
          affiliateId: affiliateId?.toString(),
          onFulfill() {
            if (!!close) {
              window.close();
            }
          },
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const off = pmLanding.on('@landing/ready', () => {
      pmLanding.emit('@landing/currentPlan', { plan: plan.planHandle, type: plan.isMonthly ? 'monthly' : 'yearly' });
    });

    return () => {
      off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);

  useEffect(() => {
    const off = pmLanding.on('@landing/plan/request', ({ handle, type, pricePerMonth, pricePerYear, title }) => {
      setPlanInfo({
        handle,
        type,
        pricePerMonth,
        pricePerYear,
        title,
        originalPricePerMonth: pricePerMonth,
        originalPricePerYear: pricePerYear,
      });

      if (checkDowngradePlan({ userPlan: plan.planHandle, itemPlan: handle as PLAN_HANDLE }) === 'pass') {
        if (handle === 'starter' || handle === Consts.FREE_PLAN) {
          downgradeFree.request({
            planHandle: handle,
            onFulfill: () => {
              setVisibleSubscription(true);
            },
          });
        } else {
          pmLanding.emit('@landing/plan/failure');
          setPlanRequesting({
            handle,
            type,
            pricePerMonth,
            pricePerYear,
            title,
            originalPricePerMonth: pricePerMonth,
            originalPricePerYear: pricePerYear,
          });
          setValidateCouponVisible(true);
        }
      } else {
        setVisibleCheckDowngrade(true);
        pmLanding.emit('@landing/plan/failure');
      }
    });

    return () => {
      off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan.planHandle]);

  const handleConfirmDowngrade = () => {
    if (planInfo.handle === 'starter' || planInfo.handle === Consts.FREE_PLAN) {
      downgradeFree.request({
        planHandle: planInfo.handle,
        onFulfill: () => {
          setVisibleSubscription(true);
          setVisibleCheckDowngrade(false);
          setPlanInfo(defaultState);
        },
      });
    } else {
      setPlanRequesting({
        handle: planInfo.handle,
        type: planInfo.type as any,
        pricePerMonth: planInfo.pricePerMonth,
        pricePerYear: planInfo.pricePerYear,
        title: planInfo.title,
        originalPricePerMonth: planInfo.pricePerMonth,
        originalPricePerYear: planInfo.pricePerYear,
      });
      setValidateCouponVisible(true);
      setVisibleCheckDowngrade(false);
      setPlanInfo(defaultState);
    }
  };

  return (
    <Dashboard
      Modals={() => (
        <>
          <ModalSubscription />

          <MyModal
            okText={i18n.t('general.confirm')}
            headerText="Downgrade confirmation"
            onCancel={() => {
              setVisibleCheckDowngrade(false);
              setPlanInfo(defaultState);
            }}
            onOk={handleConfirmDowngrade}
            isVisible={visibleCheckDowngrade}
          >
            <View color="warning">
              Warning: After 7 days from the downgrade, you will not be able to edit the sections and addons related to a higher package.
            </View>
          </MyModal>
        </>
      )}
      hasSubmenu={false}
      disabledPaddingContent
      disabledScroll
      Content={() => <IframeWrapper id="iframe-landing" src={IFRAME_URL} iframeCss={{ height: '100vh' }} />}
    />
  );
};
