import { getPurchaseCodes } from './getPurchaseCodes';
import { deletePurchaseCode } from './deletePurchaseCode';
import { deleteWebsiteOfPurchaseCode } from './deleteWebsite';
import { createPurchaseCode } from './createPurchaseCode';
import { checkPurchaseCodeIsExist } from './checkPurchaseCodeIsExist';

export const userApi = {
  getPurchaseCodes,
  deletePurchaseCode,
  deleteWebsiteOfPurchaseCode,
  createPurchaseCode,
  checkPurchaseCodeIsExist,
};
