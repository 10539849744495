export const twig_error = {
  control_flow: {
    case_when: {
      or_in_when_clause: `
        "Case & When": Vous ne pouvez pas utiliser "or" dans le "when" -> Utilisez "," comme alternative à "or"
        %% error_signal %%
      `,
      example: `
        "Case & When": Quelque chose ne va pas -> %% error_signal %%
        Syntaxe correcte :
          {% assign handle = "cake" %}
          {% case handle %}
            {% when "cake" %}
              Ceci est un gâteau
            {% when "cookie", "biscuit" %}
              Ceci est un cookie
            {% else %}
              Ceci n'est ni un gâteau ni un cookie
          {% endcase %}
      `,
    },
    unless: {
      example: `"Unless": Quelque chose ne va pas -> %% error_signal %%`,
      warning_message: `"Unless": Actuellement, les expressions complexes ne fonctionneront pas correctement dans tous les cas. Pour éviter tout cas indésirable, vous pouvez utiliser des "Unless" imbriqués comme solution de rechange -> %% error_signal %%`,
    },
  },
  deprecated_tags: {
    currency_form: `La balise 'currency form' n'est pas prise en charge`,
    include: `La balise 'include' n'est pas prise en charge`,
  },
  filters: {
    abs: '',
    append: '',
    asset_img_url: `"asset_img_url" n'est pas pris en charge`,
    asset_url: `"asset_url" n'est pas pris en charge`,
    at_least: {
      params: `"at_least" nécessite un paramètre au format nombre -> %% error_signal %%`,
      example: `
        "at_least" message: %% message %%
        Exemple 1 :
          - Entrée : {{ 4 | at_least: 5 }} + {{ 4 | at_least: 3 }}
          - Sortie : 5 + 4
      `,
    },
    at_most: {
      params: `"at_most" nécessite un paramètre au format nombre -> %% error_signal %%`,
      example: `
        "at_most" message: %% message %%
        Exemple 1 :
          - Entrée : {{ 4 | at_most: 5 }} + {{ 4 | at_most: 3 }}
          - Sortie : 5 + 4
      `,
    },
    base64_decode: {
      value: `"base64_decode": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
        "base64_decode" message: %% message %%
        Exemple 1 :
          - Entrée : {{ 4 | at_most: 5 }} + {{ 4 | at_most: 3 }}
          - Sortie : 5 + 4
      `,
    },
    base64_encode: {
      value: `"base64_encode": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
        "base64_encode" message: %% message %%
        Exemple 1 :
          - Entrée : {{ 'one two three' | base64_encode }}
          - Sortie : b25lIHR3byB0aHJlZQ==
    `,
    },
    base64_url_safe_decode: {
      value: `"base64_url_safe_decode": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
        "base64_url_safe_decode" message: %% message %%
        Exemple 1 :
          - Entrée : {{ 'PHA-b2s_PC9wPg==' | base64_url_safe_decode }}
          - Sortie : <p>ok?</p>
    `,
    },
    base64_url_safe_encode: {
      value: `"base64_url_safe_encode": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
        "base64_url_safe_encode" message: %% message %%
        Exemple 1 :
          - Entrée : {{ '<p>ok?</p>' | base64_url_safe_encode }}
          - Sortie : PHA-b2s_PC9wPg==
    `,
    },
    brightness_difference: `brightness_difference n'est pas pris en charge`,
    camelcase: ``,
    capitalize: ``,
    ceil: ``,
    color_brightness: {
      value: `"color_brightness": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
        "color_brightness" message: %% message %%
        Exemple 1 :
          - Entrée : {{ '#7ab55c' | color_brightness }}
          - Sortie : 153.21
    `,
    },
    color_contrast: {
      params: `"color_contrast" nécessite 1 paramètre au format chaîne de caractères -> %% error_signal %%`,
      value: `"color_contrast": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      color: `"color_contrast": La couleur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "color_contrast" message: %% message %%
      Exemple 1 :
        - Entrée : {{ '#495859' | color_contrast: '#fffffb' }}
        - Sortie : 7.4
    `,
    },
    color_darken: {
      params: `"color_darken" nécessite 1 paramètre au format nombre -> %% error_signal %%`,
      value: `"color_darken": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      darkenValue: `"color_darken": darkenValue doit être un nombre -> %% error_signal %%`,
      darkenDomain: `"color_darken": darkenValue ∈ [0, 100] -> %% error_signal %%`,
      example: `
      "color_darken" message: %% message %%
      Exemple 1 :
        - Entrée : {{ '#7ab55c' | color_darken: 30 }}
        - Sortie : #355325
    `,
    },
    color_desaturate: {
      params: `"color_desaturate" nécessite 1 paramètre au format nombre -> %% error_signal %%`,
      value: `"color_desaturate": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      desaturateValue: `"color_desaturate": desaturateValue doit être un nombre -> %% error_signal %%`,
      desaturateDomain: `"color_desaturate": desaturateValue ∈ [0, 100] -> %% error_signal %%`,
      example: `
      "color_desaturate" message: %% message %%
      Exemple 1 :
        - Entrée : {{ '#7ab55c' | color_desaturate: 30 }}
        - Sortie : #869180
    `,
    },
    color_difference: `"color_difference" n'est pas pris en charge`,
    color_extract: {
      params: `"color_extract" nécessite 1 paramètre au format chaîne de caractères -> %% error_signal %%`,
      value: `"color_extract": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      extractValue: `"color_extract": extractValue peut seulement être alpha, red, green, blue, hue, saturation ou lightness -> %% error_signal %%`,
      example: `
      "color_extract" message: %% message %%
      Exemple 1 :
        - Entrée : {{ '#7ab55c' | color_extract: 'red' }}
        - Sortie : 122
    `,
    },
    color_lighten: {
      params: `"color_lighten" nécessite 1 paramètre au format nombre -> %% error_signal %%`,
      value: `"color_lighten": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      lightenValue: `"color_lighten": lightenValue doit être un nombre -> %% error_signal %%`,
      example: `
      "color_lighten" message: %% message %%
      Exemple 1 :
        - Entrée : {{ '#7ab55c' | color_lighten: 30 }}
        - Sortie : #d0e5c5
    `,
    },
    color_mix: {
      params: `"color_mix" nécessite 1 paramètre au format couleur chaîne de caractères -> %% error_signal %%`,
      value: `"color_mix": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      colorValue: `"color_mix": color doit être une chaîne de caractères -> %% error_signal %%`,
      weightValue: `"color_mix": weight doit être un nombre -> %% error_signal %%`,
      weightDomain: `"color_mix": weight ∈ [0, 100] -> %% error_signal %%`,
      example: `
      "color_mix" message: %% message %%
      Exemple 1 :
        - Entrée : {{ '#7ab55c' | color_mix: '#ffc0cb', 50 }}
        - Sortie : #bdbb94
    `,
    },
    color_modify: {
      params: `"color_modify" nécessite 2 paramètres : le nom de la propriété à modifier et la nouvelle valeur -> %% error_signal %%`,
      value: `"color_modify": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      key: `"color_modify": key ne peut être que alpha, red, green, blue, hue, saturation ou lightness -> %% error_signal %%`,
      newValue: `"color_modify": newValue doit être un nombre -> %% error_signal %%`,
      redDomain: `"color_modify": newValue ∈ [0, 255] -> %% error_signal %%`,
      greenDomain: `"color_modify": newValue ∈ [0, 255] -> %% error_signal %%`,
      blueDomain: `"color_modify": newValue ∈ [0, 255] -> %% error_signal %%`,
      hueDomain: `"color_modify": newValue ∈ [0, 360] -> %% error_signal %%`,
      saturationDomain: `"color_modify": newValue ∈ [0, 100] -> %% error_signal %%`,
      example: `
      "color_modify" message: %% message %%
      Exemple 1 :
        - Entrée : {{ '#7ab55c' | color_modify: 'red', 255 }}
        - Sortie : #ffb55c
      Exemple 2 :
        - Entrée : {{ '#7ab55c' | color_modify: 'alpha', 0.85 }}
        - Sortie : rgba(122, 181, 92, 0.85)
    `,
    },
    color_saturate: {
      params: `"color_saturate" nécessite 1 paramètre au format nombre -> %% error_signal %%`,
      value: `"color_saturate": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      saturateValue: `"color_saturate": saturateValue doit être un nombre -> %% error_signal %%`,
      saturateValueDomain: `"color_saturate": saturateValue ∈ [0, 100] -> %% error_signal %%`,
      example: `
      "color_saturate" message: %% message %%
      Exemple 1 :
        - Entrée : {{ '#7ab55c' | color_saturate: 30 }}
        - Sortie : #6ed938
    `,
    },
    color_to_hex: {
      value: `"color_to_hex": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "color_to_hex" message: %% message %%
      Exemple 1 :
        - Entrée : {{ 'rgb(122, 181, 92)' | color_to_hex }}
        - Sortie : #7ab55c
      Exemple 2 :
        - Entrée : {{ 'rgba(122, 181, 92, 0.5)' | color_to_hex }}
        - Sortie : #7ab55c
    `,
    },
    color_to_hsl: {
      value: `"color_to_hsl": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "color_to_hsl" message: %% message %%
      Exemple 1 :
        - Entrée : {{ '#7ab55c' | color_to_hsl }}
        - Sortie : hsl(100, 38%, 54%)
      Exemple 2 :
        - Entrée : {{ 'rgba(122, 181, 92, 0.5)' | color_to_hsl }}
        - Sortie : hsla(100, 38%, 54%, 0.5)
    `,
    },
    color_to_rgb: {
      value: `"color_to_rgb": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "color_to_rgb" message: %% message %%
      Exemple 1 :
        - Entrée : {{ '#7ab55c' | color_to_rgb }}
        - Sortie : rgb(122, 181, 92)
      Exemple 2 :
        - Entrée : {{ 'hsla(100, 38%, 54%, 0.5)' | color_to_rgb }}
        - Sortie : rgba(122, 181, 92, 0.5)
    `,
    },
    compact: {
      value: `"compact": La valeur doit être un tableau -> %% error_signal %%`,
      example: `
      "compact" message: %% message %%

    Sans l'entrée "compact":
        {% assign site_categories = site.pages | map: "category" %}
        {% for category in site_categories %}
        - {{ category }}
        {% endfor %}
      Sans la sortie "compact":
        - business
        - celebrities
        -
        - lifestyle
        - sports
        -
        - technology

      Sans l'entrée "compact":
        {% assign site_categories = site.pages | map: "category" | compact %}
        {% for category in site_categories %}
        - {{ category }}
        {% endfor %}
      Sans la sortie "compact":
        - business
        - celebrities
        - lifestyle
        - sports
        - technology
    `,
    },
    concat: ``,
    customer_login_link: {
      value: `"customer_login_link": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "customer_login_link" message: %% message %%
      Exemple 1 :
        - Entrée : {{ 'Se connecter' | customer_login_link }}
        - Sortie : <a href="/account/login" id="customer_login_link">Se connecter</a>
    `,
    },
    date: {
      example: `
      "date" message: %% message %%
      Exemple 1 :
        - Entrée : {{ article.published_at | date: "%a, %b %d, %y" }}
        - Sortie : Mar, 14 janv., 22
      Exemple 2 :
          - Entrée : {{ "March 14, 2016" | date: "%b %d, %y" }}
          - Sortie : mars 14, 16
      Exemple 3 :
          - Entrée : {{ 1667814083463 | date: "%b %d, %y" }}
          - Sortie : déc. 06, 22
    `,
    },
    default_errors: {
      value: `"default_errors": La valeur doit être un tableau de chaînes de caractères -> %% error_signal %%`,
      example: `"default_errors": https://shopify.dev/api/liquid/filters/additional-filters#default_errors -> %% error_signal %%`,
      fake_message: `Message d\'erreur`,
    },
    default_pagination: {
      example: `
      Message d'erreur: %% message %%
      Exemple 1 :
        - Entrée :
        {% paginate collection.products by 2 %}
          {% for product in collection.products %}
            {{ product.title }} carte produit
          {% endfor %}

          {{ paginate | default_pagination }}
        {% endpaginate %}
        - Sortie : <span class="page current">1</span> <span class="page"><a href="/services/liquid_rendering/resource?page=2" title="">2</a></span> <span class="next"><a href="/services/liquid_rendering/resource?page=2" title="">Suivant &raquo;</a></span>
    `,
    },
    default: ``,
    divided_by: {
      params: `"divided_by" nécessite un paramètre au format nombre -> %% error_signal %%`,
      value: `"value": le dividende doit être un nombre -> %% error_signal %%`,
      divisor: `"value": le diviseur doit être un nombre -> %% error_signal %%`,
      example: `
      Message d'erreur: %% message %%
      Exemple 1 :
        - Entrée : {{ 100 | divided_by: 10}}
        - Sortie : 10
    `,
    },
    downcase: ``,
    escape: ``,
    external_video_tag: `external_video_tag n'est pas pris en charge`,
    external_video_url: `external_video_url n'est pas pris en charge`,
    file_img_url: `file_img_url n'est pas pris en charge`,
    file_url: `file_url n'est pas pris en charge`,
    first: ``,
    floor: ``,
    font_face: `font_face n'est pas pris en charge`,
    font_modify: `font_modify n'est pas pris en charge`,
    font_url: `font_url n'est pas pris en charge`,
    forloop: ``,
    format_address: `format_address n'est pas pris en charge`,
    global_asset_url: `global_asset_url n'est pas pris en charge`,
    handle: {
      value: `"handle": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "handle" message: %% message %%
      Exemple 1 :
        - Entrée : {{ '100% M & Ms!!!' | handleize }}
        - Sortie : 100-m-ms
    `,
    },
    highlight_active_tag: `highlight_active_tag n'est pas pris en charge`,
    highlight: `highlight n'est pas pris en charge`,
    hmac_sha1: `hmac_sha1 n'est pas pris en charge`,
    hmac_sha256: `hmac_sha256 n'est pas pris en charge`,
    image_tag: `image_tag n'est pas pris en charge`,
    image_url: {
      size: `"image_url": Erreur Liquid : La largeur et la hauteur doivent être comprises entre 1 et 5760 -> %% error_signal %%`,
      crop: `"image_url": Erreur Liquid : Mode de recadrage invalide -> %% error_signal %%`,
      format: `"image_url": Erreur Liquid : Extension invalide -> %% error_signal %%`,
      pad_color: `"image_url": Erreur Liquid : La couleur de remplissage doit être comprise entre 000000 et ffffff -> %% error_signal %%`,
      unexpect_params: `"image_url": Erreur Liquid : Paramètres inattendus -> %% error_signal %%`,
      example: `
      "image_url" message: %% message %%
      Exemple 1 :
        - Entrée : <img src="{{ product.featured_image | image_url: 300 }}"  alt="{{ product.title }}" />
        - Sortie : ...
      Exemple 2 :
        - Entrée : <img src="{{ product.featured_image | image_url }}"  alt="{{ product.title }}" />
        - Sortie : ...
    `,
    },
    img_url: {
      size: `"img_url": img_url nécessite au plus 1 paramètre au format chaîne de caractères -> %% error_signal %%`,
      example: `
      "img_url" message: %% message %%
      Exemple 1 :
        - Entrée : <img src="{{ product.featured_image | img_url: '300x300' }}"  alt="{{ product.title }}" />
        - Sortie : ...
      Exemple 2 :
        - Entrée : <img src="{{ product.featured_image | img_url }}"  alt="{{ product.title }}" />
        - Sortie : ...
    `,
    },
    join: ``,
    json: {
      warning: 'Le filtre json donne des résultats différents de Shopify. Veuillez faire attention lors des tests',
      example: `
        "json" message: %% message %%
        - Input:
          {% assign arr = "foo bar coo" | split: " " %}
          {{ arr | json }}
        - Output:
          ["foo","bar","coo"]
      `,
    },
    last: ``,
    link_to_add_tag: {
      params: `"link_to_add_tag" nécessite au moins 1 paramètre au format chaîne de caractères -> %% error_signal %%`,
      value: `"link_to_add_tag": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      params_invalid: `"link_to_add_tag": attributeName ou attributeValue est manquant -> %% error_signal %%`,
      example: `
      "link_to_add_tag" message: %% message %%
      Exemple 1 :
        - Entrée :
          <!-- collection.tags = ["Mens", "Womens", "Sale"] -->
          {% for tag in collection.tags %}
            {{ tag | link_to_add_tag: tag }}
          {% endfor %}
        - Sortie :
          <!-- Si vous êtes sur "/collections/frontpage/mens" : -->
          <a title="Afficher les produits correspondant au tag Mens" href="/collections/frontpage/mens">Mens</a>
          <a title="Afficher les produits correspondant aux tags Womens et Mens" href="/collections/frontpage/womens+mens">Womens</a>
          <a title="Afficher les produits correspondant aux tags Sale et Mens" href="/collections/frontpage/sale+mens">Sale</a>
      `,
    },
    link_to_remove_tag: {
      params: `"link_to_remove_tag" nécessite au moins 1 paramètre au format chaîne de caractères -> %% error_signal %%`,
      params_invalid: `"link_to_remove_tag": attributeName ou attributeValue est manquant -> %% error_signal %%`,
      value: `"link_to_remove_tag": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "link_to_remove_tag" message: %% message %%
      Exemple 1 :
        - Entrée :
          <!-- collection.tags = ["Mens", "Womens", "Sale"] -->
          {% for tag in collection.tags %}
            {{ tag | link_to_remove_tag: tag }}
          {% endfor %}
        - Sortie :
          <!-- Si vous êtes sur "/collections/frontpage/mens" : -->
          <a title="Supprimer le tag Mens" href="/collections/frontpage">Mens</a>
      `,
    },
    link_to_tag: {
      params: `"link_to_tag" nécessite au moins 1 paramètre au format chaîne de caractères -> %% error_signal %%`,
      value: `"link_to_tag": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      params_invalid: `"link_to_tag": attributeName ou attributeValue est manquant -> %% error_signal %%`,
      example: `
      "link_to_tag" message: %% message %%
      Exemple 1 :
        - Entrée :
          <!-- collection.tags = ["Mens", "Womens", "Sale"] -->
          {% for tag in collection.tags %}
            {{ tag | link_to_tag: tag }}
          {% endfor %}
        - Sortie :
          <a title="Afficher les produits correspondant au tag Mens" href="/collections/frontpage/mens">Mens</a>
          <a title="Afficher les produits correspondant au tag Womens" href="/collections/frontpage/womens">Womens</a>
          <a title="Afficher les produits correspondant au tag Sale" href="/collections/frontpage/sale">Sale</a>
      `,
    },
    link_to_type: {
      params: `"link_to_type": Les paramètres de link_to_type sont au mauvais format -> %% error_signal %%`,
      value: `"link_to_type": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      params_invalid: `"link_to_type": attributeName ou attributeValue est manquant -> %% error_signal %%`,
      example: `
      "link_to_type" message: %% message %%
      Exemple 1 :
        - Entrée : {{ "jeans" | link_to_type }}
        - Sortie : <a href="/collections/types?q=jeans" title="jeans">jeans</a>
      Exemple 2 :
        - Entrée : {{ 'jeans' | link_to_type: class: "link-class" }}
        - Sortie : <a href="/collections/types?q=jeans" class="link-class" title="jeans">jeans</a>
      `,
    },
    link_to_vendor: {
      params: `"link_to_vendor": Les paramètres de link_to_vendor sont au mauvais format -> %% error_signal %%`,
      value: `"link_to_vendor": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      params_invalid: `"link_to_vendor": attributeName ou attributeValue est manquant -> %% error_signal %%`,
      example: `
      "link_to_vendor" message: %% message %%
      Exemple 1 :
        - Entrée : {{ 'Shopify' | link_to_vendor }}
        - Sortie : <a href="/collections/vendors?q=Shopify" title="Shopify">Shopify</a>
      Exemple 2 :
        - Entrée : {{ 'Shopify' | link_to_vendor: class: "link-class" }}
        - Sortie : <a href="/collections/vendors?q=Shopify" class="link-class" title="Shopify">Shopify</a>
      `,
    },
    link_to: {
      params: `"link_to" nécessite au moins 1 paramètre au format chaîne de caractères -> %% error_signal %%`,
      value: `"link_to": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      params_invalid: `"link_to": attributeName ou attributeValue est manquant -> %% error_signal %%`,
      example: `
      "link_to" message: %% message %%
      Exemple 1 :
        - Entrée : {{ 'Shopify' | link_to: 'https://www.shopify.com', title: "Un lien vers Shopify", class: "link-class" }}
        - Sortie : <a title="Un lien vers Shopify" class="link-class" href="https://www.shopify.com">Shopify</a>
      Exemple 2 :
        - Entrée : {{ 'Shopify' | link_to: 'https://www.shopify.com' }}
        - Sortie : <a href="https://www.shopify.com">Shopify</a>
      `,
    },
    lstrip: {
      value: `"lstrip": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "lstrip" message: %% message %%
      Exemple 1 :
        - Entrée : {{ '   trop d'espaces           ' | lstrip }}
        - Sortie : trop d'espaces
    `,
    },
    map: {
      params: `"map" nécessite 1 paramètre au format chaîne de caractères -> %% error_signal %%`,
      property: `"map": La propriété est obligatoire -> %% error_signal %%`,
      value: `"map": La valeur doit être un tableau -> %% error_signal %%`,
      property_non_exist: `"map": La propriété n'existe pas -> %% error_signal %%`,
      example: `
      "map" message: %% message %%
      Exemple 1 :
        - Entrée :
          {% assign all_categories = site.pages | map: "category" %}
          {% for item in all_categories %}
          + {{ item }}
          {% endfor %}
        - Sortie :
          + business
          + celebrities
          + lifestyle
          + sports
          + technology
    `,
    },
    md5: `md5 n'est pas pris en charge`,
    media_tag: `media_tag n'est pas pris en charge`,
    metafield_tag: `metafield_tag n'est pas pris en charge`,
    metafield_text: `metafield_text n'est pas pris en charge`,
    minus: {
      params: `"minus" nécessite 1 paramètre au format nombre -> %% error_signal %%`,
      value: `"minus": Le sous-trahend doit être un nombre -> %% error_signal %%`,
      minus_number: `"minus": minus_number doit être un nombre -> %% error_signal %%`,
      example: `
      "minus" message: %% message %%
      Exemple 1 :
        - Entrée : {{ 100 | minus: 10}}
        - Sortie : 90
    `,
    },
    model_viewer_tag: `model_viewer_tag n'est pas pris en charge`,
    modulo: {
      params: `"modulo" nécessite 1 paramètre au format nombre -> %% error_signal %%`,
      value: `"modulo": Le dividende doit être un nombre -> %% error_signal %%`,
      modulo_number: `"modulo": modulo_number doit être un nombre -> %% error_signal %%`,
      example: `
      "modulo" message: %% message %%
      Exemple 1 :
        - Entrée : {{ 100 | modulo: 10}}
        - Sortie : 0
    `,
    },
    money: {
      value: `"money / money_with_currency / money_without_trailing_zeros / money_without_currency": La valeur doit être un nombre -> %% error_signal %%`,
      example_money: `
      "money" message: %% message %%
      Exemple 1 :
        - Entrée : {{ 145 | money }}
        - Sortie : $1.45
    `,
      example_money_with_currency: `
      "money_with_currency" message: %% message %%
      Exemple 1 :
        - Entrée : {{ 145 | money_with_currency }}
        - Sortie : $1.45 CAD
    `,
      example_money_without_trailing_zeros: `
      "money_without_trailing_zeros" message: %% message %%
      Exemple 1 :
        - Entrée :
          {{ 2000 | money_without_trailing_zeros }}
          {{ 145 | money_without_trailing_zeros }}
        - Sortie :
          $20
          $1.45
    `,
      money_without_currency: `
      "money_without_currency" message: %% message %%
      Exemple 1 :
        - Entrée : {{ 145 | money_without_currency }}
        - Sortie : 1.45
    `,
    },
    newline_to_br: {
      value: `"newline_to_br": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "newline_to_br" message: %% message %%
      Exemple 1 :
        - Entrée :
          {% capture var %}
          One
          Two
          Three
          {% endcapture %}
          {{ var | newline_to_br }}
        - Sortie :
          One<br>
          Two<br>
          Three<br>
    `,
    },
    payment_type_img_url: {
      value: `"payment_type_img_url": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      domain_value: `"payment_type_img_url": La valeur doit être %% domain_value %% -> %% error_signal %%`,
    },
    payment_type_svg_tag: {
      params: `"payment_type_svg_tag" nécessite 1 paramètre au format chaîne de caractères -> %% error_signal %%`,
      value: `"payment_type_svg_tag": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      domain_value: `"payment_type_svg_tag": La valeur doit être %% domain_value %% -> %% error_signal %%`,
      params_invalid: `"payment_type_svg_tag": Paramètres attributeName ou attributeValue manquants -> %% error_signal %%`,
      example: `
      "payment_type_svg_tag" message: %% message %%
      Exemple 1 :
        - Entrée :
          {% for type in shop.enabled_payment_types %}
            {{ type | payment_type_svg_tag: class: 'custom-class' }}
          {% endfor %}
        - Sortie :
          <svg class="custom-class" xmlns="http://www.w3.org/2000/svg">
            <circle fill="#EB001B" cx="15" cy="12" r="7"></circle>
            <circle fill="#F79E1B" cx="23" cy="12" r="7"></circle>
            ...
          </svg>
          <svg class="custom-class" xmlns="http://www.w3.org/2000/svg">
            <path fill="#494949" d="M9 11h20v2H9z"></path>
            ...
          </svg>
    `,
    },
    placeholder_svg_tag: {
      value: `"placeholder_svg_tag": La valeur doit être %% domain_value %% -> %% error_signal %%`,
      params_invalid: `"placeholder_svg_tag": Paramètres incorrects -> %% error_signal %%`,
      example: `
        "placeholder_svg_tag" message: %% error_signal %%
        - Entrée : {{ 'collection-1' | placeholder_svg_tag }}
        - Sortie : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525.5 525.5">...omis pour plus de concision...</svg>
        - Entrée : {{ 'collection-1' | placeholder_svg_tag: 'custom' }}
        - Sortie : <svg class="custom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525.5 525.5">...omis pour plus de concision...</svg>
      `,
    },
    pluralize: {
      params: `"pluralize" nécessite 2 paramètres au format chaîne de caractères -> %% error_signal %%`,
      value: `"pluralize": La valeur doit être un nombre -> %% error_signal %%`,
      singular: `"pluralize": singular est obligatoire -> %% error_signal %%`,
      plural: `"pluralize": plural est obligatoire -> %% error_signal %%`,
      example: `
      "pluralize" message: %% message %%
      Exemple 1 :
        - Entrée :
          {{ cart.item_count }}
          {{ cart.item_count | pluralize: 'article', 'articles' }}
        - Sortie : 3 articles
    `,
    },
    plus: {
      params: `"plus" nécessite 1 paramètre au format nombre -> %% error_signal %%`,
      value: `"plus": number1 doit être un nombre -> %% error_signal %%`,
      number2: `"plus": number2 doit être un nombre -> %% error_signal %%`,
      example: `
      "plus" message: %% message %%
      Exemple 1 :
        - Entrée : {{ 100 | plus: 10}}
        - Sortie : 110
    `,
    },
    preload_tag: `preload_tag n'est pas supporté`,
    prepend: {
      params: `"prepend" nécessite 1 paramètre au format chaîne de caractères -> %% error_signal %%`,
      value: `"prepend": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "prepend" message: %% message %%
      Exemple 1 :
        - Entrée :
          {{ 'World' | prepend: 'Hello' }}
          {% assign str = "apples, oranges, and bananas" | prepend: prependStr | truncate: 10 %}
          {{ "apples, oranges, and bananas" | truncate: 10 | prepend: prependStr }}
        - Sortie :
          Hello World.
    `,
    },
    remove_first: {
      params: `"remove_first" nécessite 1 paramètre au format chaîne de caractères -> %% error_signal %%`,
      value: `"remove_first": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "remove_first" message: %% message %%
      Exemple 1 :
        - Entrée : {{ 'Hello, world. Goodbye, world.' | remove_first: 'world' }}
        - Sortie : Hello, . Goodbye, world.
    `,
    },
    remove: ``,
    replace_first: {
      params: `"replace_first" nécessite 2 paramètres au format chaîne de caractères -> %% error_signal %%`,
      value: `"replace_first": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "replace_first" message: %% message %%
      Exemple 1 :
        - Entrée :
          <!-- product.title = "Awesome Awesome Shoes" -->
          {{ product.title | replace_first: 'Awesome', 'Mega' }}
        - Sortie : Mega Awesome Shoes
    `,
    },
    replace: {
      params: `"replace" nécessite 2 paramètres au format chaîne de caractères -> %% error_signal %%`,
      value: `"replace": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "replace" message: %% message %%
      Exemple 1 :
        - Entrée :
          <!-- product.title = "Awesome Awesome Shoes" -->
          {{ product.title | replace: 'Awesome', 'Mega' }}
        - Sortie : Mega Mega Shoes
    `,
    },
    reverse: ``,
    round: ``,
    rstrip: {
      value: `"rstrip": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "rstrip" message: %% message %%
      Exemple 1 :
        - Entrée : {{ '              trop d'espaces          ' | rstrip }}
        - Sortie : trop d'espaces
    `,
    },
    script_tag: `script_tag n'est pas supporté`,
    sha1: `sha1 n'est pas supporté`,
    sha256: `sha256 n'est pas supporté`,
    shopify_asset_url: `shopify_asset_url n'est pas supporté`,
    size: ``,
    slice: {
      params: `"slice" nécessite au moins 1 paramètre au format chaîne de caractères -> %% error_signal %%`,
      value: `"slice": La valeur doit être une chaîne de caractères ou un tableau primitif -> %% error_signal %%`,
      from: `"slice": "from" doit être un nombre -> %% error_signal %%`,
      to: `"slice": "to" doit être un nombre -> %% error_signal %%`,
      example: `
      "slice" message: %% message %%
      Exemple 1 :
        - Entrée : {{ "Liquid" | slice: 0 }}
        - Sortie : L
      Exemple 2 :
        - Entrée : {{ "Liquid" | slice: 2, 5 }}
        - Sortie : quid
      Exemple 3 :
        - Entrée :
          {% assign beatles = "John, Paul, George, Ringo" | split: ", " %}
          {{ beatles | slice: 1, 2 }}
        - Sortie : PaulGeorge
    `,
    },
    sort_by: {
      params: `"sort_by" nécessite 1 paramètre au format chaîne de caractères -> %% error_signal %%`,
      value: `"sort_by": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "sort_by" message: %% message %%
      Exemple 1 :
        - Entrée : {{ collection.url | sort_by: 'best-selling' }}
        - Sortie : /collections/widgets?sort_by=best-selling
    `,
    },
    sort_natural: {
      value: `"sort_natural": La valeur doit être un tableau primitif ou un tableau d'objets -> %% error_signal %%`,
      example: `
      "sort_natural" message: %% message %%
      Exemple 1 :
        - Entrée :
        {% assign my_array = "zebra, octopus, giraffe, Sally Snake" | split: ", " %}
        {{ my_array | sort_natural | join: ", " }}
        - Sortie : giraffe, octopus, Sally Snake, zebra
      Exemple 2 :
      {% assign products_by_company = collection.products | sort_natural: "company" %}
      {% for product in products_by_company %}
        <h4>{{ product.title }}</h4>
      {% endfor %}
    `,
    },
    sort: {
      value: `"sort": La valeur doit être un tableau -> %% error_signal %%`,
      property_non_exist: `"sort": La propriété n'existe pas -> %% error_signal %%`,
      example: `
      "sort" message: %% message %%
      Exemple 1 :
        - Entrée :
          {% assign my_array = "zebra, octopus, giraffe, Sally Snake" | split: ", " %}
          {{ my_array | sort | join: ", " }}

        - Sortie : Sally Snake, giraffe, octopus, zebra
    `,
    },
    split: ``,
    strip_html: ``,
    strip_newlines: {
      value: `"strip_newlines": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "strip_newlines" message: %% message %%
      Exemple 1 :
        - Entrée :
          {% capture string_with_newlines %}
          Bonjour
          tout le monde
          {% endcapture %}
          {{ string_with_newlines | strip_newlines }}
        - Sortie : Bonjourtout le monde
    `,
    },
    strip: ``,
    stylesheet_tag: `stylesheet_tag n'est pas pris en charge`,
    t: {
      format_invalid: `"t": Format invalide -> %% error_signal %%`,
      example: `
      "t" message: %% message %%
      Exemple 1 :
        - Entrée :
          Liquid : <span>{{ 'products.product.sold_out' | t }}</span>
          JSON :
            en.json : {
              "products": {
                "product": {
                    "sold_out": "Épuisé"
                }
              }
            }
            fr.json : {
              "products": {
                "product": {
                  "sold_out": "Sold out"
                }
              }
            }
        - Sortie :
            en : <span>Sold out</span>
            fr : <span>Épuisé</span>
    `,
    },
    time_tag: {
      params: `"time_tag": Au moins 1 paramètre au format chaîne de caractères est requis -> %% error_signal %%`,
      custom_format_not_exist: "Erreur Liquid : L'option de format '%% error_signal %%' n'est pas un format pris en charge.",
      example: `
        "time_tag" message: %% message %%
        Exemple :
          {{ article.created_at | time_tag: '%B %d, %Y' }}
          {{ article.created_at | time_tag: format: 'abbreviated_date' }}
          {{ article.created_at | time_tag: '%B %d, %Y', datetime: '%Y-%m-%d' }}
          {{ article.created_at | time_tag: format: 'month_day_year' }}
      `,
    },
    times: {
      params: `"times": requiert 1 paramètre au format nombre -> %% error_signal %%`,
      value: `"times": factor1 doit être un nombre -> %% error_signal %%`,
      factor: `"times": factor2 doit être un nombre -> %% error_signal %%`,
      example: `
      "times" message: %% message %%
      Exemple 1 :
        - Entrée : {{ 100 | times: 10}}
        - Sortie : 1000
    `,
    },
    truncate: {
      params: `"truncate": Les paramètres doivent être au format chaîne de caractères -> %% error_signal %%`,
      value: `"truncate": value doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "truncatewords" message: %% message %%
      Exemple 1 :
        - Entrée : {{ "Ground control to Major Tom." | truncate: 20 }}
        - Sortie : Ground control to...

      Exemple 2 :
        - Entrée : {{ "Ground control to Major Tom." | truncate: 25, ", and so on" }}
        - Sortie : Ground control, and so on
      `,
    },
    truncatewords: {
      params: `"truncatewords": nécessite au moins 1 paramètre au format nombre et chaîne de caractères -> %% error_signal %%`,
      value: `"truncatewords": value doit être une chaîne de caractères -> %% error_signal %%`,
      quantity_words: `"truncatewords": quantityWords doit être un nombre -> %% error_signal %%`,
      example: `
      "truncatewords" message: %% message %%
      Exemple 1 :
        - Entrée : {{ "Ground control to Major Tom." | truncatewords: 3 }}
        - Sortie : Ground control to...
      Exemple 2 :
        - Entrée : {{ "Ground control to Major Tom." | truncatewords: 3, "--" }}
        - Sortie : Ground control to--
    `,
    },
    uniq: {
      value: `"uniq": La valeur doit être un tableau de valeurs primitives -> %% error_signal %%`,
      example: `
      "uniq" message: %% message %%
      Exemple 1 :
        - Entrée :
          {% assign my_array = "ants, bugs, bees, bugs, ants" | split: ", " %}
          {{ my_array | uniq | join: ", " }}
        - Sortie : ants, bugs, bees
    `,
    },
    upcase: ``,
    url_decode: {
      value: `"url_decode": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "url_decode" message: %% message %%
      Exemple 1 :
        - Entrée :
        {{ "%27Stop%21%27+said+Fred" | url_decode }}
        - Sortie : 'Stop!' said Fred
    `,
    },
    url_encode: ``,
    url_escape: {
      value: `"url_escape": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "url_escape" message: %% message %%
      Exemple 1 :
        - Entrée :
          {{ '<hello> & <shopify>' | url_escape }}
        - Sortie : <hello> %26 <shopify>
    `,
    },
    url_for_type: {
      value: `"url_for_type": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
       "url_for_type" message: %% message %%
       Exemple 1 :
         - Entrée :
          {{ "T-shirt" | url_for_type }}
         - Sortie :
          /collections/types?q=T-shirt
     `,
    },
    url_for_vendor: {
      value: `"url_for_vendor": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
        "url_for_vendor" message: %% message %%
        Exemple 1 :
          - Entrée :
           {{ "Shopify" | url_for_vendor }}
          - Sortie :
           /collections/vendors?q=Shopify
      `,
    },
    url_param_escape: ``,
    video_tag: `video_tag n'est pas pris en charge`,
    weight_with_unit: {
      example: `
      "weight_with_unit" message: %% message %%
      Exemple 1 :
        - Entrée : {{ product.variants.first.weight | weight_with_unit }}
        - Sortie : 24.0 kg
      Exemple 2 :
        - Entrée : {{ variant.weight | weight_with_unit: variant.weight_unit }}
        - Sortie : 52.9 lb
    `,
    },
    where: {
      params: `"where" nécessite au moins un paramètre au format chaîne de caractères -> %% error_signal %%`,
      value: `"where": La valeur doit être un tableau -> %% error_signal %%`,
      property: `"where": La propriété est obligatoire -> %% error_signal %%`,
      property_non_exist: `"where": La propriété n'existe pas -> %% error_signal %%`,
      example: `
      "where" message: %% message %%
      Exemple 1 :
        - Entrée :
          {% for product in products %}
          - {{ product.title }}
          {% endfor %}
          {% assign kitchen_products = products | where: "type", "kitchen" %}
          Produits de cuisine :
          {% for product in kitchen_products %}
          - {{ product.title }}
          {% endfor %}
        - Sortie :
          Tous les produits :
          - Aspirateur
          - Spatule
          - Télévision
          - Presse-ail

          Produits de cuisine :
          - Spatule
          - Presse-ail
    `,
    },
    within: {
      params: `"within" nécessite un paramètre au format objet de collection -> %% error_signal %%`,
      value: `"within": La valeur doit être une chaîne de caractères -> %% error_signal %%`,
      example: `
      "within" message: %% message %%
        - Entrée: <a href="{{ product.url | within: collection }}">{{ product.title }}</a>
        - Sortie: <a href="/collections/frontpage/products/alien-poster">Alien Poster</a>
    `,
    },
  },
  global_object: {
    powered_by_link: ``,
  },
  iteration: {
    break: `break n'est pas pris en charge. Vous pouvez utiliser "if" pour gérer ce cas.`,
    continue: `continue n'est pas pris en charge. Vous pouvez utiliser "if" pour gérer ce cas.`,
    cycle: `La balise 'cycle' n'est pas prise en charge. Vous pouvez utiliser "if else" combiné avec le filtre 'slice' pour gérer ce cas.`,
    limit: `
      "limit" a quelque chose qui ne va pas ! %%error_signal%%
      Entrée :
      {% for item in array limit: 2 %}
       {{ item }}
      {% endfor %}

      {% for item in array limit: variableName %}
       {{ item }}
      {% endfor %}

      {% for item in array limit: variableObject.key %}
       {{ item }}
      {% endfor %}
      Sortie :
      {% for item in array | slice(0, 2) %}
        {{ item }}
      {% endfor %}

      {% for item in array | slice(0, variableName) %}
       {{ item }}
      {% endfor %}

      {% for item in array | slice(0, variableObject.key) %}
       {{ item }}
      {% endfor %}
    `,
    offset: `
      "offset" a quelque chose qui ne va pas ! %%error_signal%%
      Entrée :
        {% for item in array offset: 2 %}
          {{ item }}
        {% endfor %}

        {% for item in array offset: variableName %}
          {{ item }}
        {% endfor %}

        {% for item in array offset: variableObject.key %}
          {{ item }}
        {% endfor %}
        Sortie :
        {% for item in array | slice(2, 99999999) %}
          {{ item }}
        {% endfor %}

        {% for item in array | slice(variableName, 99999999) %}
          {{ item }}
        {% endfor %}

        {% for item in array | slice(variableObject.key, 99999999) %}
          {{ item }}
        {% endfor %}
    `,
    reversed: ``,
    tablerow: `La balise 'tablerow' n'est pas prise en charge. Vous pouvez utiliser une boucle 'forloop' standard comme solution de remplacement dans ce cas.`,
  },
  section_schema: "La balise 'schema' n'est pas prise en charge",
  theme_tags: {
    forms: {
      unnestable: "La balise 'form' ne prend pas en charge la forme imbriquée car elle n'est tout simplement pas nécessaire",
      activate_customer_password: `"activate_customer_password" a quelque chose qui ne va pas -> %%error_signal%%`,
      reset_customer_password: `"reset_customer_password" a quelque chose qui ne va pas -> %%error_signal%%`,
      contact: `"contact" a quelque chose qui ne va pas -> %%error_signal%%`,
      create_customer: `"create_customer" a quelque chose qui ne va pas -> %%error_signal%%`,
      customer_login: `"customer_login" a quelque chose qui ne va pas -> %%error_signal%%`,
      customer: `"customer" a quelque chose qui ne va pas -> %%error_signal%%`,
      guest_login: `"guest_login" a quelque chose qui ne va pas -> %%error_signal%%`,
      new_comment: `"new_comment" a quelque chose qui ne va pas -> %%error_signal%%`,
      product: `"product" a quelque chose qui ne va pas -> %%error_signal%%`,
      recover_customer_password: `"recover_customer_password" a quelque chose qui ne va pas -> %%error_signal%%`,
      storefront_password: `"storefront_password" a quelque chose qui ne va pas -> %%error_signal%%`,
      localization: `"localization" a quelque chose qui ne va pas -> %%error_signal%%`,
    },
    comment: {
      example: `
      "example" a quelque chose qui ne va pas -> %%error_signal%%
      Syntaxe correcte :
      {% comment %}
        contenu
      {% endcomment %}
      `,
    },
    layout: `La balise 'layout' n'est pas prise en charge`,
    liquid: {
      example: `
      "liquid" a quelque chose qui ne va pas -> %%error_signal%%
      Syntaxe correcte :
      {% liquid
        assign product_type = product.type | downcase
        assign message = ''

        case product_type
          when 'health'
            assign message = 'Ceci est une potion de santé !'
          when 'love'
            assign message = 'Ceci est une potion d'amour !'
          else
            assign message = 'Ceci est une potion !'
        endcase

        echo message
      %}
      `,
    },
    paginate: {
      example: `
        "paginate" a quelque chose qui ne va pas -> %%error_signal%%
        Syntaxe correcte :
          {% paginate collection.products by 2 %}
            {% for product in collection.products %}
              {{ product.title }} carte produit
            {% endfor %}
          {% endpaginate %}
      `,
    },
    render: {
      not_exist: `%%snippet_name%% n'existe pas -> %%error_signal%%`,
      example: `
        "render" a quelque chose qui ne va pas -> %%error_signal%%
        {% render 'nomFichier', variable1: valeur, variable2: valeur %}
      `,
    },
    section: `La balise 'section' n'est pas prise en charge`,
    style: ``,
  },
  variable_tags: {
    capture: ``,
    decrement: ``,
    increment: ``,
  },
  exception_of_code_liquid: {
    variable: `%%error_signal%%: 2.2: Le nom est en conflit avec un nom de variable, de balise ou de filtre de Shopify`,
    variable_outside_shopify_tag: `%%error_signal%%: Vous devez envelopper le code dans les balises <shopify></shopify> pour pouvoir utiliser les données Shopify`,
    filter: `%%error_signal%%: 2.3: Les filtres et les balises doivent être incluses dans les balises "shopify" pour une conversion automatique en fonction de la boutique`,
    filter_in_if_tag: `%%error_signal%%: 2.15: L'utilisation de filtres dans la balise "if" n'est pas possible`,
    field_value: `%%error_signal%%: Liquid ne prend pas en charge les types de données objet et tableau`,
    nested_form: `%%error_signal%%: Les balises "{% form %}...{% endform %}" imbriquées ne sont pas prises en charge`,
    nested_paginate: `%%error_signal%%: Les balises "{% paginate %}...{% endpaginate %}" imbriquées ne sont pas prises en charge`,
    nested_liquid_tag: `%%error_signal%%: Les balises "{% liquid ... %}" imbriquées ne sont pas prises en charge`,
  },
  clause_in_shopify: {
    navigation: `Vous ne pouvez pas utiliser "navigation" dans la balise shopify`,
    products: `Vous ne pouvez pas utiliser "products" dans la balise shopify`,
    array: `2.1: Vous ne pouvez pas utiliser un tableau dans la balise shopify`,
    translate_field: 'Vous ne pouvez pas utiliser la fonction de traduction dans un tableau',
    reassign_value_in_liquid_tag: `La variable "%%variable_name%%" est actuellement utilisée dans une boucle "forloop" et ne peut pas être réaffectée dans {% liquid ... %}. Vous pouvez utiliser {% assign %%variable_name%% = ... %} comme solution de contournement`,
  },
  error_notification_title: `Erreur dans la section %%section_name%% :`,
  error_liquid_syntax_to_twig: `
    <div style="font-size: 1.5em; font-family: Consolas, Menlo, monospace; color: black; margin-bottom: 2rem; display: flex; align-items: center">
      <span>Cliquez sur </span>
      <div style="width: 36px; height: 36px; background: #FFFFFF; border-radius: 4px; border: 1px solid #DEDEE9; display: flex; justify-content: center; align-items: center; margin: 0px 6px">
        <i style="font-size: 16px" class="far fa-sync-alt"></i>
      </div>
      <span>en haut pour corriger l'erreur.</span>
    </div>
    <div style="font-size: 1.5em; font-family: Consolas, Menlo, monospace; color: black; margin-bottom: 2rem; display: flex; align-items: center">
      <span>Si cela ne fonctionne toujours pas, cliquez sur </span>
      <div style="width: 36px; height: 36px; background: #2AB885; color: #FFFFFF; border-radius: 4px; border: 1px solid #2AB885; display: flex; justify-content: center; align-items: center; margin: 0px 6 px">
        <i style="font-size: 16px" class="far fa-comments"></i>
      </div>
      <span>en haut pour obtenir de l'aide.</span>
    </div>
  `,
} as const;
