import { mergeDeepLeft, sort } from 'ramda';
import { TextField } from 'services/ShopifyConnection/utils/VedaOutputToShopifyThemeEditor/@types/Shopify/InputSettings/TextField';
import { Locales } from 'services/ShopifyConnection/utils/VedaOutputToShopifyThemeEditor/@types/Veda/ShopifyLocales';
import { replaceExactVariableNameInLiquidCode } from 'services/ShopifyConnection/utils/VedaOutputToShopifyThemeEditor/@utils/replaceExactVariableNameInLiquidCode';
import { ignoreToReduceTotalLinesOfCode } from '../../../@utils/ignoreToReduceTotalLinesOfCode';
import { sortWithShopifyFieldIdSignal } from '../../../@utils/sortWithShopifyFieldIdSignal';
import { ILIQUID_SettingFlexOrder } from './@types/ILIQUID_SettingFlexOrder';
import { ISCHEMA_SettingFlexOrder } from './@types/ISCHEMA_SettingFlexOrder';
import { ISETTING_SettingFlexOrder } from './@types/ISETTING_SettingFlexOrder';
import { getSignalReduceTotalLinesOfCode } from './utils/getSignalReduceTotalLinesOfCode';
import { toShopifyFieldId } from './utils/toShopifyFieldId';
import { vedaOptionLabelToShopifyFieldLabel } from './utils/vedaOptionLabelToShopifyFieldLabel';
import { vedaSummariesOfFlexOrderFieldToShopifyFieldInfo } from './utils/vedaSummariesOfFlexOrderFieldToShopifyFieldInfo';

interface RTSettingFlexOrder {
  shopifyField: TextField[];
  locales: Locales;
}
export const SCHEMA_SettingFlexOrder = ({ field, parentField, ...params }: ISCHEMA_SettingFlexOrder): RTSettingFlexOrder => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode ||
    (params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField }));
  let RLocales: Locales = {
    en: {},
    fr: {},
    vi: {},
  };

  const optionsSorted = sort(sortWithShopifyFieldIdSignal, field.options);
  const data = optionsSorted.reduce<TextField[]>((res, option) => {
    const { newLabelOfKey, locales: localesOfLabel } = vedaOptionLabelToShopifyFieldLabel(
      { ...params, field, parentField, isNeedReduceTotalLinesOfCode },
      option,
    );
    const { newInfoOfKey, locales: localesOfInfo } = vedaSummariesOfFlexOrderFieldToShopifyFieldInfo({
      ...params,
      field,
      parentField,
      isNeedReduceTotalLinesOfCode,
    });
    RLocales = mergeDeepLeft(RLocales, localesOfLabel);
    RLocales = mergeDeepLeft(RLocales, localesOfInfo);
    const value = field.children[option.name];
    const valueString = typeof value === 'number' ? value.toString() : undefined;
    return res.concat({
      type: 'text',
      default: valueString,
      id: isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, option)
        : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, option),
      placeholder: undefined,
      info: newInfoOfKey,
      label: newLabelOfKey,
    });
  }, []);

  return {
    locales: RLocales,
    shopifyField: data,
  };
};

export const SETTING_SettingFlexOrder = ({ field, parentField, ...params }: ISETTING_SettingFlexOrder) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode ||
    (params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField }));
  return field.options.reduce<Record<string, string | undefined>>((res, option) => {
    // check kĩ phòng lỗi
    const value = field.children[option.name];
    const valueString = typeof value === 'number' ? value.toString() : undefined;
    if (valueString !== undefined || isNeedReduceTotalLinesOfCode) {
      const fieldName = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, option)
        : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, option);
      return {
        ...res,
        [fieldName]: valueString,
      };
    }
    return res;
  }, {});
};

export const LIQUID_SettingFlexOrder = ({ liquid, loopVariable, field, parentField, ...params }: ILIQUID_SettingFlexOrder) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode ||
    (params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField }));
  // Nếu field thuộc array
  // - loopVariable nếu là array ->  Array được dùng trong forloop -> Thế `${loopVariable}.${field.name}.${key}` = `${loopVariable}.${toShopifyFieldId({ field, parentField }, key)}`
  //   Example: --> for item in content --> item.icon_design.id ==> KQ: item.content__icon_design.id
  if (loopVariable) {
    return field.options.reduce<string>((res, option) => {
      const target = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, option)
        : `${loopVariable}.${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, option)}`;
      const source = `${loopVariable}.${field.name}.${option.name}`;
      return replaceExactVariableNameInLiquidCode({ liquid: res, source, target });
    }, liquid);
  }
  // Nếu field thuộc object
  // - parentField nếu là object -> Object được flat thành các "shopify input settings" -> Thế `${parentField.name}.${field.name}.${key}` = toShopifyFieldId
  //   Example: --> heading.icon_design.id ==> KQ: heading__icon_design.id
  else if (parentField) {
    return field.options.reduce<string>((res, option) => {
      const target = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, option)
        : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, option)}"]`;
      const source = `${parentField.name}.${field.name}.${option.name}`;
      return replaceExactVariableNameInLiquidCode({ liquid: res, source, target });
    }, liquid);
  } else {
    return field.options.reduce<string>((res, option) => {
      const target = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, option)
        : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, option)}"]`;
      const source = `${field.name}.${option.name}`;
      return replaceExactVariableNameInLiquidCode({ liquid: res, source, target });
    }, liquid);
  }
};
