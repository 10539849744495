import { createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';
import initialState from './initialState';
import {
  CancelModalUpdateScheme,
  SaveModalUpdateScheme,
  OpenModalUpdateScheme,
  ReverseGrayColorsSchemeInteractingAction,
  SetColorSchemesAction,
  UpdateColorSchemeInteractingAction,
  AddNewColorScheme,
  DeleteColorScheme,
  UpdateColorSchemeName,
  InsertPageColorSchemeToTheme,
} from './types';
import { isGray1To9 } from 'containers/BuilderPage/components/Settings/ThemeSettings/ThemeColorScheme/@constants/pSBCThreshold';
import { clone } from 'ramda';
import { isDarkColor, isLightColor } from 'containers/BuilderPage/components/Settings/ThemeSettings/ThemeColorScheme/@constants/isDarkOrLightColor';
import { ColorScheme, ColorSchemes } from 'types/Result';
import { v4 } from 'uuid';
import { removeDuplicateByKey } from '@wiloke/functions';

const REVERSE_GRAY_COLORS: Record<string, string> = {
  '--color-gray9': '--color-gray1',
  '--color-gray8': '--color-gray2',
  '--color-gray7': '--color-gray3',
  '--color-gray6': '--color-gray4',
  '--color-gray5': '--color-gray5',
  '--color-dark': '--color-light',
};

function duplicateItemsWithDiffIds(colors: ColorSchemes) {
  const firstIndex = 0;
  const secondIndex = 1;

  if (firstIndex < 0 || firstIndex >= colors.length || secondIndex < 0 || secondIndex >= colors.length) {
    return colors;
  }

  const _newId = v4();

  const item1Copy: ColorScheme = {
    ...colors[firstIndex],
    uniqueName: `${colors[firstIndex].uniqueName} ${colors.length}`,
    cssClassName: `veda-color-${_newId}`,
  };
  const item2Copy: ColorScheme = {
    ...colors[secondIndex],
    uniqueName: `${colors[secondIndex].uniqueName} ${colors.length}`,
    cssClassName: `veda-color-dark-${_newId}`,
  };

  colors.splice(2, 0, item1Copy);
  colors.splice(3, 0, item2Copy);

  return colors;
}

function insertArrayAtPosition<T>(targetArray: T[], sourceArray: T[], position: number): T[] {
  if (position < 0 || position > targetArray.length) {
    throw new Error('Invalid position');
  }

  const resultArray: T[] = [...targetArray.slice(0, position), ...sourceArray, ...targetArray.slice(position)];

  return resultArray;
}

export const sliceColorSchemes = createSlice<
  typeof initialState,
  | SetColorSchemesAction
  | UpdateColorSchemeInteractingAction
  | ReverseGrayColorsSchemeInteractingAction
  | OpenModalUpdateScheme
  | CancelModalUpdateScheme
  | SaveModalUpdateScheme
  | AddNewColorScheme
  | DeleteColorScheme
  | UpdateColorSchemeName
  | InsertPageColorSchemeToTheme
>({
  name: '@Global',
  initialState,
  reducers: [
    // Force set all
    handleAction('setColorSchemes', ({ action, state }) => {
      return {
        ...state,
        colorSchemes: action.payload.colorSchemes,
      };
    }),

    // Add
    handleAction('addNewColorScheme', ({ state }) => {
      state.colorSchemes = duplicateItemsWithDiffIds(state.colorSchemes);
    }),
    handleAction('insertPageColorSchemeToTheme', ({ state, action }) => {
      state.colorSchemes = removeDuplicateByKey(insertArrayAtPosition(state.colorSchemes, action.payload.colors, 2), 'cssClassName');
    }),

    // Delete
    handleAction('deleteColorScheme', ({ state, action }) => {
      state.colorSchemes = state.colorSchemes.filter(item => item.uniqueName !== action.payload);
    }),

    // Update
    handleAction('openModalUpdateScheme', ({ state, action }) => {
      state.colorSchemeInteracting = action.payload.colorSchemeInteracting;
    }),
    handleAction('cancelModalUpdateScheme', ({ state }) => {
      state.colorSchemeInteracting = null;
    }),
    handleAction('saveModalUpdateScheme', ({ state }) => {
      state.colorSchemes.forEach((colorScheme, index) => {
        if (state.colorSchemeInteracting && colorScheme.cssClassName === state.colorSchemeInteracting?.cssClassName) {
          state.colorSchemes[index] = state.colorSchemeInteracting;
        }
      });
      state.colorSchemeInteracting = null;
    }),
    handleAction('updateColorSchemeInteracting', ({ state, action }) => {
      const { colors } = action.payload;
      if (state.colorSchemeInteracting) {
        state.colorSchemeInteracting = {
          ...state.colorSchemeInteracting,
          colors: colors,
        };
      }
    }),
    handleAction('reverseGrayColorsSchemeInteractingAction', ({ state }) => {
      if (state.colorSchemeInteracting) {
        const colorScheme_ = clone(state.colorSchemeInteracting);
        colorScheme_.colors.forEach((color, index) => {
          if (isGray1To9(color) || isDarkColor(color) || isLightColor(color)) {
            const nameReversed = REVERSE_GRAY_COLORS[color.name];
            const colorReversedIndex = colorScheme_.colors.findIndex(color => color.name === nameReversed);
            if (colorReversedIndex !== -1 && state.colorSchemeInteracting) {
              const targetColor = {
                light: state.colorSchemeInteracting.colors[colorReversedIndex].light,
                dark: state.colorSchemeInteracting.colors[colorReversedIndex].dark,
              };
              const currentColor = {
                light: state.colorSchemeInteracting.colors[index].light,
                dark: state.colorSchemeInteracting.colors[index].dark,
              };
              colorScheme_.colors[index] = {
                ...colorScheme_.colors[index],
                light: targetColor.light,
                dark: targetColor.dark,
              };
              colorScheme_.colors[colorReversedIndex] = {
                ...colorScheme_.colors[colorReversedIndex],
                light: currentColor.light,
                dark: currentColor.dark,
              };
            }
          }
        });
        state.colorSchemeInteracting = colorScheme_;
      }
    }),

    // Update Name
    handleAction('updateColorSchemeName', ({ state, action }) => {
      if (state.colorSchemeInteracting) {
        state.colorSchemeInteracting = {
          ...state.colorSchemeInteracting,
          uniqueName: action.payload.uniqueName,
        };
      }
    }),
  ],
});

export const {
  setColorSchemes,
  updateColorSchemeInteracting,
  reverseGrayColorsSchemeInteractingAction,
  cancelModalUpdateScheme,
  openModalUpdateScheme,
  saveModalUpdateScheme,
  addNewColorScheme,
  deleteColorScheme,
  updateColorSchemeName,
  insertPageColorSchemeToTheme,
} = sliceColorSchemes.actions;

export const useSetColorSchemes = createDispatchAction(setColorSchemes);
export const useOpenModalUpdateScheme = createDispatchAction(openModalUpdateScheme);
export const useCancelModalUpdateScheme = createDispatchAction(cancelModalUpdateScheme);
export const useSaveModalUpdateScheme = createDispatchAction(saveModalUpdateScheme);
export const useUpdateColorSchemeInteracting = createDispatchAction(updateColorSchemeInteracting);
export const useReverseGrayColorsSchemeInteractingAction = createDispatchAction(reverseGrayColorsSchemeInteractingAction);
export const useAddNewColorScheme = createDispatchAction(addNewColorScheme);
export const useDeleteColorScheme = createDispatchAction(deleteColorScheme);
export const useUpdateColorSchemeName = createDispatchAction(updateColorSchemeName);
