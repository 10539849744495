import { ImageField } from '../../../@types/Shopify/InputSettings/ImageField';
import { getShopifyFieldInfo } from './utils/getShopifyFieldInfo';
import { getShopifyFieldLabel } from './utils/getShopifyFieldLabel';

export const SettingFavicon = (): ImageField => {
  return {
    type: 'image_picker',
    id: 'favicon',
    label: getShopifyFieldLabel().newLabel,
    info: getShopifyFieldInfo().newInfo,
  };
};
