import { RangeField } from '../../../@types/Shopify/InputSettings/RangeField';
import { getShopifyFieldLabel } from './utils/getShopifyFieldLabel';

export const SettingLogoHeight = (): RangeField => {
  return {
    type: 'range',
    id: 'logo_height',
    min: 30,
    max: 300,
    step: 10,
    default: 30,
    unit: 'px',
    label: getShopifyFieldLabel().newLabel,
  };
};
