import { css, Theme } from 'wiloke-react-core';

export const container = css`
  debug: RightBar-container;
  border-radius: 6px;
  margin-left: 20px;
  padding: 0;
`;

export const box = css`
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  margin: 0 auto 20px;
`;

export const boxCursor = css`
  cursor: pointer;
  border-radius: 6px;
`;

export const boxHeader = () => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

export const boxBody = css``;

export const boxBodyItem = css`
  padding: 10px;
  text-align: left;
`;

export const button = ({ colors }: Theme) => css`
  background-color: rgba(${colors.rgbGray5}, 0.2);
  margin-bottom: 10px;
`;

export const buttonFlex = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const icon = ({ colors }: Theme) => css`
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: ${colors.light};
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
  }
`;

export const iconWrap = ({ colors }: Theme) => css`
  debug: TopBar_iconWrap;
  background-color: ${colors.light};
  min-width: 44px;
  height: 44px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid ${colors.gray3};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const itemFull = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
