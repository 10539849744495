export const ModalTour = {
  install_with: 'Installer et personnaliser %% text %%',
  already_know: 'Je le sais déjà',
  interactive_tutorials: 'Interactive Tutorials',
  tour_theme: {
    step_1: {
      title: 'Parcourir la bibliothèque de thèmes',
      description: 'De nombreux modèles étonnants à choisir.',
    },
    step_2: {
      title: 'Installer votre thème',
      description: 'Survolez le thème pour prévisualiser et cliquez sur "Installer" votre préféré.',
    },
  },
  install_theme: {
    step_1: {
      label_1: 'Please download theme %% themeName %% first.',
      label_2: 'Then, you can install and customize it up!',
      label_3: 'Click here to download theme.',
      label_4: 'Skip, I have already installed this theme before.',
      label_5: 'Your plan does not support this feature. Please upgrade to a higher plan.',
    },
    step_2: {
      label_1: "You haven't installed the theme %% themeName %% to Shopify.",
      next_step: 'Next Step',
    },
    step_3: {
      label_1: 'Explore Veda Builder to make your theme unique.',
      label_2: 'The import process may take a few minutes, please do not close the browser until the data is imported.',
      customize_now: 'Customize now',
    },
  },
  tour_page: {
    step_1: {
      title: 'Choisissez le type de page',
      description: 'Cliquez sur le type de page que vous souhaitez remplacer/ajouter.',
    },
    step_2: {
      title: 'Créez une nouvelle page',
      description: 'Cliquez sur ce bouton pour parcourir de superbes modèles de page.',
    },
    step_3: {
      title: 'Installez votre page',
      description: 'Survolez la page pour prévisualiser et cliquez sur "Installer" votre préférée.',
    },
  },
  tour_addon: {
    step_1: {
      title: 'Ajoutez un nouvel addon',
      description: `Cliquez sur "Ajouter des addons" pour ouvrir la bibliothèque d'addons.`,
    },
    step_2: {
      title: `Sélectionnez la catégorie d'addon`,
      description: 'Vous pouvez basculer entre les addons intégrés et les addons tiers.',
    },
    step_3: {
      title: 'Installez votre addon',
      description: 'Cliquez sur "Ajouter" pour installer votre addon préféré.',
    },
  },
} as const;
