import { ActionType } from 'containers/IframePage/components/ComponentToolbar';
import { pmChildren } from 'utils/functions/postMessage';

export const useToolbarAddonActions = () => {
  const toolbarActionMapping: Record<Extract<ActionType, 'delete'>, (sectionId: string) => void> = {
    delete: sectionId => {
      pmChildren.emit('@addon/delete', { sectionId });
    },
  };

  const handleToolbarAction = (id: string, type: Extract<ActionType, 'delete'>) => {
    toolbarActionMapping[type](id);
  };

  return handleToolbarAction;
};
