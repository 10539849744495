import ChooseTemplate from './ChooseTemplate';
import {
  watchApproveAddonsToAdmin,
  watchCreateAdminAddonCategory,
  watchCreateAdminAddonChangelog,
  watchCreateDraftAddonCategory,
  watchCreateUserAddonCategory,
  watchDeleteAdminAddons,
  watchDeleteDraftAddons,
  watchDeleteProductAddon,
  watchForkAddonAdminToDraft,
  watchGetAddonNav,
  watchGetAddonsTemplate,
  watchGetAdminAddonCategory,
  watchGetAdminAddons,
  watchGetDraftAddonCategory,
  watchGetDraftAddons,
  watchInstallAddons,
  watchInstallAdminAddon,
  watchInstallDraftAddon,
  watchLoadMoreAddons,
  watchLoadMoreAdminAddons,
  watchLoadMoreDraftAddons,
  watchPublishAdminAddonToProduct,
  watchRejectAdminAddon,
  watchRejectDraftAddon,
} from './store/sagas/addons';
import { watchAddAtomSectionNoRequest, watchAddDraftSectionNoRequest } from './store/sagas/flows';

import {
  watchCreateAdminMegaMenuChangelog,
  watchDeleteAdminMegaMenu,
  watchPublishAdminMegaMenu,
  watchRejectAtomMegaMenu,
  watchGetAdminMegaMenu,
  watchLoadMoreAdminMegaMenu,
  watchApproveMegaMenuToAdmin,
  watchDeleteDraftMegaMenu,
  watchForkAdminMegaMenuToDraft,
  watchRejectDraftMegaMenu,
  watchGetDraftMegaMenu,
  watchLoadMoreDraftMegaMenu,
  watchInstallDraftMegaMenu,
  watchInstallAdminMegaMenu,
} from './store/sagas/megaMenu';

import {
  watchApproveSectionToAdmin,
  watchCategories,
  watchCreateAdminCategory,
  watchCreateDraftCategory,
  watchCreateProductCategory,
  watchDeleteAdminSection,
  watchDeleteDraftSection,
  watchDeleteProductSection,
  watchForkAdminSectionToDraft,
  watchGetAdminCategories,
  watchGetAdminSections,
  watchGetDraftCategories,
  watchGetDraftSections,
  watchInstallAdminSection,
  watchInstallDraftSection,
  watchLoadMoreAdminSections,
  watchLoadMoreDraftSections,
  watchLoadMoreSections,
  watchPublishAdminSection,
  watchRejectAtom,
  watchRejectDraftSection,
  watchRemoveSavedSection,
  watchSection,
  watchSections,
  watchCreateEnvatoCategory,
  watchGetEnvatoCategories,
  watchGetSavedSections,
  watchSaveToFavorite,
  watchInstallFavoriteSection,
  watchUpdateAdminCategory,
  watchDeleteProductCategory,
  watchUpdateProductCategory,
  watchDeleteAdminCategory,
  watchLoadMoreSavedSections,
  watchLoadMoreAdminCategories,
  watchLoadMoreDraftCategories,
  watchCreateAtomChangelog,
} from './store/sagas/sections';

const sagasChooseTemplate = [
  // user section
  watchSection,
  watchSections,
  watchCategories,
  watchRemoveSavedSection,
  watchLoadMoreSections,
  watchCreateProductCategory,
  watchCreateEnvatoCategory,
  watchGetEnvatoCategories,
  watchGetSavedSections,
  watchSaveToFavorite,
  watchInstallFavoriteSection,
  watchDeleteProductCategory,
  watchUpdateProductCategory,
  watchLoadMoreSavedSections,

  // dev section
  watchGetDraftSections,
  watchLoadMoreDraftSections,
  watchGetDraftCategories,
  watchCreateDraftCategory,
  watchDeleteDraftSection,
  watchApproveSectionToAdmin,
  watchForkAdminSectionToDraft,
  watchRejectDraftSection,
  watchInstallDraftSection,
  watchLoadMoreDraftCategories,

  // admin section
  watchGetAdminSections,
  watchLoadMoreAdminSections,
  watchCreateAdminCategory,
  watchGetAdminCategories,
  watchDeleteAdminSection,
  watchPublishAdminSection,
  watchCreateAtomChangelog,
  watchRejectAtom,
  watchInstallAdminSection,
  watchDeleteProductSection,
  watchDeleteAdminCategory,
  watchUpdateAdminCategory,
  watchDeleteAdminCategory,
  watchLoadMoreAdminCategories,

  // user addons
  watchGetAddonsTemplate,
  watchGetAddonNav,
  watchInstallAddons,
  watchLoadMoreAddons,
  watchCreateUserAddonCategory,

  // draft addons
  watchDeleteDraftAddons,
  watchLoadMoreDraftAddons,
  watchGetDraftAddons,
  watchApproveAddonsToAdmin,
  watchGetDraftAddonCategory,
  watchCreateDraftAddonCategory,
  watchForkAddonAdminToDraft,
  watchRejectDraftAddon,
  watchInstallDraftAddon,

  // admin addons
  watchGetAdminAddons,
  watchLoadMoreAdminAddons,
  watchDeleteAdminAddons,
  watchGetAdminAddonCategory,
  watchCreateAdminAddonCategory,
  watchPublishAdminAddonToProduct,
  watchCreateAdminAddonChangelog,
  watchRejectAdminAddon,
  watchInstallAdminAddon,
  watchDeleteProductAddon,

  // admin mega menu
  watchPublishAdminMegaMenu,
  watchDeleteAdminMegaMenu,
  watchLoadMoreAdminMegaMenu,
  watchGetAdminMegaMenu,
  watchCreateAdminMegaMenuChangelog,
  watchRejectAtomMegaMenu,
  watchInstallAdminMegaMenu,

  // dev mega menu
  watchForkAdminMegaMenuToDraft,
  watchApproveMegaMenuToAdmin,
  watchDeleteDraftMegaMenu,
  watchLoadMoreDraftMegaMenu,
  watchGetDraftMegaMenu,
  watchRejectDraftMegaMenu,
  watchInstallDraftMegaMenu,

  // flows
  watchAddAtomSectionNoRequest,
  watchAddDraftSectionNoRequest,
];

export { ChooseTemplate, sagasChooseTemplate };
