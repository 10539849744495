import { AxiosError } from 'axios';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { checkThemeIsInstallRequest } from 'services/ShopifyConnection/services/checkThemeIsInstall';
import { ErrorData } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { checkThemeIsInstall } from '../actions';

function* handleInstall({ payload: { themeName, commandId, onFulfill } }: ReturnType<typeof checkThemeIsInstall.request>) {
  try {
    const response: SagaReturnType<typeof checkThemeIsInstallRequest> = yield retry(3, 1000, checkThemeIsInstallRequest, { themeName });
    yield put(checkThemeIsInstall.success({ commandId, themeShopifyId: response.id }));
    onFulfill?.();
  } catch (error) {
    yield put(
      checkThemeIsInstall.failure({ message: (error as AxiosError<ErrorData>).response?.data.message || (error as Error).message, commandId }),
    );
  }
}

export function* watchCheckThemeIsInstall() {
  yield takeLatest(getActionType(checkThemeIsInstall.request), handleInstall);
}
