import { clone } from 'ramda';
import { PageSection } from 'types/Sections';
import { v4 } from 'uuid';
import { getDefaultSectionData, getDefaultSectionSchema, getDefaultSectionSettings } from './constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { ColorSchemeVariableNameInLiquid } from './constants';

/* Khi cài 1 section của atom admin hoặc product cần tạo 1 fe id mới để tránh bị trùng key */
export const adapterGetSection = (sectionInput: PageSection) => {
  const { role } = getUserInfo();
  const isIncludeColorSchemeField = !!sectionInput.data.schema.settings.find(setting => setting.type === 'color_scheme');

  // Check trường hợp nếu color_scheme có children null thì sửa mặc định là veda-color-default
  const section: PageSection = {
    ...sectionInput,
    data: {
      ...sectionInput.data,
      settings: sectionInput.data.settings.map(setting => {
        if (setting.type === 'color_scheme' && setting.children == null) {
          return {
            ...setting,
            children: 'veda-color-default',
          };
        }
        return setting;
      }),
    },
  };
  const defaultSectionSchema = getDefaultSectionSchema();
  const defaultSectionData = getDefaultSectionData();
  const defaultSectionSettings = getDefaultSectionSettings();
  const liquid_ =
    // FIXME: Nhả khi mở cho client => Cần xem xét khi nhả nút này
    role === 'admin' && !isIncludeColorSchemeField
      ? (section.data?.liquid ?? defaultSectionData.liquid).replace(/(<section([^>]+)>)/, openTag => {
          return openTag.replace(/class=('|")/, value => value.concat(`bgc:color-light c:color-gray7 {{ ${ColorSchemeVariableNameInLiquid} }} `));
        })
      : section.data?.liquid ?? defaultSectionData.liquid;

  return {
    ...section,
    id: `id_${v4()}`,
    type: section?.type ?? 'default',
    addonIds: section.addonIds ?? [],
    data: section.data
      ? {
          ...section.data,
          liquid: liquid_,
          schema: !section.data?.schema
            ? clone(defaultSectionSchema)
            : // FIXME: Nhả khi mở cho client => Cần xem xét khi nhả nút này
            role === 'admin' && !isIncludeColorSchemeField
            ? {
                blocks: section.data.schema.blocks,
                settings: [...clone(defaultSectionSchema.settings), ...section.data.schema.settings],
              }
            : section.data.schema,
          settings: !section.data?.settings
            ? clone(defaultSectionSettings)
            : // FIXME: Nhả khi mở cho client => Cần xem xét khi nhả nút này
            role === 'admin' && !isIncludeColorSchemeField
            ? [...defaultSectionSettings, ...section.data.settings]
            : section.data.settings,
        }
      : defaultSectionData,
  } as PageSection;
};
