import { addonApiController } from 'services/AddonService';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const getProducts = async (category: string, limit?: number, search?: string) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const response = await addonApiController.product.adminApi.addons.getProducts({
      type: 'GET FIRST PAGE',
      category,
      size: limit,
      cacheVersion: lastCache,
      search,
    });
    return response.info;
  }
  const response = await addonApiController.product.userApi.addons.getProducts({
    type: 'GET FIRST PAGE',
    category,
    size: limit,
    cacheVersion: lastCache,
    search,
  });
  return response.info;
};

export const loadMoreProducts = async (category: string, cursor: string, size: number, search?: string) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const response = await addonApiController.product.adminApi.addons.getProducts({
      type: 'LOADMORE',
      category,
      lastCursor: cursor,
      cacheVersion: lastCache,
      size,
      search,
    });
    return response.info;
  }
  const response = await addonApiController.product.userApi.addons.getProducts({
    type: 'LOADMORE',
    category,
    lastCursor: cursor,
    cacheVersion: lastCache,
    size,
    search,
  });
  return response.info;
};
