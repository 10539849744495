import { FC, useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';

export interface GuardedRouteProps extends RouteProps {
  title?: string;
}

const GuardedRoute: FC<GuardedRouteProps> = ({ title, ...rest }) => {
  // const { status, siteStatus } = useSelector(authSelector);
  // const history = useHistory();

  useEffect(() => {
    document.title = title ?? 'Veda Builder';
  }, [title]);

  // // check app is maintained
  // useEffect(() => {
  //   if (status === 'success' && siteStatus && siteStatus.siteStatus === 'MAINTAIN') {
  //     history.push('/maintenance');
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [status, siteStatus]);

  return <Route {...rest} />;
};

export default GuardedRoute;
