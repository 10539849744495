import { AxiosResponse } from 'axios';
import { AddonOfTheme_Atom_N_Client } from 'types/Addons';
import { ToRequiredKeys } from 'utils';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface GetAddonsOfDraft {
  commandIds: string[];
}

interface ResponseSuccess {
  info: Array<ToRequiredKeys<AddonOfTheme_Atom_N_Client, 'commandId'>>;
  message: string;
}

export const getAddonsOfDraft = async ({ commandIds }: GetAddonsOfDraft) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${baseUrl}/drafts/search`,
    params: {
      commandIds: commandIds.join(','),
    },
  });
  return response.data;
};
