import { AxiosResponse } from 'axios';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface Params {
  themeId: string | number;
}

interface ResponseSuccess {
  message: string;
}

export const updateThemeIdAfterMigrate = async ({ themeId }: Params) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    method: 'PUT',
    url: baseUrl,
    data: {
      themeId,
    },
  });
  return response.data;
};
