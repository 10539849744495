import { AsyncComponent, ScreenProps, ScrollBars, useStackNavigator } from '@wiloke/ui';
import HeaderDrawer from 'components/HeaderDrawer';
import SectionCard from 'components/SectionCard';
import { LeftBarParamList } from 'containers/BuilderPage/components/SidebarScreen/SidebarScreen';
import { useAddDraftMegaMenu } from 'containers/ChooseTemplate/store/actions';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAddMegaMenuToPage, useChangeMegaMenuSection } from 'store/actions/actionPages';
import {
  megaMenusOfHeaderFooterSelector,
  useAddSectionToMegaMenuOfHeaderFooter,
  useChangeSectionToMegaMenuOfHeaderFooter,
} from 'store/global/megaMenusOfHeaderFooter/sliceMegaMenusOfHeaderFooter';
import { megaMenuSelector, pageSectionsSelector } from 'store/selectors';
import { i18n } from 'translation';
import { getDate, timeAgo, timeConverter } from 'utils/timeAgo';
import { View } from 'wiloke-react-core';
import { SettingDragMenuChildren } from '../components/DraggableMenu/types';
import { useActionMenu } from '../components/DraggableMenu/utils/useActionMenu';

export const MegaMenuDraftScreen: FC<ScreenProps<LeftBarParamList, 'sectionMegaMenuDraft'>> = ({ params }) => {
  const { currentNode, path } = params;
  const { addStatus, getAllStatus, data, deleteStatus } = useSelector(megaMenuSelector.draftMegaMenu);
  const pageSections = useSelector(pageSectionsSelector);
  const { megaMenusOfHeaderFooter } = useSelector(megaMenusOfHeaderFooterSelector);

  const navigation = useStackNavigator<LeftBarParamList>();
  const { handleChangeForm } = useActionMenu();
  const addMegaMenu = useAddMegaMenuToPage();
  const changeMegaMenu = useChangeMegaMenuSection();
  const installMegaMenu = useAddDraftMegaMenu();
  const addSectionToMegaMenuOfHeaderFooter = useAddSectionToMegaMenuOfHeaderFooter();
  const changeSectionToMegaMenuOfHeaderFooter = useChangeSectionToMegaMenuOfHeaderFooter();

  const _handleAddSection = (commandId: string) => () => {
    if (commandId) {
      installMegaMenu.request({
        commandId,
        onFulFill: dataResponse => {
          if ((currentNode as SettingDragMenuChildren).megaMenuId !== '') {
            const index = pageSections.findIndex(section => section.id === (currentNode as SettingDragMenuChildren).megaMenuId);
            if (index > -1) {
              changeMegaMenu(index, dataResponse);
            } else {
              addMegaMenu(dataResponse);
            }
            const indexInMegaMenusOfHeaderFooter = megaMenusOfHeaderFooter.findIndex(
              section => section.id === (currentNode as SettingDragMenuChildren).megaMenuId,
            );
            changeSectionToMegaMenuOfHeaderFooter({
              index: indexInMegaMenusOfHeaderFooter,
              megaMenu: dataResponse,
            });
          } else {
            addMegaMenu(dataResponse);
            addSectionToMegaMenuOfHeaderFooter({ megaMenu: dataResponse });
          }
          handleChangeForm({ megaMenuId: dataResponse.id } as SettingDragMenuChildren, currentNode, path);
          navigation.goBack();
        },
      });
    }
  };

  return (
    <View css={{ height: '100%' }}>
      <HeaderDrawer
        title={i18n.t('general.back')}
        goBack={() => {
          navigation.goBack();
        }}
      />

      <ScrollBars css={{ height: 'calc(100% - 80px) !important' }}>
        <AsyncComponent
          status={getAllStatus}
          isEmpty={data.length === 0}
          Success={
            <View css={{ padding: '10px' }}>
              {data.map(item => (
                <SectionCard
                  key={item.commandId}
                  title={item.label}
                  loading={addStatus[item.commandId ?? ''] === 'loading' || deleteStatus[item.commandId] === 'loading'}
                  buttonText={i18n.t('general.install')}
                  onClick={_handleAddSection(item.commandId ?? '')}
                  date={
                    item.createdDateTimestamp
                      ? timeConverter(item.createdDateTimestamp)
                      : timeAgo(item.modifiedDateTimestamp ?? 0, getDate(item.modifiedDateTimestamp ?? Date.now()))
                  }
                  image={item.image?.src}
                  css={{ marginBottom: '5px' }}
                />
              ))}
            </View>
          }
        />
      </ScrollBars>
    </View>
  );
};
