import Box from 'components/FieldBox';
import QuickGuideBox2 from 'components/QuickGuideBox/QuickGuideBoxStyle2';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Text, withStyles, ViewProps, View } from 'wiloke-react-core';
import * as styles from './styles';

export interface QuickGuideBoxProps {
  title?: string;
  description?: string;
  isReactRouter?: boolean;
  href?: string;
  src?: string;
  onClick?: () => void;
}

const LinkWithStyle = withStyles<any, any>(Link);

interface QuickBoxStatic {
  Style2: typeof QuickGuideBox2;
}

const QuickGuideBox: FC<QuickGuideBoxProps> & QuickBoxStatic = ({ src, title = '', description = '', isReactRouter = false, href = '', onClick }) => {
  const linkProps: Pick<ViewProps, 'css'> = {
    css: styles.link,
  };

  return (
    <Box css={styles.container} borderWidth={0} onClick={onClick}>
      {!!href ? (
        isReactRouter ? (
          <LinkWithStyle {...linkProps} to={href as any} />
        ) : (
          <Text {...linkProps} tagName="a" target="blank" href={href} />
        )
      ) : null}

      <View css={styles.icon}>
        <img src={src} alt="" />
      </View>

      <View css={{ marginLeft: '10px' }}>
        <Text numberOfLines={2} color="gray9" fontFamily="secondary" css={styles.title}>
          {title}
        </Text>
        <Text
          numberOfLines={2}
          css={{ lineHeight: 'normal' }}
          size={13}
          fontFamily="secondary"
          color="gray6"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </View>
    </Box>
  );
};
QuickGuideBox.Style2 = QuickGuideBox2;
export default QuickGuideBox;
