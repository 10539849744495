import { mergeDeepLeft, sort } from 'ramda';
import { NumberField } from '../../../@types/Shopify/InputSettings/NumberField';
import { Locales } from '../../../@types/Veda/ShopifyLocales';
import { ignoreToReduceTotalLinesOfCode } from '../../../@utils/ignoreToReduceTotalLinesOfCode';
import { replaceExactVariableNameInLiquidCode } from '../../../@utils/replaceExactVariableNameInLiquidCode';
import { sortWithKeyOfFieldValues } from '../../../@utils/sortWithKeyOfFieldValues';
import { positions } from './@consts/positions';
import { ILIQUID_SettingSpace } from './@types/ILIQUID_SettingSpace';
import { ISCHEMA_SettingSpace } from './@types/ISCHEMA_SettingSpace';
import { ISETTING_SettingSpace } from './@types/ISETTING_SettingSpace';
import { getSignalReduceTotalLinesOfCode } from './utils/getSignalReduceTotalLinesOfCode';
import { toShopifyFieldId } from './utils/toShopifyFieldId';
import { vedaLabelsOfSpaceFieldToShopifyFieldLabel } from './utils/vedaLabelsOfSpaceFieldToShopifyFieldLabel';
import { vedaSummariesOfSpaceFieldToShopifyFieldInfo } from './utils/vedaSummariesOfSpaceFieldToShopifyFieldInfo';

interface RTSettingSpace {
  locales: Locales;
  shopifyField: [NumberField, NumberField, NumberField, NumberField];
}

export const SCHEMA_SettingSpace = ({ field, parentField, ...params }: ISCHEMA_SettingSpace): RTSettingSpace => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode ||
    (params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField }));
  let RLocales: Locales = {
    en: {},
    fr: {},
    vi: {},
  };

  const positionsSorted = sort(sortWithKeyOfFieldValues, positions);
  const data = positionsSorted.reduce<NumberField[]>((res, position) => {
    const { newLabelOfPosition, locales: localesOfLabel } = vedaLabelsOfSpaceFieldToShopifyFieldLabel(
      { ...params, field, parentField, isNeedReduceTotalLinesOfCode },
      position,
    );
    const { newInfoOfPosition, locales: localesOfInfo } = vedaSummariesOfSpaceFieldToShopifyFieldInfo({
      ...params,
      field,
      parentField,
      isNeedReduceTotalLinesOfCode,
    });
    RLocales = mergeDeepLeft(RLocales, localesOfLabel);
    RLocales = mergeDeepLeft(RLocales, localesOfInfo);
    const value = field.children[position];
    const valueString = typeof value === 'number' ? value : undefined;
    return res.concat({
      type: 'number',
      default: valueString,
      id: isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, position)
        : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, position),
      placeholder: undefined,
      info: newInfoOfPosition,
      label: newLabelOfPosition,
    });
  }, []);

  return {
    locales: RLocales,
    shopifyField: data as RTSettingSpace['shopifyField'],
  };
};

export const SETTING_SettingSpace = ({ field, parentField, ...params }: ISETTING_SettingSpace) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode ||
    (params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField }));
  return positions.reduce<Record<string, number | undefined>>((res, position) => {
    // check kĩ phòng lỗi
    const value = field.children[position];
    const valueString = typeof value === 'number' ? value : undefined;
    if (valueString !== undefined || isNeedReduceTotalLinesOfCode) {
      const fieldName = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, position)
        : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, position);
      return {
        ...res,
        [fieldName]: valueString,
      };
    }
    return res;
  }, {});
};

export const LIQUID_SettingSpace = ({ field, parentField, liquid, loopVariable, ...params }: ILIQUID_SettingSpace) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode ||
    (params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField }));
  // Nếu field thuộc array
  // - loopVariable nếu là array ->  Array được dùng trong forloop -> Thế `${loopVariable}.${field.name}.${key}` = `${loopVariable}.${toShopifyFieldId({ field, parentField }, key)}`
  //   Example: --> for item in content --> item.icon_design.id ==> KQ: item.content__icon_design.id
  if (loopVariable) {
    return positions.reduce<string>((res, position) => {
      const target = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, position)
        : `${loopVariable}.${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, position)}`;
      const source = `${loopVariable}.${field.name}.${position}`;
      return replaceExactVariableNameInLiquidCode({ liquid: res, source, target });
    }, liquid);
  }
  // Nếu field thuộc object
  // - parentField nếu là object -> Object được flat thành các "shopify input settings" -> Thế `${parentField.name}.${field.name}.${key}` = toShopifyFieldId
  //   Example: --> heading.icon_design.id ==> KQ: heading__icon_design.id
  else if (parentField) {
    return positions.reduce<string>((res, position) => {
      const target = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, position)
        : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, position)}"]`;
      const source = `${parentField.name}.${field.name}.${position}`;
      return replaceExactVariableNameInLiquidCode({ liquid: res, source, target });
    }, liquid);
  } else {
    return positions.reduce<string>((res, position) => {
      const target = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, position)
        : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, position)}"]`;
      const source = `${field.name}.${position}`;
      return replaceExactVariableNameInLiquidCode({ liquid: res, source, target });
    }, liquid);
  }
};
