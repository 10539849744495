import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AdminPageDatabase } from 'services/ResultService/atomTypes';

import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface Params {
  commandIds?: string;
  cacheVersion: number | null;
  size: number;
  themeCommandId?: string;
  target?: 'DEV' | 'ADMIN';
  label?: string;
}

interface GetPagesOfAtom extends Params {
  type: 'Get First';
}

interface LoadMorePagesOfAtom extends Params {
  type: 'Load More';
  cursor: string;
}

/** API được sử dụng để lấy về dữ liệu của page - được clone từ page atom - được sử dụng trong theme atom theo commandId */
export const getTrashPagesOfAtom = async ({
  size,
  type,
  commandIds,
  target,
  themeCommandId,
  cacheVersion,
  label,
  ...params
}: GetPagesOfAtom | LoadMorePagesOfAtom) => {
  let requestConfig: AxiosRequestConfig = {};
  if (type === 'Get First') {
    requestConfig = {
      url: `${baseUrl}/trash/original/search`,
      params: {
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
        size,
        commandIds: commandIds ? commandIds : undefined,
        target: target ? target : undefined,
        themeCommandId: themeCommandId ? themeCommandId : undefined,
        label: label ? label : undefined,
      },
    };
  } else {
    const { cursor } = params as LoadMorePagesOfAtom;
    requestConfig = {
      url: `${baseUrl}/trash/original/search`,
      params: {
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
        cursor,
        size,
        commandIds: commandIds ? commandIds : undefined,
        target: target ? target : undefined,
        themeCommandId: themeCommandId ? themeCommandId : undefined,
        label: label ? label : undefined,
      },
    };
  }
  const response: AxiosResponse<AdminPageDatabase[]> = await fetchAPI.request(requestConfig);
  return response.data;
};
