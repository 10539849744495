import { sectionApiController } from 'services/SectionService';
import { adapterGetManySections } from 'services/SectionService/Adapters/adapterGetManySections';
import { ProductSection } from 'types/Sections';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const getProductSections = async (categoryNames: string[], size?: number, search?: string, planFilter?: string) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const response = await sectionApiController.product.adminApi.sections.getProducts({
      type: 'GET FIRST PAGE',
      category: categoryNames.join(','),
      size,
      cacheVersion: lastCache,
      label: search,
      planFilter,
    });
    return adapterGetManySections(response.info) as ProductSection[];
  }
  const response = await sectionApiController.product.userApi.sections.getProducts({
    type: 'GET FIRST PAGE',
    category: categoryNames.join(','),
    size,
    cacheVersion: lastCache,
    label: search,
    planFilter,
  });
  return adapterGetManySections(response.info) as ProductSection[];
};

export const loadMoreProductSections = async (categoryNames: string[], cursor: string, search?: string, planFilter?: string, limit?: number) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const response = await sectionApiController.product.adminApi.sections.getProducts({
      type: 'LOADMORE',
      category: categoryNames.join(','),
      lastCursor: cursor,
      cacheVersion: lastCache,
      label: search,
      planFilter,
      size: limit,
    });
    return adapterGetManySections(response.info) as ProductSection[];
  }
  const response = await sectionApiController.product.userApi.sections.getProducts({
    type: 'LOADMORE',
    category: categoryNames.join(','),
    lastCursor: cursor,
    cacheVersion: lastCache,
    label: search,
    planFilter,
    size: limit,
  });
  return adapterGetManySections(response.info) as ProductSection[];
};
