import { CssColorVariable } from 'types/PresetStyles';
import { v4 } from 'uuid';

export const PROTON: Array<{ name: string; cssClassName: string; colors: CssColorVariable[] }> = [
  {
    name: 'Proton',
    cssClassName: 'veda-color-proton',
    colors: [
      { id: v4(), name: '--color-primary', light: '#DAA78F', dark: '#DAA78F' },
      { id: v4(), name: '--color-secondary', light: '#76B774', dark: '#76B774' },
      { id: v4(), name: '--color-tertiary', light: '#9682C9', dark: '#9682C9' },
      { id: v4(), name: '--color-quaternary', light: '#DAD7CD', dark: '#DAD7CD' },
      { id: v4(), name: '--color-dark', light: '#10271C', dark: '#ffffff' },
      { id: v4(), name: '--color-gray9', light: '#283D33', dark: '#F3F4F6' },
      { id: v4(), name: '--color-gray8', light: '#405249', dark: '#E6E9EC' },
      { id: v4(), name: '--color-gray7', light: '#586860', dark: '#CED3D9' },
      { id: v4(), name: '--color-gray6', light: '#87938D', dark: '#B5BDC6' },
      { id: v4(), name: '--color-gray5', light: '#9FA9A4', dark: '#9FA9A4' },
      { id: v4(), name: '--color-gray4', light: '#B7BEBB', dark: '#87938D' },
      { id: v4(), name: '--color-gray3', light: '#CFD4D2', dark: '#586860' },
      { id: v4(), name: '--color-gray2', light: '#E7E9E8', dark: '#405249' },
      { id: v4(), name: '--color-gray1', light: '#F3F4F4', dark: '#283D33' },
      { id: v4(), name: '--color-light', light: '#FFFFFF', dark: '#10271C' },
    ],
  },
  {
    name: 'Proton (Dark)',
    cssClassName: 'veda-color-dark-proton',
    colors: [
      { id: v4(), name: '--color-primary', light: '#DAA78F', dark: '#DAA78F' },
      { id: v4(), name: '--color-secondary', light: '#76B774', dark: '#76B774' },
      { id: v4(), name: '--color-tertiary', light: '#9682C9', dark: '#9682C9' },
      { id: v4(), name: '--color-quaternary', light: '#DAD7CD', dark: '#DAD7CD' },
      { id: v4(), name: '--color-dark', light: '#ffffff', dark: '#10271C' },
      { id: v4(), name: '--color-gray9', light: '#F3F4F6', dark: '#283D33' },
      { id: v4(), name: '--color-gray8', light: '#E6E9EC', dark: '#405249' },
      { id: v4(), name: '--color-gray7', light: '#CED3D9', dark: '#586860' },
      { id: v4(), name: '--color-gray6', light: '#B5BDC6', dark: '#87938D' },
      { id: v4(), name: '--color-gray5', light: '#9FA9A4', dark: '#9FA9A4' },
      { id: v4(), name: '--color-gray4', light: '#87938D', dark: '#B7BEBB' },
      { id: v4(), name: '--color-gray3', light: '#586860', dark: '#CFD4D2' },
      { id: v4(), name: '--color-gray2', light: '#405249', dark: '#E7E9E8' },
      { id: v4(), name: '--color-gray1', light: '#283D33', dark: '#F3F4F4' },
      { id: v4(), name: '--color-light', light: '#10271C', dark: '#FFFFFF' },
    ],
  },
];
