import { ActionTypes, createReducer, handleAction } from 'wiloke-react-core/utils';
import { ServerTemplateResponse } from 'services/PagesBuilderService';
import { actionGetTemplatesPopup, checkCreateBlank, setCurrentTemplateBlank, actionLoadMoreTemplatesPopup } from './actions';
import { deleteTemplatePage } from 'containers/Admin/PageBuilder/TemplatesPage/reducers/actions';

type ReducerAction = ActionTypes<
  | typeof actionGetTemplatesPopup
  | typeof setCurrentTemplateBlank
  | typeof checkCreateBlank
  | typeof deleteTemplatePage
  | typeof actionLoadMoreTemplatesPopup
>;

export interface ReducerState {
  getAllStatus: Status;
  data: ServerTemplateResponse[];
  currentTemplate: undefined | ServerTemplateResponse;
  isCreate: boolean;
  deleteStatus: Record<string, Status>;
  loadMoreStatus: Status;
  hasNextPage: boolean;
}

const defaultState: ReducerState = {
  getAllStatus: 'idle',
  data: [],
  currentTemplate: undefined,
  isCreate: false,
  deleteStatus: {},
  loadMoreStatus: 'idle',
  hasNextPage: false,
};

export const reducerTemplatePopup = createReducer<ReducerState, ReducerAction>(defaultState, [
  handleAction('@TemplatesInPopup/getTemplatesRequest', ({ state }) => ({
    ...state,
    getAllStatus: 'loading',
  })),
  handleAction('@TemplatesInPopup/getTemplatesSuccess', ({ state, action }) => ({
    ...state,
    getAllStatus: 'success',
    data: action.payload.data,
    hasNextPage: action.payload.hasNextPage,
  })),
  handleAction('@TemplatesInPopup/getTemplatesFailure', ({ state }) => ({
    ...state,
    getAllStatus: 'failure',
  })),
  handleAction('@TemplatesInPopup/setCurrentTemplateBlank', ({ state, action }) => {
    const { item } = action.payload;
    return {
      ...state,
      currentTemplate: item,
    };
  }),
  handleAction('@TemplatesInPopup/checkCreateBlank', ({ state, action }) => {
    const { isCreate } = action.payload;
    return {
      ...state,
      isCreate,
    };
  }),
  handleAction('@PageBuilder/deleteTemplatePage/request', ({ state, action }) => {
    state.deleteStatus[action.payload.commandId] = 'loading';
  }),
  handleAction('@PageBuilder/deleteTemplatePage/success', ({ state, action }) => {
    state.deleteStatus[action.payload.commandId] = 'success';
    state.data = state.data.filter(item => item.commandId !== action.payload.commandId);
  }),
  handleAction('@PageBuilder/deleteTemplatePage/failure', ({ state, action }) => {
    state.deleteStatus[action.payload.commandId] = 'failure';
  }),
  handleAction('@TemplatesInPopup/loadMoreTemplatesPopupRequest', ({ state }) => ({
    ...state,
    loadMoreStatus: 'loading',
  })),
  handleAction('@TemplatesInPopup/loadMoreTemplatesPopupSuccess', ({ state, action }) => ({
    ...state,
    loadMoreStatus: 'success',
    data: state.data.concat(action.payload.data),
    hasNextPage: action.payload.hasNextPage,
  })),
  handleAction('@TemplatesInPopup/loadMoreTemplatesPopupFailure', ({ state }) => ({
    ...state,
    loadMoreStatus: 'failure',
  })),
]);
