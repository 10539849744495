import { v4 } from 'uuid';
import { defaultLanguage, languages } from '../../../@consts';
import { Locales } from '../../../@types/Veda/ShopifyLocales';
import { ThemeSettings } from '../../@types/ThemeSettings';

const getLayoutTitle = (name: keyof ThemeSettings['layoutSettings']) => {
  if (name === 'columnGapX') {
    return 'Column gap horizontal (px)';
  }
  if (name === 'columnGapY') {
    return 'Column gap vertical (px)';
  }
  if (name === 'containerGap') {
    return 'Container gap (px)';
  }
  if (name === 'containerWidth') {
    return 'Container width (px)';
  }
  return 'Không tồn tại';
};

const localKeyGenByVedaFieldLabel = 'FieldGenByVedaFieldLabel';

const createLocaleKey = () => `_${localKeyGenByVedaFieldLabel}_${v4()}_`;

interface RTVedaColorToShopifyFieldLabel {
  newLabel: string;
  locales: Locales;
}
export const vedaLayoutPropertyToShopifyFieldLabel = (name: keyof ThemeSettings['layoutSettings']): RTVedaColorToShopifyFieldLabel => {
  const localeKey = createLocaleKey();
  const default_translations = { [localeKey]: '' };

  // const newLabel: RTVedaColorToShopifyFieldLabel['newLabel'] = `t:${localeKey}`;
  const locales: RTVedaColorToShopifyFieldLabel['locales'] = languages.reduce<RTVedaColorToShopifyFieldLabel['locales']>(
    (res, language) => {
      return {
        ...res,
        [language]: {
          [localeKey]: getLayoutTitle(name),
        },
      };
    },
    { en: default_translations, vi: default_translations, fr: default_translations },
  );

  // return {
  //   newLabel,
  //   locales,
  // };
  return {
    newLabel: locales[defaultLanguage][localeKey],
    locales,
  };
};
