import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { recommendAppsService } from 'services/RecommednApps/RecommendApps';
import { getActionType } from 'wiloke-react-core/utils';
import { getRecommendApps } from '../actions';

function* handleGet(_: ReturnType<typeof getRecommendApps.request>) {
  try {
    const response: SagaReturnType<typeof recommendAppsService.getRecommendApps> = yield retry(3, 1000, recommendAppsService.getRecommendApps);

    yield put(getRecommendApps.success({ data: response }));
  } catch (error) {
    yield put(getRecommendApps.failure(undefined));
  }
}

export function* watchGetRecommendApps() {
  yield takeLatest(getActionType(getRecommendApps.request), handleGet);
}
