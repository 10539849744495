import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import { Role } from 'routes/types';
import { PLAN_ENUM } from 'utils/constants/PlanEnum';
import fetchAPI from 'utils/functions/fetchAPI';
import { ServerResponseAuthService } from '.';

export interface LoginResponseSuccess {
  id: number;
  email: string;
  role: Role;
  shopName?: string;
  username: string;
  password: string;
  lastCache: number;
}

export class AuthService {
  async getUserInfo() {
    const response: AxiosResponse<ServerResponseAuthService> = await fetchAPI.request({
      url: `${configureApp.endpoint.users}/me`,
    });

    const { id, roles, plan, siteStatus, storePassword } = response.data.info;
    const newResponse = {
      info: {
        ...response.data.info,
        role: roles.map<Role>(item => {
          switch (item.name) {
            case 'ADMIN_GLOBAL': {
              return localStorage.getItem('dev') === 'DEV_BACKDOOR' ? 'dev' : 'admin';
            }
            case 'SUPPER_ADMIN': {
              return 'admin';
            }
            case 'DEV_PRIVATE': {
              return 'dev';
            }
            case 'SUPPORT_GLOBAL': {
              return 'support';
            }
            case 'USER_PRIVATE': {
              return 'user';
            }
            default:
              return 'guest';
          }
        })[0],
        siteStatus: siteStatus ? (Array.isArray(siteStatus) ? siteStatus : [siteStatus].flat()) : [],
        plan: plan
          ? {
              ...plan,
              planHandle: plan.planHandle,
            }
          : {
              chargeId: 0,
              planHandle: PLAN_ENUM.free,
              trialDays: 0,
              userId: id,
              isMonthly: true,
            },
        storefrontPassword: storePassword === null ? '' : storePassword,
      },
      message: response.data.message,
    };

    return newResponse;
  }

  async updateUserInfo({ storePassword, id, themeId, themeName }: { id: number; storePassword?: string; themeId?: string; themeName?: string }) {
    interface ResponseSuccess {
      info: {
        id: number;
        storePassword: string;
      };
      message: string;
    }
    const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
      url: `${configureApp.endpoint.users}/me`,
      method: 'PUT',
      data: {
        id,
        storePassword: !!storePassword && storePassword !== '' ? storePassword : undefined,
        themeId: !!themeId && themeId !== '' ? themeId : undefined,
        themeName: !!themeName && themeName !== '' ? themeName : undefined,
      },
    });

    return response.data;
  }
}
