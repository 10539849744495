import { MyModal, createStackNavigator, useStackNavigator } from '@wiloke/ui';
import { ArticlePickerProps } from 'components/ArticlePicker/types';
import { BlogPickerProps } from 'components/BlogPicker/types';
import { ChooseImageProps } from 'components/ChooseImage';
import { CollectionPickerProps } from 'components/CollectionPicker';
import { IconUIFieldProps } from 'components/IconUIField';
import { MultiProductsPickerProps, SingleProductPickerProps } from 'components/ProductPicker';
import { DragMenuPath, DraggableMenuProps, SettingDragMenu } from 'containers/BuilderPage/components/DraggableMenu/types';
import ArticlePickerFieldScreen from 'containers/BuilderPage/screens/ArticlePickerFieldScreen';
import BlogPickerFieldScreen from 'containers/BuilderPage/screens/BlogPickerFieldScreen';
import ChooseImageFieldScreen from 'containers/BuilderPage/screens/ChooseImageFieldScreen';
import CollectionPickerFieldScreen from 'containers/BuilderPage/screens/CollectionPickerFieldScreen';
import FieldSettingsScreen from 'containers/BuilderPage/screens/FieldSettings/FieldSettingsScreen';
import FieldsScreen from 'containers/BuilderPage/screens/FieldSettings/FieldsScreen';
import IconFieldScreen from 'containers/BuilderPage/screens/IconFieldScreen';
import LeftBarMainScreen from 'containers/BuilderPage/screens/LeftBarMain/LeftBarMainScreen';
import { MegaMenuAtomScreen } from 'containers/BuilderPage/screens/MegaMenuAtomScreen';
import { MegaMenuDraftScreen } from 'containers/BuilderPage/screens/MegaMenuDraftScreen';
import { MegaMenuUserScreen } from 'containers/BuilderPage/screens/MegaMenuUserScreen';
import { MetaFieldScreen } from 'containers/BuilderPage/screens/MetaFieldScreen';
import MultiProductsPickerFieldScreen from 'containers/BuilderPage/screens/MultiProductsPickerFieldScreen';
import NavigationFieldScreen from 'containers/BuilderPage/screens/NavigationFieldScreen';
import SingleProductPickerFieldScreen from 'containers/BuilderPage/screens/SingleProductPickerFieldScreen';
import StyleBoxScreen from 'containers/BuilderPage/screens/StyleBoxScreen';
import { selectPageSelector } from 'containers/BuilderPage/store/selectPage/slice';
import { useSectionDeleteFlow, useSectionDuplicateFlow } from 'containers/BuilderPage/store/toolbarActions/action';
import { useParentPostMessageForLeftBarScreen } from 'hooks/useParentPostMessage';
import { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { pmChildren, pmParent } from 'utils/functions/postMessage';
import { PostMessageOff } from 'wiloke-react-core/utils';
import ModalSectionDuplicate from '../ModalSectionDuplicate/ModalSectionDuplicate';
import { StyleBoxProps } from '../StyleBox/StyleBox';
import { useRemoveAddonsInSection } from 'store/actions/actionPages';
import { pageSectionsSelector } from 'store/selectors';
import { i18n } from 'translation';
import { Space, Text, View } from 'wiloke-react-core';
import Checkbox from 'components/Checkbox';

export interface LeftBarParamList {
  sectionsScreen: undefined;
  fieldSettingsScreen: {
    // Chỉ dành cho trường hợp megamenu
    sectionId: string;
    componentName: string;
  };
  iconFieldScreen: Required<Pick<IconUIFieldProps, 'value' | 'onChange' | 'label'>> & Pick<IconUIFieldProps, 'onImageClick'>;
  fieldsScreen?: {
    componentName: string;
  };
  styleBoxScreen: Required<Omit<StyleBoxProps, 'colors'>> & {
    label: string;
  };
  navigationFieldScreen: Required<Pick<DraggableMenuProps, 'settings' | 'onChange' | 'label' | 'multiLevelEnabled'>>;
  chooseImageFieldScreen: Required<Pick<ChooseImageProps, 'value' | 'onChange'>> & {
    label: string;
  };
  singleProductPickerFieldScreen: Required<Pick<SingleProductPickerProps, 'product' | 'onChange'>> & {
    label: string;
  };
  multiProductsPickerFieldScreen: Required<Pick<MultiProductsPickerProps, 'products' | 'onChange'>> & {
    label: string;
  };
  collectionPickerFieldScreen: Required<Pick<CollectionPickerProps, 'collection' | 'onChange'>> & {
    label: string;
  };
  sectionMegaMenu: {
    currentNode: SettingDragMenu;
    path: DragMenuPath;
    sectionParentId: string;
  };
  sectionMegaMenuDraft: {
    currentNode: SettingDragMenu;
    path: DragMenuPath;
    sectionParentId: string;
  };
  sectionMegaMenuAtom: {
    currentNode: SettingDragMenu;
    path: DragMenuPath;
    sectionParentId: string;
  };
  blogPickerFieldScreen: BlogPickerProps & {
    label: string;
  };
  articlePickerFieldScreen: ArticlePickerProps & {
    label: string;
  };
  metaFieldScreen: {
    label: string;
    onChange: (metaField: string) => void;
  };
}

const StackNavigator = createStackNavigator<LeftBarParamList>({
  duration: 100,
});

const ModalRemoveAddonFromSections: FC<{
  addonSectionId: string;
  visibleProp: boolean;
}> = ({ addonSectionId, visibleProp }) => {
  const pageSections = useSelector(pageSectionsSelector);

  const sectionHasAddons = pageSections.filter(item => (item.addonIds ?? []).includes(addonSectionId)).filter(Boolean);

  const removeAddonsInSection = useRemoveAddonsInSection();

  const [visible, setVisible] = useState(visibleProp);
  const [sectionIds, setSectionIds] = useState<string[]>([]);

  useEffect(() => {
    if (typeof visibleProp !== 'undefined') {
      setVisible(visibleProp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleProp]);

  const handleCancel = () => {
    setVisible(false);
    setSectionIds([]);
    pmChildren.emit('@addon/delete', { sectionId: '' });
  };

  const handleRemoveAddonsInSection = () => {
    if (sectionIds.length > 0) {
      removeAddonsInSection(sectionIds, addonSectionId);
    }
    handleCancel();
  };

  return (
    <MyModal isVisible={visible} onCancel={handleCancel} onOk={handleRemoveAddonsInSection} headerText={i18n.t('general.remove_from_sections')}>
      <Text>{i18n.t('builderPage.remove_addons_in_sections')}</Text>
      <Space size={10} />
      {sectionHasAddons.map(item => (
        <View key={item.id}>
          <Checkbox
            checked={sectionIds.includes(item.id)}
            onValueChange={isSelect => {
              if (isSelect) {
                setSectionIds([...sectionIds, item.id]);
              } else {
                const _removeId = sectionIds.filter(id => id !== item.id);
                setSectionIds(_removeId);
              }
            }}
          >
            {item.label}
          </Checkbox>
          <Space size={10} />
        </View>
      ))}
    </MyModal>
  );
};

const StackNavigatorInit: FC = () => {
  const selectPage = useSelector(selectPageSelector);

  const pmParentOff = useRef<PostMessageOff | undefined>();
  const pmParentOff2 = useRef<PostMessageOff | undefined>();
  const pmParentOff3 = useRef<PostMessageOff | undefined>();

  const navigation = useStackNavigator<LeftBarParamList>();
  const sectionDuplicateFlow = useSectionDuplicateFlow();
  const sectionDeleteFlow = useSectionDeleteFlow();

  useParentPostMessageForLeftBarScreen();

  const [addonSectionId, setAddonSectionId] = useState('');
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Thêm vedaNavigation để có thể navigate từ iframe
    window.vedaNavigation = navigation;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectPage) {
      navigation.navigate('sectionsScreen');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPage]);

  useEffect(() => {
    pmParentOff.current = pmParent.on('@component/duplicate', ({ sectionId }) => {
      sectionDuplicateFlow({
        sectionIdActive: sectionId,
        goBack: () => {
          navigation.navigate('sectionsScreen');
        },
      });
    });
    pmParentOff2.current = pmParent.on('@section/delete', ({ sectionId }) => {
      sectionDeleteFlow({
        sectionIdActive: sectionId,
        goBack: () => {
          navigation.navigate('sectionsScreen');
        },
      });
    });
    pmParentOff3.current = pmParent.on('@addon/delete', ({ sectionId }) => {
      if (sectionId) {
        setAddonSectionId(sectionId);
        setVisible(true);
      } else {
        setAddonSectionId('');
        setVisible(false);
      }
    });

    return () => {
      pmParentOff.current?.();
      pmParentOff2.current?.();
      pmParentOff3.current?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalRemoveAddonFromSections addonSectionId={addonSectionId} visibleProp={visible} />
      <ModalSectionDuplicate />
    </>
  );
};

export const SidebarScreen: FC = () => {
  return (
    <StackNavigator initialName="sectionsScreen" containerCss={{ height: '100vh' }} header={<StackNavigatorInit />}>
      <StackNavigator.Screen name="sectionsScreen" component={LeftBarMainScreen} />
      <StackNavigator.Screen name="fieldSettingsScreen" component={FieldSettingsScreen} />
      <StackNavigator.Screen name="fieldsScreen" component={FieldsScreen} />
      <StackNavigator.Screen name="iconFieldScreen" component={IconFieldScreen} />
      <StackNavigator.Screen name="styleBoxScreen" component={StyleBoxScreen} />
      <StackNavigator.Screen name="navigationFieldScreen" component={NavigationFieldScreen} />
      <StackNavigator.Screen name="chooseImageFieldScreen" component={ChooseImageFieldScreen} />
      <StackNavigator.Screen name="singleProductPickerFieldScreen" component={SingleProductPickerFieldScreen} />
      <StackNavigator.Screen name="multiProductsPickerFieldScreen" component={MultiProductsPickerFieldScreen} />
      <StackNavigator.Screen name="collectionPickerFieldScreen" component={CollectionPickerFieldScreen} />
      <StackNavigator.Screen name="sectionMegaMenu" component={MegaMenuUserScreen} />
      <StackNavigator.Screen name="sectionMegaMenuDraft" component={MegaMenuDraftScreen} />
      <StackNavigator.Screen name="sectionMegaMenuAtom" component={MegaMenuAtomScreen} />
      <StackNavigator.Screen name="blogPickerFieldScreen" component={BlogPickerFieldScreen} />
      <StackNavigator.Screen name="articlePickerFieldScreen" component={ArticlePickerFieldScreen} />
      <StackNavigator.Screen name="metaFieldScreen" component={MetaFieldScreen} />
    </StackNavigator>
  );
};
