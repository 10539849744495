import { RangeField } from '../../../@types/Shopify/InputSettings/RangeField';
import { getShopifyFieldLabel } from './utils/getShopifyFieldLabel';

export const SettingLogoWidth = (): RangeField => {
  return {
    type: 'range',
    id: 'logo_width',
    min: 50,
    max: 300,
    step: 10,
    default: 100,
    unit: 'px',
    label: getShopifyFieldLabel().newLabel,
  };
};
