import ImagePlaceholder from 'components/ImagePlaceholder';
import { LazyImage, Button, Tooltip, Confirm } from '@wiloke/ui';
import { FC } from 'react';
import { i18n } from 'translation';
import { FontAwesome, Text, View } from 'wiloke-react-core';
import { ImageTextCardProps } from '.';
import * as styles from './styles';
import { AdvancedBadge, BusinessBadge, UltimateBadge } from 'components/PlanBadge';

interface ImageTextCard2Props extends Omit<ImageTextCardProps, 'buttonText' | 'backgroundSize'> {
  /** Trạng thái loading của nút add */
  loadingAdd?: boolean;
  /** Trạng thái loading của nút preview */
  loadingPreview?: boolean;
  /** Độ rộng của ảnh */
  widthImage?: number;
  /** Chiều cao của ảnh */
  heightImage?: number;
  /** Mô tả ngắn của card */
  description?: string;
  showCheckIcon?: boolean;
  previewText?: string;
  buttonStyle?: 'style1' | 'style2';
  /** Sự kiện edit */
  onEdit?: () => void;
  /** Sự kiện preview */
  onPreview?: () => void;
  onOpenDetail?: () => void;
}

export const ImageTextCard2: FC<ImageTextCard2Props> = ({
  src,
  loadingAdd = false,
  loadingPreview = false,
  disabled = false,
  saved = false,
  disabledIconSave = false,
  heightImage = 0,
  widthImage = 0,
  label,
  description,
  showCheckIcon = false,
  previewText = 'Buy now',
  buttonStyle = 'style1',
  deleteMessage,
  planHandle,
  onDelete,
  onAdd,
  onSave,
  onPreview,
  onEdit,
  onOpenDetail,
}) => {
  const imageWrapperHeight = 320;

  const _renderImage = (image: string) => {
    if (heightImage > imageWrapperHeight && heightImage > widthImage) {
      return <img src={image} alt="" />;
    }
    return (
      <View css={styles.imageCard2}>
        <LazyImage src={image} widthImage={widthImage} heightImage={heightImage} />
      </View>
    );
  };

  const handleAdd = () => {
    if (disabled) {
      return;
    }
    onAdd?.();
  };

  const handleReview = () => {
    onPreview?.();
  };

  const handleSeeDetail = () => {
    onOpenDetail?.();
  };

  const style1 = (
    <View css={[styles.content2, { flexDirection: 'column' }]} wilokeStyles={!loadingAdd || !loadingPreview ? 'child-fadein-0' : undefined}>
      <Button
        loading={loadingAdd}
        backgroundColor="primary"
        color="light"
        size="extra-small"
        radius={6}
        fontFamily="secondary"
        css={styles.button2(disabled)}
        onClick={handleAdd}
      >
        {i18n.t('general.install')}
      </Button>
      {!!onPreview && (
        <Button
          loading={loadingPreview}
          backgroundColor="secondary"
          color="light"
          size="extra-small"
          radius={6}
          fontFamily="secondary"
          css={{ ...styles.button2(false), marginTop: '5px' }}
          onClick={handleReview}
        >
          {previewText}
        </Button>
      )}
    </View>
  );

  const style2 = (
    <View css={styles.content2} wilokeStyles={!loadingAdd || !loadingPreview ? 'child-fadein-0' : undefined}>
      {!!onPreview && (
        <Tooltip text={i18n.t('general.preview')}>
          <Button
            loading={loadingPreview}
            backgroundColor="gray8"
            color="light"
            size="extra-small"
            radius={6}
            fontFamily="secondary"
            css={{ ...styles.button2(false), height: '38px', width: '38px', lineHeight: '16px' }}
            onClick={handleReview}
          >
            <FontAwesome type="far" name="eye" size={16} />
          </Button>
        </Tooltip>
      )}
      <Button
        loading={loadingAdd}
        backgroundColor="primary"
        color="light"
        size="extra-small"
        radius={6}
        fontFamily="secondary"
        css={styles.button2(disabled)}
        onClick={handleAdd}
      >
        {i18n.t('general.install')}
      </Button>
      {!!onOpenDetail && (
        <Button
          loading={loadingAdd}
          backgroundColor="secondary"
          color="light"
          size="extra-small"
          radius={6}
          fontFamily="secondary"
          css={styles.button2(false)}
          onClick={handleSeeDetail}
        >
          {i18n.t('general.explore')}
        </Button>
      )}
    </View>
  );

  return (
    <View borderColor="gray3" borderStyle="solid" borderWidth={1} css={styles.containerStyle2} radius={6} wilokeStyles="parent-hover">
      <View css={styles.body2}>
        <View css={styles.animateWrapper}>{src ? _renderImage(src) : <ImagePlaceholder height={imageWrapperHeight} />}</View>
        {buttonStyle === 'style1' ? style1 : style2}

        {planHandle === 'business' && (
          <View css={{ position: 'absolute', bottom: '6px', right: '6px', zIndex: 10 }}>
            <BusinessBadge />
          </View>
        )}
        {planHandle === 'advanced' && (
          <View css={{ position: 'absolute', bottom: '6px', right: '6px', zIndex: 10 }}>
            <AdvancedBadge />
          </View>
        )}
        {planHandle === 'ultimate' && (
          <View css={{ position: 'absolute', bottom: '6px', right: '6px', zIndex: 10 }}>
            <UltimateBadge />
          </View>
        )}
      </View>

      <View css={styles.footer}>
        <View css={styles.groupTitle}>
          <View css={{ display: 'flex', columnGap: '10px', alignItems: 'center', marginBottom: '3px' }}>
            <Text
              tagName="h3"
              numberOfLines={1}
              size={15}
              color="gray8"
              fontFamily="secondary"
              css={{ lineHeight: '1.2', textTransform: 'capitalize' }}
            >
              {label}
            </Text>
          </View>

          <Text tagName="p" numberOfLines={1} size={13} color="gray6">
            {description}
          </Text>
        </View>

        <View css={{ ...styles.flexCenter, columnGap: '10px' }}>
          {!!onEdit && (
            <Tooltip
              portal
              onClick={event => {
                event.stopPropagation();
                onEdit?.();
              }}
              text={i18n.t('general.edit')}
              css={styles.imageCardIcon1}
            >
              <FontAwesome type="far" name="cog" color="gray7" size={14} />
            </Tooltip>
          )}

          {disabledIconSave ? null : (
            <Tooltip
              portal
              onClick={event => {
                event.stopPropagation();
                onSave?.();
              }}
              text={i18n.t(saved ? 'general.saved' : 'general.save')}
              css={styles.imageCardIcon1}
            >
              <FontAwesome type={saved ? 'fas' : 'far'} name="bookmark" color={saved ? 'tertiary' : 'gray7'} size={14} />
            </Tooltip>
          )}

          {showCheckIcon && (
            <Tooltip
              portal
              onClick={event => {
                event.stopPropagation();
                onSave?.();
              }}
              text={`You've already installed the theme`}
              css={styles.imageCardIcon1}
            >
              <FontAwesome type={'fas'} name="check-circle" color={'success'} size={16} />
            </Tooltip>
          )}

          {!!onDelete && (
            <Confirm
              isLoading={loadingAdd}
              title={`${i18n.t('general.delete', { text: i18n.t('general.confirm') })}`}
              message={deleteMessage ? deleteMessage : i18n.t('general.delete_confirm_message', { text: label })}
              onOk={onClose => {
                onDelete?.();
                onClose();
              }}
            >
              <Tooltip portal text={i18n.t('general.delete')} css={{ ...styles.imageCardIcon1, marginLeft: '10px' }}>
                <FontAwesome type={'far'} name="trash" color={'danger'} size={14} />
              </Tooltip>
            </Confirm>
          )}
        </View>
      </View>
    </View>
  );
};
