import { getCacheValue, setCacheValue, getUniqId } from '../../../CacheShopifyFieldId';
import { ISCHEMA_SettingColorScheme } from '../@types/ISCHEMA_SettingColorScheme';
import { ISETTING_SettingColorScheme } from '../@types/ISETTING_SettingColorScheme';

export const toShopifyFieldId = ({ field, parentField, section }: ISCHEMA_SettingColorScheme | ISETTING_SettingColorScheme) => {
  const theoryShopifyFieldId = parentField ? `${parentField.name}___${field.name}` : field.name;
  if (theoryShopifyFieldId.length >= 25) {
    const valueInCache = getCacheValue({ theoryShopifyFieldId, section });
    if (valueInCache) {
      return valueInCache;
    } else {
      const newKey = `VEDA_color_scheme_${getUniqId('color_scheme')}`;
      setCacheValue({ theoryShopifyFieldId, section }, newKey);
      return newKey;
    }
  }
  return theoryShopifyFieldId;
};
