export function removeEmptyKey<T extends Record<string, any>>(obj: T) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  for (const key in obj) {
    if (key === '') {
      delete obj[key];
    } else {
      obj[key] = removeEmptyKey(obj[key]);
    }
  }

  return obj;
}
