import { getJs } from 'generate/utils/generateHelpers';
import { File } from 'types/Result';
import { PageSection } from 'types/Sections';

interface GetJsOfAddon {
  addonSection: PageSection;
}
export const getJsOfAddon = ({ addonSection }: GetJsOfAddon): File => {
  const id = addonSection.id;

  return {
    type: 'js của 1 addon',
    id,
    name: addonSection.label,
    content: getJs(id, addonSection.data.js ?? ''),
    section: undefined,
  };
};
