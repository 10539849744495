import { AxiosResponse } from 'axios';
import { PartnerAPI } from 'types/Partner';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface CreateParams extends Pick<PartnerAPI, 'id'> {}

interface ResSuccess {
  message: string;
}

export const deletePartner = async (data: CreateParams) => {
  const response: AxiosResponse<ResSuccess> = await fetchAPI.request({
    url: `${baseUrl}/${data.id}`,
    method: 'DELETE',
  });
  return response.data;
};
