import { notification } from 'antd';
import { ModalReportAfterError } from 'containers/ModalReportAfterError/ModalReportAfterError';
import { MODAL_REPORT_AFTER_IMPORT_THEME_ERROR } from 'containers/ModalReportAfterError/const';
import { ModalUpdateShopifyThemeId } from 'containers/ModalUpdateShopifyThemeId/ModalUpdateShopifyThemeId';
import { useEffect, useState } from 'react';
import { put, select, take } from 'redux-saga/effects';
import {
  setEventIdOfImportThemeAtomToClientServiceNPageInThemeClient,
  setStreamSocketOfImportThemeAtomToClientServiceNPageInThemeClient,
  useResetAllState,
  useSetEventIdOfImportThemeAtomToClientServiceNPageInThemeClient,
  useSetStreamSocketOfImportThemeAtomToClientServiceNPageInThemeClient,
} from 'store/global/socket/importThemeAtomToClientService/actions';
import { socketOfImportThemeAtomClientServiceSelector } from 'store/selectors';
import { i18n } from 'translation';
import getPageInfo from 'utils/functions/getInfo';
import { v4 } from 'uuid';
import { getActionType } from 'wiloke-react-core/utils';
import { FirstConnectionMessage, OnConnect, OnDisconnect } from './types';
import { getUserInfo } from 'utils/functions/getUserInfo';
import configureApp from 'configureApp';
import fetchAPI from 'utils/functions/fetchAPI';
import { AxiosResponse } from 'axios';
// import { ModalSocketStuck } from 'containers/ModalSocketStuck/ModalSocketStuck';

export interface ImportThemeAtomToClientServiceNPageInThemeClientMessage {
  firstConnection: false;
  // Khi bắn về Socket, a có bổ sung errorCode. Nếu code Status là 404 thì e hiện popup yêu cầu update theme Id nhé. Cái này áp dụng cho mọi api sync.
  errorCode?: 'SyncThemeNotFound';
  eventType: 'Import theme atom -> client service ngoài dashboard' | 'Import page của theme';
  eventId: string;
  log?: string;
  message: string;
  step: 'PROCESSING' | 'SUCCESS' | 'END';
  status: 'success' | 'error';
  themeCommandId: null | string;
}

let eventSource: EventSource | null = null;
let eventIdInteracting: string = v4();
let eventSourceListenerMessageEvent: ((event: MessageEvent) => void) | null = null;
let eventSourceListenerSuccessEvent: ((event: Event) => void) | null = null;
let eventSourceListenerErrorEvent: ((event: Event) => void) | null = null;
const closeSocket = async () => {
  if (eventSource) {
    if (eventSourceListenerMessageEvent) {
      eventSource.removeEventListener('message', eventSourceListenerMessageEvent);
      eventSourceListenerMessageEvent = null;
    }
    if (eventSourceListenerSuccessEvent) {
      eventSource.removeEventListener('open', eventSourceListenerSuccessEvent);
      eventSourceListenerSuccessEvent = null;
    }
    if (eventSourceListenerErrorEvent) {
      eventSource.removeEventListener('error', eventSourceListenerErrorEvent);
      eventSourceListenerErrorEvent = null;
    }
    eventSource?.close();
    eventSource = null;
  }
  try {
    const response: AxiosResponse<{ eventId?: string }> = await fetchAPI.request({
      url: `${configureApp.clientBaseUrl}/me/sync/tracking`,
      method: 'DELETE',
      params: {
        eventId: eventIdInteracting ? eventIdInteracting : undefined,
      },
    });
    eventIdInteracting = response.data.eventId ?? 'Cần override bằng v4';
  } finally {
    if (eventIdInteracting === 'Cần override bằng v4') {
      eventIdInteracting = v4();
    }
  }
};

const createSocket = async () => {
  const { id } = getUserInfo();
  await closeSocket();
  eventSource = new EventSource(configureApp.eventSourceForClientEndpoint + `/sync/tracking?userId=${id}&eventId=${eventIdInteracting}`);
};

export const useSocketForImportThemeAtomToClientServiceNPageInThemeClient = () => {
  const [statusSocketConnection, setStatusSocketConnection] = useState<Status>(eventSource?.OPEN ? 'success' : 'idle');

  const resetAllState = useResetAllState();
  const setEventIdOfImportThemeAtomToClientServiceNPageInThemeClient = useSetEventIdOfImportThemeAtomToClientServiceNPageInThemeClient();
  const setStreamSocketOfImportThemeAtomToClientServiceNPageInThemeClient = useSetStreamSocketOfImportThemeAtomToClientServiceNPageInThemeClient();

  const handleDisconnect = async ({ cb }: OnDisconnect, isConnectAction: boolean) => {
    await closeSocket();
    if (!isConnectAction) {
      setStatusSocketConnection('idle');
    }
    cb?.();
  };

  const handleMessageSocketOfImportThemeAtomToClientServiceNPageInThemeClient = (data: ImportThemeAtomToClientServiceNPageInThemeClientMessage) => {
    if (data.errorCode === 'SyncThemeNotFound') {
      ModalUpdateShopifyThemeId.open({
        type: getPageInfo('themeId') ? 'Theme' : 'Session',
        isErrorShopifyThemeRemoved: true,
        themeCommandId: getPageInfo('themeId'),
      });
    }
    if (data.step.toUpperCase() === 'END' && data.status.toUpperCase() === 'ERROR') {
      setStreamSocketOfImportThemeAtomToClientServiceNPageInThemeClient({ socketData: data, status: 'failure', eventType: data.eventType });
      notification.error({
        message: i18n.t('import_theme_atom_to_client_service.import_error'),
        description: data.message,
      });
      if (data.eventType === 'Import page của theme') {
        notification.error({ message: data.message });
      } else {
        ModalReportAfterError.getActions(MODAL_REPORT_AFTER_IMPORT_THEME_ERROR).report({
          cause: i18n.t('ModalReportAfterError.error_cause.connect_socket'),
          description: [data.log || '', data.message].join('. '),
        });
      }
    }
    if (data.step.toUpperCase() === 'END' && data.status.toUpperCase() === 'SUCCESS') {
      setStreamSocketOfImportThemeAtomToClientServiceNPageInThemeClient({ socketData: data, status: 'success', eventType: data.eventType });
      notification.success({
        message: i18n.t('import_theme_atom_to_client_service.import_success'),
        description: data.message,
      });
    }
    if (data.step.toUpperCase() === 'PROCESSING' && data.status.toUpperCase() === 'ERROR') {
      setStreamSocketOfImportThemeAtomToClientServiceNPageInThemeClient({ socketData: data, status: 'failure', eventType: data.eventType });
      notification.error({
        message: i18n.t('import_theme_atom_to_client_service.import_error'),
        description: data.message,
      });
    }
    if (data.step.toUpperCase() === 'PROCESSING' && data.status.toUpperCase() !== 'ERROR') {
      setStreamSocketOfImportThemeAtomToClientServiceNPageInThemeClient({ socketData: data, status: 'loading', eventType: data.eventType });
      notification.info({
        message: i18n.t('import_theme_atom_to_client_service.importing'),
        description: data.message,
      });
    }
  };

  const handleError = async (isConnectAction: boolean, onError: OnConnect['onError']) => {
    await handleDisconnect({}, isConnectAction);
    onError?.();
    setStatusSocketConnection('failure');
  };

  const handleStartCountdown = () => {
    // ModalSocketStuck.getActions('Import theme').start();
    // window.clearTimeout(countdownRef.current);
    // countdownRef.current = window.setTimeout(() => {
    //   handleDisconnect({ cb: () => {} }, false);
    // }, 90000);
  };

  const handleSuccess = async (onSuccess: OnConnect['onSuccess'], onError: OnConnect['onError']) => {
    try {
      resetAllState();
      setEventIdOfImportThemeAtomToClientServiceNPageInThemeClient(eventIdInteracting);
      handleStartCountdown();
      setStatusSocketConnection('success');
      eventSourceListenerMessageEvent = event => {
        const data: ImportThemeAtomToClientServiceNPageInThemeClientMessage | FirstConnectionMessage = JSON.parse(event.data);
        console.log('useSocketForImportThemeAtomToClientServiceNPageInThemeClient.ts', { data, eventIdInteracting });
        if (data && 'firstConnection' in data && data.firstConnection === false && 'eventId' in data && eventIdInteracting === data.eventId) {
          console.log('Event accepted: ', { data, eventIdInteracting });
          handleMessageSocketOfImportThemeAtomToClientServiceNPageInThemeClient(data as ImportThemeAtomToClientServiceNPageInThemeClientMessage);
        }
      };
      eventSource?.addEventListener('message', eventSourceListenerMessageEvent);
      onSuccess(eventIdInteracting);
    } catch {
      await handleError?.(true, onError);
    }
  };

  const handleConnect_ = async ({ onSuccess, onError }: OnConnect) => {
    // Nếu page "iframe" và "preview" hoặc socket trước đó đang connect và thành công ngay khi gọi function này thì không connect socket
    if (['/iframe', '/preview'].includes(window.location.pathname)) {
      setStatusSocketConnection('success');
      return;
    }
    setStatusSocketConnection('loading');
    try {
      await createSocket();
      eventSourceListenerSuccessEvent = () => {
        handleSuccess(onSuccess, onError);
        setStatusSocketConnection('success');
      };
      eventSourceListenerErrorEvent = async event => {
        if ('readyState' in event && event.readyState !== EventSource.CLOSED) {
          await handleError(false, onError);
          setStatusSocketConnection('failure');
        }
      };
      eventSource?.addEventListener('error', eventSourceListenerErrorEvent);
      eventSource?.addEventListener('open', eventSourceListenerSuccessEvent);
    } catch {
      await handleError(false, onError);
      setStatusSocketConnection('failure');
    }
  };

  const handleConnect = async (callbacks: OnConnect) => {
    setStatusSocketConnection('loading');
    if (statusSocketConnection === 'success') {
      await handleDisconnect({ cb: async () => await handleConnect_(callbacks) }, true);
    } else {
      await handleConnect_(callbacks);
    }
  };

  useEffect(() => {
    return () => {
      if (statusSocketConnection === 'success') {
        handleDisconnect({}, false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    statusSocketConnection,
    connect: handleConnect,
    disconnect: async (callbacks: OnDisconnect) => {
      await handleDisconnect(callbacks, false);
      return;
    },
  };
};

const fulfillStatus: SyncFulfillStatus[] = ['success', 'failure'];

interface RTHandleWaitForSocketOfImportThemeAtomToClientServiceNPageInThemeClientFulfill {
  statusImport: SyncFulfillStatus;
  themeCommandId: string | null | undefined;
  isLostConnection: boolean;
  isNeedIgnoreReportError: boolean;
}
/** Saga ĐỢI vf lắng nghe socket import theme atom hoàn thành */
export function* handleWaitForSocketOfImportThemeAtomToClientServiceNPageInThemeClientFulfill(
  eventType: ImportThemeAtomToClientServiceNPageInThemeClientMessage['eventType'],
) {
  const { streams, eventId }: ReturnType<typeof socketOfImportThemeAtomClientServiceSelector> = yield select(
    socketOfImportThemeAtomClientServiceSelector,
  );
  if (fulfillStatus.includes(streams[eventType].status as SyncFulfillStatus)) {
    yield put(setStreamSocketOfImportThemeAtomToClientServiceNPageInThemeClient({ socketData: undefined, status: 'idle', eventType }));
    return {
      statusImport: streams[eventType].status,
      themeCommandId: streams[eventType].socketData?.themeCommandId,
      isLostConnection: false,
      isNeedIgnoreReportError: streams[eventType]?.socketData?.errorCode === 'SyncThemeNotFound',
    } as RTHandleWaitForSocketOfImportThemeAtomToClientServiceNPageInThemeClientFulfill;
  }
  if (!eventId) {
    return {
      statusImport: 'failure',
      themeCommandId: undefined,
      isLostConnection: true,
      isNeedIgnoreReportError: streams[eventType]?.socketData?.errorCode === 'SyncThemeNotFound',
    } as RTHandleWaitForSocketOfImportThemeAtomToClientServiceNPageInThemeClientFulfill;
  }
  while (true) {
    yield take([
      getActionType(setStreamSocketOfImportThemeAtomToClientServiceNPageInThemeClient),
      getActionType(setEventIdOfImportThemeAtomToClientServiceNPageInThemeClient),
    ]);
    const { streams, eventId }: ReturnType<typeof socketOfImportThemeAtomClientServiceSelector> = yield select(
      socketOfImportThemeAtomClientServiceSelector,
    );
    if (fulfillStatus.includes(streams[eventType].status as SyncFulfillStatus)) {
      yield put(setStreamSocketOfImportThemeAtomToClientServiceNPageInThemeClient({ socketData: undefined, status: 'idle', eventType }));
      return {
        statusImport: streams[eventType].status,
        themeCommandId: streams[eventType].socketData?.themeCommandId,
        isLostConnection: false,
        isNeedIgnoreReportError: streams[eventType]?.socketData?.errorCode === 'SyncThemeNotFound',
      } as RTHandleWaitForSocketOfImportThemeAtomToClientServiceNPageInThemeClientFulfill;
    }
    if (!eventId) {
      return {
        statusImport: 'failure',
        themeCommandId: undefined,
        isLostConnection: true,
        isNeedIgnoreReportError: streams[eventType]?.socketData?.errorCode === 'SyncThemeNotFound',
      } as RTHandleWaitForSocketOfImportThemeAtomToClientServiceNPageInThemeClientFulfill;
    }
  }
}
