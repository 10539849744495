import { normalizationProduct, ProductItem } from 'services/LiquidVariables/utils/Product';
import { PREFIX_PRODUCT_HANDLE, PREFIX_PRODUCT_TITLE } from './@const';
import { randomNumber, randomImage } from './@utils';

const randomTitle = () => `${PREFIX_PRODUCT_TITLE} ${randomNumber()}`;
const randomHandle = () => `${PREFIX_PRODUCT_HANDLE}-${randomNumber()}`;

export const GET_PRODUCT_ITEM_PLACEHOLDER = (handle = randomHandle()): ProductItem => ({
  id: '7917450723549',
  gift_card: true,
  price: '25.0',
  compare_at_price: 50,
  title: randomTitle(), // 'Nova Soap Dispenser',
  url: '/products/nova-soap-dispenser',
  vendor: 'Zone Denmark',
  handle, // 'nova-soap-dispenser',
  available: true,
  content:
    '<p>The Nova bathroom series creates a feeling of luxury and spa-wellness with its irresistible and delightful soft-touch surface with steel-look details and soft style. The Danish designer, Thomas Dudzinski, has succeeded in creating a series with a sculptural and elegant expression. Nova will quickly become a natural part of your bathroom and the rest of your home. This soap dispenser is made of porcelain and plastic in a modern shape that will add a fresh touch to the bathroom. Available in different colours.</p>\n<p>Zone Denmark makes a statement that leaves you in no doubt. They interpret evolving trends, using our strong and clear DNA to create functional design for everyone. Expressed in a minimalistic and honest design language, their designs embrace new ideas, innovative solutions and exquisite materials. Their purpose is to challenge convention, inspire curiosity and create beauty.</p>\n<p>Dimensions:</p>\n<ul>\n<li>H: 11.5 cm - o: 8 cm</li>\n<li>Volume: 25cl</li>\n</ul>\n<p>This is a demo store. This product can be bought on <a href="https://cphagen.com/" target="_blank" rel="noopener noreferrer">CPHAGEN eshop</a>.</p>',
  description:
    '<p>The Nova bathroom series creates a feeling of luxury and spa-wellness with its irresistible and delightful soft-touch surface with steel-look details and soft style. The Danish designer, Thomas Dudzinski, has succeeded in creating a series with a sculptural and elegant expression. Nova will quickly become a natural part of your bathroom and the rest of your home. This soap dispenser is made of porcelain and plastic in a modern shape that will add a fresh touch to the bathroom. Available in different colours.</p>\n<p>Zone Denmark makes a statement that leaves you in no doubt. They interpret evolving trends, using our strong and clear DNA to create functional design for everyone. Expressed in a minimalistic and honest design language, their designs embrace new ideas, innovative solutions and exquisite materials. Their purpose is to challenge convention, inspire curiosity and create beauty.</p>\n<p>Dimensions:</p>\n<ul>\n<li>H: 11.5 cm - o: 8 cm</li>\n<li>Volume: 25cl</li>\n</ul>\n<p>This is a demo store. This product can be bought on <a href="https://cphagen.com/" target="_blank" rel="noopener noreferrer">CPHAGEN eshop</a>.</p>',
  images: [
    {
      aspect_ratio: 1,
      id: '38328372625629',
      media_type: 'image',
      src: randomImage(),
      width: 800,
      height: 800,
      url: randomImage(),
      attached_to_variant: false,
    },
    {
      aspect_ratio: 1,
      id: '38328372658397',
      media_type: 'image',
      src: randomImage(),
      width: 800,
      height: 800,
      url: randomImage(),
      attached_to_variant: false,
    },
    {
      aspect_ratio: 1,
      id: '38328372691165',
      media_type: 'image',
      src: randomImage(),
      width: 800,
      height: 800,
      url: randomImage(),
      attached_to_variant: false,
    },
    {
      aspect_ratio: 1,
      id: '38328372723933',
      media_type: 'image',
      src: randomImage(),
      width: 800,
      height: 800,
      url: randomImage(),
      attached_to_variant: false,
    },
    {
      aspect_ratio: 1,
      id: '38328372756701',
      media_type: 'image',
      src: randomImage(),
      width: 800,
      height: 800,
      url: randomImage(),
      attached_to_variant: false,
    },
  ],
  featured_image: {
    aspect_ratio: 1,
    id: '38328372625629',
    media_type: 'image',
    src: randomImage(),
    width: 800,
    height: 800,
    url: randomImage(),
    attached_to_variant: false,
  },
  media: [
    {
      alt: '',
      id: '30918642106589',
      position: 'IMAGE',
      preview_image: {
        aspect_ratio: 1,
        id: '30918642106589',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        alt: '',
        attached_to_variant: false,
      },
    },
    {
      alt: '',
      id: '30918642139357',
      position: 'IMAGE',
      preview_image: {
        aspect_ratio: 1,
        id: '30918642139357',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        alt: '',
        attached_to_variant: false,
      },
    },
    {
      alt: '',
      id: '30918642172125',
      position: 'IMAGE',
      preview_image: {
        aspect_ratio: 1,
        id: '30918642172125',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        alt: '',
        attached_to_variant: false,
      },
    },
    {
      alt: '',
      id: '30918642204893',
      position: 'IMAGE',
      preview_image: {
        aspect_ratio: 1,
        id: '30918642204893',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        alt: '',
        attached_to_variant: false,
      },
    },
    {
      alt: '',
      id: '30918642237661',
      position: 'IMAGE',
      preview_image: {
        aspect_ratio: 1,
        id: '30918642237661',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        alt: '',
        attached_to_variant: false,
      },
    },
  ],
  options: ['Color'],
  price_max: '25.0',
  price_min: '25.0',
  price_varies: false,
  published_at: '2022-11-04T09:27:36Z',
  type: 'Living',
  tags: [
    '__label1:New',
    '__stock:50',
    'Bathroom',
    'Color_Beige',
    'Color_Black',
    'Color_Dark Grey',
    'Color_Green',
    'Color_Grey',
    'Color_Light Blue',
    'Color_Rose',
    'Color_Silver',
    'Color_White',
    'Price_$0 to $100',
  ],
  variants: [
    {
      image: {
        aspect_ratio: 1,
        id: '38328372625629',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        attached_to_variant: false,
      },
      id: '43491810574557',
      inventory_management: 'NOT_MANAGED',
      options: ['Green'],
      price: 25,
      requires_shipping: true,
      sku: 'ZO-0045299-DENM-GRE',
      title: 'Green',
      weight: 0,
      available: true,
      inventory_quantity: 1,
      featured_image: {
        aspect_ratio: 1,
        id: '38328372625629',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        attached_to_variant: false,
        variant_ids: ['43491810574557'],
      },
    },
    {
      image: {
        aspect_ratio: 1,
        id: '38328372658397',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        attached_to_variant: false,
      },
      id: '43491810607325',
      inventory_management: 'NOT_MANAGED',
      options: ['Black'],
      price: 25,
      requires_shipping: true,
      sku: 'ZO-0047389-DENM-BLA',
      title: 'Black',
      weight: 0,
      available: true,
      inventory_quantity: 1,
      featured_image: {
        aspect_ratio: 1,
        id: '38328372658397',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        attached_to_variant: false,
        variant_ids: ['43491810607325'],
      },
    },
    {
      image: {
        aspect_ratio: 1,
        id: '38328372691165',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        attached_to_variant: false,
      },
      id: '43491810640093',
      inventory_management: 'NOT_MANAGED',
      options: ['Rose'],
      price: 25,
      requires_shipping: true,
      sku: 'ZO-0056559-DENM-ROS',
      title: 'Rose',
      weight: 0,
      available: true,
      inventory_quantity: 1,
      featured_image: {
        aspect_ratio: 1,
        id: '38328372691165',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        attached_to_variant: false,
        variant_ids: ['43491810640093'],
      },
    },
    {
      image: {
        aspect_ratio: 1,
        id: '38328372723933',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        attached_to_variant: false,
      },
      id: '43491810672861',
      inventory_management: 'NOT_MANAGED',
      options: ['Grey'],
      price: 25,
      requires_shipping: true,
      sku: 'ZO-0031303-DENM-GRE',
      title: 'Grey',
      weight: 0,
      available: true,
      inventory_quantity: 1,
      featured_image: {
        aspect_ratio: 1,
        id: '38328372723933',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        attached_to_variant: false,
        variant_ids: ['43491810672861'],
      },
    },
    {
      image: {
        aspect_ratio: 1,
        id: '38328372756701',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        attached_to_variant: false,
      },
      id: '43491810705629',
      inventory_management: 'NOT_MANAGED',
      options: ['White'],
      price: 25,
      requires_shipping: true,
      sku: 'ZO-0093049-DENM-WHI',
      title: 'White',
      weight: 0,
      available: true,
      inventory_quantity: 1,
      featured_image: {
        aspect_ratio: 1,
        id: '38328372756701',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        attached_to_variant: false,
        variant_ids: ['43491810705629'],
      },
    },
    {
      image: {
        aspect_ratio: 1,
        id: '38328372789469',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        attached_to_variant: false,
      },
      id: '43491810738397',
      inventory_management: 'NOT_MANAGED',
      options: ['Misty Green'],
      price: 25,
      requires_shipping: true,
      sku: 'ZO-0039391-DENM-DUS',
      title: 'Misty Green',
      weight: 0,
      available: true,
      inventory_quantity: 1,
      featured_image: {
        aspect_ratio: 1,
        id: '38328372789469',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        attached_to_variant: false,
        variant_ids: ['43491810738397'],
      },
    },
    {
      image: {
        aspect_ratio: 1,
        id: '38328372822237',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        attached_to_variant: false,
      },
      id: '43491810771165',
      inventory_management: 'NOT_MANAGED',
      options: ['Beige'],
      price: 25,
      requires_shipping: true,
      sku: 'ZO-004800-DENM-CRE',
      title: 'Beige',
      weight: 0,
      available: true,
      inventory_quantity: 1,
      featured_image: {
        aspect_ratio: 1,
        id: '38328372822237',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        attached_to_variant: false,
        variant_ids: ['43491810771165'],
      },
    },
    {
      image: {
        aspect_ratio: 1,
        id: '38328372855005',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        attached_to_variant: false,
      },
      id: '43491810803933',
      inventory_management: 'NOT_MANAGED',
      options: ['Dark Blue'],
      price: 25,
      requires_shipping: true,
      sku: 'ZO-005466-DENM-BLU',
      title: 'Dark Blue',
      weight: 0,
      available: true,
      inventory_quantity: 1,
      featured_image: {
        aspect_ratio: 1,
        id: '38328372855005',
        media_type: 'image',
        src: randomImage(),
        width: 800,
        height: 800,
        url: randomImage(),
        attached_to_variant: false,
        variant_ids: ['43491810803933'],
      },
    },
  ],
  options_with_values: [
    {
      name: 'Color',
      position: 1,
      values: ['Green', 'Black', 'Rose', 'Grey', 'White', 'Misty Green', 'Beige', 'Dark Blue'],
      selected_value: 'Green',
    },
  ],
  first_available_variant: {
    image: {
      aspect_ratio: 1,
      id: '38328372625629',
      media_type: 'image',
      src: randomImage(),
      width: 800,
      height: 800,
      url: randomImage(),
      attached_to_variant: false,
      variant_ids: ['43491810574557'],
    },
    id: '43491810574557',
    inventory_management: 'NOT_MANAGED',
    options: ['Green'],
    price: 25,
    requires_shipping: true,
    sku: 'ZO-0045299-DENM-GRE',
    title: 'Green',
    weight: 0,
    available: true,
    inventory_quantity: 1,
    featured_image: {
      aspect_ratio: 1,
      id: '38328372625629',
      media_type: 'image',
      src: randomImage(),
      width: 800,
      height: 800,
      url: randomImage(),
      attached_to_variant: false,
      variant_ids: ['43491810574557'],
    },
  },
  featured_media: {
    alt: '',
    id: '30918642106589',
    position: 'IMAGE',
    preview_image: {
      aspect_ratio: 1,
      id: '30918642106589',
      media_type: 'image',
      src: randomImage(),
      width: 800,
      height: 800,
      url: randomImage(),
      alt: '',
      attached_to_variant: false,
    },
  },
  selected_or_first_available_variant: {
    image: {
      aspect_ratio: 1,
      id: '38328372625629',
      media_type: 'image',
      src: randomImage(),
      width: 800,
      height: 800,
      url: randomImage(),
      attached_to_variant: false,
      variant_ids: ['43491810574557'],
    },
    id: '43491810574557',
    inventory_management: 'NOT_MANAGED',
    options: ['Green'],
    price: 25,
    requires_shipping: true,
    sku: 'ZO-0045299-DENM-GRE',
    title: 'Green',
    weight: 0,
    available: true,
    inventory_quantity: 1,
    featured_image: {
      aspect_ratio: 1,
      id: '38328372625629',
      media_type: 'image',
      src: randomImage(),
      width: 800,
      height: 800,
      url: randomImage(),
      attached_to_variant: false,
      variant_ids: ['43491810574557'],
    },
  },
});

// TODO: Có hay không việc migrate cái này sang BE
export const GET_PRODUCT_PLACEHOLDER = () => normalizationProduct(GET_PRODUCT_ITEM_PLACEHOLDER());
