import { Consts } from 'utils/constants/constants';

export const builderPage = {
  back_to_dashboard2: 'Retour au tableau de bord',
  page_under_maintaince: 'Page en maintenance',
  page_error: 'Une erreur est survenue',
  get_support: "Obtenir de l'aide",
  modal_login_shopify: {
    okText: 'Je suis connecté',
    description: "N'oubliez pas de vous connecter à votre boutique Shopify pour pouvoir prévisualiser",
  },
  shopify_entity_non_exist: {
    title: 'Changements dans votre boutique',
    collection: "La collection n'existe pas ou a été modifiée. Veuillez sélectionner à nouveau avant de continuer",
    product: `Le produit n\'existe pas ou a été modifié. Veuillez sélectionner à nouveau avant de continuer`,
    blog: "Le blog n'existe pas ou a été modifié. Veuillez sélectionner à nouveau avant de continuer",
  },
  fields: {
    reorder: `Ce mode de classement ne modifie que les paramètres ci-dessous et n'affecte pas le contenu de la page.`,
  },
  schema: {
    settings: {
      title: 'Paramètres',
      text: '',
    },
    blocks: {
      title: 'Blocs',
      text: '',
    },
    error: {
      existed: '"%% name %%" est déjà défini dans le schéma',
      is_shopify_variable: '"%% name %%" est une variable Shopify',
    },
  },
  theme_settings: {
    general: {
      title: 'Général',
      text: 'Paramètres généraux pour le thème actuel',
    },
    colors: {
      title: 'Couleurs',
      text: 'Gérer les variations de couleurs',
      content: {
        title: 'Couleurs personnalisées pour le thème',
        text: 'Vous pouvez modifier ou ajouter des couleurs ci-dessous',
      },
    },
    color_schemes: {
      title: 'Schémas de couleurs',
      text: 'Gérer les schémas de couleurs',
      content: {
        title: 'Créez vos propres schémas de couleurs',
        text: 'Exprimez votre créativité et concevez des schémas de couleurs uniques pour votre thème.',
      },
    },
    fonts: {
      title: 'Polices',
      text: 'Gérer toutes les variations de polices',
      content: {
        title: 'Polices personnalisées',
        text: 'Vous pouvez modifier ou ajouter des polices ci-dessous',
      },
    },
    layout: {
      title: 'Disposition',
      text: 'Gérer la mise en page du site web',
    },
    vendors: {
      title: 'Fournisseurs',
      text: 'Ajouter du CSS et du JavaScript externes au thème',
    },
    scss: {
      title: 'SCSS (CSS)',
      text: 'Ajouter du SCSS au thème',
    },
    js: {
      title: 'Javascript',
      text: 'Ajouter du JavaScript au thème',
    },
    content: {
      colors: {
        title: 'Couleurs personnalisées',
        text:
          'Vous pouvez modifier ou ajouter des couleurs ci-dessous (Notez que le nom de la variable est utilisé lors de la modification du code CSS)',
      },
      fonts: {
        title: 'Polices personnalisées',
        text:
          'Vous pouvez modifier ou ajouter des polices ci-dessous (Notez que le nom de la variable est utilisé lors de la modification du code CSS)',
      },
    },
  },
  page_settings: {
    general: {
      title: 'Paramètres généraux',
      text: 'Paramètres généraux pour la page actuelle',
    },
    vendors: {
      title: 'Fournisseurs',
      text: 'Ajouter du CSS et du JavaScript externes',
    },
    scss: {
      title: 'SCSS (CSS)',
      text: 'Ajouter du SCSS à la page',
    },
    js: {
      title: 'Javascript',
      text: 'Ajouter du JavaScript à la page',
    },
    import: {
      title: 'Importer un fichier',
      text: `Importer un fichier *.${Consts.AppName} pour continuer à modifier la page actuelle`,
      select_options: 'Sélectionner les options à importer',
      error: 'Votre fichier est corrompu',
      upload_successfully: 'Téléchargement réussi',
      upload_failed: 'Échec du téléchargement',
      options: {
        page_data: 'Données de la page',
        page_settings: 'Paramètres de la page',
        theme_settings: 'Paramètres du thème',
      },
    },
    general_settings: {
      meta_title_placeholder: 'Le titre meta sera affiché ici',
      meta_description_placeholder: 'La description meta sera affichée ici',
      general: {
        title: 'Général',
        text: 'Paramètres généraux pour la page actuelle',
        name: 'Nom de la page',
        enable_header_footer: `Activer l'en-tête et le pied de page du thème`,
      },
      seo: {
        title: 'SEO',
        text: 'Remplissez les informations nécessaires pour le référencement dans les moteurs de recherche',
        url_slug: {
          title: `Slug d'URL`,
          text: 'Lorem ipsum dolor sit amet',
        },
        meta_title: {
          title: 'Meta titre',
          text: 'Lorem ipsum dolor sit amet',
        },
        meta_description: {
          title: 'Meta description',
          text: 'Lorem ipsum dolor sit amet',
        },
      },
      social_share: {
        title: 'Partage social',
        text: 'Lorem ipsum dolor sit amet',
      },
    },
  },
  layout_settings: {
    container_width: {
      title: 'Largeur du conteneur',
      text: 'Définir la largeur par défaut du contenu (Par défaut : %% size %%px)',
    },
    container_gap: {
      title: 'Espacement du conteneur',
      text: `Définir l'espacement par défaut des colonnes (Par défaut : %% size %%px)`,
    },
    column_gap_vertical: {
      title: 'Espacement vertical des colonnes',
      text: `Définir l'espacement par défaut entre les éléments (Par défaut : %% size %%px)`,
    },
    column_gap_horizontal: {
      title: 'Espacement horizontal des colonnes',
      text: `Définir l'espacement par défaut entre les éléments (Par défaut : %% size %%px)`,
    },
  },
  theme_general_settings: {
    preloader: {
      title: 'Préchargeur',
      text: '',
      testing: `S'exécute après 2 secondes après avoir cliqué sur le bouton Ok.`,
    },
    favicon: {
      title: 'Favicon',
      text: '',
    },
  },
  mega_menu: {
    choose_setting: 'Choisir les paramètres',
    warning: 'Vous ne pouvez pas ajouter de sous-éléments à cet élément lorsque le méga menu est activé',
    icon: 'Icône %% text %%',
    hot_spot: 'Zone chaude',
    navigation: 'Navigation',
  },
  save_for_builder: {
    preview_image: 'Image de prévisualisation',
    page_types: 'Types de page',
    add_category: 'Ajouter une catégorie',
    please_enter_item: 'Veuillez entrer un élément',
    can_add_multi: 'Peut ajouter plusieurs',
    enable_position: 'Activer la position',
    addons_type: `Type d'addon`,
    logo: 'Logo',
    detail: 'Détail',
    save_page: 'Enregistrer la page',
    create_new_page: 'Créer une nouvelle page',
    save_theme: 'Enregistrer le thème',
    create_new_theme: 'Créer un nouveau thème',
    link_preview: 'Aperçu du lien',
    create_update_section_page_type: `Les sections ajoutées dépendent du type de page. Par exemple, lors de la création d'une page d'article, certaines sections spécifiques aux produits/collections ne seront pas affichées.`,
  },
  choose_template: {
    delete_section: 'Êtes-vous sûr de vouloir supprimer cette section ?',
    import_section_failed: 'Les données de ce fichier ne sont pas une section valide',
    cannot_use_section: "Impossible d'utiliser cette section dans ce type de page - type de page actuel : %% type %%",
    import_addons_failed: 'Les données de ce fichier ne sont pas un addon valide',
    import_section_addons_failed: 'Les données de ce fichier ne sont pas une section daddon valide',
  },
  delete_addons_to_keep: "Cette section contient les addons suivants. Vérifiez si vous souhaitez les supprimer de l'onglet addons.",
  duplicate_addons_to_keep: 'Cette section contient les addons suivants. Cochez la case pour les conserver.',
  back_to_dashboard: 'Cette page contient des modifications non enregistrées. Si vous quittez maintenant, ces modifications seront perdues.',
  remove_addons_in_sections:
    'Ces addons sont utilisés dans ces sections. Cochez-les si vous souhaitez supprimer les addons utilisés dans cette section.',
  extracting: 'Extraction en cours',
  saving: 'Enregistrement en cours',
  publish_override: 'Publier et écraser',
  publish_not_override: 'Publier sans écraser',
  publish_ask_text:
    'Vos données seront mises à jour sur Shopify. Ces données auront un impact sur les autres pages de type %% pageType %%. Voulez-vous toujours les écraser ?',
  save_and_publish: 'Enregistrer et publier',
  add_more_language: 'Ajouter plus de langues',
  select_language: 'Sélectionnez la langue que vous souhaitez modifier',
  change_file_name: 'Changer le nom du fichier',
  translation_file: 'Fichier de traduction',
  must_have_section: 'Votre modèle manque de sections principales, veuillez ajouter au moins 1 section principale',
  save_as_draft: 'Enregistrer en tant que brouillon',
  save_to_my_page: 'Enregistrer sur ma page',
  use: 'Utiliser',
  lazy_load_image: 'Chargement différé des images',
  coming_soon: 'Bientôt disponible',
  in: 'dans',
  out: 'hors',
  only_have_one: 'Ajouter seulement 1',
  cannot_duplicate: 'Impossible de dupliquer',
  override_content: 'Ces données auront un impact sur les autres pages de type %% pageType %%. Voulez-vous les écraser ?',
  override: 'Écraser',
  not_override: 'Ne pas écraser',
  confirm_override: "Confirmer l'écrasement",
  network_error: 'Erreur réseau',
  retry: 'Réessayer',
  compose_email: "Cliquez sur l'icône pour composer un e-mail",
  select_url: "Cliquez sur l'icône pour sélectionner une URL",
  send_email: 'Envoyer un e-mail',
  phone_number: 'Numéro de téléphone',
  custom_url: 'URL personnalisée',
  search: 'Rechercher %% text %%',
  link_shopify: 'Lier Shopify',
  js_hook_state:
    '// Ce JS est destiné aux modèles de section mais ne fonctionne que dans le builder\n// Vous pouvez utiliser les fonctions : hiddenFieldAction, redirectTo',
  built_in: 'Intégré',
  third_party: 'Tiers',
  atom: 'Atom',
  draft: 'Brouillon',
  loaded: 'chargé',
  preset: 'Préréglage %% text %%',
  exit_without_saving: 'Quitter sans enregistrer',
  save_for: 'Enregistrer pour %% text %%',
  builder: 'builder',
  dev: 'dev',
  must_create_changelog: 'Vous devez créer un journal des modifications en premier',
  increase_version_to_update_section: 'Augmenter la version pour mettre à jour la section/addon',
  update_version: {
    deleted_old_fields: `%% text %% sera supprimé dans la nouvelle version`,
    added_new_fields: 'De nouveaux %% text %% seront ajoutés dans la nouvelle version',
    updated_fields: '%% text %% seront mis à jour dans la nouvelle version',
  },
  data_request: {
    loading: 'Chargement des données en cours',
    success: 'Récupération des données réussie',
    failed: 'Échec de la récupération des données',
  },
  data_not_selected: "%% text %% n'est pas sélectionné",
  move_to: {
    previous: 'Aller à la position précédente',
    next: 'Aller à la position suivante',
    header: "Aller à l'en-tête",
    footer: 'Aller au pied de page',
    main: 'Aller au contenu principal',
    disable: 'Impossible de se déplacer',
  },
  delete_atom: 'La suppression de ce %% text %% qui a déjà été publié signifie également la suppression dans le produit',
  select_pages_to_sync: 'Sélectionnez les pages à synchroniser avec Shopify, les autres pages seront toujours enregistrées',
  delete_from: 'Supprimer de %% text %%',
  uninstall: 'désinstaller',
  upgrade_plan: 'Veuillez effectuer une mise à niveau vers le plan %% function_plan %% pour utiliser cette fonctionnalité.',
  scroll_to_section: `Défilez jusqu'à la section`,
  buy_now: 'Buy now',
  buy_now_condition: 'Only works in Product page',
  click_to_add_main: 'Cliquez ici pour ajouter la section principale',
} as const;
