import { AsyncComponent, ScrollBars, VedaLoadingItem } from '@wiloke/ui';
import NotEnoughWidth from 'components/NotEnoughWidth';
import { SideBar } from 'containers/Dashboard/components/SideBar';
import { UserSwitchButton } from 'containers/Dashboard/components/UserSwitchButton';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import { globalMountSelector } from 'store/selectors';
import { View } from 'wiloke-react-core';

import AnnouncementBar from 'containers/Dashboard/components/AnnouncementBar';
import { PageType } from 'types/Page';
import * as styles from './styles';

export interface DashboardProps {
  disabledPaddingContent?: boolean;
  hasSubmenu?: boolean;
  disabledScroll?: boolean;
  scrollTo?: number;
  onScroll?: ({ scrollHeight, scrollTop, clientHeight }: { scrollHeight: number; scrollTop: number; clientHeight: number }) => void;
  Content: () => ReactNode;
  Modals?: () => ReactNode;
  pageType?: PageType;
}

export const Dashboard = ({
  Content,
  Modals,
  disabledPaddingContent = false,
  hasSubmenu = true,
  disabledScroll = false,
  scrollTo,
  onScroll,
}: DashboardProps) => {
  const { status } = useSelector(globalMountSelector);

  const { width } = useWindowSize();
  const DashboardContent = (
    <>
      <AnnouncementBar />

      <View css={{ padding: disabledPaddingContent ? '0px' : '30px', height: '100%' }}>{Content()}</View>
    </>
  );

  const content = (
    <View css={styles.container}>
      {width < 1100 ? <NotEnoughWidth /> : null}

      <UserSwitchButton />

      {/* Sidebar */}
      <View css={styles.sidebar(hasSubmenu)}>
        <SideBar />
      </View>

      {/* Dashboard Body */}
      <View className="template-body" backgroundColor="gray1" css={styles.content(hasSubmenu, disabledScroll)}>
        {disabledScroll ? (
          DashboardContent
        ) : (
          <ScrollBars
            scrollTo={scrollTo}
            onScrollFrame={value => {
              const { scrollHeight, scrollTop, clientHeight } = value;
              onScroll?.({ scrollHeight, scrollTop, clientHeight });
            }}
          >
            {DashboardContent}
          </ScrollBars>
        )}
      </View>

      {Modals?.()}
    </View>
  );

  return (
    <AsyncComponent
      status={status}
      Request={
        <View
          css={{
            position: 'fixed',
            inset: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <VedaLoadingItem />
        </View>
      }
      Success={content}
    />
  );
};
