import { watchGetPurchaseCodes, watchDeletePurchaseCode, watchDeleteWebsiteOfPurchaseCode, watchCreateWebsiteOfPurchaseCode } from './sagas';

export * from './PurchaseCodePage';

export const sagasPurchaseCodesPage = [
  watchGetPurchaseCodes,
  watchDeletePurchaseCode,
  watchDeleteWebsiteOfPurchaseCode,
  watchCreateWebsiteOfPurchaseCode,
];
