import { notification } from 'antd';
import { ModalReportAfterError } from 'containers/ModalReportAfterError/ModalReportAfterError';
import { MODAL_REPORT_AFTER_SYNC_ERROR } from 'containers/ModalReportAfterError/const';
import { ModalSocketStuckProps } from 'containers/ModalSocketStuck/ModalSocketStuck';
import { ModalUpdateShopifyThemeId } from 'containers/ModalUpdateShopifyThemeId/ModalUpdateShopifyThemeId';
import { useEffect, useState } from 'react';
import { put, select, take } from 'redux-saga/effects';
import { decodeIdOfLiquidFile } from 'services/ShopifyConnection/utils';
import {
  setEventIdOfSyncShopifyNClonePageAtomServiceToThemeAtomService,
  setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService,
  useResetAllState,
  useSetEventIdOfSyncShopifyNClonePageAtomServiceToThemeAtomService,
  useSetStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService,
} from 'store/global/socket/syncShopifyNClonePageAtomServiceToThemeAtomService/actions';
import { syncPageNotification as syncPageNotification2 } from 'store/global/socket/syncShopifyNClonePageAtomServiceToThemeAtomService/watchPreviewWithShopify';
import { syncPageNotification } from 'store/global/socket/syncShopifyNClonePageAtomServiceToThemeAtomService/watchSyncToShopify';
import { socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector } from 'store/selectors';
import { i18n } from 'translation';
import getPageInfo from 'utils/functions/getInfo';
import { v4 } from 'uuid';
import { getActionType } from 'wiloke-react-core/utils';
import { FirstConnectionMessage, OnConnect, OnDisconnect } from './types';
import fetchAPI from 'utils/functions/fetchAPI';
import configureApp from 'configureApp';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { AxiosResponse } from 'axios';

export interface SyncShopifyMessage {
  firstConnection: false;
  // Khi bắn về Socket, a có bổ sung errorCode. Nếu code Status là 404 thì e hiện popup yêu cầu update theme Id nhé. Cái này áp dụng cho mọi api sync.
  errorCode?: 'SyncThemeNotFound';
  pageCommandId: string;
  message: string;
  log?: string;
  id?: string;
  step: 'PROCESSING' | 'SUCCESS' | 'END';
  status: 'SUCCESS' | 'ERROR';
  previewUrls?: string[] | null;
  eventId: string;
  /** Đặt như thế này để dễ kiểm soát (vì mỗi chỗ là 1 api khác nhau) */
  eventType:
    | 'Update shopifyPages ngoài dashboard/Từ "all" -> 1 vài => Unpublish tất cả'
    | 'Update shopifyPages ngoài dashboard'
    | 'Ghi file khi update page settings ngoài dashboard'
    | 'Ghi file khi update theme settings ngoài dashboard'
    | 'Ghi file khi save ở builder page / Ghi page'
    | 'Ghi file khi save ở builder page / Ghi global (sinh ra từ themeSettings)'
    | 'Ghi file khi save ở builder page / Ghi header'
    | 'Ghi file khi save ở builder page / Ghi footer'
    | 'Ghi file khi save ở builder page / Ghi addon enable position'
    | 'Ghi file khi save ở builder page / Ghi addon disable position'
    | 'Ghi file khi save ở builder page / Ghi các addon disable position vừa tạo xong vào file theme.liquid'
    | 'Publish | Unpublish page ngoài dashboard'
    | 'Delete ouput builder ngoài dashboard'
    | 'Ghi file atomic css khi save ở builder page'
    | 'Ghi file raw atomic css khi save ở builder page'
    | 'Delete addon khi save ở builder'
    | 'Sync translation'
    | 'Ghi file atomic css khi publish theme'
    | 'Xóa toàn bộ dữ liệu veda'
    | 'Restore lại layout của theme / xoá header và footer của veda'
    | 'Publish header và footer của veda';
}

export interface MigrateThemeMessage {
  firstConnection: false;
  // Khi bắn về Socket, a có bổ sung errorCode. Nếu code Status là 404 thì e hiện popup yêu cầu update theme Id nhé. Cái này áp dụng cho mọi api sync.
  errorCode?: number;
  eventType: 'Migrate theme';
  eventId: string;
  log?: string;
  oldThemeId: string;
  newThemeId: string;
  message: string;
  step: 'PROCESSING' | 'SUCCESS' | 'END';
  status: 'SUCCESS' | 'ERROR';
}

export interface ClonePageAtomServiceToThemeAtomService {
  firstConnection: false;
  // Khi bắn về Socket, a có bổ sung errorCode. Nếu code Status là 404 thì e hiện popup yêu cầu update theme Id nhé. Cái này áp dụng cho mọi api sync.
  errorCode?: number;
  eventType: 'Clone các thành phần từ page atom service sang theme atom service';
  eventId: string;
  id: string;
  pageCommandId?: string;
  sectionCommandIds?: string[];
  step: 'PROCESSING' | 'SUCCESS' | 'END';
  status: 'SUCCESS' | 'ERROR';
  message: string;
}

export type SocketMessage = SyncShopifyMessage | MigrateThemeMessage | ClonePageAtomServiceToThemeAtomService;

let eventSource: EventSource | null = null;
let eventIdInteracting: string = v4();
let eventSourceListenerMessageEvent: ((event: MessageEvent) => void) | null = null;
let eventSourceListenerSuccessEvent: ((event: Event) => void) | null = null;
let eventSourceListenerErrorEvent: ((event: Event) => void) | null = null;

export type Variant = 'sync_shopify' | 'migrate_theme' | 'clone_page_atom_sevice_to_theme_atom_service';

const closeSocket = async (variant: Variant) => {
  const urls: Record<Variant, string> = {
    sync_shopify: `${configureApp.baseUrl}/${configureApp.version}` + '/' + configureApp.endpoint['shopify-connections'] + `/sync/tracking`,
    clone_page_atom_sevice_to_theme_atom_service: configureApp.eventSourceForClonePageAtomServiceToThemeAtomService + '/me/sync/tracking',
    migrate_theme: '',
  };
  if (eventSource) {
    if (eventSourceListenerMessageEvent) {
      eventSource.removeEventListener('message', eventSourceListenerMessageEvent);
      eventSourceListenerMessageEvent = null;
    }
    if (eventSourceListenerSuccessEvent) {
      eventSource.removeEventListener('open', eventSourceListenerSuccessEvent);
      eventSourceListenerSuccessEvent = null;
    }
    if (eventSourceListenerErrorEvent) {
      eventSource.removeEventListener('error', eventSourceListenerErrorEvent);
      eventSourceListenerErrorEvent = null;
    }
    eventSource?.close();
    eventSource = null;
  }
  try {
    const response: AxiosResponse<{ eventId?: string }> = await fetchAPI.request({
      url: urls[variant],
      baseURL: '',
      method: 'DELETE',
      params: {
        eventId: eventIdInteracting,
      },
    });
    eventIdInteracting = response.data.eventId ?? 'Cần override bằng v4';
  } finally {
    if (eventIdInteracting === 'Cần override bằng v4') {
      eventIdInteracting = v4();
    }
  }
};

const createSocket = async (variant: Variant) => {
  const { shopName, id } = getUserInfo();
  // xoá socket cũ đã tồn tại
  await closeSocket(variant);
  const urls: Record<Variant, string> = {
    sync_shopify: configureApp.eventSourceForSyncShopify + `/sync/tracking?shopName=${shopName}&eventId=${eventIdInteracting}`,
    clone_page_atom_sevice_to_theme_atom_service:
      configureApp.eventSourceForClonePageAtomServiceToThemeAtomService + `/sync/tracking?userId=${id}&eventId=${eventIdInteracting}`,
    migrate_theme: '',
  };
  eventSource = new EventSource(urls[variant]);
};

export const useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService = (variant: Variant) => {
  const [statusSocketConnection, setStatusSocketConnection] = useState<Status>(eventSource?.OPEN ? 'success' : 'idle');

  // const countdownRef = useRef<number | undefined>(undefined);

  const resetAllState = useResetAllState();
  const setEventIdOfSyncShopifyNClonePageAtomServiceToThemeAtomService = useSetEventIdOfSyncShopifyNClonePageAtomServiceToThemeAtomService();
  const setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService = useSetStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService();

  const handleEndCountdown = () => {
    // ModalSocketStuck.getActions('Sync shopify').clear();
    // ModalSocketStuck.getActions('Migrate theme on shopify').clear();
    // ModalSocketStuck.getActions('Clone các thành phần từ page atom service sang theme atom service').clear();
    // setEventIdOfSyncShopifyNClonePageAtomServiceToThemeAtomService(undefined);
    // window.clearTimeout(countdownRef.current);
  };

  const handleDisconnect = async ({ cb }: OnDisconnect, isConnectAction: boolean) => {
    handleEndCountdown();
    syncPageNotification.done();
    syncPageNotification2.done();
    await closeSocket(variant);
    if (!isConnectAction) {
      setStatusSocketConnection('idle');
    }
    cb?.();
  };

  const handleMessageSocketOfSyncShopify = (data: SyncShopifyMessage) => {
    if (data.errorCode === 'SyncThemeNotFound') {
      ModalUpdateShopifyThemeId.open({
        type: getPageInfo('themeId') ? 'Theme' : 'Session',
        isErrorShopifyThemeRemoved: true,
        themeCommandId: getPageInfo('themeId'),
        error: data.message,
      });
    }
    if (data.step.toUpperCase() === 'END' && data.status.toUpperCase() === 'ERROR') {
      setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService({ socketData: data, status: 'failure', eventType: data.eventType });
      notification.error({
        message: i18n.t('publish_shopify.sync_error'),
        description: data.message,
      });
      if (data.errorCode !== 'SyncThemeNotFound') {
        ModalReportAfterError.getActions(MODAL_REPORT_AFTER_SYNC_ERROR).report({
          cause: i18n.t('ModalReportAfterError.error_cause.sync_result_to_shopify'),
          description: [data.log || '', data.message].join('. '),
        });
      }
    }
    if (data.step.toUpperCase() === 'END' && data.status.toUpperCase() === 'SUCCESS') {
      setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService({ socketData: data, status: 'success', eventType: data.eventType });
    }
    if (data.step.toUpperCase() === 'PROCESSING' && data.status.toUpperCase() === 'ERROR') {
      setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService({ socketData: data, status: 'failure', eventType: data.eventType });
      notification.error({
        message: i18n.t('publish_shopify.sync_error'),
        description: data.message,
      });
    }
    if (data.step.toUpperCase() === 'PROCESSING' && data.status.toUpperCase() !== 'ERROR') {
      setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService({ socketData: data, status: 'loading', eventType: data.eventType });
      notification.info({
        message: i18n.t('publish_shopify.syncing'),
        description: data.message,
      });
    }
  };

  const handleMessageSocketOfMigrateTheme = (data: MigrateThemeMessage) => {
    if (data.step.toUpperCase() === 'END' && data.status.toUpperCase() === 'ERROR') {
      setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService({ socketData: data, status: 'failure', eventType: data.eventType });
      notification.error({
        message: i18n.t('migrate_theme.migrate_error'),
        description: data.message,
      });
      if (data.errorCode !== 404) {
        ModalReportAfterError.getActions(MODAL_REPORT_AFTER_SYNC_ERROR).report({
          cause: i18n.t('ModalReportAfterError.error_cause.migrate_theme'),
          description: [data.log || '', data.message].join('. '),
        });
      }
    }
    if (data.step.toUpperCase() === 'END' && data.status.toUpperCase() === 'SUCCESS') {
      setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService({ socketData: data, status: 'success', eventType: data.eventType });
    }
    if (data.step.toUpperCase() === 'PROCESSING' && data.status.toUpperCase() === 'ERROR') {
      setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService({ socketData: data, status: 'failure', eventType: data.eventType });
      notification.error({
        message: i18n.t('migrate_theme.migrate_error'),
        description: data.message,
      });
    }
    if (data.step.toUpperCase() === 'PROCESSING' && data.status.toUpperCase() !== 'ERROR') {
      setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService({ socketData: data, status: 'loading', eventType: data.eventType });
      notification.info({
        message: i18n.t('migrate_theme.migrating'),
        description: data.message,
      });
    }
  };

  const handleMessageSocketOfClonePageAtomServiceToThemeAtomService = (data: ClonePageAtomServiceToThemeAtomService) => {
    if (data.step.toUpperCase() === 'END' && data.status.toUpperCase() === 'ERROR') {
      setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService({ socketData: data, status: 'failure', eventType: data.eventType });
      notification.error({
        message: i18n.t('clone_page_atom_service_to_theme_atom_service.clone_error'),
        description: data.message,
      });
      if (data.errorCode !== 404) {
        ModalReportAfterError.getActions(MODAL_REPORT_AFTER_SYNC_ERROR).report({
          cause: i18n.t('ModalReportAfterError.error_cause.clone_page_atom_service_to_theme_atom_service'),
          description: data.message,
        });
      }
    }
    if (data.step.toUpperCase() === 'END' && data.status.toUpperCase() === 'SUCCESS') {
      setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService({ socketData: data, status: 'success', eventType: data.eventType });
    }
    if (data.step.toUpperCase() === 'PROCESSING' && data.status.toUpperCase() === 'ERROR') {
      setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService({ socketData: data, status: 'failure', eventType: data.eventType });
      notification.error({
        message: i18n.t('clone_page_atom_service_to_theme_atom_service.clone_error'),
        description: data.message,
      });
    }
    if (data.step.toUpperCase() === 'PROCESSING' && data.status.toUpperCase() !== 'ERROR') {
      setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService({ socketData: data, status: 'loading', eventType: data.eventType });
      notification.info({
        message: i18n.t('clone_page_atom_service_to_theme_atom_service.cloning'),
        description: data.message,
      });
    }
  };

  const handleError = async (isConnectAction: boolean, onError: OnConnect['onError']) => {
    await handleDisconnect({}, isConnectAction);
    onError?.();
    setStatusSocketConnection('failure');
  };

  const handleStartCountdown = (_modalSocketStuckType: ModalSocketStuckProps['type']) => {
    // ModalSocketStuck.getActions(modalSocketStuckType).start();
    // window.clearTimeout(countdownRef.current);
    // countdownRef.current = window.setTimeout(() => {
    //   handleDisconnect({ cb: () => {} }, false);
    // }, 90000);
  };

  const handleSuccess = async (onSuccess: OnConnect['onSuccess'], onError: OnConnect['onError']) => {
    try {
      handleStartCountdown('Sync shopify');
      handleStartCountdown('Migrate theme on shopify');
      handleStartCountdown('Clone các thành phần từ page atom service sang theme atom service');
      setStatusSocketConnection('success');
      resetAllState();
      setEventIdOfSyncShopifyNClonePageAtomServiceToThemeAtomService(eventIdInteracting);
      eventSourceListenerMessageEvent = event => {
        const data: SyncShopifyMessage | MigrateThemeMessage | ClonePageAtomServiceToThemeAtomService | FirstConnectionMessage = JSON.parse(
          event.data,
        );
        console.log('useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService.ts', { data, eventIdInteracting });
        if (data && 'firstConnection' in data && data.firstConnection === false && 'eventId' in data && eventIdInteracting === data.eventId) {
          console.log('Event accepted: ', { data, eventIdInteracting });
          if (variant === 'sync_shopify') {
            handleMessageSocketOfSyncShopify(data as SyncShopifyMessage);
          } else if (variant === 'migrate_theme') {
            handleMessageSocketOfMigrateTheme(data as MigrateThemeMessage);
          } else if (variant === 'clone_page_atom_sevice_to_theme_atom_service') {
            handleMessageSocketOfClonePageAtomServiceToThemeAtomService(data as ClonePageAtomServiceToThemeAtomService);
          }
        }
      };
      eventSource?.addEventListener('message', eventSourceListenerMessageEvent);
      onSuccess?.(eventIdInteracting);
    } catch {
      await handleError(true, onError);
    }
  };

  const handleConnect_ = async ({ onSuccess, onError }: OnConnect) => {
    // Nếu page "iframe" và "preview" hoặc socket trước đó đang connect và thành công ngay khi gọi function này thì không connect socket
    if (['/iframe', '/preview'].includes(window.location.pathname)) {
      setStatusSocketConnection('success');
      return;
    }
    setStatusSocketConnection('loading');
    try {
      await createSocket(variant);
      eventSourceListenerSuccessEvent = () => {
        handleSuccess(onSuccess, onError);
        setStatusSocketConnection('success');
      };
      eventSourceListenerErrorEvent = async event => {
        if ('readyState' in event && event.readyState !== EventSource.CLOSED) {
          await handleError(false, onError);
          setStatusSocketConnection('failure');
        }
      };
      eventSource?.addEventListener('error', eventSourceListenerErrorEvent);
      eventSource?.addEventListener('open', eventSourceListenerSuccessEvent);
    } catch {
      await handleError(false, onError);
      setStatusSocketConnection('failure');
    }
  };

  const handleConnect = async (callbacks: OnConnect) => {
    setStatusSocketConnection('loading');
    if (statusSocketConnection === 'success') {
      handleDisconnect(
        {
          cb: async () => await handleConnect_(callbacks),
        },
        true,
      );
    } else {
      await handleConnect_(callbacks);
    }
  };

  useEffect(() => {
    return () => {
      if (statusSocketConnection === 'success') {
        handleDisconnect({}, false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    statusSocketConnection,
    connect: handleConnect,
    disconnect: async (callbacks: OnDisconnect) => {
      await handleDisconnect(callbacks, false);
    },
  };
};

const fulfillStatus: SyncFulfillStatus[] = ['success', 'failure'];

export interface RTHandleWaitForSocketOfSyncShopifyFulfill {
  statusSync: SyncFulfillStatus;
  sectionCommandId: undefined;
  isLostConnection: boolean;
  isNeedIgnoreReportError: boolean;
}
/** Saga ĐỢI và lắng nghe socket sync shopify hoàn thành */
export function* handleWaitForSocketOfSyncShopifyFulfill(eventType: SocketMessage['eventType']) {
  const { streams, eventId }: ReturnType<typeof socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector> = yield select(
    socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector,
  );
  const socketData = streams[eventType].socketData;
  /** NOTE: @tuong -> Trong file cũng có đoạn check tương tự -> Nếu thay đổi phải xem xét việc update cả những cái bên dưới */
  /** NOTE: @tuong -> function có sự liên quan đến "watchSyncToShopify.ts" -> Nếu thay đổi phải xem xét file đó */
  if (fulfillStatus.includes(streams[eventType].status as SyncFulfillStatus)) {
    /**
     * NOTE: Thay vì làm 1 flow để reset cờ trạng thái của socket thì cứ thành công hoặc thất bại sẽ reset luôn
     * Câu hỏi 1: Tại sao làm như vậy
     * Trả lời: Tại thời điểm comment này được viết mọi chuyện xảy ra như sau
      - Các api sync shopify đều sử dụng socket
      - Sync từng phần để đảm bảo rate limit và giảm thiểu rủi ro (sync thiếu cái A thì chết app, ...)
      - Tại từng chặng sync sẽ phải check trạng thái sync trước đó phải "SUCCESS" hoặc "FAILURE" thì mới được tiếp tục chặng tiếp theo
      - 1 "flow saga" để chịu trách nhiệm reset cờ trạng thái của các chặng khi chặng đó đã hoàn thành (tức "SUCCESS" hoặc "FAILURE")
      - Trước đó mọi thứ đều OK. Nhưng tại thời điểm comment này được viết nó xảy ra lỗi. Ngữ cảnh như sau:
        + Thực hiện xoá 1 page bất kì -> action đổi cờ trạng thái chặng sync "SUCCESS" hoặc "FAILURE" và action reset cờ trạng thái cùa chặng sync xảy ra gần như là đồng thời (chưa đến 1ms) -> Bằng 1 cách nào đó function này không được thực thi khi cờ trạng thái sync chuyển "SUCCESS" hoặc "FAILURE" mà chỉ nhận được cờ trạng thái "SẴN SÀNG" -> các api sync bị treo do chặng sync trước đó không thông báo là đã "SUCCESS" hoặc "FAILURE" -> LỖI CỰC KÌ NGHIỆM TRỌNG
        + @tuong đề nghị một cách sửa như sau:
          -> Bỏ '1 "flow saga" để chịu trách nhiệm reset cờ trạng thái của các chặng khi chặng đó đã hoàn thành (tức "SUCCESS" hoặc "FAILURE")'
          -> Thay vào đó đổi cờ trạng thái chặng sync ngay tại function này -> Điều này làm cho việc cờ trạng thái chặng sync chỉ phụ thuộc vào function này -> Mọi thứ đồng bộ -> Sẽ là OK
     */
    yield put(setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService({ socketData: undefined, status: 'idle', eventType }));
    return {
      statusSync: streams[eventType].status,
      sectionCommandId: socketData && 'id' in socketData ? decodeIdOfLiquidFile(socketData.id) : undefined,
      isLostConnection: false,
      isNeedIgnoreReportError: socketData?.errorCode === 'SyncThemeNotFound',
    } as RTHandleWaitForSocketOfSyncShopifyFulfill;
  }
  if (!eventId) {
    return {
      statusSync: 'failure',
      sectionCommandId: undefined,
      isLostConnection: true,
      isNeedIgnoreReportError: socketData?.errorCode === 'SyncThemeNotFound',
    } as RTHandleWaitForSocketOfSyncShopifyFulfill;
  }
  while (true) {
    yield take([
      getActionType(setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService),
      getActionType(setEventIdOfSyncShopifyNClonePageAtomServiceToThemeAtomService),
    ]);
    const { streams, eventId }: ReturnType<typeof socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector> = yield select(
      socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector,
    );
    const socketData = streams[eventType].socketData;
    if (fulfillStatus.includes(streams[eventType].status as SyncFulfillStatus)) {
      yield put(setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService({ socketData: undefined, status: 'idle', eventType }));

      return {
        statusSync: streams[eventType].status,
        sectionCommandId: socketData && 'id' in socketData ? decodeIdOfLiquidFile(socketData.id) : undefined,
        isLostConnection: false,
        isNeedIgnoreReportError: socketData?.errorCode === 'SyncThemeNotFound',
      } as RTHandleWaitForSocketOfSyncShopifyFulfill;
    }
    if (!eventId) {
      return {
        statusSync: 'failure',
        sectionCommandId: undefined,
        isLostConnection: true,
        isNeedIgnoreReportError: socketData?.errorCode === 'SyncThemeNotFound',
      } as RTHandleWaitForSocketOfSyncShopifyFulfill;
    }
  }
}

export interface RTHandleWaitForClonePageAtomServiceToThemeAtomService {
  statusSync: SyncFulfillStatus;
  pageCommandId: string | undefined;
  isLostConnection: boolean;
}
export function* handleWaitForClonePageAtomServiceToThemeAtomService(eventType: ClonePageAtomServiceToThemeAtomService['eventType']) {
  const { streams, eventId }: ReturnType<typeof socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector> = yield select(
    socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector,
  );
  if (fulfillStatus.includes(streams[eventType].status as SyncFulfillStatus)) {
    yield put(setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService({ socketData: undefined, status: 'idle', eventType }));
    const socketData = streams[eventType].socketData as ClonePageAtomServiceToThemeAtomService;
    return {
      statusSync: streams[eventType].status,
      pageCommandId: socketData.pageCommandId,
      isLostConnection: false,
    } as RTHandleWaitForClonePageAtomServiceToThemeAtomService;
  }
  if (!eventId) {
    return {
      statusSync: 'failure',
      pageCommandId: undefined,
      isLostConnection: true,
    } as RTHandleWaitForClonePageAtomServiceToThemeAtomService;
  }
  while (true) {
    yield take([
      getActionType(setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService),
      getActionType(setEventIdOfSyncShopifyNClonePageAtomServiceToThemeAtomService),
    ]);
    const { streams, eventId }: ReturnType<typeof socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector> = yield select(
      socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector,
    );
    if (fulfillStatus.includes(streams[eventType].status as SyncFulfillStatus)) {
      yield put(setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService({ socketData: undefined, status: 'idle', eventType }));
      const socketData = streams[eventType].socketData as ClonePageAtomServiceToThemeAtomService;
      return {
        statusSync: streams[eventType].status,
        pageCommandId: socketData.pageCommandId,
        isLostConnection: true,
      } as RTHandleWaitForClonePageAtomServiceToThemeAtomService;
    }
    if (!eventId) {
      return {
        statusSync: 'failure',
        pageCommandId: undefined,
        isLostConnection: true,
      } as RTHandleWaitForClonePageAtomServiceToThemeAtomService;
    }
  }
}
