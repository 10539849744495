import { getFontFaceClause } from 'components/HelmetImportShopifyFont/HelmetImportShopifyFont';
import { getCssFont } from 'components/ShopifyFontsField/data';
import { categorizeFonts } from 'generate/utils/categorizeFonts';
import { getCssColorVariables, getCssFontVariables, getCssFromLayoutSettings, getColorSchemes } from 'generate/utils/generateHelpers';
import { vedaFontToShopifyFieldId } from 'services/ShopifyConnection/utils/VedaOutputToShopifyThemeEditor/ThemeSettingsConverter/SettingFonts/utils/vedaFontToShopifyFieldId';
import { CssVariables, File, LayoutSettings, ColorSchemes, ThemeGeneralSettings, ThemeVendors } from 'types/Result';
import { Target } from '../types';
import { SetThemeVersionAction } from 'store/global/themeVersion/types';
import { getThemeVersion } from 'utils/functions/getThemeVersion';

interface GetVendorsOfTheme {
  vendors: ThemeVendors;
  themeGeneralSettings: ThemeGeneralSettings;
  cssVariables: CssVariables;
  colorSchemes: ColorSchemes;
  layout: LayoutSettings;
  target: Target;
  themeVersion: SetThemeVersionAction['payload'];
}

export const SIGNAL_VENDORS_JS = '//startAssets @veda_id:vendorsJs @veda_name:vendorsJs @veda_type:vendorsJs @veda_content:';
export const SIGNAL_VENDORS_CSS = '//startAssets @veda_id:vendorsCss @veda_name:vendorsCss @veda_type:vendorsCss @veda_content:';

export const getVendorsOfTheme = ({ vendors, cssVariables, colorSchemes, layout, target, themeVersion }: GetVendorsOfTheme): [File, File] => {
  const themeV2 = getThemeVersion(themeVersion) === '>=2.0.0';
  const { colors, fonts } = cssVariables;

  const cssVariablesCss = [
    `:root {
      ${getCssColorVariables({ colors, target, variant: 'light' })}
      ${getCssFontVariables({ fonts, target })}
    }
    :root.dark {
      ${getCssColorVariables({ colors, target, variant: 'dark' })}
    }
    `,
    getCssFromLayoutSettings({ layoutSettings: layout, target }),
    themeV2 ? getColorSchemes({ colorSchemes, target }) : '',
  ].join('\n');

  let css = `
  <style>${cssVariablesCss}</style>
  `;
  let js = ``;

  const { googleFonts, shopifyFonts } = categorizeFonts({ fonts: cssVariables.fonts, uniqueValue: true });
  const linkGoogleFonts = googleFonts.reduce(
    (str, item) =>
      `${str}<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=${item.value.replace(
        /\s/g,
        '+',
      )}:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" />\n`,
    '',
  );
  const linkShopifyFonts = shopifyFonts
    .reduce((str, item) => {
      if (target === 'Trích xuất kết quả sync lên shopify envato') {
        const shopifyFieldId = vedaFontToShopifyFieldId(item);
        return str.concat(`
          {{ settings.${shopifyFieldId} | font_modify: 'weight', '100' | font_face: font_display: 'swap' }}
          {{ settings.${shopifyFieldId} | font_modify: 'weight', '200' | font_face: font_display: 'swap' }}
          {{ settings.${shopifyFieldId} | font_modify: 'weight', '300' | font_face: font_display: 'swap' }}
          {{ settings.${shopifyFieldId} | font_modify: 'weight', '400' | font_face: font_display: 'swap' }}
          {{ settings.${shopifyFieldId} | font_modify: 'weight', '500' | font_face: font_display: 'swap' }}
          {{ settings.${shopifyFieldId} | font_modify: 'weight', '600' | font_face: font_display: 'swap' }}
          {{ settings.${shopifyFieldId} | font_modify: 'weight', '700' | font_face: font_display: 'swap' }}
          {{ settings.${shopifyFieldId} | font_modify: 'weight', '800' | font_face: font_display: 'swap' }}
          {{ settings.${shopifyFieldId} | font_modify: 'weight', '900' | font_face: font_display: 'swap' }}
          {{ settings.${shopifyFieldId} | font_modify: 'style', 'normal' | font_face: font_display: 'swap' }}
          {{ settings.${shopifyFieldId} | font_modify: 'style', 'italic' | font_face: font_display: 'swap' }}
          {{ settings.${shopifyFieldId} | font_modify: 'style', 'oblique' | font_face: font_display: 'swap' }}
        `);
      }
      return str.concat(getFontFaceClause(getCssFont(item)) ?? '');
    }, '{% style %}')
    .concat('{% endstyle %}');
  vendors.forEach(item => {
    if (item.css) {
      css += `<link rel="stylesheet" href="${item.css}" />\n`;
    }
    if (item.js) {
      js += `<script src="${item.js}"></script>\n`;
    }
  });
  return [
    {
      content: `
      ${SIGNAL_VENDORS_CSS}
      ${[css, linkGoogleFonts, linkShopifyFonts].join('\n')}
    `,
      type: 'vendors css tổng của theme',
      id: 'vendorsCss',
      name: 'vendorsCss',
      section: undefined,
    },
    {
      content: `
        ${SIGNAL_VENDORS_JS}
        ${[js].join('\n')}
      `,
      type: 'vendors js tổng của theme',
      id: 'vendorsJs',
      name: 'vendorsJs',
      section: undefined,
    },
  ];
};
