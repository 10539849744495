import { ImportThemeAtomToClientServiceNPageInThemeClientMessage } from 'hooks/useSocket/useSocketForImportThemeAtomToClientServiceNPageInThemeClient';
import { AxiosResponse } from 'axios';
import fetchAPI from 'utils/functions/fetchAPI';
import { clientBaseUrl } from '../const';

interface ImportOtherEntityOfPageInTheme {
  themeAtomCommandId: string;
  pageCommandId: string;
  eventType: ImportThemeAtomToClientServiceNPageInThemeClientMessage['eventType'];
  eventId: string;
}

interface ResponseSuccess {
  message: string;
}

export const importOtherEntityOfPageInTheme = async ({ themeAtomCommandId, pageCommandId, eventType, eventId }: ImportOtherEntityOfPageInTheme) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${clientBaseUrl}/imports/${themeAtomCommandId}/pages/${pageCommandId}`,
    params: { themeAtomCommandId, pageCommandId, eventType, eventId },
    method: 'PUT',
  });
  return response.data;
};
