import { renewVersion } from 'utils/CacheControl/VedaClientCacheControl';
import { pageApis } from '../apis';

interface DuplicatePageAtom {
  commandId: string;
}

export const duplicatePageClientAPI = async ({ commandId }: DuplicatePageAtom) => {
  const response = await pageApis.vedaApplication.userApi.page.duplicateClient({ commandId });
  renewVersion();
  return response;
};
