import { restoreThemeToDefault } from 'store/actions/actionRestoreThemeToDefault';
import { ActionTypes, createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';

type ExtraActions = ActionTypes<typeof restoreThemeToDefault>;

type Action =
  | {
      type: 'setVisibleModalAfterRestore';
      payload: boolean;
    }
  | {
      type: 'setVisibleModalRestore';
      payload: boolean;
    };

interface State {
  status: Status;
  visibleModalAfterRestore: boolean;
  visibleModalRestore: boolean;
}

export const sliceRestoreToDefault = createSlice<State, Action, ExtraActions>({
  name: '@Global',
  initialState: {
    status: 'idle',
    visibleModalAfterRestore: false,
    visibleModalRestore: false,
  },
  reducers: [
    handleAction('setVisibleModalAfterRestore', ({ state, action }) => {
      state.visibleModalAfterRestore = action.payload;
    }),
    handleAction('setVisibleModalRestore', ({ state, action }) => {
      state.visibleModalRestore = action.payload;
    }),
  ],
  extraReducers: [
    handleAction('@Global/restoreThemeToDefault/request', ({ state }) => {
      state.status = 'loading';
    }),
    handleAction('@Global/restoreThemeToDefault/success', ({ state }) => {
      state.status = 'success';
    }),
    handleAction('@Global/restoreThemeToDefault/failure', ({ state }) => {
      state.status = 'failure';
    }),
  ],
});
export const { setVisibleModalAfterRestore, setVisibleModalRestore } = sliceRestoreToDefault.actions;

export const restoreToDefaultSelector = (state: AppState) => state.global.restoreToDefault;

export const useSetVisibleModalAfterRestore = createDispatchAction(setVisibleModalAfterRestore);
export const useSetVisibleModalRestore = createDispatchAction(setVisibleModalRestore);
