import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { userApis } from '../apis';

interface GetParams {
  size: number;
  purchaseCode?: string;
  website?: string;
}

export const getPurchaseCodesService = async (params: GetParams) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    return userApis.purchaseCode.adminApi.getPurchaseCodes({ type: 'Get First Page', ...params });
  }
  if (role === 'dev' || role === 'support' || role === 'user') {
    return userApis.purchaseCode.userApi.getPurchaseCodes({ type: 'Get First Page', ...params });
  }
  throw new RoleException();
};

interface LoadMoreParams {
  size: number;
  page: number;
  purchaseCode?: string;
  website?: string;
}

export const loadMorePurchaseCodesService = async (params: LoadMoreParams) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    return userApis.purchaseCode.adminApi.getPurchaseCodes({ type: 'Load more', ...params });
  }
  if (role === 'dev' || role === 'support' || role === 'user') {
    return userApis.purchaseCode.userApi.getPurchaseCodes({ type: 'Load more', ...params });
  }
  throw new RoleException();
};
