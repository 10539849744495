import { SectionCategoryTag } from 'types/Sections';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

export const getCategoriesOfThemeProduct = async () => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const response = await themeApis.vedaApplication.adminApi.category.getCategories({ cacheVersion: lastCache });

    const mapResponse = response.info.map<SectionCategoryTag>(item => {
      return {
        ...item,
        name: item.name,
        description: item.description ? item.description : item.name,
        commandId: item.commandId,
      };
    });

    return mapResponse;
  }
  const response = await themeApis.vedaApplication.userApi.category.getCategories({ cacheVersion: lastCache });
  const mapResponse = response.info.map<SectionCategoryTag>(item => {
    return {
      ...item,
      name: item.name,
      description: item.description ? item.description : item.name,
      commandId: item.commandId,
    };
  });

  return mapResponse;
};
