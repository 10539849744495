import { storage } from '@wiloke/functions';
import { Tooltip } from '@wiloke/ui';
import { useSetTourGuidePage } from 'containers/TourGuides/sliceTourGuides';
import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { FontAwesome, View } from 'wiloke-react-core';

export const IconTourTheme = () => {
  const setPageTour = useSetTourGuidePage();
  const location = useLocation();

  const title = 'Click here to re-open tour page';

  const handleRemove = useCallback(() => {
    if (location.pathname.includes('/page')) {
      storage.removeItem('TourGuideForPage');
      setPageTour({ run: true, stepIndex: 0 });
    }
  }, [location.pathname, setPageTour]);

  return (
    <View
      css={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        cursor: 'pointer',
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100,
      }}
      borderColor="gray3"
      borderWidth={1}
      borderStyle="solid"
      backgroundColor="light"
      onClick={handleRemove}
    >
      <Tooltip text={title} placement="left" css={{ padding: '20px' }}>
        <FontAwesome type="fas" name="lightbulb" size={20} color="primary" />
      </Tooltip>
    </View>
  );
};
