import { megaMenuApiController } from 'services/MegaMenuService';
import { adapterGetMegaMenu } from 'services/MegaMenuService/Adapters/adapterGetMegaMenu';
import { ProductSection } from 'types/Sections';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const getProductSection = async (commandId: string) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const response = await megaMenuApiController.product.adminApi.mega_menu.getProduct({ commandId, cacheVersion: lastCache });
    return adapterGetMegaMenu(response) as ProductSection;
  }
  const response = await megaMenuApiController.product.userApi.mega_menu.getProduct({ commandId, cacheVersion: lastCache });
  return adapterGetMegaMenu(response) as ProductSection;
};
