import { put, retry, SagaReturnType, select, takeLatest } from 'redux-saga/effects';
import { fontService } from 'services/ShopifyServices/Fonts';
import { getShopifyFonts } from 'store/actions/shopify/actionFonts';
import { shopifySelector } from 'store/selectors';
import { getActionType } from 'wiloke-react-core/utils';

function* handleGet() {
  try {
    const { fonts, getRequest }: ReturnType<typeof shopifySelector.fonts> = yield select(shopifySelector.fonts);
    if (getRequest === 'success') {
      yield put(getShopifyFonts.success({ data: fonts }));
    } else {
      const response: SagaReturnType<typeof fontService.getFontsShopify> = yield retry(3, 1000, fontService.getFontsShopify);

      yield put(getShopifyFonts.success({ data: response.font_families }));
    }
  } catch (error) {
    yield put(getShopifyFonts.failure(undefined));
  }
}

export function* watchGetShopifyFonts() {
  yield takeLatest(getActionType(getShopifyFonts.request), handleGet);
}
