import { useSelector } from 'react-redux';
import { ActivityIndicator, GridSmart, View, ViewportTracking } from 'wiloke-react-core';
import { themeTrashSelector } from '../../store/reducers/sliceTrashTheme';
import { useGetTrashPagesOfTheme, useLoadMoreTrashPagesOfTheme, useRestorePageOfTrashTheme } from '../../store/actions/actionThemeTrash';
import { useEffect, useMemo } from 'react';
import { AsyncComponent } from '@wiloke/ui';
import DraftBox from 'components/DraftBox';
import FieldBox from 'components/FieldBox';
import { getUserInfo } from 'utils/functions/getUserInfo';
import * as styles from '../../styles';
import { at } from 'utils/at';

export const PageOfTrashThemePageOfTrashTheme = () => {
  const {
    page: { searchKey, getStatus, data, queueRestoring, hasNextPage, loadMoreStatus },
  } = useSelector(themeTrashSelector);

  const getTrashPagesOfTheme = useGetTrashPagesOfTheme();
  const restoreRequest = useRestorePageOfTrashTheme();
  const loadMorePageRequest = useLoadMoreTrashPagesOfTheme();

  const { role } = getUserInfo();

  useEffect(() => {
    getTrashPagesOfTheme.request({ searchKey });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  const Actions = (item: typeof data[number]) => {
    const { commandId } = item;
    if (role === 'admin') {
      return (
        <View
          color="light"
          fontFamily="secondary"
          backgroundColor="behance"
          css={styles.rightItem}
          onClick={() => {
            restoreRequest.request({ commandId });
          }}
        >
          Restore
        </View>
      );
    }

    return null;
  };

  const TrackingLoadMore = useMemo(() => {
    const cursor = at(data, -1)?.commandId;
    if (hasNextPage && cursor) {
      return (
        <ViewportTracking
          onEnterViewport={() => {
            if (loadMoreStatus !== 'loading') {
              loadMorePageRequest.request({ cursor, searchKey });
            }
          }}
        >
          <View css={{ display: 'flex', justifyContent: 'center', marginTop: '14px' }}>
            <ActivityIndicator size={40} />
          </View>
        </ViewportTracking>
      );
    }
    return null;
  }, [data, hasNextPage, loadMoreStatus, loadMorePageRequest, searchKey]);

  return (
    <FieldBox radius={6} borderColor="gray2" css={{ padding: '20px 10px' }}>
      <AsyncComponent
        status={getStatus}
        isEmpty={data.length === 0}
        Success={
          <View>
            <GridSmart columnCount={2} columnWidth={300}>
              {data.map(item => (
                <DraftBox
                  key={item.commandId}
                  title={item.label}
                  image={item.image.src}
                  badgeTitle="Themes: "
                  badges={item.addedInThemes}
                  CustomDropdown={<></>}
                  Right={Actions(item)}
                  loading={queueRestoring.includes(item.commandId)}
                />
              ))}
            </GridSmart>
            {TrackingLoadMore}
          </View>
        }
      />
    </FieldBox>
  );
};
