import { MyModal } from '@wiloke/ui';
import { notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { adminApi } from 'services/ThemeService/Atom';
import { ClearCacheOnCloudflare } from 'services/ThemeService/Atom/admin/clearCacheOnCloudflare';
import { i18n } from 'translation';
import { Text, View } from 'wiloke-react-core';

interface Static {
  getActions: () => {
    open: (params: ClearCacheOnCloudflare) => void;
    close: () => void;
  };
}

const modaClearCloudflareHandlers: Map<string, ReturnType<Static['getActions']>> = new Map();
const KEY = 'MODAL_CLEAR_CLOUD_FLARE';

export const ModalClearCloudflare: FC & Static = () => {
  const [data, setData] = useState<ClearCacheOnCloudflare | undefined>(undefined);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = (data: ClearCacheOnCloudflare) => {
    setData(data);
    setIsVisible(true);
  };
  const handleCancel = () => {
    setIsVisible(false);
  };
  const handleOk = async () => {
    if (data) {
      setIsLoading(true);
      try {
        await adminApi.clearCacheOnCloudflare(data);
        setIsVisible(false);
      } catch (error) {
        console.log(error);
        notification.error({
          message: 'Clear cache cloudflare',
          description: i18n.t('general.system_error'),
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      notification.error({
        message: 'Clear cache cloudflare',
        description: i18n.t('general.system_error'),
      });
    }
  };

  useEffect(() => {
    modaClearCloudflareHandlers.set(KEY, { open: handleOpen, close: handleCancel });
    return () => {
      modaClearCloudflareHandlers.delete(KEY);
    };
  });

  return (
    <MyModal isLoading={isLoading} headerText="Clear cache cloudflare" isVisible={isVisible} onCancel={handleCancel} onOk={handleOk}>
      <View>
        <Text tagName="h5">Tại sao lại có modal này?</Text>
        <View tagName="ul" css={{ listStyle: 'none' }}>
          <View tagName="li">Cần cache để giảm lượng request khi import 1 theme (Dữ liệu được fetch từ Theme Atom tới Client Service).</View>
        </View>
      </View>
      <View>
        <Text tagName="h5">Lưu ý</Text>
        <View tagName="ul" css={{ listStyle: 'none' }}>
          <View tagName="li">
            Cloudflare giới hạn số lượng purge cache trên 1 ngày nên hạn chế sử dụng tự động xoá khi save. Chỉ clear cache cloudflare khi cần thiết,
            đừng lạm dụng
          </View>
        </View>
      </View>
    </MyModal>
  );
};

ModalClearCloudflare.getActions = () => {
  const actions = modaClearCloudflareHandlers.get(KEY);
  if (actions) {
    return actions;
  }
  throw new Error(`ModalClearCloudflare -> ${KEY} not exist`);
};
