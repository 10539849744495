import { put, retry, takeLatest } from 'redux-saga/effects';
import { getAuthors } from 'services/UserService/Logic/getAuthors';
import { getActionType } from 'wiloke-react-core/utils';
import { getAuthors as actionGetAuthors } from '../action';

function* handleGetAuthors({ payload: { search, role } }: ReturnType<typeof actionGetAuthors.request>) {
  try {
    const { content, number, totalPages }: Awaited<ReturnType<typeof getAuthors>> = yield retry(3, 1000, getAuthors, {
      page: 0,
      username: search,
      role,
    });
    yield put(actionGetAuthors.success({ authors: content, page: number, totalPages: totalPages }));
  } catch (error) {
    console.log(error);
    yield put(actionGetAuthors.failure(undefined));
  }
}

export function* watchGetAuthors() {
  yield takeLatest(getActionType(actionGetAuthors.request), handleGetAuthors);
}
