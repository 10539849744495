import { Empty, Tooltip } from '@wiloke/ui';
import { useSelector } from 'react-redux';
import { pagesSelector } from 'store/selectors';
import { i18n } from 'translation';
import { PageType, Page } from 'types/Page';
import getPageInfo from 'utils/functions/getInfo';
import { Text, View } from 'wiloke-react-core';
import * as styles from '../styles';

interface PagesSelectionProps {
  pageType: PageType;
  onChange: (page: Page) => void;
}
export const PagesSelection: React.FC<PagesSelectionProps> = ({ pageType, onChange }) => {
  const pages = useSelector(pagesSelector);
  const pagesData = Object.values(pages.data);
  const pagesByPageType = pagesData.filter(page => page.type === pageType);

  const renderEmpty = <Empty />;

  const renderPages = (page: Page) => {
    if (!page.label) {
      return renderEmpty;
    }
    return (
      <View
        key={page.commandId}
        css={styles.dropdownItem(page.commandId === getPageInfo('id'))}
        onClick={() => {
          onChange(page);
        }}
        backgroundColorHover="gray1"
      >
        <Text numberOfLines={1}>{page.label}</Text>
        {!!page.sections.length && (
          <Tooltip
            portal
            placement="right"
            text={`${i18n.t('general.page', { text: i18n.t('builderPage.loaded') })}`}
            css={{ padding: '3px', marginRight: '-3px' }}
          >
            <View backgroundColor="secondary" radius="pill" css={{ width: '6px', height: '6px' }} />
          </Tooltip>
        )}
      </View>
    );
  };

  return <>{pagesByPageType.length > 0 ? pagesByPageType.map(renderPages) : renderEmpty}</>;
};
