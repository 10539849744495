import { Role } from 'routes/types';
import { ResponseUserPlan, SiteStatus } from 'services/AuthService';
import { PLAN_HANDLE } from 'utils/constants/PlanEnum';
import { ActionTypes, createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';
import { getUserPlan, updateLastCacheOfVeda, updateThemeIdOfUser, verifyUserStorefrontPassword } from './actions';
import { GlobalCoupon } from 'types/Coupon';

interface SetAccessToken {
  type: 'setAccessToken';
  payload: string;
}

interface SetRefreshToken {
  type: 'setRefreshToken';
  payload: string;
}

interface SetRoleForDev {
  type: 'setRoleForDev';
  payload: Role;
}

interface SetPlanForDev {
  type: 'setPlanForDev';
  payload: PLAN_HANDLE;
}

export interface VerifyUserRequest {
  type: 'verifyUser/request';
  payload: undefined;
}

interface SetVisibleModalStorefrontPassword {
  type: 'setVisibleModalStorePassword';
  payload: boolean;
}

interface SetChangeThemeButNotSave {
  type: 'setChangeThemeButNotSave';
  payload: boolean;
}

interface SetGlobalCoupon {
  type: 'setGlobalCoupon';
  payload?: GlobalCoupon;
}

interface VerifyUserSuccess {
  type: 'verifyUser/success';
  payload: {
    id: number;
    email: string;
    role: Role;
    shopName?: string;
    username: string;
    siteStatus: SiteStatus[];
    plan: ResponseUserPlan;
    themeId: string;
    lastCache: number;
    shopifyActivateThemeId: string;
    shopifyPlanName?: string;
    storefrontPassword: string;
    name: string;
  };
}

interface VerifyUserFailure {
  type: 'verifyUser/failure';
  payload: undefined;
}

type AuthAction =
  | SetAccessToken
  | SetRoleForDev
  | VerifyUserRequest
  | VerifyUserSuccess
  | VerifyUserFailure
  | SetRefreshToken
  | SetVisibleModalStorefrontPassword
  | SetPlanForDev
  | SetChangeThemeButNotSave
  | SetGlobalCoupon;

type AuthExtraAction = ActionTypes<
  typeof updateLastCacheOfVeda | typeof verifyUserStorefrontPassword | typeof updateThemeIdOfUser | typeof getUserPlan
>;

interface AuthState {
  status: Status;
  id: number;
  accessToken: string;
  refreshToken: string;
  role: Role;
  username: string;
  name: string;
  email: string;
  plan: ResponseUserPlan;
  message: string;
  shopName: string;
  siteStatus: SiteStatus[];
  themeVedaId: string;
  lastCache: number | null;
  // @deprecated Không còn sử dụng cái này để check sync vào theme nào nữa => Dùng "themeVedaId" để xác định theme sẽ được ghi
  shopifyActivateThemeId: string;
  shopifyPlanName?: string;
  storefrontPassword: string;
  verifyStorefrontPasswordStatus: Status;
  visibleModalPassword: boolean;
  verifiedPasswordSuccess: boolean;
  updateThemeIdOfUserStatus: Status;
  getUserPlanStatus: Status;
  changeThemeButNotSave: boolean;
  globalCoupon?: GlobalCoupon;
}

const initialState: AuthState = {
  status: 'success',
  id: 1,
  accessToken: '',
  refreshToken: '',
  role: 'admin',
  username: '',
  email: '',
  plan: {
    chargeId: 0,
    planHandle: 'free',
    trialDays: 0,
    userId: 0,
    isMonthly: true,
  },
  message: '',
  shopName: 'magicbadgesdev.myshopify.com',
  siteStatus: [],
  themeVedaId: '',
  lastCache: null,
  shopifyActivateThemeId: '',
  shopifyPlanName: undefined,
  storefrontPassword: '',
  verifyStorefrontPasswordStatus: 'idle',
  visibleModalPassword: false,
  verifiedPasswordSuccess: false,
  updateThemeIdOfUserStatus: 'idle',
  getUserPlanStatus: 'idle',
  changeThemeButNotSave: false,
  name: '',
  globalCoupon: undefined,
};

export const sliceAuth = createSlice<AuthState, AuthAction, AuthExtraAction>({
  name: '@Global',
  initialState,
  reducers: [
    handleAction('setChangeThemeButNotSave', ({ state, action }) => {
      state.changeThemeButNotSave = action.payload;
    }),
    handleAction('setVisibleModalStorePassword', ({ state, action }) => {
      state.visibleModalPassword = action.payload;
    }),
    handleAction('setAccessToken', ({ state, action }) => ({ ...state, accessToken: action.payload })),
    handleAction('setRefreshToken', ({ state, action }) => ({ ...state, refreshToken: action.payload })),
    handleAction('setRoleForDev', ({ state, action }) => ({
      ...state,
      role: action.payload,
    })),
    handleAction('verifyUser/request', ({ state }) => ({ ...state, status: 'loading' })),
    handleAction(
      'verifyUser/success',
      ({
        state,
        action: {
          payload: {
            email,
            role,
            id,
            username,
            shopName,
            siteStatus,
            plan,
            themeId,
            shopifyActivateThemeId,
            lastCache,
            shopifyPlanName,
            storefrontPassword,
            name,
          },
        },
      }) => ({
        ...state,
        status: 'success',
        email,
        id,
        role,
        username,
        plan,
        shopName: shopName ?? state.shopName,
        siteStatus,
        themeVedaId: themeId,
        lastCache,
        shopifyActivateThemeId,
        shopifyPlanName,
        storefrontPassword,
        name,
      }),
    ),
    handleAction('verifyUser/failure', ({ state }) => ({ ...state, status: 'failure' })),
    handleAction('setPlanForDev', ({ state, action }) => {
      return {
        ...state,
        plan: {
          ...state.plan,
          planHandle: action.payload ?? state.plan.planHandle,
        },
      };
    }),
    handleAction('setGlobalCoupon', ({ state, action }) => {
      state.globalCoupon = action.payload;
    }),
  ],
  extraReducers: [
    handleAction('@updateLastCacheOfVeda', ({ state, action }) => {
      state.lastCache = action.payload;
    }),
    handleAction('@Global/verifyUserStorefrontPassword/request', ({ state }) => {
      state.verifyStorefrontPasswordStatus = 'loading';
    }),
    handleAction('@Global/verifyUserStorefrontPassword/success', ({ state, action }) => {
      state.verifyStorefrontPasswordStatus = 'success';
      state.verifiedPasswordSuccess = action.payload.verifySucceeded;
      state.storefrontPassword = action.payload.password;
    }),
    handleAction('@Global/verifyUserStorefrontPassword/failure', ({ state, action }) => {
      state.verifyStorefrontPasswordStatus = 'failure';
      state.verifiedPasswordSuccess = action.payload.verifySucceeded;
    }),
    handleAction('@Global/updateThemeIdOfUser/request', ({ state }) => {
      state.updateThemeIdOfUserStatus = 'loading';
    }),
    handleAction('@Global/updateThemeIdOfUser/success', ({ state, action }) => {
      state.updateThemeIdOfUserStatus = 'success';
      state.themeVedaId = action.payload.themeId;
    }),
    handleAction('@Global/updateThemeIdOfUser/failure', ({ state }) => {
      state.updateThemeIdOfUserStatus = 'failure';
    }),
    handleAction('@Global/getUserPlan/request', ({ state }) => {
      state.getUserPlanStatus = 'loading';
    }),
    handleAction('@Global/getUserPlan/success', ({ state, action }) => {
      state.getUserPlanStatus = 'success';
      state.plan = action.payload.plan;
    }),
    handleAction('@Global/getUserPlan/failure', ({ state }) => {
      state.getUserPlanStatus = 'failure';
    }),
  ],
});

export const {
  setAccessToken,
  setRoleForDev,
  setVisibleModalStorePassword,
  setPlanForDev,
  setChangeThemeButNotSave,
  setGlobalCoupon,
} = sliceAuth.actions;
export const useSetAccessToken = createDispatchAction(setAccessToken);
export const useSetRoleForDev = createDispatchAction(setRoleForDev);
export const useSetPlanForDev = createDispatchAction(setPlanForDev);
export const useSetChangeThemeButNotSave = createDispatchAction(setChangeThemeButNotSave);

export const useVerifyUser = () => ({
  request: createDispatchAction(sliceAuth.actions['verifyUser/request'])(),
  success: createDispatchAction(sliceAuth.actions['verifyUser/success'])(),
  failure: createDispatchAction(sliceAuth.actions['verifyUser/failure'])(),
});
export const useSetVisibleModalStorePassword = createDispatchAction(setVisibleModalStorePassword);

export const verifyUser = {
  request: sliceAuth.actions['verifyUser/request'],
  success: sliceAuth.actions['verifyUser/success'],
  failure: sliceAuth.actions['verifyUser/failure'],
};
