import { delay } from '@wiloke/functions';
import { AxiosError } from 'axios';
import { renewVersion } from 'utils/CacheControl/ShopifyConnectionCacheControl';
import fetchAPI from 'utils/functions/fetchAPI';

export const withDelayAfterThrottled = async (request: ReturnType<typeof fetchAPI.request>, ms = 2000) => {
  try {
    const response = await request;
    return response;
  } catch (error) {
    if (error instanceof Error) {
      const error_ = error as AxiosError;
      if (error_.isAxiosError) {
        renewVersion();
        await delay(ms);
      }
      throw error;
    } else {
      throw new Error('withDelayAfterError');
    }
  }
};
