import { AxiosRequestConfig, AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import { PageFavoriteDataResponse } from 'services/PageService/VedaApplication/types';
import fetchAPI from 'utils/functions/fetchAPI';
import { getApiTracking } from 'utils/functions/getApiTracking';

interface ResponseSuccess {
  info?: PageFavoriteDataResponse[];
  message: string;
}

interface GetParams {
  type: 'Get first page';
  name?: string;
  cacheVersion: number | null;
}

interface LoadMoreParams {
  type: 'Load more';
  name?: string;
  cursor: string;
  cacheVersion: number | null;
}
export const getFavorites = async ({ type, name, cacheVersion, ...params }: GetParams | LoadMoreParams) => {
  let requestConfig: AxiosRequestConfig = {};
  const url = getApiTracking({ defaultBaseUrl: `${configureApp.endpoint.clients}/me/favorites`, trackingEndpoint: `favorites` });

  if (type === 'Get first page') {
    requestConfig = {
      url,
      method: 'GET',
      params: {
        favoriteType: 'PAGE',
        name: name === '' ? undefined : name,
        group: 'GENERAL',
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
      },
    };
  } else {
    const { cursor } = params as LoadMoreParams;
    requestConfig = {
      url,
      method: 'GET',
      params: {
        favoriteType: 'PAGE',
        name: name === '' ? undefined : name,
        group: 'GENERAL',
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
        after: cursor,
      },
    };
  }
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request(requestConfig);
  return response.data;
};
