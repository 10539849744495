import { Theme, css, Styles } from 'wiloke-react-core';

export const container = ({ colors }: Theme) => css`
  background-color: ${colors.light};
  border-radius: 6px;
  border: 1px solid ${colors.gray3};
  padding: 10px;
  user-select: none;
  display: flex;
  cursor: pointer;
  :global {
    .color-scheme-popover {
      padding: 0;
      .ant-popover-inner {
        background-color: ${colors.light};
        border-radius: 6px;
        overflow: hidden;
      }
      .ant-popover-inner-content {
        padding: 0;
        color: ${colors.gray9};
      }
      .ant-popover-arrow {
        display: none;
      }
    }
  }
`;

export const buttonContent = css`
  margin-left: 10px;
  width: 100%;
`;

export const buttonInner = css`
  display: flex;
  width: 100%;
  margin-top: 4px;
`;

export const buttonChange = css`
  padding: 6px 10px;
  margin-right: 5px;
  width: 100%;
`;

export const buttonEdit = css`
  padding: 6px;
  width: 32px;
  flex-shrink: 0;
`;

export const colors = ({ colors }: Theme) => css`
  width: 60px;
  border-radius: 4px;
  padding: 8px;
  text-align: center;
  border: 1px solid ${colors.gray3};
  flex-shrink: 0;
`;

export const colorText = ({ fonts }: Theme) => css`
  font-size: 20px;
  font-family: ${fonts.secondary};
  line-height: 1;
  margin-bottom: 4px;
`;

export const colorsInner = css`
  display: flex;
`;

export const color = css`
  width: 25%;
  height: 10px;
`;

export const dropdownItem = (isActive: boolean, isDisable: boolean) => ({ colors }: Theme) => css`
  display: flex;
  align-items: center;
  padding: 6px 10px;
  gap: 10px;
  transition: 0.3s;
  cursor: pointer;
  position: relative;
  background-color: ${isActive ? colors.gray2 : colors.light};
  opacity: ${isDisable ? '0.4' : '1'};
  &:hover {
    background-color: ${colors.gray1};
  }
`;

export const iconDisable: Styles = {
  position: 'absolute',
  top: '50%',
  right: '10px',
  transform: 'translateY(-50%)',
  zIndex: '10',
  width: '25px',
  height: '25px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
