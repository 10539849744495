import { MenuItem } from 'components/Navigation/Navigation';
import { i18n } from 'translation';
import { v4 } from 'uuid';
import configureApp from 'configureApp';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { PAGE_BUILD_HEADER_FOOTER } from 'utils/constants/constants';

interface TypeRoute extends MenuItem {
  type: string;
}

export const expandPages: MenuItem[] = [
  {
    id: v4(),
    href: '/page/customer/search',
    label: i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.search') }),
    icon: 'search',
    isReactRouter: true,
    exact: true,
    pageType: 'search',
  },
  {
    id: v4(),
    href: '/page/customer/password',
    label: i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.password') }),
    icon: 'lock',
    isReactRouter: true,
    exact: true,
    pageType: 'password',
  },
  {
    id: v4(),
    href: '/page/customer/pageNotFound',
    label: i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.notFound') }),
    icon: 'exclamation-triangle',
    isReactRouter: true,
    exact: true,
    pageType: 'pageNotFound',
  },
  // {
  //   id: v4(),
  //   href: '/page/customer/giftCard',
  //   label: i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.giftCard') }),
  //   icon: 'gift',
  //   isReactRouter: true,
  //   exact: true,
  //   pageType: 'giftCard',
  // },
  {
    id: v4(),
    href: '/page/customer/login',
    label: i18n.t('adminDashboard.pages', { name: i18n.t('general.login') }),
    icon: 'user-lock',
    isReactRouter: true,
    exact: true,
    pageType: 'login',
  },
  {
    id: v4(),
    href: '/page/customer/resetPassword',
    label: i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.reset_password') }),
    icon: 'repeat',
    isReactRouter: true,
    exact: true,
    pageType: 'resetPassword',
  },
  {
    id: v4(),
    href: '/page/customer/activateAccount',
    label: i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.active_account') }),
    icon: 'user-check',
    isReactRouter: true,
    exact: true,
    pageType: 'activateAccount',
  },
  {
    id: v4(),
    href: '/page/customer/register',
    label: i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.register') }),
    icon: 'address-card',
    isReactRouter: true,
    exact: true,
    pageType: 'register',
  },
  {
    id: v4(),
    href: '/page/customer/account',
    label: i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.account') }),
    icon: 'user',
    isReactRouter: true,
    exact: true,
    pageType: 'account',
  },
  {
    id: v4(),
    href: '/page/customer/order',
    label: i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.order') }),
    icon: 'cart-arrow-down',
    isReactRouter: true,
    exact: true,
    pageType: 'order',
  },
  {
    id: v4(),
    href: '/page/customer/addresses',
    label: i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.addresses') }),
    icon: 'location-arrow',
    isReactRouter: true,
    exact: true,
    pageType: 'addresses',
  },
];

export const menuItem = (): MenuItem[] => {
  return [
    {
      id: v4(),
      href: '/page',
      label: i18n.t('adminDashboard.menubar.dashboard'),
      icon: 'columns',
      isReactRouter: true,
      exact: true,
      textHeader: 'General',
    },
    {
      id: v4(),
      href: '/page/templates',
      label: i18n.t('general.templates'),
      icon: 'browser',
      isReactRouter: true,
      exact: true,
      hasDivider: true,
    },
    {
      id: v4(),
      href: '/page/blank',
      label: i18n.t('adminDashboard.landing_and_more'),
      icon: 'file',
      isReactRouter: true,
      exact: true,
      textHeader: 'pages',
      pageType: 'page',
    },
    {
      id: v4(),
      href: '/page/home',
      label: i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.home') }),
      icon: 'home',
      isReactRouter: true,
      exact: true,
      pageType: 'home',
    },
    {
      id: v4(),
      href: '/page/products',
      label: i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.product') }),
      icon: 'shopping-cart',
      isReactRouter: true,
      exact: true,
      pageType: 'product',
    },
    {
      id: v4(),
      href: '/page/collection',
      label: i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.collection') }),
      icon: 'columns',
      isReactRouter: true,
      exact: true,
      pageType: 'collection',
    },

    {
      id: v4(),
      href: '/page/article',
      label: i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.article') }),
      icon: 'newspaper',
      isReactRouter: true,
      exact: true,
      pageType: 'article',
    },
    {
      id: v4(),
      href: '/page/blog',
      label: i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.blog') }),
      icon: 'newspaper',
      isReactRouter: true,
      exact: true,
      pageType: 'blog',
    },
    {
      id: v4(),
      href: '/page/customer/collections',
      label: i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.collection_listing') }),
      icon: 'list',
      isReactRouter: true,
      exact: true,
      pageType: 'collections',
    },
    {
      id: v4(),
      href: '/page/customer/cart',
      label: i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.cart') }),
      icon: 'cart-arrow-down',
      isReactRouter: true,
      exact: true,
      pageType: 'cart',
      plan: 'advanced',
    },
  ];
};

export const themeItems = (): MenuItem[] => {
  return [
    {
      id: v4(),
      href: '/theme',
      label: i18n.t('adminDashboard.menubar.dashboard'),
      icon: 'columns',
      isReactRouter: true,
      exact: true,
      textHeader: i18n.t('adminDashboard.general'),
    },
    ...((configureApp.DEV_ENABLE_INSTALL_THEME
      ? [
          {
            id: 'route-theme-templates',
            href: '/theme/templates',
            label: i18n.t('general.themes'),
            icon: 'browser',
            isReactRouter: true,
            exact: true,
            hasDivider: true,
            hotspot: true,
          },
        ]
      : []) as MenuItem[]),
    {
      id: v4(),
      href: '/theme/settings',
      label: i18n.t('general.theme', { text: i18n.t('general.settings') }),
      icon: 'cog',
      isReactRouter: true,
      exact: true,
    },
  ];
};

export const builderTypeRoute = (hideManager: boolean): TypeRoute[] => {
  const { shopName } = getUserInfo();
  return [
    {
      id: v4(),
      href: '/theme',
      label: i18n.t('general.theme'),
      icon: 'theater-masks',
      isReactRouter: true,
      exact: true,
      type: '/theme',
    },
    {
      id: v4(),
      href: `/builder?shop=${shopName}&id=${PAGE_BUILD_HEADER_FOOTER}&entityVariant=Client`,
      label: 'Header & Footer',
      icon: 'globe-asia',
      isReactRouter: false,
      exact: true,
      type: '/header-footer',
    },
    {
      id: v4(),
      href: '/page',
      label: i18n.t('general.page', { textTransform: 'capitalize', text: '' }),
      icon: 'layer-plus',
      isReactRouter: true,
      exact: true,
      type: '/page',
    },
    /**
     * @deprecated
     * Theme v2.0 không cần tới preset styles
     */
    // {
    //   id: v4(),
    //   href: '/preset-styles',
    //   label: i18n.t('adminDashboard.styles'),
    //   icon: 'palette',
    //   isReactRouter: true,
    //   exact: true,
    //   type: 'style',
    // },
    {
      id: v4(),
      href: '/pricing',
      label: i18n.t('general.plan'),
      icon: 'star',
      isReactRouter: true,
      exact: true,
      type: 'pricing',
    },
    {
      id: v4(),
      href: '/apps',
      label: i18n.t('adminDashboard.apps'),
      icon: 'handshake',
      isReactRouter: true,
      exact: true,
      type: 'apps',
    },
    ...((!hideManager
      ? [
          {
            id: v4(),
            href: '/manager-page',
            label: i18n.t('general.manager'),
            icon: 'tasks',
            isReactRouter: true,
            exact: true,
            type: '/manager',
          },
        ]
      : []) as TypeRoute[]),
  ];
};

export const managerItems = (isDev: boolean): MenuItem[] => [
  {
    id: v4(),
    href: '/manager-page',
    label: i18n.t('adminDashboard.pages'),
    icon: 'layer-plus',
    isReactRouter: true,
    exact: true,
    textHeader: 'Pages',
  },
  {
    id: v4(),
    href: '/manager-page-category',
    label: i18n.t('general.category'),
    icon: 'list',
    isReactRouter: true,
    exact: true,
  },
  {
    id: v4(),
    href: '/manager-page-use-case',
    label: 'Use Cases',
    icon: 'list',
    isReactRouter: true,
    exact: true,
    hasDivider: true,
  },
  {
    id: v4(),
    href: '/manager-theme',
    label: i18n.t('adminDashboard.themes'),
    icon: 'theater-masks',
    isReactRouter: true,
    exact: true,
    textHeader: 'Themes',
  },
  {
    id: v4(),
    href: '/manager-theme-settings',
    label: i18n.t('adminDashboard.theme_settings_page'),
    icon: 'cogs',
    isReactRouter: true,
    exact: true,
  },
  {
    id: v4(),
    href: '/manager-theme-category',
    label: i18n.t('general.category'),
    icon: 'list',
    isReactRouter: true,
    exact: true,
    hasDivider: true,
  },
  {
    id: v4(),
    href: '/manager-sections-updatable',
    label: 'Các section có version mới',
    icon: 'layer-plus',
    isReactRouter: true,
    exact: true,
    hasDivider: true,
    textHeader: 'Nhật ký thay đổi',
  },
  ...((!isDev
    ? [
        {
          id: v4(),
          href: '/manager-users',
          label: 'Users',
          icon: 'users',
          isReactRouter: true,
          exact: true,
          textHeader: 'Users ',
        },
        {
          id: v4(),
          href: '/manager-partners',
          label: 'Partners',
          icon: 'hands-helping',
          isReactRouter: true,
          exact: true,
        },
        {
          id: v4(),
          href: '/manager-plan',
          label: 'Plans',
          icon: 'usd-circle',
          isReactRouter: true,
          exact: true,
        },
        {
          id: v4(),
          href: '/manager-general-settings',
          label: 'General Settings',
          icon: 'cog',
          isReactRouter: true,
          exact: true,
        },
      ]
    : []) as MenuItem[]),
];

export const adminTypeRoute: TypeRoute[] = [
  {
    id: v4(),
    href: '/notifications',
    label: '',
    icon: 'bell',
    isReactRouter: true,
    exact: true,
    type: 'notifications',
  },
  {
    id: v4(),
    href: '/account',
    label: '',
    icon: 'user',
    isReactRouter: true,
    exact: true,
    type: 'account',
  },
];
