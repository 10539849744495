import { PurchaseCode } from 'types/PurchaseCode';
import { createAsyncAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';

export const getPurchaseCodes = createAsyncAction([
  '@PurchaseCodePage/getPurchaseCodes/request',
  '@PurchaseCodePage/getPurchaseCodes/success',
  '@PurchaseCodePage/getPurchaseCodes/failure',
])<undefined, { data: PurchaseCode[]; message: string }, { message: string }>();

export const createWebsiteOfPurchaseCode = createAsyncAction([
  '@PurchaseCodePage/createWebsiteOfPurchaseCode/request',
  '@PurchaseCodePage/createWebsiteOfPurchaseCode/success',
  '@PurchaseCodePage/createWebsiteOfPurchaseCode/failure',
])<{ purchaseCode: string; website: string; onFulfill?: () => void }, { purchaseCode: string; website: string }, undefined>();

export const deletePurchaseCode = createAsyncAction([
  '@PurchaseCodePage/deletePurchaseCode/request',
  '@PurchaseCodePage/deletePurchaseCode/success',
  '@PurchaseCodePage/deletePurchaseCode/failure',
])<{ purchaseCode: string }, { purchaseCode: string }, { purchaseCode: string }>();

export const deleteWebsiteOfPurchaseCode = createAsyncAction([
  '@PurchaseCodePage/deleteWebsiteOfPurchaseCode/request',
  '@PurchaseCodePage/deleteWebsiteOfPurchaseCode/success',
  '@PurchaseCodePage/deleteWebsiteOfPurchaseCode/failure',
])<{ purchaseCode: string; website: string }, { purchaseCode: string; website: string }, { website: string }>();

export const useGetPurchaseCodes = createDispatchAsyncAction(getPurchaseCodes);
export const useDeletePurchaseCode = createDispatchAsyncAction(deletePurchaseCode);
export const useDeleteWebsiteOfPurchaseCode = createDispatchAsyncAction(deleteWebsiteOfPurchaseCode);
export const useCreateWebsiteOfPurchaseCode = createDispatchAsyncAction(createWebsiteOfPurchaseCode);
