import { clone, omit } from 'ramda';
import { PageSection, ProductSection } from 'types/Sections';
import { ColorSchemeVariableNameInLiquid, getDefaultSectionData, getDefaultSectionSchema, getDefaultSectionSettings } from './constants';
import { getUserInfo } from 'utils/functions/getUserInfo';

/* Biến đổi mảng sections của server trả về do thỉnh thoảng server sẽ trả thiếu trường */
export const adapterGetManySections = (atomResponse: PageSection[], productResponse?: ProductSection[]): PageSection[] => {
  return atomResponse.map<PageSection>(item => {
    const { role } = getUserInfo();
    // Check trường hợp nếu color_scheme có children null thì sửa mặc định là veda-color-default
    let section: PageSection = item;

    if (section.data) {
      section = {
        ...section,
        data: {
          ...section.data,
          settings: section.data.settings.map(setting => {
            if (setting.type === 'color_scheme' && setting.children == null) {
              return {
                ...setting,
                children: 'veda-color-default',
              };
            }
            return setting;
          }),
        },
      };
    }
    const isIncludeColorSchemeField = section.data && !!section.data.schema.settings.find(setting => setting.type === 'color_scheme');
    const defaultSectionSchema = getDefaultSectionSchema();
    const defaultSectionData = getDefaultSectionData();
    const defaultSectionSettings = getDefaultSectionSettings();
    // FIXME: Nhả khi mở cho client => Cần xem xét khi nhả nút này
    const liquid_ =
      role === 'admin' && !isIncludeColorSchemeField
        ? (section.data?.liquid ?? defaultSectionData.liquid).replace(/(<section([^>]+)>)/, openTag => {
            return openTag.replace(/class=('|")/, value => value.concat(`bgc:color-light c:color-gray7 {{ ${ColorSchemeVariableNameInLiquid} }} `));
          })
        : section.data?.liquid ?? defaultSectionData.liquid;
    return {
      // @tuong -> 2 trường này gây section trước và sau save khác nhau -> rerender
      ...omit(['modifiedDateTimestamp', 'createdDateTimestamp'], item),
      ...item,
      type: section.type ?? 'default',
      commandId: section.commandId,
      addonIds: section.addonIds ?? [],
      category: section.category ? section.category : { name: '', commandId: '', description: '' },
      image: section.image
        ? {
            src: section.image.src,
            width: section.image.width,
            height: section.image.height,
          }
        : undefined,
      data: section.data
        ? {
            ...section.data,
            liquid: liquid_,
            schema: !section.data?.schema
              ? clone(defaultSectionSchema)
              : // FIXME: Nhả khi mở cho client => Cần xem xét khi nhả nút này
              role === 'admin' && !isIncludeColorSchemeField
              ? {
                  blocks: section.data.schema.blocks,
                  settings: [...clone(defaultSectionSchema.settings), ...section.data.schema.settings],
                }
              : section.data.schema,
            settings: !section.data?.settings
              ? clone(defaultSectionSettings)
              : // FIXME: Nhả khi mở cho client => Cần xem xét khi nhả nút này
              role === 'admin' && !isIncludeColorSchemeField
              ? [...defaultSectionSettings, ...section.data.settings]
              : section.data.settings,
          }
        : defaultSectionData,
      enable: section.enable ? section.enable : true,
      vedaProductRepresent: productResponse?.find(productSection => productSection.parentCommandId === section.commandId),
    } as PageSection;
  });
};
