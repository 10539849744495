import { AxiosResponse } from 'axios';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface Params {
  chargeId: string;
  coupon: string;
  affiliateId?: string;
}

interface ResponseSuccess {
  info: {
    confirmation_url: string;
  };
  message: string;
  status: string;
}

export const verifyAfterUpdatePlan = async ({ chargeId, coupon, affiliateId }: Params) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${baseUrl}/me/plans/subscriptions/${chargeId}`,
    method: 'PUT',
    data: {
      couponApplied: coupon !== '' ? coupon : undefined,
      affiliateId,
    },
  });
  return response.data;
};
