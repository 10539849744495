import {
  ErrorOption,
  getAllJsOfPage,
  getAllJsOfTheme,
  getCssFromAddonScss,
  getCssFromSectionsScss_PageScss_SectionsInlinesCss,
  getCssFromThemeSettings,
  getHtmlFilesOfAddon,
  getHtmlFilesOfPage,
  getHtmlFilesOfTheme,
  getJsOfAddon,
  getVendorsOfPage,
  getVendorsOfTheme,
} from 'generate/utils/getFilesForSave';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { defaultGeneralSettings } from 'store/global/generalSettings/slice';
import { defaultVendors } from 'store/reducers/reducerVendors';
import {
  allGeneralSettingsSelector,
  allGlobalJsSelector,
  allGlobalScssSelector,
  allVendorsSelector,
  pagesSelector,
  themeAddonsSelector,
  themeHeaderFooterSelector,
  themeSettingsSelector,
  themeVersionSelector,
} from 'store/selectors';
import { Page } from 'types/Page';
import { PageData, Result, ThemeTranslationsResponse } from 'types/Result';
import getPageInfo from 'utils/functions/getInfo';
import { pmParent } from 'utils/functions/postMessage';
import { isThemeBuilder } from 'utils/validateBuilderMode';
import { getAddonSections } from './utils/getAddonSections';
import { getEnableSections } from './utils/getEnableSections';
import { getHeaderFooterSections } from './utils/getHeaderFooterSections';
import { getMainSections } from './utils/getMainSections';
import { getMegamenuSections } from './utils/getMegamenuSections';

const useResultForSave = () => {
  const pages = useSelector(pagesSelector);
  const allGeneralSettings = useSelector(allGeneralSettingsSelector);
  const allVendors = useSelector(allVendorsSelector);
  const allGlobalScss = useSelector(allGlobalScssSelector);
  const allGlobalJs = useSelector(allGlobalJsSelector);
  const themeAddons = useSelector(themeAddonsSelector);
  const {
    globalJs: globalJsOfTheme,
    globalScss: globalScssOfTheme,
    vendors: vendorsOfTheme,
    layoutSettings,
    cssVariables,
    generalSettings: themeGeneralSettings,
    globalTranslations,
    colorSchemes: { colorSchemes },
  } = useSelector(themeSettingsSelector);
  const { footers, headers } = useSelector(themeHeaderFooterSelector);
  const themeVersion = useSelector(themeVersionSelector);

  const themeId = getPageInfo('themeId');
  const [isExtracting, setIsExtracting] = useState(false);

  const getScreenshot = async (pageId: string): Promise<Page['image']> => {
    if (isThemeBuilder()) {
      return Promise.resolve({ src: '', height: 0, width: 0 });
    }
    return new Promise(resolve => {
      const pmOff = pmParent.on('@screenshot/fulfill', ({ image, uniqId }) => {
        if (uniqId === pageId) {
          pmOff();
          resolve(image);
        }
      });
      pmParent.emit('@screenshot/request', { uniqId: pageId });
    });
  };

  /**
   * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
   */
  // const getResultOfAtomicCss = (_: string): Promise<Result['pages'][string]['atomicCss']> => {
  //   return Promise.resolve<Result['pages'][string]['atomicCss']>('');
  //   // return new Promise(resolve => {
  //   //   const pmOff = pmParent.on('@atomicCss/fulfill', ({ uniqId, atomicCss }) => {
  //   //     if (uniqId === pageId) {
  //   //       pmOff();
  //   //       resolve(atomicCss);
  //   //     }
  //   //   });
  //   //   pmParent.emit('@atomicCss/request', { uniqId: pageId });
  //   // });
  // };

  // Trường hợp builderType='page' -> header và footer như 1 section bình thường
  const getResultOfPages = async (entityVariant: EntityType, errorOption: ErrorOption): Promise<Result['pages']> => {
    const resultInArray = await Promise.all(
      Object.entries(pages.data).map(async ([pageId, page]) => {
        const vendors = allVendors[pageId] ?? defaultVendors;
        const globalScss = allGlobalScss[pageId] ?? '';
        const globalJs = allGlobalJs[pageId] ?? '';
        const generalSettings = allGeneralSettings[pageId] ?? defaultGeneralSettings;
        const megamenuSections = getMegamenuSections(page.sections);
        const enableMegamenuSections = getEnableSections(megamenuSections);
        const mainSections = getMainSections(page.sections);
        const sectionsEnable_notIncludeAddonSections_includeMegamenuSections = getEnableSections(mainSections).concat(enableMegamenuSections);

        /**
         * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
         */
        // const atomicCss = await getResultOfAtomicCss(pageId);
        const htmlFiles = [
          ...getHtmlFilesOfPage({
            sectionsEnable_notIncludeAddonSections_includeMegamenuSections,
            errorOption,
          }),
        ];
        const js = getAllJsOfPage({
          variant: 'Toàn bộ page - bao gồm các sections, page settings',
          globalJs,
          sectionsEnable_notIncludeAddonSections_includeMegamenuSections,
        });
        const css = await getCssFromSectionsScss_PageScss_SectionsInlinesCss({
          variant: 'Toàn bộ page - bao gồm các sections, page settings',
          globalScss,
          sectionsEnable_notIncludeAddonSections_includeMegamenuSections,

          /**
           * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
           */
          // atomicCssForAdminNDev: entityVariant === 'Atom' || entityVariant === 'Draft' ? atomicCss : '',
          atomicCssForAdminNDev: '',
        });

        const pageScreenshot = entityVariant === 'Client' ? page.image : await getScreenshot(pageId);

        const result: PageData = {
          data: {
            page: {
              ...page,
              sections: mainSections,
              image: pageScreenshot,
            },
            pageSettings: {
              generalSettings,
              vendors,
              globalScss,
              globalJs,
            },
          },
          files: [...getVendorsOfPage({ vendors }), ...css, ...js, ...htmlFiles],

          /**
           * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
           */
          // atomicCss,
        };
        return result;
      }),
    );

    // @ts-ignore
    return resultInArray.reduce<Result['pages']>(
      (obj, item) => ({
        ...obj,
        ...(!!item.data.page.sections.length ? { [item.data.page.commandId]: item } : {}),
      }),
      {},
    );
  };

  const getResultOfTheme = async (entityVariant: EntityType, errorOption: ErrorOption): Promise<Result['theme']> => {
    const pageId = getPageInfo('id');

    /**
     * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
     */
    // const atomicCssForAdminNDev = entityVariant === 'Atom' || entityVariant === 'Draft' ? await getResultOfAtomicCss(pageId) : '';
    const atomicCssForAdminNDev = '';
    if (isThemeBuilder()) {
      const pageData = pages.data[pageId];
      const { headerSections, footerSections } = getHeaderFooterSections(pageData.sections);
      const megamenuSections = getMegamenuSections(pageData.sections);
      const enableMegamenuSections = getMegamenuSections(megamenuSections);
      const headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections = getEnableSections(headerSections).concat(enableMegamenuSections);
      const footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections = getEnableSections(footerSections).concat(enableMegamenuSections);
      const js = getAllJsOfTheme({
        variant: 'Toàn bộ theme - bao gồm header, footer, theme settings',
        headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
        footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
        globalJs: globalJsOfTheme,
        themeGeneralSettings,
      });
      const [htmlFilesInPreloader, ...htmlFilesInHeader] = getHtmlFilesOfTheme({
        headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
        footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: [],
        themeGeneralSettings,
        errorOption,
        variant: 'Toàn bộ theme - bao gồm header, footer, theme settings',
      });
      const [, ...htmlFilesInFooter] = getHtmlFilesOfTheme({
        headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: [],
        footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
        themeGeneralSettings,
        errorOption,
        variant: 'Toàn bộ theme - bao gồm header, footer, theme settings',
      });
      const css = await getCssFromThemeSettings({
        headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
        footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
        globalScss: globalScssOfTheme,
        themeGeneralSettings,
        variant: 'Toàn bộ theme - bao gồm header, footer, theme settings',
        target: 'Trích xuất kết quả sync lên shopify hoặc Lấy css để hiển thị trong builder',
        atomicCssForAdminNDev,
      });
      const vendors = getVendorsOfTheme({
        vendors: vendorsOfTheme,
        themeGeneralSettings,
        cssVariables,
        layout: layoutSettings,
        target: 'Trích xuất kết quả sync lên shopify hoặc Lấy css để hiển thị trong builder',
        colorSchemes,
        themeVersion,
      });
      return {
        themeSettings: {
          generalSettings: themeGeneralSettings,
          cssVariables,
          layoutSettings,
          globalTranslations: globalTranslations as ThemeTranslationsResponse,
          colorSchemes,
        },
        addons: themeAddons.data,
        header: headerSections,
        footer: footerSections,
        themeId: themeId,
        themeName: '',
        vendors: vendorsOfTheme,
        globalJs: globalJsOfTheme,
        globalScss: globalScssOfTheme,
        files: [...js, ...css, ...htmlFilesInHeader, ...htmlFilesInFooter, htmlFilesInPreloader, ...vendors],
      };
    } else {
      const pageData = pages.data[pageId];
      const { headerSections, footerSections } = getHeaderFooterSections(pageData.sections);
      const megamenuSections = getMegamenuSections(pageData.sections);
      const enableMegamenuSections = getMegamenuSections(megamenuSections);
      const headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections = getEnableSections(headerSections).concat(enableMegamenuSections);
      const footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections = getEnableSections(footerSections).concat(enableMegamenuSections);

      const js = getAllJsOfTheme({
        variant: 'Toàn bộ theme - bao gồm header, footer, theme settings',
        headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
        footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
        globalJs: globalJsOfTheme,
        themeGeneralSettings,
      });
      const [htmlFilesInPreloader, ...htmlFilesInHeader] = getHtmlFilesOfTheme({
        headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
        footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: [],
        themeGeneralSettings,
        errorOption,
        variant: 'Toàn bộ theme - bao gồm header, footer, theme settings',
      });
      const [, ...htmlFilesInFooter] = getHtmlFilesOfTheme({
        headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: [],
        footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
        themeGeneralSettings,
        errorOption,
        variant: 'Toàn bộ theme - bao gồm header, footer, theme settings',
      });
      const css = await getCssFromThemeSettings({
        headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
        footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
        globalScss: globalScssOfTheme,
        themeGeneralSettings,
        variant: 'Toàn bộ theme - bao gồm header, footer, theme settings',
        target: 'Trích xuất kết quả sync lên shopify hoặc Lấy css để hiển thị trong builder',
        atomicCssForAdminNDev,
      });

      const vendors = getVendorsOfTheme({
        vendors: vendorsOfTheme,
        themeGeneralSettings,
        cssVariables,
        layout: layoutSettings,
        target: 'Trích xuất kết quả sync lên shopify hoặc Lấy css để hiển thị trong builder',
        colorSchemes,
        themeVersion,
      });
      return {
        themeSettings: {
          generalSettings: themeGeneralSettings,
          cssVariables,
          layoutSettings,
          globalTranslations: globalTranslations as ThemeTranslationsResponse,
          colorSchemes,
        },
        addons: themeAddons.data,
        header: headers,
        footer: footers,
        themeId: themeId,
        vendors: vendorsOfTheme,
        globalJs: globalJsOfTheme,
        globalScss: globalScssOfTheme,
        files: [...js, ...css, ...htmlFilesInHeader, ...htmlFilesInFooter, htmlFilesInPreloader, ...vendors],
        themeName: '',
      };
    }
  };

  const getResultOfAddon = async (errorOption: ErrorOption): Promise<Result['filesOfAddons']> => {
    const pageId = getPageInfo('id');
    const page = pages.data[pageId];
    const { sections } = page;
    const enableAddonSections = getEnableSections(getAddonSections(sections));
    const addonFilesOfPage = await Promise.all(
      enableAddonSections.map(async addonSection => {
        const isEnablePosition = themeAddons.data.find(addon => addon.sectionId === addonSection.id)?.positionEnabled;
        const [addonHtmlFile] = getHtmlFilesOfAddon({ addonSection, errorOption });
        const cssFile = await getCssFromAddonScss({ addonSection });
        const jsFile = getJsOfAddon({ addonSection });
        return {
          css: cssFile,
          js: jsFile,
          liquid: addonHtmlFile,
          id: addonSection.id,
          type: isEnablePosition ? 'addon enable position' : 'addon disable position',
        } as Result['filesOfAddons'][number];
      }),
    );
    const addons = addonFilesOfPage.flat();
    return Promise.resolve(addons.flat());
  };

  const getResult = async ({ entityVariant, errorOption }: { entityVariant: EntityType; errorOption: ErrorOption }): Promise<Result> => {
    setIsExtracting(true);
    try {
      const result: Result = {
        builderType: isThemeBuilder() ? 'theme' : 'page',
        pages: await getResultOfPages(entityVariant, errorOption),
        theme: await getResultOfTheme(entityVariant, errorOption),
        filesOfAddons: await getResultOfAddon(errorOption),
      };
      return result;
    } finally {
      setIsExtracting(false);
    }
  };

  return { getResult, isExtracting };
};

export default useResultForSave;
