import { imageUrl } from '@wiloke/functions';
import { AsyncComponent, Confirm } from '@wiloke/ui';
import { notification, Tooltip } from 'antd';
import Field from 'components/Field';
import FieldBox from 'components/FieldBox';
import Radio from 'components/Radio';
import SectionCard from 'components/SectionCard';
import SwitchBeauty from 'components/SwitchBeauty';
import TextInput from 'components/TextInput';
import { ButtonAction } from 'containers/Admin/Management/Pages/components/ButtonAction';
import { useDeleteTemplatePage } from 'containers/Admin/PageBuilder/TemplatesPage';
import { useGetShopifyPageTemplate } from 'containers/Admin/PageBuilder/TemplatesPage/utils/useGetShopifyPageTemplate';
import { CreateCollectionPicker, CreateProductPicker } from 'containers/Shopify/ModalCreateShopifyPicker';
import { CreateArticlePicker } from 'containers/Shopify/ModalCreateShopifyPicker/CreateArticlePicker';
import { useSettingsShopifyPicker } from 'containers/Shopify/ModalCreateShopifyPicker/slice';
import withDebounce from 'hocs/withDebounce';
import { useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AdminPageDatabase } from 'services/ResultService/atomTypes';
import { pageBuilderSelector } from 'store/selectors';
import { i18n } from 'translation';
import { PageType } from 'types/Page';
import { at } from 'utils/at';
import { getShopName, getUserInfo } from 'utils/functions/getUserInfo';
import { ActivityIndicator, GridSmart, Space, Text, View, ViewportTracking } from 'wiloke-react-core';
import { useTrackClickAction } from './hooks/useTrackClickAction';
import { ModalControlSections } from './ModalControlSections';
import { ModalHotfixAtom } from './ModalHotfixAtom';
import { ModalPublishPage } from './ModalPublishPage';
import {
  useDeletePageAtom,
  useDisableShopifyPageAtom,
  useEnableShopifyPageAtom,
  useGetPagesAtom,
  useLoadMorePagesAtom,
} from './store/actions/actionPagesAtom';
import { useForkPageAtom } from './store/actions/actionPagesDraft';
import {
  pagesAtomSelector,
  useModalControlSections,
  useModalHotfixPage,
  useModalPublishPage,
  useSetSearchKeyAdminPage,
} from './store/reducers/slicePagesAtom';
import { pagesDraftSelector } from './store/reducers/slicePagesDraft';
import * as styles from './styles';

const DebounceInput = withDebounce(TextInput, 'value', 'onValueChange', 500);

export const AdminPage = () => {
  const [currentPage, setCurrentPage] = useState<AdminPageDatabase | undefined>(undefined);

  const { data, hasNextPage, getStatus, loadMoreStatus, queueDeleting, queueHotfixing, queueDisabling, queueEnabling, searchKey } = useSelector(
    pagesAtomSelector,
  );
  const {
    allTemplates: { deleteStatus },
  } = useSelector(pageBuilderSelector.templates);

  const { queueForking } = useSelector(pagesDraftSelector);
  const { shopName, role } = getUserInfo();

  const history = useHistory();
  const getPagesAtom = useGetPagesAtom();
  const loadmorePagesAtom = useLoadMorePagesAtom();
  const deletePageAtom = useDeletePageAtom();
  const forkPageAtom = useForkPageAtom();
  const enableShopifyPageAtom = useEnableShopifyPageAtom();
  const disableShopifyPageAtom = useDisableShopifyPageAtom();
  const deleteTemplatePage = useDeleteTemplatePage();

  const setModalPublishPage = useModalPublishPage();
  const setModalHotfixPage = useModalHotfixPage();
  const setModalControlSections = useModalControlSections();
  const changeSettingsShopify = useSettingsShopifyPicker();
  const setSearchKeyAdminPage = useSetSearchKeyAdminPage();

  const { getShopifyPages, getShopifyPresentPage } = useGetShopifyPageTemplate();

  const { connect, disconnect, statusSocketConnection } = useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService('sync_shopify');

  const { get, push } = useTrackClickAction();
  const [isSticky, setIsSticky] = useState(false);
  const [pageStatus, setPageStatus] = useState<'all' | 'published' | 'unpublished'>('all');

  useEffect(() => {
    getPagesAtom.request({
      searchKey,
      stickyOnly: isSticky,
      syncedToProduct: pageStatus === 'all' ? null : pageStatus === 'published' ? true : false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, isSticky, pageStatus]);

  const handleCancel = () => {
    changeSettingsShopify({
      visibleProduct: false,
      slugsProduct: [],
      visibleCollection: false,
      slugsCollection: [],
      visibleArticle: false,
      slugArticle: [],
    });
    setCurrentPage(undefined);
  };

  const handleOk = () => {
    if (currentPage) {
      changeSettingsShopify({
        visibleProduct: false,
        slugsProduct: [],
        visibleCollection: false,
        slugsCollection: [],
        visibleArticle: false,
        slugArticle: [],
      });
      history.push(`/builder?shop=${shopName}&id=${currentPage.commandId}&entityVariant=Atom`, {
        headerFooterEnabled: currentPage.pageSettings ? currentPage.pageSettings.generalSettings.headerFooterEnabled : false,
        label: currentPage.label,
        type: currentPage.type,
        backToPage: '/manager-page',
        isCreate: false,
        entityVariant: 'Atom',
        shopifyRepresentPage: getShopifyPresentPage(currentPage.type),
        shopifyPages: getShopifyPages(currentPage.type),
      });
      push({ commandId: currentPage.commandId, label: currentPage.label });
    }
  };

  const handlePreview = (page: AdminPageDatabase) => () => {
    if (page.type === 'article') {
      setCurrentPage(page);
      changeSettingsShopify({
        visibleArticle: true,
      });
    } else if (page.type === 'product') {
      setCurrentPage(page);
      changeSettingsShopify({
        visibleProduct: true,
      });
    } else if (page.type === 'collection') {
      setCurrentPage(page);
      changeSettingsShopify({
        visibleCollection: true,
      });
    } else {
      const url = `${window.location.origin}/builder?shop=${shopName}&id=${page.commandId}&entityVariant=Atom`;
      window.open(url, '_blank');
      push({ commandId: page.commandId, label: page.label });
    }
  };

  const handlePreviewWithApplyAll = (page: AdminPageDatabase) => {
    const url = `${window.location.origin}/builder?shop=${shopName}&id=${page.commandId}&entityVariant=Atom`;
    window.open(url, '_blank');
    push({ commandId: page.commandId, label: page.label });
  };

  const handleDeleteProductPage = ({ commandId }: { commandId: string }) => () => {
    if (deleteStatus[commandId] === 'success') {
      notification.error({
        message: 'This page was deleted',
      });
    } else {
      deleteTemplatePage.request({ commandId: commandId });
    }
  };

  const handleDeleteAtomPage = (pageAtom: AdminPageDatabase) => () => {
    connect({
      onSuccess: () => {
        disableShopifyPageAtom.request({
          commandId: pageAtom.commandId,
          data: pageAtom,
          onFulfill: () => disconnect({}),
        });
      },
      onError: () => {
        notification.error({
          message: i18n.t('publish_shopify.init_sync_error.message'),
          description: i18n.t('publish_shopify.init_sync_error.description'),
        });
      },
    });
  };

  const handleDeleteShopify = ({ label, type, commandId }: { label: string; type: PageType; commandId: string }) => () => {
    connect({
      onSuccess: () => {
        deletePageAtom.request({
          commandId,
          name: label,
          type,
          onlyShopify: true,
          onFulfill: () => disconnect({}),
        });
      },
      onError: () => {
        notification.error({
          message: i18n.t('publish_shopify.init_sync_error.message'),
          description: i18n.t('publish_shopify.init_sync_error.description'),
        });
      },
    });
  };

  const handleEnableShopify = (pageAtom: AdminPageDatabase) => () => {
    connect({
      onSuccess: () => {
        enableShopifyPageAtom.request({
          commandId: pageAtom.commandId,
          data: pageAtom,
          onFulfill: () => disconnect({}),
        });
      },
      onError: () => {
        notification.error({
          message: i18n.t('publish_shopify.init_sync_error.message'),
          description: i18n.t('publish_shopify.init_sync_error.description'),
        });
      },
    });
  };

  const handleOpenModalPublishPage = (pageAtom: AdminPageDatabase) => () => {
    setModalPublishPage(pageAtom);
  };

  const Actions = (item: typeof data[number]) => {
    const { commandId, label, type, vedaProductRepresent } = item;
    if (role === 'admin') {
      return (
        <View css={{ display: 'flex', padding: '0', flexWrap: 'wrap', gap: '4px' }}>
          <ButtonAction color="light" backgroundColor="secondary" onClick={handleOpenModalPublishPage(item)}>
            {vedaProductRepresent ? i18n.t('general.published') : i18n.t('general.publish')}
          </ButtonAction>

          <ButtonAction color="light" backgroundColor="github" onClick={() => setModalControlSections(item)}>
            Upgrade version
          </ButtonAction>

          <ButtonAction color="light" backgroundColor="linkedin" onClick={handleDeleteShopify({ commandId, label, type })}>
            {i18n.t('general.delete', { text: 'shopify' })}
          </ButtonAction>

          <ButtonAction color="light" backgroundColor="behance" onClick={handleEnableShopify(item)}>
            {i18n.t('general.enable', { text: 'shopify' })}
          </ButtonAction>

          <ButtonAction backgroundColor="digg" color="light" onClick={handleDeleteAtomPage(item)}>
            {i18n.t('general.disable', { text: 'shopify' })}
          </ButtonAction>

          <View
            color="light"
            fontFamily="secondary"
            backgroundColor="linkedin"
            css={styles.rightItem}
            onClick={() => {
              setModalHotfixPage(item);
            }}
          >
            {i18n.t('general.hotfix')}
          </View>

          <Confirm
            title="Delete confirm"
            isLoading={
              queueDeleting.includes(commandId) ||
              statusSocketConnection === 'loading' ||
              deleteStatus[vedaProductRepresent?.commandId ?? ''] === 'loading'
            }
            okText="Confirm"
            message={
              <View>
                <View>
                  You want delete <View tagName="strong">{label}</View>
                </View>

                {vedaProductRepresent && (
                  <>
                    <Space size={8} />
                    <View borderWidth={1} borderStyle="solid" borderColor="gray3" radius={4} css={{ padding: '4px' }}>
                      Bạn có muốn xóa luôn page đã publish không?
                      <View
                        css={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <View tagName="strong" fontFamily="secondary">
                          {vedaProductRepresent.label}
                        </View>
                        <View
                          color="light"
                          fontFamily="secondary"
                          backgroundColor="danger"
                          css={styles.rightItem}
                          onClick={handleDeleteProductPage({ commandId: vedaProductRepresent.commandId })}
                        >
                          Delete
                        </View>
                      </View>
                    </View>
                  </>
                )}
              </View>
            }
            onOk={onCancel => {
              connect({
                onSuccess: () => {
                  deletePageAtom.request({
                    commandId,
                    name: label,
                    type,
                    onlyShopify: false,
                    onFulfill: () => {
                      disconnect({});
                      onCancel();
                    },
                  });
                },
                onError: () => {
                  notification.error({
                    message: i18n.t('publish_shopify.init_sync_error.message'),
                    description: i18n.t('publish_shopify.init_sync_error.description'),
                  });
                },
              });
            }}
          >
            <View color="light" fontFamily="secondary" backgroundColor="danger" css={styles.rightItem}>
              {i18n.t('general.delete')}
            </View>
          </Confirm>

          <View
            color="light"
            fontFamily="secondary"
            backgroundColor="dark"
            css={styles.rightItem}
            onClick={() => {
              handlePreviewWithApplyAll(item);
            }}
          >
            {i18n.t('general.apply_all')}
          </View>
        </View>
      );
    }
    if (role === 'dev') {
      return (
        <View css={{ display: 'flex', padding: '0' }}>
          <View
            color="light"
            fontFamily="secondary"
            backgroundColor="primary"
            css={styles.rightItem}
            onClick={() => {
              forkPageAtom.request({
                commandId,
                callback: (commandId, label) => {
                  history.push(`/builder?shop=${getShopName()}&id=${commandId}&entityVariant=Draft`, {
                    entityVariant: 'Draft',
                    backToPage: '/manager-page',
                    label,
                    isCreate: false,
                  });
                  push({ commandId, label });
                },
              });
            }}
          >
            {i18n.t('general.fork')}
          </View>
        </View>
      );
    }
    return null;
  };

  const TrackingLoadmore = useMemo(() => {
    const cursor = at(data, -1)?.commandId;
    if (hasNextPage && cursor) {
      return (
        <ViewportTracking
          onEnterViewport={() => {
            if (loadMoreStatus !== 'loading') {
              loadmorePagesAtom.request({
                cursor,
                searchKey,
                stickyOnly: isSticky,
                syncedToProduct: pageStatus === 'all' ? null : pageStatus === 'published' ? true : false,
              });
            }
          }}
        >
          <View css={{ display: 'flex', justifyContent: 'center', marginTop: '14px' }}>
            <ActivityIndicator size={40} />
          </View>
        </ViewportTracking>
      );
    }
    return null;
  }, [data, hasNextPage, loadMoreStatus, loadmorePagesAtom, searchKey, isSticky, pageStatus]);

  return (
    <FieldBox radius={6} borderColor="gray2" css={{ padding: '20px 10px' }}>
      <View css={{ display: 'flex', gap: '15px', flexWrap: 'wrap' }}>
        <Field css={{ width: '300px' }} label={`${i18n.t('adminDashboard.search')}`}>
          <DebounceInput
            value={searchKey}
            placeholder={i18n.t('adminDashboard.search', { text: i18n.t('general.page') })}
            block
            onValueChange={setSearchKeyAdminPage}
          />
        </Field>

        <Field label="Sticky" css={{ width: '150px' }}>
          <SwitchBeauty checked={isSticky} onValueChange={setIsSticky} />
        </Field>

        <Field css={{ width: '300px' }} label="Page Status">
          <Radio.Group
            block
            size="large"
            value={pageStatus}
            onChangeValue={val => {
              setPageStatus(val as any);
            }}
          >
            <Radio.Button key="all" value={'all'}>
              All
            </Radio.Button>
            <Radio.Button key="published" value={'published'}>
              Published
            </Radio.Button>
            <Radio.Button key="unpublished" value={'unpublished'}>
              Unpublished
            </Radio.Button>
          </Radio.Group>
        </Field>
      </View>

      <AsyncComponent
        status={getStatus}
        isEmpty={data.length === 0}
        Success={
          <View>
            <GridSmart columnCount={4} columnWidth={300}>
              {data.map(item => {
                const listVisited = get();
                const orderVisited = listVisited.findIndex(itemVisited => itemVisited.commandId === item.commandId);

                return (
                  <SectionCard
                    key={item.commandId}
                    title={
                      <View css={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        <View css={{ marginRight: '4px' }} tagName="span">
                          {item.label}
                        </View>
                        {orderVisited !== -1 && (
                          <Tooltip
                            // defaultVisible={listVisited.length === 1}
                            overlayInnerStyle={{ width: 600, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
                            title={
                              <View onClick={e => e.stopPropagation()}>
                                <View css={{ fontSize: '20px', fontWeight: 500 }}>Bạn đã từng truy cập page này trong phiên thao tác</View>
                                <View>
                                  Tại sao lại có cái này:
                                  <View tagName="ul">
                                    <View tagName="li">Giảm được phần nào kịch bản "Để người sửa page biết đã sửa những page nào nếu quên"</View>
                                    <View tagName="li">
                                      Trong quá trình save lại page có thể lơ đễnh và quên mất là đã thao tác với những page nào rồi. Từ đó có thể gây
                                      ra cho người thực hiện "sự nghi ngờ", "sự khó chịu khi dùng app quá sida", ...
                                    </View>
                                  </View>
                                </View>
                                <View>
                                  Danh sách page đã truy cập trước đó:
                                  <View tagName="ul">
                                    {listVisited.map(({ commandId, label }) => (
                                      <View tagName="li" key={commandId}>
                                        {label}
                                      </View>
                                    ))}
                                  </View>
                                </View>
                              </View>
                            }
                          >
                            <Text size={12} color="light" css={{ padding: '4px 6px', borderRadius: '6px' }} backgroundColor="warning">
                              Đã từng truy cập
                            </Text>
                          </Tooltip>
                        )}
                      </View>
                    }
                    image={imageUrl(item.image.src, 300)}
                    type={
                      !!item.vedaProductRepresent?.types && item.vedaProductRepresent.types.length > 0
                        ? item?.vedaProductRepresent?.types.join(', ')
                        : item.type
                    }
                    loading={
                      queueDeleting.includes(item.commandId) ||
                      queueForking.includes(item.commandId) ||
                      queueHotfixing.includes(item.commandId) ||
                      queueDisabling.includes(item.commandId) ||
                      queueEnabling.includes(item.commandId) ||
                      statusSocketConnection === 'loading'
                    }
                    byText={<div />}
                    author={<div />}
                    date={
                      item.vedaProductRepresent?.label && (
                        <View backgroundColor="gray2" color="tertiary" fontFamily="secondary" css={styles.badge}>
                          ({item.vedaProductRepresent?.label})
                        </View>
                      )
                    }
                    Right={Actions(item)}
                    onClick={handlePreview(item)}
                    backgroundSize="cover"
                  />
                );
              })}
            </GridSmart>
            {TrackingLoadmore}
          </View>
        }
      />

      <ModalPublishPage />
      <ModalHotfixAtom />
      <ModalControlSections />

      <CreateProductPicker onCancel={handleCancel} onOk={handleOk} />
      <CreateCollectionPicker onCancel={handleCancel} onOk={handleOk} />
      <CreateArticlePicker onCancel={handleCancel} onOk={handleOk} />
    </FieldBox>
  );
};
