import { FC } from 'react';
import { CssColorVariable } from 'types/PresetStyles';
import { View } from 'wiloke-react-core';
import * as styles from './styles';

export const Colors: FC<{ colors: CssColorVariable[] }> = ({ colors }) => {
  return (
    <View css={styles.colors} style={{ backgroundColor: colors[colors.length - 1].light }}>
      <View css={styles.colorText} style={{ color: colors[4].light }}>
        Aa
      </View>
      <View css={styles.colorsInner}>
        <View css={styles.color} style={{ backgroundColor: colors[0].light }}></View>
        <View css={styles.color} style={{ backgroundColor: colors[1].light }}></View>
        <View css={styles.color} style={{ backgroundColor: colors[2].light }}></View>
        <View css={styles.color} style={{ backgroundColor: colors[3].light }}></View>
      </View>
    </View>
  );
};
