import { call, delay } from 'redux-saga/effects';
import { handleSaveInBuilderPage } from 'services/ShopifyConnection';
import { syncPage, SyncPage, SyncPageResult } from './syncPage';

export interface SyncPagesInThemeBuilderMode extends Pick<SyncPage, 'signalToReplaceAddonInLiquidCode'> {
  pagesParams: ReturnType<typeof handleSaveInBuilderPage>['pagesParams'];
}

export interface SyncPagesInThemeBuilderModeResult {
  statusSyncPagesInThemeBuilderMode: SyncFulfillStatus;
  errorId: string | undefined;
  isNeedIgnoreReportError: boolean;
}

export function* syncPagesInThemeBuilderMode({ pagesParams, signalToReplaceAddonInLiquidCode }: SyncPagesInThemeBuilderMode) {
  for (const pageParams of pagesParams) {
    if (pageParams.pageType === 'giftCard') {
      yield delay(1000);
    } else {
      const { statusSyncPage, errorId, isNeedIgnoreReportError }: SyncPageResult = yield call(syncPage, {
        pageParams,
        signalToReplaceAddonInLiquidCode,
      });
      if (statusSyncPage !== 'success') {
        return { statusSyncPagesInThemeBuilderMode: 'failure', errorId, isNeedIgnoreReportError } as SyncPagesInThemeBuilderModeResult;
      }
    }
  }
  return { statusSyncPagesInThemeBuilderMode: 'success', errorId: undefined } as SyncPagesInThemeBuilderModeResult;
}
