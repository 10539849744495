export const linkFonts: Record<string, string> = {
  'VedaFont[Work Sans]+work_sans_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Work_Sans_work_sans_n5_normal_500.woff2?v=1684753683',
  'VedaFont[Volkhov]+volkhov_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Volkhov_volkhov_n4_normal_400.woff2?v=1684753683',
  'VedaFont[Ysobel]+ysobel_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Ysobel_ysobel_n4_normal_400.woff2?v=1684753683',
  'VedaFont[Ysobel]+ysobel_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Ysobel_ysobel_i6_italic_600.woff2?v=1684753683',
  'VedaFont[Zurich]+zurich_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Zurich_zurich_i7_italic_700.woff2?v=1684753683',
  'VedaFont[Ysobel]+ysobel_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Ysobel_ysobel_i4_italic_400.woff2?v=1684753683',
  'VedaFont[Ysobel]+ysobel_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Ysobel_ysobel_i7_italic_700.woff2?v=1684753683',
  'VedaFont[Zurich]+zurich_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Zurich_zurich_n8_normal_800.woff2?v=1684753683',
  'VedaFont[Vollkorn]+vollkorn_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Vollkorn_vollkorn_i4_italic_400.woff2?v=1684753683',
  'VedaFont[Unna]+unna_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Unna_unna_i7_italic_700.woff2?v=1684753683',
  'VedaFont[Vidaloka]+vidaloka_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Vidaloka_vidaloka_n4_normal_400.woff2?v=1684753683',
  'VedaFont[Work Sans]+work_sans_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Work_Sans_work_sans_n3_normal_300.woff2?v=1684753683',
  'VedaFont[Zurich]+zurich_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Zurich_zurich_n7_normal_700.woff2?v=1684753683',
  'VedaFont[Ysobel]+ysobel_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Ysobel_ysobel_i3_italic_300.woff2?v=1684753683',
  'VedaFont[Zurich]+zurich_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Zurich_zurich_n9_normal_900.woff2?v=1684753683',
  'VedaFont[Zurich]+zurich_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Zurich_zurich_n4_normal_400.woff2?v=1684753683',
  'VedaFont[Ysobel]+ysobel_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Ysobel_ysobel_n6_normal_600.woff2?v=1684753683',
  'VedaFont[Work Sans]+work_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Work_Sans_work_sans_n7_normal_700.woff2?v=1684753683',
  'VedaFont[Zurich]+zurich_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Zurich_zurich_n3_normal_300.woff2?v=1684753683',
  'VedaFont[Work Sans]+work_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Work_Sans_work_sans_n4_normal_400.woff2?v=1684753683',
  'VedaFont[Vollkorn]+vollkorn_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Vollkorn_vollkorn_i6_italic_600.woff2?v=1684753683',
  'VedaFont[Vollkorn]+vollkorn_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Vollkorn_vollkorn_i7_italic_700.woff2?v=1684753683',
  'VedaFont[Work Sans]+work_sans_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Work_Sans_work_sans_n2_normal_200.woff2?v=1684753683',
  'VedaFont[Zurich Extended]+zurich_extended_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Zurich_Extended_zurich_extended_n4_normal_400.woff2?v=1684753683',
  'VedaFont[Zurich]+zurich_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Zurich_zurich_i8_italic_800.woff2?v=1684753683',
  'VedaFont[Verdana Pro]+verdana_pro_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Verdana_Pro_verdana_pro_i9_italic_900.woff2?v=1684753683',
  'VedaFont[Ysobel]+ysobel_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Ysobel_ysobel_n7_normal_700.woff2?v=1684753683',
  'VedaFont[Zurich]+zurich_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Zurich_zurich_i3_italic_300.woff2?v=1684753683',
  'VedaFont[Verdana Pro]+verdana_pro_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Verdana_Pro_verdana_pro_i4_italic_400.woff2?v=1684753683',
  'VedaFont[Unna]+unna_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Unna_unna_n7_normal_700.woff2?v=1684753683',
  'VedaFont[Zurich Extended]+zurich_extended_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Zurich_Extended_zurich_extended_n9_normal_900.woff2?v=1684753683',
  'VedaFont[Zurich Extended]+zurich_extended_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Zurich_Extended_zurich_extended_n7_normal_700.woff2?v=1684753683',
  'VedaFont[Zurich Extended]+zurich_extended_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Zurich_Extended_zurich_extended_n8_normal_800.woff2?v=1684753683',
  'VedaFont[Verdana Pro]+verdana_pro_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Verdana_Pro_verdana_pro_i7_italic_700.woff2?v=1684753683',
  'VedaFont[Vollkorn]+vollkorn_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Vollkorn_vollkorn_n4_normal_400.woff2?v=1684753683',
  'VedaFont[Work Sans]+work_sans_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Work_Sans_work_sans_n6_normal_600.woff2?v=1684753683',
  'VedaFont[Zurich]+zurich_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Zurich_zurich_i4_italic_400.woff2?v=1684753683',
  'VedaFont[Work Sans]+work_sans_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Work_Sans_work_sans_n8_normal_800.woff2?v=1684753682',
  'VedaFont[Work Sans]+work_sans_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Work_Sans_work_sans_n9_normal_900.woff2?v=1684753683',
  'VedaFont[Work Sans]+work_sans_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Work_Sans_work_sans_n1_normal_100.woff2?v=1684753682',
  'VedaFont[Varela Round]+varela_round_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Varela_Round_varela_round_n4_normal_400.woff2?v=1684753682',
  'VedaFont[Waza]+waza_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Waza_waza_n4_normal_400.woff2?v=1684753682',
  'VedaFont[Unna]+unna_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Unna_unna_i4_italic_400.woff2?v=1684753682',
  'VedaFont[Vollkorn]+vollkorn_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Vollkorn_vollkorn_n7_normal_700.woff2?v=1684753682',
  'VedaFont[Ysobel]+ysobel_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Ysobel_ysobel_n3_normal_300.woff2?v=1684753682',
  'VedaFont[Vollkorn]+vollkorn_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Vollkorn_vollkorn_i9_italic_900.woff2?v=1684753682',
  'VedaFont[Verdana Pro]+verdana_pro_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Verdana_Pro_verdana_pro_n6_normal_600.woff2?v=1684753682',
  'VedaFont[Vollkorn]+vollkorn_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Vollkorn_vollkorn_n9_normal_900.woff2?v=1684753683',
  'VedaFont[Vollkorn]+vollkorn_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Vollkorn_vollkorn_n6_normal_600.woff2?v=1684753682',
  'VedaFont[Varela]+varela_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Varela_varela_n4_normal_400.woff2?v=1684753682',
  'VedaFont[Volkhov]+volkhov_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Volkhov_volkhov_n7_normal_700.woff2?v=1684753682',
  'VedaFont[Vala]+vala_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Vala_vala_n4_normal_400.woff2?v=1684753682',
  'VedaFont[Wola]+wola_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Wola_wola_n4_normal_400.woff2?v=1684753682',
  'VedaFont[Volkhov]+volkhov_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Volkhov_volkhov_i7_italic_700.woff2?v=1684753682',
  'VedaFont[Verdana Pro]+verdana_pro_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Verdana_Pro_verdana_pro_n9_normal_900.woff2?v=1684753682',
  'VedaFont[Verdana Pro]+verdana_pro_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Verdana_Pro_verdana_pro_i6_italic_600.woff2?v=1684753682',
  'VedaFont[Verdana Pro]+verdana_pro_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Verdana_Pro_verdana_pro_n3_normal_300.woff2?v=1684753682',
  'VedaFont[Unna]+unna_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Unna_unna_n4_normal_400.woff2?v=1684753682',
  'VedaFont[Verdana Pro]+verdana_pro_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Verdana_Pro_verdana_pro_i3_italic_300.woff2?v=1684753682',
  'VedaFont[Verdana Pro]+verdana_pro_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Verdana_Pro_verdana_pro_n4_normal_400.woff2?v=1684753682',
  'VedaFont[Verdana Pro]+verdana_pro_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Verdana_Pro_verdana_pro_n7_normal_700.woff2?v=1684753682',
  'VedaFont[Volkhov]+volkhov_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Volkhov_volkhov_i4_italic_400.woff2?v=1684753682',
  'VedaFont[Univers Next]+univers_next_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_univers_next_i4_italic_400.woff2?v=1684753651',
  'VedaFont[Univers Next]+univers_next_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_univers_next_i9_italic_900.woff2?v=1684753651',
  'VedaFont[Univers Next]+univers_next_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_univers_next_n3_normal_300.woff2?v=1684753651',
  'VedaFont[Univers Next]+univers_next_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_univers_next_i8_italic_800.woff2?v=1684753651',
  'VedaFont[Univers Next]+univers_next_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_univers_next_i5_italic_500.woff2?v=1684753651',
  'VedaFont[Univers Next]+univers_next_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_univers_next_n5_normal_500.woff2?v=1684753651',
  'VedaFont[Ubuntu]+ubuntu_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Ubuntu_ubuntu_n3_normal_300.woff2?v=1684753651',
  'VedaFont[Univers Next]+univers_next_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_univers_next_n2_normal_200.woff2?v=1684753651',
  'VedaFont[Univers Next]+univers_next_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_univers_next_i1_italic_100.woff2?v=1684753651',
  'VedaFont[Univers Next]+univers_next_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_univers_next_n8_normal_800.woff2?v=1684753651',
  'VedaFont[Univers Next]+univers_next_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_univers_next_i7_italic_700.woff2?v=1684753651',
  'VedaFont[Ubuntu]+ubuntu_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Ubuntu_ubuntu_n7_normal_700.woff2?v=1684753651',
  'VedaFont[Univers Next]+univers_next_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_univers_next_n6_normal_600.woff2?v=1684753651',
  'VedaFont[Univers Next]+univers_next_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_univers_next_n1_normal_100.woff2?v=1684753651',
  'VedaFont[Univers Next]+univers_next_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_univers_next_n7_normal_700.woff2?v=1684753651',
  'VedaFont[Ubuntu]+ubuntu_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Ubuntu_ubuntu_n5_normal_500.woff2?v=1684753653',
  'VedaFont[Univers Next]+univers_next_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_univers_next_i2_italic_200.woff2?v=1684753651',
  'VedaFont[Univers Next Typewriter]+univers_next_typewriter_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_Typewriter_univers_next_typewriter_i4_italic_400.woff2?v=1684753651',
  'VedaFont[Ubuntu]+ubuntu_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Ubuntu_ubuntu_n4_normal_400.woff2?v=1684753651',
  'VedaFont[Ubuntu]+ubuntu_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Ubuntu_ubuntu_i7_italic_700.woff2?v=1684753651',
  'VedaFont[Ubuntu]+ubuntu_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Ubuntu_ubuntu_i4_italic_400.woff2?v=1684753651',
  'VedaFont[Ubuntu]+ubuntu_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Ubuntu_ubuntu_i5_italic_500.woff2?v=1684753651',
  'VedaFont[Trade Gothic]+trade_gothic_o4+oblique+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Trade_Gothic_trade_gothic_o4_oblique_400.woff2?v=1684753651',
  'VedaFont[Twentieth Century]+twentieth_century_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Twentieth_Century_twentieth_century_i7_italic_700.woff2?v=1684753651',
  'VedaFont[Univers Next Typewriter]+univers_next_typewriter_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_Typewriter_univers_next_typewriter_i7_italic_700.woff2?v=1684753651',
  'VedaFont[Ubuntu]+ubuntu_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Ubuntu_ubuntu_i3_italic_300.woff2?v=1684753651',
  'VedaFont[Twentieth Century]+twentieth_century_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Twentieth_Century_twentieth_century_n7_normal_700.woff2?v=1684753651',
  'VedaFont[Titillium Web]+titillium_web_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Titillium_Web_titillium_web_n6_normal_600.woff2?v=1684753651',
  'VedaFont[Univers Next]+univers_next_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_univers_next_n9_normal_900.woff2?v=1684753651',
  'VedaFont[Twentieth Century]+twentieth_century_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Twentieth_Century_twentieth_century_n6_normal_600.woff2?v=1684753651',
  'VedaFont[Univers Next Typewriter]+univers_next_typewriter_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_Typewriter_univers_next_typewriter_n7_normal_700.woff2?v=1684753651',
  'VedaFont[Univers Next]+univers_next_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_univers_next_n4_normal_400.woff2?v=1684753651',
  'VedaFont[Univers Next]+univers_next_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_univers_next_i6_italic_600.woff2?v=1684753651',
  'VedaFont[Univers Next Typewriter]+univers_next_typewriter_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_Typewriter_univers_next_typewriter_n4_normal_400.woff2?v=1684753651',
  'VedaFont[Unica One]+unica_one_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Unica_One_unica_one_n4_normal_400.woff2?v=1684753651',
  'VedaFont[Twentieth Century]+twentieth_century_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Twentieth_Century_twentieth_century_n3_normal_300.woff2?v=1684753651',
  'VedaFont[Twentieth Century]+twentieth_century_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Twentieth_Century_twentieth_century_n8_normal_800.woff2?v=1684753651',
  'VedaFont[Twentieth Century]+twentieth_century_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Twentieth_Century_twentieth_century_i8_italic_800.woff2?v=1684753651',
  'VedaFont[Twentieth Century]+twentieth_century_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Twentieth_Century_twentieth_century_n9_normal_900.woff2?v=1684753651',
  'VedaFont[Univers Next]+univers_next_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Univers_Next_univers_next_i3_italic_300.woff2?v=1684753651',
  'VedaFont[Twentieth Century]+twentieth_century_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Twentieth_Century_twentieth_century_n4_normal_400.woff2?v=1684753651',
  'VedaFont[Twentieth Century]+twentieth_century_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Twentieth_Century_twentieth_century_n5_normal_500.woff2?v=1684753651',
  'VedaFont[Twentieth Century]+twentieth_century_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Twentieth_Century_twentieth_century_i9_italic_900.woff2?v=1684753651',
  'VedaFont[Twentieth Century]+twentieth_century_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Twentieth_Century_twentieth_century_i5_italic_500.woff2?v=1684753651',
  'VedaFont[Trade Gothic Next]+trade_gothic_next_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Trade_Gothic_Next_trade_gothic_next_i3_italic_300.woff2?v=1684753651',
  'VedaFont[Trade Gothic Next]+trade_gothic_next_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Trade_Gothic_Next_trade_gothic_next_n3_normal_300.woff2?v=1684753650',
  'VedaFont[Trade Gothic]+trade_gothic_o7+oblique+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Trade_Gothic_trade_gothic_o7_oblique_700.woff2?v=1684753650',
  'VedaFont[Trade Gothic Next]+trade_gothic_next_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Trade_Gothic_Next_trade_gothic_next_n4_normal_400.woff2?v=1684753651',
  'VedaFont[Trade Gothic]+trade_gothic_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Trade_Gothic_trade_gothic_n7_normal_700.woff2?v=1684753656',
  'VedaFont[Tiemann]+tiemann_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Tiemann_tiemann_n4_normal_400.woff2?v=1684753651',
  'VedaFont[Twentieth Century]+twentieth_century_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Twentieth_Century_twentieth_century_i3_italic_300.woff2?v=1684753650',
  'VedaFont[Tinos]+tinos_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Tinos_tinos_i7_italic_700.woff2?v=1684753650',
  'VedaFont[Trade Gothic Next]+trade_gothic_next_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Trade_Gothic_Next_trade_gothic_next_n8_normal_800.woff2?v=1684753650',
  'VedaFont[Trade Gothic]+trade_gothic_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Trade_Gothic_trade_gothic_n3_normal_300.woff2?v=1684753650',
  'VedaFont[Trade Gothic]+trade_gothic_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Trade_Gothic_trade_gothic_n4_normal_400.woff2?v=1684753650',
  'VedaFont[Trade Gothic]+trade_gothic_o3+oblique+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Trade_Gothic_trade_gothic_o3_oblique_300.woff2?v=1684753650',
  'VedaFont[Trade Gothic Next]+trade_gothic_next_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Trade_Gothic_Next_trade_gothic_next_n7_normal_700.woff2?v=1684753651',
  'VedaFont[Titillium Web]+titillium_web_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Titillium_Web_titillium_web_n7_normal_700.woff2?v=1684753650',
  'VedaFont[Tinos]+tinos_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Tinos_tinos_i4_italic_400.woff2?v=1684753650',
  'VedaFont[Titillium Web]+titillium_web_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Titillium_Web_titillium_web_i6_italic_600.woff2?v=1684753650',
  'VedaFont[Tinos]+tinos_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Tinos_tinos_n4_normal_400.woff2?v=1684753650',
  'VedaFont[Trade Gothic Next]+trade_gothic_next_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Trade_Gothic_Next_trade_gothic_next_i4_italic_400.woff2?v=1684753650',
  'VedaFont[Tinos]+tinos_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Tinos_tinos_n7_normal_700.woff2?v=1684753650',
  'VedaFont[Trade Gothic Next]+trade_gothic_next_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Trade_Gothic_Next_trade_gothic_next_i7_italic_700.woff2?v=1684753650',
  'VedaFont[Tenor Sans]+tenor_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Tenor_Sans_tenor_sans_n4_normal_400.woff2?v=1684753650',
  'VedaFont[Titillium Web]+titillium_web_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Titillium_Web_titillium_web_n4_normal_400.woff2?v=1684753650',
  'VedaFont[Titillium Web]+titillium_web_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Titillium_Web_titillium_web_n3_normal_300.woff2?v=1684753650',
  'VedaFont[Titillium Web]+titillium_web_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Titillium_Web_titillium_web_i4_italic_400.woff2?v=1684753650',
  'VedaFont[Trade Gothic Next]+trade_gothic_next_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Trade_Gothic_Next_trade_gothic_next_i8_italic_800.woff2?v=1684753650',
  'VedaFont[Titillium Web]+titillium_web_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Titillium_Web_titillium_web_i2_italic_200.woff2?v=1684753650',
  'VedaFont[Titillium Web]+titillium_web_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Titillium_Web_titillium_web_n2_normal_200.woff2?v=1684753650',
  'VedaFont[Syne]+syne_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Syne_syne_n7_normal_700.woff2?v=1684753651',
  'VedaFont[Titillium Web]+titillium_web_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Titillium_Web_titillium_web_n9_normal_900.woff2?v=1684753650',
  'VedaFont[Syne]+syne_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Syne_syne_n8_normal_800.woff2?v=1684753656',
  'VedaFont[Titillium Web]+titillium_web_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Titillium_Web_titillium_web_i3_italic_300.woff2?v=1684753650',
  'VedaFont[Titillium Web]+titillium_web_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Titillium_Web_titillium_web_i7_italic_700.woff2?v=1684753650',
  'VedaFont[Tiemann]+tiemann_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Tiemann_tiemann_n3_normal_300.woff2?v=1684753650',
  'VedaFont[Stempel Schneidler]+stempel_schneidler_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Stempel_Schneidler_stempel_schneidler_n8_normal_800.woff2?v=1684753650',
  'VedaFont[Swiss]+swiss_n7+normal+721721700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Swiss_721_swiss_721_n7_normal_700.woff2?v=1684753650',
  'VedaFont[Swiss Rounded]+swiss_rounded_n9+normal+721721900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Swiss_721_Rounded_swiss_721_rounded_n9_normal_900.woff2?v=1684753650',
  'VedaFont[Syne]+syne_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Syne_syne_n5_normal_500.woff2?v=1684753651',
  'VedaFont[Swiss]+swiss_i7+italic+721721700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Swiss_721_swiss_721_i7_italic_700.woff2?v=1684753650',
  'VedaFont[Slate]+slate_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Slate_slate_n3_normal_300.woff2?v=1684753650',
  'VedaFont[Space Mono]+space_mono_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Space_Mono_space_mono_n4_normal_400.woff2?v=1684753656',
  'VedaFont[Stempel Schneidler]+stempel_schneidler_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Stempel_Schneidler_stempel_schneidler_i7_italic_700.woff2?v=1684753650',
  'VedaFont[Swiss]+swiss_n8+normal+721721800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Swiss_721_swiss_721_n8_normal_800.woff2?v=1684753650',
  'VedaFont[Swiss]+swiss_i4+italic+721721400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Swiss_721_swiss_721_i4_italic_400.woff2?v=1684753650',
  'VedaFont[Swiss]+swiss_n2+normal+721721200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Swiss_721_swiss_721_n2_normal_200.woff2?v=1684753650',
  'VedaFont[Swiss]+swiss_i5+italic+721721500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Swiss_721_swiss_721_i5_italic_500.woff2?v=1684753650',
  'VedaFont[Swiss]+swiss_i9+italic+721721900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Swiss_721_swiss_721_i9_italic_900.woff2?v=1684753650',
  'VedaFont[Syne]+syne_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Syne_syne_n6_normal_600.woff2?v=1684753650',
  'VedaFont[Swiss]+swiss_n4+normal+721721400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Swiss_721_swiss_721_n4_normal_400.woff2?v=1684753650',
  'VedaFont[Stempel Schneidler]+stempel_schneidler_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Stempel_Schneidler_stempel_schneidler_n7_normal_700.woff2?v=1684753650',
  'VedaFont[Source Sans Pro]+source_sans_pro_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Source_Sans_Pro_source_sans_pro_i9_italic_900.woff2?v=1684753650',
  'VedaFont[Swiss]+swiss_n5+normal+721721500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Swiss_721_swiss_721_n5_normal_500.woff2?v=1684753656',
  'VedaFont[Swiss]+swiss_n9+normal+721721900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Swiss_721_swiss_721_n9_normal_900.woff2?v=1684753650',
  'VedaFont[Source Sans Pro]+source_sans_pro_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Source_Sans_Pro_source_sans_pro_n9_normal_900.woff2?v=1684753650',
  'VedaFont[Syne]+syne_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Syne_syne_n4_normal_400.woff2?v=1684753656',
  'VedaFont[Space Mono]+space_mono_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Space_Mono_space_mono_i7_italic_700.woff2?v=1684753650',
  'VedaFont[Stempel Schneidler]+stempel_schneidler_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Stempel_Schneidler_stempel_schneidler_i4_italic_400.woff2?v=1684753650',
  'VedaFont[Source Code Pro]+source_code_pro_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Source_Code_Pro_source_code_pro_n7_normal_700.woff2?v=1684753650',
  'VedaFont[Source Sans Pro]+source_sans_pro_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Source_Sans_Pro_source_sans_pro_n6_normal_600.woff2?v=1684753650',
  'VedaFont[Source Sans Pro]+source_sans_pro_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Source_Sans_Pro_source_sans_pro_i4_italic_400.woff2?v=1684753650',
  'VedaFont[Space Mono]+space_mono_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Space_Mono_space_mono_i4_italic_400.woff2?v=1684753650',
  'VedaFont[Source Sans Pro]+source_sans_pro_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Source_Sans_Pro_source_sans_pro_n2_normal_200.woff2?v=1684753650',
  'VedaFont[Source Code Pro]+source_code_pro_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Source_Code_Pro_source_code_pro_n3_normal_300.woff2?v=1684753650',
  'VedaFont[Slate]+slate_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Slate_slate_n4_normal_400.woff2?v=1684753650',
  'VedaFont[Scene]+scene_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Scene_scene_n9_normal_900.woff2?v=1684753650',
  'VedaFont[Source Sans Pro]+source_sans_pro_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Source_Sans_Pro_source_sans_pro_n4_normal_400.woff2?v=1684753650',
  'VedaFont[Source Sans Pro]+source_sans_pro_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Source_Sans_Pro_source_sans_pro_i7_italic_700.woff2?v=1684753650',
  'VedaFont[Source Code Pro]+source_code_pro_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Source_Code_Pro_source_code_pro_n9_normal_900.woff2?v=1684753650',
  'VedaFont[Soho]+soho_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_soho_i8_italic_800.woff2?v=1684753650',
  'VedaFont[Soho]+soho_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_soho_n3_normal_300.woff2?v=1684753650',
  'VedaFont[Soho]+soho_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_soho_n8_normal_800.woff2?v=1684753650',
  'VedaFont[Soho Gothic]+soho_gothic_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_Gothic_soho_gothic_i5_italic_500.woff2?v=1684753650',
  'VedaFont[Source Code Pro]+source_code_pro_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Source_Code_Pro_source_code_pro_n4_normal_400.woff2?v=1684753650',
  'VedaFont[Source Sans Pro]+source_sans_pro_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Source_Sans_Pro_source_sans_pro_i2_italic_200.woff2?v=1684753650',
  'VedaFont[Soho Gothic]+soho_gothic_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_Gothic_soho_gothic_i2_italic_200.woff2?v=1684753650',
  'VedaFont[Slate]+slate_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Slate_slate_i9_italic_900.woff2?v=1684753650',
  'VedaFont[Soho Gothic]+soho_gothic_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_Gothic_soho_gothic_n9_normal_900.woff2?v=1684753650',
  'VedaFont[Slate]+slate_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Slate_slate_i5_italic_500.woff2?v=1684753650',
  'VedaFont[Source Sans Pro]+source_sans_pro_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Source_Sans_Pro_source_sans_pro_i6_italic_600.woff2?v=1684753650',
  'VedaFont[Scherzo]+scherzo_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Scherzo_scherzo_i6_italic_600.woff2?v=1684753650',
  'VedaFont[Soho Gothic]+soho_gothic_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_Gothic_soho_gothic_n5_normal_500.woff2?v=1684753650',
  'VedaFont[Soho Gothic]+soho_gothic_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_Gothic_soho_gothic_n8_normal_800.woff2?v=1684753650',
  'VedaFont[Slate]+slate_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Slate_slate_i7_italic_700.woff2?v=1684753650',
  'VedaFont[Soho]+soho_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_soho_n4_normal_400.woff2?v=1684753650',
  'VedaFont[Source Code Pro]+source_code_pro_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Source_Code_Pro_source_code_pro_n5_normal_500.woff2?v=1684753650',
  'VedaFont[Source Code Pro]+source_code_pro_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Source_Code_Pro_source_code_pro_n6_normal_600.woff2?v=1684753650',
  'VedaFont[Scherzo]+scherzo_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Scherzo_scherzo_i4_italic_400.woff2?v=1684753650',
  'VedaFont[Scherzo]+scherzo_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Scherzo_scherzo_n4_normal_400.woff2?v=1684753650',
  'VedaFont[Slate]+slate_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Slate_slate_i3_italic_300.woff2?v=1684753650',
  'VedaFont[Slate]+slate_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Slate_slate_i4_italic_400.woff2?v=1684753650',
  'VedaFont[Scherzo]+scherzo_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Scherzo_scherzo_n7_normal_700.woff2?v=1684753650',
  'VedaFont[Scene]+scene_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Scene_scene_n7_normal_700.woff2?v=1684753650',
  'VedaFont[Scene]+scene_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Scene_scene_n8_normal_800.woff2?v=1684753650',
  'VedaFont[Scherzo]+scherzo_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Scherzo_scherzo_i7_italic_700.woff2?v=1684753650',
  'VedaFont[Soho Gothic]+soho_gothic_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_Gothic_soho_gothic_i3_italic_300.woff2?v=1684753650',
  'VedaFont[Shadows Into Light]+shadows_into_light_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Shadows_Into_Light_shadows_into_light_n4_normal_400.woff2?v=1684753650',
  'VedaFont[Slate]+slate_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Slate_slate_n7_normal_700.woff2?v=1684753650',
  'VedaFont[Slabo 13px 13px]+13px_slabo_13px_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Slabo_13px_slabo_13px_n4_normal_400.woff2?v=1684753650',
  'VedaFont[Scene]+scene_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Scene_scene_n5_normal_500.woff2?v=1684753650',
  'VedaFont[Scene]+scene_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Scene_scene_n4_normal_400.woff2?v=1684753650',
  'VedaFont[Slate]+slate_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Slate_slate_i2_italic_200.woff2?v=1684753650',
  'VedaFont[Slate]+slate_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Slate_slate_n9_normal_900.woff2?v=1684753650',
  'VedaFont[Scherzo]+scherzo_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Scherzo_scherzo_n6_normal_600.woff2?v=1684753650',
  'VedaFont[Scene]+scene_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Scene_scene_n3_normal_300.woff2?v=1684753650',
  'VedaFont[Scene]+scene_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Scene_scene_i7_italic_700.woff2?v=1684753650',
  'VedaFont[Scene]+scene_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Scene_scene_i3_italic_300.woff2?v=1684753650',
  'VedaFont[Scene]+scene_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Scene_scene_i4_italic_400.woff2?v=1684753650',
  'VedaFont[Sabon Next]+sabon_next_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Sabon_Next_sabon_next_n9_normal_900.woff2?v=1684753650',
  'VedaFont[Scene]+scene_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Scene_scene_i5_italic_500.woff2?v=1684753650',
  'VedaFont[Sagrantino]+sagrantino_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Sagrantino_sagrantino_n4_normal_400.woff2?v=1684753650',
  'VedaFont[Scene]+scene_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Scene_scene_i9_italic_900.woff2?v=1684753650',
  'VedaFont[Scene]+scene_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Scene_scene_i8_italic_800.woff2?v=1684753650',
  'VedaFont[Sackers Square Gothic]+sackers_square_gothic_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Sackers_Square_Gothic_sackers_square_gothic_n4_normal_400.woff2?v=1684753650',
  'VedaFont[Soho]+soho_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_soho_n5_normal_500.woff2?v=1684753650',
  'VedaFont[Sabon Next]+sabon_next_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Sabon_Next_sabon_next_n8_normal_800.woff2?v=1684753650',
  'VedaFont[Sabon Next]+sabon_next_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Sabon_Next_sabon_next_n7_normal_700.woff2?v=1684753650',
  'VedaFont[Swiss]+swiss_i1+italic+721721100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Swiss_721_swiss_721_i1_italic_100.woff2?v=1684753649',
  'VedaFont[Stempel Schneidler]+stempel_schneidler_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Stempel_Schneidler_stempel_schneidler_n5_normal_500.woff2?v=1684753649',
  'VedaFont[Swiss]+swiss_i2+italic+721721200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Swiss_721_swiss_721_i2_italic_200.woff2?v=1684753650',
  'VedaFont[Smooch]+smooch_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Smooch_smooch_n4_normal_400.woff2?v=1684753649',
  'VedaFont[Soho]+soho_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_soho_i5_italic_500.woff2?v=1684753649',
  'VedaFont[Soho]+soho_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_soho_n2_normal_200.woff2?v=1684753649',
  'VedaFont[Stempel Schneidler]+stempel_schneidler_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Stempel_Schneidler_stempel_schneidler_i3_italic_300.woff2?v=1684753649',
  'VedaFont[Soho Gothic]+soho_gothic_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_Gothic_soho_gothic_n2_normal_200.woff2?v=1684753650',
  'VedaFont[Soho Gothic]+soho_gothic_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_Gothic_soho_gothic_i9_italic_900.woff2?v=1684753650',
  'VedaFont[Slate]+slate_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Slate_slate_n2_normal_200.woff2?v=1684753649',
  'VedaFont[Soho]+soho_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_soho_i1_italic_100.woff2?v=1684753649',
  'VedaFont[Soho]+soho_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_soho_i7_italic_700.woff2?v=1684753649',
  'VedaFont[Stempel Schneidler]+stempel_schneidler_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Stempel_Schneidler_stempel_schneidler_i8_italic_800.woff2?v=1684753649',
  'VedaFont[Soho Gothic]+soho_gothic_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_Gothic_soho_gothic_i7_italic_700.woff2?v=1684753650',
  'VedaFont[Swiss]+swiss_n1+normal+721721100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Swiss_721_swiss_721_n1_normal_100.woff2?v=1684753656',
  'VedaFont[Source Code Pro]+source_code_pro_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Source_Code_Pro_source_code_pro_n2_normal_200.woff2?v=1684753650',
  'VedaFont[Soho]+soho_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_soho_n9_normal_900.woff2?v=1684753649',
  'VedaFont[Source Sans Pro]+source_sans_pro_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Source_Sans_Pro_source_sans_pro_n3_normal_300.woff2?v=1684753649',
  'VedaFont[Soho]+soho_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_soho_i6_italic_600.woff2?v=1684753649',
  'VedaFont[Soho]+soho_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_soho_i3_italic_300.woff2?v=1684753650',
  'VedaFont[Stempel Schneidler]+stempel_schneidler_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Stempel_Schneidler_stempel_schneidler_i5_italic_500.woff2?v=1684753650',
  'VedaFont[Swiss]+swiss_i8+italic+721721800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Swiss_721_swiss_721_i8_italic_800.woff2?v=1684753649',
  'VedaFont[Stempel Schneidler]+stempel_schneidler_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Stempel_Schneidler_stempel_schneidler_n4_normal_400.woff2?v=1684753649',
  'VedaFont[Soho Gothic]+soho_gothic_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_Gothic_soho_gothic_i4_italic_400.woff2?v=1684753649',
  'VedaFont[Soho]+soho_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_soho_i2_italic_200.woff2?v=1684753649',
  'VedaFont[Soho]+soho_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_soho_i4_italic_400.woff2?v=1684753649',
  'VedaFont[Soho]+soho_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_soho_n6_normal_600.woff2?v=1684753649',
  'VedaFont[Source Sans Pro]+source_sans_pro_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Source_Sans_Pro_source_sans_pro_i3_italic_300.woff2?v=1684753649',
  'VedaFont[Soho]+soho_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_soho_n1_normal_100.woff2?v=1684753649',
  'VedaFont[Source Sans Pro]+source_sans_pro_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Source_Sans_Pro_source_sans_pro_n7_normal_700.woff2?v=1684753656',
  'VedaFont[Swiss Rounded]+swiss_rounded_n7+normal+721721700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Swiss_721_Rounded_swiss_721_rounded_n7_normal_700.woff2?v=1684753649',
  'VedaFont[Soho]+soho_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_soho_n7_normal_700.woff2?v=1684753649',
  'VedaFont[Soho Gothic]+soho_gothic_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_Gothic_soho_gothic_n4_normal_400.woff2?v=1684753649',
  'VedaFont[Stempel Schneidler]+stempel_schneidler_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Stempel_Schneidler_stempel_schneidler_n3_normal_300.woff2?v=1684753649',
  'VedaFont[Soho Gothic]+soho_gothic_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_Gothic_soho_gothic_n3_normal_300.woff2?v=1684753649',
  'VedaFont[Space Mono]+space_mono_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Space_Mono_space_mono_n7_normal_700.woff2?v=1684753649',
  'VedaFont[Slate]+slate_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Slate_slate_n5_normal_500.woff2?v=1684753649',
  'VedaFont[Soho Gothic]+soho_gothic_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_Gothic_soho_gothic_i8_italic_800.woff2?v=1684753649',
  'VedaFont[Soho]+soho_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_soho_i9_italic_900.woff2?v=1684753649',
  'VedaFont[Soho Gothic]+soho_gothic_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Soho_Gothic_soho_gothic_n7_normal_700.woff2?v=1684753649',
  'VedaFont[Sabon Next]+sabon_next_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Sabon_Next_sabon_next_n6_normal_600.woff2?v=1684753649',
  'VedaFont[Rubik]+rubik_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rubik_rubik_n7_normal_700.woff2?v=1684753597',
  'VedaFont[Sabon Next]+sabon_next_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Sabon_Next_sabon_next_i8_italic_800.woff2?v=1684753597',
  'VedaFont[Sabon Next]+sabon_next_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Sabon_Next_sabon_next_i7_italic_700.woff2?v=1684753597',
  'VedaFont[Sabon Next]+sabon_next_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Sabon_Next_sabon_next_n4_normal_400.woff2?v=1684753597',
  'VedaFont[Sabon Next]+sabon_next_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Sabon_Next_sabon_next_i9_italic_900.woff2?v=1684753598',
  'VedaFont[Sabon Next]+sabon_next_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Sabon_Next_sabon_next_i6_italic_600.woff2?v=1684753597',
  'VedaFont[Rockwell]+rockwell_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rockwell_rockwell_n4_normal_400.woff2?v=1684753597',
  'VedaFont[Rubik]+rubik_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rubik_rubik_n4_normal_400.woff2?v=1684753597',
  'VedaFont[Rubik]+rubik_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rubik_rubik_i9_italic_900.woff2?v=1684753597',
  'VedaFont[Roboto]+roboto_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_roboto_i3_italic_300.woff2?v=1684753597',
  'VedaFont[Roboto Condensed]+roboto_condensed_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Condensed_roboto_condensed_i4_italic_400.woff2?v=1684753597',
  'VedaFont[Roboto Condensed]+roboto_condensed_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Condensed_roboto_condensed_n3_normal_300.woff2?v=1684753597',
  'VedaFont[Rubik]+rubik_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rubik_rubik_i4_italic_400.woff2?v=1684753597',
  'VedaFont[Sabon Next]+sabon_next_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Sabon_Next_sabon_next_i4_italic_400.woff2?v=1684753597',
  'VedaFont[Roboto]+roboto_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_roboto_n9_normal_900.woff2?v=1684753597',
  'VedaFont[Roboto]+roboto_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_roboto_i5_italic_500.woff2?v=1684753597',
  'VedaFont[Roboto Slab]+roboto_slab_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Slab_roboto_slab_n4_normal_400.woff2?v=1684753597',
  'VedaFont[Rockwell]+rockwell_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rockwell_rockwell_n8_normal_800.woff2?v=1684753597',
  'VedaFont[Roboto]+roboto_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_roboto_n5_normal_500.woff2?v=1684753597',
  'VedaFont[Really No]+really_no_n9+normal+22900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Really_No_2_really_no_2_n9_normal_900.woff2?v=1684753597',
  'VedaFont[Roboto]+roboto_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_roboto_i4_italic_400.woff2?v=1684753597',
  'VedaFont[Rockwell]+rockwell_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rockwell_rockwell_n7_normal_700.woff2?v=1684753597',
  'VedaFont[Rubik]+rubik_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rubik_rubik_n5_normal_500.woff2?v=1684753597',
  'VedaFont[Roboto]+roboto_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_roboto_n3_normal_300.woff2?v=1684753597',
  'VedaFont[Roboto]+roboto_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_roboto_i7_italic_700.woff2?v=1684753597',
  'VedaFont[Rockwell]+rockwell_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rockwell_rockwell_i7_italic_700.woff2?v=1684753597',
  'VedaFont[Rubik]+rubik_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rubik_rubik_n9_normal_900.woff2?v=1684753597',
  'VedaFont[Roboto]+roboto_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_roboto_i1_italic_100.woff2?v=1684753597',
  'VedaFont[Really No]+really_no_n7+normal+22700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Really_No_2_really_no_2_n7_normal_700.woff2?v=1684753597',
  'VedaFont[Roboto]+roboto_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_roboto_n7_normal_700.woff2?v=1684753597',
  'VedaFont[Rubik]+rubik_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rubik_rubik_n3_normal_300.woff2?v=1684753597',
  'VedaFont[Rubik]+rubik_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rubik_rubik_i3_italic_300.woff2?v=1684753597',
  'VedaFont[Rubik]+rubik_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rubik_rubik_i5_italic_500.woff2?v=1684753597',
  'VedaFont[Roboto]+roboto_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_roboto_n4_normal_400.woff2?v=1684753597',
  'VedaFont[Rubik]+rubik_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rubik_rubik_i7_italic_700.woff2?v=1684753597',
  'VedaFont[Rockwell]+rockwell_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rockwell_rockwell_i4_italic_400.woff2?v=1684753597',
  'VedaFont[Roboto]+roboto_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_roboto_i9_italic_900.woff2?v=1684753597',
  'VedaFont[Roboto Mono]+roboto_mono_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Mono_roboto_mono_i1_italic_100.woff2?v=1684753597',
  'VedaFont[Roboto Mono]+roboto_mono_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Mono_roboto_mono_i7_italic_700.woff2?v=1684753597',
  'VedaFont[Roboto Slab]+roboto_slab_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Slab_roboto_slab_n7_normal_700.woff2?v=1684753597',
  'VedaFont[Raleway]+raleway_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Raleway_raleway_i2_italic_200.woff2?v=1684753597',
  'VedaFont[Roboto Mono]+roboto_mono_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Mono_roboto_mono_n4_normal_400.woff2?v=1684753597',
  'VedaFont[Roboto Mono]+roboto_mono_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Mono_roboto_mono_n1_normal_100.woff2?v=1684753597',
  'VedaFont[Roboto Mono]+roboto_mono_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Mono_roboto_mono_n3_normal_300.woff2?v=1684753597',
  'VedaFont[Roboto Slab]+roboto_slab_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Slab_roboto_slab_n3_normal_300.woff2?v=1684753597',
  'VedaFont[Really No]+really_no_i6+italic+22600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Really_No_2_really_no_2_i6_italic_600.woff2?v=1684753597',
  'VedaFont[Roboto]+roboto_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_roboto_n1_normal_100.woff2?v=1684753597',
  'VedaFont[Righteous]+righteous_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Righteous_righteous_n4_normal_400.woff2?v=1684753597',
  'VedaFont[Really No]+really_no_i7+italic+22700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Really_No_2_really_no_2_i7_italic_700.woff2?v=1684753597',
  'VedaFont[Raleway]+raleway_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Raleway_raleway_n5_normal_500.woff2?v=1684753597',
  'VedaFont[Roboto Mono]+roboto_mono_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Mono_roboto_mono_n5_normal_500.woff2?v=1684753597',
  'VedaFont[Roboto Mono]+roboto_mono_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Mono_roboto_mono_i5_italic_500.woff2?v=1684753597',
  'VedaFont[Roboto Condensed]+roboto_condensed_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Condensed_roboto_condensed_i3_italic_300.woff2?v=1684753597',
  'VedaFont[Roboto Mono]+roboto_mono_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Mono_roboto_mono_i4_italic_400.woff2?v=1684753597',
  'VedaFont[Roboto Slab]+roboto_slab_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Slab_roboto_slab_n1_normal_100.woff2?v=1684753597',
  'VedaFont[Really No]+really_no_n4+normal+22400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Really_No_2_really_no_2_n4_normal_400.woff2?v=1684753597',
  'VedaFont[Really No]+really_no_i5+italic+22500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Really_No_2_really_no_2_i5_italic_500.woff2?v=1684753597',
  'VedaFont[Really No]+really_no_i8+italic+22800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Really_No_2_really_no_2_i8_italic_800.woff2?v=1684753597',
  'VedaFont[Really No]+really_no_n5+normal+22500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Really_No_2_really_no_2_n5_normal_500.woff2?v=1684753597',
  'VedaFont[Really No]+really_no_i3+italic+22300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Really_No_2_really_no_2_i3_italic_300.woff2?v=1684753597',
  'VedaFont[Raleway]+raleway_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Raleway_raleway_n7_normal_700.woff2?v=1684753597',
  'VedaFont[Roboto Condensed]+roboto_condensed_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Condensed_roboto_condensed_n7_normal_700.woff2?v=1684753597',
  'VedaFont[Really No]+really_no_i4+italic+22400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Really_No_2_really_no_2_i4_italic_400.woff2?v=1684753597',
  'VedaFont[Roboto Mono]+roboto_mono_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Mono_roboto_mono_n7_normal_700.woff2?v=1684753597',
  'VedaFont[Raleway]+raleway_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Raleway_raleway_n6_normal_600.woff2?v=1684753597',
  'VedaFont[Raleway]+raleway_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Raleway_raleway_n8_normal_800.woff2?v=1684753597',
  'VedaFont[Roboto Condensed]+roboto_condensed_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Condensed_roboto_condensed_i7_italic_700.woff2?v=1684753597',
  'VedaFont[Raleway]+raleway_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Raleway_raleway_n9_normal_900.woff2?v=1684753597',
  'VedaFont[Roboto Condensed]+roboto_condensed_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Condensed_roboto_condensed_n4_normal_400.woff2?v=1684753597',
  'VedaFont[Roboto Mono]+roboto_mono_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Roboto_Mono_roboto_mono_i3_italic_300.woff2?v=1684753597',
  'VedaFont[Raleway]+raleway_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Raleway_raleway_n4_normal_400.woff2?v=1684753597',
  'VedaFont[Raleway]+raleway_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Raleway_raleway_n3_normal_300.woff2?v=1684753597',
  'VedaFont[Raleway]+raleway_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Raleway_raleway_i5_italic_500.woff2?v=1684753597',
  'VedaFont[Raleway]+raleway_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Raleway_raleway_i6_italic_600.woff2?v=1684753597',
  'VedaFont[Really No]+really_no_n8+normal+22800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Really_No_2_really_no_2_n8_normal_800.woff2?v=1684753597',
  'VedaFont[Really No]+really_no_n3+normal+22300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Really_No_2_really_no_2_n3_normal_300.woff2?v=1684753597',
  'VedaFont[Raleway]+raleway_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Raleway_raleway_i4_italic_400.woff2?v=1684753597',
  'VedaFont[Really No]+really_no_i9+italic+22900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Really_No_2_really_no_2_i9_italic_900.woff2?v=1684753597',
  'VedaFont[Raleway]+raleway_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Raleway_raleway_i3_italic_300.woff2?v=1684753596',
  'VedaFont[Quicksand]+quicksand_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quicksand_quicksand_n4_normal_400.woff2?v=1684753597',
  'VedaFont[Really No]+really_no_n6+normal+22600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Really_No_2_really_no_2_n6_normal_600.woff2?v=1684753596',
  'VedaFont[Raleway]+raleway_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Raleway_raleway_i8_italic_800.woff2?v=1684753597',
  'VedaFont[Rajdhani]+rajdhani_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rajdhani_rajdhani_n7_normal_700.woff2?v=1684753597',
  'VedaFont[PT Sans]+pt_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_PT_Sans_pt_sans_i4_italic_400.woff2?v=1684753596',
  'VedaFont[Raleway]+raleway_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Raleway_raleway_i9_italic_900.woff2?v=1684753597',
  'VedaFont[Raleway]+raleway_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Raleway_raleway_i1_italic_100.woff2?v=1684753596',
  'VedaFont[Raleway]+raleway_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Raleway_raleway_n2_normal_200.woff2?v=1684753597',
  'VedaFont[Rajdhani]+rajdhani_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rajdhani_rajdhani_n3_normal_300.woff2?v=1684753597',
  'VedaFont[Quire Sans]+quire_sans_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quire_Sans_quire_sans_n6_normal_600.woff2?v=1684753596',
  'VedaFont[Quire Sans]+quire_sans_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quire_Sans_quire_sans_i9_italic_900.woff2?v=1684753596',
  'VedaFont[Raleway]+raleway_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Raleway_raleway_n1_normal_100.woff2?v=1684753596',
  'VedaFont[Quire Sans]+quire_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quire_Sans_quire_sans_n7_normal_700.woff2?v=1684753596',
  'VedaFont[Rajdhani]+rajdhani_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rajdhani_rajdhani_n6_normal_600.woff2?v=1684753597',
  'VedaFont[Quire Sans]+quire_sans_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quire_Sans_quire_sans_n5_normal_500.woff2?v=1684753596',
  'VedaFont[Raleway]+raleway_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Raleway_raleway_i7_italic_700.woff2?v=1684753596',
  'VedaFont[Quire Sans]+quire_sans_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quire_Sans_quire_sans_n8_normal_800.woff2?v=1684753596',
  'VedaFont[Rajdhani]+rajdhani_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rajdhani_rajdhani_n5_normal_500.woff2?v=1684753596',
  'VedaFont[Quire Sans]+quire_sans_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quire_Sans_quire_sans_i2_italic_200.woff2?v=1684753596',
  'VedaFont[Quire Sans]+quire_sans_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quire_Sans_quire_sans_n9_normal_900.woff2?v=1684753596',
  'VedaFont[Quire Sans]+quire_sans_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quire_Sans_quire_sans_n2_normal_200.woff2?v=1684753596',
  'VedaFont[Rajdhani]+rajdhani_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Rajdhani_rajdhani_n4_normal_400.woff2?v=1684753596',
  'VedaFont[Quire Sans]+quire_sans_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quire_Sans_quire_sans_i6_italic_600.woff2?v=1684753596',
  'VedaFont[Quicksand]+quicksand_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quicksand_quicksand_n3_normal_300.woff2?v=1684753596',
  'VedaFont[Quire Sans]+quire_sans_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quire_Sans_quire_sans_n3_normal_300.woff2?v=1684753596',
  'VedaFont[Quire Sans]+quire_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quire_Sans_quire_sans_n4_normal_400.woff2?v=1684753596',
  'VedaFont[Quire Sans]+quire_sans_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quire_Sans_quire_sans_n1_normal_100.woff2?v=1684753596',
  'VedaFont[Quicksand]+quicksand_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quicksand_quicksand_n7_normal_700.woff2?v=1684753596',
  'VedaFont[Quire Sans]+quire_sans_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quire_Sans_quire_sans_i5_italic_500.woff2?v=1684753596',
  'VedaFont[Quantico]+quantico_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quantico_quantico_n7_normal_700.woff2?v=1684753596',
  'VedaFont[Questrial]+questrial_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Questrial_questrial_n4_normal_400.woff2?v=1684753596',
  'VedaFont[Quattrocento]+quattrocento_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quattrocento_quattrocento_n7_normal_700.woff2?v=1684753596',
  'VedaFont[Quattrocento Sans]+quattrocento_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quattrocento_Sans_quattrocento_sans_i4_italic_400.woff2?v=1684753596',
  'VedaFont[PT Serif]+pt_serif_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_PT_Serif_pt_serif_n4_normal_400.woff2?v=1684753596',
  'VedaFont[PT Serif]+pt_serif_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_PT_Serif_pt_serif_n7_normal_700.woff2?v=1684753596',
  'VedaFont[PT Sans Narrow]+pt_sans_narrow_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_PT_Sans_Narrow_pt_sans_narrow_n7_normal_700.woff2?v=1684753596',
  'VedaFont[PT Sans]+pt_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_PT_Sans_pt_sans_n7_normal_700.woff2?v=1684753596',
  'VedaFont[PT Sans]+pt_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_PT_Sans_pt_sans_i7_italic_700.woff2?v=1684753596',
  'VedaFont[PT Sans]+pt_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_PT_Sans_pt_sans_n4_normal_400.woff2?v=1684753596',
  'VedaFont[Quantico]+quantico_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quantico_quantico_n4_normal_400.woff2?v=1684753596',
  'VedaFont[Proza Libre]+proza_libre_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Proza_Libre_proza_libre_n8_normal_800.woff2?v=1684753596',
  'VedaFont[Quicksand]+quicksand_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quicksand_quicksand_n5_normal_500.woff2?v=1684753596',
  'VedaFont[Quattrocento]+quattrocento_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quattrocento_quattrocento_n4_normal_400.woff2?v=1684753596',
  'VedaFont[Proza Libre]+proza_libre_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Proza_Libre_proza_libre_i7_italic_700.woff2?v=1684753596',
  'VedaFont[PT Serif]+pt_serif_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_PT_Serif_pt_serif_i4_italic_400.woff2?v=1684753596',
  'VedaFont[Quattrocento Sans]+quattrocento_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quattrocento_Sans_quattrocento_sans_i7_italic_700.woff2?v=1684753596',
  'VedaFont[Quire Sans]+quire_sans_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quire_Sans_quire_sans_i8_italic_800.woff2?v=1684753596',
  'VedaFont[PT Serif]+pt_serif_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_PT_Serif_pt_serif_i7_italic_700.woff2?v=1684753596',
  'VedaFont[Proza Libre]+proza_libre_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Proza_Libre_proza_libre_i8_italic_800.woff2?v=1684753596',
  'VedaFont[Prompt]+prompt_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Prompt_prompt_n7_normal_700.woff2?v=1684753596',
  'VedaFont[Quattrocento Sans]+quattrocento_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quattrocento_Sans_quattrocento_sans_n4_normal_400.woff2?v=1684753596',
  'VedaFont[Quantico]+quantico_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quantico_quantico_i7_italic_700.woff2?v=1684753596',
  'VedaFont[Quire Sans]+quire_sans_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quire_Sans_quire_sans_i1_italic_100.woff2?v=1684753596',
  'VedaFont[Quire Sans]+quire_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quire_Sans_quire_sans_i7_italic_700.woff2?v=1684753596',
  'VedaFont[Quattrocento Sans]+quattrocento_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quattrocento_Sans_quattrocento_sans_n7_normal_700.woff2?v=1684753596',
  'VedaFont[Quantico]+quantico_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quantico_quantico_i4_italic_400.woff2?v=1684753596',
  'VedaFont[PT Mono]+pt_mono_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_PT_Mono_pt_mono_n4_normal_400.woff2?v=1684753596',
  'VedaFont[PT Sans Narrow]+pt_sans_narrow_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_PT_Sans_Narrow_pt_sans_narrow_n4_normal_400.woff2?v=1684753596',
  'VedaFont[Proza Libre]+proza_libre_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Proza_Libre_proza_libre_n7_normal_700.woff2?v=1684753596',
  'VedaFont[Proza Libre]+proza_libre_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Proza_Libre_proza_libre_n6_normal_600.woff2?v=1684753596',
  'VedaFont[Proza Libre]+proza_libre_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Proza_Libre_proza_libre_n4_normal_400.woff2?v=1684753596',
  'VedaFont[Poppins]+poppins_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Poppins_poppins_n5_normal_500.woff2?v=1684753596',
  'VedaFont[Proza Libre]+proza_libre_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Proza_Libre_proza_libre_n5_normal_500.woff2?v=1684753596',
  'VedaFont[Poppins]+poppins_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Poppins_poppins_n6_normal_600.woff2?v=1684753596',
  'VedaFont[Prompt]+prompt_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Prompt_prompt_n6_normal_600.woff2?v=1684753596',
  'VedaFont[Proza Libre]+proza_libre_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Proza_Libre_proza_libre_i5_italic_500.woff2?v=1684753596',
  'VedaFont[Prata]+prata_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Prata_prata_n4_normal_400.woff2?v=1684753596',
  'VedaFont[Proza Libre]+proza_libre_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Proza_Libre_proza_libre_i6_italic_600.woff2?v=1684753596',
  'VedaFont[Prompt]+prompt_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Prompt_prompt_n8_normal_800.woff2?v=1684753596',
  'VedaFont[Prompt]+prompt_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Prompt_prompt_i5_italic_500.woff2?v=1684753596',
  'VedaFont[Prompt]+prompt_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Prompt_prompt_n5_normal_500.woff2?v=1684753596',
  'VedaFont[Prompt]+prompt_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Prompt_prompt_i1_italic_100.woff2?v=1684753596',
  'VedaFont[Poppins]+poppins_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Poppins_poppins_n9_normal_900.woff2?v=1684753596',
  'VedaFont[Prompt]+prompt_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Prompt_prompt_i9_italic_900.woff2?v=1684753596',
  'VedaFont[Prompt]+prompt_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Prompt_prompt_i7_italic_700.woff2?v=1684753596',
  'VedaFont[Prompt]+prompt_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Prompt_prompt_n4_normal_400.woff2?v=1684753596',
  'VedaFont[Prompt]+prompt_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Prompt_prompt_n9_normal_900.woff2?v=1684753596',
  'VedaFont[Prompt]+prompt_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Prompt_prompt_i6_italic_600.woff2?v=1684753596',
  'VedaFont[Prompt]+prompt_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Prompt_prompt_n2_normal_200.woff2?v=1684753596',
  'VedaFont[Prompt]+prompt_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Prompt_prompt_i2_italic_200.woff2?v=1684753596',
  'VedaFont[Prompt]+prompt_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Prompt_prompt_i4_italic_400.woff2?v=1684753596',
  'VedaFont[Prompt]+prompt_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Prompt_prompt_n1_normal_100.woff2?v=1684753596',
  'VedaFont[Prompt]+prompt_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Prompt_prompt_i3_italic_300.woff2?v=1684753596',
  'VedaFont[Poppins]+poppins_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Poppins_poppins_n8_normal_800.woff2?v=1684753596',
  'VedaFont[Proza Libre]+proza_libre_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Proza_Libre_proza_libre_i4_italic_400.woff2?v=1684753596',
  'VedaFont[Poppins]+poppins_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Poppins_poppins_n7_normal_700.woff2?v=1684753596',
  'VedaFont[Prompt]+prompt_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Prompt_prompt_n3_normal_300.woff2?v=1684753596',
  'VedaFont[Prompt]+prompt_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Prompt_prompt_i8_italic_800.woff2?v=1684753596',
  'VedaFont[Playfair Display]+playfair_display_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Playfair_Display_playfair_display_i9_italic_900.woff2?v=1684753596',
  'VedaFont[Poppins]+poppins_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Poppins_poppins_i8_italic_800.woff2?v=1684753596',
  'VedaFont[Poppins]+poppins_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Poppins_poppins_n4_normal_400.woff2?v=1684753596',
  'VedaFont[Poppins]+poppins_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Poppins_poppins_n2_normal_200.woff2?v=1684753596',
  'VedaFont[Poppins]+poppins_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Poppins_poppins_i7_italic_700.woff2?v=1684753596',
  'VedaFont[Poppins]+poppins_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Poppins_poppins_i1_italic_100.woff2?v=1684753596',
  'VedaFont[Poppins]+poppins_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Poppins_poppins_i9_italic_900.woff2?v=1684753596',
  'VedaFont[Playfair Display]+playfair_display_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Playfair_Display_playfair_display_n9_normal_900.woff2?v=1684753596',
  'VedaFont[PMN Caecilia]+pmn_caecilia_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_PMN_Caecilia_pmn_caecilia_n4_normal_400.woff2?v=1684753596',
  'VedaFont[PMN Caecilia]+pmn_caecilia_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_PMN_Caecilia_pmn_caecilia_i9_italic_900.woff2?v=1684753596',
  'VedaFont[Playfair Display]+playfair_display_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Playfair_Display_playfair_display_i4_italic_400.woff2?v=1684753596',
  'VedaFont[Poppins]+poppins_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Poppins_poppins_i5_italic_500.woff2?v=1684753596',
  'VedaFont[Poppins]+poppins_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Poppins_poppins_n3_normal_300.woff2?v=1684753596',
  'VedaFont[PMN Caecilia]+pmn_caecilia_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_PMN_Caecilia_pmn_caecilia_n3_normal_300.woff2?v=1684753596',
  'VedaFont[Playball]+playball_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Playball_playball_n4_normal_400.woff2?v=1684753596',
  'VedaFont[PMN Caecilia]+pmn_caecilia_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_PMN_Caecilia_pmn_caecilia_i7_italic_700.woff2?v=1684753596',
  'VedaFont[PMN Caecilia]+pmn_caecilia_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_PMN_Caecilia_pmn_caecilia_i4_italic_400.woff2?v=1684753596',
  'VedaFont[Playfair Display]+playfair_display_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Playfair_Display_playfair_display_n7_normal_700.woff2?v=1684753596',
  'VedaFont[Poppins]+poppins_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Poppins_poppins_n1_normal_100.woff2?v=1684753596',
  'VedaFont[Poppins]+poppins_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Poppins_poppins_i3_italic_300.woff2?v=1684753596',
  'VedaFont[Poppins]+poppins_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Poppins_poppins_i4_italic_400.woff2?v=1684753596',
  'VedaFont[Playfair Display]+playfair_display_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Playfair_Display_playfair_display_n4_normal_400.woff2?v=1684753596',
  'VedaFont[PMN Caecilia]+pmn_caecilia_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_PMN_Caecilia_pmn_caecilia_n7_normal_700.woff2?v=1684753596',
  'VedaFont[PMN Caecilia]+pmn_caecilia_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_PMN_Caecilia_pmn_caecilia_i3_italic_300.woff2?v=1684753596',
  'VedaFont[Poppins]+poppins_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Poppins_poppins_i6_italic_600.woff2?v=1684753595',
  'VedaFont[Poppins]+poppins_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Poppins_poppins_i2_italic_200.woff2?v=1684753596',
  'VedaFont[Plantin]+plantin_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Plantin_plantin_n7_normal_700.woff2?v=1684753596',
  'VedaFont[PMN Caecilia]+pmn_caecilia_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_PMN_Caecilia_pmn_caecilia_n9_normal_900.woff2?v=1684753596',
  'VedaFont[Playfair Display]+playfair_display_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Playfair_Display_playfair_display_i7_italic_700.woff2?v=1684753596',
  'VedaFont[Plantin]+plantin_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Plantin_plantin_n6_normal_600.woff2?v=1684753595',
  'VedaFont[Quire Sans]+quire_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quire_Sans_quire_sans_i4_italic_400.woff2?v=1684753595',
  'VedaFont[Quire Sans]+quire_sans_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Quire_Sans_quire_sans_i3_italic_300.woff2?v=1684753595',
  'VedaFont[Plantin]+plantin_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Plantin_plantin_n4_normal_400.woff2?v=1684753595',
  'VedaFont[Perpetua]+perpetua_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Perpetua_perpetua_n7_normal_700.woff2?v=1684753546',
  'VedaFont[Oxygen]+oxygen_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Oxygen_oxygen_n4_normal_400.woff2?v=1684753546',
  'VedaFont[Parma]+parma_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Parma_parma_n4_normal_400.woff2?v=1684753546',
  'VedaFont[Perpetua]+perpetua_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Perpetua_perpetua_i4_italic_400.woff2?v=1684753546',
  'VedaFont[Perpetua]+perpetua_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Perpetua_perpetua_n4_normal_400.woff2?v=1684753546',
  'VedaFont[Oswald]+oswald_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Oswald_oswald_n6_normal_600.woff2?v=1684753546',
  'VedaFont[Perpetua]+perpetua_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Perpetua_perpetua_i7_italic_700.woff2?v=1684753546',
  'VedaFont[Plantin]+plantin_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Plantin_plantin_n3_normal_300.woff2?v=1684753546',
  'VedaFont[Parma]+parma_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Parma_parma_n7_normal_700.woff2?v=1684753546',
  'VedaFont[Oswald]+oswald_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Oswald_oswald_n2_normal_200.woff2?v=1684753546',
  'VedaFont[Plantin]+plantin_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Plantin_plantin_i3_italic_300.woff2?v=1684753546',
  'VedaFont[Plantin]+plantin_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Plantin_plantin_i4_italic_400.woff2?v=1684753546',
  'VedaFont[Ovo]+ovo_n4+normal+400.woff2': 'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Ovo_ovo_n4_normal_400.woff2?v=1684753546',
  'VedaFont[Optima]+nova_optima_nova_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Optima_nova_optima_nova_n7_normal_700.woff2?v=1684753546',
  'VedaFont[Oswald]+oswald_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Oswald_oswald_n7_normal_700.woff2?v=1684753546',
  'VedaFont[Optima]+nova_optima_nova_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Optima_nova_optima_nova_n4_normal_400.woff2?v=1684753546',
  'VedaFont[Parma]+parma_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Parma_parma_i4_italic_400.woff2?v=1684753546',
  'VedaFont[Optima]+nova_optima_nova_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Optima_nova_optima_nova_n8_normal_800.woff2?v=1684753546',
  'VedaFont[Optima]+nova_optima_nova_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Optima_nova_optima_nova_i8_italic_800.woff2?v=1684753546',
  'VedaFont[Oswald]+oswald_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Oswald_oswald_n4_normal_400.woff2?v=1684753546',
  'VedaFont[Optima]+nova_optima_nova_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Optima_nova_optima_nova_n5_normal_500.woff2?v=1684753546',
  'VedaFont[Plantin]+plantin_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Plantin_plantin_i6_italic_600.woff2?v=1684753546',
  'VedaFont[Oswald]+oswald_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Oswald_oswald_n5_normal_500.woff2?v=1684753546',
  'VedaFont[Oxygen]+oxygen_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Oxygen_oxygen_n3_normal_300.woff2?v=1684753546',
  'VedaFont[Plantin]+plantin_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Plantin_plantin_i7_italic_700.woff2?v=1684753546',
  'VedaFont[Pacifico]+pacifico_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Pacifico_pacifico_n4_normal_400.woff2?v=1684753546',
  'VedaFont[Optima]+nova_optima_nova_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Optima_nova_optima_nova_i9_italic_900.woff2?v=1684753546',
  'VedaFont[Optima]+nova_optima_nova_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Optima_nova_optima_nova_n9_normal_900.woff2?v=1684753546',
  'VedaFont[Oxygen]+oxygen_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Oxygen_oxygen_n7_normal_700.woff2?v=1684753546',
  'VedaFont[Nunito]+nunito_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_nunito_n3_normal_300.woff2?v=1684753546',
  'VedaFont[Optima]+nova_optima_nova_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Optima_nova_optima_nova_n3_normal_300.woff2?v=1684753546',
  'VedaFont[Oswald]+oswald_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Oswald_oswald_n3_normal_300.woff2?v=1684753546',
  'VedaFont[Nunito]+nunito_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_nunito_n2_normal_200.woff2?v=1684753546',
  'VedaFont[Nunito]+nunito_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_nunito_i8_italic_800.woff2?v=1684753546',
  'VedaFont[Optima]+nova_optima_nova_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Optima_nova_optima_nova_n6_normal_600.woff2?v=1684753546',
  'VedaFont[Nunito]+nunito_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_nunito_i9_italic_900.woff2?v=1684753546',
  'VedaFont[Optima]+nova_optima_nova_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Optima_nova_optima_nova_i6_italic_600.woff2?v=1684753546',
  'VedaFont[Open Sans]+open_sans_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Open_Sans_open_sans_i3_italic_300.woff2?v=1684753546',
  'VedaFont[Open Sans]+open_sans_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Open_Sans_open_sans_i6_italic_600.woff2?v=1684753546',
  'VedaFont[Open Sans Condensed]+open_sans_condensed_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Open_Sans_Condensed_open_sans_condensed_n7_normal_700.woff2?v=1684753546',
  'VedaFont[Optima]+nova_optima_nova_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Optima_nova_optima_nova_i7_italic_700.woff2?v=1684753546',
  'VedaFont[Open Sans]+open_sans_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Open_Sans_open_sans_n3_normal_300.woff2?v=1684753546',
  'VedaFont[Open Sans]+open_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Open_Sans_open_sans_n4_normal_400.woff2?v=1684753546',
  'VedaFont[Open Sans]+open_sans_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Open_Sans_open_sans_i8_italic_800.woff2?v=1684753546',
  'VedaFont[Open Sans]+open_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Open_Sans_open_sans_n7_normal_700.woff2?v=1684753546',
  'VedaFont[Optima]+nova_optima_nova_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Optima_nova_optima_nova_i3_italic_300.woff2?v=1684753546',
  'VedaFont[Open Sans]+open_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Open_Sans_open_sans_i4_italic_400.woff2?v=1684753546',
  'VedaFont[Open Sans]+open_sans_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Open_Sans_open_sans_n6_normal_600.woff2?v=1684753546',
  'VedaFont[Optima]+nova_optima_nova_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Optima_nova_optima_nova_i5_italic_500.woff2?v=1684753546',
  'VedaFont[Open Sans]+open_sans_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Open_Sans_open_sans_n8_normal_800.woff2?v=1684753546',
  'VedaFont[Optima]+nova_optima_nova_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Optima_nova_optima_nova_i4_italic_400.woff2?v=1684753546',
  'VedaFont[Open Sans]+open_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Open_Sans_open_sans_i7_italic_700.woff2?v=1684753546',
  'VedaFont[Nunito Sans]+nunito_sans_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_Sans_nunito_sans_n2_normal_200.woff2?v=1684753546',
  'VedaFont[Open Sans Condensed]+open_sans_condensed_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Open_Sans_Condensed_open_sans_condensed_i3_italic_300.woff2?v=1684753546',
  'VedaFont[Nunito]+nunito_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_nunito_n7_normal_700.woff2?v=1684753546',
  'VedaFont[Nunito]+nunito_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_nunito_n4_normal_400.woff2?v=1684753546',
  'VedaFont[Noticia Text]+noticia_text_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Noticia_Text_noticia_text_n7_normal_700.woff2?v=1684753546',
  'VedaFont[Nunito Sans]+nunito_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_Sans_nunito_sans_n7_normal_700.woff2?v=1684753546',
  'VedaFont[Old Standard TT]+old_standard_tt_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Old_Standard_TT_old_standard_tt_i4_italic_400.woff2?v=1684753546',
  'VedaFont[Nunito]+nunito_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_nunito_i2_italic_200.woff2?v=1684753546',
  'VedaFont[Nunito Sans]+nunito_sans_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_Sans_nunito_sans_n3_normal_300.woff2?v=1684753546',
  'VedaFont[Nunito]+nunito_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_nunito_n9_normal_900.woff2?v=1684753546',
  'VedaFont[Nunito Sans]+nunito_sans_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_Sans_nunito_sans_n8_normal_800.woff2?v=1684753546',
  'VedaFont[Noticia Text]+noticia_text_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Noticia_Text_noticia_text_i4_italic_400.woff2?v=1684753546',
  'VedaFont[Nunito Sans]+nunito_sans_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_Sans_nunito_sans_i8_italic_800.woff2?v=1684753546',
  'VedaFont[Nunito]+nunito_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_nunito_i4_italic_400.woff2?v=1684753546',
  'VedaFont[Nunito]+nunito_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_nunito_n6_normal_600.woff2?v=1684753546',
  'VedaFont[Nunito]+nunito_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_nunito_n8_normal_800.woff2?v=1684753546',
  'VedaFont[Nunito Sans]+nunito_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_Sans_nunito_sans_n4_normal_400.woff2?v=1684753546',
  'VedaFont[Nunito]+nunito_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_nunito_i7_italic_700.woff2?v=1684753546',
  'VedaFont[Old Standard TT]+old_standard_tt_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Old_Standard_TT_old_standard_tt_n7_normal_700.woff2?v=1684753546',
  'VedaFont[Nunito Sans]+nunito_sans_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_Sans_nunito_sans_n9_normal_900.woff2?v=1684753546',
  'VedaFont[Nunito Sans]+nunito_sans_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_Sans_nunito_sans_n6_normal_600.woff2?v=1684753546',
  'VedaFont[Old Standard TT]+old_standard_tt_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Old_Standard_TT_old_standard_tt_n4_normal_400.woff2?v=1684753546',
  'VedaFont[Nunito]+nunito_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_nunito_i3_italic_300.woff2?v=1684753546',
  'VedaFont[Open Sans Condensed]+open_sans_condensed_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Open_Sans_Condensed_open_sans_condensed_n3_normal_300.woff2?v=1684753546',
  'VedaFont[Nunito]+nunito_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_nunito_i6_italic_600.woff2?v=1684753546',
  'VedaFont[Nunito Sans]+nunito_sans_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_Sans_nunito_sans_i9_italic_900.woff2?v=1684753546',
  'VedaFont[Nunito Sans]+nunito_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_Sans_nunito_sans_i7_italic_700.woff2?v=1684753546',
  'VedaFont[Noto Serif]+noto_serif_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Noto_Serif_noto_serif_n4_normal_400.woff2?v=1684753546',
  'VedaFont[Nunito Sans]+nunito_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_Sans_nunito_sans_i4_italic_400.woff2?v=1684753546',
  'VedaFont[Noto Serif]+noto_serif_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Noto_Serif_noto_serif_n7_normal_700.woff2?v=1684753545',
  'VedaFont[Noto Serif]+noto_serif_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Noto_Serif_noto_serif_i7_italic_700.woff2?v=1684753546',
  'VedaFont[Nunito Sans]+nunito_sans_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_Sans_nunito_sans_i6_italic_600.woff2?v=1684753545',
  'VedaFont[Nunito Sans]+nunito_sans_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_Sans_nunito_sans_i3_italic_300.woff2?v=1684753546',
  'VedaFont[Noto Serif]+noto_serif_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Noto_Serif_noto_serif_i4_italic_400.woff2?v=1684753546',
  'VedaFont[Nunito Sans]+nunito_sans_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nunito_Sans_nunito_sans_i2_italic_200.woff2?v=1684753545',
  'VedaFont[Noticia Text]+noticia_text_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Noticia_Text_noticia_text_i7_italic_700.woff2?v=1684753545',
  'VedaFont[News Plantin]+news_plantin_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_Plantin_news_plantin_n7_normal_700.woff2?v=1684753546',
  'VedaFont[News Plantin]+news_plantin_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_Plantin_news_plantin_n4_normal_400.woff2?v=1684753546',
  'VedaFont[News Gothic No]+news_gothic_no_n8+normal+22800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_Gothic_No_2_news_gothic_no_2_n8_normal_800.woff2?v=1684753545',
  'VedaFont[Noticia Text]+noticia_text_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Noticia_Text_noticia_text_n4_normal_400.woff2?v=1684753545',
  'VedaFont[Nobile]+nobile_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nobile_nobile_i5_italic_500.woff2?v=1684753545',
  'VedaFont[Nobile]+nobile_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nobile_nobile_n5_normal_500.woff2?v=1684753545',
  'VedaFont[News Plantin]+news_plantin_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_Plantin_news_plantin_i7_italic_700.woff2?v=1684753545',
  'VedaFont[Nobile]+nobile_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nobile_nobile_i4_italic_400.woff2?v=1684753546',
  'VedaFont[Nobile]+nobile_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nobile_nobile_n4_normal_400.woff2?v=1684753545',
  'VedaFont[Nobile]+nobile_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nobile_nobile_i7_italic_700.woff2?v=1684753545',
  'VedaFont[Nobile]+nobile_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Nobile_nobile_n7_normal_700.woff2?v=1684753545',
  'VedaFont[News Plantin]+news_plantin_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_Plantin_news_plantin_i4_italic_400.woff2?v=1684753545',
  'VedaFont[News Cycle]+news_cycle_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_Cycle_news_cycle_n7_normal_700.woff2?v=1684753545',
  'VedaFont[News Gothic No]+news_gothic_no_i4+italic+22400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_Gothic_No_2_news_gothic_no_2_i4_italic_400.woff2?v=1684753545',
  'VedaFont[New Century Schoolbook]+new_century_schoolbook_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_New_Century_Schoolbook_new_century_schoolbook_i7_italic_700.woff2?v=1684753545',
  'VedaFont[News Gothic No]+news_gothic_no_n5+normal+22500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_Gothic_No_2_news_gothic_no_2_n5_normal_500.woff2?v=1684753545',
  'VedaFont[Neue Plak]+neue_plak_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Plak_neue_plak_i2_italic_200.woff2?v=1684753545',
  'VedaFont[Neuzeit Office]+neuzeit_office_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neuzeit_Office_neuzeit_office_n7_normal_700.woff2?v=1684753545',
  'VedaFont[News Gothic No]+news_gothic_no_i3+italic+22300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_Gothic_No_2_news_gothic_no_2_i3_italic_300.woff2?v=1684753545',
  'VedaFont[News Gothic No]+news_gothic_no_i8+italic+22800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_Gothic_No_2_news_gothic_no_2_i8_italic_800.woff2?v=1684753545',
  'VedaFont[Neue Swift]+neue_swift_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Swift_neue_swift_i4_italic_400.woff2?v=1684753545',
  'VedaFont[News Gothic No]+news_gothic_no_n7+normal+22700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_Gothic_No_2_news_gothic_no_2_n7_normal_700.woff2?v=1684753545',
  'VedaFont[News Gothic No]+news_gothic_no_i2+italic+22200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_Gothic_No_2_news_gothic_no_2_i2_italic_200.woff2?v=1684753545',
  'VedaFont[Neuton]+neuton_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neuton_neuton_n8_normal_800.woff2?v=1684753545',
  'VedaFont[New Century Schoolbook]+new_century_schoolbook_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_New_Century_Schoolbook_new_century_schoolbook_n7_normal_700.woff2?v=1684753545',
  'VedaFont[News Gothic No]+news_gothic_no_n2+normal+22200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_Gothic_No_2_news_gothic_no_2_n2_normal_200.woff2?v=1684753545',
  'VedaFont[Neue Haas Unica]+neue_haas_unica_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Haas_Unica_neue_haas_unica_n1_normal_100.woff2?v=1684753545',
  'VedaFont[News Gothic No]+news_gothic_no_n3+normal+22300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_Gothic_No_2_news_gothic_no_2_n3_normal_300.woff2?v=1684753545',
  'VedaFont[Neue Plak]+neue_plak_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Plak_neue_plak_n9_normal_900.woff2?v=1684753545',
  'VedaFont[News Cycle]+news_cycle_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_Cycle_news_cycle_n4_normal_400.woff2?v=1684753545',
  'VedaFont[News Gothic No]+news_gothic_no_i7+italic+22700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_Gothic_No_2_news_gothic_no_2_i7_italic_700.woff2?v=1684753545',
  'VedaFont[News]+news_i7+italic+705705700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_705_news_705_i7_italic_700.woff2?v=1684753545',
  'VedaFont[New Century Schoolbook]+new_century_schoolbook_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_New_Century_Schoolbook_new_century_schoolbook_i4_italic_400.woff2?v=1684753545',
  'VedaFont[Neue Haas Unica]+neue_haas_unica_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Haas_Unica_neue_haas_unica_i3_italic_300.woff2?v=1684753545',
  'VedaFont[News Gothic No]+news_gothic_no_i5+italic+22500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_Gothic_No_2_news_gothic_no_2_i5_italic_500.woff2?v=1684753545',
  'VedaFont[News]+news_i4+italic+705705400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_705_news_705_i4_italic_400.woff2?v=1684753545',
  'VedaFont[News Gothic No]+news_gothic_no_n4+normal+22400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_Gothic_No_2_news_gothic_no_2_n4_normal_400.woff2?v=1684753545',
  'VedaFont[Neuzeit S]+neuzeit_s_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neuzeit_S_neuzeit_s_n4_normal_400.woff2?v=1684753545',
  'VedaFont[Neue Frutiger]+neue_frutiger_i7+italic+14501450700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Frutiger_1450_neue_frutiger_1450_i7_italic_700.woff2?v=1684753545',
  'VedaFont[Neue Frutiger]+neue_frutiger_n3+normal+14501450300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Frutiger_1450_neue_frutiger_1450_n3_normal_300.woff2?v=1684753545',
  'VedaFont[Neue Aachen]+neue_aachen_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Aachen_neue_aachen_n8_normal_800.woff2?v=1684753545',
  'VedaFont[News]+news_i4+italic+702702400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_702_news_702_i4_italic_400.woff2?v=1684753545',
  'VedaFont[News]+news_n7+normal+705705700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_705_news_705_n7_normal_700.woff2?v=1684753545',
  'VedaFont[News]+news_n7+normal+702702700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_702_news_702_n7_normal_700.woff2?v=1684753545',
  'VedaFont[Neuzeit S]+neuzeit_s_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neuzeit_S_neuzeit_s_n9_normal_900.woff2?v=1684753545',
  'VedaFont[News]+news_n4+normal+702702400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_702_news_702_n4_normal_400.woff2?v=1684753545',
  'VedaFont[Neue Aachen]+neue_aachen_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Aachen_neue_aachen_i7_italic_700.woff2?v=1684753545',
  'VedaFont[News]+news_n4+normal+705705400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_705_news_705_n4_normal_400.woff2?v=1684753545',
  'VedaFont[New Century Schoolbook]+new_century_schoolbook_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_New_Century_Schoolbook_new_century_schoolbook_n4_normal_400.woff2?v=1684753545',
  'VedaFont[Neuzeit Office Soft Rounded]+neuzeit_office_soft_rounded_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neuzeit_Office_Soft_Rounded_neuzeit_office_soft_rounded_n7_normal_700.woff2?v=1684753545',
  'VedaFont[News]+news_i7+italic+702702700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_News_702_news_702_i7_italic_700.woff2?v=1684753545',
  'VedaFont[Neue Frutiger]+neue_frutiger_n6+normal+14501450600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Frutiger_1450_neue_frutiger_1450_n6_normal_600.woff2?v=1684753545',
  'VedaFont[Neue Plak]+neue_plak_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Plak_neue_plak_n7_normal_700.woff2?v=1684753545',
  'VedaFont[Neuton]+neuton_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neuton_neuton_n7_normal_700.woff2?v=1684753545',
  'VedaFont[Neuton]+neuton_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neuton_neuton_n3_normal_300.woff2?v=1684753545',
  'VedaFont[Neuzeit Office Soft Rounded]+neuzeit_office_soft_rounded_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neuzeit_Office_Soft_Rounded_neuzeit_office_soft_rounded_n4_normal_400.woff2?v=1684753545',
  'VedaFont[Neue Swift]+neue_swift_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Swift_neue_swift_n5_normal_500.woff2?v=1684753545',
  'VedaFont[Neue Swift]+neue_swift_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Swift_neue_swift_i3_italic_300.woff2?v=1684753545',
  'VedaFont[Neue Swift]+neue_swift_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Swift_neue_swift_i6_italic_600.woff2?v=1684753545',
  'VedaFont[Neue Plak]+neue_plak_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Plak_neue_plak_n3_normal_300.woff2?v=1684753545',
  'VedaFont[Neuton]+neuton_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neuton_neuton_i4_italic_400.woff2?v=1684753545',
  'VedaFont[Neue Swift]+neue_swift_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Swift_neue_swift_n3_normal_300.woff2?v=1684753545',
  'VedaFont[Neuton]+neuton_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neuton_neuton_n4_normal_400.woff2?v=1684753545',
  'VedaFont[Neue Swift]+neue_swift_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Swift_neue_swift_i5_italic_500.woff2?v=1684753545',
  'VedaFont[Neue Swift]+neue_swift_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Swift_neue_swift_n6_normal_600.woff2?v=1684753545',
  'VedaFont[Neue Plak]+neue_plak_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Plak_neue_plak_n6_normal_600.woff2?v=1684753545',
  'VedaFont[Neue Plak]+neue_plak_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Plak_neue_plak_n2_normal_200.woff2?v=1684753545',
  'VedaFont[Neue Plak]+neue_plak_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Plak_neue_plak_i7_italic_700.woff2?v=1684753545',
  'VedaFont[Neue Swift]+neue_swift_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Swift_neue_swift_i7_italic_700.woff2?v=1684753545',
  'VedaFont[Neuton]+neuton_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neuton_neuton_n2_normal_200.woff2?v=1684753545',
  'VedaFont[Neue Plak]+neue_plak_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Plak_neue_plak_n4_normal_400.woff2?v=1684753545',
  'VedaFont[Neue Plak]+neue_plak_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Plak_neue_plak_i6_italic_600.woff2?v=1684753545',
  'VedaFont[Neue Plak]+neue_plak_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Plak_neue_plak_i9_italic_900.woff2?v=1684753545',
  'VedaFont[Neue Swift]+neue_swift_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Swift_neue_swift_n4_normal_400.woff2?v=1684753545',
  'VedaFont[Neue Haas Unica]+neue_haas_unica_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Haas_Unica_neue_haas_unica_n7_normal_700.woff2?v=1684753545',
  'VedaFont[Neue Haas Unica]+neue_haas_unica_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Haas_Unica_neue_haas_unica_n5_normal_500.woff2?v=1684753545',
  'VedaFont[Neue Plak]+neue_plak_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Plak_neue_plak_i3_italic_300.woff2?v=1684753545',
  'VedaFont[Neue Haas Unica]+neue_haas_unica_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Haas_Unica_neue_haas_unica_n6_normal_600.woff2?v=1684753545',
  'VedaFont[Neue Haas Unica]+neue_haas_unica_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Haas_Unica_neue_haas_unica_n9_normal_900.woff2?v=1684753545',
  'VedaFont[Neue Haas Unica]+neue_haas_unica_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Haas_Unica_neue_haas_unica_n3_normal_300.woff2?v=1684753545',
  'VedaFont[Neue Plak]+neue_plak_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Plak_neue_plak_i4_italic_400.woff2?v=1684753545',
  'VedaFont[Neue Haas Unica]+neue_haas_unica_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Haas_Unica_neue_haas_unica_n8_normal_800.woff2?v=1684753545',
  'VedaFont[Neue Haas Unica]+neue_haas_unica_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Haas_Unica_neue_haas_unica_n4_normal_400.woff2?v=1684753545',
  'VedaFont[Neue Haas Unica]+neue_haas_unica_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Haas_Unica_neue_haas_unica_i6_italic_600.woff2?v=1684753545',
  'VedaFont[Neue Haas Unica]+neue_haas_unica_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Haas_Unica_neue_haas_unica_i5_italic_500.woff2?v=1684753545',
  'VedaFont[Neue Haas Unica]+neue_haas_unica_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Haas_Unica_neue_haas_unica_i4_italic_400.woff2?v=1684753545',
  'VedaFont[Neue Haas Unica]+neue_haas_unica_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Haas_Unica_neue_haas_unica_i7_italic_700.woff2?v=1684753545',
  'VedaFont[Neue Haas Unica]+neue_haas_unica_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Haas_Unica_neue_haas_unica_n2_normal_200.woff2?v=1684753545',
  'VedaFont[Neue Frutiger]+neue_frutiger_n7+normal+14501450700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Frutiger_1450_neue_frutiger_1450_n7_normal_700.woff2?v=1684753545',
  'VedaFont[Neue Haas Unica]+neue_haas_unica_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Haas_Unica_neue_haas_unica_i1_italic_100.woff2?v=1684753545',
  'VedaFont[Neue Haas Unica]+neue_haas_unica_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Haas_Unica_neue_haas_unica_i8_italic_800.woff2?v=1684753545',
  'VedaFont[Neue Haas Unica]+neue_haas_unica_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Haas_Unica_neue_haas_unica_i9_italic_900.woff2?v=1684753545',
  'VedaFont[Neue Frutiger]+neue_frutiger_i6+italic+14501450600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Frutiger_1450_neue_frutiger_1450_i6_italic_600.woff2?v=1684753545',
  'VedaFont[Neue Frutiger]+neue_frutiger_i3+italic+14501450300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Frutiger_1450_neue_frutiger_1450_i3_italic_300.woff2?v=1684753545',
  'VedaFont[Neue Aachen]+neue_aachen_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Aachen_neue_aachen_n9_normal_900.woff2?v=1684753545',
  'VedaFont[Neue Aachen]+neue_aachen_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Aachen_neue_aachen_n3_normal_300.woff2?v=1684753545',
  'VedaFont[Neue Haas Unica]+neue_haas_unica_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Haas_Unica_neue_haas_unica_i2_italic_200.woff2?v=1684753545',
  'VedaFont[Neue Aachen]+neue_aachen_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Aachen_neue_aachen_n7_normal_700.woff2?v=1684753545',
  'VedaFont[Neue Aachen]+neue_aachen_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Aachen_neue_aachen_n4_normal_400.woff2?v=1684753545',
  'VedaFont[Neue Aachen]+neue_aachen_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Aachen_neue_aachen_n5_normal_500.woff2?v=1684753545',
  'VedaFont[Neue Aachen]+neue_aachen_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Aachen_neue_aachen_i8_italic_800.woff2?v=1684753545',
  'VedaFont[Neue Aachen]+neue_aachen_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Aachen_neue_aachen_n2_normal_200.woff2?v=1684753545',
  'VedaFont[Neue Aachen]+neue_aachen_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Aachen_neue_aachen_i9_italic_900.woff2?v=1684753545',
  'VedaFont[Neue Aachen]+neue_aachen_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Aachen_neue_aachen_i6_italic_600.woff2?v=1684753545',
  'VedaFont[Neuzeit Office]+neuzeit_office_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neuzeit_Office_neuzeit_office_i7_italic_700.woff2?v=1684753545',
  'VedaFont[Neue Aachen]+neue_aachen_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Aachen_neue_aachen_n1_normal_100.woff2?v=1684753544',
  'VedaFont[Neue Aachen]+neue_aachen_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Aachen_neue_aachen_n6_normal_600.woff2?v=1684753545',
  'VedaFont[Neue Frutiger]+neue_frutiger_i4+italic+14501450400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Frutiger_1450_neue_frutiger_1450_i4_italic_400.woff2?v=1684753545',
  'VedaFont[Neuzeit Office]+neuzeit_office_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neuzeit_Office_neuzeit_office_n4_normal_400.woff2?v=1684753544',
  'VedaFont[Neuzeit Office]+neuzeit_office_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neuzeit_Office_neuzeit_office_i4_italic_400.woff2?v=1684753544',
  'VedaFont[Neue Swift]+neue_swift_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Swift_neue_swift_n7_normal_700.woff2?v=1684753544',
  'VedaFont[Neue Frutiger]+neue_frutiger_n4+normal+14501450400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Frutiger_1450_neue_frutiger_1450_n4_normal_400.woff2?v=1684753544',
  'VedaFont[Neue Aachen]+neue_aachen_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Aachen_neue_aachen_i4_italic_400.woff2?v=1684753484',
  'VedaFont[Montserrat]+montserrat_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Montserrat_montserrat_i4_italic_400.woff2?v=1684753484',
  'VedaFont[Neo Sans]+neo_sans_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neo_Sans_neo_sans_n8_normal_800.woff2?v=1684753484',
  'VedaFont[Neue Aachen]+neue_aachen_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Aachen_neue_aachen_i1_italic_100.woff2?v=1684753484',
  'VedaFont[Neo Sans]+neo_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neo_Sans_neo_sans_n7_normal_700.woff2?v=1684753484',
  'VedaFont[Neo Sans]+neo_sans_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neo_Sans_neo_sans_i3_italic_300.woff2?v=1684753484',
  'VedaFont[Neue Aachen]+neue_aachen_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Aachen_neue_aachen_i3_italic_300.woff2?v=1684753484',
  'VedaFont[Neo Sans]+neo_sans_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neo_Sans_neo_sans_i9_italic_900.woff2?v=1684753484',
  'VedaFont[Neo Sans]+neo_sans_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neo_Sans_neo_sans_n5_normal_500.woff2?v=1684753484',
  'VedaFont[Neo Sans]+neo_sans_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neo_Sans_neo_sans_i8_italic_800.woff2?v=1684753483',
  'VedaFont[Neo Sans]+neo_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neo_Sans_neo_sans_n4_normal_400.woff2?v=1684753484',
  'VedaFont[Neue Aachen]+neue_aachen_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Aachen_neue_aachen_i5_italic_500.woff2?v=1684753484',
  'VedaFont[Neo Sans]+neo_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neo_Sans_neo_sans_i7_italic_700.woff2?v=1684753484',
  'VedaFont[Muli]+muli_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Muli_muli_i6_italic_600.woff2?v=1684753484',
  'VedaFont[Mundo Sans]+mundo_sans_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mundo_Sans_mundo_sans_i3_italic_300.woff2?v=1684753484',
  'VedaFont[Montserrat]+montserrat_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Montserrat_montserrat_n5_normal_500.woff2?v=1684753484',
  'VedaFont[Neo Sans]+neo_sans_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neo_Sans_neo_sans_i5_italic_500.woff2?v=1684753484',
  'VedaFont[Mundo Sans]+mundo_sans_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mundo_Sans_mundo_sans_n9_normal_900.woff2?v=1684753484',
  'VedaFont[Mundo Sans]+mundo_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mundo_Sans_mundo_sans_i7_italic_700.woff2?v=1684753484',
  'VedaFont[Neo Sans]+neo_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neo_Sans_neo_sans_i4_italic_400.woff2?v=1684753484',
  'VedaFont[Muli]+muli_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Muli_muli_i3_italic_300.woff2?v=1684753484',
  'VedaFont[Montserrat]+montserrat_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Montserrat_montserrat_n9_normal_900.woff2?v=1684753483',
  'VedaFont[Muli]+muli_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Muli_muli_i4_italic_400.woff2?v=1684753484',
  'VedaFont[Montserrat]+montserrat_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Montserrat_montserrat_i7_italic_700.woff2?v=1684753484',
  'VedaFont[Montserrat]+montserrat_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Montserrat_montserrat_n1_normal_100.woff2?v=1684753483',
  'VedaFont[Muli]+muli_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Muli_muli_i7_italic_700.woff2?v=1684753483',
  'VedaFont[Mundo Sans]+mundo_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mundo_Sans_mundo_sans_n4_normal_400.woff2?v=1684753483',
  'VedaFont[Montserrat]+montserrat_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Montserrat_montserrat_i6_italic_600.woff2?v=1684753483',
  'VedaFont[Mouse Memoirs]+mouse_memoirs_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mouse_Memoirs_mouse_memoirs_n4_normal_400.woff2?v=1684753483',
  'VedaFont[Mundo Sans]+mundo_sans_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mundo_Sans_mundo_sans_n8_normal_800.woff2?v=1684753483',
  'VedaFont[Muli]+muli_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Muli_muli_n9_normal_900.woff2?v=1684753483',
  'VedaFont[Montserrat]+montserrat_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Montserrat_montserrat_i5_italic_500.woff2?v=1684753483',
  'VedaFont[Mundo Sans]+mundo_sans_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mundo_Sans_mundo_sans_n5_normal_500.woff2?v=1684753483',
  'VedaFont[Muli]+muli_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Muli_muli_n3_normal_300.woff2?v=1684753484',
  'VedaFont[Montserrat]+montserrat_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Montserrat_montserrat_n8_normal_800.woff2?v=1684753483',
  'VedaFont[Muli]+muli_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Muli_muli_n2_normal_200.woff2?v=1684753483',
  'VedaFont[Muli]+muli_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Muli_muli_i2_italic_200.woff2?v=1684753483',
  'VedaFont[Montserrat]+montserrat_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Montserrat_montserrat_n2_normal_200.woff2?v=1684753483',
  'VedaFont[Montserrat]+montserrat_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Montserrat_montserrat_n3_normal_300.woff2?v=1684753483',
  'VedaFont[Montserrat]+montserrat_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Montserrat_montserrat_n4_normal_400.woff2?v=1684753483',
  'VedaFont[Montserrat]+montserrat_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Montserrat_montserrat_i8_italic_800.woff2?v=1684753483',
  'VedaFont[Neue Aachen]+neue_aachen_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neue_Aachen_neue_aachen_i2_italic_200.woff2?v=1684753483',
  'VedaFont[Montserrat]+montserrat_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Montserrat_montserrat_i3_italic_300.woff2?v=1684753483',
  'VedaFont[Monotype News Gothic]+monotype_news_gothic_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_News_Gothic_monotype_news_gothic_i7_italic_700.woff2?v=1684753483',
  'VedaFont[Neo Sans]+neo_sans_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neo_Sans_neo_sans_n9_normal_900.woff2?v=1684753483',
  'VedaFont[Monotype New Clarendon]+monotype_new_clarendon_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_New_Clarendon_monotype_new_clarendon_n5_normal_500.woff2?v=1684753483',
  'VedaFont[Montserrat]+montserrat_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Montserrat_montserrat_i2_italic_200.woff2?v=1684753483',
  'VedaFont[Monotype News Gothic]+monotype_news_gothic_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_News_Gothic_monotype_news_gothic_n4_normal_400.woff2?v=1684753483',
  'VedaFont[Monotype News Gothic]+monotype_news_gothic_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_News_Gothic_monotype_news_gothic_i4_italic_400.woff2?v=1684753483',
  'VedaFont[Mundo Sans]+mundo_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mundo_Sans_mundo_sans_n7_normal_700.woff2?v=1684753483',
  'VedaFont[Neo Sans]+neo_sans_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Neo_Sans_neo_sans_n3_normal_300.woff2?v=1684753483',
  'VedaFont[Monotype Sabon]+monotype_sabon_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Sabon_monotype_sabon_n4_normal_400.woff2?v=1684753483',
  'VedaFont[Mundo Sans]+mundo_sans_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mundo_Sans_mundo_sans_n3_normal_300.woff2?v=1684753483',
  'VedaFont[Mundo Sans]+mundo_sans_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mundo_Sans_mundo_sans_i9_italic_900.woff2?v=1684753483',
  'VedaFont[Mundo Sans]+mundo_sans_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mundo_Sans_mundo_sans_i5_italic_500.woff2?v=1684753483',
  'VedaFont[Mundo Sans]+mundo_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mundo_Sans_mundo_sans_i4_italic_400.woff2?v=1684753483',
  'VedaFont[Mundo Sans]+mundo_sans_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mundo_Sans_mundo_sans_n2_normal_200.woff2?v=1684753483',
  'VedaFont[Mundo Sans]+mundo_sans_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mundo_Sans_mundo_sans_i2_italic_200.woff2?v=1684753483',
  'VedaFont[Mundo Sans]+mundo_sans_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mundo_Sans_mundo_sans_i8_italic_800.woff2?v=1684753483',
  'VedaFont[Muli]+muli_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Muli_muli_n8_normal_800.woff2?v=1684753482',
  'VedaFont[Muli]+muli_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Muli_muli_n6_normal_600.woff2?v=1684753482',
  'VedaFont[Muli]+muli_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Muli_muli_n7_normal_700.woff2?v=1684753482',
  'VedaFont[Montserrat]+montserrat_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Montserrat_montserrat_n7_normal_700.woff2?v=1684753482',
  'VedaFont[Muli]+muli_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Muli_muli_n4_normal_400.woff2?v=1684753482',
  'VedaFont[Muli]+muli_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Muli_muli_i9_italic_900.woff2?v=1684753482',
  'VedaFont[Muli]+muli_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Muli_muli_i8_italic_800.woff2?v=1684753482',
  'VedaFont[Montserrat]+montserrat_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Montserrat_montserrat_n6_normal_600.woff2?v=1684753482',
  'VedaFont[Monotype Italian Old Style]+monotype_italian_old_style_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Italian_Old_Style_monotype_italian_old_style_i4_italic_400.woff2?v=1684753482',
  'VedaFont[Monotype Goudy]+monotype_goudy_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Goudy_monotype_goudy_n8_normal_800.woff2?v=1684753482',
  'VedaFont[Montserrat]+montserrat_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Montserrat_montserrat_i9_italic_900.woff2?v=1684753482',
  'VedaFont[Montserrat]+montserrat_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Montserrat_montserrat_i1_italic_100.woff2?v=1684753482',
  'VedaFont[Monotype Goudy Modern]+monotype_goudy_modern_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Goudy_Modern_monotype_goudy_modern_n7_normal_700.woff2?v=1684753482',
  'VedaFont[Monotype Century Old Style]+monotype_century_old_style_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Century_Old_Style_monotype_century_old_style_n5_normal_500.woff2?v=1684753482',
  'VedaFont[Monotype Goudy]+monotype_goudy_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Goudy_monotype_goudy_n7_normal_700.woff2?v=1684753482',
  'VedaFont[Monotype Goudy]+monotype_goudy_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Goudy_monotype_goudy_n4_normal_400.woff2?v=1684753482',
  'VedaFont[Monotype New Clarendon]+monotype_new_clarendon_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_New_Clarendon_monotype_new_clarendon_n7_normal_700.woff2?v=1684753482',
  'VedaFont[Monotype Goudy]+monotype_goudy_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Goudy_monotype_goudy_i4_italic_400.woff2?v=1684753482',
  'VedaFont[Monotype Goudy Modern]+monotype_goudy_modern_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Goudy_Modern_monotype_goudy_modern_i7_italic_700.woff2?v=1684753482',
  'VedaFont[Lobster]+lobster_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lobster_lobster_n4_normal_400.woff2?v=1684753482',
  'VedaFont[Monotype Century Old Style]+monotype_century_old_style_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Century_Old_Style_monotype_century_old_style_i5_italic_500.woff2?v=1684753482',
  'VedaFont[Monotype Bodoni]+monotype_bodoni_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Bodoni_monotype_bodoni_i9_italic_900.woff2?v=1684753482',
  'VedaFont[Monotype News Gothic]+monotype_news_gothic_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_News_Gothic_monotype_news_gothic_n7_normal_700.woff2?v=1684753482',
  'VedaFont[Monotype Sabon]+monotype_sabon_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Sabon_monotype_sabon_n6_normal_600.woff2?v=1684753482',
  'VedaFont[Monotype Century Old Style]+monotype_century_old_style_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Century_Old_Style_monotype_century_old_style_i7_italic_700.woff2?v=1684753482',
  'VedaFont[Monotype Sabon]+monotype_sabon_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Sabon_monotype_sabon_i6_italic_600.woff2?v=1684753482',
  'VedaFont[Monotype Goudy Modern]+monotype_goudy_modern_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Goudy_Modern_monotype_goudy_modern_n4_normal_400.woff2?v=1684753482',
  'VedaFont[Monotype Goudy Modern]+monotype_goudy_modern_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Goudy_Modern_monotype_goudy_modern_i4_italic_400.woff2?v=1684753482',
  'VedaFont[Monotype Bodoni]+monotype_bodoni_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Bodoni_monotype_bodoni_n8_normal_800.woff2?v=1684753482',
  'VedaFont[Metro Nova]+metro_nova_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Metro_Nova_metro_nova_n7_normal_700.woff2?v=1684753482',
  'VedaFont[Lobster Two]+lobster_two_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lobster_Two_lobster_two_n4_normal_400.woff2?v=1684753482',
  'VedaFont[Metro Nova]+metro_nova_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Metro_Nova_metro_nova_n4_normal_400.woff2?v=1684753481',
  'VedaFont[Monotype Italian Old Style]+monotype_italian_old_style_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Italian_Old_Style_monotype_italian_old_style_n4_normal_400.woff2?v=1684753482',
  'VedaFont[Lobster Two]+lobster_two_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lobster_Two_lobster_two_i7_italic_700.woff2?v=1684753482',
  'VedaFont[Monotype Italian Old Style]+monotype_italian_old_style_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Italian_Old_Style_monotype_italian_old_style_i7_italic_700.woff2?v=1684753482',
  'VedaFont[Merriweather Sans]+merriweather_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Merriweather_Sans_merriweather_sans_n7_normal_700.woff2?v=1684753482',
  'VedaFont[Metro Nova]+metro_nova_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Metro_Nova_metro_nova_n3_normal_300.woff2?v=1684753482',
  'VedaFont[Metro Nova]+metro_nova_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Metro_Nova_metro_nova_i7_italic_700.woff2?v=1684753481',
  'VedaFont[Monotype Baskerville]+monotype_baskerville_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Baskerville_monotype_baskerville_i6_italic_600.woff2?v=1684753482',
  'VedaFont[Metro Nova]+metro_nova_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Metro_Nova_metro_nova_i3_italic_300.woff2?v=1684753482',
  'VedaFont[Monotype Italian Old Style]+monotype_italian_old_style_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Italian_Old_Style_monotype_italian_old_style_n7_normal_700.woff2?v=1684753482',
  'VedaFont[Metro Nova]+metro_nova_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Metro_Nova_metro_nova_i8_italic_800.woff2?v=1684753482',
  'VedaFont[Metro Nova]+metro_nova_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Metro_Nova_metro_nova_n9_normal_900.woff2?v=1684753481',
  'VedaFont[Monotype Bodoni]+monotype_bodoni_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Bodoni_monotype_bodoni_i4_italic_400.woff2?v=1684753481',
  'VedaFont[Merriweather Sans]+merriweather_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Merriweather_Sans_merriweather_sans_n4_normal_400.woff2?v=1684753481',
  'VedaFont[Metro Nova]+metro_nova_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Metro_Nova_metro_nova_i4_italic_400.woff2?v=1684753482',
  'VedaFont[Metro Nova]+metro_nova_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Metro_Nova_metro_nova_i2_italic_200.woff2?v=1684753481',
  'VedaFont[Monotype Baskerville]+monotype_baskerville_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Baskerville_monotype_baskerville_n7_normal_700.woff2?v=1684753482',
  'VedaFont[Metro Nova]+metro_nova_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Metro_Nova_metro_nova_i5_italic_500.woff2?v=1684753481',
  'VedaFont[Metro Nova]+metro_nova_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Metro_Nova_metro_nova_n2_normal_200.woff2?v=1684753481',
  'VedaFont[Metro Nova]+metro_nova_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Metro_Nova_metro_nova_i9_italic_900.woff2?v=1684753482',
  'VedaFont[Lobster Two]+lobster_two_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lobster_Two_lobster_two_n7_normal_700.woff2?v=1684753482',
  'VedaFont[Monotype Bodoni]+monotype_bodoni_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Bodoni_monotype_bodoni_i8_italic_800.woff2?v=1684753481',
  'VedaFont[Monotype Sabon]+monotype_sabon_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Sabon_monotype_sabon_i4_italic_400.woff2?v=1684753481',
  'VedaFont[Merriweather Sans]+merriweather_sans_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Merriweather_Sans_merriweather_sans_i8_italic_800.woff2?v=1684753481',
  'VedaFont[Monotype Goudy]+monotype_goudy_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Goudy_monotype_goudy_i7_italic_700.woff2?v=1684753481',
  'VedaFont[Mentor Sans]+mentor_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mentor_Sans_mentor_sans_i4_italic_400.woff2?v=1684753481',
  'VedaFont[Memphis]+memphis_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Memphis_memphis_i5_italic_500.woff2?v=1684753481',
  'VedaFont[Mentor Sans]+mentor_sans_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mentor_Sans_mentor_sans_i3_italic_300.woff2?v=1684753481',
  'VedaFont[Mentor Sans]+mentor_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mentor_Sans_mentor_sans_n7_normal_700.woff2?v=1684753481',
  'VedaFont[Merriweather Sans]+merriweather_sans_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Merriweather_Sans_merriweather_sans_n3_normal_300.woff2?v=1684753481',
  'VedaFont[Mentor Sans]+mentor_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mentor_Sans_mentor_sans_n4_normal_400.woff2?v=1684753481',
  'VedaFont[Mentor Sans]+mentor_sans_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mentor_Sans_mentor_sans_i9_italic_900.woff2?v=1684753481',
  'VedaFont[Memphis]+memphis_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Memphis_memphis_i7_italic_700.woff2?v=1684753481',
  'VedaFont[Memphis]+memphis_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Memphis_memphis_n3_normal_300.woff2?v=1684753481',
  'VedaFont[Merriweather Sans]+merriweather_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Merriweather_Sans_merriweather_sans_i7_italic_700.woff2?v=1684753481',
  'VedaFont[Memphis Soft Rounded]+memphis_soft_rounded_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Memphis_Soft_Rounded_memphis_soft_rounded_n8_normal_800.woff2?v=1684753481',
  'VedaFont[Monotype Bodoni]+monotype_bodoni_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Bodoni_monotype_bodoni_n9_normal_900.woff2?v=1684753481',
  'VedaFont[Mentor Sans]+mentor_sans_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mentor_Sans_mentor_sans_n9_normal_900.woff2?v=1684753481',
  'VedaFont[Melior]+melior_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Melior_melior_n7_normal_700.woff2?v=1684753481',
  'VedaFont[Malabar]+malabar_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Malabar_malabar_i9_italic_900.woff2?v=1684753481',
  'VedaFont[Memphis Soft Rounded]+memphis_soft_rounded_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Memphis_Soft_Rounded_memphis_soft_rounded_n5_normal_500.woff2?v=1684753481',
  'VedaFont[Monotype Bodoni]+monotype_bodoni_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Bodoni_monotype_bodoni_i7_italic_700.woff2?v=1684753481',
  'VedaFont[Monotype Century Old Style]+monotype_century_old_style_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Century_Old_Style_monotype_century_old_style_n7_normal_700.woff2?v=1684753481',
  'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Syntax_Serif_linotype_syntax_serif_n5_normal_500.woff2?v=1684753481',
  'VedaFont[Monotype Bodoni]+monotype_bodoni_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Bodoni_monotype_bodoni_n4_normal_400.woff2?v=1684753481',
  'VedaFont[Memphis]+memphis_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Memphis_memphis_i3_italic_300.woff2?v=1684753481',
  'VedaFont[Monotype Bodoni]+monotype_bodoni_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Bodoni_monotype_bodoni_n7_normal_700.woff2?v=1684753481',
  'VedaFont[Melior]+melior_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Melior_melior_i4_italic_400.woff2?v=1684753481',
  'VedaFont[Melior]+melior_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Melior_melior_i7_italic_700.woff2?v=1684753481',
  'VedaFont[Monotype Bodoni]+monotype_bodoni_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Bodoni_monotype_bodoni_n5_normal_500.woff2?v=1684753481',
  'VedaFont[Melior]+melior_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Melior_melior_n4_normal_400.woff2?v=1684753481',
  'VedaFont[Maven Pro]+maven_pro_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Maven_Pro_maven_pro_n9_normal_900.woff2?v=1684753481',
  'VedaFont[Mariposa Sans]+mariposa_sans_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mariposa_Sans_mariposa_sans_n9_normal_900.woff2?v=1684753481',
  'VedaFont[Mariposa Sans]+mariposa_sans_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mariposa_Sans_mariposa_sans_n5_normal_500.woff2?v=1684753481',
  'VedaFont[Maven Pro]+maven_pro_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Maven_Pro_maven_pro_n7_normal_700.woff2?v=1684753481',
  'VedaFont[Modern No]+modern_no_n4+normal+2020400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Modern_No_20_modern_no_20_n4_normal_400.woff2?v=1684753481',
  'VedaFont[Mariposa Sans]+mariposa_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mariposa_Sans_mariposa_sans_n4_normal_400.woff2?v=1684753481',
  'VedaFont[Monotype Bodoni]+monotype_bodoni_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Bodoni_monotype_bodoni_i5_italic_500.woff2?v=1684753481',
  'VedaFont[Mariposa Sans]+mariposa_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mariposa_Sans_mariposa_sans_i4_italic_400.woff2?v=1684753481',
  'VedaFont[Malabar]+malabar_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Malabar_malabar_n9_normal_900.woff2?v=1684753481',
  'VedaFont[Lora]+lora_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lora_lora_i7_italic_700.woff2?v=1684753481',
  'VedaFont[Mariposa Sans]+mariposa_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mariposa_Sans_mariposa_sans_n7_normal_700.woff2?v=1684753481',
  'VedaFont[Lora]+lora_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lora_lora_n7_normal_700.woff2?v=1684753481',
  'VedaFont[Luthersche Fraktur]+luthersche_fraktur_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Luthersche_Fraktur_luthersche_fraktur_n4_normal_400.woff2?v=1684753481',
  'VedaFont[Malabar]+malabar_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Malabar_malabar_i4_italic_400.woff2?v=1684753481',
  'VedaFont[Malabar]+malabar_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Malabar_malabar_n7_normal_700.woff2?v=1684753481',
  'VedaFont[Malabar]+malabar_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Malabar_malabar_i7_italic_700.woff2?v=1684753481',
  'VedaFont[Maven Pro]+maven_pro_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Maven_Pro_maven_pro_n5_normal_500.woff2?v=1684753481',
  'VedaFont[Lora]+lora_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lora_lora_i4_italic_400.woff2?v=1684753481',
  'VedaFont[Lora]+lora_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lora_lora_n4_normal_400.woff2?v=1684753481',
  'VedaFont[Monotype Baskerville]+monotype_baskerville_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Baskerville_monotype_baskerville_i4_italic_400.woff2?v=1684753480',
  'VedaFont[Monotype Baskerville]+monotype_baskerville_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Baskerville_monotype_baskerville_n4_normal_400.woff2?v=1684753481',
  'VedaFont[Lucia]+lucia_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lucia_lucia_n4_normal_400.woff2?v=1684753480',
  'VedaFont[Monotype Baskerville]+monotype_baskerville_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Baskerville_monotype_baskerville_n6_normal_600.woff2?v=1684753481',
  'VedaFont[Metro Nova]+metro_nova_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Metro_Nova_metro_nova_n8_normal_800.woff2?v=1684753480',
  'VedaFont[Monotype Baskerville]+monotype_baskerville_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Monotype_Baskerville_monotype_baskerville_i7_italic_700.woff2?v=1684753481',
  'VedaFont[Metro Nova]+metro_nova_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Metro_Nova_metro_nova_n5_normal_500.woff2?v=1684753480',
  'VedaFont[Lobster Two]+lobster_two_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lobster_Two_lobster_two_i4_italic_400.woff2?v=1684753481',
  'VedaFont[Modern No]+modern_no_i4+italic+2020400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Modern_No_20_modern_no_20_i4_italic_400.woff2?v=1684753480',
  'VedaFont[Merriweather Sans]+merriweather_sans_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Merriweather_Sans_merriweather_sans_n8_normal_800.woff2?v=1684753480',
  'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Syntax_Serif_linotype_syntax_serif_n7_normal_700.woff2?v=1684753480',
  'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Syntax_Serif_linotype_syntax_serif_n9_normal_900.woff2?v=1684753480',
  'VedaFont[Merriweather Sans]+merriweather_sans_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Merriweather_Sans_merriweather_sans_i3_italic_300.woff2?v=1684753480',
  'VedaFont[Merriweather Sans]+merriweather_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Merriweather_Sans_merriweather_sans_i4_italic_400.woff2?v=1684753480',
  'VedaFont[Mentor Sans]+mentor_sans_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mentor_Sans_mentor_sans_n3_normal_300.woff2?v=1684753480',
  'VedaFont[Memphis]+memphis_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Memphis_memphis_n7_normal_700.woff2?v=1684753480',
  'VedaFont[Memphis]+memphis_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Memphis_memphis_n8_normal_800.woff2?v=1684753480',
  'VedaFont[Mentor Sans]+mentor_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Mentor_Sans_mentor_sans_i7_italic_700.woff2?v=1684753480',
  'VedaFont[Memphis]+memphis_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Memphis_memphis_n5_normal_500.woff2?v=1684753480',
  'VedaFont[Memphis Soft Rounded]+memphis_soft_rounded_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Memphis_Soft_Rounded_memphis_soft_rounded_n7_normal_700.woff2?v=1684753480',
  'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Syntax_Serif_linotype_syntax_serif_n8_normal_800.woff2?v=1684753480',
  'VedaFont[Megrim]+megrim_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Megrim_megrim_n4_normal_400.woff2?v=1684753480',
  'VedaFont[Maven Pro]+maven_pro_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Maven_Pro_maven_pro_n4_normal_400.woff2?v=1684753480',
  'VedaFont[Madera]+madera_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Madera_madera_n3_normal_300.woff2?v=1684753480',
  'VedaFont[Madera]+madera_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Madera_madera_n2_normal_200.woff2?v=1684753480',
  'VedaFont[Madera]+madera_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Madera_madera_n1_normal_100.woff2?v=1684753480',
  'VedaFont[Madera]+madera_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Madera_madera_n8_normal_800.woff2?v=1684753479',
  'VedaFont[Madera]+madera_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Madera_madera_i7_italic_700.woff2?v=1684753480',
  'VedaFont[Madera]+madera_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Madera_madera_i6_italic_600.woff2?v=1684753480',
  'VedaFont[Madera]+madera_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Madera_madera_i4_italic_400.woff2?v=1684753480',
  'VedaFont[Madera]+madera_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Madera_madera_i3_italic_300.woff2?v=1684753480',
  'VedaFont[Madera]+madera_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Madera_madera_i5_italic_500.woff2?v=1684753480',
  'VedaFont[Madera]+madera_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Madera_madera_n7_normal_700.woff2?v=1684753479',
  'VedaFont[Madera]+madera_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Madera_madera_n5_normal_500.woff2?v=1684753479',
  'VedaFont[Madera]+madera_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Madera_madera_i1_italic_100.woff2?v=1684753480',
  'VedaFont[Madera]+madera_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Madera_madera_n6_normal_600.woff2?v=1684753479',
  'VedaFont[Malabar]+malabar_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Malabar_malabar_n4_normal_400.woff2?v=1684753480',
  'VedaFont[Madera]+madera_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Madera_madera_i8_italic_800.woff2?v=1684753479',
  'VedaFont[Madera]+madera_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Madera_madera_i2_italic_200.woff2?v=1684753479',
  'VedaFont[Madera]+madera_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Madera_madera_n4_normal_400.woff2?v=1684753480',
  'VedaFont[Libre Baskerville]+libre_baskerville_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Baskerville_libre_baskerville_n4_normal_400.woff2?v=1684753433',
  'VedaFont[Lato]+lato_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lato_lato_i7_italic_700.woff2?v=1684753433',
  'VedaFont[Lato]+lato_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lato_lato_n9_normal_900.woff2?v=1684753433',
  'VedaFont[Lato]+lato_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lato_lato_i6_italic_600.woff2?v=1684753433',
  'VedaFont[Lato]+lato_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lato_lato_i3_italic_300.woff2?v=1684753433',
  'VedaFont[Lato]+lato_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lato_lato_n5_normal_500.woff2?v=1684753433',
  'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Syntax_Serif_linotype_syntax_serif_i3_italic_300.woff2?v=1684753433',
  'VedaFont[Linotype Gianotten]+linotype_gianotten_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Gianotten_linotype_gianotten_n9_normal_900.woff2?v=1684753433',
  'VedaFont[Lato]+lato_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lato_lato_i8_italic_800.woff2?v=1684753433',
  'VedaFont[Lato]+lato_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lato_lato_i9_italic_900.woff2?v=1684753433',
  'VedaFont[Linotype Gianotten]+linotype_gianotten_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Gianotten_linotype_gianotten_i8_italic_800.woff2?v=1684753432',
  'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Syntax_Serif_linotype_syntax_serif_i5_italic_500.woff2?v=1684753432',
  'VedaFont[Lato]+lato_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lato_lato_n3_normal_300.woff2?v=1684753433',
  'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Syntax_Serif_linotype_syntax_serif_i7_italic_700.woff2?v=1684753433',
  'VedaFont[Lato]+lato_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lato_lato_i2_italic_200.woff2?v=1684753433',
  'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Syntax_Serif_linotype_syntax_serif_i4_italic_400.woff2?v=1684753433',
  'VedaFont[Lato]+lato_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lato_lato_i4_italic_400.woff2?v=1684753433',
  'VedaFont[Linotype Gianotten]+linotype_gianotten_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Gianotten_linotype_gianotten_n3_normal_300.woff2?v=1684753433',
  'VedaFont[Linotype Gianotten]+linotype_gianotten_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Gianotten_linotype_gianotten_i7_italic_700.woff2?v=1684753432',
  'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Syntax_Serif_linotype_syntax_serif_n4_normal_400.woff2?v=1684753433',
  'VedaFont[Linotype Really]+linotype_really_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Really_linotype_really_i6_italic_600.woff2?v=1684753432',
  'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Syntax_Serif_linotype_syntax_serif_i8_italic_800.woff2?v=1684753433',
  'VedaFont[Lato]+lato_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lato_lato_i5_italic_500.woff2?v=1684753432',
  'VedaFont[Linotype Gianotten]+linotype_gianotten_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Gianotten_linotype_gianotten_i4_italic_400.woff2?v=1684753432',
  'VedaFont[Linotype Really]+linotype_really_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Really_linotype_really_n5_normal_500.woff2?v=1684753432',
  'VedaFont[Libre Franklin]+libre_franklin_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Franklin_libre_franklin_n4_normal_400.woff2?v=1684753432',
  'VedaFont[Linotype Really]+linotype_really_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Really_linotype_really_i5_italic_500.woff2?v=1684753432',
  'VedaFont[Libre Franklin]+libre_franklin_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Franklin_libre_franklin_n3_normal_300.woff2?v=1684753432',
  'VedaFont[Libre Franklin]+libre_franklin_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Franklin_libre_franklin_n7_normal_700.woff2?v=1684753432',
  'VedaFont[Linotype Gianotten]+linotype_gianotten_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Gianotten_linotype_gianotten_n5_normal_500.woff2?v=1684753432',
  'VedaFont[Linotype Gianotten]+linotype_gianotten_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Gianotten_linotype_gianotten_n8_normal_800.woff2?v=1684753432',
  'VedaFont[Linotype Gianotten]+linotype_gianotten_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Gianotten_linotype_gianotten_n7_normal_700.woff2?v=1684753432',
  'VedaFont[Libre Franklin]+libre_franklin_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Franklin_libre_franklin_n5_normal_500.woff2?v=1684753432',
  'VedaFont[Libre Franklin]+libre_franklin_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Franklin_libre_franklin_n2_normal_200.woff2?v=1684753432',
  'VedaFont[Linotype Didot]+linotype_didot_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Didot_linotype_didot_i4_italic_400.woff2?v=1684753432',
  'VedaFont[Libre Franklin]+libre_franklin_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Franklin_libre_franklin_i5_italic_500.woff2?v=1684753432',
  'VedaFont[Libre Franklin]+libre_franklin_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Franklin_libre_franklin_n8_normal_800.woff2?v=1684753432',
  'VedaFont[Linotype Gianotten]+linotype_gianotten_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Gianotten_linotype_gianotten_n4_normal_400.woff2?v=1684753432',
  'VedaFont[Libre Franklin]+libre_franklin_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Franklin_libre_franklin_n6_normal_600.woff2?v=1684753432',
  'VedaFont[Linotype Didot]+linotype_didot_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Didot_linotype_didot_n4_normal_400.woff2?v=1684753432',
  'VedaFont[Libre Franklin]+libre_franklin_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Franklin_libre_franklin_i7_italic_700.woff2?v=1684753432',
  'VedaFont[Libre Franklin]+libre_franklin_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Franklin_libre_franklin_i2_italic_200.woff2?v=1684753432',
  'VedaFont[Libre Franklin]+libre_franklin_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Franklin_libre_franklin_i9_italic_900.woff2?v=1684753432',
  'VedaFont[Libre Franklin]+libre_franklin_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Franklin_libre_franklin_n1_normal_100.woff2?v=1684753432',
  'VedaFont[Libre Franklin]+libre_franklin_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Franklin_libre_franklin_i3_italic_300.woff2?v=1684753432',
  'VedaFont[Libre Franklin]+libre_franklin_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Franklin_libre_franklin_i1_italic_100.woff2?v=1684753432',
  'VedaFont[Libre Baskerville]+libre_baskerville_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Baskerville_libre_baskerville_i4_italic_400.woff2?v=1684753432',
  'VedaFont[Libre Franklin]+libre_franklin_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Franklin_libre_franklin_i4_italic_400.woff2?v=1684753432',
  'VedaFont[Libre Baskerville]+libre_baskerville_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Baskerville_libre_baskerville_n7_normal_700.woff2?v=1684753432',
  'VedaFont[Libelle]+libelle_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libelle_libelle_n4_normal_400.woff2?v=1684753432',
  'VedaFont[Laurentian]+laurentian_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Laurentian_laurentian_n6_normal_600.woff2?v=1684753432',
  'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Syntax_Serif_linotype_syntax_serif_n3_normal_300.woff2?v=1684753432',
  'VedaFont[Laurentian]+laurentian_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Laurentian_laurentian_i6_italic_600.woff2?v=1684753432',
  'VedaFont[Laurentian]+laurentian_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Laurentian_laurentian_i4_italic_400.woff2?v=1684753432',
  'VedaFont[Lato]+lato_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lato_lato_n7_normal_700.woff2?v=1684753432',
  'VedaFont[Lato]+lato_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lato_lato_n6_normal_600.woff2?v=1684753431',
  'VedaFont[Lato]+lato_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lato_lato_n8_normal_800.woff2?v=1684753431',
  'VedaFont[Lato]+lato_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lato_lato_n2_normal_200.woff2?v=1684753431',
  'VedaFont[Josefin Slab]+josefin_slab_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Slab_josefin_slab_n6_normal_600.woff2?v=1684753431',
  'VedaFont[Lato]+lato_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lato_lato_i1_italic_100.woff2?v=1684753431',
  'VedaFont[Lato]+lato_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lato_lato_n4_normal_400.woff2?v=1684753431',
  'VedaFont[Lato]+lato_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Lato_lato_n1_normal_100.woff2?v=1684753431',
  'VedaFont[Kalam]+kalam_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kalam_kalam_n4_normal_400.woff2?v=1684753431',
  'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Syntax_Serif_linotype_syntax_serif_i9_italic_900.woff2?v=1684753431',
  'VedaFont[Linotype Really]+linotype_really_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Really_linotype_really_n6_normal_600.woff2?v=1684753431',
  'VedaFont[Linotype Gianotten]+linotype_gianotten_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Gianotten_linotype_gianotten_i5_italic_500.woff2?v=1684753431',
  'VedaFont[Kalam]+kalam_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kalam_kalam_n7_normal_700.woff2?v=1684753431',
  'VedaFont[Linotype Gianotten]+linotype_gianotten_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Gianotten_linotype_gianotten_i3_italic_300.woff2?v=1684753431',
  'VedaFont[Josefin Slab]+josefin_slab_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Slab_josefin_slab_i6_italic_600.woff2?v=1684753431',
  'VedaFont[Josefin Slab]+josefin_slab_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Slab_josefin_slab_i3_italic_300.woff2?v=1684753431',
  'VedaFont[Linotype Didot]+linotype_didot_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Didot_linotype_didot_i7_italic_700.woff2?v=1684753431',
  'VedaFont[Linotype Didot]+linotype_didot_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Linotype_Didot_linotype_didot_n7_normal_700.woff2?v=1684753431',
  'VedaFont[Libre Franklin]+libre_franklin_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Franklin_libre_franklin_n9_normal_900.woff2?v=1684753431',
  'VedaFont[Kairos]+kairos_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kairos_kairos_n7_normal_700.woff2?v=1684753431',
  'VedaFont[Kairos]+kairos_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kairos_kairos_n9_normal_900.woff2?v=1684753431',
  'VedaFont[Kalam]+kalam_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kalam_kalam_n3_normal_300.woff2?v=1684753431',
  'VedaFont[Kairos]+kairos_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kairos_kairos_n8_normal_800.woff2?v=1684753431',
  'VedaFont[Libre Franklin]+libre_franklin_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Franklin_libre_franklin_i8_italic_800.woff2?v=1684753430',
  'VedaFont[Kairos]+kairos_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kairos_kairos_n5_normal_500.woff2?v=1684753430',
  'VedaFont[Libre Franklin]+libre_franklin_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Libre_Franklin_libre_franklin_i6_italic_600.woff2?v=1684753430',
  'VedaFont[Laurentian]+laurentian_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Laurentian_laurentian_n7_normal_700.woff2?v=1684753430',
  'VedaFont[Laurentian]+laurentian_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Laurentian_laurentian_n4_normal_400.woff2?v=1684753430',
  'VedaFont[Kairos]+kairos_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kairos_kairos_i9_italic_900.woff2?v=1684753430',
  'VedaFont[Josefin Slab]+josefin_slab_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Slab_josefin_slab_n7_normal_700.woff2?v=1684753430',
  'VedaFont[Karla]+karla_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Karla_karla_n4_normal_400.woff2?v=1684753430',
  'VedaFont[Kairos]+kairos_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kairos_kairos_i7_italic_700.woff2?v=1684753430',
  'VedaFont[Kairos]+kairos_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kairos_kairos_i2_italic_200.woff2?v=1684753430',
  'VedaFont[Kairos]+kairos_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kairos_kairos_i4_italic_400.woff2?v=1684753430',
  'VedaFont[Kairos]+kairos_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kairos_kairos_i5_italic_500.woff2?v=1684753430',
  'VedaFont[Kreon]+kreon_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kreon_kreon_n7_normal_700.woff2?v=1684753430',
  'VedaFont[ITC Officina Serif]+itc_officina_serif_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Serif_itc_officina_serif_i5_italic_500.woff2?v=1684753430',
  'VedaFont[Karla]+karla_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Karla_karla_n7_normal_700.woff2?v=1684753430',
  'VedaFont[Karla]+karla_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Karla_karla_i4_italic_400.woff2?v=1684753430',
  'VedaFont[Kreon]+kreon_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kreon_kreon_n4_normal_400.woff2?v=1684753430',
  'VedaFont[Karla]+karla_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Karla_karla_i7_italic_700.woff2?v=1684753430',
  'VedaFont[Josefin Slab]+josefin_slab_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Slab_josefin_slab_n4_normal_400.woff2?v=1684753430',
  'VedaFont[Josefin Slab]+josefin_slab_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Slab_josefin_slab_i7_italic_700.woff2?v=1684753429',
  'VedaFont[ITC Officina Serif]+itc_officina_serif_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Serif_itc_officina_serif_i7_italic_700.woff2?v=1684753430',
  'VedaFont[Josefin Slab]+josefin_slab_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Slab_josefin_slab_n1_normal_100.woff2?v=1684753429',
  'VedaFont[Josefin Slab]+josefin_slab_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Slab_josefin_slab_i4_italic_400.woff2?v=1684753429',
  'VedaFont[Josefin Slab]+josefin_slab_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Slab_josefin_slab_n3_normal_300.woff2?v=1684753429',
  'VedaFont[Kreon]+kreon_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kreon_kreon_n3_normal_300.woff2?v=1684753429',
  'VedaFont[Josefin Sans]+josefin_sans_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Sans_josefin_sans_n6_normal_600.woff2?v=1684753429',
  'VedaFont[Kairos]+kairos_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kairos_kairos_n4_normal_400.woff2?v=1684753429',
  'VedaFont[Josefin Sans]+josefin_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Sans_josefin_sans_n7_normal_700.woff2?v=1684753429',
  'VedaFont[Josefin Slab]+josefin_slab_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Slab_josefin_slab_i1_italic_100.woff2?v=1684753429',
  'VedaFont[Josefin Sans]+josefin_sans_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Sans_josefin_sans_i1_italic_100.woff2?v=1684753429',
  'VedaFont[Josefin Sans]+josefin_sans_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Sans_josefin_sans_n3_normal_300.woff2?v=1684753429',
  'VedaFont[Kairos]+kairos_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kairos_kairos_n3_normal_300.woff2?v=1684753429',
  'VedaFont[Joanna Sans Nova]+joanna_sans_nova_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Sans_Nova_joanna_sans_nova_n8_normal_800.woff2?v=1684753429',
  'VedaFont[Kairos]+kairos_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kairos_kairos_n2_normal_200.woff2?v=1684753429',
  'VedaFont[Kairos]+kairos_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kairos_kairos_i8_italic_800.woff2?v=1684753429',
  'VedaFont[Joanna Nova]+joanna_nova_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Nova_joanna_nova_i5_italic_500.woff2?v=1684753429',
  'VedaFont[Josefin Sans]+josefin_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Sans_josefin_sans_i4_italic_400.woff2?v=1684753429',
  'VedaFont[Josefin Sans]+josefin_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Sans_josefin_sans_n4_normal_400.woff2?v=1684753429',
  'VedaFont[Kairos]+kairos_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kairos_kairos_n1_normal_100.woff2?v=1684753429',
  'VedaFont[Josefin Sans]+josefin_sans_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Sans_josefin_sans_i6_italic_600.woff2?v=1684753429',
  'VedaFont[Josefin Sans]+josefin_sans_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Sans_josefin_sans_i3_italic_300.woff2?v=1684753429',
  'VedaFont[Joanna Sans Nova]+joanna_sans_nova_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Sans_Nova_joanna_sans_nova_i5_italic_500.woff2?v=1684753429',
  'VedaFont[Joanna Sans Nova]+joanna_sans_nova_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Sans_Nova_joanna_sans_nova_n7_normal_700.woff2?v=1684753429',
  'VedaFont[Joanna Sans Nova]+joanna_sans_nova_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Sans_Nova_joanna_sans_nova_i8_italic_800.woff2?v=1684753429',
  'VedaFont[Joanna Nova]+joanna_nova_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Nova_joanna_nova_i6_italic_600.woff2?v=1684753429',
  'VedaFont[Kairos]+kairos_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kairos_kairos_i3_italic_300.woff2?v=1684753429',
  'VedaFont[Joanna Nova]+joanna_nova_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Nova_joanna_nova_i1_italic_100.woff2?v=1684753429',
  'VedaFont[Joanna Sans Nova]+joanna_sans_nova_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Sans_Nova_joanna_sans_nova_i1_italic_100.woff2?v=1684753429',
  'VedaFont[Joanna Sans Nova]+joanna_sans_nova_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Sans_Nova_joanna_sans_nova_n5_normal_500.woff2?v=1684753429',
  'VedaFont[Joanna Nova]+joanna_nova_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Nova_joanna_nova_n4_normal_400.woff2?v=1684753429',
  'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Sans_II_itc_stone_sans_ii_i6_italic_600.woff2?v=1684753429',
  'VedaFont[Joanna Nova]+joanna_nova_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Nova_joanna_nova_i9_italic_900.woff2?v=1684753429',
  'VedaFont[Joanna Nova]+joanna_nova_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Nova_joanna_nova_n9_normal_900.woff2?v=1684753429',
  'VedaFont[Joanna Nova]+joanna_nova_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Nova_joanna_nova_i8_italic_800.woff2?v=1684753429',
  'VedaFont[ITC Stone Serif]+itc_stone_serif_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Serif_itc_stone_serif_i4_italic_400.woff2?v=1684753429',
  'VedaFont[Joanna Nova]+joanna_nova_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Nova_joanna_nova_n2_normal_200.woff2?v=1684753429',
  'VedaFont[Joanna Nova]+joanna_nova_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Nova_joanna_nova_i7_italic_700.woff2?v=1684753429',
  'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Sans_II_itc_stone_sans_ii_n6_normal_600.woff2?v=1684753429',
  'VedaFont[Kairos]+kairos_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Kairos_kairos_i1_italic_100.woff2?v=1684753429',
  'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Sans_II_itc_stone_sans_ii_n3_normal_300.woff2?v=1684753429',
  'VedaFont[ITC Stone Serif]+itc_stone_serif_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Serif_itc_stone_serif_i6_italic_600.woff2?v=1684753429',
  'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Sans_II_itc_stone_sans_ii_i8_italic_800.woff2?v=1684753429',
  'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Sans_II_itc_stone_sans_ii_n5_normal_500.woff2?v=1684753429',
  'VedaFont[ITC Tapioca]+itc_tapioca_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Tapioca_itc_tapioca_n5_normal_500.woff2?v=1684753429',
  'VedaFont[ITC Stone Serif]+itc_stone_serif_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Serif_itc_stone_serif_n7_normal_700.woff2?v=1684753429',
  'VedaFont[ITC Stone Informal]+itc_stone_informal_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Informal_itc_stone_informal_i7_italic_700.woff2?v=1684753429',
  'VedaFont[ITC Officina Serif]+itc_officina_serif_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Serif_itc_officina_serif_n7_normal_700.woff2?v=1684753429',
  'VedaFont[ITC Stone Serif]+itc_stone_serif_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Serif_itc_stone_serif_n4_normal_400.woff2?v=1684753429',
  'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Sans_II_itc_stone_sans_ii_n4_normal_400.woff2?v=1684753429',
  'VedaFont[ITC Stone Serif]+itc_stone_serif_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Serif_itc_stone_serif_n6_normal_600.woff2?v=1684753429',
  'VedaFont[ITC Stone Informal]+itc_stone_informal_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Informal_itc_stone_informal_n6_normal_600.woff2?v=1684753429',
  'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Sans_II_itc_stone_sans_ii_n8_normal_800.woff2?v=1684753429',
  'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Sans_II_itc_stone_sans_ii_i4_italic_400.woff2?v=1684753429',
  'VedaFont[ITC Officina Serif]+itc_officina_serif_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Serif_itc_officina_serif_n8_normal_800.woff2?v=1684753429',
  'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Sans_II_itc_stone_sans_ii_i3_italic_300.woff2?v=1684753429',
  'VedaFont[ITC Stone Humanist]+itc_stone_humanist_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Humanist_itc_stone_humanist_n5_normal_500.woff2?v=1684753429',
  'VedaFont[ITC Stepp]+itc_stepp_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stepp_itc_stepp_i3_italic_300.woff2?v=1684753429',
  'VedaFont[ITC Stone Informal]+itc_stone_informal_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Informal_itc_stone_informal_n4_normal_400.woff2?v=1684753429',
  'VedaFont[ITC Officina Serif]+itc_officina_serif_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Serif_itc_officina_serif_n5_normal_500.woff2?v=1684753429',
  'VedaFont[ITC Stone Informal]+itc_stone_informal_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Informal_itc_stone_informal_i4_italic_400.woff2?v=1684753429',
  'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Sans_II_itc_stone_sans_ii_i5_italic_500.woff2?v=1684753429',
  'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Sans_II_itc_stone_sans_ii_i7_italic_700.woff2?v=1684753429',
  'VedaFont[ITC Stone Informal]+itc_stone_informal_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Informal_itc_stone_informal_i6_italic_600.woff2?v=1684753429',
  'VedaFont[ITC Stone Humanist]+itc_stone_humanist_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Humanist_itc_stone_humanist_n6_normal_600.woff2?v=1684753429',
  'VedaFont[ITC Stone Humanist]+itc_stone_humanist_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Humanist_itc_stone_humanist_i7_italic_700.woff2?v=1684753429',
  'VedaFont[ITC Stepp]+itc_stepp_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stepp_itc_stepp_n4_normal_400.woff2?v=1684753428',
  'VedaFont[ITC Stepp]+itc_stepp_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stepp_itc_stepp_n9_normal_900.woff2?v=1684753429',
  'VedaFont[ITC Officina Serif]+itc_officina_serif_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Serif_itc_officina_serif_n4_normal_400.woff2?v=1684753428',
  'VedaFont[ITC Stone Humanist]+itc_stone_humanist_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Humanist_itc_stone_humanist_i5_italic_500.woff2?v=1684753428',
  'VedaFont[ITC Officina Serif]+itc_officina_serif_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Serif_itc_officina_serif_i8_italic_800.woff2?v=1684753428',
  'VedaFont[ITC Officina Serif]+itc_officina_serif_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Serif_itc_officina_serif_i9_italic_900.woff2?v=1684753428',
  'VedaFont[ITC Stepp]+itc_stepp_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stepp_itc_stepp_n3_normal_300.woff2?v=1684753429',
  'VedaFont[ITC Stepp]+itc_stepp_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stepp_itc_stepp_n7_normal_700.woff2?v=1684753428',
  'VedaFont[ITC Stepp]+itc_stepp_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stepp_itc_stepp_i9_italic_900.woff2?v=1684753428',
  'VedaFont[Josefin Sans]+josefin_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Sans_josefin_sans_i7_italic_700.woff2?v=1684753428',
  'VedaFont[Joanna Sans Nova]+joanna_sans_nova_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Sans_Nova_joanna_sans_nova_n9_normal_900.woff2?v=1684753428',
  'VedaFont[ITC Stepp]+itc_stepp_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stepp_itc_stepp_i7_italic_700.woff2?v=1684753428',
  'VedaFont[Josefin Sans]+josefin_sans_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Josefin_Sans_josefin_sans_n1_normal_100.woff2?v=1684753428',
  'VedaFont[Joanna Sans Nova]+joanna_sans_nova_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Sans_Nova_joanna_sans_nova_n4_normal_400.woff2?v=1684753428',
  'VedaFont[Joanna Sans Nova]+joanna_sans_nova_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Sans_Nova_joanna_sans_nova_n2_normal_200.woff2?v=1684753428',
  'VedaFont[Joanna Sans Nova]+joanna_sans_nova_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Sans_Nova_joanna_sans_nova_n1_normal_100.woff2?v=1684753428',
  'VedaFont[Joanna Sans Nova]+joanna_sans_nova_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Sans_Nova_joanna_sans_nova_n3_normal_300.woff2?v=1684753428',
  'VedaFont[Joanna Sans Nova]+joanna_sans_nova_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Sans_Nova_joanna_sans_nova_i7_italic_700.woff2?v=1684753428',
  'VedaFont[Joanna Sans Nova]+joanna_sans_nova_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Sans_Nova_joanna_sans_nova_i9_italic_900.woff2?v=1684753428',
  'VedaFont[Joanna Sans Nova]+joanna_sans_nova_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Sans_Nova_joanna_sans_nova_i3_italic_300.woff2?v=1684753428',
  'VedaFont[Joanna Sans Nova]+joanna_sans_nova_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Sans_Nova_joanna_sans_nova_i4_italic_400.woff2?v=1684753428',
  'VedaFont[Joanna Sans Nova]+joanna_sans_nova_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Sans_Nova_joanna_sans_nova_i2_italic_200.woff2?v=1684753428',
  'VedaFont[ITC Stepp]+itc_stepp_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stepp_itc_stepp_i5_italic_500.woff2?v=1684753428',
  'VedaFont[Joanna Nova]+joanna_nova_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Nova_joanna_nova_n6_normal_600.woff2?v=1684753428',
  'VedaFont[Joanna Nova]+joanna_nova_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Nova_joanna_nova_n7_normal_700.woff2?v=1684753428',
  'VedaFont[Joanna Nova]+joanna_nova_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Nova_joanna_nova_i3_italic_300.woff2?v=1684753428',
  'VedaFont[Joanna Nova]+joanna_nova_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Nova_joanna_nova_n8_normal_800.woff2?v=1684753428',
  'VedaFont[Joanna Nova]+joanna_nova_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Nova_joanna_nova_n1_normal_100.woff2?v=1684753428',
  'VedaFont[Joanna Nova]+joanna_nova_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Nova_joanna_nova_n5_normal_500.woff2?v=1684753428',
  'VedaFont[Joanna Nova]+joanna_nova_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Nova_joanna_nova_n3_normal_300.woff2?v=1684753428',
  'VedaFont[Joanna Nova]+joanna_nova_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Nova_joanna_nova_i4_italic_400.woff2?v=1684753428',
  'VedaFont[Joanna Nova]+joanna_nova_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Joanna_Nova_joanna_nova_i2_italic_200.woff2?v=1684753428',
  'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Sans_II_itc_stone_sans_ii_n7_normal_700.woff2?v=1684753428',
  'VedaFont[ITC Stone Serif]+itc_stone_serif_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Serif_itc_stone_serif_i7_italic_700.woff2?v=1684753427',
  'VedaFont[ITC Stone Humanist]+itc_stone_humanist_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Humanist_itc_stone_humanist_n7_normal_700.woff2?v=1684753428',
  'VedaFont[ITC Officina Serif]+itc_officina_serif_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Serif_itc_officina_serif_n9_normal_900.woff2?v=1684753427',
  'VedaFont[ITC Stone Humanist]+itc_stone_humanist_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Humanist_itc_stone_humanist_i6_italic_600.woff2?v=1684753427',
  'VedaFont[ITC Stone Informal]+itc_stone_informal_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Stone_Informal_itc_stone_informal_n7_normal_700.woff2?v=1684753427',
  'VedaFont[ITC New Esprit]+itc_new_esprit_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Esprit_itc_new_esprit_i7_italic_700.woff2?v=1684753363',
  'VedaFont[ITC Officina Sans]+itc_officina_sans_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Sans_itc_officina_sans_n5_normal_500.woff2?v=1684753363',
  'VedaFont[ITC Novarese]+itc_novarese_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Novarese_itc_novarese_i5_italic_500.woff2?v=1684753363',
  'VedaFont[ITC Modern No]+itc_modern_no_i8+italic+216216800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Modern_No_216_itc_modern_no_216_i8_italic_800.woff2?v=1684753363',
  'VedaFont[ITC New Veljovic]+itc_new_veljovic_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Veljovic_itc_new_veljovic_i3_italic_300.woff2?v=1684753363',
  'VedaFont[ITC Officina Sans]+itc_officina_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Sans_itc_officina_sans_n7_normal_700.woff2?v=1684753363',
  'VedaFont[ITC Goudy Sans]+itc_goudy_sans_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Goudy_Sans_itc_goudy_sans_i9_italic_900.woff2?v=1684753363',
  'VedaFont[ITC New Baskerville]+itc_new_baskerville_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Baskerville_itc_new_baskerville_i6_italic_600.woff2?v=1684753363',
  'VedaFont[ITC Novarese]+itc_novarese_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Novarese_itc_novarese_i7_italic_700.woff2?v=1684753363',
  'VedaFont[ITC New Baskerville]+itc_new_baskerville_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Baskerville_itc_new_baskerville_n6_normal_600.woff2?v=1684753363',
  'VedaFont[ITC New Baskerville]+itc_new_baskerville_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Baskerville_itc_new_baskerville_n7_normal_700.woff2?v=1684753363',
  'VedaFont[ITC New Veljovic]+itc_new_veljovic_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Veljovic_itc_new_veljovic_i5_italic_500.woff2?v=1684753363',
  'VedaFont[ITC New Esprit]+itc_new_esprit_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Esprit_itc_new_esprit_i5_italic_500.woff2?v=1684753363',
  'VedaFont[ITC Officina Sans]+itc_officina_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Sans_itc_officina_sans_i4_italic_400.woff2?v=1684753363',
  'VedaFont[ITC Officina Sans]+itc_officina_sans_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Sans_itc_officina_sans_i8_italic_800.woff2?v=1684753363',
  'VedaFont[ITC Johnston]+itc_johnston_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Johnston_itc_johnston_i7_italic_700.woff2?v=1684753363',
  'VedaFont[ITC Officina Sans]+itc_officina_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Sans_itc_officina_sans_n4_normal_400.woff2?v=1684753363',
  'VedaFont[ITC Mendoza Roman]+itc_mendoza_roman_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Mendoza_Roman_itc_mendoza_roman_n4_normal_400.woff2?v=1684753363',
  'VedaFont[ITC Novarese]+itc_novarese_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Novarese_itc_novarese_n7_normal_700.woff2?v=1684753363',
  'VedaFont[ITC Officina Serif]+itc_officina_serif_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Serif_itc_officina_serif_i4_italic_400.woff2?v=1684753363',
  'VedaFont[ITC New Veljovic]+itc_new_veljovic_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Veljovic_itc_new_veljovic_n3_normal_300.woff2?v=1684753363',
  'VedaFont[ITC Officina Sans]+itc_officina_sans_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Sans_itc_officina_sans_n8_normal_800.woff2?v=1684753363',
  'VedaFont[ITC Officina Sans]+itc_officina_sans_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Sans_itc_officina_sans_i9_italic_900.woff2?v=1684753363',
  'VedaFont[ITC Officina Sans]+itc_officina_sans_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Sans_itc_officina_sans_n9_normal_900.woff2?v=1684753363',
  'VedaFont[ITC Mendoza Roman]+itc_mendoza_roman_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Mendoza_Roman_itc_mendoza_roman_i5_italic_500.woff2?v=1684753363',
  'VedaFont[ITC Modern No]+itc_modern_no_n3+normal+216216300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Modern_No_216_itc_modern_no_216_n3_normal_300.woff2?v=1684753363',
  'VedaFont[ITC Novarese]+itc_novarese_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Novarese_itc_novarese_n4_normal_400.woff2?v=1684753363',
  'VedaFont[ITC Modern No]+itc_modern_no_n5+normal+216216500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Modern_No_216_itc_modern_no_216_n5_normal_500.woff2?v=1684753363',
  'VedaFont[ITC Gamma]+itc_gamma_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Gamma_itc_gamma_n7_normal_700.woff2?v=1684753363',
  'VedaFont[ITC Novarese]+itc_novarese_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Novarese_itc_novarese_n9_normal_900.woff2?v=1684753363',
  'VedaFont[ITC Gamma]+itc_gamma_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Gamma_itc_gamma_i8_italic_800.woff2?v=1684753363',
  'VedaFont[ITC New Esprit]+itc_new_esprit_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Esprit_itc_new_esprit_i4_italic_400.woff2?v=1684753363',
  'VedaFont[ITC Mendoza Roman]+itc_mendoza_roman_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Mendoza_Roman_itc_mendoza_roman_i7_italic_700.woff2?v=1684753363',
  'VedaFont[ITC Gamma]+itc_gamma_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Gamma_itc_gamma_n8_normal_800.woff2?v=1684753363',
  'VedaFont[ITC New Veljovic]+itc_new_veljovic_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Veljovic_itc_new_veljovic_n9_normal_900.woff2?v=1684753363',
  'VedaFont[ITC New Veljovic]+itc_new_veljovic_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Veljovic_itc_new_veljovic_n7_normal_700.woff2?v=1684753363',
  'VedaFont[ITC New Veljovic]+itc_new_veljovic_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Veljovic_itc_new_veljovic_n4_normal_400.woff2?v=1684753363',
  'VedaFont[ITC Modern No]+itc_modern_no_i7+italic+216216700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Modern_No_216_itc_modern_no_216_i7_italic_700.woff2?v=1684753363',
  'VedaFont[ITC New Baskerville]+itc_new_baskerville_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Baskerville_itc_new_baskerville_n9_normal_900.woff2?v=1684753363',
  'VedaFont[ITC New Baskerville]+itc_new_baskerville_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Baskerville_itc_new_baskerville_n4_normal_400.woff2?v=1684753363',
  'VedaFont[ITC Novarese]+itc_novarese_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Novarese_itc_novarese_i4_italic_400.woff2?v=1684753363',
  'VedaFont[ITC Goudy Sans]+itc_goudy_sans_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Goudy_Sans_itc_goudy_sans_i5_italic_500.woff2?v=1684753363',
  'VedaFont[ITC New Esprit]+itc_new_esprit_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Esprit_itc_new_esprit_n7_normal_700.woff2?v=1684753363',
  'VedaFont[ITC New Esprit]+itc_new_esprit_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Esprit_itc_new_esprit_n4_normal_400.woff2?v=1684753363',
  'VedaFont[ITC Gamma]+itc_gamma_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Gamma_itc_gamma_i4_italic_400.woff2?v=1684753363',
  'VedaFont[ITC Officina Sans]+itc_officina_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Sans_itc_officina_sans_i7_italic_700.woff2?v=1684753363',
  'VedaFont[ITC Galliard]+itc_galliard_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Galliard_itc_galliard_n7_normal_700.woff2?v=1684753363',
  'VedaFont[ITC New Veljovic]+itc_new_veljovic_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Veljovic_itc_new_veljovic_i7_italic_700.woff2?v=1684753363',
  'VedaFont[ITC New Esprit]+itc_new_esprit_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Esprit_itc_new_esprit_i8_italic_800.woff2?v=1684753363',
  'VedaFont[ITC New Esprit]+itc_new_esprit_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Esprit_itc_new_esprit_n5_normal_500.woff2?v=1684753363',
  'VedaFont[ITC Modern No]+itc_modern_no_n7+normal+216216700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Modern_No_216_itc_modern_no_216_n7_normal_700.woff2?v=1684753363',
  'VedaFont[ITC Mendoza Roman]+itc_mendoza_roman_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Mendoza_Roman_itc_mendoza_roman_n5_normal_500.woff2?v=1684753363',
  'VedaFont[ITC Novarese]+itc_novarese_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Novarese_itc_novarese_n5_normal_500.woff2?v=1684753363',
  'VedaFont[ITC Officina Sans]+itc_officina_sans_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Officina_Sans_itc_officina_sans_i5_italic_500.woff2?v=1684753363',
  'VedaFont[ITC New Esprit]+itc_new_esprit_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Esprit_itc_new_esprit_n8_normal_800.woff2?v=1684753363',
  'VedaFont[ITC Mendoza Roman]+itc_mendoza_roman_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Mendoza_Roman_itc_mendoza_roman_i4_italic_400.woff2?v=1684753363',
  'VedaFont[ITC New Veljovic]+itc_new_veljovic_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Veljovic_itc_new_veljovic_n5_normal_500.woff2?v=1684753363',
  'VedaFont[ITC New Veljovic]+itc_new_veljovic_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Veljovic_itc_new_veljovic_i9_italic_900.woff2?v=1684753363',
  'VedaFont[ITC New Veljovic]+itc_new_veljovic_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Veljovic_itc_new_veljovic_i4_italic_400.woff2?v=1684753363',
  'VedaFont[ITC Mendoza Roman]+itc_mendoza_roman_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Mendoza_Roman_itc_mendoza_roman_n7_normal_700.woff2?v=1684753363',
  'VedaFont[ITC New Baskerville]+itc_new_baskerville_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Baskerville_itc_new_baskerville_i9_italic_900.woff2?v=1684753363',
  'VedaFont[ITC Goudy Sans]+itc_goudy_sans_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Goudy_Sans_itc_goudy_sans_n5_normal_500.woff2?v=1684753363',
  'VedaFont[ITC New Baskerville]+itc_new_baskerville_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Baskerville_itc_new_baskerville_i7_italic_700.woff2?v=1684753363',
  'VedaFont[ITC New Baskerville]+itc_new_baskerville_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_New_Baskerville_itc_new_baskerville_i4_italic_400.woff2?v=1684753363',
  'VedaFont[ITC Modern No]+itc_modern_no_i5+italic+216216500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Modern_No_216_itc_modern_no_216_i5_italic_500.woff2?v=1684753363',
  'VedaFont[ITC Modern No]+itc_modern_no_n8+normal+216216800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Modern_No_216_itc_modern_no_216_n8_normal_800.woff2?v=1684753363',
  'VedaFont[ITC Johnston]+itc_johnston_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Johnston_itc_johnston_i5_italic_500.woff2?v=1684753363',
  'VedaFont[ITC Gamma]+itc_gamma_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Gamma_itc_gamma_n5_normal_500.woff2?v=1684753363',
  'VedaFont[ITC Goudy Sans]+itc_goudy_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Goudy_Sans_itc_goudy_sans_n4_normal_400.woff2?v=1684753363',
  'VedaFont[ITC Goudy Sans]+itc_goudy_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Goudy_Sans_itc_goudy_sans_n7_normal_700.woff2?v=1684753363',
  'VedaFont[ITC Goudy Sans]+itc_goudy_sans_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Goudy_Sans_itc_goudy_sans_n9_normal_900.woff2?v=1684753362',
  'VedaFont[ITC Johnston]+itc_johnston_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Johnston_itc_johnston_i3_italic_300.woff2?v=1684753363',
  'VedaFont[ITC Modern No]+itc_modern_no_i3+italic+216216300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Modern_No_216_itc_modern_no_216_i3_italic_300.woff2?v=1684753362',
  'VedaFont[ITC Gamma]+itc_gamma_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Gamma_itc_gamma_i5_italic_500.woff2?v=1684753363',
  'VedaFont[ITC Goudy Sans]+itc_goudy_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Goudy_Sans_itc_goudy_sans_i7_italic_700.woff2?v=1684753362',
  'VedaFont[ITC Galliard]+itc_galliard_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Galliard_itc_galliard_n9_normal_900.woff2?v=1684753362',
  'VedaFont[ITC Johnston]+itc_johnston_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Johnston_itc_johnston_n3_normal_300.woff2?v=1684753362',
  'VedaFont[ITC Johnston]+itc_johnston_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Johnston_itc_johnston_n5_normal_500.woff2?v=1684753362',
  'VedaFont[ITC Goudy Sans]+itc_goudy_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Goudy_Sans_itc_goudy_sans_i4_italic_400.woff2?v=1684753363',
  'VedaFont[ITC Gamma]+itc_gamma_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Gamma_itc_gamma_i7_italic_700.woff2?v=1684753363',
  'VedaFont[ITC Galliard]+itc_galliard_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Galliard_itc_galliard_n8_normal_800.woff2?v=1684753363',
  'VedaFont[ITC Galliard]+itc_galliard_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Galliard_itc_galliard_i8_italic_800.woff2?v=1684753363',
  'VedaFont[ITC Galliard]+itc_galliard_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Galliard_itc_galliard_i9_italic_900.woff2?v=1684753362',
  'VedaFont[ITC Johnston]+itc_johnston_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Johnston_itc_johnston_n7_normal_700.woff2?v=1684753362',
  'VedaFont[ITC Gamma]+itc_gamma_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Gamma_itc_gamma_n4_normal_400.woff2?v=1684753362',
  'VedaFont[ITC Galliard]+itc_galliard_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Galliard_itc_galliard_i7_italic_700.woff2?v=1684753362',
  'VedaFont[ITC Galliard]+itc_galliard_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Galliard_itc_galliard_n4_normal_400.woff2?v=1684753362',
  'VedaFont[ITC Galliard]+itc_galliard_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Galliard_itc_galliard_i4_italic_400.woff2?v=1684753362',
  'VedaFont[ITC Franklin Gothic]+itc_franklin_gothic_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Franklin_Gothic_itc_franklin_gothic_i7_italic_700.woff2?v=1684753362',
  'VedaFont[ITC Franklin Gothic]+itc_franklin_gothic_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Franklin_Gothic_itc_franklin_gothic_i5_italic_500.woff2?v=1684753362',
  'VedaFont[ITC Franklin Gothic]+itc_franklin_gothic_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Franklin_Gothic_itc_franklin_gothic_n4_normal_400.woff2?v=1684753362',
  'VedaFont[ITC Franklin Gothic]+itc_franklin_gothic_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Franklin_Gothic_itc_franklin_gothic_i4_italic_400.woff2?v=1684753362',
  'VedaFont[ITC Franklin Gothic]+itc_franklin_gothic_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Franklin_Gothic_itc_franklin_gothic_n5_normal_500.woff2?v=1684753362',
  'VedaFont[ITC Franklin Gothic]+itc_franklin_gothic_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Franklin_Gothic_itc_franklin_gothic_n9_normal_900.woff2?v=1684753362',
  'VedaFont[ITC Franklin Gothic]+itc_franklin_gothic_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Franklin_Gothic_itc_franklin_gothic_i9_italic_900.woff2?v=1684753362',
  'VedaFont[ITC Franklin Gothic]+itc_franklin_gothic_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Franklin_Gothic_itc_franklin_gothic_n7_normal_700.woff2?v=1684753362',
  'VedaFont[ITC Conduit]+itc_conduit_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Conduit_itc_conduit_n3_normal_300.woff2?v=1684753362',
  'VedaFont[ITC Conduit]+itc_conduit_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Conduit_itc_conduit_i4_italic_400.woff2?v=1684753362',
  'VedaFont[ITC Conduit]+itc_conduit_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Conduit_itc_conduit_i2_italic_200.woff2?v=1684753362',
  'VedaFont[ITC Bodoni Seventytwo]+itc_bodoni_seventytwo_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Bodoni_Seventytwo_itc_bodoni_seventytwo_n4_normal_400.woff2?v=1684753362',
  'VedaFont[ITC Clearface]+itc_clearface_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Clearface_itc_clearface_i7_italic_700.woff2?v=1684753362',
  'VedaFont[ITC Conduit]+itc_conduit_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Conduit_itc_conduit_n5_normal_500.woff2?v=1684753362',
  'VedaFont[ITC Conduit]+itc_conduit_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Conduit_itc_conduit_n4_normal_400.woff2?v=1684753362',
  'VedaFont[ITC Founders Caslon]+itc_founders_caslon_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Founders_Caslon_itc_founders_caslon_n4_normal_400.woff2?v=1684753362',
  'VedaFont[ITC Clearface]+itc_clearface_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Clearface_itc_clearface_n8_normal_800.woff2?v=1684753362',
  'VedaFont[ITC Conduit]+itc_conduit_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Conduit_itc_conduit_n2_normal_200.woff2?v=1684753362',
  'VedaFont[ITC Clearface]+itc_clearface_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Clearface_itc_clearface_n7_normal_700.woff2?v=1684753362',
  'VedaFont[ITC Caslon No]+itc_caslon_no_n5+normal+224224500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Caslon_No_224_itc_caslon_no_224_n5_normal_500.woff2?v=1684753362',
  'VedaFont[ITC Clearface]+itc_clearface_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Clearface_itc_clearface_n9_normal_900.woff2?v=1684753362',
  'VedaFont[ITC Clearface]+itc_clearface_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Clearface_itc_clearface_i8_italic_800.woff2?v=1684753362',
  'VedaFont[ITC Clearface]+itc_clearface_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Clearface_itc_clearface_n4_normal_400.woff2?v=1684753362',
  'VedaFont[ITC Clearface]+itc_clearface_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Clearface_itc_clearface_i4_italic_400.woff2?v=1684753362',
  'VedaFont[ITC Berkeley Old Style]+itc_berkeley_old_style_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Berkeley_Old_Style_itc_berkeley_old_style_n7_normal_700.woff2?v=1684753362',
  'VedaFont[ITC Caslon No]+itc_caslon_no_i9+italic+224224900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Caslon_No_224_itc_caslon_no_224_i9_italic_900.woff2?v=1684753362',
  'VedaFont[ITC Bodoni Seventytwo]+itc_bodoni_seventytwo_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Bodoni_Seventytwo_itc_bodoni_seventytwo_i7_italic_700.woff2?v=1684753362',
  'VedaFont[ITC Bodoni Twelve]+itc_bodoni_twelve_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Bodoni_Twelve_itc_bodoni_twelve_n7_normal_700.woff2?v=1684753362',
  'VedaFont[ITC Cheltenham]+itc_cheltenham_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Cheltenham_itc_cheltenham_n9_normal_900.woff2?v=1684753362',
  'VedaFont[ITC Cheltenham]+itc_cheltenham_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Cheltenham_itc_cheltenham_i4_italic_400.woff2?v=1684753362',
  'VedaFont[ITC Berkeley Old Style]+itc_berkeley_old_style_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Berkeley_Old_Style_itc_berkeley_old_style_n9_normal_900.woff2?v=1684753362',
  'VedaFont[ITC Caslon No]+itc_caslon_no_i4+italic+224224400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Caslon_No_224_itc_caslon_no_224_i4_italic_400.woff2?v=1684753362',
  'VedaFont[ITC Bodoni Twelve]+itc_bodoni_twelve_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Bodoni_Twelve_itc_bodoni_twelve_n4_normal_400.woff2?v=1684753362',
  'VedaFont[ITC Bodoni Twelve]+itc_bodoni_twelve_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Bodoni_Twelve_itc_bodoni_twelve_i7_italic_700.woff2?v=1684753362',
  'VedaFont[ITC Bodoni Twelve]+itc_bodoni_twelve_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Bodoni_Twelve_itc_bodoni_twelve_i4_italic_400.woff2?v=1684753362',
  'VedaFont[ITC Charter]+itc_charter_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Charter_itc_charter_i7_italic_700.woff2?v=1684753362',
  'VedaFont[ITC Bodoni Seventytwo]+itc_bodoni_seventytwo_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Bodoni_Seventytwo_itc_bodoni_seventytwo_i4_italic_400.woff2?v=1684753362',
  'VedaFont[ITC Charter]+itc_charter_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Charter_itc_charter_n7_normal_700.woff2?v=1684753362',
  'VedaFont[ITC Charter]+itc_charter_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Charter_itc_charter_n4_normal_400.woff2?v=1684753362',
  'VedaFont[ITC Berkeley Old Style]+itc_berkeley_old_style_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Berkeley_Old_Style_itc_berkeley_old_style_n5_normal_500.woff2?v=1684753362',
  'VedaFont[ITC Berkeley Old Style]+itc_berkeley_old_style_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Berkeley_Old_Style_itc_berkeley_old_style_n4_normal_400.woff2?v=1684753362',
  'VedaFont[ITC Berkeley Old Style]+itc_berkeley_old_style_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Berkeley_Old_Style_itc_berkeley_old_style_i9_italic_900.woff2?v=1684753362',
  'VedaFont[ITC Benguiat]+itc_benguiat_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Benguiat_itc_benguiat_n5_normal_500.woff2?v=1684753362',
  'VedaFont[ITC Berkeley Old Style]+itc_berkeley_old_style_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Berkeley_Old_Style_itc_berkeley_old_style_i4_italic_400.woff2?v=1684753362',
  'VedaFont[ITC Berkeley Old Style]+itc_berkeley_old_style_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Berkeley_Old_Style_itc_berkeley_old_style_i5_italic_500.woff2?v=1684753362',
  'VedaFont[ITC Benguiat]+itc_benguiat_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Benguiat_itc_benguiat_n7_normal_700.woff2?v=1684753362',
  'VedaFont[ITC Benguiat]+itc_benguiat_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Benguiat_itc_benguiat_i7_italic_700.woff2?v=1684753362',
  'VedaFont[ITC Benguiat]+itc_benguiat_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Benguiat_itc_benguiat_i5_italic_500.woff2?v=1684753362',
  'VedaFont[ITC Benguiat]+itc_benguiat_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Benguiat_itc_benguiat_n4_normal_400.woff2?v=1684753362',
  'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_o7+oblique+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Avant_Garde_Gothic_itc_avant_garde_gothic_o7_oblique_700.woff2?v=1684753362',
  'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_o6+oblique+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Avant_Garde_Gothic_itc_avant_garde_gothic_o6_oblique_600.woff2?v=1684753362',
  'VedaFont[ITC Benguiat]+itc_benguiat_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Benguiat_itc_benguiat_i4_italic_400.woff2?v=1684753362',
  'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_o4+oblique+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Avant_Garde_Gothic_itc_avant_garde_gothic_o4_oblique_400.woff2?v=1684753362',
  'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Avant_Garde_Gothic_itc_avant_garde_gothic_n6_normal_600.woff2?v=1684753362',
  'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_o2+oblique+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Avant_Garde_Gothic_itc_avant_garde_gothic_o2_oblique_200.woff2?v=1684753362',
  'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Avant_Garde_Gothic_itc_avant_garde_gothic_n4_normal_400.woff2?v=1684753362',
  'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Avant_Garde_Gothic_itc_avant_garde_gothic_n7_normal_700.woff2?v=1684753362',
  'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_o5+oblique+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Avant_Garde_Gothic_itc_avant_garde_gothic_o5_oblique_500.woff2?v=1684753362',
  'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Avant_Garde_Gothic_itc_avant_garde_gothic_n2_normal_200.woff2?v=1684753362',
  'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Avant_Garde_Gothic_itc_avant_garde_gothic_n5_normal_500.woff2?v=1684753362',
  'VedaFont[Inter]+inter_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Inter_inter_n5_normal_500.woff2?v=1684753362',
  'VedaFont[Inter]+inter_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Inter_inter_n9_normal_900.woff2?v=1684753362',
  'VedaFont[Inconsolata]+inconsolata_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Inconsolata_inconsolata_n7_normal_700.woff2?v=1684753362',
  'VedaFont[Inknut Antiqua]+inknut_antiqua_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Inknut_Antiqua_inknut_antiqua_n5_normal_500.woff2?v=1684753362',
  'VedaFont[Inknut Antiqua]+inknut_antiqua_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Inknut_Antiqua_inknut_antiqua_n7_normal_700.woff2?v=1684753362',
  'VedaFont[Inter]+inter_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Inter_inter_n7_normal_700.woff2?v=1684753362',
  'VedaFont[Inter]+inter_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Inter_inter_n4_normal_400.woff2?v=1684753362',
  'VedaFont[Inter]+inter_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Inter_inter_n1_normal_100.woff2?v=1684753362',
  'VedaFont[Inter]+inter_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Inter_inter_n8_normal_800.woff2?v=1684753362',
  'VedaFont[Inter]+inter_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Inter_inter_n3_normal_300.woff2?v=1684753362',
  'VedaFont[Inter]+inter_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Inter_inter_n6_normal_600.woff2?v=1684753362',
  'VedaFont[Inknut Antiqua]+inknut_antiqua_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Inknut_Antiqua_inknut_antiqua_n8_normal_800.woff2?v=1684753362',
  'VedaFont[Inknut Antiqua]+inknut_antiqua_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Inknut_Antiqua_inknut_antiqua_n6_normal_600.woff2?v=1684753362',
  'VedaFont[Inknut Antiqua]+inknut_antiqua_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Inknut_Antiqua_inknut_antiqua_n3_normal_300.woff2?v=1684753362',
  'VedaFont[Inknut Antiqua]+inknut_antiqua_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Inknut_Antiqua_inknut_antiqua_n9_normal_900.woff2?v=1684753362',
  'VedaFont[Inknut Antiqua]+inknut_antiqua_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Inknut_Antiqua_inknut_antiqua_n4_normal_400.woff2?v=1684753362',
  'VedaFont[Inter]+inter_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Inter_inter_n2_normal_200.woff2?v=1684753362',
  'VedaFont[ITC Esprit]+itc_esprit_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Esprit_itc_esprit_n9_normal_900.woff2?v=1684753362',
  'VedaFont[ITC Conduit]+itc_conduit_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Conduit_itc_conduit_i3_italic_300.woff2?v=1684753362',
  'VedaFont[ITC Cheltenham]+itc_cheltenham_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Cheltenham_itc_cheltenham_i9_italic_900.woff2?v=1684753362',
  'VedaFont[ITC Conduit]+itc_conduit_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Conduit_itc_conduit_i9_italic_900.woff2?v=1684753362',
  'VedaFont[ITC Cheltenham]+itc_cheltenham_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Cheltenham_itc_cheltenham_n4_normal_400.woff2?v=1684753362',
  'VedaFont[ITC Cheltenham]+itc_cheltenham_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Cheltenham_itc_cheltenham_n3_normal_300.woff2?v=1684753362',
  'VedaFont[ITC Charter]+itc_charter_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Charter_itc_charter_i4_italic_400.woff2?v=1684753362',
  'VedaFont[ITC Esprit]+itc_esprit_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Esprit_itc_esprit_n5_normal_500.woff2?v=1684753362',
  'VedaFont[ITC Cheltenham]+itc_cheltenham_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Cheltenham_itc_cheltenham_i3_italic_300.woff2?v=1684753362',
  'VedaFont[ITC Cheltenham]+itc_cheltenham_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Cheltenham_itc_cheltenham_i7_italic_700.woff2?v=1684753361',
  'VedaFont[ITC Founders Caslon]+itc_founders_caslon_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Founders_Caslon_itc_founders_caslon_i4_italic_400.woff2?v=1684753361',
  'VedaFont[ITC Esprit]+itc_esprit_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Esprit_itc_esprit_n7_normal_700.woff2?v=1684753362',
  'VedaFont[ITC Charter]+itc_charter_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Charter_itc_charter_n9_normal_900.woff2?v=1684753362',
  'VedaFont[ITC Caslon No]+itc_caslon_no_n9+normal+224224900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Caslon_No_224_itc_caslon_no_224_n9_normal_900.woff2?v=1684753361',
  'VedaFont[ITC Esprit]+itc_esprit_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Esprit_itc_esprit_n4_normal_400.woff2?v=1684753362',
  'VedaFont[ITC Charter]+itc_charter_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Charter_itc_charter_i9_italic_900.woff2?v=1684753361',
  'VedaFont[ITC Conduit]+itc_conduit_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Conduit_itc_conduit_i5_italic_500.woff2?v=1684753361',
  'VedaFont[ITC Clearface]+itc_clearface_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Clearface_itc_clearface_i9_italic_900.woff2?v=1684753361',
  'VedaFont[ITC Caslon No]+itc_caslon_no_i7+italic+224224700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Caslon_No_224_itc_caslon_no_224_i7_italic_700.woff2?v=1684753362',
  'VedaFont[ITC Esprit]+itc_esprit_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Esprit_itc_esprit_i4_italic_400.woff2?v=1684753361',
  'VedaFont[ITC Esprit]+itc_esprit_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Esprit_itc_esprit_i9_italic_900.woff2?v=1684753361',
  'VedaFont[ITC Cheltenham]+itc_cheltenham_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Cheltenham_itc_cheltenham_n7_normal_700.woff2?v=1684753361',
  'VedaFont[ITC Esprit]+itc_esprit_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Esprit_itc_esprit_i5_italic_500.woff2?v=1684753361',
  'VedaFont[ITC Caslon No]+itc_caslon_no_n4+normal+224224400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Caslon_No_224_itc_caslon_no_224_n4_normal_400.woff2?v=1684753361',
  'VedaFont[ITC Berkeley Old Style]+itc_berkeley_old_style_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Berkeley_Old_Style_itc_berkeley_old_style_i7_italic_700.woff2?v=1684753361',
  'VedaFont[ITC Conduit]+itc_conduit_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Conduit_itc_conduit_n9_normal_900.woff2?v=1684753361',
  'VedaFont[ITC Caslon No]+itc_caslon_no_i5+italic+224224500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Caslon_No_224_itc_caslon_no_224_i5_italic_500.woff2?v=1684753361',
  'VedaFont[ITC Conduit]+itc_conduit_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Conduit_itc_conduit_i8_italic_800.woff2?v=1684753361',
  'VedaFont[ITC Conduit]+itc_conduit_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Conduit_itc_conduit_i7_italic_700.woff2?v=1684753361',
  'VedaFont[ITC Conduit]+itc_conduit_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Conduit_itc_conduit_n7_normal_700.woff2?v=1684753361',
  'VedaFont[ITC Conduit]+itc_conduit_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Conduit_itc_conduit_n8_normal_800.woff2?v=1684753361',
  'VedaFont[ITC Bodoni Seventytwo]+itc_bodoni_seventytwo_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Bodoni_Seventytwo_itc_bodoni_seventytwo_n7_normal_700.woff2?v=1684753361',
  'VedaFont[ITC Caslon No]+itc_caslon_no_n7+normal+224224700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Caslon_No_224_itc_caslon_no_224_n7_normal_700.woff2?v=1684753361',
  'VedaFont[ITC Esprit]+itc_esprit_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_ITC_Esprit_itc_esprit_i7_italic_700.woff2?v=1684753361',
  'VedaFont[Inconsolata]+inconsolata_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Inconsolata_inconsolata_n4_normal_400.woff2?v=1684753361',
  'VedaFont[Georgia Pro]+georgia_pro_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Georgia_Pro_georgia_pro_n6_normal_600.woff2?v=1684753276',
  'VedaFont[Hope Sans]+hope_sans_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Hope_Sans_hope_sans_n2_normal_200.woff2?v=1684753276',
  'VedaFont[IBM Plex Sans]+ibm_plex_sans_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_IBM_Plex_Sans_ibm_plex_sans_i6_italic_600.woff2?v=1684753275',
  'VedaFont[IBM Plex Sans]+ibm_plex_sans_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_IBM_Plex_Sans_ibm_plex_sans_n1_normal_100.woff2?v=1684753275',
  'VedaFont[IBM Plex Sans]+ibm_plex_sans_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_IBM_Plex_Sans_ibm_plex_sans_n6_normal_600.woff2?v=1684753276',
  'VedaFont[IBM Plex Sans]+ibm_plex_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_IBM_Plex_Sans_ibm_plex_sans_i4_italic_400.woff2?v=1684753276',
  'VedaFont[IBM Plex Sans]+ibm_plex_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_IBM_Plex_Sans_ibm_plex_sans_i7_italic_700.woff2?v=1684753276',
  'VedaFont[Gill Sans Nova]+gill_sans_nova_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Gill_Sans_Nova_gill_sans_nova_n1_normal_100.woff2?v=1684753275',
  'VedaFont[IBM Plex Sans]+ibm_plex_sans_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_IBM_Plex_Sans_ibm_plex_sans_n2_normal_200.woff2?v=1684753275',
  'VedaFont[IBM Plex Sans]+ibm_plex_sans_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_IBM_Plex_Sans_ibm_plex_sans_n5_normal_500.woff2?v=1684753276',
  'VedaFont[Gill Sans Nova]+gill_sans_nova_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Gill_Sans_Nova_gill_sans_nova_n5_normal_500.woff2?v=1684753276',
  'VedaFont[IBM Plex Sans]+ibm_plex_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_IBM_Plex_Sans_ibm_plex_sans_n4_normal_400.woff2?v=1684753276',
  'VedaFont[IBM Plex Sans]+ibm_plex_sans_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_IBM_Plex_Sans_ibm_plex_sans_i5_italic_500.woff2?v=1684753276',
  'VedaFont[IBM Plex Sans]+ibm_plex_sans_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_IBM_Plex_Sans_ibm_plex_sans_i1_italic_100.woff2?v=1684753275',
  'VedaFont[IBM Plex Sans]+ibm_plex_sans_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_IBM_Plex_Sans_ibm_plex_sans_n3_normal_300.woff2?v=1684753276',
  'VedaFont[IBM Plex Sans]+ibm_plex_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_IBM_Plex_Sans_ibm_plex_sans_n7_normal_700.woff2?v=1684753275',
  'VedaFont[Humanist]+humanist_i4+italic+521521400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Humanist_521_humanist_521_i4_italic_400.woff2?v=1684753275',
  'VedaFont[IBM Plex Sans]+ibm_plex_sans_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_IBM_Plex_Sans_ibm_plex_sans_i3_italic_300.woff2?v=1684753275',
  'VedaFont[Hope Sans]+hope_sans_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Hope_Sans_hope_sans_n6_normal_600.woff2?v=1684753276',
  'VedaFont[Hope Sans]+hope_sans_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Hope_Sans_hope_sans_n3_normal_300.woff2?v=1684753275',
  'VedaFont[Humanist]+humanist_i7+italic+521521700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Humanist_521_humanist_521_i7_italic_700.woff2?v=1684753275',
  'VedaFont[Humanist]+humanist_n8+normal+521521800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Humanist_521_humanist_521_n8_normal_800.woff2?v=1684753276',
  'VedaFont[Hope Sans]+hope_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Hope_Sans_hope_sans_n7_normal_700.woff2?v=1684753275',
  'VedaFont[Hope Sans]+hope_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Hope_Sans_hope_sans_i4_italic_400.woff2?v=1684753275',
  'VedaFont[Futura]+futura_o8+oblique+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Futura_futura_o8_oblique_800.woff2?v=1684753275',
  'VedaFont[Humanist]+humanist_n9+normal+521521900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Humanist_521_humanist_521_n9_normal_900.woff2?v=1684753275',
  'VedaFont[FS Siena]+fs_siena_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Siena_fs_siena_i1_italic_100.woff2?v=1684753276',
  'VedaFont[IBM Plex Sans]+ibm_plex_sans_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_IBM_Plex_Sans_ibm_plex_sans_i2_italic_200.woff2?v=1684753276',
  'VedaFont[Hope Sans]+hope_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Hope_Sans_hope_sans_n4_normal_400.woff2?v=1684753275',
  'VedaFont[Humanist]+humanist_n2+normal+521521200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Humanist_521_humanist_521_n2_normal_200.woff2?v=1684753276',
  'VedaFont[Humanist]+humanist_n7+normal+521521700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Humanist_521_humanist_521_n7_normal_700.woff2?v=1684753275',
  'VedaFont[Humanist]+humanist_n4+normal+521521400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Humanist_521_humanist_521_n4_normal_400.woff2?v=1684753275',
  'VedaFont[Helvetica]+helvetica_o7+oblique+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Helvetica_helvetica_o7_oblique_700.woff2?v=1684753275',
  'VedaFont[FS Koopman]+fs_koopman_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Koopman_fs_koopman_n1_normal_100.woff2?v=1684753276',
  'VedaFont[Harmonia Sans]+harmonia_sans_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Harmonia_Sans_harmonia_sans_i6_italic_600.woff2?v=1684753275',
  'VedaFont[Hope Sans]+hope_sans_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Hope_Sans_hope_sans_i1_italic_100.woff2?v=1684753275',
  'VedaFont[Hope Sans]+hope_sans_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Hope_Sans_hope_sans_i6_italic_600.woff2?v=1684753275',
  'VedaFont[Hope Sans]+hope_sans_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Hope_Sans_hope_sans_n1_normal_100.woff2?v=1684753275',
  'VedaFont[Helvetica]+helvetica_o4+oblique+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Helvetica_helvetica_o4_oblique_400.woff2?v=1684753275',
  'VedaFont[Goudy Old Style]+goudy_old_style_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Goudy_Old_Style_goudy_old_style_n8_normal_800.woff2?v=1684753275',
  'VedaFont[Hope Sans]+hope_sans_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Hope_Sans_hope_sans_i3_italic_300.woff2?v=1684753275',
  'VedaFont[Hope Sans]+hope_sans_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Hope_Sans_hope_sans_i2_italic_200.woff2?v=1684753275',
  'VedaFont[Harmonia Sans]+harmonia_sans_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Harmonia_Sans_harmonia_sans_n9_normal_900.woff2?v=1684753275',
  'VedaFont[Humanist]+humanist_i2+italic+521521200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Humanist_521_humanist_521_i2_italic_200.woff2?v=1684753275',
  'VedaFont[Hope Sans]+hope_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Hope_Sans_hope_sans_i7_italic_700.woff2?v=1684753275',
  'VedaFont[Harmonia Sans]+harmonia_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Harmonia_Sans_harmonia_sans_n4_normal_400.woff2?v=1684753275',
  'VedaFont[Goudy Old Style]+goudy_old_style_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Goudy_Old_Style_goudy_old_style_i7_italic_700.woff2?v=1684753275',
  'VedaFont[Helvetica]+helvetica_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Helvetica_helvetica_n7_normal_700.woff2?v=1684753275',
  'VedaFont[Helvetica]+helvetica_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Helvetica_helvetica_n4_normal_400.woff2?v=1684753276',
  'VedaFont[Helvetica]+helvetica_o3+oblique+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Helvetica_helvetica_o3_oblique_300.woff2?v=1684753275',
  'VedaFont[Harmonia Sans]+harmonia_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Harmonia_Sans_harmonia_sans_i7_italic_700.woff2?v=1684753275',
  'VedaFont[Goudy Old Style]+goudy_old_style_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Goudy_Old_Style_goudy_old_style_n4_normal_400.woff2?v=1684753275',
  'VedaFont[Glegoo]+glegoo_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Glegoo_glegoo_n7_normal_700.woff2?v=1684753275',
  'VedaFont[Helvetica]+helvetica_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Helvetica_helvetica_n9_normal_900.woff2?v=1684753275',
  'VedaFont[Harmonia Sans]+harmonia_sans_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Harmonia_Sans_harmonia_sans_n3_normal_300.woff2?v=1684753275',
  'VedaFont[Helvetica]+helvetica_o9+oblique+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Helvetica_helvetica_o9_oblique_900.woff2?v=1684753275',
  'VedaFont[Helvetica]+helvetica_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Helvetica_helvetica_n3_normal_300.woff2?v=1684753276',
  'VedaFont[Goudy Old Style]+goudy_old_style_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Goudy_Old_Style_goudy_old_style_n7_normal_700.woff2?v=1684753275',
  'VedaFont[Harmonia Sans]+harmonia_sans_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Harmonia_Sans_harmonia_sans_n6_normal_600.woff2?v=1684753275',
  'VedaFont[Gill Sans Nova]+gill_sans_nova_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Gill_Sans_Nova_gill_sans_nova_i6_italic_600.woff2?v=1684753275',
  'VedaFont[Harmonia Sans]+harmonia_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Harmonia_Sans_harmonia_sans_n7_normal_700.woff2?v=1684753275',
  'VedaFont[Glegoo]+glegoo_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Glegoo_glegoo_n4_normal_400.woff2?v=1684753275',
  'VedaFont[Harmonia Sans]+harmonia_sans_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Harmonia_Sans_harmonia_sans_i9_italic_900.woff2?v=1684753275',
  'VedaFont[Goudy Old Style]+goudy_old_style_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Goudy_Old_Style_goudy_old_style_i4_italic_400.woff2?v=1684753275',
  'VedaFont[Harmonia Sans]+harmonia_sans_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Harmonia_Sans_harmonia_sans_i3_italic_300.woff2?v=1684753275',
  'VedaFont[Harmonia Sans]+harmonia_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Harmonia_Sans_harmonia_sans_i4_italic_400.woff2?v=1684753275',
  'VedaFont[Gill Sans Nova]+gill_sans_nova_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Gill_Sans_Nova_gill_sans_nova_n7_normal_700.woff2?v=1684753275',
  'VedaFont[FS Siena]+fs_siena_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Siena_fs_siena_i6_italic_600.woff2?v=1684753275',
  'VedaFont[Gill Sans Nova]+gill_sans_nova_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Gill_Sans_Nova_gill_sans_nova_n6_normal_600.woff2?v=1684753275',
  'VedaFont[Gill Sans Nova]+gill_sans_nova_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Gill_Sans_Nova_gill_sans_nova_n8_normal_800.woff2?v=1684753275',
  'VedaFont[Gill Sans Nova]+gill_sans_nova_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Gill_Sans_Nova_gill_sans_nova_n3_normal_300.woff2?v=1684753275',
  'VedaFont[Gill Sans Nova]+gill_sans_nova_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Gill_Sans_Nova_gill_sans_nova_n4_normal_400.woff2?v=1684753275',
  'VedaFont[Georgia Pro]+georgia_pro_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Georgia_Pro_georgia_pro_n9_normal_900.woff2?v=1684753275',
  'VedaFont[Gill Sans Nova]+gill_sans_nova_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Gill_Sans_Nova_gill_sans_nova_n9_normal_900.woff2?v=1684753275',
  'VedaFont[Georgia Pro]+georgia_pro_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Georgia_Pro_georgia_pro_n7_normal_700.woff2?v=1684753275',
  'VedaFont[Georgia Pro]+georgia_pro_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Georgia_Pro_georgia_pro_i7_italic_700.woff2?v=1684753275',
  'VedaFont[FS Koopman]+fs_koopman_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Koopman_fs_koopman_n8_normal_800.woff2?v=1684753275',
  'VedaFont[Gill Sans Nova]+gill_sans_nova_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Gill_Sans_Nova_gill_sans_nova_i1_italic_100.woff2?v=1684753275',
  'VedaFont[Geometric]+geometric_i7+italic+415415700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Geometric_415_geometric_415_i7_italic_700.woff2?v=1684753275',
  'VedaFont[Gill Sans Nova]+gill_sans_nova_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Gill_Sans_Nova_gill_sans_nova_n2_normal_200.woff2?v=1684753275',
  'VedaFont[Gill Sans Nova]+gill_sans_nova_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Gill_Sans_Nova_gill_sans_nova_i4_italic_400.woff2?v=1684753275',
  'VedaFont[Georgia Pro]+georgia_pro_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Georgia_Pro_georgia_pro_i3_italic_300.woff2?v=1684753275',
  'VedaFont[Geometric]+geometric_i4+italic+415415400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Geometric_415_geometric_415_i4_italic_400.woff2?v=1684753275',
  'VedaFont[FS Siena]+fs_siena_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Siena_fs_siena_n1_normal_100.woff2?v=1684753275',
  'VedaFont[Gill Sans Nova]+gill_sans_nova_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Gill_Sans_Nova_gill_sans_nova_i2_italic_200.woff2?v=1684753275',
  'VedaFont[Georgia Pro]+georgia_pro_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Georgia_Pro_georgia_pro_n4_normal_400.woff2?v=1684753275',
  'VedaFont[Geometric]+geometric_n7+normal+415415700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Geometric_415_geometric_415_n7_normal_700.woff2?v=1684753275',
  'VedaFont[Georgia Pro]+georgia_pro_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Georgia_Pro_georgia_pro_n3_normal_300.woff2?v=1684753275',
  'VedaFont[Gill Sans Nova]+gill_sans_nova_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Gill_Sans_Nova_gill_sans_nova_i3_italic_300.woff2?v=1684753275',
  'VedaFont[Gill Sans Nova]+gill_sans_nova_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Gill_Sans_Nova_gill_sans_nova_i5_italic_500.woff2?v=1684753275',
  'VedaFont[Frutiger Serif]+frutiger_serif_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Frutiger_Serif_frutiger_serif_n7_normal_700.woff2?v=1684753275',
  'VedaFont[FS Siena]+fs_siena_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Siena_fs_siena_i7_italic_700.woff2?v=1684753275',
  'VedaFont[Frutiger Serif]+frutiger_serif_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Frutiger_Serif_frutiger_serif_n8_normal_800.woff2?v=1684753275',
  'VedaFont[FS Koopman]+fs_koopman_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Koopman_fs_koopman_i9_italic_900.woff2?v=1684753275',
  'VedaFont[Futura]+futura_o5+oblique+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Futura_futura_o5_oblique_500.woff2?v=1684753275',
  'VedaFont[Gill Sans Nova]+gill_sans_nova_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Gill_Sans_Nova_gill_sans_nova_i7_italic_700.woff2?v=1684753275',
  'VedaFont[Futura]+futura_o4+oblique+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Futura_futura_o4_oblique_400.woff2?v=1684753275',
  'VedaFont[FS Kim]+fs_kim_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Kim_fs_kim_n7_normal_700.woff2?v=1684753275',
  'VedaFont[Geometric]+geometric_n4+normal+415415400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Geometric_415_geometric_415_n4_normal_400.woff2?v=1684753275',
  'VedaFont[FS Koopman]+fs_koopman_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Koopman_fs_koopman_n7_normal_700.woff2?v=1684753275',
  'VedaFont[Geometric]+geometric_n6+normal+415415600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Geometric_415_geometric_415_n6_normal_600.woff2?v=1684753275',
  'VedaFont[FS Siena]+fs_siena_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Siena_fs_siena_n2_normal_200.woff2?v=1684753275',
  'VedaFont[Georgia Pro]+georgia_pro_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Georgia_Pro_georgia_pro_i4_italic_400.woff2?v=1684753275',
  'VedaFont[Futura]+futura_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Futura_futura_n8_normal_800.woff2?v=1684753275',
  'VedaFont[Georgia Pro]+georgia_pro_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Georgia_Pro_georgia_pro_i9_italic_900.woff2?v=1684753275',
  'VedaFont[FS Siena]+fs_siena_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Siena_fs_siena_n4_normal_400.woff2?v=1684753275',
  'VedaFont[Georgia Pro]+georgia_pro_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Georgia_Pro_georgia_pro_i6_italic_600.woff2?v=1684753275',
  'VedaFont[Geometric]+geometric_i6+italic+415415600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Geometric_415_geometric_415_i6_italic_600.woff2?v=1684753275',
  'VedaFont[Futura]+futura_o6+oblique+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Futura_futura_o6_oblique_600.woff2?v=1684753275',
  'VedaFont[Futura]+futura_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Futura_futura_n7_normal_700.woff2?v=1684753275',
  'VedaFont[Futura]+futura_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Futura_futura_n9_normal_900.woff2?v=1684753275',
  'VedaFont[Frutiger Serif]+frutiger_serif_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Frutiger_Serif_frutiger_serif_i3_italic_300.woff2?v=1684753275',
  'VedaFont[Futura]+futura_o7+oblique+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Futura_futura_o7_oblique_700.woff2?v=1684753275',
  'VedaFont[Futura]+futura_o3+oblique+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Futura_futura_o3_oblique_300.woff2?v=1684753275',
  'VedaFont[Futura]+futura_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Futura_futura_n4_normal_400.woff2?v=1684753275',
  'VedaFont[Fira Sans]+fira_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fira_Sans_fira_sans_n7_normal_700.woff2?v=1684753275',
  'VedaFont[Frutiger Serif]+frutiger_serif_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Frutiger_Serif_frutiger_serif_i8_italic_800.woff2?v=1684753275',
  'VedaFont[FS Koopman]+fs_koopman_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Koopman_fs_koopman_i7_italic_700.woff2?v=1684753275',
  'VedaFont[FS Koopman]+fs_koopman_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Koopman_fs_koopman_i4_italic_400.woff2?v=1684753275',
  'VedaFont[Frutiger Serif]+frutiger_serif_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Frutiger_Serif_frutiger_serif_n4_normal_400.woff2?v=1684753275',
  'VedaFont[Futura]+futura_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Futura_futura_n3_normal_300.woff2?v=1684753275',
  'VedaFont[FS Siena]+fs_siena_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Siena_fs_siena_n6_normal_600.woff2?v=1684753275',
  'VedaFont[FS Siena]+fs_siena_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Siena_fs_siena_n7_normal_700.woff2?v=1684753275',
  'VedaFont[Futura]+futura_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Futura_futura_n5_normal_500.woff2?v=1684753275',
  'VedaFont[Fjalla One]+fjalla_one_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fjalla_One_fjalla_one_n4_normal_400.woff2?v=1684753275',
  'VedaFont[Futura]+futura_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Futura_futura_n6_normal_600.woff2?v=1684753275',
  'VedaFont[FS Siena]+fs_siena_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Siena_fs_siena_n5_normal_500.woff2?v=1684753275',
  'VedaFont[Futura Black]+futura_black_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Futura_Black_futura_black_n4_normal_400.woff2?v=1684753275',
  'VedaFont[Fira Sans]+fira_sans_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fira_Sans_fira_sans_n1_normal_100.woff2?v=1684753275',
  'VedaFont[FS Koopman]+fs_koopman_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Koopman_fs_koopman_n3_normal_300.woff2?v=1684753275',
  'VedaFont[FS Kim]+fs_kim_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Kim_fs_kim_i8_italic_800.woff2?v=1684753275',
  'VedaFont[FS Koopman]+fs_koopman_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Koopman_fs_koopman_n5_normal_500.woff2?v=1684753275',
  'VedaFont[FS Siena]+fs_siena_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Siena_fs_siena_i5_italic_500.woff2?v=1684753275',
  'VedaFont[Fira Sans]+fira_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fira_Sans_fira_sans_i4_italic_400.woff2?v=1684753275',
  'VedaFont[FS Siena]+fs_siena_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Siena_fs_siena_i2_italic_200.woff2?v=1684753275',
  'VedaFont[FS Kim]+fs_kim_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Kim_fs_kim_i9_italic_900.woff2?v=1684753275',
  'VedaFont[FS Siena]+fs_siena_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Siena_fs_siena_n3_normal_300.woff2?v=1684753275',
  'VedaFont[Friz Quadrata]+friz_quadrata_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Friz_Quadrata_friz_quadrata_i7_italic_700.woff2?v=1684753275',
  'VedaFont[FS Siena]+fs_siena_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Siena_fs_siena_i4_italic_400.woff2?v=1684753275',
  'VedaFont[FS Koopman]+fs_koopman_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Koopman_fs_koopman_n4_normal_400.woff2?v=1684753275',
  'VedaFont[FS Siena]+fs_siena_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Siena_fs_siena_i3_italic_300.woff2?v=1684753275',
  'VedaFont[FS Kim]+fs_kim_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Kim_fs_kim_n5_normal_500.woff2?v=1684753275',
  'VedaFont[FS Kim]+fs_kim_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Kim_fs_kim_n9_normal_900.woff2?v=1684753275',
  'VedaFont[FS Koopman]+fs_koopman_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Koopman_fs_koopman_n9_normal_900.woff2?v=1684753275',
  'VedaFont[FS Kim]+fs_kim_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Kim_fs_kim_i5_italic_500.woff2?v=1684753275',
  'VedaFont[FS Kim]+fs_kim_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Kim_fs_kim_i7_italic_700.woff2?v=1684753275',
  'VedaFont[FS Koopman]+fs_koopman_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Koopman_fs_koopman_i8_italic_800.woff2?v=1684753275',
  'VedaFont[FF Unit Slab]+ff_unit_slab_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Slab_ff_unit_slab_n4_normal_400.woff2?v=1684753275',
  'VedaFont[FS Koopman]+fs_koopman_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Koopman_fs_koopman_i5_italic_500.woff2?v=1684753275',
  'VedaFont[Frutiger Serif]+frutiger_serif_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Frutiger_Serif_frutiger_serif_i5_italic_500.woff2?v=1684753275',
  'VedaFont[FS Kim]+fs_kim_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Kim_fs_kim_n4_normal_400.woff2?v=1684753275',
  'VedaFont[Fira Sans]+fira_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fira_Sans_fira_sans_i7_italic_700.woff2?v=1684753275',
  'VedaFont[FS Kim]+fs_kim_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Kim_fs_kim_n8_normal_800.woff2?v=1684753275',
  'VedaFont[FS Kim]+fs_kim_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Kim_fs_kim_i4_italic_400.woff2?v=1684753275',
  'VedaFont[FS Koopman]+fs_koopman_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FS_Koopman_fs_koopman_i1_italic_100.woff2?v=1684753275',
  'VedaFont[FF Unit Slab]+ff_unit_slab_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Slab_ff_unit_slab_n9_normal_900.woff2?v=1684753275',
  'VedaFont[FF Unit]+ff_unit_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_ff_unit_n8_normal_800.woff2?v=1684753275',
  'VedaFont[Frutiger Serif]+frutiger_serif_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Frutiger_Serif_frutiger_serif_n3_normal_300.woff2?v=1684753275',
  'VedaFont[Friz Quadrata]+friz_quadrata_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Friz_Quadrata_friz_quadrata_n7_normal_700.woff2?v=1684753275',
  'VedaFont[FF Unit Slab]+ff_unit_slab_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Slab_ff_unit_slab_n3_normal_300.woff2?v=1684753275',
  'VedaFont[Frutiger Serif]+frutiger_serif_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Frutiger_Serif_frutiger_serif_n5_normal_500.woff2?v=1684753275',
  'VedaFont[FF Unit Slab]+ff_unit_slab_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Slab_ff_unit_slab_n2_normal_200.woff2?v=1684753275',
  'VedaFont[FF Unit]+ff_unit_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_ff_unit_n3_normal_300.woff2?v=1684753275',
  'VedaFont[FF Unit]+ff_unit_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_ff_unit_i3_italic_300.woff2?v=1684753275',
  'VedaFont[Friz Quadrata]+friz_quadrata_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Friz_Quadrata_friz_quadrata_n4_normal_400.woff2?v=1684753275',
  'VedaFont[Frutiger Serif]+frutiger_serif_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Frutiger_Serif_frutiger_serif_i7_italic_700.woff2?v=1684753275',
  'VedaFont[Fira Sans]+fira_sans_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fira_Sans_fira_sans_i1_italic_100.woff2?v=1684753275',
  'VedaFont[Fira Sans]+fira_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fira_Sans_fira_sans_n4_normal_400.woff2?v=1684753275',
  'VedaFont[FF Unit]+ff_unit_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_ff_unit_i2_italic_200.woff2?v=1684753275',
  'VedaFont[Fira Sans]+fira_sans_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fira_Sans_fira_sans_n6_normal_600.woff2?v=1684753275',
  'VedaFont[FF Unit]+ff_unit_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_ff_unit_i9_italic_900.woff2?v=1684753275',
  'VedaFont[FF Unit]+ff_unit_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_ff_unit_i7_italic_700.woff2?v=1684753275',
  'VedaFont[FF Unit]+ff_unit_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_ff_unit_i4_italic_400.woff2?v=1684753275',
  'VedaFont[FF Unit]+ff_unit_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_ff_unit_i8_italic_800.woff2?v=1684753275',
  'VedaFont[FF Unit Slab]+ff_unit_slab_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Slab_ff_unit_slab_n8_normal_800.woff2?v=1684753275',
  'VedaFont[FF Unit]+ff_unit_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_ff_unit_n2_normal_200.woff2?v=1684753275',
  'VedaFont[FF Unit]+ff_unit_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_ff_unit_i5_italic_500.woff2?v=1684753275',
  'VedaFont[FF Unit Slab]+ff_unit_slab_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Slab_ff_unit_slab_n5_normal_500.woff2?v=1684753275',
  'VedaFont[FF Unit Slab]+ff_unit_slab_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Slab_ff_unit_slab_n7_normal_700.woff2?v=1684753275',
  'VedaFont[Frutiger Serif]+frutiger_serif_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Frutiger_Serif_frutiger_serif_i4_italic_400.woff2?v=1684753275',
  'VedaFont[Fira Sans]+fira_sans_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fira_Sans_fira_sans_n2_normal_200.woff2?v=1684753275',
  'VedaFont[FF Unit Slab]+ff_unit_slab_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Slab_ff_unit_slab_i7_italic_700.woff2?v=1684753275',
  'VedaFont[Friz Quadrata]+friz_quadrata_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Friz_Quadrata_friz_quadrata_i4_italic_400.woff2?v=1684753274',
  'VedaFont[FF Unit Slab]+ff_unit_slab_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Slab_ff_unit_slab_i8_italic_800.woff2?v=1684753275',
  'VedaFont[Fira Sans]+fira_sans_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fira_Sans_fira_sans_i2_italic_200.woff2?v=1684753274',
  'VedaFont[Fira Sans]+fira_sans_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fira_Sans_fira_sans_n3_normal_300.woff2?v=1684753275',
  'VedaFont[Fira Sans]+fira_sans_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fira_Sans_fira_sans_i6_italic_600.woff2?v=1684753275',
  'VedaFont[FF Unit Slab]+ff_unit_slab_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Slab_ff_unit_slab_i9_italic_900.woff2?v=1684753275',
  'VedaFont[Fira Sans]+fira_sans_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fira_Sans_fira_sans_i3_italic_300.woff2?v=1684753275',
  'VedaFont[Fira Sans]+fira_sans_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fira_Sans_fira_sans_n8_normal_800.woff2?v=1684753274',
  'VedaFont[Fira Sans]+fira_sans_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fira_Sans_fira_sans_i9_italic_900.woff2?v=1684753275',
  'VedaFont[Fira Sans]+fira_sans_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fira_Sans_fira_sans_n9_normal_900.woff2?v=1684753275',
  'VedaFont[Fira Sans]+fira_sans_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fira_Sans_fira_sans_n5_normal_500.woff2?v=1684753274',
  'VedaFont[FF Unit]+ff_unit_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_ff_unit_n7_normal_700.woff2?v=1684753275',
  'VedaFont[FF Unit]+ff_unit_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_ff_unit_n4_normal_400.woff2?v=1684753274',
  'VedaFont[Fira Sans]+fira_sans_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fira_Sans_fira_sans_i8_italic_800.woff2?v=1684753274',
  'VedaFont[Fira Sans]+fira_sans_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fira_Sans_fira_sans_i5_italic_500.woff2?v=1684753274',
  'VedaFont[FF Unit]+ff_unit_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_ff_unit_n5_normal_500.woff2?v=1684753275',
  'VedaFont[FF Unit]+ff_unit_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_ff_unit_n9_normal_900.woff2?v=1684753274',
  'VedaFont[FF Tisa]+ff_tisa_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_ff_tisa_n5_normal_500.woff2?v=1684753196',
  'VedaFont[FF Unit Slab]+ff_unit_slab_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Slab_ff_unit_slab_i5_italic_500.woff2?v=1684753196',
  'VedaFont[FF Unit Rounded]+ff_unit_rounded_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Rounded_ff_unit_rounded_n8_normal_800.woff2?v=1684753196',
  'VedaFont[FF Unit Slab]+ff_unit_slab_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Slab_ff_unit_slab_i2_italic_200.woff2?v=1684753196',
  'VedaFont[FF Tisa]+ff_tisa_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_ff_tisa_i9_italic_900.woff2?v=1684753196',
  'VedaFont[FF Unit Slab]+ff_unit_slab_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Slab_ff_unit_slab_i3_italic_300.woff2?v=1684753196',
  'VedaFont[FF Tisa]+ff_tisa_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_ff_tisa_n2_normal_200.woff2?v=1684753196',
  'VedaFont[FF Unit Rounded]+ff_unit_rounded_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Rounded_ff_unit_rounded_n9_normal_900.woff2?v=1684753196',
  'VedaFont[FF Unit Rounded]+ff_unit_rounded_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Rounded_ff_unit_rounded_n3_normal_300.woff2?v=1684753196',
  'VedaFont[FF Unit Rounded]+ff_unit_rounded_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Rounded_ff_unit_rounded_i9_italic_900.woff2?v=1684753196',
  'VedaFont[FF Tisa]+ff_tisa_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_ff_tisa_n4_normal_400.woff2?v=1684753196',
  'VedaFont[FF Unit Rounded]+ff_unit_rounded_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Rounded_ff_unit_rounded_i3_italic_300.woff2?v=1684753196',
  'VedaFont[FF Tisa]+ff_tisa_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_ff_tisa_n3_normal_300.woff2?v=1684753196',
  'VedaFont[FF Tisa]+ff_tisa_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_ff_tisa_i8_italic_800.woff2?v=1684753196',
  'VedaFont[FF Unit Rounded]+ff_unit_rounded_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Rounded_ff_unit_rounded_i7_italic_700.woff2?v=1684753196',
  'VedaFont[FF Meta]+ff_meta_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_ff_meta_i2_italic_200.woff2?v=1684753196',
  'VedaFont[FF Unit Slab]+ff_unit_slab_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Slab_ff_unit_slab_i4_italic_400.woff2?v=1684753196',
  'VedaFont[FF Unit Rounded]+ff_unit_rounded_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Rounded_ff_unit_rounded_n5_normal_500.woff2?v=1684753196',
  'VedaFont[FF Unit Rounded]+ff_unit_rounded_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Rounded_ff_unit_rounded_i8_italic_800.woff2?v=1684753196',
  'VedaFont[FF Unit Rounded]+ff_unit_rounded_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Rounded_ff_unit_rounded_n4_normal_400.woff2?v=1684753196',
  'VedaFont[FF Unit Rounded]+ff_unit_rounded_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Rounded_ff_unit_rounded_n7_normal_700.woff2?v=1684753196',
  'VedaFont[FF Tisa Sans]+ff_tisa_sans_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_Sans_ff_tisa_sans_n9_normal_900.woff2?v=1684753196',
  'VedaFont[FF Tisa]+ff_tisa_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_ff_tisa_n7_normal_700.woff2?v=1684753196',
  'VedaFont[FF Tisa]+ff_tisa_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_ff_tisa_n9_normal_900.woff2?v=1684753196',
  'VedaFont[FF Unit Rounded]+ff_unit_rounded_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Rounded_ff_unit_rounded_i5_italic_500.woff2?v=1684753196',
  'VedaFont[FF Unit Rounded]+ff_unit_rounded_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Unit_Rounded_ff_unit_rounded_i4_italic_400.woff2?v=1684753196',
  'VedaFont[FF Tisa]+ff_tisa_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_ff_tisa_n8_normal_800.woff2?v=1684753196',
  'VedaFont[FF Tisa]+ff_tisa_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_ff_tisa_i7_italic_700.woff2?v=1684753196',
  'VedaFont[FF Tisa]+ff_tisa_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_ff_tisa_i2_italic_200.woff2?v=1684753196',
  'VedaFont[FF Tisa]+ff_tisa_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_ff_tisa_i4_italic_400.woff2?v=1684753196',
  'VedaFont[FF Tisa Sans]+ff_tisa_sans_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_Sans_ff_tisa_sans_n8_normal_800.woff2?v=1684753196',
  'VedaFont[DIN Next]+din_next_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_din_next_i7_italic_700.woff2?v=1684753196',
  'VedaFont[Electra]+electra_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Electra_electra_n4_normal_400.woff2?v=1684753196',
  'VedaFont[Eurostile Next]+eurostile_next_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Eurostile_Next_eurostile_next_n3_normal_300.woff2?v=1684753196',
  'VedaFont[Dosis]+dosis_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Dosis_dosis_n6_normal_600.woff2?v=1684753196',
  'VedaFont[FF Tisa Sans]+ff_tisa_sans_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_Sans_ff_tisa_sans_i5_italic_500.woff2?v=1684753196',
  'VedaFont[FF Tisa Sans]+ff_tisa_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_Sans_ff_tisa_sans_i7_italic_700.woff2?v=1684753196',
  'VedaFont[FF Tisa]+ff_tisa_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_ff_tisa_i5_italic_500.woff2?v=1684753196',
  'VedaFont[FF Tisa]+ff_tisa_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_ff_tisa_i3_italic_300.woff2?v=1684753196',
  'VedaFont[FF Meta]+ff_meta_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_ff_meta_i3_italic_300.woff2?v=1684753196',
  'VedaFont[FF Meta]+ff_meta_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_ff_meta_n9_normal_900.woff2?v=1684753196',
  'VedaFont[FF Tisa Sans]+ff_tisa_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_Sans_ff_tisa_sans_n4_normal_400.woff2?v=1684753196',
  'VedaFont[FF Meta]+ff_meta_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_ff_meta_i4_italic_400.woff2?v=1684753196',
  'VedaFont[FF Tisa Sans]+ff_tisa_sans_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_Sans_ff_tisa_sans_n2_normal_200.woff2?v=1684753196',
  'VedaFont[FF Tisa Sans]+ff_tisa_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_Sans_ff_tisa_sans_n7_normal_700.woff2?v=1684753196',
  'VedaFont[FF Tisa Sans]+ff_tisa_sans_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_Sans_ff_tisa_sans_i8_italic_800.woff2?v=1684753196',
  'VedaFont[FF Meta]+ff_meta_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_ff_meta_n2_normal_200.woff2?v=1684753196',
  'VedaFont[FF Tisa Sans]+ff_tisa_sans_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_Sans_ff_tisa_sans_n3_normal_300.woff2?v=1684753196',
  'VedaFont[FF Meta]+ff_meta_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_ff_meta_n7_normal_700.woff2?v=1684753196',
  'VedaFont[FF Tisa Sans]+ff_tisa_sans_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_Sans_ff_tisa_sans_n5_normal_500.woff2?v=1684753196',
  'VedaFont[FF Tisa Sans]+ff_tisa_sans_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_Sans_ff_tisa_sans_i9_italic_900.woff2?v=1684753196',
  'VedaFont[FF Tisa Sans]+ff_tisa_sans_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_Sans_ff_tisa_sans_i3_italic_300.woff2?v=1684753196',
  'VedaFont[FF Tisa Sans]+ff_tisa_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_Sans_ff_tisa_sans_i4_italic_400.woff2?v=1684753196',
  'VedaFont[FF Meta]+ff_meta_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_ff_meta_n4_normal_400.woff2?v=1684753196',
  'VedaFont[FF Meta]+ff_meta_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_ff_meta_n5_normal_500.woff2?v=1684753196',
  'VedaFont[FF Tisa Sans]+ff_tisa_sans_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Tisa_Sans_ff_tisa_sans_i2_italic_200.woff2?v=1684753196',
  'VedaFont[FF Meta]+ff_meta_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_ff_meta_i5_italic_500.woff2?v=1684753196',
  'VedaFont[FF Meta Serif]+ff_meta_serif_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_Serif_ff_meta_serif_n8_normal_800.woff2?v=1684753196',
  'VedaFont[FF Meta]+ff_meta_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_ff_meta_n3_normal_300.woff2?v=1684753196',
  'VedaFont[FF Meta]+ff_meta_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_ff_meta_n1_normal_100.woff2?v=1684753196',
  'VedaFont[FF Meta Serif]+ff_meta_serif_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_Serif_ff_meta_serif_i7_italic_700.woff2?v=1684753195',
  'VedaFont[FF Meta]+ff_meta_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_ff_meta_i7_italic_700.woff2?v=1684753196',
  'VedaFont[FF Meta Serif]+ff_meta_serif_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_Serif_ff_meta_serif_n5_normal_500.woff2?v=1684753196',
  'VedaFont[FF Meta]+ff_meta_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_ff_meta_n6_normal_600.woff2?v=1684753195',
  'VedaFont[FF Meta]+ff_meta_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_ff_meta_i9_italic_900.woff2?v=1684753196',
  'VedaFont[FF Meta Serif]+ff_meta_serif_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_Serif_ff_meta_serif_n9_normal_900.woff2?v=1684753195',
  'VedaFont[FF Meta Serif]+ff_meta_serif_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_Serif_ff_meta_serif_n4_normal_400.woff2?v=1684753196',
  'VedaFont[FF Meta]+ff_meta_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_ff_meta_i1_italic_100.woff2?v=1684753195',
  'VedaFont[FF Meta]+ff_meta_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_ff_meta_i6_italic_600.woff2?v=1684753195',
  'VedaFont[Eurostile Next]+eurostile_next_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Eurostile_Next_eurostile_next_i7_italic_700.woff2?v=1684753195',
  'VedaFont[FF Meta Serif]+ff_meta_serif_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_Serif_ff_meta_serif_i5_italic_500.woff2?v=1684753196',
  'VedaFont[Eczar]+eczar_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Eczar_eczar_n7_normal_700.woff2?v=1684753195',
  'VedaFont[FF Meta Serif]+ff_meta_serif_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_Serif_ff_meta_serif_i8_italic_800.woff2?v=1684753196',
  'VedaFont[FF Meta Serif]+ff_meta_serif_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_Serif_ff_meta_serif_n7_normal_700.woff2?v=1684753196',
  'VedaFont[Fette Gotisch]+fette_gotisch_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Fette_Gotisch_fette_gotisch_n4_normal_400.woff2?v=1684753195',
  'VedaFont[FF Meta Serif]+ff_meta_serif_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_Serif_ff_meta_serif_i3_italic_300.woff2?v=1684753196',
  'VedaFont[Eurostile Next]+eurostile_next_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Eurostile_Next_eurostile_next_n6_normal_600.woff2?v=1684753196',
  'VedaFont[Eurostile Next]+eurostile_next_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Eurostile_Next_eurostile_next_n7_normal_700.woff2?v=1684753196',
  'VedaFont[FF Meta Serif]+ff_meta_serif_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_Serif_ff_meta_serif_n3_normal_300.woff2?v=1684753195',
  'VedaFont[Cormorant]+cormorant_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cormorant_cormorant_n6_normal_600.woff2?v=1684753195',
  'VedaFont[DIN Next]+din_next_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_din_next_n4_normal_400.woff2?v=1684753195',
  'VedaFont[FF Meta Serif]+ff_meta_serif_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_Serif_ff_meta_serif_i9_italic_900.woff2?v=1684753195',
  'VedaFont[Eurostile Next]+eurostile_next_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Eurostile_Next_eurostile_next_n2_normal_200.woff2?v=1684753195',
  'VedaFont[FF Meta Serif]+ff_meta_serif_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_FF_Meta_Serif_ff_meta_serif_i4_italic_400.woff2?v=1684753195',
  'VedaFont[Eurostile Next]+eurostile_next_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Eurostile_Next_eurostile_next_i3_italic_300.woff2?v=1684753196',
  'VedaFont[Eczar]+eczar_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Eczar_eczar_n8_normal_800.woff2?v=1684753195',
  'VedaFont[Eurostile Next]+eurostile_next_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Eurostile_Next_eurostile_next_i4_italic_400.woff2?v=1684753195',
  'VedaFont[Eurostile Next]+eurostile_next_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Eurostile_Next_eurostile_next_n4_normal_400.woff2?v=1684753195',
  'VedaFont[Electra]+electra_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Electra_electra_n7_normal_700.woff2?v=1684753195',
  'VedaFont[Eurostile Next]+eurostile_next_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Eurostile_Next_eurostile_next_i2_italic_200.woff2?v=1684753196',
  'VedaFont[Eurostile Next]+eurostile_next_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Eurostile_Next_eurostile_next_i6_italic_600.woff2?v=1684753195',
  'VedaFont[Eczar]+eczar_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Eczar_eczar_n4_normal_400.woff2?v=1684753195',
  'VedaFont[Dosis]+dosis_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Dosis_dosis_n7_normal_700.woff2?v=1684753195',
  'VedaFont[DIN Next Slab]+din_next_slab_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_Slab_din_next_slab_i9_italic_900.woff2?v=1684753195',
  'VedaFont[Eczar]+eczar_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Eczar_eczar_n5_normal_500.woff2?v=1684753195',
  'VedaFont[Dosis]+dosis_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Dosis_dosis_n3_normal_300.woff2?v=1684753195',
  'VedaFont[DM Sans]+dm_sans_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DM_Sans_dm_sans_n5_normal_500.woff2?v=1684753195',
  'VedaFont[Eczar]+eczar_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Eczar_eczar_n6_normal_600.woff2?v=1684753195',
  'VedaFont[Electra]+electra_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Electra_electra_i4_italic_400.woff2?v=1684753195',
  'VedaFont[Electra]+electra_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Electra_electra_i7_italic_700.woff2?v=1684753195',
  'VedaFont[DM Sans]+dm_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DM_Sans_dm_sans_n7_normal_700.woff2?v=1684753195',
  'VedaFont[Dosis]+dosis_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Dosis_dosis_n2_normal_200.woff2?v=1684753195',
  'VedaFont[Dosis]+dosis_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Dosis_dosis_n8_normal_800.woff2?v=1684753196',
  'VedaFont[Dosis]+dosis_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Dosis_dosis_n4_normal_400.woff2?v=1684753195',
  'VedaFont[DIN Next Slab]+din_next_slab_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_Slab_din_next_slab_i2_italic_200.woff2?v=1684753195',
  'VedaFont[Chivo]+chivo_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Chivo_chivo_n4_normal_400.woff2?v=1684753195',
  'VedaFont[DIN Next Slab]+din_next_slab_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_Slab_din_next_slab_i3_italic_300.woff2?v=1684753195',
  'VedaFont[Dosis]+dosis_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Dosis_dosis_n5_normal_500.woff2?v=1684753195',
  'VedaFont[DM Sans]+dm_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DM_Sans_dm_sans_i7_italic_700.woff2?v=1684753195',
  'VedaFont[Daytona]+daytona_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Daytona_daytona_n7_normal_700.woff2?v=1684753195',
  'VedaFont[Daytona]+daytona_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Daytona_daytona_i2_italic_200.woff2?v=1684753195',
  'VedaFont[Domine]+domine_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Domine_domine_n7_normal_700.woff2?v=1684753195',
  'VedaFont[Daytona]+daytona_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Daytona_daytona_i4_italic_400.woff2?v=1684753195',
  'VedaFont[DIN Neuzeit Grotesk]+din_neuzeit_grotesk_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Neuzeit_Grotesk_din_neuzeit_grotesk_n3_normal_300.woff2?v=1684753195',
  'VedaFont[Cooper BT]+cooper_bt_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cooper_BT_cooper_bt_i9_italic_900.woff2?v=1684753195',
  'VedaFont[Crimson Text]+crimson_text_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Crimson_Text_crimson_text_n4_normal_400.woff2?v=1684753195',
  'VedaFont[DIN Next Slab]+din_next_slab_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_Slab_din_next_slab_i7_italic_700.woff2?v=1684753195',
  'VedaFont[DIN Next]+din_next_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_din_next_n5_normal_500.woff2?v=1684753195',
  'VedaFont[Cormorant]+cormorant_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cormorant_cormorant_n5_normal_500.woff2?v=1684753195',
  'VedaFont[DM Sans]+dm_sans_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DM_Sans_dm_sans_i5_italic_500.woff2?v=1684753195',
  'VedaFont[DM Sans]+dm_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DM_Sans_dm_sans_n4_normal_400.woff2?v=1684753195',
  'VedaFont[DIN Next]+din_next_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_din_next_n9_normal_900.woff2?v=1684753195',
  'VedaFont[Cormorant]+cormorant_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cormorant_cormorant_i4_italic_400.woff2?v=1684753195',
  'VedaFont[DIN Next]+din_next_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_din_next_i8_italic_800.woff2?v=1684753195',
  'VedaFont[Cormorant]+cormorant_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cormorant_cormorant_i6_italic_600.woff2?v=1684753195',
  'VedaFont[Cooper BT]+cooper_bt_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cooper_BT_cooper_bt_n9_normal_900.woff2?v=1684753195',
  'VedaFont[DIN Next]+din_next_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_din_next_n8_normal_800.woff2?v=1684753195',
  'VedaFont[DIN Next Slab]+din_next_slab_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_Slab_din_next_slab_i5_italic_500.woff2?v=1684753195',
  'VedaFont[DIN Next]+din_next_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_din_next_n3_normal_300.woff2?v=1684753195',
  'VedaFont[Chivo]+chivo_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Chivo_chivo_i7_italic_700.woff2?v=1684753195',
  'VedaFont[DIN Next]+din_next_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_din_next_i9_italic_900.woff2?v=1684753195',
  'VedaFont[DIN Next]+din_next_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_din_next_i3_italic_300.woff2?v=1684753195',
  'VedaFont[Domine]+domine_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Domine_domine_n4_normal_400.woff2?v=1684753195',
  'VedaFont[DIN Next Slab]+din_next_slab_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_Slab_din_next_slab_n8_normal_800.woff2?v=1684753195',
  'VedaFont[DIN Next Slab]+din_next_slab_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_Slab_din_next_slab_n9_normal_900.woff2?v=1684753195',
  'VedaFont[Chong Modern]+chong_modern_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Chong_Modern_chong_modern_n4_normal_400.woff2?v=1684753195',
  'VedaFont[DIN Next]+din_next_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_din_next_i4_italic_400.woff2?v=1684753195',
  'VedaFont[DM Sans]+dm_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DM_Sans_dm_sans_i4_italic_400.woff2?v=1684753195',
  'VedaFont[DIN Next]+din_next_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_din_next_i5_italic_500.woff2?v=1684753195',
  'VedaFont[DIN Next]+din_next_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_din_next_n2_normal_200.woff2?v=1684753195',
  'VedaFont[DIN Next Slab]+din_next_slab_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_Slab_din_next_slab_n7_normal_700.woff2?v=1684753195',
  'VedaFont[DIN Next Slab]+din_next_slab_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_Slab_din_next_slab_n4_normal_400.woff2?v=1684753195',
  'VedaFont[Centaur]+centaur_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Centaur_centaur_n7_normal_700.woff2?v=1684753195',
  'VedaFont[Chivo]+chivo_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Chivo_chivo_i3_italic_300.woff2?v=1684753195',
  'VedaFont[Daytona]+daytona_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Daytona_daytona_n4_normal_400.woff2?v=1684753195',
  'VedaFont[Cooper BT]+cooper_bt_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cooper_BT_cooper_bt_n7_normal_700.woff2?v=1684753195',
  'VedaFont[DIN Next Slab]+din_next_slab_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_Slab_din_next_slab_n5_normal_500.woff2?v=1684753195',
  'VedaFont[DIN Next]+din_next_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_din_next_n7_normal_700.woff2?v=1684753195',
  'VedaFont[Century Gothic]+century_gothic_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Century_Gothic_century_gothic_i7_italic_700.woff2?v=1684753195',
  'VedaFont[DIN Next Slab]+din_next_slab_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_Slab_din_next_slab_n3_normal_300.woff2?v=1684753195',
  'VedaFont[Daytona]+daytona_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Daytona_daytona_n6_normal_600.woff2?v=1684753195',
  'VedaFont[Daytona]+daytona_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Daytona_daytona_n3_normal_300.woff2?v=1684753195',
  'VedaFont[Cormorant]+cormorant_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cormorant_cormorant_i7_italic_700.woff2?v=1684753195',
  'VedaFont[Cormorant]+cormorant_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cormorant_cormorant_n7_normal_700.woff2?v=1684753195',
  'VedaFont[Cooper BT]+cooper_bt_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cooper_BT_cooper_bt_i5_italic_500.woff2?v=1684753195',
  'VedaFont[Crimson Text]+crimson_text_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Crimson_Text_crimson_text_n7_normal_700.woff2?v=1684753195',
  'VedaFont[Centaur]+centaur_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Centaur_centaur_i7_italic_700.woff2?v=1684753195',
  'VedaFont[Crimson Text]+crimson_text_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Crimson_Text_crimson_text_i6_italic_600.woff2?v=1684753195',
  'VedaFont[DIN Neuzeit Grotesk]+din_neuzeit_grotesk_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Neuzeit_Grotesk_din_neuzeit_grotesk_n7_normal_700.woff2?v=1684753195',
  'VedaFont[DIN Next]+din_next_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_din_next_i2_italic_200.woff2?v=1684753195',
  'VedaFont[Daytona]+daytona_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Daytona_daytona_n2_normal_200.woff2?v=1684753195',
  'VedaFont[Daytona]+daytona_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Daytona_daytona_i6_italic_600.woff2?v=1684753195',
  'VedaFont[Century Gothic]+century_gothic_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Century_Gothic_century_gothic_i4_italic_400.woff2?v=1684753195',
  'VedaFont[Crimson Text]+crimson_text_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Crimson_Text_crimson_text_n6_normal_600.woff2?v=1684753195',
  'VedaFont[DIN Next Slab]+din_next_slab_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_Slab_din_next_slab_i4_italic_400.woff2?v=1684753195',
  'VedaFont[DIN Next Slab]+din_next_slab_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_Slab_din_next_slab_n2_normal_200.woff2?v=1684753195',
  'VedaFont[DIN Next Slab]+din_next_slab_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_DIN_Next_Slab_din_next_slab_i8_italic_800.woff2?v=1684753195',
  'VedaFont[Daytona]+daytona_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Daytona_daytona_i3_italic_300.woff2?v=1684753195',
  'VedaFont[Daytona]+daytona_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Daytona_daytona_i7_italic_700.woff2?v=1684753195',
  'VedaFont[Cooper BT]+cooper_bt_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cooper_BT_cooper_bt_i7_italic_700.woff2?v=1684753195',
  'VedaFont[Catamaran]+catamaran_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Catamaran_catamaran_n6_normal_600.woff2?v=1684753195',
  'VedaFont[Cormorant]+cormorant_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cormorant_cormorant_n4_normal_400.woff2?v=1684753195',
  'VedaFont[Crimson Text]+crimson_text_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Crimson_Text_crimson_text_i4_italic_400.woff2?v=1684753195',
  'VedaFont[Crimson Text]+crimson_text_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Crimson_Text_crimson_text_i7_italic_700.woff2?v=1684753195',
  'VedaFont[Cormorant]+cormorant_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cormorant_cormorant_i5_italic_500.woff2?v=1684753195',
  'VedaFont[Cooper BT]+cooper_bt_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cooper_BT_cooper_bt_i2_italic_200.woff2?v=1684753195',
  'VedaFont[Chong Modern]+chong_modern_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Chong_Modern_chong_modern_n7_normal_700.woff2?v=1684753195',
  'VedaFont[Cormorant]+cormorant_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cormorant_cormorant_n3_normal_300.woff2?v=1684753195',
  'VedaFont[Cooper BT]+cooper_bt_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cooper_BT_cooper_bt_n5_normal_500.woff2?v=1684753195',
  'VedaFont[Cormorant]+cormorant_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cormorant_cormorant_i3_italic_300.woff2?v=1684753195',
  'VedaFont[Catamaran]+catamaran_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Catamaran_catamaran_n9_normal_900.woff2?v=1684753195',
  'VedaFont[Cooper BT]+cooper_bt_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cooper_BT_cooper_bt_n2_normal_200.woff2?v=1684753195',
  'VedaFont[Claire News]+claire_news_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Claire_News_claire_news_n7_normal_700.woff2?v=1684753195',
  'VedaFont[Claire News]+claire_news_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Claire_News_claire_news_n3_normal_300.woff2?v=1684753195',
  'VedaFont[Catamaran]+catamaran_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Catamaran_catamaran_n5_normal_500.woff2?v=1684753195',
  'VedaFont[Chong Modern]+chong_modern_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Chong_Modern_chong_modern_n3_normal_300.woff2?v=1684753195',
  'VedaFont[Chong Modern]+chong_modern_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Chong_Modern_chong_modern_i4_italic_400.woff2?v=1684753195',
  'VedaFont[Chivo]+chivo_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Chivo_chivo_n7_normal_700.woff2?v=1684753195',
  'VedaFont[Chivo]+chivo_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Chivo_chivo_i9_italic_900.woff2?v=1684753195',
  'VedaFont[Centaur]+centaur_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Centaur_centaur_n4_normal_400.woff2?v=1684753195',
  'VedaFont[Chong Modern]+chong_modern_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Chong_Modern_chong_modern_i3_italic_300.woff2?v=1684753195',
  'VedaFont[Centaur]+centaur_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Centaur_centaur_i4_italic_400.woff2?v=1684753195',
  'VedaFont[Chivo]+chivo_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Chivo_chivo_i4_italic_400.woff2?v=1684753195',
  'VedaFont[Chivo]+chivo_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Chivo_chivo_n3_normal_300.woff2?v=1684753195',
  'VedaFont[Chivo]+chivo_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Chivo_chivo_n9_normal_900.woff2?v=1684753195',
  'VedaFont[Century Gothic]+century_gothic_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Century_Gothic_century_gothic_n7_normal_700.woff2?v=1684753195',
  'VedaFont[Century Gothic]+century_gothic_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Century_Gothic_century_gothic_n4_normal_400.woff2?v=1684753195',
  'VedaFont[Catamaran]+catamaran_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Catamaran_catamaran_n8_normal_800.woff2?v=1684753195',
  'VedaFont[Catamaran]+catamaran_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Catamaran_catamaran_n7_normal_700.woff2?v=1684753195',
  'VedaFont[Chong Modern]+chong_modern_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Chong_Modern_chong_modern_i7_italic_700.woff2?v=1684753195',
  'VedaFont[Caslon Old Face]+caslon_old_face_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Caslon_Old_Face_caslon_old_face_n7_normal_700.woff2?v=1684753085',
  'VedaFont[Caslon Bold]+caslon_bold_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Caslon_Bold_caslon_bold_i4_italic_400.woff2?v=1684753085',
  'VedaFont[Cardo]+cardo_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cardo_cardo_i4_italic_400.woff2?v=1684753085',
  'VedaFont[Carter Sans]+carter_sans_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Carter_Sans_carter_sans_i5_italic_500.woff2?v=1684753085',
  'VedaFont[Catamaran]+catamaran_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Catamaran_catamaran_n3_normal_300.woff2?v=1684753085',
  'VedaFont[Catamaran]+catamaran_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Catamaran_catamaran_n2_normal_200.woff2?v=1684753085',
  'VedaFont[Cardamon]+cardamon_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cardamon_cardamon_n6_normal_600.woff2?v=1684753085',
  'VedaFont[Carter Sans]+carter_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Carter_Sans_carter_sans_n7_normal_700.woff2?v=1684753085',
  'VedaFont[Carter Sans]+carter_sans_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Carter_Sans_carter_sans_n6_normal_600.woff2?v=1684753085',
  'VedaFont[Carter Sans]+carter_sans_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Carter_Sans_carter_sans_n5_normal_500.woff2?v=1684753085',
  'VedaFont[Cardo]+cardo_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cardo_cardo_n7_normal_700.woff2?v=1684753085',
  'VedaFont[Caslon Old Face]+caslon_old_face_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Caslon_Old_Face_caslon_old_face_i4_italic_400.woff2?v=1684753085',
  'VedaFont[Catamaran]+catamaran_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Catamaran_catamaran_n1_normal_100.woff2?v=1684753085',
  'VedaFont[Carter Sans]+carter_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Carter_Sans_carter_sans_i7_italic_700.woff2?v=1684753085',
  'VedaFont[Caslon Bold]+caslon_bold_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Caslon_Bold_caslon_bold_n4_normal_400.woff2?v=1684753085',
  'VedaFont[Catamaran]+catamaran_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Catamaran_catamaran_n4_normal_400.woff2?v=1684753085',
  'VedaFont[Carter Sans]+carter_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Carter_Sans_carter_sans_n4_normal_400.woff2?v=1684753085',
  'VedaFont[Carter Sans]+carter_sans_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Carter_Sans_carter_sans_i6_italic_600.woff2?v=1684753085',
  'VedaFont[Carter Sans]+carter_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Carter_Sans_carter_sans_i4_italic_400.woff2?v=1684753085',
  'VedaFont[Caslon Old Face]+caslon_old_face_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Caslon_Old_Face_caslon_old_face_n4_normal_400.woff2?v=1684753085',
  'VedaFont[Cardamon]+cardamon_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cardamon_cardamon_n7_normal_700.woff2?v=1684753085',
  'VedaFont[Cardamon]+cardamon_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cardamon_cardamon_n5_normal_500.woff2?v=1684753085',
  'VedaFont[Cachet]+cachet_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cachet_cachet_n7_normal_700.woff2?v=1684753085',
  'VedaFont[Cardo]+cardo_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cardo_cardo_n4_normal_400.woff2?v=1684753085',
  'VedaFont[Cardamon]+cardamon_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cardamon_cardamon_n4_normal_400.woff2?v=1684753085',
  'VedaFont[Cardamon]+cardamon_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cardamon_cardamon_i7_italic_700.woff2?v=1684753085',
  'VedaFont[Cardamon]+cardamon_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cardamon_cardamon_i6_italic_600.woff2?v=1684753085',
  'VedaFont[Cardamon]+cardamon_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cardamon_cardamon_i5_italic_500.woff2?v=1684753085',
  'VedaFont[Cachet]+cachet_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cachet_cachet_n6_normal_600.woff2?v=1684753085',
  'VedaFont[Cabin]+cabin_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cabin_cabin_i7_italic_700.woff2?v=1684753085',
  'VedaFont[Cardamon]+cardamon_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cardamon_cardamon_i4_italic_400.woff2?v=1684753085',
  'VedaFont[Cachet]+cachet_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cachet_cachet_n8_normal_800.woff2?v=1684753085',
  'VedaFont[Cachet]+cachet_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cachet_cachet_n5_normal_500.woff2?v=1684753085',
  'VedaFont[Cachet]+cachet_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cachet_cachet_n3_normal_300.woff2?v=1684753085',
  'VedaFont[Cachet]+cachet_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cachet_cachet_i7_italic_700.woff2?v=1684753085',
  'VedaFont[Cachet]+cachet_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cachet_cachet_n2_normal_200.woff2?v=1684753085',
  'VedaFont[Cabin]+cabin_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cabin_cabin_i6_italic_600.woff2?v=1684753085',
  'VedaFont[Cachet]+cachet_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cachet_cachet_i5_italic_500.woff2?v=1684753085',
  'VedaFont[Cachet]+cachet_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cachet_cachet_n4_normal_400.woff2?v=1684753085',
  'VedaFont[Burlingame]+burlingame_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Burlingame_burlingame_n7_normal_700.woff2?v=1684753085',
  'VedaFont[Burlingame]+burlingame_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Burlingame_burlingame_n3_normal_300.woff2?v=1684753085',
  'VedaFont[Burlingame]+burlingame_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Burlingame_burlingame_n6_normal_600.woff2?v=1684753085',
  'VedaFont[Cachet]+cachet_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cachet_cachet_i8_italic_800.woff2?v=1684753085',
  'VedaFont[Cachet]+cachet_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cachet_cachet_i1_italic_100.woff2?v=1684753085',
  'VedaFont[Cachet]+cachet_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cachet_cachet_i6_italic_600.woff2?v=1684753085',
  'VedaFont[Cachet]+cachet_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cachet_cachet_i3_italic_300.woff2?v=1684753085',
  'VedaFont[Burlingame]+burlingame_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Burlingame_burlingame_i9_italic_900.woff2?v=1684753085',
  'VedaFont[Cachet]+cachet_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cachet_cachet_i4_italic_400.woff2?v=1684753085',
  'VedaFont[Burlingame]+burlingame_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Burlingame_burlingame_n1_normal_100.woff2?v=1684753085',
  'VedaFont[Burlingame]+burlingame_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Burlingame_burlingame_n4_normal_400.woff2?v=1684753085',
  'VedaFont[Cabin]+cabin_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cabin_cabin_n7_normal_700.woff2?v=1684753085',
  'VedaFont[Cabin]+cabin_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cabin_cabin_n4_normal_400.woff2?v=1684753085',
  'VedaFont[Burlingame]+burlingame_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Burlingame_burlingame_n2_normal_200.woff2?v=1684753085',
  'VedaFont[Burlingame]+burlingame_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Burlingame_burlingame_n9_normal_900.woff2?v=1684753085',
  'VedaFont[Baskerville No]+baskerville_no_n7+normal+22700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Baskerville_No_2_baskerville_no_2_n7_normal_700.woff2?v=1684753085',
  'VedaFont[Bauer Bodoni]+bauer_bodoni_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bauer_Bodoni_bauer_bodoni_n9_normal_900.woff2?v=1684753085',
  'VedaFont[Cabin]+cabin_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cabin_cabin_n5_normal_500.woff2?v=1684753085',
  'VedaFont[Burlingame]+burlingame_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Burlingame_burlingame_n5_normal_500.woff2?v=1684753085',
  'VedaFont[Arimo]+arimo_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Arimo_arimo_i7_italic_700.woff2?v=1684753085',
  'VedaFont[Basic Commercial]+basic_commercial_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Basic_Commercial_basic_commercial_i7_italic_700.woff2?v=1684753085',
  'VedaFont[Burlingame]+burlingame_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Burlingame_burlingame_i6_italic_600.woff2?v=1684753085',
  'VedaFont[Cachet]+cachet_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cachet_cachet_n1_normal_100.woff2?v=1684753085',
  'VedaFont[Cabin]+cabin_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cabin_cabin_i4_italic_400.woff2?v=1684753085',
  'VedaFont[Basic Commercial]+basic_commercial_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Basic_Commercial_basic_commercial_i4_italic_400.woff2?v=1684753085',
  'VedaFont[Cachet]+cachet_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cachet_cachet_i2_italic_200.woff2?v=1684753085',
  'VedaFont[Cabin]+cabin_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cabin_cabin_i5_italic_500.woff2?v=1684753085',
  'VedaFont[Cabin]+cabin_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Cabin_cabin_n6_normal_600.woff2?v=1684753085',
  'VedaFont[Burlingame]+burlingame_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Burlingame_burlingame_i8_italic_800.woff2?v=1684753085',
  'VedaFont[Burlingame]+burlingame_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Burlingame_burlingame_n8_normal_800.woff2?v=1684753085',
  'VedaFont[Assistant]+assistant_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Assistant_assistant_n8_normal_800.woff2?v=1684753084',
  'VedaFont[Burlingame]+burlingame_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Burlingame_burlingame_i4_italic_400.woff2?v=1684753085',
  'VedaFont[Azbuka]+azbuka_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Azbuka_azbuka_i5_italic_500.woff2?v=1684753085',
  'VedaFont[Burlingame]+burlingame_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Burlingame_burlingame_i1_italic_100.woff2?v=1684753085',
  'VedaFont[Burlingame]+burlingame_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Burlingame_burlingame_i7_italic_700.woff2?v=1684753085',
  'VedaFont[Bodoni Poster]+bodoni_poster_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bodoni_Poster_bodoni_poster_n9_normal_900.woff2?v=1684753085',
  'VedaFont[Bio Rhyme]+bio_rhyme_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bio_Rhyme_bio_rhyme_n8_normal_800.woff2?v=1684753085',
  'VedaFont[Burlingame]+burlingame_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Burlingame_burlingame_i5_italic_500.woff2?v=1684753085',
  'VedaFont[Bodoni Poster]+bodoni_poster_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bodoni_Poster_bodoni_poster_i9_italic_900.woff2?v=1684753085',
  'VedaFont[Burlingame]+burlingame_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Burlingame_burlingame_i3_italic_300.woff2?v=1684753085',
  'VedaFont[Bauer Bodoni]+bauer_bodoni_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bauer_Bodoni_bauer_bodoni_i9_italic_900.woff2?v=1684753085',
  'VedaFont[Bitter]+bitter_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bitter_bitter_i4_italic_400.woff2?v=1684753085',
  'VedaFont[Bio Rhyme]+bio_rhyme_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bio_Rhyme_bio_rhyme_n7_normal_700.woff2?v=1684753084',
  'VedaFont[Bitter]+bitter_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bitter_bitter_n7_normal_700.woff2?v=1684753084',
  'VedaFont[Bio Rhyme]+bio_rhyme_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bio_Rhyme_bio_rhyme_n2_normal_200.woff2?v=1684753085',
  'VedaFont[Burlingame]+burlingame_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Burlingame_burlingame_i2_italic_200.woff2?v=1684753084',
  'VedaFont[Antique Olive]+antique_olive_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Antique_Olive_antique_olive_n7_normal_700.woff2?v=1684753084',
  'VedaFont[Bitter]+bitter_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bitter_bitter_n4_normal_400.woff2?v=1684753085',
  'VedaFont[Bio Rhyme]+bio_rhyme_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bio_Rhyme_bio_rhyme_n3_normal_300.woff2?v=1684753084',
  'VedaFont[Bio Rhyme]+bio_rhyme_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bio_Rhyme_bio_rhyme_n4_normal_400.woff2?v=1684753084',
  'VedaFont[Bernhard Modern]+bernhard_modern_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bernhard_Modern_bernhard_modern_i7_italic_700.woff2?v=1684753085',
  'VedaFont[Bernhard Modern]+bernhard_modern_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bernhard_Modern_bernhard_modern_n7_normal_700.woff2?v=1684753084',
  'VedaFont[Bembo Book]+bembo_book_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bembo_Book_bembo_book_n4_normal_400.woff2?v=1684753085',
  'VedaFont[Bembo Book]+bembo_book_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bembo_Book_bembo_book_n7_normal_700.woff2?v=1684753084',
  'VedaFont[Avenir Next]+avenir_next_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_avenir_next_i5_italic_500.woff2?v=1684753085',
  'VedaFont[Bembo Book]+bembo_book_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bembo_Book_bembo_book_i7_italic_700.woff2?v=1684753085',
  'VedaFont[Bauer Bodoni]+bauer_bodoni_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bauer_Bodoni_bauer_bodoni_n7_normal_700.woff2?v=1684753084',
  'VedaFont[Bernhard Modern]+bernhard_modern_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bernhard_Modern_bernhard_modern_i4_italic_400.woff2?v=1684753084',
  'VedaFont[Bernhard Modern]+bernhard_modern_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bernhard_Modern_bernhard_modern_n4_normal_400.woff2?v=1684753085',
  'VedaFont[Bauer Bodoni]+bauer_bodoni_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bauer_Bodoni_bauer_bodoni_i7_italic_700.woff2?v=1684753084',
  'VedaFont[Bauer Bodoni]+bauer_bodoni_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bauer_Bodoni_bauer_bodoni_i4_italic_400.woff2?v=1684753085',
  'VedaFont[Beefcakes]+beefcakes_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Beefcakes_beefcakes_n4_normal_400.woff2?v=1684753084',
  'VedaFont[Bembo Book]+bembo_book_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bembo_Book_bembo_book_i4_italic_400.woff2?v=1684753084',
  'VedaFont[Basic Commercial Soft Rounded]+basic_commercial_soft_rounded_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Basic_Commercial_Soft_Rounded_basic_commercial_soft_rounded_n9_normal_900.woff2?v=1684753085',
  'VedaFont[Avenir Next]+avenir_next_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_avenir_next_i1_italic_100.woff2?v=1684753085',
  'VedaFont[Beefcakes]+beefcakes_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Beefcakes_beefcakes_i4_italic_400.woff2?v=1684753085',
  'VedaFont[Bauer Bodoni]+bauer_bodoni_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Bauer_Bodoni_bauer_bodoni_n4_normal_400.woff2?v=1684753084',
  'VedaFont[Archivo]+archivo_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Archivo_archivo_i7_italic_700.woff2?v=1684753084',
  'VedaFont[Baskerville No]+baskerville_no_i4+italic+22400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Baskerville_No_2_baskerville_no_2_i4_italic_400.woff2?v=1684753085',
  'VedaFont[Basic Commercial]+basic_commercial_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Basic_Commercial_basic_commercial_i3_italic_300.woff2?v=1684753084',
  'VedaFont[Basic Commercial]+basic_commercial_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Basic_Commercial_basic_commercial_i8_italic_800.woff2?v=1684753084',
  'VedaFont[Avenir Next]+avenir_next_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_avenir_next_n8_normal_800.woff2?v=1684753085',
  'VedaFont[Azbuka]+azbuka_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Azbuka_azbuka_n5_normal_500.woff2?v=1684753085',
  'VedaFont[Baskerville No]+baskerville_no_i7+italic+22700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Baskerville_No_2_baskerville_no_2_i7_italic_700.woff2?v=1684753084',
  'VedaFont[Basic Commercial]+basic_commercial_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Basic_Commercial_basic_commercial_n3_normal_300.woff2?v=1684753084',
  'VedaFont[Azbuka]+azbuka_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Azbuka_azbuka_n3_normal_300.woff2?v=1684753084',
  'VedaFont[Azbuka]+azbuka_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Azbuka_azbuka_n4_normal_400.woff2?v=1684753084',
  'VedaFont[Basic Commercial]+basic_commercial_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Basic_Commercial_basic_commercial_n7_normal_700.woff2?v=1684753084',
  'VedaFont[Azbuka]+azbuka_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Azbuka_azbuka_n6_normal_600.woff2?v=1684753084',
  'VedaFont[Archivo Narrow]+archivo_narrow_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Archivo_Narrow_archivo_narrow_i5_italic_500.woff2?v=1684753085',
  'VedaFont[Azbuka]+azbuka_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Azbuka_azbuka_n2_normal_200.woff2?v=1684753085',
  'VedaFont[Basic Commercial Soft Rounded]+basic_commercial_soft_rounded_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Basic_Commercial_Soft_Rounded_basic_commercial_soft_rounded_n4_normal_400.woff2?v=1684753084',
  'VedaFont[Baskerville No]+baskerville_no_n4+normal+22400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Baskerville_No_2_baskerville_no_2_n4_normal_400.woff2?v=1684753085',
  'VedaFont[Azbuka]+azbuka_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Azbuka_azbuka_n8_normal_800.woff2?v=1684753084',
  'VedaFont[Azbuka]+azbuka_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Azbuka_azbuka_n7_normal_700.woff2?v=1684753084',
  'VedaFont[Avenir Next Rounded]+avenir_next_rounded_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_Rounded_avenir_next_rounded_i7_italic_700.woff2?v=1684753084',
  'VedaFont[Avenir Next]+avenir_next_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_avenir_next_n7_normal_700.woff2?v=1684753084',
  'VedaFont[Basic Commercial]+basic_commercial_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Basic_Commercial_basic_commercial_n8_normal_800.woff2?v=1684753085',
  'VedaFont[Basic Commercial]+basic_commercial_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Basic_Commercial_basic_commercial_n4_normal_400.woff2?v=1684753084',
  'VedaFont[Avenir Next Rounded]+avenir_next_rounded_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_Rounded_avenir_next_rounded_i4_italic_400.woff2?v=1684753085',
  'VedaFont[Archivo Narrow]+archivo_narrow_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Archivo_Narrow_archivo_narrow_i6_italic_600.woff2?v=1684753084',
  'VedaFont[Avenir Next]+avenir_next_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_avenir_next_n1_normal_100.woff2?v=1684753084',
  'VedaFont[Avenir Next]+avenir_next_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_avenir_next_n5_normal_500.woff2?v=1684753084',
  'VedaFont[Basic Commercial Soft Rounded]+basic_commercial_soft_rounded_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Basic_Commercial_Soft_Rounded_basic_commercial_soft_rounded_n7_normal_700.woff2?v=1684753084',
  'VedaFont[Azbuka]+azbuka_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Azbuka_azbuka_i3_italic_300.woff2?v=1684753084',
  'VedaFont[Archivo Narrow]+archivo_narrow_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Archivo_Narrow_archivo_narrow_n6_normal_600.woff2?v=1684753084',
  'VedaFont[Azbuka]+azbuka_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Azbuka_azbuka_i6_italic_600.woff2?v=1684753085',
  'VedaFont[Azbuka]+azbuka_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Azbuka_azbuka_i7_italic_700.woff2?v=1684753084',
  'VedaFont[Avenir Next]+avenir_next_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_avenir_next_n2_normal_200.woff2?v=1684753085',
  'VedaFont[Azbuka]+azbuka_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Azbuka_azbuka_n9_normal_900.woff2?v=1684753084',
  'VedaFont[Antique Olive]+antique_olive_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Antique_Olive_antique_olive_i4_italic_400.woff2?v=1684753084',
  'VedaFont[Avenir Next]+avenir_next_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_avenir_next_i8_italic_800.woff2?v=1684753084',
  'VedaFont[Archivo Narrow]+archivo_narrow_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Archivo_Narrow_archivo_narrow_i7_italic_700.woff2?v=1684753084',
  'VedaFont[Anonymous Pro]+anonymous_pro_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Anonymous_Pro_anonymous_pro_n7_normal_700.woff2?v=1684753084',
  'VedaFont[Azbuka]+azbuka_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Azbuka_azbuka_i4_italic_400.woff2?v=1684753084',
  'VedaFont[Avenir Next]+avenir_next_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_avenir_next_n6_normal_600.woff2?v=1684753084',
  'VedaFont[Anonymous Pro]+anonymous_pro_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Anonymous_Pro_anonymous_pro_n4_normal_400.woff2?v=1684753084',
  'VedaFont[Avenir Next]+avenir_next_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_avenir_next_i7_italic_700.woff2?v=1684753085',
  'VedaFont[Avenir Next]+avenir_next_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_avenir_next_i3_italic_300.woff2?v=1684753084',
  'VedaFont[Asul]+asul_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Asul_asul_n4_normal_400.woff2?v=1684753084',
  'VedaFont[Avenir Next]+avenir_next_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_avenir_next_i2_italic_200.woff2?v=1684753084',
  'VedaFont[Avenir Next]+avenir_next_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_avenir_next_i6_italic_600.woff2?v=1684753084',
  'VedaFont[Anonymous Pro]+anonymous_pro_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Anonymous_Pro_anonymous_pro_i4_italic_400.woff2?v=1684753084',
  'VedaFont[Avenir Next]+avenir_next_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_avenir_next_n3_normal_300.woff2?v=1684753084',
  'VedaFont[Avenir Next Rounded]+avenir_next_rounded_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_Rounded_avenir_next_rounded_i6_italic_600.woff2?v=1684753084',
  'VedaFont[Assistant]+assistant_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Assistant_assistant_n2_normal_200.woff2?v=1684753084',
  'VedaFont[Antique Olive]+antique_olive_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Antique_Olive_antique_olive_n3_normal_300.woff2?v=1684753084',
  'VedaFont[Avenir Next Rounded]+avenir_next_rounded_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_Rounded_avenir_next_rounded_n5_normal_500.woff2?v=1684753084',
  'VedaFont[Avenir Next]+avenir_next_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_avenir_next_i4_italic_400.woff2?v=1684753084',
  'VedaFont[Avenir Next]+avenir_next_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_avenir_next_n4_normal_400.woff2?v=1684753084',
  'VedaFont[Assistant]+assistant_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Assistant_assistant_n4_normal_400.woff2?v=1684753084',
  'VedaFont[Avenir Next Rounded]+avenir_next_rounded_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_Rounded_avenir_next_rounded_n6_normal_600.woff2?v=1684753084',
  'VedaFont[Antique Olive]+antique_olive_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Antique_Olive_antique_olive_n4_normal_400.woff2?v=1684753084',
  'VedaFont[Avenir Next Rounded]+avenir_next_rounded_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_Rounded_avenir_next_rounded_n7_normal_700.woff2?v=1684753084',
  'VedaFont[Asap]+asap_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Asap_asap_n7_normal_700.woff2?v=1684753084',
  'VedaFont[Asul]+asul_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Asul_asul_n7_normal_700.woff2?v=1684753084',
  'VedaFont[Avenir Next Rounded]+avenir_next_rounded_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_Rounded_avenir_next_rounded_n4_normal_400.woff2?v=1684753084',
  'VedaFont[Asap]+asap_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Asap_asap_i5_italic_500.woff2?v=1684753084',
  'VedaFont[Avenir Next Rounded]+avenir_next_rounded_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Avenir_Next_Rounded_avenir_next_rounded_i5_italic_500.woff2?v=1684753084',
  'VedaFont[Asap]+asap_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Asap_asap_i7_italic_700.woff2?v=1684753084',
  'VedaFont[Archivo]+archivo_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Archivo_archivo_n4_normal_400.woff2?v=1684753084',
  'VedaFont[Asap]+asap_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Asap_asap_i4_italic_400.woff2?v=1684753084',
  'VedaFont[Assistant]+assistant_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Assistant_assistant_n3_normal_300.woff2?v=1684753084',
  'VedaFont[Asap]+asap_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Asap_asap_n5_normal_500.woff2?v=1684753084',
  'VedaFont[Asap]+asap_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Asap_asap_i6_italic_600.woff2?v=1684753084',
  'VedaFont[Arimo]+arimo_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Arimo_arimo_i4_italic_400.woff2?v=1684753084',
  'VedaFont[Asap]+asap_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Asap_asap_n6_normal_600.woff2?v=1684753084',
  'VedaFont[Assistant]+assistant_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Assistant_assistant_n6_normal_600.woff2?v=1684753084',
  'VedaFont[Asap]+asap_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Asap_asap_n4_normal_400.woff2?v=1684753084',
  'VedaFont[Archivo]+archivo_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Archivo_archivo_n6_normal_600.woff2?v=1684753084',
  'VedaFont[Assistant]+assistant_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Assistant_assistant_n7_normal_700.woff2?v=1684753084',
  'VedaFont[Arvo]+arvo_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Arvo_arvo_n4_normal_400.woff2?v=1684753084',
  'VedaFont[Arvo]+arvo_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Arvo_arvo_n7_normal_700.woff2?v=1684753084',
  'VedaFont[Armata]+armata_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Armata_armata_n4_normal_400.woff2?v=1684753084',
  'VedaFont[Arvo]+arvo_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Arvo_arvo_i7_italic_700.woff2?v=1684753084',
  'VedaFont[Archivo]+archivo_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Archivo_archivo_i5_italic_500.woff2?v=1684753084',
  'VedaFont[Arvo]+arvo_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Arvo_arvo_i4_italic_400.woff2?v=1684753084',
  'VedaFont[Archivo]+archivo_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Archivo_archivo_n7_normal_700.woff2?v=1684753084',
  'VedaFont[Arimo]+arimo_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Arimo_arimo_n4_normal_400.woff2?v=1684753084',
  'VedaFont[Archivo]+archivo_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Archivo_archivo_i6_italic_600.woff2?v=1684753084',
  'VedaFont[Archivo]+archivo_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Archivo_archivo_i4_italic_400.woff2?v=1684753084',
  'VedaFont[Arimo]+arimo_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Arimo_arimo_n7_normal_700.woff2?v=1684753084',
  'VedaFont[Archivo]+archivo_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Archivo_archivo_n5_normal_500.woff2?v=1684753084',
  'VedaFont[Arapey]+arapey_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Arapey_arapey_i4_italic_400.woff2?v=1684753084',
  'VedaFont[Archivo Narrow]+archivo_narrow_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Archivo_Narrow_archivo_narrow_n7_normal_700.woff2?v=1684753084',
  'VedaFont[Antique Olive]+antique_olive_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Antique_Olive_antique_olive_n9_normal_900.woff2?v=1684753084',
  'VedaFont[Archivo Narrow]+archivo_narrow_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Archivo_Narrow_archivo_narrow_n4_normal_400.woff2?v=1684753084',
  'VedaFont[Archivo Narrow]+archivo_narrow_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Archivo_Narrow_archivo_narrow_i4_italic_400.woff2?v=1684753084',
  'VedaFont[Arapey]+arapey_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Arapey_arapey_n4_normal_400.woff2?v=1684753084',
  'VedaFont[Archivo Narrow]+archivo_narrow_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Archivo_Narrow_archivo_narrow_n5_normal_500.woff2?v=1684753084',
  'VedaFont[Anonymous Pro]+anonymous_pro_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Anonymous_Pro_anonymous_pro_i7_italic_700.woff2?v=1684753084',
  'VedaFont[Amiri]+amiri_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Amiri_amiri_n7_normal_700.woff2?v=1684753021',
  'VedaFont[Amiri]+amiri_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Amiri_amiri_i4_italic_400.woff2?v=1684753021',
  'VedaFont[Amiri]+amiri_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Amiri_amiri_i7_italic_700.woff2?v=1684753021',
  'VedaFont[Amiri]+amiri_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Amiri_amiri_n4_normal_400.woff2?v=1684753021',
  'VedaFont[Americana]+americana_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Americana_americana_i4_italic_400.woff2?v=1684752987',
  'VedaFont[Americana]+americana_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Americana_americana_n7_normal_700.woff2?v=1684752987',
  'VedaFont[Americana]+americana_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Americana_americana_n4_normal_400.woff2?v=1684752987',
  'VedaFont[Americana]+americana_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Americana_americana_n8_normal_800.woff2?v=1684752987',
  'VedaFont[Alfie]+alfie_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alfie_alfie_n4_normal_400.woff2?v=1684752965',
  'VedaFont[Alegreya]+alegreya_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_alegreya_n8_normal_800.woff2?v=1684752950',
  'VedaFont[Alegreya]+alegreya_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_alegreya_n9_normal_900.woff2?v=1684752950',
  'VedaFont[Alegreya]+alegreya_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_alegreya_i4_italic_400.woff2?v=1684752950',
  'VedaFont[Alegreya]+alegreya_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_alegreya_n4_normal_400.woff2?v=1684752950',
  'VedaFont[Alegreya]+alegreya_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_alegreya_i5_italic_500.woff2?v=1684752950',
  'VedaFont[Alegreya]+alegreya_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_alegreya_i9_italic_900.woff2?v=1684752950',
  'VedaFont[Alegreya]+alegreya_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_alegreya_n7_normal_700.woff2?v=1684752950',
  'VedaFont[Alegreya]+alegreya_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_alegreya_n5_normal_500.woff2?v=1684752950',
  'VedaFont[Alegreya]+alegreya_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_alegreya_i8_italic_800.woff2?v=1684752950',
  'VedaFont[Alegreya]+alegreya_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_alegreya_i7_italic_700.woff2?v=1684752950',
  'VedaFont[Alegreya Sans]+alegreya_sans_i1+italic+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_Sans_alegreya_sans_i1_italic_100.woff2?v=1684752931',
  'VedaFont[Alegreya Sans]+alegreya_sans_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_Sans_alegreya_sans_i3_italic_300.woff2?v=1684752931',
  'VedaFont[Alegreya Sans]+alegreya_sans_n8+normal+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_Sans_alegreya_sans_n8_normal_800.woff2?v=1684752931',
  'VedaFont[Alegreya Sans]+alegreya_sans_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_Sans_alegreya_sans_i5_italic_500.woff2?v=1684752931',
  'VedaFont[Alegreya Sans]+alegreya_sans_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_Sans_alegreya_sans_i7_italic_700.woff2?v=1684752931',
  'VedaFont[Alegreya Sans]+alegreya_sans_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_Sans_alegreya_sans_i9_italic_900.woff2?v=1684752931',
  'VedaFont[Alegreya Sans]+alegreya_sans_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_Sans_alegreya_sans_n7_normal_700.woff2?v=1684752931',
  'VedaFont[Alegreya Sans]+alegreya_sans_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_Sans_alegreya_sans_n5_normal_500.woff2?v=1684752931',
  'VedaFont[Alegreya Sans]+alegreya_sans_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_Sans_alegreya_sans_n3_normal_300.woff2?v=1684752931',
  'VedaFont[Alegreya Sans]+alegreya_sans_n1+normal+100.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_Sans_alegreya_sans_n1_normal_100.woff2?v=1684752931',
  'VedaFont[Alegreya Sans]+alegreya_sans_i8+italic+800.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_Sans_alegreya_sans_i8_italic_800.woff2?v=1684752931',
  'VedaFont[Alegreya Sans]+alegreya_sans_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_Sans_alegreya_sans_n9_normal_900.woff2?v=1684752931',
  'VedaFont[Alegreya Sans]+alegreya_sans_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_Sans_alegreya_sans_i4_italic_400.woff2?v=1684752931',
  'VedaFont[Alegreya Sans]+alegreya_sans_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Alegreya_Sans_alegreya_sans_n4_normal_400.woff2?v=1684752931',
  'VedaFont[Akko]+akko_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Akko_akko_i7_italic_700.woff2?v=1684752893',
  'VedaFont[Akko]+akko_i9+italic+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Akko_akko_i9_italic_900.woff2?v=1684752893',
  'VedaFont[Akko]+akko_i2+italic+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Akko_akko_i2_italic_200.woff2?v=1684752894',
  'VedaFont[Akko]+akko_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Akko_akko_i4_italic_400.woff2?v=1684752894',
  'VedaFont[Akko]+akko_n5+normal+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Akko_akko_n5_normal_500.woff2?v=1684752894',
  'VedaFont[Akko]+akko_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Akko_akko_n7_normal_700.woff2?v=1684752894',
  'VedaFont[Akko]+akko_n9+normal+900.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Akko_akko_n9_normal_900.woff2?v=1684752893',
  'VedaFont[Akko]+akko_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Akko_akko_i3_italic_300.woff2?v=1684752893',
  'VedaFont[Akko]+akko_i5+italic+500.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Akko_akko_i5_italic_500.woff2?v=1684752893',
  'VedaFont[Akko]+akko_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Akko_akko_n4_normal_400.woff2?v=1684752893',
  'VedaFont[Akko]+akko_n2+normal+200.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Akko_akko_n2_normal_200.woff2?v=1684752893',
  'VedaFont[Akko]+akko_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Akko_akko_n3_normal_300.woff2?v=1684752893',
  'VedaFont[Agmena]+agmena_n7+normal+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Agmena_agmena_n7_normal_700.woff2?v=1684752871',
  'VedaFont[Agmena]+agmena_i7+italic+700.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Agmena_agmena_i7_italic_700.woff2?v=1684752870',
  'VedaFont[Agmena]+agmena_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Agmena_agmena_n4_normal_400.woff2?v=1684752870',
  'VedaFont[Agmena]+agmena_n6+normal+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Agmena_agmena_n6_normal_600.woff2?v=1684752871',
  'VedaFont[Agmena]+agmena_i3+italic+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Agmena_agmena_i3_italic_300.woff2?v=1684752870',
  'VedaFont[Agmena]+agmena_n3+normal+300.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Agmena_agmena_n3_normal_300.woff2?v=1684752870',
  'VedaFont[Agmena]+agmena_i4+italic+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Agmena_agmena_i4_italic_400.woff2?v=1684752870',
  'VedaFont[Agmena]+agmena_i6+italic+600.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Agmena_agmena_i6_italic_600.woff2?v=1684752870',
  'VedaFont[Abril Fatface]+abril_fatface_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Abril_Fatface_abril_fatface_n4_normal_400.woff2?v=1684752854',
  'VedaFont[Abel]+abel_n4+normal+400.woff2':
    'https://cdn.shopify.com/s/files/1/0732/6416/9268/files/VedaFont_Abel_abel_n4_normal_400.woff2?v=1684752826',
};
