import { themeApis } from 'services/ThemeService/apis';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const validateClientThemeRequest = async () => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    return themeApis.vedaApplication.adminApi.theme.validateClientTheme();
  } else {
    return themeApis.vedaApplication.userApi.theme.validateClientTheme();
  }
};
