import { createAction, createAsyncAction, createDispatchAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';

export const importDefaultLanguage = createAsyncAction([
  '@Global/importDefaultLanguage/request',
  '@Global/importDefaultLanguage/success',
  '@Global/importDefaultLanguage/failure',
])<
  { fileName: string; defaultFileContent: string; isEdit: boolean; onFulfill?: (fileContent: string) => void; onFailed?: () => void },
  { fileName: string; fileContent: string },
  { fileName: string; defaultFileContent: string }
>();

export const deleteLangFlow = createAction('@Global/deleteLangFlow', (fileName: string) => fileName);

export const useImportDefaultLanguage = createDispatchAsyncAction(importDefaultLanguage);

export const useDeleteLangFlow = createDispatchAction(deleteLangFlow);
