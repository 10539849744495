import { getPartners } from 'containers/Admin/PartnerManagement/store/actions';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { getPartnersAPI } from 'services/UserService/Logic/getPartners';
import { getActionType } from 'wiloke-react-core/utils';

function* handleAction() {
  try {
    const response: SagaReturnType<typeof getPartnersAPI> = yield retry(3, 1000, getPartnersAPI);
    yield put(getPartners.success({ data: response?.info ?? [] }));
  } catch (error) {
    console.log(error);
    yield put(getPartners.failure(undefined));
  }
}

export function* watchGetPartnerApps() {
  yield takeLatest(getActionType(getPartners.request), handleAction);
}
