import { Button, MyModal } from '@wiloke/ui';
import ChooseImage from 'components/ChooseImage';
import Field from 'components/Field';
import { NumberInput } from 'components/NumberInput';
import SelectAntd from 'components/SelectAntd';
import { InputTags } from 'components/SelectTags';
import { TextEditor2 } from 'components/TextEditor2';
import TextInput from 'components/TextInput';
import { useCreateUserAddonsCategory, usePublishAdminAddonsToProduct } from 'containers/ChooseTemplate/store/actions';
import { useSetModalPublishAddons } from 'containers/ChooseTemplate/store/reducers/addons/admin.sliceAddons';
import { transformUserAddonCategories } from 'containers/ChooseTemplate/store/reducers/addons/user.sliceAddonCategory';
import withDebounce from 'hocs/withDebounce';
import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { addonSelector } from 'store/selectors';
import { i18n } from 'translation';
import { ProductAddon } from 'types/Addons';
import { PreviewImage } from 'types/Page';
import { PLAN_HANDLE } from 'utils/constants/PlanEnum';
import { Divider, View } from 'wiloke-react-core';

const DebounceInput = withDebounce(TextInput, 'value', 'onValueChange');
const TextEditorDebounce = withDebounce(TextEditor2, 'value', 'onChange');

const plans: Array<{ label: string; value: PLAN_HANDLE }> = [
  {
    value: 'free',
    label: 'Free',
  },
  {
    value: 'business',
    label: 'Business',
  },
  {
    value: 'advanced',
    label: 'Advanced',
  },
  {
    value: 'ultimate',
    label: 'Ultimate',
  },
];

interface Actions {
  type: 'setInfoPublishAddon';
  payload: Partial<State>;
}

interface State {
  category: { name: string; description: string };
  createdCategory: { name: string; description: string };
  authorName: string;
  image: PreviewImage | undefined;
  logo: string | undefined;
  detail: string | undefined;
  plan: PLAN_HANDLE | undefined;
  addonName: string;
  priorityOrder?: number;
  searchTerms?: string[];
}

const defaultState: State = {
  plan: undefined,
  category: { name: '', description: '' },
  createdCategory: { name: '', description: '' },
  image: undefined,
  priorityOrder: 0,
  searchTerms: [],
  addonName: '',
  authorName: '',
  detail: '',
  logo: '',
};

const publishReducer = (state: State, action: Actions) => {
  switch (action.type) {
    case 'setInfoPublishAddon': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export const ModalPublishAddon = () => {
  const { visible, addons, addonId, approveStatus } = useSelector(addonSelector.adminAddons);
  const { addStatus, addonsNav: userCategories } = useSelector(addonSelector.userAddonsCategory);
  const categories = useSelector(transformUserAddonCategories);
  const addon = addons.find(item => item.commandId === addonId);

  const setModalVisible = useSetModalPublishAddons();
  const createCategoryProduct = useCreateUserAddonsCategory();
  const publishAddon = usePublishAdminAddonsToProduct();

  const [
    { addonName, authorName, category, createdCategory, detail, image, logo, plan, priorityOrder, searchTerms },
    dispatch,
  ] = useReducer(publishReducer, { ...defaultState });

  useEffect(() => {
    if (addon && addon.vedaProductRepresent) {
      if (addon.vedaProductRepresent.searchTerms) {
        dispatch({
          type: 'setInfoPublishAddon',
          payload: {
            searchTerms: addon.vedaProductRepresent.searchTerms,
          },
        });
      }
      if (addon.vedaProductRepresent.priorityOrder) {
        dispatch({
          type: 'setInfoPublishAddon',
          payload: {
            priorityOrder: addon.vedaProductRepresent.priorityOrder,
          },
        });
      }
      if (addon.vedaProductRepresent.authorName) {
        dispatch({
          type: 'setInfoPublishAddon',
          payload: {
            authorName: addon.vedaProductRepresent.authorName,
          },
        });
      }

      if (addon.vedaProductRepresent.image) {
        dispatch({
          type: 'setInfoPublishAddon',
          payload: {
            image: addon.vedaProductRepresent.image,
          },
        });
      }

      if (addon.vedaProductRepresent.logo) {
        dispatch({
          type: 'setInfoPublishAddon',
          payload: {
            logo: addon.vedaProductRepresent.logo,
          },
        });
      }

      if (addon.vedaProductRepresent.planHandle) {
        dispatch({
          type: 'setInfoPublishAddon',
          payload: {
            plan: addon.vedaProductRepresent.planHandle,
          },
        });
      }

      if (addon.vedaProductRepresent.detail) {
        dispatch({
          type: 'setInfoPublishAddon',
          payload: {
            detail: addon.vedaProductRepresent.detail,
          },
        });
      }

      if (addon.vedaProductRepresent.label) {
        dispatch({
          type: 'setInfoPublishAddon',
          payload: {
            addonName: addon.vedaProductRepresent.label,
          },
        });
      }

      if (addon.vedaProductRepresent.category) {
        dispatch({
          type: 'setInfoPublishAddon',
          payload: {
            category: addon.vedaProductRepresent.category,
          },
        });
      }
    }
  }, [addon]);

  const handlePublish = () => {
    if (addon) {
      const _cate = userCategories.find(item => item.name === category.name);
      const { body } = addon;

      const productAddon: ProductAddon = {
        id: addon.id,
        commandId: '',
        parentCommandId: addon.commandId,
        category: {
          commandId: _cate?.commandId ?? '',
          description: _cate?.description ?? '',
          name: _cate?.name ?? '',
        },
        logo: logo || addon.logo,
        currentVersion: addon.currentVersion || addon.body.currentVersion,
        downloadedCount: 0,
        planHandle: plan,
        label: addonName,
        positionEnabled: addon.positionEnabled,
        sectionId: addon.body.id,
        syncedToServices: null,
        tagLine: addon.tagLine,
        type: addon.type,
        authorName: authorName,
        detail,
        canAddMulti: addon.canAddMulti,
        image: image,
        body: {
          id: body.id,
          commandId: '',
          category: {
            commandId: _cate?.commandId ?? '',
            description: _cate?.description ?? '',
            name: _cate?.name ?? '',
          },
          parentCommandId: addon.commandId ?? '',
          currentVersion: addon.currentVersion || addon.body.currentVersion,
          data: body.data,
          downloadedCount: 0,
          enable: true,
          label: addon.label,
          planHandle: plan,
          syncedToServices: null,
          addonIds: body.addonIds,
          image: image,
          type: body.type,
          approvedBy: addon.approvedBy,
          createdBy: addon.createdBy,
          pageTypes: [],
          tags: null,
          megaMenuCommandIds: body.megaMenuCommandIds ?? [],
        },
        enable: addon.enable || true,
        priorityOrder,
        searchTerms,
      };

      publishAddon.request({ addon: productAddon });
    }
  };

  const handleCancel = () => {
    setModalVisible({
      visible: false,
      addonId: '',
    });
    dispatch({
      type: 'setInfoPublishAddon',
      payload: defaultState,
    });
  };

  return (
    <MyModal
      onCancel={handleCancel}
      isVisible={visible}
      size="medium"
      headerText={`${i18n.t('general.publish', { text: i18n.t('general.on', { text: i18n.t('general.application') }) })}`}
      okText={i18n.t('general.publish')}
      isLoading={approveStatus === 'loading'}
      onOk={handlePublish}
    >
      <Field label={`${i18n.t('general.name')}`}>
        <DebounceInput
          block
          value={addonName}
          onValueChange={val => {
            dispatch({
              type: 'setInfoPublishAddon',
              payload: {
                addonName: val,
              },
            });
          }}
        />
      </Field>

      <Field label={`${i18n.t('general.plan')}`}>
        <SelectAntd
          value={plan}
          onChange={val => {
            dispatch({
              type: 'setInfoPublishAddon',
              payload: {
                plan: val as PLAN_HANDLE,
              },
            });
          }}
          data={plans}
        />
      </Field>

      <Field label="Priority Order">
        <NumberInput
          block
          borderColor="gray3"
          radius={6}
          max={Infinity}
          value={priorityOrder}
          onValueChange={val => {
            dispatch({
              type: 'setInfoPublishAddon',
              payload: {
                priorityOrder: val,
              },
            });
          }}
        />
      </Field>

      <Field label="Search Terms (Nhập tag và ấn nút Enter để insert)">
        <InputTags
          values={searchTerms?.join(',')}
          onChange={values => {
            dispatch({
              type: 'setInfoPublishAddon',
              payload: {
                searchTerms: values.split(','),
              },
            });
          }}
        />
      </Field>

      <Field label={i18n.t('general.category')}>
        <SelectAntd
          data={categories}
          value={category.name}
          onChange={(val, opt: any) => {
            dispatch({
              type: 'setInfoPublishAddon',
              payload: {
                category: {
                  name: val,
                  description: opt.children,
                },
              },
            });
          }}
          dropdownRender={menu => {
            return (
              <>
                {menu}
                <Divider />
                <View css={{ padding: '8px 0 4px 0', display: 'flex', justifyContent: 'space-between' }}>
                  <View columns={[10, 10, 10]}>
                    <DebounceInput
                      value={createdCategory.name}
                      block
                      placeholder={i18n.t('builderPage.save_for_builder.please_enter_item')}
                      sizeInput="small"
                      css={{ width: '100%', height: '38px' }}
                      onValueChange={val => {
                        dispatch({
                          type: 'setInfoPublishAddon',
                          payload: {
                            createdCategory: {
                              description: val,
                              name: val,
                            },
                          },
                        });
                      }}
                    />
                  </View>

                  <View columns={[2, 2, 2]}>
                    <Button
                      radius={4}
                      size="extra-small"
                      block
                      loading={addStatus === 'loading'}
                      onClick={() => {
                        if (createdCategory.name) {
                          createCategoryProduct.request({
                            name: createdCategory.name,
                            description: createdCategory.description,
                          });

                          dispatch({
                            type: 'setInfoPublishAddon',
                            payload: {
                              createdCategory: {
                                description: '',
                                name: '',
                              },
                            },
                          });
                        }
                      }}
                    >
                      {i18n.t('builderPage.save_for_builder.add_category')}
                    </Button>
                  </View>
                </View>
              </>
            );
          }}
        />
      </Field>

      <Field label={`${i18n.t('general.author')} ${i18n.t('general.name')}`}>
        <DebounceInput
          block
          value={authorName}
          onValueChange={val => {
            dispatch({
              type: 'setInfoPublishAddon',
              payload: {
                authorName: val,
              },
            });
          }}
        />
      </Field>
      <Field width={350} label={i18n.t('builderPage.save_for_builder.logo')}>
        <ChooseImage
          mode="popup"
          value={{ src: logo ?? '', width: 0, height: 0 }}
          onChange={val => {
            dispatch({
              type: 'setInfoPublishAddon',
              payload: {
                logo: val.src,
              },
            });
          }}
        />
      </Field>
      <Field width={350} label={i18n.t('general.image')}>
        <ChooseImage
          mode="popup"
          value={image}
          onChange={val => {
            dispatch({
              type: 'setInfoPublishAddon',
              payload: {
                image: val,
              },
            });
          }}
        />
      </Field>

      <Field label={i18n.t('builderPage.save_for_builder.detail')}>
        <TextEditorDebounce
          value={detail}
          onChange={value => {
            dispatch({
              type: 'setInfoPublishAddon',
              payload: {
                detail: value,
              },
            });
          }}
        />
      </Field>
    </MyModal>
  );
};
