import { ScreenProps } from '@wiloke/ui';
import { LeftBarParamList } from 'containers/BuilderPage/components/SidebarScreen/SidebarScreen';
import { FC } from 'react';
import { getLabel } from 'utils/functions/getLabel';
import StyleBox from '../components/StyleBox/StyleBox';
import DefaultScreen from '../components/DefaultScreen';
import { useGetColorsToColorPicker2 } from 'hooks/useGetColorsToColorPicker2';

const StyleBoxScreen: FC<ScreenProps<LeftBarParamList, 'styleBoxScreen'>> = ({ params }) => {
  const { label, ...rest } = params;

  const colors = useGetColorsToColorPicker2();

  return (
    <DefaultScreen title={getLabel(label)}>
      <StyleBox {...rest} colors={colors} />
    </DefaultScreen>
  );
};

export default StyleBoxScreen;
