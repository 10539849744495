import { userApis } from 'services/UserService/apis';
import { PageOfThemeService } from 'types/Page';
import { PageSettings } from 'types/Result';
import { SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client } from 'types/Sections';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

interface UpdatePageOfThemeAtom {
  page: PageOfThemeService;
  pageSettings: PageSettings;
}

export const updatePageOfThemeAtom = async ({ page, pageSettings }: UpdatePageOfThemeAtom) => {
  const { role, id } = getUserInfo();
  const { sections, label, type, commandId, parentCommandId, enable } = page;

  if (role === 'admin') {
    const response = await themeApis.atom.adminApi.page.updatePageOfAtom({
      page: {
        commandId,
        lastModifiedBy: id,
        parentCommandId,
        pageSettings,
        label,
        sectionCommandIds: sections.reduce<string[]>((result, section) => {
          const section_ = section as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client;
          if (section_.commandId) {
            return result.concat(section_.commandId);
          }
          return result;
        }, []),
        type,
        enable,
      },
    });
    await userApis.user.adminApi.updateVedaCacheVersion();
    return response;
  }

  throw new RoleException();
};
