import { sassCompile } from '@wiloke/functions';
import defaultGlobalScss from 'generate/scss/scss';
import { File } from 'types/Result';
import { PageSection } from 'types/Sections';
import { inlineCss } from 'utils/functions/InlineCss';

interface OnlyPageSettings {
  globalScss: string;
  variant: '1 phần page - bao gồm page settings';
  atomicCssForAdminNDev: string; // Khi lưu page atom -> client sẽ không tồn tại các section này -> Nếu optimize thì sẽ bị xoá mất nhưng nếu không ghi atomic thì khi build page atom (demo) sẽ không có atomic -> Lưu atomic css theo page để dễ dàng làm tool xoá khi zip folders theme
}

interface AllPropertiesOfPage {
  sectionsEnable_notIncludeAddonSections_includeMegamenuSections: PageSection[];
  globalScss: string;
  atomicCssForAdminNDev: string; // Khi lưu page atom -> client sẽ không tồn tại các section này -> Nếu optimize thì sẽ bị xoá mất nhưng nếu không ghi atomic thì khi build page atom (demo) sẽ không có atomic -> Lưu atomic css theo page để dễ dàng làm tool xoá khi zip folders theme
  variant: 'Toàn bộ page - bao gồm các sections, page settings';
}

export const getCssFromSectionsScss_PageScss_SectionsInlinesCss = async (
  params: OnlyPageSettings | AllPropertiesOfPage,
): Promise<[File] | [File, File]> => {
  const { variant, globalScss, atomicCssForAdminNDev } = params;
  const [_globalScss, _defaultGlobalScss] = await Promise.all([sassCompile.client(globalScss), sassCompile.client(defaultGlobalScss)]);

  const globalCssFile: File = {
    // NOTE: @tuong -> Hiện tại file được ghi vào file có tên "assets/<pageType>-veda-<pageCommandId>-style.css" và được sử dụng trong file "sections/<pageType>-veda-{pageCommandId}}.liquid" tổng
    /** "Làm dấu" để BE có thể xử lí sync shopify */
    // đánh dấu "/* globalCssBorder */" vì ở ngoài dashboard khi lưu sẽ không thể lấy được css của các "sections"
    content: `
      ${[_globalScss, _defaultGlobalScss].join('\n')}
      /* globalCssBorder */
      /* Temp atomic css */
      ${atomicCssForAdminNDev}
    `,
    type: 'globalCss của page - chỉ bảo gồm globalCss của page',
    id: 'globalCss',
    name: 'globalCss',
    section: undefined,
  };

  if (variant === '1 phần page - bao gồm page settings') {
    return [globalCssFile];
  }
  if (variant === 'Toàn bộ page - bao gồm các sections, page settings') {
    const { sectionsEnable_notIncludeAddonSections_includeMegamenuSections } = params;
    const sections = await Promise.all(
      sectionsEnable_notIncludeAddonSections_includeMegamenuSections.reduce<Promise<string>[]>((res, item) => {
        if (item.enable) {
          return res.concat(sassCompile.client(item.data.scss ?? '', item.id));
        }
        return res;
      }, []),
    );
    return [
      globalCssFile,
      {
        content: [
          ...Array.from(new Set(sections)),
          inlineCss.getCssFromSettings(sectionsEnable_notIncludeAddonSections_includeMegamenuSections.map(section => section.data.settings)),
        ].join('\n'),
        type:
          'css của page - bao gồm css của các section "thuộc page", css inline của các sections "thuộc page", không bao gồm atomic css của các sections "thuộc page"',
        name: 'sections',
        id: 'sections',
        section: undefined,
      },
    ];
  }
  throw new Error('getCssFromSectionScssNPageScssAsync');
};
