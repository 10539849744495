import { deletePartner } from 'containers/Admin/PartnerManagement/store/actions';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { deletePartnerAPI } from 'services/UserService/Logic/deletePartner';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';

function* handleAction({ payload: { id, onFulfilled } }: ReturnType<typeof deletePartner.request>) {
  try {
    const response: SagaReturnType<typeof deletePartnerAPI> = yield retry(3, 1000, deletePartnerAPI, { id });
    yield put(deletePartner.success({ id }));
    notifyAxiosHandler.handleSuccess(response.message);
    onFulfilled?.();
  } catch (error) {
    notifyAxiosHandler.handleError(error as any);
    yield put(deletePartner.failure({ id: id }));
  }
}

export function* watchDeletePartnerApps() {
  yield takeLatest(getActionType(deletePartner.request), handleAction);
}
