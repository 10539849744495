import { pageApis } from 'services/PageService/apis';
import { getCurrentVersion } from 'utils/CacheControl/VedaClientCacheControl';

interface GetPagesOfThemeClient {
  commandIds: string[];
}

const DEFAULT_SIZE = 20;

export const getPagesOfThemeClient = async ({ commandIds }: GetPagesOfThemeClient) => {
  if (!commandIds.length) {
    return [];
  }
  const response = await pageApis.vedaApplication.userApi.page.getClients({
    type: 'GET FIRST PAGE',
    commandIds: commandIds.join(','),
    group: 'THEME',
    cacheVersion: getCurrentVersion().getTime(),
    size: DEFAULT_SIZE,
  });
  return response.info;

  // const { role } = getUserInfo();
  // if (role === 'admin') {
  //   throw new Error('Chưa lắp api');
  // }
  // if (role === 'user') {
  //   const response = await pageApis.vedaApplication.userApi.page.getClient({ commandId });
  //   return response.info;
  // }
  // throw new RoleException();
};
