import { message as AntMessage, notification } from 'antd';
import { selectExtractThemeToFileForSync } from '../../../selectors';
import { all, call, put, SagaReturnType, select, takeLeading } from 'redux-saga/effects';
import { copyToClipboard } from 'utils/copyToClipboard';
import { getActionType } from 'wiloke-react-core/utils';
import { extractThemeToFileForSync, initSessionOfProcesses, pushActualProcesses, setExpectProcesses } from '../../actions';
import { extractResultOfAddons } from './steps/extractResultOfAddons';
import { extractResultOfPage } from './steps/extractResultOfPage';
import { extractResultOfTheme } from './steps/extractResultOfTheme';
import { extractResultReadyForSync } from './steps/extractResultReadyForSync';
import { getAddons } from './steps/getAddons';
import { getFooterSections } from './steps/getFooterSections';
import { getHeaderSections } from './steps/getHeaderSections';
import { getLiquidVariables } from './steps/getLiquidVariables';
import { getMegamenus } from './steps/getMegamenus';
import { getPage } from './steps/getPage';
import { getSections } from './steps/getSections';
import { getTheme } from './steps/getTheme';
import { AdminTheme } from 'types/Theme';

function* handleExtractThemeToFileForSync({ payload }: ReturnType<typeof extractThemeToFileForSync.request>) {
  const startTime = Date.now();
  const { dataInteracting, isRetry, actionExportVariant, isNeedReduceTotalLinesOfCode, sectionsWithLinkListSetting, onSuccess, onFailure } = payload;
  const { theme, entityVariant } = dataInteracting;
  const { commandId } = theme;
  const isExportForEnvato = actionExportVariant === 'ExportForEnvato';
  try {
    yield put(
      setExpectProcesses([
        'Chuẩn bị dữ liệu theme',
        'Chuẩn bị dữ liệu addons',
        'Chuẩn bị dữ liệu headers',
        'Chuẩn bị dữ liệu footers',
        'Trích xuất kết quả addons',
        'Trích xuất kết quả theme',
        `Chuẩn bị dữ liệu và trích xuất kết quả của ${theme.pageCommandIds.length} pages`,
        'Trích xuất kết quả cuối cùng',
      ]),
    );
    yield put(initSessionOfProcesses());

    // Theme
    yield put(pushActualProcesses('Chuẩn bị dữ liệu theme'));
    const cacheKeyOfTheme: SagaReturnType<typeof getTheme> = yield call(getTheme, {
      isRetry,
      commandId,
      entityVariant,
    });
    notification.success({
      message: 'Chuẩn bị dữ liệu theme thành công',
    });

    // Addon
    yield put(pushActualProcesses('Chuẩn bị dữ liệu addons'));
    const cacheKeysOfAddons: SagaReturnType<typeof getAddons> = yield call(getAddons, cacheKeyOfTheme.addonCommandIds, isRetry);
    notification.success({
      message: 'Chuẩn bị dữ liệu addons thành công',
    });

    // Header
    yield put(pushActualProcesses('Chuẩn bị dữ liệu headers'));
    const cacheKeysOfHeaders: SagaReturnType<typeof getHeaderSections> = yield call(getHeaderSections, {
      commandIds: cacheKeyOfTheme.headerSectionCommandIds,
      entityVariant,
      isRetry,
    });
    const cacheKeysOfMegamenusInHeaders: SagaReturnType<typeof getMegamenus> = yield call(getMegamenus, {
      commandIds: cacheKeysOfHeaders.megamenuCommandIds,
      entityVariant,
      isRetry,
    });
    notification.success({
      message: 'Chuẩn bị dữ liệu headers thành công',
    });

    // Footer
    yield put(pushActualProcesses('Chuẩn bị dữ liệu footers'));
    const cacheKeysOfFooters: SagaReturnType<typeof getFooterSections> = yield call(getFooterSections, {
      commandIds: cacheKeyOfTheme.footerSectionCommandIds,
      entityVariant,
      isRetry,
    });
    const cacheKeysOfMegamenusInFooters: SagaReturnType<typeof getMegamenus> = yield call(getMegamenus, {
      commandIds: cacheKeysOfFooters.megamenuCommandIds,
      entityVariant,
      isRetry,
    });
    notification.success({
      message: 'Chuẩn bị dữ liệu footers thành công',
    });

    // extract addons
    yield put(pushActualProcesses('Trích xuất kết quả addons'));
    const cacheKeyOfExtractResultOfAddons: SagaReturnType<typeof extractResultOfAddons> = yield call(extractResultOfAddons, { cacheKeysOfAddons });
    notification.success({
      message: 'Trích xuất kết quả addons thành công',
    });

    // extract theme
    yield put(pushActualProcesses('Trích xuất kết quả theme'));
    const cacheKeyOfExtractResultOfTheme: SagaReturnType<typeof extractResultOfTheme> = yield call(extractResultOfTheme, {
      cacheKeyOfTheme,
      cacheKeysOfAddons,
      cacheKeysOfFooters,
      cacheKeysOfHeaders,
      cacheKeysOfMegamenusInFooters,
      cacheKeysOfMegamenusInHeaders,
      isExportForEnvato,
    });
    notification.success({
      message: 'Trích xuất kết quả theme thành công',
    });

    // Page
    yield put(pushActualProcesses(`Chuẩn bị dữ liệu và trích xuất kết quả của ${theme.pageCommandIds.length} pages`));
    let isSomethingError = false;
    const cacheKeysOfPages: Array<SagaReturnType<typeof extractResultOfPage>> = yield all(
      cacheKeyOfTheme.pageCommandIds.map(async (pageCommandId, index) => {
        try {
          const cacheKeyOfPage = await getPage({ commandId: pageCommandId, isRetry, entityVariant });
          if (isSomethingError) {
            throw new Error('Có phần tử lỗi rồi => Ngắt quá trình để tăng performance');
          }
          const cacheKeysOfSections = await getSections({ commandIds: cacheKeyOfPage.sectionCommandIds, isRetry, entityVariant });
          if (isSomethingError) {
            throw new Error('Có phần tử lỗi rồi => Ngắt quá trình để tăng performance');
          }
          const cacheKeysOfMegamenusInSections = await getMegamenus({ commandIds: cacheKeysOfSections.megamenuCommandIds, isRetry, entityVariant });
          if (isSomethingError) {
            throw new Error('Có phần tử lỗi rồi => Ngắt quá trình để tăng performance');
          }
          const cacheKeyOfLiquidVariables = await getLiquidVariables(
            { cacheKeyOfPage, cacheKeysOfSections, cacheKeysOfMegamenusInSections, entityVariant },
            isRetry,
          );
          if (isSomethingError) {
            throw new Error('Có phần tử lỗi rồi => Ngắt quá trình để tăng performance');
          }
          return extractResultOfPage({
            cacheKeyOfPage,
            cacheKeysOfSections,
            cacheKeyOfLiquidVariables,
            cacheKeysOfMegamenusInSections,
            entityVariant,
          }).then(response => {
            if (!isSomethingError) {
              notification.success({
                message: `Page: "${response.pageName}" OK`,
                description: `Thành công trích xuất kết quả page "${response.pageName}" - page thứ ${index + 1}`,
              });
            }
            return response;
          });
        } catch (error) {
          if (error instanceof Error && error.message === 'Có phần tử lỗi rồi => Ngắt quá trình để tăng performance') {
          } else {
            isSomethingError = true;
            throw error;
          }
        }
      }),
    );
    notification.success({
      message: `Chuẩn bị dữ liệu và trích xuất kết quả của ${theme.pageCommandIds.length} pages thành công`,
    });

    yield put(pushActualProcesses('Trích xuất kết quả cuối cùng'));
    yield call(extractResultReadyForSync, {
      cacheKeyOfExtractResultOfAddons,
      cacheKeysOfPages,
      cacheKeyOfExtractResultOfTheme,
      isExportForEnvato,
      isNeedReduceTotalLinesOfCode,
      entityVariant,
      sectionsWithLinkListSetting,
      themeProduct: dataInteracting.entityVariant === 'Atom' ? (dataInteracting.theme as AdminTheme).vedaProductRepresent : undefined,
    });

    yield put(extractThemeToFileForSync.success({ dataInteracting }));
    notification.success({ message: 'Trích xuất kết quả thành công' });
    onSuccess();
  } catch (error) {
    const message = typeof error === 'object' && error && 'message' in error ? error.message : error;
    const { actualProcessed }: SagaReturnType<typeof selectExtractThemeToFileForSync> = yield select(selectExtractThemeToFileForSync);
    notification.error({
      duration: null,
      message: 'Quá trình trích xuất kết quả để đồng bộ theme lên shopify gặp lỗi',
      description: (
        <div>
          <p>
            Lỗi có thể xảy ra do mạng nhưng hãy cứ báo bug bằng cách gửi vào nhóm kèm lời nhắn "Quá trình trích xuất kết quả để đồng bộ theme lên
            shopify gặp lỗi: {theme.label} - {message}"
          </p>
          <button
            style={{
              borderRadius: '4px',
              backgroundColor: '#2C36DC',
              color: '#fff',
              fontWeight: 500,
              padding: '10px 16px',
              cursor: 'pointer',
              outline: 'none',
              border: 'none',
            }}
            onClick={() => {
              copyToClipboard(`
                "Quá trình trích xuất kết quả để đồng bộ theme lên shopify gặp lỗi: ${theme.label} - ${message}"
                - Tiến trình đã hoàn thành:
                  ${actualProcessed.slice(0, -1).map(item => `+ ${item}`)}
                - Tiến trình bị treo:
                  + ${actualProcessed[actualProcessed.length - 1]}
              `);
              AntMessage.success('Đã copy');
            }}
          >
            Copy lời nhắn
          </button>
        </div>
      ),
    });
    onFailure(dataInteracting);
    yield put(extractThemeToFileForSync.failure({ dataInteracting, actionExportVariant }));
  } finally {
    notification.info({
      message: `Quá trình trích xuất kết quả mất ${Math.floor((Date.now() - startTime) / 1000)}s`,
    });
  }
}

export function* wachExtractThemeToFileForSync() {
  yield takeLeading(getActionType(extractThemeToFileForSync.request), handleExtractThemeToFileForSync);
}
