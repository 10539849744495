import configureApp from 'configureApp';
import fetchAPI from 'utils/functions/fetchAPI';

export const clearCache = () => {
  return fetchAPI.request({
    method: 'DELETE',
    baseURL: '',
    url: `${configureApp.endpoint['shopify-connections']}/cache-manager/evict`,
  });
};
