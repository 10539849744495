import { Tooltip } from '@wiloke/ui';
import { useSetResponsive } from 'containers/BuilderPage/store/responsive/slice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSetReloadPage } from 'store/actions/actionReloadPage';
import { useRenewData } from 'store/global/renewDataBuilder/actions';
import { renewDataBuilderSelector } from 'store/selectors';
import { i18n } from 'translation';
import { getCurrentVersion, renewVersion } from 'utils/CacheControl/ShopifyConnectionCacheControl';
import { ActivityIndicator, FontAwesome, View } from 'wiloke-react-core';
import * as styles from './styles';

export const RenewButton = () => {
  const { statusRequest } = useSelector(renewDataBuilderSelector);
  const setReloadPage = useSetReloadPage();
  const renewDataBuilder = useRenewData();
  const setResponsive = useSetResponsive();

  useEffect(() => {
    if (statusRequest === 'idle' && getCurrentVersion().getDay() - new Date().getDay() >= 1) {
      renewVersion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRefreshShopifyData = () => {
    renewDataBuilder.request(undefined);
    setResponsive('desktop');
    setReloadPage(undefined);
  };

  return (
    <View css={styles.iconWrap} onClick={handleRefreshShopifyData}>
      <Tooltip text={i18n.t('general.renew_data')} placement="bottom" css={styles.itemFull}>
        {statusRequest === 'loading' ? <ActivityIndicator size={16} /> : <FontAwesome type="far" name="sync-alt" size={16} />}
      </Tooltip>
    </View>
  );
};
