import { Theme, ThemeSettings } from 'types/Result';
import { SectionCategoryTag } from 'types/Sections';
import { AdminTheme, ProductTheme } from 'types/Theme';
import { PLAN_HANDLE } from 'utils/constants/PlanEnum';
import { createAsyncAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';

export const getThemesAtom = createAsyncAction([
  '@ThemeManager/getThemesAtom/request',
  '@ThemeManager/getThemesAtom/success',
  '@ThemeManager/getThemesAtom/failure',
])<undefined, { hasNextPage: boolean; data: AdminTheme[] }, undefined>();

export const loadMoreThemesAtom = createAsyncAction([
  '@ThemeManager/loadMoreThemesAtom/request',
  '@ThemeManager/loadMoreThemesAtom/success',
  '@ThemeManager/loadMoreThemesAtom/failure',
])<{ cursor: string }, { hasNextPage: boolean; data: AdminTheme[] }, undefined>();

export const createThemeAtom = createAsyncAction([
  '@ThemeManager/createThemeAtom/request',
  '@ThemeManager/createThemeAtom/success',
  '@ThemeManager/createThemeAtom/failure',
])<
  {
    themeSettings: ThemeSettings;
    pageCommandIds: string[];
    label: string;
    featuredImage: string;
    screenshot1: string;
    globalJs: Theme['globalJs'];
    globalScss: Theme['globalScss'];
    vendors: Theme['vendors'];
    onFulfill: () => void;
  },
  { data: AdminTheme },
  undefined
>();

export const updateThemeAtom = createAsyncAction([
  '@ThemeManager/updateThemeAtom/request',
  '@ThemeManager/updateThemeAtom/success',
  '@ThemeManager/updateThemeAtom/failure',
])<
  {
    prevThemeData: AdminTheme;
    pageCommandIdsNeedImport: string[];
    pageCommandIdsNeedDelete: string[];
    label: string;
    featuredImage: string;
    screenshot1: string;
    onFulfill: () => void;
  },
  { data: AdminTheme },
  undefined
>();

export const deleteThemeAtom = createAsyncAction([
  '@ThemeManager/deleteThemeAtom/request',
  '@ThemeManager/deleteThemeAtom/success',
  '@ThemeManager/deleteThemeAtom/failure',
])<{ commandId: string; name: string }, { commandId: string }, { commandId: string }>();

interface PublishParams {
  categoryOfProduct: undefined | SectionCategoryTag;
  zipUrl: string;
  documentUrl: string;
  group: ProductTheme['group'];
  envatoThemeId: string | undefined;
  buyNowUrl: string;
  plan: PLAN_HANDLE;
  demoUrl?: string;
  version?: string;
  description?: string;
}

export const publishThemeAtom = createAsyncAction([
  '@ThemeManager/publishThemeAtom/request',
  '@ThemeManager/publishThemeAtom/success',
  '@ThemeManager/publishThemeAtom/failure',
])<
  {
    themeAtom: AdminTheme;
  } & PublishParams,
  { themeAtom: AdminTheme },
  undefined
>();

export const hotfixThemeAtom = createAsyncAction([
  '@ThemeManager/hotfixThemeAtom/request',
  '@ThemeManager/hotfixThemeAtom/success',
  '@ThemeManager/hotfixThemeAtom/failure',
])<{ commandId: string; assignToUserId: number; message: string }, { commandId: string }, { commandId: string }>();

export const useGetThemesAtom = createDispatchAsyncAction(getThemesAtom);
export const useLoadMoreThemesAtom = createDispatchAsyncAction(loadMoreThemesAtom);
export const useCreateThemeAtom = createDispatchAsyncAction(createThemeAtom);
export const useUpdateThemeAtom = createDispatchAsyncAction(updateThemeAtom);
export const useDeleteThemeAtom = createDispatchAsyncAction(deleteThemeAtom);
export const usePublishThemeAtom = createDispatchAsyncAction(publishThemeAtom);
export const useHotfixThemeAtom = createDispatchAsyncAction(hotfixThemeAtom);
