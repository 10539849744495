import { all, put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { getChangelogOfAddonAtom } from 'services/AddonService/Logic/Changelogs/getChangelogOfAddonAdomt';
import { getImportAddonVersion } from 'store/actions/versions/actionSectionVersion';
import { getActionType } from 'wiloke-react-core/utils';

function* handleGet({ payload: { addonCommandId } }: ReturnType<typeof getImportAddonVersion.request>) {
  try {
    const response: SagaReturnType<typeof getChangelogOfAddonAtom> = yield retry(3, 1000, getChangelogOfAddonAtom, addonCommandId);

    yield all(response.map(({ atomCommandId, version }) => put(getImportAddonVersion.success({ addonCommandId: atomCommandId, data: version }))));
  } catch (error) {
    yield put(getImportAddonVersion.failure({ addonCommandId }));
  }
}

export function* watchGetImportAddonVersion() {
  yield takeLatest(getActionType(getImportAddonVersion.request), handleGet);
}
