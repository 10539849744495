import { megaMenuApiController } from 'services/MegaMenuService';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const updateVersion = async ({ atomCommandId }: { atomCommandId: string }) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const response = await megaMenuApiController.product.adminApi.mega_menu.updateVersion({ commandId: atomCommandId, cacheVersion: lastCache });
    return response;
  }
  if (role === 'dev') {
    const response = await megaMenuApiController.product.devApi.mega_menu.updateVersion({ commandId: atomCommandId, cacheVersion: lastCache });
    return response;
  }
  const response = await megaMenuApiController.product.userApi.mega_menu.updateVersion({ commandId: atomCommandId, cacheVersion: lastCache });
  return response;
};
