import { jsonParse } from '@wiloke/functions';
import { Button, MyModal, ScrollBars, Tooltip } from '@wiloke/ui';
import { message } from 'antd';
import { CodeEditor } from 'components/CodeEditor/CodeEditor';
import DragItem from 'components/DragItem';
import SelectAntd from 'components/SelectAntd';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDeleteLangFlow, useImportDefaultLanguage } from 'store/global/globalTranslation/actions';
import {
  globalThemeTranslationSelector,
  useEditFileLanguageActive,
  useEditGlobalThemeTransition,
  useSetFileLanguageActive,
} from 'store/global/globalTranslation/slice';
import { i18n } from 'translation';
import { translationLanguage } from 'utils/constants/translationLanguage';
import { FontAwesome, useTheme, View } from 'wiloke-react-core';

export interface ThemeJsonProps {}

const ThemeJson: FC = () => {
  const { languageActive, translation, importStatus } = useSelector(globalThemeTranslationSelector);
  const [visible, setVisible] = useState(false);
  const [fileName, setFileName] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const setFileActive = useSetFileLanguageActive();
  const deleteFlow = useDeleteLangFlow();
  const editFileName = useEditFileLanguageActive();
  const saveCurrentJson = useEditGlobalThemeTransition();
  const { colors } = useTheme();
  const importDefaultLanguage = useImportDefaultLanguage();

  const handleAddMoreFile = () => {
    setIsEdit(false);
    setVisible(true);
  };

  const handleSetEditFileName = (name: string) => () => {
    setIsEdit(true);
    setVisible(true);
    setFileActive(name);
    setFileName(name);
  };

  const handleDeleteFile = (lang: string) => () => {
    if (Object.keys(translation).length === 1) {
      message.warning('You must have at least 1 language');
    } else {
      deleteFlow(lang);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setFileName('');
    setIsEdit(false);
  };

  const handleOk = () => {
    if (isEdit) {
      editFileName(fileName);
      setFileName('');
      setVisible(false);
    } else {
      const firstValOfTranslate = translation['en'] ?? Object.values(translation)[0] ?? '';
      importDefaultLanguage.request({
        fileName,
        isEdit,
        defaultFileContent: firstValOfTranslate,
        onFulfill() {
          setFileName('');
          setVisible(false);
        },
        onFailed() {
          setFileName('');
          setVisible(false);
        },
      });
    }
  };

  const getDescription = (label: string) => {
    if (label.length > 10) {
      return `${label.slice(0, 10)}...`;
    }
    return `${label}`;
  };

  return (
    <View css={{ width: '100%', height: '100%', display: 'flex' }}>
      <View css={{ width: '200px', padding: '4px' }} borderWidth={1} borderColor="gray3" borderStyle="solid" backgroundColor="gray1">
        <ScrollBars css={{ height: '100%' }}>
          {Object.keys(translation).map(lang => {
            return (
              <DragItem
                key={lang}
                active={lang === languageActive}
                css={{ borderRadius: 0, marginBottom: '4px' }}
                Icon={() => <></>}
                label={getDescription(lang)}
                innerCss={{
                  padding: '0 10px 0 0',
                  height: '30px',
                  borderBottomStyle: `${lang === languageActive ? 'solid' : 'unset'}`,
                  borderBottomColor: `${lang === languageActive ? colors.primary : colors.transparent}`,
                }}
                onEdit={() => {
                  setFileActive(lang);
                }}
                RightItem={[
                  <Tooltip key="edit" portal text={i18n.t('builderPage.change_file_name')}>
                    <FontAwesome size={12} type="far" name="edit" onClick={handleSetEditFileName(lang)} />
                  </Tooltip>,
                  <Tooltip key="delete" portal text={i18n.t('general.delete')}>
                    <FontAwesome size={12} type="far" name="trash" css={{ marginLeft: '4px' }} onClick={handleDeleteFile(lang)} />
                  </Tooltip>,
                ]}
              />
            );
          })}

          <Button backgroundColor="secondary" size="extra-small" block onClick={handleAddMoreFile}>
            {i18n.t('builderPage.add_more_language')}
          </Button>
        </ScrollBars>
      </View>

      <CodeEditor
        id={`theme-json-${languageActive}`}
        language="json"
        alwaysShow
        highlight={false}
        value={JSON.stringify(jsonParse(translation[languageActive] || '{}'), null, 2)}
        options={{
          readOnly: languageActive === '',
        }}
        onChange={value => {
          try {
            if (value !== undefined) {
              saveCurrentJson(value);
            }
          } catch (error) {
            console.log(error);
          }
        }}
      />

      <MyModal
        isVisible={visible}
        headerText={`${isEdit ? i18n.t('general.edit') : i18n.t('general.create')} ${i18n.t('builderPage.translation_file')}`}
        onCancel={handleCancel}
        onOk={handleOk}
        isLoading={importStatus === 'loading'}
      >
        <SelectAntd
          placeholder={i18n.t('builderPage.select_language')}
          showSearch
          value={fileName}
          data={translationLanguage}
          onChange={val => {
            setFileName(val);
          }}
          filterOption={(input, option) => {
            return ((option?.children as unknown) as string).toLowerCase().includes(input.toLowerCase());
          }}
        />
      </MyModal>
    </View>
  );
};

export default ThemeJson;
