import { AxiosResponse } from 'axios';
import { UploadFile } from 'components/ChooseImage/types/MyMedia';
import configureApp from 'configureApp';
import fetchAPI from 'utils/functions/fetchAPI';
import { objectToFormData } from 'utils/objectToFormData';

interface ResponseSuccess {
  message: string;
}

interface Params {
  email: string;
  file: UploadFile['file'];
}

export const importCSVFile = async ({ email, file }: Params) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${configureApp.endpoint['shopify-connections']}/reviews/importers/me/aliexpress`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: {
      receiverEmail: email,
    },
    data: objectToFormData({ file }),
  });
  return response;
};
