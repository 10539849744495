import ImagePlaceholder from 'components/ImagePlaceholder';
import { Tooltip, Button } from '@wiloke/ui';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { i18n } from 'translation';
import { PreviewImage } from 'types/Page';
import { imageUrl } from '@wiloke/functions';
import { ActivityIndicator, FontAwesome, View } from 'wiloke-react-core';
import { useRemoveBackground } from './actions/actionMyMedia';
import { imageGallerySelector } from './selector';
import * as styles from './styles';
import { imageUrlToFile } from './utils/imageUrlToFile';
import { getUserInfo } from 'utils/functions/getUserInfo';
import imagePlaceHolder from './image-placeholder.svg';

interface ChooseImageButtonProps {
  /** Sự kiện onClick vào thumbnail */
  onClick?: () => void;
  image?: PreviewImage;
  imageWidth?: number;
  onClear?: ({ height, src, width }: PreviewImage) => void;
  onAfterRemoveBg?: (newImage: PreviewImage) => void;
  forceClear?: boolean;
}

export const ChooseImageButton: FC<ChooseImageButtonProps> = ({ onClick, image, imageWidth = 300, forceClear = false, onClear, onAfterRemoveBg }) => {
  const { my_media } = useSelector(imageGallerySelector);
  const { removeBackgroundStatus } = my_media;

  const removeBackground = useRemoveBackground();
  const { role } = getUserInfo();

  const _handleClick = () => {
    onClick?.();
  };

  const renderLoadingOverlay = (
    <View css={styles.loadingOverlay}>
      <ActivityIndicator size={20} color="light" />
    </View>
  );

  const handleRemoveBackground = async () => {
    if (image && image.src) {
      const filename = image.src
        .split('/')
        .pop()
        ?.replace(/(.jpg|.png|.jpeg).*/gm, '');
      if (filename) {
        const file = await imageUrlToFile({
          url: image.src,
          name: filename,
        });
        removeBackground.request({
          url: 'choose-image-button',
          name: filename,
          data: {
            file,
          },
          onFulFill: newImage => {
            onAfterRemoveBg?.({ src: newImage.url, height: newImage.height, width: newImage.width });
          },
        });
      }
    }
  };

  return (
    <View css={styles.container} backgroundColor="light">
      {removeBackgroundStatus['choose-image-button'] === 'loading' && renderLoadingOverlay}
      {!image?.src ? (
        <View onClick={_handleClick}>
          <ImagePlaceholder aspectRatio={16 / 9} />
        </View>
      ) : (
        <View css={styles.imageContainer}>
          <img src={imageUrl(image.src, imageWidth)} alt="" onClick={_handleClick} />

          {image.src !== imagePlaceHolder && (
            <View
              css={styles.clearButton}
              backgroundColor="gray8"
              onClick={() => {
                onClear?.({
                  src: forceClear ? '' : imagePlaceHolder,
                  height: 0,
                  width: 0,
                });
              }}
            >
              <FontAwesome name="times" type="far" color="light" css={{ lineHeight: 'normal' }} />
            </View>
          )}

          {image.src !== imagePlaceHolder && role !== 'user' && (
            <View css={styles.removeBackgroundButton} backgroundColor="gray8" onClick={handleRemoveBackground}>
              <Tooltip portal text={'Remove background'}>
                <FontAwesome name="eraser" type="far" color="light" css={{ lineHeight: 'normal' }} />
              </Tooltip>
            </View>
          )}
        </View>
      )}
      <Button backgroundColor="light" color="gray9" colorHover="primary" block onClick={_handleClick} size="extra-small">
        {i18n.t('general.change_image')}
      </Button>
    </View>
  );
};
