import { handleWaitForSocketOfSyncThemeByResultOfExtractThemeToFileForSync } from 'packages/SyncThemeByResultOfExtractThemeToFileForSync/hooks/useSocketForSyncThemeByResultOfExtractThemeToFileForSync';
import { call, retry, SagaReturnType } from 'redux-saga/effects';
import { handleExtractResultForForceSync, shopifyConnectionService } from 'services/ShopifyConnection';

export interface SyncGlobalOfTheme {
  themeParams: ReturnType<typeof handleExtractResultForForceSync>['themeParams'];

  /**
   * @deprecated Anh Long đang xử lý bằng js nên không cần ghi ic css lên shopify nữa
   */
  // icCssParams: ReturnType<typeof handleExtractResultForForceSync>['icCssParams'];
}

export interface SyncGlobalOfThemeResult {
  statusSyncGlobalOfTheme: SyncFulfillStatus;
  isNeedIgnoreReportError: boolean;
  messageError: string | undefined;

  /**
   * @deprecated Anh Long đang xử lý bằng js nên không cần ghi ic css lên shopify nữa
   */
  // statusSyncicCss: SyncFulfillStatus;
}

/** Sync global (cái được sinh ra từ themeSettings) và ic css */
export function* syncGlobalOfTheme({ themeParams }: SyncGlobalOfTheme) {
  yield retry(3, 1000, shopifyConnectionService.writeGlobalOfThemeToShopify, { ...themeParams, isPreview: false });
  const socketSyncGlobalOfTheme: SagaReturnType<typeof handleWaitForSocketOfSyncThemeByResultOfExtractThemeToFileForSync> = yield call(
    handleWaitForSocketOfSyncThemeByResultOfExtractThemeToFileForSync,
    'Đồng bộ theme / Ghi global (sinh ra từ themeSettings)',
  );
  return {
    statusSyncGlobalOfTheme: socketSyncGlobalOfTheme.statusSync,
    messageError: socketSyncGlobalOfTheme.statusSync === 'failure' ? socketSyncGlobalOfTheme.message : undefined,
    isNeedIgnoreReportError: socketSyncGlobalOfTheme.isNeedIgnoreReportError,
    /**
     * @deprecated Anh Long đang xử lý bằng js nên không cần ghi ic css lên shopify nữa
     */
    // statusSyncicCss: 'success',
  } as SyncGlobalOfThemeResult;
}
