import { loadMorePagesPublish } from 'services/PageService/Logic/getProductsPublish';
import { actionLoadMoreTemplatesPopup } from '../actions';
import { put, retry, takeLatest } from 'redux-saga/effects';
import { ServerTemplateResponse } from 'services/PagesBuilderService';
import { getActionType } from 'wiloke-react-core/utils';

const PAGE_SIZE = 20;

function* handleAction({
  payload: { cursor, type, categoryId, planFilter, search, useCases },
}: ReturnType<typeof actionLoadMoreTemplatesPopup.request>) {
  try {
    const response: Awaited<ReturnType<typeof loadMorePagesPublish>> = yield retry(3, 1000, loadMorePagesPublish, {
      pageType: type,
      size: PAGE_SIZE,
      categoryId,
      label: search,
      planFilter,
      useCases,
      cursor,
    });
    yield put(
      actionLoadMoreTemplatesPopup.success({
        data: response.info as ServerTemplateResponse[],
        hasNextPage: response.info.length >= PAGE_SIZE,
      }),
    );
  } catch (error) {
    yield put(actionLoadMoreTemplatesPopup.failure(undefined));
  }
}

export function* watchLoadMoreTemplatesInPopup() {
  yield takeLatest(getActionType(actionLoadMoreTemplatesPopup.request), handleAction);
}
