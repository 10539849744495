import { Dropdown } from '@wiloke/ui';
import BoxCenter from 'components/BoxCenter';
import Logo from 'components/Logo';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useSetAppSettingsVisible } from 'store/global/appSettings/slice';
import { authSelector, pageDataSelector, pageSettingsSelector } from 'store/selectors';
import { i18n } from 'translation';
import { FileImportExportContent } from 'types/FileImportExport';
import { Consts } from 'utils/constants/constants';
import { PLAN_STATIC_DATA, VEDA_FUNCTION_ENUM } from 'utils/constants/PlanEnum';
import { isFooter, isHeader, isSectionAddonsOrMegamenu } from 'utils/functions/checkSectionType';
import { deepFind } from 'utils/functions/deepFind';
import fileDownload from 'utils/functions/fileDownload';
import getPageInfo from 'utils/functions/getInfo';
import { validatePlan } from 'utils/functions/validatePlan';
import { FontAwesome, View } from 'wiloke-react-core';
import { Exit } from './Exit';
import * as styles from './styles';

/**
 * LeftBarHeader
 * @description Component tại đầu trang bên trái
 */

const LeftBarHeader: FC = () => {
  const page = useSelector(pageDataSelector);
  const pageSettings = useSelector(pageSettingsSelector);
  const { plan, role } = useSelector(authSelector);

  const setAppSettingsVisible = useSetAppSettingsVisible();
  const pageId = getPageInfo('id');
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();

  const handleFilterSectionsExport = () => {
    const mainSections = page.sections.filter(
      section => !isSectionAddonsOrMegamenu(section.type) && !isHeader(section.type) && !isFooter(section.type),
    );
    const megaMenuIds = deepFind(mainSections, 'megaMenuId');
    const sectionMegaMenus = page.sections.filter(section => megaMenuIds.includes(section.id));
    return [...mainSections, ...sectionMegaMenus];
  };

  const handlePageExport = () => {
    if (validatePlan({ userPlan: plan.planHandle, itemPlan: VEDA_FUNCTION_ENUM.export_import_page, role }) === 'pass') {
      const data = JSON.stringify({
        page: {
          ...page,
          sections: handleFilterSectionsExport(),
        },
        pageSettings: {
          generalSettings: pageSettings.generalSettings[pageId],
          vendors: pageSettings.vendors[pageId],
          globalJs: pageSettings.globalJs[pageId],
          globalScss: pageSettings.globalScss[pageId],
        },
      } as Required<FileImportExportContent>);
      const name = `${page.label.replace(/\s/g, '-')}-${page.commandId}.${Consts.AppName}`;
      fileDownload({
        data,
        name,
      });
    } else {
      setPlanRequesting({
        handle: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_page].handle,
        type: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_page].type,
        pricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_page].pricePerMonth,
        pricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_page].pricePerYear,
        title: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_page].title,
        originalPricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_page].pricePerMonth,
        originalPricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_page].pricePerYear,
      });
      setValidateCouponVisible(true);
    }
  };

  const handleOpenAppSettings = () => {
    setAppSettingsVisible(true);
  };

  return (
    <View css={styles.header}>
      <View css={styles.headerLeft}>
        <Exit />
        <Logo />
      </View>
      <Dropdown
        data={[
          { icon: 'cog', label: i18n.t('general.app_settings'), value: 'app_settings' },
          { icon: 'file-alt', label: i18n.t('general.documents'), value: 'documents' },
          // { icon: 'life-ring', label: i18n.t('general.support'), value: 'support' },
          // { icon: 'history', label: i18n.t('general.version'), value: 'version' },
          {
            icon: plan.planHandle === 'free' ? 'star' : 'file-export',
            label: i18n.t('general.page', { text: i18n.t('general.export'), textTransform: 'capitalize' }),
            value: 'page_export',
            iconCss(value) {
              return plan.planHandle === 'free'
                ? {
                    color: value.colors.light,
                    backgroundColor: value.colors.quaternary,
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '4px',
                  }
                : { color: value.colors.gray7 };
            },
          },
        ]}
        onClick={type => {
          switch (type) {
            case 'page_export':
              return handlePageExport();
            case 'app_settings':
              return handleOpenAppSettings();
            case 'documents':
              return window.open('https://help.vedabuilder.com/en/', '_blank');
          }
        }}
      >
        <BoxCenter onClick={() => {}}>
          <FontAwesome type="far" name="ellipsis-v" size={18} color="gray8" colorHover="primary" />
        </BoxCenter>
      </Dropdown>
    </View>
  );
};

export default LeftBarHeader;
