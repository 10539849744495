import { megaMenuApiController } from 'services/MegaMenuService';
import { userApis } from 'services/UserService/apis';
import { ProductSection } from 'types/Sections';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const publishAtomToProduct = async (section: ProductSection) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const searchResponse = await megaMenuApiController.product.adminApi.mega_menu.getProductsWithParentCommandIds({
      parentCommandIds: section.parentCommandId,
      cacheVersion: lastCache,
    });
    const itemExisted = searchResponse.info.find(item => item.parentCommandId === section.parentCommandId);
    if (!!itemExisted) {
      const response = await megaMenuApiController.product.adminApi.mega_menu.updateProduct({
        section: {
          ...itemExisted,
          category: section.category,
          planHandle: section.planHandle ?? itemExisted.planHandle, // FIXME: Đang chưa có
          currentVersion: section.currentVersion,
          pageTypes: section.pageTypes,
        },
      });
      await userApis.user.adminApi.updateVedaCacheVersion();
      return response;
    } else {
      const response = await megaMenuApiController.product.adminApi.mega_menu.createProduct({
        section: {
          ...section,
          commandId: undefined,
        },
      });
      await userApis.user.adminApi.updateVedaCacheVersion();
      return response;
    }
  }
  throw new RoleException();
};
