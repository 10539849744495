import { partnerAppsSlice } from 'containers/Admin/AppsPartner/store/partnerAppsSlice';
import { PartnerCategory, PartnerItem } from 'services/RecommednApps/types';
import { createAsyncAction, createDispatchAsyncAction, createDispatchAction } from 'wiloke-react-core/utils';

export const getPartnerApps = createAsyncAction([
  '@Dashboard/getPartnerApps/request',
  '@Dashboard/getPartnerApps/success',
  '@Dashboard/getPartnerApps/failure',
])<{ categoryId?: number; search?: string }, { data: PartnerItem[] }, undefined>();

export const loadMorePartnerApps = createAsyncAction([
  '@Dashboard/loadMorePartnerApps/request',
  '@Dashboard/loadMorePartnerApps/success',
  '@Dashboard/loadMorePartnerApps/failure',
])<{ categoryId?: number; search?: string }, { data: PartnerItem[] }, undefined>();

export const getPartnerAppCategories = createAsyncAction([
  '@Dashboard/getPartnerAppCategories/request',
  '@Dashboard/getPartnerAppCategories/success',
  '@Dashboard/getPartnerAppCategories/failure',
])<undefined, { data: PartnerCategory[] }, undefined>();

export const { setPartnerAppCategory, setSearchPartnerApp } = partnerAppsSlice.actions;

export const useGetPartnerApps = createDispatchAsyncAction(getPartnerApps);
export const useGetPartnerAppCategories = createDispatchAsyncAction(getPartnerAppCategories);
export const useSetPartnerAppCategory = createDispatchAction(setPartnerAppCategory);
export const useLoadMorePartnerApps = createDispatchAsyncAction(loadMorePartnerApps);
export const useSetSearchPartnerApp = createDispatchAction(setSearchPartnerApp);
