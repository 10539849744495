import { sectionApiController } from 'services/SectionService';
import { adapterGetSection } from 'services/SectionService/Adapters/adapterGetSection';
import { AdminSection } from 'types/Sections';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const getAtomSection = async (commandId: string) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const atomResponse = await sectionApiController.atom.adminApi.sections.getAtom({ commandId, cacheVersion: lastCache });
    const productResponse = await sectionApiController.product.adminApi.sections.getProductsWithParentCommandIds({
      parentCommandIds: atomResponse.commandId,
      cacheVersion: lastCache,
    });
    const withAdapter = adapterGetSection(atomResponse) as AdminSection;
    return {
      ...withAdapter,
      vedaProductRepresent: productResponse.info.find(item => item.parentCommandId === atomResponse.commandId),
    };
  }
  const response = await sectionApiController.atom.publishApi.sections.getAtom({ commandId, cacheVersion: lastCache });
  return adapterGetSection(response.data.info) as AdminSection;
};
