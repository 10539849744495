import { AsyncComponent, Button, VedaLoadingItem } from '@wiloke/ui';
import useIsTabVisible from 'containers/ModalInstallTheme/useIsTabVisible';
import { useSelector } from 'react-redux';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { Space, Text, useTheme, View } from 'wiloke-react-core';
import { ID_MODAL_INSTALL_THEME_SHOPIFY } from './constants';
import { sliceModalInstallThemeSelector, useSetInstallThemeProduct, useSetVisibleInstallShopifyTheme } from './slice';
import { ReactNode, useEffect, useState } from 'react';
import { themeBuilderSelector } from 'store/selectors';
import { useCheckThemeIsInstall, useImportThemeAtomToClient, useValidateBuiltinTheme } from 'containers/Admin/ThemeBuilder/ThemeTemplates/actions';
import { StepDownloadTheme } from './components/StepDownloadTheme';
import { StepInstallThemePendingShopify } from './components/StepInstallThemePendingShopify';
import { StepInstallThemeSuccess } from './components/StepInstallThemeSuccess';
import { useHistory } from 'react-router';
import { Step, StepModal } from 'components/StepModal';
import { useSocketForImportThemeAtomToClientServiceNPageInThemeClient } from 'hooks/useSocket/useSocketForImportThemeAtomToClientServiceNPageInThemeClient';
import { notification } from 'antd';
import { i18n } from 'translation';
import axios from 'axios';
import { composeShopName } from 'utils/functions/composeShopName';

import oops from 'assets/sorry.png';
import * as styles from './styles';
import { VideoCart } from 'containers/ModalInstallTheme/components/VideoCard';
import { Highlight } from 'components/Highlight';

const getFileName = (url: string) => {
  const regex = /([^/]+\.zip)(\?.*)?$/;
  const match = url.match(regex);

  if (match) {
    const filename = match[1];
    return filename;
  } else {
    return url.substring(url.lastIndexOf('/') + 1);
  }
};

// TODO: i18n
export const ModalInstallThemeShopify = () => {
  const { visibleInstallShopifyTheme, themeProduct } = useSelector(sliceModalInstallThemeSelector);
  const { checkThemeIsInstallStatus, importStatus, validateBuiltinThemeStatus, validatedShopifyThemeId } = useSelector(
    themeBuilderSelector.templates,
  );

  const { shopName } = getUserInfo();
  const setVisible = useSetVisibleInstallShopifyTheme();
  const setInstallThemeProduct = useSetInstallThemeProduct();
  const isChangeVisible = useIsTabVisible();
  const checkThemeIsInstall = useCheckThemeIsInstall();
  const history = useHistory();
  const { connect, disconnect, statusSocketConnection } = useSocketForImportThemeAtomToClientServiceNPageInThemeClient();
  const importThemeAtomToClient = useImportThemeAtomToClient();
  const validateBuiltinTheme = useValidateBuiltinTheme();

  const [_downloadSucceed, setDownloadSucceed] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [step, setStep] = useState<Step | undefined>(undefined);
  const { colors } = useTheme();

  useEffect(() => {
    if (visibleInstallShopifyTheme && themeProduct && step && step.id === 'ID_MODAL_INSTALL_THEME_SHOPIFY_1') {
      validateBuiltinTheme.request({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleInstallShopifyTheme, themeProduct, step]);

  useEffect(() => {
    if (isChangeVisible && themeProduct) {
      if (step && step.id === 'ID_MODAL_INSTALL_THEME_SHOPIFY_2') {
        checkThemeIsInstall.request({
          commandId: themeProduct.commandId,
          themeName: themeProduct.label,
          group: 'built_in',
          zipUrl: themeProduct.zipUrl,
          onFulfill: () => {
            StepModal.getId(ID_MODAL_INSTALL_THEME_SHOPIFY).onNavigate(handlers => {
              handlers.next();
            });
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeVisible, themeProduct, step]);

  useEffect(() => {
    if (visibleInstallShopifyTheme && themeProduct && validatedShopifyThemeId && step && step.id === 'ID_MODAL_INSTALL_THEME_SHOPIFY_3') {
      connect({
        onSuccess: () => {
          importThemeAtomToClient.request({
            group: 'built_in',
            themeProductCommandId: themeProduct.commandId,
            shopifyThemeId: validatedShopifyThemeId,
            onFulfill() {
              disconnect({});
            },
          });
        },
        onError: () => {
          notification.error({
            message: i18n.t('publish_shopify.init_sync_error.message'),
            description: i18n.t('publish_shopify.init_sync_error.description'),
          });
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, themeProduct, visibleInstallShopifyTheme, validatedShopifyThemeId]);

  const download = async (uri: string) => {
    setIsDownloading(true);
    try {
      const response = await axios({
        url: uri,
        method: 'GET',
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.download = getFileName(uri);
      link.href = url;
      link.click();
      setDownloadSucceed(true);
      setIsDownloading(false);
    } catch (error) {
      setDownloadSucceed(false);
      setIsDownloading(false);
      throw new Error((error as Error).message);
    }
  };

  const handleClickDownload = () => {
    if (themeProduct) {
      const decodedURI = decodeURIComponent(decodeURIComponent(themeProduct.zipUrl));
      download(decodedURI);

      StepModal.getId(ID_MODAL_INSTALL_THEME_SHOPIFY).onNavigate(handlers => {
        handlers.next();
      });
    }
  };

  const handleClickLink2 = () => {
    const url = `https://admin.shopify.com/store/${composeShopName(shopName)}/themes`;
    window.open(url, '_blank');
  };

  const handleClickLink1 = () => {
    if (themeProduct?.documentUrl) {
      window.open(themeProduct.documentUrl, '_blank');
    } else {
      notification.warning({
        message: `documentUrl not exist in theme ${themeProduct?.label}`,
      });
    }
  };

  const handleClickNextStep = () => {
    if (themeProduct) {
      checkThemeIsInstall.request({
        commandId: themeProduct.commandId,
        themeName: themeProduct.label,
        group: 'built_in',
        zipUrl: themeProduct.zipUrl,
        onFulfill: () => {
          StepModal.getId(ID_MODAL_INSTALL_THEME_SHOPIFY).onNavigate(handlers => {
            handlers.next();
          });
        },
      });
    } else {
      notification.warning({
        message: `Error, please contact with support to resolve the problem`,
      });
    }
  };

  const renderStep2IdleAndFailure = (
    <StepInstallThemePendingShopify
      themeName={themeProduct?.label}
      onClickLink1={handleClickLink1}
      onClickLink2={handleClickLink2}
      onNextStep={handleClickNextStep}
    />
  );

  const handleClose = () => {
    setVisible(false);
    setInstallThemeProduct(undefined);
    StepModal.getId(ID_MODAL_INSTALL_THEME_SHOPIFY).onNavigate(handlers => {
      handlers.go('ID_MODAL_INSTALL_THEME_SHOPIFY_1');
    });
    setStep(undefined);
  };

  const handleCustomize = () => {
    history.push('/theme', {
      justInstalled: true,
    });
    setVisible(false);
    setInstallThemeProduct(undefined);
    StepModal.getId(ID_MODAL_INSTALL_THEME_SHOPIFY).onNavigate(handlers => {
      handlers.go('ID_MODAL_INSTALL_THEME_SHOPIFY_1');
    });
  };

  const handleSkip = () => {
    StepModal.getId(ID_MODAL_INSTALL_THEME_SHOPIFY).onNavigate(handlers => {
      handlers.next();
    });
    if (themeProduct) {
      checkThemeIsInstall.request({
        commandId: themeProduct.commandId,
        themeName: themeProduct.label,
        group: 'built_in',
        zipUrl: themeProduct.zipUrl,
        onFulfill: () => {
          StepModal.getId(ID_MODAL_INSTALL_THEME_SHOPIFY).onNavigate(handlers => {
            handlers.next();
          });
        },
      });
    }
  };

  const retryImportTheme = () => {
    if (themeProduct) {
      connect({
        onSuccess: () => {
          importThemeAtomToClient.request({
            group: 'built_in',
            themeProductCommandId: themeProduct.commandId,
            shopifyThemeId: validatedShopifyThemeId,
            onFulfill() {
              disconnect({});
            },
          });
        },
        onError: () => {
          notification.error({
            message: i18n.t('publish_shopify.init_sync_error.message'),
            description: i18n.t('publish_shopify.init_sync_error.description'),
          });
        },
      });
    }
  };

  const renderVideoByStep: Record<string, ReactNode> = {
    ID_MODAL_INSTALL_THEME_SHOPIFY_1: <VideoCart embedId="zV_9r2tTHLM" href="https://youtu.be/vYc2pwqPNds" />,
    ID_MODAL_INSTALL_THEME_SHOPIFY_2: <VideoCart embedId="D9MlIgIzt7E" href="https://youtu.be/vYc2pwqPNds?t=11" />,
    ID_MODAL_INSTALL_THEME_SHOPIFY_3: <VideoCart embedId="1Sf2vepJGcY" href="https://youtu.be/vYc2pwqPNds?t=50" />,
  };

  return (
    <StepModal
      id={ID_MODAL_INSTALL_THEME_SHOPIFY}
      visible={visibleInstallShopifyTheme}
      onClose={handleClose}
      headerText="Install Theme"
      onChange={result => {
        setStep(result.step);
      }}
      variant="style2"
      data={[
        {
          id: 'ID_MODAL_INSTALL_THEME_SHOPIFY_1',
          heading: 'Download Theme',
          content: (
            <AsyncComponent
              status={validateBuiltinThemeStatus}
              Failure={
                <View css={{ textAlign: 'center', margin: '0 auto' }}>
                  <Text size={16} fontFamily="secondary">
                    Your plan does not support this feature. Please upgrade to a higher plan.
                  </Text>
                  <Space size={20} />
                  <Button
                    size="extra-small"
                    backgroundColor="primary"
                    radius={6}
                    onClick={() => {
                      history.push('/pricing');
                      handleClose();
                    }}
                  >
                    {i18n.t('pricing.upgrade')}
                  </Button>
                </View>
              }
              Success={
                <StepDownloadTheme
                  isDownloading={isDownloading}
                  themeName={themeProduct?.label}
                  onDownload={handleClickDownload}
                  onSkip={handleSkip}
                />
              }
            />
          ),
          stepDescription: 'Download Theme',
        },
        {
          id: 'ID_MODAL_INSTALL_THEME_SHOPIFY_2',
          content: (
            <AsyncComponent
              status={checkThemeIsInstallStatus}
              Success={<StepInstallThemeSuccess onBack={handleClose} onCustomize={handleCustomize} themeName={themeProduct?.label} />}
              Request={
                <View
                  css={{
                    padding: '50px 10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <VedaLoadingItem />
                  <Space size={20} />
                  <View tagName="h3">Checking...</View>
                  <Space size={20} />
                  <Highlight query={themeProduct?.label ?? ''} highlightCss={{ color: colors.dark, fontWeight: '500' }}>
                    {`You haven't installed the theme ${themeProduct?.label ?? ''} to Shopify.`}
                  </Highlight>
                  <View>
                    Click{' '}
                    <View
                      tagName="span"
                      color="primary"
                      css={{ fontWeight: '500', cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={handleClickLink1}
                    >
                      here
                    </View>{' '}
                    to install your downloaded theme and come back here to continue. Otherwise, click{' '}
                    <View
                      tagName="span"
                      color="primary"
                      css={{ fontWeight: '500', cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={handleClickLink2}
                    >
                      here
                    </View>{' '}
                    to go to Shopify Themes area.
                  </View>
                </View>
              }
              Idle={renderStep2IdleAndFailure}
              Failure={renderStep2IdleAndFailure}
            />
          ),
          stepDescription: 'Upload Theme',
        },
        {
          id: 'ID_MODAL_INSTALL_THEME_SHOPIFY_3',
          content: (
            <View>
              {(statusSocketConnection === 'loading' || importStatus[themeProduct?.commandId ?? ''] === 'loading') && (
                <View
                  css={{
                    padding: '50px 10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <VedaLoadingItem />
                  <Space size={20} />
                  <View tagName="h3">Importing...</View>
                  <Space size={10} />
                  <Text size={13} color="gray5">
                    (The import process may take a few minutes, please do not close the browser until the data is imported.)
                  </Text>
                </View>
              )}

              {importStatus[themeProduct?.commandId ?? ''] === 'failure' && (
                <View
                  css={{
                    maxWidth: '600px',
                    minWidth: '200px',
                    margin: 'auto',
                    padding: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <img src={oops} style={{ maxWidth: '270px' }} />
                  <View css={{ marginTop: '8px' }}>
                    <Button size="extra-small" backgroundColor="primary" radius={6} onClick={retryImportTheme}>
                      {i18n.t('builderPage.retry')}
                    </Button>
                  </View>
                </View>
              )}

              {importStatus[themeProduct?.commandId ?? ''] === 'success' && (
                <StepInstallThemeSuccess onBack={handleClose} onCustomize={handleCustomize} themeName={themeProduct?.label} />
              )}
            </View>
          ),
          stepDescription: 'Import Data',
        },
      ]}
      modalBodyCss={styles.modalBody}
      ExtraContent={step ? renderVideoByStep[step.id] : null}
      modalContentCss={{ padding: '0px' }}
    />
  );
};
