import { Consts } from 'utils/constants/constants';

export const builderPage = {
  back_to_dashboard2: 'Quay lại bảng điều khiển',
  page_under_maintaince: 'Trang đang bảo trì',
  page_error: 'Đã xảy ra lỗi',
  get_support: 'Liên hệ hỗ trợ',
  modal_login_shopify: {
    okText: 'Tôi đã đăng nhập',
    description: 'Đừng quên đăng nhập vào cửa hàng Shopify của bạn để có thể xem bản xem trước',
  },
  shopify_entity_non_exist: {
    title: 'Có sự thay đổi tại shop của bạn',
    collection: 'Bộ sưu tập không tồn tại hoặc đã bị thay đổi. Vui lòng chọn lại trước khi tiếp tục',
    product: 'Sản phẩm không tồn tại hoặc đã bị thay đổi. Vui lòng chọn lại trước khi tiếp tục',
    blog: 'Blog không tồn tại hoặc đã bị thay đổi. Vui lòng chọn lại trước khi tiếp tục',
  },
  fields: {
    reorder: 'Cách sắp xếp này chỉ giúp bạn quản lý các cài đặt bên dưới chứ không ảnh hưởng đến nội dung của trang.',
  },
  schema: {
    settings: {
      title: 'Cài đặt',
      text: '',
    },
    blocks: {
      title: 'Blocks',
      text: '',
    },
    error: {
      existed: '"%% name %%" được định nghĩa trong schema',
      is_shopify_variable: '"%% name %%" là biến shopify',
    },
  },
  theme_settings: {
    general: {
      title: 'Chung',
      text: 'Cài đặt chung cho chủ đề hiện tại',
    },
    colors: {
      title: 'Màu sắc',
      text: 'Quản lý các biến thể màu sắc',
      content: {
        title: 'Màu sắc tùy chỉnh cho chủ đề',
        text: 'Bạn có thể chỉnh sửa hoặc thêm màu bên dưới',
      },
    },
    color_schemes: {
      title: 'Bảng màu',
      text: 'Quản lý bảng màu',
      content: {
        title: 'Tạo bảng phối màu của riêng bạn',
        text: 'Thể hiện sự sáng tạo và thiết kế các bảng màu độc đáo cho chủ đề của bạn.',
      },
    },
    fonts: {
      title: 'Phông chữ',
      text: 'Quản lý tất cả các biến thể phông chữ',
      content: {
        title: 'Phông chữ tùy chỉnh',
        text: 'Bạn có thể chỉnh sửa hoặc thêm phông chữ bên dưới',
      },
    },
    layout: {
      title: 'Bố cục',
      text: 'Quản lý bố cục cho trang web',
    },
    vendors: {
      title: 'Vendors',
      text: 'Thêm Css & Javascript bên ngoài cho chủ đề',
    },
    scss: {
      title: 'SCSS (CSS)',
      text: 'Thêm SCSS cho chủ đề',
    },
    js: {
      title: 'Javascript',
      text: 'Thêm Javascript cho chủ đề',
    },
    content: {
      colors: {
        title: 'Màu tùy chỉnh',
        text: 'Bạn có thể chỉnh sửa hoặc thêm màu bên dưới (Lưu ý tên biến được sử dụng khi bạn chỉnh sửa mã CSS)',
      },
      fonts: {
        title: 'Phông chữ tùy chỉnh',
        text: 'Bạn có thể chỉnh sửa hoặc thêm các phông chữ bên dưới (Lưu ý tên biến được sử dụng khi bạn chỉnh sửa mã CSS)',
      },
    },
  },
  page_settings: {
    general: {
      title: 'Cài đặt chung',
      text: 'Cài đặt chung cho trang hiện tại',
    },
    vendors: {
      title: 'Vendors',
      text: 'Thêm Css & Javascript bên ngoài',
    },
    scss: {
      title: 'SCSS (CSS)',
      text: 'Thêm SCSS cho trang',
    },
    js: {
      title: 'Javascript',
      text: 'Thêm Javascript cho trang',
    },
    import: {
      title: 'Nhập tệp',
      text: `Nhập tệp *.${Consts.AppName} để tiếp tục chỉnh sửa trang hiện tại`,
      select_options: 'Chọn các tùy chọn để nhập',
      error: 'Tệp của bạn bị hỏng',
      upload_successfully: 'Tải lên thành công',
      upload_failed: 'Tải lên thất bạn',
      options: {
        page_data: 'Dữ liệu trang',
        page_settings: 'Cài đặt trang',
        theme_settings: 'Cài đặt chủ đề',
      },
    },
    general_settings: {
      meta_title_placeholder: 'Tiêu đề meta sẽ được hiển thị ở đây',
      meta_description_placeholder: 'Mô tả meta sẽ được hiển thị ở đây',
      general: {
        title: 'General',
        text: 'Cài đặt chung cho trang hiện tại',
        name: 'Tên trang',
        enable_header_footer: 'Bật đầu trang & chân trang của chủ đề',
      },
      seo: {
        title: 'SEO',
        text: 'Fill out necessary information for search engine listing',
        url_slug: {
          title: 'URL slug',
          text: 'Lorem ipsum dolor sit amet',
        },
        meta_title: {
          title: 'Meta title',
          text: 'Lorem ipsum dolor sit amet',
        },
        meta_description: {
          title: 'Meta description',
          text: 'Lorem ipsum dolor sit amet',
        },
      },
      social_share: {
        title: 'Ảnh chia sẻ',
        text: 'Lorem ipsum dolor sit amet',
      },
    },
  },
  layout_settings: {
    container_width: {
      title: 'Chiều rộng vùng chứa',
      text: 'Đặt chiều rộng mặc định của vùng nội dung (Mặc định: %% size %%px)',
    },
    container_gap: {
      title: 'khoảng cách vùng chứa',
      text: 'Đặt khoảng cách cột mặc định (Mặc định: %% size %%px)',
    },
    column_gap_vertical: {
      title: 'Khoảng cách cột dọc',
      text: 'Đặt khoảng cách mặc định giữa các phần tử (Mặc định: %% size %%px)',
    },
    column_gap_horizontal: {
      title: 'Khoảng cách cột ngang',
      text: 'Đặt khoảng cách mặc định giữa các phần tử (Mặc định: %% size %%px)',
    },
  },
  theme_general_settings: {
    preloader: {
      title: 'Tải trước',
      text: '',
      testing: 'Chạy sau 2 giây sau khi bạn nhấp vào nút Ok.',
    },
    favicon: {
      title: 'Favicon',
      text: '',
    },
  },
  mega_menu: {
    choose_setting: 'Chọn cài đặt',
    warning: 'Bạn không thể thêm các phần tử con vào mục này khi bật mega menu',
    icon: 'Icon %% text %%',
    hot_spot: 'Hot spot',
    navigation: 'Điều hướng',
  },
  save_for_builder: {
    preview_image: 'Hình ảnh xem trước',
    page_types: 'Loại trang',
    add_category: 'Thêm thể loại',
    please_enter_item: 'Vui lòng nhập mục',
    can_add_multi: 'Có thể thêm nhiều hơn',
    enable_position: 'Kích hoạt vị trí',
    addons_type: 'Loại addon',
    logo: 'Logo',
    detail: 'Chi tiết',
    save_page: 'Lưu trang',
    create_new_page: 'Tạo một trang mới',
    save_theme: 'Lưu chủ đề',
    create_new_theme: 'Tạo một chủ đề mới',
    link_preview: 'Xem trước liên kết',
    create_update_section_page_type:
      'Các section được thêm vào tùy thuộc vào loại trang. Ví dụ: Khi tạo trang Bài viết, khi chọn section mẫu sẽ không hiển thị các section dành cho sản phẩm/bộ sưu tập.v.v.',
  },
  choose_template: {
    delete_section: 'Bạn có chắc chắn muốn xóa section này?',
    import_section_failed: 'Dữ liệu của tệp này không phải là một section',
    cannot_use_section: 'Không thể sử dụng section này trong loại trang này - loại trang hiện tại: %% type %%',
    import_addons_failed: 'Dữ liệu của tệp này không phải là một addon',
    import_section_addons_failed: 'Dữ liệu của tệp này không phải là một section của addons',
  },
  delete_addons_to_keep: 'Section này chứa các addons sau. Kiểm tra xem bạn có muốn xóa chúng khỏi tab addons không.',
  duplicate_addons_to_keep: 'Section này chứa các addons sau. Nhấp vào hộp kiểm để giữ lại nó',
  back_to_dashboard: 'Trang này có các thay đổi chưa được lưu. Nếu bạn thoát ngay bây giờ, những thay đổi này sẽ bị mất.',
  remove_addons_in_sections:
    'Các addons này đang được sử dụng trong các section này. Tích vào nó nếu bạn muốn xóa các addons đã sử dụng trong section đó',
  extracting: 'Kết quả đang được trích xuất',
  saving: 'Đang lưu',
  publish_override: 'Xuất bản và ghi đè',
  publish_not_override: 'Xuất bản nhưng không ghi đè',
  publish_ask_text: 'Dữ liệu của bạn sẽ được cập nhật lên Shopify. Dữ liệu này sẽ ảnh hưởng đến các trang %% pageType %% khác. Bạn vẫn muốn ghi đè?',
  save_and_publish: 'Lưu và xuất bản',
  add_more_language: 'Thêm nhiều ngôn ngữ',
  select_language: 'Chọn ngôn ngữ bạn muốn chỉnh sửa',
  change_file_name: 'Thay đổi tên tệp',
  translation_file: 'Tệp bản dịch',
  must_have_section: 'Trang của bạn thiếu section chính, làm ơn hãy thêm ít nhất 1 section chính vào trang của bạn',
  save_as_draft: 'Lưu dưới dạng bản nháp',
  save_to_my_page: 'Lưu vào trang của tôi',
  use: 'Use',
  lazy_load_image: 'Lazy load cho hình ảnh',
  coming_soon: 'Sắp ra mắt',
  in: 'trong',
  out: 'ngoài',
  only_have_one: 'Chỉ thêm 1',
  cannot_duplicate: 'Không thể nhân bản',
  override_content: 'Dữ liệu này sẽ ảnh hưởng đến các trang %% pageType %% khác. Bạn vẫn muốn ghi đè?',
  override: 'Ghi đè',
  not_override: 'Không ghi đè',
  confirm_override: 'Xác nhận ghi đè',
  network_error: 'Lỗi mạng',
  retry: 'Retry',
  compose_email: 'Nhấp vào icon để soạn email',
  select_url: 'Nhấp vào icon để chọn URL',
  send_email: 'Gửi email',
  phone_number: 'Số điện thoại',
  custom_url: 'URL tùy chỉnh',
  search: 'Tìm kiếm %% text %%',
  link_shopify: 'Liên kết Shopify',
  js_hook_state: '// Js này hướng đến các mẫu section nhưng chỉ hoạt động ở trình tạo\n// Có thể sử dụng chức năng: hiddenFieldAction, redirectTo',
  built_in: 'Dựng sẵn',
  third_party: 'Bên thứ ba',
  atom: 'Atom',
  draft: 'Draft',
  loaded: 'đã tải',
  preset: 'Cài đặt trước %% text %%',
  exit_without_saving: 'Thoát mà không lưu',
  save_for: 'Lưu cho %% text %%',
  builder: 'builder',
  dev: 'dev',
  must_create_changelog: 'Phải tạo nhật ký thay đổi trước',
  increase_version_to_update_section: 'Nâng cấp phiên bản để cập nhật section/addon',
  update_version: {
    deleted_old_fields: '%% text %% sẽ bị xóa trong phiên bản mới',
    added_new_fields: 'New %% text %% sẽ được thêm vào trong phiên bản mới',
    updated_fields: '%% text %% sẽ được cập nhật trong phiên bản mới',
  },
  data_request: {
    loading: 'Đang lấy dữ liệu',
    success: 'Lấy dữ liệu thành công',
    failed: 'Lấy dữ liệu thất bại',
  },
  data_not_selected: '%% text %% chưa được chọn',
  move_to: {
    previous: 'Di chuyển đến vị trí trước đó',
    next: 'Di chuyển đến vị trí tiếp theo',
    header: 'Di chuyển đến đầu trang',
    footer: 'Di chuyển đến chân trang',
    main: 'Di chuyển đến thân trang',
    disable: 'Không thể di chuyển',
  },
  delete_atom: '%% text %% này đã được publish, xóa ở admin đồng nghĩa với việc xóa ở product',
  select_pages_to_sync: 'Chọn các trang để đồng bộ lên shopify, các trang còn lại vẫn được lưu',
  delete_from: 'Xóa khỏi %% text %%',
  uninstall: 'Gỡ cài đặt',
  upgrade_plan: 'Làm ơn hãy nâng lên gói %% function_plan %% để sử dụng chức năng này.',
  scroll_to_section: 'Cuộn đến section',
  buy_now: 'Mua ngay',
  buy_now_condition: 'Chỉ dùng được ở trang Sản Phẩm',
  click_to_add_main: 'Bấm vào đây để thêm',
} as const;
