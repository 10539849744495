import { sectionApiController } from 'services/SectionService';
import { adapterGetSection } from 'services/SectionService/Adapters/adapterGetSection';
import { ProductSection } from 'types/Sections';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const getProductSection = async (commandId: string) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const response = await sectionApiController.product.adminApi.sections.getProduct({ commandId, cacheVersion: lastCache });
    return adapterGetSection(response) as ProductSection;
  }
  const response = await sectionApiController.product.userApi.sections.getProduct({ commandId, cacheVersion: lastCache });
  return adapterGetSection(response) as ProductSection;
};
