import { addonApiController } from 'services/AddonService';
import { adapterGetAddon } from 'services/AddonService/Adapters/adapterGetAddon';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const updateVersion = async ({ atomCommandId }: { atomCommandId: string }) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const response = await addonApiController.product.adminApi.addons.updateVersion({ commandId: atomCommandId, cacheVersion: lastCache });
    return adapterGetAddon(response);
  }
  if (role === 'dev') {
    const response = await addonApiController.product.devApi.addons.updateVersion({ commandId: atomCommandId, cacheVersion: lastCache });
    return adapterGetAddon(response);
  }
  const response = await addonApiController.product.userApi.addons.updateVersion({ commandId: atomCommandId, cacheVersion: lastCache });
  return adapterGetAddon(response);
};
