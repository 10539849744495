import { userApis } from 'services/UserService/apis';
import { AdminTheme } from 'types/Theme';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { removeEmptyKey } from 'utils/functions/removeEmptyKey';
import { handleGlobalTranslationsNColorSchemesInThemeAtom } from '../Adapter/handleGlobalTranslationsNColorSchemes';
import { themeApis } from '../apis';

interface UpdateThemeAtom {
  theme: Required<
    Pick<
      Parameters<typeof themeApis.atom.adminApi.theme.updateAtom>[0]['theme'],
      | 'commandId'
      | 'addonCommandIds'
      | 'headerSectionCommandIds'
      | 'footerSectionCommandIds'
      | 'themeSettings'
      | 'featuredImage'
      | 'globalJs'
      | 'globalScss'
      | 'vendors'
    >
  >;
}

export const updateThemeAtom = async ({ theme }: UpdateThemeAtom): Promise<AdminTheme> => {
  const { role, id } = getUserInfo();

  if (role === 'admin') {
    const response = await themeApis.atom.adminApi.theme.updateAtom({
      theme: {
        ...theme,
        lastModifiedBy: id,
        themeSettings: {
          ...theme.themeSettings,
          globalTranslations: {
            ...theme.themeSettings.globalTranslations,
            translation: removeEmptyKey(theme.themeSettings.globalTranslations.translation),
          },
        },
      },
    });
    await userApis.user.adminApi.updateVedaCacheVersion();
    return handleGlobalTranslationsNColorSchemesInThemeAtom(response.info);
  }

  throw new RoleException();
};
