import { Button, MyModal } from '@wiloke/ui';
import SimpleTabs from 'components/SimpleTabs';
import { ActionExportVariant } from 'packages/ExtractThemeToFileForSync/@types/VariantOfActionExport';
import { selectExtractThemeToFileForSync } from 'packages/ExtractThemeToFileForSync/selectors';
import { useExtractThemeToFileForSync, useInitSessionOfProcesses, useSetModalExtract } from 'packages/ExtractThemeToFileForSync/store/actions';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { View } from 'wiloke-react-core';
import { ThemeInteracting } from '../../@types/ThemeInteracting';
import { SectionInfomation, useGetSections } from '../../hooks/useGetSections';
import { ModalWarningDuplicateCommandId } from '../ModalWarningDuplicateCommandId';
import { Contstraints } from './components/Constraints';
import { Processing } from './components/Processing';
import { ResolveSecondConstraint } from './components/ResolveSecondConstraint';
import { ResolveThirdConstraint } from './components/ResolveThirdConstraint';

interface ModalExtractThemeToFileForSyncProps {
  onSuccess: () => void;
}

export const ModalExtractThemeToFileForSync = ({ onSuccess }: ModalExtractThemeToFileForSyncProps) => {
  const [actionExportVariant, setActionExportVariant] = useState<ActionExportVariant>('Default');
  const [selectedSections, setSelectedSections] = useState<SectionInfomation[]>([]);
  const [isNeedReduceTotalLinesOfCode, setIsNeedReduceTotalLinesOfCode] = useState(true);

  const [openConfirmClose, setOpenConfirmClose] = useState(false);
  const [openNotifyRetryable, setOpenNotifyRetryable] = useState(false);

  const { actualProcessed, expectProcesses, modalExtractThemeToFileForSync, prevThemeExtractFailed, themeExtracting } = useSelector(
    selectExtractThemeToFileForSync,
  );

  const setModalExtractResult = useSetModalExtract();
  const extract = useExtractThemeToFileForSync();

  const initActualProcessed = useInitSessionOfProcesses();

  const { data, isError, request, cancel, isLoading: gettingSections } = useGetSections();

  const isLoading = useMemo(() => {
    return modalExtractThemeToFileForSync && !!themeExtracting;
  }, [themeExtracting, modalExtractThemeToFileForSync]);

  const isRetryable = useMemo(() => {
    return prevThemeExtractFailed?.data.theme.commandId === modalExtractThemeToFileForSync?.theme.commandId;
  }, [prevThemeExtractFailed, modalExtractThemeToFileForSync]);

  const handleExtractResult = (theme: ThemeInteracting, isRetry: boolean) => {
    extract.request({
      isRetry,
      actionExportVariant,
      dataInteracting: theme,
      isNeedReduceTotalLinesOfCode: isNeedReduceTotalLinesOfCode && actionExportVariant === 'ExportForEnvato',
      sectionsWithLinkListSetting: selectedSections,
      onSuccess() {
        setOpenConfirmClose(false);
        setModalExtractResult(undefined);
        onSuccess();
      },
      onFailure(themeExtracting) {
        setOpenConfirmClose(false);
        setModalExtractResult(themeExtracting);
      },
    });
  };

  useEffect(() => {
    cancel();
    if (modalExtractThemeToFileForSync) {
      setSelectedSections([]);
      setActionExportVariant('Default');
      setIsNeedReduceTotalLinesOfCode(true);
      request({
        themeCommandId: modalExtractThemeToFileForSync.theme.commandId,
        pageCommandIds: modalExtractThemeToFileForSync.theme.pageCommandIds,
        entityVariant: modalExtractThemeToFileForSync.entityVariant,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalExtractThemeToFileForSync]);

  useEffect(() => {
    // Đang mở thằng mới chứ không mở thằng cũ hoặc thằng cũ lỗi
    if (modalExtractThemeToFileForSync && prevThemeExtractFailed?.data.theme.commandId !== modalExtractThemeToFileForSync?.theme.commandId) {
      initActualProcessed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalExtractThemeToFileForSync]);

  const renderTabExportForEnvato = () => {
    if (isError && modalExtractThemeToFileForSync) {
      return (
        <View>
          <Button
            onClick={() => {
              request({
                themeCommandId: modalExtractThemeToFileForSync.theme.commandId,
                pageCommandIds: modalExtractThemeToFileForSync.theme.pageCommandIds,
                entityVariant: modalExtractThemeToFileForSync.entityVariant,
              });
            }}
          >
            Retry
          </Button>
        </View>
      );
    }
    if (gettingSections) {
      return <View tagName="h2">Loading...</View>;
    }
    return (
      <View>
        <Contstraints />
        <ResolveSecondConstraint
          data={data}
          selectedSections={selectedSections}
          setSelectedSections={setSelectedSections}
          themeExtracting={themeExtracting}
        />
        <ResolveThirdConstraint
          isNeedReduceTotalLinesOfCode={isNeedReduceTotalLinesOfCode}
          setIsNeedReduceTotalLinesOfCode={setIsNeedReduceTotalLinesOfCode}
        />
      </View>
    );
  };

  const renderTabExportDefault = () => {
    return (
      <View>
        <View tagName="h5">Lưu theo kiểu shopify không cần tuân thủ một luật bên thứ 3 nào cả. Lưu thành hình là OK</View>
        {isRetryable && <View color="danger">Có lỗi xảy ra. Vui lòng thử lại</View>}
      </View>
    );
  };

  const renderModalConfirmClose = () => {
    return (
      <MyModal
        isVisible={openConfirmClose}
        okText="Đóng"
        cancelText="Tiếp tục chờ"
        onCancel={() => setOpenConfirmClose(false)}
        onOk={() => {
          setOpenConfirmClose(false);
          setModalExtractResult(undefined);
        }}
        headerText="Cảnh báo"
      >
        <View>
          Quá trình trích xuất kết quả đồng bộ có thể gây ra hiện tượng giật lag. Nếu có thể hãy đợi cho đến khi quá trình hoàn thành và tiếp tục công
          việc
        </View>
        <View color="danger" css={{ fontSize: '18px', fontWeight: 500, textAlign: 'center' }}>
          Bạn vẫn muốn tắt modal?
        </View>
      </MyModal>
    );
  };

  const renderModalNotifyRetryable = () => {
    if (isRetryable && prevThemeExtractFailed) {
      return (
        <MyModal
          isVisible={openNotifyRetryable}
          onCancel={() => {
            setOpenNotifyRetryable(false);
            handleExtractResult(prevThemeExtractFailed.data, false);
          }}
          onOk={() => {
            setOpenNotifyRetryable(false);
            handleExtractResult(prevThemeExtractFailed.data, true);
          }}
          headerText="Tìm thấy một bản lỗi trước đó"
          disabledCloseButton
          cancelText="Thực hiện từ đầu"
          okText="Retry"
        >
          <View>
            Tìm thấy một bản lỗi trước đó. Bạn có thể thực hiện "Retry" để tiếp tục quá trình lỗi trước đó nhằm đẩy nhanh quá trình trích xuất kết
            quar diễn ra nhanh hơn
          </View>
        </MyModal>
      );
    }
    return null;
  };

  const renderTabs = () => {
    if (isRetryable && prevThemeExtractFailed) {
      return <View>{prevThemeExtractFailed.actionExportVariant === 'ExportForEnvato' ? renderTabExportForEnvato() : renderTabExportDefault()}</View>;
    }
    return (
      <SimpleTabs
        value={actionExportVariant}
        defaultValue={actionExportVariant}
        onChange={setActionExportVariant}
        data={[
          { label: 'Đồng bộ cho Shopify', value: 'Default' },
          { label: 'Đồng bộ cho Envato', value: 'ExportForEnvato' },
        ]}
      >
        {value => <View css={{ paddingTop: '8px' }}>{value === 'ExportForEnvato' ? renderTabExportForEnvato() : renderTabExportDefault()}</View>}
      </SimpleTabs>
    );
  };

  const renderMainModal = () => {
    if (!modalExtractThemeToFileForSync) {
      return null;
    }
    return (
      <>
        <MyModal
          isVisible
          headerText={`Đồng bộ toàn bộ "${modalExtractThemeToFileForSync.theme.label}" lên shopify`}
          depsHeightRecalculation={[actionExportVariant, themeExtracting, expectProcesses]}
          isLoading={isLoading}
          okButtonDisabled={gettingSections}
          size="medium"
          okText={isRetryable ? 'Thử lại' : 'Bắt đầu'}
          cancelText="Đóng"
          onCancel={() => {
            if (!!themeExtracting) {
              setOpenConfirmClose(true);
            } else {
              setModalExtractResult(undefined);
            }
          }}
          onOk={() => {
            if (isRetryable) {
              setOpenNotifyRetryable(true);
            } else if (modalExtractThemeToFileForSync) {
              handleExtractResult(modalExtractThemeToFileForSync, false);
            }
          }}
        >
          {!!themeExtracting ? (
            <Processing actualProcessed={actualProcessed} expectProcesses={expectProcesses} isRetryable={isRetryable} />
          ) : (
            renderTabs()
          )}
        </MyModal>
        {renderModalConfirmClose()}
        {renderModalNotifyRetryable()}
      </>
    );
  };

  return (
    <>
      {renderMainModal()}
      <ModalWarningDuplicateCommandId />
    </>
  );
};

ModalExtractThemeToFileForSync.useSetModalExtract = useSetModalExtract;
