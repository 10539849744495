import { AxiosResponse } from 'axios';
import { PartnerAPI } from 'types/Partner';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface CreateParams extends PartnerAPI {}

interface ResSuccess {
  info: PartnerAPI;
  message: string;
}

export const updatePartner = async (data: CreateParams) => {
  const response: AxiosResponse<ResSuccess> = await fetchAPI.request({
    url: `${baseUrl}/${data.id}`,
    method: 'PUT',
    data,
  });
  return response.data;
};
