import { CssColorVariable } from 'types/PresetStyles';
import { v4 } from 'uuid';

export const STRAWBERRY: Array<{ name: string; cssClassName: string; colors: CssColorVariable[] }> = [
  {
    name: 'Strawberry',
    cssClassName: 'veda-color-strawberry',
    colors: [
      { id: v4(), name: '--color-primary', light: '#F14666', dark: '#F14666' },
      { id: v4(), name: '--color-secondary', light: '#EE8980', dark: '#EE8980' },
      { id: v4(), name: '--color-tertiary', light: '#FFCDAA', dark: '#FFCDAA' },
      { id: v4(), name: '--color-quaternary', light: '#9CB898', dark: '#9CB898' },
      { id: v4(), name: '--color-dark', light: '#15092F', dark: '#ffffff' },
      { id: v4(), name: '--color-gray9', light: '#241448', dark: '#F3F1F5' },
      { id: v4(), name: '--color-gray8', light: '#39285C', dark: '#E9E7EC' },
      { id: v4(), name: '--color-gray7', light: '#4D4069', dark: '#D2CEDA' },
      { id: v4(), name: '--color-gray6', light: '#6C6183', dark: '#B7B2C3' },
      { id: v4(), name: '--color-gray5', light: '#8F87A1', dark: '#8F87A1' },
      { id: v4(), name: '--color-gray4', light: '#B7B2C3', dark: '#6C6183' },
      { id: v4(), name: '--color-gray3', light: '#D2CEDA', dark: '#4D4069' },
      { id: v4(), name: '--color-gray2', light: '#E9E7EC', dark: '#39285C' },
      { id: v4(), name: '--color-gray1', light: '#F3F1F5', dark: '#241448' },
      { id: v4(), name: '--color-light', light: '#ffffff', dark: '#15092F' },
    ],
  },
  {
    name: 'Strawberry (Dark)',
    cssClassName: 'veda-color-dark-strawberry',
    colors: [
      { id: v4(), name: '--color-primary', light: '#F14666', dark: '#F14666' },
      { id: v4(), name: '--color-secondary', light: '#EE8980', dark: '#EE8980' },
      { id: v4(), name: '--color-tertiary', light: '#FFCDAA', dark: '#FFCDAA' },
      { id: v4(), name: '--color-quaternary', light: '#9CB898', dark: '#9CB898' },
      { id: v4(), name: '--color-dark', light: '#ffffff', dark: '#15092F' },
      { id: v4(), name: '--color-gray9', light: '#F3F1F5', dark: '#241448' },
      { id: v4(), name: '--color-gray8', light: '#E9E7EC', dark: '#39285C' },
      { id: v4(), name: '--color-gray7', light: '#D2CEDA', dark: '#4D4069' },
      { id: v4(), name: '--color-gray6', light: '#B7B2C3', dark: '#6C6183' },
      { id: v4(), name: '--color-gray5', light: '#8F87A1', dark: '#8F87A1' },
      { id: v4(), name: '--color-gray4', light: '#6C6183', dark: '#B7B2C3' },
      { id: v4(), name: '--color-gray3', light: '#4D4069', dark: '#D2CEDA' },
      { id: v4(), name: '--color-gray2', light: '#39285C', dark: '#E9E7EC' },
      { id: v4(), name: '--color-gray1', light: '#241448', dark: '#F3F1F5' },
      { id: v4(), name: '--color-light', light: '#15092F', dark: '#ffffff' },
    ],
  },
];
