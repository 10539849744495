import { CssColorVariable } from 'types/PresetStyles';
import { v4 } from 'uuid';

export const BEAN: Array<{ name: string; cssClassName: string; colors: CssColorVariable[] }> = [
  {
    name: 'Bean',
    cssClassName: 'veda-color-bean',
    colors: [
      { id: v4(), name: '--color-primary', light: '#FF0C9B', dark: '#FF0C9B' },
      { id: v4(), name: '--color-secondary', light: '#24DBF4', dark: '#24DBF4' },
      { id: v4(), name: '--color-tertiary', light: '#8667DE', dark: '#8667DE' },
      { id: v4(), name: '--color-quaternary', light: '#06E82A', dark: '#06E82A' },
      { id: v4(), name: '--color-dark', light: '#220C19', dark: '#ffffff' },
      { id: v4(), name: '--color-gray9', light: '#493842', dark: '#f0edef' },
      { id: v4(), name: '--color-gray8', light: '#493842', dark: '#e7e1e4' },
      { id: v4(), name: '--color-gray7', light: '#5C4C55', dark: '#d8d2d6' },
      { id: v4(), name: '--color-gray6', light: '#70636B', dark: '#afa6ab' },
      { id: v4(), name: '--color-gray5', light: '#83787E', dark: '#83787E' },
      { id: v4(), name: '--color-gray4', light: '#afa6ab', dark: '#70636B' },
      { id: v4(), name: '--color-gray3', light: '#d8d2d6', dark: '#5C4C55' },
      { id: v4(), name: '--color-gray2', light: '#e7e1e4', dark: '#493842' },
      { id: v4(), name: '--color-gray1', light: '#f0edef', dark: '#493842' },
      { id: v4(), name: '--color-light', light: '#ffffff', dark: '#220C19' },
    ],
  },
  {
    name: 'Bean (Dark)',
    cssClassName: 'veda-color-dark-bean',
    colors: [
      { id: v4(), name: '--color-primary', light: '#FF0C9B', dark: '#FF0C9B' },
      { id: v4(), name: '--color-secondary', light: '#24DBF4', dark: '#24DBF4' },
      { id: v4(), name: '--color-tertiary', light: '#8667DE', dark: '#8667DE' },
      { id: v4(), name: '--color-quaternary', light: '#06E82A', dark: '#06E82A' },
      { id: v4(), name: '--color-dark', light: '#ffffff', dark: '#220C19' },
      { id: v4(), name: '--color-gray9', light: '#f0edef', dark: '#493842' },
      { id: v4(), name: '--color-gray8', light: '#e7e1e4', dark: '#493842' },
      { id: v4(), name: '--color-gray7', light: '#d8d2d6', dark: '#5C4C55' },
      { id: v4(), name: '--color-gray6', light: '#afa6ab', dark: '#70636B' },
      { id: v4(), name: '--color-gray5', light: '#83787E', dark: '#83787E' },
      { id: v4(), name: '--color-gray4', light: '#70636B', dark: '#afa6ab' },
      { id: v4(), name: '--color-gray3', light: '#5C4C55', dark: '#d8d2d6' },
      { id: v4(), name: '--color-gray2', light: '#493842', dark: '#e7e1e4' },
      { id: v4(), name: '--color-gray1', light: '#493842', dark: '#f0edef' },
      { id: v4(), name: '--color-light', light: '#220C19', dark: '#ffffff' },
    ],
  },
];
