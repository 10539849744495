import { AxiosResponse } from 'axios';
import { PartnerAPI } from 'types/Partner';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface CreateParams extends Omit<PartnerAPI, 'id'> {}

interface ResSuccess {
  info: PartnerAPI;
  message: string;
}

export const createPartner = async (data: CreateParams) => {
  const response: AxiosResponse<ResSuccess> = await fetchAPI.request({
    url: baseUrl,
    method: 'POST',
    data,
  });
  return response.data;
};
