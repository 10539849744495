export interface RGB {
  b: number;
  g: number;
  r: number;
}

// default if hex = null. default = color white
const DEFAULT_RGB_COLOR: RGB = {
  g: 255,
  r: 255,
  b: 255,
};

// function checkHex(hex: string) {
//   if (hex.length === 4) {
//     const [r, g, b] = hex.replace('#', '').split('');
//     return `#${r}${r}${g}${g}${b}${b}`;
//   }
//   return hex;
// }

// export function hexToRgb(hex: string): RGB {
//   const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(checkHex(hex)) as RegExpExecArray;
//   return {
//     r: parseInt(result[1], 16),
//     g: parseInt(result[2], 16),
//     b: parseInt(result[3], 16),
//   };
// }

export function hexToRgb(hex: string): RGB {
  // Check if the input is a valid hexadecimal color code
  const hexRegex = /^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

  if (!hexRegex.test(hex)) {
    console.error('Không tồn tại color code');
    return DEFAULT_RGB_COLOR;
  }

  // Remove the '#' symbol if present
  hex = hex.replace('#', '');

  // Check if the input is a shorthand hexadecimal color code (e.g., #ABC)
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(char => char + char)
      .join('');
  }

  const red = parseInt(hex.substring(0, 2), 16);
  const green = parseInt(hex.substring(2, 4), 16);
  const blue = parseInt(hex.substring(4, 6), 16);

  return { r: red, g: green, b: blue };
}
