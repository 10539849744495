import { sectionApiController } from 'services/SectionService';
import { ProductSection } from 'types/Sections';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const publishAtomToProduct = async (section: ProductSection) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const searchResponse = await sectionApiController.product.adminApi.sections.getProductsWithParentCommandIds({
      parentCommandIds: section.parentCommandId,
      cacheVersion: lastCache,
    });
    const itemExisted = searchResponse.info.find(item => item.parentCommandId === section.parentCommandId);
    if (!!itemExisted) {
      return sectionApiController.product.adminApi.sections.updateProduct({
        section: {
          ...itemExisted,
          label: section.label,
          category: section.category,
          envatoCategories: section.envatoCategories,
          image: section.image,
          planHandle: section.planHandle ?? itemExisted.planHandle,
          pageTypes: section.pageTypes,
          priorityOrder: section.priorityOrder,
          searchTerms: section.searchTerms,
        },
      });
    } else {
      return sectionApiController.product.adminApi.sections.createProduct({
        section: {
          ...section,
          commandId: undefined,
        },
      });
    }
  }
  throw new RoleException();
};
