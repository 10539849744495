import { call, put, retry, takeLatest } from 'redux-saga/effects';
import { addonService } from 'services/AddonService';
import { updateAddonVersion, updateAddonsInPage, updateSectionVersion } from 'store/actions/versions/actionSectionVersion';
import { i18n } from 'translation';
import { AdminAddon, ProductAddon, ThemeAddon } from 'types/Addons';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';

function* handleUpdateAddon(parentCommandId: string, prevAddonSection: ThemeAddon) {
  try {
    const response: Awaited<ReturnType<typeof addonService.addons.updateVersion>> = yield retry(3, 1000, addonService.addons.updateVersion, {
      atomCommandId: parentCommandId,
    });
    const responseSuccess = ({
      ...response,
      sectionId: prevAddonSection.body.id,
      body: {
        ...response.body,
        id: prevAddonSection.body.id,
        parentCommandId,
        currentVersion: (response as AdminAddon).currentVersion,
      },
    } as unknown) as ThemeAddon;

    yield put(updateAddonVersion.success({ updatedAddon: responseSuccess }));
    yield put(updateSectionVersion.success({ updatedItem: responseSuccess.body }));

    notifyAxiosHandler.handleSuccess(`${i18n.t('general.update', { text: `${response.label} ${i18n.t('general.successfully')}` })}`);
  } catch (error) {
    const error_ = error as Error;
    notifyAxiosHandler.handleError(error_);
    yield put(updateAddonVersion.failure(undefined));
  }
}

function* handleAction({ payload: { prevAddons } }: ReturnType<typeof updateAddonsInPage.request>) {
  try {
    if (prevAddons.length > 0) {
      for (const prevAddon of prevAddons) {
        if ((prevAddon as ProductAddon).parentCommandId && (prevAddon as ProductAddon).parentCommandId !== '') {
          yield call(handleUpdateAddon, (prevAddon as ProductAddon).parentCommandId ?? '', prevAddon);
        }
      }
      yield put(updateAddonsInPage.success(undefined));
    } else {
      yield put(updateAddonsInPage.failure(undefined));
    }
  } catch (error) {
    yield put(updateAddonsInPage.failure(undefined));
  }
}

export function* watchUpdateAddonsInPage() {
  yield takeLatest(getActionType(updateAddonsInPage.request), handleAction);
}
