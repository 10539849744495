import { ImportThemeAtomToClientServiceNPageInThemeClientMessage } from 'hooks/useSocket/useSocketForImportThemeAtomToClientServiceNPageInThemeClient';
import { userApis } from 'services/UserService/apis';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

interface ImportOtherEntityOfPageInTheme {
  themeAtomCommandId: string;
  pageCommandId: string;
  eventType: ImportThemeAtomToClientServiceNPageInThemeClientMessage['eventType'];
  eventId: string;
}

export const importOtherEntityOfPageInTheme = async ({ themeAtomCommandId, pageCommandId, eventType, eventId }: ImportOtherEntityOfPageInTheme) => {
  const { role } = getUserInfo();

  if (role === 'admin') {
    const response = await themeApis.vedaApplication.adminApi.theme.importOtherEntityOfPageInTheme({
      themeAtomCommandId,
      pageCommandId,
      eventType,
      eventId,
    });
    await userApis.user.adminApi.updateVedaCacheVersion();
    return response;
  }
  const response = await themeApis.vedaApplication.userApi.theme.importOtherEntityOfPageInTheme({
    themeAtomCommandId,
    pageCommandId,
    eventType,
    eventId,
  });
  await userApis.user.adminApi.updateVedaCacheVersion();
  return response;
};
