import { MenuItem } from 'components/Navigation/Navigation';
import { i18n } from 'translation';
import { v4 } from 'uuid';

export const getMenu = (shopName: string): MenuItem[] => [
  {
    id: v4(),
    href: `/account`,
    label: i18n.t('adminDashboard.account'),
    icon: 'user',
    isReactRouter: true,
    exact: true,
  },
  {
    id: v4(),
    href: `/account/danger-zone`,
    label: i18n.t('adminDashboard.danger_zone'),
    icon: 'engine-warning',
    isReactRouter: true,
    exact: true,
  },
  {
    id: v4(),
    href: `/account/purchase-code`,
    label: i18n.t('adminDashboard.purchase_code'),
    icon: 'gift',
    isReactRouter: true,
    exact: true,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/orders`,
    label: i18n.t('adminDashboard.orders'),
    icon: 'box',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/products`,
    label: i18n.t('general.products'),
    icon: 'shopping-bag',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/products/inventory`,
    label: i18n.t('adminDashboard.inventory'),
    icon: 'cart-arrow-down',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/collections`,
    label: i18n.t('adminDashboard.collections'),
    icon: 'tags',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/customers`,
    label: i18n.t('adminDashboard.customers'),
    icon: 'users',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/dashboards`,
    label: i18n.t('adminDashboard.analytics'),
    icon: 'analytics' as any,
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/marketing`,
    label: i18n.t('adminDashboard.marketing'),
    icon: 'bullseye-arrow' as any,
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/discounts`,
    label: i18n.t('adminDashboard.discounts'),
    icon: 'badge-percent' as any,
    isReactRouter: false,
    hasDivider: true,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/settings/general`,
    label: i18n.t('adminDashboard.store_details'),
    icon: 'store',
    isReactRouter: false,
    textHeader: i18n.t('general.settings'),
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/settings/plan`,
    label: i18n.t('general.plan'),
    icon: 'list-alt',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/settings/billing`,
    label: i18n.t('adminDashboard.billing'),
    icon: 'badge-dollar' as any,
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/settings/account`,
    label: i18n.t('adminDashboard.users_and_permissions'),
    icon: 'user',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/settings/payments`,
    label: i18n.t('adminDashboard.payments'),
    icon: 'credit-card-front',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/settings/checkout`,
    label: i18n.t('adminDashboard.checkout_and_accounts'),
    icon: 'shopping-cart',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/settings/shipping`,
    label: i18n.t('adminDashboard.shipping_and_delivery'),
    icon: 'shipping-fast',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/settings/taxes`,
    label: i18n.t('adminDashboard.taxes_and_duties'),
    icon: 'percentage',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/settings/locations`,
    label: i18n.t('adminDashboard.locations'),
    icon: 'map-marker-alt',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/settings/gift_cards`,
    label: i18n.t('adminDashboard.gift_cards'),
    icon: 'gift-card',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/settings/markets`,
    label: i18n.t('adminDashboard.markets'),
    icon: 'globe',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/settings/apps`,
    label: i18n.t('adminDashboard.apps_and_sales_channels'),
    icon: 'layer-plus',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/settings/domains`,
    label: i18n.t('adminDashboard.domains'),
    icon: 'external-link-square',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/settings/customer_events`,
    label: i18n.t('adminDashboard.customer_events'),
    icon: 'location-arrow',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/settings/branding`,
    label: i18n.t('adminDashboard.brand'),
    icon: 'shapes',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/settings/notifications`,
    label: i18n.t('adminDashboard.notification'),
    icon: 'bell',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/metafields`,
    label: i18n.t('adminDashboard.meta_fields'),
    icon: 'database',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/settings/files`,
    label: i18n.t('adminDashboard.files'),
    icon: 'paperclip',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/settings/languages`,
    label: i18n.t('adminDashboard.languages'),
    icon: 'language',
    isReactRouter: false,
  },
  {
    id: v4(),
    href: `https://${shopName}/admin/settings/legal`,
    label: i18n.t('adminDashboard.policies'),
    icon: 'file-alt',
    isReactRouter: false,
  },
];
