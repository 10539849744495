import { put, retry, takeLatest } from '@redux-saga/core/effects';
import { AxiosError } from 'axios';
import { addAdminCategory } from 'containers/ChooseTemplate/store/actions';
import { sectionService } from 'services/SectionService';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';

function* handleCreateCategory({ payload }: ReturnType<typeof addAdminCategory.request>) {
  try {
    const response: Awaited<ReturnType<typeof sectionService.categories.createCategoryOfAtom>> = yield retry(
      3,
      1000,
      sectionService.categories.createCategoryOfAtom,
      { description: payload.description, name: payload.name },
    );
    yield put(
      addAdminCategory.success({
        count: 0,
        commandId: response.info.commandId,
        name: response.info.name,
        description: response.info.description,
      }),
    );
    notifyAxiosHandler.handleSuccess(response.message);
  } catch (error) {
    const err = error as AxiosError<{ message: string }>;
    const message = err.response?.data?.message || '';
    yield put(addAdminCategory.failure({ message }));
  }
}

export function* watchCreateAdminCategory() {
  yield takeLatest(getActionType(addAdminCategory.request), handleCreateCategory);
}
