import { getHeaderOrFooterSectionsOfThemeAtom } from 'services/ThemeService/Logic/getHeaderOrFooterSectionsOfThemeAtom';
import { pushToCache, readFromCache } from './utils/CacheStorage';
import { getMegamenuCacheKey, getSectionCacheKey } from './utils/CacheKeyControl';
import { PageSection } from 'types/Sections';
import { BaseParams } from './@types/BaseParams';
import { getHeaderOrFooterSectionsOfThemeDraft } from 'services/ThemeService/Logic/getHeaderOrFooterSectionsOfThemeDraft';

export const getFooterSections_ = async ({ commandIds, entityVariant }: GetFooterSections) => {
  const getHeaderOrFooterSectionsOfThemeWithEntityVariant =
    entityVariant === 'Atom' ? getHeaderOrFooterSectionsOfThemeAtom : getHeaderOrFooterSectionsOfThemeDraft;
  const footerSections = await getHeaderOrFooterSectionsOfThemeWithEntityVariant({ commandIds });

  const megamenuCommandIds = footerSections.reduce<string[]>((result, footerSection) => {
    if (footerSection.enable && footerSection.megaMenuCommandIds) {
      return result.concat(footerSection.megaMenuCommandIds);
    }
    return result;
  }, []);

  const footerKeys = await footerSections.reduce<Promise<string[]>>(async (result, footerSection) => {
    const result_ = await result;
    if (footerSection.commandId && footerSection.enable) {
      const cacheKey = await pushToCache(JSON.stringify(footerSection), getMegamenuCacheKey(footerSection.commandId));
      return result_.concat(cacheKey);
    }
    return result_;
  }, Promise.resolve([]));
  return {
    footerKeys,
    megamenuCommandIds,
  };
};

interface GetFooterSections extends BaseParams {
  commandIds: string[];
}
export const getFooterSections = async ({ commandIds, isRetry, ...params }: GetFooterSections) => {
  if (isRetry) {
    const megamenuCommandIds: string[] = [];
    const footerKeys = await Promise.all(
      commandIds.map(async commandId => {
        const cacheKey = getSectionCacheKey(commandId);
        const dataFromCache = await readFromCache<PageSection>(cacheKey);
        if (dataFromCache) {
          if (dataFromCache.megaMenuCommandIds) {
            megamenuCommandIds.push(...dataFromCache.megaMenuCommandIds);
          }
          return cacheKey;
        } else {
          const { footerKeys, megamenuCommandIds: megamenuCommandIds_ } = await getFooterSections_({ commandIds: [commandId], isRetry, ...params });
          megamenuCommandIds.push(...megamenuCommandIds_);
          return footerKeys[0];
        }
      }),
    );
    return { footerKeys, megamenuCommandIds };
  } else {
    return getFooterSections_({ commandIds, isRetry, ...params });
  }
};
