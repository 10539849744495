import { AxiosResponse } from 'axios';
import { PartnerAPI } from 'types/Partner';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface ResSuccess {
  info: PartnerAPI[];
  message: string;
}

export const getPartners = async () => {
  const response: AxiosResponse<ResSuccess> = await fetchAPI.request({
    url: `${baseUrl}`,
    method: 'GET',
  });
  return response.data;
};
