import { mergeDeepLeft, sort } from 'ramda';
import { NumberField } from 'services/ShopifyConnection/utils/VedaOutputToShopifyThemeEditor/@types/Shopify/InputSettings/NumberField';
import { ImageField } from '../../../@types/Shopify/InputSettings/ImageField';
import { TextField } from '../../../@types/Shopify/InputSettings/TextField';
import { Locales } from '../../../@types/Veda/ShopifyLocales';
import { ignoreToReduceTotalLinesOfCode } from '../../../@utils/ignoreToReduceTotalLinesOfCode';
import { replaceExactVariableNameInLiquidCode } from '../../../@utils/replaceExactVariableNameInLiquidCode';
import { sortWithKeyOfFieldValues } from '../../../@utils/sortWithKeyOfFieldValues';
import { keys } from './@consts/keys';
import { ILIQUID_SettingChooseImage } from './@types/ILIQUID_SettingChooseImage';
import { ISCHEMA_SettingChooseImage } from './@types/ISCHEMA_SettingChooseImage';
import { ISETTING_SettingChooseImage } from './@types/ISETTING_SettingChooseImage';
import { getSignalReduceTotalLinesOfCode } from './utils/getSignalReduceTotalLinesOfCode';
import { toShopifyFieldId } from './utils/toShopifyFieldId';
import { vedaLabelsOfChooseImageFieldToShopifyFieldLabel } from './utils/vedaLabelsOfChooseImageFieldToShopifyFieldLabel';
import { vedaSummariesOfChooseImageFieldToShopifyFieldInfo } from './utils/vedaSummariesOfChooseImageFieldToShopifyFieldInfo';

interface RTSettingChooseImage {
  shopifyField: [ImageField] | [TextField, NumberField, NumberField];
  locales: Locales;
}

export const SCHEMA_SettingChooseImage = ({ field, parentField, isExportForEnvato, ...params }: ISCHEMA_SettingChooseImage): RTSettingChooseImage => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField });
  let RLocales: Locales = {
    en: {},
    fr: {},
    vi: {},
  };

  const keysSorted = sort(sortWithKeyOfFieldValues, keys);
  const data = keysSorted.reduce<Array<ImageField | TextField | NumberField>>((res, key) => {
    const { newLabelOfKey, locales: localesOfLabel } = vedaLabelsOfChooseImageFieldToShopifyFieldLabel(
      { ...params, field, parentField, isNeedReduceTotalLinesOfCode, isExportForEnvato },
      key,
    );
    const { newInfoOfKey, locales: localesOfInfo } = vedaSummariesOfChooseImageFieldToShopifyFieldInfo({
      ...params,
      field,
      parentField,
      isNeedReduceTotalLinesOfCode,
      isExportForEnvato,
    });
    RLocales = mergeDeepLeft(RLocales, localesOfLabel);
    RLocales = mergeDeepLeft(RLocales, localesOfInfo);
    if (key === 'src') {
      if (isExportForEnvato) {
        const shopifyField: ImageField = {
          type: 'image_picker',
          id: isNeedReduceTotalLinesOfCode
            ? getSignalReduceTotalLinesOfCode(field, key)
            : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode, isExportForEnvato }, key),
          info: newInfoOfKey,
          label: newLabelOfKey,
        };
        return res.concat(shopifyField);
      } else {
        const shopifyField: TextField = {
          type: 'text',
          default: undefined,
          id: isNeedReduceTotalLinesOfCode
            ? getSignalReduceTotalLinesOfCode(field, key)
            : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode, isExportForEnvato }, key),
          placeholder: undefined,
          info: newInfoOfKey,
          label: newLabelOfKey,
        };
        return res.concat(shopifyField);
      }
    }
    if (!isExportForEnvato && (key === 'width' || key === 'height')) {
      const shopifyField: NumberField = {
        type: 'number',
        default: undefined,
        id: isNeedReduceTotalLinesOfCode
          ? getSignalReduceTotalLinesOfCode(field, key)
          : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode, isExportForEnvato }, key),
        placeholder: undefined,
        info: newInfoOfKey,
        label: newLabelOfKey,
      };
      return res.concat(shopifyField);
    }
    return res;
  }, []);

  return {
    locales: RLocales,
    shopifyField: data as RTSettingChooseImage['shopifyField'],
  };
};

export const SETTING_SettingChooseImage = ({ field, isExportForEnvato, parentField, ...params }: ISETTING_SettingChooseImage) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField });
  return keys.reduce<Record<string, string | number | undefined>>((res, key) => {
    if (key === 'src') {
      const fieldName = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, key)
        : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode, isExportForEnvato }, key);
      // check kĩ phòng lỗi
      const value = isExportForEnvato
        ? undefined
        : typeof field.children === 'object'
        ? typeof field.children[key] === 'string' && !!field.children[key]
          ? field.children[key]
          : undefined
        : undefined;
      if (value !== undefined) {
        return {
          ...res,
          [fieldName]: value,
        };
      }
      return res;
    } else {
      if (isExportForEnvato) {
        return res;
      } else {
        const fieldName = isNeedReduceTotalLinesOfCode
          ? getSignalReduceTotalLinesOfCode(field, key)
          : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode, isExportForEnvato }, key);
        // check kĩ phòng lỗi
        const value = typeof field.children === 'object' ? (typeof field.children[key] === 'number' ? field.children[key] : undefined) : undefined;
        if (value !== undefined) {
          return {
            ...res,
            [fieldName]: value,
          };
        }
        return res;
      }
    }
  }, {});
};

export const LIQUID_SettingChooseImage = ({ field, parentField, isExportForEnvato, liquid, loopVariable, ...params }: ILIQUID_SettingChooseImage) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField });
  // Nếu field thuộc array
  // - loopVariable nếu là array ->  Array được dùng trong forloop -> Thế `${loopVariable}.${field.name}.${key}` = `${loopVariable}.${toShopifyFieldId({ field, parentField }, key)}`
  //   Example: --> for item in content --> item.icon_design.id ==> KQ: item.content__icon_design.id
  if (loopVariable) {
    return keys.reduce<string>((res, key) => {
      // Nếu là action export ra theme -> Sử dụng image field của shopify -> Không cần tách field width, height ra làm 2 field riêng như bình thường
      if (key === 'src') {
        if (isExportForEnvato) {
          const target = isNeedReduceTotalLinesOfCode
            ? getSignalReduceTotalLinesOfCode(field, key)
            : `${loopVariable}.${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode, isExportForEnvato }, key)}`;
          const sourceDotSrc = `${loopVariable}.${field.name}.${key}`;
          const sourceDotWidth = `${loopVariable}.${field.name}.width`;
          const sourceDotHeight = `${loopVariable}.${field.name}.height`;
          const sourceImageObject = `${loopVariable}.${field.name}`;
          // Replace cả những syntax item.image và item.image.src
          const liquidReplaceImageSrc = replaceExactVariableNameInLiquidCode({
            liquid: res,
            source: sourceDotSrc,
            target: `${target}.src`,
          });
          const liquidReplaceImageWidth = replaceExactVariableNameInLiquidCode({
            liquid: liquidReplaceImageSrc,
            source: sourceDotWidth,
            target: `${target}.width`,
          });
          const liquidReplaceImageHeight = replaceExactVariableNameInLiquidCode({
            liquid: liquidReplaceImageWidth,
            source: sourceDotHeight,
            target: `${target}.height`,
          });
          return replaceExactVariableNameInLiquidCode({
            liquid: liquidReplaceImageHeight,
            source: sourceImageObject,
            target,
          });
        } else {
          const target = isNeedReduceTotalLinesOfCode
            ? getSignalReduceTotalLinesOfCode(field, key)
            : `${loopVariable}.${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode, isExportForEnvato }, key)}`;
          const sourceDotSrc = `${loopVariable}.${field.name}.${key}`;
          const sourceImageObject = `${loopVariable}.${field.name}`;
          // Replace cả những syntax item.image và item.image.src
          const liquidReplaceImageSrc = replaceExactVariableNameInLiquidCode({
            liquid: res,
            source: sourceDotSrc,
            target,
          });
          return replaceExactVariableNameInLiquidCode({
            liquid: liquidReplaceImageSrc,
            source: sourceImageObject,
            target,
          });
        }
      } else {
        if (isExportForEnvato) {
          // Tại "key='src'" đã xử lý tất cả các còn lại
          return res;
        } else {
          const target = isNeedReduceTotalLinesOfCode
            ? getSignalReduceTotalLinesOfCode(field, key)
            : `${loopVariable}.${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode, isExportForEnvato }, key)}`;
          const source = `${loopVariable}.${field.name}.${key}`;
          return replaceExactVariableNameInLiquidCode({ liquid: res, source, target });
        }
      }
    }, liquid);
  }
  // Nếu field thuộc object
  // - parentField nếu là object -> Object được flat thành các "shopify input settings" -> Thế `${parentField.name}.${field.name}.${key}` = toShopifyFieldId
  //   Example: --> heading.icon_design.id ==> KQ: heading__icon_design.id
  else if (parentField) {
    return keys.reduce<string>((res, key) => {
      // Nếu là action export ra theme -> Sử dụng image field của shopify -> Không cần tách field width, height ra làm 2 field riêng như bình thường
      if (key === 'src') {
        if (isExportForEnvato) {
          const target = isNeedReduceTotalLinesOfCode
            ? getSignalReduceTotalLinesOfCode(field, key)
            : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode, isExportForEnvato }, key)}"]`;
          const sourceDotSrc = `${parentField.name}.${field.name}.${key}`;
          const sourceDotWidth = `${parentField.name}.${field.name}.width`;
          const sourceDotHeight = `${parentField.name}.${field.name}.height`;
          const sourceImageObject = `${parentField.name}.${field.name}`;
          // Replace cả những syntax item.image và item.image.src
          const liquidReplaceImageSrc = replaceExactVariableNameInLiquidCode({
            liquid: res,
            source: sourceDotSrc,
            target: `${target}.src`,
          });
          const liquidReplaceImageWidth = replaceExactVariableNameInLiquidCode({
            liquid: liquidReplaceImageSrc,
            source: sourceDotWidth,
            target: `${target}.width`,
          });
          const liquidReplaceImageHeight = replaceExactVariableNameInLiquidCode({
            liquid: liquidReplaceImageWidth,
            source: sourceDotHeight,
            target: `${target}.height`,
          });
          return replaceExactVariableNameInLiquidCode({
            liquid: liquidReplaceImageHeight,
            source: sourceImageObject,
            target,
          });
        } else {
          const target = isNeedReduceTotalLinesOfCode
            ? getSignalReduceTotalLinesOfCode(field, key)
            : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode, isExportForEnvato }, key)}"]`;
          const sourceDotSrc = `${parentField.name}.${field.name}.${key}`;
          const sourceImageObject = `${parentField.name}.${field.name}`;
          // Replace cả những syntax item.image và item.image.src
          const liquidReplaceImageSrc = replaceExactVariableNameInLiquidCode({ liquid: res, source: sourceDotSrc, target });
          return replaceExactVariableNameInLiquidCode({
            liquid: liquidReplaceImageSrc,
            source: sourceImageObject,
            target,
          });
        }
      } else {
        if (isExportForEnvato) {
          // Tại "key='src'" đã xử lý tất cả các còn lại
          return res;
        } else {
          const target = isNeedReduceTotalLinesOfCode
            ? getSignalReduceTotalLinesOfCode(field, key)
            : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode, isExportForEnvato }, key)}"]`;
          const source = `${parentField.name}.${field.name}.${key}`;
          return replaceExactVariableNameInLiquidCode({ liquid: res, source, target });
        }
      }
    }, liquid);
  } else {
    return keys.reduce<string>((res, key) => {
      // Nếu là action export ra theme -> Sử dụng image field của shopify -> Không cần tách field width, height ra làm 2 field riêng như bình thường
      if (key === 'src') {
        if (isExportForEnvato) {
          const target = isNeedReduceTotalLinesOfCode
            ? getSignalReduceTotalLinesOfCode(field, key)
            : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode, isExportForEnvato }, key)}"]`;
          const sourceDotSrc = `${field.name}.${key}`;
          const sourceDotWidth = `${field.name}.width`;
          const sourceDotHeight = `${field.name}.height`;
          const sourceImageObject = `${field.name}`;
          // Replace cả những syntax item.image và item.image.src
          const liquidReplaceImageSrc = replaceExactVariableNameInLiquidCode({
            liquid: res,
            source: sourceDotSrc,
            target: `${target}.src`,
          });
          const liquidReplaceImageWidth = replaceExactVariableNameInLiquidCode({
            liquid: liquidReplaceImageSrc,
            source: sourceDotWidth,
            target: `${target}.width`,
          });
          const liquidReplaceImageHeight = replaceExactVariableNameInLiquidCode({
            liquid: liquidReplaceImageWidth,
            source: sourceDotHeight,
            target: `${target}.height`,
          });
          return replaceExactVariableNameInLiquidCode({
            liquid: liquidReplaceImageHeight,
            source: sourceImageObject,
            target,
          });
        } else {
          const target = isNeedReduceTotalLinesOfCode
            ? getSignalReduceTotalLinesOfCode(field, key)
            : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isExportForEnvato, isNeedReduceTotalLinesOfCode }, key)}"]`;
          const sourceDotSrc = `${field.name}.${key}`;
          const sourceImageObject = `${field.name}`;
          // Replace cả những syntax item.image và item.image.src
          const liquidReplaceImageSrc = replaceExactVariableNameInLiquidCode({
            liquid: res,
            source: sourceDotSrc,
            target,
          });
          return replaceExactVariableNameInLiquidCode({
            liquid: liquidReplaceImageSrc,
            source: sourceImageObject,
            target,
          });
        }
      } else {
        if (isExportForEnvato) {
          // Tại "key='src'" đã xử lý tất cả các còn lại
          return res;
        } else {
          const target = isNeedReduceTotalLinesOfCode
            ? getSignalReduceTotalLinesOfCode(field, key)
            : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isExportForEnvato, isNeedReduceTotalLinesOfCode }, key)}"]`;
          const source = `${field.name}.${key}`;
          return replaceExactVariableNameInLiquidCode({ liquid: res, source, target });
        }
      }
    }, liquid);
  }
};
