import { delay } from '@wiloke/functions';
import { Button, MyModal } from '@wiloke/ui';
import { notification } from 'antd';
import SelectAntd, { Option } from 'components/SelectAntd';
import Textarea from 'components/Textarea';
import withDebounce from 'hocs/withDebounce';
import { useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { customerReportService } from 'services/CustomerReportService';
import { useRestoreThemeToDefault } from 'store/actions/actionRestoreThemeToDefault';
import { restoreToDefaultSelector, useSetVisibleModalRestore } from 'store/reducers/sliceRestoreToDefault';
import { i18n } from 'translation';
import { Space, Styles, Text, Theme, View } from 'wiloke-react-core';
import { useCrispChat } from 'containers/CrispChat/hooks/useCrispChat';
import { useHistory } from 'react-router';
import { useSetValidatedCode } from 'containers/Admin/PlanManagement/store/sliceCoupon';

const bodyModalStyles = ({ colors }: Theme): Styles => ({
  minWidth: '600px',
  width: '600px',
  color: colors.gray8,
});

const option_data: Option[] = [
  {
    label: i18n.t('adminDashboard.danger_zone_page.budget_concerns_title'),
    value: 'budget_concerns',
  },
  {
    label: i18n.t('adminDashboard.danger_zone_page.missing_features_title'),
    value: 'missing_features',
  },
  {
    label: i18n.t('adminDashboard.danger_zone_page.technical_issues_title'),
    value: 'technical_issues',
  },
  {
    label: i18n.t('adminDashboard.danger_zone_page.no_need_title'),
    value: 'no_need_anymore',
  },
  {
    label: i18n.t('adminDashboard.danger_zone_page.difficulty_of_use_title'),
    value: 'difficulty_of_use',
  },
  {
    label: i18n.t('adminDashboard.danger_zone_page.something_else_title'),
    value: 'something_else',
  },
];

const mappingReason: Record<string, string> = {
  budget_concerns: i18n.t('adminDashboard.danger_zone_page.budget_concerns_desc'),
  missing_features: i18n.t('adminDashboard.danger_zone_page.missing_features_desc'),
  technical_issues: i18n.t('adminDashboard.danger_zone_page.technical_issues_desc'),
  no_need_anymore: i18n.t('adminDashboard.danger_zone_page.no_need_desc'),
  difficulty_of_use: i18n.t('adminDashboard.danger_zone_page.difficulty_of_use_desc'),
  something_else: i18n.t('adminDashboard.danger_zone_page.something_else_desc'),
  '': '',
};

const FREE_CODE = 'VSTAY40';

const TextareaDebounce = withDebounce(Textarea, 'value', 'onChangeText');

export const ModalRestore = () => {
  const { visibleModalRestore, status } = useSelector(restoreToDefaultSelector);
  const { connect, disconnect, statusSocketConnection } = useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService('sync_shopify');

  const setVisibleModalRestore = useSetVisibleModalRestore();
  const restoreThemeToDefault = useRestoreThemeToDefault();
  const setValidatedCode = useSetValidatedCode();

  const [reason, setReason] = useState('budget_concerns');
  const [feedback, setFeedback] = useState('');
  const history = useHistory();
  const { sendMessage } = useCrispChat();

  const cancelModal = async () => {
    customerReportService.restoreTheme({
      reason,
      status: 'uninstalled',
      feedback,
    });
    setVisibleModalRestore(false);
    await delay(200);
    setFeedback('');
  };

  const handleRestoreVeda = () => {
    connect({
      onSuccess() {
        restoreThemeToDefault.request({
          eventType: 'Xóa toàn bộ dữ liệu veda',
          onFulfill() {
            cancelModal();
            disconnect({});
          },
        });
      },
      onError() {
        notification.error({
          message: i18n.t('publish_shopify.init_sync_error.message'),
          description: i18n.t('publish_shopify.init_sync_error.description'),
        });
      },
    });
  };

  const handleRedeemCode = () => {
    setValidatedCode(FREE_CODE);
    history.push('/pricing');
    setVisibleModalRestore(false);
  };

  const handleChatNow = () => {
    sendMessage({ message: '' });
  };

  const closeModal = () => {
    customerReportService.restoreTheme({
      reason,
      feedback: '',
      status: 'staywithapp',
    });
    setVisibleModalRestore(false);
    setFeedback('');
  };

  return (
    <MyModal
      headerText={i18n.t('adminDashboard.danger_zone_page.restore_veda')}
      isLoading={statusSocketConnection === 'loading' || status === 'loading'}
      isVisible={visibleModalRestore}
      bodyCss={bodyModalStyles}
      onCancel={closeModal}
      onOk={handleRestoreVeda}
      okText={i18n.t('adminDashboard.danger_zone_page.restore')}
      okBackgroundColor="danger"
      cancelText={i18n.t('general.cancel')}
      contentCss={{ padding: '10px 20px' }}
    >
      <View fontFamily="secondary" css={{ backgroundColor: '#FFF8E9', padding: '10px 20px', fontWeight: '500' }} color="gray9">
        {i18n.t('adminDashboard.danger_zone_page.modal_restore_sorry')}
      </View>
      <Space size={20} />
      <View fontFamily="secondary">{i18n.t('adminDashboard.danger_zone_page.modal_restore_before')}</View>

      <Space size={10} />
      <SelectAntd value={reason} data={option_data} onChange={setReason} />
      <Space size={10} />

      <View fontFamily="secondary" css={{ fontWeight: '350', fontSize: '13px' }}>
        {mappingReason[reason]}
      </View>
      <Space size={20} />
      {reason === 'budget_concerns' ? (
        <Button backgroundColor="secondary" size="extra-small" radius={4} onClick={handleRedeemCode}>
          {i18n.t('adminDashboard.danger_zone_page.redeem_code')}
        </Button>
      ) : (
        <Button backgroundColor="secondary" size="extra-small" radius={4} onClick={handleChatNow}>
          {i18n.t('adminDashboard.danger_zone_page.chat_now')}
        </Button>
      )}

      <Space size={10} />
      <View>
        <Text fontFamily="secondary">
          <Text tagName="strong">Before you click Restore app</Text>, please let us know what function or feature is missing from Veda Builder:
        </Text>
        <Space size={10} />
        <TextareaDebounce value={feedback} onChangeText={setFeedback} />
        <Space size={10} />
      </View>
    </MyModal>
  );
};
