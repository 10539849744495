import { CssColorVariable } from 'types/PresetStyles';
import { DEFAULT } from '../@colors/default';
import { VISOR } from '../@colors/visor';
import { CLAVE } from '../@colors/clave';
import { MAZE } from '../@colors/maze';
import { PROTON } from '../@colors/proton';
import { LAVITE } from '../@colors/lavite';
import { BEAN } from '../@colors/bean';
import { STRAWBERRY } from '../@colors/strawberry';
import { VELVET } from '../@colors/velvet';
import { STILE } from '../@colors/stile';

export const SCHEMES: Array<{ name: string; cssClassName: string; colors: CssColorVariable[] }> = [
  ...DEFAULT,
  ...STILE,
  ...VISOR,
  ...CLAVE,
  ...MAZE,
  ...LAVITE,
  ...PROTON,
  ...BEAN,
  ...STRAWBERRY,
  ...VELVET,
];

export const SelectColorSchemes = () => {
  return null;
  // const colorSchemes = useSelector(colorSchemesSelector);

  // const setColorSchemes = useSetColorSchemes();

  // return (
  //   <View>
  //     <SectionPageHeader
  //       disableButton
  //       title={i18n.t('general.presets', { text: i18n.t('general.style') })}
  //       description={i18n.t('adminDashboard.page_description.preset')}
  //     />
  //     <GridSmart columnWidth={200} columnCount={3} columnGap={16}>
  //       {SCHEMES.map(item => {
  //         return (
  //           <View key={item.cssClassName} radius={8} borderWidth={1} borderStyle="solid" borderColor="gray3" css={{ padding: '8px' }}>
  //             <View css={styles.titleContainer}>
  //               <Text tagName="h5">{item.name}</Text>
  //               <View>
  //                 <Checkbox
  //                   checked={colorSchemes.findIndex(colorScheme => colorScheme.cssClassName === item.cssClassName) !== -1}
  //                   onValueChange={checked => {
  //                     setColorSchemes({
  //                       colorSchemes: checked
  //                         ? colorSchemes.concat({ uniqueName: item.name, cssClassName: item.cssClassName, colors: item.colors })
  //                         : colorSchemes.filter(colorScheme => colorScheme.cssClassName !== item.cssClassName),
  //                     });
  //                   }}
  //                 />
  //               </View>
  //             </View>
  //             <View backgroundColor="gray3" height={1} css={styles.line} />
  //             <View row css={[styles.innerBox, styles.spaceBox]}>
  //               <View tagName="h6" columns={[12, 3, 3]} css={styles.innerBoxText}>
  //                 {i18n.t('general.colors')}
  //               </View>

  //               <View columns={[12, 9, 9]}>
  //                 <View css={styles.colorGrid}>
  //                   {item.colors.map(({ id, light }) => (
  //                     <View key={id} css={{ backgroundColor: light }} />
  //                   ))}
  //                 </View>
  //               </View>
  //             </View>
  //           </View>
  //         );
  //       })}
  //     </GridSmart>
  //   </View>
  // );
};
