import { ColorSchemes } from 'types/Result';
import { createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';

interface PageColorSchemesActions {
  type: 'setPageColorSchemes';
  payload: ColorSchemes;
}

export type PageColorSchemesState = ColorSchemes;

export const defaultPageColorSchemes: PageColorSchemesState = [];

export const slicePageColorSchemes = createSlice<PageColorSchemesState, PageColorSchemesActions>({
  name: '@Global',
  initialState: defaultPageColorSchemes,
  reducers: [
    handleAction('setPageColorSchemes', ({ action }) => {
      return action.payload;
    }),
  ],
});

export const { setPageColorSchemes } = slicePageColorSchemes.actions;
export const useSetPageColorSchemes = createDispatchAction(setPageColorSchemes);
