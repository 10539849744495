import { useSocketForImportThemeAtomToClientServiceNPageInThemeClient } from 'hooks/useSocket/useSocketForImportThemeAtomToClientServiceNPageInThemeClient';
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { themeBuilderSelector } from 'store/selectors';

function useIsTabVisible() {
  const [isVisible, setIsVisible] = useState(false);
  const { statusSocketConnection } = useSocketForImportThemeAtomToClientServiceNPageInThemeClient();
  const { checkThemeIsInstallStatus } = useSelector(themeBuilderSelector.templates);

  const handleVisibility = useCallback(() => {
    if (statusSocketConnection === 'loading' || checkThemeIsInstallStatus === 'loading' || checkThemeIsInstallStatus === 'success') {
      setIsVisible(false);
    } else {
      setIsVisible(!document.hidden);
    }
  }, [statusSocketConnection, checkThemeIsInstallStatus]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibility);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibility);
    };
  }, [handleVisibility]);

  return isVisible;
}

export default useIsTabVisible;
