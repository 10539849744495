import { Title } from '@wiloke/ui';
import { AdvancedIcon } from 'components/PlanBadge';
import Switch from 'components/Switch';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { appSettingsSelector, useChangeAppSettings } from 'store/global/appSettings/slice';
import { authSelector } from 'store/selectors';
import { PLAN_STATIC_DATA, VEDA_FUNCTION_ENUM } from 'utils/constants/PlanEnum';
import { validatePlan } from 'utils/functions/validatePlan';
import { View } from 'wiloke-react-core';
import * as styles from '../styles';

export const EditCode: FC = () => {
  const { data } = useSelector(appSettingsSelector);
  const { plan, role } = useSelector(authSelector);

  const changeAppSettings = useChangeAppSettings();
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();

  return (
    <View css={styles.field}>
      <View css={styles.left}>
        <Title
          title={
            <View css={{ display: 'flex', columnGap: '6px' }}>
              <>Edit Code</>
              {plan.planHandle === 'free' && (
                <View
                  css={{
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  radius={4}
                  backgroundColor="quaternary"
                >
                  <AdvancedIcon />
                </View>
              )}
            </View>
          }
          text="If you open this option you can edit the code for the sections"
        />
      </View>
      <View css={styles.right}>
        <Switch
          checked={data.editCodeEnabled}
          onValueChange={value => {
            if (validatePlan({ userPlan: plan.planHandle, itemPlan: VEDA_FUNCTION_ENUM.edit_code, role }) === 'pass') {
              changeAppSettings({ editCodeEnabled: value });
            } else {
              setPlanRequesting({
                handle: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.edit_code].handle,
                type: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.edit_code].type,
                pricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.edit_code].pricePerMonth,
                pricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.edit_code].pricePerYear,
                title: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.edit_code].title,
                originalPricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.edit_code].pricePerMonth,
                originalPricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.edit_code].pricePerYear,
              });
              setValidateCouponVisible(true);
            }
          }}
        />
      </View>
    </View>
  );
};
