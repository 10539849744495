import { put, retry, takeLatest } from 'redux-saga/effects';
import { megaMenuService } from 'services/MegaMenuService';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { deleteAdminMegaMenu } from '../../actions';

function* handleDelete({ payload: { section, onFulfill } }: ReturnType<typeof deleteAdminMegaMenu.request>) {
  try {
    const response: Awaited<ReturnType<typeof megaMenuService.mega_menu.deleteAtomSection>> = yield retry(
      3,
      1000,
      megaMenuService.mega_menu.deleteAtomSection,
      section.commandId,
    );
    if (section.vedaProductRepresent) {
      yield retry(3, 1000, megaMenuService.mega_menu.deleteProductSection, section.vedaProductRepresent.commandId);
    }
    yield put(deleteAdminMegaMenu.success({ commandId: section.commandId }));
    onFulfill?.();
    notifyAxiosHandler.handleSuccess(response.message);
  } catch (error) {
    yield put(deleteAdminMegaMenu.failure({ commandId: section.commandId }));
  }
}

export function* watchDeleteAdminMegaMenu() {
  yield takeLatest(getActionType(deleteAdminMegaMenu.request), handleDelete);
}
