import { LazyImage, Title } from '@wiloke/ui';
import ImagePlaceholder from 'components/ImagePlaceholder';
import { FC, ReactNode, Ref } from 'react';
import { View, ViewProps } from 'wiloke-react-core';
import { DraftBoxDropdown, DraftBoxDropdownProps } from './DraftBoxDropdown';
import * as styles from './styles';

export interface DraftBoxProps {
  title: ReactNode;
  slug?: string;
  image?: string;
  Right?: ReactNode;
  CustomDropdown?: ReactNode;
  cssContainer?: ViewProps['css'];
  loading?: boolean;
  innerRef?: Ref<HTMLElement>;
  badges?: string[];
  badgeTitle?: string;
  onClickDropdown?: DraftBoxDropdownProps['onClickDropdown'];
  onClick?: () => void;
  isShopifyTheme?: boolean;
  isActive?: boolean;
  productPageLabel?: string;
}

const DraftBox: FC<DraftBoxProps> = ({
  image,
  slug,
  title,
  Right,
  CustomDropdown,
  cssContainer,
  loading = false,
  innerRef,
  badges,
  badgeTitle,
  onClickDropdown,
  onClick,
  isShopifyTheme,
  isActive = false,
  productPageLabel,
}) => {
  return (
    <View ref={innerRef} className="DraftBox-container" css={[styles.container, styles.isActive(isActive), cssContainer]}>
      {!!loading && <View css={styles.overlay} />}
      <View css={styles.item}>
        <View css={styles.imageContainer} onClick={onClick}>
          {image ? (
            <LazyImage
              src={image}
              containerCss={{ width: `70px`, height: `60px`, maxHeight: `60px` }}
              imageCss={{ height: '100%' }}
              ratioPercent={0}
            />
          ) : (
            <ImagePlaceholder css={{ width: '70px !important' }} height={60} size={24} />
          )}
        </View>

        <View css={styles.itemContent} onClick={onClick}>
          <View>
            <Title title={title} text={slug} />

            {productPageLabel && (
              <View css={styles.badgeContainer}>
                <View css={{ marginRight: '4px' }}>Product Page:</View>
                <View css={{ display: 'flex', flexWrap: 'wrap', columnGap: '2px', alignItems: 'center' }}>
                  <View backgroundColor="gray2" color="tertiary" fontFamily="secondary" css={styles.badge}>
                    {productPageLabel}
                  </View>
                </View>
              </View>
            )}

            {badges && badges.length > 0 ? (
              <View css={styles.badgeContainer}>
                <View css={{ marginRight: '4px' }}>{badgeTitle}</View>
                <View css={{ display: 'flex', flexWrap: 'wrap', columnGap: '2px' }}>
                  {badges.map(badge => (
                    <View key={badge} backgroundColor="gray4" color="primary" fontFamily="secondary" css={styles.badge}>
                      {badge}
                    </View>
                  ))}
                </View>
              </View>
            ) : null}
          </View>
        </View>

        {isShopifyTheme ? null : (
          <View css={styles.actions}>
            {Right}
            {CustomDropdown ? (
              CustomDropdown
            ) : (
              <DraftBoxDropdown isShopifyTheme={isShopifyTheme} isActive={isActive} onClickDropdown={onClickDropdown} />
            )}
          </View>
        )}
      </View>
    </View>
  );
};

export default DraftBox;
