import { CssColorVariable } from 'types/PresetStyles';
import { v4 } from 'uuid';

export const VELVET: Array<{ name: string; cssClassName: string; colors: CssColorVariable[] }> = [
  {
    name: 'Velvet',
    cssClassName: 'veda-color-velvet',
    colors: [
      { id: v4(), name: '--color-primary', light: '#FF7BBF', dark: '#FF7BBF' },
      { id: v4(), name: '--color-secondary', light: '#50409A', dark: '#50409A' },
      { id: v4(), name: '--color-tertiary', light: '#964EC2', dark: '#964EC2' },
      { id: v4(), name: '--color-quaternary', light: '#78AC77', dark: '#78AC77' },
      { id: v4(), name: '--color-dark', light: '#080D32', dark: '#ffffff' },
      { id: v4(), name: '--color-gray9', light: '#141B47', dark: '#F3F4F6' },
      { id: v4(), name: '--color-gray8', light: '#282F5B', dark: '#E1E2E8' },
      { id: v4(), name: '--color-gray7', light: '#444863', dark: '#CBCDD8' },
      { id: v4(), name: '--color-gray6', light: '#626680', dark: '#ADAFBD' },
      { id: v4(), name: '--color-gray5', light: '#85889C', dark: '#85889C' },
      { id: v4(), name: '--color-gray4', light: '#ADAFBD', dark: '#626680' },
      { id: v4(), name: '--color-gray3', light: '#CBCDD8', dark: '#444863' },
      { id: v4(), name: '--color-gray2', light: '#E1E2E8', dark: '#282F5B' },
      { id: v4(), name: '--color-gray1', light: '#F3F4F6', dark: '#141B47' },
      { id: v4(), name: '--color-light', light: '#ffffff', dark: '#080D32' },
    ],
  },
  {
    name: 'Velvet (Dark)',
    cssClassName: 'veda-color-dark-velvet',
    colors: [
      { id: v4(), name: '--color-primary', light: '#FF7BBF', dark: '#FF7BBF' },
      { id: v4(), name: '--color-secondary', light: '#50409A', dark: '#50409A' },
      { id: v4(), name: '--color-tertiary', light: '#964EC2', dark: '#964EC2' },
      { id: v4(), name: '--color-quaternary', light: '#78AC77', dark: '#78AC77' },
      { id: v4(), name: '--color-dark', light: '#ffffff', dark: '#080D32' },
      { id: v4(), name: '--color-gray9', light: '#F3F4F6', dark: '#141B47' },
      { id: v4(), name: '--color-gray8', light: '#E1E2E8', dark: '#282F5B' },
      { id: v4(), name: '--color-gray7', light: '#CBCDD8', dark: '#444863' },
      { id: v4(), name: '--color-gray6', light: '#ADAFBD', dark: '#626680' },
      { id: v4(), name: '--color-gray5', light: '#85889C', dark: '#85889C' },
      { id: v4(), name: '--color-gray4', light: '#626680', dark: '#ADAFBD' },
      { id: v4(), name: '--color-gray3', light: '#444863', dark: '#CBCDD8' },
      { id: v4(), name: '--color-gray2', light: '#282F5B', dark: '#E1E2E8' },
      { id: v4(), name: '--color-gray1', light: '#141B47', dark: '#F3F4F6' },
      { id: v4(), name: '--color-light', light: '#080D32', dark: '#ffffff' },
    ],
  },
];
