import { storage } from '@wiloke/functions';
import { isNguyenDttnMode } from 'services/ShopifyConnection/utils/isNguyenDttnMode';

export default {
  baseUrl: 'https://api.vedabuilder.com',
  version: 'api/v1',
  clientBaseUrl: 'https://clients.vedabuilder.com',
  endpoint: {
    auth: 'auth',
    sections: 'sections',
    sections_categories: 'categories',
    sections_changelogs: 'changelogs',
    addons: 'addons',
    pages: 'pages',
    pages_tags: 'tags',
    pages_categories: 'categories',
    users: 'users',
    'shopify-connections': isNguyenDttnMode() ? 'https://shopifyconnection.vedabuilder.com' : 'shopify-connections',
    me_medias: 'command/medias/me',
    admin_medias: 'command/medias/admin',
    themes: 'themes',
    themes_tags: 'tags',
    themes_categories: 'categories',
    atom: 'atom',
    product: 'product',
    mega_menus: 'mega-menus',
    dev: 'dev',
    admin: 'admin',
    clients: 'clients',
    use_cases: 'usecases',
  },
  timeout: 30000,
  apiFake: storage.getItem('API_FAKE') === 'true',
  env: (storage.getItem('env') as 'dev' | 'prod') || 'prod',
  eventSourceForSyncShopify: 'https://shopifyconnection.vedabuilder.com',
  eventSourceForClonePageAtomServiceToThemeAtomService: 'https://themeatom.vedabuilder.com',
  eventSourceForClientEndpoint: 'https://clients.vedabuilder.com',
  forceReload: storage.getItem('FORCE_RELOAD') === 'true',
  DEV_ENABLE_INSTALL_THEME: storage.getItem('DEV_ENABLE_INSTALL_THEME') === 'true',
  enable_live_preview: false,
};
