import { Button, ScrollBars } from '@wiloke/ui';
import Box from 'components/FieldBox';
import { Navigation } from 'components/Navigation';
import SectionPageHeader from 'components/SectionPageHeader';
import { ModalAfterRestore } from 'containers/Admin/AccountPage/ModalAfterRestore';
import { ModalRestore } from 'containers/Admin/AccountPage/ModalRestore';
import { Dashboard } from 'containers/Dashboard';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useSetVisibleModalRestore } from 'store/reducers/sliceRestoreToDefault';
import { authSelector } from 'store/selectors';
import { i18n } from 'translation';
import { View } from 'wiloke-react-core';
import { getMenu } from './menu';
import * as styles from './styles';

export const DangerZonePage: FC = () => {
  const { shopName } = useSelector(authSelector);
  const setVisibleModalRestore = useSetVisibleModalRestore();

  return (
    <Dashboard
      hasSubmenu={false}
      disabledPaddingContent
      Modals={() => (
        <>
          <ModalAfterRestore />
          <ModalRestore />
        </>
      )}
      Content={() => (
        <View css={styles.container}>
          <View css={styles.menu}>
            <ScrollBars>
              <Navigation data={getMenu(shopName)} />
            </ScrollBars>
          </View>
          <View css={styles.body}>
            <SectionPageHeader
              title={i18n.t('adminDashboard.danger_zone')}
              description={i18n.t('adminDashboard.danger_zone_page.description')}
              disableButton
            />
            <Box.WithTitle
              titleCss={{ marginBottom: '10px' }}
              title={i18n.t('adminDashboard.danger_zone_page.danger_card_title')}
              description={
                <View>
                  {i18n.t('adminDashboard.danger_zone_page.danger_card_desc')} <br />
                  <View
                    tagName="a"
                    color="primary"
                    css={{ cursor: 'pointer' }}
                    href="https://help.vedabuilder.com/en/article/uninstall-veda-builder-safely-tef496/"
                    target="_blank"
                  >
                    {i18n.t('adminDashboard.danger_zone_page.see_details_guide')}
                  </View>
                </View>
              }
              borderWidth={0}
              css={styles.box}
            >
              <Button
                size="extra-small"
                backgroundColor="danger"
                radius={6}
                onClick={() => {
                  setVisibleModalRestore(true);
                }}
              >
                {i18n.t('adminDashboard.danger_zone_page.restore')}
              </Button>
            </Box.WithTitle>
          </View>
        </View>
      )}
    />
  );
};
