export const icons = [
  'fa-address-book',
  'fa-address-card',
  'fa-adjust',
  'fa-air-freshener',
  'fa-alarm-clock',
  'fa-align-center',
  'fa-align-justify',
  'fa-align-left',
  'fa-align-right',
  'fa-allergies',
  'fa-ambulance',
  'fa-american-sign-language-interpreting',
  'fa-anchor',
  'fa-angle-double-down',
  'fa-angle-double-left',
  'fa-angle-double-right',
  'fa-angle-double-up',
  'fa-angle-down',
  'fa-angle-left',
  'fa-angle-right',
  'fa-angle-up',
  'fa-angry',
  'fa-apple-alt',
  'fa-archive',
  'fa-archway',
  'fa-arrow-alt-circle-down',
  'fa-arrow-alt-circle-left',
  'fa-arrow-alt-circle-right',
  'fa-arrow-alt-circle-up',
  'fa-arrow-alt-down',
  'fa-arrow-alt-from-bottom',
  'fa-arrow-alt-from-left',
  'fa-arrow-alt-from-right',
  'fa-arrow-alt-from-top',
  'fa-arrow-alt-left',
  'fa-arrow-alt-right',
  'fa-arrow-alt-square-down',
  'fa-arrow-alt-square-left',
  'fa-arrow-alt-square-right',
  'fa-arrow-alt-square-up',
  'fa-arrow-alt-to-bottom',
  'fa-arrow-alt-to-left',
  'fa-arrow-alt-to-right',
  'fa-arrow-alt-to-top',
  'fa-arrow-alt-up',
  'fa-arrow-circle-down',
  'fa-arrow-circle-left',
  'fa-arrow-circle-right',
  'fa-arrow-circle-up',
  'fa-arrow-down',
  'fa-arrow-from-bottom',
  'fa-arrow-from-left',
  'fa-arrow-from-right',
  'fa-arrow-from-top',
  'fa-arrow-left',
  'fa-arrow-right',
  'fa-arrow-square-down',
  'fa-arrow-square-left',
  'fa-arrow-square-right',
  'fa-arrow-square-up',
  'fa-arrow-to-bottom',
  'fa-arrow-to-left',
  'fa-arrow-to-right',
  'fa-arrow-to-top',
  'fa-arrow-up',
  'fa-arrows',
  'fa-arrows-alt',
  'fa-arrows-alt-h',
  'fa-arrows-alt-v',
  'fa-arrows-h',
  'fa-arrows-v',
  'fa-assistive-listening-systems',
  'fa-asterisk',
  'fa-at',
  'fa-atlas',
  'fa-atom',
  'fa-atom-alt',
  'fa-audio-description',
  'fa-award',
  'fa-backpack',
  'fa-backspace',
  'fa-backward',
  'fa-badge',
  'fa-badge-check',
  'fa-balance-scale',
  'fa-balance-scale-left',
  'fa-balance-scale-right',
  'fa-ban',
  'fa-band-aid',
  'fa-barcode',
  'fa-barcode-alt',
  'fa-barcode-read',
  'fa-barcode-scan',
  'fa-bars',
  'fa-baseball',
  'fa-baseball-ball',
  'fa-basketball-ball',
  'fa-basketball-hoop',
  'fa-bath',
  'fa-battery-bolt',
  'fa-battery-empty',
  'fa-battery-full',
  'fa-battery-half',
  'fa-battery-quarter',
  'fa-battery-slash',
  'fa-battery-three-quarters',
  'fa-bed',
  'fa-beer',
  'fa-bell',
  'fa-bell-school',
  'fa-bell-school-slash',
  'fa-bell-slash',
  'fa-bezier-curve',
  'fa-bicycle',
  'fa-binoculars',
  'fa-birthday-cake',
  'fa-blanket',
  'fa-blender',
  'fa-blind',
  'fa-bold',
  'fa-bolt',
  'fa-bomb',
  'fa-bone',
  'fa-bone-break',
  'fa-bong',
  'fa-book',
  'fa-book-alt',
  'fa-book-heart',
  'fa-book-open',
  'fa-book-reader',
  'fa-bookmark',
  'fa-books',
  'fa-bowling-ball',
  'fa-bowling-pins',
  'fa-box',
  'fa-box-alt',
  'fa-box-check',
  'fa-box-fragile',
  'fa-box-full',
  'fa-box-heart',
  'fa-box-open',
  'fa-box-up',
  'fa-box-usd',
  'fa-boxes',
  'fa-boxes-alt',
  'fa-boxing-glove',
  'fa-braille',
  'fa-brain',
  'fa-briefcase',
  'fa-briefcase-medical',
  'fa-broadcast-tower',
  'fa-broom',
  'fa-browser',
  'fa-brush',
  'fa-bug',
  'fa-building',
  'fa-bullhorn',
  'fa-bullseye',
  'fa-burn',
  'fa-bus',
  'fa-bus-alt',
  'fa-bus-school',
  'fa-calculator',
  'fa-calendar',
  'fa-calendar-alt',
  'fa-calendar-check',
  'fa-calendar-edit',
  'fa-calendar-exclamation',
  'fa-calendar-minus',
  'fa-calendar-plus',
  'fa-calendar-times',
  'fa-camera',
  'fa-camera-alt',
  'fa-camera-retro',
  'fa-cannabis',
  'fa-capsules',
  'fa-car',
  'fa-car-alt',
  'fa-car-battery',
  'fa-car-bump',
  'fa-car-crash',
  'fa-car-garage',
  'fa-car-mechanic',
  'fa-car-side',
  'fa-car-tilt',
  'fa-car-wash',
  'fa-caret-circle-down',
  'fa-caret-circle-left',
  'fa-caret-circle-right',
  'fa-caret-circle-up',
  'fa-caret-down',
  'fa-caret-left',
  'fa-caret-right',
  'fa-caret-square-down',
  'fa-caret-square-left',
  'fa-caret-square-right',
  'fa-caret-square-up',
  'fa-caret-up',
  'fa-cart-arrow-down',
  'fa-cart-plus',
  'fa-certificate',
  'fa-chalkboard',
  'fa-chalkboard-teacher',
  'fa-charging-station',
  'fa-chart-area',
  'fa-chart-bar',
  'fa-chart-line',
  'fa-chart-pie',
  'fa-check',
  'fa-check-circle',
  'fa-check-double',
  'fa-check-square',
  'fa-chess',
  'fa-chess-bishop',
  'fa-chess-bishop-alt',
  'fa-chess-board',
  'fa-chess-clock',
  'fa-chess-clock-alt',
  'fa-chess-king',
  'fa-chess-king-alt',
  'fa-chess-knight',
  'fa-chess-knight-alt',
  'fa-chess-pawn',
  'fa-chess-pawn-alt',
  'fa-chess-queen',
  'fa-chess-queen-alt',
  'fa-chess-rook',
  'fa-chess-rook-alt',
  'fa-chevron-circle-down',
  'fa-chevron-circle-left',
  'fa-chevron-circle-right',
  'fa-chevron-circle-up',
  'fa-chevron-double-down',
  'fa-chevron-double-left',
  'fa-chevron-double-right',
  'fa-chevron-double-up',
  'fa-chevron-down',
  'fa-chevron-left',
  'fa-chevron-right',
  'fa-chevron-square-down',
  'fa-chevron-square-left',
  'fa-chevron-square-right',
  'fa-chevron-square-up',
  'fa-chevron-up',
  'fa-child',
  'fa-church',
  'fa-circle',
  'fa-circle-notch',
  'fa-clipboard',
  'fa-clipboard-check',
  'fa-clipboard-list',
  'fa-clipboard-prescription',
  'fa-clock',
  'fa-clone',
  'fa-closed-captioning',
  'fa-cloud',
  'fa-cloud-download',
  'fa-cloud-download-alt',
  'fa-cloud-upload',
  'fa-cloud-upload-alt',
  'fa-club',
  'fa-cocktail',
  'fa-code',
  'fa-code-branch',
  'fa-code-commit',
  'fa-code-merge',
  'fa-coffee',
  'fa-cog',
  'fa-cogs',
  'fa-coins',
  'fa-columns',
  'fa-comment',
  'fa-comment-alt',
  'fa-comment-alt-check',
  'fa-comment-alt-dots',
  'fa-comment-alt-edit',
  'fa-comment-alt-exclamation',
  'fa-comment-alt-lines',
  'fa-comment-alt-minus',
  'fa-comment-alt-plus',
  'fa-comment-alt-slash',
  'fa-comment-alt-smile',
  'fa-comment-alt-times',
  'fa-comment-check',
  'fa-comment-dots',
  'fa-comment-edit',
  'fa-comment-exclamation',
  'fa-comment-lines',
  'fa-comment-minus',
  'fa-comment-plus',
  'fa-comment-slash',
  'fa-comment-smile',
  'fa-comment-times',
  'fa-comments',
  'fa-comments-alt',
  'fa-compact-disc',
  'fa-compass',
  'fa-compass-slash',
  'fa-compress',
  'fa-compress-alt',
  'fa-compress-wide',
  'fa-concierge-bell',
  'fa-container-storage',
  'fa-conveyor-belt',
  'fa-conveyor-belt-alt',
  'fa-cookie',
  'fa-cookie-bite',
  'fa-copy',
  'fa-copyright',
  'fa-couch',
  'fa-credit-card',
  'fa-credit-card-blank',
  'fa-credit-card-front',
  'fa-cricket',
  'fa-crop',
  'fa-crop-alt',
  'fa-crosshairs',
  'fa-crow',
  'fa-crown',
  'fa-cube',
  'fa-cubes',
  'fa-curling',
  'fa-cut',
  'fa-database',
  'fa-deaf',
  'fa-desktop',
  'fa-desktop-alt',
  'fa-diagnoses',
  'fa-diamond',
  'fa-dice',
  'fa-dice-five',
  'fa-dice-four',
  'fa-dice-one',
  'fa-dice-six',
  'fa-dice-three',
  'fa-dice-two',
  'fa-digital-tachograph',
  'fa-diploma',
  'fa-directions',
  'fa-divide',
  'fa-dizzy',
  'fa-dna',
  'fa-do-not-enter',
  'fa-dollar-sign',
  'fa-dolly',
  'fa-dolly-empty',
  'fa-dolly-flatbed',
  'fa-dolly-flatbed-alt',
  'fa-dolly-flatbed-empty',
  'fa-donate',
  'fa-door-closed',
  'fa-door-open',
  'fa-dot-circle',
  'fa-dove',
  'fa-download',
  'fa-drafting-compass',
  'fa-draw-circle',
  'fa-draw-polygon',
  'fa-draw-square',
  'fa-drum',
  'fa-drum-steelpan',
  'fa-dumbbell',
  'fa-ear',
  'fa-edit',
  'fa-eject',
  'fa-ellipsis-h',
  'fa-ellipsis-h-alt',
  'fa-ellipsis-v',
  'fa-ellipsis-v-alt',
  'fa-engine-warning',
  'fa-envelope',
  'fa-envelope-open',
  'fa-envelope-square',
  'fa-equals',
  'fa-eraser',
  'fa-euro-sign',
  'fa-exchange',
  'fa-exchange-alt',
  'fa-exclamation',
  'fa-exclamation-circle',
  'fa-exclamation-square',
  'fa-exclamation-triangle',
  'fa-expand',
  'fa-expand-alt',
  'fa-expand-arrows',
  'fa-expand-arrows-alt',
  'fa-expand-wide',
  'fa-external-link',
  'fa-external-link-alt',
  'fa-external-link-square',
  'fa-external-link-square-alt',
  'fa-eye',
  'fa-eye-dropper',
  'fa-eye-slash',
  'fa-fast-backward',
  'fa-fast-forward',
  'fa-fax',
  'fa-feather',
  'fa-feather-alt',
  'fa-female',
  'fa-field-hockey',
  'fa-fighter-jet',
  'fa-file',
  'fa-file-alt',
  'fa-file-archive',
  'fa-file-audio',
  'fa-file-certificate',
  'fa-file-check',
  'fa-file-code',
  'fa-file-contract',
  'fa-file-download',
  'fa-file-edit',
  'fa-file-excel',
  'fa-file-exclamation',
  'fa-file-export',
  'fa-file-image',
  'fa-file-import',
  'fa-file-invoice',
  'fa-file-invoice-dollar',
  'fa-file-medical',
  'fa-file-medical-alt',
  'fa-file-minus',
  'fa-file-pdf',
  'fa-file-plus',
  'fa-file-powerpoint',
  'fa-file-prescription',
  'fa-file-signature',
  'fa-file-times',
  'fa-file-upload',
  'fa-file-video',
  'fa-file-word',
  'fa-fill',
  'fa-fill-drip',
  'fa-film',
  'fa-film-alt',
  'fa-filter',
  'fa-fingerprint',
  'fa-fire',
  'fa-fire-extinguisher',
  'fa-first-aid',
  'fa-fish',
  'fa-flag',
  'fa-flag-checkered',
  'fa-flask',
  'fa-flushed',
  'fa-folder',
  'fa-folder-open',
  'fa-font',
  'fa-football-ball',
  'fa-football-helmet',
  'fa-forklift',
  'fa-forward',
  'fa-fragile',
  'fa-frog',
  'fa-frown',
  'fa-frown-open',
  'fa-futbol',
  'fa-gamepad',
  'fa-gas-pump',
  'fa-gas-pump-slash',
  'fa-gavel',
  'fa-gem',
  'fa-genderless',
  'fa-gift',
  'fa-glass-martini',
  'fa-glass-martini-alt',
  'fa-glasses',
  'fa-glasses-alt',
  'fa-globe',
  'fa-globe-africa',
  'fa-globe-americas',
  'fa-globe-asia',
  'fa-globe-stand',
  'fa-golf-ball',
  'fa-golf-club',
  'fa-graduation-cap',
  'fa-greater-than',
  'fa-greater-than-equal',
  'fa-grimace',
  'fa-grin',
  'fa-grin-alt',
  'fa-grin-beam',
  'fa-grin-beam-sweat',
  'fa-grin-hearts',
  'fa-grin-squint',
  'fa-grin-squint-tears',
  'fa-grin-stars',
  'fa-grin-tears',
  'fa-grin-tongue',
  'fa-grin-tongue-squint',
  'fa-grin-tongue-wink',
  'fa-grin-wink',
  'fa-grip-horizontal',
  'fa-grip-vertical',
  'fa-h-square',
  'fa-h1',
  'fa-h2',
  'fa-h3',
  'fa-hand-heart',
  'fa-hand-holding',
  'fa-hand-holding-box',
  'fa-hand-holding-heart',
  'fa-hand-holding-seedling',
  'fa-hand-holding-usd',
  'fa-hand-holding-water',
  'fa-hand-lizard',
  'fa-hand-paper',
  'fa-hand-peace',
  'fa-hand-point-down',
  'fa-hand-point-left',
  'fa-hand-point-right',
  'fa-hand-point-up',
  'fa-hand-pointer',
  'fa-hand-receiving',
  'fa-hand-rock',
  'fa-hand-scissors',
  'fa-hand-spock',
  'fa-hands',
  'fa-hands-heart',
  'fa-hands-helping',
  'fa-hands-usd',
  'fa-handshake',
  'fa-handshake-alt',
  'fa-hashtag',
  'fa-hdd',
  'fa-heading',
  'fa-headphones',
  'fa-headphones-alt',
  'fa-headset',
  'fa-heart',
  'fa-heart-circle',
  'fa-heart-rate',
  'fa-heart-square',
  'fa-heartbeat',
  'fa-helicopter',
  'fa-hexagon',
  'fa-highlighter',
  'fa-history',
  'fa-hockey-puck',
  'fa-hockey-sticks',
  'fa-home',
  'fa-home-heart',
  'fa-hospital',
  'fa-hospital-alt',
  'fa-hospital-symbol',
  'fa-hot-tub',
  'fa-hotel',
  'fa-hourglass',
  'fa-hourglass-end',
  'fa-hourglass-half',
  'fa-hourglass-start',
  'fa-i-cursor',
  'fa-id-badge',
  'fa-id-card',
  'fa-id-card-alt',
  'fa-image',
  'fa-images',
  'fa-inbox',
  'fa-inbox-in',
  'fa-inbox-out',
  'fa-indent',
  'fa-industry',
  'fa-industry-alt',
  'fa-infinity',
  'fa-info',
  'fa-info-circle',
  'fa-info-square',
  'fa-inhaler',
  'fa-inventory',
  'fa-italic',
  'fa-jack-o-lantern',
  'fa-joint',
  'fa-key',
  'fa-keyboard',
  'fa-kidneys',
  'fa-kiss',
  'fa-kiss-beam',
  'fa-kiss-wink-heart',
  'fa-kiwi-bird',
  'fa-lamp',
  'fa-language',
  'fa-laptop',
  'fa-laptop-code',
  'fa-laugh',
  'fa-laugh-beam',
  'fa-laugh-squint',
  'fa-laugh-wink',
  'fa-layer-group',
  'fa-layer-minus',
  'fa-layer-plus',
  'fa-leaf',
  'fa-leaf-heart',
  'fa-lemon',
  'fa-less-than',
  'fa-less-than-equal',
  'fa-level-down',
  'fa-level-down-alt',
  'fa-level-up',
  'fa-level-up-alt',
  'fa-life-ring',
  'fa-lightbulb',
  'fa-link',
  'fa-lips',
  'fa-lira-sign',
  'fa-list',
  'fa-list-alt',
  'fa-list-ol',
  'fa-list-ul',
  'fa-location',
  'fa-location-arrow',
  'fa-location-circle',
  'fa-location-slash',
  'fa-lock',
  'fa-lock-alt',
  'fa-lock-open',
  'fa-lock-open-alt',
  'fa-long-arrow-alt-down',
  'fa-long-arrow-alt-left',
  'fa-long-arrow-alt-right',
  'fa-long-arrow-alt-up',
  'fa-long-arrow-down',
  'fa-long-arrow-left',
  'fa-long-arrow-right',
  'fa-long-arrow-up',
  'fa-loveseat',
  'fa-low-vision',
  'fa-luchador',
  'fa-luggage-cart',
  'fa-lungs',
  'fa-magic',
  'fa-magnet',
  'fa-male',
  'fa-map',
  'fa-map-marked',
  'fa-map-marked-alt',
  'fa-map-marker',
  'fa-map-marker-alt',
  'fa-map-marker-alt-slash',
  'fa-map-marker-check',
  'fa-map-marker-edit',
  'fa-map-marker-exclamation',
  'fa-map-marker-minus',
  'fa-map-marker-plus',
  'fa-map-marker-question',
  'fa-map-marker-slash',
  'fa-map-marker-smile',
  'fa-map-marker-times',
  'fa-map-pin',
  'fa-map-signs',
  'fa-marker',
  'fa-mars',
  'fa-mars-double',
  'fa-mars-stroke',
  'fa-mars-stroke-h',
  'fa-mars-stroke-v',
  'fa-medal',
  'fa-medkit',
  'fa-meh',
  'fa-meh-blank',
  'fa-meh-rolling-eyes',
  'fa-memory',
  'fa-mercury',
  'fa-microchip',
  'fa-microphone',
  'fa-microphone-alt',
  'fa-microphone-alt-slash',
  'fa-microphone-slash',
  'fa-microscope',
  'fa-minus',
  'fa-minus-circle',
  'fa-minus-hexagon',
  'fa-minus-octagon',
  'fa-minus-square',
  'fa-mobile',
  'fa-mobile-alt',
  'fa-mobile-android',
  'fa-mobile-android-alt',
  'fa-money-bill',
  'fa-money-bill-alt',
  'fa-money-bill-wave',
  'fa-money-bill-wave-alt',
  'fa-money-check',
  'fa-money-check-alt',
  'fa-monitor-heart-rate',
  'fa-monument',
  'fa-moon',
  'fa-mortar-pestle',
  'fa-motorcycle',
  'fa-mouse-pointer',
  'fa-music',
  'fa-neuter',
  'fa-newspaper',
  'fa-not-equal',
  'fa-notes-medical',
  'fa-object-group',
  'fa-object-ungroup',
  'fa-octagon',
  'fa-oil-can',
  'fa-oil-temp',
  'fa-outdent',
  'fa-paint-brush',
  'fa-paint-brush-alt',
  'fa-paint-roller',
  'fa-palette',
  'fa-pallet',
  'fa-pallet-alt',
  'fa-paper-plane',
  'fa-paperclip',
  'fa-parachute-box',
  'fa-paragraph',
  'fa-parking',
  'fa-parking-circle',
  'fa-parking-circle-slash',
  'fa-parking-slash',
  'fa-passport',
  'fa-paste',
  'fa-pause',
  'fa-pause-circle',
  'fa-paw',
  'fa-pen',
  'fa-pen-alt',
  'fa-pen-fancy',
  'fa-pen-nib',
  'fa-pen-square',
  'fa-pencil',
  'fa-pencil-alt',
  'fa-pencil-paintbrush',
  'fa-pencil-ruler',
  'fa-pennant',
  'fa-people-carry',
  'fa-percent',
  'fa-percentage',
  'fa-person-carry',
  'fa-person-dolly',
  'fa-person-dolly-empty',
  'fa-phone',
  'fa-phone-plus',
  'fa-phone-slash',
  'fa-phone-square',
  'fa-phone-volume',
  'fa-piggy-bank',
  'fa-pills',
  'fa-plane',
  'fa-plane-alt',
  'fa-plane-arrival',
  'fa-plane-departure',
  'fa-play',
  'fa-play-circle',
  'fa-plug',
  'fa-plus',
  'fa-plus-circle',
  'fa-plus-hexagon',
  'fa-plus-octagon',
  'fa-plus-square',
  'fa-podcast',
  'fa-poo',
  'fa-poop',
  'fa-portrait',
  'fa-pound-sign',
  'fa-power-off',
  'fa-prescription',
  'fa-prescription-bottle',
  'fa-prescription-bottle-alt',
  'fa-print',
  'fa-procedures',
  'fa-project-diagram',
  'fa-puzzle-piece',
  'fa-qrcode',
  'fa-question',
  'fa-question-circle',
  'fa-question-square',
  'fa-quidditch',
  'fa-quote-left',
  'fa-quote-right',
  'fa-racquet',
  'fa-ramp-loading',
  'fa-random',
  'fa-receipt',
  'fa-rectangle-landscape',
  'fa-rectangle-portrait',
  'fa-rectangle-wide',
  'fa-recycle',
  'fa-redo',
  'fa-redo-alt',
  'fa-registered',
  'fa-repeat',
  'fa-repeat-1',
  'fa-repeat-1-alt',
  'fa-repeat-alt',
  'fa-reply',
  'fa-reply-all',
  'fa-retweet',
  'fa-retweet-alt',
  'fa-ribbon',
  'fa-road',
  'fa-robot',
  'fa-rocket',
  'fa-route',
  'fa-route-highway',
  'fa-route-interstate',
  'fa-rss',
  'fa-rss-square',
  'fa-ruble-sign',
  'fa-ruler',
  'fa-ruler-combined',
  'fa-ruler-horizontal',
  'fa-ruler-triangle',
  'fa-ruler-vertical',
  'fa-rupee-sign',
  'fa-sad-cry',
  'fa-sad-tear',
  'fa-save',
  'fa-scalpel',
  'fa-scalpel-path',
  'fa-scanner',
  'fa-scanner-keyboard',
  'fa-scanner-touchscreen',
  'fa-school',
  'fa-screwdriver',
  'fa-scrubber',
  'fa-search',
  'fa-search-minus',
  'fa-search-plus',
  'fa-seedling',
  'fa-server',
  'fa-shapes',
  'fa-share',
  'fa-share-all',
  'fa-share-alt',
  'fa-share-alt-square',
  'fa-share-square',
  'fa-shekel-sign',
  'fa-shield',
  'fa-shield-alt',
  'fa-shield-check',
  'fa-ship',
  'fa-shipping-fast',
  'fa-shipping-timed',
  'fa-shoe-prints',
  'fa-shopping-bag',
  'fa-shopping-basket',
  'fa-shopping-cart',
  'fa-shower',
  'fa-shuttle-van',
  'fa-shuttlecock',
  'fa-sign',
  'fa-sign-in',
  'fa-sign-in-alt',
  'fa-sign-language',
  'fa-sign-out',
  'fa-sign-out-alt',
  'fa-signal',
  'fa-signature',
  'fa-sitemap',
  'fa-skeleton',
  'fa-skull',
  'fa-sliders-h',
  'fa-sliders-h-square',
  'fa-sliders-v',
  'fa-sliders-v-square',
  'fa-smile',
  'fa-smile-beam',
  'fa-smile-plus',
  'fa-smile-wink',
  'fa-smoking',
  'fa-smoking-ban',
  'fa-snowflake',
  'fa-solar-panel',
  'fa-sort',
  'fa-sort-alpha-down',
  'fa-sort-alpha-up',
  'fa-sort-amount-down',
  'fa-sort-amount-up',
  'fa-sort-down',
  'fa-sort-numeric-down',
  'fa-sort-numeric-up',
  'fa-sort-up',
  'fa-spa',
  'fa-space-shuttle',
  'fa-spade',
  'fa-spinner',
  'fa-spinner-third',
  'fa-splotch',
  'fa-spray-can',
  'fa-square',
  'fa-square-full',
  'fa-stamp',
  'fa-star',
  'fa-star-exclamation',
  'fa-star-half',
  'fa-star-half-alt',
  'fa-star-of-life',
  'fa-steering-wheel',
  'fa-step-backward',
  'fa-step-forward',
  'fa-stethoscope',
  'fa-sticky-note',
  'fa-stomach',
  'fa-stop',
  'fa-stop-circle',
  'fa-stopwatch',
  'fa-store',
  'fa-store-alt',
  'fa-stream',
  'fa-street-view',
  'fa-strikethrough',
  'fa-stroopwafel',
  'fa-subscript',
  'fa-subway',
  'fa-suitcase',
  'fa-suitcase-rolling',
  'fa-sun',
  'fa-superscript',
  'fa-surprise',
  'fa-swatchbook',
  'fa-swimmer',
  'fa-swimming-pool',
  'fa-sync',
  'fa-sync-alt',
  'fa-syringe',
  'fa-table',
  'fa-table-tennis',
  'fa-tablet',
  'fa-tablet-alt',
  'fa-tablet-android',
  'fa-tablet-android-alt',
  'fa-tablet-rugged',
  'fa-tablets',
  'fa-tachometer',
  'fa-tachometer-alt',
  'fa-tachometer-alt-average',
  'fa-tachometer-alt-fast',
  'fa-tachometer-alt-fastest',
  'fa-tachometer-alt-slow',
  'fa-tachometer-alt-slowest',
  'fa-tachometer-average',
  'fa-tachometer-fast',
  'fa-tachometer-fastest',
  'fa-tachometer-slow',
  'fa-tachometer-slowest',
  'fa-tag',
  'fa-tags',
  'fa-tape',
  'fa-tasks',
  'fa-taxi',
  'fa-teeth',
  'fa-teeth-open',
  'fa-tennis-ball',
  'fa-terminal',
  'fa-text-height',
  'fa-text-width',
  'fa-th',
  'fa-th-large',
  'fa-th-list',
  'fa-theater-masks',
  'fa-thermometer',
  'fa-thermometer-empty',
  'fa-thermometer-full',
  'fa-thermometer-half',
  'fa-thermometer-quarter',
  'fa-thermometer-three-quarters',
  'fa-thumbs-down',
  'fa-thumbs-up',
  'fa-thumbtack',
  'fa-ticket',
  'fa-ticket-alt',
  'fa-times',
  'fa-times-circle',
  'fa-times-hexagon',
  'fa-times-octagon',
  'fa-times-square',
  'fa-tint',
  'fa-tint-slash',
  'fa-tire',
  'fa-tire-flat',
  'fa-tire-pressure-warning',
  'fa-tire-rugged',
  'fa-tired',
  'fa-toggle-off',
  'fa-toggle-on',
  'fa-toolbox',
  'fa-tooth',
  'fa-toothbrush',
  'fa-trademark',
  'fa-traffic-cone',
  'fa-traffic-light',
  'fa-traffic-light-go',
  'fa-traffic-light-slow',
  'fa-traffic-light-stop',
  'fa-train',
  'fa-transgender',
  'fa-transgender-alt',
  'fa-trash',
  'fa-trash-alt',
  'fa-tree',
  'fa-tree-alt',
  'fa-triangle',
  'fa-trophy',
  'fa-trophy-alt',
  'fa-truck',
  'fa-truck-container',
  'fa-truck-couch',
  'fa-truck-loading',
  'fa-truck-monster',
  'fa-truck-moving',
  'fa-truck-pickup',
  'fa-truck-ramp',
  'fa-tshirt',
  'fa-tty',
  'fa-tv',
  'fa-tv-retro',
  'fa-umbrella',
  'fa-umbrella-beach',
  'fa-underline',
  'fa-undo',
  'fa-undo-alt',
  'fa-universal-access',
  'fa-university',
  'fa-unlink',
  'fa-unlock',
  'fa-unlock-alt',
  'fa-upload',
  'fa-usd-circle',
  'fa-usd-square',
  'fa-user',
  'fa-user-alt',
  'fa-user-alt-slash',
  'fa-user-astronaut',
  'fa-user-check',
  'fa-user-circle',
  'fa-user-clock',
  'fa-user-cog',
  'fa-user-edit',
  'fa-user-friends',
  'fa-user-graduate',
  'fa-user-lock',
  'fa-user-md',
  'fa-user-minus',
  'fa-user-ninja',
  'fa-user-plus',
  'fa-user-secret',
  'fa-user-shield',
  'fa-user-slash',
  'fa-user-tag',
  'fa-user-tie',
  'fa-user-times',
  'fa-users',
  'fa-users-class',
  'fa-users-cog',
  'fa-utensil-fork',
  'fa-utensil-knife',
  'fa-utensil-spoon',
  'fa-utensils',
  'fa-utensils-alt',
  'fa-vector-square',
  'fa-venus',
  'fa-venus-double',
  'fa-venus-mars',
  'fa-vial',
  'fa-vials',
  'fa-video',
  'fa-video-plus',
  'fa-video-slash',
  'fa-volleyball-ball',
  'fa-volume-down',
  'fa-volume-mute',
  'fa-volume-off',
  'fa-volume-up',
  'fa-walking',
  'fa-wallet',
  'fa-warehouse',
  'fa-warehouse-alt',
  'fa-watch',
  'fa-watch-fitness',
  'fa-weight',
  'fa-weight-hanging',
  'fa-wheelchair',
  'fa-whistle',
  'fa-wifi',
  'fa-window',
  'fa-window-alt',
  'fa-window-close',
  'fa-window-maximize',
  'fa-window-minimize',
  'fa-window-restore',
  'fa-wine-glass',
  'fa-wine-glass-alt',
  'fa-won-sign',
  'fa-wrench',
  'fa-x-ray',
  'fa-yen-sign',
] as const;

export const iconBrands = [
  'fa-500px',
  'fa-accessible-icon',
  'fa-accusoft',
  'fa-acquisitions-incorporated',
  'fa-adn',
  'fa-adversal',
  'fa-affiliatetheme',
  'fa-airbnb',
  'fa-algolia',
  'fa-alipay',
  'fa-amazon',
  'fa-amazon-pay',
  'fa-amilia',
  'fa-android',
  'fa-angellist',
  'fa-angrycreative',
  'fa-angular',
  'fa-app-store',
  'fa-app-store-ios',
  'fa-apper',
  'fa-apple',
  'fa-apple-pay',
  'fa-artstation',
  'fa-asymmetrik',
  'fa-atlassian',
  'fa-audible',
  'fa-autoprefixer',
  'fa-avianex',
  'fa-aviato',
  'fa-aws',
  'fa-bandcamp',
  'fa-battle-net',
  'fa-behance',
  'fa-behance-square',
  'fa-bimobject',
  'fa-bitbucket',
  'fa-bitcoin',
  'fa-bity',
  'fa-black-tie',
  'fa-blackberry',
  'fa-blogger',
  'fa-blogger-b',
  'fa-bluetooth',
  'fa-bluetooth-b',
  'fa-bootstrap',
  'fa-btc',
  'fa-buffer',
  'fa-buromobelexperte',
  'fa-buy-n-large',
  'fa-buysellads',
  'fa-canadian-maple-leaf',
  'fa-cc-amazon-pay',
  'fa-cc-amex',
  'fa-cc-apple-pay',
  'fa-cc-diners-club',
  'fa-cc-discover',
  'fa-cc-jcb',
  'fa-cc-mastercard',
  'fa-cc-paypal',
  'fa-cc-stripe',
  'fa-cc-visa',
  'fa-centercode',
  'fa-centos',
  'fa-chrome',
  'fa-chromecast',
  'fa-cloudflare',
  'fa-cloudscale',
  'fa-cloudsmith',
  'fa-cloudversify',
  'fa-codepen',
  'fa-codiepie',
  'fa-confluence',
  'fa-connectdevelop',
  'fa-contao',
  'fa-cotton-bureau',
  'fa-cpanel',
  'fa-creative-commons',
  'fa-creative-commons-by',
  'fa-creative-commons-nc',
  'fa-creative-commons-nc-eu',
  'fa-creative-commons-nc-jp',
  'fa-creative-commons-nd',
  'fa-creative-commons-pd',
  'fa-creative-commons-pd-alt',
  'fa-creative-commons-remix',
  'fa-creative-commons-sa',
  'fa-creative-commons-sampling',
  'fa-creative-commons-sampling-plus',
  'fa-creative-commons-share',
  'fa-creative-commons-zero',
  'fa-critical-role',
  'fa-css3',
  'fa-css3-alt',
  'fa-cuttlefish',
  'fa-d-and-d',
  'fa-d-and-d-beyond',
  'fa-dailymotion',
  'fa-dashcube',
  'fa-deezer',
  'fa-delicious',
  'fa-deploydog',
  'fa-deskpro',
  'fa-dev',
  'fa-deviantart',
  'fa-dhl',
  'fa-diaspora',
  'fa-digg',
  'fa-digital-ocean',
  'fa-discord',
  'fa-discourse',
  'fa-dochub',
  'fa-docker',
  'fa-draft2digital',
  'fa-dribbble',
  'fa-dribbble-square',
  'fa-dropbox',
  'fa-drupal',
  'fa-dyalog',
  'fa-earlybirds',
  'fa-ebay',
  'fa-edge',
  'fa-edge-legacy',
  'fa-elementor',
  'fa-ello',
  'fa-ember',
  'fa-empire',
  'fa-envira',
  'fa-erlang',
  'fa-ethereum',
  'fa-etsy',
  'fa-evernote',
  'fa-expeditedssl',
  'fa-facebook',
  'fa-facebook-f',
  'fa-facebook-messenger',
  'fa-facebook-square',
  'fa-fantasy-flight-games',
  'fa-fedex',
  'fa-fedora',
  'fa-figma',
  'fa-firefox',
  'fa-firefox-browser',
  'fa-first-order',
  'fa-first-order-alt',
  'fa-firstdraft',
  'fa-flickr',
  'fa-flipboard',
  'fa-fly',
  'fa-font-awesome',
  'fa-font-awesome-alt',
  'fa-font-awesome-flag',
  'fa-fonticons',
  'fa-fonticons-fi',
  'fa-fort-awesome',
  'fa-fort-awesome-alt',
  'fa-forumbee',
  'fa-foursquare',
  'fa-free-code-camp',
  'fa-freebsd',
  'fa-fulcrum',
  'fa-galactic-republic',
  'fa-galactic-senate',
  'fa-get-pocket',
  'fa-gg',
  'fa-gg-circle',
  'fa-git',
  'fa-git-alt',
  'fa-git-square',
  'fa-github',
  'fa-github-alt',
  'fa-github-square',
  'fa-gitkraken',
  'fa-gitlab',
  'fa-gitter',
  'fa-glide',
  'fa-glide-g',
  'fa-gofore',
  'fa-goodreads',
  'fa-goodreads-g',
  'fa-google',
  'fa-google-drive',
  'fa-google-pay',
  'fa-google-play',
  'fa-google-plus',
  'fa-google-plus-g',
  'fa-google-plus-square',
  'fa-google-wallet',
  'fa-gratipay',
  'fa-grav',
  'fa-gripfire',
  'fa-grunt',
  'fa-guilded',
  'fa-gulp',
  'fa-hacker-news',
  'fa-hacker-news-square',
  'fa-hackerrank',
  'fa-hips',
  'fa-hire-a-helper',
  'fa-hive',
  'fa-hooli',
  'fa-hornbill',
  'fa-hotjar',
  'fa-houzz',
  'fa-html5',
  'fa-hubspot',
  'fa-ideal',
  'fa-imdb',
  'fa-innosoft',
  'fa-instagram',
  'fa-instagram-square',
  'fa-instalod',
  'fa-intercom',
  'fa-internet-explorer',
  'fa-invision',
  'fa-ioxhost',
  'fa-itch-io',
  'fa-itunes',
  'fa-itunes-note',
  'fa-java',
  'fa-jedi-order',
  'fa-jenkins',
  'fa-jira',
  'fa-joget',
  'fa-joomla',
  'fa-js',
  'fa-js-square',
  'fa-jsfiddle',
  'fa-kaggle',
  'fa-keybase',
  'fa-keycdn',
  'fa-kickstarter',
  'fa-kickstarter-k',
  'fa-korvue',
  'fa-laravel',
  'fa-lastfm',
  'fa-lastfm-square',
  'fa-leanpub',
  'fa-less',
  'fa-line',
  'fa-linkedin',
  'fa-linkedin-in',
  'fa-linode',
  'fa-linux',
  'fa-lyft',
  'fa-magento',
  'fa-mailchimp',
  'fa-mandalorian',
  'fa-markdown',
  'fa-mastodon',
  'fa-maxcdn',
  'fa-mdb',
  'fa-medapps',
  'fa-medium',
  'fa-medium-m',
  'fa-medrt',
  'fa-meetup',
  'fa-megaport',
  'fa-mendeley',
  'fa-microblog',
  'fa-microsoft',
  'fa-mix',
  'fa-mixcloud',
  'fa-mixer',
  'fa-mizuni',
  'fa-modx',
  'fa-monero',
  'fa-napster',
  'fa-neos',
  'fa-nimblr',
  'fa-node',
  'fa-node-js',
  'fa-npm',
  'fa-ns8',
  'fa-nutritionix',
  'fa-octopus-deploy',
  'fa-odnoklassniki',
  'fa-odnoklassniki-square',
  'fa-old-republic',
  'fa-opencart',
  'fa-openid',
  'fa-opera',
  'fa-optin-monster',
  'fa-orcid',
  'fa-osi',
  'fa-page4',
  'fa-pagelines',
  'fa-palfed',
  'fa-patreon',
  'fa-paypal',
  'fa-penny-arcade',
  'fa-perbyte',
  'fa-periscope',
  'fa-phabricator',
  'fa-phoenix-framework',
  'fa-phoenix-squadron',
  'fa-php',
  'fa-pied-piper',
  'fa-pied-piper-alt',
  'fa-pied-piper-hat',
  'fa-pied-piper-pp',
  'fa-pied-piper-square',
  'fa-pinterest',
  'fa-pinterest-p',
  'fa-pinterest-square',
  'fa-playstation',
  'fa-product-hunt',
  'fa-pushed',
  'fa-python',
  'fa-qq',
  'fa-quinscape',
  'fa-quora',
  'fa-r-project',
  'fa-raspberry-pi',
  'fa-ravelry',
  'fa-react',
  'fa-reacteurope',
  'fa-readme',
  'fa-rebel',
  'fa-red-river',
  'fa-reddit',
  'fa-reddit-alien',
  'fa-reddit-square',
  'fa-redhat',
  'fa-renren',
  'fa-replyd',
  'fa-researchgate',
  'fa-resolving',
  'fa-rev',
  'fa-rocketchat',
  'fa-rockrms',
  'fa-rust',
  'fa-safari',
  'fa-salesforce',
  'fa-sass',
  'fa-schlix',
  'fa-scribd',
  'fa-searchengin',
  'fa-sellcast',
  'fa-sellsy',
  'fa-servicestack',
  'fa-shirtsinbulk',
  'fa-shopify',
  'fa-shopware',
  'fa-simplybuilt',
  'fa-sistrix',
  'fa-sith',
  'fa-sketch',
  'fa-skyatlas',
  'fa-skype',
  'fa-slack',
  'fa-slack-hash',
  'fa-slideshare',
  'fa-snapchat',
  'fa-snapchat-ghost',
  'fa-snapchat-square',
  'fa-soundcloud',
  'fa-sourcetree',
  'fa-speakap',
  'fa-speaker-deck',
  'fa-spotify',
  'fa-wordpress',
  'fa-wordpress-simple',
  'fa-wpbeginner',
  'fa-wpexplorer',
  'fa-windows',
  'fa-wix',
  'fa-wolf-pack-battalion',
  'fa-y-combinator',
  'fa-yahoo',
  'fa-yandex',
  'fa-yandex-international',
  'fa-yelp',
  'fa-yoast',
  'fa-youtube',
  'fa-youtube-square',
  'fa-zhihu',
  'fa-xbox',
  'fa-xing',
  'fa-xing-square',
  'fa-wpforms',
  'fa-weebly',
  'fa-weibo',
  'fa-weixin',
  'fa-whatsapp',
  'fa-whatsapp-square',
  'fa-whmcs',
  'fa-vuejs',
  'fa-viber',
  'fa-vimeo',
  'fa-vimeo-square',
  'fa-vimeo-v',
  'fa-vine',
  'fa-vk',
  'fa-vnv',
  'fa-wikipedia-w',
  'fa-viacoin',
  'fa-viadeo',
  'fa-viadeo-square',
  'fa-vaadin',
  'fa-ussunnah',
  'fa-untappd',
  'fa-usb',
  'fa-uniregistry',
  'fa-tumblr',
  'fa-tumblr-square',
  'fa-twitch',
  'fa-twitter',
  'fa-twitter-square',
  'fa-typo3',
  'fa-uber',
  'fa-uikit',
  'fa-trello',
  'fa-trade-federation',
  'fa-themeco',
  'fa-themeisle',
  'fa-teamspeak',
  'fa-telegram',
  'fa-telegram-plane',
  'fa-tencent-weibo',
  'fa-superpowers',
  'fa-supple',
  'fa-stripe',
  'fa-stripe-s',
  'fa-studiovinari',
  'fa-stumbleupon',
  'fa-stumbleupon-circle',
  'fa-strava',
  'fa-squarespace',
  'fa-stack-exchange',
  'fa-stack-overflow',
  'fa-staylinked',
  'fa-steam',
  'fa-steam-square',
  'fa-steam-symbol',
  'fa-sticker-mule',
  'fa-tiktok',
] as const;
