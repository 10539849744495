import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { getTrashPagesOfAtom, loadMoreTrashPagesOfAtom } from 'services/ThemeService/Logic/getPagesOfThemeTrash';
import { getActionType } from 'wiloke-react-core/utils';
import { getTrashPagesOfTheme, loadMoreTrashPagesOfTheme } from '../actions/actionThemeTrash';

const size = 20;

function* handleGet({ payload: { searchKey } }: ReturnType<typeof getTrashPagesOfTheme.request>) {
  try {
    const response: SagaReturnType<typeof getTrashPagesOfAtom> = yield retry(3, 1000, getTrashPagesOfAtom, { size, label: searchKey });
    yield put(getTrashPagesOfTheme.success({ data: response, hasNextPage: response.length < size ? false : true }));
  } catch (error) {
    console.log(error);
    yield put(getTrashPagesOfTheme.failure(undefined));
  }
}

export function* watchGetPagesOfTrashTheme() {
  yield takeLatest(getActionType(getTrashPagesOfTheme.request), handleGet);
}

function* handleLoadMore({ payload: { searchKey, cursor } }: ReturnType<typeof loadMoreTrashPagesOfTheme.request>) {
  try {
    const response: SagaReturnType<typeof loadMoreTrashPagesOfAtom> = yield retry(3, 1000, loadMoreTrashPagesOfAtom, {
      size,
      label: searchKey,
      cursor,
    });
    yield put(loadMoreTrashPagesOfTheme.success({ data: response, hasNextPage: response.length < size ? false : true }));
  } catch (error) {
    console.log(error);
    yield put(loadMoreTrashPagesOfTheme.failure(undefined));
  }
}

export function* watchLoadMorePagesOfTrashTheme() {
  yield takeLatest(getActionType(loadMoreTrashPagesOfTheme.request), handleLoadMore);
}
