import TurndownService from 'turndown';

const turndownService = new TurndownService();
const AnchorTagName = document.createElement('a').tagName;

turndownService.addRule('RemoveAllTagNoneAnchor', {
  filter: node => {
    return node.tagName !== AnchorTagName;
  },
  replacement: content => {
    return content;
  },
});

turndownService.addRule('ShopifyLink', {
  filter: 'a',
  replacement: (content, node) => {
    const href = (node as HTMLAnchorElement).getAttribute('href');
    if (!!href) {
      return `[${content}](${href})`;
    }
    return content;
  },
});

export const htmlToShopifyMarkdown = (html: string) => {
  return turndownService.turndown(html);
};
