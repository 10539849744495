import { userApis } from 'services/UserService/apis';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const getGlobalDiscountRequest = async () => {
  const { role } = getUserInfo();
  if (role === 'admin' || role === 'dev' || role === 'support') {
    const response = await userApis.coupon.adminApi.getGlobalDiscount();
    return response;
  } else {
    const response = await userApis.coupon.userApi.getGlobalDiscount();
    return response;
  }
};
