import { PartnerAPI } from 'types/Partner';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { userApis } from '../apis';

interface DeleteParams extends Pick<PartnerAPI, 'id'> {}

export const deletePartnerAPI = async (data: DeleteParams) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    const response = await userApis.partner.adminApi.deletePartner({ id: data.id });
    return response;
  }
  throw new RoleException();
};
