import { getCacheValue, setCacheValue, getUniqId } from '../../../CacheShopifyFieldId';
import { ISCHEMA_SettingArticlePicker } from '../@types/ISCHEMA_SettingArticlePicker';
import { ISETTING_SettingArticlePicker } from '../@types/ISETTING_SettingArticlePicker';

export const toShopifyFieldId = ({ field, parentField, section }: ISCHEMA_SettingArticlePicker | ISETTING_SettingArticlePicker) => {
  const theoryShopifyFieldId = parentField ? `${parentField.name}___${field.name}` : field.name;
  if (theoryShopifyFieldId.length >= 25) {
    const valueInCache = getCacheValue({ theoryShopifyFieldId, section });
    if (valueInCache) {
      return valueInCache;
    } else {
      const newKey = `VEDA_articlePicker_${getUniqId('articlePicker')}`;
      setCacheValue({ theoryShopifyFieldId, section }, newKey);
      return newKey;
    }
  }
  return theoryShopifyFieldId;
};
