import { Button } from '@wiloke/ui';
import TextInput from 'components/TextInput';
import { FC } from 'react';
import { Space, View } from 'wiloke-react-core';

export const DevLoginPage: FC = () => {
  return (
    <View container css={{ maxWidth: '400px', margin: '100px auto' }}>
      <View tagName="form" action="https://login.vedabuilder.com?action=requestShopifyCode" method="GET">
        <input type="hidden" name="action" value="requestShopifyCode" />
        <input type="hidden" name="mode" value="dev" />
        <TextInput type="text" name="shop" placeholder="EG: vedabuilder.myshopify.com" block />
        <Space size={10} />
        <Button type="submit" radius={6} block>
          Login
        </Button>
      </View>
    </View>
  );
};
