import { AxiosResponse } from 'axios';
import { BE_ThemeAtom } from 'services/ThemeService/Atom/types';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface Params {
  commandId: string;
}

interface ResponseSuccess {
  message: string;
  info: BE_ThemeAtom;
}

export const getTrash = async ({ commandId }: Params) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({ url: `${baseUrl}/trash/original/${commandId}`, method: 'get' });
  return response.data;
};
