import { importFileCSV } from 'store/global/importCSV/actionsImport';
import { ActionTypes, createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';

interface Actions {
  type: 'setImportMessage';
  payload: string;
}

type ExtraActions = ActionTypes<typeof importFileCSV>;

interface State {
  importStatus: Status;
  message: string;
}

export const sliceImportCSV = createSlice<State, Actions, ExtraActions>({
  name: '@Builder',
  initialState: {
    importStatus: 'idle',
    message: '',
  },
  reducers: [
    handleAction('setImportMessage', ({ state, action }) => {
      state.message = action.payload;
    }),
  ],
  extraReducers: [
    handleAction('@Builder/importFileCSV/request', ({ state }) => {
      state.importStatus = 'loading';
    }),
    handleAction('@Builder/importFileCSV/success', ({ state, action }) => {
      state.importStatus = 'success';
      state.message = action.payload.message;
    }),
    handleAction('@Builder/importFileCSV/failure', ({ state, action }) => {
      state.importStatus = 'failure';
      state.message = action.payload.message;
    }),
  ],
});

export const { setImportMessage } = sliceImportCSV.actions;

export const importCSVSelector = (state: AppState) => state.importCSV;

export const useSetImportMessage = createDispatchAction(setImportMessage);
