import { instructionSlice } from 'store/global/vedaInstruction/instructionReducer';
import { VedaInstruction } from 'types/Instruction';
import { createDispatchAction } from 'wiloke-react-core/utils';

export interface SetVedaInstructionAction {
  type: 'setVedaInstructionAction';
  payload: {
    vedaInstruction: VedaInstruction[];
  };
}

export const { setVedaInstructionAction } = instructionSlice.actions;

export const useSetVedaInstructionAction = createDispatchAction(setVedaInstructionAction);
