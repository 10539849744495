import {
  createPageUseCase,
  deletePageUseCase,
  getPageUseCases,
  updatePageUseCase,
} from 'containers/Admin/Management/Pages/store/actions/actionPageUseCase';
import { PageCategory } from 'types/Page';
import { ActionTypes, createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';

type ExtraActions = ActionTypes<typeof createPageUseCase | typeof deletePageUseCase | typeof getPageUseCases | typeof updatePageUseCase>;

interface Actions {
  type: 'editProductPageUseCase';
  payload: AtLeast<PageCategory, 'commandId'>;
}

interface State {
  useCases: PageCategory[];
  getStatus: Status;
  createStatus: Status;
  deleteStatus: Record<string, Status>;
  updateStatus: Record<string, Status>;
}

export const slicePageUseCase = createSlice<State, Actions, ExtraActions>({
  name: '@Page',
  initialState: {
    useCases: [],
    createStatus: 'idle',
    getStatus: 'idle',
    deleteStatus: {},
    updateStatus: {},
  },
  reducers: [
    handleAction('editProductPageUseCase', ({ state, action }) => {
      state.useCases = state.useCases.map(item => {
        if (item.commandId === action.payload.commandId) {
          return {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });
    }),
  ],
  extraReducers: [
    // C
    handleAction('@Page/createPageUseCase/request', ({ state }) => {
      state.createStatus = 'loading';
    }),
    handleAction('@Page/createPageUseCase/success', ({ state, action }) => {
      state.createStatus = 'success';
      state.useCases = state.useCases.concat(action.payload);
    }),
    handleAction('@Page/createPageUseCase/failure', ({ state }) => {
      state.createStatus = 'failure';
    }),
    // R
    handleAction('@Page/getPageUseCases/request', ({ state }) => {
      state.getStatus = 'loading';
    }),
    handleAction('@Page/getPageUseCases/success', ({ state, action }) => {
      state.getStatus = 'success';
      state.useCases = action.payload;
    }),
    handleAction('@Page/getPageUseCases/failure', ({ state }) => {
      state.getStatus = 'failure';
    }),
    // U
    handleAction('@Page/updatePageUseCase/request', ({ state, action }) => {
      state.updateStatus[action.payload.commandId] = 'loading';
    }),
    handleAction('@Page/updatePageUseCase/success', ({ state, action }) => {
      state.updateStatus[action.payload.commandId] = 'success';
      state.useCases = state.useCases.map(item => {
        if (item.commandId === action.payload.commandId) {
          return {
            ...item,
            commandId: action.payload.commandId,
            slug: action.payload.name,
            title: action.payload.description,
            belongsToPageTypes: action.payload.belongsToPageTypes,
          };
        }
        return item;
      });
    }),
    handleAction('@Page/updatePageUseCase/failure', ({ state, action }) => {
      state.updateStatus[action.payload.commandId] = 'failure';
    }),
    // D
    handleAction('@Page/deletePageUseCase/request', ({ state, action }) => {
      state.deleteStatus[action.payload.commandId] = 'loading';
    }),
    handleAction('@Page/deletePageUseCase/success', ({ state, action }) => {
      state.deleteStatus[action.payload.commandId] = 'success';
      state.useCases = state.useCases.filter(item => item.commandId !== action.payload.commandId);
    }),
    handleAction('@Page/deletePageUseCase/failure', ({ state, action }) => {
      state.deleteStatus[action.payload.commandId] = 'failure';
    }),
  ],
});

export const { editProductPageUseCase } = slicePageUseCase.actions;
export const useEditProductPageUseCase = createDispatchAction(editProductPageUseCase);

export const pageUseCaseSelector = (state: AppState) => state.adminDashboard.pageUseCase;
