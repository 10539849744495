import { CodeSpliting } from 'components/CodeSpliting';
import SectionPageHeader from 'components/SectionPageHeader';
import { ModalCreateNormalPage, ModalDeletePageDashboard } from 'containers/Admin/Modals';
import { useChangeModalAdminSettings } from 'containers/Admin/Modals/store';
import { Dashboard } from 'containers/Dashboard';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { homePageSelector } from 'store/selectors';
import { i18n } from 'translation';
import { BUILDER_HOME_PAGE_MAIN_CONTENT } from 'utils/constants/chunkIds';
import { View } from 'wiloke-react-core';
import { useGetHomePageItems } from './store';
import { DashboardPageSettings } from '../DashboardPageSettings/DashboardPageSettings';

export const HomePage: FC = () => {
  const changeSettings = useChangeModalAdminSettings();
  const { filterType, search } = useSelector(homePageSelector);
  const getItems = useGetHomePageItems();

  useEffect(() => {
    getItems.request({ s: search, pageType: 'home', filterType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filterType]);

  return (
    <Dashboard
      pageType="home"
      Modals={() => (
        <>
          <ModalCreateNormalPage pageType="home" />
          <ModalDeletePageDashboard pageType="home" />
          <DashboardPageSettings pageType="home" />
        </>
      )}
      Content={() => (
        <View>
          <SectionPageHeader
            pageType="home"
            title={i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.home') })}
            description={i18n.t('adminDashboard.page_description.home')}
            onClick={() => {
              changeSettings({ createNormalPage: true });
            }}
          />
          <CodeSpliting
            component={() => import(/* webpackChunkName: "BuilderHomePageMainContent", webpackPrefetch: true */ './MainContent')}
            CHUNK_ID={BUILDER_HOME_PAGE_MAIN_CONTENT}
          />
        </View>
      )}
    />
  );
};
