import { FC, useRef, useState } from 'react';
import { ColorScheme } from 'types/Result';
import { FontAwesome, View } from 'wiloke-react-core';
import { Popover } from 'antd';
import * as styles from './styles';
import { Colors } from './Colors';
import { Button, ScrollBars, Tooltip } from '@wiloke/ui';
import { i18n } from 'translation';
import { at } from 'utils/at';
import { BusinessIcon } from 'components/PlanBadge';

export interface ColorSchemeFieldUIProps {
  value: string | null;
  data?: ColorScheme[];
  disabled?: boolean;
  onDisable?: () => void;
  onChange?: (value: string) => void;
  onEditColorScheme?: () => void;
}

export const ColorSchemeFieldUI: FC<ColorSchemeFieldUIProps> = ({ disabled = false, value, data = [], onChange, onEditColorScheme, onDisable }) => {
  const [open, setOpen] = useState(false);
  const current = data.find(item => item.cssClassName === value) || at(data, 0);
  const containerRef = useRef<HTMLElement>(null);
  const [containerWidth, setContainerWidth] = useState(280);

  if (!data.length || !current) {
    return null;
  }

  const { colors, uniqueName } = current;
  const content = (
    <ScrollBars css={{ maxHeight: '400px' }}>
      {data.map((item, index) => {
        const _disabled = index !== 0 && index !== 1 && disabled;
        return (
          <View
            key={item.cssClassName}
            css={styles.dropdownItem(current.cssClassName === item.cssClassName, _disabled)}
            onClick={() => {
              if (_disabled) {
                onDisable?.();
              } else {
                onChange?.(item.cssClassName);
              }
              setOpen(false);
            }}
          >
            <Colors colors={item.colors} />
            {item.uniqueName}

            {_disabled && (
              <View css={styles.iconDisable} backgroundColor="tertiary">
                <BusinessIcon />
              </View>
            )}
          </View>
        );
      })}
    </ScrollBars>
  );

  return (
    <Popover
      visible={open}
      onVisibleChange={val => {
        setOpen(val);
        if (containerRef.current) {
          setContainerWidth(containerRef.current.offsetWidth);
        }
      }}
      trigger="click"
      placement="bottom"
      content={content}
      overlayStyle={{ width: containerWidth }}
      overlayClassName="color-scheme-popover"
    >
      <View ref={containerRef} css={styles.container}>
        <Colors colors={colors} />
        <View css={styles.buttonContent}>
          <View tagName="h6">{uniqueName}</View>
          <View css={styles.buttonInner}>
            <Button
              css={styles.buttonChange}
              radius={4}
              backgroundColor="light"
              backgroundColorHover="gray2"
              color="gray9"
              borderWidth={1}
              borderStyle="solid"
              borderColor="gray3"
              size="extra-small"
            >
              {i18n.t('general.change')}
            </Button>
            <Tooltip portal text={i18n.t('general.edit')}>
              <Button
                css={styles.buttonEdit}
                radius={4}
                backgroundColor="light"
                backgroundColorHover="gray2"
                color="gray9"
                borderWidth={1}
                borderStyle="solid"
                borderColor="gray3"
                size="extra-small"
                onClick={event => {
                  event.stopPropagation();
                  onEditColorScheme?.();
                }}
              >
                <FontAwesome type="far" name="cog" />
              </Button>
            </Tooltip>
          </View>
        </View>
      </View>
    </Popover>
  );
};
