import { ProductTheme } from 'types/Theme';
import { createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';

type Actions =
  | {
      type: 'setVisibleInstallShopifyTheme';
      payload: boolean;
    }
  | {
      type: 'setVisibleInstallEvantoTheme';
      payload: boolean;
    }
  | {
      type: 'setInstallThemeProduct';
      payload: ProductTheme | undefined;
    };

interface State {
  visibleInstallShopifyTheme: boolean;
  visibleInstallEvantoTheme: boolean;
  themeProduct: ProductTheme | undefined;
}

export const sliceModalInstallTheme = createSlice<State, Actions>({
  name: '@Global',
  initialState: {
    visibleInstallEvantoTheme: false,
    visibleInstallShopifyTheme: false,
    themeProduct: undefined,
  },
  reducers: [
    handleAction('setVisibleInstallEvantoTheme', ({ state, action }) => {
      state.visibleInstallEvantoTheme = action.payload;
    }),
    handleAction('setVisibleInstallShopifyTheme', ({ state, action }) => {
      state.visibleInstallShopifyTheme = action.payload;
    }),
    handleAction('setInstallThemeProduct', ({ state, action }) => {
      state.themeProduct = action.payload;
    }),
  ],
});

export const { setVisibleInstallEvantoTheme, setVisibleInstallShopifyTheme, setInstallThemeProduct } = sliceModalInstallTheme.actions;

export const sliceModalInstallThemeSelector = (state: AppState) => state.modalInstallTheme;

export const useSetVisibleInstallEvantoTheme = createDispatchAction(setVisibleInstallEvantoTheme);
export const useSetVisibleInstallShopifyTheme = createDispatchAction(setVisibleInstallShopifyTheme);
export const useSetInstallThemeProduct = createDispatchAction(setInstallThemeProduct);
