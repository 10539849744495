import { AxiosResponse } from 'axios';
import { CategoryDataResponse } from 'services/SectionService/types';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface GetCategoriesOfAtom {
  type: 'GET FIRST PAGE';
  cacheVersion: number | null;
}

interface LoadmoreCategoriesOfAtom {
  type: 'LOADMORE';
  cacheVersion: number | null;
  cursor: string;
}

interface ResponseSuccess {
  message: string;
  info: CategoryDataResponse[];
}

/** API được sử dụng để lấy về tất các category của "Addon Atom" */
export const getCategoriesOfAtom = async ({ type, cacheVersion, ...params }: GetCategoriesOfAtom | LoadmoreCategoriesOfAtom) => {
  if (type === 'GET FIRST PAGE') {
    const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
      url: `${baseUrl}`,
      params: {
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
      },
    });
    return response.data;
  } else {
    const { cursor } = params as LoadmoreCategoriesOfAtom;
    const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
      url: `${baseUrl}/search`,
      params: {
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
        after: cursor,
      },
    });
    return response.data;
  }
};
