import { i18n } from 'translation';
import { FontAwesomeName } from 'wiloke-react-core';

interface TabData {
  label: string;
  value: string;
  icon: FontAwesomeName;
}

export const tabData: TabData[] = [
  {
    label: i18n.t('adminDashboard.all'),
    value: 'all',
    icon: 'folder-open',
  },
  {
    label: i18n.t('general.saved'),
    value: 'my-template',
    icon: 'heart',
  },
];

export const filterPageType = () => {
  return [
    {
      label: i18n.t('adminDashboard.landing_and_more'),
      value: 'page',
    },
    {
      label: i18n.t('adminDashboard.home'),
      value: 'home',
    },
    {
      label: i18n.t('adminDashboard.product'),
      value: 'product',
    },
    {
      label: i18n.t('adminDashboard.collection'),
      value: 'collection',
    },
    {
      label: i18n.t('adminDashboard.article'),
      value: 'article',
    },
    {
      label: i18n.t('adminDashboard.blog'),
      value: 'blog',
    },
    {
      label: i18n.t('adminDashboard.cart'),
      value: 'cart',
    },
    {
      label: i18n.t('adminDashboard.search'),
      value: 'search',
    },
    {
      label: i18n.t('adminDashboard.notFound'),
      value: 'pageNotFound',
    },
    {
      label: i18n.t('adminDashboard.password'),
      value: 'password',
    },
    {
      label: i18n.t('adminDashboard.customersLogin'),
      value: 'login',
    },
    {
      label: i18n.t('adminDashboard.reset_password'),
      value: 'resetPassword',
    },
    {
      label: i18n.t('adminDashboard.active_account'),
      value: 'activateAccount',
    },
    {
      label: i18n.t('adminDashboard.register'),
      value: 'register',
    },
    {
      label: i18n.t('adminDashboard.account'),
      value: 'account',
    },
    {
      label: i18n.t('adminDashboard.order'),
      value: 'order',
    },
    {
      label: i18n.t('adminDashboard.addresses'),
      value: 'addresses',
    },
    // {
    //   label: 'Gift Card',
    //   value: 'giftCard',
    // },
    {
      label: i18n.t('adminDashboard.collection_listing'),
      value: 'collections',
    },
  ];
};
