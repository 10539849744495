import { Title } from '@wiloke/ui';
import TextInput from 'components/TextInput';
import { CheckStorefrontPassword } from 'containers/BuilderPage/components/CheckStorefrontPassword';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { Space, View } from 'wiloke-react-core';

export const StorePassword = () => {
  const { email } = getUserInfo();

  return (
    <View>
      <View row css={{ padding: '20px' }} radius={6} backgroundColor="gray1">
        <View columns={[5]}>
          <Space size={10} />
          <Title title={'Account Email'} titleCss={{ fontSize: '20px' }} />
        </View>

        <View columns={[7]}>
          <TextInput block value={email} disabled />
        </View>
      </View>
      <Space size={20} />
      <View row css={{ padding: '20px' }} radius={6} backgroundColor="gray1">
        <View columns={[5]}>
          <Space size={10} />
          <Title title={'Storefront password'} titleCss={{ fontSize: '20px' }} />
        </View>

        <View columns={[7]}>
          <CheckStorefrontPassword />
        </View>
      </View>
    </View>
  );
};
