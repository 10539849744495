import { AsyncComponent, MyModal } from '@wiloke/ui';
import TextInput from 'components/TextInput';
import { RenewButton } from 'containers/Admin/PageBuilder/DashboardPageSettings/components/RenewButton';
import withDebounce from 'hocs/withDebounce';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useChangeBlogKey, useGetBlogs, useLoadMoreBlogs } from 'store/actions/shopify';

import Active from 'components/Active';
import ProductCard from 'components/ProductCard';
import { defaultBlogDataState } from 'store/reducers/shopify/reducerBlogs';
import { defaultPickerRelateShopifySelector, shopifySelector } from 'store/selectors';
import { i18n } from 'translation';
import { ArticlePageLiquidVariable } from 'types/Page';
import { SHOPIFY_UNKNOWN_HANDLE, SHOPIFY_UNKNOWN_ID } from 'utils/constants/constants';
import { ActivityIndicator, GridSmart, View, ViewportTracking } from 'wiloke-react-core';
import { useSettingsShopifyPicker } from './slice';
import * as styles from './styles';

const DebounceInput = withDebounce(TextInput, 'value', 'onValueChange', 300);

interface CreateArticlePickerProps {
  onOk?: () => void;
  onCancel?: () => void;
}

export const CreateArticlePicker: FC<CreateArticlePickerProps> = ({ onOk, onCancel }) => {
  const { blogs, searchKey } = useSelector(shopifySelector.blogs);
  const blogData = blogs[searchKey] || defaultBlogDataState;

  // const { data: articleState } = useSelector(shopifySelector.articles);
  const {
    data: { article },
  } = useSelector(defaultPickerRelateShopifySelector);

  const { visibleArticle: visibleBlog, slugArticle: slugBlog } = useSelector(shopifySelector.multiShopifyPicker);

  const changeKey = useChangeBlogKey();
  const changeSettingsShopify = useSettingsShopifyPicker();

  const getBlogs = useGetBlogs();
  const loadMoreBlogs = useLoadMoreBlogs();

  useEffect(() => {
    if (visibleBlog) {
      getBlogs.request({ search: searchKey, shouldGetArticle: false, refresh: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleBlog, searchKey]);

  const _handleSelectBlog = ({
    handle,
    id,
    blogHandle,
    blogId,
    featuredImage,
  }: {
    handle: string;
    id: number;
    blogId: number;
    blogHandle: string;
    featuredImage?: string;
  }) => (isSelect: boolean) => {
    if (isSelect) {
      const article: Exclude<ArticlePageLiquidVariable, undefined> = { handle, itemId: id, blogHandle, blogId, featuredImg: featuredImage };
      changeSettingsShopify({
        slugArticle: [...slugBlog, article],
      });
    } else {
      changeSettingsShopify({
        slugArticle: slugBlog.filter(item => item.handle !== handle),
      });
    }
  };

  const handleSelectAll = ({ blogHandle, blogId }: { blogId: number; blogHandle: string }) => (isSelect: boolean) => {
    if (isSelect) {
      changeSettingsShopify({
        slugArticle: [
          {
            blogId,
            blogHandle,
            featuredImg: undefined,
            handle: !!article && typeof article !== 'string' ? article.handle : SHOPIFY_UNKNOWN_HANDLE,
            itemId: !!article && typeof article !== 'string' ? article.itemId : SHOPIFY_UNKNOWN_ID,
          },
        ],
      });
    } else {
      changeSettingsShopify({
        slugArticle: slugBlog.filter(item => item.blogHandle !== blogHandle),
      });
    }
  };

  const handleSearch = (val: string) => {
    changeKey(val);
  };

  const handleClose = () => {
    if (!!onCancel) {
      onCancel?.();
    } else {
      changeSettingsShopify({
        visibleArticle: false,
      });
    }
  };

  const handleOk = () => {
    // NOTE: Nếu không chọn article nào trong blog này thì mặc định sẽ là chọn all article thuộc blog này ?? hoặc không
    if (!!onOk) {
      onOk?.();
    } else {
      changeSettingsShopify({
        visibleArticle: false,
      });
    }
  };

  const renderLoadMoreBlog = () => {
    if (blogData.hasNextPage || blogData.loadMoreStatus === 'loading') {
      return (
        <ViewportTracking
          offsetBottom={0}
          onEnterViewport={() => {
            if (blogData.requestStatus === 'success') {
              loadMoreBlogs.request({ search: searchKey, shouldGetArticle: true });
            }
          }}
        >
          <View css={{ padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <ActivityIndicator />
          </View>
        </ViewportTracking>
      );
    }
  };

  const renderBlogSuccess = () => {
    return (
      <View css={styles.renderBlogSuccessContainer}>
        <GridSmart columnWidth={200} columnGap={10}>
          {blogData.data.map(item => {
            const isActive = slugBlog.map(item => item.blogHandle).includes(item.handle);

            return (
              <Active
                disabledMulti
                onActive={handleSelectAll({ blogHandle: item.handle, blogId: item.id })}
                variant="style2"
                active={isActive}
                key={item.id}
              >
                <ProductCard imageAspectRatio={16 / 9} imageSrc={''} title={item.title} />
              </Active>
            );
          })}
        </GridSmart>
        {renderLoadMoreBlog()}
      </View>
    );
  };

  return (
    <MyModal
      headerText={i18n.t('adminDashboard.blog')}
      bodyCss={{
        height: '80%',
      }}
      isVisible={visibleBlog}
      depsHeightRecalculation={blogData.requestStatus}
      cancelText={i18n.t('general.remove')}
      okText={i18n.t('general.accept')}
      scrollDisabled
      onCancel={handleClose}
      onOk={handleOk}
      size="medium"
    >
      <View css={{ height: '100%' }}>
        <View
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '8px',
            columnGap: '10px',
          }}
        >
          <DebounceInput
            block
            sizeInput="medium"
            placeholder={i18n.t('general.search_with', { text: i18n.t('adminDashboard.blog') })}
            css={{ flex: '1' }}
            value={searchKey}
            onValueChange={handleSearch}
          />
          <RenewButton variant="blog" css={{ width: '44px', height: '44px', borderRadius: '6px' }} />
        </View>

        <AsyncComponent status={blogData.requestStatus} isEmpty={blogData.data.length === 0} Success={renderBlogSuccess()} />
      </View>
    </MyModal>
  );
};
