import { handleWaitForSocketOfSyncShopifyFulfill } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { call, retry, SagaReturnType } from 'redux-saga/effects';
import { handleSaveInBuilderPage, shopifyConnectionService } from 'services/ShopifyConnection';

export interface SyncGlobalOfThemeInDashboard {
  themeParams: ReturnType<typeof handleSaveInBuilderPage>['themeParams'];
}

export interface SyncGlobalOfThemeInDashboardResult {
  statusSyncGlobalOfThemeInDashboard: SyncFulfillStatus;
  isNeedIgnoreReportError: boolean;
}

export function* syncGlobalOfThemeInDashboard({ themeParams }: SyncGlobalOfThemeInDashboard) {
  yield retry(3, 1000, shopifyConnectionService.writeGlobalOfThemeToShopify, { ...themeParams, isPreview: false });
  const socketSyncGlobalOfThemeInDashboard: SagaReturnType<typeof handleWaitForSocketOfSyncShopifyFulfill> = yield call(
    handleWaitForSocketOfSyncShopifyFulfill,
    'Ghi file khi update theme settings ngoài dashboard',
  );
  return {
    statusSyncGlobalOfThemeInDashboard: socketSyncGlobalOfThemeInDashboard.statusSync,
    isNeedIgnoreReportError: socketSyncGlobalOfThemeInDashboard.isNeedIgnoreReportError,
  } as SyncGlobalOfThemeInDashboardResult;
}
