import { handleWaitForSocketOfSyncShopifyFulfill } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { call, retry, SagaReturnType } from 'redux-saga/effects';
import { shopifyConnectionService } from 'services/ShopifyConnection';
import { handleUpdateShopifyPagesInDashboard } from 'services/ShopifyConnection/handleUpdateShopifyPagesInDashboard';
import { getUserInfo } from 'utils/functions/getUserInfo';

export interface UpdateShopifyPages {
  updateShopifyPagesParams: ReturnType<typeof handleUpdateShopifyPagesInDashboard>;
}

export interface UpdateShopifyPagesResult {
  statusSync: SyncFulfillStatus;
  isNeedIgnoreReportError: boolean;
}

export function* updateShopifyPages({ updateShopifyPagesParams }: UpdateShopifyPages) {
  const { unpublishAllParameters, updateShopifyPagesParameters } = updateShopifyPagesParams;
  const { name } = getUserInfo();

  if (unpublishAllParameters) {
    yield retry(3, 1000, shopifyConnectionService.writePageToShopify, {
      ...unpublishAllParameters,
      isPreview: false,
      author: { name },
    });
    const socketSyncUnpublishAll: SagaReturnType<typeof handleWaitForSocketOfSyncShopifyFulfill> = yield call(
      handleWaitForSocketOfSyncShopifyFulfill,
      'Update shopifyPages ngoài dashboard/Từ "all" -> 1 vài => Unpublish tất cả',
    );
    if (socketSyncUnpublishAll.statusSync !== 'success') {
      return {
        statusSync: 'failure',
        isNeedIgnoreReportError: socketSyncUnpublishAll.isNeedIgnoreReportError,
      } as UpdateShopifyPagesResult;
    }
  }
  yield retry(3, 1000, shopifyConnectionService.writePageToShopify, {
    ...updateShopifyPagesParameters,
    isPreview: false,
    author: { name },
  });
  const socketSync: SagaReturnType<typeof handleWaitForSocketOfSyncShopifyFulfill> = yield call(
    handleWaitForSocketOfSyncShopifyFulfill,
    'Update shopifyPages ngoài dashboard',
  );

  return {
    statusSync: socketSync.statusSync,
    isNeedIgnoreReportError: socketSync.isNeedIgnoreReportError,
  } as UpdateShopifyPagesResult;
}
