import { PageType } from 'types/Page';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';

interface GetPageClient {
  commandId: string;
  type?: PageType;
}

export const getPageClientUseAtomTemplate = async ({ commandId, type }: GetPageClient) => {
  const { lastCache } = getUserInfo();
  const response = await pageApis.atom.userApi.page.getAtom({ commandId, cacheVersion: lastCache, type });

  return {
    ...response.info,
    parentCommandId: response.info.commandId,
    type: response.info.type,
  };
};
