import { adapterGetMegaMenus } from 'services/MegaMenuService/Adapters/adapterGetMegaMenus';
import { PageSection } from 'types/Sections';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

interface GetMegamenusOfThemeDraft {
  megamenuCommandIds: string[];
}

export const getMegamenusOfSectionInThemeDraft = async ({ megamenuCommandIds }: GetMegamenusOfThemeDraft): Promise<PageSection[]> => {
  if (!megamenuCommandIds.length) {
    return [];
  }
  const { role } = getUserInfo();
  if (role === 'admin') {
    const responses = await themeApis.atom.adminApi.megamenu.getMegamenusOfDraft({ commandIds: megamenuCommandIds });
    return adapterGetMegaMenus(responses.info);
  }
  if (role === 'dev' || role === 'support') {
    const responses = await themeApis.atom.devApi.megamenu.getMegamenusOfDraft({ commandIds: megamenuCommandIds });
    return adapterGetMegaMenus(responses.info);
  }
  throw new RoleException();
};
