import { createAction, createAsyncAction, createDispatchAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';
import { Record, SectionInfo } from '../types/Record';

export const getGeneralInfomationOfRecords = createAsyncAction([
  '@ListingUpdatable/getGeneralInfomationOfRecordsRequest',
  '@ListingUpdatable/getGeneralInfomationOfRecordsSuccess',
  '@ListingUpdatable/getGeneralInfomationOfRecordsFailure',
])<{ limit: number; offset: number }, { recordsPaginated: Array<Record['generalInfomation']>; totalRecords: number }, undefined>();
export const useGetGeneralInfomationOfRecords = createDispatchAsyncAction(getGeneralInfomationOfRecords);

export const getMetaDataOfRecord = createAsyncAction([
  '@ListingUpdatable/getMetaDataOfRecordRequest',
  '@ListingUpdatable/getMetaDataOfRecordSuccess',
  '@ListingUpdatable/getMetaDataOfRecordFailure',
])<{ sectionAtomCommandId: string }, { sectionAtomCommandId: string; data: Record['metaData'] }, undefined>();
export const useGetMetaDataOfRecord = createDispatchAsyncAction(getMetaDataOfRecord);

export const deleteRecord = createAsyncAction([
  '@ListingUpdatable/deleteRecordRequest',
  '@ListingUpdatable/deleteRecordSuccess',
  '@ListingUpdatable/deleteRecordFailure',
])<{ record: Record }, { record: Record }, { record: Record }>();
export const useDeleteRecord = createDispatchAsyncAction(deleteRecord);

export const setRecordInteracting = createAction('@ListingUpdatable/setRecordInteracting', (payload: Record | null) => payload);
export const useSetRecordInteracting = createDispatchAction(setRecordInteracting);

export const upgradeSection = createAsyncAction([
  '@ListingUpdatable/upgradeSectionRequest',
  '@ListingUpdatable/upgradeSectionSuccess',
  '@ListingUpdatable/upgradeSectionFailure',
])<
  { sectionAtomCommandId: string; sectionInfo: SectionInfo },
  { sectionAtomCommandId: string; latestSectionInfo: SectionInfo },
  { sectionInfo: SectionInfo }
>();
export const useUpgradeSection = createDispatchAsyncAction(upgradeSection);
