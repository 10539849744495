import { ThemeGeneralSettings } from 'types/Result';
import { PageSection } from 'types/Sections';
import { ErrorOption } from './@types/ErrorOption';
import { ExpectReturnType } from './@types/ExpectReturnType';
import { getHtmlFiles } from './getHtmlFiles';
import { getHtmlPreloaderFile } from './getHtmlPreloaderFile';
import { handleCssInlines } from './utils/handleCssInlines';

interface GetHtmlFilesOfTheme_AllPropertiesOfTheme {
  headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: PageSection[];
  footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: PageSection[];
  themeGeneralSettings: ThemeGeneralSettings;
  errorOption: ErrorOption;
  variant: 'Toàn bộ theme - bao gồm header, footer, theme settings';
}

interface GetHtmlFilesOfTheme_OnlyThemeSettings {
  themeGeneralSettings: ThemeGeneralSettings;
  variant: '1 phần theme - bao gồm theme settings';
}

export const getHtmlFilesOfTheme = (params: GetHtmlFilesOfTheme_AllPropertiesOfTheme | GetHtmlFilesOfTheme_OnlyThemeSettings): ExpectReturnType[] => {
  const { variant } = params;
  if (variant === 'Toàn bộ theme - bao gồm header, footer, theme settings') {
    const {
      footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
      headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
      themeGeneralSettings,
    } = params;
    return [
      getHtmlPreloaderFile(themeGeneralSettings),
      ...handleCssInlines(
        getHtmlFiles({
          ...params,
          sectionsIncludeMegamenuSections: headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
          fileType:
            'liquid của các section thuộc header - bao gồm liquid của section bình thường[đã thế megamenu tag placeholder] (cái này được ghi vào file header.liquid cái mà được render ở theme.liquid)',
        }),
      ),
      ...handleCssInlines(
        getHtmlFiles({
          ...params,
          sectionsIncludeMegamenuSections: footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
          fileType:
            'liquid của các section thuộc footer - bao gồm liquid của section bình thường[đã thế megamenu tag placeholder] (cái này được ghi vào file footer.liquid cái mà được render ở theme.liquid)',
        }),
      ),
    ];
  }
  if (variant === '1 phần theme - bao gồm theme settings') {
    const { themeGeneralSettings } = params;
    return [getHtmlPreloaderFile(themeGeneralSettings)];
  }
  throw new Error('getHtmlFilesOfTheme');
};
