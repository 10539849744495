export const ModalInteractiveTheme = {
  createTheme: 'Créer un thème',
  updateTheme: 'Mettre à jour le thème',
  name: 'Nom',
  featured_image: {
    title: 'Image vedette',
    description: "Lorsque le thème est activé, il y aura une grande image et cette image en sera l'illustration.",
  },
  screenshot_image_1: {
    title: "Capture d'écran 1",
    description:
      "C'est une capture d'écran générale du thème. L'image sera affichée dans 'Modèles de thème' et sera utilisée comme miniature des thèmes en cours de rédaction dans le tableau de bord du thème.",
  },
};
