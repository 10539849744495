import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import { SyncShopifyMessage } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { SyncThemeByResultOfExtractThemeToFileForSyncMessage } from 'packages/SyncThemeByResultOfExtractThemeToFileForSync/hooks/useSocketForSyncThemeByResultOfExtractThemeToFileForSync';
import { i18n } from 'translation';
import fetchAPI from 'utils/functions/fetchAPI';
import { areUndefined } from '../utils';
import { File } from './.types';

export interface WriteHeaderToShopify_BEExpectParameters {
  eventId: string;
  eventType: SyncShopifyMessage['eventType'] | SyncThemeByResultOfExtractThemeToFileForSyncMessage['eventType'];
  assets: {
    files: File[];
  };
}

interface WriteHeaderToShopify_BEResponse {
  message: string;
  info: {
    eventId: string;
    eventType: SyncShopifyMessage['eventType'] | SyncThemeByResultOfExtractThemeToFileForSyncMessage['eventType'];
  };
}

interface WriteHeaderToShopify extends DeepPartial<WriteHeaderToShopify_BEExpectParameters> {
  isPreview: boolean;
}

/**
 * Services thực hiện ghi header vào shopify
 * - Ghi liquid, css, js
 */
export const writeHeaderToShopify = async (data: WriteHeaderToShopify) => {
  if (areUndefined(data.eventId, data.eventType, data.assets)) {
    throw new Error(i18n.t('general.lack_of_params'));
  }
  const res: AxiosResponse<WriteHeaderToShopify_BEResponse> = await fetchAPI.request({
    method: 'PUT',
    params: {
      eventId: data.eventId,
      eventType: data.eventType,
    } as Pick<WriteHeaderToShopify_BEExpectParameters, 'eventId' | 'eventType'>,
    data: data as WriteHeaderToShopify_BEExpectParameters,
    url: data.isPreview
      ? `${configureApp.endpoint['shopify-connections']}/sync/shopify/preview/me/themes/header`
      : `${configureApp.endpoint['shopify-connections']}/sync/shopify/me/themes/header`,
  });
  return res.data.message;
};
