import { Role } from 'routes/types';
import { PLAN_HANDLE } from 'utils/constants/PlanEnum';

type CHECK_STATE = 'pass' | 'invalid';
type ExceptionPlan = 'for-admin-only';

type Plan = PLAN_HANDLE | ExceptionPlan;

export const validatePlan = ({ itemPlan, userPlan, role }: { userPlan: Plan; itemPlan: PLAN_HANDLE; role?: Role }): CHECK_STATE => {
  if (userPlan === 'ultimate' || userPlan === itemPlan || userPlan === 'for-admin-only' || role === 'admin') {
    return 'pass';
  }

  // free
  if (userPlan === 'free' && itemPlan === 'free') {
    return 'pass';
  }
  if (userPlan === 'free' && (itemPlan === 'business' || itemPlan === 'advanced' || itemPlan === 'ultimate')) {
    return 'invalid';
  }

  // business
  if (userPlan === 'business' && (itemPlan === 'free' || itemPlan === 'business')) {
    return 'pass';
  }
  if (userPlan === 'business' && (itemPlan === 'advanced' || itemPlan === 'ultimate')) {
    return 'invalid';
  }

  // advanced
  if (userPlan === 'advanced' && (itemPlan === 'free' || itemPlan === 'business' || itemPlan === 'advanced')) {
    return 'pass';
  }
  if (userPlan === 'advanced' && itemPlan === 'ultimate') {
    return 'invalid';
  }

  return 'invalid';
};

export const checkDowngradePlan = ({ userPlan, itemPlan }: { userPlan: Plan; itemPlan: PLAN_HANDLE }): CHECK_STATE => {
  if (userPlan === 'for-admin-only') {
    return 'pass';
  }

  // Từ ultimate hạ plan xuống
  if (userPlan === 'ultimate' && (itemPlan === 'advanced' || itemPlan === 'business' || itemPlan === 'free')) {
    return 'invalid';
  }

  // Từ advanced hạ plan xuống
  if (userPlan === 'advanced' && (itemPlan === 'business' || itemPlan === 'free')) {
    return 'invalid';
  }

  // Từ business hạ plan xuống
  if (userPlan === 'business' && itemPlan === 'free') {
    return 'invalid';
  }

  return 'pass';
};
