import { SectionCategoryTag } from 'types/Sections';
import { createAsyncAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';

export const getDraftCategories = createAsyncAction([
  '@ChooseTemplate/getDraftCategories/request',
  '@ChooseTemplate/getDraftCategories/success',
  '@ChooseTemplate/getDraftCategories/failure',
])<undefined, { data: SectionCategoryTag[] }, { message: string }>();

export const addDraftCategory = createAsyncAction([
  '@ChooseTemplate/addDraftCategory/request',
  '@ChooseTemplate/addDraftCategory/success',
  '@ChooseTemplate/addDraftCategory/failure',
])<{ description: string; name: string }, SectionCategoryTag, { message: string }>();

export const loadMoreDraftCategories = createAsyncAction([
  '@ChooseTemplate/loadMoreDraftCategories/request',
  '@ChooseTemplate/loadMoreDraftCategories/success',
  '@ChooseTemplate/loadMoreDraftCategories/failure',
])<{ cursor: string }, { data: SectionCategoryTag[] }, { message: string }>();

export const useGetDraftCategories = createDispatchAsyncAction(getDraftCategories);
export const useAddDraftCategory = createDispatchAsyncAction(addDraftCategory);
export const useLoadMoreDraftCategories = createDispatchAsyncAction(loadMoreDraftCategories);
