import { PageCategory } from 'types/Page';
import { createAsyncAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';

export const createPageUseCase = createAsyncAction([
  '@Page/createPageUseCase/request',
  '@Page/createPageUseCase/success',
  '@Page/createPageUseCase/failure',
])<
  {
    name: string;
    description: string;
    belongsToPageTypes?: PageCategory['belongsToPageTypes'];
    onFulfill?: () => void;
  },
  PageCategory,
  undefined
>();

export const getPageUseCases = createAsyncAction(['@Page/getPageUseCases/request', '@Page/getPageUseCases/success', '@Page/getPageUseCases/failure'])<
  { pageType?: string },
  PageCategory[],
  undefined
>();

export const deletePageUseCase = createAsyncAction([
  '@Page/deletePageUseCase/request',
  '@Page/deletePageUseCase/success',
  '@Page/deletePageUseCase/failure',
])<{ commandId: string }, { commandId: string }, { commandId: string }>();

export const updatePageUseCase = createAsyncAction([
  '@Page/updatePageUseCase/request',
  '@Page/updatePageUseCase/success',
  '@Page/updatePageUseCase/failure',
])<
  { commandId: string; description: string; name: string; belongsToPageTypes?: PageCategory['belongsToPageTypes'] },
  { commandId: string; description: string; name: string; belongsToPageTypes?: PageCategory['belongsToPageTypes'] },
  { commandId: string }
>();

export const useCreatePageUseCase = createDispatchAsyncAction(createPageUseCase);
export const useGetPageUseCases = createDispatchAsyncAction(getPageUseCases);
export const useDeletePageUseCase = createDispatchAsyncAction(deletePageUseCase);
export const useUpdatePageUseCase = createDispatchAsyncAction(updatePageUseCase);
