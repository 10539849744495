import { MyModal } from '@wiloke/ui';
import { modalUpdatePlanSelector, useSetVisibleModalUpdatePlan } from 'containers/ModalUpdatePlan/sliceModalUpdatePlan';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetUserPlan } from 'store/global/auth/actions';
import { authSelector } from 'store/selectors';
import { i18n } from 'translation';

function useIsTabVisible() {
  const [isVisible, setIsVisible] = useState(false);

  const handleVisibility = useCallback(() => {
    setIsVisible(!document.hidden);
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibility);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibility);
    };
  }, [handleVisibility]);

  return isVisible;
}

export default useIsTabVisible;

export const ModalUpdatePlan: FC = () => {
  const { visible } = useSelector(modalUpdatePlanSelector);
  const { getUserPlanStatus, plan } = useSelector(authSelector);
  const setVisible = useSetVisibleModalUpdatePlan();
  const getUserInfo = useGetUserPlan();

  const isChangedTab = useIsTabVisible();

  useEffect(() => {
    if (isChangedTab) {
      getUserInfo.request({
        prevPlan: plan.planHandle,
        onFulfill() {
          setVisible(false);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangedTab, plan.planHandle]);

  const handleOke = () => {
    window.open(`${window.location.origin}/pricing`, '_blank');
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <MyModal
      isLoading={getUserPlanStatus === 'loading'}
      headerText="Upgrade plan"
      isVisible={visible}
      okText={i18n.t('pricing.upgrade')}
      onCancel={handleCancel}
      onOk={handleOke}
    >
      This template is only available in Business plan. Please upgrade to any paid plan to use this one.
    </MyModal>
  );
};
