import { css, Theme } from 'wiloke-react-core';

export const container = ({ colors, fonts }: Theme) => css`
  debug: TopBar_container;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.light};
  border-bottom: 1px solid ${colors.gray3};
  height: 54px;
  padding: 12px;
  font-family: ${fonts.primary};
`;

export const iconWrap = ({ colors }: Theme) => css`
  debug: TopBar_iconWrap;
  background-color: ${colors.light};
  min-width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid ${colors.gray3};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const icon = css`
  debug: TopBar_icon;
  width: 35px;
  vertical-align: middle;
`;

export const divider = ({ colors }: Theme) => css`
  debug: TopBar_divider;
  width: 1px;
  height: 100%;
  background-color: ${colors.gray3};
`;

export const left = css`
  debug: TopBar_left;
  display: flex;
  flex-direction: row;
`;

export const right = css`
  debug: TopBar_right;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const itemFull = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const selectTheme = css`
  :global {
    #veda-root .DuplicateThemeBuilder .ant-select-selector {
      padding-top: 2px !important;
      padding-bottom: 2px !important;
      border-radius: 4px 0px 0px 4px !important;
      border-right: unset !important;
    }

    #veda-root .DuplicateThemeBuilder .ant-select {
      max-width: 164px !important;
    }

    #veda-root .DuplicateThemeBuilder .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 35px !important;
      padding-top: 2px !important;
      padding-bottom: 2px !important;
    }
  }
`;

export const btnDuplicateTheme = ({ colors }: Theme) => css`
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 0px 4px 4px 0px;
  border-left: unset;
  flex-shrink: 0;

  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: {
    0% {
      background-color: ${colors.warning};
      color: ${colors.light};
      border-color: ${colors.warning};
    }
    50% {
      background-color: ${colors.light};
      color: ${colors.gray9};
      border-color: ${colors.gray3};
    }
    100% {
      background-color: ${colors.warning};
      color: ${colors.light};
      border-color: ${colors.warning};
    }
  }
`;

export const btnText = css``;
