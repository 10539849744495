import { AxiosError } from 'axios';
import { handleWaitForSocketOfSyncShopifyFulfill } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { call, put, retry, SagaReturnType, select, takeLatest } from 'redux-saga/effects';
import { shopifyConnectionService } from 'services/ShopifyConnection';
import { restoreThemeToDefault } from 'store/actions/actionRestoreThemeToDefault';
import { setVisibleModalAfterRestore } from 'store/reducers/sliceRestoreToDefault';
import { socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector } from 'store/selectors';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';

function* handleAction({ payload: { eventType, onFulfill } }: ReturnType<typeof restoreThemeToDefault.request>) {
  try {
    const { eventId }: ReturnType<typeof socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector> = yield select(
      socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector,
    );

    yield retry(3, 1000, shopifyConnectionService.restoreThemeToDefault, {
      eventId: eventId ?? '',
      eventType,
    });

    const { statusSync }: SagaReturnType<typeof handleWaitForSocketOfSyncShopifyFulfill> = yield call(
      handleWaitForSocketOfSyncShopifyFulfill,
      'Xóa toàn bộ dữ liệu veda',
    );
    if (statusSync === 'success') {
      yield put(restoreThemeToDefault.success(undefined));
      onFulfill?.();
      yield put(setVisibleModalAfterRestore(true));
    } else {
      yield put(restoreThemeToDefault.failure(undefined));
      onFulfill?.();
    }
  } catch (error) {
    notifyAxiosHandler.handleError(error as AxiosError<ErrorData> | Error);
    yield put(restoreThemeToDefault.failure(undefined));
    onFulfill?.();
  }
}
export function* watchRestoreThemeToDefault() {
  yield takeLatest(getActionType(restoreThemeToDefault.request), handleAction);
}
