import { Button } from '@wiloke/ui';
import { FORMAT } from 'packages/ExtractThemeToFileForSync/utils/getFileName';
import { View } from 'wiloke-react-core';
import { selectSyncThemeByResultOfExtractThemeToFileForSync } from '../../../selectors';
import * as styles from '../../styles';

export interface DescriptionTextProps {
  modalSyncThemeByResultOfExtractThemeToFileForSync: ReturnType<
    typeof selectSyncThemeByResultOfExtractThemeToFileForSync
  >['modalSyncThemeByResultOfExtractThemeToFileForSync'];
}

export const DescriptionText = ({ modalSyncThemeByResultOfExtractThemeToFileForSync }: DescriptionTextProps) => {
  if (modalSyncThemeByResultOfExtractThemeToFileForSync === 'Được mở từ bước extract result') {
    return (
      <View css={{ fontSize: '18px' }}>
        Một file có định dạng{' '}
        <View color="primary" css={{ fontWeight: 500 }} tagName="span">
          "{FORMAT}"
        </View>{' '}
        đã được tự động tải xuống. Kéo nó vào và tiến hành đồng bộ shopify
      </View>
    );
  }
  if (modalSyncThemeByResultOfExtractThemeToFileForSync === 'Được mở bởi click button') {
    return (
      <View css={{ fontSize: '18px' }}>
        <View>
          Một file có định dạng{' '}
          <View color="primary" css={{ fontWeight: 500 }} tagName="span">
            "{FORMAT}"
          </View>{' '}
          đã được tự động tải xuống bởi hành động click{' '}
          <Button css={styles.rightItem} color="light" fontFamily="secondary" backgroundColor="github">
            Đồng bộ theme
          </Button>{' '}
          tại tab trong quá khứ. Kéo nó vào và tiến hành đồng bộ shopify
        </View>
      </View>
    );
  }

  return null;
};
