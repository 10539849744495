import { DevTheme } from 'types/Theme';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { handleGlobalTranslationsNColorSchemesInThemeDraft } from '../Adapter/handleGlobalTranslationsNColorSchemes';
import { themeApis } from '../apis';

interface AppendPageToThemeDraft {
  theme: Required<
    Pick<
      Parameters<typeof themeApis.atom.devApi.theme.updateDraftOfDev>[0]['theme'],
      'commandId' | 'pageCommandIds' | 'featuredImage' | 'screenshot1' | 'label'
    >
  >;
}

export const appendPageToThemeDraft = async ({ theme }: AppendPageToThemeDraft): Promise<DevTheme> => {
  const { role, id } = getUserInfo();
  if (role === 'admin') {
    const response = await themeApis.atom.adminApi.theme.updateDraftOfDev({
      theme: {
        ...theme,
        lastModifiedBy: id,
      },
    });
    return handleGlobalTranslationsNColorSchemesInThemeDraft(response.info);
  }
  if (role === 'dev') {
    const response = await themeApis.atom.devApi.theme.updateDraftOfDev({
      theme: {
        ...theme,
        lastModifiedBy: id,
      },
    });
    return handleGlobalTranslationsNColorSchemesInThemeDraft(response.info);
  }
  throw new RoleException();
};
