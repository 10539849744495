import { linkFonts } from 'components/ShopifyFontsField/linkFonts';
import { Helmet } from 'react-helmet';
import { Option } from 'components/SelectAntd';

interface Props {
  option?: Option & { family: string; shopifyFiles: string[] };
}

export const getFontFaceClause = (option?: Option & { family: string; shopifyFiles: string[] }) => {
  return option?.shopifyFiles
    .map(item => {
      const src = linkFonts[item];
      // VedaFont[Volkhov]+volkhov_n4+normal+400.woff2
      const [family, _handle, style, weight] = item
        .replace('VedaFont', '')
        .replace('.woff2', '')
        .split('+');
      return `
    @font-face {
      font-family: "${family.replace('[', '').replace(']', '')}";
      font-weight: ${weight};
      font-style: ${style};
      font-display: swap;
      src: url("${src}") format("woff2");
    }
  `;
    })
    .join('\n');
};

export const HelmetImportShopifyFont = ({ option }: Props) => {
  return (
    <Helmet>
      <style>{getFontFaceClause(option)}</style>
    </Helmet>
  );
};
