import { AxiosResponse } from 'axios';
import { AddonOfTheme_Atom_N_Client } from 'types/Addons';
import { ToRequiredKeys } from 'utils';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface GetAddonsOfAtom {
  /** CommandId của addon cần lấy dữ liệu */
  commandIds: string[];
  cacheVersion: number | null;

  // Dùng ở trường hợp client install page template
  sessionId?: number;
}

interface ResponseSuccess {
  info: Array<ToRequiredKeys<AddonOfTheme_Atom_N_Client, 'commandId'> | undefined>;
  message: string;
}

/** API được sử dụng để lấy về dữ liệu của addon - được clone từ addon atom - được sử dụng trong page atom theo commandId */
export const getAddonsOfAtom = async ({ commandIds, cacheVersion, sessionId }: GetAddonsOfAtom) => {
  if (commandIds.length) {
    const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
      url: `${baseUrl}/search`,
      params: {
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
        commandIds: commandIds.join(','),
        sessionId,
      },
    });
    return response.data.info;
  }
  return [];
};
