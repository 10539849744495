export const general = {
  settings: '%% text %% Settings',
  setting: '%% text %% Setting',
  status: 'Status',
  enable: 'Enable %% text %%',
  disable: 'Disable %% text %%',
  title: 'Title',
  text: 'Text',
  font_size: 'Font size',
  font_family: 'Font family',
  line_height: 'Line height',
  color: 'Color',
  colors: 'Colors',
  variant: 'Variant',
  variable: 'Variable %% text %% ',
  variables: 'Variables %% text %% ',
  email: 'Email %% text %%',
  name: 'Name',
  button: 'Button',
  placeholder: 'Placeholder',
  subscribe: 'Subscribe',
  coupon: 'Coupon',
  discount_code: 'Discount code',
  discount_placeholder: 'Discount placeholder',
  discount_code_placeholder: 'Discount code placeholder',
  background: 'Background',
  background_image: 'Background image',
  drag: 'Drag',
  edit: 'Edit %% text %%',
  create: 'Create %% text %%',
  duplicate: 'Duplicate',
  remove: 'Remove',
  reset: 'Reset',
  slice: 'Slice',
  slices: 'Slices',
  form: 'Form',
  add: 'Add %% text %%',
  element: 'Element',
  content: 'Content',
  image: 'Image',
  divider: 'Divider',
  social_follows: 'Social follows',
  label: 'Label',
  win_text: 'Win Text',
  win_ratio: 'Win Ratio',
  cancel: 'Cancel',
  save: 'Save %% text %%',
  saving: 'Saving %% text %%',
  saved: 'Saved',
  immediately: 'Immediately',
  seconds: 'Seconds',
  delay: 'Delay',
  all: 'All',
  custommers: 'Visitors',
  animate: 'Animate',
  animation: 'Animation',
  animation_type: 'Animation type',
  seconds_delay: '%% seconds %% seconds delay',
  day: '%% day %% day',
  days: '%% days %% days',
  close: 'Close',
  save_campaign: 'Save campaign',
  goal: 'Goal',
  layout: 'Layout',
  template: 'Template',
  templates: 'Templates',
  editor: 'Editor',
  design: 'Design',
  target: 'Target',
  conversion: 'Conversion',
  full_screen: 'Full screen',
  full_width: 'Full Width',
  desktop: 'Desktop',
  tablet: 'Tablet',
  mobile: 'Mobile',
  size: 'Size',
  paragraph: 'Paragraph',
  heading: 'Heading',
  socials: 'Socials',
  social: 'Social',
  background_color: 'Background color',
  text_color: 'Text color',
  action: 'Action',
  space: 'Space',
  equal_first_value: 'Set all values equal to top value',
  width: 'Width',
  height: 'Height',
  align: 'Align',
  email_placeholder: 'Email placeholder',
  name_placeholder: 'Name placeholder',
  button_color: 'Button Color',
  badge: 'Badge',
  more: 'More',
  pattern: 'Pattern',
  my_photos: 'My Photos',
  placement: 'Placement',
  top: 'Top',
  right: 'Right',
  bottom: 'Bottom',
  left: 'Left',
  center: 'Center',
  link: 'Link',
  title_color: 'Title color',
  sub_message_color: 'Sub message color',
  close_button: 'Close button',
  vertical: 'Vertical',
  horizontal: 'Horizontal',
  show_brand: 'Show %% type %% Brand',
  gdpr: 'Add GDPR compliance Opt-In field',
  gdpr_message: 'GDPR compliance message',
  gdpr_message_color: 'GDPR compliance message color',
  hero_image: 'Hero image',
  image_aspect_ratio: 'Aspect ratio for image',
  desktop_aspect_ratio: 'Aspect ratio on desktop',
  mobile_aspect_ratio: 'Aspect ratio on mobile',
  choose_image: 'Choose image',
  min_width: 'Min width',
  graphic_image: 'Graphic image',
  display: 'Display',
  image_placement: 'Image Placement',
  appearance: 'Appearance',
  previous: 'Previous',
  preview: 'Preview %% text %%',
  prev: 'Prev',
  next: 'Next',
  back: 'Back',
  continue: 'Continue',
  done: 'Done',
  illustrations: 'Illustrations',
  arrow_color: 'Arrow color',
  backdrop_color: 'Backdrop color',
  skip: 'Skip',
  invalid_email: 'Invalid email address',
  enter_email: 'Please enter your email address',
  create_discount_code: 'Please click here to create discount codes',
  discount_expired: 'Your discount code has expired',
  prev_template: 'Previous template you saved',
  undo: 'Undo',
  redo: 'Redo',
  guided_tours: 'Open guided tours in the app',
  clear: 'Clear',
  clear_all: 'Clear all %% text %%',
  missing_information: 'Missing information',
  go_to_setting: 'Go to setting',
  discount_alert: 'You have not entered the discount code. Please go to the Setting > Discount code',
  wheel_alert: 'You have not edited the wheel slice. Please go to the Design > Wheel Slice Settings',
  countdown: 'Countdown',
  expiration_date: 'Expiration date',
  sticker: 'Sticker',
  products: 'Products',
  add_products: 'Add products',
  ok: 'Ok',
  load_more: 'Load more',
  search_products: 'Search for products',
  light_colors: 'Light colors',
  dark_colors: 'Dark colors',
  rgb_colors: 'Rgb colors',
  fonts: 'Fonts',
  font: 'Font',
  up: 'Up',
  down: 'Down',
  add_section_to_top: 'Add section to top',
  add_section_to_bottom: 'Add section to bottom',
  item: 'Item',
  section: 'Section %% text %%',
  sections: 'Sections',
  show: 'Show',
  hide: 'Hide',
  theme: 'Theme %% text %%',
  page: 'Page %% text %%',
  value: '%% text %% Value',
  type: 'Type',
  block: 'Block',
  blocks: 'Blocks',
  children: 'Children',
  field: '%% text %% Field',
  fields: '%% text %% Fields',
  open_close_type: 'Open/close type',
  default: 'Default',
  option: 'Option',
  options: 'Options',
  copy: 'Copy %% text %%',
  copied: 'Copied %% text %%',
  paste: 'Paste %% text %%',
  schema: 'Schema',
  click_to: 'Click to %% text %%',
  export: 'Export',
  import: 'Import',
  delete: 'Delete %% text %%',
  choose: 'Choose %% text %%',
  enter_your_image: 'Enter your image',
  download: 'Download %% text %%',
  upload: 'Upload %% text %%',
  upload_a_file_or_drag_drop: 'Upload a file or drag & drop it here',
  invalid_field: 'Invalid %% text %%',
  padding: 'Padding',
  margin: 'Margin',
  border: 'Border',
  style: 'Style %% text %%',
  typography: 'Typography',
  radius: 'Radius',
  transition: 'Transition',
  duration: 'Duration',
  timing_function: 'Timing function',
  rename: 'Rename',
  save_to_my_section: 'Save to my section',
  save_changes: 'Save changes',
  create_button: 'Create new %% text %%',
  presets: 'Presets %% text %%',
  change_section: 'Change section',
  reorder: 'Reorder',
  select_preloader: 'Select preloader',
  test: 'Test',
  addons: 'Add-ons',
  change: 'Change %% text %%',
  position: 'Position',
  limit_add_addons: 'You have installed 1 add-ons, and it has reached its limit',
  before: 'Before',
  after: 'After',
  header: 'Header',
  main: 'Main',
  footer: 'Footer',
  move_to: 'Move to %% text %%',
  publish: 'Publish %% text %%',
  update: 'Update %% text %%',
  changelog: 'Changelog',
  code: 'Code',
  app_settings: 'App settings',
  documents: 'Documents',
  support: 'Support',
  install: 'Install',
  prefix_page: '%% prefix %% Page',
  select_template: 'Select %% text %% Templates',
  create_description: 'Designed to make %% text %% more professional and easy to use.',
  warning: 'Warning',
  advanced: 'Advanced',
  language: 'Language',
  header_of_theme: 'The Header of Your Shopify Theme (Please go to Shopify admin to customize the Header)',
  footer_of_theme: 'The Footer of Your Shopify Theme (Please go to Shopify admin to customize the Footer)',
  megamenu: 'Mega menu',
  opacity: 'Opacity',
  auto_change_color_for_dark_theme: 'Auto change color for dark theme',
  no_color: 'No color',
  add_or_edit_global_color: 'Add or edit global color',
  color_settings_description: 'You can customize the parameters below and it will apply to the color you selected',
  version: 'Version',
  description: 'Description',
  createNewSection: 'Create new section',
  repeat: 'Repeat',
  once: 'Once',
  scroll_down_to_run: 'Scroll to run',
  createNewAddons: 'Create new addons',
  category: 'Category',
  approve: 'Approve %% text %%',
  reject: 'Reject %% text %%',
  hotfix: 'Hotfix %% text %%',
  rejected: 'Rejected',
  edit_info: 'Edit info',
  lack_of_params: 'Missing parameter\nExpect: %% expect %%\nActual: %% actual %%',
  copy_code: 'Copy code',
  copied_code: 'Copied',
  addon_pick_position: 'Pick live position',
  remove_from_page: 'Remove from page',
  remove_from_sections: 'Remove from sections',
  not_enough_width: 'The width of the screen is not enough, please open it on a larger screen',
  add_addons: 'Add addons',
  general_settings: 'General Settings',
  vendors: 'Vendors',
  accept: 'Accept',
  search_with: 'Search %% text %%',
  author: 'Author',
  shopify_inside_shopify: 'Shopify tag cannot be inside shopify tag',
  login: 'Login',
  open_new_tab: 'Open in a new tab',
  admin: 'Admin',
  commit: 'Commit %% text %%',
  recommit: 'Recommit %% text %%',
  fork: 'fork %% text %%',
  assign_to: 'Assign To',
  apply: 'Apply',
  apply_all: 'Apply all',
  confirm: 'Confirm',
  font_style: 'Font style',
  letter_spacing: 'Letter spacing',
  font_weight: 'Font weight',
  text_decoration: 'Text decoration',
  text_transform: 'Text transform',
  data_binding_between_items: 'Data binding between item make you build faster',
  data_binding_text:
    "Data binding is when you change any field at the item, the other items also change. Now let's choose the fields you want to bind data:",
  data_binding_field_changed: 'When this field is changed, all other items change',
  by: 'By %% text %%',
  message: 'Message',
  success: 'success',
  color_scheme: 'Color scheme',
  custom_color: 'Custom color',
  background_fixed: 'Background fixed',
  background_size: 'Background size',
  successfully: 'Successfully!',
  manager: 'Manager',
  plan: 'Plan',
  on: 'On %% text %%',
  application: 'Application',
  free: 'Free',
  pro: 'Pro',
  enterprise: 'Enterprise',
  duplicate_the_last_item: 'Duplicate the last item',
  delete_confirm_message: 'Do you want to delete %% text %%?',
  delete_all_confirm: 'Do you want to delete the entire %% text %%?',
  comment: 'Comment',
  from: 'From %% text %%',
  not_have_permission: "You don't have permission",
  roles: 'Roles',
  to: 'to',
  apply_to: 'Apply to',
  tabs: 'Tabs',
  loading: 'Loading',
  active: 'Active',
  activated: 'Activated',
  activate: 'Activate',
  blank_item: 'Blank item',
  border_and_shadow: 'Border and shadow',
  current: 'Current %% text %%',
  icon_font: 'Icon font',
  live_preview: 'Live preview',
  renew_data: 'Refresh data',
  renew_data_success: 'Data refreshed',
  renew_data_failure: 'Refresh failed',
  change_image: 'Change image',
  release: 'Release',
  latest: 'Latest',
  add_component: 'Add component',
  address: 'Address',
  subject: 'Subject',
  commit_page: 'Commit page %% text %%',
  published: 'Published',
  assign_error: 'Cannot assign a variable that use logical operator or comparison operator',
  same_assign_error: 'Cannot assign a variable that is the same as `name` in `schema`',
  same_loop_item_error: '%% text %% already exists. Please change the name',
  item_reach_limit: 'The number of items has reached the limit',
  item_changed_in_new_version: '%% text %% changed in the new version',
  congratulations: 'Congratulations',
  your_item_updated: 'Your %% text %% was updated',
  uploading: 'Uploading %% text %%',
  exit: 'Exit',
  meta_field_use_in: 'You can only use meta field in pages: collections, products, posts',
  confirm_publish: 'Are you sure you want to publish %% text %%',
  currently_selected: 'Currently selected',
  new_file: 'New file',
  file_permission: 'You do not have permission to edit this file',
  discover_more: 'Discover more',
  themes: 'Themes',
  changelogs: 'Changelogs',
  just_added: 'Just added',
  deselect: 'Deselect',
  welcome: 'Welcome %% text %%',
  addon_loop_desc: 'Add-on in preview only shows on the first item, but when published it’ll show on all items.',
  change_language_of_app: 'Change the language of the app',
  device: 'Device',
  color_scheme_field_description: "To edit all your theme's colors, go to your theme settings.",
  explore: 'Explore',
  disabled_dark_and_light_color_schemes: 'To edit this color, you must change color mode to "Light colors"',
  system_error: 'There is an error in the system',
} as const;
