import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';

interface GetSectionsOfPageAtom {
  commandIds: string[];
}

export const getAddonsOfPageAtom = async ({ commandIds }: GetSectionsOfPageAtom) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const responses = await pageApis.atom.adminApi.addon.getAddonsOfAtom({ cacheVersion: lastCache, commandIds });
    return responses.filter(Boolean);
  }
  throw new RoleException();
};
