import { Confirm, ScrollBars } from '@wiloke/ui';
import { Popover } from 'antd';
import Logo from 'components/Logo';
import { Navigation } from 'components/Navigation';
import {
  selectorVisibleTour,
  useSetVisibleTourPage,
  useSetVisibleTourTheme,
} from 'containers/Admin/Modals/components/ModalStepTour/store/sliceModalTour';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';
import { CrispChat } from 'containers/CrispChat/CrispChat';
import { Language } from 'containers/ModalAppSettings/components/Language';
import { TourGuideForPage } from 'containers/TourGuides/TourGuideForPage';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { createGlobalState, useWindowSize } from 'react-use';
import { appSettingsSelector, useUpdateAppSettings } from 'store/global/appSettings/slice';
import { pageCounterSelector } from 'store/global/pageCounter/slicePageCounter';
import { authSelector } from 'store/selectors';
import { i18n } from 'translation';
import { PLAN_STATIC_DATA, VEDA_FUNCTION_ENUM } from 'utils/constants/PlanEnum';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { validatePlan } from 'utils/functions/validatePlan';
import { FontAwesome, Space, useStyleSheet, useTheme, View, withStyles } from 'wiloke-react-core';
import { builderTypeRoute, expandPages, managerItems, menuItem, themeItems } from './data';
import * as styles from './styles';

// @ts-ignore
const NavLinkWithStyles = withStyles(NavLink);

const useCollapse = createGlobalState(false);

export const SideBar = () => {
  const { data: pageCounter } = useSelector(pageCounterSelector);
  const { data, updateStatus } = useSelector(appSettingsSelector);
  const { visibleTourPage, visibleTourTheme } = useSelector(selectorVisibleTour);
  const { plan } = useSelector(authSelector);

  const location = useLocation();
  const history = useHistory();
  const updateAppSettings = useUpdateAppSettings();

  const { colors } = useTheme();
  const { styles: classNames } = useStyleSheet(colors);
  const { role } = getUserInfo();
  const [isCollapse, setIsCollapse] = useCollapse();
  const { width, height } = useWindowSize();
  const setVisibleTourPage = useSetVisibleTourPage();
  const setVisibleTourTheme = useSetVisibleTourTheme();
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();

  const isUser = role === 'user';
  const isDev = role === 'dev';

  useEffect(() => {
    if (location.pathname === '/page' && visibleTourPage === 'close') {
      setVisibleTourPage('on');
    }
    if (location.pathname === '/theme' && visibleTourTheme === 'close') {
      setVisibleTourTheme('on');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleUpdateAppSettings = (onCancel?: () => void) => {
    updateAppSettings.request({
      data,
      onFulfill() {
        window.location.reload();
        onCancel?.();
      },
    });
  };

  const renderExtraNode = (
    <View>
      <Confirm
        isLoading={updateStatus === 'loading'}
        onOk={handleUpdateAppSettings}
        okText={i18n.t('general.confirm')}
        title={i18n.t('general.change', { text: i18n.t('general.language') })}
        message={<Language />}
      >
        <FontAwesome
          type="far"
          size={20}
          name="language"
          style={styles.roundShape}
          css={[styles.icon(false), { color: colors.primary, cursor: 'pointer' }]}
        />
      </Confirm>
      <NavLinkWithStyles
        color="gray8"
        colorHover="primary"
        backgroundColor="light"
        to={'/notifications'}
        exact={true}
        css={styles.link}
        activeClassName={classNames(styles.active)}
      >
        <FontAwesome type="far" size={18} name={'bell'} style={styles.roundShape} css={styles.icon(location.pathname.includes('/notifications'))} />
      </NavLinkWithStyles>

      <NavLinkWithStyles
        color="gray8"
        colorHover="primary"
        backgroundColor="light"
        to={'/account'}
        exact={true}
        css={styles.link}
        activeClassName={classNames(styles.active)}
      >
        <FontAwesome type="far" size={18} name={'user'} style={styles.roundShape} css={styles.icon(location.pathname.includes('/account'))} />
      </NavLinkWithStyles>
    </View>
  );

  return (
    <View css={styles.container} backgroundColor="light">
      {/* <TourGuideForTheme /> */}
      <TourGuideForPage />

      <View css={styles.nav}>
        <View css={styles.themeBar}>
          <View css={styles.sideUp}>
            {width > 1366 && height > 768 && (
              <View
                css={{ paddingBottom: '8px', cursor: 'pointer' }}
                onClick={() => {
                  history.push('/page');
                }}
              >
                <Logo variant="style2" />
              </View>
            )}

            {builderTypeRoute(isUser).map(item => {
              return (
                <NavLinkWithStyles
                  key={item.id}
                  color="gray8"
                  colorHover="primary"
                  backgroundColor="light"
                  to={item.href}
                  exact={item.exact}
                  css={styles.link}
                  activeClassName={classNames(styles.active)}
                >
                  <FontAwesome type="far" size={18} name={item.icon} css={styles.icon(location.pathname.includes(item.type))} />
                  <View>{item.label}</View>
                </NavLinkWithStyles>
              );
            })}
          </View>

          <View css={styles.popper}>
            <Popover placement="right" content={renderExtraNode} trigger="click">
              <FontAwesome type="far" size={20} name="ellipsis-h" style={styles.roundShape} css={[styles.icon(false), { cursor: 'pointer' }]} />
            </Popover>

            <Space size={5} />
            <CrispChat>
              <FontAwesome
                backgroundColor="secondary"
                type="fas"
                size={18}
                name="comment-dots"
                style={styles.roundShape}
                css={[styles.icon(false), { background: colors.secondary, color: colors.light }]}
              />
            </CrispChat>
          </View>
        </View>

        <ScrollBars>
          {location.pathname.includes('/theme') && <Navigation containerCss={styles.navBar} data={themeItems()} />}
          {location.pathname.includes('/page') && (
            <Navigation
              id="route-pages-type"
              counter={pageCounter}
              containerCss={styles.navBar}
              data={menuItem()}
              expandContent={
                <>
                  <View
                    color="gray8"
                    backgroundColor="gray1"
                    css={styles.expandContainer}
                    onClick={() => {
                      setIsCollapse(prev => !prev);
                    }}
                  >
                    <FontAwesome type="far" size={16} name={isCollapse ? 'angle-double-up' : 'angle-double-down'} css={styles.expandIcon} />
                    <View fontFamily="secondary">{isCollapse ? i18n.t('adminDashboard.collapse') : i18n.t('adminDashboard.otherPages')}</View>
                  </View>

                  {isCollapse ? (
                    <Navigation
                      counter={pageCounter}
                      containerCss={{ padding: '0' }}
                      data={expandPages}
                      onClick={(event, item) => {
                        if (!!item.plan && validatePlan({ userPlan: plan.planHandle, itemPlan: item.plan, role }) === 'invalid') {
                          event.preventDefault();
                          setPlanRequesting({
                            handle: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.cart_page].handle,
                            type: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.cart_page].type,
                            pricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.cart_page].pricePerMonth,
                            pricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.cart_page].pricePerYear,
                            title: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.cart_page].title,
                            originalPricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.cart_page].pricePerMonth,
                            originalPricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.cart_page].pricePerYear,
                          });
                          setValidateCouponVisible(true);
                        }
                      }}
                    />
                  ) : null}
                </>
              }
            />
          )}
          {location.pathname.includes('/manager') && <Navigation containerCss={styles.navBar} data={managerItems(isDev)} />}
        </ScrollBars>
      </View>
    </View>
  );
};
