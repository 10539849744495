import { notification } from 'antd';
import { MyModal } from '@wiloke/ui';
import { useDeleteArticlePages } from 'containers/Admin/PageBuilder/ArticlesPage';
import { useDeleteBlankPages } from 'containers/Admin/PageBuilder/BlankPage/stores';
import { useDeleteCollectionPages } from 'containers/Admin/PageBuilder/CollectionPage';
import { useDeleteHomePages } from 'containers/Admin/PageBuilder/HomePage/store';
import { useDeleteProductPages } from 'containers/Admin/PageBuilder/ProductsPage';
import { blankPageSelector, modalsSelector } from 'containers/Admin/selector';
import { useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  articlePageSelector,
  blogPageSelector,
  collectionPageSelector,
  customerPagesSelector,
  homePageSelector,
  productPageSelector,
} from 'store/selectors';
import { i18n } from 'translation';
import { PageType } from 'types/Page';
import { useChangeModalAdminSettings } from '../../store';
import { useDeleteBlogPages } from 'containers/Admin/PageBuilder/BlogPage';
import { useDeleteCustomerPages } from 'containers/Admin/PageBuilder/CustomerPages';

interface ModalDeletePageDashboardProps {
  pageType: PageType;
}

export const ModalDeletePageDashboard: FC<ModalDeletePageDashboardProps> = ({ pageType }) => {
  const { deleteBlank, deleteHome, deleteProduct, deleteCollection, deleteArticle, deleteBlog, deleteCustomerPage } = useSelector(modalsSelector);
  const customerPageState = useSelector(customerPagesSelector);
  const articlePageState = useSelector(articlePageSelector);
  const blankPageState = useSelector(blankPageSelector);
  const collectionPageState = useSelector(collectionPageSelector);
  const homePageState = useSelector(homePageSelector);
  const productPageState = useSelector(productPageSelector);
  const blogPageState = useSelector(blogPageSelector);

  const changeModalAdminSettings = useChangeModalAdminSettings();
  const deleteArticleRequest = useDeleteArticlePages();
  const deleteBlankRequest = useDeleteBlankPages();
  const deleteCollectionRequest = useDeleteCollectionPages();
  const deleteHomePageRequest = useDeleteHomePages();
  const deleteProductRequest = useDeleteProductPages();
  const deleteBlogRequest = useDeleteBlogPages();
  const deleteCustomerPages = useDeleteCustomerPages();

  const { connect, disconnect, statusSocketConnection } = useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService('sync_shopify');

  const visibleMapping: Record<PageType, boolean> = {
    page: deleteBlank,
    home: deleteHome,
    collection: deleteCollection,
    product: deleteProduct,
    article: deleteArticle,
    blog: deleteBlog,

    account: deleteCustomerPage,
    activateAccount: deleteCustomerPage,
    addresses: deleteCustomerPage,
    cart: deleteCustomerPage,
    collections: deleteCustomerPage,
    giftCard: deleteCustomerPage,
    login: deleteCustomerPage,
    order: deleteCustomerPage,
    pageNotFound: deleteCustomerPage,
    password: deleteCustomerPage,
    register: deleteCustomerPage,
    resetPassword: deleteCustomerPage,
    search: deleteCustomerPage,
  };

  const handleCancel = useCallback(() => {
    const cancelMapping: Record<PageType, () => void> = {
      page: () => changeModalAdminSettings({ deleteBlank: false }),
      home: () => changeModalAdminSettings({ deleteHome: false }),
      collection: () => changeModalAdminSettings({ deleteCollection: false }),
      product: () => changeModalAdminSettings({ deleteProduct: false }),
      blog: () => changeModalAdminSettings({ deleteBlog: false }),
      article: () => changeModalAdminSettings({ deleteArticle: false }),

      account: () => changeModalAdminSettings({ deleteCustomerPage: false }),
      activateAccount: () => changeModalAdminSettings({ deleteCustomerPage: false }),
      addresses: () => changeModalAdminSettings({ deleteCustomerPage: false }),
      cart: () => changeModalAdminSettings({ deleteCustomerPage: false }),
      collections: () => changeModalAdminSettings({ deleteCustomerPage: false }),
      giftCard: () => changeModalAdminSettings({ deleteCustomerPage: false }),
      login: () => changeModalAdminSettings({ deleteCustomerPage: false }),
      order: () => changeModalAdminSettings({ deleteCustomerPage: false }),
      pageNotFound: () => changeModalAdminSettings({ deleteCustomerPage: false }),
      password: () => changeModalAdminSettings({ deleteCustomerPage: false }),
      register: () => changeModalAdminSettings({ deleteCustomerPage: false }),
      resetPassword: () => changeModalAdminSettings({ deleteCustomerPage: false }),
      search: () => changeModalAdminSettings({ deleteCustomerPage: false }),
    };
    cancelMapping[pageType]();
  }, [changeModalAdminSettings, pageType]);

  const handleOk = () => {
    const okMapping: Record<PageType, () => void> = {
      article: () => {
        deleteArticleRequest.request({ ids: articlePageState.ids, onFulfill: () => disconnect({}) });
      },
      account: () => {
        deleteCustomerPages.request({ ids: customerPageState.ids, onFulfill: () => disconnect({}) });
      },
      activateAccount: () => {
        deleteCustomerPages.request({ ids: customerPageState.ids, onFulfill: () => disconnect({}) });
      },
      addresses: () => {
        deleteCustomerPages.request({ ids: customerPageState.ids, onFulfill: () => disconnect({}) });
      },
      cart: () => {
        deleteCustomerPages.request({ ids: customerPageState.ids, onFulfill: () => disconnect({}) });
      },
      collection: () => {
        deleteCollectionRequest.request({ ids: collectionPageState.ids, onFulfill: () => disconnect({}) });
      },
      collections: () => {
        deleteCustomerPages.request({ ids: customerPageState.ids, onFulfill: () => disconnect({}) });
      },
      giftCard: () => {
        deleteCustomerPages.request({ ids: customerPageState.ids, onFulfill: () => disconnect({}) });
      },
      home: () => {
        deleteHomePageRequest.request({ ids: homePageState.ids, onFulfill: () => disconnect({}) });
      },
      login: () => {
        deleteCustomerPages.request({ ids: customerPageState.ids, onFulfill: () => disconnect({}) });
      },
      order: () => {
        deleteCustomerPages.request({ ids: customerPageState.ids, onFulfill: () => disconnect({}) });
      },
      page: () => {
        deleteBlankRequest.request({ ids: blankPageState.ids, onFulfill: () => disconnect({}) });
      },
      pageNotFound: () => {
        deleteCustomerPages.request({ ids: customerPageState.ids, onFulfill: () => disconnect({}) });
      },
      password: () => {
        deleteCustomerPages.request({ ids: customerPageState.ids, onFulfill: () => disconnect({}) });
      },
      product: () => {
        deleteProductRequest.request({ ids: productPageState.ids, onFulfill: () => disconnect({}) });
      },
      register: () => {
        deleteCustomerPages.request({ ids: customerPageState.ids, onFulfill: () => disconnect({}) });
      },
      resetPassword: () => {
        deleteCustomerPages.request({ ids: customerPageState.ids, onFulfill: () => disconnect({}) });
      },
      search: () => {
        deleteCustomerPages.request({ ids: customerPageState.ids, onFulfill: () => disconnect({}) });
      },
      blog: () => {
        deleteBlogRequest.request({ ids: blogPageState.ids, onFulfill: () => disconnect({}) });
      },
    };
    connect({
      onSuccess: () => {
        okMapping[pageType]();
      },
      onError: () => {
        notification.error({
          message: i18n.t('publish_shopify.init_sync_error.message'),
          description: i18n.t('publish_shopify.init_sync_error.description'),
        });
      },
    });
  };

  return (
    <MyModal
      isVisible={visibleMapping[pageType]}
      isLoading={
        statusSocketConnection === 'loading' ||
        blankPageState.deletePending.length > 0 ||
        homePageState.deletePending.length > 0 ||
        articlePageState.deletePending.length > 0 ||
        collectionPageState.deletePending.length > 0 ||
        blogPageState.deletePending.length > 0 ||
        productPageState.deletePending.length > 0
      }
      onOk={handleOk}
      onCancel={handleCancel}
      headerText={i18n.t('general.warning')}
      okText={i18n.t('general.delete')}
    >
      {i18n.t('adminDashboard.confirm_delete')}
    </MyModal>
  );
};
