import { AxiosError } from 'axios';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { deleteCategoryOfPageProductRequest } from 'services/PageService/Logic/deleteCategoryOfPageProduct';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { deleteCategoryOfPageProduct } from '../actions/actionPagesProduct';

function* handleDelete({ payload: { commandId } }: ReturnType<typeof deleteCategoryOfPageProduct.request>) {
  try {
    const response: SagaReturnType<typeof deleteCategoryOfPageProductRequest> = yield retry(3, 1000, deleteCategoryOfPageProductRequest, {
      commandId,
    });
    notifyAxiosHandler.handleSuccess(response.message);
    yield put(deleteCategoryOfPageProduct.success({ commandId }));
  } catch (error) {
    notifyAxiosHandler.handleError(error as AxiosError<ErrorData> | Error);
    yield put(deleteCategoryOfPageProduct.failure({ commandId }));
  }
}

export function* watchDeleteCategoryOfPageProduct() {
  yield takeLatest(getActionType(deleteCategoryOfPageProduct.request), handleDelete);
}
