import { AxiosError } from 'axios';
import { calculatePricing, transformPriceStringToNumber } from 'containers/Admin/PlanManagement/utils';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { validateCouponService } from 'services/UserService/Logic/validateCoupon';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { validateCoupon } from '../actions';
import { setPlanRequesting, setValidatedCode } from '../sliceCoupon';

function* handleValidate({
  payload: { couponCode, planHandle, pricePerMonth, pricePerYear, onFulfill, onError },
}: ReturnType<typeof validateCoupon.request>) {
  try {
    const response: SagaReturnType<typeof validateCouponService> = yield retry(3, 1000, validateCouponService, { couponCode, planHandle });
    if (response.info?.code) {
      yield put(setValidatedCode(response.info.code));

      yield put(
        setPlanRequesting({
          pricePerMonth: calculatePricing({
            type: response.info.type,
            discount: response.info.number,
            currentPrice: transformPriceStringToNumber(pricePerMonth),
          }).toString(),
          pricePerYear: calculatePricing({
            type: response.info.type,
            discount: response.info.number,
            currentPrice: transformPriceStringToNumber(pricePerYear),
          }).toString(),
        }),
      );

      yield put(validateCoupon.success(undefined));
      notifyAxiosHandler.handleSuccess(response.message);
      onFulfill?.(response.info.description);
    } else {
      onError?.(response.message);
      yield put(validateCoupon.failure(undefined));
    }
  } catch (error) {
    if ((error as AxiosError).isAxiosError) {
      onError?.((error as AxiosError<ErrorData>).response?.data.message ?? '');
    }
    yield put(validateCoupon.failure(undefined));
  }
}

export function* watchValidateCoupon() {
  yield takeLatest(getActionType(validateCoupon.request), handleValidate);
}
