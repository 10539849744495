import { sliceCopyStyle } from 'containers/BuilderPage/store/copyStyle/sliceCopyStyle';
import { combineReducers } from 'redux';

import { sliceAddonPositionStart } from './addonPosition/slice';
import { sliceAddonsTopBarMounted } from './addonsTopbarMounted/slice';
import { sliceFullScreen } from './fullScreen/slice';
import { sliceIframeLoaded } from './iframeLoaded/slice';
import { slicePreviewLoaded } from './previewLoaded/slice';
import { sliceResponsive } from './responsive/slice';

import { sliceSaveForBuilder } from './saveForBuilder/slice';
import { sliceSelectPage } from './selectPage/slice';
import { sliceSettingsVisible } from './settingsVisible/slice';

import { sliceAddonsToKeep } from './toolbarActions/sliceAddonsToKeep';
import { sliceModalDeleteVisible } from './toolbarActions/sliceModalDeleteVisible';
import { sliceModalDuplicateVisible } from './toolbarActions/sliceModalDuplicateVisible';
import { sliceTwigLoading } from './twigLoading/slice';

export const reducersBuilderPage = combineReducers({
  responsive: sliceResponsive.reducer,
  fullScreen: sliceFullScreen.reducer,
  addonPositionStart: sliceAddonPositionStart.reducer,
  addonsTopBarMounted: sliceAddonsTopBarMounted.reducer,
  toolbarActions: combineReducers({
    modalDeleteVisible: sliceModalDeleteVisible.reducer,
    modalDuplicateVisible: sliceModalDuplicateVisible.reducer,
    addonsToKeep: sliceAddonsToKeep.reducer,
  }),
  iframeLoaded: sliceIframeLoaded.reducer,
  previewLoaded: slicePreviewLoaded.reducer,
  selectPage: sliceSelectPage.reducer,
  twigLoading: sliceTwigLoading.reducer,
  saveForBuilder: sliceSaveForBuilder.reducer,
  settingsVisible: sliceSettingsVisible.reducer,
  copyStyle: sliceCopyStyle.reducer,
});
