import { Hotspot } from '@wiloke/ui';
import { AdvancedIcon, BusinessIcon, UltimateIcon } from 'components/PlanBadge';
import { FC, MouseEvent, ReactNode } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { PageCounter } from 'store/global/pageCounter/slicePageCounter';
import { PageType } from 'types/Page';
import { PLAN_HANDLE } from 'utils/constants/PlanEnum';
import { FontAwesome, FontAwesomeName, Text, useStyleSheet, useTheme, View, ViewProps, withStyles, WithStylesProps } from 'wiloke-react-core';
import * as css from './styles';

export interface MenuItem {
  id: string;
  label: string;
  href: string;
  exact?: boolean;
  icon: FontAwesomeName;
  isReactRouter: boolean;
  hasDivider?: boolean;
  textHeader?: string;
  pageType?: PageType;
  hotspot?: boolean;
  plan?: PLAN_HANDLE;
}

export interface NavigationProps {
  data: MenuItem[];
  containerCss?: ViewProps['css'];
  counter?: PageCounter;
  expandContent?: ReactNode;
  id?: string;
  onClick?: (e: MouseEvent, item: MenuItem) => void;
}

// @ts-ignore
const NavLinkWithStyles = withStyles(NavLink);

export const Navigation: FC<NavigationProps> = ({ counter, data, containerCss, id, expandContent, onClick }) => {
  const { colors } = useTheme();
  const { styles } = useStyleSheet(colors);
  const match = useRouteMatch();

  const linkProps: Pick<WithStylesProps, 'color' | 'colorHover' | 'css'> = {
    css: css.link,
    color: 'gray8',
    colorHover: 'primary',
  };

  const renderPlan: Record<PLAN_HANDLE, ReactNode> = {
    free: null,
    business: (
      <View css={css.icon} radius={4} backgroundColor="tertiary">
        <BusinessIcon />
      </View>
    ),
    advanced: (
      <View css={css.icon} radius={4} backgroundColor="quaternary">
        <AdvancedIcon />
      </View>
    ),
    ultimate: (
      <View css={css.icon} radius={4} backgroundColor="dark">
        <UltimateIcon />
      </View>
    ),
  };

  const renderLink = (item: MenuItem): ReactNode => {
    const { isReactRouter, href, label, icon, exact, id, pageType, plan } = item;
    const _counter = counter && pageType ? counter[pageType] : 0;
    const isActive = match.url === href;

    const anchorContent = (
      <>
        <View css={css.flex}>
          {plan ? renderPlan[plan] : <FontAwesome type="far" size={14} name={icon} css={css.icon} />}
          {label}
          {!!item.hotspot && <Hotspot css={{ marginLeft: '10px' }} />}
        </View>

        {_counter > 0 && (
          <Text fontFamily="primary" css={css.num} backgroundColor={isActive ? 'gray3' : 'gray2'}>
            {_counter}
          </Text>
        )}
      </>
    );

    if (isReactRouter) {
      return (
        <NavLinkWithStyles
          {...linkProps}
          sidebar-id={id}
          id={id}
          activeClassName={styles(css.active)}
          exact={exact}
          to={href as any}
          onClick={e => {
            onClick?.(e, item);
          }}
        >
          {anchorContent}
        </NavLinkWithStyles>
      );
    }
    return (
      <Text
        {...linkProps}
        sidebar-id={id}
        id={id}
        tagName="a"
        target="_blank"
        href={href}
        onClick={e => {
          onClick?.(e, item);
        }}
      >
        {anchorContent}
      </Text>
    );
  };

  const renderMenuItem = (item: MenuItem): ReactNode => {
    const { id, hasDivider, textHeader } = item;
    return (
      <View key={id}>
        {!!textHeader && (
          <Text size={12} fontFamily="secondary" css={css.title}>
            {textHeader}
          </Text>
        )}

        <View className="Navigation-parent" css={css.parent}>
          {renderLink(item)}
        </View>
        {!!hasDivider && (
          <View height={1} backgroundColor="gray2" css={{ position: 'relative', marginTop: '15px', marginBottom: '15px', width: '100%' }} />
        )}
      </View>
    );
  };

  return (
    <View id={id} tagName="nav" css={[css.container, containerCss]}>
      {data.map(renderMenuItem)}
      {expandContent}
    </View>
  );
};
