import { CssVariables } from 'types/Result';
import { v4 } from 'uuid';

const initialState: CssVariables = {
  colors: [
    { id: v4(), name: '--color-primary', light: '#33C9C4', dark: '#33C9C4' },
    { id: v4(), name: '--color-secondary', light: '#3540df', dark: '#3540df' },
    { id: v4(), name: '--color-tertiary', light: '#eb6c6c', dark: '#eb6c6c' },
    { id: v4(), name: '--color-quaternary', light: '#FBC473', dark: '#FBC473' },
    { id: v4(), name: '--color-dark', light: '#000000', dark: '#ffffff' },
    { id: v4(), name: '--color-gray9', light: '#232323', dark: '#fafafa' },
    { id: v4(), name: '--color-gray8', light: '#3e3e3e', dark: '#f3f3f3' },
    { id: v4(), name: '--color-gray7', light: '#575757', dark: '#e9e9e9' },
    { id: v4(), name: '--color-gray6', light: '#888888', dark: '#dbdbdb' },
    { id: v4(), name: '--color-gray5', light: '#b3b3b3', dark: '#b3b3b3' },
    { id: v4(), name: '--color-gray4', light: '#dbdbdb', dark: '#888888' },
    { id: v4(), name: '--color-gray3', light: '#e9e9e9', dark: '#575757' },
    { id: v4(), name: '--color-gray2', light: '#f3f3f3', dark: '#3e3e3e' },
    { id: v4(), name: '--color-gray1', light: '#fafafa', dark: '#232323' },
    { id: v4(), name: '--color-light', light: '#ffffff', dark: '#000000' },
  ],
  fonts: [
    { id: v4(), name: '--font-primary', value: 'mono' },
    { id: v4(), name: '--font-secondary', value: 'sans-serif' },
    { id: v4(), name: '--font-tertiary', value: 'abel_n4' },
    { id: v4(), name: '--font-quaternary', value: 'roboto_mono_n4' },
  ],
};

export const defaultColorNames = [
  '--color-primary',
  '--color-secondary',
  '--color-tertiary',
  '--color-quaternary',
  '--color-dark',
  '--color-gray9',
  '--color-gray8',
  '--color-gray7',
  '--color-gray6',
  '--color-gray5',
  '--color-gray4',
  '--color-gray3',
  '--color-gray2',
  '--color-gray1',
  '--color-light',
];
export const defaultFontNames = ['--font-primary', '--font-secondary', '--font-tertiary', '--font-quaternary'];

export default initialState;
