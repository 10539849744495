import { TourGuide, TourGuideStep, TourGuideProps } from 'components/TourGuide';
import { tourGuideSelector, useSetTourGuideAddon } from 'containers/TourGuides/sliceTourGuides';
import { useSelector } from 'react-redux';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useSetTemplateBoardVisible } from 'containers/ChooseTemplate/store/actions';
import { sidebarTabActiveSelector } from 'store/selectors';
import { i18n } from 'translation';
import { storage } from '@wiloke/functions';

const tourGuideAddon: TourGuideStep[] = [
  {
    target: '#button-add-addon',
    title: i18n.t('ModalTour.tour_addon.step_1.title'),
    content: i18n.t('ModalTour.tour_addon.step_1.description'),
    placement: 'auto',
    showProgress: true,
    disableBeacon: true,
  },
  {
    target: '#navigation-addon',
    title: i18n.t('ModalTour.tour_addon.step_2.title'),
    content: i18n.t('ModalTour.tour_addon.step_2.description'),
    placement: 'right-start',
    showProgress: true,
  },
  {
    target: '#choose-template-addon',
    title: i18n.t('ModalTour.tour_addon.step_3.title'),
    content: i18n.t('ModalTour.tour_addon.step_3.description'),
    placement: 'left-start',
    showProgress: true,
  },
];

export const TourGuideForAddon = () => {
  const { addonsTour } = useSelector(tourGuideSelector);
  const sidebarTabActive = useSelector(sidebarTabActiveSelector);

  const setAddonTour = useSetTourGuideAddon();
  const setTemplateBoardVisible = useSetTemplateBoardVisible();

  const handleTourGuide: TourGuideProps['callback'] = data => {
    const { action, index, type, status } = data;

    if (action === 'close') {
      setAddonTour({ run: false, stepIndex: 0 });
      storage.setItem('TourGuideForAddon', 'off');
      return;
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type as any)) {
      setAddonTour({ run: true, stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
      if (index === 0) {
        setTemplateBoardVisible({ visible: true, navKeys: ['addons'] });
      }
      if (index === 1 && ACTIONS.PREV === action) {
        setTemplateBoardVisible({ visible: false, navKeys: ['addons'] });
      }
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)) {
      setAddonTour({ run: false, stepIndex: 0 });
      storage.setItem('TourGuideForAddon', 'off');
    }
  };

  if (window.location.href.includes('/builder') && sidebarTabActive == 'add-ons' && !storage.getItem('TourGuideForAddon')) {
    return (
      <TourGuide
        steps={tourGuideAddon}
        disableOverlayClose={false}
        stepIndex={addonsTour?.stepIndex ?? 0}
        run={addonsTour?.run ?? true}
        callback={handleTourGuide}
        disableOverlay={false}
      />
    );
  }

  return null;
};
