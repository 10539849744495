import { delay, imageUrl } from '@wiloke/functions';
import { AsyncComponent, ScrollBars } from '@wiloke/ui';
import TextInput from 'components/TextInput';
import { useDeleteEditTemplate } from 'containers/Admin/Modals/util/useDeleteEditTemplate';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';
import { templatePopupSelector } from 'containers/Admin/selector';
import withDebounce from 'hocs/withDebounce';
import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useGuarded from 'routes/useGuarded';
import { BE_PageProduct } from 'services/PageService/VedaApplication/types';
import { ServerTemplateResponse } from 'services/PagesBuilderService';
import { useSetPages } from 'store/actions/actionPages';
import { authSelector } from 'store/selectors';
import { PLAN_ENUM, PLAN_STATIC_DATA } from 'utils/constants/PlanEnum';
import { validatePlan } from 'utils/functions/validatePlan';
import * as styles from './styles';
import { ActivityIndicator, GridSmart, View, ViewportTracking } from 'wiloke-react-core';
import ImageTextCard from 'components/ImageTextCard';
import { ModalPreviewPage } from 'containers/Admin/Modals/components/ModalPreviewPage';
import { i18n } from 'translation';
import { useLoadMoreTemplatesPopup } from 'containers/Admin/PageBuilder/TemplatesInPopup';
import { at } from 'utils/at';
import { PageType } from 'types/Page';

const DebounceTextInput = withDebounce(TextInput, 'value', 'onValueChange', 300);

export const TemplatesContent = ({
  onAdd,
  onInstall,
  onSearch,
  search,
  type,
  categoryId,
  planFilter,
  useCases,
}: {
  onAdd: () => void;
  onInstall: (item: ServerTemplateResponse) => void;
  onSearch?: (value: string) => void;

  search?: string;
  type: PageType;
  categoryId?: string;
  planFilter?: string;
  useCases?: string[];
}) => {
  const { data: templatesData, getAllStatus, deleteStatus, loadMoreStatus, hasNextPage } = useSelector(templatePopupSelector);
  const { plan, role } = useSelector(authSelector);

  const { handleDeleteTemplatePage } = useDeleteEditTemplate();
  const guard = useGuarded();
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();
  const setPages = useSetPages();
  const loadMoreTemplates = useLoadMoreTemplatesPopup();

  const [previewPage, setPreviewPage] = useState<BE_PageProduct | undefined>(undefined);
  const filterRef = useRef<HTMLDivElement | null>(null);

  const handleAdd = async (item: ServerTemplateResponse) => {
    if (validatePlan({ userPlan: plan.planHandle, itemPlan: item.planHandle ?? PLAN_ENUM.free, role }) === 'pass') {
      onInstall(item);
      await delay(100);
      setPreviewPage(undefined);
    } else {
      setPlanRequesting({
        handle: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].handle,
        type: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].type,
        pricePerMonth: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].pricePerMonth,
        pricePerYear: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].pricePerYear,
        title: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].title,
        originalPricePerMonth: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].pricePerMonth,
        originalPricePerYear: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].pricePerYear,
      });
      setValidateCouponVisible(true);
    }
  };

  const handleCancelPreview = () => {
    setPreviewPage(undefined);
    setPages({});
  };

  const handlePreview = (page: BE_PageProduct) => {
    if (!page.previewUrl?.includes('https://landing.vedabuilder.com')) {
      window.open(page.previewUrl, '_blank');
    } else {
      setPreviewPage(page);
    }
  };

  const TrackingLoadMore = useMemo(() => {
    const cursor = at(templatesData, -1)?.commandId;
    if (cursor && hasNextPage) {
      return (
        <ViewportTracking
          onEnterViewport={() => {
            if (loadMoreStatus !== 'loading') {
              loadMoreTemplates.request({ cursor, type, search, categoryId, planFilter, useCases });
            }
          }}
        >
          <View css={{ display: 'flex', justifyContent: 'center', marginTop: '14px' }}>
            <ActivityIndicator size={40} />
          </View>
        </ViewportTracking>
      );
    }
    return null;
  }, [templatesData, hasNextPage, loadMoreStatus, loadMoreTemplates, type, search, categoryId, planFilter, useCases]);

  const renderTemplates = () => {
    return (
      <>
        <GridSmart columnWidth={220} columnGap={10} columnCount={4}>
          <ImageTextCard.Style2 key="default" label={'New blank page'} src={undefined} onAdd={onAdd} disabledIconSave />
          {templatesData.map(item => (
            <ImageTextCard.Style2
              planHandle={item.planHandle}
              key={item.commandId}
              label={(item as any)?.category?.name ?? item.type}
              description={item.label}
              widthImage={item.image.width}
              heightImage={item.image.height}
              src={imageUrl(item.image.src, 270)}
              onAdd={() => handleAdd(item)}
              disabledIconSave
              onDelete={guard('admin', 'dev') ? handleDeleteTemplatePage(item.commandId) : undefined}
              loadingAdd={deleteStatus[item.commandId] === 'loading'}
              buttonStyle="style2"
              onPreview={(item as BE_PageProduct).previewUrl ? () => handlePreview(item as BE_PageProduct) : undefined}
            />
          ))}
        </GridSmart>
        {TrackingLoadMore}
      </>
    );
  };

  return (
    <View css={{ position: 'relative', width: '100%', height: '100%' }}>
      <ScrollBars
        onScrollFrame={({ scrollTop }) => {
          if (filterRef.current) {
            if (scrollTop > 100) {
              filterRef.current.style.cssText = `
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                background-color: var(--ui-color-gray1);
                padding: 15px 15px 0px 15px;
                z-index: 1000;
              `;
            } else {
              filterRef.current.style.cssText = `
                position: relative;
              `;
            }
          }
        }}
      >
        <View backgroundColor="gray1" css={styles.templateContent.container}>
          <View ref={filterRef} css={{ width: '100%' }}>
            <DebounceTextInput
              placeholder={i18n.t('builderPage.search', { text: i18n.t('adminDashboard.pages') })}
              sizeInput="small"
              width={300}
              css={styles.templateContent.input}
              value={search}
              onValueChange={onSearch}
            />
          </View>
          <View id="modal-create-normal-page" />
          <AsyncComponent status={getAllStatus} Success={renderTemplates()} />
        </View>
      </ScrollBars>
      {previewPage && (
        <ModalPreviewPage
          page={previewPage}
          onCancel={handleCancelPreview}
          onInstall={previewPage ? () => handleAdd(previewPage as ServerTemplateResponse) : undefined}
        />
      )}
    </View>
  );
};
