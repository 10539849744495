import { notification } from 'antd';
import Active from 'components/Active';
import { AsyncComponent, VedaLoadingItem, MyModal } from '@wiloke/ui';
import Field from 'components/Field';
import ProductCard from 'components/ProductCard';
import Radio from 'components/Radio';
import TextInput from 'components/TextInput';
import withDebounce from 'hocs/withDebounce';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useChangeBlogKey, useGetBlogs, useLoadMoreBlogs } from 'store/actions/shopify';

import { shopifySelector } from 'store/selectors';
import { i18n } from 'translation';
import { BlogPageLiquidVariable } from 'types/Page';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { GridSmart, View, ViewportTracking } from 'wiloke-react-core';
import { dashboardPageSettingsSelector, useChangeSettingsDashboardPage } from '../slice';
import { defaultBlogDataState } from 'store/reducers/shopify/reducerBlogs';
import { SHOPIFY_UNKNOWN_HANDLE, SHOPIFY_UNKNOWN_ID } from 'utils/constants/constants';

const DebounceInput = withDebounce(TextInput, 'value', 'onValueChange');
/*
 * Component này nằm ở các page item trang collection
 */
export const BlogSettings = () => {
  const { page, originPage, visibleListBlog } = useSelector(dashboardPageSettingsSelector);
  const { blogs, searchKey } = useSelector(shopifySelector.blogs);
  const { data, requestStatus, hasNextPage } = blogs[searchKey] || defaultBlogDataState;
  const [applyType, setApplyType] = useState<'all' | 'custom'>(Array.isArray(page?.shopifyPages) ? 'custom' : 'all');

  const changeKey = useChangeBlogKey();
  const changeSettings = useChangeSettingsDashboardPage();
  const getBlogs = useGetBlogs();
  const loadMoreBlogs = useLoadMoreBlogs();

  const history = useHistory();
  const { shopName } = getUserInfo();

  useEffect(() => {
    if (visibleListBlog) {
      getBlogs.request({ search: searchKey, shouldGetArticle: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleListBlog, searchKey]);

  useEffect(() => {
    if (visibleListBlog) {
      setApplyType(Array.isArray(page?.shopifyPages) ? 'custom' : 'all');
    }
  }, [page?.shopifyPages, visibleListBlog]);

  const handleSelectPage = (handle: string, id: number, featuredImg: string | undefined) => (isSelect: boolean) => {
    if (page) {
      // selected = true thì thêm vào mảng
      if (isSelect) {
        changeSettings({
          page: {
            ...page,
            shopifyPages: [...(Array.isArray(page?.shopifyPages) ? page.shopifyPages : []), { itemId: id, handle: handle, featuredImg }],
          },
        });
      } else {
        changeSettings({
          page: {
            ...page,
            shopifyPages: (Array.isArray(page?.shopifyPages) ? page.shopifyPages : []).filter(liquid => liquid?.handle !== handle),
          },
        });
      }
    }
  };

  const _handleChangeType = (val: string) => {
    const _val = val as 'all' | 'custom';
    if (page && _val === 'all') {
      changeSettings({
        page: {
          ...page,
          shopifyPages: 'all',
        },
      });
    }
    if (page && _val === 'custom') {
      changeSettings({
        page: {
          ...page,
          shopifyPages: !originPage?.shopifyPages || typeof originPage.shopifyPages === 'string' ? [] : originPage?.shopifyPages,
        },
      });
    }
  };

  const _handleSearch = (val: string) => {
    changeKey(val);
  };

  const renderLoadMore = () => {
    if (hasNextPage) {
      return (
        <ViewportTracking
          offsetBottom={-50}
          onEnterViewport={() => {
            if (requestStatus === 'success') {
              loadMoreBlogs.request({ search: searchKey, shouldGetArticle: false });
            }
          }}
        >
          <View css={{ padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <VedaLoadingItem />
          </View>
        </ViewportTracking>
      );
    }
  };

  const renderSuccess = () => {
    return (
      <View>
        <GridSmart columnWidth={200} columnGap={10}>
          {data.map(item => {
            const isActive =
              page !== undefined && page.shopifyPages !== undefined && page.shopifyPages !== 'all'
                ? page.shopifyPages.map(item => (item as BlogPageLiquidVariable)?.handle ?? '').includes(item.handle)
                : false;
            return (
              <Active disabledMulti onActive={handleSelectPage(item.handle, item.id, undefined)} variant="style2" active={isActive} key={item.id}>
                <ProductCard imageAspectRatio={16 / 9} imageSrc={''} title={item.title} />
              </Active>
            );
          })}
        </GridSmart>
        {renderLoadMore()}
      </View>
    );
  };

  const handleCancel = () => {
    changeSettings({
      visibleListBlog: false,
      originPage: undefined,
      page: undefined,
    });
  };

  const handleOverride = () => {
    if (page) {
      if (applyType === 'custom' && !Array.isArray(page.shopifyPages)) {
        notification.warning({
          message: i18n.t('validate.choose_at_least', { text: i18n.t('adminDashboard.blog') }),
        });
      } else {
        const entityVariant = 'Client';
        const defaultFirstItem = {
          featuredImg: undefined,
          handle: data[0]?.handle ?? SHOPIFY_UNKNOWN_HANDLE,
          itemId: data[0]?.id ?? SHOPIFY_UNKNOWN_ID,
        } as BlogPageLiquidVariable;
        const prevRepresentPage: BlogPageLiquidVariable =
          !page.shopifyRepresentPage || page.shopifyRepresentPage?.handle === SHOPIFY_UNKNOWN_HANDLE
            ? defaultFirstItem
            : (page.shopifyRepresentPage as BlogPageLiquidVariable);
        const isDeleted = Array.isArray(page.shopifyPages) ? !page.shopifyPages.find(item => item?.handle === prevRepresentPage?.handle) : false;
        const firstItemSelected = Array.isArray(page.shopifyPages) ? page.shopifyPages[0] : defaultFirstItem;
        history.push(`/builder?shop=${shopName}&id=${page.commandId}&entityVariant=${entityVariant}`, {
          label: page.label,
          type: 'blog',
          isCreate: false,
          shopifyRepresentPage: isDeleted ? firstItemSelected : prevRepresentPage,
          shopifyPages: applyType === 'all' ? 'all' : page.shopifyPages,
          backToPage: '/page/blog',
          entityVariant,
        });
        changeSettings({
          visibleListBlog: false,
          originPage: undefined,
        });
      }
    }
  };

  return (
    <MyModal
      isVisible={visibleListBlog}
      bodyCss={{
        height: applyType === 'all' ? '280px' : '80%',
      }}
      headerText={i18n.t('adminDashboard.blog')}
      size="large"
      onOk={handleOverride}
      onCancel={handleCancel}
      isLoading={requestStatus === 'loading'}
    >
      <Field label={`${i18n.t('general.apply_to')}`}>
        <View css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Radio.Group size="large" defaultValue={applyType} value={applyType} onChangeValue={_handleChangeType}>
            <Radio.Button value="all">{i18n.t('adminDashboard.all')}</Radio.Button>
            <Radio.Button value="custom"> {i18n.t('adminDashboard.specific_text', { text: i18n.t('adminDashboard.blog') })}</Radio.Button>
          </Radio.Group>
        </View>
      </Field>

      {applyType === 'custom' && (
        <>
          <DebounceInput
            block
            sizeInput="medium"
            placeholder={`${i18n.t('builderPage.search')} ${i18n.t('adminDashboard.blog')}...`}
            css={{ marginBottom: '8px' }}
            value={searchKey}
            onValueChange={_handleSearch}
          />

          <AsyncComponent status={requestStatus} Success={renderSuccess()} />
        </>
      )}

      {/* <ModalAskBeforeSave isLoading={statusSocketConnection === 'loading' || updateStatus === 'loading'} onOverride={handleOverride} /> */}
    </MyModal>
  );
};
