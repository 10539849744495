import { imageUrl } from '@wiloke/functions';
import { AsyncComponent, Masonry } from '@wiloke/ui';
import { Badge } from 'components/Badge';
import Field from 'components/Field';
import ImageTextCard from 'components/ImageTextCard';
import SelectAntd from 'components/SelectAntd';
import TextInput from 'components/TextInput';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';
import { useDeleteProductSection, useGetSection, useGetSections, useLoadMoreSections } from 'containers/ChooseTemplate/store/actions';
import { useSetUserCategoriesSection } from 'containers/ChooseTemplate/store/reducers/sections/user.reducerCategory';
import withDebounce from 'hocs/withDebounce';
import { range } from 'ramda';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelector, chooseTemplateVisibleSelector, pageDataSelector, sectionIdActiveSelector, sectionsSelector } from 'store/selectors';
import { i18n } from 'translation';
import { ProductSection } from 'types/Sections';
import { PLAN_ENUM, PLAN_HANDLE, PLAN_STATIC_DATA } from 'utils/constants/PlanEnum';
import { validatePlan } from 'utils/functions/validatePlan';
import { GridSmart, Space, View, ViewportTracking } from 'wiloke-react-core';

const DebounceInput = withDebounce(TextInput, 'value', 'onValueChange', 400);

const plans: Array<{ label: string; value: 'premium' | 'free' | 'all' }> = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'free',
    label: 'Free',
  },
  {
    value: 'premium',
    label: 'Premium',
  },
];

const Content: FC = () => {
  const { categorySlugs } = useSelector(sectionsSelector.categories);
  const { sections, getAllStatus, sectionIdLoading, loadMoreStatus, deleteStatus, savedStatus, hasNextPage } = useSelector(
    sectionsSelector.userSections,
  );

  const sectionIdActive = useSelector(sectionIdActiveSelector);

  const { role, plan } = useSelector(authSelector);
  const chooseTemplateVisible = useSelector(chooseTemplateVisibleSelector);
  const page = useSelector(pageDataSelector);

  const getSection = useGetSection();
  const getSections = useGetSections();
  const deleteSection = useDeleteProductSection();
  const loadMoreSections = useLoadMoreSections();
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();
  const setUserCategoriesSection = useSetUserCategoriesSection();

  const [searchKey, setSearchKey] = useState<string | undefined>(undefined);
  const [planFilter, setPlanFilter] = useState<string>('all');
  const filterRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (filterRef.current) {
      if (chooseTemplateVisible.isScrollingContent) {
        filterRef.current.style.cssText = `
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          background-color: var(--ui-color-gray2);
          padding: 10px;
          z-index: 1000;
        `;
      } else {
        filterRef.current.style.cssText = `
          position: relative;
        `;
      }
    }
  }, [chooseTemplateVisible.isScrollingContent]);

  useEffect(() => {
    if (chooseTemplateVisible.navKeys[0] === 'sections') {
      getSections.request({
        sectionIdActive,
        limit: 20,
        categoryNames: categorySlugs,
        search: searchKey,
        planFilter,
        isSearch: !!searchKey,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionIdActive, searchKey, categorySlugs, planFilter, chooseTemplateVisible.navKeys[0]]);

  const renderLoadingHeader = range(0, 5).map(i => (
    <View key={i}>
      <ImageTextCard.Loading aspectRatio={20} />
      <Space size={10} />
    </View>
  ));

  const renderLoading = (
    <GridSmart columnWidth={200} columnCount={2} columnGap={10}>
      {range(0, 5).map(i => (
        <ImageTextCard.Loading key={i} />
      ))}
    </GridSmart>
  );

  const TrackingLoadMore = useMemo(() => {
    const cursor = sections.at(-1)?.commandId;
    if (cursor && hasNextPage && getAllStatus === 'success') {
      return (
        <ViewportTracking
          offsetTop={-100}
          onEnterViewport={() => {
            if (loadMoreStatus !== 'loading') {
              loadMoreSections.request({ lastCursor: cursor, search: searchKey, planFilter, limit: 20, isSearch: !!searchKey });
            }
          }}
        >
          {loadMoreStatus === 'loading' ? (
            <GridSmart columnWidth={200} columnCount={2} columnGap={10}>
              {range(0, 3).map(i => (
                <ImageTextCard.Loading key={i} />
              ))}
            </GridSmart>
          ) : null}
        </ViewportTracking>
      );
    }
    return null;
  }, [getAllStatus, hasNextPage, loadMoreSections, loadMoreStatus, planFilter, searchKey, sections]);

  const handleInstallSection = (item: ProductSection) => () => {
    if (validatePlan({ userPlan: plan.planHandle, itemPlan: item.planHandle ?? PLAN_ENUM.free, role }) === 'pass') {
      getSection.request({
        sectionId: item.parentCommandId,
        productSection: item,
      });
    } else {
      setPlanRequesting({
        handle: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].handle,
        type: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].type,
        pricePerMonth: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].pricePerMonth,
        pricePerYear: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].pricePerYear,
        title: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].title,
        originalPricePerMonth: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].pricePerMonth,
        originalPricePerYear: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].pricePerYear,
      });
      setValidateCouponVisible(true);
    }
  };

  const renderSuccess = () => {
    return (
      <View css={{ height: '100%' }}>
        <Masonry defaultColumn={2} gap={10}>
          {sections.map(item => (
            <ImageTextCard
              planHandle={item.planHandle}
              key={item.commandId}
              label={item.label}
              loading={
                item.parentCommandId === sectionIdLoading ||
                deleteStatus[item.commandId] === 'loading' ||
                savedStatus[item.parentCommandId] === 'loading'
              }
              previewImg={item.image?.src ? imageUrl(item.image?.src, 10) : ''}
              src={item.image ? imageUrl(item.image.src, 300) : undefined}
              backgroundSize="contain"
              placeholderAspectRatio={16 / 9}
              buttonText={i18n.t('general.install')}
              disabled={
                role === 'user' ? ((item as ProductSection).pageTypes ? !(item as ProductSection).pageTypes.includes(page.type) : false) : false
              }
              disableText={i18n.t('builderPage.choose_template.cannot_use_section', { type: page.type, textTransform: 'uppercase' })}
              onAdd={handleInstallSection(item)}
              onDelete={
                role !== 'admin'
                  ? undefined
                  : () => {
                      deleteSection.request({ commandId: item.commandId });
                    }
              }
            />
          ))}
        </Masonry>
        {TrackingLoadMore}
      </View>
    );
  };

  const renderSectionsHeader = () => {
    return (
      <>
        {sections.map(item => (
          <ImageTextCard
            containerCss={{ marginBottom: '8px' }}
            key={item.commandId}
            label={item.label}
            planHandle={item.planHandle}
            loading={
              item.parentCommandId === sectionIdLoading ||
              deleteStatus[item.commandId] === 'loading' ||
              savedStatus[item.parentCommandId] === 'loading'
            }
            src={item.image ? imageUrl(item.image.src, 1000) : undefined}
            placeholderAspectRatio={10 / 2}
            widthImage={item.image?.width}
            heightImage={item.image?.height}
            buttonText={i18n.t('general.install')}
            onSave={undefined}
            disableText={i18n.t('builderPage.choose_template.cannot_use_section', { type: page.type, textTransform: 'uppercase' })}
            onAdd={() => {
              getSection.request({
                sectionId: item.parentCommandId,
                productSection: item,
              });
            }}
            onDelete={
              role !== 'admin'
                ? undefined
                : () => {
                    deleteSection.request({ commandId: item.commandId });
                  }
            }
          />
        ))}
        {TrackingLoadMore}
      </>
    );
  };

  return (
    <View css={{ padding: '10px', height: '100%' }}>
      <View
        tagName="div"
        ref={filterRef}
        css={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '10px',
          transition: 'position 0.5s all ease',
        }}
      >
        <View
          css={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '10px',
          }}
        >
          <Field
            css={{ width: '300px' }}
            label={`${i18n.t('adminDashboard.search', {
              text: i18n.t('general.sections'),
            })}`}
          >
            <DebounceInput value={searchKey} onValueChange={setSearchKey} block placeholder="Combo box, Countdown, v.v" />
          </Field>

          <Field css={{ width: '300px' }} label={`${i18n.t('general.plan')}`}>
            <SelectAntd
              data={plans}
              value={planFilter}
              onChange={val => {
                setPlanFilter(val as PLAN_HANDLE | 'all');
              }}
            />
          </Field>
        </View>

        {!!categorySlugs.length && (
          <Field
            css={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}
            label={
              <View
                css={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => {
                  setUserCategoriesSection([]);
                }}
              >
                {i18n.t('general.clear_all')}
              </View>
            }
          >
            {categorySlugs.map(item => (
              <Badge
                key={item}
                text={item}
                containerCss={{ cursor: 'pointer' }}
                onClick={currentBadge => {
                  const filteredSlugs = categorySlugs.filter(slug => slug !== currentBadge);
                  setUserCategoriesSection(filteredSlugs);
                }}
                onDelete={currentBadge => {
                  const filteredSlugs = categorySlugs.filter(slug => slug !== currentBadge);
                  setUserCategoriesSection(filteredSlugs);
                }}
              />
            ))}
          </Field>
        )}
      </View>

      <AsyncComponent
        isEmpty={sections.length === 0}
        Request={categorySlugs.includes('header') || categorySlugs.includes('Header') ? renderLoadingHeader : renderLoading}
        status={getAllStatus}
        Success={categorySlugs.includes('header') || categorySlugs.includes('Header') ? renderSectionsHeader() : renderSuccess()}
      />
    </View>
  );
};

export default Content;
