import { Coupon } from 'types/Coupon';

/**
 * Làm tròn giá xuống: trên 0.5 thì làm tròn xuống 0.5, dưới 0.5 thì làm tròn xuống số nguyên gần nhất
 * - Ví dụ:
 *  2.6234 => 2.5 -
 *  2.3 => 2
 * */
function roundDownNumber(num: number) {
  return Math.floor(num) + (num % 1 >= 0.5 ? 0.5 : 0);
}

export const calculatePricing = ({ type, discount, currentPrice }: { type: Coupon['type']; currentPrice: number; discount: number }) => {
  if (type === 'PERCENTAGE') {
    return roundDownNumber(currentPrice * (100 - discount)) / 100;
  } else if (type === 'FIXED') {
    return roundDownNumber(currentPrice - discount);
  } else {
    // Loại chiết khấu không hợp lệ thì return về giá gốc
    return currentPrice;
  }
};

/**
 * Regex text bên postmessage bắng sang thành số
 * - Ví du: $19/month => 19
 * */
export const transformPriceStringToNumber = (str: string) => {
  const regex = /\d+/;
  const match = str.match(regex);
  const number = match ? parseInt(match[0]) : 0;
  return number;
};
