import { all, call, retry, SagaReturnType, select } from '@redux-saga/core/effects';
import { put } from 'redux-saga/effects';
import { getMegamenusOfPageClient } from 'services/PageService/Logic/getMegamenusOfPageClient';
import { getPageClient } from 'services/PageService/Logic/getPageClient';
import { getSectionsOfPageClient } from 'services/PageService/Logic/getSectionsOfPageClient';
import { getChangelogsOfAtoms as getChangelogsOfSectionAtoms } from 'services/SectionService/Logic/Changelogs';
import { getChangelogsOfAtoms as getChangelogsOfMegamenuAtoms } from 'services/MegaMenuService/Logic/Changelogs';
import { importOtherEntityOfPageInTheme } from 'services/ThemeService/Logic/importOtherEntityOfPageInTheme';
import {
  addMegaMenusToPage,
  getPage,
  setMegaMenusOfHeaderFooterToPages,
  setThemeAddonsToPages,
  setThemeHeaderFooterToPages,
} from 'store/actions/actionPages';
import { setVendors } from 'store/actions/actionVendors';
import { getSectionVersion } from 'store/actions/versions/actionSectionVersion';
import { setGeneralSettingsPage } from 'store/global/generalSettings/slice';
import { setGlobalJs } from 'store/global/globalJs/slice';
import { setGlobalScss } from 'store/global/globalScss/slice';
import { megaMenusOfHeaderFooterSelector } from 'store/global/megaMenusOfHeaderFooter';
import { getGeneralSettingsPage } from 'store/global/statusGeneralSettings/actions';
import { addMultiAddons } from 'store/global/themeAddons';
import {
  defaultPickerRelateShopifySelector,
  socketOfImportThemeAtomClientServiceSelector,
  themeAddonsSelector,
  themeHeaderFooterSelector,
} from 'store/selectors';
import { ClientPage, PageType } from 'types/Page';
import { ThemeAddon } from 'types/Addons';
import { getDefaultShopifyPresentPage } from 'utils/getDefaultShopifyPresentPage';
import { handleWaitForSocketOfImportThemeAtomToClientServiceNPageInThemeClientFulfill } from 'hooks/useSocket/useSocketForImportThemeAtomToClientServiceNPageInThemeClient';
import { adapterSectionsHadShopifyData, adapterSectionHadShopifyData } from 'utils/adapterSectionHadShopifyData';
import { PageSection } from 'types/Sections';

// BE chỉ import header, footer, pages khi import theme atom sang theme client ==> Khi vào page cần check để import
function* handleImportTheOtherEntityOfPage({ payload }: ReturnType<typeof getPage.request>) {
  const { eventId }: ReturnType<typeof socketOfImportThemeAtomClientServiceSelector> = yield select(socketOfImportThemeAtomClientServiceSelector);
  const { id, themeAtomCommandId, onDisconnectSocketEarly } = payload;
  const page: SagaReturnType<typeof getPageClient> = yield retry(3, 1000, getPageClient, { commandId: id });
  if (eventId && page.isImported === false && themeAtomCommandId) {
    yield retry(3, 1000, importOtherEntityOfPageInTheme, {
      themeAtomCommandId,
      pageCommandId: id,
      eventId,
      eventType: 'Import page của theme',
    });
    const pageAfterImport: SagaReturnType<typeof getPageClient> = yield retry(3, 1000, getPageClient, { commandId: id });
    const { statusImport }: SagaReturnType<typeof handleWaitForSocketOfImportThemeAtomToClientServiceNPageInThemeClientFulfill> = yield call(
      handleWaitForSocketOfImportThemeAtomToClientServiceNPageInThemeClientFulfill,
      'Import page của theme',
    );
    if (statusImport === 'success') {
      return { page: pageAfterImport, isImportAction: true };
    }
    throw new Error('BE chưa import đc page -> Cần chạy lại');
  }
  onDisconnectSocketEarly();
  return { page, isImportAction: false };
}

export function* handleGetPageOfThemeClient_(action: ReturnType<typeof getPage.request>) {
  const { payload } = action;

  try {
    /** Xử lý "pageSettings" */
    const { page, isImportAction }: SagaReturnType<typeof handleImportTheOtherEntityOfPage> = yield retry(
      3,
      1000,
      handleImportTheOtherEntityOfPage,
      action,
    );

    if (page.pageSettings) {
      const { generalSettings, globalJs, globalScss, vendors } = page.pageSettings;
      const headerFooterEnabled = true; // Theme luôn có header footer + do nghiệp vụ nên 1 số page sẽ có "headerFooterEnabled" khi import vào theme
      yield put(
        setGeneralSettingsPage({
          settings: {
            ...generalSettings,
            headerFooterEnabled,
            label: payload.name || page.label,
          },
          pageId: payload.id,
        }),
      );
      yield put(setGlobalJs({ js: globalJs ?? '' }));
      yield put(setGlobalScss({ scss: globalScss ?? '' }));
      yield put(setVendors({ vendors: vendors ?? [] }));
    }

    yield put(getGeneralSettingsPage.success(undefined));
    /** Xử lý sections */
    const sectionCommandIds = page.sectionCommandIds;
    const sections: SagaReturnType<typeof getSectionsOfPageClient> = yield retry(3, 1000, getSectionsOfPageClient, { sectionCommandIds });
    /** Xử lý section version */
    const sectionSourceIds = sections.reduce<string[]>((res, section) => {
      if ('parentCommandId' in section && section.parentCommandId) {
        return res.concat(section.parentCommandId);
      }
      return res;
    }, []);
    yield all(
      sectionSourceIds.map(sectionCommandId => {
        return put(getSectionVersion.request({ sectionCommandId: sectionCommandId }));
      }),
    );
    const sectionsVersion: SagaReturnType<typeof getChangelogsOfSectionAtoms> = yield retry(3, 1000, getChangelogsOfSectionAtoms, sectionSourceIds);
    yield all(
      sectionsVersion.map(({ atomCommandId, version }) => put(getSectionVersion.success({ sectionCommandId: atomCommandId, data: version }))),
    );

    /** Xử lý megamenu */
    const megamenuResponses: Array<SagaReturnType<typeof getMegamenusOfPageClient> | undefined> = yield all(
      sections.map(section => {
        const { megaMenuCommandIds } = section;
        if (megaMenuCommandIds?.length) {
          return retry(3, 1000, getMegamenusOfPageClient, { megamenuCommandIds: megaMenuCommandIds });
        }
      }),
    );
    /** Xử lý megamenu version */
    const megamenuSectionSourceIds = Array.from(
      new Set(
        megamenuResponses.reduce<string[]>((res, megamenuResponse) => {
          if (megamenuResponse) {
            megamenuResponse.map(megamenuSection => {
              if ('parentCommandId' in megamenuSection && megamenuSection.parentCommandId) {
                res.push(megamenuSection.parentCommandId);
              }
            });
          }
          return res;
        }, []),
      ),
    );
    yield all(
      megamenuSectionSourceIds.map(sectionSectionCommandId => {
        return put(getSectionVersion.request({ sectionCommandId: sectionSectionCommandId }));
      }),
    );
    const megamenuSectionsVersion: SagaReturnType<typeof getChangelogsOfMegamenuAtoms> = yield retry(
      3,
      1000,
      getChangelogsOfMegamenuAtoms,
      megamenuSectionSourceIds,
    );
    yield all(
      megamenuSectionsVersion.map(({ atomCommandId, version }) => put(getSectionVersion.success({ sectionCommandId: atomCommandId, data: version }))),
    );

    /** Xử lý lấy shopify data */
    const { data: defaultPickerRelateShopifyData }: ReturnType<typeof defaultPickerRelateShopifySelector> = yield select(
      defaultPickerRelateShopifySelector,
    );
    const { article, blog, collection, product } = defaultPickerRelateShopifyData;

    const adapterSectionsHadShopifyData_ = (sections: PageSection[]) => {
      return isImportAction
        ? adapterSectionsHadShopifyData({ sections, article, blog, collection, product, isUsePageTemplateAction: false })
        : sections;
    };
    const adapterSectionHadShopifyData_ = (section: PageSection) => {
      return isImportAction ? adapterSectionHadShopifyData({ section, article, blog, collection, product, isUsePageTemplateAction: false }) : section;
    };

    const shopifyPages_ = 'all';
    const shopifyRepresentPage_ = getDefaultShopifyPresentPage({
      article,
      blog,
      collection,
      product,
      pageType: page.type as PageType,
    });

    // TODO: Utils transform thay vì ép kiểu
    const final_page: ClientPage = {
      ...page,
      parentCommandId: page.parentCommandId ?? undefined,
      image: page.image ?? { src: '', width: 0, height: 0 },
      sections: adapterSectionsHadShopifyData_(sections),
      type: payload.type ?? (page.type as PageType),
      label: payload.name || page.label,
      // @tuong -> Vì page của theme là "isApplyAll=true" nên BE sẽ trả về đại diện 1 item và mặc định page của theme có "isPublished=true"
      shopifyRepresentPage: shopifyRepresentPage_,
      shopifyPages: shopifyPages_,
      enable: true,
      entityType: 'Client',
    };

    yield put(getPage.success({ result: final_page }));
    yield all(
      megamenuResponses.map(megamenuResponse => {
        if (megamenuResponse) {
          return put(addMegaMenusToPage(adapterSectionsHadShopifyData_(megamenuResponse)));
        }
      }),
    );

    // sau khi getPage/success thì thêm addons, footer,header và mega menus của header,footer vào pages
    const { footers, headers }: ReturnType<typeof themeHeaderFooterSelector> = yield select(themeHeaderFooterSelector);
    const { megaMenusOfHeaderFooter }: ReturnType<typeof megaMenusOfHeaderFooterSelector> = yield select(megaMenusOfHeaderFooterSelector);

    const { data: addons }: ReturnType<typeof themeAddonsSelector> = yield select(themeAddonsSelector);
    const themeAddonsBody = addons.map(item => item.body).filter(Boolean) ?? [];
    yield put(
      addMultiAddons({
        addons: addons.map(addon => {
          return {
            ...addon,
            body: {
              ...addon.body,
              ...adapterSectionHadShopifyData_(addon.body),
            },
          } as ThemeAddon;
        }),
      }),
    );
    yield put(
      setThemeHeaderFooterToPages({
        footers: adapterSectionsHadShopifyData_(footers),
        headers: adapterSectionsHadShopifyData_(headers),
      }),
    );
    yield put(setThemeAddonsToPages(themeAddonsBody));
    yield put(setMegaMenusOfHeaderFooterToPages({ megaMenus: adapterSectionsHadShopifyData_(megaMenusOfHeaderFooter) }));
    payload.onFulfill(final_page);
  } catch (error) {
    console.log(error);
    if (error instanceof Error) {
      const message = error.message;
      yield put(getPage.failure({ message, id: payload.id }));
      yield put(getGeneralSettingsPage.failure(undefined));
      throw error;
    }
  }
}
