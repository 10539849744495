import { useLinkPicker } from 'components/LinkPicker/store/context/LinkPickerContext';
import { View } from 'wiloke-react-core';
import { DebounceTextInput } from '../Form';

export const BuyNow = () => {
  const { value } = useLinkPicker();
  return (
    <View css={{ marginTop: '8px' }}>
      <DebounceTextInput disabled value={value} sizeInput="small" block />
    </View>
  );
};
