import { Styles, Theme } from 'wiloke-react-core';

export const container: Styles = {
  zIndex: 2,
  padding: '6px 8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: '700',
  boxShadow: '1px 1px 5px rgb(12 11 11 / 40%)',
  columnGap: '4px',
  textTransform: 'uppercase',
  borderRadius: '100px',
  filter: 'drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.15))',
  fontSize: '12px',
};

export const text = ({ colors }: Theme): Styles => ({
  textTransform: 'uppercase',
  color: colors.light,
  fontWeight: '700',
  lineHeight: 'normal',
});
