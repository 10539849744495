import { Button } from '@wiloke/ui';
import Field from 'components/Field';
import { Option } from 'components/SelectAntd';
import { SwitchBeautyDebounce, TextInputDebounce } from 'containers/BuilderPage/components/SchemaField/components/InputDebounced/InputDebounced';
import { FC } from 'react';
import { i18n } from 'translation';
import { SettingRadioGroup, SettingSelect } from 'types/Schema';
import { v4 } from 'uuid';
import { FontAwesome, View } from 'wiloke-react-core';
import { FormSchemaSettingProps } from '../../../type';

export const Select_RadioGroup: FC<FormSchemaSettingProps> = ({ data, onChange }) => {
  const _data = data as SettingSelect | SettingRadioGroup;

  const renderMultiSwitch = ({ item }: { item: Option & { id: string | number } }) => {
    const _children = _data.children ? _data.children.split(', ') : _data.children.split('');

    return (
      <SwitchBeautyDebounce
        disableText=""
        enableText=""
        checked={!!_children && _children.includes(item.value.toString())}
        onValueChange={checked => {
          if (checked) {
            const _value = _children.concat(item.value.toString());
            onChange?.({
              children: _value.join(', '),
            } as SettingSelect);
          } else {
            const _value = _children.filter(child => child.toString() !== item.value.toString()).join(', ');
            onChange?.({
              children: _value,
            } as SettingSelect);
          }
        }}
      />
    );
  };

  return (
    <View>
      {_data.options.map(item => (
        <View key={item.id} css={{ marginBottom: '8px' }}>
          <View css={{ display: 'flex', alignItems: 'end', '> *': { flex: '1 1 auto', margin: '0 8px 0 0' } }}>
            <Field fontSize={12} label={i18n.t('general.label')}>
              <TextInputDebounce
                block
                autoFocus
                value={item.label}
                onValueChange={value => {
                  onChange?.({
                    options: _data.options.map(option => {
                      if (option.id === item.id) {
                        return {
                          ...option,
                          label: value,
                          value:
                            option.label.toLowerCase().replaceAll(' ', '_') === option.value
                              ? value.toLowerCase().replaceAll(' ', '_')
                              : option.value,
                        };
                      }
                      return option;
                    }),
                  } as SettingSelect);
                }}
              />
            </Field>
            <Field fontSize={12} label={i18n.t('general.value')}>
              <TextInputDebounce
                block
                value={item.value.toString()}
                maxLength={15} // @tuong -> Shopify thường giới hạn 25 kí tự ==> Giới hạn 15 vì cần nối thêm những cái khác nữa
                onValueChange={value => {
                  onChange?.({
                    children: item.value === _data.children ? value : _data.children,
                    options: _data.options.map(option => {
                      if (option.id === item.id) {
                        return { ...option, value };
                      }
                      return option;
                    }),
                  } as SettingSelect);
                }}
              />
            </Field>
            <Field fontSize={12} label={i18n.t('general.default')}>
              {_data.type === 'select' && _data.enabledMultiple ? (
                renderMultiSwitch({ item })
              ) : (
                <SwitchBeautyDebounce
                  disableText=""
                  enableText=""
                  checked={!!_data.children && _data.children === item.value}
                  onValueChange={checked => {
                    if (checked) {
                      onChange?.({
                        children: item.value,
                      } as SettingSelect);
                    } else {
                      onChange?.({
                        children: '',
                      } as SettingSelect);
                    }
                  }}
                />
              )}
            </Field>
            <Button
              backgroundColor="gray2"
              size="small"
              radius={6}
              color="gray9"
              css={{ height: '46px' }}
              onClick={() => {
                if (_data.type === 'select' && _data.enabledMultiple) {
                  const _children = _data.children ? _data.children.split(', ') : _data.children.split('');
                  const _value = _children.filter(child => child.toString() !== item.value.toString()).join(', ');

                  onChange?.({
                    children: _value,
                    options: _data.options.filter(option => option.id !== item.id),
                  } as SettingSelect);
                } else {
                  onChange?.({
                    options: _data.options.filter(option => option.id !== item.id),
                  } as SettingSelect);
                }
              }}
            >
              <FontAwesome type="far" name="trash" size={18} />
            </Button>
          </View>
        </View>
      ))}
      <Button
        block
        radius={6}
        backgroundColor="gray2"
        color="gray8"
        css={{ marginTop: '8px' }}
        onClick={() => {
          onChange?.({
            options: _data.options.concat({ label: '', value: '', id: v4() }),
          } as any);
        }}
      >
        {i18n.t('general.add', { text: i18n.t('general.value', { text: i18n.t('general.default') }), textTransform: 'capitalize' })}
      </Button>

      {_data.type === 'select' && (
        <Field label="Enable Multiple" css={{ marginTop: '10px' }}>
          <SwitchBeautyDebounce
            checked={_data.enabledMultiple}
            onValueChange={value => {
              // @ts-ignore
              onChange?.({ enabledMultiple: value });
            }}
          />
        </Field>
      )}
    </View>
  );
};
