import Field from 'components/Field';
import SelectAntd from 'components/SelectAntd';
import { equals, omit } from 'ramda';
import { FC, memo, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PageType } from 'types/Page';
import { useGetPagesTemplate, useLoadMorePagesTemplate } from '../../../store/actions/actionPagesTemplate';
import { pagesTemplateSelector } from '../../../store/reducers/slicePagesTemplate';
import { LABEL_OF_PAGES } from '../../@consts';

interface Props {
  pageType: PageType;
  onChange: (value: string[]) => void;
  value: string[];
  isLoading: boolean;
}

const SelectPagesTemplateComponent: FC<Props> = ({ pageType, value, isLoading, onChange }) => {
  const { data } = useSelector(pagesTemplateSelector);
  const { items, getStatus, hasNextPage, loadmoreStatus } = useMemo(() => data[pageType], [data, pageType]);

  const getPagesTemplate = useGetPagesTemplate();
  const loadmorePagesTemplate = useLoadMorePagesTemplate();

  useEffect(() => {
    getPagesTemplate.request({ pageType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Field label={LABEL_OF_PAGES[pageType]}>
      <SelectAntd
        value={value}
        mode="multiple"
        loading={getStatus === 'loading' || loadmoreStatus === 'loading' || isLoading}
        data={items.map(item => ({ label: item.label, value: item.commandId }))}
        onChange={onChange}
        onPopupScroll={e => {
          const { scrollHeight, scrollTop, clientHeight } = e.target as Element;
          if (scrollHeight - scrollTop - clientHeight < 300 && getStatus === 'success' && hasNextPage) {
            const lastCursor = items[items.length - 1].commandId;
            loadmorePagesTemplate.request({
              pageType,
              cursor: lastCursor,
            });
          }
        }}
      />
    </Field>
  );
};

export const SelectPagesTemplate = memo(SelectPagesTemplateComponent, (prevProps, nextProps) => {
  return equals(omit(['onChange'], prevProps), omit(['onChange'], nextProps));
});
