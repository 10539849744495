import { Button, Hotspot, MyModal, Tooltip } from '@wiloke/ui';
import { Descriptions, Popover, message, notification } from 'antd';
import Field from 'components/Field/Field';
import Switch from 'components/Switch/Switch';
import TextInput from 'components/TextInput/TextInput';
import { savePageForDev, useSavePageForAdmin, useSavePageForUser, useSaveTheme } from 'containers/BuilderPage/store/saveForBuilder/actions';
import { useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { useUndoRedoForRedux } from 'hooks/useUndoRedoForRedux/useUndoRedoForRedux';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useSetPageAfterCreate } from 'store/actions/actionPages';
import { syncPageNotification } from 'store/global/socket/syncShopifyNClonePageAtomServiceToThemeAtomService/watchSyncToShopify';
import {
  cssVariablesSelector,
  layoutSettingsSelector,
  pagesDataSelector,
  saveForBuilderSelector,
  socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector,
} from 'store/selectors';
import { i18n } from 'translation';
import { AddonOfTheme_Atom_N_Client } from 'types/Addons';
import { AdminPage, Page, PageOfThemeService } from 'types/Page';
import { Result } from 'types/Result';
import { SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client } from 'types/Sections';
import { LiquidSyntaxToTwigError } from 'utils/LiquidSyntaxToTwig';
import { copyToClipboard } from 'utils/copyToClipboard';
import getPageInfo from 'utils/functions/getInfo';
import { FontAwesome, Space, View } from 'wiloke-react-core';
import useResultForSave from '../../../useResult/useResultForSave';
import { Action, ActionMappingToLabel } from '../@consts/ActionMapping';
import { ShopifyPageTypeMappingToLabel } from '../@consts/ShopifyPageTypeMapping';
import { VedaPageEntityTypeMappingToLabel, VedaPageEntityTypeMappingToSrcImg } from '../@consts/VedaPageEntityTypeMapping';
import { Consts } from 'utils/constants/constants';

type Open = (page: Page) => void;
let modalStaticHandler: Open = () => {};
export const ModalSavePageAdminRole = () => {
  const [page, setPage] = useState<Page | undefined>(undefined);
  const [action, setAction] = useState<{ type: Action; entityType: Page['entityType'] } | null>(null);
  const [finalFormForSavePage, setFinalFormForSavePage] = useState<{
    type: Action;
    entityType: Page['entityType'];
    label: string;
    isSyncToShopify: boolean;
  } | null>(null);

  const [finalFormForSaveTheme, setFinalFormForSaveTheme] = useState<{
    isSyncToShopify: boolean;
    type: Action;
    entityType: Page['entityType'];
  } | null>(null);

  const { getResult, isExtracting } = useResultForSave();
  const savePageForAdmin = useSavePageForAdmin();
  const setPageAfterCreate = useSetPageAfterCreate();
  const savePageForUser = useSavePageForUser();
  const saveTheme = useSaveTheme();

  const layoutSettings = useSelector(layoutSettingsSelector);
  const cssVariables = useSelector(cssVariablesSelector);
  const { savePageStatus, saveThemeStatus } = useSelector(saveForBuilderSelector);
  const { statusSyncToShopify } = useSelector(socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector);
  const pages = useSelector(pagesDataSelector);
  const shop = getPageInfo('shop');

  const history = useHistory<'/builder'>();

  const { connect, disconnect, statusSocketConnection } = useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService('sync_shopify');

  const isSaving = useMemo(() => {
    return (
      isExtracting ||
      savePageStatus === 'loading' ||
      saveThemeStatus === 'loading' ||
      statusSocketConnection === 'loading' ||
      statusSyncToShopify === 'loading'
    );
  }, [isExtracting, savePageStatus, saveThemeStatus, statusSocketConnection, statusSyncToShopify]);

  const handleError = (entityType: Page['entityType']) => {
    const messageError = `Lỗi api liên quan đến page --> ${entityType}`;
    notification.error({
      message: 'Có gì đó hơi sai sai.',
      description: (
        <div>
          <p>Báo dev kèm lời nhắn: {messageError}"</p>
          <button
            style={{
              borderRadius: '4px',
              backgroundColor: '#2C36DC',
              color: '#fff',
              fontWeight: 500,
              padding: '10px 16px',
              cursor: 'pointer',
              outline: 'none',
              border: 'none',
            }}
            onClick={() => {
              copyToClipboard(messageError);
              message.success('Đã copy');
            }}
          >
            Copy lời nhắn
          </button>
        </div>
      ),
    });
  };

  const handleSuccess = (action: Action, __: Page['entityType']) => {
    setPage(undefined);
    if (action === 'Create') {
      notification.success({
        message: 'Tạo mới thành công',
        description: 'Tạo mới thành công. Vui lòng đợi trang web redirect trong vài giây sắp tới',
      });
    } else {
      notification.success({
        message: 'Ghi đè thành công',
      });
    }
  };

  const handlePageFinalOk = async (
    { type, entityType, label, isSyncToShopify }: Exclude<typeof finalFormForSavePage, null>,
    isOverrideIndividualPages = true,
  ) => {
    if (page) {
      if (type === 'Create') {
        notification.info({ message: 'Bắt đầu trích xuất kết quả' });
        const result = await getResult({
          entityVariant: 'Atom',
          errorOption: 'throw',
        });
        const pageData = result.pages[page.commandId];
        const pageSettings = {
          generalSettings: {
            ...pageData.data.pageSettings.generalSettings,
            label,
          },
          globalJs: pageData.data.pageSettings.globalJs,
          globalScss: pageData.data.pageSettings.globalScss,
          vendors: pageData.data.pageSettings.vendors,
          cssVariables,
          layoutSettings,
        };
        if (entityType === 'Atom') {
          connect({
            onSuccess: () => {
              savePageForAdmin.request({
                isIgnoreSyncShopify: !isSyncToShopify,
                page: pageData.data.page as AdminPage,
                settings: pageSettings,
                outputBuilder: result,
                method: 'create',
                isOverrideIndividualPages,
                onFulfill() {
                  useUndoRedoForRedux.reset?.();
                  disconnect({});
                },
                onError() {
                  handleError(entityType);
                },
                onSuccess(pageResponse) {
                  if (pageResponse) {
                    handleSuccess(type, entityType);
                    history.replace({
                      ...history.location,
                      state: {
                        ...history.location.state,
                        label: '',
                        isCreate: false,
                        entityVariant: 'Atom',
                      },
                      search: `?shop=${shop}&id=${pageResponse.commandId}&entityVariant=Atom`,
                    });
                    setPageAfterCreate(pageResponse);
                  } else {
                    handleError(entityType);
                  }
                },
              });
            },
            onError() {
              syncPageNotification.done();
              notification.error({
                message: i18n.t('publish_shopify.init_sync_error.message'),
                description: i18n.t('publish_shopify.init_sync_error.description'),
              });
            },
          });
        } else if (entityType === 'Draft') {
          connect({
            onSuccess: () => {
              savePageForDev.request({
                isIgnoreSyncShopify: !isSyncToShopify,
                page: pageData.data.page,
                settings: pageSettings,
                outputBuilder: result,
                method: 'create',
                isOverrideIndividualPages,
                onFulfill() {
                  useUndoRedoForRedux.reset?.();
                  disconnect({});
                },
                onError() {
                  handleError(entityType);
                },
                onSuccess(pageResponse) {
                  if (pageResponse) {
                    handleSuccess(type, entityType);
                    history.replace({
                      ...history.location,
                      state: {
                        ...history.location.state,
                        label: '',
                        isCreate: false,
                        entityVariant: 'Draft',
                      },
                      search: `?shop=${shop}&id=${pageResponse.commandId}&entityVariant=Draft`,
                    });
                    setPageAfterCreate(pageResponse);
                  } else {
                    handleError(entityType);
                  }
                },
              });
            },
            onError() {
              syncPageNotification.done();
              notification.error({
                message: i18n.t('publish_shopify.init_sync_error.message'),
                description: i18n.t('publish_shopify.init_sync_error.description'),
              });
            },
          });
        } else if (entityType === 'Client') {
          if (isSyncToShopify) {
            syncPageNotification.next({
              title: i18n.t('publish_shopify.sync_page_message.step', { text: '1' }),
              description: i18n.t('publish_shopify.sync_page_message.init'),
            });
          }

          const id = getPageInfo('id');

          const result_with_enable_page: Result = {
            ...result,
            pages: {
              ...result.pages,
              [id]: {
                ...result.pages[id],
                data: {
                  ...result.pages[id].data,
                  pageSettings: {
                    ...result.pages[id].data.pageSettings,
                    generalSettings: {
                      ...result.pages[id].data.pageSettings.generalSettings,
                      label,
                    },
                  },
                  page: {
                    ...result.pages[id].data.page,
                    enable: true,
                    label,
                  },
                },
              },
            },
          };

          const pageData = result_with_enable_page.pages[page.commandId];

          connect({
            onSuccess() {
              savePageForUser.request({
                isIgnoreSyncShopify: !isSyncToShopify,
                isSaveAsLandingPage: false,
                isOverrideIndividualPages,
                data: pageData.data.page,
                type: 'create',
                result: pageData,
                addons: result_with_enable_page.theme.addons,
                builderType: 'page',
                previewImage: pageData.data.page.image ?? { src: '', height: 0, width: 0 },
                outputBuilder: result_with_enable_page,
                onFulfill() {
                  useUndoRedoForRedux.reset?.();
                  disconnect({});
                },
                onError() {
                  handleError(entityType);
                },
                onSuccess: pageResponse => {
                  if (pageResponse) {
                    handleSuccess(type, entityType);
                    // @duong: Nếu là tạo page thì sau khi save thành công sẽ thay id ở trên params bằng id mới của server trả về và xóa state params ở bên dashboard bắn sang
                    const entityVariant = 'Client';
                    history.replace({
                      ...history.location,
                      state: {
                        ...history.location.state,
                        label: '',
                        isCreate: false,
                        isAdminTemplate: false,
                        entityVariant,
                      },
                      search: `?shop=${shop}&id=${pageResponse.commandId}&entityVariant=${entityVariant}`,
                    });
                    setPageAfterCreate(pageResponse);
                  } else {
                    handleError(entityType);
                  }
                },
                settings: {
                  generalSettings: pageData.data.pageSettings.generalSettings,
                  globalJs: pageData.data.pageSettings.globalJs,
                  globalScss: pageData.data.pageSettings.globalScss,
                  vendors: pageData.data.pageSettings.vendors,
                  cssVariables: result_with_enable_page.theme.themeSettings.cssVariables,
                  layoutSettings: result_with_enable_page.theme.themeSettings.layoutSettings,
                },
                page: pageData.data.page as PageOfThemeService,
                headers: (result_with_enable_page.theme.header ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
                footers: (result_with_enable_page.theme.footer ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
              });
            },
            onError() {
              syncPageNotification.done();
              notification.error({
                message: i18n.t('publish_shopify.init_sync_error.message'),
                description: i18n.t('publish_shopify.init_sync_error.description'),
              });
            },
          });
        }
      } else {
        notification.info({ message: 'Bắt đầu trích xuất kết quả' });
        const result = await getResult({
          entityVariant: 'Atom',
          errorOption: 'throw',
        });
        const pageData = result.pages[page.commandId];
        const pageSettings = {
          generalSettings: {
            ...pageData.data.pageSettings.generalSettings,
            label,
          },
          globalJs: pageData.data.pageSettings.globalJs,
          globalScss: pageData.data.pageSettings.globalScss,
          vendors: pageData.data.pageSettings.vendors,
          cssVariables,
          layoutSettings,
        };
        if (entityType === 'Atom') {
          connect({
            onSuccess: () => {
              savePageForAdmin.request({
                isIgnoreSyncShopify: !isSyncToShopify,
                page: pageData.data.page as AdminPage,
                settings: pageSettings,
                outputBuilder: result,
                method: 'update',
                isOverrideIndividualPages,
                onFulfill() {
                  useUndoRedoForRedux.reset?.();
                  disconnect({});
                },
                onError() {
                  handleError(entityType);
                },
                onSuccess(pageResponse) {
                  if (pageResponse) {
                    handleSuccess(type, entityType);
                  } else {
                    handleError(entityType);
                  }
                },
              });
            },
            onError() {
              syncPageNotification.done();
              notification.error({
                message: i18n.t('publish_shopify.init_sync_error.message'),
                description: i18n.t('publish_shopify.init_sync_error.description'),
              });
            },
          });
        } else if (entityType === 'Draft') {
          connect({
            onSuccess: () => {
              savePageForDev.request({
                isIgnoreSyncShopify: !isSyncToShopify,
                page: pageData.data.page,
                settings: pageSettings,
                outputBuilder: result,
                method: 'update',
                isOverrideIndividualPages,
                onFulfill() {
                  useUndoRedoForRedux.reset?.();
                  disconnect({});
                },
                onError() {
                  handleError(entityType);
                },
                onSuccess(pageResponse) {
                  if (pageResponse) {
                    handleSuccess(type, entityType);
                    history.replace({
                      ...history.location,
                      state: {
                        ...history.location.state,
                        label: '',
                        isCreate: false,
                        entityVariant: 'Draft',
                      },
                      search: `?shop=${shop}&id=${pageResponse.commandId}&entityVariant=Draft`,
                    });
                  } else {
                    handleError(entityType);
                  }
                },
              });
            },
            onError() {
              syncPageNotification.done();
              notification.error({
                message: i18n.t('publish_shopify.init_sync_error.message'),
                description: i18n.t('publish_shopify.init_sync_error.description'),
              });
            },
          });
        } else if (entityType === 'Client') {
          if (isSyncToShopify) {
            syncPageNotification.next({
              title: i18n.t('publish_shopify.sync_page_message.step', { text: '1' }),
              description: i18n.t('publish_shopify.sync_page_message.init'),
            });
          }

          const id = getPageInfo('id');

          const result_with_enable_page: Result = {
            ...result,
            pages: {
              ...result.pages,
              [id]: {
                ...result.pages[id],
                data: {
                  ...result.pages[id].data,
                  pageSettings: {
                    ...result.pages[id].data.pageSettings,
                    generalSettings: {
                      ...result.pages[id].data.pageSettings.generalSettings,
                      label,
                    },
                  },
                  page: {
                    ...result.pages[id].data.page,
                    enable: true,
                    label,
                  },
                },
              },
            },
          };

          const pageData = result_with_enable_page.pages[page.commandId];

          connect({
            onSuccess() {
              savePageForUser.request({
                isIgnoreSyncShopify: !isSyncToShopify,
                isSaveAsLandingPage: false,
                isOverrideIndividualPages,
                data: {
                  ...page,
                  label,
                },
                type: 'update',
                result: pageData,
                addons: result_with_enable_page.theme.addons,
                builderType: 'page',
                previewImage: pageData.data.page.image ?? { src: '', height: 0, width: 0 },
                outputBuilder: result_with_enable_page,
                onFulfill() {
                  useUndoRedoForRedux.reset?.();
                  disconnect({});
                },
                onError() {
                  handleError(entityType);
                },
                onSuccess: pageResponse => {
                  useUndoRedoForRedux.reset?.();
                  disconnect({});
                  if (pageResponse) {
                    handleSuccess(type, entityType);
                  } else {
                    handleError(entityType);
                  }
                },
                settings: {
                  generalSettings: pageData.data.pageSettings.generalSettings,
                  globalJs: pageData.data.pageSettings.globalJs,
                  globalScss: pageData.data.pageSettings.globalScss,
                  vendors: pageData.data.pageSettings.vendors,
                  cssVariables: result_with_enable_page.theme.themeSettings.cssVariables,
                  layoutSettings: result_with_enable_page.theme.themeSettings.layoutSettings,
                },
                page: pageData.data.page as PageOfThemeService,
                headers: (result_with_enable_page.theme.header ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
                footers: (result_with_enable_page.theme.footer ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
              });
            },
            onError() {
              syncPageNotification.done();
              notification.error({
                message: i18n.t('publish_shopify.init_sync_error.message'),
                description: i18n.t('publish_shopify.init_sync_error.description'),
              });
            },
          });
        }
      }
    }
  };

  const handleThemeFinalOk = ({ type, entityType, isSyncToShopify }: Exclude<typeof finalFormForSaveTheme, null>) => {
    if (isSyncToShopify) {
      syncPageNotification.next({
        title: i18n.t('publish_shopify.sync_page_message.step', { text: '1' }),
        description: i18n.t('publish_shopify.sync_page_message.init'),
      });
    }

    const id = getPageInfo('id');
    const themeId = getPageInfo('themeId');

    const clearLabelParams = () => {
      const state = {
        ...history.location.state,
        label: '',
        handle: '',
        isCreate: false,
      };
      history.replace({ ...history.location, state });
    };

    connect({
      onSuccess: async () => {
        try {
          if (page) {
            const entityVariant = entityType === 'ThemeAtom' ? 'Atom' : 'Draft';
            const result = await getResult({
              entityVariant,
              errorOption: 'throw',
            });

            const result_with_enable_page: Result = {
              ...result,
              pages: {
                ...result.pages,
                [id]: {
                  ...result.pages[id],
                  data: {
                    ...result.pages[id].data,
                    page: {
                      ...result.pages[id].data.page,
                      enable: true,
                    },
                  },
                },
              },
            };

            const pageData = result_with_enable_page.pages[page.commandId];

            // Nếu page không có section thì không cho save
            if (typeof pageData === 'undefined') {
              notification.error({
                message: i18n.t('builderPage.must_have_section'),
              });
              return;
            }

            const pageIds = Object.values(pages).map(item => item.commandId);

            saveTheme.request({
              isIgnoreSyncShopify: !isSyncToShopify,
              variant: entityVariant,
              commandId: themeId,
              featuredImage: result_with_enable_page.theme.themeSettings.generalSettings.featuredImage ?? '',
              globalJs: result_with_enable_page.theme.globalJs,
              globalScss: result_with_enable_page.theme.globalScss,
              name: result_with_enable_page.theme.themeSettings.generalSettings.label,
              outputBuilder: result_with_enable_page,
              pageIds: pageIds,
              themeSettings: result_with_enable_page.theme.themeSettings,
              vendors: {
                data: result_with_enable_page.theme.vendors,
              },
              addons: result_with_enable_page.theme.addons as AddonOfTheme_Atom_N_Client[],
              headers: (result_with_enable_page.theme.header ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
              footers: (result_with_enable_page.theme.footer ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
              onFulfill() {
                useUndoRedoForRedux.reset?.();
                disconnect({});
              },
              onError() {
                handleError(entityType);
              },
              onSuccess: () => {
                handleSuccess(type, entityType);
              },
            });
            clearLabelParams();
          }
        } catch (err) {
          console.log(err);
          disconnect({});
          if (err instanceof LiquidSyntaxToTwigError) {
            notification.error({
              message: i18n.t('publish_shopify.error_in_code'),
              description: err.message,
            });
          } else if (err instanceof Error) {
            notification.error({
              message: i18n.t('publish_shopify.error_unknown.message'),
              description: i18n.t('publish_shopify.error_unknown.description'),
            });
          }
        }
      },
      onError: () => {
        notification.error({
          message: i18n.t('publish_shopify.init_sync_error.message'),
          description: i18n.t('publish_shopify.init_sync_error.description'),
        });
        syncPageNotification.done();
      },
    });
  };

  useEffect(() => {
    modalStaticHandler = setPage;
  }, [page]);

  useEffect(() => {
    if (!page) {
      // Reset state
      setAction(null);
      setFinalFormForSavePage(null);
      setFinalFormForSaveTheme(null);
    }
  }, [page]);

  if (!page) {
    return null;
  }

  const renderVedaPageEntityTypeWithDescription = (entityType: Page['entityType']) => {
    return (
      <View css={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
        {VedaPageEntityTypeMappingToLabel[entityType]}
        <Popover title={<img src={VedaPageEntityTypeMappingToSrcImg[entityType]} style={{ maxWidth: 900 }} />}>
          <View css={{ position: 'relative' }}>
            <FontAwesome type="far" name="question-circle" color="primary" size={18} />
            <Hotspot css={{ position: 'absolute', top: '0px', right: '0px' }} />
          </View>
        </Popover>
      </View>
    );
  };

  const renderAction = (entityType: Page['entityType']) => {
    const isNewPage = !page.commandId;
    const isDifferenceEntityType = entityType !== page.entityType;
    const isNotTheme = entityType !== 'ThemeAtom' && entityType !== 'ThemeDraft';
    return (
      <Descriptions.Item label={renderVedaPageEntityTypeWithDescription(entityType)}>
        <View css={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          {isNotTheme && (
            <Button
              radius={6}
              backgroundColor="primary"
              onClick={() => {
                setAction({ type: 'Create', entityType });
              }}
            >
              {ActionMappingToLabel.Create}
            </Button>
          )}
          <Tooltip
            portal
            text={
              isNewPage
                ? 'Không hợp lệ, page này đang là page mới - không ghi đè được vào đâu cả'
                : isDifferenceEntityType
                ? `Không hợp lệ, page đang thao tác là "${VedaPageEntityTypeMappingToLabel[page.entityType]}"`
                : undefined
            }
          >
            <Button
              radius={6}
              backgroundColor="secondary"
              disabled={isNewPage || isDifferenceEntityType}
              onClick={() => {
                setAction({ type: 'Update', entityType });
              }}
            >
              {ActionMappingToLabel.Update}
            </Button>
          </Tooltip>
        </View>
      </Descriptions.Item>
    );
  };

  const renderModalFinalAction = () => {
    if (!action) {
      return null;
    }
    return (
      <MyModal
        isVisible
        isLoading={isSaving}
        size="medium"
        headerText="Hãy giữ cái đầu lạnh"
        onCancel={() => {
          setAction(null);
        }}
        onOk={() => {
          if (action.entityType !== 'ThemeAtom' && action.entityType !== 'ThemeDraft') {
            setFinalFormForSavePage({
              type: action.type,
              entityType: action.entityType,
              label: page.label,
              isSyncToShopify: false,
            });
          } else {
            setFinalFormForSaveTheme({
              type: action.type,
              entityType: action.entityType,
              isSyncToShopify: false,
            });
          }
        }}
      >
        <View css={{ fontSize: '20px', fontWeight: '500', marginBottom: '16px' }}>
          Bạn đang "{ActionMappingToLabel[action.type]}" + "{VedaPageEntityTypeMappingToLabel[action.entityType]}".{' '}
          <View tagName="span" color="danger" css={{ textDecoration: 'underline' }}>
            Hãy suy nghĩ kỹ trước khi quyết định
          </View>
        </View>
        <img src={VedaPageEntityTypeMappingToSrcImg[action.entityType]} style={{ maxWidth: 900 }} />
      </MyModal>
    );
  };

  const renderFinalFormForSavePage = () => {
    if (!finalFormForSavePage) {
      return null;
    }
    return (
      <MyModal
        isVisible
        headerText="Bước cuối cùng"
        cancelText="Huỷ bỏ"
        okText={ActionMappingToLabel[finalFormForSavePage.type]}
        onCancel={() => {
          if (isSaving) {
            notification.info({
              message: 'Đừng',
              description: 'Chức năng này được làm khá tạm bợ, rất có thể những thứ đáng tiếc sẽ xảy ra. Xin hãy đợi quá trình update kết thúc',
            });
          } else {
            setFinalFormForSavePage(null);
          }
        }}
        isLoading={isSaving}
        onOk={() => {
          if (finalFormForSavePage.entityType !== 'ThemeAtom' && finalFormForSavePage.entityType !== 'ThemeDraft') {
            handlePageFinalOk(finalFormForSavePage);
          }
        }}
      >
        <Field
          label="Có sync lên shopify hay không?"
          description="Chậm lại và suy nghĩ. Việc sync lên shopify có thể gây ảnh hưởng đến những người khác vì rất có thể bạn đang login vào shop làm demo"
        >
          <Switch
            checked={finalFormForSavePage.isSyncToShopify}
            onValueChange={checked => {
              setFinalFormForSavePage({ ...finalFormForSavePage, isSyncToShopify: checked });
            }}
          />
        </Field>
        <Field
          label="Tên page"
          description={
            finalFormForSavePage.type === 'Create'
              ? `Một page mới có tên "${finalFormForSavePage.label}" được tạo ra tại "${
                  VedaPageEntityTypeMappingToLabel[finalFormForSavePage.entityType]
                }"`
              : `Page ${page.label} gốc tại "${
                  VedaPageEntityTypeMappingToLabel[finalFormForSavePage.entityType]
                }" sẽ được ghi đè bởi dữ liệu của page này với cái tên "${finalFormForSavePage.label}"`
          }
        >
          <TextInput
            block
            value={finalFormForSavePage.label}
            sizeInput="medium"
            maxLength={Consts.MAX_LENGTH_PAGE}
            onValueChange={value => {
              setFinalFormForSavePage({ ...finalFormForSavePage, label: value });
            }}
          />
        </Field>
      </MyModal>
    );
  };

  const renderFinalFormForSaveTheme = () => {
    if (!finalFormForSaveTheme) {
      return null;
    }
    return (
      <MyModal
        isVisible
        headerText="Bước cuối cùng"
        cancelText="Huỷ bỏ"
        okText={ActionMappingToLabel[finalFormForSaveTheme.type]}
        onCancel={() => {
          if (isSaving) {
            notification.info({
              message: 'Đừng',
              description: 'Chức năng này được làm khá tạm bợ, rất có thể những thứ đáng tiếc sẽ xảy ra. Xin hãy đợi quá trình update kết thúc',
            });
          } else {
            setFinalFormForSaveTheme(null);
          }
        }}
        isLoading={isSaving}
        onOk={() => {
          if (finalFormForSaveTheme.entityType === 'ThemeAtom' || finalFormForSaveTheme.entityType === 'ThemeDraft') {
            handleThemeFinalOk(finalFormForSaveTheme);
          }
        }}
      >
        <Field
          label="Có sync lên shopify hay không?"
          description="Chậm lại và suy nghĩ. Việc sync lên shopify có thể gây ảnh hưởng đến những người khác vì rất có thể bạn đang login vào shop làm demo"
        >
          <Switch
            checked={finalFormForSaveTheme.isSyncToShopify}
            onValueChange={checked => {
              setFinalFormForSaveTheme({ ...finalFormForSaveTheme, isSyncToShopify: checked });
            }}
          />
        </Field>
      </MyModal>
    );
  };

  return (
    <>
      <MyModal
        size="large"
        headerText={`Thao tác với page ${page.label}`}
        isVisible
        onCancel={() => setPage(undefined)}
        isLoading={isSaving}
        cancelText="Đóng"
        okText=""
      >
        <Descriptions colon bordered column={{ xs: 1, lg: 1, md: 1, sm: 1, xl: 1, xxl: 1 }} title="Thông tin page">
          <Descriptions.Item label="Tên page">{page.label}</Descriptions.Item>
          <Descriptions.Item label="Loại page tại shopify">{ShopifyPageTypeMappingToLabel[page.type]}</Descriptions.Item>
          <Descriptions.Item label="Loại page tại Veda">{renderVedaPageEntityTypeWithDescription(page.entityType)}</Descriptions.Item>
          <Descriptions.Item label="Trạng thái">{page.commandId ? 'Đã tồn tại' : 'Tạo mới'}</Descriptions.Item>
        </Descriptions>
        <Space size={16} />
        <Descriptions colon bordered column={{ xs: 1, lg: 1, md: 1, sm: 1, xl: 1, xxl: 1 }} title="Các thao tác có thể thực hiện">
          {renderAction('Atom')}
          {renderAction('Client')}
          {renderAction('Draft')}
          {renderAction('ThemeAtom')}
          {renderAction('ThemeDraft')}
        </Descriptions>
      </MyModal>
      {renderModalFinalAction()}
      {renderFinalFormForSavePage()}
      {renderFinalFormForSaveTheme()}
    </>
  );
};

ModalSavePageAdminRole.open = (page: Page) => modalStaticHandler(page);
