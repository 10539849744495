import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { snippetService } from 'services/SnippetService';
import { loadMoreLiquidSnippets } from 'store/global/globalSnippets/action';
import { getActionType } from 'wiloke-react-core/utils';

function* handleLoadMore({ payload: { page, size, fileNames, keyword } }: ReturnType<typeof loadMoreLiquidSnippets.request>) {
  try {
    const response: SagaReturnType<typeof snippetService.loadMoreSnippets> = yield retry(3, 1000, snippetService.loadMoreSnippets, {
      page,
      size,
      fileNames,
      keyword,
    });
    yield put(loadMoreLiquidSnippets.success({ data: response.info, page, totalPage: response.maxPages }));
  } catch (error) {
    console.log(error);
    yield put(loadMoreLiquidSnippets.failure(undefined));
  }
}

export function* watchLoadMoreLiquidSnippets() {
  yield takeLatest(getActionType(loadMoreLiquidSnippets.request), handleLoadMore);
}
