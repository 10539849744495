import SelectAntd, { Option } from 'components/SelectAntd';
import withDebounce from 'hocs/withDebounce';
import { useSelector } from 'react-redux';
import { useUpdateSettingsValue } from 'store/actions/actionPages';
import { sectionIdActiveSelector } from 'store/selectors';
import { pmParent } from 'utils/functions/postMessage';
import { FieldContainerProps } from '../types';

export interface SelectFieldProps<T> extends FieldContainerProps<T> {
  options: Option[];
  enabledMultiple?: boolean;
}

const SelectDebounce = withDebounce(SelectAntd, 'value', 'onChange', 200);

const SelectField = <T extends string>({
  value,
  settingId,
  childId,
  grandChildId,
  options,
  forceRenderSection,
  enabledMultiple = false,
}: SelectFieldProps<T>) => {
  const updateSettingsValue = useUpdateSettingsValue();
  const sectionIdActive = useSelector(sectionIdActiveSelector);
  const _value = enabledMultiple ? (value ? value.split(', ') : value.split('')) : value;

  const handleChange = (value: any) => {
    const _value = Array.isArray(value) ? value.join(', ') : value;

    updateSettingsValue({
      value: _value,
      settingId,
      childId,
      grandChildId,
    });
    if (forceRenderSection) {
      pmParent.emit('@rerender', { sectionId: sectionIdActive, action: 'SelectField.tsx' });
    }
  };

  return (
    <SelectDebounce
      mode={enabledMultiple ? 'multiple' : undefined}
      value={_value}
      data={options}
      onChange={handleChange}
      forceRenderSection={forceRenderSection}
    />
  );
};

export default SelectField;
