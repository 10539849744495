import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { validateClientThemeRequest } from 'services/ThemeService/Logic/validateThemeClient';
import { getActionType } from 'wiloke-react-core/utils';
import { validateBuiltinTheme } from '../actions';

function* handleAction({ payload: { onFulfill, onReject } }: ReturnType<typeof validateBuiltinTheme.request>) {
  try {
    const response: SagaReturnType<typeof validateClientThemeRequest> = yield retry(3, 1000, validateClientThemeRequest);
    if (response.status === 200) {
      yield put(validateBuiltinTheme.success(undefined));
      onFulfill?.();
    } else {
      yield put(validateBuiltinTheme.failure(undefined));
      onReject?.();
    }
  } catch (error) {
    yield put(validateBuiltinTheme.failure(undefined));
    onReject?.();
  }
}

export function* watchValidateBuiltinTheme() {
  yield takeLatest(getActionType(validateBuiltinTheme.request), handleAction);
}
