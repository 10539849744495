import { PageType } from 'types/Page';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';

interface RequestParams {
  pageType?: PageType;
  label?: string;
  categoryId?: string;
  cursor: string;
  saved?: boolean;
  size: number;
}

export const loadMorePagesPublish = ({ pageType, label, categoryId, cursor, saved, size }: RequestParams) => {
  const { lastCache } = getUserInfo();
  return pageApis.vedaApplication.userApi.page.getProducts({
    type: 'LOADMORE',
    cacheVersion: lastCache,
    categoryId,
    pageType,
    label,
    cursor,
    saved,
    size,
  });
};
