import { AxiosResponse } from 'axios';
import { BE_ThemeClient } from 'services/ThemeService/VedaApplication/types';
import fetchAPI from 'utils/functions/fetchAPI';
import { clientBaseUrl } from '../const';

interface GetThemeActive {
  cacheVersion: number | null;
}

interface ResponseSuccess {
  info: BE_ThemeClient;
  message: string;
}

export const getThemeActive = async ({ cacheVersion }: GetThemeActive) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${clientBaseUrl}/publish`,
    params: {
      cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
    },
  });
  return response.data;
};
