import { AxiosError } from 'axios';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { updateCategoryOfThemeProductRequest } from 'services/ThemeService/Logic/updateCategoryOfProductTheme';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { updateCategoryOfThemeProduct } from '../actions/actionThemesProduct';

function* handleUpdate({ payload: { commandId, description, name } }: ReturnType<typeof updateCategoryOfThemeProduct.request>) {
  try {
    const response: SagaReturnType<typeof updateCategoryOfThemeProductRequest> = yield retry(3, 1000, updateCategoryOfThemeProductRequest, {
      commandId,
      description,
      name,
    });
    notifyAxiosHandler.handleSuccess(response.message);
    yield put(updateCategoryOfThemeProduct.success({ commandId, description, name }));
  } catch (error) {
    notifyAxiosHandler.handleError(error as AxiosError<ErrorData> | Error);
    yield put(updateCategoryOfThemeProduct.failure({ commandId }));
  }
}

export function* watchUpdateCategoryOfThemeProduct() {
  yield takeLatest(getActionType(updateCategoryOfThemeProduct.request), handleUpdate);
}
