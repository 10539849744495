import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import fetchAPI from 'utils/functions/fetchAPI';
import { SectionInfo } from '../types/Record';

const baseUrl = `${configureApp.endpoint.atom}/${configureApp.endpoint.pages}/admin/sections/reverse-query`;

interface GetPageAtomWithSectionAtomCommandId {
  sectionAtomCommandId: string;
  cacheVersion: number | null;
}

interface ResponseSuccess {
  message: string;
  info?: Array<
    Pick<SectionInfo, 'commandId' | 'currentVersion' | 'image' | 'label' | 'type'> & {
      pageInfo?: SectionInfo['pageInfo'];
    }
  >;
}

export const getPageAtomWithSectionAtomCommandId = async ({ sectionAtomCommandId, cacheVersion }: GetPageAtomWithSectionAtomCommandId) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${baseUrl}/${sectionAtomCommandId}`,
    params: {
      cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
    },
  });

  return response.data;
};
