export const schema = {
  fields: 'Champs %% text %%',
  type: 'Type',
  name: 'Nom',
  label: 'Étiquette',
  summary: 'Résumé',
  default: 'Par défaut',
  value: 'Valeur %% text %%',
  conditions: 'Conditions',
  clear_conditions: 'Effacer les conditions',
  force_render_section: 'Forcer le rendu de cette section',
  children: 'Enfants',
  add: 'Ajouter %% text %%',
  default_picker_relate_shopify_non_exist: "%% object_name %% n'existe pas",
  create: 'Créer ici',
  fieldLabel: {
    array: 'Tableau',
    object: 'Objet',
    articlePicker: 'Article',
    blogPicker: 'Blog',
    collectionPicker: 'Collection',
    color: 'Couleur',
    date: 'Date',
    flexOrder: 'Ordre Flex',
    font: 'Police',
    icon: 'Icône',
    image: 'Image',
    linkPicker: 'Lien',
    navigation: 'Navigation',
    productPicker: 'Produit',
    radioGroup: 'Groupe de boutons radio',
    responsive: 'Adaptatif',
    select: 'Sélection',
    slider: 'Curseur',
    space: 'Espace',
    styleBox: 'Style',
    switch: 'Interrupteur',
    text: 'Texte',
    textarea: 'Zone de texte',
    textReadOnly: 'Texte en lecture seule',
    video: 'Vidéo',
    tags: 'Balises',
    richText: 'Texte enrichi',
    hidden: 'Caché',
    divider: 'Diviseur',
    products: 'Produits',
    animate: 'Animer',
    animateInOut: 'Animer Entrée Sortie',
    color_scheme: 'Palette de couleurs',
    upload_csv: 'Upload CSV',
  },
  automated: 'Traduction automatique',
  must_be_object: 'Vous devez supprimer les champs %% field_types %% pour effectuer des modifications',
} as const;
