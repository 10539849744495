import { pasteStyleFlow } from 'containers/BuilderPage/store/copyStyle/actions';
import { clearDraft, setLoadingOnPaste } from 'containers/BuilderPage/store/copyStyle/sliceCopyStyle';
import { deleteCache, readFromCache } from 'packages/ExtractThemeToFileForSync/store/sagas/watchExtractThemeToFileForSync/steps/utils/CacheStorage';
import { SagaReturnType, call, put, select, takeLatest } from 'redux-saga/effects';
import { updateSettingsValue } from 'store/actions/actionPages';
import { dataBindingFieldNamesSelector } from 'store/global/dataBindingFieldNames/slice';
import { sectionIdActiveSelector } from 'store/selectors';
import { CopyResult } from 'utils/functions/copyStyle';
import { CopyStyleStorage } from 'utils/functions/copyStyle/utils/constants';
import { mergeSettingsCopied } from 'utils/functions/copyStyle/utils/mergeSettingsCopied';
import { getActionType } from 'wiloke-react-core/utils';

function* handlePasteStyle(_: ReturnType<typeof pasteStyleFlow>) {
  yield put(setLoadingOnPaste('loading'));
  try {
    const cachedDraftData: CopyResult | undefined = yield call(readFromCache, CopyStyleStorage.draft_data);
    const cachedCopiedData: CopyResult | undefined = yield call(readFromCache, CopyStyleStorage.copied_data);

    const sectionIdActive: SagaReturnType<typeof sectionIdActiveSelector> = yield select(sectionIdActiveSelector);
    const dataBindingFieldNames: SagaReturnType<typeof dataBindingFieldNamesSelector> = yield select(dataBindingFieldNamesSelector);

    if (!cachedDraftData || !cachedCopiedData) {
      return;
    }

    const result = mergeSettingsCopied(cachedCopiedData, cachedDraftData);

    for (const field of result.copiedData) {
      yield put(
        updateSettingsValue({
          settingId: field.settingId,
          value: field.children,
          childId: field.childId,
          grandChildId: field.grandChildId,
          sectionId: sectionIdActive,
          dataBindingFieldNames: dataBindingFieldNames,
        }),
      );
    }
  } catch (error) {
    console.log('watchPasteStyleFlow', error);
  } finally {
    yield call(deleteCache, CopyStyleStorage.draft_data);
    yield put(setLoadingOnPaste('done'));
    yield put(clearDraft({}));
  }
}

export function* watchPasteStyleFlow() {
  yield takeLatest(getActionType(pasteStyleFlow), handlePasteStyle);
}
