import FieldGroup from 'components/FieldGroup';
import { useLinkPicker } from 'components/LinkPicker/store/context/LinkPickerContext';
import { PickerType } from 'components/LinkPicker/types';
import { removeTel } from 'components/LinkPicker/utils/getLinkType';
import Radio from 'components/Radio';
import TextInput from 'components/TextInput';
import { Tooltip } from '@wiloke/ui';
import withDebounce from 'hocs/withDebounce';
import { ReactNode, useEffect } from 'react';
import { i18n } from 'translation';
import { FontAwesome, View } from 'wiloke-react-core';
import { Custom } from './Fields/Custom';
import { Email } from './Fields/Email';
import { PhoneNumber } from './Fields/PhoneNumber';
import { Shopify } from './Fields/Shopify';
import * as styles from './Fields/styles';
import { ScrollToSections } from 'components/LinkPicker/components/Form/Fields/ScrollToSections';
import { BuyNow } from 'components/LinkPicker/components/Form/Fields/BuyNow';
import { useSelector } from 'react-redux';
import { pageDataSelector } from 'store/selectors';

export const DebounceTextInput = withDebounce(TextInput, 'value', 'onValueChange');

export const Form = () => {
  const { type, label, summary, dispatch, value, AfterLabel } = useLinkPicker();
  const { type: pageType } = useSelector(pageDataSelector);

  useEffect(() => {
    if (type === 'phone') {
      if (value.includes('tel:')) {
        const _currentVal = removeTel(value);
        dispatch({
          type: 'setSettings',
          payload: {
            value: `tel:${_currentVal}`,
            type: 'phone',
          },
        });
      } else {
        dispatch({
          type: 'setSettings',
          payload: {
            value: `tel:`,
            type: 'phone',
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const _mappingContent: Record<PickerType, ReactNode> = {
    custom: <Custom />,
    email: <Email />,
    phone: <PhoneNumber />,
    shopify: <Shopify />,
    section: <ScrollToSections />,
    buy_now: <BuyNow />,
  };

  const _handleChangeTab = (val: string) => {
    const _type = val as PickerType;
    const _value = _type === 'buy_now' ? 'veda-link-picker-buy-now' : '';
    dispatch({
      type: 'setSettings',
      payload: {
        type: _type,
        value: _value,
      },
    });
  };

  const renderContent = (
    <View>
      <Radio.Group defaultValue={'settings.type'} value={type} block onChangeValue={_handleChangeTab}>
        <Radio.Button
          value="shopify"
          children={
            <Tooltip css={styles.tooltip} portal text="Shopify">
              <View css={styles.radioButton}>
                <FontAwesome type="fab" name="shopify" />
              </View>
            </Tooltip>
          }
        />
        <Radio.Button
          value="email"
          children={
            <Tooltip css={styles.tooltip} portal text={i18n.t('builderPage.send_email')}>
              <View css={styles.radioButton}>
                <FontAwesome type="far" name="envelope" />
              </View>
            </Tooltip>
          }
        />
        <Radio.Button
          value="phone"
          children={
            <Tooltip css={styles.tooltip} portal text={i18n.t('builderPage.phone_number')}>
              <View css={styles.radioButton}>
                <FontAwesome type="far" name="phone" />
              </View>
            </Tooltip>
          }
        />
        <Radio.Button
          value="custom"
          children={
            <Tooltip css={styles.tooltip} portal text={i18n.t('builderPage.custom_url')}>
              <View css={styles.radioButton}>
                <FontAwesome type="far" name="link" />
              </View>
            </Tooltip>
          }
        />
        <Radio.Button
          value="section"
          children={
            <Tooltip css={styles.tooltip} portal text={i18n.t('builderPage.scroll_to_section')}>
              <View css={styles.radioButton}>
                <FontAwesome type="far" name="arrow-up" />
              </View>
            </Tooltip>
          }
        />
        <Radio.Button
          disabled={pageType !== 'product'}
          value="buy_now"
          children={
            <Tooltip
              css={styles.tooltip}
              portal
              text={pageType !== 'product' ? i18n.t('builderPage.buy_now_condition') : i18n.t('builderPage.buy_now')}
            >
              <View css={styles.radioButton}>
                <FontAwesome type="far" name="cart-plus" />
              </View>
            </Tooltip>
          }
        />
      </Radio.Group>

      {_mappingContent[type]}
    </View>
  );

  if (!label) {
    return renderContent;
  }

  return (
    <FieldGroup label={label} summary={summary} visible AfterLabel={AfterLabel}>
      {renderContent}
    </FieldGroup>
  );
};
