import { Schema, SectionSettings } from 'types/Schema';
import { PageSection } from 'types/Sections';
import { v4 } from 'uuid';
export const ColorSchemeVariableNameInLiquid = 'color_scheme';

export const getDefaultSectionSchema = (): Schema => ({
  blocks: [],
  settings: [
    {
      id: v4(),
      type: 'color_scheme',
      children: 'veda-color-default',
      label: 'Color Scheme',
      name: ColorSchemeVariableNameInLiquid,
    },
  ],
});

export const getDefaultSectionSettings = (): SectionSettings => [
  {
    id: v4(),
    type: 'color_scheme',
    children: 'veda-color-default',
    label: 'Color Scheme',
    name: ColorSchemeVariableNameInLiquid,
  },
];

export const getDefaultSectionData = (): PageSection['data'] => ({
  liquid: '',
  schema: getDefaultSectionSchema(),
  settings: getDefaultSectionSettings(),
  js: '',
  jsHook: '',
  scss: '',
});
