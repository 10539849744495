import { createPageUseCase } from 'containers/Admin/Management/Pages/store/actions/actionPageUseCase';
import { SagaReturnType, put, retry, takeLatest } from 'redux-saga/effects';
import { createUseCaseOfProdPage } from 'services/PageService/Logic/createUseCaseOfProdPage';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';

function* handleCreate({ payload: { description, name, belongsToPageTypes, onFulfill } }: ReturnType<typeof createPageUseCase.request>) {
  try {
    const response: SagaReturnType<typeof createUseCaseOfProdPage> = yield retry(3, 1000, createUseCaseOfProdPage, {
      description,
      name,
      belongsToPageTypes,
    });
    yield put(
      createPageUseCase.success({
        commandId: response.info.commandId,
        count: response.info.count,
        name: response.info.name,
        description: response.info.description,
        belongsToPageTypes: response.info.belongsToPageTypes,
      }),
    );
    notifyAxiosHandler.handleSuccess(response.message);
    onFulfill?.();
  } catch (error) {
    const error_ = error as Error;
    notifyAxiosHandler.handleError(error_);
    yield put(createPageUseCase.failure(undefined));
  }
}

export function* watchCreatePageUseCase() {
  yield takeLatest(getActionType(createPageUseCase.request), handleCreate);
}
