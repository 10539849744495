import { MyModal } from '@wiloke/ui';
import Radio from 'components/Radio';
import { useSectionDeleteFlow } from 'containers/BuilderPage/store/toolbarActions/action';
import { isEmpty } from 'ramda';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useRemoveAddonsInSection } from 'store/actions/actionPages';
import { useSetActiveAddon, useSetVisibleModalInstallAddon, useUpdateThemeAddon } from 'store/global/themeAddons';
import { pageDataSelector, pageSectionsSelector, sectionEdittingIdSelector, themeAddonsSelector } from 'store/selectors';
import { i18n } from 'translation';
import { AddonOfTheme_Atom_N_Client } from 'types/Addons';
import { Space, Text, View } from 'wiloke-react-core';

export const ModalUninstallAddon = () => {
  const { visibleUninstall, activeAddon } = useSelector(themeAddonsSelector);
  const page = useSelector(pageDataSelector);
  const pageSections = useSelector(pageSectionsSelector);
  const sectionEditing = useSelector(sectionEdittingIdSelector);

  const setVisibleModalInstallAddon = useSetVisibleModalInstallAddon();
  const setActiveAddon = useSetActiveAddon();
  const sectionDeleteFlow = useSectionDeleteFlow();
  const updateThemeAddon = useUpdateThemeAddon();
  const removeAddonsInSection = useRemoveAddonsInSection();

  const [selected, setSelected] = useState<string>('app');

  if (!activeAddon) {
    return null;
  }

  const belongsToPage = (activeAddon as AddonOfTheme_Atom_N_Client).belongsToPage;

  const options = [
    {
      label: 'This page only',
      value: 'page',
      disable: Array.isArray(belongsToPage) && !isEmpty(belongsToPage) ? !belongsToPage.some(item => item.commandId === page.commandId) : false,
    },
    {
      label: 'All pages',
      value: 'app',
      disable: false,
    },
  ];

  const handleCancel = () => {
    setSelected('app');
    setActiveAddon({ addon: undefined });
    setVisibleModalInstallAddon(false);
  };

  const handleConfirm = () => {
    if (selected === 'page') {
      const sectionsIncludeThisAddon = pageSections.filter(section => section.addonIds?.includes(sectionEditing)).filter(Boolean);
      const sectionIds = sectionsIncludeThisAddon.map(item => item.id);
      updateThemeAddon({
        addons: {
          ...activeAddon,
          belongsToPage: (activeAddon as AddonOfTheme_Atom_N_Client).belongsToPage?.filter(item => item.commandId !== page.commandId),
        },
      });
      if (sectionIds.length > 0) {
        removeAddonsInSection(sectionIds, sectionEditing);
      }
      handleCancel();
    } else {
      sectionDeleteFlow({ sectionIdActive: sectionEditing, goBack() {} });
      handleCancel();
    }
  };

  return (
    <MyModal
      headerText="Uninstall Addon"
      isVisible={visibleUninstall}
      onCancel={handleCancel}
      okText={i18n.t('general.confirm')}
      onOk={handleConfirm}
      okBackgroundColor="danger"
    >
      <Text size={16} fontFamily="secondary">
        Do you want to remove addon from this page only or all pages?
      </Text>
      <Space size={10} />

      {options.map(item => (
        <View key={item.value}>
          <Radio
            size="small"
            value={item.value}
            checked={selected === item.value}
            onChangeValue={val => {
              setSelected(val);
            }}
            disabled={item.disable}
            disabledText={null}
          >
            <Text fontFamily="secondary">{item.label}</Text>

            {item.disable && (
              <>
                <Text size={13} color="warning" css={{ fontStyle: 'italic' }}>
                  This addon has not been added to the current page
                </Text>
                <Text size={13} color="warning" css={{ fontStyle: 'italic' }}>
                  This option will be enabled once you have added the addon to the section and saved it to the current page
                </Text>
              </>
            )}
          </Radio>
          <Space size={5} />
        </View>
      ))}
    </MyModal>
  );
};
