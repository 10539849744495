import { addonApiController } from 'services/AddonService';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const getAtoms = async ({ categoryId, label }: { categoryId?: string; label?: string }) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const atomResponse = await addonApiController.atom.adminApi.addons.getAtoms({
      type: 'GET FIRST PAGE',
      categoryId,
      cacheVersion: lastCache,
      label,
    });
    const productResponse = await addonApiController.product.adminApi.addons.getProductsWithParentCommandIds({
      parentCommandIds: atomResponse.info.map(item => item.commandId).join(','),
      cacheVersion: lastCache,
    });
    return atomResponse.info.map(atom => ({
      ...atom,
      vedaProductRepresent: productResponse.info.find(product => product.parentCommandId === atom.commandId),
    }));
  }
  const response = await addonApiController.atom.publishApi.addons.getAtoms({ type: 'GET FIRST PAGE', categoryId, cacheVersion: lastCache, label });
  return response.info;
};

export const loadMoreAtoms = async ({ cursor, categoryId, label }: { cursor: string; categoryId?: string; label?: string }) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const atomResponse = await addonApiController.atom.adminApi.addons.getAtoms({
      type: 'LOADMORE',
      categoryId,
      lastCursor: cursor,
      cacheVersion: lastCache,
      label,
    });
    const productResponse = await addonApiController.product.adminApi.addons.getProductsWithParentCommandIds({
      parentCommandIds: atomResponse.info.map(item => item.commandId).join(','),
      cacheVersion: lastCache,
    });
    return atomResponse.info.map(atom => ({
      ...atom,
      vedaProductRepresent: productResponse.info.find(product => product.parentCommandId === atom.commandId),
    }));
  }
  const response = await addonApiController.atom.publishApi.addons.getAtoms({
    type: 'LOADMORE',
    categoryId,
    lastCursor: cursor,
    cacheVersion: lastCache,
    label,
  });
  return response.info;
};
