import { AxiosResponse } from 'axios';
import fetchAPI from 'utils/functions/fetchAPI';
import { getApiTracking } from 'utils/functions/getApiTracking';
import { baseUrl } from './const';

interface Params {
  envatoThemeId: string;
}

interface ResponseSuccess {
  message: string;
}

export const checkPurchaseCodeIsExist = async ({ envatoThemeId }: Params) => {
  const url = getApiTracking({
    defaultBaseUrl: `${baseUrl}/envato-items/${envatoThemeId}/purchase-code`,
    trackingEndpoint: `envato-purchase-codes/envato-items/${envatoThemeId}/purchase-code`,
  });
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url,
  });
  return response.data;
};
