import { Author } from 'types/Author';
import { createAsyncAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';

export const getAuthors = createAsyncAction(['@Authors/getAuthorsRequest', '@Authors/getAuthorsSuccess', '@Authors/getAuthorsFailure'])<
  { search?: string; role?: string },
  { authors: Author[]; page: number; totalPages: number },
  undefined
>();

export const loadmoreAuthors = createAsyncAction([
  '@Authors/loadmoreAuthorsRequest',
  '@Authors/loadmoreAuthorsSuccess',
  '@Authors/loadmoreAuthorsFailure',
])<{ page: number; search?: string; role?: string }, { authors: Author[]; page: number; totalPages: number }, undefined>();

export const updateAuthorRole = createAsyncAction([
  '@Authors/updateAuthorRole/request',
  '@Authors/updateAuthorRole/success',
  '@Authors/updateAuthorRole/failure',
])<{ userId: number; role: string; onFulfill?: () => void }, { userId: number; role: string }, { userId: number }>();

export const updateAuthorPlan = createAsyncAction([
  '@Authors/updateAuthorPlan/request',
  '@Authors/updateAuthorPlan/success',
  '@Authors/updateAuthorPlan/failure',
])<
  {
    userId: number;
    planHandle?: string;
    shopifyPlanName?: string;
    chargeId: number | null;
    price: string | null;
    status: string | null;
    trialDays: number | null;
    onFulfill?: () => void;
  },
  {
    id: number;
    planHandle: string;
    shopifyPlanName: string;
    chargeId: number | null;
    price: string | null;
    status: string | null;
    trialDays: number | null;
  },
  undefined
>();

export const banOrUnBanAuthor = createAsyncAction([
  '@Authors/banOrUnBanAuthor/request',
  '@Authors/banOrUnBanAuthor/success',
  '@Authors/banOrUnBanAuthor/failure',
])<{ userId: number; verifyStatus: 1 | 2 }, { userId: number; verifyStatus: 1 | 2 }, { userId: number }>();

export const useGetAuthors = createDispatchAsyncAction(getAuthors);
export const useLoadmoreAuthors = createDispatchAsyncAction(loadmoreAuthors);
export const useUpdateAuthorRole = createDispatchAsyncAction(updateAuthorRole);
export const useUpdateAuthorPlan = createDispatchAsyncAction(updateAuthorPlan);
export const useBanOrUnBanAuthor = createDispatchAsyncAction(banOrUnBanAuthor);
