import { hexToRgb } from '@wiloke/functions';
import { ISCHEMA_SettingColor } from '../@types/ISCHEMA_SettingColor';
import { replaceComplexVarToSimpleVar } from './replaceComplexVarToSimpleVar';

const getColorName = (str: string) => {
  return str
    .replace(/var\(|\)/g, '')
    .replace(/-freeze/g, '')
    .replace(/rgba\(|,.*$/g, '')
    .replace(/--rgb-/g, '--');
};

export const getFinalColor = ({ field, themeSettings }: Pick<ISCHEMA_SettingColor, 'field' | 'themeSettings'>) => {
  const valueOfField = typeof field.children === 'string' && !!field.children ? field.children : undefined;
  const colorNameInThemeSettings = getColorName(field.children ?? '');
  const colorValueInThemeSettings = themeSettings.cssVariables.colors.find(color => color.name === colorNameInThemeSettings);
  const value =
    colorValueInThemeSettings && valueOfField
      ? replaceComplexVarToSimpleVar(valueOfField).replace(colorNameInThemeSettings, () => {
          if (valueOfField.startsWith('rgba')) {
            const { r, g, b } = hexToRgb(colorValueInThemeSettings.light);
            return `${r}, ${g}, ${b}`;
          }
          return colorValueInThemeSettings.light;
        })
      : valueOfField;

  return value;
};
