import { renewVersion } from 'utils/CacheControl/VedaClientCacheControl';
import { pageApis } from '../apis';

interface Params {
  commandId: string;
  disableOthers: boolean;
}

export const enableProductPageService = async ({ commandId, disableOthers }: Params) => {
  const response = await pageApis.vedaApplication.userApi.page.enableProduct({ commandId, disableOthers });
  renewVersion();
  return response;
};
