import { Button, Title } from '@wiloke/ui';
import parse from 'html-react-parser';
import { FC } from 'react';
import { VedaInstruction } from 'types/Instruction';
import { Image, Space, View } from 'wiloke-react-core';

interface VedaInstructionProps {
  instruction: VedaInstruction;
}

export const VedaInstructionBar: FC<VedaInstructionProps> = ({ instruction }) => {
  return (
    <View
      backgroundColor="light"
      css={{
        overflow: 'hidden',
        display: 'flex',
      }}
      radius={16}
      key={instruction.id}
    >
      <Image
        containerCss={{
          cursor: 'pointer',
        }}
        src={instruction.thumbnailUrl}
        height={300}
        width={600}
        onClick={() => {
          window.open(instruction.youtubeUrl, '_blank');
        }}
      />

      <View css={{ flex: '1', padding: '20px' }}>
        <Title title={instruction.title} text={parse(instruction.description)} titleCss={{ marginBottom: '10px' }} />

        {instruction.buttonName && (
          <>
            <Space size={20} />

            <Button
              radius={8}
              size="extra-small"
              borderColor="gray6"
              borderStyle="solid"
              borderWidth={1}
              backgroundColor="light"
              color="gray9"
              onClick={() => {
                window.open(instruction.buttonUrl, '_blank');
              }}
            >
              {instruction.buttonName}
            </Button>
          </>
        )}
      </View>
    </View>
  );
};
