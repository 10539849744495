export const schema = {
  fields: '%% text %% Fields',
  type: 'Type',
  name: 'Name',
  label: 'Label',
  summary: 'Summary',
  default: 'Default',
  value: '%% text %% Value',
  conditions: 'Conditions',
  clear_conditions: 'Clear Conditions',
  force_render_section: 'Force Render Section',
  children: 'Children',
  add: 'Add %% text %%',
  default_picker_relate_shopify_non_exist: '%% object_name %% does not exist',
  create: 'Create here',
  fieldLabel: {
    array: 'Array',
    object: 'Object',
    articlePicker: 'Article',
    blogPicker: 'Blog',
    collectionPicker: 'Collection',
    color: 'Color',
    date: 'Date',
    flexOrder: 'Flex Order',
    font: 'Font',
    icon: 'Icon',
    image: 'Image',
    linkPicker: 'Link',
    navigation: 'Navigation',
    productPicker: 'Product',
    radioGroup: 'Radio Group',
    responsive: 'Responsive',
    select: 'Select',
    slider: 'Slider',
    space: 'Space',
    styleBox: 'Style',
    switch: 'Switch',
    text: 'Text',
    textarea: 'Textarea',
    textReadOnly: 'Text Read Only',
    video: 'Video',
    tags: 'Tags',
    richText: 'Rich Text',
    hidden: 'Hidden',
    divider: 'Divider',
    products: 'Products',
    animate: 'Animate',
    animateInOut: 'Animate In Out',
    color_scheme: 'Color scheme',
    upload_csv: 'Upload CSV',
  },
  automated: 'Automatically translated',
  must_be_object: 'You need to delete the fields %% field_types %% to be able to change',
} as const;
