import { css, Theme } from 'wiloke-react-core';

export const container = ({ colors }: Theme) => css`
  text-align: left;

  :global {
    .__floater.__floater__open {
      filter: drop-shadow(rgba(${colors.rgbDark}, 0.3) 0px 0px 1px) !important;
      padding: 8px !important;
    }
  }
`;
