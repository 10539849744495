import { Empty } from '@wiloke/ui';
import { Dropdown } from 'antd';
import VirtualList from 'components/VirtualList/VirtualList';
import { CSSPropKey } from 'containers/BuilderPage/components/StyleBox/types';
import { equals, isEmpty } from 'ramda';
import { FC, useEffect, useRef, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { FontAwesome, OuterTrigger, Text, useStyleSheet, View } from 'wiloke-react-core';
import { classNames } from 'wiloke-react-core/utils';
import { cssPropKeys as data } from './data';
import * as styles from './styles';

export interface CssPropertiesFieldProps {
  value?: CSSPropKey[];
  onChange?: (value: CSSPropKey[]) => void;
}
export const CssPropertiesField: FC<CssPropertiesFieldProps> = ({ value = [], onChange }) => {
  const [visible, setVisible] = useState(false);
  const [state, setState] = useState<CSSPropKey[]>(value);
  const [search, setSearch] = useState('');
  const containerRef = useRef<HTMLElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { styles: styleSheet } = useStyleSheet();

  useDeepCompareEffect(() => {
    if (value !== undefined) {
      setState(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [[value]]);

  useEffect(() => {
    if (!equals(state, value)) {
      onChange?.(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleSelect = ({ isActive, val }: { isActive: boolean; val: CSSPropKey }) => () => {
    if (isActive) {
      setState(state => state.filter(item => item !== val));
    } else {
      setState(state => state.concat(val));
    }
  };

  const handleRemove = (val: CSSPropKey) => () => {
    setState(state => state.filter(item => item !== val));
  };

  const renderList = () => {
    const items = search
      ? data.filter(item => {
          return item.toLowerCase().includes(search.toLowerCase()) || item.toLowerCase().includes(search.toLowerCase());
        })
      : data;
    return (
      <OuterTrigger
        onClick={({ target }) => {
          const _target = target as HTMLElement;

          if (_target.getAttribute('class')?.includes('CSS_KEY_BADGE')) {
            return;
          } else if (!_target.getAttribute('class')?.includes('DROPDOWN_OUTER_TRIGGER')) {
            setVisible(false);
          }
        }}
      >
        <View css={styles.listContainer}>
          <View css={styles.listBody}>
            {isEmpty(items) ? (
              <Empty />
            ) : (
              <VirtualList
                containerCss={{ flex: '1', height: '100%' }}
                rowCount={items.length}
                rowHeight={40}
                rowRender={index => {
                  const val = items[index];
                  const isActive = state.findIndex(item => item === val) !== -1;
                  return (
                    <View key={index} css={styles.listItem(false)} onClick={handleSelect({ isActive, val })}>
                      <View>
                        <Text css={styles.listItemLabel}>{val}</Text>
                      </View>
                      {isActive && <FontAwesome type="far" name="check" color="primary" size={16} css={styles.listItemActiveIcon} />}
                    </View>
                  );
                }}
              />
            )}
          </View>
        </View>
      </OuterTrigger>
    );
  };

  return (
    <Dropdown getPopupContainer={() => containerRef.current as any} overlay={renderList()} visible={visible} trigger={['click']}>
      <View
        ref={containerRef}
        css={styles.tagInner}
        onClick={() => {
          inputRef.current?.focus();
          setVisible(prev => !prev);
        }}
        className="DROPDOWN_OUTER_TRIGGER"
      >
        {state.length > 0 &&
          state.map(item => (
            <View
              className="CSS_KEY_BADGE"
              borderColor="gray3"
              borderStyle="solid"
              borderWidth={1}
              radius={4}
              css={styles.badge}
              key={item}
              backgroundColor="gray2"
            >
              <View className="CSS_KEY_BADGE" css={styles.badgeTitle}>
                {item}
              </View>
              <FontAwesome className="CSS_KEY_BADGE" css={{ cursor: 'pointer' }} type="far" name="times" onClick={handleRemove(item)} />
            </View>
          ))}

        <View css={styles.inputContainer}>
          <input
            ref={inputRef}
            className={classNames('DROPDOWN_OUTER_TRIGGER', styleSheet(styles.input))}
            value={search}
            onChange={e => {
              setSearch(e.target.value);
            }}
          />
        </View>
      </View>
    </Dropdown>
  );
};
