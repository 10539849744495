export type OwnerType = 'PAGE' | 'ARTICLE' | 'BLOG' | 'COLLECTION' | 'PRODUCT' | 'SHOP' | 'PRODUCTVARIANT';

export interface Metafield {
  //
  ownerType: OwnerType;
  //
  id: string;
  // The type of data that the metafield will store
  type: {
    category: string;
    name: string;
  };
  // The namespace of the metafield definition
  namespace: string;
  // The key name used to identify a metafield definition within a namespace
  key: string;
  // The human-readable name for the metafield definition.
  name: string;
  //
  description: string;
  //
  cursor: string;
}

/** Những metafields types mà không được dùng trong liquid */
export const Unusable_Metafields = {
  integer: 'Integer',
  json_string: 'Json string',
  string: 'String',
  'list.color': 'Color',
};

/**
 * Possible values
 * link: https://shopify.dev/docs/api/liquid/objects/metafield
 * */
export const Usable_Metafields = {
  single_line_text_field: 'single_line_text_field',
  multi_line_text_field: 'multi_line_text_field',
  product_reference: 'product_reference',
  collection_reference: 'collection_reference',
  variant_reference: 'variant_reference',
  page_reference: 'page_reference',
  file_reference: 'file_reference',
  number_integer: 'number_integer',
  number_decimal: 'number_decimal',
  date: 'date',
  date_time: 'date_time',
  url_reference: 'url_reference',
  json: 'json',
  boolean: 'boolean',
  color: 'color',
  weight: 'weight',
  volume: 'volume',
  dimension: 'dimension',
  rating: 'rating',
  list: 'list',
  money: 'money',
  rich_text_field: 'rich_text_field',
  metaobject_reference: 'metaobject_reference',
  mixed_reference: 'mixed_reference',
  'list.collection_reference': 'list.collection_reference',
  'list.color': 'list.color',
  'list.date': 'list.date',
  'list.date_time': 'list.date_time',
  'list.dimension': 'list.dimension',
  'list.file_reference': 'list.file_reference',
  'list.metaobject_reference': 'list.metaobject_reference',
  'list.mixed_reference': 'list.mixed_reference',
  'list.number_integer': 'list.number_integer',
  'list.number_decimal': 'list.number_decimal',
  'list.page_reference': 'list.page_reference',
  'list.product_reference': 'list.product_reference',
  'list.rating': 'list.rating',
  'list.single_line_text_field': 'list.single_line_text_field',
  'list.url': 'list.url',
  'list.variant_reference': 'list.variant_reference',
  'list.volume': 'list.volume',
  'list.weight': 'list.weight',
};
