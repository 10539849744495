export function checkDesignFieldName({ componentName, fieldDesignName }: { fieldDesignName: string; componentName: string }) {
  const validDesignFieldNames: string[] = [
    `${componentName}_design`,
    `${componentName}_style`,
    `${componentName}_border_radius`,
    `${componentName}_aspect_ratio`,
    `${componentName}_width`,
    `${componentName}_height`,
    `border_radius`,
    `aspect_ratio`,
    `image_aspect_ratio`,
    `image_border_radius`,
    `design`,
  ];
  return validDesignFieldNames.some(option => option === fieldDesignName);
}

// Tìm field design của item trong array
export function checkDesignFieldContainerItemName({ componentName }: { componentName: string }) {
  const validDesignFieldNames: string[] = [`item_design`, `content_design`, `container_design`];
  return validDesignFieldNames.some(option => option === componentName);
}

export const relatedFieldNames: string[] = [
  `border_radius`,
  `aspect_ratio`,
  `image_aspect_ratio`,
  `image_border_radius`,
  `design`,
  `image_height`,
  `image_width`,
  `height`,
  `width`,
];
