import { PageSection } from 'types/Sections';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

interface GetSectionsOfPageInThemeDraft {
  sectionCommandIds: string[];
}

export const getSectionsOfPageInThemeDraft = async ({ sectionCommandIds }: GetSectionsOfPageInThemeDraft): Promise<PageSection[]> => {
  if (!sectionCommandIds.length) {
    return [];
  }
  const { role } = getUserInfo();
  if (role === 'admin') {
    const responses = await themeApis.atom.adminApi.section.getSectionsOfDraft({ commandIds: sectionCommandIds });
    return responses.info;
  }
  if (role === 'dev' || role === 'support') {
    const responses = await themeApis.atom.devApi.section.getSectionsOfDraft({ commandIds: sectionCommandIds });
    return responses.info;
  }
  throw new RoleException();
};
