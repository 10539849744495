import { css, Styles, Theme } from 'wiloke-react-core';

export const container: Styles = {
  height: '100%',
};

export const collapse = (active: boolean) => ({ colors }: Theme): Styles => ({
  className: 'Fields_collapse',
  backgroundColor: colors.light,
  border: `1px solid ${colors.gray3}`,
  borderRadius: '6px',
  boxShadow: active ? `0 2px 10px rgba(${colors.rgbGray9}, 0.15)` : 'none',
  '> div:first-child': {
    borderRadius: active ? '6px 6px 0 0' : '6px',
    overflow: 'hidden',
  },
  '.react-datepicker-popper': {
    marginLeft: '-10px',
  },
});

export const allowReordering = (allowReorderingFields: boolean, even: boolean) => {
  if (!allowReorderingFields) {
    return css``;
  }
  return css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation-duration: 300ms;
    animation-delay: ${even ? 100 : 0}ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    animation-name: {
      0%,
      100% {
        transform: rotate(-0.3deg);
      }
      50% {
        transform: rotate(0.3deg);
      }
    }
  `;
};

export const addonStatic = {
  container: css`
    debug: AddonStaticActions_container;
    display: flex;
    padding-bottom: 12px;
  `,
  btnSmall: css`
    debug: AddonStaticActions_btnSmall;
    width: 44px;
    height: 44px;
    padding: 0;
  `,
};

export const modalBody = css`
  max-width: 1400px;
  width: calc(100% - 60px);
  height: calc(100% - 200px);
  margin: 30px 50px;
  min-height: 620px;
`;
export const extraNode = ({ colors }: Theme) => css`
  width: 760px;
  max-width: 760px;
  min-width: 760px;
  height: 100%;
  background-color: ${colors.gray2};
  padding: 20px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  border-radius: 6px;
`;

export const buildFaster = ({ colors }: Theme) => css`
  font-size: 10px;
  position: absolute;
  top: 0px;
  right: 5px;
  padding: 2px 4px;
  white-space: nowrap;
  font-weight: 600;

  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  color: ${colors.light};

  animation-name: {
    0% {
      background-color: ${colors.danger};
    }
    50% {
      background-color: ${colors.primary};
    }
    100% {
      background-color: ${colors.danger};
    }
  }
`;
