import { handleExtractResultForForceSync } from 'services/ShopifyConnection';
import { clearCache, pushToCache, readFromCache } from './utils/CacheStorage';
import { PageData, Result } from 'types/Result';
import fileDownload from 'utils/functions/fileDownload';
import { extractResultOfAddons } from './extractResultOfAddons';
import { extractResultOfPage } from './extractResultOfPage';
import { extractResultOfTheme } from './extractResultOfTheme';
import { getExtractResultReadyForSyncCacheKey } from './utils/CacheKeyControl';
import { SectionInfomation } from '../../../../hooks/useGetSections';
import { getFileName } from 'packages/ExtractThemeToFileForSync/utils/getFileName';
import { ProductTheme } from 'types/Theme';

interface ExtractResultReadyForSync {
  cacheKeysOfPages: Array<Awaited<ReturnType<typeof extractResultOfPage>>>;
  cacheKeyOfExtractResultOfAddons: Awaited<ReturnType<typeof extractResultOfAddons>>;
  cacheKeyOfExtractResultOfTheme: Awaited<ReturnType<typeof extractResultOfTheme>>;
  isExportForEnvato: boolean;
  isNeedReduceTotalLinesOfCode: boolean;
  sectionsWithLinkListSetting: SectionInfomation[];
  entityVariant: EntityType;
  themeProduct: ProductTheme | undefined;
}

export const extractResultReadyForSync = async ({
  cacheKeysOfPages,
  cacheKeyOfExtractResultOfAddons,
  cacheKeyOfExtractResultOfTheme,
  isExportForEnvato,
  isNeedReduceTotalLinesOfCode,
  sectionsWithLinkListSetting,
  entityVariant,
  themeProduct,
}: ExtractResultReadyForSync) => {
  const pages_ = await Promise.all(
    cacheKeysOfPages.map(cacheKeysOfPage => {
      return readFromCache<PageData>(cacheKeysOfPage.cacheKey);
    }),
  );
  const addons_ = await readFromCache<Result['filesOfAddons']>(cacheKeyOfExtractResultOfAddons);
  const theme_ = await readFromCache<Result['theme']>(cacheKeyOfExtractResultOfTheme);

  const pages = pages_ as PageData[];
  const addons = addons_ as Result['filesOfAddons'];
  const theme = theme_ as Result['theme'];

  const finalResult = JSON.stringify(
    handleExtractResultForForceSync({
      data: {
        builderType: 'theme',
        filesOfAddons: addons,
        theme,
        pages: pages.reduce<Record<string, PageData>>((result, page) => {
          return {
            ...result,
            [page.data.page.commandId]: page,
          };
        }, {}),
      },
      isOverrideIndividualPages: false,
      themeName: theme.themeName,
      isExportForEnvato,
      isNeedReduceTotalLinesOfCode,
      sectionsWithLinkListSetting,
      entityVariant,
      themeProduct,
    }),
  );

  fileDownload({
    data: finalResult,
    name: getFileName({ isExportForEnvato, themeName: theme.themeName }),
    type: 'application/json',
  });
  const [cacheKey] = await Promise.all([pushToCache(finalResult, getExtractResultReadyForSyncCacheKey()), clearCache()]);
  return cacheKey;
};
