import { useSelector } from 'react-redux';
import { AsyncComponent, Button, MyModal } from '@wiloke/ui';
import { selectListingSectionsUpdatable } from '../redux/selectors';
import { useGetMetaDataOfRecord, useSetRecordInteracting, useUpgradeSection } from '../redux/actions';
import { useEffect } from 'react';
import { Descriptions, Table, notification } from 'antd';
import { View } from 'wiloke-react-core';
import { RecordTypeMappingToLabel } from '../constants/RecordTypeMapping';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const ModalUpgrade = () => {
  const { recordInteracting, statusGetMetaDataOfRecord, queueUpgrading, queueUpgradeFailed } = useSelector(selectListingSectionsUpdatable);

  const setRecordInteracting = useSetRecordInteracting();
  const getMetaDataOfRecord = useGetMetaDataOfRecord();
  const upgradeSection = useUpgradeSection();

  useEffect(() => {
    if (recordInteracting && !recordInteracting?.metaData) {
      getMetaDataOfRecord.request({
        sectionAtomCommandId: recordInteracting?.generalInfomation.firebaseTrackingData.atomCommandId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordInteracting]);

  if (!recordInteracting) {
    return null;
  }

  const recordType = recordInteracting.generalInfomation.firebaseTrackingData.type;
  const renderContent = () => {
    if (!recordInteracting) {
      return null;
    }
    return (
      <View>
        <Descriptions
          bordered
          title={`Thông tin ${RecordTypeMappingToLabel[recordType]} trong kho`}
          column={{ lg: 1, md: 1, sm: 1, xl: 1, xs: 1, xxl: 1 }}
        >
          <Descriptions.Item label="Ảnh">
            <img
              width={200}
              src={
                recordInteracting.generalInfomation.sectionAtom.image?.src ??
                'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg'
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="Loại">{RecordTypeMappingToLabel[recordType]}</Descriptions.Item>
          <Descriptions.Item label="Tên">{recordInteracting.generalInfomation.sectionAtom.label}</Descriptions.Item>
          <Descriptions.Item label="Version mới nhất">{recordInteracting.generalInfomation.sectionAtom.currentVersion}</Descriptions.Item>
        </Descriptions>
        <Table
          dataSource={recordInteracting.metaData?.sectionsInfo ?? []}
          rowKey={record => record.commandId}
          columns={[
            {
              title: 'Thông tin section',
              render(_, record) {
                return (
                  <View css={{ display: 'flex', alignItems: 'center', gap: '8px ' }}>
                    <img width={80} src={record.image?.src ?? 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg'} />
                    <View>
                      <View color="gray9">{record.label}</View>
                      <View css={{ fontSize: '12px' }} color="gray6">
                        Version: {record.currentVersion}
                      </View>
                    </View>
                  </View>
                );
              },
            },
            {
              title: 'Tên page',
              render(_, record) {
                return record.pageInfo?.label;
              },
            },
            {
              title: 'Tên theme',
              render(_, record) {
                return record.themeInfo?.label ?? 'Có lẽ đây là page atom hoặc một page dư thừa của một theme nào đó';
              },
            },
            {
              title: 'Các thao tác',
              render(_, record) {
                const isUpdatable = record.currentVersion !== recordInteracting.generalInfomation.sectionAtom.currentVersion;
                return (
                  <View css={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Button
                      size="extra-small"
                      radius={6}
                      onClick={() => {
                        const { shopName } = getUserInfo();
                        if (record.themeInfo) {
                          window.open(
                            `/builder?shop=${shopName}&id=${record.pageInfo.commandId}&themeId=${record.themeInfo.commandId}&entityVariant=Atom`,
                            '_blank',
                          );
                        } else {
                          window.open(
                            `${window.location.origin}/builder?shop=${shopName}&id=${record.pageInfo.commandId}&entityVariant=Atom`,
                            '_blank',
                          );
                        }
                      }}
                    >
                      View
                    </Button>
                    {isUpdatable && (
                      <Button
                        size="extra-small"
                        backgroundColor="github"
                        radius={6}
                        loading={queueUpgrading.includes(record.commandId)}
                        onClick={() => {
                          upgradeSection.request({
                            sectionInfo: record,
                            sectionAtomCommandId: recordInteracting.generalInfomation.firebaseTrackingData.atomCommandId,
                          });
                        }}
                      >
                        {!queueUpgradeFailed.includes(record.commandId) ? 'Upgrade version' : 'Thử lại'}
                      </Button>
                    )}
                  </View>
                );
              },
            },
          ]}
        />
      </View>
    );
  };

  const headerText =
    statusGetMetaDataOfRecord === 'loading'
      ? 'Đang lấy dữ liệu'
      : recordInteracting.metaData?.sectionsInfo.length
      ? `${RecordTypeMappingToLabel[recordType]}: ${recordInteracting.generalInfomation.sectionAtom.label}`
      : 'Không có dữ liệu';
  return (
    <MyModal
      isVisible
      size="large"
      okText="Upgrade tất cả"
      cancelText="Đóng"
      headerText={headerText}
      onCancel={() => {
        if (statusGetMetaDataOfRecord === 'loading') {
          notification.info({
            message: 'Đang lấy dữ liệu',
            description: 'Đang lấy dữ liệu. Chờ chút đi',
          });
        } else {
          setRecordInteracting(null);
        }
      }}
      onOk={() => {
        recordInteracting.metaData?.sectionsInfo.forEach(sectionInfo => {
          const isUpdatable = sectionInfo.currentVersion !== recordInteracting.generalInfomation.sectionAtom.currentVersion;
          if (isUpdatable) {
            upgradeSection.request({
              sectionInfo,
              sectionAtomCommandId: recordInteracting.generalInfomation.firebaseTrackingData.atomCommandId,
            });
          }
        });
      }}
      FooterLeft={null}
    >
      <AsyncComponent
        Success={renderContent()}
        status={statusGetMetaDataOfRecord}
        Failure={
          <Button
            onClick={() => {
              getMetaDataOfRecord.request({
                sectionAtomCommandId: recordInteracting?.generalInfomation.firebaseTrackingData.atomCommandId,
              });
            }}
          >
            Retry
          </Button>
        }
      />
    </MyModal>
  );
};
