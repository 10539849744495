import { AsyncComponent, MyModal } from '@wiloke/ui';
import { FC, useRef } from 'react';
import { useSelector } from 'react-redux';
import { appSettingsSelector, useChangeAppSettings, useSetAppSettingsVisible, useUpdateAppSettings } from 'store/global/appSettings/slice';
import { i18n } from 'translation';
import { View } from 'wiloke-react-core';
import { EditCode } from './components/EditCode';
import { Language } from './components/Language';
import { Rtl } from './components/Rtl';

const ModalAppSettings: FC = () => {
  const { visible, status, data, updateStatus } = useSelector(appSettingsSelector);
  const changeAppSettings = useChangeAppSettings();
  const prevDataRef = useRef(data);

  const setAppSettingsVisible = useSetAppSettingsVisible();
  const updateAppSettings = useUpdateAppSettings();

  const handleOk = () => {
    updateAppSettings.request({
      data,
    });
  };

  return (
    <MyModal
      depsHeightRecalculation={status}
      headerText={i18n.t('general.app_settings')}
      size="small"
      isVisible={visible}
      bodyCss={{ width: '600px' }}
      onCancel={() => {
        changeAppSettings(prevDataRef.current);
        setAppSettingsVisible(false);
      }}
      onOk={handleOk}
      okText={i18n.t('general.confirm')}
      isLoading={updateStatus === 'loading'}
    >
      <AsyncComponent
        status={status}
        Success={
          <View>
            <EditCode />
            <Language />
            <Rtl />
          </View>
        }
      />
    </MyModal>
  );
};

export default ModalAppSettings;
