import { FC } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { pageSectionsSelector } from 'store/selectors';
import { Emitter, getSettingsValueByPath, Observers } from '@wiloke/functions';
import { pmChildren } from 'utils/functions/postMessage';
import { useIframeSelector } from '../hooks/useIframeSelector';
import _ from 'lodash';

export interface SettingsHooksData {
  sectionId: string;
  name: string;
  value: any;
  forceRender?: boolean;
}

export interface Events {
  settingsHooks: (data: SettingsHooksData) => void;
}

export type EventsObservers = { [key in keyof Events]: Observers<Events[key]> };

const eventEmitter = new Emitter<EventsObservers>();

window.hiddenFieldAction = eventEmitter.emit.bind(eventEmitter, 'settingsHooks');

export const SettingsHooks: FC = () => {
  const sections = useIframeSelector(pageSectionsSelector);

  useDeepCompareEffect(() => {
    const id = eventEmitter.on('settingsHooks', data => {
      const section = sections.find(section => section.id === data.sectionId);
      const settings = section?.data?.settings;
      const children = getSettingsValueByPath(settings, data.name);
      if (!!settings) {
        if (typeof data.value === 'function') {
          const value = data.value(children);
          if (!_.isEqual(children, value)) {
            pmChildren.emit('@settingsHooks', {
              ...data,
              value,
            });
          }
        } else {
          if (!_.isEqual(children, data.value)) {
            pmChildren.emit('@settingsHooks', data);
          }
        }
      }
    });
    return () => {
      eventEmitter.off(id);
    };
  }, [sections]);

  return null;
};
