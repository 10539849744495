import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';

export const getPagesDraft = () => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    return pageApis.atom.adminApi.page.getDraftsOfDev({
      type: 'GET FIRST PAGE',
    });
  }
  if (role === 'dev' || role === 'support') {
    return pageApis.atom.devApi.page.getDraftsOfDev({
      type: 'GET FIRST PAGE',
    });
  }
  throw new RoleException();
};
