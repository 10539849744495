import { sassCompile } from '@wiloke/functions';
import defaultGlobalScss from 'generate/scss/scss';
import { File, ThemeGeneralSettings } from 'types/Result';
import { PageSection } from 'types/Sections';
import { isSectionAddons } from 'utils/functions/checkSectionType';
import { getPreloader } from 'utils/functions/getPreloader';
import { inlineCss } from 'utils/functions/InlineCss';
import { Target } from '../types';

interface GetCssFromThemeSettings_OnlyThemeSettings {
  globalScss: string;
  variant: '1 phần theme - bao gồm theme settings';
  themeGeneralSettings: ThemeGeneralSettings;
  atomicCssForAdminNDev: string; // Khi lưu page atom -> client sẽ không tồn tại các section này -> Nếu optimize thì sẽ bị xoá mất nhưng nếu không ghi atomic thì khi build page atom (demo) sẽ không có atomic -> Lưu atomic css theo page để dễ dàng làm tool xoá khi zip folders theme
  target: Target;
}

interface GetCssFromThemeSettings_AllPropertiesOfTheme {
  globalScss: string;
  variant: 'Toàn bộ theme - bao gồm header, footer, theme settings';
  themeGeneralSettings: ThemeGeneralSettings;
  headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: PageSection[];
  footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: PageSection[];
  atomicCssForAdminNDev: string; // Khi lưu page atom -> client sẽ không tồn tại các section này -> Nếu optimize thì sẽ bị xoá mất nhưng nếu không ghi atomic thì khi build page atom (demo) sẽ không có atomic -> Lưu atomic css theo page để dễ dàng làm tool xoá khi zip folders theme
  target: Target;
}

export const SIGNAL_GLOBAL_CSS_FILE = '//startAssets @veda_id:globalCss @veda_name:globalCss @veda_type:css @veda_content:';
export const SIGNAL_PRELOADER_CSS_FILE =
  '//startAssets @veda_id:preloaderCss @veda_name:preloader @veda_position:footer @veda_type:css @veda_content:';

export const getCssFromThemeSettings = async (
  params: GetCssFromThemeSettings_OnlyThemeSettings | GetCssFromThemeSettings_AllPropertiesOfTheme,
): Promise<[File, File] | [File, File, File, File]> => {
  const { variant, globalScss, themeGeneralSettings, atomicCssForAdminNDev } = params;

  const [_globalScss, _defaultGlobalScss] = await Promise.all([sassCompile.client(globalScss), sassCompile.client(defaultGlobalScss)]);
  const { css: preloaderCss } = getPreloader(themeGeneralSettings);

  const globalThemeCssFile: File = {
    content: `
      ${SIGNAL_GLOBAL_CSS_FILE}
      ${[_globalScss, _defaultGlobalScss].join('\n')}

      /* Temp atomic css */
      ${atomicCssForAdminNDev}
    `,
    type: 'globalCss của theme - bao gồm layout, colors, fonts, scss của theme',
    name: 'globalCss',
    id: 'globalCss',
    section: undefined,
  };

  const preloaderCssFile: File = {
    content: `
      ${SIGNAL_PRELOADER_CSS_FILE}
      ${[preloaderCss].join('\n')}
    `,
    type: 'css của preloader',
    name: 'preloaderCss',
    id: 'preloaderCss',
    section: undefined,
  };

  if (variant === '1 phần theme - bao gồm theme settings') {
    return [globalThemeCssFile, preloaderCssFile];
  }
  if (variant === 'Toàn bộ theme - bao gồm header, footer, theme settings') {
    const {
      headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
      footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
    } = params;
    const headerSectionsCss = await Promise.all(
      headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections.reduce<Promise<string>[]>((res, item) => {
        if (item.enable && !isSectionAddons(item.type)) {
          return res.concat(sassCompile.client(item.data.scss ?? '', item.id));
        }
        return res;
      }, []),
    );
    const footerSectionsCss = await Promise.all(
      footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections.reduce<Promise<string>[]>((res, item) => {
        if (item.enable) {
          return res.concat(sassCompile.client(item.data.scss ?? '', item.id));
        }
        return res;
      }, []),
    );
    return [
      globalThemeCssFile,
      preloaderCssFile,
      {
        type:
          'css của các sections thuộc header - chỉ gồm css của các sections thuộc header và css inline của các sections thuộc header, không bao gồm atomic css',
        content: `
          ${[
            inlineCss.getCssFromSettings(headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections.map(section => section.data.settings)),
            ...headerSectionsCss,
          ].join('\n')}
        `,
        id: 'header',
        name: 'header',
        section: undefined,
      },
      {
        type:
          'css của các sections thuộc footer - chỉ gồm css của các sections thuộc footer và css inline của các sections thuộc footer, không bao gồm atomic css',
        content: `
          ${[
            inlineCss.getCssFromSettings(footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections.map(section => section.data.settings)),
            ...footerSectionsCss,
          ].join('\n')}
        `,
        id: 'footer',
        name: 'footer',
        section: undefined,
      },
    ];
  }
  throw new Error('GetCssFromThemeSettings');
};
