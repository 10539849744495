import { Button, ButtonProps, ScrollBars, useUI } from '@wiloke/ui';
import ModalHeader from '../ModalHeader';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from '@wiloke/functions';
import { Colors, View, ViewProps } from 'wiloke-react-core';
import * as styles from './styles';

export interface MyModalProps<T extends any = any> {
  children: ReactNode;
  size?: 'medium' | 'large' | 'small';
  headerText?: ReactNode;
  isVisible: boolean;
  isLoading?: boolean;
  cancelText?: string;
  okText?: string;
  okBackgroundColor?: keyof Colors;
  okButtonDisabled?: ButtonProps['disabled'];
  scrollDisabled?: boolean;
  contentCss?: ViewProps['css'];
  bodyCss?: ViewProps['css'];
  /** Để tính lại chiều cao cho modal */
  depsHeightRecalculation?: T;
  FooterRight?: ReactNode;
  FooterLeft?: ReactNode;
  disabledCloseButton?: boolean;
  disableFooter?: boolean;
  onCancel?: () => void;
  /** Event cancel cho riêng nút button ( không phải nút x hay overlay ) */
  onButtonCancel?: () => void;
  onOk?: () => void;
}

export const MyModal = <T extends any = any>({
  size = 'small',
  children,
  headerText = '',
  isVisible,
  isLoading = false,
  disabledCloseButton = false,
  cancelText: cancelTextProp,
  okButtonDisabled,
  scrollDisabled = false,
  disableFooter = false,
  okText: okTextProp,
  okBackgroundColor = 'primary',
  contentCss,
  bodyCss,
  depsHeightRecalculation,
  onButtonCancel,
  onCancel,
  onOk,
  FooterRight,
  FooterLeft,
}: MyModalProps<T>) => {
  const [height, setHeight] = useState<string>('auto');
  const childRef = useRef<HTMLElement | null>(null);
  const { translate } = useUI();
  const cancelText = cancelTextProp ?? translate.cancel;
  const okText = okTextProp ?? translate.ok;
  // useKey('Escape', onCancel);

  const setHeightState = () => {
    const maxHeight = window.innerHeight - 60;
    if (!!childRef.current) {
      setHeight(
        childRef.current.offsetHeight >= maxHeight
          ? `${maxHeight}px`
          : `${childRef.current.offsetHeight + 50 + (!!cancelText || !!okText || !!FooterRight ? 56 : 0)}px`,
      );
    }
  };

  useEffect(() => {
    if (isVisible) {
      setHeightState();
      window.addEventListener('resize', setHeightState);
      return () => {
        window.removeEventListener('resize', setHeightState);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, depsHeightRecalculation]);

  const renderChild = (
    <View ref={childRef} css={[styles.child(size), contentCss]}>
      {children}
    </View>
  );

  const renderContent = (
    <View css={styles.container}>
      <View css={styles.overlay} onClick={onCancel} />
      <View css={[styles.content(size, height), bodyCss]}>
        <ModalHeader title={headerText} onClose={disabledCloseButton ? undefined : onCancel} />
        {scrollDisabled ? (
          <View css={styles.body(!!cancelText || !!okText || !!FooterRight)}>{renderChild}</View>
        ) : (
          <ScrollBars css={styles.body(!!cancelText || !!okText || !!FooterRight)}>{renderChild}</ScrollBars>
        )}

        {!disableFooter && (
          <View css={styles.footer}>
            <View>{FooterLeft}</View>
            <View css={{ display: 'flex' }}>
              {!!cancelText && (
                <View>
                  <Button
                    backgroundColor="gray2"
                    color="gray8"
                    size="extra-small"
                    radius={4}
                    fontFamily="secondary"
                    css={{ fontWeight: 500 }}
                    onClick={() => {
                      onButtonCancel?.();
                      onCancel?.();
                    }}
                  >
                    {cancelText}
                  </Button>
                </View>
              )}
              {!!okText && (
                <View css={{ marginLeft: '10px' }}>
                  <Button
                    disabled={okButtonDisabled}
                    loading={isLoading}
                    backgroundColor={okBackgroundColor}
                    size="extra-small"
                    radius={4}
                    fontFamily="secondary"
                    css={{ fontWeight: 500 }}
                    onClick={() => {
                      if (isLoading) {
                        return;
                      } else {
                        onOk?.();
                      }
                    }}
                  >
                    {okText}
                  </Button>
                </View>
              )}
              {!!FooterRight && <View css={styles.footerRight}>{FooterRight}</View>}
            </View>
          </View>
        )}
      </View>
    </View>
  );

  if (!isVisible) {
    return null;
  }

  return createPortal(renderContent, document.body);
};
