import { getColorTitle } from 'components/ColorPicker2/utils';
import { ThemeSettings } from '../../../@types/ThemeSettings';
import { ColorMode } from '../@types/ColorMode';

export const vedaColorToShopifyFieldId = (color: ThemeSettings['cssVariables']['colors'][number], variant: ColorMode) => {
  const colorVarName = getColorTitle(color.name)
    .toLowerCase()
    .replace(/\s/g, '_');
  if (variant === 'light') {
    return `s_light_${colorVarName}`;
  }
  return `s_dark_${colorVarName}`;
};
