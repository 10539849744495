import { ShopifyFontFamily } from 'services/ShopifyServices/Fonts';
import { getShopifyFonts } from 'store/actions/shopify/actionFonts';
import { ActionTypes, createReducer, handleAction } from 'wiloke-react-core/utils';

type Actions = ActionTypes<typeof getShopifyFonts>;

interface State {
  fonts: ShopifyFontFamily[];
  getRequest: Status;
}

export const reducerShopifyFonts = createReducer<State, Actions>({ fonts: [], getRequest: 'idle' }, [
  handleAction('@Shopify/getShopifyFonts/request', ({ state }) => {
    state.getRequest = state.fonts.length ? 'success' : 'loading';
  }),
  handleAction('@Shopify/getShopifyFonts/success', ({ state, action }) => {
    state.getRequest = 'success';
    state.fonts = action.payload.data;
  }),
  handleAction('@Shopify/getShopifyFonts/failure', ({ state }) => {
    state.getRequest = 'failure';
  }),
]);
