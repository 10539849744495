import { ColorNames, css, Theme } from 'wiloke-react-core';

export const container = css`
  debug: QuickGuideBox-container;

  position: relative;
  display: flex;
  padding: 0px 5px;
  margin-bottom: 20px;
  cursor: pointer;
`;

export const icon = css`
  debug: QuickGuideBox-icon-container;
  width: 75px;
  height: 75px;
  border-radius: 6px;
  min-width: 75px;
  min-height: 75px;
  max-width: 75px;
  max-height: 75px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  overflow: hidden;
  z-index: 1;

  img {
    width: 100%;
  }
`;

export const iconOverlay = (color: ColorNames) => ({ colors }: Theme) => css`
  debug: QuickGuideBox-icon-overlay;
  position: absolute;
  inset: 0;
  z-index: 0;

  background-color: ${colors[color]};
  opacity: 0.15;
`;

export const link = css`
  debug: QuickGuideBox-link;

  position: absolute;
  inset: 0;
`;

export const title = css`
  font-size: 16px;
  line-height: normal;
  margin-bottom: 5px;
`;

export const styles2 = {
  imageContainer: css`
    min-width: 150px;
    max-width: 150px;

    overflow: hidden;

    img {
      height: 100%;
    }
  `,
};
