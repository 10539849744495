import { put, retry, select, takeLatest } from '@redux-saga/core/effects';
import { AxiosError } from 'axios';
import { getDraftCategories, loadMoreDraftCategories } from 'containers/ChooseTemplate/store/actions';
import { draftCategorySelector } from 'containers/ChooseTemplate/store/reducers/sections/draft.reducerCategory';
import { sectionService } from 'services/SectionService';

import { getActionType } from 'wiloke-react-core/utils';

function* handleCategories(_: ReturnType<typeof getDraftCategories.request>) {
  try {
    const { getStatus, categories }: ReturnType<typeof draftCategorySelector> = yield select(draftCategorySelector);

    if (getStatus === 'success') {
      yield put(getDraftCategories.success({ data: categories }));
    } else {
      const data: Awaited<ReturnType<typeof sectionService.categories.getCategoriesOfDraft>> = yield retry(
        3,
        1000,
        sectionService.categories.getCategoriesOfDraft,
      );
      yield put(getDraftCategories.success({ data }));
    }
  } catch (error) {
    console.log(error);
    const err = error as AxiosError<{ message: string }>;
    const message = err.response?.data?.message || '';
    yield put(getDraftCategories.failure({ message }));
  }
}

function* handleLoadMoreCategories({ payload: { cursor } }: ReturnType<typeof loadMoreDraftCategories.request>) {
  try {
    const data: Awaited<ReturnType<typeof sectionService.categories.loadMoreCategoriesOfDraft>> = yield retry(
      3,
      1000,
      sectionService.categories.loadMoreCategoriesOfDraft,
      cursor,
    );
    yield put(loadMoreDraftCategories.success({ data }));
  } catch (error) {
    console.log(error);
    const err = error as AxiosError<{ message: string }>;
    const message = err.response?.data?.message || '';
    yield put(loadMoreDraftCategories.failure({ message }));
  }
}

export function* watchGetDraftCategories() {
  yield takeLatest(getActionType(getDraftCategories.request), handleCategories);
}

export function* watchLoadMoreDraftCategories() {
  yield takeLatest(getActionType(loadMoreDraftCategories.request), handleLoadMoreCategories);
}
