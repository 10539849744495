import { adapterGetMegaMenus } from 'services/MegaMenuService/Adapters/adapterGetMegaMenus';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';

interface GetMegamenusOfPageDraft {
  megamenuCommandIds: string[];
}

export const getMegamenusOfPageDraft = async ({ megamenuCommandIds }: GetMegamenusOfPageDraft) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    const responses = await pageApis.atom.adminApi.megamenu.getMegamenusOfDraft({ commandIds: megamenuCommandIds });
    return adapterGetMegaMenus(responses.info);
  }
  if (role === 'dev' || role === 'support') {
    const responses = await pageApis.atom.devApi.megamenu.getMegamenusOfDraft({ commandIds: megamenuCommandIds });
    return adapterGetMegaMenus(responses.info);
  }
  throw new RoleException();
};
