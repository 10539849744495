import { put, select, takeEvery } from 'redux-saga/effects';
import { deleteLangFlow } from 'store/global/globalTranslation/actions';
import { deleteFileTranslation, globalThemeTranslationSelector, setFileLanguageActive } from 'store/global/globalTranslation/slice';
import { getActionType } from 'wiloke-react-core/utils';

function* handleDelete({ payload: fileName }: ReturnType<typeof deleteLangFlow>) {
  yield put(deleteFileTranslation({ fileName }));

  const { translation }: ReturnType<typeof globalThemeTranslationSelector> = yield select(globalThemeTranslationSelector);

  yield put(setFileLanguageActive(Object.keys(translation)[0]));
}

export function* watchDeleteLangFlow() {
  yield takeEvery(getActionType(deleteLangFlow), handleDelete);
}
