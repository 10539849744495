import { AxiosResponse } from 'axios';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from './const';

interface Params {
  purchaseCode: string;
  website: string;
}

interface ResponseSuccess {
  message: string;
}

export const deleteWebsiteOfPurchaseCode = async ({ purchaseCode, website }: Params) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${baseUrl}/websites`,
    method: 'PUT',
    data: { purchaseCode, website },
  });
  return response.data;
};
