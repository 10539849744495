import { customLog, imageUrl } from '@wiloke/functions';
import { isEmpty } from 'ramda';
import { MouseEvent } from 'react';
import { SchemaSettingField, SettingArray, SettingArrayValue } from 'types/Schema';
import { CopyData, CopyResult } from 'utils/functions/copyStyle/types';
import { relatedFieldNames } from 'utils/functions/copyStyle/utils/checkFieldName';
import { deepFindByExactValue, deepFindByObjectValue, deepFindByStringValue } from 'utils/functions/deepFind';

function getSettingImage(settingArray: SettingArray, settingArrayValue: SettingArrayValue, target: HTMLElement): CopyResult | undefined {
  const src = (target as HTMLImageElement).src;
  const fieldImageChildrenValues = deepFindByExactValue(settingArray, imageUrl(src));
  if (isEmpty(fieldImageChildrenValues)) {
    return {
      type: 'unknown',
      copiedData: [],
    };
  }

  const fieldImageChildrenValue = fieldImageChildrenValues[0];
  const currentImageFields = deepFindByObjectValue<SettingArrayValue, SchemaSettingField>(settingArrayValue, fieldImageChildrenValue);
  if (isEmpty(currentImageFields)) {
    return {
      type: 'unknown',
      copiedData: [],
    };
  }
  const imageField = currentImageFields[0];
  const designOfSelectedField = settingArrayValue.children.filter(field => field.type === 'styleBox' || relatedFieldNames.includes(field.name));
  if (isEmpty(designOfSelectedField)) {
    return {
      type: imageField.type,
      copiedData: [],
    };
  }

  const result: CopyData[] = [];
  for (const field of designOfSelectedField) {
    result.push({
      children: field.children,
      grandChildId: field.id,
      childId: settingArrayValue.id,
      settingId: settingArray.id,
      name: field.name,
    });
  }
  return {
    type: imageField.type,
    copiedData: result,
  };
}

function getTextSettings(settingArray: SettingArray, settingArrayValue: SettingArrayValue, target: HTMLElement): CopyResult | undefined {
  const _value = target.textContent?.trim();
  // tìm field design với hậu tố _design hoặc _style, tiền tố là name của component được click
  // => kết quả mong đợi title_design hoặc title_style

  const designOfSelectedField = settingArrayValue.children.filter(field => field.type === 'styleBox' || relatedFieldNames.includes(field.name));

  if (isEmpty(designOfSelectedField)) {
    return {
      type: 'unknown',
      copiedData: [],
    };
  }

  if (!_value) {
    const result: CopyData[] = [];
    for (const field of designOfSelectedField) {
      result.push({
        children: field.children,
        grandChildId: field.id,
        childId: settingArrayValue.id,
        settingId: settingArray.id,
        name: field.name,
      });
    }
    return {
      type: 'unknown',
      copiedData: result,
    };
  }

  const selectedFields = deepFindByStringValue<SettingArrayValue, SchemaSettingField>(settingArrayValue, _value);

  if (isEmpty(selectedFields)) {
    const result: CopyData[] = [];
    for (const field of designOfSelectedField) {
      result.push({
        children: field.children,
        grandChildId: field.id,
        childId: settingArrayValue.id,
        settingId: settingArray.id,
        name: field.name,
      });
    }
    return {
      type: 'shopify_text',
      copiedData: result,
    };
  }

  const selectedChildField = selectedFields[0];

  const result: CopyData[] = [];
  for (const field of designOfSelectedField) {
    result.push({
      children: field.children,
      grandChildId: field.id,
      childId: settingArrayValue.id,
      settingId: settingArray.id,
      name: field.name,
    });
  }
  return {
    type: selectedChildField.type ?? 'unknown',
    copiedData: result,
  };
}

function getItemSettings(settingArray: SettingArray, settingArrayValue: SettingArrayValue): CopyResult | undefined {
  const fieldItemsDesign = settingArrayValue.children.filter(field => field.type === 'styleBox' || relatedFieldNames.includes(field.name));

  if (isEmpty(fieldItemsDesign)) {
    return {
      type: 'item',
      copiedData: [],
    };
  }

  const result: CopyData[] = [];
  for (const field of fieldItemsDesign) {
    result.push({
      name: field.name,
      children: field.children,
      grandChildId: field.id,
      settingId: settingArray.id,
      childId: settingArrayValue.id,
    });
  }
  return {
    type: 'item',
    copiedData: result,
  };
}

/*
  warning: Hiện tại đang phải tìm setting bằng text content nên:
    - Chỉ có thể tìm được setting của text. Những field khác như image chưa tìm được
    - Có thể bị trường hợp nếu có 2 hoặc nhiều setting có text content giống nhau thì sẽ không biết được setting đang click là phần tử thứ mấy, ví dụ: có 2 item trong block array đều có giá trị là "Text A"
  solution: Cần phải in thêm attribute là veda-setting-id=settingId cùng cấp với các attribute như là veda-index-boc, veda-open-tag
  result:
    + Tìm kiếm setting đang được click dễ hơn vì settingId là unique nên kết quả tìm được luôn là 1
 */
// đang được gọi ở useAtomEvent
export function getComponentStyleInArray(
  settingArray: SettingArray,
  componentIndex: number,
  event: MouseEvent<HTMLElement, globalThis.MouseEvent>,
): CopyResult | undefined {
  try {
    const currentChild = settingArray.children.at(componentIndex);
    if (!currentChild) {
      return undefined;
    }

    if (!currentChild) {
      return {
        type: 'unknown',
        copiedData: [],
      };
    }

    const _target = event.target as HTMLElement;

    // Tìm item design trong block array
    // todo: Nếu khu vực click có nhiều child node bên trong thì => là item container
    if (_target.getAttribute('veda-component-index') && _target.getAttribute('veda-length-of-loop') && _target.childElementCount >= 2) {
      return getItemSettings(settingArray, currentChild);
    }

    if (_target.tagName === 'IMG') {
      return getSettingImage(settingArray, currentChild, _target);
    }

    return getTextSettings(settingArray, currentChild, _target);
  } catch (error) {
    const _error = error as Error;
    customLog('getComponentStyleInArray', _error.message);
    console.log(error);
    return {
      type: 'unknown',
      copiedData: [],
    };
  }
}
