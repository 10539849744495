import { addonApiController } from 'services/AddonService';
import { adapterGetAddon } from 'services/AddonService/Adapters/adapterGetAddon';
import { AdminAddon } from 'types/Addons';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const getAtom = async (commandId: string) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const atomResponse = await addonApiController.atom.adminApi.addons.getAtom({ commandId, cacheVersion: lastCache });
    const productResponse = await addonApiController.product.adminApi.addons.getProductsWithParentCommandIds({
      parentCommandIds: atomResponse.commandId,
      cacheVersion: lastCache,
    });
    const withAdapter = adapterGetAddon(atomResponse);
    return {
      ...withAdapter,
      vedaProductRepresent: productResponse.info.find(product => product.commandId === atomResponse.commandId),
    } as AdminAddon;
  }
  const response = await addonApiController.atom.publishApi.addons.getAtom({ commandId, cacheVersion: lastCache });
  return adapterGetAddon(response) as AdminAddon;
};
