export const pricing = {
  annual: 'Annuel',
  monthly: 'Mensuel',
  save_month: 'Économisez %% year %% mois',
  subtotal: 'Sous-total',
  coupon_cannot_blank: 'Le code de réduction ne peut pas être vide',
  billing_cycle: 'Cycle de facturation',
  upgrade: 'Mise à niveau',
  checkout: 'Paiement',
  enter_promo: 'Entrez le code promo',
  apply: 'Appliquer',
  already_used_code: 'Ce code a déjà été utilisé',
} as const;
