import { Button, Tooltip } from '@wiloke/ui';
import Box from 'components/FieldBox';
import SelectAntd from 'components/SelectAntd';
import { useGetThemesShopify } from 'containers/Admin/ThemeBuilder/ThemeDashboard/slice/actions';
import { themeDashboardSelector } from 'containers/Admin/ThemeBuilder/ThemeDashboard/slice/sliceThemeDashboard';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateThemeIdOfUser } from 'store/global/auth/actions';
import { authSelector } from 'store/selectors';
import { i18n } from 'translation';
import { ActivityIndicator, FontAwesome, Space, View } from 'wiloke-react-core';
import * as styles from './styles';

export const DuplicateThemeShopify = () => {
  const { getThemesShopifyStatus, shopifyThemes } = useSelector(themeDashboardSelector);

  const getThemesShopify = useGetThemesShopify();
  const { themeVedaId, updateThemeIdOfUserStatus } = useSelector(authSelector);
  const [themeId, setThemeId] = useState(themeVedaId);
  const updateThemeIdOfUser = useUpdateThemeIdOfUser();

  useEffect(() => {
    if (themeVedaId !== '') {
      setThemeId(themeVedaId);
    }
  }, [themeVedaId]);

  useEffect(() => {
    getThemesShopify.request(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    updateThemeIdOfUser.request({ themeId });
  };

  return (
    <Box.WithTitle
      title={i18n.t('adminDashboard.duplicate_theme.title')}
      description={
        <View>
          <View>{i18n.t('adminDashboard.duplicate_theme.description')}</View>
          <View
            tagName="a"
            href="https://help.myshopkit.app/en/docs/veda-landing-page-builder/frequently-asked-questions/customize-without-affecting-your-live-theme/"
            target="_blank"
          >
            {i18n.t('adminDashboard.duplicate_theme.click_to_learn')}
          </View>
        </View>
      }
      borderWidth={0}
      css={styles.box}
    >
      <View
        css={{
          display: 'flex',
          columnGap: '8px',
          alignItems: 'center',
        }}
      >
        <View css={{ maxWidth: '405px', width: '100%' }}>
          <SelectAntd
            style={{ textAlign: 'left' }}
            value={themeId}
            options={shopifyThemes.map(item => {
              const timeCreate = item.created_at ? ` - ${item.created_at}` : '';
              return {
                label: `${item.name}${timeCreate}`,
                value: item.id,
              };
            })}
            placeholder={i18n.t('adminDashboard.duplicate_theme.select')}
            showSearch
            onChange={setThemeId}
          />
        </View>
        <View
          css={styles.iconWrap}
          onClick={() => {
            getThemesShopify.request(undefined);
          }}
        >
          <Tooltip portal text={i18n.t('general.renew_data')} placement="left" css={styles.itemFull}>
            {getThemesShopifyStatus === 'loading' ? <ActivityIndicator size={16} /> : <FontAwesome type="far" name="sync-alt" size={16} />}
          </Tooltip>
        </View>
      </View>
      <Space size={15} />
      <Button loading={updateThemeIdOfUserStatus === 'loading'} size="extra-small" backgroundColor="secondary" radius={6} onClick={handleSave}>
        {i18n.t('general.save')}
      </Button>
    </Box.WithTitle>
  );
};
