import { getCssFromAddonScss, getHtmlFilesOfAddon, getJsOfAddon } from 'generate/utils/getFilesForSave';
import { AddonOfTheme_Atom_N_Client } from 'types/Addons';
import { File, Result } from 'types/Result';
import { AddonIssue, ModalWarningDuplicateCommandId } from '../../../../components/ModalWarningDuplicateCommandId';
import { getAddons } from './getAddons';
import { getExtractResultOfAddonsCacheKey } from './utils/CacheKeyControl';
import { pushToCache, readFromCache } from './utils/CacheStorage';

interface ExtractResultOfAddons {
  cacheKeysOfAddons: Awaited<ReturnType<typeof getAddons>>;
}

const createId = (file: File) => file.id;
export const extractResultOfAddons = async ({ cacheKeysOfAddons }: ExtractResultOfAddons) => {
  const storageForTrackDuplicate = new Map<string, AddonOfTheme_Atom_N_Client>();
  const temporaryErrors: AddonIssue['data'] = [];

  const enableAddonSections_ = await Promise.all(cacheKeysOfAddons.map(async key => await readFromCache<AddonOfTheme_Atom_N_Client>(key)));
  const enableAddonSections = enableAddonSections_ as AddonOfTheme_Atom_N_Client[];

  const addonFilesOfPage = await Promise.all(
    enableAddonSections.map(async addonSection => {
      const isEnablePosition = addonSection.positionEnabled;
      const [addonHtmlFile] = getHtmlFilesOfAddon({ addonSection: addonSection.body, errorOption: 'throw' });
      const cssFile = await getCssFromAddonScss({ addonSection: addonSection.body });
      const jsFile = getJsOfAddon({ addonSection: addonSection.body });
      const ID = createId(addonHtmlFile);
      const itemInStorageForTrackDuplicate = storageForTrackDuplicate.get(ID);
      if (itemInStorageForTrackDuplicate) {
        temporaryErrors.push({
          addonError: addonSection,
          addonExisted: itemInStorageForTrackDuplicate,
        });
      }
      storageForTrackDuplicate.set(ID, addonSection);
      return {
        css: cssFile,
        js: jsFile,
        liquid: addonHtmlFile,
        id: addonSection.body.id,
        type: isEnablePosition ? 'addon enable position' : 'addon disable position',
      } as Result['filesOfAddons'][number];
    }),
  );
  const addons = addonFilesOfPage.flat();
  const result: Result['filesOfAddons'] = addons.flat();
  if (temporaryErrors.length) {
    ModalWarningDuplicateCommandId.setError?.({ type: 'addon', data: temporaryErrors });
  }
  const cacheKey = await pushToCache(JSON.stringify(result), getExtractResultOfAddonsCacheKey());
  return cacheKey;
};
