import { reduceTotalLinesOfCodeBySpreadFileSignal } from '../@consts';
import { strToRegexpPattern } from './strToRegexpPattern';

export const getRegexMatchSignalCreatedByTemplateCoders = () => {
  const { end, endTag, start, startTag } = reduceTotalLinesOfCodeBySpreadFileSignal;
  return {
    start: new RegExp(`{%\\s*${startTag}\\s*%}.*${strToRegexpPattern(start)}.*{%\\s*${endTag}\\s*%}`, 'g'),
    end: new RegExp(`{%\\s*${startTag}\\s*%}.*${strToRegexpPattern(end)}.*{%\\s*${endTag}\\s*%}`, 'g'),
  };
};
