export type PLAN_HANDLE = 'free' | 'business' | 'advanced' | 'ultimate';

export enum PLAN_ENUM {
  free = 'free',
  business = 'business',
  advanced = 'advanced',
  ultimate = 'ultimate',
}

type PREMIUM_FUNCTIONS =
  | 'export_import_page'
  | 'export_import_section'
  | 'edit_code'
  | 'meta_fields'
  | 'copyright'
  | 'theme_page_javascript_css'
  | 'color_scheme'
  | 'cart_page'
  | 'animation_field'
  | 'header_footer'
  | 'duplicate_page'
  | 'save_section_favorite'
  | 'upload_csv';

export const VEDA_FUNCTION_ENUM: Record<PREMIUM_FUNCTIONS, PLAN_HANDLE> = {
  // advance
  export_import_page: 'advanced',
  export_import_section: 'advanced',
  edit_code: 'advanced',
  cart_page: 'advanced',
  header_footer: 'advanced',
  upload_csv: 'advanced',

  // business
  meta_fields: 'business',
  copyright: 'business',
  theme_page_javascript_css: 'business',
  color_scheme: 'business',
  animation_field: 'business',
  duplicate_page: 'business',
  save_section_favorite: 'business',
};

interface IPlanStatic {
  handle: PLAN_HANDLE;
  pricePerMonth: string;
  pricePerYear: string;
  title: string;
  type: 'yearly' | 'monthly';
}

type MoneyDataType = Record<PLAN_HANDLE, Record<string, string>>;

const default_money_by_plan: MoneyDataType = {
  free: {
    month: '0',
    year: '0',
  },
  business: {
    month: '19',
    year: '190',
  },
  advanced: {
    month: '39',
    year: '390',
  },
  ultimate: {
    month: '149',
    year: '1490',
  },
};

const money_by_plan: MoneyDataType = {
  free: {
    month: process.env.MONEY_PLAN_FREE_PER_MONTH ?? default_money_by_plan.free.month,
    year: process.env.MONEY_PLAN_FREE_PER_YEAR ?? default_money_by_plan.free.year,
  },
  business: {
    month: process.env.MONEY_PLAN_BUSINESS_PER_MONTH ?? default_money_by_plan.business.month,
    year: process.env.MONEY_PLAN_BUSINESS_PER_YEAR ?? default_money_by_plan.business.year,
  },
  advanced: {
    month: process.env.MONEY_PLAN_ADVANCED_PER_MONTH ?? default_money_by_plan.advanced.month,
    year: process.env.MONEY_PLAN_ADVANCED_PER_YEAR ?? default_money_by_plan.advanced.year,
  },
  ultimate: {
    month: process.env.MONEY_PLAN_ULTIMATE_PER_MONTH ?? default_money_by_plan.ultimate.month,
    year: process.env.MONEY_PLAN_ULTIMATE_PER_YEAR ?? default_money_by_plan.ultimate.year,
  },
};

export const PLAN_STATIC_DATA: Record<PLAN_HANDLE, IPlanStatic> = {
  free: {
    handle: 'free',
    pricePerMonth: `$${money_by_plan.free.month}<span>/month</span>`,
    pricePerYear: `$${money_by_plan.free.year}<span>/year</span>`,
    title: 'Free',
    type: 'monthly',
  },
  business: {
    handle: 'business',
    pricePerMonth: `$${money_by_plan.business.month}<span>/month</span>`,
    pricePerYear: `$${money_by_plan.business.year}<span>/year</span>`,
    title: 'Business',
    type: 'monthly',
  },
  advanced: {
    handle: 'advanced',
    pricePerMonth: `$${money_by_plan.advanced.month}<span>/month</span>`,
    pricePerYear: `$${money_by_plan.advanced.year}<span>/year</span>`,
    title: 'Advanced',
    type: 'monthly',
  },
  ultimate: {
    handle: 'advanced',
    pricePerMonth: `$${money_by_plan.ultimate.month}<span>/month</span>`,
    pricePerYear: `$${money_by_plan.ultimate.year}<span>/year</span>`,
    title: 'Advanced',
    type: 'monthly',
  },
};
