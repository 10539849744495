import { sectionApiController } from 'services/SectionService';
import { userApis } from 'services/UserService/apis';
import { SectionChangelog } from 'types/Sections';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const createChangelogOfAtom = async ({ content, version, versionId }: Omit<SectionChangelog, 'commandId'>) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    const response = await sectionApiController.atom.adminApi.changelogs.createChangelog({
      changelog: { content, version, versionId },
    });
    await userApis.user.adminApi.updateVedaCacheVersion();
    return response;
  }
  throw new RoleException();
};
