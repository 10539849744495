import Collapse from 'components/Collapse';
import DragItem from 'components/DragItem';
import DragItemRight from 'components/DragItemRight';
import { Dropdown, RenderItemParam2 } from '@wiloke/ui';
import { FC } from 'react';
import { SettingBlock } from 'types/Schema';
import { getLabel } from 'utils/functions/getLabel';
import { FontAwesome, View } from 'wiloke-react-core';
import { i18n } from 'translation';
import { message } from 'antd';
import { SchemaBlocksProps } from '../type';
import { COLLAPSE_GROUP_NAME_BLOCKS } from '../const';
import { useSchemaBlocks } from '../contexts/SchemaBlocksContext';
import { getDescriptionOfBlocksField } from '../utils';
import { FormBlock } from './forms/FormBlock/FormBlock';
import BoxCenter from 'components/BoxCenter';

interface BlockProps extends Pick<SchemaBlocksProps, 'section' | 'colorSchemes'> {
  blockDragItem: RenderItemParam2<SettingBlock>;
}

export const Block: FC<BlockProps> = ({ blockDragItem, ...rest }) => {
  const { duplicateBlock, deleteBlock, copyBlock, pasteBlock, clipboard } = useSchemaBlocks();
  const { item, dragHandleProps, index } = blockDragItem;
  const { id, label } = item;

  return (
    <Collapse
      name={id}
      groupName={COLLAPSE_GROUP_NAME_BLOCKS}
      renderHeader={(handler, active) => {
        return (
          <View>
            <DragItem
              longPressDragHandleProps={dragHandleProps}
              variant="variant2"
              active={active}
              label={getLabel(label)}
              description={getDescriptionOfBlocksField({ variant: 'block', data: item })}
              onEdit={handler}
              innerCss={{ backgroundColor: 'transparent' }}
              RightItem={
                <>
                  <DragItemRight
                    onEdit={handler}
                    onDuplicate={() => {
                      duplicateBlock(index);
                    }}
                    onDelete={() => {
                      deleteBlock({ blockId: id });
                    }}
                  />
                  <Dropdown
                    data={[
                      { icon: 'copy', label: i18n.t('general.copy', { text: i18n.t('builderPage.schema.blocks.title') }), value: 'copy' },
                      { icon: 'paste', label: i18n.t('general.paste', { text: i18n.t('builderPage.schema.blocks.title') }), value: 'paste' },
                    ]}
                    onClick={value => {
                      if (value === 'copy') {
                        copyBlock({ blockId: id });
                        message.success(i18n.t('general.copied'));
                      } else {
                        if (clipboard) {
                          pasteBlock({
                            newData: clipboard,
                            blockId: id,
                          });
                        }
                      }
                    }}
                  >
                    <BoxCenter size={22} css={{ marginLeft: '-8px', marginRight: '5px' }}>
                      <View>
                        <FontAwesome name="ellipsis-v" type="far" color="gray6" />
                      </View>
                    </BoxCenter>
                  </Dropdown>
                </>
              }
            />
          </View>
        );
      }}
    >
      <View css={{ padding: '8px 20px' }}>
        <FormBlock {...rest} blockData={item} />
      </View>
    </Collapse>
  );
};
