import { AxiosResponse } from 'axios';
import fetchAPI from 'utils/functions/fetchAPI';
import { cUrl } from '../const';

interface Params {
  shopDevAllowable: 'yes' | 'no';
}

interface ResponseSuccess {
  message: string;
}

export const allowShopDev = async ({ shopDevAllowable }: Params) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${cUrl}/options`,
    method: 'PUT',
    data: {
      name: 'shopDevAllowable',
      value: shopDevAllowable,
    },
  });

  return response.data;
};
