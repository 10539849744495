import { MyModal, ScrollBars } from '@wiloke/ui';
import { ProductCategoriesByTopic } from 'containers/ChooseTemplate/components/Admin/ProductCategoriesByTopic';
import { useSetTemplateBoardVisible } from 'containers/ChooseTemplate/store/actions';
import { tourGuideSelector, useSetTourGuideAddon } from 'containers/TourGuides/sliceTourGuides';
import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useSetSectionEdittingId } from 'store/global/sectionEdittingId/actions';
import { chooseTemplateVisibleSelector } from 'store/selectors';
import { i18n } from 'translation';
import { View } from 'wiloke-react-core';
import { AdminAddonTemplate } from './components/Admin/AdminAddonTemplate';
import { AdminCategories } from './components/Admin/AdminCategories';
import { AdminMegaMenu } from './components/Admin/AdminMegaMenu';
import AdminSectionTemplate from './components/Admin/AdminSectionTemplate';
import { ProductCategories } from './components/Admin/ProductCategories';
import { DraftAddonTemplate } from './components/Draft/DraftAddonTemplate';
import { DraftMegaMenu } from './components/Draft/DraftMegaMenu';
import DraftSectionTemplate from './components/Draft/DraftSectionTemplate';
import { ImportSectionContent } from './components/ImportSectionContent';
import { LibraryNav } from './components/NavBar';
import { AddonsContent } from './components/Users/AddonsContent';
import { SavedContent } from './components/Users/SavedContent';
import SectionContent from './components/Users/SectionContent';
import * as styles from './styles';

const ChooseTemplate: FC = () => {
  const { navKeys, visible } = useSelector(chooseTemplateVisibleSelector);
  const { addonsTour } = useSelector(tourGuideSelector);

  const setSectionEditing = useSetSectionEdittingId();
  const setTemplateBoardVisible = useSetTemplateBoardVisible();
  const renderNav = <LibraryNav />;
  const setAddonTour = useSetTourGuideAddon();

  const handleScroll = useCallback(
    (scrollTop: number) => {
      if (navKeys[0] === 'sections' || navKeys[0] === 'addons') {
        if (scrollTop > 300) {
          setTemplateBoardVisible({ isScrollingContent: true });
        } else {
          setTemplateBoardVisible({ isScrollingContent: false });
        }
      }
    },
    [setTemplateBoardVisible, navKeys],
  );

  const renderContent = (
    <ScrollBars css={styles.right} onScrollFrame={({ scrollTop }) => handleScroll(scrollTop)}>
      {navKeys[0] === 'sections' && <SectionContent />}
      {navKeys[0] === 'addons' && <AddonsContent />}

      {navKeys[0] === 'import' && <ImportSectionContent />}
      {navKeys[0] === 'my_sections' && <SavedContent />}

      {/* dev zone */}
      {navKeys[0] === 'draft' && navKeys[1] === 'Sections' && <DraftSectionTemplate />}
      {navKeys[0] === 'draft' && navKeys[1] === 'Addons' && <DraftAddonTemplate />}
      {navKeys[0] === 'draft' && navKeys[1] === 'Mega-menu' && <DraftMegaMenu />}

      {/* admin zone */}
      {navKeys[0] === 'admin' && navKeys[1] === 'Sections' && <AdminSectionTemplate />}
      {navKeys[0] === 'admin' && navKeys[1] === 'Addons' && <AdminAddonTemplate />}
      {navKeys[0] === 'admin' && navKeys[1] === 'Mega-menu' && <AdminMegaMenu />}
      {navKeys[0] === 'admin' && navKeys[1] === 'Atom Categories' && <AdminCategories />}
      {navKeys[0] === 'admin' && navKeys[1] === 'Product Categories' && <ProductCategories />}
      {navKeys[0] === 'admin' && navKeys[1] === 'Product Categories by Topic' && <ProductCategoriesByTopic />}
    </ScrollBars>
  );

  return (
    <MyModal
      size="large"
      bodyCss={{ maxWidth: '1600px' }}
      scrollDisabled
      isVisible={visible}
      headerText={i18n.t('general.templates')}
      contentCss={{ padding: 0 }}
      cancelText=""
      okText=""
      onCancel={() => {
        setTemplateBoardVisible({ visible: false });
        setSectionEditing('');
        setAddonTour({ run: addonsTour.run, stepIndex: 0 });
      }}
    >
      <View css={styles.inner}>
        {renderNav}
        <View css={{ position: 'relative', width: '100%', height: '100%' }}>{renderContent}</View>
      </View>
    </MyModal>
  );
};

export default ChooseTemplate;
