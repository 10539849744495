import { setLoadingPageThemeSettings, setModalSettingsOk } from 'store/actions/actionModalSettingsOk';
import { ActionTypes, createReducer, handleAction } from 'wiloke-react-core/utils';

type ModalSettingsOkAction = ActionTypes<typeof setModalSettingsOk | typeof setLoadingPageThemeSettings>;
interface ModalSettingsOkState {
  settingsOk: boolean;
  loadingPageThemeSettings: boolean;
}

export const reducerModalSettingsOk = createReducer<ModalSettingsOkState, ModalSettingsOkAction>(
  { loadingPageThemeSettings: false, settingsOk: false },
  [
    handleAction('@Global/setModalSettingsOk', ({ action, state }) => {
      state.settingsOk = action.payload.value;
    }),
    handleAction('@Global/setLoadingPageThemeSettings', ({ state, action }) => {
      state.loadingPageThemeSettings = action.payload.value;
    }),
  ],
);
