import BoxCenter from 'components/BoxCenter';
import ImagePlaceholder from 'components/ImagePlaceholder';
import { LazyImage, Hotspot, Tooltip, Button } from '@wiloke/ui';
import { CSSProperties, FC, ReactNode } from 'react';
import { i18n } from 'translation';
import { FontAwesome, Space, Text, View, ViewProps } from 'wiloke-react-core';
import { SectionCardLoading } from './SectionLoading';
import * as styles from './styles';
import { PLAN_HANDLE } from 'utils/constants/PlanEnum';
import { AdvancedBadge, BusinessBadge, UltimateBadge } from 'components/PlanBadge';

export interface SectionCardProps {
  title: ReactNode;
  type?: string;
  loading?: boolean;
  image?: string;
  imageWidth?: number;
  imageHeight?: number;
  Right?: ReactNode;
  author?: ReactNode;
  date?: ReactNode;
  css?: ViewProps['css'];
  byText?: ReactNode;
  /** Text của nút */
  buttonText?: string;
  buttonText2?: string;
  disabled?: boolean;
  hasUpdate?: boolean;
  plan?: PLAN_HANDLE;
  backgroundSize?: 'cover' | 'contain';
  imageCss?: CSSProperties;
  onClick?: () => void;
  onClick2?: () => void;
  onUpdate?: () => void;
}

const SectionCard: FC<SectionCardProps> = ({
  type,
  title,
  loading = false,
  disabled = false,
  hasUpdate = false,
  image = '',
  imageHeight,
  imageWidth,
  Right,
  author,
  css,
  date,
  buttonText = i18n.t('general.preview'),
  buttonText2,
  plan,
  byText,
  backgroundSize = 'contain',
  imageCss,
  onClick,
  onClick2,
  onUpdate,
}) => {
  return (
    <View css={[styles.container, css]}>
      <View css={styles.featureContainer} wilokeStyles="parent-hover">
        <View css={styles.featureContent} wilokeStyles={!loading ? 'child-fadein-0' : undefined}>
          <Button
            loading={loading}
            backgroundColor="primary"
            color="light"
            size="extra-small"
            radius={4}
            fontFamily="secondary"
            css={styles.button(disabled)}
            onClick={() => {
              if (disabled || !buttonText) {
                return;
              }
              onClick?.();
            }}
          >
            {buttonText}
          </Button>

          {!!buttonText2 && (
            <Button
              loading={loading}
              backgroundColor="secondary"
              color="light"
              size="extra-small"
              radius={4}
              fontFamily="secondary"
              css={styles.button(disabled)}
              onClick={() => {
                if (disabled || !buttonText) {
                  return;
                }
                onClick2?.();
              }}
            >
              {buttonText2}
            </Button>
          )}
        </View>

        {image ? (
          <LazyImage src={image} heightImage={imageHeight} widthImage={imageWidth} backgroundSize={backgroundSize} imageCss={imageCss} />
        ) : (
          <ImagePlaceholder aspectRatio={16 / 9} />
        )}
      </View>

      <View css={styles.body}>
        <View css={styles.head}>
          <Text css={styles.type} size={10}>
            {i18n.t('general.type')}: {type}
          </Text>
          <View>
            <Text tagName="span" color="gray6" size={13} css={styles.by}>
              {byText ? byText : i18n.t('general.by')}
            </Text>

            <Text tagName="span" size={13} color="gray8">
              {author ? author : 'Someone'}
            </Text>
          </View>
        </View>
        <Space size={3} />
        <View css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Text tagName="h5">{title}</Text>

          <View>
            {plan === 'business' && <BusinessBadge />}
            {plan === 'advanced' && <AdvancedBadge />}
            {plan === 'ultimate' && <UltimateBadge />}
          </View>
        </View>

        <Space size={2} />

        {(!!date || hasUpdate) && (
          <View css={styles.footer}>
            {hasUpdate && (
              <Tooltip portal text={i18n.t('general.update')}>
                <BoxCenter css={{ position: 'relative' }} onClick={onUpdate}>
                  <Hotspot css={{ position: 'absolute', top: '5px', right: '5px' }} />
                  <FontAwesome type="far" name="arrow-from-bottom" size={13} color="gray6" />
                </BoxCenter>
              </Tooltip>
            )}

            <Text tagName="span" color="gray6" size={13}>
              {date}
            </Text>
          </View>
        )}

        {!!Right && <Space size={5} />}
        <View onClick={event => event.stopPropagation()}>{Right}</View>
      </View>
    </View>
  );
};

export default Object.assign(SectionCard, {
  Loading: SectionCardLoading,
});
