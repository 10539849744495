import { ClientTheme } from 'types/Theme';
import { renewVersion } from 'utils/CacheControl/VedaClientCacheControl';
import { removeEmptyKey } from 'utils/functions/removeEmptyKey';
import { handleGlobalTranslationsNColorSchemesInThemeClient } from '../Adapter/handleGlobalTranslationsNColorSchemes';
import { themeApis } from '../apis';

interface UpdateThemeClient {
  theme: Required<
    Pick<
      Parameters<typeof themeApis.vedaApplication.userApi.theme.updateClient>[0]['theme'],
      | 'commandId'
      | 'addonCommandIds'
      | 'headerSectionCommandIds'
      | 'footerSectionCommandIds'
      | 'themeSettings'
      | 'globalJs'
      | 'globalScss'
      | 'vendors'
    >
  >;
}

export const updateThemeClient = async ({ theme }: UpdateThemeClient): Promise<ClientTheme> => {
  const response = await themeApis.vedaApplication.userApi.theme.updateClient({
    theme: {
      ...theme,
      themeSettings: {
        ...theme.themeSettings,
        globalTranslations: {
          ...theme.themeSettings.globalTranslations,
          translation: removeEmptyKey(theme.themeSettings.globalTranslations.translation),
        },
      },
    },
  });
  renewVersion();
  return handleGlobalTranslationsNColorSchemesInThemeClient(response.info);
};
