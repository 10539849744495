import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { PurchaseCode } from 'types/PurchaseCode';
import fetchAPI from 'utils/functions/fetchAPI';
import { getApiTracking } from 'utils/functions/getApiTracking';
import { baseUrl } from './const';

interface ResponseSuccess {
  info: PurchaseCode[];
  message: string;
}

interface GetParams {
  type: 'Get First Page';
  size: number;
  purchaseCode?: string;
  website?: string;
}

interface LoadMoreParams {
  type: 'Load more';
  size: number;
  page: number;
  purchaseCode?: string;
  website?: string;
}

export const getPurchaseCodes = async ({ type, purchaseCode, website, size, ...params }: GetParams | LoadMoreParams) => {
  let requestConfig: AxiosRequestConfig = {};

  if (type === 'Get First Page') {
    requestConfig = {
      url: getApiTracking({ defaultBaseUrl: `${baseUrl}`, trackingEndpoint: `envato-purchase-codes` }),
      method: 'GET',
      params: {
        size,
      },
    };
  } else {
    const { page } = params as LoadMoreParams;
    requestConfig = {
      url: getApiTracking({ defaultBaseUrl: `${baseUrl}`, trackingEndpoint: `envato-purchase-codes/search` }),
      method: 'GET',
      params: {
        purchaseCode,
        website,
        size,
        page,
      },
    };
  }
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request(requestConfig);

  return response.data;
};
