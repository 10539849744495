import { CategoryDataResponse } from 'services/SectionService/types';
import { SectionCategoryTag } from 'types/Sections';

export const adapterGetCategories = (categoriesResponse: CategoryDataResponse[]) => {
  return categoriesResponse.map<SectionCategoryTag>(item => {
    return {
      ...item,
      quantity: item.count ? item.count.toString() : '0',
      name: item.name,
      description: item.description ? item.description : item.name,
      commandId: item.commandId,
    };
  });
};
