import { ShopifyFonts } from 'components/ShopifyFontsField';
import withDebounce from 'hocs/withDebounce';
import { useSelector } from 'react-redux';
import { useUpdateSettingsValue } from 'store/actions/actionPages';
import { sectionIdActiveSelector } from 'store/selectors';
import { pmParent } from 'utils/functions/postMessage';
import { FieldContainerProps } from '../types';

export interface ShopifyFontFieldProps<T> extends FieldContainerProps<T> {}

const FontFieldDebounce = withDebounce(ShopifyFonts, 'value', 'onChange', 200);

const ShopifyFontField = <T extends string>({ value, settingId, childId, grandChildId, forceRenderSection }: ShopifyFontFieldProps<T>) => {
  const updateSettingsValue = useUpdateSettingsValue();
  const sectionIdActive = useSelector(sectionIdActiveSelector);

  const handleChange = (value: string) => {
    updateSettingsValue({
      value,
      settingId,
      childId,
      grandChildId,
    });
    if (forceRenderSection) {
      pmParent.emit('@rerender', { sectionId: sectionIdActive, action: 'ShopifyFontField.tsx' });
    }
  };

  return <FontFieldDebounce value={value} onChange={handleChange} />;
};

export default ShopifyFontField;
