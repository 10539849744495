import qs from 'qs';
import { isIframePage } from 'utils/isIframePage';

export interface Results {
  shop: string;
  id: string;
  themeId: string;
  shopifyThemeId?: string; // Không có nếu edit page & edit theme shopify
  entityVariant?: EntityType;
}

const getPageInfo = <KeyT extends keyof Results>(key: KeyT, locationSearch?: string) => {
  const windowLocation = isIframePage() ? window.parent.location : window.location;
  const result = qs.parse(locationSearch ?? windowLocation.search, { ignoreQueryPrefix: true, comma: true })[key];
  return result as Results[KeyT];
};

export const checkIsInBuilder = () => {
  const result = {
    shop: getPageInfo('shop'),
    pageId: getPageInfo('id'),
    themeId: getPageInfo('themeId'),
  };

  return {
    isBuildPage: !!result.pageId && !!result.shop,
    isBuilderTheme: !!result.pageId && !!result.shop && !!result.themeId,
  };
};

export default getPageInfo;
