import { handleLiquidFile } from 'generate/utils/getFilesForSave/getHtmlFiles/utils/handleLiquidFile';
import { i18n } from 'translation';
import { File } from 'types/Result';
import { PageSection } from 'types/Sections';
import { Consts, LIMIT_LIQUID_FILE_SIZE } from 'utils/constants/constants';
import { isSectionMegamenu } from 'utils/functions/checkSectionType';
import { ErrorOption } from './@types/ErrorOption';
import { ExpectReturnType } from './@types/ExpectReturnType';

type HandleHtmlContent = Parameters<typeof handleLiquidFile>[0] & { errorOption: ErrorOption };
const handleHtmlContent = ({ section, errorOption, variant }: HandleHtmlContent) => {
  let result = '';
  try {
    result = handleLiquidFile({ section, variant });

    if (![result.length].every(item => item < LIMIT_LIQUID_FILE_SIZE.value)) {
      throw new Error(i18n.t('publish_shopify.limit_liquid_file_size', { size: LIMIT_LIQUID_FILE_SIZE.humanity }));
    }
  } catch (err) {
    console.log(err);
    if (errorOption === 'throw') {
      throw err;
    }
  }

  return result;
};

interface GetHtmlFiles {
  sectionsIncludeMegamenuSections: PageSection[];
  errorOption: ErrorOption;
  fileType: Extract<
    File['type'],
    | 'liquid của page - bao gồm liquid của section bình thường[đã thế megamenu tag placeholder]'
    | 'liquid của các section thuộc header - bao gồm liquid của section bình thường[đã thế megamenu tag placeholder] (cái này được ghi vào file header.liquid cái mà được render ở theme.liquid)'
    | 'liquid của các section thuộc footer - bao gồm liquid của section bình thường[đã thế megamenu tag placeholder] (cái này được ghi vào file footer.liquid cái mà được render ở theme.liquid)'
    | 'liquid của 1 addon'
  >;
}
const MegamenuTag = Consts.FakeTags.Megamenu;
export const getHtmlFiles = ({ sectionsIncludeMegamenuSections, errorOption, fileType }: GetHtmlFiles): ExpectReturnType[] => {
  return sectionsIncludeMegamenuSections.reduce<ReturnType<typeof getHtmlFiles>>((res, item) => {
    // NOTE: @tuong -> Megamenu sẽ được thế theo một cách khác nên ignore section megamenu ở đây
    if (!item.enable || isSectionMegamenu(item.type)) {
      return res;
    }
    const htmlWithMegamenuTags = handleHtmlContent({
      section: item,
      errorOption,
      variant:
        fileType === 'liquid của 1 addon'
          ? 'addon -> compile như lúc ban đầu - giữ lại code trong cặp thẻ "shopify" còn lại compile tất cả'
          : 'section -> compile các thành phần nằm trong cặp thẻ "compiler". Ví dụ "navigation", "script", ...',
    });

    // NOTE: @tuong -> Thế megamenu vào section (Không thế addon vì addon cần xử lý theo 1 cách khác)
    const htmlReplacedMegamenuTags = htmlWithMegamenuTags.replace(new RegExp(`<${MegamenuTag}\\s*data-id.*><\\/${MegamenuTag}>`, 'g'), x => {
      const domParser = new DOMParser();
      const doc = domParser.parseFromString(x, 'text/html');
      const megamenusTagEl = doc.querySelector(MegamenuTag) as HTMLElement;
      const id = megamenusTagEl.getAttribute('data-id') as string;
      const megamenuSections = sectionsIncludeMegamenuSections.find(section => section.id === id && section.enable);

      if (!megamenuSections) {
        return '';
      }

      return `<div data-id="${id}">${handleHtmlContent({
        section: megamenuSections,
        errorOption,
        variant: 'megamenu -> compile như lúc ban đầu - giữ lại code trong cặp thẻ "shopify" còn lại compile tất cả',
      })}</div>`;
    });

    if (
      fileType ===
      'liquid của các section thuộc header - bao gồm liquid của section bình thường[đã thế megamenu tag placeholder] (cái này được ghi vào file header.liquid cái mà được render ở theme.liquid)'
    ) {
      return res.concat({
        content: htmlReplacedMegamenuTags,
        type: fileType,
        name: item.label,
        id: item.id,
        section: item,
      });
    }
    if (
      fileType ===
      'liquid của các section thuộc footer - bao gồm liquid của section bình thường[đã thế megamenu tag placeholder] (cái này được ghi vào file footer.liquid cái mà được render ở theme.liquid)'
    ) {
      return res.concat({
        content: htmlReplacedMegamenuTags,
        type: fileType,
        name: item.label,
        id: item.id,
        section: item,
      });
    }
    if (fileType === 'liquid của 1 addon') {
      return res.concat({
        type: 'liquid của 1 addon',
        id: item.id,
        name: item.label,
        content: htmlReplacedMegamenuTags,
        section: undefined,
      });
    }
    if (fileType === 'liquid của page - bao gồm liquid của section bình thường[đã thế megamenu tag placeholder]') {
      return res.concat({
        content: htmlReplacedMegamenuTags,
        type: fileType,
        name: item.label,
        id: item.id,
        section: item,
      });
    }
    throw new Error('getHtmlFiles');
  }, []);
};
