export const pricing = {
  annual: 'Yearly',
  monthly: 'Monthly',
  save_month: 'Save %% year %% months',
  subtotal: 'Subtotal',
  coupon_cannot_blank: 'Coupon cannot be blank',
  billing_cycle: 'Billing cycle',
  upgrade: 'Upgrade',
  checkout: 'Checkout',
  enter_promo: 'Enter a promo code',
  apply: 'Apply',
  already_used_code: 'You have already used this code',
} as const;
