export const VARIABLES_NAME_MUST_BE_IN_SHOPIFY_TAG = [
  'templates',
  'template',
  'request',
  'sections',
  'section',
  'localization',
  'form',
  'orders',
  'order',
  'customer',
  'line_item',
  'paginate',
  'part',
  'gift_cards',
  'gift_card',
  'media',
  'routes',
  'route',
  'block',
  'search',
  'general',

  // https://shopify.dev/api/liquid/objects#images
  'collections',
  'collection',
  'product',
  'all_products',
  'blogs',
  'blog',
  'articles',
  'article',
  'cart',
  'shop',
  'settings',
  'setting',
  'recommendations',
  'recommendation',
  'canonical_url',
  'current_page',
  'current_tags',
  'current_tag',
  'linklists',
  'linklist',
  'handle',
  'images',
  'image',
  'pages',
  'page',
  'page_description',
  'page_title',
  'powered_by_link',
  'scripts',
  'script',
  'theme',
  'content_for_header',
  'content_for_index',
  'content_for_layout',
  'additional_checkout_buttons',
  'content_for_additional_checkout_buttons',
];

// @tuong -> Không check ['t', 'weight_with_unit', 'money'] vì có một số trường fix cứng dữ liệu của shopify tại thời điểm edit (ví dụ như các section "Lookbook" sẽ sử dụng field "products" để làm "hotpot")
export const FILTERS_AND_TAGS_MUST_BE_IN_SHOPIFY_TAG = [];

export const VARIABLES_NAME = [
  ...VARIABLES_NAME_MUST_BE_IN_SHOPIFY_TAG,
  // Các tag và filter
  // NOTE: Để giảm rủi ro về lỗi khi regex
  'case',
  'when',
  'unless',
  'deprecated_tags',
  'include',
  'abs',
  'append',
  'asset_img_url',
  'at_least',
  'at_most',
  'base64_decode',
  'base64_endcode',
  'base64_url_safe_decode',
  'base64_url_safe_encode',
  'brightness_difference',
  'camelcase',
  'capitalize',
  'ceil',
  'color_brightness',
  'color_contrast',
  'color_darken',
  'color_desaturate',
  'color_difference',
  'color_lighten',
  'color_mix',
  'color_modify',
  'color_saturate',
  'color_to_hex',
  'color_to_hsl',
  'color_to_rgb',
  'compact',
  'concat',
  'customer_login_link',
  'date',
  'default_errors',
  'default_pagination',
  'default',
  'divided_by',
  'downcase',
  'escape',
  'external_video_tag',
  'external_video_url',
  'file_img_url',
  'file_url',
  'first',
  'floor',
  'font_face',
  'font_modify',
  'font_url',
  'forloop',
  'format_address',
  'global_asset_url',
  'handle',
  'highlight_active_tag',
  'highlight',
  'hmac_sha1',
  'hmac_sha256',
  'image_tag',
  'image_url',
  'img_url',
  'join',
  'json',
  'last',
  'link_to_add_tag',
  'link_to_remove_tag',
  'link_to_tag',
  'link_to_type',
  'link_to_vendor',
  'link_to',
  'lstrip',
  'map',
  'md5',
  'media_tag',
  'metafield_tag',
  'metafield_text',
  'minus',
  'model_viewer_tag',
  'modulo',
  'money',
  'newline_to_br',
  'payment_type_img_url',
  'payment_type_svg_tag',
  'placeholder_svg_tag',
  'pluralize',
  'plus',
  'preload_tag',
  'prepend',
  'remove_first',
  'remove',
  'replace_first',
  'replace',
  'reverse',
  'round',
  'rstrip',
  'script_tag',
  'sha1',
  'sha256',
  'shopify_asset_url',
  'size',
  'slice',
  'sort_by',
  'sort_natural',
  'sort',
  'split',
  'strip_html',
  'strip_newlines',
  'strip',
  'stylesheet_tag',
  't',
  'time_tag',
  'times',
  'truncate',
  'truncatewords',
  'uniq',
  'upcase',
  'url_decode',
  'url_encode',
  'url_escape',
  'url_for_type',
  'url_for_vendor',
  'url_param_escape',
  'video_tag',
  'weight_with_unit',
  'where',
  'within',
];

export const SHOPIFY_PICKER_FIELD_TYPES = ['collectionPicker', 'productPicker', 'blogPicker', 'articlePicker'];
