import { SectionInfomation } from '../hooks/useGetSections';
import { omit } from 'ramda';
import { Result } from 'types/Result';
import { handleGetTranslationParams } from '../../../services/ShopifyConnection/handleGetSyncTranslationParams';
import { LiquidFile } from '../../../services/ShopifyConnection/services/.types';
import { GetAdditionalDataRelateToShopify_BEExpectResponse } from '../../../services/ShopifyConnection/services/getAdditionalDataRelateToShopify';
import { WriteAddonToShopify_BEExpectParameters } from '../../../services/ShopifyConnection/services/writeAddonToShopify';
import { WriteFooterToShopify_BEExpectParameters } from '../../../services/ShopifyConnection/services/writeFooterToShopify';
import { WriteGlobalOfThemeToShopify_BEExpectParameters } from '../../../services/ShopifyConnection/services/writeGlobalOfThemeToShopify';
import { WriteHeaderToShopify_BEExpectParameters } from '../../../services/ShopifyConnection/services/writeHeaderToShopify';
import { WritePageToShopify_BEExpectParameters } from '../../../services/ShopifyConnection/services/writePageToShopify';
import { WriteTranslation_BEExpectParameters } from '../../../services/ShopifyConnection/services/writeTranslationToShopify';
import {
  encodeIdOfGlobalCssFile,
  encodeIdOfGlobalJsFile,
  encodeIdForAllPageSectionsOfJsFile,
  encodeIdOfVendorsCssFile,
  encodeIdOfVendorsJsFile,
  getShopifyPages,
  sectionConverter,
  encodeIdForAllPageSectionsOfCssFile,
  encodeIdOfLiquidFile,
  themeSettingsConverter,
} from '../../../services/ShopifyConnection/utils';
import { PageSection } from 'types/Sections';
import { WriteThemeSchemaNSettings_BEExpectParameters } from 'services/ShopifyConnection/services/writeThemeSchemaNSettings';
import { v4 } from 'uuid';
import { ProductTheme } from 'types/Theme';
import { getThemeSlug } from 'services/ShopifyConnection/utils/VedaOutputToShopifyThemeEditor/ThemeSettingsConverter/SettingLayout/utils/getThemeSlug';

type PagesParamsExpect = DeepPartial<WritePageToShopify_BEExpectParameters> & {
  isSyncTurnedOff: boolean | null;
};
type PageFileExpect = WritePageToShopify_BEExpectParameters['assets']['files'][number];

type ThemeParamsExpect = DeepPartial<WriteGlobalOfThemeToShopify_BEExpectParameters>;

type HeaderParamsExpect = DeepPartial<WriteHeaderToShopify_BEExpectParameters>;
type FooterParamsExpect = DeepPartial<WriteFooterToShopify_BEExpectParameters>;

type AddonsParamsExpect = DeepPartial<WriteAddonToShopify_BEExpectParameters>;

type SyncTranslationParamsExpect = DeepPartial<WriteTranslation_BEExpectParameters>[];

type SyncThemeSchemaNSettings = DeepPartial<WriteThemeSchemaNSettings_BEExpectParameters> | null;

interface HandleExtractResultForForceSync {
  data: Result;
  isOverrideIndividualPages: GetAdditionalDataRelateToShopify_BEExpectResponse['info']['isOverrideIndividualPages'];
  themeName: string;
  isExportForEnvato: boolean;
  isNeedReduceTotalLinesOfCode: boolean;
  sectionsWithLinkListSetting: SectionInfomation[];
  entityVariant: EntityType;
  themeProduct: ProductTheme | undefined;
}

/** Function thực hiện lấy ra parameters để bắn lên api OutputOfBuilderService và cái tên đang thể hiện lên vị trí mà function được gọi */
export const handleExtractResultForForceSync = ({
  data,
  isOverrideIndividualPages,
  themeName,
  isExportForEnvato,
  isNeedReduceTotalLinesOfCode,
  entityVariant,
  sectionsWithLinkListSetting,
  themeProduct,
}: HandleExtractResultForForceSync) => {
  const { pages, theme, filesOfAddons, builderType } = data;
  // Lấy ra page params
  const pagesParams = Object.entries(pages).reduce<[PagesParamsExpect] | PagesParamsExpect[]>((result, [, pageData]) => {
    const { files, data } = pageData;
    const { page, pageSettings } = data;
    const { type, commandId, shopifyPages, enable } = page;
    const { generalSettings } = pageSettings;
    // NOTE: Tạm thời chuyển sang dùng label của page
    const { handle, headerFooterEnabled, metaDescription, metaTitle, socialShareImage } = generalSettings;
    // const { handle, headerFooterEnabled, metaDescription, metaTitle, socialShareImage, label } = generalSettings;

    const allJs: Record<'globalJsOfPage' | 'jsOfSectionsInPage', Exclude<PageFileExpect, LiquidFile>> = {
      globalJsOfPage: {
        name: encodeIdOfGlobalJsFile(page),
        id: encodeIdOfGlobalJsFile(page),
        type: 'js',
        content: '',
      },
      jsOfSectionsInPage: {
        name: encodeIdForAllPageSectionsOfJsFile(page),
        id: encodeIdForAllPageSectionsOfJsFile(page),
        type: 'js',
        content: '',
      },
    };

    const allCss: Record<'globalCssOfPage' | 'cssOfSectionsInPage', Exclude<PageFileExpect, LiquidFile>> = {
      globalCssOfPage: {
        name: encodeIdOfGlobalCssFile(page),
        id: encodeIdOfGlobalCssFile(page),
        type: 'css',
        content: '',
      },
      cssOfSectionsInPage: {
        name: encodeIdForAllPageSectionsOfCssFile(page),
        id: encodeIdForAllPageSectionsOfCssFile(page),
        type: 'css',
        content: '',
      },
    };

    const allLiquids: Array<LiquidFile & { section: PageSection }> = [];

    const allVendorsCss: Exclude<PageFileExpect, LiquidFile> = {
      type: 'vendorsCss',
      id: encodeIdOfVendorsCssFile(page),
      name: encodeIdOfVendorsCssFile(page),
      content: '',
    };

    const allVendorsJs: Exclude<PageFileExpect, LiquidFile> = {
      type: 'vendorsJs',
      id: encodeIdOfVendorsJsFile(page),
      name: encodeIdOfVendorsJsFile(page),
      content: '',
    };

    files.reduce((result, file) => {
      const { content, type, section } = file;
      const isNeedLinkListSetting = sectionsWithLinkListSetting.findIndex(item => item.commandId === section?.commandId) !== -1;
      // Gộp js
      if (type === 'globalJs của page - bảo gồm globalJs và lazyLoadJs của page') {
        allJs.globalJsOfPage.content += content;
      }
      if (type === 'js của page - bao gồm js của các section') {
        allJs.jsOfSectionsInPage.content += content;
      }
      // Gộp css
      if (type === 'globalCss của page - chỉ bảo gồm globalCss của page') {
        allCss.globalCssOfPage.content += content;
      }
      if (
        type ===
        'css của page - bao gồm css của các section "thuộc page", css inline của các sections "thuộc page", không bao gồm atomic css của các sections "thuộc page"'
      ) {
        allCss.cssOfSectionsInPage.content += content;
      }
      // Gộp vendorsCss
      if (type === 'vendors css tổng của page') {
        allVendorsCss.content += content;
      }
      // Gộp vendorsJs
      if (type === 'vendors js tổng của page') {
        allVendorsJs.content += content;
      }
      // Các file liquids
      if (type === 'liquid của page - bao gồm liquid của section bình thường[đã thế megamenu tag placeholder]' && section) {
        const shopify_converter_result = sectionConverter({
          themeSettings: theme.themeSettings,
          lastLiquid: content,
          section,
          isPreview: false,
          isExportForEnvato,
          isNeedLinkListSetting,
          isNeedReduceTotalLinesOfCode,
        });
        allLiquids.push({
          content: shopify_converter_result.liquid,
          id: encodeIdOfLiquidFile(section),
          parentId: !isExportForEnvato ? undefined : 'parentCommandId' in section ? section.parentCommandId ?? undefined : undefined,
          name: encodeIdOfLiquidFile(section),
          type: 'section',
          label: section.label,
          section,
          shopify_converter_result: {
            block_order: shopify_converter_result.block_order,
            blocks: shopify_converter_result.blocks,
            settings: shopify_converter_result.settings,
          },
        });
      }
      return result;
    }, '');

    let parameters: PagesParamsExpect = {
      pageType: type,
      pageName: pageSettings.generalSettings.label,
      pageCommandId: commandId,
      eventId: '',
      eventType: 'Đồng bộ theme / Ghi page',
      isIncludeThemeHeaderFooter: builderType === 'theme' ? true : headerFooterEnabled,
      isEnvatoTheme: isExportForEnvato,
      isSyncTurnedOff: null,
      assets: {
        files: [
          allVendorsJs,
          allVendorsCss,
          allCss.cssOfSectionsInPage,
          allCss.globalCssOfPage,
          allJs.globalJsOfPage,
          allJs.jsOfSectionsInPage,
          ...allLiquids.map(liquidFile => omit(['section'], liquidFile)),
        ],
      },
    };

    /** Chỉ có "page" mới NÊN update SEO */
    if (type === 'page') {
      parameters = {
        ...parameters,
        page: {
          // Với regular page handle rỗng là không thể
          handle: handle ? handle : undefined,
          isPublished: entityVariant !== 'Client' ? true : enable, // Vá tạm lỗi khi tạo page blank và save mất trường "enable"
          pageName: pageSettings.generalSettings.label,
          seo: {
            description: metaDescription,
            title: metaTitle,
            featuredImage: socialShareImage,
          },
        },
      };
    } else if (type === 'article') {
      parameters = {
        ...parameters,
        article: {
          handle: handle ? handle : undefined,
          isApplyToAll: builderType === 'theme' ? true : shopifyPages === 'all',
          isPublished: entityVariant !== 'Client' ? true : enable, // Vá tạm lỗi khi tạo page blank và save mất trường "enable"
          isOverrideIndividualPages,
          pageName: pageSettings.generalSettings.label,
          seo: {
            description: metaDescription,
            title: metaTitle,
            featuredImage: socialShareImage,
          }, // Chỉ có "Page" mới cần update SEO nên đang để "seo: undefined"
          shopifyPages: getShopifyPages(page),
        },
      };
    } else if (type === 'product' || type === 'collection' || type === 'blog') {
      parameters = {
        ...parameters,
        [type]: {
          handle: handle ? handle : undefined,
          isApplyToAll: builderType === 'theme' ? true : shopifyPages === 'all',
          isPublished: entityVariant !== 'Client' ? true : enable, // Vá tạm lỗi khi tạo page blank và save mất trường "enable"
          isOverrideIndividualPages,
          pageName: pageSettings.generalSettings.label,
          seo: undefined, // Chỉ có "Page" mới cần update SEO nên đang để "seo: undefined"
          shopifyPages: getShopifyPages(page),
        },
      };
    } else if (
      [
        'home',
        'cart',
        'pageNotFound',
        'password',
        'search',
        'collections',
        'account',
        'activateAccount',
        'addresses',
        'login',
        'order',
        'register',
        'resetPassword',
        'giftCard',
      ].includes(type)
    ) {
      // const _shopifyRepresentPage = shopifyRepresentPage as HomePageLiquidVariable
      parameters = {
        ...parameters,
        [type]: {
          handle: handle ? handle : undefined,
          isPublished: entityVariant !== 'Client' ? true : enable, // Vá tạm lỗi khi tạo page blank và save mất trường "enable"
          pageName: pageSettings.generalSettings.label,
        },
      };
    } else {
      throw new Error('handleExtractResultForForceSync -> thêm type ');
    }

    return result.concat(parameters);
  }, []);

  // Kết thúc lấy ra page params

  // Lấy ra theme Params
  let globalJsOfTheme = '';
  let globalCssOfTheme = '';
  let vendorsJsOfTheme = '';
  let vendorsCssOfTheme = '';
  let liquidPreloaderOfTheme = '';
  let cssPreloaderOfTheme = '';
  let jsPreloaderOfTheme = '';
  const liquidHeaderSectionsOfTheme: Array<LiquidFile & { section: PageSection }> = [];
  let jsHeaderSectionsOfTheme = '';
  let cssHeaderSectionsOfTheme = '';
  const liquidFooterSectionsOfTheme: Array<LiquidFile & { section: PageSection }> = [];
  let jsFooterSectionsOfTheme = '';
  let cssFooterSectionsOfTheme = '';
  theme.files.forEach(file => {
    const { type, content, section } = file;
    const isNeedLinkListSetting = sectionsWithLinkListSetting.findIndex(item => item.commandId === section?.commandId) !== -1;
    if (type === 'globalJs của theme - bao gồm globalJs của theme') {
      globalJsOfTheme = content;
    }
    if (type === 'globalCss của theme - bao gồm layout, colors, fonts, scss của theme') {
      globalCssOfTheme = content;
    }
    if (type === 'vendors js tổng của theme') {
      vendorsJsOfTheme = content;
    }
    if (type === 'vendors css tổng của theme') {
      vendorsCssOfTheme = content;
    }
    if (type === 'liquid của preloader - cái này được ghi vào theme.*.liquid') {
      liquidPreloaderOfTheme = content;
    }
    if (type === 'js của preloader') {
      jsPreloaderOfTheme = content;
    }
    if (type === 'css của preloader') {
      cssPreloaderOfTheme = content;
    }
    if (type === 'js của các section header') {
      jsHeaderSectionsOfTheme = content;
    }
    if (
      type ===
      'css của các sections thuộc header - chỉ gồm css của các sections thuộc header và css inline của các sections thuộc header, không bao gồm atomic css'
    ) {
      cssHeaderSectionsOfTheme = content;
    }
    if (
      type ===
        'liquid của các section thuộc header - bao gồm liquid của section bình thường[đã thế megamenu tag placeholder] (cái này được ghi vào file header.liquid cái mà được render ở theme.liquid)' &&
      section
    ) {
      const shopify_converter_result = sectionConverter({
        themeSettings: theme.themeSettings,
        lastLiquid: content,
        section,
        isPreview: false,
        isExportForEnvato,
        isNeedLinkListSetting,
        isNeedReduceTotalLinesOfCode,
      });
      liquidHeaderSectionsOfTheme.push({
        id: encodeIdOfLiquidFile(section),
        parentId: !isExportForEnvato ? undefined : 'parentCommandId' in section ? section.parentCommandId ?? undefined : undefined,
        name: encodeIdOfLiquidFile(section),
        type: 'section',
        label: section.label,
        content: shopify_converter_result.liquid,
        section,
        shopify_converter_result: {
          block_order: shopify_converter_result.block_order,
          blocks: shopify_converter_result.blocks,
          settings: shopify_converter_result.settings,
        },
      });
    }
    if (type === 'js của các section footer') {
      jsFooterSectionsOfTheme = content;
    }
    if (
      type ===
      'css của các sections thuộc footer - chỉ gồm css của các sections thuộc footer và css inline của các sections thuộc footer, không bao gồm atomic css'
    ) {
      cssFooterSectionsOfTheme = content;
    }
    if (
      type ===
        'liquid của các section thuộc footer - bao gồm liquid của section bình thường[đã thế megamenu tag placeholder] (cái này được ghi vào file footer.liquid cái mà được render ở theme.liquid)' &&
      section
    ) {
      const shopify_converter_result = sectionConverter({
        themeSettings: theme.themeSettings,
        lastLiquid: content,
        section,
        isPreview: false,
        isExportForEnvato,
        isNeedLinkListSetting,
        isNeedReduceTotalLinesOfCode,
      });
      liquidFooterSectionsOfTheme.push({
        id: encodeIdOfLiquidFile(section),
        parentId: !isExportForEnvato ? undefined : 'parentCommandId' in section ? section.parentCommandId ?? undefined : undefined,
        name: encodeIdOfLiquidFile(section),
        type: 'section',
        label: section.label,
        section,
        content: shopify_converter_result.liquid,
        shopify_converter_result: {
          block_order: shopify_converter_result.block_order,
          blocks: shopify_converter_result.blocks,
          settings: shopify_converter_result.settings,
        },
      });
    }
  });

  if (isExportForEnvato) {
    pagesParams.forEach(pageParams => {
      pageParams.assets?.files?.forEach(file => {
        if (file) {
          if (file.id === encodeIdForAllPageSectionsOfCssFile({ commandId: pageParams.pageCommandId ?? v4() })) {
            globalCssOfTheme += file.content;
            file.content = '';
          }
          if (file.id === encodeIdForAllPageSectionsOfJsFile({ commandId: pageParams.pageCommandId ?? v4() })) {
            globalJsOfTheme += file.content;
            file.content = '';
          }
        }
      });
    });
  }

  const themeParams: ThemeParamsExpect = {
    themeName: theme.themeName,
    eventId: '',
    eventType: 'Đồng bộ theme / Ghi global (sinh ra từ themeSettings)',
    content: `
      ${globalJsOfTheme}
      ${globalCssOfTheme}
      ${vendorsJsOfTheme}
      ${vendorsCssOfTheme}
      ${liquidPreloaderOfTheme}
      ${cssPreloaderOfTheme}
      ${jsPreloaderOfTheme}
    `,
  };
  // Kết thúc lấy ra themeParams

  // Lấy ra addonsEnablePositionParams
  const addonsEnablePositionParams: AddonsParamsExpect = {
    eventId: '',
    eventType: 'Đồng bộ theme / Ghi addon enable position',
    addonFiles: filesOfAddons.reduce<Exclude<AddonsParamsExpect['addonFiles'], undefined>>((res, item) => {
      if (item.type === 'addon enable position') {
        return res.concat({
          id: item.id,
          css: item.css.content,
          js: item.js.content,
          liquid: item.liquid.content,
        });
      }
      return res;
    }, []),
  };
  // Kết thúc lấy ra addonsEnablePositionParams

  // Lấy ra addon disable position
  const addonsDisablePositionParams: AddonsParamsExpect & { themeName: string } = {
    eventId: '',
    eventType: 'Đồng bộ theme / Ghi addon disable position',
    themeName,
    addonFiles: filesOfAddons.reduce<Exclude<AddonsParamsExpect['addonFiles'], undefined>>((res, item) => {
      if (item.type === 'addon disable position') {
        return res.concat({
          id: item.id,
          css: item.css.content,
          js: item.js.content,
          liquid: item.liquid.content,
        });
      }
      return res;
    }, []),
  };
  // Kết thúc lấy ra addon disable position

  // Lấy ra syncTranslation params  const languages = globalTranslations.translation;
  const syncTranslationsParams: SyncTranslationParamsExpect = handleGetTranslationParams({
    eventId: '',
    themeSettings: theme.themeSettings,
    eventType: 'Đồng bộ theme / Sync translation',
  });
  // Kết thúc Lấy ra syncTranslation params

  // Lấy ra headerParams và return kết quả cuối cùng
  const headerParams: HeaderParamsExpect = {
    eventId: '',
    eventType: 'Đồng bộ theme / Ghi header',
    assets: {
      files: [
        ...liquidHeaderSectionsOfTheme,
        {
          type: 'css',
          content: cssHeaderSectionsOfTheme,
          id: 'css_header',
          name: 'css_header',
        },
        {
          type: 'js',
          content: jsHeaderSectionsOfTheme,
          id: 'js_header',
          name: 'js_header',
        },
      ],
    },
  };
  const footerParams: FooterParamsExpect = {
    eventId: '',
    eventType: 'Đồng bộ theme / Ghi footer',
    assets: {
      files: [
        ...liquidFooterSectionsOfTheme,
        {
          type: 'css',
          id: 'css_footer',
          name: 'css_footer',
          content: cssFooterSectionsOfTheme,
        },
        {
          type: 'js',
          id: 'js_footer',
          name: 'js_footer',
          content: jsFooterSectionsOfTheme,
        },
      ],
    },
  };

  const themeSchemaNSettingsParams: SyncThemeSchemaNSettings = {
    eventId: undefined,
    eventType: 'Đồng bộ theme / Ghi file configure theme',
    data: isExportForEnvato
      ? themeSettingsConverter({
          cssVariables: data.theme.themeSettings.cssVariables,
          generalSettings: data.theme.themeSettings.generalSettings,
          layoutSettings: data.theme.themeSettings.layoutSettings,
          colorSchemes: data.theme.themeSettings.colorSchemes,
          themeAuthor: undefined,
          themeDocumentUrl: themeProduct?.documentUrl,
          themeName: themeProduct?.label,
          themeSupportUrl: undefined,
          themeVersion: undefined,
          themeSlug: themeProduct ? getThemeSlug(themeProduct.label) : undefined,
        })
      : undefined,
  };

  return {
    pagesParams,
    themeParams,
    addonsEnablePositionParams,
    addonsDisablePositionParams,
    syncTranslationsParams,
    headerParams,
    footerParams,
    themeSchemaNSettingsParams,
  };
};
