import { AddonOfTheme_Atom_N_Client } from 'types/Addons';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

interface GetAddonsOfThemeDraft {
  addonCommandIds: string[];
}

export const getAddonsOfThemeDraft = async ({ addonCommandIds }: GetAddonsOfThemeDraft): Promise<AddonOfTheme_Atom_N_Client[]> => {
  if (!addonCommandIds.length) {
    return [];
  }
  const { role } = getUserInfo();
  if (role === 'admin') {
    const responses = await themeApis.atom.adminApi.addon.getAddonsOfDraft({ commandIds: addonCommandIds });
    return responses.info;
  }
  if (role === 'dev' || role === 'support') {
    const responses = await themeApis.atom.devApi.addon.getAddonsOfDraft({ commandIds: addonCommandIds });
    return responses.info;
  }
  throw new RoleException();
};
