import { ActionTypes, createReducer, handleAction } from 'wiloke-react-core/utils';
import { Record, SectionInfo } from '../types/Record';
import * as actions from './actions';

interface State {
  statusRequest: Status;
  statusGetMetaDataOfRecord: Status;
  records: Record[];
  totalRecords: number;

  queueDeleting: Array<Record['generalInfomation']['firebaseTrackingData']['atomCommandId']>;
  queueDeleteFailed: Array<Record['generalInfomation']['firebaseTrackingData']['atomCommandId']>;

  recordInteracting: Record | null;

  queueUpgrading: Array<SectionInfo['commandId']>;
  queueUpgradeFailed: Array<SectionInfo['commandId']>;
}
type Actions = ActionTypes<typeof actions>;

const defaultState: State = {
  records: [],
  totalRecords: 0,
  statusGetMetaDataOfRecord: 'idle',
  statusRequest: 'idle',

  queueDeleteFailed: [],
  queueDeleting: [],

  recordInteracting: null,

  queueUpgrading: [],
  queueUpgradeFailed: [],
};

export const reducerListingSectionsUpdatable = createReducer<State, Actions>(defaultState, [
  /** Sync */
  handleAction('@ListingUpdatable/setRecordInteracting', ({ state, action }) => {
    return {
      ...state,
      recordInteracting: action.payload,
    };
  }),

  /** Async */
  // Get records with general infomation
  handleAction('@ListingUpdatable/getGeneralInfomationOfRecordsRequest', ({ state }) => {
    return {
      ...state,
      statusRequest: 'loading',
    };
  }),
  handleAction('@ListingUpdatable/getGeneralInfomationOfRecordsSuccess', ({ state, action }) => {
    return {
      ...state,
      statusRequest: 'success',
      totalRecords: action.payload.totalRecords,
      records: action.payload.recordsPaginated.map(item => ({
        generalInfomation: item,
      })),
    };
  }),
  handleAction('@ListingUpdatable/getGeneralInfomationOfRecordsFailure', ({ state }) => {
    return {
      ...state,
      statusRequest: 'failure',
    };
  }),

  // Get metadata of record
  handleAction('@ListingUpdatable/getMetaDataOfRecordRequest', ({ state }) => {
    return {
      ...state,
      statusGetMetaDataOfRecord: 'loading',
    };
  }),
  handleAction('@ListingUpdatable/getMetaDataOfRecordSuccess', ({ state, action }) => {
    const { data, sectionAtomCommandId } = action.payload;
    return {
      ...state,
      statusGetMetaDataOfRecord: 'success',
      recordInteracting:
        state.recordInteracting?.generalInfomation.firebaseTrackingData.atomCommandId === sectionAtomCommandId
          ? { ...state.recordInteracting, metaData: data }
          : state.recordInteracting,
      records: state.records.map(record => {
        if (record.generalInfomation.firebaseTrackingData.atomCommandId === sectionAtomCommandId) {
          return {
            ...record,
            metaData: data,
          };
        }
        return record;
      }),
    };
  }),
  handleAction('@ListingUpdatable/getMetaDataOfRecordFailure', ({ state }) => {
    return {
      ...state,
      statusGetMetaDataOfRecord: 'failure',
    };
  }),

  // Delete record
  handleAction('@ListingUpdatable/deleteRecordRequest', ({ state, action }) => {
    const { record } = action.payload;
    const recordId = record.generalInfomation.firebaseTrackingData.atomCommandId;
    return {
      ...state,
      queueDeleting: state.queueDeleting.concat(recordId),
      queueDeleteFailed: state.queueDeleteFailed.filter(item => item !== recordId),
    };
  }),
  handleAction('@ListingUpdatable/deleteRecordSuccess', ({ state, action }) => {
    const { record } = action.payload;
    const recordId = record.generalInfomation.firebaseTrackingData.atomCommandId;
    return {
      ...state,
      queueDeleting: state.queueDeleting.filter(item => item !== recordId),
      records: state.records.filter(item => {
        return item.generalInfomation.firebaseTrackingData.atomCommandId !== recordId;
      }),
    };
  }),
  handleAction('@ListingUpdatable/deleteRecordFailure', ({ state, action }) => {
    const { record } = action.payload;
    const recordId = record.generalInfomation.firebaseTrackingData.atomCommandId;
    return {
      ...state,
      queueDeleteFailed: state.queueDeleteFailed.concat(recordId),
      queueDeleting: state.queueDeleting.filter(item => item !== recordId),
    };
  }),

  // Upgrade one of section in a record
  handleAction('@ListingUpdatable/upgradeSectionRequest', ({ state, action }) => {
    const { sectionInfo } = action.payload;
    return {
      ...state,
      queueUpgrading: state.queueUpgrading.concat(sectionInfo.commandId),
      queueUpgradeFailed: state.queueUpgradeFailed.filter(item => item !== sectionInfo.commandId),
    };
  }),
  handleAction('@ListingUpdatable/upgradeSectionSuccess', ({ state, action }) => {
    const { sectionAtomCommandId, latestSectionInfo } = action.payload;
    return {
      ...state,
      queueUpgrading: state.queueUpgrading.filter(item => item !== latestSectionInfo.commandId),
      recordInteracting:
        state.recordInteracting?.metaData && state.recordInteracting?.generalInfomation.firebaseTrackingData.atomCommandId === sectionAtomCommandId
          ? {
              ...state.recordInteracting,
              metaData: {
                ...state.recordInteracting.metaData,
                sectionsInfo: state.recordInteracting.metaData.sectionsInfo.map(item => {
                  if (item.commandId === latestSectionInfo.commandId) {
                    return latestSectionInfo;
                  }
                  return item;
                }),
              },
            }
          : state.recordInteracting,
      records: state.records.map(record => {
        if (record.generalInfomation.firebaseTrackingData.atomCommandId === sectionAtomCommandId) {
          return {
            ...record,
            metaData: record.metaData
              ? {
                  ...record.metaData,
                  sectionsInfo: record.metaData.sectionsInfo.map(item => {
                    if (item.commandId === latestSectionInfo.commandId) {
                      return latestSectionInfo;
                    }
                    return item;
                  }),
                }
              : record.metaData,
          };
        }
        return record;
      }),
    };
  }),
  handleAction('@ListingUpdatable/upgradeSectionFailure', ({ state, action }) => {
    const { sectionInfo } = action.payload;
    return {
      ...state,
      queueUpgradeFailed: state.queueUpgradeFailed.concat(sectionInfo.commandId),
      queueUpgrading: state.queueUpgrading.filter(item => item !== sectionInfo.commandId),
    };
  }),
]);
