import oops from 'assets/sorry.png';
import ShakeHand from 'assets/shake-hand.png';
import { Button } from '@wiloke/ui';
import { View, Text, Styles } from 'wiloke-react-core';
import { CrispChat } from 'containers/CrispChat/CrispChat';
import { CSSProperties, FC, MouseEventHandler, useEffect, useState } from 'react';
import Box from 'components/FieldBox';

const styles = {
  container: (): Styles => ({
    maxWidth: '900px',
    minWidth: '200px',
    margin: 'auto',
    padding: '100px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100vh',
  }),
  title: (): Styles => ({
    margin: '0',
    color: '#048395',
    fontWeight: '800',
  }),
  description: (): Styles => ({
    padding: '0 160px',
    textAlign: 'center',
  }),
  buttonContainer: (): Styles => ({
    marginTop: '20px',
    marginBottom: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    maxWidth: '500px',
  }),
  buttonContent: (): Styles => ({
    fontSize: '18px',
    padding: '16px 30px',
  }),
  buttonIcon: (): Styles => ({
    marginLeft: '8px',
    verticalAlign: 'text-bottom',
  }),
  formContainer: (): Styles => ({
    position: 'relative',
    padding: 0,
    overflow: 'hidden',
    width: '100%',
    marginBottom: '10px',
    borderRadius: '6px',
  }),
  formInput: {
    display: 'block',
    padding: '10px',
    width: '100%',
    boxShadow: 'none',
    border: 'none',
    outline: 'none',
    height: '54px',
  } as CSSProperties,
  formButton: (): Styles => ({
    position: 'absolute',
    right: '4px',
    top: '50%',
    transform: 'translateY(-50%)',
  }),
};

interface Props {
  onClick?: MouseEventHandler;
}

export const ErrorWithCrispChat: FC<Props> = ({ onClick }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <View css={styles.container}>
      <img src={oops} />
      <Text tagName="h1" size={38} fontFamily="quaternary" css={styles.title}>
        We apologize for the inconvenience!
      </Text>
      <Text size={18} fontFamily="secondary" css={styles.description}>
        It appears that the Login Form has expired. Please <View tagName="strong">log in again</View>. Alternatively, you can{' '}
        <View tagName="strong">chat with us</View> so that our support team can resolve the issue for you as soon as possible.
      </Text>
      <View css={styles.buttonContainer}>
        <Box css={styles.formContainer} borderColor="gray3" tagName="form" action="https://login.vedabuilder.com" method="GET" radius={4}>
          <input name="shop" style={styles.formInput} placeholder="eg: your_shop (No need for the suffix .myshopkit...)" defaultValue="" />
          <input type="text" hidden value="requestShopifyCode" name="action" />
          <Button
            loading={loading}
            type="submit"
            radius={6}
            size="small"
            css={styles.formButton}
            onClick={() => {
              setLoading(true);
            }}
          >
            Re-login
          </Button>
        </Box>
        <CrispChat>
          <Button
            backgroundColor="secondary"
            radius={6}
            onClick={event => {
              onClick?.(event);
            }}
            css={styles.buttonContent}
          >
            Chat With Us
            <View tagName="img" src={ShakeHand} alt="" css={styles.buttonIcon} />
          </Button>
        </CrispChat>
      </View>
    </View>
  );
};
