import { actionShopDevAllowable, actionGetOptionsSettings } from 'containers/Admin/GeneralSettingsManagement/store/actions';
import { ActionTypes, createSlice, handleAction } from 'wiloke-react-core/utils';

type ExtraActions = ActionTypes<typeof actionShopDevAllowable | typeof actionGetOptionsSettings>;

interface State {
  shopDevAllowableStatus: Status;
  getStatus: Status;
  shopDevAllowable: 'yes' | 'no';
}

export const sliceSettingManager = createSlice<State, any, ExtraActions>({
  name: '@SettingManager',
  initialState: {
    shopDevAllowableStatus: 'idle',
    getStatus: 'idle',
    shopDevAllowable: 'no',
  },
  reducers: [],
  extraReducers: [
    handleAction('@SettingManager/actionShopDevAllowable/request', ({ state }) => {
      state.shopDevAllowableStatus = 'loading';
    }),
    handleAction('@SettingManager/actionShopDevAllowable/success', ({ state, action }) => {
      state.shopDevAllowableStatus = 'success';
      state.shopDevAllowable = action.payload.shopDevAllowable;
    }),
    handleAction('@SettingManager/actionShopDevAllowable/failure', ({ state }) => {
      state.shopDevAllowableStatus = 'failure';
    }),
    handleAction('@SettingManager/actionGetOptionsSettings/request', ({ state }) => {
      state.getStatus = 'loading';
    }),
    handleAction('@SettingManager/actionGetOptionsSettings/success', ({ state, action }) => {
      state.getStatus = 'success';
      state.shopDevAllowable = action.payload.shopDevAllowable;
    }),
    handleAction('@SettingManager/actionGetOptionsSettings/failure', ({ state }) => {
      state.getStatus = 'failure';
    }),
  ],
});

export const settingsManagerSelector = (state: AppState) => state.settingsManager;
