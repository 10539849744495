import { AdvancedIcon, BusinessIcon } from 'components/PlanBadge';
import { ReactNode } from 'react';
import { i18n } from 'translation';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { FontAwesomeBrandsName, FontAwesomeName, FontAwesomeType, View } from 'wiloke-react-core';

export type SettingValue = 'theme' | 'page';
export type ThemeSettingValue = 'colors' | 'fonts' | 'layout' | 'general' | 'vendors' | 'scss' | 'js' | 'json' | 'password' | 'colorSchemes';
export type PageSettingValue = 'general' | 'vendors' | 'scss' | 'js' | 'import';
export type DashboardPageSettingValue = 'general' | 'vendors' | 'scss' | 'js';

export interface Setting<T> {
  label: ReactNode;
  description?: string;
  value: T;
  icon?: FontAwesomeName | FontAwesomeBrandsName;
  iconType?: FontAwesomeType;
}

export const settings: Setting<SettingValue>[] = [
  {
    label: i18n.t('general.settings', { text: i18n.t('general.theme'), textTransform: 'capitalize' }),
    value: 'theme',
    icon: 'theater-masks',
  },
  {
    label: i18n.t('general.settings', { text: i18n.t('general.page'), textTransform: 'capitalize' }),
    value: 'page',
    icon: 'layer-plus',
  },
];

export const themeSettings = (v2 = false): Setting<ThemeSettingValue>[] => {
  const { plan } = getUserInfo();
  return [
    {
      icon: 'store',
      label: 'Store Details',
      description: 'Manage your store password',
      value: 'password',
    },
    {
      icon: 'cog',
      label: i18n.t('builderPage.theme_settings.general.title'),
      description: i18n.t('builderPage.theme_settings.general.text'),
      value: 'general',
    },
    {
      icon: 'layer-group',
      label: i18n.t('builderPage.theme_settings.layout.title'),
      description: i18n.t('builderPage.theme_settings.layout.text'),
      value: 'layout',
    },
    v2
      ? {
          icon: 'palette',
          label: i18n.t('builderPage.theme_settings.color_schemes.title'),
          description: i18n.t('builderPage.theme_settings.color_schemes.text'),
          value: 'colorSchemes',
        }
      : {
          icon: 'palette',
          label: i18n.t('builderPage.theme_settings.colors.title'),
          description: i18n.t('builderPage.theme_settings.colors.text'),
          value: 'colors',
        },
    {
      icon: 'font',
      label: i18n.t('builderPage.theme_settings.fonts.title'),
      description: i18n.t('builderPage.theme_settings.fonts.text'),
      value: 'fonts',
    },
    {
      icon: 'css3',
      iconType: 'fab',
      label: (
        <View css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
          {i18n.t('builderPage.theme_settings.scss.title')}
          {plan.planHandle === 'free' && (
            <View
              css={{
                width: '20px',
                height: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              radius={4}
              backgroundColor="tertiary"
            >
              <BusinessIcon />
            </View>
          )}
        </View>
      ),
      description: i18n.t('builderPage.theme_settings.scss.text'),
      value: 'scss',
    },
    {
      icon: 'js',
      iconType: 'fab',
      label: (
        <View css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
          {i18n.t('builderPage.theme_settings.js.title')}
          {plan.planHandle === 'free' && (
            <View
              css={{
                width: '20px',
                height: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              radius={4}
              backgroundColor="tertiary"
            >
              <BusinessIcon />
            </View>
          )}
        </View>
      ),
      description: i18n.t('builderPage.theme_settings.js.text'),
      value: 'js',
    },
    {
      icon: 'language',
      iconType: 'far',
      label: 'Translation',
      description: 'Add global translation for theme',
      value: 'json',
    },
    {
      icon: 'boxes',
      label: i18n.t('builderPage.theme_settings.vendors.title'),
      description: i18n.t('builderPage.theme_settings.vendors.text'),
      value: 'vendors',
    },
  ];
};

export const pageSettings = (): Setting<PageSettingValue>[] => {
  const { plan } = getUserInfo();
  return [
    {
      icon: 'cog',
      label: i18n.t('builderPage.page_settings.general.title'),
      description: i18n.t('builderPage.page_settings.general.text'),
      value: 'general',
    },
    {
      icon: 'boxes',
      label: i18n.t('builderPage.page_settings.vendors.title'),
      description: i18n.t('builderPage.page_settings.vendors.text'),
      value: 'vendors',
    },
    {
      icon: 'css3',
      iconType: 'fab',
      label: (
        <View css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
          {i18n.t('builderPage.page_settings.scss.title')}
          {plan.planHandle === 'free' && (
            <View
              css={{
                width: '20px',
                height: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              radius={4}
              backgroundColor="tertiary"
            >
              <BusinessIcon />
            </View>
          )}
        </View>
      ),

      description: i18n.t('builderPage.page_settings.scss.text'),
      value: 'scss',
    },
    {
      icon: 'js',
      iconType: 'fab',
      label: (
        <View css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          {i18n.t('builderPage.page_settings.js.title')}
          {plan.planHandle === 'free' && (
            <View
              css={{
                width: '20px',
                height: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              radius={4}
              backgroundColor="tertiary"
            >
              <BusinessIcon />
            </View>
          )}
        </View>
      ),
      description: i18n.t('builderPage.page_settings.js.text'),
      value: 'js',
    },
    {
      icon: 'file-import',
      label: (
        <View css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          {i18n.t('builderPage.page_settings.import.title')}

          {plan.planHandle === 'free' && (
            <View
              css={{
                width: '20px',
                height: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              radius={4}
              backgroundColor="quaternary"
            >
              <AdvancedIcon />
            </View>
          )}
        </View>
      ),
      description: i18n.t('builderPage.page_settings.import.text'),
      value: 'import',
    },
  ];
};
export const preloaders = [
  {
    id: '1',
    backgroundColor: '#fff',
    color: '#43eba6',
  },
  {
    id: '2',
    backgroundColor: '#fff',
    color: '#a843eb',
  },
  {
    id: '3',
    backgroundColor: '#fff',
    color: '#f77772',
  },
  {
    id: '4',
    backgroundColor: '#fff',
    color: '#f7d872',
  },
];

export const dashboardPageSettings: Setting<DashboardPageSettingValue>[] = [
  {
    icon: 'cog',
    label: i18n.t('builderPage.page_settings.general.title'),
    description: i18n.t('builderPage.page_settings.general.text'),
    value: 'general',
  },
  {
    icon: 'boxes',
    label: i18n.t('builderPage.page_settings.vendors.title'),
    description: i18n.t('builderPage.page_settings.vendors.text'),
    value: 'vendors',
  },
  {
    icon: 'css3',
    iconType: 'fab',
    label: i18n.t('builderPage.page_settings.scss.title'),
    description: i18n.t('builderPage.page_settings.scss.text'),
    value: 'scss',
  },
  {
    icon: 'js',
    iconType: 'fab',
    label: i18n.t('builderPage.page_settings.js.title'),
    description: i18n.t('builderPage.page_settings.js.text'),
    value: 'js',
  },
];
