import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import { ProductSection } from 'types/Sections';
import fetchAPI from 'utils/functions/fetchAPI';
import { getApiTracking } from 'utils/functions/getApiTracking';

interface GetClient {
  /** List CommandId của section product cần lấy dữ liệu */
  commandIds: string[];
  cacheVersion: number | null;
}
interface ResponseSuccess {
  info: ProductSection[];
  message: string;
}

/** API được client sử dụng để lấy dữ liệu của product section */
export const searchClients = async ({ commandIds, cacheVersion }: GetClient) => {
  const url = getApiTracking({
    defaultBaseUrl: `${configureApp.clientBaseUrl}/me/${configureApp.endpoint.mega_menus}/search`,
    trackingEndpoint: `${configureApp.endpoint.mega_menus}/search`,
  });

  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url,
    params: {
      commandIds,
      cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
    },
  });

  return response.data;
};
