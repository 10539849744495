import { all, call, spawn, delay } from '@redux-saga/core/effects';
import { sagasChooseTemplate } from 'containers/ChooseTemplate';
import sagaImageGallery from 'components/ChooseImage/sagas/sagaStockPage';
import { sagasBlankPage } from 'containers/Admin/PageBuilder/BlankPage/stores';
import { sagasThemeTemplates } from 'containers/Admin/ThemeBuilder/ThemeTemplates';
import { sagasBuilderPage } from 'containers/BuilderPage';
import { sagasTemplatePage } from 'containers/Admin/PageBuilder/TemplatesPage';
import { sagasPresetStyle } from 'containers/Admin/PresetStylesPage';
import { sagasMegaMenu } from 'containers/BuilderPage/components/DraggableMenu';
import { sagasProductPage } from 'containers/Admin/PageBuilder/ProductsPage';
import { sagasCollectionPage } from 'containers/Admin/PageBuilder/CollectionPage';
import { sagasArticlePage } from 'containers/Admin/PageBuilder/ArticlesPage';
import { sagasDashboardPageSettings } from 'containers/Admin/PageBuilder/DashboardPageSettings';
import { sagaThemeBuilder } from 'containers/Admin/ThemeBuilder';
import { sagasHomePage } from 'containers/Admin/PageBuilder/HomePage/store';
import { sagasPageManagement } from 'containers/Admin/Management/Pages';
import { sagasThemeManagement } from 'containers/Admin/Management/Themes';
import { sagasPlanManagement } from 'containers/Admin/PlanManagement';
import { sagasLiquidVariables } from './sagas/liquid/sagasLiquidVariables';
import sagasGlobal from './sagas';
import { sagaShopify } from './sagas/shopify/sagaShopify';
import { sagasVersion } from './sagas/versions';
import { sagasAuth } from './global/auth';
import { sagasAuthors } from './global/authors';
import { sagasLiquidSnippets } from './global/globalSnippets/sagas';
import { sagaPageCounter } from './global/pageCounter';
import { sagaBlogPage } from 'containers/Admin/PageBuilder/BlogPage';
import { sagasPurchaseCodesPage } from 'containers/Admin/PurchaseCodePage';
import { sagaAppSettings } from './global/appSettings/sagas';
import { sagaRecommendApps } from 'containers/Admin/RightBar/store/sagas';
import { sagasCustomerPages } from 'containers/Admin/PageBuilder/CustomerPages';
import { sagasGeneralManager } from 'containers/Admin/GeneralSettingsManagement/store';
import { sagasThemeToFileForSync } from 'packages/ExtractThemeToFileForSync';
import { sagasSyncThemeByResultOfExtractThemeToFileForSync } from 'packages/SyncThemeByResultOfExtractThemeToFileForSync';
import { sagaPartnerApps } from 'containers/Admin/AppsPartner/store/saga';
import { sagasLanguage } from 'store/global/globalTranslation/sagas/sagas';
import { sagasListingSectionsUpdatable } from 'packages/ListingSectionsUpdatable';
import { sagasPartners } from 'containers/Admin/PartnerManagement/store/sagas';
import { sagaImportFile } from 'store/global/importCSV/sagas/sagas';
import { sagasPageUseCase } from 'containers/Admin/Management/Pages/store/sagas/use-cases/sagas';
import { sagaTemplatePopup } from 'containers/Admin/PageBuilder/TemplatesInPopup';

const sagas = [
  ...sagasGlobal,
  ...sagasBuilderPage,
  ...sagasBlankPage,
  ...sagasChooseTemplate,
  ...sagaImageGallery,
  ...sagaShopify,
  ...sagasLiquidVariables,
  ...sagasThemeTemplates,
  ...sagasVersion,
  ...sagasTemplatePage,
  ...sagasPresetStyle,
  ...sagasMegaMenu,
  ...sagasProductPage,
  ...sagasCollectionPage,
  ...sagasArticlePage,
  ...sagasAuth,
  ...sagasDashboardPageSettings,
  ...sagaThemeBuilder,
  ...sagasHomePage,
  ...sagasPageManagement,
  ...sagasThemeManagement,
  ...sagasAuthors,
  ...sagasPlanManagement,
  ...sagasLiquidSnippets,
  ...sagaPageCounter,
  ...sagaBlogPage,
  ...sagasPurchaseCodesPage,
  ...sagaAppSettings,
  ...sagaRecommendApps,
  ...sagasCustomerPages,
  ...sagasGeneralManager,
  ...sagasThemeToFileForSync,
  ...sagasSyncThemeByResultOfExtractThemeToFileForSync,
  ...sagaPartnerApps,
  ...sagasLanguage,
  ...sagasListingSectionsUpdatable,
  ...sagasPartners,
  ...sagaImportFile,
  ...sagasPageUseCase,
  ...sagaTemplatePopup,
];

// https://github.com/redux-saga/redux-saga/issues/760#issuecomment-273737022
const makeRestartable = (saga: any) => {
  return function*() {
    yield spawn(function*() {
      while (true) {
        try {
          yield call(saga);
          console.error('unexpected root saga termination. The root sagas are supposed to be sagas that live during the whole app lifetime!', saga);
        } catch (e) {
          console.error('Saga error, the saga will be restarted', e);
        }
        yield delay(1000); // Avoid infinite failures blocking app TODO use backoff retry policy...
      }
    });
  };
};

const rootSagas = sagas.map(makeRestartable);

export default function* root() {
  yield all(rootSagas.map(call));
}
