import { combineReducers } from 'redux';
import { reducerBlankPage } from './PageBuilder/BlankPage/stores';
import { reducerThemeTemplates } from './ThemeBuilder/ThemeTemplates';
import { sliceTemplatePage } from './PageBuilder/TemplatesPage';
import { sliceProductPage } from './PageBuilder/ProductsPage';
import { sliceCollectionPage } from './PageBuilder/CollectionPage';
import { sliceArticlePage } from './PageBuilder/ArticlesPage';
import { dashboardPageSettingsSlice } from './PageBuilder/DashboardPageSettings/slice';
import { sliceThemeDashboardSettings } from './ThemeBuilder/ThemeSettings/sliceThemeSettingsDashboard';
import { sliceHomePage } from './PageBuilder/HomePage/store';
import { sliceThemeDashboard } from './ThemeBuilder/ThemeDashboard/slice/sliceThemeDashboard';
import { slicePagesAtom } from './Management/Pages/store/reducers/slicePagesAtom';
import { slicePagesDraft } from './Management/Pages/store/reducers/slicePagesDraft';
import { slicePagesProduct } from './Management/Pages/store/reducers/slicePagesProduct';
import { sliceThemesAtom } from './Management/Themes/store/reducers/sliceThemesAtom';
import { slicePagesTemplate } from './Management/Themes/store/reducers/slicePagesTemplate';
import { sliceThemesDraft } from './Management/Themes/store/reducers/sliceThemesDraft';
import { sliceThemesProduct } from './Management/Themes/store/reducers/sliceThemesProduct';
import { sliceBlogPage } from './PageBuilder/BlogPage';
import { sliceRecommendApps } from './RightBar/store/sliceRecommend';
import { sliceTrashTheme } from './Management/Themes/store/reducers/sliceTrashTheme';
import { sliceCustomerPages } from 'containers/Admin/PageBuilder/CustomerPages';
import { partnerAppsSlice } from 'containers/Admin/AppsPartner/store/partnerAppsSlice';
import { slicePageUseCase } from 'containers/Admin/Management/Pages/store/reducers/slicePageUseCase';
import { reducerTemplatePopup } from 'containers/Admin/PageBuilder/TemplatesInPopup';

const adminDashboardReducers = combineReducers({
  pageBuilder: combineReducers({
    blankPage: reducerBlankPage,
    templates: sliceTemplatePage.reducer,
    productPage: sliceProductPage.reducer,
    collectionPage: sliceCollectionPage.reducer,
    articlePage: sliceArticlePage.reducer,
    dashboardPageSettings: dashboardPageSettingsSlice.reducer,
    homePage: sliceHomePage.reducer,
    blogPage: sliceBlogPage.reducer,
    customerPages: sliceCustomerPages.reducer,
    templatesInPopup: reducerTemplatePopup,
  }),
  themeBuilder: combineReducers({
    templates: reducerThemeTemplates,
    themeSettings: sliceThemeDashboardSettings.reducer,
    themeDashboard: sliceThemeDashboard.reducer,
  }),
  pagesDraft: slicePagesDraft.reducer,
  pagesAtom: slicePagesAtom.reducer,
  pagesProduct: slicePagesProduct.reducer,
  pagesTemplate: slicePagesTemplate.reducer,
  themesAtom: sliceThemesAtom.reducer,
  themesDraft: sliceThemesDraft.reducer,
  themeTrash: sliceTrashTheme.reducer,
  themesProduct: sliceThemesProduct.reducer,
  recommendApps: sliceRecommendApps.reducer,
  partnerApps: partnerAppsSlice.reducer,
  pageUseCase: slicePageUseCase.reducer,
});

export { adminDashboardReducers };
