import { put, retry, takeLatest } from 'redux-saga/effects';
import { addonService } from 'services/AddonService';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { createDraftAddonsCategory, getDraftAddonsCategory } from '../../actions';

function* handleGetCategories(_: ReturnType<typeof getDraftAddonsCategory.request>) {
  try {
    const response: Awaited<ReturnType<typeof addonService.categories.getCategoriesOfDraft>> = yield retry(
      3,
      1000,
      addonService.categories.getCategoriesOfDraft,
    );

    yield put(getDraftAddonsCategory.success({ data: response }));
  } catch {
    yield put(getDraftAddonsCategory.failure(undefined));
  }
}

export function* watchGetDraftAddonCategory() {
  yield takeLatest(getActionType(getDraftAddonsCategory.request), handleGetCategories);
}

function* createDraftCate({ payload: { description, name } }: ReturnType<typeof createDraftAddonsCategory.request>) {
  try {
    const response: Awaited<ReturnType<typeof addonService.categories.createCategoryOfDraft>> = yield retry(
      3,
      1000,
      addonService.categories.createCategoryOfDraft,
      { description, name },
    );
    yield put(
      createDraftAddonsCategory.success({
        commandId: response.info.commandId,
        name: response.info.name,
        description: response.info.description,
        count: 0,
      }),
    );
    notifyAxiosHandler.handleSuccess(response.message);
  } catch {
    yield put(createDraftAddonsCategory.failure(undefined));
  }
}

export function* watchCreateDraftAddonCategory() {
  yield takeLatest(getActionType(createDraftAddonsCategory.request), createDraftCate);
}
