import { storage } from '@wiloke/functions';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { isNguyenDttnMode } from 'services/ShopifyConnection/utils/isNguyenDttnMode';
import { getShopifyThemeId } from 'utils/getShopifyThemeId';

interface Configure {
  configure: AxiosRequestConfig;
  setAccessToken: () => string;
  setRefreshToken: () => string;
}

type Success<ResponseDataT> = (res: AxiosResponse<ResponseDataT>, originalRequest: AxiosRequestConfig) => void;

type Failure = (error: AxiosError) => void;

interface AccessTokenParams {
  setCondition: (config: AxiosRequestConfig) => boolean;
}

interface Config<ResponseDataT, AxiosDataReturnT> {
  url: string;
  setRefreshCondition: (error: AxiosError) => boolean;
  axiosData?: (refreshToken: string, accessToken: string) => AxiosDataReturnT;
  success: Success<ResponseDataT>;
  failure: Failure;
  checkIsRefreshingToken: (status: Status) => void;
}

const { CancelToken } = axios;

export default class ConfigureAxios {
  private axiosInstance: AxiosInstance;
  private setAccessToken: () => string;
  private setRefreshToken: () => string;
  private withCredentialsEnable: boolean;

  public constructor({ configure, setAccessToken, setRefreshToken }: Configure) {
    this.setAccessToken = setAccessToken;
    this.setRefreshToken = setRefreshToken;
    this.axiosInstance = axios.create(configure);
    this.withCredentialsEnable = true;
  }

  public create = (cancel = '', setConfig?: () => AxiosRequestConfig) => {
    return {
      request: (requestConfig: AxiosRequestConfig) => {
        const source = CancelToken.source();
        const config = setConfig?.();
        const shopifyThemeId = getShopifyThemeId();

        const request = this.axiosInstance({
          withCredentials: this.withCredentialsEnable,
          ...requestConfig,
          ...(config ?? {}),
          cancelToken: source.token,
          headers: {
            // 'content-type': 'application/json',
            // accept: 'application/json',
            ...requestConfig.headers,
            ...(config?.headers ?? {}),
            ...(shopifyThemeId ? { 'X-Shopify-ThemeId': config?.headers?.shopifyThemeId ?? shopifyThemeId } : {}),
            'X-Dev-Mode': !!storage.getItem('X-Dev-Mode') || isNguyenDttnMode(),
          },
          params: {
            ...requestConfig.params,
            ...(config?.params ?? {}),
          },
        });
        if (!!cancel) {
          // @ts-ignore
          request[cancel] = source.cancel;
        }
        return request;
      },
    };
  };

  public accessToken = (_: AccessTokenParams) => {
    this.axiosInstance.interceptors.request.use(config => {
      if (!config?.url) {
        return config;
      }
      const accessToken = this.setAccessToken();
      if (!!accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    });
  };

  private handleRefreshTokenAsync = async <ResponseDataT extends any, AxiosDataReturnT extends any>(
    config: Config<ResponseDataT, AxiosDataReturnT>,
    error: AxiosError,
  ) => {
    const { url, axiosData, success, failure } = config;
    config?.checkIsRefreshingToken?.('loading');

    try {
      const accessToken = this.setAccessToken();
      const refreshToken = this.setRefreshToken();
      let res: AxiosResponse | undefined;

      if (!!accessToken) {
        res = await this.axiosInstance.post(url, axiosData?.(refreshToken, accessToken), {
          withCredentials: false,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } else {
        res = await this.axiosInstance.post(url, axiosData?.(refreshToken, accessToken), {
          withCredentials: this.withCredentialsEnable,
        });
      }

      success(res as AxiosResponse, error.config);
      config?.checkIsRefreshingToken('success');
      this.withCredentialsEnable = false;
      return await this.axiosInstance.request(error.config);
    } catch {
      config?.checkIsRefreshingToken('failure');
      failure(error);
      return await Promise.reject(error);
    } finally {
      this.refreshToken(config);
    }
  };

  public refreshToken = <ResponseDataT extends any = any, AxiosDataReturnT = any>(config: Config<ResponseDataT, AxiosDataReturnT>) => {
    const interceptor = this.axiosInstance.interceptors.response.use(undefined, (error: AxiosError) => {
      if (!config.setRefreshCondition(error)) {
        return Promise.reject(error);
      }
      this.axiosInstance.interceptors.response.eject(interceptor);
      return this.handleRefreshTokenAsync(config, error);
    });
  };
}
