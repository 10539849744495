import { AxiosError } from 'axios';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { verifyAfterUpdatePlanService } from 'services/UserService/Logic/verifyAfterUpdatePlan';
import { ErrorData } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { subscriptionChargeId } from '../actions';
import { setVisibleSubscription } from '../slicePlan';

function* handleAction({ payload: { chargeId, coupon, affiliateId, onFulfill } }: ReturnType<typeof subscriptionChargeId.request>) {
  yield put(setVisibleSubscription(true));
  try {
    const response: SagaReturnType<typeof verifyAfterUpdatePlanService> = yield retry(3, 1000, verifyAfterUpdatePlanService, {
      chargeId,
      coupon,
      affiliateId,
    });
    yield put(subscriptionChargeId.success({ message: response.message }));
    onFulfill?.();
  } catch (error) {
    const _error = error as AxiosError<ErrorData>;
    yield put(subscriptionChargeId.failure({ message: _error.response?.data.message ?? '' }));
  }
}

export function* watchSubscriptionChargeId() {
  yield takeLatest(getActionType(subscriptionChargeId.request), handleAction);
}
