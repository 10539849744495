import { getUsers } from './getUsers/getUsers';
import { updateRoleUser } from './updateRole/updateRoleUser';
import { updatePlan } from './updatePlan/updatePlan';
import { updateVedaCacheVersion } from './updateVedaCacheVersion/updateVedaCacheVersion';
import { updatePlanInfo } from './updatePlan/updatePlanInfo';
import { allowShopDev } from './allowShopDev/allowShopDev';
import { getUserSettings } from './getUserSettings/getUserSettings';
import { banAndUnBanUser } from 'services/UserService/UserController/admin/banAndUnBanUser/banAndUnBanUser';

export const adminApi = {
  getUsers,
  updateRoleUser,
  updatePlan,
  updateVedaCacheVersion,
  updatePlanInfo,
  allowShopDev,
  getUserSettings,
  banAndUnBanUser,
};
