import { CssColorVariable } from 'types/PresetStyles';
import { v4 } from 'uuid';

export const MAZE: Array<{ name: string; cssClassName: string; colors: CssColorVariable[] }> = [
  {
    name: 'Maze',
    cssClassName: 'veda-color-maze',
    colors: [
      { id: v4(), name: '--color-primary', light: '#EE6C4D', dark: '#EE6C4D' },
      { id: v4(), name: '--color-secondary', light: '#7EB3D1', dark: '#7EB3D1' },
      { id: v4(), name: '--color-tertiary', light: '#ee4d9b', dark: '#ee4d9b' },
      { id: v4(), name: '--color-quaternary', light: '#E0FBFC', dark: '#E0FBFC' },
      { id: v4(), name: '--color-dark', light: '#092142', dark: '#ffffff' },
      { id: v4(), name: '--color-gray9', light: '#223755', dark: '#F3F4F6' },
      { id: v4(), name: '--color-gray8', light: '#3A4D68', dark: '#E6E9EC' },
      { id: v4(), name: '--color-gray7', light: '#53647B', dark: '#CED3D9' },
      { id: v4(), name: '--color-gray6', light: '#8490A0', dark: '#B5BDC6' },
      { id: v4(), name: '--color-gray5', light: '#9DA6B3', dark: '#9DA6B3' },
      { id: v4(), name: '--color-gray4', light: '#B5BDC6', dark: '#8490A0' },
      { id: v4(), name: '--color-gray3', light: '#CED3D9', dark: '#53647B' },
      { id: v4(), name: '--color-gray2', light: '#E6E9EC', dark: '#3A4D68' },
      { id: v4(), name: '--color-gray1', light: '#F3F4F6', dark: '#223755' },
      { id: v4(), name: '--color-light', light: '#FFFFFF', dark: '#092142' },
    ],
  },
  {
    name: 'Maze (Dark)',
    cssClassName: 'veda-color-dark-maze',
    colors: [
      { id: v4(), name: '--color-primary', light: '#EE6C4D', dark: '#EE6C4D' },
      { id: v4(), name: '--color-secondary', light: '#7EB3D1', dark: '#7EB3D1' },
      { id: v4(), name: '--color-tertiary', light: '#ee4d9b', dark: '#ee4d9b' },
      { id: v4(), name: '--color-quaternary', light: '#E0FBFC', dark: '#E0FBFC' },
      { id: v4(), name: '--color-dark', light: '#ffffff', dark: '#092142' },
      { id: v4(), name: '--color-gray9', light: '#F3F4F6', dark: '#223755' },
      { id: v4(), name: '--color-gray8', light: '#E6E9EC', dark: '#3A4D68' },
      { id: v4(), name: '--color-gray7', light: '#CED3D9', dark: '#53647B' },
      { id: v4(), name: '--color-gray6', light: '#B5BDC6', dark: '#8490A0' },
      { id: v4(), name: '--color-gray5', light: '#9DA6B3', dark: '#9DA6B3' },
      { id: v4(), name: '--color-gray4', light: '#8490A0', dark: '#B5BDC6' },
      { id: v4(), name: '--color-gray3', light: '#53647B', dark: '#CED3D9' },
      { id: v4(), name: '--color-gray2', light: '#3A4D68', dark: '#E6E9EC' },
      { id: v4(), name: '--color-gray1', light: '#223755', dark: '#F3F4F6' },
      { id: v4(), name: '--color-light', light: '#092142', dark: '#ffffff' },
    ],
  },
];
