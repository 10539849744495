import { AxiosError } from 'axios';
import { setInstallThemeProduct, setVisibleInstallEvantoTheme, setVisibleInstallShopifyTheme } from 'containers/ModalInstallTheme/slice';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { getThemeProductService } from 'services/ThemeService/Logic/getThemeProduct';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { getProductTheme } from '../actions';

function* handleGet({ payload: { commandId, type, showNotify } }: ReturnType<typeof getProductTheme.request>) {
  try {
    const response: SagaReturnType<typeof getThemeProductService> = yield retry(3, 1000, getThemeProductService, { commandId });

    yield put(getProductTheme.success({ commandId }));
    yield put(setInstallThemeProduct(response.info));
    if (type === 'built_in') {
      yield put(setVisibleInstallShopifyTheme(true));
    } else {
      yield put(setVisibleInstallEvantoTheme(true));
    }
  } catch (error) {
    if (showNotify) {
      notifyAxiosHandler.handleError(error as AxiosError<ErrorData> | Error);
    }
    yield put(getProductTheme.failure({ commandId }));
  }
}

export function* watchGetThemeZipUrl() {
  yield takeLatest(getActionType(getProductTheme.request), handleGet);
}
