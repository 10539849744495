import { Tooltip } from '@wiloke/ui';
import { FC, memo, useEffect, useState } from 'react';
import { FontAwesome, View, ViewProps } from 'wiloke-react-core';
import * as styles from './styles';

export interface ButtonPlusProps extends ViewProps {
  innerRef?: React.Ref<HTMLElement>;
  tooltip?: string;
  isLoading?: boolean;
}

const ButtonPlusComponent: FC<ButtonPlusProps> = ({ innerRef, tooltip, isLoading = false, ...rest }) => {
  const [loading, setLoading] = useState(isLoading);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const icon = <FontAwesome type="far" name={loading ? 'spinner' : 'plus'} />;

  return (
    <View {...rest} ref={innerRef} css={[styles.buttonContainer, rest.css]}>
      {!!tooltip ? (
        <Tooltip text={tooltip} portal css={styles.tooltip}>
          {icon}
        </Tooltip>
      ) : (
        <View css={[styles.tooltip, { fontSize: '14px' }]}>{icon}</View>
      )}
    </View>
  );
};

export const ButtonPlus = memo(ButtonPlusComponent);
