import { handleWaitForSocketOfSyncThemeByResultOfExtractThemeToFileForSync } from 'packages/SyncThemeByResultOfExtractThemeToFileForSync/hooks/useSocketForSyncThemeByResultOfExtractThemeToFileForSync';
import { call, retry, SagaReturnType } from 'redux-saga/effects';
import { handleExtractResultForForceSync, shopifyConnectionService } from 'services/ShopifyConnection';

export interface SyncTranslation {
  syncTranslationParams: ReturnType<typeof handleExtractResultForForceSync>['syncTranslationsParams'][number];
}

export interface SyncTranslationResult {
  statusSyncTranslation: SyncFulfillStatus;
  messageError: string | undefined;
  isNeedIgnoreReportError: boolean;
}

export function* syncTranslation({ syncTranslationParams }: SyncTranslation) {
  try {
    yield retry(3, 1000, shopifyConnectionService.writeTranslation, { ...syncTranslationParams, isPreview: false });
    const socketStatusSyncTranslation: SagaReturnType<typeof handleWaitForSocketOfSyncThemeByResultOfExtractThemeToFileForSync> = yield call(
      handleWaitForSocketOfSyncThemeByResultOfExtractThemeToFileForSync,
      'Đồng bộ theme / Sync translation',
    );
    return {
      statusSyncTranslation: socketStatusSyncTranslation.statusSync,
      messageError: socketStatusSyncTranslation.statusSync === 'failure' ? socketStatusSyncTranslation.message : undefined,
      isNeedIgnoreReportError: socketStatusSyncTranslation.isNeedIgnoreReportError,
    } as SyncTranslationResult;
  } catch (error) {
    return {
      statusSyncTranslation: 'failure',
      messageError: error && typeof error === 'object' && 'message' in error ? error?.message : error,
      isNeedIgnoreReportError: false,
    } as SyncTranslationResult;
  }
}
