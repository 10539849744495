import { AxiosResponse } from 'axios';
import { BE_ThemeAtom } from 'services/ThemeService/Atom/types';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrlForAccessAtom } from '../const';

interface FilterAtomsParams {
  cacheVersion: number | null;
  pageParentCommandIds: string;
}

interface ResponseSuccess {
  message: string;
  info: BE_ThemeAtom[];
}

/** API được sử dụng để lấy về các theme atom đã được "Admin" duyệt */
export const filterAtoms = async ({ cacheVersion, pageParentCommandIds }: FilterAtomsParams) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${baseUrlForAccessAtom}/search`,
    params: {
      cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
      parentPageCommandIds: pageParentCommandIds,
    },
  });
  return response.data;
};
