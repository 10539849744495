import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import fetchAPI from 'utils/functions/fetchAPI';

interface DeleteClient {
  /** CommandId của section cần xoá */
  commandId: string;
}

interface ResponseSuccess {
  message: string;
}

/** API được sử dụng để client xoá section */
export const deleteClient = async ({ commandId }: DeleteClient) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${configureApp.clientBaseUrl}/me/${configureApp.endpoint.sections}/${commandId}`,
    method: 'DELETE',
  });
  return response.data;
};
