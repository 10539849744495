import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { userApis } from '../apis';

interface Params {
  userId: number;
  planHandle?: string;
  shopifyPlanName?: string;
  chargeId: number | null;
  price: string | null;
  status: string | null;
  trialDays: number | null;
}

export const updatePlanAuthorService = async (params: Params) => {
  const { role: _role } = getUserInfo();
  if (_role === 'admin') {
    await userApis.user.adminApi.updatePlan({ userId: params.userId, planHandle: params.planHandle, shopifyPlanName: params.shopifyPlanName });
    const responseUpdateInfoPlan = await userApis.user.adminApi.updatePlanInfo({
      chargeId: params.chargeId,
      price: params.price,
      status: params.status,
      trialDays: params.trialDays,
      userId: params.userId,
    });
    return responseUpdateInfoPlan;
  }
  if (_role === 'support') {
    await userApis.user.supportApi.updatePlan({ userId: params.userId, planHandle: params.planHandle, shopifyPlanName: params.shopifyPlanName });
    const responseUpdateInfoPlan = await userApis.user.supportApi.updatePlanInfo({
      chargeId: params.chargeId,
      price: params.price,
      status: params.status,
      trialDays: params.trialDays,
      userId: params.userId,
    });
    return responseUpdateInfoPlan;
  }

  throw new RoleException();
};
