import { AxiosResponse } from 'axios';
import { CategoryDataResponse } from 'services/SectionService/types';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface GetCategoriesOfProduct {
  type: 'GET FIRST PAGE';
  cacheVersion: number | null;
  label?: string;
  showParentOnly?: boolean;
}

interface LoadmoreCategoriesOfProduct {
  type: 'LOADMORE';
  cacheVersion: number | null;
  cursor: string;
  label?: string;
  showParentOnly?: boolean;
}

interface ResponseSuccess {
  message: string;
  info: CategoryDataResponse[];
}

/** API được sử dụng để lấy về tất các category của "Section Product" */
export const getCategories = async ({
  type,
  cacheVersion,
  label,
  showParentOnly,
  ...params
}: GetCategoriesOfProduct | LoadmoreCategoriesOfProduct) => {
  if (type === 'GET FIRST PAGE') {
    const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
      url: `${baseUrl}`,
      params: {
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
        size: 100,
        label,
        showParentOnly,
      },
    });
    return response.data;
  } else {
    const { cursor } = params as LoadmoreCategoriesOfProduct;
    const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
      url: `${baseUrl}/search`,
      params: {
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
        after: cursor,
        size: 100,
        label,
        showParentOnly,
      },
    });
    return response.data;
  }
};
