import { at } from 'utils/at';
import { getBOCsBetweenSomething } from './utils/getBOCsBetweenSomething';
import { getMatches } from './utils/getMatches';

interface HandleGetScopeOfAddon {
  /** Đoạn code liquid trước thẻ <addons>  */
  liquidCompiled: string;
}
interface RTHandleGetScopeOfAddon {
  variables: string[];

  variant: // Khi addon được in vào vòng lặp có chứa nhiều product và tabs nên có thể máy yếu sẽ bị crash =>  Vì vậy ta chỉ in vào phần tử đầu tiên
  | 'Render 1 addon để tối ưu performance'
    // Addon được đặt trong tab thì vẫn phải được render
    | 'Render tất cả';
}

// Các quy luật đặt biến loop của đội code template
const SIGNAL_VARIABLES_NAME_IS_FORLOOP_NEED_OPTIMIZE_PERFORMANCE = [
  'searchItem',
  'product_setting_content',
  'productItem',
  'productListItem',
  'i',
  'j',
  'navItem',
  'cart_item',
  'variantItem',
];
export const handleGetScopeOfAddon = ({ liquidCompiled }: HandleGetScopeOfAddon): RTHandleGetScopeOfAddon => {
  const variables = new Set<string>();
  let variant: RTHandleGetScopeOfAddon['variant'] = 'Render tất cả';

  const BOCs = getBOCsBetweenSomething({ liquid: liquidCompiled, endBOC: new RegExp('%}', 'g'), startBOC: new RegExp('{%', 'g') });
  const forLoopVariables: string[] = [];
  BOCs.forEach(BOC => {
    // Xử lý cho assign trước
    if (/assign.*=/.test(BOC)) {
      const _BOC = BOC.replace(/\n/g, '');
      const targetAssignClause = getMatches(_BOC, new RegExp(/assign.*=/g))[0];
      const variableName = at(targetAssignClause?.split(' '), 1);
      if (variableName) {
        variables.add(variableName);
      }
    } else if (/for\s+.*\s+in/.test(BOC)) {
      const _BOC = BOC.replace(/\n/g, '');
      const targetAssignClause = getMatches(_BOC, new RegExp(/for\s+.*\s+in/g))[0];
      const variableName = at(targetAssignClause?.split(' '), 1);
      if (variableName) {
        forLoopVariables.push(variableName);
      }
    }
    // Nếu "endfor" => Biến loop của vòng for trước đó được xoá bỏ
    else if (/{%\s*endfor\s*%}/.test(BOC)) {
      forLoopVariables.pop();
    }
  });

  forLoopVariables.forEach(variableName => {
    if (variableName && SIGNAL_VARIABLES_NAME_IS_FORLOOP_NEED_OPTIMIZE_PERFORMANCE.includes(variableName)) {
      variant = 'Render 1 addon để tối ưu performance';
      variables.add(variableName);
    }
  });

  return { variables: Array.from(variables), variant };
};
