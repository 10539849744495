import { imageUrl } from '@wiloke/functions';
import { Button, ScrollBars, Title } from '@wiloke/ui';
import Checkbox from 'components/Checkbox';
import CreatePageCard from 'components/CreatePageCard';
import Field from 'components/Field';
import ModalCreate from 'components/ModalCreate';
import TextInput from 'components/TextInput';

import { TemplatesContent } from 'containers/Admin/Modals/components/ModalPageBuilder/TemplateContent';
import { TemplateFilter } from 'containers/Admin/Modals/components/ModalPageBuilder/TemplateFilter';
import { useGetTemplatesPopup } from 'containers/Admin/PageBuilder/TemplatesInPopup';
import { modalsSelector } from 'containers/Admin/selector';
import withDebounce from 'hocs/withDebounce';
import parse from 'html-react-parser';
import { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Location } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationStates } from 'routes/LocationStates';
import { ServerTemplateResponse } from 'services/PagesBuilderService';
import { authSelector } from 'store/selectors';
import { i18n } from 'translation';
import { PageType } from 'types/Page';
import { Consts } from 'utils/constants/constants';
import { getEntityVariant } from 'utils/getEntityVariant';
import { Space, View } from 'wiloke-react-core';
import { CSSProp } from 'wiloke-react-core/dist/hocs/withStyles';
import { useChangeModalAdminSettings } from '../../store';

interface ModalCreateNormalPageProps {
  pageType: PageType;
  forceActiveDrawer?: boolean;
  pageInput?: ServerTemplateResponse | undefined;
  bodyCss?: CSSProp;
  onCancel?: () => void;
}

const DebounceTextInput = withDebounce(TextInput, 'value', 'onValueChange', 300);

const pateTypeTitle: Record<PageType, string> = {
  account: i18n.t('adminDashboard.account'),
  activateAccount: i18n.t('adminDashboard.active_account'),
  addresses: i18n.t('adminDashboard.addresses'),
  article: i18n.t('adminDashboard.addresses'),
  blog: i18n.t('adminDashboard.blog'),
  cart: i18n.t('adminDashboard.cart'),
  collection: i18n.t('adminDashboard.collection'),
  collections: i18n.t('adminDashboard.collectionListing'),
  giftCard: i18n.t('adminDashboard.giftCard'),
  home: i18n.t('adminDashboard.home'),
  login: i18n.t('adminDashboard.customersLogin'),
  order: i18n.t('adminDashboard.order'),
  page: i18n.t('adminDashboard.landing'),
  pageNotFound: i18n.t('adminDashboard.notFound'),
  password: i18n.t('adminDashboard.password'),
  product: i18n.t('adminDashboard.product'),
  register: i18n.t('adminDashboard.register'),
  resetPassword: i18n.t('adminDashboard.reset_password'),
  search: i18n.t('adminDashboard.search'),
};

export const ModalCreateNormalPage: FC<ModalCreateNormalPageProps> = ({
  bodyCss,
  pageType,
  pageInput = undefined,
  forceActiveDrawer = false,
  onCancel,
}) => {
  const { createNormalPage } = useSelector(modalsSelector);
  const { shopName } = useSelector(authSelector);
  const changeModalAdminSettings = useChangeModalAdminSettings();
  const history = useHistory();

  const [includeHeader, setIncludeHeader] = useState(true);
  const [drawerContentActive, setDrawerContentActive] = useState(forceActiveDrawer);
  const [currentTemplate, setCurrentTemplate] = useState<ServerTemplateResponse | undefined>(pageInput);
  const [value, setValue] = useState(currentTemplate?.label ?? '');
  const [errorText, setErrorText] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const location = useLocation<'/builder'>();
  const getTemplatesPopup = useGetTemplatesPopup();
  const [search, setSearch] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [planFilter, setPlanFilter] = useState('all');
  const [useCase, setUseCase] = useState('');

  useEffect(() => {
    if (createNormalPage) {
      getTemplatesPopup.request({ type: pageType, categoryId, search, planFilter, useCases: [useCase] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNormalPage, pageType, categoryId, search, planFilter, useCase]);

  useEffect(() => {
    if (!!forceActiveDrawer) {
      setDrawerContentActive(forceActiveDrawer);
    }
  }, [forceActiveDrawer]);

  useEffect(() => {
    if (!!pageInput) {
      setCurrentTemplate(pageInput);
    }
  }, [pageInput]);

  useEffect(() => {
    if (location?.state?.label) {
      setValue(location?.state?.label);
    } else if (currentTemplate !== undefined) {
      setValue(currentTemplate.label ?? '');
    }
  }, [currentTemplate, location]);

  const _handleSubmitCreate = () => {
    if (!value) {
      setErrorText(i18n.t('validate.name_blank', { text: i18n.t('general.page', { textTransform: 'lowercase', text: '' }) }));
      return;
    }
    // create page xong thì redirect sang builder page
    setErrorText('');

    const id = currentTemplate ? currentTemplate.parentCommandId : Consts.BlankCommandId;
    const entityVariant = getEntityVariant(history.location as Location<keyof LocationStates>);
    const isAdminTemplate = !!currentTemplate;

    history.push(`/builder?shop=${shopName}&id=${id}&entityVariant=${entityVariant}`, {
      headerFooterEnabled: includeHeader,
      label: value,
      type: pageType,
      isCreate: true,
      backToPage: '/page',
      isAdminTemplate,
      entityVariant,
    });
    setDrawerContentActive(false);
    changeModalAdminSettings({ createNormalPage: false });
  };

  const handleCreate = (type: 'create' | 'install', item?: ServerTemplateResponse) => {
    setDrawerContentActive(true);
    inputRef.current?.focus();
    if (type === 'create') {
      changeModalAdminSettings({ isCreate: true });
      setCurrentTemplate(undefined);
    } else {
      changeModalAdminSettings({ isCreate: false });
      setCurrentTemplate(item);
    }
  };

  const _handleCloseModal = () => {
    changeModalAdminSettings({ createNormalPage: false });
    setCurrentTemplate(undefined);
    setCategoryId('');
    onCancel?.();
  };

  const _handleGoBack = () => {
    setDrawerContentActive(false);
    setCurrentTemplate(undefined);
    setValue('');
  };

  const renderFormContent = (
    <ScrollBars css={{ height: 'calc(100% - 15px) !important' }}>
      <View row css={{ height: '100%', margin: '0', padding: '15px 0 0px' }}>
        <View columns={[12, 6, 6]}>
          <CreatePageCard
            image={imageUrl(currentTemplate?.image.src || '', '700') || ''}
            includeHeader={includeHeader}
            variant={currentTemplate?.image.src ? 'image' : 'default'}
          />
        </View>
        <View columns={[12, 6, 6]}>
          <View css={{ marginBottom: '25px' }}>
            <Title
              size="medium"
              title={i18n.t('adminDashboard.pages', { name: pateTypeTitle[pageType] })}
              text={i18n.t('general.create_description', { text: `${pateTypeTitle[pageType]} Page` })}
            />
          </View>

          {currentTemplate && currentTemplate.html && <View css={{ marginBottom: '25px' }}>{parse(`${currentTemplate.html}`)}</View>}

          <Field label={i18n.t('builderPage.page_settings.general_settings.general.name')} error={errorText}>
            <DebounceTextInput
              innerRef={inputRef}
              placeholder={i18n.t('adminDashboard.name_your_page')}
              radius={6}
              sizeInput="medium"
              block
              maxLength={Consts.MAX_LENGTH_PAGE}
              onValueChange={text => setValue(text)}
              borderColor={!!errorText ? 'danger' : 'gray4'}
              value={value}
            />
          </Field>

          <View css={{ margin: '10px 0 25px' }}>
            <Checkbox
              checked={includeHeader}
              onValueChange={val => {
                setIncludeHeader(val);
              }}
              borderColor="gray4"
            >
              <View color="dark" fontFamily="secondary">
                {i18n.t('builderPage.page_settings.general_settings.general.enable_header_footer')}
              </View>
            </Checkbox>
            <Space size={8} />
            <View>
              <View tagName="strong" color="danger">
                Turn off
              </View>{' '}
              this feature to use the Veda Header Footer
            </View>
            <View target="_blank" tagName="a" href="https://help.myshopkit.app/en/docs/veda-landing-page-builder/veda-page/header-footer-building/">
              Click to learn how this feature works
            </View>
          </View>

          <Button
            css={{ marginRight: '10px' }}
            borderColor="primary"
            borderStyle="solid"
            borderWidth={1}
            size="small"
            radius={6}
            onClick={_handleSubmitCreate}
          >
            {i18n.t('general.create_button', {
              text: i18n.t('general.page', { textTransform: 'lowercase', text: '' }),
            })}
          </Button>

          {currentTemplate?.description && (
            <View
              css={{
                padding: '10px',
                marginTop: '20px',
              }}
              borderColor="gray3"
              borderStyle="solid"
              borderWidth={1}
              radius={6}
            >
              {parse(currentTemplate.description)}
            </View>
          )}
        </View>
      </View>
    </ScrollBars>
  );

  return (
    <ModalCreate
      bodyCss={bodyCss}
      visible={createNormalPage}
      goBack={forceActiveDrawer === true ? undefined : _handleGoBack}
      onOk={_handleCloseModal}
      onCancel={_handleCloseModal}
      activeDrawer={drawerContentActive}
      headerText={i18n.t('general.select_template', { text: i18n.t('general.page') })}
      FormContent={renderFormContent}
      Content={
        <View css={{ display: 'flex', minHeight: '100%', height: '100%' }}>
          <View
            backgroundColor="light"
            css={{
              width: '240px',
              minHeight: '100%',
              padding: '10px',
              minWidth: '240px',
              maxWidth: '240px',
            }}
          >
            <TemplateFilter
              categoryId={categoryId}
              onChangeCategory={setCategoryId}
              pageType={pageType}
              planFilter={planFilter}
              onChangePlan={setPlanFilter}
              useCase={useCase}
              onChangeUseCase={setUseCase}
            />
          </View>
          <TemplatesContent
            onAdd={() => handleCreate('create', undefined)}
            onInstall={item => handleCreate('install', item)}
            search={search}
            onSearch={setSearch}
            categoryId={categoryId}
            planFilter={planFilter}
            useCases={[useCase]}
            type={pageType}
          />
        </View>
      }
    />
  );
};
