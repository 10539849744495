const googleVNFonts = [
  'Roboto',
  'Open Sans',
  'Montserrat',
  'Source Sans Pro',
  'Roboto Condensed',
  'Oswald',
  'Roboto Mono',
  'Raleway',
  'Noto Sans',
  'Recursive',
  'Roboto Slab',
  'Merriweather',
  'Playfair Display',
  'Nunito',
  'Open Sans Condensed',
  'Lora',
  'Noto Serif',
  'Work Sans',
  'Nunito Sans',
  'Fira Sans',
  'Quicksand',
  'Inter',
  'Barlow',
  'Inconsolata',
  'Source Code Pro',
  'Dosis',
  'Josefin Sans',
  'Arimo',
  'Libre Franklin',
  'Anton',
  'Cabin',
  'Bitter',
  'IBM Plex Sans',
  'Lobster',
  'Dancing Script',
  'Source Serif Pro',
  'Yanone Kaffeesatz',
  'Oi',
  'Varela Round',
  'Comfortaa',
  'Barlow Condensed',
  'Exo 2',
  'EB Garamond',
  'Merriweather Sans',
  'Pacifico',
  'Cormorant Unicase',
  'Archivo Narrow',
  'Prompt',
  'Kanit',
  'Asap',
  'Questrial',
  'Exo',
  'Cormorant Garamond',
  'Maven Pro',
  'Brygada 1918',
  'Amatic SC',
  'Play',
  'Fira Sans Condensed',
  'IBM Plex Serif',
  'Signika',
  'Patrick Hand',
  'Barlow Semi Condensed',
  'Alfa Slab One',
  'Vollkorn',
  'Mulish',
  'Archivo',
  'Alegreya Sans',
  'Alegreya',
  'M PLUS Rounded 1c',
  'Encode Sans',
  'Prata',
  'Tinos',
  'M PLUS 1p',
  'Francois One',
  'IBM Plex Mono',
  'Cuprum',
  'Baloo 2',
  'Noticia Text',
  'Rokkitt',
  'Old Standard TT',
  'Sarabun',
  'Asap Condensed',
  'Saira Condensed',
  'Playfair Display SC',
  'Paytone One',
  'Montserrat Alternates',
  'Fira Sans Extra Condensed',
  'Spectral',
  'JetBrains Mono',
  'Philosopher',
  'Jura',
  'Cormorant',
  'Ruda',
  'Bangers',
  'Saira',
  'Chakra Petch',
  'Taviraj',
  'Srisakdi',
  'Faustina',
  'Mitr',
  'Truculenta',
  'Sigmar One',
  'Cabin Condensed',
  'Encode Sans Condensed',
  'Space Mono',
  'Bungee',
  'Bai Jamjuree',
  'Sawarabi Gothic',
  'Potta One',
  'Mali',
  'Pangolin',
  'Markazi Text',
  'Alata',
  'Imbue',
  'Yeseva One',
  'Gelasio',
  'Krub',
  'Baloo Tamma 2',
  'Saira Semi Condensed',
  'Pridi',
  'Sriracha',
  'Crimson Pro',
  'Arima Madurai',
  'Arsenal',
  'Baloo Chettan 2',
  'Itim',
  'Cousine',
  'Baloo Tammudu 2',
  'Andika New Basic',
  'Saira Extra Condensed',
  'Alegreya Sans SC',
  'Texturina',
  'Lexend Deca',
  'Bevan',
  'VT323',
  'Lalezar',
  'Lemonada',
  'Charm',
  'Trirong',
  'Baloo Da 2',
  'Chonburi',
  'Literata',
  'Pattaya',
  'Alegreya SC',
  'Thasadith',
  'Bungee Inline',
  'Big Shoulders Stencil Text',
  'Big Shoulders Inline Display',
  'Big Shoulders Stencil Display',
  'Big Shoulders Inline Text',
  'Judson',
  'Alatsi',
  'IBM Plex Sans Condensed',
  'Niramit',
  'Piazzolla',
  'Space Grotesk',
  'Maitree',
  'Rosario',
  'Darker Grotesque',
  'Metrophobic',
  'Athiti',
  'Trispace',
  'Epilogue',
  'Big Shoulders Display',
  'Cormorant Infant',
  'Podkova',
  'K2D',
  'Ballet',
  'Spectral SC',
  'Cormorant SC',
  'Sansita Swashed',
  'Grandstander',
  'Encode Sans Semi Condensed',
  'Be Vietnam',
  'Sedgwick Ave',
  'Bungee Shade',
  'Hepta Slab',
  'Andika',
  'Fraunces',
  'Patrick Hand SC',
  'Grenze Gotisch',
  'Petrona',
  'Commissioner',
  'David Libre',
  'Baloo Thambi 2',
  'Kufam',
  'Xanh Mono',
  'Encode Sans Expanded',
  'Newsreader',
  'Red Rose',
  'Vollkorn SC',
  'Goldman',
  'Calistoga',
  'Cormorant Upright',
  'Big Shoulders Text',
  'Manuale',
  'Livvic',
  'Coiny',
  'Major Mono Display',
  'Varta',
  'MuseoModerno',
  'Lexend Zetta',
  'Encode Sans Semi Expanded',
  'Bellota Text',
  'Kodchasan',
  'Baloo Bhaina 2',
  'Baloo Bhai 2',
  'Baloo Paaji 2',
  'Farsan',
  'KoHo',
  'Bellota',
  'Fahkwang',
  'Saira Stencil One',
  'Rowdies',
  'Lexend Exa',
  'Gotu',
  'Charmonman',
  'Bungee Outline',
  'Lexend Mega',
  'Viaoda Libre',
  'Lexend Giga',
  'Sedgwick Ave Display',
  'Barriecito',
  'Lexend Tera',
  'Lexend Peta',
  'Bungee Hairline',
  'Bahianita',
  'Grenze',
] as const;

const googleFonts = [
  ...googleVNFonts,
  'Tajawal',
  'Amiri',
  'Almarai',
  'Changa',
  'El Messiri',
  'Baloo Bhaijaan 2',
  'Mada',
  'Markazi Text',
  'Lateef',
  'Reem Kufi',
  'Harmattan',
  'Mirza',
  'Hind Siliguri',
  'Baloo Da 2',
  'Galada',
  'Atma',
  'Noto Sans Hong Kong',
  'Noto Sans Simplified Chinese',
  'Russo One',
  'Do Hyeon',
  'Poppins',
  'Noto Sans JP',
  'Lato',
  'PT Sans',
  'Ubuntu',
  'Libre Caslon Display',
  'PT Serif',
  'Rubik',
  'Noto Sans KR',
  'Nanum Gothic',
  'Titillium Web',
  'Mukta',
  'Noto Sans TC',
  'Heebo',
  'PT Sans Narrow',
  'Oxygen',
  'Bebas Neue',
  'Slabo 27px',
  'Karla',
  'Hind Siliguri',
  'Crimson Text',
  'Libre Baskerville',
  'Londrina Solid',
  'Hind',
  'Fjalla One',
  'Abel',
  'Overpass',
  'Cairo',
  'Arvo',
  'Balsamiq Sans',
  'Indie Flower',
  'Zilla Slab',
  'Teko',
  'Noto Sans SC',
  'Shadows Into Light',
  'Abril Fatface',
  'Catamaran',
  'Bree Serif',
  'Monda',
  'Acme',
  'Antic Slab',
  'Architects Daughter',
  'Raleway Dots',
  'Martel',
  'Assistant',
  'Domine',
  'Permanent Marker',
  'Righteous',
  'Hind Madurai',
  'Tajawal',
  'Noto Sans HK',
  'Patua One',
  'Fredoka One',
  'Amiri',
  'Manrope',
  'PT Sans Caption',
  'Caveat',
  'DM Sans',
  'Crete Round',
  'Rajdhani',
  'Nanum Myeongjo',
  'Ubuntu Condensed',
  'Satisfy',
  'Noto Serif JP',
  'Alike Angular',
  'Cantarell',
  'ABeeZee',
  'Cinzel',
  'Courgette',
  'Archivo Black',
  'Lobster Two',
  'Kaushan Script',
  'Frank Ruhl Libre',
  'Cardo',
  'Kalam',
  'Concert One',
  'Pathway Gothic One',
  'Great Vibes',
  'Hind Vadodara',
  'Sacramento',
  'Volkhov',
  'Luckiest Guy',
  'Changa',
  'News Cycle',
  'Advent Pro',
  'Ropa Sans',
  'Yantramanav',
  'Passion One',
  'Special Elite',
  'Pragati Narrow',
  'Quattrocento Sans',
  'Orbitron',
  'Gloria Hallelujah',
  'Didact Gothic',
  'Electrolize',
  'Schoolbell',
  'Krona One',
  'Chivo',
  'Neuton',
  'Sawarabi Mincho',
  'Poiret One',
  'Josefin Slab',
  'Ramabhadra',
  'Yellowtail',
  'Istok Web',
  'Ultra',
  'Almarai',
  'Hind Guntur',
  'Parisienne',
  'Quattrocento',
  'BenchNine',
  'Cookie',
  'Unica One',
  'Khand',
  'Staatliches',
  'Gudea',
  'Playball',
  'Sora',
  'Vidaloka',
  'Squada One',
  'Shanti',
  'Press Start 2P',
  'Monoton',
  'Sanchez',
  'Arapey',
  'Jaldi',
  'Karma',
  'Neucha',
  'Alice',
  'Hammersmith One',
  'Gentium Basic',
  'Economica',
  'Gothic A1',
  'Spartan',
  'El Messiri',
  'Oleo Script',
  'Handlee',
  'Adamina',
  'Viga',
  'Tangerine',
  'Fugaz One',
  'Rock Salt',
  'Amaranth',
  'Aclonica',
  'Katibeh',
  'Marck Script',
  'Gochi Hand',
  'Armata',
  'Pontano Sans',
  'Bad Script',
  'Merienda',
  'Actor',
  'Alef',
  'Julius Sans One',
  'PT Mono',
  'Nanum Gothic Coding',
  'Lusitana',
  'Allura',
  'Quantico',
  'Palanquin',
  'Audiowide',
  'Gentium Book Basic',
  'Abhaya Libre',
  'Noto Serif TC',
  'Sorts Mill Goudy',
  'Pinyon Script',
  'Carter One',
  'Homemade Apple',
  'Tenor Sans',
  'Enriqueta',
  'Unna',
  'Damion',
  'Sarala',
  'Chewy',
  'Alex Brush',
  'PT Serif Caption',
  'Signika Negative',
  'Marcellus',
  'Khula',
  'Noto Serif SC',
  'DM Serif Display',
  'Covered By Your Grace',
  'Lilita One',
  'Rambla',
  'Shadows Into Light Two',
  'Basic',
  'Varela',
  'Kreon',
  'Kosugi Maru',
  'Cantata One',
  'Yrsa',
  'Berkshire Swash',
  'Leckerli One',
  'Black Ops One',
  'Spinnaker',
  'Average',
  'Sintony',
  'Mukta Malar',
  'Shrikhand',
  'Nothing You Could Do',
  'Red Hat Display',
  'Rubik Mono One',
  'Coda',
  'Rancho',
  'Nanum Pen Script',
  'Michroma',
  'Mada',
  'Eczar',
  'Glegoo',
  'Mirza',
  'Overpass Mono',
  'Black Han Sans',
  'Forum',
  'Overlock',
  'Allerta',
  'Scheherazade',
  'Boogaloo',
  'Aleo',
  'Fredericka the Great',
  'Niconne',
  'Gruppo',
  'Italianno',
  'Allan',
  'Suez One',
  'Antic',
  'Holtwood One SC',
  'Libre Caslon Text',
  'Rufina',
  'Martel Sans',
  'Aldrich',
  'Cabin Sketch',
  'Syncopate',
  'Anonymous Pro',
  'Share Tech Mono',
  'Caveat Brush',
  'Annie Use Your Telescope',
  'Cinzel Decorative',
  'Bentham',
  'Nobile',
  'Share',
  'Six Caps',
  'Capriola',
  'Halant',
  'Bowlby One SC',
  'Arbutus Slab',
  'DM Serif Text',
  'Candal',
  'Londrina Outline',
  'Hanuman',
  'Lateef',
  'Days One',
  'Telex',
  'Allerta Stencil',
  'Nixie One',
  'Seaweed Script',
  'Reenie Beanie',
  'Contrail One',
  'Suranna',
  'DM Mono',
  'Kameron',
  'Racing Sans One',
  'Oranienbaum',
  'Molengo',
  'Caudex',
  'Scada',
  'Just Another Hand',
  'Jockey One',
  'Aladin',
  'Poller One',
  'Kadwa',
  'Biryani',
  'Marcellus SC',
  'Graduate',
  'Arizonia',
  'Cutive Mono',
  'Rozha One',
  'Belleza',
  'Fira Mono',
  'IM Fell Double Pica',
  'Marmelad',
  'Magra',
  'Palanquin Dark',
  'Red Hat Text',
  'Yesteryear',
  'Coustard',
  'Mallanna',
  'Rye',
  'Sansita',
  'Lustria',
  'Miriam Libre',
  'Coming Soon',
  'Kristi',
  'Herr Von Muellerhoff',
  'Fauna One',
  'Public Sans',
  'Merienda One',
  'Noto Serif KR',
  'Carme',
  'Average Sans',
  'Rochester',
  'Changa One',
  'Bubblegum Sans',
  'Norican',
  'Laila',
  'Averia Serif Libre',
  'Grand Hotel',
  'Petit Formal Script',
  'Voltaire',
  'Love Ya Like A Sister',
  'Buenard',
  'Slabo 13px',
  'Oxygen Mono',
  'Amethysta',
  'Nanum Brush Script',
  'Vast Shadow',
  'Mr De Haviland',
  'Sue Ellen Francisco',
  'Radley',
  'Chelsea Market',
  'Knewave',
  'Kelly Slab',
  'Mukta Vaani',
  'Federo',
  'Carrois Gothic',
  'Corben',
  'Jost',
  'Poly',
  'Delius',
  'Montaga',
  'ZCOOL XiaoWei',
  'Ovo',
  'Chau Philomene One',
  'Sen',
  'Ceviche One',
  'Trocchi',
  'GFS Didot',
  'Gilda Display',
  'Amiko',
  'Harmattan',
  'Cambay',
  'Calligraffitti',
  'Sofia',
  'Sniglet',
  'Comic Neue',
  'Amita',
  'Belgrano',
  'Esteban',
  'Gugi',
  'Cutive',
  'Goudy Bookletter 1911',
  'Cedarville Cursive',
  'Gabriela',
  'Rouge Script',
  'Duru Sans',
  'Smokum',
  'Mansalva',
  'Marvel',
  'IM Fell English',
  'Salsa',
  'Faster One',
  'Brawler',
  'Montserrat Subrayada',
  'Lekton',
  'Freckle Face',
  'Alike',
  'Piedra',
  'Libre Barcode 39',
  'Kurale',
  'Kosugi',
  'Montez',
  'IM Fell English SC',
  'Pompiere',
  'UnifrakturMaguntia',
  'Titan One',
  'Mrs Saint Delafield',
  'Anaheim',
  'Andada',
  'Mandali',
  'Secular One',
  'Mate',
  'Wendy One',
  'Wallpoet',
  'Limelight',
  'Jua',
  'Convergence',
  'Homenaje',
  'Lemon',
  'Balthazar',
  'Oleo Script Swash Caps',
  'Manjari',
  'Finger Paint',
  'Gurajada',
  'Qwigley',
  'Gravitas One',
  'La Belle Aurore',
  'Share Tech',
  'Oregano',
  'Rakkas',
  'Clicker Script',
  'Proza Libre',
  'Baumans',
  'Meera Inimai',
  'Dawning of a New Day',
  'Shojumaru',
  'Oxanium',
  'Orienta',
  'Mouse Memoirs',
  'Happy Monkey',
  'Atma',
  'Crafty Girls',
  'Fjord One',
  'Fondamento',
  'Bowlby One',
  'Nova Square',
  'IM Fell DW Pica',
  'Trade Winds',
  'Farro',
  'Antic Didone',
  'Fanwood Text',
  'Padauk',
  'Unkempt',
  'NTR',
  'Chela One',
  'Frijole',
  'Battambang',
  'Rammetto One',
  'Doppio One',
  'Pavanam',
  'Baskervville',
  'Caladea',
  'Bellefair',
  'Rasa',
  'Galada',
  'McLaren',
  'Expletus Sans',
  'Inder',
  'Skranji',
  'Vesper Libre',
  'Quando',
  'Stardos Stencil',
] as const;

export default googleFonts;
