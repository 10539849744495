import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

export const getThemesTrash = async ({ size, label }: { size: number; label?: string }) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const atomResponse = await themeApis.atom.adminApi.theme.getTrashes({ type: 'GET FIRST PAGE', cacheVersion: lastCache, size, label });
    return atomResponse;
  }

  throw new RoleException();
};

export const loadMoreThemesTrash = async ({ size, label, cursor }: { size: number; label?: string; cursor: string }) => {
  const { role, lastCache } = getUserInfo();

  if (role === 'admin') {
    const atomResponse = await themeApis.atom.adminApi.theme.getTrashes({ type: 'LOADMORE', cacheVersion: lastCache, size, label, cursor });
    return atomResponse;
  }

  throw new RoleException();
};
