/** Mỗi file nằm trong folder "Sections" phải ít hơn 600 dòng code */
import SwitchBeauty from 'components/SwitchBeauty';
import { Dispatch, SetStateAction } from 'react';
import { Text, View } from 'wiloke-react-core';

const FIELD_WILL_REMOVE = [
  'Setting Animate',
  'Setting Animate In Out',
  'Setting Color',
  'Setting Flex Order',
  'Setting Font',
  'Setting Radio Group',
  'Setting Responsive',
  'Setting Space',
  'Setting Style Box',
];

interface ResolveThirdConstraintProps {
  setIsNeedReduceTotalLinesOfCode: Dispatch<SetStateAction<boolean>>;
  isNeedReduceTotalLinesOfCode: boolean;
}
export const ResolveThirdConstraint = ({ isNeedReduceTotalLinesOfCode, setIsNeedReduceTotalLinesOfCode }: ResolveThirdConstraintProps) => {
  return (
    <View css={{ padding: '12px 0' }}>
      <View tagName="h5">
        Xoá bớt các field setting có thể fix cứng để thoả mãn điều kiện 'Mỗi file trong folder "Sections" phải ít hơn 600 dòng code'
      </View>
      <SwitchBeauty
        checked={isNeedReduceTotalLinesOfCode}
        onValueChange={setIsNeedReduceTotalLinesOfCode}
        radius={6}
        borderColor="gray3"
        borderWidth={1}
        enableText="Lược bỏ"
        disableText="Giữ nguyên"
      />
      <View css={{ padding: '8px' }}>
        <Text tagName="b">Các field bị cắt vợi đi</Text>
        <View tagName="ul">
          {FIELD_WILL_REMOVE.map(item => (
            <View tagName="li" key={item}>
              {item}
            </View>
          ))}
          <View tagName="li" color="danger">
            Liên hệ developer nếu muốn thêm, xoá field setting nào đó hoặc bất kì những thứ phát sinh nào khác
          </View>
        </View>
      </View>
    </View>
  );
};
