import { AsyncComponent } from '@wiloke/ui';
import ImageTextCard from 'components/ImageTextCard';
import { useGetCategoriesOfThemeProduct } from 'containers/Admin/Management/Themes/store/actions/actionThemesProduct';

import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { authSelector, themeBuilderSelector } from 'store/selectors';
import { ProductTheme } from 'types/Theme';
import { at } from 'utils/at';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { validatePlan } from 'utils/functions/validatePlan';
import { ActivityIndicator, GridSmart, View, ViewportTracking } from 'wiloke-react-core';
import { useDeleteProductTheme, useGetProductTheme, useGetThemeTemplates, useLoadMoreThemeTemplates } from '../actions';
import { PLAN_ENUM, PLAN_STATIC_DATA } from 'utils/constants/PlanEnum';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';

export const Content = () => {
  const { requestStatus, templates, deleteStatus, hasNextPage, getThemeZipStatus } = useSelector(themeBuilderSelector.templates);
  // const { categories, getStatus, categoryId } = useSelector(themesProductSelector);
  const { plan } = useSelector(authSelector);
  const getCategoriesOfThemeProduct = useGetCategoriesOfThemeProduct();

  const getTemplates = useGetThemeTemplates();
  const loadMoreTemplate = useLoadMoreThemeTemplates();
  const deleteTheme = useDeleteProductTheme();
  const getProductTheme = useGetProductTheme();
  const { role } = getUserInfo();
  // const changeThemeCategoryId = useChangeThemeCategoryId();
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();

  useEffect(() => {}, []);

  useEffect(() => {
    getCategoriesOfThemeProduct.request(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTemplates.request({ search: '', group: 'built_in', size: 50, categoryId: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInstall = (record: ProductTheme) => () => {
    if (validatePlan({ userPlan: plan.planHandle, itemPlan: record.planHandle ?? PLAN_ENUM.free, role }) === 'pass') {
      getProductTheme.request({
        commandId: record.commandId,
        type: 'built_in',
      });
    } else {
      setPlanRequesting({
        handle: PLAN_STATIC_DATA[record.planHandle ?? PLAN_ENUM.business].handle,
        type: PLAN_STATIC_DATA[record.planHandle ?? PLAN_ENUM.business].type,
        pricePerMonth: PLAN_STATIC_DATA[record.planHandle ?? PLAN_ENUM.business].pricePerMonth,
        pricePerYear: PLAN_STATIC_DATA[record.planHandle ?? PLAN_ENUM.business].pricePerYear,
        title: PLAN_STATIC_DATA[record.planHandle ?? PLAN_ENUM.business].title,
        originalPricePerMonth: PLAN_STATIC_DATA[record.planHandle ?? PLAN_ENUM.business].pricePerMonth,
        originalPricePerYear: PLAN_STATIC_DATA[record.planHandle ?? PLAN_ENUM.business].pricePerYear,
      });
      setValidateCouponVisible(true);
    }
  };

  const LoadMore = useMemo(() => {
    const cursor = at(templates, -1)?.commandId;
    if (cursor && hasNextPage) {
      return (
        <ViewportTracking
          onEnterViewport={() => {
            loadMoreTemplate.request({ categoryId: '', cursor, group: 'built_in', size: 50 });
          }}
        >
          <View css={{ display: 'flex', justifyContent: 'center', marginTop: '14px' }}>
            <ActivityIndicator size={40} />
          </View>
        </ViewportTracking>
      );
    }
    return null;
  }, [hasNextPage, loadMoreTemplate, templates]);

  const renderGetThemesSuccess = () => {
    return (
      <View>
        <GridSmart columnWidth={250} columnGap={20} columnCount={5}>
          {templates.map(item => (
            <ImageTextCard.Style2
              key={item.commandId}
              label={item.category?.name ?? item.label}
              description={item.label}
              heightImage={item.screenshot1 ? 330 : 320}
              widthImage={250}
              planHandle={item.planHandle}
              src={item?.screenshot1}
              onAdd={handleInstall(item)}
              buttonStyle="style2"
              disabled={item.isInstalled}
              loadingAdd={deleteStatus[item.commandId] === 'loading' || getThemeZipStatus[item.commandId] === 'loading'}
              previewText={'Preview'}
              deleteMessage="Đây là hành động xoá Theme Product, bạn có chắc chắn xoá không?"
              onDelete={
                role === 'admin'
                  ? () => {
                      deleteTheme.request({ commandId: item.commandId });
                    }
                  : undefined
              }
              onPreview={
                item.demoUrl
                  ? () => {
                      window.open(item.demoUrl, '_blank');
                    }
                  : undefined
              }
              onOpenDetail={
                item.documentUrl
                  ? () => {
                      window.open(item.documentUrl, '_blank');
                    }
                  : undefined
              }
              disabledIconSave
              showCheckIcon={item.isInstalled}
            />
          ))}
        </GridSmart>
        {LoadMore}
      </View>
    );
  };

  return (
    <View id="theme-templates-container">
      {/* <View css={{ width: '280px', marginBottom: '20px', display: 'flex' }}>
        <SelectAntd
          loading={getStatus === 'loading'}
          showArrow={false}
          allowClear
          filterOption={(input, option) => {
            return ((option?.children as unknown) as string).toLowerCase().includes(input.toLowerCase());
          }}
          value={categoryId === '' ? undefined : categoryId}
          onChange={val => {
            changeThemeCategoryId(val as string);
          }}
          showSearch
          data={categories.map(item => ({ label: item.title, value: item.commandId }))}
          placeholder={i18n.t('builderPage.search', { text: i18n.t('general.category') })}
        />
      </View> */}

      <AsyncComponent
        status={requestStatus}
        isEmpty={templates.length === 0}
        Request={
          <GridSmart columnWidth={250} columnGap={20} columnCount={5}>
            {[0, 1, 2, 3].map(item => (
              <ImageTextCard.Loading key={item} height={350} />
            ))}
          </GridSmart>
        }
        Success={renderGetThemesSuccess()}
      />
    </View>
  );
};

export default Content;
