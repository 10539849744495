import { ArticlePickerResult } from 'components/ArticlePicker/types';
import { put, retry, SagaReturnType } from 'redux-saga/effects';
import { shopifyArticleService } from 'services/ShopifyServices/Article';
import { BlogShopifyResponse } from 'services/ShopifyServices/Blog/types';
import { CollectionShopifyResponse } from 'services/ShopifyServices/Collection/types';
import { ShopifyProductResponse } from 'services/ShopifyServices/ProductService/types';
import { getDefaultPickerFieldRelateShopify } from 'store/actions/liquid/actionDefaultPickerFieldRelateShopify';
import { at } from 'utils/at';

interface Params {
  productResponse: ShopifyProductResponse;
  collectionResponse: CollectionShopifyResponse;
  blogResponse: BlogShopifyResponse;
}

export function* setDefaultPickerFieldRelateShopify({ blogResponse, collectionResponse, productResponse }: Params) {
  const firstProduct = at(productResponse.info, 0);
  const firstCollection = at(collectionResponse.info, 0);
  const firstBlog = at(blogResponse.info, 0);

  let article: ArticlePickerResult | undefined = undefined;
  if (firstBlog) {
    const response: SagaReturnType<typeof shopifyArticleService.getArticles> = yield retry(3, 500, shopifyArticleService.getArticles, {
      blogId: firstBlog?.id,
    });

    const firstArticle = response.info.find(article => article.id);
    if (firstArticle) {
      article = {
        blogHandle: firstBlog.handle,
        blogId: firstBlog.id,
        featuredImg: firstArticle.image?.src,
        handle: firstArticle.handle,
        itemId: firstArticle.id,
      };
    }
  }

  yield put(
    getDefaultPickerFieldRelateShopify.success({
      product: firstProduct
        ? { featuredImg: firstProduct.featuredImage?.src, handle: firstProduct.handle, itemId: Number(firstProduct.id) }
        : 'Không tồn tại',
      collection: firstCollection
        ? { featuredImg: firstCollection.image?.src, handle: firstCollection.handle, itemId: Number(firstCollection.id) }
        : 'Không tồn tại',
      blog: firstBlog ? { featuredImg: undefined, handle: firstBlog.handle, itemId: firstBlog.id } : 'Không tồn tại',
      article: article ? article : 'Không tồn tại',
    }),
  );
}
