import { Highlight } from 'components/Highlight';
import { Empty } from 'antd';
import { FC } from 'react';
import { View, Space, useTheme } from 'wiloke-react-core';
import { Button } from '@wiloke/ui';

interface StepInstallThemePendingProps {
  themeName?: string;
  onClickLink1: () => void;
  onClickLink2: () => void;
  onNextStep?: () => void;
}

export const StepInstallThemePendingShopify: FC<StepInstallThemePendingProps> = ({ themeName, onClickLink1, onClickLink2, onNextStep }) => {
  const { colors } = useTheme();
  return (
    <View css={{ fontSize: '16px' }}>
      <Space size={40} />
      <Empty description="" />
      <Space size={40} />
      <Highlight query={themeName ?? ''} highlightCss={{ color: colors.dark, fontWeight: '500' }}>
        {`You haven't installed the theme ${themeName ?? ''} to Shopify.`}
      </Highlight>
      <View>
        Click{' '}
        <View
          tagName="span"
          color="primary"
          css={{
            fontWeight: '500',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={onClickLink1}
        >
          here
        </View>{' '}
        to know how to upload. Otherwise, click{' '}
        <View
          tagName="span"
          color="primary"
          css={{
            fontWeight: '500',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={onClickLink2}
        >
          here
        </View>{' '}
        to go to Shopify Themes area.
      </View>

      <View css={{ marginTop: '20px' }}>
        <Button radius={4} size="extra-small" onClick={onNextStep}>
          Next Step
        </Button>
      </View>
    </View>
  );
};
