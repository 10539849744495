import { delay } from '@wiloke/functions';
import { BE_PageInThemeAtomNDraft } from 'services/ThemeService/Atom/types';
import { getLiquidVariablesWithPageData } from 'store/actions/liquid/actionLiquidVariables';
import { LiquidVariablesState } from 'store/reducers/liquid/reducerLiquidVariables';
import { PageSection } from 'types/Sections';
import { ToRequiredKeys } from 'utils';
import { getBuilderPageReduxStore } from 'utils/getParentStore';
import { getMegamenus } from './getMegamenus';
import { getPage } from './getPage';
import { getSections } from './getSections';
import { getLiquidVariablesCacheKey } from './utils/CacheKeyControl';
import { pushToCache, readFromCache } from './utils/CacheStorage';
import { BaseParams } from './@types/BaseParams';

interface GetLiquidVariables extends Pick<BaseParams, 'entityVariant'> {
  cacheKeysOfSections: Awaited<ReturnType<typeof getSections>>;
  cacheKeysOfMegamenusInSections: Awaited<ReturnType<typeof getMegamenus>>;
  cacheKeyOfPage: Awaited<ReturnType<typeof getPage>>;
}

const getLiquidVariablesFromRedux = async ({
  cacheKeyOfPage,
  cacheKeysOfSections,
  cacheKeysOfMegamenusInSections,
  entityVariant,
}: GetLiquidVariables) => {
  const page_ = await readFromCache<ToRequiredKeys<BE_PageInThemeAtomNDraft, 'pageSettings'>>(cacheKeyOfPage.pageKey);
  const sections_ = await Promise.all(cacheKeysOfSections.sectionKeys.map(cacheKey => readFromCache<PageSection>(cacheKey)));
  const megamenus_ = await Promise.all(cacheKeysOfMegamenusInSections.map(cacheKey => readFromCache<PageSection>(cacheKey)));

  const page = page_ as ToRequiredKeys<BE_PageInThemeAtomNDraft, 'pageSettings'>;
  const sections = sections_ as PageSection[];
  const megamenus = megamenus_ as PageSection[];

  return new Promise<LiquidVariablesState>(resolve => {
    const unsubscribe = getBuilderPageReduxStore().subscribe(async () => {
      const { statusGetLiquidVariablesWithPageData } = getBuilderPageReduxStore().getState().liquidVariables;
      if (statusGetLiquidVariablesWithPageData === 'success') {
        resolve(getBuilderPageReduxStore().getState().liquidVariables);
        unsubscribe();
      }
      if (statusGetLiquidVariablesWithPageData === 'failure') {
        await delay(1000);
        getBuilderPageReduxStore().dispatch(
          getLiquidVariablesWithPageData.request({
            page: {
              ...page,
              sections: [...sections, ...megamenus],
              enable: !!page.enable,
              entityType: entityVariant === 'Atom' ? 'ThemeAtom' : 'ThemeDraft',
              addedInThemes: [],
            },
          }),
        );
      }
    });
    getBuilderPageReduxStore().dispatch(
      getLiquidVariablesWithPageData.request({
        page: {
          ...page,
          sections: [...sections, ...megamenus],
          enable: !!page.enable,
          entityType: entityVariant === 'Atom' ? 'ThemeAtom' : 'ThemeDraft',
          addedInThemes: [],
        },
      }),
    );
  });
};

const getLiquidVariables_ = async (params: GetLiquidVariables) => {
  const cacheKey = getLiquidVariablesCacheKey();
  const data = await getLiquidVariablesFromRedux(params);
  await pushToCache(JSON.stringify(data), cacheKey);
  return cacheKey;
};

export const getLiquidVariables = async (params: GetLiquidVariables, isRetry: boolean) => {
  const cacheKey = getLiquidVariablesCacheKey();
  if (isRetry) {
    const dataFromCache = readFromCache<LiquidVariablesState>(cacheKey);
    if (dataFromCache) {
      return dataFromCache;
    } else {
      return getLiquidVariables_(params);
    }
  }
  return getLiquidVariables_(params);
};
