import { put, retry, takeLatest } from '@redux-saga/core/effects';
import { AxiosError } from 'axios';
import { addDraftCategory } from 'containers/ChooseTemplate/store/actions';
import { sectionService } from 'services/SectionService';

import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';

function* handleCreateCategory({ payload: { description, name } }: ReturnType<typeof addDraftCategory.request>) {
  try {
    const response: Awaited<ReturnType<typeof sectionService.categories.createCategoryOfDraft>> = yield retry(
      3,
      1000,
      sectionService.categories.createCategoryOfDraft,
      { description, name },
    );
    yield put(
      addDraftCategory.success({
        count: 0,
        commandId: response.info.commandId,
        name: response.info.name,
        description: response.info.description,
      }),
    );
    notifyAxiosHandler.handleSuccess(response.message);
  } catch (error) {
    const err = error as AxiosError<{ message: string }>;
    const message = err.response?.data?.message || '';
    yield put(addDraftCategory.failure({ message }));
  }
}

export function* watchCreateDraftCategory() {
  yield takeLatest(getActionType(addDraftCategory.request), handleCreateCategory);
}
