import { getLinkType } from 'components/LinkPicker/utils/getLinkType';
import { createContext, FC, useContext, useEffect, useReducer } from 'react';
import { LinkPickerProps, PickerType } from '../../types';
import { Action, reducerLinkPicker } from './reducer';
import { useDeepCompareEffect } from 'react-use';

interface ILinkPickerContext {
  label?: string;
  summary?: string;
  value: string;
  type: PickerType;
  AfterLabel?: LinkPickerProps['AfterLabel'];
  dispatch: React.Dispatch<Action>;
  visibleShopify: boolean;
  visibleEmail: boolean;
}

const LinkPickerContext = createContext<ILinkPickerContext | null>(null);

export const useLinkPicker = () => {
  const context = useContext(LinkPickerContext);
  if (context === null) {
    throw new Error('Error connecting to the Context');
  }
  return context;
};

export const LinkPickerProvider: FC<LinkPickerProps> = ({ value, label, summary, AfterLabel, children, onChange }) => {
  const [state, dispatch] = useReducer(reducerLinkPicker, { value, type: getLinkType(value), visibleShopify: false, visibleEmail: false });

  useEffect(() => {
    if (state.value !== value) {
      dispatch({
        type: 'setSettings',
        payload: {
          value,
          type: getLinkType(value),
          label: '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useDeepCompareEffect(() => {
    if (state.value !== value) {
      onChange?.(state.value, state.label, state.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [[state.value, state.label, state.type]]);

  return (
    <LinkPickerContext.Provider
      value={{
        value: state.value,
        type: state.type,
        dispatch,
        label,
        summary,
        AfterLabel,
        visibleEmail: state.visibleEmail ?? false,
        visibleShopify: state.visibleShopify ?? false,
      }}
    >
      {children}
    </LinkPickerContext.Provider>
  );
};
