import { Button, MyModal } from '@wiloke/ui';
import { notification } from 'antd';
import ChooseImage from 'components/ChooseImage';
import Field from 'components/Field';
import SelectAntd from 'components/SelectAntd';
import SwitchBeauty from 'components/SwitchBeauty';
import TextInput from 'components/TextInput';
import { useSaveAtomMegaMenu, useSaveAtomSection } from 'containers/BuilderPage/store/saveForBuilder/actions';
import { modalAdminSectionVisibleSelector, useSetModalAdminSectionVisible } from 'containers/BuilderPage/store/saveForBuilder/slice';
import {
  useAddAdminCategory,
  useCreateAdminMegaMenuChangelog,
  useCreateAdminSectionChangelog,
  useGetAdminCategories,
  useLoadMoreAdminCategories,
  useSetCreateSectionChangelogStatus,
} from 'containers/ChooseTemplate/store/actions';
import { adminCategorySelector } from 'containers/ChooseTemplate/store/reducers/sections/admin.reducerCategory';
import withDebounce from 'hocs/withDebounce';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { megaMenuSelector, saveForBuilderSelector, sectionsSelector } from 'store/selectors';
import { i18n } from 'translation';
import { AdminSection, PageSectionType } from 'types/Sections';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { Divider, Space, View } from 'wiloke-react-core';
import { useSaveInfoSection } from './utils/useSaveInfoSection';

const DebounceInput = withDebounce(TextInput, 'value', 'onValueChange');

const defaultSectionTypes = [
  {
    value: 'default',
    label: 'Default',
  },
  {
    value: 'megamenu',
    label: 'Mega menu',
  },
];

export function compareVersion(oldVersion: number, newVersion: number) {
  return newVersion > oldVersion;
}

export function _compareVersionsV2(version1: string, version2: string) {
  const version1Arr = version1.split('.');
  const version2Arr = version2.split('.');

  for (let i = 0; i < 3; i++) {
    const num1 = parseInt(version1Arr[i] || '0');
    const num2 = parseInt(version2Arr[i] || '0');

    if (num1 < num2) {
      return -1;
    } else if (num1 > num2) {
      return 1;
    }
  }

  return 0;
}

const MAX_CHARACTER = 25;

export const ModalAdminSection = () => {
  const modalSectionVisible = useSelector(modalAdminSectionVisibleSelector);
  const { saveSectionStatus } = useSelector(saveForBuilderSelector);
  const {
    addStatus,
    categories: adminCategories,
    getStatus: getCateStatus,
    loadMoreStatus: loadMoreCateStatus,
    hasNextPage: hasNextPageCate,
  } = useSelector(adminCategorySelector);
  const { createChangelogStatus } = useSelector(sectionsSelector.adminSections);
  const { createChangelogStatus: createChangelogMegaMenuStatus } = useSelector(megaMenuSelector.adminMegaMenu);

  const { id } = getUserInfo();

  const setModalSectionVisible = useSetModalAdminSectionVisible();
  const saveSectionForBuilder = useSaveAtomSection();
  const addCategory = useAddAdminCategory();
  const getCategories = useGetAdminCategories();
  const createChangelog = useCreateAdminSectionChangelog();
  const createChangelogMegaMenu = useCreateAdminMegaMenuChangelog();
  const saveAtomMegaMenu = useSaveAtomMegaMenu();

  const loadMoreAdminCategories = useLoadMoreAdminCategories();
  const setCreateSectionChangelogStatus = useSetCreateSectionChangelogStatus();

  const { category, createdCategory, image, label, methodType, section, type, changelog, version, dispatch } = useSaveInfoSection();

  const [error, setError] = useState('');

  useEffect(() => {
    if (modalSectionVisible) {
      getCategories.request(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalSectionVisible]);

  const handleSaveSection = () => {
    if (section) {
      const _cate = adminCategories.find(item => item.name === category.name);
      setError('');
      const newSection: AdminSection = {
        ...section,
        label,
        image,
        id: section.id,
        category:
          type !== 'megamenu'
            ? {
                name: _cate ? _cate.name : category.name,
                description: _cate ? _cate.description : category.description,
                commandId: _cate ? _cate.commandId : section?.category?.commandId ?? '',
              }
            : undefined,
        type: type,
        userId: methodType === 'create' ? id : section.userId ? section.userId : id,
        currentVersion: methodType === 'create' ? '1.0.0' : version,
      };

      if (type === 'megamenu') {
        saveAtomMegaMenu.request({ type: methodType, data: newSection });
      } else {
        saveSectionForBuilder.request({ type: methodType, data: newSection });
      }
    }
  };

  const handleAddCategory = () => {
    if (createdCategory.name && createdCategory.description) {
      addCategory.request({ description: createdCategory.description, name: createdCategory.name });
      dispatch({
        type: 'setInfoSection',
        payload: {
          createdCategory: {
            description: '',
            name: '',
          },
        },
      });
    }
  };

  const handleChangeCategory = (val: string, option: any) => {
    dispatch({
      type: 'setInfoSection',
      payload: {
        category: {
          description: option.children,
          name: val,
        },
      },
    });
  };

  const handleCreateChangelog = () => {
    if (section && version) {
      if (type === 'megamenu') {
        createChangelogMegaMenu.request({
          content: changelog,
          version: version,
          versionId: section.commandId,
        });
      } else {
        createChangelog.request({
          content: changelog,
          version: version,
          versionId: section.commandId,
        });
      }
    }
  };

  const handleCancel = () => {
    if (type === 'megamenu') {
      saveAtomMegaMenu.cancel();
    } else {
      saveSectionForBuilder.cancel();
    }
    setError('');
    setModalSectionVisible(false);
    setCreateSectionChangelogStatus('idle');
  };

  const handleChangeMethodType = (value: boolean) => {
    if (!section?.commandId) {
      dispatch({
        type: 'setInfoSection',
        payload: {
          methodType: 'create',
        },
      });

      // TODO: I18n
      notification.error({
        message: 'Không đúng nghiệp vụ',
        description: 'Bạn đang install section để tạo mới chứ không phải update',
      });
    } else {
      dispatch({
        type: 'setInfoSection',
        payload: {
          methodType: value ? 'create' : 'update',
        },
      });
    }
  };

  const handleChangeTitle = (val: string) => {
    if (val.length <= MAX_CHARACTER) {
      dispatch({
        type: 'setInfoSection',
        payload: {
          label: val,
        },
      });
    }
  };

  const handleChangeCreateCategory = (val: string) => {
    dispatch({
      type: 'setInfoSection',
      payload: {
        createdCategory: {
          description: val,
          name: val ? val : '',
        },
      },
    });
  };

  const handleSetSectionType = (val: any) => {
    dispatch({
      type: 'setInfoSection',
      payload: {
        type: val as PageSectionType,
      },
    });
  };

  const handleChangeVersion = (val: string) => {
    if (section) {
      const comparison = _compareVersionsV2(section.currentVersion, val);

      if (val === '') {
        setError('Cannot be blank');
      } else if (comparison < 0) {
        setError('');
        dispatch({
          type: 'setInfoSection',
          payload: {
            version: val,
          },
        });
      } else if (comparison > 0) {
        setError('The new version must be different from the old version');
      } else {
        setError('The new version must be different from the old version');
      }
    }
  };
  const handleChangelog = (val: string) => {
    dispatch({
      type: 'setInfoSection',
      payload: {
        changelog: val,
      },
    });
  };

  const handleChangeImage = ({ src, width, height }: { src: string; width: number; height: number }) => {
    dispatch({
      type: 'setInfoSection',
      payload: {
        image: {
          src: src,
          width: width ?? 0,
          height: height ?? 0,
        },
      },
    });
  };

  return (
    <MyModal
      size="medium"
      isLoading={saveSectionStatus === 'loading'}
      headerText={`${i18n.t('general.save', { text: i18n.t('general.section') })}`}
      depsHeightRecalculation={section}
      isVisible={modalSectionVisible}
      onCancel={handleCancel}
      onOk={handleSaveSection}
      okText={i18n.t('general.save', { text: 'to admin database' })}
    >
      <View css={{ margin: 'auto', padding: '20px 0' }}>
        <Field label={i18n.t('general.createNewSection')}>
          <SwitchBeauty
            checked={methodType === 'create'}
            radius={6}
            borderColor="gray3"
            borderWidth={1}
            enableText={i18n.t('general.enable')}
            disableText={i18n.t('general.disable')}
            onValueChange={handleChangeMethodType}
          />
        </Field>

        <Field
          label={
            <View>
              {i18n.t('general.title')}{' '}
              <View tagName="span">
                (
                <View tagName="i">
                  Max Length: {label.length}/{MAX_CHARACTER}
                </View>
                )
              </View>
            </View>
          }
        >
          <DebounceInput value={label} block sizeInput="medium" maxLength={MAX_CHARACTER} onValueChange={handleChangeTitle} />
        </Field>

        {type !== 'megamenu' ? (
          <Field label={i18n.t('general.category')}>
            <SelectAntd
              data={adminCategories.map(item => ({ value: item.name, label: item.description }))}
              value={category?.name}
              onChange={handleChangeCategory}
              dropdownRender={menu => {
                return (
                  <>
                    {menu}
                    <Divider />
                    <View css={{ padding: '8px 0 4px 0', display: 'flex', justifyContent: 'space-between' }}>
                      <View columns={[10, 10, 10]}>
                        <DebounceInput
                          value={createdCategory.name}
                          block
                          placeholder={i18n.t('builderPage.save_for_builder.please_enter_item')}
                          sizeInput="small"
                          css={{ width: '100%', height: '38px' }}
                          onValueChange={handleChangeCreateCategory}
                        />
                      </View>
                      <View columns={[2, 2, 2]}>
                        <Button block radius={4} size="extra-small" loading={addStatus === 'loading'} onClick={handleAddCategory}>
                          {i18n.t('builderPage.save_for_builder.add_category')}
                        </Button>
                      </View>
                    </View>
                  </>
                );
              }}
              loading={loadMoreCateStatus === 'loading'}
              onPopupScroll={e => {
                const { scrollHeight, scrollTop, clientHeight } = e.target as Element;
                const lastCursor = adminCategories.at(-1)?.commandId;
                if (scrollHeight - scrollTop - clientHeight < 300 && getCateStatus === 'success' && lastCursor && hasNextPageCate) {
                  loadMoreAdminCategories.request({ cursor: lastCursor });
                }
              }}
              filterOption={(input, option) => {
                return ((option?.children as unknown) as string).toLowerCase().includes(input.toLowerCase());
              }}
              showSearch
            />
          </Field>
        ) : null}

        <Field label={i18n.t('general.type')}>
          <SelectAntd data={defaultSectionTypes} value={type} onChange={handleSetSectionType} />
        </Field>

        {!!section?.commandId && methodType === 'update' && (
          <Field label={i18n.t('general.changelog')}>
            <TextInput value={version} block sizeInput="medium" borderColor={!!error ? 'danger' : 'gray3'} onValueChange={handleChangeVersion} />
            {error ? (
              <View tagName="span" color="danger">
                {error}
              </View>
            ) : null}
            <Space size={8} />
            <DebounceInput placeholder={i18n.t('general.content')} value={changelog} block sizeInput="medium" onValueChange={handleChangelog} />
            <Space size={8} />
            <Button
              size="extra-small"
              radius={4}
              loading={createChangelogStatus === 'loading' || createChangelogMegaMenuStatus === 'loading'}
              onClick={handleCreateChangelog}
            >
              {i18n.t('general.create')} {i18n.t('general.changelog')}
            </Button>
          </Field>
        )}

        <Field label={i18n.t('builderPage.save_for_builder.preview_image')} width={400}>
          <ChooseImage value={image} onChange={handleChangeImage} mode="popup" />
        </Field>
      </View>
    </MyModal>
  );
};
