export const getPageCacheKey = (commandId: string) => `page_${commandId}`;
export const getSectionCacheKey = (commandId: string) => `section_${commandId}`;
export const getAddonCacheKey = (commandId: string) => `addon_${commandId}`;
export const getMegamenuCacheKey = (commandId: string) => `megamenu_${commandId}`;
export const getThemeCacheKey = (commandId: string) => `theme_${commandId}`;
export const getLiquidVariablesCacheKey = () => `liquidVariables`;

export const getExtractResultOfAddonsCacheKey = () => 'extract_result_of_addons';
export const getExtractResultOfPageCacheKey = (commandId: string) => `extract_result_of_page_${commandId}`;
export const getExtractResultOfThemeCacheKey = (commandId: string) => `extract_result_of_theme_${commandId}`;
export const getExtractResultReadyForSyncCacheKey = () => `extract_result_ready_for_sync`;
