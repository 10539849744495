import { FC } from 'react';
import { FontAwesome, FontAwesomeName, View, ViewProps } from 'wiloke-react-core';
import * as styles from './styles';
import { BadgeWithImage } from './WithImage';

export interface BadgeProps {
  text: string;
  iconName?: FontAwesomeName;
  active?: boolean;
  onClick?: (text?: string) => void;
  onDelete?: (text?: string) => void;
  containerCss?: ViewProps['css'];
}

interface Variants {
  WithImage: typeof BadgeWithImage;
}

export const Badge: FC<BadgeProps> & Variants = ({ text, iconName, active = false, containerCss, onClick, onDelete }) => {
  return (
    <View
      css={[styles.container, containerCss]}
      onClick={() => {
        onClick?.(text);
      }}
    >
      {iconName ? <FontAwesome type="far" name={iconName} color={active ? 'primary' : 'gray7'} /> : null}
      <View fontFamily="secondary" css={styles.text(active)}>
        {text}
      </View>
      <FontAwesome
        type="far"
        name="times"
        onClick={() => {
          onDelete?.(text);
        }}
        css={styles.deleteIcon}
        color="gray5"
      />
    </View>
  );
};

Badge.WithImage = BadgeWithImage;
