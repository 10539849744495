import { getPartnerApps } from 'containers/Admin/AppsPartner/store/action';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { recommendAppsService } from 'services/RecommednApps/RecommendApps';
import { getActionType } from 'wiloke-react-core/utils';

function* handleGet({ payload: { categoryId, search } }: ReturnType<typeof getPartnerApps.request>) {
  try {
    const _categoryId = categoryId === 0 ? undefined : categoryId;
    const response: SagaReturnType<typeof recommendAppsService.getPartnerApps> = yield retry(
      3,
      1000,
      recommendAppsService.getPartnerApps,
      _categoryId,
      search,
    );
    const _response = response.filter(item => item.slug !== 'veda-landing-page-builder');
    yield put(getPartnerApps.success({ data: _response }));
  } catch (error) {
    yield put(getPartnerApps.failure(undefined));
  }
}

export function* watchGetPartnerApps() {
  yield takeLatest(getActionType(getPartnerApps.request), handleGet);
}
