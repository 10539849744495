import { all, call, put, retry, SagaReturnType } from 'redux-saga/effects';
import { adapterGetAddons } from 'services/AddonService/Adapters/adapterGetAddons';
import { getChangelogsOfAtoms as getChangelogsOfAddonAtoms } from 'services/AddonService/Logic/Changelogs';
import { getChangelogsOfAtoms as getChangelogsOfMegamenuAtoms } from 'services/MegaMenuService/Logic/Changelogs';
import { getChangelogsOfAtoms as getChangelogsOfSectionAtoms } from 'services/SectionService/Logic/Changelogs';
import { getAddonsOfThemeClient } from 'services/ThemeService/Logic/getAddonsOfThemeClient';
import { getHeaderOrFooterSectionsOfThemeClient } from 'services/ThemeService/Logic/getHeaderOrFooterSectionsOfThemeClient';
import { getMegamenusOfSectionInThemeClient } from 'services/ThemeService/Logic/getMegamenusOfSectionInThemeClient';
import { getPagesOfThemeClient } from 'services/ThemeService/Logic/getPagesOfThemeClient';
import { getThemeClient } from 'services/ThemeService/Logic/getThemeClient';
import { setLayoutSettings } from 'store/actions/actionLayoutSettings';
import { getPage, setPages } from 'store/actions/actionPages';
import { getAddonVersion, getSectionVersion } from 'store/actions/versions/actionSectionVersion';
import { updateCssVariables } from 'store/global/cssVariables/slice';
import { setGlobalThemeJs } from 'store/global/globalThemeJs/slice';
import { setGlobalThemeScss } from 'store/global/globalThemeScss/slice';
import { setFileLanguageActive, setGlobalThemeTranslation } from 'store/global/globalTranslation/slice';
import { setMegaMenusOfHeaderFooter } from 'store/global/megaMenusOfHeaderFooter';
import { getThemeAddons } from 'store/global/themeAddons/actions';
import { getThemeFooters, getThemeHeaders } from 'store/global/themeHeaderFooter/action';
import { setThemeGeneralSettings } from 'store/global/themeSettings/slice';
import { setThemeVendors } from 'store/global/themeVendors/slice';
import { Page } from 'types/Page';
import getPageInfo from 'utils/functions/getInfo';
import { customLog, reorder } from '@wiloke/functions';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { actionGetCurrentTheme } from '../../../actions';
import { PageSection } from 'types/Sections';
import { setColorSchemes } from 'store/global/colorSchemes/slice';
import { setThemeVersion } from 'store/global/themeVersion/slice';

export function* handleGetMegamenuOfSections(sections: PageSection[]) {
  /** Xử lý lấy megamenu của header, footer, addon */
  const megamenuResponses: Array<Awaited<ReturnType<typeof getMegamenusOfSectionInThemeClient>> | undefined> = yield all(
    sections.map(section => {
      const { megaMenuCommandIds } = section;
      if (megaMenuCommandIds && megaMenuCommandIds.length) {
        return retry(3, 1000, getMegamenusOfSectionInThemeClient, { megamenuCommandIds: megaMenuCommandIds });
      }
      return [];
    }),
  );

  yield all(
    megamenuResponses.map(megamenuResponse => {
      if (megamenuResponse && megamenuResponse.length > 0) {
        return put(setMegaMenusOfHeaderFooter(megamenuResponse));
      }
    }),
  ); /** Xử lý megamenu version */
  const megamenuSectionSourceIds = Array.from(
    new Set(
      megamenuResponses.reduce<string[]>((res, megamenuResponse) => {
        if (megamenuResponse) {
          megamenuResponse.map(megamenuSection => {
            if ('parentCommandId' in megamenuSection && megamenuSection.parentCommandId) {
              res.push(megamenuSection.parentCommandId);
            }
          });
        }
        return res;
      }, []),
    ),
  );
  yield all(
    megamenuSectionSourceIds.map(sectionSectionCommandId => {
      return put(getSectionVersion.request({ sectionCommandId: sectionSectionCommandId }));
    }),
  );
  const megamenuSectionsVersion: SagaReturnType<typeof getChangelogsOfMegamenuAtoms> = yield retry(
    3,
    1000,
    getChangelogsOfMegamenuAtoms,
    megamenuSectionSourceIds,
  );
  yield all(
    megamenuSectionsVersion.map(({ atomCommandId, version }) => put(getSectionVersion.success({ sectionCommandId: atomCommandId, data: version }))),
  );
}

export function* handleGetFooterSections(responseTheme: Awaited<ReturnType<typeof getThemeClient>>) {
  /** Xử lý lấy footer */
  const footerResponse: Awaited<ReturnType<typeof getHeaderOrFooterSectionsOfThemeClient>> = yield retry(
    3,
    1000,
    getHeaderOrFooterSectionsOfThemeClient,
    { commandIds: responseTheme.footerSectionCommandIds },
  );
  customLog('handleGetFooterSections', `Số lượng footer: ${footerResponse.length}`);
  const footers = footerResponse;
  yield put(getThemeFooters.success({ footers }));

  /** Xử lý footer sections version */
  const footerSectionSourceIds = Array.from(
    new Set(
      footerResponse.reduce<string[]>((res, footerSection) => {
        if ('parentCommandId' in footerSection && footerSection.parentCommandId) {
          return res.concat(footerSection.parentCommandId);
        }
        return res;
      }, []),
    ),
  );
  yield all([
    call(handleGetMegamenuOfSections, footers),
    ...footerSectionSourceIds.map(footerSectionCommandId => {
      return put(getSectionVersion.request({ sectionCommandId: footerSectionCommandId }));
    }),
  ]);
  const footerSectionsVersion: SagaReturnType<typeof getChangelogsOfSectionAtoms> = yield retry(
    3,
    1000,
    getChangelogsOfSectionAtoms,
    footerSectionSourceIds,
  );
  yield all(
    footerSectionsVersion.map(({ atomCommandId, version }) => put(getSectionVersion.success({ sectionCommandId: atomCommandId, data: version }))),
  );
}

export function* handleGetHeaderSections(responseTheme: Awaited<ReturnType<typeof getThemeClient>>) {
  /** Xử lý lấy header */
  const headerResponse: Awaited<ReturnType<typeof getHeaderOrFooterSectionsOfThemeClient>> = yield retry(
    3,
    1000,
    getHeaderOrFooterSectionsOfThemeClient,
    { commandIds: responseTheme.headerSectionCommandIds },
  );
  customLog('handleGetHeaderSections', `Số lượng header: ${headerResponse.length}`);
  const headers = headerResponse;

  yield put(getThemeHeaders.success({ headers }));

  /** Xử lý header sections version */
  const headerSectionSourceIds = Array.from(
    new Set(
      headerResponse.reduce<string[]>((res, headerSection) => {
        if ('parentCommandId' in headerSection && headerSection.parentCommandId) {
          return res.concat(headerSection.parentCommandId);
        }
        return res;
      }, []),
    ),
  ) as string[];
  yield all([
    call(handleGetMegamenuOfSections, headers),
    ...headerSectionSourceIds.map(headerSectionCommandId => {
      return put(getSectionVersion.request({ sectionCommandId: headerSectionCommandId }));
    }),
  ]);
  const headerSectionsVersion: SagaReturnType<typeof getChangelogsOfSectionAtoms> = yield retry(
    3,
    1000,
    getChangelogsOfSectionAtoms,
    headerSectionSourceIds,
  );
  yield all(
    headerSectionsVersion.map(({ atomCommandId, version }) => put(getSectionVersion.success({ sectionCommandId: atomCommandId, data: version }))),
  );
}

function* handleGetAddons(responseTheme: Awaited<ReturnType<typeof getThemeClient>>) {
  /** Xử lý lấy addon */
  const addonResponse: Awaited<ReturnType<typeof getAddonsOfThemeClient>> = yield retry(3, 1000, getAddonsOfThemeClient, {
    addonCommandIds: responseTheme.addonCommandIds,
  });
  const addons = adapterGetAddons(addonResponse);
  yield put(getThemeAddons.success({ addons }));

  /** Xử lý addon addons version */
  const addonSectionSourceIds = Array.from(
    new Set(
      addonResponse.reduce<string[]>((res, addonSection) => {
        if (addonSection.parentCommandId) {
          return res.concat(addonSection.parentCommandId);
        }
        return res;
      }, []),
    ),
  );
  yield all(
    addonSectionSourceIds.map(addonSectionCommandId => {
      return put(getAddonVersion.request({ addonCommandId: addonSectionCommandId }));
    }),
  );
  const addonSectionsVersion: SagaReturnType<typeof getChangelogsOfAddonAtoms> = yield retry(
    3,
    1000,
    getChangelogsOfAddonAtoms,
    addonSectionSourceIds,
  );
  yield all(addonSectionsVersion.map(({ atomCommandId, version }) => put(getAddonVersion.success({ addonCommandId: atomCommandId, data: version }))));
}

function* handleGetPageSkeleton(responseTheme: Awaited<ReturnType<typeof getThemeClient>>) {
  const pageId = getPageInfo('id');
  /** Xử lý lấy thông tin page */
  const pageCommandIds = responseTheme.pageCommandIds;
  const pagesResponse: Awaited<ReturnType<typeof getPagesOfThemeClient>> = yield retry(3, 1000, getPagesOfThemeClient, {
    commandIds: pageCommandIds,
  });

  // Đảo vị trí của page theo pageId ở trên params
  const matchPageIndex = pagesResponse.findIndex(page => page.commandId === pageId);
  const pagesAfterReorder = reorder(pagesResponse, matchPageIndex, 0);

  const pages = pagesAfterReorder.reduce<Record<string, Page>>((acc, page) => {
    return {
      ...acc,
      [page.commandId]: {
        ...page,
        sections: [],
        // page của theme k thể disable đi đc
        enable: true,
        themeAtomCommandId: responseTheme.parentCommandId,
        entityType: 'Client',
      } as Page,
    };
  }, {});

  yield put(setPages(pages));
}

export function* handleGetThemeClient({ payload }: ReturnType<typeof actionGetCurrentTheme.request>) {
  const { themeId, variant, onFulfill, onDisconnectSocketEarly } = payload;
  const pageId = getPageInfo('id');
  try {
    /** Xử lý lấy thông tin của theme */
    const responseTheme: Awaited<ReturnType<typeof getThemeClient>> = yield retry(3, 500, getThemeClient, { commandId: themeId });

    yield all([
      call(handleGetPageSkeleton, responseTheme),
      call(handleGetFooterSections, responseTheme),
      call(handleGetHeaderSections, responseTheme),
      call(handleGetAddons, responseTheme),
    ]);

    if (
      responseTheme.themeSettings !== undefined &&
      responseTheme.vendors !== undefined &&
      responseTheme.globalJs !== undefined &&
      responseTheme.globalScss !== undefined
    ) {
      const { cssVariables, generalSettings, layoutSettings, globalTranslations } = responseTheme.themeSettings;
      const { colors, fonts } = cssVariables;

      yield put(setColorSchemes({ colorSchemes: responseTheme.themeSettings.colorSchemes }));
      yield put(setThemeVersion({ version: responseTheme.version }));
      yield put(setLayoutSettings(layoutSettings));
      yield put(updateCssVariables({ colors, fonts }));
      yield put(setThemeGeneralSettings(generalSettings));
      yield put(setGlobalThemeScss(responseTheme.globalScss));
      yield put(setGlobalThemeJs(responseTheme.globalJs));
      yield put(setThemeVendors({ vendors: responseTheme.vendors }));
      yield put(setGlobalThemeTranslation(globalTranslations.translation));
      yield put(
        setFileLanguageActive(globalTranslations.languageActive ? globalTranslations.languageActive : Object.keys(globalTranslations.translation)[0]),
      );
    }

    yield put(actionGetCurrentTheme.success({ themeId: responseTheme.commandId }));

    yield put(
      getPage.request({
        id: pageId,
        variant,
        themeAtomCommandId: responseTheme.parentCommandId,
        onFulfill,
        onDisconnectSocketEarly,
      }),
    );
  } catch (error) {
    const error_ = error as Error;
    notifyAxiosHandler.handleError(error_);
    yield put(actionGetCurrentTheme.failure({ themeId }));
    yield put(getThemeHeaders.failure(undefined));
    yield put(getThemeFooters.failure(undefined));
    yield put(getThemeAddons.failure(undefined));
  }
}
