import { MyModal } from '@wiloke/ui';
import { useSelector } from 'react-redux';
import { upgradeOrChangePlanSelector, useSetVisibleUpgradePlan } from './sliceUpgradePlan';

const PRICING_URL = 'https://app.vedabuilder.com/pricing';

export const ModalUpgradePlan = () => {
  const { visibleUpgradePlan, messageUpgradePlan } = useSelector(upgradeOrChangePlanSelector);
  const setVisible = useSetVisibleUpgradePlan();

  const handleCancel = () => {
    setVisible({ visible: false });
  };

  return (
    <MyModal
      headerText="Upgrade Plan"
      isVisible={visibleUpgradePlan}
      okText="Upgrade now"
      onCancel={handleCancel}
      onOk={() => {
        window.open(PRICING_URL, '_blank', 'noopener,noreferrer');
      }}
    >
      {messageUpgradePlan}
    </MyModal>
  );
};
