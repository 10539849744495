import { addonApiController } from 'services/AddonService';
import { userApis } from 'services/UserService/apis';
import { ProductAddon } from 'types/Addons';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const publishAtomToProduct = async (addon: ProductAddon) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    if (addon.parentCommandId) {
      const searchResponse = await addonApiController.product.adminApi.addons.getProductsWithParentCommandIds({
        parentCommandIds: addon.parentCommandId,
        cacheVersion: lastCache,
      });
      const itemExisted = searchResponse.info.find(item => item.parentCommandId === addon.parentCommandId);
      if (!!itemExisted) {
        const response = await addonApiController.product.adminApi.addons.updateProduct({
          addon: {
            ...itemExisted,
            category: addon.category,
            planHandle: addon.planHandle ?? itemExisted.planHandle,
            currentVersion: addon.currentVersion,
            authorName: addon.authorName,
            image: addon.image,
            body: addon.body,
            logo: addon.logo,
            detail: addon.detail,
            label: addon.label,
            priorityOrder: addon.priorityOrder,
            searchTerms: addon.searchTerms,
          },
        });
        await userApis.user.adminApi.updateVedaCacheVersion();
        return response;
      } else {
        const response = await addonApiController.product.adminApi.addons.createProduct({
          addon: {
            ...addon,
            commandId: undefined,
          },
        });
        await userApis.user.adminApi.updateVedaCacheVersion();
        return response;
      }
    } else {
      const response = await addonApiController.product.adminApi.addons.createProduct({
        addon: {
          ...addon,
          commandId: undefined,
        },
      });
      await userApis.user.adminApi.updateVedaCacheVersion();
      return response;
    }
  }
  throw new RoleException();
};
