import { FC, ReactNode } from 'react';
import { Text, View, ViewProps, WithStylesProps } from 'wiloke-react-core';
import * as css from './styles';

export interface MenuItem {
  id: string;
  label: string;
  hasDivider?: boolean;
  textHeader?: string;
  counter: number;
}

export interface NavigationProps {
  data: MenuItem[];
  containerCss?: ViewProps['css'];
  expandContent?: ReactNode;
  idActive: string | number;
  onClick?: (item: MenuItem) => void;
}

export const NavigationNoneLink: FC<NavigationProps> = ({ data, containerCss, idActive, expandContent, onClick }) => {
  const linkProps: Pick<WithStylesProps, 'color' | 'colorHover' | 'css'> = {
    css: [css.link, { cursor: 'pointer' }],
    color: 'gray8',
    colorHover: 'primary',
  };

  const renderLink = (item: MenuItem): ReactNode => {
    const { id, label, counter } = item;

    const isActive = item.id === idActive.toString();

    const anchorContent = (
      <>
        <View css={css.flex} dangerouslySetInnerHTML={{ __html: label }}></View>

        {counter > 0 && (
          <Text fontFamily="primary" css={css.num} backgroundColor={isActive ? 'gray3' : 'gray2'}>
            {counter}
          </Text>
        )}
      </>
    );

    return (
      <Text
        {...linkProps}
        sidebar-id={id}
        id={id}
        onClick={() => {
          onClick?.(item);
        }}
        backgroundColor={isActive ? 'gray2' : 'light'}
      >
        {anchorContent}
      </Text>
    );
  };

  const renderMenuItem = (item: MenuItem): ReactNode => {
    const { id, hasDivider, textHeader } = item;
    return (
      <View key={id} id={id}>
        {!!textHeader && (
          <Text size={12} fontFamily="secondary" css={css.title}>
            {textHeader}
          </Text>
        )}

        <View className="Navigation-parent" css={css.parent}>
          {renderLink(item)}
        </View>
        {!!hasDivider && (
          <View height={1} backgroundColor="gray2" css={{ position: 'relative', marginTop: '15px', marginBottom: '15px', width: '100%' }} />
        )}
      </View>
    );
  };

  return (
    <View tagName="nav" css={[css.container, containerCss]}>
      {data.map(renderMenuItem)}
      {expandContent}
    </View>
  );
};
