import { AsyncComponent, ScrollBars } from '@wiloke/ui';
import { useLinkPicker } from 'components/LinkPicker/store/context/LinkPickerContext';
import { getShopifyHandle } from 'components/LinkPicker/utils/getShopifyHandle';
import PageCard from 'components/PageCard';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useChangePageKey, useGetPages, useLoadMoreShopifyPages } from 'store/actions/shopify';
import { defaultPageDataState } from 'store/reducers/shopify/reducerPages';
import { shopifySelector } from 'store/selectors';
import { i18n } from 'translation';
import { PageApiData } from 'types/Pages';
import { renewVersion } from 'utils/CacheControl/ShopifyConnectionCacheControl';
import { ActivityIndicator, FontAwesome, View, ViewportTracking } from 'wiloke-react-core';
import { DebounceTextInput } from '../Form/Form';
import * as styles from './styles';

const customerPages = [
  '/collections',
  '/account',
  '/account/activate_account',
  '/account/addresses',
  '/account/login',
  '/account/orders',
  '/account/register',
  '/account/login#recover',
  '/search',
  '/404',
  '/cart',
  '/policies/refund-policy',
  '/policies/privacy-policy',
  '/policies/terms-of-service',
  '/policies/shipping-policy',
  '/policies/subscription-policy',
];

export const Page = () => {
  const { pages, searchKey } = useSelector(shopifySelector.pages);
  const { data, requestStatus, hasNextPage, loadMoreStatus } = pages[searchKey] || defaultPageDataState;
  const { dispatch, value } = useLinkPicker();
  const getPages = useGetPages();
  const changeKey = useChangePageKey();
  const loadMorePages = useLoadMoreShopifyPages();

  useEffect(() => {
    getPages.request({ search: searchKey });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  const _handleSearch = (val: string) => {
    changeKey(val);
  };

  const renderLoadMore = () => {
    if (hasNextPage || loadMoreStatus === 'loading') {
      return (
        <ViewportTracking
          offsetBottom={0}
          onEnterViewport={() => {
            if (requestStatus === 'success') {
              loadMorePages.request({ search: searchKey });
            }
          }}
        >
          <View css={styles.loading}>
            <ActivityIndicator />
          </View>
        </ViewportTracking>
      );
    }
  };

  const renderRowItem = (item: PageApiData) => {
    const { id, title, handle } = item;
    return (
      <PageCard.Style2
        title={title}
        isActive={handle === getShopifyHandle(value)}
        css={{ marginBottom: '5px' }}
        key={id}
        onClick={() => {
          dispatch({
            type: 'setModalShopifyVisible',
            payload: false,
          });
          dispatch({
            type: 'setSettings',
            payload: {
              value: customerPages.includes(handle) ? handle : `/pages/${handle}`,
              type: 'shopify',
              label: title,
            },
          });
        }}
      />
    );
  };

  const handleRefresh = () => {
    renewVersion();
    getPages.request({ search: searchKey });
  };

  const _renderSuccess = () => {
    return (
      <ScrollBars css={{ height: 'calc(100% - 100px) !important' }}>
        <View css={{ padding: '10px', height: '100%' }}>
          {data.map(renderRowItem)}
          {renderLoadMore()}
        </View>
      </ScrollBars>
    );
  };

  return (
    <View css={styles.modalContent}>
      <View css={styles.filterContainer}>
        <DebounceTextInput
          block
          sizeInput="medium"
          placeholder={i18n.t('builderPage.search', { text: i18n.t('general.page') })}
          css={styles.filterInput}
          value={searchKey}
          onValueChange={_handleSearch}
        />
        <View css={styles.refreshIcon} onClick={handleRefresh}>
          {requestStatus === 'loading' ? <ActivityIndicator size={18} /> : <FontAwesome color="primary" size={18} type="far" name="sync-alt" />}
        </View>
      </View>

      <AsyncComponent status={requestStatus} Success={_renderSuccess()} />
    </View>
  );
};
