import { AsyncComponent, MyModal, VedaLoadingItem } from '@wiloke/ui';
import { notification } from 'antd';
import Field from 'components/Field';
import Radio from 'components/Radio';
import TextInput from 'components/TextInput';
import withDebounce from 'hocs/withDebounce';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useChangeBlogKey, useGetBlogs, useLoadMoreBlogs } from 'store/actions/shopify';
import { defaultBlogDataState } from 'store/reducers/shopify/reducerBlogs';
import { defaultPickerRelateShopifySelector, shopifySelector } from 'store/selectors';
import { i18n } from 'translation';

import { RenewButton } from 'containers/Admin/PageBuilder/DashboardPageSettings/components/RenewButton';
import { useHistory } from 'react-router-dom';

import Active from 'components/Active';
import ProductCard from 'components/ProductCard';
import { ArticlePageLiquidVariable } from 'types/Page';
import { SHOPIFY_UNKNOWN_HANDLE, SHOPIFY_UNKNOWN_ID } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { GridSmart, View, ViewportTracking } from 'wiloke-react-core';
import { dashboardPageSettingsSelector, useChangeSettingsDashboardPage } from '../slice';
import * as styles from '../styles';

const DebounceInput = withDebounce(TextInput, 'value', 'onValueChange');

export const ArticleSettings = () => {
  const { page, visibleListArticle: visibleListBlog, originPage } = useSelector(dashboardPageSettingsSelector);
  const { blogs, searchKey } = useSelector(shopifySelector.blogs);
  const { data, requestStatus, hasNextPage, loadMoreStatus } = blogs[searchKey] || defaultBlogDataState;
  const [applyType, setApplyType] = useState<'all' | 'custom'>('all');
  const {
    data: { article },
  } = useSelector(defaultPickerRelateShopifySelector);

  const changeSettings = useChangeSettingsDashboardPage();
  const changeKey = useChangeBlogKey();

  const getBlogs = useGetBlogs();
  const loadMoreBlogs = useLoadMoreBlogs();

  const history = useHistory();
  const { shopName } = getUserInfo();

  useEffect(() => {
    if (visibleListBlog) {
      getBlogs.request({ search: searchKey, shouldGetArticle: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleListBlog, searchKey]);

  useEffect(() => {
    if (visibleListBlog) {
      setApplyType(Array.isArray(page?.shopifyPages) ? 'custom' : 'all');
    }
  }, [page?.shopifyPages, visibleListBlog]);

  const _handleSearch = (val: string) => {
    changeKey(val);
  };

  const handleCancel = () => {
    changeSettings({
      visibleListArticle: false,
      page: undefined,
      originPage: undefined,
    });
  };

  const handleOverride = () => {
    if (page) {
      // nếu applyType = custom thì người dùng phải chọn ít nhất 1 product để có thể update lên server
      if (applyType === 'custom' && Array.isArray(page.shopifyPages) && page.shopifyPages.length < 1) {
        notification.warning({
          message: i18n.t('validate.choose_at_least', { text: i18n.t('adminDashboard.article') }),
        });
      } else {
        const entityVariant = 'Client';
        const defaultFirstItem = {
          blogId: data[0]?.id ?? SHOPIFY_UNKNOWN_ID,
          blogHandle: data[0]?.handle ?? SHOPIFY_UNKNOWN_HANDLE,
          featuredImg: undefined,
          handle: SHOPIFY_UNKNOWN_HANDLE,
          itemId: SHOPIFY_UNKNOWN_ID,
        } as ArticlePageLiquidVariable;
        const prevRepresentPage: ArticlePageLiquidVariable =
          !page.shopifyRepresentPage || page.shopifyRepresentPage?.handle === SHOPIFY_UNKNOWN_HANDLE
            ? defaultFirstItem
            : (page.shopifyRepresentPage as ArticlePageLiquidVariable);
        const isDeleted = Array.isArray(page.shopifyPages) ? !page.shopifyPages.find(item => item?.handle === prevRepresentPage?.handle) : false;
        const firstItemSelected = Array.isArray(page.shopifyPages) ? page.shopifyPages[0] : defaultFirstItem;
        history.push(`/builder?shop=${shopName}&id=${page.commandId}&entityVariant=${entityVariant}`, {
          label: page.label,
          type: 'article',
          isCreate: false,
          shopifyRepresentPage: isDeleted ? firstItemSelected : prevRepresentPage,
          shopifyPages: applyType === 'all' ? 'all' : page.shopifyPages,
          backToPage: '/page/article',
          entityVariant,
        });

        changeSettings({
          visibleListArticle: false,
          originPage: undefined,
        });
      }
    }
  };

  const handleChangeType = (val: string) => {
    const _val = val as 'all' | 'custom';
    setApplyType(_val);
    if (page && _val === 'all') {
      changeSettings({
        page: {
          ...page,
          shopifyPages: 'all',
        },
      });
    }
    if (page && _val === 'custom') {
      changeSettings({
        page: {
          ...page,
          shopifyPages: !originPage?.shopifyPages || typeof originPage.shopifyPages === 'string' ? [] : originPage?.shopifyPages,
        },
      });
    }
  };

  const renderLoadMoreBlog = () => {
    if (hasNextPage || loadMoreStatus === 'loading') {
      return (
        <ViewportTracking
          offsetBottom={0}
          onEnterViewport={() => {
            if (requestStatus === 'success') {
              loadMoreBlogs.request({ search: searchKey, shouldGetArticle: true });
            }
          }}
        >
          <View css={{ padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <VedaLoadingItem />
          </View>
        </ViewportTracking>
      );
    }
  };

  const handleSelectPage = ({ blogHandle, blogId, featuredImg }: { blogId: number; blogHandle: string; featuredImg: string | undefined }) => (
    isSelect: boolean,
  ) => {
    if (page) {
      // selected = true thì thêm vào mảng
      if (isSelect) {
        changeSettings({
          page: {
            ...page,
            shopifyPages: [
              {
                blogId,
                blogHandle,
                featuredImg,
                handle: !!article && typeof article !== 'string' ? article.handle : SHOPIFY_UNKNOWN_HANDLE,
                itemId: !!article && typeof article !== 'string' ? article.itemId : SHOPIFY_UNKNOWN_ID,
              },
            ],
          },
        });
      } else {
        changeSettings({
          page: {
            ...page,
            shopifyPages: (Array.isArray(page?.shopifyPages) ? page.shopifyPages : []).filter(liquid => liquid?.handle !== blogHandle),
          },
        });
      }
    }
  };

  const renderBlogSuccess = () => {
    return (
      <View css={styles.renderBlogSuccessContainer}>
        <GridSmart columnWidth={200} columnGap={10}>
          {data.map(item => {
            const isActive =
              page !== undefined && page.shopifyPages !== undefined && page.shopifyPages !== 'all'
                ? page.shopifyPages.map(item => (item as ArticlePageLiquidVariable)?.blogId).includes(item.id)
                : false;

            return (
              <Active
                disabledMulti
                onActive={handleSelectPage({
                  blogHandle: item.handle,
                  blogId: item.id,
                  featuredImg: undefined,
                })}
                variant="style2"
                active={isActive}
                key={item.id}
              >
                <ProductCard imageAspectRatio={16 / 9} imageSrc={''} title={item.title} />
              </Active>
            );
          })}
        </GridSmart>
        {renderLoadMoreBlog()}
      </View>
    );
  };

  return (
    <MyModal
      isVisible={visibleListBlog}
      bodyCss={{
        height: applyType === 'all' ? '280px' : '80%',
      }}
      headerText={i18n.t('adminDashboard.article')}
      size="medium"
      onCancel={handleCancel}
      onOk={handleOverride}
      scrollDisabled
      okButtonDisabled={requestStatus === 'loading'}
    >
      <Field label="Apply to">
        <Radio.Group size="large" defaultValue={applyType} value={applyType} onChangeValue={handleChangeType}>
          <Radio.Button value="all">{i18n.t('adminDashboard.all')}</Radio.Button>
          <Radio.Button value="custom"> {i18n.t('adminDashboard.specific_text', { text: i18n.t('adminDashboard.blog') })}</Radio.Button>
        </Radio.Group>
      </Field>

      {applyType === 'custom' && (
        <View css={{ height: '100%' }}>
          <View
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginBottom: '8px',
              columnGap: '10px',
            }}
          >
            <DebounceInput
              block
              sizeInput="medium"
              placeholder={`${i18n.t('adminDashboard.search')} ${i18n.t('adminDashboard.blog')}...`}
              css={{ flex: '1' }}
              value={searchKey}
              onValueChange={_handleSearch}
            />
            <RenewButton variant="blog" css={{ width: '44px', height: '44px', borderRadius: '6px' }} />
          </View>

          <AsyncComponent status={requestStatus} Success={renderBlogSuccess()} />
        </View>
      )}
    </MyModal>
  );
};
