import { AxiosError } from 'axios';
import { handleWaitForSocketOfImportThemeAtomToClientServiceNPageInThemeClientFulfill } from 'hooks/useSocket/useSocketForImportThemeAtomToClientServiceNPageInThemeClient';
import { call, put, retry, SagaReturnType, select, takeLatest } from 'redux-saga/effects';
import { importThemeAtomToClientService } from 'services/ThemeService/Logic/importThemeAtomToClient';
import { socketOfImportThemeAtomClientServiceSelector } from 'store/selectors';
import { i18n } from 'translation';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { importThemeAtomToClient, setVisible402PurchaseCode } from '../actions';

function* handleImportThemeAtomToClientService({ payload }: ReturnType<typeof importThemeAtomToClient.request>) {
  const { themeProductCommandId, group, onFulfill, shopifyThemeId } = payload;
  const { eventId }: ReturnType<typeof socketOfImportThemeAtomClientServiceSelector> = yield select(socketOfImportThemeAtomClientServiceSelector);
  try {
    if (eventId) {
      yield retry(3, 1000, importThemeAtomToClientService, {
        themeProductCommandId,
        eventId,
        eventType: 'Import theme atom -> client service ngoài dashboard',
        group,
        shopifyThemeId,
      });

      const {
        statusImport,
        themeCommandId,
      }: SagaReturnType<typeof handleWaitForSocketOfImportThemeAtomToClientServiceNPageInThemeClientFulfill> = yield call(
        handleWaitForSocketOfImportThemeAtomToClientServiceNPageInThemeClientFulfill,
        'Import theme atom -> client service ngoài dashboard',
      );
      if (statusImport === 'success' && themeCommandId !== null && themeCommandId !== undefined) {
        notifyAxiosHandler.handleSuccess(i18n.t('import_theme_atom_to_client_service.import_success'));
        yield put(importThemeAtomToClient.success({ themeProductCommandId }));
        onFulfill?.();
      } else {
        yield put(importThemeAtomToClient.failure({ themeProductCommandId }));
      }
    } else {
      throw new Error(i18n.t('import_theme_atom_to_client_service.error_unknown'));
    }
  } catch (error) {
    if ((error as AxiosError).isAxiosError) {
      const _error = error as AxiosError;
      if (_error.response?.status === 402) {
        yield put(setVisible402PurchaseCode(true));
      } else {
        notifyAxiosHandler.handleError(error as AxiosError<ErrorData>);
      }
    } else {
      notifyAxiosHandler.handleError(error as Error);
    }
    yield put(importThemeAtomToClient.failure({ themeProductCommandId }));
  }
}

export function* watchImportThemeAtomToClientService() {
  yield takeLatest(getActionType(importThemeAtomToClient.request), handleImportThemeAtomToClientService);
}
