import { ThemeAddon } from 'types/Addons';
import { createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';

type Actions =
  | {
      type: 'setOriginalThemeAddons';
      payload: ThemeAddon[];
    }
  | {
      type: 'setIsSaveAddons';
      payload: boolean;
    };

interface State {
  data: ThemeAddon[];
  isSaveAddons: boolean;
}
// FIXME: lưu theo themeId (update sau khi release)
export const sliceOriginThemeAddons = createSlice<State, Actions>({
  name: '@Global',
  initialState: {
    data: [],
    isSaveAddons: false,
  },
  reducers: [
    handleAction('setIsSaveAddons', ({ state, action }) => {
      state.isSaveAddons = action.payload;
    }),
    handleAction('setOriginalThemeAddons', ({ state, action }) => {
      state.data = action.payload;
    }),
  ],
});

export const { setIsSaveAddons, setOriginalThemeAddons } = sliceOriginThemeAddons.actions;

export const useSetIsSaveAddons = createDispatchAction(setIsSaveAddons);
export const useSetOriginalThemeAddons = createDispatchAction(setOriginalThemeAddons);
