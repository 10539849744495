export const adminDashboard = {
  confirm_delete: 'Bạn có muốn xóa trang này không? Lưu ý: hành động này không thể hoàn tác',
  search_empty: 'Xin lỗi, không có kết quả nào trùng khớp với từ khóa của bạn.',
  page_description: {
    article: 'Một trang bài viết phải vừa giàu thông tin vừa đẹp mắt để giữ chân người đọc.',
    regular: "Tạo các trang đích khác nhau như wishlist, trang 'Giới thiệu', thông tin liên hệ và đánh giá của khách hàng.",
    collection: 'Một trang bộ sưu tập tuyệt đẹp có thể khơi dậy sự tò mò của khách hàng và khuyến khích họ khám phá thêm.',
    home: 'Mang lại ấn tượng đầu tiên tuyệt vời cho khách hàng với trang chủ hoàn hảo và chuyên nghiệp.',
    product: 'Một trang sản phẩm tinh tế có thể thuyết phục khách hàng mua hàng khi họ đang do dự.',
    preset: 'Chọn một cài đặt chung để đảm bảo cửa hàng của bạn có bản sắc trực quan nhất quán.',
    theme_settings: 'Mở khóa vô số cài đặt được cá nhân hóa để giúp cửa hàng của bạn trở nên độc đáo.',
    quick_create: 'Khám phá các bố cục hấp dẫn đang thịnh hành!',
    live_theme: 'Bản xem trước chủ đề cửa hàng của bạn. Tinh chỉnh theo ý thích của bạn!',
    draft_themes: 'Những chủ đề này chỉ hiển thị cho bạn. Bạn có thể chuyển sang chủ đề khác bằng cách xuất bản chủ đề đó lên cửa hàng của mình.',
  },
  views: 'Số lượt xem',
  timeOnPage: 'Thời gian trên trang',
  conversion: 'Tỷ lệ chuyển đổi',
  publish: 'Xuất bản',
  draft: '%% text %% nháp',
  all: 'Tất cả',
  get_started: 'Bắt đầu',
  get_started_description: 'Xem video hướng dẫn thân thiện với người dùng này để thiết lập nhanh chóng!',
  quickly: 'Nhanh chóng',
  customize: 'Tùy chỉnh',
  blank: 'rỗng',
  collection: 'bộ sưu tập %% text %%',
  product: 'Sản phẩm',
  article: 'Bài viết',
  lastUpdate: 'Cập nhật lúc',
  all_templates: 'Tất cả các mẫu %% text %%',
  my_templates: 'Mẫu của tôi',
  fav_templates: 'Mẫu trang của tôi',
  theme_templates: 'Chủ đề mẫu',
  page_templates: 'Mẫu trang',
  page_tempaltes_description: 'Một loạt các mẫu trang đang vẫy gọi bạn, sẵn sàng để được tùy chỉnh theo nhu cầu chính xác của bạn.',
  join_our_team: 'Tham gia cùng chúng tui',
  specific_text: '%% text %% cụ thể',
  theme_settings_page: 'Cài đặt chung cho theme',
  view: 'Xem',
  notification: 'Thông báo',
  account: 'Tài khoản',
  unread: 'Chưa đọc',
  featured_news: 'Tin nổi bật',
  menubar: {
    dashboard: 'Dashboard',
    templates: 'Templates',
    regular_page: 'Landing Pages & More',
    product_page: 'Product Page',
    collection_page: 'Collection Page',
    article_pages: 'Article Page',
  },
  blog: 'Blog',
  select_presets: 'Chọn bộ cài đặt trước',
  choose_presets: 'Lựa chọn bản cài đặt trước đã được chúng tôi tổng hợp tại đây',
  option_presets: 'Màu, phông chữ, bố cục',
  theme_settings: {
    confirm_description: 'Những cài đặt này sẽ ảnh hưởng đến toàn bộ chủ đề của bạn',
    confirm_label: 'Chọn những cài đặt bạn muốn cập nhật vào chủ đề',
  },
  home: 'Trang chủ',
  pleaseChooseBlog: 'Hãy chọn blog để thấy các bài viết thuộc blog đấy',
  cart: 'Giỏ hàng',
  search: 'Tìm kiếm %% text %%',
  password: 'Mật khẩu',
  notFound: 'Không tìm thấy',
  customersLogin: 'Đăng nhập',
  customersResetPassword: 'Đặt lại mật khẩu',
  customersActivateAccount: 'Kích hoạt tài khoản',
  customersRegister: 'Đăng kí',
  customersAccount: 'Tài khoản',
  customersOrder: 'Đặt hàng',
  customersAddresses: 'Địa chỉ',
  giftCard: 'Thẻ quà tặng',
  collectionListing: 'Danh sách bộ sưu tập',
  blog_has_no_articles: 'Blog này không có article',
  cannot_choose_blog: 'Bạn không thể chọn blog trống',
  name_your_page: 'Đặt tên trang của bạn',
  chose_shopify_variant: 'Đã chọn %% text %%',
  chose_shopify_to_design: 'Chọn %% text %% mà bạn muốn thiết kế',
  chose_article_belong: 'Tạo một bài viết mới và gán vào blog',
  select_something_to_design: 'Chọn %% text %% mà bạn muốn thiết kế',
  collection_listing: 'Danh sách bộ sưu tập',
  reset_password: 'Đặt lại mật khẩu',
  active_account: 'Kích hoạt tài khoản',
  register: 'Đăng kí',
  order: 'Đặt hàng',
  addresses: 'Địa chỉ',
  general: 'General',
  advanced_settings: 'Cài đặt nâng cao',
  styles: 'Styles',
  pages: 'Trang %% name %%',
  themes: 'Chủ đề',
  username: 'Username',
  featured_layouts: 'Bố cục nổi bật',
  regular: 'Thường',
  otherPages: 'Các trang khác',
  collapse: 'Thu về',
  reinstall: 'Cài lại',
  no_shopify_theme_id: "Chủ đề của bạn đã bị xóa trên shopify, vui lòng nhấn nút 'Cài đặt lại' để cài đặt lại chủ đề",
  last_saved: 'Mới lưu',
  added_ago: 'Mới thêm %% text %% trước',
  theme_library: 'Thư viện các chủ đề',
  landing: 'Landing',
  right_bar: {
    leave_us_a_feedback_title: 'Hãy để lại phản hồi của bạn',
    leave_us_a_feedback_desc: 'Chúng tôi đánh giá ý kiến của bạn, vui lòng để lại phản hồi để giúp chúng tôi phục vụ bạn tốt hơn!',
    leave_us_a_feedback_button: 'Gửi phản hồi',
    veda_youtube_title: 'Danh sách phát Youtube của Veda',
    veda_youtube_desc: 'Xem video hướng dẫn của chúng tôi để tối ưu hóa trải nghiệm của bạn.',
    quick_guide_title: 'Hướng dẫn nhanh',
    quick_guide_desc: 'Hướng dẫn nhanh',
    customization_title: 'Tùy chỉnh trong Trình chỉnh sửa trực tiếp',
    customization_desc: 'Tùy chỉnh trang của bạn đến tối đa với hướng dẫn Trình chỉnh sửa trực tiếp của chúng tôi.',
    faq_title: 'Câu trả lời cho các câu hỏi thường gặp',
    faq_desc: 'Câu trả lời nhanh chóng và hữu ích.',
    uninstall_title: 'Làm cách nào để gỡ bỏ Veda Builder một cách an toàn',
    uninstall_desc: 'Nhấp để tìm hiểu cách gỡ bỏ Veda một cách an toàn',
    recommended_apps: 'Ứng dụng được đề xuất',
    theme_and_page_title: 'Sự khác biệt giữa Trình tạo Chủ đề và Trình tạo Trang',
    theme_and_page_desc: 'Những sự khác biệt giữa Trình tạo Chủ đề và Trình tạo Trang của Veda',
  },
  apps: 'Ứng dụng',
  app: 'App',
  get: 'Tải %% text %%',
  danger_zone: 'Vùng nguy hiểm',
  purchase_code: 'Mã mua hàng',
  orders: 'Đơn hàng',
  inventory: 'Tồn kho',
  gift_cards: 'Thẻ quà tặng',
  customers: 'Khách hàng',
  analytics: 'Phân tích dữ liệu',
  marketing: 'Marketing',
  discounts: 'Giảm giá',
  store_details: 'Thông tin cửa hàng',
  collections: 'Bộ sưu tập',
  billing: 'Thanh toán',
  users_and_permissions: 'Người dùng và quyền hạn',
  payments: 'Thanh toán',
  checkout_and_accounts: 'Thanh toán và tài khoản',
  shipping_and_delivery: 'Vận chuyển và giao hàng',
  taxes_and_duties: 'Thuế và nhiệm vụ',
  locations: 'Vị trí',
  markets: 'Thị trường',
  apps_and_sales_channels: 'Ứng dụng và kênh bán hàng',
  domains: 'Miền',
  customer_events: 'Sự kiện khách hàng',
  brand: 'Thương hiệu',
  meta_fields: 'Trường meta',
  files: 'Tệp tin',
  languages: 'Ngôn ngữ',
  policies: 'Chính sách',
  account_page_welcome:
    'Chào mừng đến với Veda Builder! Ứng dụng của chúng tôi giúp việc xây dựng trang và cửa hàng trên Shopify trở nên dễ dàng hơn với những mẫu giao diện hấp dẫn. Hãy kiểm tra tài liệu hướng dẫn của chúng tôi để được trợ giúp và đừng ngần ngại nhấp vào biểu tượng chat để được hỗ trợ. Chúc bạn xây dựng thành công!',
  danger_zone_page: {
    description: 'Vui lòng cẩn thận khi thực hiện hành động trong khu vực này.',
    danger_card_title: 'Xóa tất cả các tệp Veda khỏi giao diện đã xuất bản của bạn',
    danger_card_desc: 'Nhấp vào "Khôi phục" sẽ xóa vĩnh viễn tất cả các bố cục Veda đã xuất bản. Giao diện của bạn sẽ trở về bố cục ban đầu.',
    see_details_guide: 'Xem hướng dẫn chi tiết',
    restore: 'Khôi phục',
    restore_veda: 'Khôi phục Veda Page Builder',
    modal_after_restore:
      'Xin chúc mừng, dữ liệu Veda đã được xóa khỏi ứng dụng của bạn thành công. Để gỡ cài đặt Veda khỏi cửa hàng của bạn, vui lòng nhấp vào đây',
    budget_concerns_title: 'Vấn đề ngân sách',
    budget_concerns_desc: `Nếu vấn đề chi phí là một vấn đề, chúng tôi cam kết sẽ giúp các cửa hàng mới thành công, đó là lý do tại sao chúng tôi đang cung cấp mã giảm giá độc quyền 40% cho đăng ký 3 tháng cho bất kỳ gói ứng dụng nào. Chỉ cần sử dụng mã "VSTAY40" khi thanh toán để nhận mã giảm giá của bạn. Chúng tôi hy vọng điều này sẽ giúp giảm bớt bất kỳ lo ngại về ngân sách nào bạn có thể gặp phải.`,
    missing_features_title: 'Tính năng thiếu',
    missing_features_desc: `Chờ tí! Có thể Veda Builder đã hỗ trợ tính năng đó, và các chuyên gia chức năng của chúng tôi sẵn sàng giúp bạn xác nhận. Nhấp vào nút bên dưới để bắt đầu trò chuyện trực tiếp với một trong những chuyên gia của chúng tôi để giúp bạn tìm tính năng bạn cần. `,
    technical_issues_title: 'Vấn đề kỹ thuật',
    technical_issues_desc: `Chúng tôi xin lỗi vì mọi khó khăn bạn đã gặp phải với ứng dụng của chúng tôi. Vui lòng chia sẻ thêm thông tin chi tiết về vấn đề, và đội ngũ hỗ trợ của chúng tôi sẽ hân hạnh hỗ trợ bạn và cải thiện trải nghiệm của bạn. Nhấp vào nút bên dưới để trò chuyện với một nhân viên hỗ trợ thực sự ngay bây giờ. `,
    no_need_title: 'Không cần nữa',
    no_need_desc: `Chúng tôi tin rằng Veda Builder vẫn có thể là một công cụ quan trọng cho doanh nghiệp của bạn, và chúng tôi rất mong có cơ hội để chỉ cho bạn chính xác như thế nào. `,
    difficulty_of_use_title: 'Khó khăn trong việc sử dụng',
    difficulty_of_use_desc: `Chúng tôi hiểu rằng việc sử dụng bất kỳ phần mềm mới nào cũng có thể khó khăn ban đầu. Đó là lý do tại sao chúng tôi cung cấp sự trợ giúp 1:1 từ các chuyên gia Thành công của Khách hàng để hướng dẫn bạn vượt qua bất kỳ khó khăn nào bạn gặp phải. Nhấp vào nút bên dưới để kết nối với một người thực sự có thể giúp bạn. `,
    something_else_title: 'Vấn đề khác',
    something_else_desc: `Nếu có vấn đề khác khiến bạn cân nhắc gỡ cài đặt, hãy cho chúng tôi biết. Chúng tôi luôn tìm cách cải thiện ứng dụng của chúng tôi và cung cấp trải nghiệm tốt nhất có thể cho người dùng của chúng tôi. Ý kiến phản hồi của bạn là quan trọng đối với chúng tôi, và chúng tôi sẽ làm tất cả những gì có thể để giải quyết mọi vấn đề và giữ bạn là khách hàng hài lòng. `,
    modal_restore_sorry: `Chúng tôi rất tiếc khi nghe tin bạn đang cân nhắc gỡ cài đặt Veda Builder. Chúng tôi luôn cố gắng cải thiện ứng dụng của chúng tôi và cung cấp trải nghiệm tốt nhất có thể cho người dùng của chúng tôi.`,
    modal_restore_before: `Trước khi bạn nhấp vào nút "Khôi phục" đó, chúng tôi muốn khám phá một số lựa chọn để giải quyết các vấn đề đã khiến bạn cân nhắc hành động này.`,
    redeem_code: 'Rút mã',
    chat_now: 'Chat ngay',
  },
  landing_and_more: 'Trang Landing',
  draft_theme: {
    delete_modal_title: 'Hành động này sẽ xóa chủ đề %% text %% từ Veda Builder vĩnh viễn, nhấn Ok để tiếp tục.',
    delete_modal_desc:
      'Ghi chú: Do chính sách của Shopify, chúng tôi không được phép xóa chủ đề này khỏi Shopify của bạn. Do đó, việc xóa chủ đề chỉ loại bỏ dữ liệu của nó khỏi Veda Page Builder. Để xóa hoặc sử dụng chủ đề khác trên Shopify của bạn, vui lòng nhấp vào',
  },
  duplicate_theme: {
    title: 'Tùy chỉnh mà không ảnh hưởng đến chủ đề hiện tại của bạn.',
    description:
      'Bạn có thể tạo bản sao chủ đề hiện tại của bạn và đồng bộ các mẫu Veda với nó để không ảnh hưởng đến chủ đề hiện tại. Khi hoàn tất tùy chỉnh, bạn có thể xuất bản chủ đề bản sao đó.',
    select: 'Chọn một chủ đề',
    click_to_learn: 'Nhấn để tìm hiểu cách tính năng này hoạt động',
  },
  quick_guide: {
    how_to_use_theme: {
      title: 'Hướng dẫn sử dụng chủ đề',
      description: 'Xây dựng cửa hàng một cách ấn tượng, dễ dàng để bán hàng nhiều hơn và tiết kiệm tiền.',
    },
    how_to_use_page: {
      title: 'Hướng dẫn sử dụng trang',
      description: 'Thay thế/thêm bất kỳ trang nổi bật nào vào chủ đề hiện tại của bạn.',
    },
    recommend_apps: {
      title: 'Hướng dẫn thêm Ứng dụng được đề xuất',
      description: 'Các ứng dụng tích hợp và bên thứ ba để mở khóa nhiều chức năng hơn.',
    },
    how_to_use_section: {
      title: 'Tìm hiểu cách sử dụng các tính năng Veda',
      description: 'Các tính năng mạnh mẽ khác nhau thúc đẩy người truy cập thêm vào giỏ hàng ngay lập tức.',
    },
  },
} as const;
