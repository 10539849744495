import { getVedaBlogs } from 'containers/Admin/RightBar/store/actions';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { recommendAppsService } from 'services/RecommednApps/RecommendApps';
import { getActionType } from 'wiloke-react-core/utils';

function* handleGet() {
  try {
    const response: SagaReturnType<typeof recommendAppsService.getVedaBlogs> = yield retry(3, 1000, recommendAppsService.getVedaBlogs);
    yield put(getVedaBlogs.success({ data: response }));
  } catch (error) {
    yield put(getVedaBlogs.failure(undefined));
  }
}

export function* watchGetVedaBlogs() {
  yield takeLatest(getActionType(getVedaBlogs.request), handleGet);
}
