export const ModalUpdateShopifyThemeId = {
  update_shopify_theme_id_title: "Mettre à jour l'ID du thème Shopify",
  update_shopify_theme_id_description_1: 'Lors de la personnalisation du thème, les données sont mises à jour avec cet identifiant de thème. Aller à',
  update_shopify_theme_id_description_2: 'Online Store',
  update_shopify_theme_id_description_3: "pour obtenir l'identifiant du thème.",
  theme_removed_warning_title: 'Attention : Mise à jour nécessaire du thème - Possible suppression',
  theme_removed_warning_description:
    'Attention ! Il semblerait que votre thème actuel ait été supprimé et nécessite une mise à jour avec un nouvel identifiant de thème. Pour continuer à utiliser votre thème, veuillez fournir le nouvel identifiant ou suivre les étapes de mise à jour indiquées. Nous nous excusons pour les désagréments occasionnés et nous sommes là pour vous aider lors du processus de mise à jour.',
  go_to_theme_area: 'Allez dans la section "Thèmes".',
} as const;
