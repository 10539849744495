import { handleWaitForSocketOfSyncShopifyFulfill } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { all, call, retry, SagaReturnType } from 'redux-saga/effects';
import { handlePreviewInBuilderPage, shopifyConnectionService } from 'services/ShopifyConnection';

export interface SyncGlobalOfTheme {
  themeParams: ReturnType<typeof handlePreviewInBuilderPage>['themeParams'];

  /**
   * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
   */
  // atomicCssParams: ReturnType<typeof handlePreviewInBuilderPage>['atomicCssParams'];
}

export interface SyncGlobalOfThemeResult {
  statusSyncGlobalOfTheme: SyncFulfillStatus;
  statusSyncAtomicCss: SyncFulfillStatus;
  isNeedIgnoreReportError: boolean;
}

/** Sync global (cái được sinh ra từ themeSettings) và atomic css */
export function* syncGlobalOfTheme({ themeParams }: SyncGlobalOfTheme) {
  yield all([
    retry(3, 1000, shopifyConnectionService.writeGlobalOfThemeToShopify, { ...themeParams, isPreview: true }),

    /**
     * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
     */
    // retry(3, 1000, shopifyConnectionService.writeRawAtomicCssToShopify, { ...atomicCssParams, isPreview: true }),
  ]);

  const [socketSyncGlobalOfTheme]: [SagaReturnType<typeof handleWaitForSocketOfSyncShopifyFulfill>] = yield all([
    call(handleWaitForSocketOfSyncShopifyFulfill, 'Ghi file khi save ở builder page / Ghi global (sinh ra từ themeSettings)'),
  ]);

  return {
    statusSyncGlobalOfTheme: socketSyncGlobalOfTheme.statusSync,
    isNeedIgnoreReportError: socketSyncGlobalOfTheme.isNeedIgnoreReportError,
  } as SyncGlobalOfThemeResult;
}
