import { ProductTheme } from 'types/Theme';
import { ActionTypes, createReducer, handleAction } from 'wiloke-react-core/utils';
import {
  actionGetCurrentTheme,
  actionGetThemeTemplates,
  checkPurchaseCodeIsExist,
  checkThemeIsInstall,
  deleteProductTheme,
  getProductTheme,
  importThemeAtomToClient,
  loadMoreThemeTemplates,
  setErrorMessagePurchaseCode,
  setVisible402PurchaseCode,
  validateBuiltinTheme,
  validatePurchaseCode,
} from '../actions';

type ActionsTheme = ActionTypes<
  | typeof actionGetThemeTemplates
  | typeof actionGetCurrentTheme
  | typeof importThemeAtomToClient
  | typeof deleteProductTheme
  | typeof loadMoreThemeTemplates
  | typeof validatePurchaseCode
  | typeof setVisible402PurchaseCode
  | typeof setErrorMessagePurchaseCode
  | typeof getProductTheme
  | typeof checkThemeIsInstall
  | typeof checkPurchaseCodeIsExist
  | typeof validateBuiltinTheme
>;

export interface ThemeState {
  templates: ProductTheme[];
  requestStatus: Status;
  // get current theme status
  getThemeStatus: Status;
  message: string;
  hasNextPage: boolean;
  importStatus: Record<string, Status>;
  deleteStatus: Record<string, Status>;
  loadMoreStatus: Status;
  visible402PurchaseCode: boolean;
  validatePurchaseCodeStatus: Status;
  getThemeZipStatus: Record<string, Status>;
  checkThemeIsInstallStatus: Status;
  checkPurchaseCodeIsExistStatus: Status;
  validateBuiltinThemeStatus: Status;
  validatedShopifyThemeId: string;
}

export const defaultThemeTemplateState: ThemeState = {
  templates: [],
  hasNextPage: false,
  message: '',
  requestStatus: 'idle',
  getThemeStatus: 'idle',
  importStatus: {},
  deleteStatus: {},
  loadMoreStatus: 'idle',
  validatePurchaseCodeStatus: 'idle',
  visible402PurchaseCode: false,
  getThemeZipStatus: {},
  checkThemeIsInstallStatus: 'idle',
  checkPurchaseCodeIsExistStatus: 'idle',
  validateBuiltinThemeStatus: 'idle',
  validatedShopifyThemeId: '',
};

export const reducerThemeTemplates = createReducer<ThemeState, ActionsTheme>(defaultThemeTemplateState, [
  handleAction('@ThemeBuilder/getThemeTemplates/request', ({ state }) => {
    return {
      ...state,
      requestStatus: 'loading',
    };
  }),
  handleAction('@ThemeBuilder/getThemeTemplates/success', ({ state, action }) => {
    const { data, hasNextPage } = action.payload;
    return {
      ...state,
      requestStatus: 'success',
      templates: data,
      hasNextPage,
    };
  }),
  handleAction('@ThemeBuilder/getThemeTemplates/failure', ({ state }) => {
    return {
      ...state,
      requestStatus: 'failure',
    };
  }),
  handleAction('@ThemeBuilder/actionGetCurrentTheme/request', ({ state }) => {
    return {
      ...state,
      getThemeStatus: 'loading',
    };
  }),
  handleAction('@ThemeBuilder/actionGetCurrentTheme/success', ({ state }) => {
    return {
      ...state,
      getThemeStatus: 'success',
    };
  }),
  handleAction('@ThemeBuilder/actionGetCurrentTheme/failure', ({ state }) => {
    return {
      ...state,
      getThemeStatus: 'failure',
    };
  }),
  handleAction('@ThemeBuilder/actionGetCurrentTheme/cancel', ({ state }) => {
    return {
      ...state,
      getThemeStatus: 'idle',
    };
  }),
  handleAction('@ThemeBuilder/importThemeAtomToClient/request', ({ state, action }) => {
    return {
      ...state,
      importStatus: {
        [action.payload.themeProductCommandId]: 'loading',
      },
    };
  }),
  handleAction('@ThemeBuilder/importThemeAtomToClient/success', ({ state, action }) => {
    return {
      ...state,
      importStatus: {
        [action.payload.themeProductCommandId]: 'success',
      },
      templates: state.templates.map(item => {
        if (item.commandId === action.payload.themeProductCommandId) {
          return {
            ...item,
            isInstalled: true,
          };
        }
        return item;
      }),
    };
  }),
  handleAction('@ThemeBuilder/importThemeAtomToClient/failure', ({ state, action }) => {
    return {
      ...state,
      importStatus: {
        [action.payload.themeProductCommandId]: 'failure',
      },
      templates: state.templates.map(item => {
        if (item.commandId === action.payload.themeProductCommandId) {
          return {
            ...item,
            isInstalled: false,
          };
        }
        return item;
      }),
    };
  }),
  handleAction('@ThemeBuilder/deleteProductTheme/request', ({ state, action }) => {
    return {
      ...state,
      deleteStatus: {
        [action.payload.commandId]: 'loading',
      },
    };
  }),
  handleAction('@ThemeBuilder/deleteProductTheme/success', ({ state, action }) => {
    return {
      ...state,
      deleteStatus: {
        [action.payload.commandId]: 'success',
      },
      templates: state.templates.filter(item => item.commandId !== action.payload.commandId),
    };
  }),
  handleAction('@ThemeBuilder/deleteProductTheme/failure', ({ state, action }) => {
    return {
      ...state,
      deleteStatus: {
        [action.payload.commandId]: 'failure',
      },
    };
  }),
  handleAction('@ThemeBuilder/loadMoreThemeTemplates/request', ({ state }) => {
    state.loadMoreStatus = 'loading';
  }),
  handleAction('@ThemeBuilder/loadMoreThemeTemplates/success', ({ state, action }) => {
    state.loadMoreStatus = 'success';
    state.templates = state.templates.concat(action.payload.data);
    state.hasNextPage = action.payload.hasNextPage;
  }),
  handleAction('@ThemeBuilder/loadMoreThemeTemplates/failure', ({ state }) => {
    state.loadMoreStatus = 'failure';
  }),
  handleAction('@ThemeBuilder/setVisible402PurchaseCode', ({ state, action }) => {
    state.visible402PurchaseCode = action.payload.visible;
  }),
  handleAction('@ThemeBuilder/validatePurchaseCode/request', ({ state }) => {
    state.validatePurchaseCodeStatus = 'loading';
  }),
  handleAction('@ThemeBuilder/validatePurchaseCode/success', ({ state }) => {
    state.validatePurchaseCodeStatus = 'success';
  }),
  handleAction('@ThemeBuilder/validatePurchaseCode/failure', ({ state, action }) => {
    state.validatePurchaseCodeStatus = 'failure';
    state.message = action.payload.message;
  }),
  handleAction('@ThemeBuilder/setErrorMessagePurchaseCode', ({ state, action }) => {
    state.message = action.payload.message;
  }),
  handleAction('@Global/getProductTheme/request', ({ state, action }) => {
    state.getThemeZipStatus[action.payload.commandId] = 'loading';
  }),
  handleAction('@Global/getProductTheme/success', ({ state, action }) => {
    state.getThemeZipStatus[action.payload.commandId] = 'success';
  }),
  handleAction('@Global/getProductTheme/failure', ({ state, action }) => {
    state.getThemeZipStatus[action.payload.commandId] = 'failure';
  }),
  handleAction('@ThemeBuilder/checkThemeIsInstall/request', ({ state }) => {
    state.checkThemeIsInstallStatus = 'loading';
  }),
  handleAction('@ThemeBuilder/checkThemeIsInstall/success', ({ state, action }) => {
    state.checkThemeIsInstallStatus = 'success';
    state.templates = state.templates.map(item => {
      if (item.commandId === action.payload.commandId) {
        return {
          ...item,
          isInstalled: true,
        };
      }
      return item;
    });
    state.validatedShopifyThemeId = action.payload.themeShopifyId;
  }),
  handleAction('@ThemeBuilder/checkThemeIsInstall/failure', ({ state, action }) => {
    state.checkThemeIsInstallStatus = 'failure';
    state.templates = state.templates.map(item => {
      if (item.commandId === action.payload.commandId) {
        return {
          ...item,
          isInstalled: false,
        };
      }
      return item;
    });
  }),
  handleAction('@ThemeBuilder/checkPurchaseCodeIsExist/request', ({ state }) => {
    state.checkPurchaseCodeIsExistStatus = 'loading';
  }),
  handleAction('@ThemeBuilder/checkPurchaseCodeIsExist/success', ({ state }) => {
    state.checkPurchaseCodeIsExistStatus = 'success';
  }),
  handleAction('@ThemeBuilder/checkPurchaseCodeIsExist/failure', ({ state }) => {
    state.checkPurchaseCodeIsExistStatus = 'failure';
  }),
  handleAction('@ThemeBuilder/validateBuiltinTheme/request', ({ state }) => {
    state.validateBuiltinThemeStatus = 'loading';
  }),
  handleAction('@ThemeBuilder/validateBuiltinTheme/success', ({ state }) => {
    state.validateBuiltinThemeStatus = 'success';
  }),
  handleAction('@ThemeBuilder/validateBuiltinTheme/failure', ({ state }) => {
    state.validateBuiltinThemeStatus = 'failure';
  }),
]);
