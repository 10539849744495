import { notification, Tooltip } from 'antd';
import { AsyncComponent } from '@wiloke/ui';
import PopConfirmAntd from 'components/ConfirmAntd';
import DraftBox from 'components/DraftBox';
import FieldBox from 'components/FieldBox';
import { useGetShopifyPageTemplate } from 'containers/Admin/PageBuilder/TemplatesPage/utils/useGetShopifyPageTemplate';
import { CreateCollectionPicker, CreateProductPicker } from 'containers/Shopify/ModalCreateShopifyPicker';
import { CreateArticlePicker } from 'containers/Shopify/ModalCreateShopifyPicker/CreateArticlePicker';
import { useSettingsShopifyPicker } from 'containers/Shopify/ModalCreateShopifyPicker/slice';
import { useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DevPageDatabase } from 'services/ResultService/atomTypes';
import { i18n } from 'translation';
import { RegularPageLiquidVariable } from 'types/Page';
import { at } from 'utils/at';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { ActivityIndicator, GridSmart, Text, View, ViewportTracking } from 'wiloke-react-core';
import { ModalCommitDraft } from './ModalCommitDraft';
import { ModalRejectDraft } from './ModalRejectDraft';
import {
  useApprovePageDraft,
  useDeletePageDraft,
  useDisableShopifyPageDraft,
  useEnableShopifyPageDraft,
  useGetPagesDraft,
  useLoadMorePagesDraft,
} from './store/actions/actionPagesDraft';
import { pagesDraftSelector, useSetModalCommitDraft, useSetModalRejectDraft } from './store/reducers/slicePagesDraft';
import * as styles from './styles';
import { useTrackClickAction } from './hooks/useTrackClickAction';

export const DraftPage = () => {
  const [currentPage, setCurrentPage] = useState<DevPageDatabase | undefined>(undefined);

  const {
    data,
    hasNextPage,
    loadMoreStatus,
    getStatus,
    queueApproving,
    queueDeleting,
    queueRejecting,
    queueCommiting,
    queueDisabling,
    queueEnabling,
  } = useSelector(pagesDraftSelector);
  const { shopName, role } = getUserInfo();

  const history = useHistory();
  const getPagesDraft = useGetPagesDraft();
  const loadmorePagesDraft = useLoadMorePagesDraft();
  const approvePageDraft = useApprovePageDraft();
  const deletePageDraft = useDeletePageDraft();
  const enableShopifyPageDraft = useEnableShopifyPageDraft();
  const disableShopifyPageDraft = useDisableShopifyPageDraft();

  const setModalComitDraft = useSetModalCommitDraft();
  const setModalRejectDraft = useSetModalRejectDraft();
  const changeSettingsShopify = useSettingsShopifyPicker();
  const { getShopifyPages, getShopifyPresentPage } = useGetShopifyPageTemplate();

  const { connect, disconnect, statusSocketConnection } = useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService('sync_shopify');

  const { get, push } = useTrackClickAction();

  const handleCancel = () => {
    changeSettingsShopify({
      visibleProduct: false,
      slugsProduct: [],
      visibleCollection: false,
      slugsCollection: [],
      visibleArticle: false,
      slugArticle: [],
    });
    setCurrentPage(undefined);
  };

  const handleOk = () => {
    if (currentPage) {
      changeSettingsShopify({
        visibleProduct: false,
        slugsProduct: [],
        visibleCollection: false,
        slugsCollection: [],
        visibleArticle: false,
        slugArticle: [],
      });
      history.push(`/builder?shop=${shopName}&id=${currentPage.commandId}&entityVariant=Draft`, {
        headerFooterEnabled: currentPage.pageSettings ? currentPage.pageSettings.generalSettings.headerFooterEnabled : false,
        label: currentPage.label,
        type: currentPage.type,
        backToPage: '/manager-page',
        isCreate: false,
        entityVariant: 'Draft',
        shopifyRepresentPage: getShopifyPresentPage(currentPage.type),
        shopifyPages: getShopifyPages(currentPage.type),
      });
      push({ commandId: currentPage.commandId, label: currentPage.label });
    }
  };

  const handlePreview = (page: DevPageDatabase) => () => {
    if (page.type === 'article') {
      setCurrentPage(page);
      changeSettingsShopify({
        visibleArticle: true,
      });
    } else if (page.type === 'product') {
      setCurrentPage(page);
      changeSettingsShopify({
        visibleProduct: true,
      });
    } else if (page.type === 'collection') {
      setCurrentPage(page);
      changeSettingsShopify({
        visibleCollection: true,
      });
    } else {
      history.push(`/builder?shop=${shopName}&id=${page.commandId}&entityVariant=Draft`, {
        headerFooterEnabled: page.pageSettings ? page.pageSettings.generalSettings.headerFooterEnabled : false,
        label: page.label,
        type: page.type,
        backToPage: '/manager-page',
        isCreate: false,
        entityVariant: 'Draft',
        shopifyPages: page.type === 'page' ? [{ handle: page.label } as RegularPageLiquidVariable] : undefined,
        shopifyRepresentPage: page.type === 'page' ? ({ handle: page.label } as RegularPageLiquidVariable) : undefined,
      });
      push({ commandId: page.commandId, label: page.label });
    }
  };

  const handlePreviewWithApplyAll = (page: DevPageDatabase) => {
    history.push(`/builder?shop=${shopName}&id=${page.commandId}&entityVariant=Draft`, {
      headerFooterEnabled: page.pageSettings ? page.pageSettings.generalSettings.headerFooterEnabled : false,
      label: page.label,
      type: page.type,
      backToPage: '/manager-page',
      isCreate: false,
      entityVariant: 'Draft',
    });
    push({ commandId: page.commandId, label: page.label });
  };

  const Actions = (item: typeof data[number]) => {
    const { commandId, status, label, type } = item;

    if (role === 'admin') {
      return (
        <View css={{ display: 'flex', padding: '0' }}>
          <View
            color="light"
            backgroundColor="secondary"
            css={styles.rightItem}
            fontFamily="secondary"
            onClick={() => {
              approvePageDraft.request({ commandId });
            }}
          >
            {i18n.t('general.approve')}
          </View>

          <View
            color="light"
            fontFamily="secondary"
            backgroundColor="danger"
            css={styles.rightItem}
            onClick={() => {
              setModalRejectDraft(item);
            }}
          >
            {i18n.t('general.reject')}
          </View>
        </View>
      );
    }

    if (role === 'dev') {
      return (
        <View css={{ display: 'flex', padding: '0' }}>
          <PopConfirmAntd
            title={`You want delete "${label}"?`}
            onConfirm={() => {
              connect({
                onSuccess: () => {
                  deletePageDraft.request({
                    commandId,
                    name: label,
                    type,
                    onlyShopify: false,
                    onFulfill: () => disconnect({}),
                  });
                },
                onError: () => {
                  notification.error({
                    message: i18n.t('publish_shopify.init_sync_error.message'),
                    description: i18n.t('publish_shopify.init_sync_error.description'),
                  });
                },
              });
            }}
          >
            <View color="light" fontFamily="secondary" backgroundColor="danger" css={styles.rightItem}>
              {i18n.t('general.delete')}
            </View>
          </PopConfirmAntd>

          <View
            color="light"
            fontFamily="secondary"
            backgroundColor="success"
            css={styles.rightItem}
            onClick={() => {
              setModalComitDraft(item);
            }}
          >
            {status === 'pending' ? i18n.t('general.recommit') : i18n.t('general.commit')}
          </View>
        </View>
      );
    }
    return null;
  };

  const TrackingLoadmore = useMemo(() => {
    const cursor = at(data, -1)?.commandId;
    if (hasNextPage && cursor) {
      return (
        <ViewportTracking
          onEnterViewport={() => {
            if (loadMoreStatus !== 'loading') {
              loadmorePagesDraft.request({ cursor });
            }
          }}
        >
          <View css={{ display: 'flex', justifyContent: 'center', marginTop: '14px' }}>
            <ActivityIndicator size={40} />
          </View>
        </ViewportTracking>
      );
    }
    return null;
  }, [data, hasNextPage, loadMoreStatus, loadmorePagesDraft]);

  useEffect(() => {
    getPagesDraft.request(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FieldBox radius={6} borderColor="gray2" css={{ padding: '20px 10px' }}>
      <AsyncComponent
        status={getStatus}
        isEmpty={data.length === 0}
        Success={
          <View>
            <GridSmart columnCount={1} columnWidth={300}>
              {data.map(item => {
                const listVisited = get();
                const orderVisited = listVisited.findIndex(itemVisited => itemVisited.commandId === item.commandId);
                return (
                  <DraftBox
                    key={item.commandId}
                    title={
                      <View css={{ display: 'flex', alignItems: 'center' }}>
                        <View css={{ marginRight: '4px' }} tagName="span">
                          {item.label}
                        </View>
                        {orderVisited !== -1 && (
                          <Tooltip
                            defaultVisible={listVisited.length === 1 || listVisited.length === 2}
                            overlayInnerStyle={{ width: 600, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
                            title={
                              <View onClick={e => e.stopPropagation()}>
                                <View css={{ fontSize: '20px', fontWeight: 500 }}>Bạn đã từng truy cập page này trong phiên thao tác</View>
                                <View>
                                  Tại sao lại có cái này:
                                  <View tagName="ul">
                                    <View tagName="li">Giảm được phần nào kịch bản "Để người sửa page biết đã sửa những page nào nếu quên"</View>
                                    <View tagName="li">
                                      Trong quá trình save lại page có thể lơ đễnh và quên mất là đã thao tác với những page nào rồi. Từ đó có thể gây
                                      ra cho người thực hiện "sự nghi ngờ", "sự khó chịu khi dùng app quá sida", ...
                                    </View>
                                  </View>
                                </View>
                                <View>
                                  Danh sách page đã truy cập trước đó:
                                  <View tagName="ul">
                                    {listVisited.map(({ commandId, label }) => (
                                      <View tagName="li" key={commandId}>
                                        {label}
                                      </View>
                                    ))}
                                  </View>
                                </View>
                              </View>
                            }
                          >
                            <Text size={12} color="light" css={{ padding: '4px 6px', borderRadius: '6px' }} backgroundColor="warning">
                              Đã từng truy cập
                            </Text>
                          </Tooltip>
                        )}
                      </View>
                    }
                    slug={`Page type: ${item.type}`}
                    image={item.image.src}
                    CustomDropdown={<></>}
                    cssContainer={{ padding: '15px 20px', marginBottom: '0' }}
                    onClick={handlePreview(item)}
                    loading={
                      queueApproving.includes(item.commandId) ||
                      queueDeleting.includes(item.commandId) ||
                      queueRejecting.includes(item.commandId) ||
                      queueCommiting.includes(item.commandId) ||
                      queueDisabling.includes(item.commandId) ||
                      queueEnabling.includes(item.commandId) ||
                      statusSocketConnection === 'loading'
                    }
                    Right={
                      <>
                        {Actions(item)}
                        <View
                          color="light"
                          fontFamily="secondary"
                          backgroundColor="dark"
                          css={styles.rightItem}
                          onClick={() => {
                            handlePreviewWithApplyAll(item);
                          }}
                        >
                          {i18n.t('general.apply_all')}
                        </View>
                        <View
                          color="light"
                          fontFamily="secondary"
                          backgroundColor="behance"
                          css={styles.rightItem}
                          onClick={() => {
                            connect({
                              onSuccess: () => {
                                enableShopifyPageDraft.request({
                                  commandId: item.commandId,
                                  data: item,
                                  onFulfill: () => disconnect({}),
                                });
                              },
                              onError: () => {
                                notification.error({
                                  message: i18n.t('publish_shopify.init_sync_error.message'),
                                  description: i18n.t('publish_shopify.init_sync_error.description'),
                                });
                              },
                            });
                          }}
                        >
                          {i18n.t('general.enable', { text: 'shopify' })}
                        </View>
                        <View
                          color="light"
                          fontFamily="secondary"
                          backgroundColor="digg"
                          css={styles.rightItem}
                          onClick={() => {
                            connect({
                              onSuccess: () => {
                                disableShopifyPageDraft.request({
                                  commandId: item.commandId,
                                  data: item,
                                  onFulfill: () => disconnect({}),
                                });
                              },
                              onError: () => {
                                notification.error({
                                  message: i18n.t('publish_shopify.init_sync_error.message'),
                                  description: i18n.t('publish_shopify.init_sync_error.description'),
                                });
                              },
                            });
                          }}
                        >
                          {i18n.t('general.disable', { text: 'shopify' })}
                        </View>{' '}
                        <View
                          color="light"
                          fontFamily="secondary"
                          backgroundColor="linkedin"
                          css={styles.rightItem}
                          onClick={() => {
                            connect({
                              onSuccess: () => {
                                deletePageDraft.request({
                                  commandId: item.commandId,
                                  name: item.label,
                                  type: item.type,
                                  onlyShopify: true,
                                  onFulfill: () => disconnect({}),
                                });
                              },
                              onError: () => {
                                notification.error({
                                  message: i18n.t('publish_shopify.init_sync_error.message'),
                                  description: i18n.t('publish_shopify.init_sync_error.description'),
                                });
                              },
                            });
                          }}
                        >
                          {i18n.t('general.delete', { text: 'shopify' })}
                        </View>
                      </>
                    }
                  />
                );
              })}
            </GridSmart>
            {TrackingLoadmore}
            <ModalCommitDraft />
            <ModalRejectDraft />

            <CreateProductPicker onCancel={handleCancel} onOk={handleOk} />
            <CreateCollectionPicker onCancel={handleCancel} onOk={handleOk} />
            <CreateArticlePicker onCancel={handleCancel} onOk={handleOk} />
          </View>
        }
      />
    </FieldBox>
  );
};
