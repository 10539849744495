import { AdminTheme, ClientTheme, DevTheme } from 'types/Theme';
import { v4 } from 'uuid';
import { BE_ThemeAtom, BE_ThemeDraft } from '../Atom/types';
import { _defaultThemeSettings } from '../Logic/getThemeVedaActivate';
import { BE_ThemeClient } from '../VedaApplication/types';
import { SCHEMES } from 'containers/BuilderPage/components/Settings/ThemeSettings/ThemeColorScheme/components/SelectColorSchemes';

export const handleGlobalTranslationsNColorSchemesInThemeAtom = (theme: BE_ThemeAtom): AdminTheme => {
  return {
    ...theme,
    vendors: theme?.vendors ? theme.vendors.map(vendor => ({ ...vendor, id: v4() })) : [],
    themeSettings: theme?.themeSettings
      ? {
          ...theme.themeSettings,
          colorSchemes:
            theme.themeSettings.colorSchemes ??
            SCHEMES.map(item => ({ colors: item.colors, cssClassName: item.cssClassName, uniqueName: item.name })),
        }
      : _defaultThemeSettings,
  };
};

export const handleGlobalTranslationsNColorSchemesInThemeDraft = (theme: BE_ThemeDraft): DevTheme => {
  return {
    ...theme,
    vendors: theme.vendors ? theme.vendors.map(vendor => ({ id: v4(), ...vendor })) : [],
    themeSettings: theme?.themeSettings
      ? {
          ...theme.themeSettings,
          colorSchemes:
            theme.themeSettings.colorSchemes ??
            SCHEMES.map(item => ({ colors: item.colors, cssClassName: item.cssClassName, uniqueName: item.name })),
        }
      : _defaultThemeSettings,
  };
};

export const handleGlobalTranslationsNColorSchemesInThemeClient = (theme: BE_ThemeClient): ClientTheme => {
  return {
    ...theme,
    vendors: theme?.vendors ? theme.vendors.map(vendor => ({ ...vendor, id: v4() })) : [],
    themeSettings: theme?.themeSettings
      ? {
          ...theme.themeSettings,
          colorSchemes:
            theme.themeSettings.colorSchemes ??
            SCHEMES.map(item => ({ colors: item.colors, cssClassName: item.cssClassName, uniqueName: item.name })),
        }
      : _defaultThemeSettings,
    isShopifyTheme: !!theme.isShopifyTheme,
    shopifyThemeId: theme.shopifyThemeId ?? undefined,
  };
};
