import { notification } from 'antd';
import { MODAL_REPORT_ACTIVE_THEME_VEDA_ERROR } from 'containers/ModalReportAfterError/const';
import { ModalReportAfterError } from 'containers/ModalReportAfterError/ModalReportAfterError';
import { call, put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { adapterGetAddons } from 'services/AddonService/Adapters/adapterGetAddons';
import { checkThemeAppStatus } from 'services/ShopifyConnection/flowSyncToShopify/checkThemeAppStatus';
import { activeThemeClientService } from 'services/ThemeService/Logic/activeThemeClient';
import { getAddonsOfThemeClient } from 'services/ThemeService/Logic/getAddonsOfThemeClient';
import { publishClientDraftTheme } from 'services/ThemeService/Logic/publishClientDraftTheme';
import { setLayoutSettings } from 'store/actions/actionLayoutSettings';
import { getThemeObjectNCss } from 'store/actions/liquid/actionLiquidVariables';
import { updateCssVariables } from 'store/global/cssVariables/slice';
import { setGlobalThemeJs } from 'store/global/globalThemeJs/slice';
import { setGlobalThemeScss } from 'store/global/globalThemeScss/slice';
import { setFileLanguageActive, setGlobalThemeTranslation } from 'store/global/globalTranslation/slice';
import { setThemeGeneralSettings } from 'store/global/themeSettings/slice';
import { setThemeVendors } from 'store/global/themeVendors/slice';
import { setIsSavedTheme, setOriginThemeSettings } from 'store/reducers/sliceOriginThemeSettings';
import { i18n } from 'translation';
import { Vendor } from 'types/Result';
import { ClientTheme } from 'types/Theme';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { activeThemeVeda } from '../slice/actions';
import { setThemeActive } from '../slice/sliceThemeDashboard';
import { setIsSaveAddons, setOriginalThemeAddons } from 'store/reducers/sliceOriginThemeAddons';
import { AxiosError } from 'axios';
import { handleUpdateThemeIdOfUser } from 'store/global/auth/sagas/watchUpdateThemeIdOfUser';

function* handleChangePublishStatus(clientTheme: ClientTheme) {
  // Active theme veda
  yield put(getThemeObjectNCss.request({ variant: 'Action chạy sau khi active theme khác ở client theme manager' }));
  try {
    const response: SagaReturnType<typeof activeThemeClientService> = yield retry(3, 1000, activeThemeClientService, {
      commandId: clientTheme.commandId,
    });
    yield put(activeThemeVeda.success({ themeId: response.commandId }));
    yield put(setThemeActive({ ...response }));

    const {
      themeSettings: {
        cssVariables: { colors, fonts },
        generalSettings,
        globalTranslations,
        layoutSettings,
        colorSchemes,
      },
      vendors,
      globalJs,
      globalScss,
    } = response;

    yield put(setLayoutSettings(layoutSettings));
    yield put(updateCssVariables({ colors, fonts }));
    yield put(setThemeGeneralSettings(generalSettings));
    yield put(setGlobalThemeScss(globalScss));
    yield put(setGlobalThemeJs(globalJs));
    yield put(setThemeVendors({ vendors: vendors as Vendor[] }));
    yield put(setGlobalThemeTranslation(globalTranslations.translation));

    yield put(
      setFileLanguageActive(globalTranslations.languageActive ? globalTranslations.languageActive : Object.keys(globalTranslations.translation)[0]),
    );

    yield put(setIsSavedTheme(true));
    yield put(
      setOriginThemeSettings({
        cssVariables: {
          colors,
          fonts,
        },
        generalSettings,
        globalTranslations,
        layoutSettings,
        vendors,
        globalJs,
        globalScss,
        colorSchemes,
      }),
    );

    notifyAxiosHandler.handleSuccess(`${i18n.t('general.update')} ${i18n.t('general.successfully')}`);
  } catch (error) {
    yield put(activeThemeVeda.failure({ themeId: clientTheme.commandId }));
    throw error;
  }
}

export function* saveAddonsInPublishTheme(addonCommandIds: string[]) {
  try {
    const addonResponse: Awaited<ReturnType<typeof getAddonsOfThemeClient>> = yield retry(3, 1000, getAddonsOfThemeClient, { addonCommandIds });
    const addons = adapterGetAddons(addonResponse);
    yield put(setIsSaveAddons(true));
    yield put(setOriginalThemeAddons(addons));
  } catch (error) {
    console.log('saveAddonsInPublishTheme', error);
  }
}

function* handleActiveThemeOfVeda(clientTheme: ClientTheme) {
  // Active theme trên shopify
  yield retry(3, 1000, publishClientDraftTheme, { shopifyThemeId: clientTheme.shopifyThemeId ?? '', themeName: clientTheme.label });
  yield call(handleChangePublishStatus, clientTheme);

  /**
   * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
   */
  // try {
  //   const { eventId }: ReturnType<typeof socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector> = yield select(
  //     socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector,
  //   );
  //   yield retry(3, 1000, shopifyConnectionService.writeAtomicCssToShopify, {
  //     eventId,
  //     isPreview: false,
  //     eventType: 'Ghi file atomic css khi publish theme',
  //   });
  // } catch {}

  yield put(activeThemeVeda.success({ themeId: clientTheme.commandId }));
}

function* handleActiveThemeOfShopify(clientTheme: ClientTheme) {
  const response: SagaReturnType<typeof activeThemeClientService> = yield retry(3, 1000, activeThemeClientService, {
    commandId: clientTheme.commandId,
  });
  yield put(activeThemeVeda.success({ themeId: clientTheme.commandId }));
  yield put(setThemeActive({ ...response }));
  const {
    themeSettings: {
      cssVariables: { colors, fonts },
      generalSettings,
      globalTranslations,
      layoutSettings,
    },
    vendors,
    globalJs,
    globalScss,
  } = response;

  yield put(setLayoutSettings(layoutSettings));
  yield put(updateCssVariables({ colors, fonts }));
  yield put(setThemeGeneralSettings(generalSettings));
  yield put(setGlobalThemeScss(globalScss));
  yield put(setGlobalThemeJs(globalJs));
  yield put(setThemeVendors({ vendors: vendors as Vendor[] }));
  yield put(setGlobalThemeTranslation(globalTranslations.translation));
  yield put(
    setFileLanguageActive(globalTranslations.languageActive ? globalTranslations.languageActive : Object.keys(globalTranslations.translation)[0]),
  );

  notifyAxiosHandler.handleSuccess(`${i18n.t('general.update')} ${i18n.t('general.successfully')}`);
}

function* handleActive({ payload }: ReturnType<typeof activeThemeVeda.request>) {
  const { clientTheme, onFulfill, onFailed } = payload;
  try {
    const isActived: SagaReturnType<typeof checkThemeAppStatus> = yield call(checkThemeAppStatus);
    if (!isActived) {
      yield put(activeThemeVeda.failure({ themeId: clientTheme.commandId }));
      onFulfill?.();
      return;
    }
    if (clientTheme.isShopifyTheme) {
      yield call(handleActiveThemeOfShopify, clientTheme);
    } else {
      yield call(handleActiveThemeOfVeda, clientTheme);
    }
    if (clientTheme.shopifyThemeId) {
      yield call(handleUpdateThemeIdOfUser, { type: '@Global/updateThemeIdOfUser/request', payload: { themeId: clientTheme.shopifyThemeId } });
    }

    yield call(saveAddonsInPublishTheme, clientTheme.addonCommandIds);
  } catch (error) {
    if ((error as AxiosError).response?.status === 400) {
      onFailed?.((error as AxiosError<ErrorData>).response?.data.message ?? (error as Error).message);
    } else {
      ModalReportAfterError.getActions(MODAL_REPORT_ACTIVE_THEME_VEDA_ERROR).report({
        cause: i18n.t('ModalReportAfterError.error_cause.active_theme_veda'),
        description: (error as AxiosError<ErrorData>).response?.data.message ?? (error as Error).message,
      });
    }
    notification.error({ message: (error as AxiosError<ErrorData>).response?.data.message ?? (error as Error).message });
    yield put(activeThemeVeda.failure({ themeId: clientTheme.commandId }));
  } finally {
    onFulfill?.();
  }
}

export function* watchActiveThemeVeda() {
  yield takeLatest(getActionType(activeThemeVeda.request), handleActive);
}
