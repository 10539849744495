import { userApis } from 'services/UserService/apis';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';

interface ApprovePageDraft {
  commandId: string;
}

export const approvePageDraft = async ({ commandId }: ApprovePageDraft) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    const response = await pageApis.atom.adminApi.page.approveDraftToAtom({ devCommandId: commandId });
    await userApis.user.adminApi.updateVedaCacheVersion();
    return response;
  }
  throw new RoleException();
};
