import { removeDuplicateByKey } from '@wiloke/functions';
import { AxiosError } from 'axios';
import { themeDashboardSelector } from 'containers/Admin/ThemeBuilder/ThemeDashboard/slice/sliceThemeDashboard';
import { setResponsive } from 'containers/BuilderPage/store/responsive/slice';
import { MODAL_REPORT_SAVE_ERROR } from 'containers/ModalReportAfterError/const';
import { ModalReportAfterError } from 'containers/ModalReportAfterError/ModalReportAfterError';
import { uniqBy } from 'lodash';
import { Task } from 'redux-saga';
import { all, call, cancel, fork, put, retry, SagaReturnType, select, take } from 'redux-saga/effects';
import { mediaService } from 'services/MediaService';
import { createAddonOfPageAtom } from 'services/PageService/Logic/createAddonOfPageAtom';
import { createMegamenuOfPageAtom } from 'services/PageService/Logic/createMegamenuOfPageAtom';
import { createPageAtom } from 'services/PageService/Logic/createPageAtom';
import { createSectionOfPageAtom } from 'services/PageService/Logic/createSectionOfPageAtom';
import { deleteMegaMenusOfPageAtom } from 'services/PageService/Logic/deleteMegaMenusOfPageAtom';
import { deleteSectionsOfPageAtom } from 'services/PageService/Logic/deleteSectionsOfPageAtom';
import { updateAddonOfPageAtom } from 'services/PageService/Logic/updateAddonOfPageAtom';
import { updateMegamenuOfPageAtom } from 'services/PageService/Logic/updateMegamenuOfPageAtom';
import { updatePageAtom } from 'services/PageService/Logic/updatePageAtom';
import { updateSectionOfPageAtom } from 'services/PageService/Logic/updateSectionOfPageAtom';
import { updateThemeSettingsClient } from 'services/ThemeService/Logic/updateThemeSettingsClient';
import {
  addMegaMenusToPage,
  removeDeletedSectionAddonMegaMenuCommandIds,
  setPage,
  setThemeAddonsToPages,
  updateMainSectionsToPages,
} from 'store/actions/actionPages';
import { setVendors } from 'store/actions/actionVendors';
import { setGeneralSettingsPage } from 'store/global/generalSettings/slice';
import { setGlobalJs } from 'store/global/globalJs/slice';
import { setGlobalScss } from 'store/global/globalScss/slice';
import { setPageColorSchemes } from 'store/global/pageColorSchemes/pageColorSchemesSlice';
import { syncToShopify } from 'store/global/socket/syncShopifyNClonePageAtomServiceToThemeAtomService/actions';
import { syncPageNotification } from 'store/global/socket/syncShopifyNClonePageAtomServiceToThemeAtomService/watchSyncToShopify';
import { setThemeAddons } from 'store/global/themeAddons';
import { setIsSavedTheme, setOriginThemeSettings } from 'store/reducers/sliceOriginThemeSettings';
import { pageSectionsSelector, pagesSelector, themeAddonsSelector, themeSettingsSelector } from 'store/selectors';
import { i18n } from 'translation';
import { AddonOfTheme_Atom_N_Client } from 'types/Addons';
import { AdminPage, PageType } from 'types/Page';
import { DEFAULT_COLOR_SCHEME_NAMES } from 'types/Result';
import { PageSection, SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client } from 'types/Sections';
import { isDefault, isMain } from 'utils/functions/checkSectionType';
import { deepFind } from 'utils/functions/deepFind';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { ErrorData } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { savePageForAdmin } from './actions';

interface SaveSectionOfPageAtom {
  section: PageSection;
  megaMenus: PageSection[];
}

function* handleSaveSectionOfPageAtom(section: SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client, variant: 'Create' | 'Update') {
  const pageSections: ReturnType<typeof pageSectionsSelector> = yield select(pageSectionsSelector);

  const megaMenuIds = deepFind(section, 'megaMenuId');
  const megaMenuSections = pageSections.filter(item => megaMenuIds.includes(item.id));

  /** Xử lý lưu mega menu */
  const megamenuResponses: Array<Awaited<ReturnType<typeof createMegamenuOfPageAtom | typeof updateMegamenuOfPageAtom>>> = yield all(
    megaMenuSections.map(megamenuSection => {
      if (megamenuSection.commandId && variant === 'Update') {
        return retry(3, 1000, updateMegamenuOfPageAtom, {
          megamenu: megamenuSection as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client,
        });
      } else {
        return retry(3, 1000, createMegamenuOfPageAtom, {
          megamenu: megamenuSection as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client,
        });
      }
    }),
  );

  const megaMenuCommandIds = megamenuResponses.map(megamenuResponse => megamenuResponse.commandId).filter(Boolean);
  /** Xử lý lưu section */
  if (section.commandId && variant === 'Update') {
    const response: Awaited<ReturnType<typeof updateSectionOfPageAtom>> = yield retry(3, 1000, updateSectionOfPageAtom, {
      section: { ...section, megaMenuCommandIds } as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client,
    });
    return {
      section: response,
      megaMenus: megamenuResponses,
    };
  } else {
    const response: Awaited<ReturnType<typeof createSectionOfPageAtom>> = yield retry(3, 1000, createSectionOfPageAtom, {
      section: { ...section, megaMenuCommandIds } as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client,
    });
    return {
      section: response,
      megaMenus: megamenuResponses,
    };
  }
}

function* handleCreatePageAtom({ payload }: ReturnType<typeof savePageForAdmin.request>) {
  const { page, settings, outputBuilder, isOverrideIndividualPages, onFulfill, onSuccess, onError, isIgnoreSyncShopify } = payload;
  const mainSections = page.sections.filter(section => isMain(section.type) || isDefault(section.type));
  try {
    const { role } = getUserInfo();
    const { data: themeAddons }: ReturnType<typeof themeAddonsSelector> = yield select(themeAddonsSelector);
    const themeSettings: ReturnType<typeof themeSettingsSelector> = yield select(themeSettingsSelector);

    const pageAddons = themeAddons.filter(item => (item as AddonOfTheme_Atom_N_Client).isAddonOfPage);
    const _pageColorScheme = themeSettings.colorSchemes.colorSchemes.filter(item => !DEFAULT_COLOR_SCHEME_NAMES.includes(item.cssClassName));

    const { url }: Awaited<ReturnType<typeof mediaService.pollingUploadBase64ScreenshotToMyMedia>> = yield call(
      mediaService.pollingUploadBase64ScreenshotToMyMedia,
      { base64: page.image.src },
      role,
    );

    /** Xử lý lưu section & megamenu */
    // TODO: Utils transform thay vì ép kiểu
    let sectionResponse: PageSection[] = [];
    let megaMenusResponse: PageSection[] = [];

    const addonResponses: Array<Awaited<ReturnType<typeof createAddonOfPageAtom | typeof updateAddonOfPageAtom>>> = yield all(
      pageAddons.map(addon => {
        const currentPage = page;
        const isUsedInCurrentPage = page.sections.find(section => section.addonIds?.includes(addon.sectionId));
        const prevBelongToPages = (addon as AddonOfTheme_Atom_N_Client).belongsToPage?.filter(page => page.commandId !== currentPage.commandId) ?? [];

        const currentBelongToPages: AddonOfTheme_Atom_N_Client['belongsToPage'] = (isUsedInCurrentPage ? [page] : []).map(page => {
          // Nếu addon thuộc header, footer thì commandId + label => allPages
          if (addon.type === 'built-in') {
            return {
              commandId: 'allPages',
              label: 'allPages',
            };
          }
          return {
            commandId: page.commandId,
            label: page.label,
          };
        });
        const finalBelongToPages = uniqBy(prevBelongToPages.concat(currentBelongToPages), 'commandId');
        if (addon.commandId) {
          return retry(3, 1000, updateAddonOfPageAtom, {
            addon: { ...addon, belongsToPage: finalBelongToPages, category: undefined } as AddonOfTheme_Atom_N_Client,
          });
        }
        return retry(3, 1000, createAddonOfPageAtom, {
          addon: { ...addon, belongsToPage: finalBelongToPages, category: undefined } as AddonOfTheme_Atom_N_Client,
        });
      }),
    );

    const responses: SaveSectionOfPageAtom[] = yield all(
      mainSections.map(section => {
        return call(handleSaveSectionOfPageAtom, section as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client, 'Create');
      }),
    );
    for (const response of responses) {
      sectionResponse = sectionResponse.concat(response.section);
      megaMenusResponse = megaMenusResponse.concat(response.megaMenus).flat();
    }

    yield put(updateMainSectionsToPages({ sections: sectionResponse, pageId: page.commandId }));

    /** Xử lý lưu page */
    const response: Awaited<ReturnType<typeof createPageAtom>> = yield retry(3, 1000, createPageAtom, {
      page: {
        ...page,
        image: { ...page.image, src: url },
        sections: sectionResponse.length > 0 ? sectionResponse : [],
        enable: true,
        label: settings.generalSettings.label,
        addonCommandIds: addonResponses.map(item => item.commandId),
      },
      pageSettings: {
        ...settings,
        colorSchemes: _pageColorScheme,
      },
    });

    const { info } = response;

    if (info.pageSettings) {
      yield put(setGeneralSettingsPage({ settings: info.pageSettings.generalSettings }));
      yield put(setVendors({ vendors: info.pageSettings.vendors }));
      yield put(setGlobalJs({ js: info.pageSettings.globalJs }));
      yield put(setGlobalScss({ scss: info.pageSettings.globalScss }));
      if (info.pageSettings?.colorSchemes) {
        yield put(setPageColorSchemes(info.pageSettings.colorSchemes));
      }
    }

    const { themeActivate }: ReturnType<typeof themeDashboardSelector> = yield select(themeDashboardSelector);
    const themeResponse: SagaReturnType<typeof updateThemeSettingsClient> = yield retry(3, 1000, updateThemeSettingsClient, {
      commandId: themeActivate.commandId,
      themeSettings: {
        themeSettings: {
          ...themeSettings,
          colorSchemes: themeSettings.colorSchemes.colorSchemes,
        },
      },
    });

    yield put(
      setPage({
        ...page,
        label: settings.generalSettings.label,
        commandId: info.commandId,
        type: info.type as PageType,
        sections: removeDuplicateByKey([...page.sections, ...sectionResponse], 'id'),
      }),
    );
    yield put(addMegaMenusToPage(megaMenusResponse));
    yield put(setThemeAddons({ addons: addonResponses }));

    const pageResponse = {
      ...page,
      ...((response.info as unknown) as AdminPage),
      commandId: response.info.commandId,
    };
    if (isIgnoreSyncShopify) {
      syncPageNotification.done();
      onSuccess?.(pageResponse);
      onFulfill(pageResponse);
    } else {
      const outputBuilderAfterResponse: typeof outputBuilder = {
        ...outputBuilder,
        pages: {
          ...outputBuilder.pages,
          [page.commandId]: {
            ...outputBuilder.pages[page.commandId],

            /**
             * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
             */
            // atomicCss: outputBuilder.pages[page.commandId].atomicCss,
            data: {
              ...outputBuilder.pages[page.commandId].data,
              page: {
                ...page,
                commandId: response.info.commandId,
                label: settings.generalSettings.label,
                addonCommandIds: addonResponses.map(item => item.commandId),
              },
            },
            files: outputBuilder.pages[page.commandId].files,
          },
        },
      };

      yield put(
        syncToShopify.request({
          deletedAddonIds: [],
          entityVariant: 'Atom',
          result: outputBuilderAfterResponse,
          isOverrideIndividualPages,
          onSyncFulfill: () => {
            onSuccess?.(pageResponse);
            onFulfill(pageResponse);
          },
        }),
      );
    }
    const themeAddonsBody = themeAddons.map(item => item.body).filter(Boolean) ?? [];
    yield put(setThemeAddonsToPages(themeAddonsBody));
    yield put(savePageForAdmin.success(undefined));
    yield put(setResponsive('desktop'));
    yield put(setIsSavedTheme(true));

    yield put(
      setOriginThemeSettings({
        cssVariables: themeResponse.themeSettings.cssVariables,
        generalSettings: themeResponse.themeSettings.generalSettings,
        globalTranslations: themeResponse.themeSettings.globalTranslations,
        layoutSettings: themeResponse.themeSettings.layoutSettings,
        vendors: themeResponse.vendors,
        globalJs: themeResponse.globalJs,
        globalScss: themeResponse.globalScss,
        colorSchemes: themeResponse.themeSettings.colorSchemes,
      }),
    );
  } catch (error) {
    onFulfill();
    onError?.();
    syncPageNotification.done();
    ModalReportAfterError.getActions(MODAL_REPORT_SAVE_ERROR).report({
      cause: i18n.t('ModalReportAfterError.error_cause.save_result'),
      description: (error as AxiosError).isAxiosError ? (error as AxiosError<ErrorData>).response?.data.message ?? '' : (error as Error).message,
    });
    yield put(savePageForAdmin.failure(undefined));
  }
}

function* handleUpdatePageAtom({ payload }: ReturnType<typeof savePageForAdmin.request>) {
  const { page, settings, outputBuilder, isOverrideIndividualPages, onFulfill, isIgnoreSyncShopify, onSuccess, onError } = payload;
  const mainSections = page.sections.filter(section => isMain(section.type) || isDefault(section.type));

  try {
    const { role } = getUserInfo();
    const { data: themeAddons }: ReturnType<typeof themeAddonsSelector> = yield select(themeAddonsSelector);
    const themeSettings: ReturnType<typeof themeSettingsSelector> = yield select(themeSettingsSelector);

    const pageAddons = themeAddons.filter(item => (item as AddonOfTheme_Atom_N_Client).isAddonOfPage);
    const _pageColorScheme = themeSettings.colorSchemes.colorSchemes.filter(item => !DEFAULT_COLOR_SCHEME_NAMES.includes(item.cssClassName));

    const { url }: Awaited<ReturnType<typeof mediaService.pollingUploadBase64ScreenshotToMyMedia>> = yield call(
      mediaService.pollingUploadBase64ScreenshotToMyMedia,
      { base64: page.image.src },
      role,
    );

    const { deletedMegaMenuCommandIds, deletedSectionCommandIds }: ReturnType<typeof pagesSelector> = yield select(pagesSelector);

    if (deletedSectionCommandIds.length > 0) {
      for (const deletedSectionCommandId of deletedSectionCommandIds) {
        yield retry(3, 1000, deleteSectionsOfPageAtom, { commandId: deletedSectionCommandId });
        yield put(removeDeletedSectionAddonMegaMenuCommandIds({ deletedSectionCommandId }));
      }
    }
    if (deletedMegaMenuCommandIds.length > 0) {
      for (const deletedMegaMenuCommandId of deletedMegaMenuCommandIds) {
        yield retry(3, 1000, deleteMegaMenusOfPageAtom, { commandId: deletedMegaMenuCommandId });
        yield put(removeDeletedSectionAddonMegaMenuCommandIds({ deletedMegaMenuCommandId }));
      }
    }

    /** Xử lý lưu section & megamenu */
    // TODO: Utils transform thay vì ép kiểu
    let sectionResponse: PageSection[] = [];
    let megaMenusResponse: PageSection[] = [];

    const addonResponses: Array<Awaited<ReturnType<typeof createAddonOfPageAtom | typeof updateAddonOfPageAtom>>> = yield all(
      pageAddons.map(addon => {
        const currentPage = page;
        const isUsedInCurrentPage = page.sections.find(section => section.addonIds?.includes(addon.sectionId));
        const prevBelongToPages = (addon as AddonOfTheme_Atom_N_Client).belongsToPage?.filter(page => page.commandId !== currentPage.commandId) ?? [];

        const currentBelongToPages: AddonOfTheme_Atom_N_Client['belongsToPage'] = (isUsedInCurrentPage ? [page] : []).map(page => {
          // Nếu addon thuộc header, footer thì commandId + label => allPages
          if (addon.type === 'built-in') {
            return {
              commandId: 'allPages',
              label: 'allPages',
            };
          }
          return {
            commandId: page.commandId,
            label: page.label,
          };
        });
        const finalBelongToPages = uniqBy(prevBelongToPages.concat(currentBelongToPages), 'commandId');
        if (addon.commandId) {
          return retry(3, 1000, updateAddonOfPageAtom, {
            addon: { ...addon, belongsToPage: finalBelongToPages, category: undefined } as AddonOfTheme_Atom_N_Client,
          });
        }
        return retry(3, 1000, createAddonOfPageAtom, {
          addon: { ...addon, belongsToPage: finalBelongToPages, category: undefined } as AddonOfTheme_Atom_N_Client,
        });
      }),
    );

    const responses: SaveSectionOfPageAtom[] = yield all(
      mainSections.map(section => {
        return call(handleSaveSectionOfPageAtom, section as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client, 'Update');
      }),
    );
    for (const response of responses) {
      sectionResponse = sectionResponse.concat(response.section);
      megaMenusResponse = megaMenusResponse.concat(response.megaMenus).flat();
    }

    yield put(updateMainSectionsToPages({ sections: sectionResponse, pageId: page.commandId }));

    const response: Awaited<ReturnType<typeof updatePageAtom>> = yield retry(3, 1000, updatePageAtom, {
      page: {
        ...page,
        image: { ...page.image, src: url },
        sections: sectionResponse.length > 0 ? sectionResponse : [],
        enable: true,
        label: settings.generalSettings.label,
        addonCommandIds: addonResponses.map(item => item.commandId),
      },
      pageSettings: {
        ...settings,
        colorSchemes: _pageColorScheme,
      },
    });

    const { info } = response;

    if (info.pageSettings) {
      yield put(setGeneralSettingsPage({ settings: info.pageSettings.generalSettings }));
      yield put(setVendors({ vendors: info.pageSettings.vendors }));
      yield put(setGlobalJs({ js: info.pageSettings.globalJs }));
      yield put(setGlobalScss({ scss: info.pageSettings.globalScss }));
      if (info.pageSettings?.colorSchemes) {
        yield put(setPageColorSchemes(info.pageSettings.colorSchemes));
      }
    }

    const { themeActivate }: ReturnType<typeof themeDashboardSelector> = yield select(themeDashboardSelector);
    const themeResponse: SagaReturnType<typeof updateThemeSettingsClient> = yield retry(3, 1000, updateThemeSettingsClient, {
      commandId: themeActivate.commandId,
      themeSettings: {
        themeSettings: {
          ...themeSettings,
          colorSchemes: themeSettings.colorSchemes.colorSchemes,
        },
      },
    });

    yield put(
      setPage({
        ...page,
        label: settings.generalSettings.label,
        commandId: info.commandId,
        type: info.type as PageType,
        sections: removeDuplicateByKey([...page.sections, ...sectionResponse], 'id'),
      }),
    );
    yield put(addMegaMenusToPage(megaMenusResponse));
    yield put(setThemeAddons({ addons: addonResponses }));

    const pageResponse = {
      ...page,
      ...((response.info as unknown) as AdminPage),
      commandId: response.info.commandId,
    };
    if (isIgnoreSyncShopify) {
      syncPageNotification.done();
      onSuccess?.(pageResponse);
      onFulfill(pageResponse);
    } else {
      yield put(
        syncToShopify.request({
          deletedAddonIds: [],
          entityVariant: 'Atom',
          result: outputBuilder,
          isOverrideIndividualPages,
          onSyncFulfill: () => {
            onSuccess?.(pageResponse);
            onFulfill(pageResponse);
          },
        }),
      );
    }

    const themeAddonsBody = themeAddons.map(item => item.body).filter(Boolean) ?? [];
    yield put(setThemeAddonsToPages(themeAddonsBody));

    yield put(savePageForAdmin.success(undefined));
    yield put(setResponsive('desktop'));
    yield put(setIsSavedTheme(true));
    yield put(
      setOriginThemeSettings({
        cssVariables: themeResponse.themeSettings.cssVariables,
        generalSettings: themeResponse.themeSettings.generalSettings,
        globalTranslations: themeResponse.themeSettings.globalTranslations,
        layoutSettings: themeResponse.themeSettings.layoutSettings,
        vendors: themeResponse.vendors,
        globalJs: themeResponse.globalJs,
        globalScss: themeResponse.globalScss,
        colorSchemes: themeResponse.themeSettings.colorSchemes,
      }),
    );
  } catch (error) {
    onFulfill();
    onError?.();
    syncPageNotification.done();
    ModalReportAfterError.getActions(MODAL_REPORT_SAVE_ERROR).report({
      cause: i18n.t('ModalReportAfterError.error_cause.save_result'),
      description: (error as AxiosError).isAxiosError ? (error as AxiosError<ErrorData>).response?.data.message ?? '' : (error as Error).message,
    });

    yield put(savePageForAdmin.failure(undefined));
  }
}

function* handleSavePageAtom(params: ReturnType<typeof savePageForAdmin.request>) {
  const { method } = params.payload;
  syncPageNotification.next({
    title: i18n.t('publish_shopify.sync_page_message.step', { text: '2' }),
    description: i18n.t('general.saving', { text: i18n.t('general.page') }),
  });
  if (method === 'create') {
    yield call(handleCreatePageAtom, params);
  }
  if (method === 'update') {
    yield call(handleUpdatePageAtom, params);
  }
}

let savePageTask: Task | undefined;

export function* watchSavePageAtom() {
  while (true) {
    const requestAction: ReturnType<typeof savePageForAdmin.request> = yield take(getActionType(savePageForAdmin.request));
    if (!!savePageTask) {
      yield cancel(savePageTask);
    }
    savePageTask = yield fork(handleSavePageAtom, requestAction);
  }
}

export function* watchCancelSavePageAtom() {
  while (true) {
    const cancelAction: ReturnType<typeof savePageForAdmin.cancel> = yield take(getActionType(savePageForAdmin.cancel));
    if (cancelAction.type === '@BuilderPage/savePageForAdmin/cancel' && !!savePageTask) {
      yield cancel(savePageTask);
    }
  }
}
