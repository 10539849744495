import { reducersImageGallery } from 'components/ChooseImage/reducers';
import { adminDashboardReducers } from 'containers/Admin/stores';
import { sliceSettingManager } from 'containers/Admin/GeneralSettingsManagement/store';
import { reducerModals } from 'containers/Admin/Modals/store';
import { sliceCoupon } from 'containers/Admin/PlanManagement/store/sliceCoupon';
import { slicePlan } from 'containers/Admin/PlanManagement/store/slicePlan';
import { slicePresetStyle } from 'containers/Admin/PresetStylesPage/reducer/slice';
import { slicePurchaseCodePage } from 'containers/Admin/PurchaseCodePage/slice';
import { reducersBuilderPage } from 'containers/BuilderPage/store';
import { reducersChooseTemplate } from 'containers/ChooseTemplate/store/reducers';
import { reducersIframe } from 'containers/IframePage';
import { sliceAskBeforeSave } from 'containers/ModalAskBeforeSave/slice';
import { sliceModalInstallTheme } from 'containers/ModalInstallTheme/slice';
import { reducerAuthors } from './global/authors/reducer';
import { sliceGlobalMount } from './global/globalMount/slice';
import { slicePageCounter } from './global/pageCounter/slicePageCounter';
import { reducerRenewData } from './global/renewDataBuilder/reducers';
import { reducersSocket } from './global/socket/reducers';
import reducersGlobal from './reducers';
import { reducerDefaultPickerFieldRelateShopify } from './reducers/liquid/reducerDefaultPickerFieldRelateShopify';
import { reducerLiquidVariables } from './reducers/liquid/reducerLiquidVariables';
import { reducerExtractThemeToFileForSync } from 'packages/ExtractThemeToFileForSync';
import { reducerSyncThemeByResultOfExtractThemeToFileForSync } from 'packages/SyncThemeByResultOfExtractThemeToFileForSync';
import { sliceVisibleTour } from 'containers/Admin/Modals/components/ModalStepTour/store/sliceModalTour';
import { reducerListingSectionsUpdatable } from 'packages/ListingSectionsUpdatable/redux/slice';
import { sliceTourGuides } from 'containers/TourGuides/sliceTourGuides';
import { sliceModalUpdatePlan } from 'containers/ModalUpdatePlan/sliceModalUpdatePlan';
import { slicePartner } from 'containers/Admin/PartnerManagement/store/slicePartner';
import { sliceImportCSV } from 'store/global/importCSV/sliceImportCSV';
import { instructionSlice } from 'store/global/vedaInstruction/instructionReducer';

const reducers = {
  global: reducersGlobal,
  chooseTemplate: reducersChooseTemplate,
  adminDashboard: adminDashboardReducers,
  modals: reducerModals,
  iframe: reducersIframe,
  imageGallery: reducersImageGallery,
  builderPage: reducersBuilderPage,
  globalMount: sliceGlobalMount.reducer,
  presetStyles: slicePresetStyle.reducer,
  askBeforeSave: sliceAskBeforeSave.reducer,
  defaultPickerFieldRelateShopify: reducerDefaultPickerFieldRelateShopify,
  liquidVariables: reducerLiquidVariables,
  socket: reducersSocket,
  authors: reducerAuthors,
  plan: slicePlan.reducer,
  renewDataBuilder: reducerRenewData,
  pageCounter: slicePageCounter.reducer,
  coupon: sliceCoupon.reducer,
  purchaseCodes: slicePurchaseCodePage.reducer,
  modalInstallTheme: sliceModalInstallTheme.reducer,
  settingsManager: sliceSettingManager.reducer,
  extractThemeToFileForSync: reducerExtractThemeToFileForSync,
  syncThemeByResultOfExtractThemeToFileForSync: reducerSyncThemeByResultOfExtractThemeToFileForSync,
  modalTour: sliceVisibleTour.reducer,
  listingSectionsUpdatable: reducerListingSectionsUpdatable,
  tourGuide: sliceTourGuides.reducer,
  modalUpdatePlan: sliceModalUpdatePlan.reducer,
  partners: slicePartner.reducer,
  importCSV: sliceImportCSV.reducer,
  vedaInstruction: instructionSlice.reducer,
};

export default reducers;
