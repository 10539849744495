import { CodeSpliting } from 'components/CodeSpliting';
import SectionPageHeader from 'components/SectionPageHeader';
import { ModalCreateCollection, ModalDeletePageDashboard } from 'containers/Admin/Modals';
import { useChangeModalAdminSettings } from 'containers/Admin/Modals/store';
import { Dashboard } from 'containers/Dashboard';
import { CreateCollectionPicker } from 'containers/Shopify/ModalCreateShopifyPicker';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { collectionPageSelector } from 'store/selectors';
import { i18n } from 'translation';
import { COLLECTION_PAGE_MAIN_CONTENT } from 'utils/constants/chunkIds';
import { View } from 'wiloke-react-core';
import { useGetCollectionPageItems } from '.';
import { CollectionSettings } from '../DashboardPageSettings/components/CollectionSettings';
import { DashboardPageSettings } from '../DashboardPageSettings/DashboardPageSettings';

export const CollectionPage: FC = () => {
  const changeSettings = useChangeModalAdminSettings();

  const { filterType, search } = useSelector(collectionPageSelector);
  const getItems = useGetCollectionPageItems();

  useEffect(() => {
    getItems.request({ s: search, pageType: 'collection', filterType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filterType]);

  return (
    <Dashboard
      pageType="collection"
      Modals={() => (
        <>
          <ModalCreateCollection />
          <ModalDeletePageDashboard pageType="collection" />
          <DashboardPageSettings pageType="collection" />
          <CollectionSettings />
          <CreateCollectionPicker />
        </>
      )}
      Content={() => (
        <View>
          <SectionPageHeader
            pageType="collection"
            title={i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.collection') })}
            description={i18n.t('adminDashboard.page_description.collection')}
            onClick={() => {
              changeSettings({ createCollection: true });
            }}
          />

          <CodeSpliting
            component={() => import(/* webpackChunkName: "CollectionPageMainContent", webpackPrefetch: true */ './MainContent')}
            CHUNK_ID={COLLECTION_PAGE_MAIN_CONTENT}
          />
        </View>
      )}
    />
  );
};
