import { Styles, css } from 'wiloke-react-core';

export const line = css`
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const colorGrid = css`
  padding-right: 0;
  display: grid;
  grid-template-columns: 1.75fr 1.25fr repeat(2, 0.5fr);
  grid-template-rows: 80px 0px;
  overflow: hidden;
`;
export const colorFlex = css`
  display: flex;
`;

export const innerBox = css`
  justify-content: space-between;
  margin: 0;
`;

export const titleContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const tdItem: Styles = {
  width: '100%',
  padding: '0 6px',
};
export const tr: Styles = {
  display: 'flex',
  alignItems: 'center',
};
export const iconDisable: Styles = {
  zIndex: '10',
  width: '25px',
  height: '25px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
