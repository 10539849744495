import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

interface GetPagesOfThemeDraft {
  commandIds: string[];
}

export const getPagesOfThemeDraft = async ({ commandIds }: GetPagesOfThemeDraft) => {
  if (!commandIds.length) {
    return [];
  }
  const { role } = getUserInfo();
  if (role === 'admin') {
    const response = await themeApis.atom.adminApi.page.getPagesOfDraft({ commandIds: commandIds.join(',') });
    return response.info;
  }
  if (role === 'dev' || role === 'support') {
    const response = await themeApis.atom.devApi.page.getPagesOfDraft({ commandIds: commandIds.join(',') });
    return response.info;
  }
  throw new RoleException();
};
