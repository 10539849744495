import { AxiosError } from 'axios';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { downgradeToFreeService } from 'services/UserService/Logic/downgradeToFree';
import { pmLanding } from 'utils/functions/postMessage';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { downgradeToFreePlan, subscriptionChargeId } from '../actions';

function* handleAction({ payload: { planHandle, onFulfill } }: ReturnType<typeof downgradeToFreePlan.request>) {
  try {
    const response: SagaReturnType<typeof downgradeToFreeService> = yield retry(3, 1000, downgradeToFreeService);
    yield put(downgradeToFreePlan.success({ planHandle: response.info.planHandle, confirmation_url: '' }));
    pmLanding.emit('@landing/plan/success', { plan: response.info.planHandle, type: 'yearly' });
    yield put(subscriptionChargeId.success({ message: response.message }));
    onFulfill?.();
  } catch (error) {
    notifyAxiosHandler.handleError(error as AxiosError<ErrorData> | Error);
    yield put(downgradeToFreePlan.failure({ planHandle }));
    pmLanding.emit('@landing/plan/failure');
  }
}

export function* watchDowngradeToFree() {
  yield takeLatest(getActionType(downgradeToFreePlan.request), handleAction);
}
