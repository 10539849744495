import { AxiosResponse } from 'axios';
import { AdminSection } from 'types/Sections';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface FilterAtom {
  categoryName?: string;
  label?: string;
  categoryCommandId?: string;
  commandIds?: string;
  syncedToProduct?: boolean | null;
}

interface ResponseSuccess {
  info: AdminSection[];
  message: string;
}

/** API được sử dụng để filter section atom theo params truyền vào */
export const filterAtom = async ({ categoryCommandId, categoryName, label, commandIds, syncedToProduct }: FilterAtom) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${baseUrl}/search`,
    params: {
      categoryName: categoryName && categoryName !== '' ? categoryName : undefined,
      label: label && label !== '' ? label : undefined,
      categoryId: categoryCommandId && categoryCommandId !== '' ? categoryCommandId : undefined,
      commandIds: commandIds && commandIds !== '' ? commandIds : undefined,
      syncedToProduct,
    },
  });
  return response.data;
};
