import { themeApis } from 'services/ThemeService/apis';
import { BE_ThemeAtom } from 'services/ThemeService/Atom/types';
import { getPagesOfThemeAtom } from 'services/ThemeService/Logic/getPagesOfThemeAtom';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';

async function getPages(pageCommandIds: string[]) {
  const pagesOfTheme = await getPagesOfThemeAtom({ commandIds: pageCommandIds });
  return pagesOfTheme;
}

async function getThemes(themesAtom: BE_ThemeAtom[]) {
  return themesAtom.reduce<Promise<Array<{ label: string; pageIds: string[] }>>>(async (res, theme) => {
    const pagesAtomOfTheme = await getPages(theme.pageCommandIds ?? []);
    const item = { label: theme.label, pageIds: pagesAtomOfTheme.map(page => page.parentCommandId ?? '') };
    const _res = await res;
    return _res.concat(item);
  }, Promise.resolve([]));
}

export const getPagesAtom = async (searchKey?: string, stickyOnly?: boolean, syncedToProduct?: boolean | null) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const atomResponse = await pageApis.atom.adminApi.page.getAtoms({
      type: 'GET FIRST PAGE',
      cacheVersion: lastCache,
      label: searchKey,
      stickyOnly,
      syncedToProduct,
    });

    const pageParentCommandIds = atomResponse.info.map(item => item.commandId).join(',');

    const productResponse = await pageApis.vedaApplication.adminApi.page.getProductsWithParentCommandIds({
      parentCommandIds: pageParentCommandIds,
      cacheVersion: lastCache,
    });

    const themeAtom = await themeApis.atom.adminApi.theme.filterAtoms({ cacheVersion: lastCache, pageParentCommandIds });
    const themeIncludesPagesAtom = await getThemes(themeAtom.info);

    return atomResponse.info.map(atom => ({
      ...atom,
      vedaProductRepresent: productResponse.info.find(product => product.parentCommandId === atom.commandId),
      addedInThemes: themeIncludesPagesAtom.filter(theme => theme.pageIds.filter(Boolean).includes(atom.commandId)).map(theme => theme.label),
    }));
  }
  if (role === 'dev' || role === 'support') {
    const response = await pageApis.atom.devApi.page.getAtoms({
      type: 'GET FIRST PAGE',
      label: searchKey,
      stickyOnly,
      syncedToProduct,
    });

    return response.info;
  }
  throw new RoleException();
};
