import { updatePageUseCase } from 'containers/Admin/Management/Pages/store/actions/actionPageUseCase';
import { SagaReturnType, put, retry, takeLatest } from 'redux-saga/effects';
import { updateUseCaseOfProdPage } from 'services/PageService/Logic/updateUseCaseOfProdPage';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';

function* handleUpdate({ payload: { commandId, description, name, belongsToPageTypes } }: ReturnType<typeof updatePageUseCase.request>) {
  try {
    const response: SagaReturnType<typeof updateUseCaseOfProdPage> = yield retry(3, 1000, updateUseCaseOfProdPage, {
      commandId,
      description,
      name,
      belongsToPageTypes,
    });
    yield put(
      updatePageUseCase.success({
        commandId: response.info.commandId,
        description: response.info.description,
        name: response.info.name,
        belongsToPageTypes: response.info.belongsToPageTypes,
      }),
    );
    notifyAxiosHandler.handleSuccess(response.message);
  } catch (error) {
    const error_ = error as Error;
    notifyAxiosHandler.handleError(error_);
    yield put(updatePageUseCase.failure({ commandId }));
  }
}

export function* watchUpdatePageUseCase() {
  yield takeLatest(getActionType(updatePageUseCase.request), handleUpdate);
}
