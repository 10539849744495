import { AsyncComponent, ScreenProps, ScrollBars, useStackNavigator } from '@wiloke/ui';
import HeaderDrawer from 'components/HeaderDrawer';
import ImageTextCard from 'components/ImageTextCard';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';
import { LeftBarParamList } from 'containers/BuilderPage/components/SidebarScreen/SidebarScreen';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { authSelector, megaMenuSelector } from 'store/selectors';
import { i18n } from 'translation';
import { ProductSection } from 'types/Sections';
import { PLAN_ENUM, PLAN_STATIC_DATA } from 'utils/constants/PlanEnum';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { validatePlan } from 'utils/functions/validatePlan';
import { View } from 'wiloke-react-core';
import { useDeleteProductMegaMenu, useInstallUserMegaMenu } from '../components/DraggableMenu';
import { useAddUserMegaMenuFlow } from '../components/DraggableMenu/store/actions';
import { SettingDragMenuChildren } from '../components/DraggableMenu/types';
import { useActionMenu } from '../components/DraggableMenu/utils/useActionMenu';

export const MegaMenuUserScreen: FC<ScreenProps<LeftBarParamList, 'sectionMegaMenu'>> = ({ params }) => {
  const { currentNode, path, sectionParentId } = params;
  const { megaSections, getSectionsStatus, installStatus } = useSelector(megaMenuSelector.userMegaMenu);
  const { plan } = useSelector(authSelector);

  const navigation = useStackNavigator<LeftBarParamList>();
  const { handleChangeForm } = useActionMenu();
  const installMegaMenu = useInstallUserMegaMenu();
  const addUserMegaMenuFlow = useAddUserMegaMenuFlow();
  const deleteMegaMenu = useDeleteProductMegaMenu();
  const { role } = getUserInfo();
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();

  const handleChooseMegaMenu = (productSection: ProductSection) => () => {
    if (validatePlan({ userPlan: plan.planHandle, itemPlan: productSection.planHandle ?? PLAN_ENUM.free, role }) === 'pass') {
      if (productSection.parentCommandId) {
        installMegaMenu.request({
          commandId: productSection.parentCommandId,
          productMegaMenu: productSection,
          onFulFill: dataResponse => {
            addUserMegaMenuFlow({
              megaMenu: dataResponse,
              node: currentNode as SettingDragMenuChildren,
              sectionParentId,
              onDone: () => {
                handleChangeForm({ megaMenuId: dataResponse.id } as SettingDragMenuChildren, currentNode, path);
                navigation.goBack();
              },
            });
          },
        });
      }
    } else {
      setPlanRequesting({
        handle: PLAN_STATIC_DATA[productSection.planHandle ?? PLAN_ENUM.business].handle,
        type: PLAN_STATIC_DATA[productSection.planHandle ?? PLAN_ENUM.business].type,
        pricePerMonth: PLAN_STATIC_DATA[productSection.planHandle ?? PLAN_ENUM.business].pricePerMonth,
        pricePerYear: PLAN_STATIC_DATA[productSection.planHandle ?? PLAN_ENUM.business].pricePerYear,
        title: PLAN_STATIC_DATA[productSection.planHandle ?? PLAN_ENUM.business].title,
        originalPricePerMonth: PLAN_STATIC_DATA[productSection.planHandle ?? PLAN_ENUM.business].pricePerMonth,
        originalPricePerYear: PLAN_STATIC_DATA[productSection.planHandle ?? PLAN_ENUM.business].pricePerYear,
      });
      setValidateCouponVisible(true);
    }
  };

  return (
    <View css={{ height: '100%' }}>
      <HeaderDrawer
        title={i18n.t('general.back')}
        goBack={() => {
          navigation.goBack();
        }}
      />

      <ScrollBars css={{ height: 'calc(100% - 80px) !important' }}>
        <AsyncComponent
          status={getSectionsStatus}
          isEmpty={megaSections.length === 0}
          Success={
            <View css={{ padding: '10px' }}>
              {megaSections.map(item => (
                <ImageTextCard
                  key={item.commandId}
                  containerCss={{ marginBottom: '5px' }}
                  loading={installStatus[item.parentCommandId ?? ''] === 'loading'}
                  label={item.label}
                  src={item.image?.src}
                  widthImage={item.image?.width}
                  heightImage={item.image?.height}
                  buttonText={i18n.t('general.choose')}
                  onAdd={handleChooseMegaMenu(item)}
                  planHandle={item.planHandle}
                  onDelete={
                    role === 'admin'
                      ? () => {
                          deleteMegaMenu.request({ commandId: item.commandId });
                        }
                      : undefined
                  }
                  disableText={'Mega menu này đã tồn tại'}
                />
              ))}
            </View>
          }
        />
      </ScrollBars>
    </View>
  );
};
