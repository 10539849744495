import { useSetTemplateBoardVisible } from 'containers/ChooseTemplate/store/actions';
import { FC } from 'react';
import { i18n } from 'translation';
import { FontAwesome, Space, View } from 'wiloke-react-core';
import * as styles from './styles';
import getPageInfo from 'utils/functions/getInfo';
import { PAGE_BUILD_HEADER_FOOTER } from 'utils/constants/constants';

export interface AddSectionProps {}

export const AddSection: FC<AddSectionProps> = () => {
  const pageId = getPageInfo('id');

  const setTemplateBoardVisible = useSetTemplateBoardVisible();
  const visibleCondition = pageId !== PAGE_BUILD_HEADER_FOOTER;

  return (
    <View
      onClick={() => {
        setTemplateBoardVisible({
          visible: true,
          navKeys: ['sections'],
          sectionType: visibleCondition ? 'default' : 'header',
        });
      }}
      css={styles.container}
    >
      <View>
        <FontAwesome size={40} color="gray9" name="plus-circle" type="fal" />

        <Space size={10} />
        <View tagName="h6" fontFamily="secondary" color="gray9" css={{ margin: 0, fontWeight: '500' }}>
          {visibleCondition ? i18n.t('schema.add', { text: i18n.t('general.section') }) : i18n.t('schema.add', { text: i18n.t('general.header') })}
        </View>
      </View>
    </View>
  );
};
