import { ClientTheme } from 'types/Theme';
import { getCurrentVersion } from 'utils/CacheControl/VedaClientCacheControl';
import { handleGlobalTranslationsNColorSchemesInThemeClient } from '../Adapter/handleGlobalTranslationsNColorSchemes';
import { themeApis } from '../apis';

export const getClientThemesAPI = async ({ size, themeStatus }: { size: number; themeStatus?: string }): Promise<ClientTheme[]> => {
  const response = await themeApis.vedaApplication.userApi.theme.getClients({
    type: 'GET FIRST PAGE',
    cacheVersion: getCurrentVersion().getTime(),
    themeStatus,
    size,
  });
  return response.info.map(handleGlobalTranslationsNColorSchemesInThemeClient);
};
