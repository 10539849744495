import { mergeDeepLeft } from 'ramda';
import { ColorSchemeField } from '../../../@types/Shopify/InputSettings/ColorSchemeField';
import { Locales } from '../../../@types/Veda/ShopifyLocales';
import { ignoreToReduceTotalLinesOfCode } from '../../../@utils/ignoreToReduceTotalLinesOfCode';
import { replaceExactVariableNameInLiquidCode } from '../../../@utils/replaceExactVariableNameInLiquidCode';
import { ILIQUID_SettingColorScheme } from './@types/ILIQUID_SettingColorScheme';
import { ISCHEMA_SettingColorScheme } from './@types/ISCHEMA_SettingColorScheme';
import { ISETTING_SettingColorScheme } from './@types/ISETTING_SettingColorScheme';
import { getSignalReduceTotalLinesOfCode } from './utils/getSignalReduceTotalLinesOfCode';
import { toShopifyFieldId } from './utils/toShopifyFieldId';
import { vedaLabelToShopifyFieldLabel } from './utils/vedaLabelToShopifyFieldLabel';
import { vedaSummaryToShopifyFieldInfo } from './utils/vedaSummaryToShopifyFieldInfo';
import { TextField } from '../../../@types/Shopify/InputSettings/TextField';

interface RTSettingColorScheme {
  shopifyField: ColorSchemeField | TextField;
  locales: Locales;
}

export const SCHEMA_SettingColorScheme = ({ field, parentField, isExportForEnvato, ...params }: ISCHEMA_SettingColorScheme): RTSettingColorScheme => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField });
  const { newLabel, locales: labelLocales } = vedaLabelToShopifyFieldLabel({
    ...params,
    isExportForEnvato,
    field,
    parentField,
    isNeedReduceTotalLinesOfCode,
  });
  const { newInfo, locales: infoLocales } = vedaSummaryToShopifyFieldInfo({
    ...params,
    isExportForEnvato,
    field,
    parentField,
    isNeedReduceTotalLinesOfCode,
  });
  const value = typeof field.children === 'string' && !!field.children ? field.children : undefined;
  if (isExportForEnvato) {
    return {
      shopifyField: {
        label: newLabel,
        info: newInfo,
        id: isNeedReduceTotalLinesOfCode
          ? getSignalReduceTotalLinesOfCode(field)
          : toShopifyFieldId({ ...params, isExportForEnvato, field, parentField, isNeedReduceTotalLinesOfCode }),
        type: 'color_scheme',
        default: value,
      },
      locales: mergeDeepLeft(labelLocales, infoLocales),
    };
  }
  return {
    shopifyField: {
      label: newLabel,
      info: newInfo,
      id: isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field)
        : toShopifyFieldId({ ...params, isExportForEnvato, field, parentField, isNeedReduceTotalLinesOfCode }),
      type: 'text',
      default: value,
      placeholder: undefined,
    },
    locales: mergeDeepLeft(labelLocales, infoLocales),
  };
};

export const SETTING_SettingColorScheme = ({ field, isPreview, parentField, ...params }: ISETTING_SettingColorScheme) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField });
  // check kĩ phòng lỗi
  const value = typeof field.children === 'string' && !!field.children ? field.children : undefined;
  if (value !== undefined) {
    const fieldName = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode, isPreview });
    return { [fieldName]: value };
  }
  return {};
};

export const LIQUID_SettingColorScheme = ({ field, parentField, liquid, loopVariable, isPreview, ...params }: ILIQUID_SettingColorScheme) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField });
  /// Nếu field thuộc array
  // - loopVariable nếu là array ->  Array được dùng trong forloop -> Thế `${loopVariable}.${field.name}.${key}` = `${loopVariable}.${toShopifyFieldId({ field, parentField }, key)}`
  //   Example: --> for item in content --> item.icon_design.id ==> KQ: item.content__icon_design.id
  if (loopVariable) {
    const target = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : `${loopVariable}.${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode, isPreview })}`;
    const source = `${loopVariable}.${field.name}`;
    return replaceExactVariableNameInLiquidCode({ liquid, source, target });
  }
  // Nếu field thuộc object
  // - parentField nếu là object -> Object được flat thành các "shopify input settings" -> Thế `${parentField.name}.${field.name}.${key}` = toShopifyFieldId
  //   Example: --> heading.icon_design.id ==> KQ: heading__icon_design.id
  else if (parentField) {
    const target = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode, isPreview })}"]`;
    const source = `${parentField.name}.${field.name}`;
    return replaceExactVariableNameInLiquidCode({ liquid, source, target });
  } else {
    const target = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode, isPreview })}"]`;
    const source = field.name;
    return replaceExactVariableNameInLiquidCode({ liquid, source, target });
  }
};
