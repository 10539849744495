import { CancelTokenSource } from 'axios';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

interface GetPagesInfomationOfThemeAtom {
  pageCommandIds: string[];
  cancelTokenSource: CancelTokenSource;
}

export const getPagesInfomationOfThemeAtom = async ({ pageCommandIds, cancelTokenSource }: GetPagesInfomationOfThemeAtom) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    return themeApis.atom.adminApi.page.getPagesInfomationOfAtom({
      lastCache,
      pageCommandIds,
      cancelTokenSource,
    });
  }
  if (role === 'dev' || role === 'support') {
    return themeApis.atom.devApi.page.getPagesInfomationOfAtom({
      lastCache,
      pageCommandIds,
      cancelTokenSource,
    });
  }
  throw new RoleException();
};
