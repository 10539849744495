import { reorder } from '@wiloke/functions';
import { PreviewImage } from 'types/Page';

export const swapPickedImage = (images: Array<{ url: string; [key: string]: any }>, currentImage: PreviewImage | undefined) => {
  if (!currentImage || currentImage?.src === '') {
    return images;
  }

  const currentIdx = images.findIndex(image => image.url === currentImage.src);
  const filename = currentImage.src
    .split('/')
    .pop()
    ?.replace(/(.jpg|.png|.jpeg).*/gm, '');

  if (currentIdx >= 0) {
    return reorder(images, currentIdx, 0);
  } else {
    const result = [{ url: currentImage.src, width: currentImage.width, height: currentImage.height, name: filename }, ...images];
    return result;
  }
};
