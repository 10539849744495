import {
  watchCancelSyncThemeByResultOfExtractThemeToFileForSync,
  watchSyncThemeByResultOfExtractThemeToFileForSync,
} from './store/sagas/watchSyncThemeByResultOfExtractThemeToFileForSync';

export const sagasSyncThemeByResultOfExtractThemeToFileForSync = [
  watchCancelSyncThemeByResultOfExtractThemeToFileForSync,
  watchSyncThemeByResultOfExtractThemeToFileForSync,
];

export { reducerSyncThemeByResultOfExtractThemeToFileForSync } from './store/reducer';
export { ModalSyncThemeByResultOfExtractThemeToFileForSync } from './components/ModalSyncThemeByResultOfExtractThemeToFileForSync';
export { ModalPreviewUrlsAfterSyncTheme } from './components/ModalPreviewUrlsAfterSyncTheme';
export { selectSyncThemeByResultOfExtractThemeToFileForSync } from './selectors';
