export const adminDashboard = {
  confirm_delete: 'Do you want to delete these pages? Caution: This action cannot be undone',
  search_empty: 'Sorry, we cannot find any results that matched your searching keywords.',
  page_description: {
    article: 'An article page should be both informative and captivating to retain readers.',
    regular: "Create distinct landing pages like a wishlist, an informational 'About Us' page, contact information, and customer reviews.",
    collection: "A stunning collection page can spark customer's curiosity and encourage further exploration.",
    home: 'Make a great first impression with a polished and professional homepage.',
    product: "An exquisite product page can compel customers to add to cart when they're hesitating.",
    preset: 'Select a cohesive aesthetic to ensure your store has a consistent visual identity.',
    theme_settings: 'Unlock a plethora of personalized possibilities to make your store unique.',
    quick_create: 'Unearth stylish designs surging in popularity today!',
    live_theme: "Take a peek at your store's face! Tweak it to your heart's content!",
    draft_themes: 'These themes are only visible to you. You can switch to another theme by publishing it to your store.',
  },
  views: 'Views',
  timeOnPage: 'Time On Page',
  conversion: ' Conversion Rate',
  publish: 'Publish',
  draft: 'Draft %% text %%',
  all: 'All',
  get_started: 'Get Started',
  get_started_description: 'Get up and running quickly by viewing our user-friendly guide video!',
  quickly: 'Quickly',
  customize: 'Customize',
  blank: 'Blank',
  collection: 'Collection %% text %%',
  product: 'Product',
  article: 'Article',
  lastUpdate: 'Last Update',
  all_templates: 'All %% text %% Templates',
  my_templates: 'My Templates',
  fav_templates: 'Saved Templates',
  theme_templates: 'Theme Templates',
  page_templates: 'Page Templates',
  page_tempaltes_description: 'An array of page templates awaits you, ready to be tailored to your exacting requirements.',
  join_our_team: 'Join Our Team',
  specific_text: 'Specific %% text %%',
  theme_settings_page: 'Theme Settings',
  view: 'View',
  notification: 'Notification',
  account: 'Account',
  unread: 'Unread',
  featured_news: 'Featured News',
  menubar: {
    dashboard: 'Dashboard',
    templates: 'Templates',
    regular_page: 'Regular Page',
    product_page: 'Product Page',
    collection_page: 'Collection Page',
    article_pages: 'Article Page',
  },
  blog: 'Blog',
  select_presets: 'Select presets',
  choose_presets: "Choose from a selection of presets we've compiled here",
  option_presets: 'Colors, fonts and layout presets',
  theme_settings: {
    confirm_description: 'These settings will affect your entire theme',
    confirm_label: 'Select the settings you want to update to the theme',
  },
  home: 'Home',
  pleaseChooseBlog: 'Please choose a blog to see its articles',
  cart: 'Cart',
  search: 'Search %% text %%',
  password: 'Password',
  notFound: 'Not Found',
  customersLogin: 'Login',
  customersResetPassword: 'Reset Password',
  customersActivateAccount: 'Activate Account',
  customersRegister: 'Register',
  customersAccount: 'Account',
  customersOrder: 'Order',
  customersAddresses: 'Addresses',
  giftCard: 'Gift Card',
  collectionListing: 'Collection Listing',
  blog_has_no_articles: 'This blog has no articles',
  cannot_choose_blog: "You can't choose a blog without an article",
  name_your_page: 'Name your page',
  chose_shopify_variant: 'Chose %% text %%',
  chose_shopify_to_design: 'Choose %% text %% you want to design',
  chose_article_belong: 'Create a new article and assign it to the blog',
  select_something_to_design: 'Select %% text %% you want to design',
  collection_listing: 'Collection Listing',
  reset_password: 'Reset Password',
  active_account: 'Activate Account',
  register: 'Register',
  order: 'Order',
  addresses: 'Addresses',
  general: 'General',
  advanced_settings: 'Advanced Settings',
  styles: 'Styles',
  pages: '%% name %% Pages',
  themes: 'Themes',
  username: 'Username',
  featured_layouts: 'Featured Layouts',
  regular: 'Regular',
  otherPages: 'Other pages',
  collapse: 'Collapse',
  reinstall: 'Reinstall',
  no_shopify_theme_id: "Your theme has been deleted on shopify, please press the 'Reinstall' button to reinstall the theme",
  last_saved: 'Last saved',
  added_ago: 'Added %% text %% ago',
  theme_library: 'Theme library',
  landing: 'Landing',
  right_bar: {
    leave_us_a_feedback_title: 'Leave us a feedback',
    leave_us_a_feedback_desc: 'We value your opinion, please leave us a feedback to help us serve you better!',
    leave_us_a_feedback_button: 'Send Feedback',
    veda_youtube_title: 'Veda Youtube Playlist',
    veda_youtube_desc: 'Watch our video tutorials to optimize your experience.',
    quick_guide_title: 'Quick Guides',
    quick_guide_desc: 'Quick Guides',
    customization_title: 'Customization in the Live Editor',
    customization_desc: 'Customize your pages to perfection with our Live Editor guide.',
    faq_title: 'Answers To FAQs',
    faq_desc: 'Quick and helpful answers.',
    uninstall_title: 'How to uninstall Veda Builder safely',
    uninstall_desc: 'Click to learn how to uninstall Veda safely',
    recommended_apps: 'Recommended Apps',
    theme_and_page_title: 'Differences between Theme & Page Builder',
    theme_and_page_desc: "The differences between Veda's Theme Builder and Page Builder",
  },
  apps: 'Apps',
  app: 'App',
  get: 'Get %% text %%',
  danger_zone: 'Danger Zone',
  purchase_code: 'Purchase Code',
  orders: 'Orders',
  inventory: 'Inventory',
  gift_cards: 'Gift cards',
  customers: 'Customers',
  analytics: 'Analytics',
  marketing: 'Marketing',
  discounts: 'Discounts',
  store_details: 'Store details',
  collections: 'Collections',
  billing: 'Billing',
  users_and_permissions: 'Users and permissions',
  payments: 'Payments',
  checkout_and_accounts: 'Checkout and accounts',
  shipping_and_delivery: 'Shipping and delivery',
  taxes_and_duties: 'Taxes and duties',
  locations: 'Locations',
  markets: 'Markets',
  apps_and_sales_channels: 'Apps and sales channels',
  domains: 'Domains',
  customer_events: 'Customer events',
  brand: 'Brand',
  meta_fields: 'Meta fields',
  files: 'Files',
  languages: 'Languages',
  policies: 'Policies',
  account_page_welcome:
    'Welcome to Veda Builder! Our app makes Shopify page & store building easier with eye-catching templates. Check out our documentation for help and feel free to click the chat icon for support. Happy building!',
  danger_zone_page: {
    description: 'Please be careful with your action in this area.',
    danger_card_title: 'Remove all Veda files from your published theme',
    danger_card_desc: 'Clicking “Restore” will delete all Veda published layouts forever. Your theme will revert to the original layout.',
    see_details_guide: 'See details guide',
    restore: 'Restore',
    restore_veda: 'Restore Veda Page Builder',
    modal_after_restore:
      'Congrats, the Veda data have been removed from your app successfully. In order to Uninstall the Veda from your shop, please click on this',
    budget_concerns_title: 'Budget Concerns',
    budget_concerns_desc: `If cost is an issue, we're committed to helping new stores succeed, which is why we're offering an exclusive discount of 40% off for a 3-month subscription for any app plan. Simply use the code "VSTAY40" at checkout to redeem your discount. We hope this will help alleviate any budget concerns you may have.`,
    missing_features_title: 'Missing Features',
    missing_features_desc: `Hold on! It's possible that Veda Builder already supports it, and our functionality experts are available to help you confirm. Click the button below to start a live chat with one of our experts who can help you find the feature you need.
    `,
    technical_issues_title: 'Technical Issues',
    technical_issues_desc: `We apologize for any trouble you've experienced with our app. Please share more details about the issue, and our support team will be happy to assist you and improve your experience. Click the button below to chat with a real support agent now.
    `,
    no_need_title: 'No Need Anymore',
    no_need_desc: `We believe that Veda Builder can still be a valuable tool for your business, and we'd love the chance to show you exactly how.
    `,
    difficulty_of_use_title: 'Difficulty Of Use',
    difficulty_of_use_desc: `We know that using any new software can be challenging at first. That's why we offer 1:1 help from our Customer Success Experts to guide you through any difficulties you've encountered. Click the button below to connect with a real person who can assist you.
    `,
    something_else_title: 'Something Else',
    something_else_desc: `If there's something else that's causing you to consider uninstalling, please let us know. We're always looking for ways to improve our app and provide the best experience possible for our users. Your feedback is valuable to us, and we'll do everything we can to address your concerns and keep you as a happy customer.
    `,
    modal_restore_sorry: `We're sorry to hear that you're considering uninstalling Veda Builder. We're constantly working to improve our app and provide the best
    experience possible for our users.`,
    modal_restore_before: `Before you click that "Restore" button, we'd like to explore some options to address the concerns that led you to consider this action.`,
    redeem_code: 'Redeem code',
    chat_now: 'Chat now',
  },
  landing_and_more: 'Landing Pages & More',
  draft_theme: {
    delete_modal_title: 'This action will delete the theme %% text %% from Veda Builder permanently, click Ok to continue.',
    delete_modal_desc:
      "Note: Due to Shopify's policy, we are not allowed to delete this theme from your Shopify. Therefore, deleting the theme will only remove its data from Veda Page Builder. To delete or use a different theme on your Shopify, please click",
  },
  duplicate_theme: {
    title: 'Customize without affecting your live theme.',
    description:
      'You can make a copy of your live theme & sync Veda templates to it so the live theme aren’t affected. When finished customizing, you can publish the copy version.',
    select: 'Select a theme',
    click_to_learn: 'Click to learn how this feature works',
  },
  quick_guide: {
    how_to_use_theme: {
      title: 'Learn how to use Veda Theme',
      description: 'Effortlessly build your stunning store to sell more while saving money.',
    },
    how_to_use_page: {
      title: 'Learn how to use Veda Page',
      description: 'Replace/add any eye-catching page(s) to your current theme.',
    },
    recommend_apps: {
      title: 'Learn how to add Recommended Apps',
      description: 'Built-in & third-party apps to unlock more functions.',
    },
    how_to_use_section: {
      title: 'Learn how to use Veda Features',
      description: 'Various powerful features drive visitors to add to cart immediately.',
    },
  },
} as const;
