import { megaMenuApiController } from 'services/MegaMenuService';
import { adapterGetMegaMenu } from 'services/MegaMenuService/Adapters/adapterGetMegaMenu';
import { ProductSection } from 'types/Sections';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const installClientMegaMenu = async (commandId: string, productMegaMenu: ProductSection) => {
  const { lastCache } = getUserInfo();
  const response = await megaMenuApiController.atom.publishApi.mega_menu.getAtom({ commandId, cacheVersion: lastCache });

  const transformData = adapterGetMegaMenu(response.info) as ProductSection;

  return {
    info: {
      ...transformData,
      downloadedCount: productMegaMenu.downloadedCount,
      commandId: '',
      parentCommandId: transformData.commandId,
    },
    message: response.message,
  };
};
