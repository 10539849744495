import { AsyncComponent, Button, VedaLoadingItem } from '@wiloke/ui';
import {
  useCheckPurchaseCodeIsExist,
  useCheckThemeIsInstall,
  useImportThemeAtomToClient,
  useValidatePurchaseCode,
} from 'containers/Admin/ThemeBuilder/ThemeTemplates/actions';
import useIsTabVisible from 'containers/ModalInstallTheme/useIsTabVisible';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { themeBuilderSelector } from 'store/selectors';
import { Space, Text, View } from 'wiloke-react-core';
import { StepInstallThemeSuccess } from './components/StepInstallThemeSuccess';
import { StepPurchaseCode } from './components/StepPuchaseCode';
import { ID_MODAL_INSTALL_THEME_EVANTO } from './constants';
import { sliceModalInstallThemeSelector, useSetInstallThemeProduct, useSetVisibleInstallEvantoTheme } from './slice';
import { Step, StepModal } from 'components/StepModal';
import { StepInstallThemePendingEnvato } from './components/StepInstallThemePendingEnvato';
import { debounce } from '@wiloke/functions';
import { useSocketForImportThemeAtomToClientServiceNPageInThemeClient } from 'hooks/useSocket/useSocketForImportThemeAtomToClientServiceNPageInThemeClient';
import { notification } from 'antd';
import { i18n } from 'translation';
import oops from 'assets/oops.svg';

// Todo: i18n
export const ModalInstallThemeForest = () => {
  const { themeProduct, visibleInstallEvantoTheme } = useSelector(sliceModalInstallThemeSelector);
  const {
    checkThemeIsInstallStatus,
    validatePurchaseCodeStatus,
    importStatus,
    checkPurchaseCodeIsExistStatus,
    validatedShopifyThemeId,
  } = useSelector(themeBuilderSelector.templates);

  const setInstallThemeProduct = useSetInstallThemeProduct();
  const isChangeVisible = useIsTabVisible();
  const checkThemeIsInstall = useCheckThemeIsInstall();
  const history = useHistory();
  const setVisible = useSetVisibleInstallEvantoTheme();
  const validatePurchaseCode = useValidatePurchaseCode();
  const { connect, disconnect, statusSocketConnection } = useSocketForImportThemeAtomToClientServiceNPageInThemeClient();
  const importThemeAtomToClient = useImportThemeAtomToClient();
  const checkPurchaseCodeIsExist = useCheckPurchaseCodeIsExist();

  const [step, setStep] = useState<Step | undefined>(undefined);
  const [error, setError] = useState('');

  useEffect(() => {
    if (visibleInstallEvantoTheme && themeProduct && themeProduct.envatoThemeId && step && step.id === 'ID_MODAL_INSTALL_THEME_EVANTO_1') {
      checkPurchaseCodeIsExist.request({
        envatoThemeId: themeProduct.envatoThemeId,
        onFulfill: () => {
          StepModal.getId(ID_MODAL_INSTALL_THEME_EVANTO).onNavigate(handlers => {
            handlers.go('ID_MODAL_INSTALL_THEME_EVANTO_2');
          });
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleInstallEvantoTheme, themeProduct, step]);

  useEffect(() => {
    if (isChangeVisible && themeProduct) {
      if (step && step.id === 'ID_MODAL_INSTALL_THEME_EVANTO_2') {
        checkThemeIsInstall.request({
          commandId: themeProduct.commandId,
          themeName: themeProduct.label,
          group: 'envato',
          zipUrl: themeProduct.zipUrl,
          onFulfill: () => {
            StepModal.getId(ID_MODAL_INSTALL_THEME_EVANTO).onNavigate(handlers => {
              handlers.next();
            });
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeVisible, themeProduct, step]);

  useEffect(() => {
    if (visibleInstallEvantoTheme && themeProduct && validatedShopifyThemeId && step && step.id === 'ID_MODAL_INSTALL_THEME_EVANTO_3') {
      connect({
        onSuccess: () => {
          importThemeAtomToClient.request({
            group: 'envato',
            themeProductCommandId: themeProduct.commandId,
            shopifyThemeId: validatedShopifyThemeId,
            onFulfill() {
              disconnect({});
            },
          });
        },
        onError: () => {
          notification.error({
            message: i18n.t('publish_shopify.init_sync_error.message'),
            description: i18n.t('publish_shopify.init_sync_error.description'),
          });
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, themeProduct, visibleInstallEvantoTheme, validatedShopifyThemeId]);

  const handleRedirect = () => {
    if (themeProduct) {
      window.open(themeProduct.documentUrl, '_blank');
    }
  };

  const renderStep2IdleAndFailure = <StepInstallThemePendingEnvato themeName={themeProduct?.label} onRedirect={handleRedirect} />;

  const handleClose = () => {
    setVisible(false);
    setInstallThemeProduct(undefined);
    StepModal.getId(ID_MODAL_INSTALL_THEME_EVANTO).onNavigate(handlers => {
      handlers.go('ID_MODAL_INSTALL_THEME_EVANTO_1');
    });

    setStep(undefined);
    setError('');
  };

  const handleCustomize = () => {
    history.push('/theme', {
      justInstalled: true,
    });
    setVisible(false);
    StepModal.getId(ID_MODAL_INSTALL_THEME_EVANTO).onNavigate(handlers => {
      handlers.go('ID_MODAL_INSTALL_THEME_EVANTO_1');
    });
    setInstallThemeProduct(undefined);
    setStep(undefined);
  };

  const handleGetPurchaseCode = () => {
    window.open('https://help.market.envato.com/hc/en-us/articles/202822600-Where-Is-My-Purchase-Code', '_blank');
  };

  const handleVerifyPurchaseCode = debounce((value: string) => {
    if (themeProduct) {
      setError('');
      validatePurchaseCode.request({
        purchaseCode: value,
        themeImportId: themeProduct.commandId,
        onFulfill() {
          setError('');
          StepModal.getId(ID_MODAL_INSTALL_THEME_EVANTO).onNavigate(handlers => {
            handlers.next();
          });
        },
        onError(message) {
          setError(message);
        },
      });
    }
  }, 500);

  const renderLoading = (
    <View
      css={{
        padding: '50px 10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <VedaLoadingItem />
      <Space size={20} />
      <View tagName="h3">Checking...</View>
      <Space size={20} />

      <View>
        Haven't downloaded theme{' '}
        <View tagName="span" color="gray8" css={{ fontWeight: '500' }}>
          {themeProduct?.label}
        </View>{' '}
        from Themeforest?
      </View>
      <View>
        Click{' '}
        <View tagName="span" color="primary" css={{ fontWeight: '500', cursor: 'pointer' }} onClick={handleRedirect}>
          here
        </View>{' '}
        to known how to download.
      </View>
    </View>
  );

  const retryImportTheme = () => {
    if (themeProduct) {
      connect({
        onSuccess: () => {
          importThemeAtomToClient.request({
            group: 'envato',
            themeProductCommandId: themeProduct.commandId,
            shopifyThemeId: validatedShopifyThemeId,
            onFulfill() {
              disconnect({});
            },
          });
        },
        onError: () => {
          notification.error({
            message: i18n.t('publish_shopify.init_sync_error.message'),
            description: i18n.t('publish_shopify.init_sync_error.description'),
          });
        },
      });
    }
  };

  return (
    <StepModal
      id={ID_MODAL_INSTALL_THEME_EVANTO}
      visible={visibleInstallEvantoTheme}
      headerText="Install Theme"
      onClose={handleClose}
      onChange={result => {
        setStep(result.step);
      }}
      data={[
        {
          id: 'ID_MODAL_INSTALL_THEME_EVANTO_1',
          heading: checkPurchaseCodeIsExistStatus === 'loading' ? '' : 'Enter Purchase Code',
          content: (
            <AsyncComponent
              status={checkPurchaseCodeIsExistStatus}
              Failure={
                <StepPurchaseCode
                  loading={validatePurchaseCodeStatus === 'loading'}
                  value={''}
                  onChange={handleVerifyPurchaseCode}
                  onGetPurchaseCode={handleGetPurchaseCode}
                  error={error}
                />
              }
              Idle={
                <StepPurchaseCode
                  loading={validatePurchaseCodeStatus === 'loading'}
                  value={''}
                  onChange={handleVerifyPurchaseCode}
                  onGetPurchaseCode={handleGetPurchaseCode}
                  error={error}
                />
              }
              Success={<View />}
            />
          ),
          stepDescription: 'Enter Code',
        },
        {
          id: 'ID_MODAL_INSTALL_THEME_EVANTO_2',
          content: (
            <AsyncComponent
              status={checkThemeIsInstallStatus}
              Success={<StepInstallThemeSuccess onBack={handleClose} onCustomize={handleCustomize} themeName={themeProduct?.label} />}
              Request={renderLoading}
              Idle={renderStep2IdleAndFailure}
              Failure={renderStep2IdleAndFailure}
            />
          ),
          stepDescription: 'Upload Theme',
        },
        {
          id: 'ID_MODAL_INSTALL_THEME_EVANTO_3',
          content: (
            <View>
              {(statusSocketConnection === 'loading' || importStatus[themeProduct?.commandId ?? ''] === 'loading') && (
                <View
                  css={{
                    padding: '50px 10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <VedaLoadingItem />
                  <Space size={20} />
                  <View tagName="h3">Importing...</View>
                  <Space size={10} />
                  <Text size={13} color="gray5">
                    (The import process may take a few minutes, please do not close the browser until the data is imported.)
                  </Text>
                </View>
              )}

              {importStatus[themeProduct?.commandId ?? ''] === 'failure' && (
                <View
                  css={{
                    maxWidth: '600px',
                    minWidth: '200px',
                    margin: 'auto',
                    padding: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <img src={oops} style={{ maxWidth: '270px' }} />
                  <View css={{ marginTop: '8px' }}>
                    <Button size="extra-small" backgroundColor="primary" radius={6} onClick={retryImportTheme}>
                      {i18n.t('builderPage.retry')}
                    </Button>
                  </View>
                </View>
              )}

              {importStatus[themeProduct?.commandId ?? ''] === 'success' && (
                <StepInstallThemeSuccess onBack={handleClose} onCustomize={handleCustomize} themeName={themeProduct?.label} />
              )}
            </View>
          ),
          stepDescription: 'Import Data',
        },
      ]}
    />
  );
};
