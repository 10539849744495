import { AdminPageDatabase } from 'services/ResultService/atomTypes';
import { userApis } from 'services/UserService/apis';
import { PageType, PreviewImage } from 'types/Page';
import { SectionCategoryTag } from 'types/Sections';
import { RoleException } from 'utils/constants/constants';
import { PLAN_HANDLE } from 'utils/constants/PlanEnum';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';

interface PublishPageAtom {
  pageAtom: AdminPageDatabase;
  label: string;
  image: PreviewImage;
  categoryOfProduct: SectionCategoryTag | undefined;
  plan: PLAN_HANDLE;
  searchTerms?: string[];
  priorityOrder?: number;
  previewUrl?: string;
  types: PageType[];
  isSticky?: boolean;
  usecases?: SectionCategoryTag[];
  description?: string;
}

export const publishPageAtom = async ({
  pageAtom,
  label,
  image,
  categoryOfProduct,
  plan,
  priorityOrder,
  searchTerms,
  previewUrl,
  types,
  isSticky,
  usecases,
  description,
}: PublishPageAtom) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const searchResponse = await pageApis.vedaApplication.adminApi.page.getProductsWithParentCommandIds({
      parentCommandIds: pageAtom.commandId,
      cacheVersion: lastCache,
    });
    const itemExisted = searchResponse.info.find(item => item.parentCommandId === pageAtom.commandId);
    if (!!itemExisted) {
      const response = await pageApis.vedaApplication.adminApi.page.updateProduct({
        page: {
          ...itemExisted,
          type: pageAtom.type,
          label,
          image,
          planHandle: plan,
          category: categoryOfProduct
            ? {
                commandId: categoryOfProduct.commandId,
                name: categoryOfProduct.name,
                description: categoryOfProduct.description,
              }
            : undefined,
          priorityOrder,
          searchTerms,
          previewUrl,
          types,
          isSticky,
          usecases,
          description,
        },
      });
      await userApis.user.adminApi.updateVedaCacheVersion();
      return response;
    } else {
      const response = await pageApis.vedaApplication.adminApi.page.createProduct({
        page: {
          parentCommandId: pageAtom.commandId,
          image,
          label,
          type: pageAtom.type,
          downloadedCount: 0,
          planHandle: plan,
          category: categoryOfProduct
            ? {
                commandId: categoryOfProduct.commandId,
                name: categoryOfProduct.name,
                description: categoryOfProduct.description,
              }
            : undefined,
          priorityOrder,
          searchTerms,
          previewUrl,
          types,
          isSticky,
          usecases,
          description,
        },
      });
      await userApis.user.adminApi.updateVedaCacheVersion();
      return response;
    }
  }

  throw new RoleException();
};
