import { imageUrl } from '@wiloke/functions';
import { AsyncComponent, Dropdown, MyModal } from '@wiloke/ui';
import AddonsCard from 'components/AddonsCard';
import BoxCenter from 'components/BoxCenter';
import TextInput from 'components/TextInput';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';
import { useAddAddons, useDeleteProductAddons, useGetAddonsTemplate, useLoadMoreAddonsTemplate } from 'containers/ChooseTemplate/store/actions';
import { useSetSearchKeyUserAddon } from 'containers/ChooseTemplate/store/reducers/addons/user.sliceAddons';
import withDebounce from 'hocs/withDebounce';
import parse from 'html-react-parser';
import { range } from 'ramda';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import GuardedComponent from 'routes/components/GuardedComponent';
import { useSetSectionIdActive } from 'store/actions/actionSectionIdActive';
import { useSetActiveAddon } from 'store/global/themeAddons';
import { addonSectionsSelector, addonSelector, authSelector, chooseTemplateVisibleSelector } from 'store/selectors';
import { i18n } from 'translation';
import { ProductAddon } from 'types/Addons';
import { SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client } from 'types/Sections';
import { PLAN_ENUM, PLAN_STATIC_DATA } from 'utils/constants/PlanEnum';
import { pmParent } from 'utils/functions/postMessage';
import { validatePlan } from 'utils/functions/validatePlan';
import { FontAwesome, GridSmart, View, ViewportTracking } from 'wiloke-react-core';
const DebounceInput = withDebounce(TextInput, 'value', 'onValueChange');

export const AddonsContent = () => {
  const pageAddons = useSelector(addonSectionsSelector);
  const { addAddonStatus, getAddonsStatus, loadMoreStatus, deleteStatus, addons: publishAddons, hasNextPage, searchKey } = useSelector(
    addonSelector.userAddons,
  );
  const { navAddonSlug, getNavStatus, addonQuantityOfCategory } = useSelector(addonSelector.userAddonsCategory);
  const chooseTemplateVisible = useSelector(chooseTemplateVisibleSelector);

  const [addonDetail, setAddonDetail] = useState('');
  const [visible, setVisible] = useState(false);
  const { plan, role } = useSelector(authSelector);
  const filterRef = useRef<HTMLDivElement | null>(null);

  const installAddons = useAddAddons();
  const getAddonsTemplate = useGetAddonsTemplate();
  const deleteAddon = useDeleteProductAddons();
  const loadMoreAddons = useLoadMoreAddonsTemplate();
  const setSearchKeyUserAddon = useSetSearchKeyUserAddon();
  const setActiveAddon = useSetActiveAddon();
  const setSectionIdActive = useSetSectionIdActive();
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();

  useEffect(() => {
    if (filterRef.current) {
      if (chooseTemplateVisible.isScrollingContent) {
        filterRef.current.style.cssText = `
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          background-color: var(--ui-color-gray2);
          padding: 10px;
          z-index: 1000;
        `;
      } else {
        filterRef.current.style.cssText = `
          position: relative;
        `;
      }
    }
  }, [chooseTemplateVisible.isScrollingContent]);

  useEffect(() => {
    if (getNavStatus === 'success') {
      getAddonsTemplate.request({ categoryName: navAddonSlug, limit: 20, search: searchKey });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navAddonSlug, getNavStatus, searchKey]);

  const _handleInstall = (item: ProductAddon) => () => {
    if (validatePlan({ userPlan: plan.planHandle, itemPlan: item.planHandle ?? PLAN_ENUM.free, role }) === 'pass') {
      if (!!item.saved && !item.canAddMulti) {
        return;
      }
      installAddons.request({
        commandId: item.parentCommandId ?? '',
        productAddon: item,
        onFulfilled(response) {
          setActiveAddon({ addon: response });
          setSectionIdActive(response.sectionId);
          pmParent.emit('@section/sectionIdActive', response.sectionId);
        },
      });
    } else {
      setPlanRequesting({
        handle: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].handle,
        type: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].type,
        pricePerMonth: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].pricePerMonth,
        pricePerYear: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].pricePerYear,
        title: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].title,
        originalPricePerMonth: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].pricePerMonth,
        originalPricePerYear: PLAN_STATIC_DATA[item.planHandle ?? PLAN_ENUM.business].pricePerYear,
      });
      setValidateCouponVisible(true);
    }
  };

  const renderLoading = (
    <GridSmart columnWidth={200} columnCount={3} columnGap={10}>
      {range(0, addonQuantityOfCategory).map(i => (
        <AddonsCard.Loading key={i} />
      ))}
    </GridSmart>
  );

  const TrackingLoadMore = useMemo(() => {
    const lastCursor = publishAddons.at(-1)?.commandId;
    if (lastCursor && hasNextPage && getAddonsStatus === 'success') {
      return (
        <ViewportTracking
          offsetTop={-200}
          onEnterViewport={() => {
            if (loadMoreStatus !== 'loading') {
              loadMoreAddons.request({ cursor: lastCursor, search: searchKey });
            }
          }}
        >
          {loadMoreStatus === 'loading' ? (
            <GridSmart columnWidth={200} columnCount={3} columnGap={10}>
              {range(0, 3).map(i => (
                <AddonsCard.Loading key={i} />
              ))}
            </GridSmart>
          ) : null}
        </ViewportTracking>
      );
    }
    return null;
  }, [hasNextPage, loadMoreAddons, loadMoreStatus, publishAddons, getAddonsStatus, searchKey]);

  const renderSuccess = () => {
    return (
      <>
        <GridSmart columnWidth={120} columnCount={3} columnGap={10}>
          {publishAddons.map(item => {
            return (
              <AddonsCard key={item.commandId} hide={!!item.saved && !item.canAddMulti}>
                <AddonsCard.Feature
                  disabled={
                    pageAddons.map(item => (item as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client)?.parentCommandId).includes(item.commandId) &&
                    item.canAddMulti === false
                  }
                  onClick={_handleInstall(item)}
                  loading={addAddonStatus[item.parentCommandId ?? ''] === 'loading' || deleteStatus[item.commandId ?? ''] === 'loading'}
                  image={item.image?.src ? imageUrl(item.image.src, 500) : ''}
                  buttonDetailText={item.detail ? 'Detail' : ''}
                  onClickDetail={() => {
                    setAddonDetail(item.detail ?? '');
                    setVisible(true);
                  }}
                />
                <AddonsCard.Body
                  planHandle={item.planHandle}
                  logo={item.logo}
                  title={item.label}
                  text={item.tagLine}
                  type={item.type}
                  typeColor="#292dcb"
                />
                <AddonsCard.Footer
                  author={item.authorName}
                  Right={
                    <GuardedComponent roles={['admin']}>
                      <Dropdown
                        data={[{ label: i18n.t('general.delete'), value: 'delete' }]}
                        onClick={type => {
                          if (type === 'delete') {
                            deleteAddon.request({ commandId: item.commandId });
                          }
                        }}
                      >
                        <BoxCenter size={15}>
                          <FontAwesome type="far" name="ellipsis-v" size={16} color="gray6" colorHover="primary" />
                        </BoxCenter>
                      </Dropdown>
                    </GuardedComponent>
                  }
                />
              </AddonsCard>
            );
          })}
        </GridSmart>
        {TrackingLoadMore}
      </>
    );
  };

  return (
    <View css={{ padding: '10px' }}>
      <View
        tagName="div"
        ref={filterRef}
        css={{
          display: 'flex',
          columnGap: '10px',
          marginBottom: '8px',
          alignItems: 'center',
        }}
      >
        <View width={300}>
          <DebounceInput
            radius={4}
            sizeInput="small"
            block
            placeholder={i18n.t('adminDashboard.search', { text: i18n.t('general.addons') })}
            value={searchKey}
            onValueChange={setSearchKeyUserAddon}
            css={{ height: '40px' }}
          />
        </View>
      </View>

      <View id="choose-template-addon">
        <AsyncComponent Request={renderLoading} isEmpty={publishAddons.length === 0} status={getAddonsStatus} Success={renderSuccess()} />
      </View>

      <MyModal
        headerText="Addons Detail"
        bodyCss={{
          width: '800px',
          height: '700px',
        }}
        isVisible={visible}
        depsHeightRecalculation={addonDetail}
        onCancel={() => {
          setVisible(false);
        }}
        okText=""
        cancelText=""
      >
        {parse(addonDetail)}
      </MyModal>
    </View>
  );
};
