import { FontAwesome, View } from 'wiloke-react-core';

export const HackTips = () => {
  return (
    <View
      tagName="a"
      target="_blank"
      href="https://help.myshopkit.app/en/docs/veda-landing-page-builder/builder-faster-tips/"
      color="gray8"
      colorHover="inherit"
      css={{
        display: 'flex',
        columnGap: '4px',
        alignItems: 'center',
        padding: '4px 6px',
        height: '35px',
      }}
      backgroundColor="light"
      radius={4}
      borderColor="tertiary"
      borderWidth={1}
      borderStyle="solid"
    >
      <View tagName="span">
        {/* @ts-ignore */}
        <FontAwesome type="far" name="lightbulb-on" color="tertiary" />
      </View>
      <View color="gray8" fontFamily="secondary" css={{ fontWeight: '500' }}>
        Build Faster Tips
      </View>
    </View>
  );
};
