import { Button, Hotspot, MyModal, Tooltip } from '@wiloke/ui';
import { useSelector } from 'react-redux';
import { pagesAtomSelector, useModalControlSections } from './store/reducers/slicePagesAtom';
import { useEffect, useState } from 'react';
import { AdminSection, SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client } from 'types/Sections';
import { getSectionsOfPageAtom } from 'services/PageService/Logic/getSectionsOfPageAtom';
import { getChangelogsOfAtoms as getChangelogsOfSectionAtoms } from 'services/SectionService/Logic/Changelogs';
import { getMegamenusOfPageAtom } from 'services/PageService/Logic/getMegamenusOfPageAtom';
import { getChangelogsOfAtoms as getChangelogsOfMegamenuAtoms } from 'services/MegaMenuService/Logic/Changelogs';
import { Table, notification, message as AntMessage } from 'antd';
import { VersionSection } from 'types/Version';
import { i18n } from 'translation';
import { FontAwesome, View } from 'wiloke-react-core';
import BoxCenter from 'components/BoxCenter/BoxCenter';
import { v4 } from 'uuid';
import { copyToClipboard } from 'utils/copyToClipboard';
import { sectionService } from 'services/SectionService';
import { handleCompareSettings } from 'containers/BuilderPage/components/UpdateVersion/flows/utils';
import { handleCompareString } from 'containers/BuilderPage/components/UpdateVersion/flows/utils';
import { flatten } from 'ramda';
import { megaMenuService } from 'services/MegaMenuService';
import { SchemaSettingField, SettingBlock } from 'types/Schema';
import { updateSection } from 'store/reducers/utils/updateSection';
import { updateMegamenuOfPageAtom } from 'services/PageService/Logic/updateMegamenuOfPageAtom';
import { updateSectionOfPageAtom } from 'services/PageService/Logic/updateSectionOfPageAtom';

interface LatestSectionInfo {
  comparedJs: string;
  comparedJsHook: string;
  comparedLiquid: string;
  comparedScss: string;
  deletedBlocks: SettingBlock[];
  deletedSettings: SchemaSettingField[];
  newBlocks: SettingBlock[];
  newSettings: SchemaSettingField[];
  updatedBlocks: SettingBlock[];
  updatedSettings: SchemaSettingField[];
  atom: AdminSection;
}
interface Record {
  section: SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client;
  version: VersionSection | undefined;
  latestSectionInfo: LatestSectionInfo | undefined;
  overridedSectionInfo: SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client | undefined;
}

const isUpdatable = (record: Pick<Record, 'section' | 'version'>) => {
  return record.version?.version && record.section.currentVersion !== record.version?.version;
};

export const ModalControlSections = () => {
  const { modalControlSections } = useSelector(pagesAtomSelector);

  const setModalControlSections = useModalControlSections();

  const [isPreparingData, setIsPrepearingData] = useState(false);
  const [isPrepareFailed, setIsPrepareFailed] = useState(false);

  const [isUpdating, setIsUpdaing] = useState(false);
  const [_, setIsUpdateFailed] = useState(false);

  const [sectionsState, setSectionsState] = useState<Record[]>([]);
  const [megamenusState, setMegamenusState] = useState<Record[]>([]);

  const handleError = (error: Error) => {
    notification.error({
      message: 'Có lỗi rồi',
      description: (
        <div>
          <p>Báo dev kèm lời nhắn: {error.message}"</p>
          <button
            style={{
              borderRadius: '4px',
              backgroundColor: '#2C36DC',
              color: '#fff',
              fontWeight: 500,
              padding: '10px 16px',
              cursor: 'pointer',
              outline: 'none',
              border: 'none',
            }}
            onClick={() => {
              copyToClipboard(error.message);
              AntMessage.success('Đã copy');
            }}
          >
            Copy lời nhắn
          </button>
        </div>
      ),
    });
  };

  const handleGetSectionLatestInfo = async (record: Pick<Record, 'section' | 'version'>) => {
    const { section } = record;
    if (isUpdatable(record) && section.parentCommandId) {
      const response = await sectionService.sections.getAtomSection(section.parentCommandId);
      const { deletedSettings, newSettings, updatedSettings, deletedBlocks, newBlocks, updatedBlocks } = handleCompareSettings(
        section.data.schema,
        response.data.schema,
      );
      const comparedLiquid = handleCompareString(section.data.liquid, response.data.liquid);

      const comparedScss = handleCompareString(section.data.scss ?? '', response.data.scss ?? '');

      const comparedJs = handleCompareString(section.data.js ?? '', response.data.js ?? '');

      const comparedJsHook = handleCompareString(section.data.jsHook ?? '', response.data.jsHook ?? '');

      return {
        comparedJs,
        comparedJsHook,
        comparedLiquid,
        comparedScss,
        deletedBlocks,
        deletedSettings,
        newBlocks,
        newSettings,
        updatedBlocks,
        updatedSettings,
        atom: response,
      };
    }
  };

  const handleGetMegamenuLatestInfo = async (record: Pick<Record, 'section' | 'version'>) => {
    const { section } = record;
    if (isUpdatable(record) && section.parentCommandId) {
      const response = await megaMenuService.mega_menu.getAtomSection(section.parentCommandId);
      const { deletedSettings, newSettings, updatedSettings, deletedBlocks, newBlocks, updatedBlocks } = handleCompareSettings(
        section.data.schema,
        response.data.schema,
      );
      const comparedLiquid = handleCompareString(section.data.liquid, response.data.liquid);

      const comparedScss = handleCompareString(section.data.scss ?? '', response.data.scss ?? '');

      const comparedJs = handleCompareString(section.data.js ?? '', response.data.js ?? '');

      const comparedJsHook = handleCompareString(section.data.jsHook ?? '', response.data.jsHook ?? '');

      return {
        comparedJs,
        comparedJsHook,
        comparedLiquid,
        comparedScss,
        deletedBlocks,
        deletedSettings,
        newBlocks,
        newSettings,
        updatedBlocks,
        updatedSettings,
        atom: response,
      };
    }
  };

  const handlePrepareData = async () => {
    if (modalControlSections) {
      setIsPrepearingData(true);
      setIsPrepareFailed(false);
      try {
        /** Xử lý sections */
        const { sectionCommandIds } = modalControlSections;
        const sections = await getSectionsOfPageAtom({ sectionCommandIds });

        /** Xử lý section version */
        const sectionSourceIds = sections.reduce<string[]>((res, section) => {
          if ('parentCommandId' in section && section.parentCommandId) {
            return res.concat(section.parentCommandId);
          }
          return res;
        }, []);
        const sectionsVersion = await getChangelogsOfSectionAtoms(sectionSourceIds);

        const nextSectionsState = await Promise.all(
          sections.map(async section_ => {
            const section = section_ as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client;
            const sectionVersion = sectionsVersion.find(sectionVersion => sectionVersion.atomCommandId === section.parentCommandId);
            const latestSectionInfo = await handleGetSectionLatestInfo({ section, version: sectionVersion?.version });
            return {
              section,
              version: sectionVersion?.version,
              latestSectionInfo,
              overridedSectionInfo: latestSectionInfo
                ? (updateSection(section, latestSectionInfo.atom, true) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client)
                : undefined,
            };
          }),
        );

        setSectionsState(nextSectionsState);

        /** Xử lý megamenu */
        const megamenuResponses = await Promise.all(
          sections
            .filter(section => section.megaMenuCommandIds && section.megaMenuCommandIds.length)
            .map(section => {
              return getMegamenusOfPageAtom({ megamenuCommandIds: section.megaMenuCommandIds ?? [] });
            }),
        );

        /** Xử lý megamenu version */
        const megamenuSectionSourceIds = Array.from(
          new Set(
            megamenuResponses.reduce<string[]>((res, megamenuResponse) => {
              if (megamenuResponse) {
                megamenuResponse.map(megamenuSection => {
                  if (megamenuSection && 'parentCommandId' in megamenuSection && megamenuSection.parentCommandId) {
                    res.push(megamenuSection.parentCommandId);
                  }
                });
              }
              return res;
            }, []),
          ),
        );
        const megamenuSectionsVersion = await getChangelogsOfMegamenuAtoms(megamenuSectionSourceIds);

        const nextMegamenusState = await Promise.all(
          flatten(megamenuResponses).map(async section_ => {
            const section = section_ as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client;
            const sectionVersion = megamenuSectionsVersion.find(sectionVersion => sectionVersion.atomCommandId === section.parentCommandId);
            const latestSectionInfo = await handleGetMegamenuLatestInfo({ section, version: sectionVersion?.version });
            return {
              section,
              version: sectionVersion?.version,
              latestSectionInfo,
              overridedSectionInfo: latestSectionInfo
                ? (updateSection(section, latestSectionInfo.atom, true) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client)
                : undefined,
            };
          }),
        );

        setMegamenusState(nextMegamenusState);
      } catch (error_) {
        const error = error_ as Error;
        setIsPrepareFailed(true);
        handleError(error);
      } finally {
        setIsPrepearingData(false);
      }
    }
  };

  const handleUpdateAll = async () => {
    setIsUpdaing(true);
    setIsUpdateFailed(false);
    try {
      /** Xử lý lưu mega menu */
      await Promise.all([
        ...megamenusState
          .filter(item => item.overridedSectionInfo)
          .map(item => {
            return updateMegamenuOfPageAtom({
              megamenu: item.overridedSectionInfo as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client,
            });
          }),
        ...sectionsState
          .filter(item => item.overridedSectionInfo)
          .map(item => {
            return updateSectionOfPageAtom({
              section: item.overridedSectionInfo as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client,
            });
          }),
      ]);
      notification.success({
        message: 'Update thành công',
        description: (
          <p>
            Các thành phần của page đã được upgrade version mới nhất. Tôi rất muốn hiển thị nút preview tại đây nhưng một vài lí do nghiệp vụ nên vui
            lòng thao tác tại dashboard. Xin chân thành cảm ơn
          </p>
        ),
      });

      setModalControlSections(undefined);
    } catch (error_) {
      const error = error_ as Error;
      setIsUpdateFailed(true);
      handleError(error);
      handlePrepareData();
    } finally {
      setIsUpdaing(false);
    }
  };

  const handleClose = () => {
    if (isUpdating) {
      notification.info({
        message: 'Đừng',
        description: 'Chức năng này được làm khá tạm bợ, rất có thể những thứ đáng tiếc sẽ xảy ra. Xin hãy đợi quá trình update kết thúc',
      });
    } else {
      setModalControlSections(undefined);
    }
  };

  useEffect(() => {
    if (modalControlSections) {
      handlePrepareData();
    } else {
      // Reset state
      setIsPrepearingData(false);
      setSectionsState([]);
      setMegamenusState([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalControlSections]);

  if (!modalControlSections) {
    return null;
  }

  const renderTable = () => {
    if (isPrepareFailed) {
      return <Button onClick={handlePrepareData}>Retry</Button>;
    }

    return (
      <Table
        rowKey={item => item.section.commandId ?? v4()}
        pagination={false}
        scroll={{ y: 600 }}
        loading={isPreparingData}
        dataSource={[...sectionsState, ...megamenusState]}
        columns={[
          {
            title: 'Tên section',
            render: (_, record) => {
              return (
                <View css={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                  <View>{record.section.label}</View>
                  {isUpdatable(record) && (
                    <Tooltip
                      portal
                      text={i18n.t('general.update', {
                        text: i18n.t(`general.version`),
                        textTransform: 'capitalize',
                      })}
                    >
                      <BoxCenter css={{ position: 'relative' }}>
                        <Hotspot css={{ position: 'absolute', top: '5px', right: '5px' }} />
                        <FontAwesome type="far" name="arrow-from-bottom" size={13} color="gray6" />
                      </BoxCenter>
                    </Tooltip>
                  )}
                </View>
              );
            },
          },
          {
            title: 'Version hiện tại',
            render: (_, record) => record.section.currentVersion,
          },
          {
            title: 'Version mới nhất',
            render: (_, record) => record.version?.version ?? record.section.currentVersion,
          },
        ]}
      />
    );
  };

  return (
    <MyModal
      isVisible
      size="large"
      isLoading={isUpdating}
      onOk={handleUpdateAll}
      onCancel={handleClose}
      headerText={`Sections & Megamenus của ${modalControlSections.label}`}
    >
      <View css={{ padding: '12px' }}>
        <View className="with-ant-table-scroll-shadows">{renderTable()}</View>
      </View>
    </MyModal>
  );
};
