import { EditorConfig } from '@ckeditor/ckeditor5-core/src/editor/editorconfig';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from '@duongto1998/custom-builder-ckeditor/build/ckeditor';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';
import { FC, useRef } from 'react';
import { useSelector } from 'react-redux';
import { authSelector, pageDataSelector } from 'store/selectors';
import { i18n } from 'translation';
import { Unusable_Metafields } from 'types/Metafields';
import { PLAN_STATIC_DATA, VEDA_FUNCTION_ENUM } from 'utils/constants/PlanEnum';
import { validatePlan } from 'utils/functions/validatePlan';
import { View } from 'wiloke-react-core';
import * as styles from './styles';

export interface TextEditor2Props {
  value?: string;
  richText?: boolean;
  isPortal?: boolean;
  disabled?: boolean;
  enabledMetaField?: boolean;
  onChange?: (html: string) => void;
  onClickMetaField?: (editor?: any) => void;
}

const setAttributes = (el: HTMLElement, attrs: Record<string, any>) => {
  for (const key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
};

const SELECTOR_TO_APPEND_META_FIELDS = 'button[data-cke-tooltip-text*="Bold"]';

export const TextEditor2: FC<TextEditor2Props> = ({ value = '', richText = true, disabled, enabledMetaField, onChange, onClickMetaField }) => {
  const page = useSelector(pageDataSelector);
  const { plan, role } = useSelector(authSelector);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const canUseMetaField =
    typeof enabledMetaField !== 'undefined'
      ? enabledMetaField
      : page
      ? page.type === 'article' || page.type === 'collection' || page.type === 'product'
      : false;
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();

  const getEditorConfig = (): EditorConfig => ({
    toolbar: {
      // Cần để 2 bold ( đọc CHEAT phía dưới )
      items: ['heading', '|', 'bold', 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
      shouldNotGroupWhenFull: false,
    },
    // @ts-ignore
    link: {
      decorators: {
        isExternal: {
          mode: 'manual',
          label: i18n.t('general.open_new_tab'),
          attributes: {
            target: '_blank',
          },
        },
      },
    },
    // fontColor: {
    //   colors: colors.map(item => ({
    //     color: item.light,
    //     label: getColorTitle(item.name),
    //   })),
    //   columns: 4,
    //   documentColors: 0,
    // },
    // @ts-ignore
    forcePasteAsPlainText: false,
  });

  const handleChange = (_: any, editor: any) => {
    const data = editor.getData();
    onChange?.(data);
  };

  if (!richText) {
    return (
      <View css={styles.container}>
        <View
          css={styles.textarea}
          tagName="textarea"
          value={value}
          onChange={event => {
            const el = event.target as HTMLTextAreaElement;
            onChange?.(el.value);
          }}
        />
      </View>
    );
  }

  return (
    <View ref={containerRef} className="CKEditor-container" css={styles.container}>
      <CKEditor
        editor={Editor}
        config={getEditorConfig()}
        disabled={disabled}
        data={value}
        onChange={handleChange}
        onReady={(editor: any) => {
          // Đợi ckeditor được khỏi tạo rồi append icon vào
          if (editor && containerRef.current) {
            editor.editing.view.change((writer: { setStyle: (arg0: string, arg1: string, arg2: any) => void }) => {
              writer.setStyle('max-height', '280px', editor.editing.view.document.getRoot());
            });

            const toolbarsEl = containerRef.current.querySelector(SELECTOR_TO_APPEND_META_FIELDS) as HTMLButtonElement;
            const metaFieldButton = document.createElement('button');
            metaFieldButton.className = 'ck ck-button ck-off customEditorButton';
            // toolbarsEl.style.paddingLeft = '0';
            setAttributes(metaFieldButton, {
              tabindex: -1,
              'aria-pressed': false,
              'data-cke-tooltip-position': 's',
              'data-cke-tooltip-text': canUseMetaField
                ? `Metafields (Not support types: ${Object.values(Unusable_Metafields).join(', ')})`
                : i18n.t('general.meta_field_use_in'),
            });
            if (!canUseMetaField) {
              metaFieldButton.style.opacity = '0.4';
            }
            const metaFieldIcon = document.createElement('i');
            metaFieldIcon.className = 'far fa-database ck-fontawesome';
            metaFieldButton.addEventListener('click', () => {
              if (canUseMetaField) {
                if (validatePlan({ userPlan: plan.planHandle, itemPlan: VEDA_FUNCTION_ENUM.meta_fields, role }) === 'pass') {
                  onClickMetaField?.(editor);
                } else {
                  setPlanRequesting({
                    handle: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.meta_fields].handle,
                    type: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.meta_fields].type,
                    pricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.meta_fields].pricePerMonth,
                    pricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.meta_fields].pricePerYear,
                    title: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.meta_fields].title,
                    originalPricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.meta_fields].pricePerMonth,
                    originalPricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.meta_fields].pricePerYear,
                  });
                  setValidateCouponVisible(true);
                }
              }
            });
            metaFieldButton.appendChild(metaFieldIcon);

            // CHEAT: Sinh ra 2 bold và ẩn 1 cái đi để giúp việc những thứ đứng sau được
            // in vào trong dropdown và để chỗ cho metafield
            toolbarsEl.style.display = 'none';

            toolbarsEl.before(metaFieldButton);
          }
        }}
      />
    </View>
  );
};
