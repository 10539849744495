import { AdvancedBadge, BusinessBadge, UltimateBadge } from 'components/PlanBadge';
import { FC } from 'react';
import { PLAN_HANDLE } from 'utils/constants/PlanEnum';
import { Image, Space, Text, View } from 'wiloke-react-core';
import * as styles from './styles';

export interface BodyProps {
  logo: string;
  title: string;
  text: string;
  type: string;
  typeColor?: string;
  planHandle?: PLAN_HANDLE;
}

const Body: FC<BodyProps> = ({ logo, title, text, type, typeColor = 'var(--ui-color-primary)', planHandle }) => {
  return (
    <View css={styles.body}>
      <View css={styles.image}>
        {logo !== '' ? (
          <Image src={logo} aspectRatioInPercent={100} radius={4} />
        ) : (
          <View radius={4} backgroundColor="gray3" width={44} height={44} />
        )}
      </View>
      <Space size={8} />
      <View css={styles.head}>
        <View css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <Text css={[styles.type, { color: typeColor }]} size={10}>
            {type}
          </Text>
          {planHandle === 'business' && <BusinessBadge css={{ marginTop: '-15px', marginRight: '-5px' }} />}
          {planHandle === 'advanced' && <AdvancedBadge css={{ marginTop: '-15px', marginRight: '-5px' }} />}
          {planHandle === 'ultimate' && <UltimateBadge css={{ marginTop: '-15px', marginRight: '-5px' }} />}
        </View>

        <Space size={3} />
        <Text tagName="h5">{title}</Text>
        <Space size={3} />
      </View>

      {text && (
        <Text css={styles.text} numberOfLines={2}>
          {text}
        </Text>
      )}
    </View>
  );
};

export default Body;
