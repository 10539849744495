import { AxiosError } from 'axios';
import configureApp from 'configureApp';
import fetchAPI from 'utils/functions/fetchAPI';

interface ResponseError {
  message: 'The page data has been saved to Veda. The app is not enabled! Please click the following link to enable it.';
  info: {
    themeEditor: 'https://admin.shopify.com/store/nguyendttn/themes/132797038781/editor';
  };
}

interface ActivedState {
  variant: 'active_theme_app_extension';
  isActived: true;
}
interface UnactiveState {
  variant: 'unactive_theme_app_extension';
  isActived: false;
  themeEditor: string;
}

interface Other {
  variant: 'theme_removed';
}

interface GetThemeAppStatus {
  currentShopifyThemeIdActived: string;
}

export const getThemeAppStatus = async ({ currentShopifyThemeIdActived }: GetThemeAppStatus): Promise<Other | (ActivedState | UnactiveState)> => {
  try {
    await fetchAPI.request({
      url: `${configureApp.endpoint['shopify-connections']}/sync/shopify/me/theme-app-status`,
      params: {
        shopifyThemeId: currentShopifyThemeIdActived,
      },
    });
    return {
      variant: 'active_theme_app_extension',
      isActived: true,
    };
  } catch (error) {
    const error_ = error as AxiosError<ResponseError>;
    if (error_.isAxiosError && error_.response?.status === 404) {
      return {
        variant: 'theme_removed',
      };
    } else if (error_.isAxiosError) {
      return {
        isActived: false,
        variant: 'unactive_theme_app_extension',
        themeEditor: error_.response?.data.info.themeEditor ?? '',
      };
    }
    if (error instanceof Error) {
      throw error;
    }
    throw new Error('Something went wrong!!!');
  }
};
