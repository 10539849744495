import { AxiosResponse } from 'axios';
import { ThemeSettings } from 'types/Result';
import fetchAPI from 'utils/functions/fetchAPI';
import { clientBaseUrl } from '../const';

interface Params {
  themeSettings: Partial<ThemeSettings>;
}

interface ResponseSuccess {
  message: string;
}

export const updateThemeSettingsDefault = async ({ themeSettings }: Params) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${clientBaseUrl}/default-theme-settings`,
    method: 'PUT',
    data: {
      themeSettings,
    },
  });
  return response.data;
};
