import JoyRide, { Step, Props } from 'react-joyride';
import { FC, useRef } from 'react';
import { useTheme, View } from 'wiloke-react-core';
import { i18n } from 'translation';
import * as css from './styles';
export interface TourGuideStep extends Step {}

export interface TourGuideProps extends Props {}

export const TourGuide: FC<TourGuideProps> = ({
  continuous = true,
  showSkipButton = true,
  showProgress = true,
  disableCloseOnEsc = true,
  disableOverlayClose = true,
  disableOverlay = true,
  ...props
}) => {
  const { colors, fonts } = useTheme();
  const ref = useRef<JoyRide | null>(null);

  const styles: Props['styles'] = {
    beacon: {
      width: '25px',
      height: '25px',
    },
    beaconOuter: {
      borderColor: colors.secondary,
      backgroundColor: `rgba(${colors.rgbSecondary}, 0.2)`,
    },
    beaconInner: {
      backgroundColor: colors.secondary,
    },
    tooltipContainer: {
      borderRadius: '10px',
      fontFamily: fonts.secondary,
    },
    buttonNext: {
      backgroundColor: colors.transparent,
      borderRadius: '4px',
      fontFamily: fonts.secondary,
      fontSize: '14px',
      border: `1px solid ${colors.light}`,
      padding: '8px 12px',
      color: colors.light,
    },
    buttonSkip: {
      borderRadius: '4px',
      fontFamily: fonts.secondary,
      fontSize: '14px',
      padding: '8px 0',
      color: colors.light,
      textDecoration: 'underline',
    },
    buttonBack: {
      fontFamily: fonts.secondary,
      fontSize: '14px',
      color: colors.light,
      backgroundColor: colors.transparent,
      border: `1px solid ${colors.light}`,
      borderRadius: '4px',
      padding: '8px 12px',
    },
    tooltip: {
      width: '350px',
      padding: '15px',
      boxShadow: 'unset',
      backgroundColor: colors.secondary,
    },
    tooltipContent: {
      padding: '10px 0px 0px 0px',
      color: colors.light,
      fontSize: '14px',
      textAlign: 'left',
    },
    tooltipTitle: {
      textAlign: 'left',
      fontSize: '18px',
      color: colors.light,
    },
    buttonClose: {
      color: colors.light,
    },
    options: {
      arrowColor: colors.secondary,
    },
    tooltipFooter: {
      marginTop: '25px',
    },
  };

  return (
    <View css={css.container}>
      <JoyRide
        {...props}
        ref={ref}
        continuous={continuous}
        showSkipButton={showSkipButton}
        showProgress={showProgress}
        disableCloseOnEsc={disableCloseOnEsc}
        disableOverlayClose={disableOverlayClose}
        disableScrollParentFix={true}
        styles={styles}
        disableOverlay={disableOverlay}
        locale={{
          back: i18n.t('general.back'),
          skip: i18n.t('general.skip'),
          next: i18n.t('general.next'),
          last: i18n.t('general.done'),
        }}
        floaterProps={{
          styles: {
            arrow: {
              padding: '8px',
              backgroundColor: colors.secondary,
              color: colors.secondary,
            },
          },
        }}
      />
    </View>
  );
};
