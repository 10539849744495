import { Button, Dropdown } from '@wiloke/ui';
import { FC, useState } from 'react';
import { i18n } from 'translation';
import { PageSectionType } from 'types/Sections';
import { PAGE_BUILD_HEADER_FOOTER } from 'utils/constants/constants';
import getPageInfo from 'utils/functions/getInfo';
import { View } from 'wiloke-react-core';

export interface ButtonAddThemeSectionProps {
  onClick: (type: Extract<PageSectionType, 'header' | 'default' | 'footer'>) => void;
}

// TODO: i18n
export const ButtonAddThemeSection: FC<ButtonAddThemeSectionProps> = ({ onClick }) => {
  const [buttonRef, setButtonRef] = useState<HTMLElement | null>(null);
  const pageId = getPageInfo('id');

  return (
    <View css={{ width: '100%' }}>
      <Dropdown
        data={[
          ...(pageId === PAGE_BUILD_HEADER_FOOTER
            ? [
                {
                  label: 'Add To Header',
                  value: 'header',
                },
              ]
            : []),

          ...(pageId !== PAGE_BUILD_HEADER_FOOTER
            ? [
                {
                  label: 'Add To Main',
                  value: 'default',
                },
              ]
            : []),
          ...(pageId === PAGE_BUILD_HEADER_FOOTER
            ? [
                {
                  label: 'Add To Footer',
                  value: 'footer',
                },
              ]
            : []),
        ]}
        overlayCss={buttonRef ? { width: `${buttonRef.offsetWidth}px` } : {}}
        onClick={value => {
          onClick?.(value as Extract<PageSectionType, 'header' | 'default' | 'footer'>);
        }}
      >
        <Button
          ref={setButtonRef}
          type="button"
          backgroundColor="gray3"
          color="gray9"
          size="small"
          block
          radius={6}
          fontFamily="secondary"
          css={{ height: '46px' }}
        >
          {i18n.t('general.add', { text: i18n.t('general.section'), textTransform: 'capitalize' })}
        </Button>
      </Dropdown>
    </View>
  );
};
