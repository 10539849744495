import { strToRegexpPattern } from '@wiloke/functions';
import { handleWaitForSocketOfSyncShopifyFulfill } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { all, call, retry, SagaReturnType } from 'redux-saga/effects';
import { handlePreviewInBuilderPage, shopifyConnectionService } from 'services/ShopifyConnection';
import { SyncAddonsEnablePositionResult } from '../syncAddons';
import { getAddonHtml } from 'utils/functions/getAddonHtml';
import { Prettier } from 'utils/functions/Prettier';
import { handleGetScopeOfAddon } from 'utils/LiquidSyntaxToTwig';

export interface SyncHeaderFooter {
  headerParams: Exclude<ReturnType<typeof handlePreviewInBuilderPage>['headerParams'], undefined>;
  footerParams: Exclude<ReturnType<typeof handlePreviewInBuilderPage>['footerParams'], undefined>;
  signalToReplaceAddonInLiquidCode: SyncAddonsEnablePositionResult['signalToReplaceAddonInLiquidCode'];
}

export interface SyncHeaderFooterResult {
  statusSyncFooter: SyncFulfillStatus;
  statusSyncHeader: SyncFulfillStatus;
  isNeedIgnoreReportError: boolean;
}

const replaceAddonHtml = (liquid: string, addonFilesAfterSync: Array<{ addonId: string; fileNameWithoutExtension: string }>) => {
  let _liquid = liquid;
  addonFilesAfterSync.forEach(addonFile => {
    const { addonId, fileNameWithoutExtension } = addonFile;
    const regexp = new RegExp(`${strToRegexpPattern(getAddonHtml(addonId))}`, 'g');
    const { variables } = handleGetScopeOfAddon({ liquidCompiled: liquid.substring(0, liquid.search(regexp)) });
    _liquid = _liquid.replace(
      regexp,
      `
          {% capture addon_${addonId}_content %}
            {% render '${fileNameWithoutExtension}' %}
          {% endcapture %}

          {% unless addon_${addonId}_content contains "Could not find asset" %}
           {% render '${fileNameWithoutExtension}', ${variables.map(variable => `${variable}:${variable}`).join(',')} %}
          {% endunless %}
        `,
    );
  });
  return _liquid;
};

export function* syncHeaderFooter({ headerParams, footerParams, signalToReplaceAddonInLiquidCode }: SyncHeaderFooter) {
  const addonFilesAfterSync = signalToReplaceAddonInLiquidCode.map(item => ({
    addonId: item.id,
    fileNameWithoutExtension: item.fileNameWithoutExtension,
  }));
  yield retry(3, 1000, shopifyConnectionService.writeHeaderToShopify, {
    ...headerParams,
    assets: {
      ...headerParams.assets,
      files: headerParams.assets?.files?.map(file => {
        if (file && file.type === 'section' && file.content) {
          return {
            ...file,
            content: Prettier.liquid(replaceAddonHtml(file.content, addonFilesAfterSync)),
          };
        }
        return file;
      }),
    },
    isPreview: true,
  });
  yield retry(3, 1000, shopifyConnectionService.writeFooterToShopify, {
    ...footerParams,
    assets: {
      ...footerParams.assets,
      files: footerParams.assets?.files?.map(file => {
        if (file && file.type === 'section' && file.content) {
          return {
            ...file,
            content: Prettier.liquid(replaceAddonHtml(file.content, addonFilesAfterSync)),
          };
        }
        return file;
      }),
    },
    isPreview: true,
  });
  const [socketSyncHeader, socketSyncFooter]: [
    SagaReturnType<typeof handleWaitForSocketOfSyncShopifyFulfill>,
    SagaReturnType<typeof handleWaitForSocketOfSyncShopifyFulfill>,
  ] = yield all([
    call(handleWaitForSocketOfSyncShopifyFulfill, 'Ghi file khi save ở builder page / Ghi header'),
    call(handleWaitForSocketOfSyncShopifyFulfill, 'Ghi file khi save ở builder page / Ghi footer'),
  ]);
  return {
    statusSyncHeader: socketSyncHeader.statusSync,
    statusSyncFooter: socketSyncFooter.statusSync,
    isNeedIgnoreReportError: socketSyncHeader.isNeedIgnoreReportError || socketSyncFooter.isNeedIgnoreReportError,
  } as SyncHeaderFooterResult;
}
