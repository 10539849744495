import { CopyResult } from 'utils/functions/copyStyle/types';
import { createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';

type Actions =
  | {
      type: 'setDraftCopyStyle';
      payload: CopyResult | undefined;
    }
  | {
      type: 'setDataCopyStyle';
    }
  | {
      type: 'setCopyStatus';
      payload: boolean;
    }
  | {
      type: 'clearDraft';
    }
  | {
      type: 'setLoadingOnPaste';
      payload: 'idle' | 'loading' | 'done';
    };

export type DesignFieldOutput = { id: string; css: string } | string;

export interface ComponentStyleOutput {
  type: string;
  children: DesignFieldOutput;
}

interface State {
  data?: CopyResult;
  draftData?: CopyResult;
  fieldType: string;
  copyStatus: boolean;
  loadingOnPaste: 'idle' | 'loading' | 'done';
}

const initialState: State = {
  data: undefined,
  draftData: undefined,
  fieldType: '',
  copyStatus: false,
  loadingOnPaste: 'idle',
};

export const sliceCopyStyle = createSlice<State, Actions>({
  initialState,
  name: '@Builder',
  reducers: [
    handleAction('setDraftCopyStyle', ({ state, action }) => {
      state.draftData = {
        ...(state.draftData ?? { copiedData: [], type: 'unknown' }),
        type: action.payload?.type ?? 'unknown',
      };

      if (!!action.payload?.copiedData?.length) {
        state.copyStatus = true;
      } else {
        state.copyStatus = false;
      }
    }),
    handleAction('setDataCopyStyle', ({ state }) => {
      state.data = state.draftData;
      if (state.draftData) {
        state.fieldType = state.draftData.type;
      }
    }),
    handleAction('setCopyStatus', ({ state, action }) => {
      state.copyStatus = action.payload;
    }),
    handleAction('clearDraft', ({ state }) => {
      state.draftData = initialState.draftData;
      state.copyStatus = initialState.copyStatus;
      state.fieldType = initialState.fieldType;
    }),
    handleAction('setLoadingOnPaste', ({ state, action }) => {
      state.loadingOnPaste = action.payload;
    }),
  ],
});

export const copyStyleSelector = (state: AppState) => state.builderPage.copyStyle;

export const { setDraftCopyStyle, setDataCopyStyle, setCopyStatus, clearDraft, setLoadingOnPaste } = sliceCopyStyle.actions;

export const useSetDraftCopyStyle = createDispatchAction(setDraftCopyStyle);
export const useSetDataCopyStyle = createDispatchAction(setDataCopyStyle);
export const useSetCopyStatus = createDispatchAction(setCopyStatus);
export const useClearDraftData = createDispatchAction(clearDraft);
export const useSetLoadingOnPaste = createDispatchAction(setLoadingOnPaste);
