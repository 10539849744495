import { Page } from 'types/Page';
import pageAdminPreview from '../@assets/page-admin.png';
import themeAdminPreview from '../@assets/theme-admin.png';
import pageDraftPreview from '../@assets/page-draft.png';
import themeDraftPreview from '../@assets/theme-draft.png';
import pageClientPreview from '../@assets/page-client.png';

export const VedaPageEntityTypeMappingToLabel: Record<Page['entityType'], string> = {
  Atom: 'Page thuộc khu vực của Veda admin',
  Client: 'Page thuộc khu vực của Veda client',
  Draft: 'Page thuộc khu vực của Veda dev',
  ThemeAtom: 'Theme thuộc khu vực của Veda admin',
  ThemeDraft: 'Theme thuộc khu vực của Veda dev',
};

export const VedaPageEntityTypeMappingToSrcImg: Record<Page['entityType'], string> = {
  Atom: pageAdminPreview,
  Client: pageClientPreview,
  Draft: pageDraftPreview,
  ThemeAtom: themeAdminPreview,
  ThemeDraft: themeDraftPreview,
};
