import withDebounce from 'hocs/withDebounce';
import { useSelector } from 'react-redux';
import { useUpdateSettingsValue } from 'store/actions/actionPages';
import { authSelector, sectionIdActiveSelector } from 'store/selectors';
import { ColorSchemes } from 'types/Result';
import { pmParent } from 'utils/functions/postMessage';
import { FieldContainerProps } from '../types';
import { ColorSchemeFieldUI } from 'components/ColorSchemeFieldUI';
import { useSetSettingsVisible } from 'containers/BuilderPage/store/settingsVisible/slice';
import { validatePlan } from 'utils/functions/validatePlan';
import { PLAN_STATIC_DATA, VEDA_FUNCTION_ENUM } from 'utils/constants/PlanEnum';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';

export interface ColorSchemeFieldProps<T> extends FieldContainerProps<T> {
  colorSchemes: ColorSchemes;
}

const ColorSchemeFieldUIDebounce = withDebounce(ColorSchemeFieldUI, 'value', 'onChange', 200);

const ColorSchemeField = <T extends string | null>({
  value,
  settingId,
  childId,
  grandChildId,
  colorSchemes,
  forceRenderSection,
}: ColorSchemeFieldProps<T>) => {
  const updateSettingsValue = useUpdateSettingsValue();
  const sectionIdActive = useSelector(sectionIdActiveSelector);
  const setSettingsVisible = useSetSettingsVisible();
  const { plan, role } = useSelector(authSelector);
  const isDisable = validatePlan({ userPlan: plan.planHandle, itemPlan: VEDA_FUNCTION_ENUM.color_scheme, role }) === 'invalid';
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();

  const handleChange = (value: string) => {
    updateSettingsValue({
      value,
      settingId,
      childId,
      grandChildId,
    });
    if (forceRenderSection) {
      pmParent.emit('@rerender', { sectionId: sectionIdActive, action: 'ColorSchemeField.tsx' });
    }
  };

  return (
    <ColorSchemeFieldUIDebounce
      value={value}
      data={colorSchemes}
      disabled={isDisable}
      onChange={handleChange}
      onEditColorScheme={() => {
        setSettingsVisible({ visible: true, keys: ['theme', 'colorSchemes'] });
      }}
      onDisable={() => {
        setPlanRequesting({
          handle: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].handle,
          type: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].type,
          pricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].pricePerMonth,
          pricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].pricePerYear,
          title: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].title,
          originalPricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].pricePerMonth,
          originalPricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].pricePerYear,
        });
        setValidateCouponVisible(true);
      }}
    />
  );
};

export default ColorSchemeField;
