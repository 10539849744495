import { i18n } from 'translation';
import { liquidFilterParamsToTwigFilterParams } from 'utils/LiquidSyntaxToTwig/utils/liquidFilterParamsToTwigFilterParams';
import { LiquidSyntaxToTwigError } from '../Error';

window.Twig.extendFilter('media_tag', value => {
  try {
    // fix tạm lỗi media_tag
    return value;
  } catch {
    throw new LiquidSyntaxToTwigError(i18n.t('twig_error.filters.media_tag'));
  }
});

/**
 * TODO: Chưa làm được cái này nhưng nhìn có lẽ là khả thi để làm được
 * @link https://shopify.dev/api/liquid/filters/media-filters#media_tag
 */
export const media_tag = (liquid: string) =>
  liquidFilterParamsToTwigFilterParams({ liquid, liquidFilterName: 'media_tag', twigFilterName: 'media_tag' });
