import { put, retry, takeEvery } from 'redux-saga/effects';
import { getActionType } from 'wiloke-react-core/utils';
import { deleteRecord } from '../../services/Firebase';
import { deleteRecord as actionDeleteRecord } from '../actions';
import { message, notification } from 'antd';
import { copyToClipboard } from 'utils/copyToClipboard';
import { i18n } from 'translation';

function* handleDeleteRecord({ payload }: ReturnType<typeof actionDeleteRecord.request>) {
  const { record } = payload;
  try {
    yield retry(3, 1000, deleteRecord, record.generalInfomation.firebaseTrackingData.atomCommandId);
    yield put(actionDeleteRecord.success({ record }));
    notification.success({
      message: `Bỏ qua version hiện tại của section "${record.generalInfomation.sectionAtom.label}" thành công`,
    });
  } catch (error) {
    yield put(actionDeleteRecord.failure({ record }));
    if (error instanceof Error) {
      const messageErr = `${error.message}`;
      notification.error({
        message: i18n.t('general.system_error'),
        description: (
          <div>
            <p>Báo dev kèm lời nhắn: {messageErr}"</p>
            <button
              style={{
                borderRadius: '4px',
                backgroundColor: '#2C36DC',
                color: '#fff',
                fontWeight: 500,
                padding: '10px 16px',
                cursor: 'pointer',
                outline: 'none',
                border: 'none',
              }}
              onClick={() => {
                copyToClipboard(messageErr);
                message.success('Đã copy');
              }}
            >
              Copy lời nhắn
            </button>
          </div>
        ),
      });
    }
  }
}

export function* watchDeleteRecord() {
  yield takeEvery(getActionType(actionDeleteRecord.request), handleDeleteRecord);
}
