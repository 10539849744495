export const pricing = {
  annual: 'Hàng năm',
  monthly: 'Hàng tháng',
  save_month: 'Tiết kiệm %% year %% tháng',
  subtotal: 'Tổng tiền',
  coupon_cannot_blank: 'Phiếu giảm giá không được để trống',
  billing_cycle: 'Chu kỳ thanh toán',
  upgrade: 'Nâng cấp',
  checkout: 'Thanh toán',
  enter_promo: 'Nhập mã khuyến mãi',
  apply: 'Nhập',
  already_used_code: 'Mã này đã được sử dụng',
} as const;
