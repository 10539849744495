import { AxiosError } from 'axios';
import { setVisibleChangePlanName } from 'containers/ModalUpgradeOrChangePlanName/sliceUpgradePlan';
import { call, put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { authService } from 'services/AuthService';
import { handleVerifyStorefrontPassword } from 'store/global/auth/sagas/watchVerifyStorefrontPassword';
import { handleGetGlobalData } from 'store/global/globalMount/saga';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { verifyUser, VerifyUserRequest } from '../slice';

function* handleVerify(_: VerifyUserRequest) {
  try {
    const response: SagaReturnType<typeof authService.getUserInfo> = yield retry(3, 500, authService.getUserInfo);
    const dataSuccess = response.info;

    if (response.info.isBanned) {
      yield put(setVisibleChangePlanName(true));
    }

    yield put(verifyUser.success(dataSuccess));
    yield call(handleGetGlobalData, { type: '@Root/globalMount/request', payload: undefined });
    if (dataSuccess.storefrontPassword && dataSuccess.storefrontPassword !== '') {
      yield call(handleVerifyStorefrontPassword, {
        type: '@Global/verifyUserStorefrontPassword/request',
        payload: { password: dataSuccess.storefrontPassword, id: response.info.id },
      });
    }
  } catch (_error) {
    yield put(verifyUser.failure(undefined));
    notifyAxiosHandler.handleError(_error as AxiosError<ErrorData> | Error);
  }
}

export function* watchVerifyUser() {
  yield takeLatest(getActionType(verifyUser.request), handleVerify);
}
