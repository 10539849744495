import { MyModal } from '@wiloke/ui';
import { notification } from 'antd';
import ChooseImage from 'components/ChooseImage';
import Field from 'components/Field';
import TextInput from 'components/TextInput';
import ThemeSettingsWithSidebar from 'containers/BuilderPage/components/Settings/ThemeSettingsWithSidebar';
import { useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { globalThemeJsSelector } from 'store/global/globalThemeJs/slice';
import { globalThemeScssSelector } from 'store/global/globalThemeScss/slice';
import { themeVendorsSelector } from 'store/global/themeVendors/slice';
import { themeSettingsSelector } from 'store/selectors';
import { i18n } from 'translation';
import { PageType } from 'types/Page';
import { GridSmart, View } from 'wiloke-react-core';
import { useCreateThemeAtom } from '../../store/actions/actionThemesAtom';
import { themesAtomSelector, useSetModalCreateThemeAtom } from '../../store/reducers/sliceThemesAtom';
import { MAX_LENGTH_OF_THEME_NAME, PAGE_TYPES_WITH_EXPECT_ORDER } from '../@consts';
import { INITIAL_STATE } from './@consts';
import { State } from './@types';
import { SelectPagesTemplate } from './components/SelectPagesTemplate';

export const ModalCreateThemeAtom = () => {
  const [state, setState] = useState<State>(INITIAL_STATE);

  const { modalCreateThemeAtom, createStatus } = useSelector(themesAtomSelector);
  const themeSettings = useSelector(themeSettingsSelector);
  const globalJs = useSelector(globalThemeJsSelector);
  const globalScss = useSelector(globalThemeScssSelector);
  const vendors = useSelector(themeVendorsSelector);
  const setModalCreateThemeAtom = useSetModalCreateThemeAtom();
  const createThemeAtom = useCreateThemeAtom();

  const { connect, disconnect, statusSocketConnection } = useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService(
    'clone_page_atom_sevice_to_theme_atom_service',
  );

  const handleCreateTheme = () => {
    connect({
      onError: () => {
        notification.error({
          message: i18n.t('clone_page_atom_service_to_theme_atom_service.init_clone_error'),
        });
      },
      onSuccess: () => {
        const ids = Object.keys(state.pageIds).reduce<string[]>((result, pageType) => {
          const pageType_ = pageType as PageType;
          return result.concat(...state.pageIds[pageType_]);
        }, []);
        createThemeAtom.request({
          featuredImage: state.featuredImage,
          screenshot1: state.screenshot1,
          label: state.name,
          pageCommandIds: ids,
          themeSettings: {
            ...themeSettings,
            colorSchemes: themeSettings.colorSchemes.colorSchemes,
            globalTranslations: {
              languageActive: themeSettings.globalTranslations.languageActive,
              translation: themeSettings.globalTranslations.translation,
            },
          },
          globalJs,
          globalScss,
          vendors,
          onFulfill: () => disconnect({}),
        });
      },
    });
  };

  useEffect(() => {
    setState(INITIAL_STATE);
  }, [modalCreateThemeAtom]);

  if (!modalCreateThemeAtom) {
    return null;
  }

  return (
    <MyModal
      isVisible
      size="large"
      onCancel={() => setModalCreateThemeAtom(false)}
      headerText={i18n.t('ModalInteractiveTheme.createTheme')}
      onOk={handleCreateTheme}
      isLoading={createStatus === 'loading' || statusSocketConnection === 'loading'}
    >
      <View row>
        <View columns={[6, 6, 6]}>
          <Field label={i18n.t('ModalInteractiveTheme.name')}>
            <TextInput
              block
              value={state.name}
              onValueChange={val => {
                setState(prev => ({ ...prev, name: val }));
              }}
              maxLength={MAX_LENGTH_OF_THEME_NAME}
            />
          </Field>

          <GridSmart columnWidth={200} columnCount={2}>
            <Field label={i18n.t('ModalInteractiveTheme.featured_image.title')} note={i18n.t('ModalInteractiveTheme.featured_image.description')}>
              <ChooseImage
                mode="popup"
                value={{ src: state.featuredImage, width: 0, height: 0 }}
                onChange={({ src }) => {
                  setState(prev => ({ ...prev, featuredImage: src }));
                }}
              />
            </Field>
            <Field
              label={i18n.t('ModalInteractiveTheme.screenshot_image_1.title')}
              note={i18n.t('ModalInteractiveTheme.screenshot_image_1.description')}
            >
              <ChooseImage
                mode="popup"
                value={{ src: state.screenshot1, width: 0, height: 0 }}
                onChange={({ src }) => {
                  setState(prev => ({ ...prev, screenshot1: src }));
                }}
              />
            </Field>
          </GridSmart>
        </View>
        <View columns={[6, 6, 6]}>
          <GridSmart columnWidth={200}>
            {PAGE_TYPES_WITH_EXPECT_ORDER.map(pageType => (
              <SelectPagesTemplate
                isLoading={false}
                key={pageType}
                value={state.pageIds[pageType]}
                pageType={pageType}
                onChange={value => {
                  setState(state => ({
                    ...state,
                    pageIds: {
                      ...state.pageIds,
                      [pageType]: value,
                    },
                  }));
                }}
              />
            ))}
          </GridSmart>
        </View>
      </View>
      <ThemeSettingsWithSidebar />
    </MyModal>
  );
};
