import { v4 } from 'uuid';
import { defaultLanguage, languages } from '../../../../@consts';
import { Locales } from '../../../../@types/Veda/ShopifyLocales';
import { ColorMode } from '../@types/ColorMode';

const localKeyGenByVedaFieldLabel = 'FieldGenByVedaFieldLabel';

const createLocaleKey = () => `_${localKeyGenByVedaFieldLabel}_${v4()}_`;

interface RTVedaColorToShopifyFieldLabel {
  newLabel: string;
  locales: Locales;
}
export const getHeaderLabel = (variant: ColorMode): RTVedaColorToShopifyFieldLabel => {
  const localeKey = createLocaleKey();
  const default_translations = { [localeKey]: '' };
  // const newLabel: RTVedaColorToShopifyFieldLabel['newLabel'] = `t:${localeKey}`;
  const locales: RTVedaColorToShopifyFieldLabel['locales'] = languages.reduce<RTVedaColorToShopifyFieldLabel['locales']>(
    (res, language) => {
      return {
        ...res,
        [language]: {
          [localeKey]: variant === 'dark' ? 'Dark colors' : 'Light colors',
        },
      };
    },
    { en: default_translations, vi: default_translations, fr: default_translations },
  );

  return {
    newLabel: locales[defaultLanguage][localeKey],
    locales,
  };
  // return {
  //   newLabel,
  //   locales,
  // };
};
