import {
  SectionSettings,
  Schema,
  SectionSetting,
  SchemaSettingField,
  SettingBlock,
  SettingArray,
  SettingArrayValue,
  SettingBlockObject,
} from 'types/Schema';
import { AdminSection, PageSection, SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client } from 'types/Sections';
import { v4 } from 'uuid';

function getSettingsByPath(array: SectionSettings | SchemaSettingField[], pathParam: string, name = '') {
  let result: SectionSetting | SettingBlockObject | SchemaSettingField[] | undefined;
  array.forEach(item => {
    const path = `${name ? `${name}.` : ''}${item.name}`;
    if (item.type === 'object' && result === null) {
      result = getSettingsByPath(item.children, pathParam, path);
    }
    if (item.type === 'array') {
      item.children.forEach((child, index) => {
        if (result === null) {
          const name_ = `${path}[${index}]`;
          if (name_ === pathParam) {
            result = child.children;
          } else {
            result = getSettingsByPath(child.children, pathParam, name_);
          }
        }
      });
    }
    if (path === pathParam) {
      result = item;
    }
  });
  return result;
}

function getSchemaBlocksByPath(array: SettingBlock[] | SchemaSettingField[], pathParam: string, name = '') {
  let result: SectionSetting | SchemaSettingField[] | undefined;
  array.forEach((item, index) => {
    const path = `${name ? `${name}.` : ''}${item.name}`;
    if (item.type === 'object' && result === null) {
      result = getSettingsByPath(item.children, pathParam, path);
    }
    if (item.type === 'array') {
      if (path === pathParam) {
        result = item.children;
      } else {
        result = getSchemaBlocksByPath(item.children, pathParam, `${path}[${index}]`);
      }
    }
  });
  return result;
}

function arrToObj<T extends SchemaSettingField>(arr: T[]): T {
  return arr.reduce(
    (obj, item) => ({
      ...obj,
      [item.name]: item,
    }),
    {} as T,
  );
}

/**
 * @description: Nếu merge của 2 settings theo cách này thì settings cũ không dùng sẽ bị thừa và dẫn đến thứ tự settings ở bên sidebar bị lộn xộn
 * */
function _mergeSettings<T extends SchemaSettingField>(arr1: T[], arr2: T[]): T[] {
  const obj1 = arrToObj(arr1);
  const obj2 = arrToObj(arr2);
  return Object.values({ ...obj2, ...obj1 });
}

/**
 * @description Merge settings cũ với settings mới với điền kiện
 *
 * +> Setting cũ không dùng ở setting sẽ bị loại bỏ
 *
 * +> Nếu setting cũ khác type setting mới thì sẽ thay children ở setting mới vào (không thay thì sẽ ko hoạt động được)
 */
function _compareThenMergeSettings<T extends SchemaSettingField>(oldData: T[], newData: T[]): T[] {
  return newData.map(item => {
    const oldItem = oldData.filter(Boolean).find(oldObj => oldObj.name === item.name || oldObj.id === item.id);
    if (oldItem) {
      if (item.type === 'textReadOnly' || item.type === 'divider') {
        return item;
      }
      return {
        ...oldItem,
        type: item.type,
        name: item.name,
        children: item.type !== oldItem.type ? item.children : oldItem.children,
      };
    }

    return item;
  });
}

function compareSettings(vedaSettings: SectionSettings, customerSetting: SectionSettings, vedaSchema: Schema, name = ''): SectionSettings {
  return vedaSettings.map(item => {
    const path = `${name ? `${name}.` : ''}${item.name}`;

    if (item.type === 'array') {
      return {
        ...item,
        children: (getSettingsByPath(customerSetting, path) as SettingArray)?.children?.map?.((child, index) => {
          const vedaChild = getSettingsByPath(vedaSettings, `${path}[${index}]`);
          const schemaChild = getSchemaBlocksByPath(vedaSchema.blocks, path);
          const compareId = (getSettingsByPath(vedaSettings, path) as SettingArrayValue)?.children.some(item => item.id === child.id);
          if (vedaChild && compareId) {
            return {
              ...child,
              children: _compareThenMergeSettings(child.children, vedaChild as SchemaSettingField[]),
            };
          }
          if (schemaChild) {
            return {
              ...child,
              children: _compareThenMergeSettings(child.children, schemaChild as SchemaSettingField[]),
            };
          }
          return child;
        }),
      };
    }

    if (item.type === 'object') {
      const customerChildren = (getSettingsByPath(customerSetting, path) as SectionSetting)?.children;
      if (customerChildren) {
        const merged = _compareThenMergeSettings(customerChildren, item.children);
        return {
          ...item,
          children: merged,
        };
      }
      return item;
    }

    const newChildren = (getSettingsByPath(customerSetting, path) as SectionSetting)?.children;

    return {
      ...item,
      children: newChildren ?? item.children,
    };
  });
}

function combineAddonElements(liquidTemplate: string): string {
  const addonMap: { [dataId: string]: boolean } = {};
  const regex = /<addons\s+data-id="([^"]+)"><\/addons>/g;

  // Remove duplicate addon elements based on data-id
  const modifiedLiquidTemplate = liquidTemplate.replace(regex, (match, dataId) => {
    if (!addonMap[dataId]) {
      addonMap[dataId] = true;
      return match;
    }
    return '';
  });

  return modifiedLiquidTemplate;
}

function mergeLiquid(newLiquid: string, oldLiquid: string) {
  return (
    [
      ...(oldLiquid.match(/<addons\s+data-id.*\/addons>([\s\S]{200})/g) ?? []),
      ...(oldLiquid.match(/([\s\S]{200})<addons\s+data-id.*\/addons>/g) ?? []),
    ]
      // Thêm lại addon cho new liquid
      .reduce((str, item) => {
        const searchValue = item.replace(/<addons\s+data-id.*<\/addons>/g, '');
        return str.replace(searchValue, item);
      }, newLiquid)
      // Xoá trường hợp trùng lặp do vừa check đầu đuôi không thay đổi tạo ra
      .replace(/(<addons\s+data-id.*\/addons>)(<addons\s+data-id.*\/addons>)/g, (_, s1, s2) => {
        if (s1 === s2) {
          return s1;
        }
        return s1 + s2;
      })
  );
}

export function updateSection(oldSection: PageSection, newSection: PageSection, ignoreCompareFeId = false): PageSection {
  const _oldSection = { ...oldSection } as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client;
  const _newSection = { ...newSection } as AdminSection;
  const isValidToCompare = _oldSection.parentCommandId === _newSection.commandId && (ignoreCompareFeId ? true : _oldSection.id === _newSection.id);
  if (isValidToCompare) {
    // Thêm và sửa color_scheme chỉ dành riêng cho đội ngũ của veda
    if (/(veda-.*|magicbadgesdev).myshopify.com/g.test(window.location.href)) {
      const settings = compareSettings(_newSection.data.settings, _oldSection.data.settings, _newSection.data.schema);
      const liquid = combineAddonElements(mergeLiquid(_newSection.data.liquid, _oldSection.data.liquid));
      const updatedSection: PageSection = {
        ..._oldSection,
        category: (_oldSection.category ?? newSection.category) as any,
        currentVersion: _newSection.currentVersion,
        parentCommandId: _oldSection.parentCommandId,
        commandId: _oldSection.commandId,
        data: {
          js: _newSection.data.js,
          liquid: liquid.includes('{{ color_scheme }}')
            ? liquid
            : liquid.replace(
                /class=".*(?=".*(\n.*(\n.*)?{{\sbackgroundColor\s}}{{\sbackgroundImage\s}}{{\ssectionPadding\s}}))/g,
                s => `${s} bgc:color-light c:color-gray7 {{ color_scheme }}`,
              ),
          scss: _newSection.data.scss,
          jsHook: _newSection.data.jsHook,
          schema: {
            blocks: _newSection.data.schema.blocks,
            settings: _newSection.data.schema.settings.some(item => item.type === 'color_scheme')
              ? _newSection.data.schema.settings.map(setting => {
                  if (setting.type === 'color_scheme' && setting.children == null) {
                    return {
                      ...setting,
                      children: 'veda-color-default',
                    };
                  }
                  return setting;
                })
              : [
                  {
                    id: v4(),
                    type: 'color_scheme',
                    children: 'veda-color-default',
                    label: 'Color Scheme',
                    name: 'color_scheme',
                  },
                  ..._newSection.data.schema.settings,
                ],
          },
          settings: settings.some(item => item.type === 'color_scheme')
            ? settings.map(setting => {
                if (setting.type === 'color_scheme' && setting.children == null) {
                  return {
                    ...setting,
                    children: 'veda-color-default',
                  };
                }
                return setting;
              })
            : [
                {
                  id: v4(),
                  type: 'color_scheme',
                  children: 'veda-color-default',
                  label: 'Color Scheme',
                  name: 'color_scheme',
                },
                ...settings,
              ],
        },
      };
      return updatedSection;
    } else {
      const updatedSection: PageSection = {
        ..._oldSection,
        currentVersion: _newSection.currentVersion,
        parentCommandId: _oldSection.parentCommandId,
        commandId: _oldSection.commandId,
        data: {
          js: _newSection.data.js,
          liquid: combineAddonElements(mergeLiquid(_newSection.data.liquid, _oldSection.data.liquid)),
          scss: _newSection.data.scss,
          jsHook: _newSection.data.jsHook,
          schema: _newSection.data.schema,
          settings: compareSettings(_newSection.data.settings, _oldSection.data.settings, _newSection.data.schema),
        },
      };
      return updatedSection;
    }
  }
  return _oldSection;
}
