import { userApis } from 'services/UserService/apis';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';

interface Params {
  userId: number;
  verifyStatus: 1 | 2;
}

export const banAndUnBanUserReq = async ({ userId, verifyStatus }: Params) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    const response = await userApis.user.adminApi.banAndUnBanUser({
      userId,
      verifyStatus,
    });
    return response;
  }
  throw new RoleException();
};
