import { addonApiController } from 'services/AddonService';
import { adapterGetCategories } from 'services/AddonService/Adapters/adapterGetCategories';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const getCategoriesOfAtom = async () => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const response = await addonApiController.atom.adminApi.category.getCategoriesOfAtom({ type: 'GET FIRST PAGE', cacheVersion: lastCache });
    return adapterGetCategories(response.info);
  }
  const response = await addonApiController.atom.publishApi.category.getCategoriesOfAtom({ type: 'GET FIRST PAGE', cacheVersion: lastCache });
  return adapterGetCategories(response.info);
};

export const loadMoreCategoriesOfAtom = async (cursor: string) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const response = await addonApiController.atom.adminApi.category.getCategoriesOfAtom({ type: 'LOADMORE', cacheVersion: lastCache, cursor });
    return adapterGetCategories(response.info);
  }
  const response = await addonApiController.atom.publishApi.category.getCategoriesOfAtom({ type: 'LOADMORE', cacheVersion: lastCache, cursor });
  return adapterGetCategories(response.info);
};
