import { AxiosError } from 'axios';
import { call, put, retry, select, takeLatest, SagaReturnType, all } from 'redux-saga/effects';
import { updateThemeSettingsClient } from 'services/ThemeService/Logic/updateThemeSettingsClient';
import { i18n } from 'translation';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector, themeSettingsSelector } from 'store/selectors';
import { handleUpdateThemeSettingsInDashboard, shopifyConnectionService } from 'services/ShopifyConnection';
import { syncTranslations } from 'services/ShopifyConnection/flowSyncToShopify/syncTranslations';
import { MODAL_REPORT_SAVE_THEME_SETITNGS_IN_DASHBOARD_ERROR } from 'containers/ModalReportAfterError/const';
import { ModalReportAfterError } from 'containers/ModalReportAfterError/ModalReportAfterError';
import { updateThemeActiveSettings } from './sliceThemeSettingsDashboard';
import { syncGlobalOfThemeInDashboard } from 'services/ShopifyConnection/flowSyncToShopify/syncGlobalSettings';
import { checkThemeAppStatus } from 'services/ShopifyConnection/flowSyncToShopify/checkThemeAppStatus';
import { createPublishStepLoading } from 'components/PublishStepLoading';
import { removeEmptyKey } from 'utils/functions/removeEmptyKey';

export const syncThemeSettingsInDashboardNotification = createPublishStepLoading(3);

function* handleUpdate({ payload }: ReturnType<typeof updateThemeActiveSettings.request>) {
  const { commandId, body, onFulfill } = payload;
  try {
    syncThemeSettingsInDashboardNotification.next({
      title: i18n.t('publish_shopify.sync_page_message.step', { text: '1' }),
      description: i18n.t('general.saving', { text: i18n.t('general.settings') }),
    });
    yield retry(3, 1000, updateThemeSettingsClient, { commandId, themeSettings: body });

    notifyAxiosHandler.handleSuccess(`${i18n.t('general.update', { text: i18n.t('general.successfully') })}`);

    const isActived: SagaReturnType<typeof checkThemeAppStatus> = yield call(checkThemeAppStatus);
    if (!isActived) {
      onFulfill();
      syncThemeSettingsInDashboardNotification.done();
      return;
    }

    syncThemeSettingsInDashboardNotification.next({
      title: i18n.t('publish_shopify.sync_page_message.step', { text: '2' }),
      description: i18n.t('publish_shopify.syncing', { text: i18n.t('publish_shopify.text_to_shopify', { text: 'Translation & Theme Settings' }) }),
    });

    const themeSettingsState: ReturnType<typeof themeSettingsSelector> = yield select(themeSettingsSelector);
    const { eventId }: ReturnType<typeof socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector> = yield select(
      socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector,
    );
    const { themeParams, syncTranslationsParams }: Awaited<ReturnType<typeof handleUpdateThemeSettingsInDashboard>> = yield call(
      handleUpdateThemeSettingsInDashboard,
      {
        themeSettings: {
          cssVariables: {
            colors: themeSettingsState.cssVariables.colors,
            fonts: themeSettingsState.cssVariables.fonts,
          },
          generalSettings: themeSettingsState.generalSettings,
          globalJs: themeSettingsState.globalJs,
          globalScss: themeSettingsState.globalScss,
          layoutSettings: themeSettingsState.layoutSettings,
          vendors: themeSettingsState.vendors,
          globalTranslations: {
            ...themeSettingsState.globalTranslations,
            translation: removeEmptyKey(themeSettingsState.globalTranslations.translation),
          },
          colorSchemes: themeSettingsState.colorSchemes,
          themeVersion: themeSettingsState.themeVersion,
        },
        eventId,
      },
    );

    const [{ statusSyncGlobalOfThemeInDashboard }, { statusSyncTranslations }]: [
      SagaReturnType<typeof syncGlobalOfThemeInDashboard>,
      SagaReturnType<typeof syncTranslations>,
    ] = yield all([call(syncGlobalOfThemeInDashboard, { themeParams }), call(syncTranslations, { syncTranslationsParams })]);
    if (statusSyncGlobalOfThemeInDashboard === 'failure' || statusSyncTranslations === 'failure') {
      throw new Error(i18n.t('publish_shopify.sync_error'));
    }
    notifyAxiosHandler.handleSuccess(`${i18n.t('general.update', { text: i18n.t('general.successfully') })}`);
    yield put(updateThemeActiveSettings.success(undefined));
  } catch (error) {
    notifyAxiosHandler.handleError(error as AxiosError<ErrorData> | Error);
    yield put(updateThemeActiveSettings.failure(undefined));
    ModalReportAfterError.getActions(MODAL_REPORT_SAVE_THEME_SETITNGS_IN_DASHBOARD_ERROR).report({
      cause: i18n.t('ModalReportAfterError.error_cause.save_theme_setting_in_dashboard'),
      description: error instanceof Error ? error.message : '',
    });
  } finally {
    syncThemeSettingsInDashboardNotification.done();
    onFulfill();
    try {
      yield retry(3, 1000, shopifyConnectionService.cleanAfterSync);
    } catch {}
  }
}

export function* watchUpdateThemeActiveSettings() {
  yield takeLatest(getActionType(updateThemeActiveSettings.request), handleUpdate);
}
