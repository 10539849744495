import { AxiosResponse } from 'axios';
import fetchAPI from 'utils/functions/fetchAPI';
import { clientBaseUrl } from '../const';

interface Params {
  language: string;
}

interface ResponseSuccess {
  message: string;
  info: string;
}

export const getDefaultLanguage = async ({ language }: Params) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${clientBaseUrl}/${language}`,
  });

  return response.data;
};
