import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import fetchAPI from 'utils/functions/fetchAPI';
import { getShopName } from 'utils/functions/getUserInfo';
import { withDelayAfterThrottled } from '../utils/withDelayAfterThrottled';

interface GetThemeCss {}

interface GetThemeCssResponse {
  url: string;
}

interface BE_Response {
  urlThemeCssGlobal: string;
}

export const getThemeCss = async ({}: GetThemeCss): Promise<GetThemeCssResponse> => {
  if (configureApp.apiFake) {
    return { url: '' };
  } else {
    const response: AxiosResponse<BE_Response> = await withDelayAfterThrottled(
      fetchAPI.request({
        baseURL: '',
        url: `${configureApp.endpoint['shopify-connections']}/me/theme-assets`,
        params: {
          shopName: getShopName(),
        },
      }),
    );
    return {
      url: response.data.urlThemeCssGlobal,
    };
  }
};
