import { AsyncComponent, ScrollBars } from '@wiloke/ui';
import { NavigationNoneLink } from 'components/Navigation';
import { useGetPartnerAppCategories, useSetPartnerAppCategory, useSetSearchPartnerApp } from 'containers/Admin/AppsPartner/store/action';
import { partnerAppSelector } from 'containers/Admin/AppsPartner/store/partnerAppsSlice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const PartnerCategories = () => {
  const { getCategoriesStatus, categories, categoryId } = useSelector(partnerAppSelector);

  const getCategories = useGetPartnerAppCategories();
  const setIdCategory = useSetPartnerAppCategory();
  const setSearchPartnerApp = useSetSearchPartnerApp();

  useEffect(() => {
    getCategories.request(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScrollBars>
      <AsyncComponent
        status={getCategoriesStatus}
        Success={
          <NavigationNoneLink
            idActive={categoryId}
            data={categories.map(item => ({
              id: item.id.toString(),
              counter: item.count ?? 0,
              label: item.name,
              textHeader: item.name === 'All' ? 'Filter' : undefined,
            }))}
            onClick={item => {
              setIdCategory(item.id);
              setSearchPartnerApp('');
            }}
          />
        }
      />
    </ScrollBars>
  );
};
