import { watchUpdateShopifyThemeId } from 'containers/Admin/ThemeBuilder/ThemeDashboard/sagas/watchUpdateShopifyThemeId';
import { watchActiveThemeVeda } from './ThemeDashboard/sagas/watchActiveThemeVeda';
import { watchCancelMigrateTheme } from './ThemeDashboard/sagas/watchCancelMigrateTheme';
import { watchChangeThemeShopifyActivate } from './ThemeDashboard/sagas/watchChangeThemeActivateShopify';
import { watchDeleteClientTheme } from './ThemeDashboard/sagas/watchDeleteClientTheme';
import { watchGetClientThemes } from './ThemeDashboard/sagas/watchGetClientThemes';
import { watchGetHomePageBeforeCustomize } from './ThemeDashboard/sagas/watchGetHomePageBeforeCustomize';
import { watchGetThemesShopify } from './ThemeDashboard/sagas/watchGetThemesShopify';
import { watchLoadMoreClientThemes } from './ThemeDashboard/sagas/watchLoadMoreClientThemes';
import { watchMigrateThemeShopify } from './ThemeDashboard/sagas/watchMigrateThemeShopify';
import { watchUpdateThemeActiveSettings } from './ThemeSettings/sagas';

export const sagaThemeBuilder = [
  watchUpdateThemeActiveSettings,
  watchGetClientThemes,
  watchDeleteClientTheme,
  watchLoadMoreClientThemes,
  watchGetThemesShopify,
  watchMigrateThemeShopify,
  watchChangeThemeShopifyActivate,
  watchActiveThemeVeda,
  watchCancelMigrateTheme,
  watchGetHomePageBeforeCustomize,
  watchUpdateShopifyThemeId,
];
