import { getCacheValue, setCacheValue, getUniqId } from '../../../CacheShopifyFieldId';
import { ISCHEMA_SettingResponsive } from '../@types/ISCHEMA_SettingResponsive';
import { ResponsiveValue } from '../@types/SettingResponsive';

export const toShopifyFieldId = ({ field, parentField, section }: ISCHEMA_SettingResponsive, breakpoint: keyof ResponsiveValue) => {
  const theoryShopifyFieldId = parentField ? `${parentField.name}___${field.name}__${breakpoint}` : `${field.name}__${breakpoint}`;
  if (theoryShopifyFieldId.length >= 25) {
    const valueInCache = getCacheValue({ theoryShopifyFieldId, section });
    if (valueInCache) {
      return valueInCache;
    } else {
      const newKey = `VEDA_responsive_${getUniqId('responsive')}`;
      setCacheValue({ theoryShopifyFieldId, section }, newKey);
      return newKey;
    }
  }
  return theoryShopifyFieldId;
};
