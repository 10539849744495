import { MyModal, ScrollBars, Title, Tooltip } from '@wiloke/ui';
import BoxCenter from 'components/BoxCenter';
import Checkbox from 'components/Checkbox';
import { labelOfTypes } from 'containers/BuilderPage/components/SchemaField/components/FormSchemaSetting/fields/TypeField/TypeField';
import { VideoCart } from 'containers/ModalInstallTheme/components/VideoCard';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dataBindingFieldNamesSelector, useDataBindingFieldNamesClear, useDataBindingFieldNamesSave } from 'store/global/dataBindingFieldNames/slice';
import { sectionIdActiveSelector } from 'store/selectors';
import { i18n } from 'translation';
import { SettingArray } from 'types/Schema';
import { getLabel } from 'utils/functions/getLabel';
import { FontAwesome, Space, Text, View } from 'wiloke-react-core';
import * as styles from './styles';

export interface DataBindingBetweenItemsProps {
  setting: SettingArray;
}

export const DataBindingBetweenItems: FC<DataBindingBetweenItemsProps> = ({ setting }) => {
  const dataBindingFieldNames = useSelector(dataBindingFieldNamesSelector);
  const [modalVisible, setModalVisible] = useState(false);
  const [fieldNames, setFieldNames] = useState<string[]>([]);
  const sectionIdActive = useSelector(sectionIdActiveSelector);
  const dataBindingFieldNamesSave = useDataBindingFieldNamesSave();
  const dataBindingFieldNamesClear = useDataBindingFieldNamesClear();
  // Active khi length của field names đc lưu tại redux > 0
  const active = dataBindingFieldNames.length > 0;

  useEffect(() => {
    if (modalVisible) {
      setFieldNames(dataBindingFieldNames);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  const handleApply = () => {
    dataBindingFieldNamesSave({ sectionId: sectionIdActive, fieldNames });
    setModalVisible(false);
    setFieldNames([]);
  };

  const handleCancel = () => {
    dataBindingFieldNamesClear({ sectionId: sectionIdActive });
    setModalVisible(false);
    setFieldNames([]);
  };

  const handleSelectAll = () => {
    const allFields = setting.children[0]?.children
      .map(item => {
        if (
          item.type === 'slider' ||
          item.type === 'styleBox' ||
          item.type === 'color' ||
          item.type === 'animate' ||
          item.type === 'animateInOut' ||
          item.type === 'responsive' ||
          item.type === 'radioGroup' ||
          item.type === 'icon' ||
          item.type === 'switch'
        ) {
          return item.name;
        }
        return '';
      })
      .filter(Boolean);
    setFieldNames(allFields);
  };

  const handleClearAll = () => {
    setFieldNames([]);
  };

  return (
    <>
      <Tooltip portal placement="right" text={i18n.t('general.data_binding_between_items')}>
        <BoxCenter
          size={45}
          onClick={() => {
            setModalVisible(true);
          }}
          css={{ position: 'relative', display: 'flex', gap: '5px', width: 'auto' }}
        >
          <View fontFamily="secondary" css={styles.buildFaster} radius={'pill'} backgroundColor="danger" color="light">
            Build Faster
          </View>
          <View tagName="span">
            <FontAwesome type={active ? 'fas' : 'far'} name="link" size={12} color={active ? 'secondary' : 'gray6'} />
          </View>
        </BoxCenter>
      </Tooltip>
      <MyModal
        isVisible={modalVisible}
        headerText={i18n.t('general.data_binding_between_items')}
        okText={i18n.t('general.apply')}
        cancelText={i18n.t('general.clear')}
        onButtonCancel={handleCancel}
        onCancel={() => setModalVisible(false)}
        onOk={handleApply}
        bodyCss={styles.modalBody}
        scrollDisabled
        contentCss={{ height: '100%' }}
      >
        <View css={{ display: 'flex', gap: '10px', height: '100%' }}>
          <View css={{ width: '100%' }}>
            <View css={{ display: 'flex', columnGap: '5px' }}>
              <View fontFamily="secondary" color="primary" css={{ cursor: 'pointer', userSelect: 'none' }} onClick={handleSelectAll}>
                {i18n.t('general.apply_all')}
              </View>
              <View>-</View>
              <View fontFamily="secondary" css={{ cursor: 'pointer', userSelect: 'none' }} color="danger" onClick={handleClearAll}>
                {i18n.t('general.clear_all')}
              </View>
            </View>
            <Space size={10} />

            <ScrollBars css={{ height: 'calc(100% - 50px)' }}>
              {setting.children[0]?.children.map(item => {
                if (
                  item.type === 'slider' ||
                  item.type === 'styleBox' ||
                  item.type === 'color' ||
                  item.type === 'animate' ||
                  item.type === 'animateInOut' ||
                  item.type === 'responsive' ||
                  item.type === 'radioGroup' ||
                  item.type === 'icon' ||
                  item.type === 'switch'
                ) {
                  return (
                    <View key={item.id} css={{ marginTop: '3px' }}>
                      <Checkbox
                        checked={fieldNames.includes(item.name)}
                        innerCss={{ verticalAlign: 'top', marginTop: '-3px' }}
                        onValueChange={value => {
                          if (value) {
                            setFieldNames(fieldNames => [...fieldNames, item.name]);
                          } else {
                            setFieldNames(fieldNames => fieldNames.filter(name => name !== item.name));
                          }
                        }}
                      >
                        <View css={{ marginLeft: '5px' }}>
                          <Text size={11}>{labelOfTypes[item.type]}</Text>
                          <Text>{getLabel(item.label)}</Text>
                        </View>
                      </Checkbox>
                      <Space size={10} />
                    </View>
                  );
                }

                return null;
              })}
            </ScrollBars>
          </View>
          <View css={styles.extraNode}>
            <Title size="medium" title="Builder Faster!!" text={i18n.t('general.data_binding_text')} />
            <VideoCart disabledViewDetail description="" title="" embedId="6S4NzLTP12U" href="https://youtu.be/6S4NzLTP12U" />
          </View>
        </View>
      </MyModal>
    </>
  );
};
