import { PageCategory } from 'types/Page';
import { createAsyncAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';

export const createCategoryOfPageProduct = createAsyncAction([
  '@PageManager/createCategoryOfPageProduct/request',
  '@PageManager/createCategoryOfPageProduct/success',
  '@PageManager/createCategoryOfPageProduct/failure',
])<
  {
    name: string;
    description: string;
    belongsToPageTypes?: PageCategory['belongsToPageTypes'];
    onFulfill?: () => void;
  },
  PageCategory,
  undefined
>();

export const getCategoriesOfPageProduct = createAsyncAction([
  '@PageManager/getCategoriesOfPageProduct/request',
  '@PageManager/getCategoriesOfPageProduct/success',
  '@PageManager/getCategoriesOfPageProduct/failure',
])<{ pageType?: string }, PageCategory[], undefined>();

export const deleteCategoryOfPageProduct = createAsyncAction([
  '@PageManager/deleteCategoryOfPageProduct/request',
  '@PageManager/deleteCategoryOfPageProduct/success',
  '@PageManager/deleteCategoryOfPageProduct/failure',
])<{ commandId: string }, { commandId: string }, { commandId: string }>();

export const updateCategoryOfPageProduct = createAsyncAction([
  '@PageManager/updateCategoryOfPageProduct/request',
  '@PageManager/updateCategoryOfPageProduct/success',
  '@PageManager/updateCategoryOfPageProduct/failure',
])<
  { commandId: string; description: string; name: string; belongsToPageTypes?: PageCategory['belongsToPageTypes'] },
  { commandId: string; description: string; name: string; belongsToPageTypes?: PageCategory['belongsToPageTypes'] },
  { commandId: string }
>();

export const useCreateCategoryOfPageProduct = createDispatchAsyncAction(createCategoryOfPageProduct);
export const useGetCategoriesOfPageProduct = createDispatchAsyncAction(getCategoriesOfPageProduct);
export const useDeleteCategoryOfPageProduct = createDispatchAsyncAction(deleteCategoryOfPageProduct);
export const useUpdateCategoryOfPageProduct = createDispatchAsyncAction(updateCategoryOfPageProduct);
