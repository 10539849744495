import { ClientTheme } from 'types/Theme';
import { getCurrentVersion } from 'utils/CacheControl/VedaClientCacheControl';
import { handleGlobalTranslationsNColorSchemesInThemeClient } from '../Adapter/handleGlobalTranslationsNColorSchemes';
import { themeApis } from '../apis';

interface LoadMoreClientThemesAPI {
  lastCursor: string;
  themeStatus?: string;
  size: number;
}

export const loadMoreClientThemesAPI = async ({ lastCursor, themeStatus, size }: LoadMoreClientThemesAPI): Promise<ClientTheme[]> => {
  const response = await themeApis.vedaApplication.userApi.theme.getClients({
    type: 'LOADMORE',
    lastCursor,
    cacheVersion: getCurrentVersion().getTime(),
    themeStatus,
    size,
  });
  return response.info.map(item => handleGlobalTranslationsNColorSchemesInThemeClient(item));
};
