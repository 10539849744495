import { PageType } from 'types/Page';

export const MAX_LENGTH_OF_THEME_NAME = 50 - '63c384374166f83c2fecea57'.length - 'veda'.length - 5;

export const PAGE_TYPES_WITH_EXPECT_ORDER: PageType[] = [
  'home',
  'product',
  'collection',
  'article',
  'page',
  'account',
  'activateAccount',
  'addresses',
  'cart',
  'collections',
  'giftCard',
  'login',
  'order',
  'pageNotFound',
  'password',
  'register',
  'resetPassword',
  'search',
  'blog',
];

export const LABEL_OF_PAGES: Record<PageType, string> = {
  account: 'Account',
  activateAccount: 'Activate Account',
  addresses: 'Addresses',
  article: 'Article',
  blog: 'Blog',
  cart: 'Cart',
  collection: 'Collection',
  collections: 'Collections',
  giftCard: 'Gift Card',
  home: 'Home',
  login: 'Login',
  order: 'Order',
  page: 'Page',
  pageNotFound: '404',
  password: 'Password',
  product: 'Product',
  register: 'Register',
  resetPassword: 'Reset Password',
  search: 'Search',
};

export const FILE_NAME_OF_DEFAULT: Record<PageType, string> = {
  account: 'customers/account.json',
  activateAccount: 'customers/activate_account.json',
  addresses: 'customers/addresses.json',
  article: 'article.json',
  blog: 'blog.json',
  cart: 'cart.json',
  collection: 'collection.json',
  collections: 'list-collections.json',
  giftCard: 'Veda không hỗ trợ page này',
  home: 'index.json',
  login: 'customers/login.json',
  order: 'customers/order.json',
  page: 'page.json',
  pageNotFound: '404.json',
  password: 'password.json',
  product: 'product.json',
  register: 'customers/register.json',
  resetPassword: 'customers/reset_password.json',
  search: 'search.json',
};
