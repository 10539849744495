import { ThemeSchema } from '../../../@types/Shopify/ThemeConfigs/ThemeSchema';
import { ThemeSetting } from '../../../@types/Shopify/ThemeConfigs/ThemeSetting';
import { ThemeSettings } from '../../@types/ThemeSettings';
import { ISCHEMA_SettingColors } from './@types/ISCHEMA_SettingColors';
import { ISETTING_SettingColors } from './@types/ISETTING_SettingColors';
import { getHeaderLabel } from './utils/getHeaderLabel';
import { getSchemaLabel } from './utils/getSchemaLabel';
import { vedaColorToShopifyFieldId } from './utils/vedaColorToShopifyFieldId';
import { vedaColorToShopifyFieldLabel } from './utils/vedaColorToShopifyFieldLabel';

export const SCHEMA_SettingColors = ({ colors }: ISCHEMA_SettingColors): ThemeSchema => {
  return {
    name: getSchemaLabel('light').newLabel,
    settings: [
      { type: 'header', content: getHeaderLabel('light').newLabel },
      ...colors.map<ThemeSchema['settings'][number]>(color => {
        return {
          type: 'color',
          default: color.light,
          label: vedaColorToShopifyFieldLabel(color, 'light').newLabel,
          id: vedaColorToShopifyFieldId(color, 'light'),
          placeholder: undefined,
        };
      }),
      { type: 'header', content: getHeaderLabel('dark').newLabel },
      ...colors.map<ThemeSchema['settings'][number]>(color => {
        return {
          type: 'color',
          default: color.dark,
          label: vedaColorToShopifyFieldLabel(color, 'dark').newLabel,
          id: vedaColorToShopifyFieldId(color, 'dark'),
          placeholder: undefined,
        };
      }),
    ],
  };
};

export const SETTING_SettingColors = ({ colors }: ISETTING_SettingColors): ThemeSetting => {
  return colors.reduce<ThemeSetting>((res: ThemeSetting, color: ThemeSettings['cssVariables']['colors'][number]) => {
    const lightColorKey = vedaColorToShopifyFieldId(color, 'light');
    const darkColorKey = vedaColorToShopifyFieldId(color, 'dark');
    return {
      ...res,
      [lightColorKey]: color.light,
      [darkColorKey]: color.dark,
    };
  }, {});
};
