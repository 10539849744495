import { ImageField } from '../../../@types/Shopify/InputSettings/ImageField';
import { getShopifyFieldLabel } from './utils/getShopifyFieldLabel';

export const SettingLogoImage = (): ImageField => {
  return {
    type: 'image_picker',
    id: 'logo',
    label: getShopifyFieldLabel().newLabel,
  };
};
