import { createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';

type Actions =
  | {
      type: 'setVisibleUpgradePlan';
      payload: {
        visible: boolean;
        message?: string;
      };
    }
  | {
      type: 'setVisibleChangePlanName';
      payload: boolean;
    };

interface State {
  visibleUpgradePlan: boolean;
  visibleChangePlanName: boolean;
  messageUpgradePlan: string;
}

export const sliceUpgradePlan = createSlice<State, Actions>({
  initialState: {
    visibleUpgradePlan: false,
    visibleChangePlanName: false,
    messageUpgradePlan: 'Quá số lượng section',
  },
  name: '@Global',
  reducers: [
    handleAction('setVisibleUpgradePlan', ({ state, action }) => {
      state.visibleUpgradePlan = action.payload.visible;
      state.messageUpgradePlan = action.payload.message ?? state.messageUpgradePlan;
    }),
    handleAction('setVisibleChangePlanName', ({ state, action }) => {
      state.visibleChangePlanName = action.payload;
    }),
  ],
});

export const { setVisibleUpgradePlan, setVisibleChangePlanName } = sliceUpgradePlan.actions;

export const useSetVisibleUpgradePlan = createDispatchAction(setVisibleUpgradePlan);
export const useSetVisibleChangePlanName = createDispatchAction(setVisibleChangePlanName);

export const upgradeOrChangePlanSelector = (state: AppState) => state.global.upgradePlan;
