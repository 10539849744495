import { PresetStyle } from 'types/PresetStyles';
import { delay, createState } from '@wiloke/functions';
import { v4 } from 'uuid';

const presetState = createState<PresetStyle[]>([
  {
    id: v4(),
    title: 'Style 1',
    colors: [
      { id: v4(), name: '--color-primary', light: '#33C9C4', dark: '#33C9C4' },
      { id: v4(), name: '--color-secondary', light: '#3540df', dark: '#3540df' },
      { id: v4(), name: '--color-tertiary', light: '#eb6c6c', dark: '#eb6c6c' },
      { id: v4(), name: '--color-quaternary', light: '#FBC473', dark: '#FBC473' },
      { id: v4(), name: '--color-dark', light: '#000000', dark: '#ffffff' },
      { id: v4(), name: '--color-gray9', light: '#232323', dark: '#fafafa' },
      { id: v4(), name: '--color-gray8', light: '#3e3e3e', dark: '#f3f3f3' },
      { id: v4(), name: '--color-gray7', light: '#575757', dark: '#e9e9e9' },
      { id: v4(), name: '--color-gray6', light: '#888888', dark: '#dbdbdb' },
      { id: v4(), name: '--color-gray5', light: '#b3b3b3', dark: '#b3b3b3' },
      { id: v4(), name: '--color-gray4', light: '#dbdbdb', dark: '#888888' },
      { id: v4(), name: '--color-gray3', light: '#e9e9e9', dark: '#575757' },
      { id: v4(), name: '--color-gray2', light: '#f3f3f3', dark: '#3e3e3e' },
      { id: v4(), name: '--color-gray1', light: '#fafafa', dark: '#232323' },
      { id: v4(), name: '--color-light', light: '#ffffff', dark: '#000000' },
    ],
    fonts: [
      { id: v4(), name: '--font-primary', value: 'Inter' },
      { id: v4(), name: '--font-secondary', value: 'Inter' },
      { id: v4(), name: '--font-tertiary', value: 'Playfair Display' },
      { id: v4(), name: '--font-quaternary', value: 'Amatic SC' },
    ],
  },
  {
    id: v4(),
    title: 'Style 2',
    colors: [
      { id: v4(), name: '--color-primary', light: '#4840D8', dark: '#4840D8' },
      { id: v4(), name: '--color-secondary', light: '#EA7E4C', dark: '#EA7E4C' },
      { id: v4(), name: '--color-tertiary', light: '#1B3839', dark: '#1B3839' },
      { id: v4(), name: '--color-quaternary', light: '#EEF9FE', dark: '#EEF9FE' },
      { id: v4(), name: '--color-dark', light: '#080626', dark: '#ffffff' },
      { id: v4(), name: '--color-gray9', light: '#211F3C', dark: '#F3F3F4' },
      { id: v4(), name: '--color-gray8', light: '#393851', dark: '#E6E6E9' },
      { id: v4(), name: '--color-gray7', light: '#525167', dark: '#CECDD4' },
      { id: v4(), name: '--color-gray6', light: '#838293', dark: '#B5B4BE' },
      { id: v4(), name: '--color-gray5', light: '#9C9BA8', dark: '#9C9BA8' },
      { id: v4(), name: '--color-gray4', light: '#B5B4BE', dark: '#838293' },
      { id: v4(), name: '--color-gray3', light: '#CECDD4', dark: '#525167' },
      { id: v4(), name: '--color-gray2', light: '#E6E6E9', dark: '#393851' },
      { id: v4(), name: '--color-gray1', light: '#F3F3F4', dark: '#211F3C' },
      { id: v4(), name: '--color-light', light: '#FFFFFF', dark: '#080626' },
    ],
    fonts: [
      { id: v4(), name: '--font-primary', value: 'Jost' },
      { id: v4(), name: '--font-secondary', value: 'Jost' },
      { id: v4(), name: '--font-tertiary', value: 'Playfair Display' },
      { id: v4(), name: '--font-quaternary', value: 'Roboto Mono' },
    ],
  },
  {
    id: v4(),
    title: 'Style 3',
    colors: [
      { id: v4(), name: '--color-primary', light: '#254978', dark: '#254978' },
      { id: v4(), name: '--color-secondary', light: '#7EB3D1', dark: '#7EB3D1' },
      { id: v4(), name: '--color-tertiary', light: '#EE6C4D', dark: '#EE6C4D' },
      { id: v4(), name: '--color-quaternary', light: '#E0FBFC', dark: '#E0FBFC' },
      { id: v4(), name: '--color-dark', light: '#092142', dark: '#ffffff' },
      { id: v4(), name: '--color-gray9', light: '#223755', dark: '#F3F4F6' },
      { id: v4(), name: '--color-gray8', light: '#3A4D68', dark: '#E6E9EC' },
      { id: v4(), name: '--color-gray7', light: '#53647B', dark: '#CED3D9' },
      { id: v4(), name: '--color-gray6', light: '#8490A0', dark: '#B5BDC6' },
      { id: v4(), name: '--color-gray5', light: '#9DA6B3', dark: '#9DA6B3' },
      { id: v4(), name: '--color-gray4', light: '#B5BDC6', dark: '#8490A0' },
      { id: v4(), name: '--color-gray3', light: '#CED3D9', dark: '#53647B' },
      { id: v4(), name: '--color-gray2', light: '#E6E9EC', dark: '#3A4D68' },
      { id: v4(), name: '--color-gray1', light: '#F3F4F6', dark: '#223755' },
      { id: v4(), name: '--color-light', light: '#FFFFFF', dark: '#092142' },
    ],
    fonts: [
      { id: v4(), name: '--font-primary', value: 'Roboto' },
      { id: v4(), name: '--font-secondary', value: 'Roboto' },
      { id: v4(), name: '--font-tertiary', value: 'Maitree' },
      { id: v4(), name: '--font-quaternary', value: 'Charmonman' },
    ],
  },
  {
    id: v4(),
    title: 'Style 4',
    colors: [
      { id: v4(), name: '--color-primary', light: '#DAA78F', dark: '#DAA78F' },
      { id: v4(), name: '--color-secondary', light: '#76B774', dark: '#76B774' },
      { id: v4(), name: '--color-tertiary', light: '#9682C9', dark: '#9682C9' },
      { id: v4(), name: '--color-quaternary', light: '#DAD7CD', dark: '#DAD7CD' },
      { id: v4(), name: '--color-dark', light: '#10271C', dark: '#ffffff' },
      { id: v4(), name: '--color-gray9', light: '#283D33', dark: '#F3F4F6' },
      { id: v4(), name: '--color-gray8', light: '#405249', dark: '#E6E9EC' },
      { id: v4(), name: '--color-gray7', light: '#586860', dark: '#CED3D9' },
      { id: v4(), name: '--color-gray6', light: '#87938D', dark: '#B5BDC6' },
      { id: v4(), name: '--color-gray5', light: '#9FA9A4', dark: '#9FA9A4' },
      { id: v4(), name: '--color-gray4', light: '#B7BEBB', dark: '#87938D' },
      { id: v4(), name: '--color-gray3', light: '#CFD4D2', dark: '#586860' },
      { id: v4(), name: '--color-gray2', light: '#E7E9E8', dark: '#405249' },
      { id: v4(), name: '--color-gray1', light: '#F3F4F4', dark: '#283D33' },
      { id: v4(), name: '--color-light', light: '#FFFFFF', dark: '#10271C' },
    ],
    fonts: [
      { id: v4(), name: '--font-primary', value: 'Open Sans' },
      { id: v4(), name: '--font-secondary', value: 'Lora' },
      { id: v4(), name: '--font-tertiary', value: 'Playfair Display' },
      { id: v4(), name: '--font-quaternary', value: 'Roboto Mono' },
    ],
  },
  {
    id: v4(),
    title: 'Style 5',
    colors: [
      { id: v4(), name: '--color-primary', light: '#624CAB', dark: '#624CAB' },
      { id: v4(), name: '--color-secondary', light: '#7189FF', dark: '#7189FF' },
      { id: v4(), name: '--color-tertiary', light: '#C1CEFE', dark: '#C1CEFE' },
      { id: v4(), name: '--color-quaternary', light: '#D7E4A2', dark: '#D7E4A2' },
      { id: v4(), name: '--color-dark', light: '#141D48', dark: '#ffffff' },
      { id: v4(), name: '--color-gray9', light: '#2C345A', dark: '#F3F4F6' },
      { id: v4(), name: '--color-gray8', light: '#434A6D', dark: '#E6E9EC' },
      { id: v4(), name: '--color-gray7', light: '#5A617F', dark: '#CED3D9' },
      { id: v4(), name: '--color-gray6', light: '#898EA3', dark: '#B5BDC6' },
      { id: v4(), name: '--color-gray5', light: '#A1A5B6', dark: '#A1A5B6' },
      { id: v4(), name: '--color-gray4', light: '#B9BBC8', dark: '#898EA3' },
      { id: v4(), name: '--color-gray3', light: '#D0D2DA', dark: '#5A617F' },
      { id: v4(), name: '--color-gray2', light: '#E7E8ED', dark: '#434A6D' },
      { id: v4(), name: '--color-gray1', light: '#F3F4F6', dark: '#2C345A' },
      { id: v4(), name: '--color-light', light: '#FFFFFF', dark: '#141D48' },
    ],
    fonts: [
      { id: v4(), name: '--font-primary', value: 'Nunito' },
      { id: v4(), name: '--font-secondary', value: 'Nunito' },
      { id: v4(), name: '--font-tertiary', value: 'Lora' },
      { id: v4(), name: '--font-quaternary', value: 'Oswald' },
    ],
  },
  {
    id: v4(),
    title: 'Style 6',
    colors: [
      { id: v4(), name: '--color-primary', light: '#FF0C9B', dark: '#FF0C9B' },
      { id: v4(), name: '--color-secondary', light: '#24DBF4', dark: '#24DBF4' },
      { id: v4(), name: '--color-tertiary', light: '#8667DE', dark: '#8667DE' },
      { id: v4(), name: '--color-quaternary', light: '#06E82A', dark: '#06E82A' },
      { id: v4(), name: '--color-dark', light: '#220C19', dark: '#ffffff' },
      { id: v4(), name: '--color-gray9', light: '#493842', dark: '#f0edef' },
      { id: v4(), name: '--color-gray8', light: '#493842', dark: '#e7e1e4' },
      { id: v4(), name: '--color-gray7', light: '#5C4C55', dark: '#d8d2d6' },
      { id: v4(), name: '--color-gray6', light: '#70636B', dark: '#afa6ab' },
      { id: v4(), name: '--color-gray5', light: '#83787E', dark: '#83787E' },
      { id: v4(), name: '--color-gray4', light: '#afa6ab', dark: '#70636B' },
      { id: v4(), name: '--color-gray3', light: '#d8d2d6', dark: '#5C4C55' },
      { id: v4(), name: '--color-gray2', light: '#e7e1e4', dark: '#493842' },
      { id: v4(), name: '--color-gray1', light: '#f0edef', dark: '#493842' },
      { id: v4(), name: '--color-light', light: '#ffffff', dark: '#220C19' },
    ],
    fonts: [
      { id: v4(), name: '--font-primary', value: 'Questrial' },
      { id: v4(), name: '--font-secondary', value: 'Questrial' },
      { id: v4(), name: '--font-tertiary', value: 'Alice' },
      { id: v4(), name: '--font-quaternary', value: 'Indie Flower' },
    ],
  },
  {
    id: v4(),
    title: 'Style 7',
    colors: [
      { id: v4(), name: '--color-primary', light: '#5A345C', dark: '#5A345C' },
      { id: v4(), name: '--color-secondary', light: '#974747', dark: '#974747' },
      { id: v4(), name: '--color-tertiary', light: '#CE999D', dark: '#CE999D' },
      { id: v4(), name: '--color-quaternary', light: '#3554BB', dark: '#3554BB' },
      { id: v4(), name: '--color-dark', light: '#302641', dark: '#ffffff' },
      { id: v4(), name: '--color-gray9', light: '#3C2E5C', dark: '#efeff1' },
      { id: v4(), name: '--color-gray8', light: '#4F426B', dark: '#e3e1e7' },
      { id: v4(), name: '--color-gray7', light: '#61557A', dark: '#d3d0da' },
      { id: v4(), name: '--color-gray6', light: '#867E99', dark: '#b7b3c0' },
      { id: v4(), name: '--color-gray5', light: '#9993A8', dark: '#9993A8' },
      { id: v4(), name: '--color-gray4', light: '#b7b3c0', dark: '#867E99' },
      { id: v4(), name: '--color-gray3', light: '#d3d0da', dark: '#61557A' },
      { id: v4(), name: '--color-gray2', light: '#e3e1e7', dark: '#4F426B' },
      { id: v4(), name: '--color-gray1', light: '#efeff1', dark: '#3C2E5C' },
      { id: v4(), name: '--color-light', light: '#ffffff', dark: '#302641' },
    ],
    fonts: [
      { id: v4(), name: '--font-primary', value: 'Inter' },
      { id: v4(), name: '--font-secondary', value: 'Prata' },
      { id: v4(), name: '--font-tertiary', value: 'Playfair Display' },
      { id: v4(), name: '--font-quaternary', value: 'Caveat' },
    ],
  },
  {
    id: v4(),
    title: 'Strawberry',
    colors: [
      { id: v4(), name: '--color-primary', light: '#F14666', dark: '#F14666' },
      { id: v4(), name: '--color-secondary', light: '#EE8980', dark: '#EE8980' },
      { id: v4(), name: '--color-tertiary', light: '#FFCDAA', dark: '#FFCDAA' },
      { id: v4(), name: '--color-quaternary', light: '#9CB898', dark: '#9CB898' },
      { id: v4(), name: '--color-dark', light: '#15092F', dark: '#ffffff' },
      { id: v4(), name: '--color-gray9', light: '#241448', dark: '#F3F1F5' },
      { id: v4(), name: '--color-gray8', light: '#39285C', dark: '#E9E7EC' },
      { id: v4(), name: '--color-gray7', light: '#4D4069', dark: '#D2CEDA' },
      { id: v4(), name: '--color-gray6', light: '#6C6183', dark: '#B7B2C3' },
      { id: v4(), name: '--color-gray5', light: '#8F87A1', dark: '#8F87A1' },
      { id: v4(), name: '--color-gray4', light: '#B7B2C3', dark: '#6C6183' },
      { id: v4(), name: '--color-gray3', light: '#D2CEDA', dark: '#4D4069' },
      { id: v4(), name: '--color-gray2', light: '#E9E7EC', dark: '#39285C' },
      { id: v4(), name: '--color-gray1', light: '#F3F1F5', dark: '#241448' },
      { id: v4(), name: '--color-light', light: '#ffffff', dark: '#15092F' },
    ],
    fonts: [
      { id: v4(), name: '--font-primary', value: 'Open Sans' },
      { id: v4(), name: '--font-secondary', value: 'Mali' },
      { id: v4(), name: '--font-tertiary', value: 'Sacramento' },
      { id: v4(), name: '--font-quaternary', value: 'Roboto Mono' },
    ],
  },
  {
    id: v4(),
    title: 'Velvet',
    colors: [
      { id: v4(), name: '--color-primary', light: '#FF7BBF', dark: '#FF7BBF' },
      { id: v4(), name: '--color-secondary', light: '#50409A', dark: '#50409A' },
      { id: v4(), name: '--color-tertiary', light: '#964EC2', dark: '#964EC2' },
      { id: v4(), name: '--color-quaternary', light: '#78AC77', dark: '#78AC77' },
      { id: v4(), name: '--color-dark', light: '#080D32', dark: '#ffffff' },
      { id: v4(), name: '--color-gray9', light: '#141B47', dark: '#F3F4F6' },
      { id: v4(), name: '--color-gray8', light: '#282F5B', dark: '#E1E2E8' },
      { id: v4(), name: '--color-gray7', light: '#444863', dark: '#CBCDD8' },
      { id: v4(), name: '--color-gray6', light: '#626680', dark: '#ADAFBD' },
      { id: v4(), name: '--color-gray5', light: '#85889C', dark: '#85889C' },
      { id: v4(), name: '--color-gray4', light: '#ADAFBD', dark: '#626680' },
      { id: v4(), name: '--color-gray3', light: '#CBCDD8', dark: '#444863' },
      { id: v4(), name: '--color-gray2', light: '#E1E2E8', dark: '#282F5B' },
      { id: v4(), name: '--color-gray1', light: '#F3F4F6', dark: '#141B47' },
      { id: v4(), name: '--color-light', light: '#ffffff', dark: '#080D32' },
    ],
    fonts: [
      { id: v4(), name: '--font-primary', value: 'Inter' },
      { id: v4(), name: '--font-secondary', value: 'Inter' },
      { id: v4(), name: '--font-tertiary', value: 'Playfair Display' },
      { id: v4(), name: '--font-quaternary', value: 'Roboto Mono' },
    ],
  },
]);

async function getPresetStyles() {
  await delay(500);
  return presetState.getState();
}

async function getPresetStyle(id: string) {
  await delay(500);
  return presetState.getState().filter(item => item.id === id)[0];
}

export { getPresetStyles, getPresetStyle };
