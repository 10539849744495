import { Option } from 'components/SelectAntd';
import { CssFontVariable } from 'types/PresetStyles';

export type ShopifyFontsType =
  | 'mono'
  | 'sans-serif'
  | 'serif'
  | 'abel_n4'
  | 'abril_fatface_n4'
  | 'agmena_n4'
  | 'akko_n4'
  | 'alegreya_n4'
  | 'alegreya_sans_n4'
  | 'alfie_n4'
  | 'americana_n4'
  | 'amiri_n4'
  | 'anonymous_pro_n4'
  | 'antique_olive_n4'
  | 'arapey_n4'
  | 'archivo_n4'
  | 'archivo_narrow_n4'
  | 'arimo_n4'
  | 'armata_n4'
  | 'arvo_n4'
  | 'asap_n4'
  | 'assistant_n4'
  | 'asul_n4'
  | 'avenir_next_n4'
  | 'avenir_next_rounded_n4'
  | 'azbuka_n4'
  | 'basic_commercial_n4'
  | 'basic_commercial_soft_rounded_n4'
  | 'baskerville_no_2_n4'
  | 'bauer_bodoni_n4'
  | 'beefcakes_n4'
  | 'bembo_book_n4'
  | 'bernhard_modern_n4'
  | 'bio_rhyme_n4'
  | 'bitter_n4'
  | 'bodoni_poster_n9'
  | 'burlingame_n4'
  | 'cabin_n4'
  | 'cachet_n4'
  | 'cardamon_n4'
  | 'cardo_n4'
  | 'carter_sans_n4'
  | 'caslon_bold_n4'
  | 'caslon_old_face_n4'
  | 'catamaran_n4'
  | 'centaur_n4'
  | 'century_gothic_n4'
  | 'chivo_n4'
  | 'chong_modern_n4'
  | 'claire_news_n3'
  | 'cooper_bt_n5'
  | 'cormorant_n4'
  | 'courier_new_n4'
  | 'crimson_text_n4'
  | 'din_neuzeit_grotesk_n3'
  | 'din_next_n4'
  | 'din_next_slab_n4'
  | 'dm_sans_n4'
  | 'daytona_n4'
  | 'domine_n4'
  | 'dosis_n4'
  | 'eczar_n4'
  | 'electra_n4'
  | 'eurostile_next_n4'
  | 'ff_meta_n4'
  | 'ff_meta_serif_n4'
  | 'ff_tisa_n4'
  | 'ff_tisa_sans_n4'
  | 'ff_unit_n4'
  | 'ff_unit_rounded_n4'
  | 'ff_unit_slab_n4'
  | 'fs_kim_n4'
  | 'fs_koopman_n4'
  | 'fs_siena_n4'
  | 'fette_gotisch_n4'
  | 'fira_sans_n4'
  | 'fjalla_one_n4'
  | 'friz_quadrata_n4'
  | 'frutiger_serif_n4'
  | 'futura_n4'
  | 'futura_black_n4'
  | 'garamond_n4'
  | 'geometric_415_n4'
  | 'georgia_pro_n4'
  | 'gill_sans_nova_n4'
  | 'glegoo_n4'
  | 'goudy_old_style_n4'
  | 'harmonia_sans_n4'
  | 'helvetica_n4'
  | 'hope_sans_n4'
  | 'humanist_521_n4'
  | 'ibm_plex_sans_n4'
  | 'itc_avant_garde_gothic_n4'
  | 'itc_benguiat_n4'
  | 'itc_berkeley_old_style_n4'
  | 'itc_bodoni_seventytwo_n4'
  | 'itc_bodoni_twelve_n4'
  | 'itc_caslon_no_224_n4'
  | 'itc_charter_n4'
  | 'itc_cheltenham_n4'
  | 'itc_clearface_n4'
  | 'itc_conduit_n4'
  | 'itc_esprit_n4'
  | 'itc_founders_caslon_n4'
  | 'itc_franklin_gothic_n4'
  | 'itc_galliard_n4'
  | 'itc_gamma_n4'
  | 'itc_goudy_sans_n4'
  | 'itc_johnston_n5'
  | 'itc_mendoza_roman_n4'
  | 'itc_modern_no_216_n5'
  | 'itc_new_baskerville_n4'
  | 'itc_new_esprit_n4'
  | 'itc_new_veljovic_n4'
  | 'itc_novarese_n4'
  | 'itc_officina_sans_n4'
  | 'itc_officina_serif_n4'
  | 'itc_stepp_n4'
  | 'itc_stone_humanist_n5'
  | 'itc_stone_informal_n4'
  | 'itc_stone_sans_ii_n4'
  | 'itc_stone_serif_n4'
  | 'itc_tapioca_n5'
  | 'inconsolata_n4'
  | 'inknut_antiqua_n4'
  | 'inter_n4'
  | 'joanna_nova_n4'
  | 'joanna_sans_nova_n4'
  | 'josefin_sans_n4'
  | 'josefin_slab_n4'
  | 'kairos_n4'
  | 'kalam_n4'
  | 'karla_n4'
  | 'kreon_n4'
  | 'lato_n4'
  | 'laurentian_n4'
  | 'libelle_n4'
  | 'libre_baskerville_n4'
  | 'libre_franklin_n4'
  | 'linotype_didot_n4'
  | 'linotype_gianotten_n4'
  | 'linotype_really_n5'
  | 'linotype_syntax_serif_n4'
  | 'lobster_n4'
  | 'lobster_two_n4'
  | 'lora_n4'
  | 'lucia_n4'
  | 'lucida_grande_n4'
  | 'luthersche_fraktur_n4'
  | 'madera_n4'
  | 'malabar_n4'
  | 'mariposa_sans_n4'
  | 'maven_pro_n4'
  | 'megrim_n4'
  | 'melior_n4'
  | 'memphis_n5'
  | 'memphis_soft_rounded_n5'
  | 'mentor_sans_n4'
  | 'merriweather_sans_n4'
  | 'metro_nova_n4'
  | 'modern_no_20_n4'
  | 'monaco_n4'
  | 'monotype_baskerville_n4'
  | 'monotype_bodoni_n4'
  | 'monotype_century_old_style_n5'
  | 'monotype_goudy_n4'
  | 'monotype_goudy_modern_n4'
  | 'monotype_italian_old_style_n4'
  | 'monotype_new_clarendon_n5'
  | 'monotype_news_gothic_n4'
  | 'monotype_sabon_n4'
  | 'montserrat_n4'
  | 'mouse_memoirs_n4'
  | 'muli_n4'
  | 'mundo_sans_n4'
  | 'neo_sans_n4'
  | 'neue_aachen_n4'
  | 'neue_frutiger_1450_n4'
  | 'neue_haas_unica_n4'
  | 'neue_plak_n4'
  | 'neue_swift_n4'
  | 'neuton_n4'
  | 'neuzeit_office_n4'
  | 'neuzeit_office_soft_rounded_n4'
  | 'neuzeit_s_n4'
  | 'new_century_schoolbook_n4'
  | 'news_702_n4'
  | 'news_705_n4'
  | 'news_cycle_n4'
  | 'news_gothic_no_2_n4'
  | 'news_plantin_n4'
  | 'nobile_n4'
  | 'noticia_text_n4'
  | 'noto_serif_n4'
  | 'nunito_n4'
  | 'nunito_sans_n4'
  | 'old_standard_tt_n4'
  | 'open_sans_n4'
  | 'open_sans_condensed_n3'
  | 'optima_nova_n4'
  | 'oswald_n4'
  | 'ovo_n4'
  | 'oxygen_n4'
  | 'pmn_caecilia_n4'
  | 'pt_mono_n4'
  | 'pt_sans_n4'
  | 'pt_sans_narrow_n4'
  | 'pt_serif_n4'
  | 'pacifico_n4'
  | 'palatino_n4'
  | 'parma_n4'
  | 'perpetua_n4'
  | 'plantin_n4'
  | 'playball_n4'
  | 'playfair_display_n4'
  | 'poppins_n4'
  | 'prata_n4'
  | 'prompt_n4'
  | 'proza_libre_n4'
  | 'quantico_n4'
  | 'quattrocento_n4'
  | 'quattrocento_sans_n4'
  | 'questrial_n4'
  | 'quicksand_n4'
  | 'quire_sans_n4'
  | 'rajdhani_n4'
  | 'raleway_n4'
  | 'really_no_2_n4'
  | 'righteous_n4'
  | 'roboto_n4'
  | 'roboto_condensed_n4'
  | 'roboto_mono_n4'
  | 'roboto_slab_n4'
  | 'rockwell_n4'
  | 'rubik_n4'
  | 'sabon_next_n4'
  | 'sackers_square_gothic_n4'
  | 'sagrantino_n4'
  | 'scene_n4'
  | 'scherzo_n4'
  | 'shadows_into_light_n4'
  | 'slabo_13px_n4'
  | 'slate_n4'
  | 'smooch_n4'
  | 'soho_n4'
  | 'soho_gothic_n4'
  | 'source_code_pro_n4'
  | 'source_sans_pro_n4'
  | 'space_mono_n4'
  | 'stempel_schneidler_n4'
  | 'swiss_721_n4'
  | 'swiss_721_rounded_n7'
  | 'syne_n4'
  | 'tenor_sans_n4'
  | 'tiemann_n4'
  | 'times_new_roman_n4'
  | 'tinos_n4'
  | 'titillium_web_n4'
  | 'trade_gothic_n4'
  | 'trade_gothic_next_n4'
  | 'trebuchet_ms_n4'
  | 'twentieth_century_n4'
  | 'ubuntu_n4'
  | 'unica_one_n4'
  | 'univers_next_n4'
  | 'univers_next_typewriter_n4'
  | 'unna_n4'
  | 'vala_n4'
  | 'varela_n4'
  | 'varela_round_n4'
  | 'verdana_pro_n4'
  | 'vidaloka_n4'
  | 'volkhov_n4'
  | 'vollkorn_n4'
  | 'waza_n4'
  | 'wola_n4'
  | 'work_sans_n4'
  | 'ysobel_n4'
  | 'zurich_n4'
  | 'zurich_extended_n4';

/**
 * @desciption
 * label = name của font
 * value = handle của font
 *
 * Vào fodler veda/scripts/getShopifyFontOptions để xem cách lấy data
 *  */
export const shopifyFonts: Option[] = [
  {
    label: 'Mono',
    value: 'mono',
    // Các font system được fix cứng font-family bởi shopify
    family:
      '"SF Mono", Menlo, Consolas, Monaco, "Liberation Mono", "Lucida Console", monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; font-style: normal; font-weight: 400',
    shopifyFiles: [],
  },
  {
    label: 'Sans-serif',
    value: 'sans-serif',
    // Các font system được fix cứng font-family bởi shopify
    family:
      '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
    shopifyFiles: [],
  },
  {
    label: 'Serif',
    value: 'serif',
    // Các font system được fix cứng font-family bởi shopify
    family:
      '"New York", Iowan Old Style, Apple Garamond, Baskerville, Times New Roman, Droid Serif, Times, Source Serif Pro, serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
    shopifyFiles: [],
  },
  {
    label: 'Abel',
    value: 'abel_n4',
    family: 'Abel',
    shopifyFiles: ['VedaFont[Abel]+abel_n4+normal+400.woff2'],
  },
  {
    label: 'Abril Fatface',
    value: 'abril_fatface_n4',
    family: 'Abril Fatface',
    shopifyFiles: ['VedaFont[Abril Fatface]+abril_fatface_n4+normal+400.woff2'],
  },
  {
    label: 'Agmena',
    value: 'agmena_n4',
    family: 'Agmena',
    shopifyFiles: [
      'VedaFont[Agmena]+agmena_n3+normal+300.woff2',
      'VedaFont[Agmena]+agmena_i3+italic+300.woff2',
      'VedaFont[Agmena]+agmena_n4+normal+400.woff2',
      'VedaFont[Agmena]+agmena_i4+italic+400.woff2',
      'VedaFont[Agmena]+agmena_n6+normal+600.woff2',
      'VedaFont[Agmena]+agmena_i6+italic+600.woff2',
      'VedaFont[Agmena]+agmena_n7+normal+700.woff2',
      'VedaFont[Agmena]+agmena_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Akko',
    value: 'akko_n4',
    family: 'Akko',
    shopifyFiles: [
      'VedaFont[Akko]+akko_n2+normal+200.woff2',
      'VedaFont[Akko]+akko_i2+italic+200.woff2',
      'VedaFont[Akko]+akko_n3+normal+300.woff2',
      'VedaFont[Akko]+akko_i3+italic+300.woff2',
      'VedaFont[Akko]+akko_n4+normal+400.woff2',
      'VedaFont[Akko]+akko_i4+italic+400.woff2',
      'VedaFont[Akko]+akko_n5+normal+500.woff2',
      'VedaFont[Akko]+akko_i5+italic+500.woff2',
      'VedaFont[Akko]+akko_n7+normal+700.woff2',
      'VedaFont[Akko]+akko_i7+italic+700.woff2',
      'VedaFont[Akko]+akko_n9+normal+900.woff2',
      'VedaFont[Akko]+akko_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Alegreya',
    value: 'alegreya_n4',
    family: 'Alegreya',
    shopifyFiles: [
      'VedaFont[Alegreya]+alegreya_n4+normal+400.woff2',
      'VedaFont[Alegreya]+alegreya_i4+italic+400.woff2',
      'VedaFont[Alegreya]+alegreya_n5+normal+500.woff2',
      'VedaFont[Alegreya]+alegreya_i5+italic+500.woff2',
      'VedaFont[Alegreya]+alegreya_n7+normal+700.woff2',
      'VedaFont[Alegreya]+alegreya_i7+italic+700.woff2',
      'VedaFont[Alegreya]+alegreya_n8+normal+800.woff2',
      'VedaFont[Alegreya]+alegreya_i8+italic+800.woff2',
      'VedaFont[Alegreya]+alegreya_n9+normal+900.woff2',
      'VedaFont[Alegreya]+alegreya_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Alegreya Sans',
    value: 'alegreya_sans_n4',
    family: 'Alegreya Sans',
    shopifyFiles: [
      'VedaFont[Alegreya Sans]+alegreya_sans_n1+normal+100.woff2',
      'VedaFont[Alegreya Sans]+alegreya_sans_i1+italic+100.woff2',
      'VedaFont[Alegreya Sans]+alegreya_sans_n3+normal+300.woff2',
      'VedaFont[Alegreya Sans]+alegreya_sans_i3+italic+300.woff2',
      'VedaFont[Alegreya Sans]+alegreya_sans_n4+normal+400.woff2',
      'VedaFont[Alegreya Sans]+alegreya_sans_i4+italic+400.woff2',
      'VedaFont[Alegreya Sans]+alegreya_sans_n5+normal+500.woff2',
      'VedaFont[Alegreya Sans]+alegreya_sans_i5+italic+500.woff2',
      'VedaFont[Alegreya Sans]+alegreya_sans_n7+normal+700.woff2',
      'VedaFont[Alegreya Sans]+alegreya_sans_i7+italic+700.woff2',
      'VedaFont[Alegreya Sans]+alegreya_sans_n8+normal+800.woff2',
      'VedaFont[Alegreya Sans]+alegreya_sans_i8+italic+800.woff2',
      'VedaFont[Alegreya Sans]+alegreya_sans_n9+normal+900.woff2',
      'VedaFont[Alegreya Sans]+alegreya_sans_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Alfie',
    value: 'alfie_n4',
    family: 'Alfie',
    shopifyFiles: ['VedaFont[Alfie]+alfie_n4+normal+400.woff2'],
  },
  {
    label: 'Americana',
    value: 'americana_n4',
    family: 'Americana',
    shopifyFiles: [
      'VedaFont[Americana]+americana_n4+normal+400.woff2',
      'VedaFont[Americana]+americana_i4+italic+400.woff2',
      'VedaFont[Americana]+americana_n7+normal+700.woff2',
      'VedaFont[Americana]+americana_n8+normal+800.woff2',
    ],
  },
  {
    label: 'Amiri',
    value: 'amiri_n4',
    family: 'Amiri',
    shopifyFiles: [
      'VedaFont[Amiri]+amiri_n4+normal+400.woff2',
      'VedaFont[Amiri]+amiri_i4+italic+400.woff2',
      'VedaFont[Amiri]+amiri_n7+normal+700.woff2',
      'VedaFont[Amiri]+amiri_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Anonymous Pro',
    value: 'anonymous_pro_n4',
    family: 'Anonymous Pro',
    shopifyFiles: [
      'VedaFont[Anonymous Pro]+anonymous_pro_n4+normal+400.woff2',
      'VedaFont[Anonymous Pro]+anonymous_pro_i4+italic+400.woff2',
      'VedaFont[Anonymous Pro]+anonymous_pro_n7+normal+700.woff2',
      'VedaFont[Anonymous Pro]+anonymous_pro_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Antique Olive',
    value: 'antique_olive_n4',
    family: 'Antique Olive',
    shopifyFiles: [
      'VedaFont[Antique Olive]+antique_olive_n3+normal+300.woff2',
      'VedaFont[Antique Olive]+antique_olive_n4+normal+400.woff2',
      'VedaFont[Antique Olive]+antique_olive_i4+italic+400.woff2',
      'VedaFont[Antique Olive]+antique_olive_n7+normal+700.woff2',
      'VedaFont[Antique Olive]+antique_olive_n9+normal+900.woff2',
    ],
  },
  {
    label: 'Arapey',
    value: 'arapey_n4',
    family: 'Arapey',
    shopifyFiles: ['VedaFont[Arapey]+arapey_n4+normal+400.woff2', 'VedaFont[Arapey]+arapey_i4+italic+400.woff2'],
  },
  {
    label: 'Archivo',
    value: 'archivo_n4',
    family: 'Archivo',
    shopifyFiles: [
      'VedaFont[Archivo]+archivo_n4+normal+400.woff2',
      'VedaFont[Archivo]+archivo_i4+italic+400.woff2',
      'VedaFont[Archivo]+archivo_n5+normal+500.woff2',
      'VedaFont[Archivo]+archivo_i5+italic+500.woff2',
      'VedaFont[Archivo]+archivo_n6+normal+600.woff2',
      'VedaFont[Archivo]+archivo_i6+italic+600.woff2',
      'VedaFont[Archivo]+archivo_n7+normal+700.woff2',
      'VedaFont[Archivo]+archivo_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Archivo Narrow',
    value: 'archivo_narrow_n4',
    family: 'Archivo Narrow',
    shopifyFiles: [
      'VedaFont[Archivo Narrow]+archivo_narrow_n4+normal+400.woff2',
      'VedaFont[Archivo Narrow]+archivo_narrow_i4+italic+400.woff2',
      'VedaFont[Archivo Narrow]+archivo_narrow_n5+normal+500.woff2',
      'VedaFont[Archivo Narrow]+archivo_narrow_i5+italic+500.woff2',
      'VedaFont[Archivo Narrow]+archivo_narrow_n6+normal+600.woff2',
      'VedaFont[Archivo Narrow]+archivo_narrow_i6+italic+600.woff2',
      'VedaFont[Archivo Narrow]+archivo_narrow_n7+normal+700.woff2',
      'VedaFont[Archivo Narrow]+archivo_narrow_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Arimo',
    value: 'arimo_n4',
    family: 'Arimo',
    shopifyFiles: [
      'VedaFont[Arimo]+arimo_n4+normal+400.woff2',
      'VedaFont[Arimo]+arimo_i4+italic+400.woff2',
      'VedaFont[Arimo]+arimo_n7+normal+700.woff2',
      'VedaFont[Arimo]+arimo_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Armata',
    value: 'armata_n4',
    family: 'Armata',
    shopifyFiles: ['VedaFont[Armata]+armata_n4+normal+400.woff2'],
  },
  {
    label: 'Arvo',
    value: 'arvo_n4',
    family: 'Arvo',
    shopifyFiles: [
      'VedaFont[Arvo]+arvo_n4+normal+400.woff2',
      'VedaFont[Arvo]+arvo_i4+italic+400.woff2',
      'VedaFont[Arvo]+arvo_n7+normal+700.woff2',
      'VedaFont[Arvo]+arvo_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Asap',
    value: 'asap_n4',
    family: 'Asap',
    shopifyFiles: [
      'VedaFont[Asap]+asap_n4+normal+400.woff2',
      'VedaFont[Asap]+asap_i4+italic+400.woff2',
      'VedaFont[Asap]+asap_n5+normal+500.woff2',
      'VedaFont[Asap]+asap_i5+italic+500.woff2',
      'VedaFont[Asap]+asap_n6+normal+600.woff2',
      'VedaFont[Asap]+asap_i6+italic+600.woff2',
      'VedaFont[Asap]+asap_n7+normal+700.woff2',
      'VedaFont[Asap]+asap_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Assistant',
    value: 'assistant_n4',
    family: 'Assistant',
    shopifyFiles: [
      'VedaFont[Assistant]+assistant_n2+normal+200.woff2',
      'VedaFont[Assistant]+assistant_n3+normal+300.woff2',
      'VedaFont[Assistant]+assistant_n4+normal+400.woff2',
      'VedaFont[Assistant]+assistant_n6+normal+600.woff2',
      'VedaFont[Assistant]+assistant_n7+normal+700.woff2',
      'VedaFont[Assistant]+assistant_n8+normal+800.woff2',
    ],
  },
  {
    label: 'Asul',
    value: 'asul_n4',
    family: 'Asul',
    shopifyFiles: ['VedaFont[Asul]+asul_n4+normal+400.woff2', 'VedaFont[Asul]+asul_n7+normal+700.woff2'],
  },
  {
    label: 'Avenir Next',
    value: 'avenir_next_n4',
    family: 'Avenir Next',
    shopifyFiles: [
      'VedaFont[Avenir Next]+avenir_next_n1+normal+100.woff2',
      'VedaFont[Avenir Next]+avenir_next_i1+italic+100.woff2',
      'VedaFont[Avenir Next]+avenir_next_n2+normal+200.woff2',
      'VedaFont[Avenir Next]+avenir_next_i2+italic+200.woff2',
      'VedaFont[Avenir Next]+avenir_next_n3+normal+300.woff2',
      'VedaFont[Avenir Next]+avenir_next_i3+italic+300.woff2',
      'VedaFont[Avenir Next]+avenir_next_n4+normal+400.woff2',
      'VedaFont[Avenir Next]+avenir_next_i4+italic+400.woff2',
      'VedaFont[Avenir Next]+avenir_next_n5+normal+500.woff2',
      'VedaFont[Avenir Next]+avenir_next_i5+italic+500.woff2',
      'VedaFont[Avenir Next]+avenir_next_n6+normal+600.woff2',
      'VedaFont[Avenir Next]+avenir_next_i6+italic+600.woff2',
      'VedaFont[Avenir Next]+avenir_next_n7+normal+700.woff2',
      'VedaFont[Avenir Next]+avenir_next_i7+italic+700.woff2',
      'VedaFont[Avenir Next]+avenir_next_n8+normal+800.woff2',
      'VedaFont[Avenir Next]+avenir_next_i8+italic+800.woff2',
    ],
  },
  {
    label: 'Avenir Next Rounded',
    value: 'avenir_next_rounded_n4',
    family: 'Avenir Next Rounded',
    shopifyFiles: [
      'VedaFont[Avenir Next Rounded]+avenir_next_rounded_n4+normal+400.woff2',
      'VedaFont[Avenir Next Rounded]+avenir_next_rounded_i4+italic+400.woff2',
      'VedaFont[Avenir Next Rounded]+avenir_next_rounded_n5+normal+500.woff2',
      'VedaFont[Avenir Next Rounded]+avenir_next_rounded_i5+italic+500.woff2',
      'VedaFont[Avenir Next Rounded]+avenir_next_rounded_n6+normal+600.woff2',
      'VedaFont[Avenir Next Rounded]+avenir_next_rounded_i6+italic+600.woff2',
      'VedaFont[Avenir Next Rounded]+avenir_next_rounded_n7+normal+700.woff2',
      'VedaFont[Avenir Next Rounded]+avenir_next_rounded_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Azbuka',
    value: 'azbuka_n4',
    family: 'Azbuka',
    shopifyFiles: [
      'VedaFont[Azbuka]+azbuka_n2+normal+200.woff2',
      'VedaFont[Azbuka]+azbuka_n3+normal+300.woff2',
      'VedaFont[Azbuka]+azbuka_i3+italic+300.woff2',
      'VedaFont[Azbuka]+azbuka_n4+normal+400.woff2',
      'VedaFont[Azbuka]+azbuka_i4+italic+400.woff2',
      'VedaFont[Azbuka]+azbuka_n5+normal+500.woff2',
      'VedaFont[Azbuka]+azbuka_i5+italic+500.woff2',
      'VedaFont[Azbuka]+azbuka_n6+normal+600.woff2',
      'VedaFont[Azbuka]+azbuka_i6+italic+600.woff2',
      'VedaFont[Azbuka]+azbuka_n7+normal+700.woff2',
      'VedaFont[Azbuka]+azbuka_i7+italic+700.woff2',
      'VedaFont[Azbuka]+azbuka_n8+normal+800.woff2',
      'VedaFont[Azbuka]+azbuka_n9+normal+900.woff2',
    ],
  },
  {
    label: 'Basic Commercial',
    value: 'basic_commercial_n4',
    family: 'Basic Commercial',
    shopifyFiles: [
      'VedaFont[Basic Commercial]+basic_commercial_n3+normal+300.woff2',
      'VedaFont[Basic Commercial]+basic_commercial_i3+italic+300.woff2',
      'VedaFont[Basic Commercial]+basic_commercial_n4+normal+400.woff2',
      'VedaFont[Basic Commercial]+basic_commercial_i4+italic+400.woff2',
      'VedaFont[Basic Commercial]+basic_commercial_n7+normal+700.woff2',
      'VedaFont[Basic Commercial]+basic_commercial_i7+italic+700.woff2',
      'VedaFont[Basic Commercial]+basic_commercial_n8+normal+800.woff2',
      'VedaFont[Basic Commercial]+basic_commercial_i8+italic+800.woff2',
    ],
  },
  {
    label: 'Basic Commercial Soft Rounded',
    value: 'basic_commercial_soft_rounded_n4',
    family: 'Basic Commercial Soft Rounded',
    shopifyFiles: [
      'VedaFont[Basic Commercial Soft Rounded]+basic_commercial_soft_rounded_n4+normal+400.woff2',
      'VedaFont[Basic Commercial Soft Rounded]+basic_commercial_soft_rounded_n7+normal+700.woff2',
      'VedaFont[Basic Commercial Soft Rounded]+basic_commercial_soft_rounded_n9+normal+900.woff2',
    ],
  },
  {
    label: 'Baskerville No 2',
    value: 'baskerville_no_2_n4',
    family: 'Baskerville No 2',
    shopifyFiles: [
      'VedaFont[Baskerville No 2]+baskerville_no_2_n4+normal+400.woff2',
      'VedaFont[Baskerville No 2]+baskerville_no_2_i4+italic+400.woff2',
      'VedaFont[Baskerville No 2]+baskerville_no_2_n7+normal+700.woff2',
      'VedaFont[Baskerville No 2]+baskerville_no_2_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Bauer Bodoni',
    value: 'bauer_bodoni_n4',
    family: 'Bauer Bodoni',
    shopifyFiles: [
      'VedaFont[Bauer Bodoni]+bauer_bodoni_n4+normal+400.woff2',
      'VedaFont[Bauer Bodoni]+bauer_bodoni_i4+italic+400.woff2',
      'VedaFont[Bauer Bodoni]+bauer_bodoni_n7+normal+700.woff2',
      'VedaFont[Bauer Bodoni]+bauer_bodoni_i7+italic+700.woff2',
      'VedaFont[Bauer Bodoni]+bauer_bodoni_n9+normal+900.woff2',
      'VedaFont[Bauer Bodoni]+bauer_bodoni_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Beefcakes',
    value: 'beefcakes_n4',
    family: 'Beefcakes',
    shopifyFiles: ['VedaFont[Beefcakes]+beefcakes_n4+normal+400.woff2', 'VedaFont[Beefcakes]+beefcakes_i4+italic+400.woff2'],
  },
  {
    label: 'Bembo Book',
    value: 'bembo_book_n4',
    family: 'Bembo Book',
    shopifyFiles: [
      'VedaFont[Bembo Book]+bembo_book_n4+normal+400.woff2',
      'VedaFont[Bembo Book]+bembo_book_i4+italic+400.woff2',
      'VedaFont[Bembo Book]+bembo_book_n7+normal+700.woff2',
      'VedaFont[Bembo Book]+bembo_book_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Bernhard Modern',
    value: 'bernhard_modern_n4',
    family: 'Bernhard Modern',
    shopifyFiles: [
      'VedaFont[Bernhard Modern]+bernhard_modern_n4+normal+400.woff2',
      'VedaFont[Bernhard Modern]+bernhard_modern_i4+italic+400.woff2',
      'VedaFont[Bernhard Modern]+bernhard_modern_n7+normal+700.woff2',
      'VedaFont[Bernhard Modern]+bernhard_modern_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Bio Rhyme',
    value: 'bio_rhyme_n4',
    family: 'Bio Rhyme',
    shopifyFiles: [
      'VedaFont[Bio Rhyme]+bio_rhyme_n2+normal+200.woff2',
      'VedaFont[Bio Rhyme]+bio_rhyme_n3+normal+300.woff2',
      'VedaFont[Bio Rhyme]+bio_rhyme_n4+normal+400.woff2',
      'VedaFont[Bio Rhyme]+bio_rhyme_n7+normal+700.woff2',
      'VedaFont[Bio Rhyme]+bio_rhyme_n8+normal+800.woff2',
    ],
  },
  {
    label: 'Bitter',
    value: 'bitter_n4',
    family: 'Bitter',
    shopifyFiles: [
      'VedaFont[Bitter]+bitter_n4+normal+400.woff2',
      'VedaFont[Bitter]+bitter_i4+italic+400.woff2',
      'VedaFont[Bitter]+bitter_n7+normal+700.woff2',
    ],
  },
  {
    label: 'Bodoni Poster',
    value: 'bodoni_poster_n9',
    family: 'Bodoni Poster',
    shopifyFiles: ['VedaFont[Bodoni Poster]+bodoni_poster_n9+normal+900.woff2', 'VedaFont[Bodoni Poster]+bodoni_poster_i9+italic+900.woff2'],
  },
  {
    label: 'Burlingame',
    value: 'burlingame_n4',
    family: 'Burlingame',
    shopifyFiles: [
      'VedaFont[Burlingame]+burlingame_n1+normal+100.woff2',
      'VedaFont[Burlingame]+burlingame_i1+italic+100.woff2',
      'VedaFont[Burlingame]+burlingame_n2+normal+200.woff2',
      'VedaFont[Burlingame]+burlingame_i2+italic+200.woff2',
      'VedaFont[Burlingame]+burlingame_n3+normal+300.woff2',
      'VedaFont[Burlingame]+burlingame_i3+italic+300.woff2',
      'VedaFont[Burlingame]+burlingame_n4+normal+400.woff2',
      'VedaFont[Burlingame]+burlingame_i4+italic+400.woff2',
      'VedaFont[Burlingame]+burlingame_n5+normal+500.woff2',
      'VedaFont[Burlingame]+burlingame_i5+italic+500.woff2',
      'VedaFont[Burlingame]+burlingame_n6+normal+600.woff2',
      'VedaFont[Burlingame]+burlingame_i6+italic+600.woff2',
      'VedaFont[Burlingame]+burlingame_n7+normal+700.woff2',
      'VedaFont[Burlingame]+burlingame_i7+italic+700.woff2',
      'VedaFont[Burlingame]+burlingame_n8+normal+800.woff2',
      'VedaFont[Burlingame]+burlingame_i8+italic+800.woff2',
      'VedaFont[Burlingame]+burlingame_n9+normal+900.woff2',
      'VedaFont[Burlingame]+burlingame_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Cabin',
    value: 'cabin_n4',
    family: 'Cabin',
    shopifyFiles: [
      'VedaFont[Cabin]+cabin_n4+normal+400.woff2',
      'VedaFont[Cabin]+cabin_i4+italic+400.woff2',
      'VedaFont[Cabin]+cabin_n5+normal+500.woff2',
      'VedaFont[Cabin]+cabin_i5+italic+500.woff2',
      'VedaFont[Cabin]+cabin_n6+normal+600.woff2',
      'VedaFont[Cabin]+cabin_i6+italic+600.woff2',
      'VedaFont[Cabin]+cabin_n7+normal+700.woff2',
      'VedaFont[Cabin]+cabin_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Cachet',
    value: 'cachet_n4',
    family: 'Cachet',
    shopifyFiles: [
      'VedaFont[Cachet]+cachet_n1+normal+100.woff2',
      'VedaFont[Cachet]+cachet_i1+italic+100.woff2',
      'VedaFont[Cachet]+cachet_n2+normal+200.woff2',
      'VedaFont[Cachet]+cachet_i2+italic+200.woff2',
      'VedaFont[Cachet]+cachet_n3+normal+300.woff2',
      'VedaFont[Cachet]+cachet_i3+italic+300.woff2',
      'VedaFont[Cachet]+cachet_n4+normal+400.woff2',
      'VedaFont[Cachet]+cachet_i4+italic+400.woff2',
      'VedaFont[Cachet]+cachet_n5+normal+500.woff2',
      'VedaFont[Cachet]+cachet_i5+italic+500.woff2',
      'VedaFont[Cachet]+cachet_n6+normal+600.woff2',
      'VedaFont[Cachet]+cachet_i6+italic+600.woff2',
      'VedaFont[Cachet]+cachet_n7+normal+700.woff2',
      'VedaFont[Cachet]+cachet_i7+italic+700.woff2',
      'VedaFont[Cachet]+cachet_n8+normal+800.woff2',
      'VedaFont[Cachet]+cachet_i8+italic+800.woff2',
    ],
  },
  {
    label: 'Cardamon',
    value: 'cardamon_n4',
    family: 'Cardamon',
    shopifyFiles: [
      'VedaFont[Cardamon]+cardamon_n4+normal+400.woff2',
      'VedaFont[Cardamon]+cardamon_i4+italic+400.woff2',
      'VedaFont[Cardamon]+cardamon_n5+normal+500.woff2',
      'VedaFont[Cardamon]+cardamon_i5+italic+500.woff2',
      'VedaFont[Cardamon]+cardamon_n6+normal+600.woff2',
      'VedaFont[Cardamon]+cardamon_i6+italic+600.woff2',
      'VedaFont[Cardamon]+cardamon_n7+normal+700.woff2',
      'VedaFont[Cardamon]+cardamon_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Cardo',
    value: 'cardo_n4',
    family: 'Cardo',
    shopifyFiles: [
      'VedaFont[Cardo]+cardo_n4+normal+400.woff2',
      'VedaFont[Cardo]+cardo_i4+italic+400.woff2',
      'VedaFont[Cardo]+cardo_n7+normal+700.woff2',
    ],
  },
  {
    label: 'Carter Sans',
    value: 'carter_sans_n4',
    family: 'Carter Sans',
    shopifyFiles: [
      'VedaFont[Carter Sans]+carter_sans_n4+normal+400.woff2',
      'VedaFont[Carter Sans]+carter_sans_i4+italic+400.woff2',
      'VedaFont[Carter Sans]+carter_sans_n5+normal+500.woff2',
      'VedaFont[Carter Sans]+carter_sans_i5+italic+500.woff2',
      'VedaFont[Carter Sans]+carter_sans_n6+normal+600.woff2',
      'VedaFont[Carter Sans]+carter_sans_i6+italic+600.woff2',
      'VedaFont[Carter Sans]+carter_sans_n7+normal+700.woff2',
      'VedaFont[Carter Sans]+carter_sans_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Caslon Bold',
    value: 'caslon_bold_n4',
    family: 'Caslon Bold',
    shopifyFiles: ['VedaFont[Caslon Bold]+caslon_bold_n4+normal+400.woff2', 'VedaFont[Caslon Bold]+caslon_bold_i4+italic+400.woff2'],
  },
  {
    label: 'Caslon Old Face',
    value: 'caslon_old_face_n4',
    family: 'Caslon Old Face',
    shopifyFiles: [
      'VedaFont[Caslon Old Face]+caslon_old_face_n4+normal+400.woff2',
      'VedaFont[Caslon Old Face]+caslon_old_face_i4+italic+400.woff2',
      'VedaFont[Caslon Old Face]+caslon_old_face_n7+normal+700.woff2',
    ],
  },
  {
    label: 'Catamaran',
    value: 'catamaran_n4',
    family: 'Catamaran',
    shopifyFiles: [
      'VedaFont[Catamaran]+catamaran_n1+normal+100.woff2',
      'VedaFont[Catamaran]+catamaran_n2+normal+200.woff2',
      'VedaFont[Catamaran]+catamaran_n3+normal+300.woff2',
      'VedaFont[Catamaran]+catamaran_n4+normal+400.woff2',
      'VedaFont[Catamaran]+catamaran_n5+normal+500.woff2',
      'VedaFont[Catamaran]+catamaran_n6+normal+600.woff2',
      'VedaFont[Catamaran]+catamaran_n7+normal+700.woff2',
      'VedaFont[Catamaran]+catamaran_n8+normal+800.woff2',
      'VedaFont[Catamaran]+catamaran_n9+normal+900.woff2',
    ],
  },
  {
    label: 'Centaur',
    value: 'centaur_n4',
    family: 'Centaur',
    shopifyFiles: [
      'VedaFont[Centaur]+centaur_n4+normal+400.woff2',
      'VedaFont[Centaur]+centaur_i4+italic+400.woff2',
      'VedaFont[Centaur]+centaur_n7+normal+700.woff2',
      'VedaFont[Centaur]+centaur_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Century Gothic',
    value: 'century_gothic_n4',
    family: 'Century Gothic',
    shopifyFiles: [
      'VedaFont[Century Gothic]+century_gothic_n4+normal+400.woff2',
      'VedaFont[Century Gothic]+century_gothic_i4+italic+400.woff2',
      'VedaFont[Century Gothic]+century_gothic_n7+normal+700.woff2',
      'VedaFont[Century Gothic]+century_gothic_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Chivo',
    value: 'chivo_n4',
    family: 'Chivo',
    shopifyFiles: [
      'VedaFont[Chivo]+chivo_n3+normal+300.woff2',
      'VedaFont[Chivo]+chivo_i3+italic+300.woff2',
      'VedaFont[Chivo]+chivo_n4+normal+400.woff2',
      'VedaFont[Chivo]+chivo_i4+italic+400.woff2',
      'VedaFont[Chivo]+chivo_n7+normal+700.woff2',
      'VedaFont[Chivo]+chivo_i7+italic+700.woff2',
      'VedaFont[Chivo]+chivo_n9+normal+900.woff2',
      'VedaFont[Chivo]+chivo_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Chong Modern',
    value: 'chong_modern_n4',
    family: 'Chong Modern',
    shopifyFiles: [
      'VedaFont[Chong Modern]+chong_modern_n3+normal+300.woff2',
      'VedaFont[Chong Modern]+chong_modern_i3+italic+300.woff2',
      'VedaFont[Chong Modern]+chong_modern_n4+normal+400.woff2',
      'VedaFont[Chong Modern]+chong_modern_i4+italic+400.woff2',
      'VedaFont[Chong Modern]+chong_modern_n7+normal+700.woff2',
      'VedaFont[Chong Modern]+chong_modern_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Claire News',
    value: 'claire_news_n3',
    family: 'Claire News',
    shopifyFiles: ['VedaFont[Claire News]+claire_news_n3+normal+300.woff2', 'VedaFont[Claire News]+claire_news_n7+normal+700.woff2'],
  },
  {
    label: 'Cooper BT',
    value: 'cooper_bt_n5',
    family: 'Cooper BT',
    shopifyFiles: [
      'VedaFont[Cooper BT]+cooper_bt_n2+normal+200.woff2',
      'VedaFont[Cooper BT]+cooper_bt_i2+italic+200.woff2',
      'VedaFont[Cooper BT]+cooper_bt_n5+normal+500.woff2',
      'VedaFont[Cooper BT]+cooper_bt_i5+italic+500.woff2',
      'VedaFont[Cooper BT]+cooper_bt_n7+normal+700.woff2',
      'VedaFont[Cooper BT]+cooper_bt_i7+italic+700.woff2',
      'VedaFont[Cooper BT]+cooper_bt_n9+normal+900.woff2',
      'VedaFont[Cooper BT]+cooper_bt_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Cormorant',
    value: 'cormorant_n4',
    family: 'Cormorant',
    shopifyFiles: [
      'VedaFont[Cormorant]+cormorant_n3+normal+300.woff2',
      'VedaFont[Cormorant]+cormorant_i3+italic+300.woff2',
      'VedaFont[Cormorant]+cormorant_n4+normal+400.woff2',
      'VedaFont[Cormorant]+cormorant_i4+italic+400.woff2',
      'VedaFont[Cormorant]+cormorant_n5+normal+500.woff2',
      'VedaFont[Cormorant]+cormorant_i5+italic+500.woff2',
      'VedaFont[Cormorant]+cormorant_n6+normal+600.woff2',
      'VedaFont[Cormorant]+cormorant_i6+italic+600.woff2',
      'VedaFont[Cormorant]+cormorant_n7+normal+700.woff2',
      'VedaFont[Cormorant]+cormorant_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Courier New',
    value: 'courier_new_n4',
    family: 'Courier New',
    shopifyFiles: [],
  },
  {
    label: 'Crimson Text',
    value: 'crimson_text_n4',
    family: 'Crimson Text',
    shopifyFiles: [
      'VedaFont[Crimson Text]+crimson_text_n4+normal+400.woff2',
      'VedaFont[Crimson Text]+crimson_text_i4+italic+400.woff2',
      'VedaFont[Crimson Text]+crimson_text_n6+normal+600.woff2',
      'VedaFont[Crimson Text]+crimson_text_i6+italic+600.woff2',
      'VedaFont[Crimson Text]+crimson_text_n7+normal+700.woff2',
      'VedaFont[Crimson Text]+crimson_text_i7+italic+700.woff2',
    ],
  },
  {
    label: 'DIN Neuzeit Grotesk',
    value: 'din_neuzeit_grotesk_n3',
    family: 'DIN Neuzeit Grotesk',
    shopifyFiles: [
      'VedaFont[DIN Neuzeit Grotesk]+din_neuzeit_grotesk_n3+normal+300.woff2',
      'VedaFont[DIN Neuzeit Grotesk]+din_neuzeit_grotesk_n7+normal+700.woff2',
    ],
  },
  {
    label: 'DIN Next',
    value: 'din_next_n4',
    family: 'DIN Next',
    shopifyFiles: [
      'VedaFont[DIN Next]+din_next_n2+normal+200.woff2',
      'VedaFont[DIN Next]+din_next_i2+italic+200.woff2',
      'VedaFont[DIN Next]+din_next_n3+normal+300.woff2',
      'VedaFont[DIN Next]+din_next_i3+italic+300.woff2',
      'VedaFont[DIN Next]+din_next_n4+normal+400.woff2',
      'VedaFont[DIN Next]+din_next_i4+italic+400.woff2',
      'VedaFont[DIN Next]+din_next_n5+normal+500.woff2',
      'VedaFont[DIN Next]+din_next_i5+italic+500.woff2',
      'VedaFont[DIN Next]+din_next_n7+normal+700.woff2',
      'VedaFont[DIN Next]+din_next_i7+italic+700.woff2',
      'VedaFont[DIN Next]+din_next_n8+normal+800.woff2',
      'VedaFont[DIN Next]+din_next_i8+italic+800.woff2',
      'VedaFont[DIN Next]+din_next_n9+normal+900.woff2',
      'VedaFont[DIN Next]+din_next_i9+italic+900.woff2',
    ],
  },
  {
    label: 'DIN Next Slab',
    value: 'din_next_slab_n4',
    family: 'DIN Next Slab',
    shopifyFiles: [
      'VedaFont[DIN Next Slab]+din_next_slab_n2+normal+200.woff2',
      'VedaFont[DIN Next Slab]+din_next_slab_i2+italic+200.woff2',
      'VedaFont[DIN Next Slab]+din_next_slab_n3+normal+300.woff2',
      'VedaFont[DIN Next Slab]+din_next_slab_i3+italic+300.woff2',
      'VedaFont[DIN Next Slab]+din_next_slab_n4+normal+400.woff2',
      'VedaFont[DIN Next Slab]+din_next_slab_i4+italic+400.woff2',
      'VedaFont[DIN Next Slab]+din_next_slab_n5+normal+500.woff2',
      'VedaFont[DIN Next Slab]+din_next_slab_i5+italic+500.woff2',
      'VedaFont[DIN Next Slab]+din_next_slab_n7+normal+700.woff2',
      'VedaFont[DIN Next Slab]+din_next_slab_i7+italic+700.woff2',
      'VedaFont[DIN Next Slab]+din_next_slab_n8+normal+800.woff2',
      'VedaFont[DIN Next Slab]+din_next_slab_i8+italic+800.woff2',
      'VedaFont[DIN Next Slab]+din_next_slab_n9+normal+900.woff2',
      'VedaFont[DIN Next Slab]+din_next_slab_i9+italic+900.woff2',
    ],
  },
  {
    label: 'DM Sans',
    value: 'dm_sans_n4',
    family: 'DM Sans',
    shopifyFiles: [
      'VedaFont[DM Sans]+dm_sans_n4+normal+400.woff2',
      'VedaFont[DM Sans]+dm_sans_i4+italic+400.woff2',
      'VedaFont[DM Sans]+dm_sans_n5+normal+500.woff2',
      'VedaFont[DM Sans]+dm_sans_i5+italic+500.woff2',
      'VedaFont[DM Sans]+dm_sans_n7+normal+700.woff2',
      'VedaFont[DM Sans]+dm_sans_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Daytona',
    value: 'daytona_n4',
    family: 'Daytona',
    shopifyFiles: [
      'VedaFont[Daytona]+daytona_n2+normal+200.woff2',
      'VedaFont[Daytona]+daytona_i2+italic+200.woff2',
      'VedaFont[Daytona]+daytona_n3+normal+300.woff2',
      'VedaFont[Daytona]+daytona_i3+italic+300.woff2',
      'VedaFont[Daytona]+daytona_n4+normal+400.woff2',
      'VedaFont[Daytona]+daytona_i4+italic+400.woff2',
      'VedaFont[Daytona]+daytona_n6+normal+600.woff2',
      'VedaFont[Daytona]+daytona_i6+italic+600.woff2',
      'VedaFont[Daytona]+daytona_n7+normal+700.woff2',
      'VedaFont[Daytona]+daytona_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Domine',
    value: 'domine_n4',
    family: 'Domine',
    shopifyFiles: ['VedaFont[Domine]+domine_n4+normal+400.woff2', 'VedaFont[Domine]+domine_n7+normal+700.woff2'],
  },
  {
    label: 'Dosis',
    value: 'dosis_n4',
    family: 'Dosis',
    shopifyFiles: [
      'VedaFont[Dosis]+dosis_n2+normal+200.woff2',
      'VedaFont[Dosis]+dosis_n3+normal+300.woff2',
      'VedaFont[Dosis]+dosis_n4+normal+400.woff2',
      'VedaFont[Dosis]+dosis_n5+normal+500.woff2',
      'VedaFont[Dosis]+dosis_n6+normal+600.woff2',
      'VedaFont[Dosis]+dosis_n7+normal+700.woff2',
      'VedaFont[Dosis]+dosis_n8+normal+800.woff2',
    ],
  },
  {
    label: 'Eczar',
    value: 'eczar_n4',
    family: 'Eczar',
    shopifyFiles: [
      'VedaFont[Eczar]+eczar_n4+normal+400.woff2',
      'VedaFont[Eczar]+eczar_n5+normal+500.woff2',
      'VedaFont[Eczar]+eczar_n6+normal+600.woff2',
      'VedaFont[Eczar]+eczar_n7+normal+700.woff2',
      'VedaFont[Eczar]+eczar_n8+normal+800.woff2',
    ],
  },
  {
    label: 'Electra',
    value: 'electra_n4',
    family: 'Electra',
    shopifyFiles: [
      'VedaFont[Electra]+electra_n4+normal+400.woff2',
      'VedaFont[Electra]+electra_i4+italic+400.woff2',
      'VedaFont[Electra]+electra_n7+normal+700.woff2',
      'VedaFont[Electra]+electra_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Eurostile Next',
    value: 'eurostile_next_n4',
    family: 'Eurostile Next',
    shopifyFiles: [
      'VedaFont[Eurostile Next]+eurostile_next_n2+normal+200.woff2',
      'VedaFont[Eurostile Next]+eurostile_next_i2+italic+200.woff2',
      'VedaFont[Eurostile Next]+eurostile_next_n3+normal+300.woff2',
      'VedaFont[Eurostile Next]+eurostile_next_i3+italic+300.woff2',
      'VedaFont[Eurostile Next]+eurostile_next_n4+normal+400.woff2',
      'VedaFont[Eurostile Next]+eurostile_next_i4+italic+400.woff2',
      'VedaFont[Eurostile Next]+eurostile_next_n6+normal+600.woff2',
      'VedaFont[Eurostile Next]+eurostile_next_i6+italic+600.woff2',
      'VedaFont[Eurostile Next]+eurostile_next_n7+normal+700.woff2',
      'VedaFont[Eurostile Next]+eurostile_next_i7+italic+700.woff2',
    ],
  },
  {
    label: 'FF Meta',
    value: 'ff_meta_n4',
    family: 'FF Meta',
    shopifyFiles: [
      'VedaFont[FF Meta]+ff_meta_n1+normal+100.woff2',
      'VedaFont[FF Meta]+ff_meta_i1+italic+100.woff2',
      'VedaFont[FF Meta]+ff_meta_n2+normal+200.woff2',
      'VedaFont[FF Meta]+ff_meta_i2+italic+200.woff2',
      'VedaFont[FF Meta]+ff_meta_n3+normal+300.woff2',
      'VedaFont[FF Meta]+ff_meta_i3+italic+300.woff2',
      'VedaFont[FF Meta]+ff_meta_n4+normal+400.woff2',
      'VedaFont[FF Meta]+ff_meta_i4+italic+400.woff2',
      'VedaFont[FF Meta]+ff_meta_n5+normal+500.woff2',
      'VedaFont[FF Meta]+ff_meta_i5+italic+500.woff2',
      'VedaFont[FF Meta]+ff_meta_n6+normal+600.woff2',
      'VedaFont[FF Meta]+ff_meta_i6+italic+600.woff2',
      'VedaFont[FF Meta]+ff_meta_n7+normal+700.woff2',
      'VedaFont[FF Meta]+ff_meta_i7+italic+700.woff2',
      'VedaFont[FF Meta]+ff_meta_n9+normal+900.woff2',
      'VedaFont[FF Meta]+ff_meta_i9+italic+900.woff2',
    ],
  },
  {
    label: 'FF Meta Serif',
    value: 'ff_meta_serif_n4',
    family: 'FF Meta Serif',
    shopifyFiles: [
      'VedaFont[FF Meta Serif]+ff_meta_serif_n3+normal+300.woff2',
      'VedaFont[FF Meta Serif]+ff_meta_serif_i3+italic+300.woff2',
      'VedaFont[FF Meta Serif]+ff_meta_serif_n4+normal+400.woff2',
      'VedaFont[FF Meta Serif]+ff_meta_serif_i4+italic+400.woff2',
      'VedaFont[FF Meta Serif]+ff_meta_serif_n5+normal+500.woff2',
      'VedaFont[FF Meta Serif]+ff_meta_serif_i5+italic+500.woff2',
      'VedaFont[FF Meta Serif]+ff_meta_serif_n7+normal+700.woff2',
      'VedaFont[FF Meta Serif]+ff_meta_serif_i7+italic+700.woff2',
      'VedaFont[FF Meta Serif]+ff_meta_serif_n8+normal+800.woff2',
      'VedaFont[FF Meta Serif]+ff_meta_serif_i8+italic+800.woff2',
      'VedaFont[FF Meta Serif]+ff_meta_serif_n9+normal+900.woff2',
      'VedaFont[FF Meta Serif]+ff_meta_serif_i9+italic+900.woff2',
    ],
  },
  {
    label: 'FF Tisa',
    value: 'ff_tisa_n4',
    family: 'FF Tisa',
    shopifyFiles: [
      'VedaFont[FF Tisa]+ff_tisa_n2+normal+200.woff2',
      'VedaFont[FF Tisa]+ff_tisa_i2+italic+200.woff2',
      'VedaFont[FF Tisa]+ff_tisa_n3+normal+300.woff2',
      'VedaFont[FF Tisa]+ff_tisa_i3+italic+300.woff2',
      'VedaFont[FF Tisa]+ff_tisa_n4+normal+400.woff2',
      'VedaFont[FF Tisa]+ff_tisa_i4+italic+400.woff2',
      'VedaFont[FF Tisa]+ff_tisa_n5+normal+500.woff2',
      'VedaFont[FF Tisa]+ff_tisa_i5+italic+500.woff2',
      'VedaFont[FF Tisa]+ff_tisa_n7+normal+700.woff2',
      'VedaFont[FF Tisa]+ff_tisa_i7+italic+700.woff2',
      'VedaFont[FF Tisa]+ff_tisa_n8+normal+800.woff2',
      'VedaFont[FF Tisa]+ff_tisa_i8+italic+800.woff2',
      'VedaFont[FF Tisa]+ff_tisa_n9+normal+900.woff2',
      'VedaFont[FF Tisa]+ff_tisa_i9+italic+900.woff2',
    ],
  },
  {
    label: 'FF Tisa Sans',
    value: 'ff_tisa_sans_n4',
    family: 'FF Tisa Sans',
    shopifyFiles: [
      'VedaFont[FF Tisa Sans]+ff_tisa_sans_n2+normal+200.woff2',
      'VedaFont[FF Tisa Sans]+ff_tisa_sans_i2+italic+200.woff2',
      'VedaFont[FF Tisa Sans]+ff_tisa_sans_n3+normal+300.woff2',
      'VedaFont[FF Tisa Sans]+ff_tisa_sans_i3+italic+300.woff2',
      'VedaFont[FF Tisa Sans]+ff_tisa_sans_n4+normal+400.woff2',
      'VedaFont[FF Tisa Sans]+ff_tisa_sans_i4+italic+400.woff2',
      'VedaFont[FF Tisa Sans]+ff_tisa_sans_n5+normal+500.woff2',
      'VedaFont[FF Tisa Sans]+ff_tisa_sans_i5+italic+500.woff2',
      'VedaFont[FF Tisa Sans]+ff_tisa_sans_n7+normal+700.woff2',
      'VedaFont[FF Tisa Sans]+ff_tisa_sans_i7+italic+700.woff2',
      'VedaFont[FF Tisa Sans]+ff_tisa_sans_n8+normal+800.woff2',
      'VedaFont[FF Tisa Sans]+ff_tisa_sans_i8+italic+800.woff2',
      'VedaFont[FF Tisa Sans]+ff_tisa_sans_n9+normal+900.woff2',
      'VedaFont[FF Tisa Sans]+ff_tisa_sans_i9+italic+900.woff2',
    ],
  },
  {
    label: 'FF Unit',
    value: 'ff_unit_n4',
    family: 'FF Unit',
    shopifyFiles: [
      'VedaFont[FF Unit]+ff_unit_n2+normal+200.woff2',
      'VedaFont[FF Unit]+ff_unit_i2+italic+200.woff2',
      'VedaFont[FF Unit]+ff_unit_n3+normal+300.woff2',
      'VedaFont[FF Unit]+ff_unit_i3+italic+300.woff2',
      'VedaFont[FF Unit]+ff_unit_n4+normal+400.woff2',
      'VedaFont[FF Unit]+ff_unit_i4+italic+400.woff2',
      'VedaFont[FF Unit]+ff_unit_n5+normal+500.woff2',
      'VedaFont[FF Unit]+ff_unit_i5+italic+500.woff2',
      'VedaFont[FF Unit]+ff_unit_n7+normal+700.woff2',
      'VedaFont[FF Unit]+ff_unit_i7+italic+700.woff2',
      'VedaFont[FF Unit]+ff_unit_n8+normal+800.woff2',
      'VedaFont[FF Unit]+ff_unit_i8+italic+800.woff2',
      'VedaFont[FF Unit]+ff_unit_n9+normal+900.woff2',
      'VedaFont[FF Unit]+ff_unit_i9+italic+900.woff2',
    ],
  },
  {
    label: 'FF Unit Rounded',
    value: 'ff_unit_rounded_n4',
    family: 'FF Unit Rounded',
    shopifyFiles: [
      'VedaFont[FF Unit Rounded]+ff_unit_rounded_n3+normal+300.woff2',
      'VedaFont[FF Unit Rounded]+ff_unit_rounded_i3+italic+300.woff2',
      'VedaFont[FF Unit Rounded]+ff_unit_rounded_n4+normal+400.woff2',
      'VedaFont[FF Unit Rounded]+ff_unit_rounded_i4+italic+400.woff2',
      'VedaFont[FF Unit Rounded]+ff_unit_rounded_n5+normal+500.woff2',
      'VedaFont[FF Unit Rounded]+ff_unit_rounded_i5+italic+500.woff2',
      'VedaFont[FF Unit Rounded]+ff_unit_rounded_n7+normal+700.woff2',
      'VedaFont[FF Unit Rounded]+ff_unit_rounded_i7+italic+700.woff2',
      'VedaFont[FF Unit Rounded]+ff_unit_rounded_n8+normal+800.woff2',
      'VedaFont[FF Unit Rounded]+ff_unit_rounded_i8+italic+800.woff2',
      'VedaFont[FF Unit Rounded]+ff_unit_rounded_n9+normal+900.woff2',
      'VedaFont[FF Unit Rounded]+ff_unit_rounded_i9+italic+900.woff2',
    ],
  },
  {
    label: 'FF Unit Slab',
    value: 'ff_unit_slab_n4',
    family: 'FF Unit Slab',
    shopifyFiles: [
      'VedaFont[FF Unit Slab]+ff_unit_slab_n2+normal+200.woff2',
      'VedaFont[FF Unit Slab]+ff_unit_slab_i2+italic+200.woff2',
      'VedaFont[FF Unit Slab]+ff_unit_slab_n3+normal+300.woff2',
      'VedaFont[FF Unit Slab]+ff_unit_slab_i3+italic+300.woff2',
      'VedaFont[FF Unit Slab]+ff_unit_slab_n4+normal+400.woff2',
      'VedaFont[FF Unit Slab]+ff_unit_slab_i4+italic+400.woff2',
      'VedaFont[FF Unit Slab]+ff_unit_slab_n5+normal+500.woff2',
      'VedaFont[FF Unit Slab]+ff_unit_slab_i5+italic+500.woff2',
      'VedaFont[FF Unit Slab]+ff_unit_slab_n7+normal+700.woff2',
      'VedaFont[FF Unit Slab]+ff_unit_slab_i7+italic+700.woff2',
      'VedaFont[FF Unit Slab]+ff_unit_slab_n8+normal+800.woff2',
      'VedaFont[FF Unit Slab]+ff_unit_slab_i8+italic+800.woff2',
      'VedaFont[FF Unit Slab]+ff_unit_slab_n9+normal+900.woff2',
      'VedaFont[FF Unit Slab]+ff_unit_slab_i9+italic+900.woff2',
    ],
  },
  {
    label: 'FS Kim',
    value: 'fs_kim_n4',
    family: 'FS Kim',
    shopifyFiles: [
      'VedaFont[FS Kim]+fs_kim_n4+normal+400.woff2',
      'VedaFont[FS Kim]+fs_kim_i4+italic+400.woff2',
      'VedaFont[FS Kim]+fs_kim_n5+normal+500.woff2',
      'VedaFont[FS Kim]+fs_kim_i5+italic+500.woff2',
      'VedaFont[FS Kim]+fs_kim_n7+normal+700.woff2',
      'VedaFont[FS Kim]+fs_kim_i7+italic+700.woff2',
      'VedaFont[FS Kim]+fs_kim_n8+normal+800.woff2',
      'VedaFont[FS Kim]+fs_kim_i8+italic+800.woff2',
      'VedaFont[FS Kim]+fs_kim_n9+normal+900.woff2',
      'VedaFont[FS Kim]+fs_kim_i9+italic+900.woff2',
    ],
  },
  {
    label: 'FS Koopman',
    value: 'fs_koopman_n4',
    family: 'FS Koopman',
    shopifyFiles: [
      'VedaFont[FS Koopman]+fs_koopman_n1+normal+100.woff2',
      'VedaFont[FS Koopman]+fs_koopman_i1+italic+100.woff2',
      'VedaFont[FS Koopman]+fs_koopman_n3+normal+300.woff2',
      'VedaFont[FS Koopman]+fs_koopman_i3+italic+300.woff2',
      'VedaFont[FS Koopman]+fs_koopman_n4+normal+400.woff2',
      'VedaFont[FS Koopman]+fs_koopman_i4+italic+400.woff2',
      'VedaFont[FS Koopman]+fs_koopman_n5+normal+500.woff2',
      'VedaFont[FS Koopman]+fs_koopman_i5+italic+500.woff2',
      'VedaFont[FS Koopman]+fs_koopman_n7+normal+700.woff2',
      'VedaFont[FS Koopman]+fs_koopman_i7+italic+700.woff2',
      'VedaFont[FS Koopman]+fs_koopman_n8+normal+800.woff2',
      'VedaFont[FS Koopman]+fs_koopman_i8+italic+800.woff2',
      'VedaFont[FS Koopman]+fs_koopman_n9+normal+900.woff2',
      'VedaFont[FS Koopman]+fs_koopman_i9+italic+900.woff2',
    ],
  },
  {
    label: 'FS Siena',
    value: 'fs_siena_n4',
    family: 'FS Siena',
    shopifyFiles: [
      'VedaFont[FS Siena]+fs_siena_n1+normal+100.woff2',
      'VedaFont[FS Siena]+fs_siena_i1+italic+100.woff2',
      'VedaFont[FS Siena]+fs_siena_n2+normal+200.woff2',
      'VedaFont[FS Siena]+fs_siena_i2+italic+200.woff2',
      'VedaFont[FS Siena]+fs_siena_n3+normal+300.woff2',
      'VedaFont[FS Siena]+fs_siena_i3+italic+300.woff2',
      'VedaFont[FS Siena]+fs_siena_n4+normal+400.woff2',
      'VedaFont[FS Siena]+fs_siena_i4+italic+400.woff2',
      'VedaFont[FS Siena]+fs_siena_n5+normal+500.woff2',
      'VedaFont[FS Siena]+fs_siena_i5+italic+500.woff2',
      'VedaFont[FS Siena]+fs_siena_n6+normal+600.woff2',
      'VedaFont[FS Siena]+fs_siena_i6+italic+600.woff2',
      'VedaFont[FS Siena]+fs_siena_n7+normal+700.woff2',
      'VedaFont[FS Siena]+fs_siena_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Fette Gotisch',
    value: 'fette_gotisch_n4',
    family: 'Fette Gotisch',
    shopifyFiles: ['VedaFont[Fette Gotisch]+fette_gotisch_n4+normal+400.woff2'],
  },
  {
    label: 'Fira Sans',
    value: 'fira_sans_n4',
    family: 'Fira Sans',
    shopifyFiles: [
      'VedaFont[Fira Sans]+fira_sans_n1+normal+100.woff2',
      'VedaFont[Fira Sans]+fira_sans_i1+italic+100.woff2',
      'VedaFont[Fira Sans]+fira_sans_n2+normal+200.woff2',
      'VedaFont[Fira Sans]+fira_sans_i2+italic+200.woff2',
      'VedaFont[Fira Sans]+fira_sans_n3+normal+300.woff2',
      'VedaFont[Fira Sans]+fira_sans_i3+italic+300.woff2',
      'VedaFont[Fira Sans]+fira_sans_n4+normal+400.woff2',
      'VedaFont[Fira Sans]+fira_sans_i4+italic+400.woff2',
      'VedaFont[Fira Sans]+fira_sans_n5+normal+500.woff2',
      'VedaFont[Fira Sans]+fira_sans_i5+italic+500.woff2',
      'VedaFont[Fira Sans]+fira_sans_n6+normal+600.woff2',
      'VedaFont[Fira Sans]+fira_sans_i6+italic+600.woff2',
      'VedaFont[Fira Sans]+fira_sans_n7+normal+700.woff2',
      'VedaFont[Fira Sans]+fira_sans_i7+italic+700.woff2',
      'VedaFont[Fira Sans]+fira_sans_n8+normal+800.woff2',
      'VedaFont[Fira Sans]+fira_sans_i8+italic+800.woff2',
      'VedaFont[Fira Sans]+fira_sans_n9+normal+900.woff2',
      'VedaFont[Fira Sans]+fira_sans_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Fjalla One',
    value: 'fjalla_one_n4',
    family: 'Fjalla One',
    shopifyFiles: ['VedaFont[Fjalla One]+fjalla_one_n4+normal+400.woff2'],
  },
  {
    label: 'Friz Quadrata',
    value: 'friz_quadrata_n4',
    family: 'Friz Quadrata',
    shopifyFiles: [
      'VedaFont[Friz Quadrata]+friz_quadrata_n4+normal+400.woff2',
      'VedaFont[Friz Quadrata]+friz_quadrata_i4+italic+400.woff2',
      'VedaFont[Friz Quadrata]+friz_quadrata_n7+normal+700.woff2',
      'VedaFont[Friz Quadrata]+friz_quadrata_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Frutiger Serif',
    value: 'frutiger_serif_n4',
    family: 'Frutiger Serif',
    shopifyFiles: [
      'VedaFont[Frutiger Serif]+frutiger_serif_n3+normal+300.woff2',
      'VedaFont[Frutiger Serif]+frutiger_serif_i3+italic+300.woff2',
      'VedaFont[Frutiger Serif]+frutiger_serif_n4+normal+400.woff2',
      'VedaFont[Frutiger Serif]+frutiger_serif_i4+italic+400.woff2',
      'VedaFont[Frutiger Serif]+frutiger_serif_n5+normal+500.woff2',
      'VedaFont[Frutiger Serif]+frutiger_serif_i5+italic+500.woff2',
      'VedaFont[Frutiger Serif]+frutiger_serif_n7+normal+700.woff2',
      'VedaFont[Frutiger Serif]+frutiger_serif_i7+italic+700.woff2',
      'VedaFont[Frutiger Serif]+frutiger_serif_n8+normal+800.woff2',
      'VedaFont[Frutiger Serif]+frutiger_serif_i8+italic+800.woff2',
    ],
  },
  {
    label: 'Futura',
    value: 'futura_n4',
    family: 'Futura',
    shopifyFiles: [
      'VedaFont[Futura]+futura_n3+normal+300.woff2',
      'VedaFont[Futura]+futura_o3+oblique+300.woff2',
      'VedaFont[Futura]+futura_n4+normal+400.woff2',
      'VedaFont[Futura]+futura_o4+oblique+400.woff2',
      'VedaFont[Futura]+futura_n5+normal+500.woff2',
      'VedaFont[Futura]+futura_o5+oblique+500.woff2',
      'VedaFont[Futura]+futura_n6+normal+600.woff2',
      'VedaFont[Futura]+futura_o6+oblique+600.woff2',
      'VedaFont[Futura]+futura_n7+normal+700.woff2',
      'VedaFont[Futura]+futura_o7+oblique+700.woff2',
      'VedaFont[Futura]+futura_n8+normal+800.woff2',
      'VedaFont[Futura]+futura_o8+oblique+800.woff2',
      'VedaFont[Futura]+futura_n9+normal+900.woff2',
    ],
  },
  {
    label: 'Futura Black',
    value: 'futura_black_n4',
    family: 'Futura Black',
    shopifyFiles: ['VedaFont[Futura Black]+futura_black_n4+normal+400.woff2'],
  },
  {
    label: 'Garamond',
    value: 'garamond_n4',
    family: 'Garamond',
    shopifyFiles: [],
  },
  {
    label: 'Geometric 415',
    value: 'geometric_415_n4',
    family: 'Geometric 415',
    shopifyFiles: [
      'VedaFont[Geometric 415]+geometric_415_n4+normal+400.woff2',
      'VedaFont[Geometric 415]+geometric_415_i4+italic+400.woff2',
      'VedaFont[Geometric 415]+geometric_415_n6+normal+600.woff2',
      'VedaFont[Geometric 415]+geometric_415_i6+italic+600.woff2',
      'VedaFont[Geometric 415]+geometric_415_n7+normal+700.woff2',
      'VedaFont[Geometric 415]+geometric_415_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Georgia Pro',
    value: 'georgia_pro_n4',
    family: 'Georgia Pro',
    shopifyFiles: [
      'VedaFont[Georgia Pro]+georgia_pro_n3+normal+300.woff2',
      'VedaFont[Georgia Pro]+georgia_pro_i3+italic+300.woff2',
      'VedaFont[Georgia Pro]+georgia_pro_n4+normal+400.woff2',
      'VedaFont[Georgia Pro]+georgia_pro_i4+italic+400.woff2',
      'VedaFont[Georgia Pro]+georgia_pro_n6+normal+600.woff2',
      'VedaFont[Georgia Pro]+georgia_pro_i6+italic+600.woff2',
      'VedaFont[Georgia Pro]+georgia_pro_n7+normal+700.woff2',
      'VedaFont[Georgia Pro]+georgia_pro_i7+italic+700.woff2',
      'VedaFont[Georgia Pro]+georgia_pro_n9+normal+900.woff2',
      'VedaFont[Georgia Pro]+georgia_pro_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Gill Sans Nova',
    value: 'gill_sans_nova_n4',
    family: 'Gill Sans Nova',
    shopifyFiles: [
      'VedaFont[Gill Sans Nova]+gill_sans_nova_n1+normal+100.woff2',
      'VedaFont[Gill Sans Nova]+gill_sans_nova_i1+italic+100.woff2',
      'VedaFont[Gill Sans Nova]+gill_sans_nova_n2+normal+200.woff2',
      'VedaFont[Gill Sans Nova]+gill_sans_nova_i2+italic+200.woff2',
      'VedaFont[Gill Sans Nova]+gill_sans_nova_n3+normal+300.woff2',
      'VedaFont[Gill Sans Nova]+gill_sans_nova_i3+italic+300.woff2',
      'VedaFont[Gill Sans Nova]+gill_sans_nova_n4+normal+400.woff2',
      'VedaFont[Gill Sans Nova]+gill_sans_nova_i4+italic+400.woff2',
      'VedaFont[Gill Sans Nova]+gill_sans_nova_n5+normal+500.woff2',
      'VedaFont[Gill Sans Nova]+gill_sans_nova_i5+italic+500.woff2',
      'VedaFont[Gill Sans Nova]+gill_sans_nova_n6+normal+600.woff2',
      'VedaFont[Gill Sans Nova]+gill_sans_nova_i6+italic+600.woff2',
      'VedaFont[Gill Sans Nova]+gill_sans_nova_n7+normal+700.woff2',
      'VedaFont[Gill Sans Nova]+gill_sans_nova_i7+italic+700.woff2',
      'VedaFont[Gill Sans Nova]+gill_sans_nova_n8+normal+800.woff2',
      'VedaFont[Gill Sans Nova]+gill_sans_nova_n9+normal+900.woff2',
    ],
  },
  {
    label: 'Glegoo',
    value: 'glegoo_n4',
    family: 'Glegoo',
    shopifyFiles: ['VedaFont[Glegoo]+glegoo_n4+normal+400.woff2', 'VedaFont[Glegoo]+glegoo_n7+normal+700.woff2'],
  },
  {
    label: 'Goudy Old Style',
    value: 'goudy_old_style_n4',
    family: 'Goudy Old Style',
    shopifyFiles: [
      'VedaFont[Goudy Old Style]+goudy_old_style_n4+normal+400.woff2',
      'VedaFont[Goudy Old Style]+goudy_old_style_i4+italic+400.woff2',
      'VedaFont[Goudy Old Style]+goudy_old_style_n7+normal+700.woff2',
      'VedaFont[Goudy Old Style]+goudy_old_style_i7+italic+700.woff2',
      'VedaFont[Goudy Old Style]+goudy_old_style_n8+normal+800.woff2',
    ],
  },
  {
    label: 'Harmonia Sans',
    value: 'harmonia_sans_n4',
    family: 'Harmonia Sans',
    shopifyFiles: [
      'VedaFont[Harmonia Sans]+harmonia_sans_n3+normal+300.woff2',
      'VedaFont[Harmonia Sans]+harmonia_sans_i3+italic+300.woff2',
      'VedaFont[Harmonia Sans]+harmonia_sans_n4+normal+400.woff2',
      'VedaFont[Harmonia Sans]+harmonia_sans_i4+italic+400.woff2',
      'VedaFont[Harmonia Sans]+harmonia_sans_n6+normal+600.woff2',
      'VedaFont[Harmonia Sans]+harmonia_sans_i6+italic+600.woff2',
      'VedaFont[Harmonia Sans]+harmonia_sans_n7+normal+700.woff2',
      'VedaFont[Harmonia Sans]+harmonia_sans_i7+italic+700.woff2',
      'VedaFont[Harmonia Sans]+harmonia_sans_n9+normal+900.woff2',
      'VedaFont[Harmonia Sans]+harmonia_sans_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Helvetica',
    value: 'helvetica_n4',
    family: 'Helvetica',
    shopifyFiles: [
      'VedaFont[Helvetica]+helvetica_n3+normal+300.woff2',
      'VedaFont[Helvetica]+helvetica_o3+oblique+300.woff2',
      'VedaFont[Helvetica]+helvetica_n4+normal+400.woff2',
      'VedaFont[Helvetica]+helvetica_o4+oblique+400.woff2',
      'VedaFont[Helvetica]+helvetica_n7+normal+700.woff2',
      'VedaFont[Helvetica]+helvetica_o7+oblique+700.woff2',
      'VedaFont[Helvetica]+helvetica_n9+normal+900.woff2',
      'VedaFont[Helvetica]+helvetica_o9+oblique+900.woff2',
    ],
  },
  {
    label: 'Hope Sans',
    value: 'hope_sans_n4',
    family: 'Hope Sans',
    shopifyFiles: [
      'VedaFont[Hope Sans]+hope_sans_n1+normal+100.woff2',
      'VedaFont[Hope Sans]+hope_sans_i1+italic+100.woff2',
      'VedaFont[Hope Sans]+hope_sans_n2+normal+200.woff2',
      'VedaFont[Hope Sans]+hope_sans_i2+italic+200.woff2',
      'VedaFont[Hope Sans]+hope_sans_n3+normal+300.woff2',
      'VedaFont[Hope Sans]+hope_sans_i3+italic+300.woff2',
      'VedaFont[Hope Sans]+hope_sans_n4+normal+400.woff2',
      'VedaFont[Hope Sans]+hope_sans_i4+italic+400.woff2',
      'VedaFont[Hope Sans]+hope_sans_n6+normal+600.woff2',
      'VedaFont[Hope Sans]+hope_sans_i6+italic+600.woff2',
      'VedaFont[Hope Sans]+hope_sans_n7+normal+700.woff2',
      'VedaFont[Hope Sans]+hope_sans_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Humanist 521',
    value: 'humanist_521_n4',
    family: 'Humanist 521',
    shopifyFiles: [
      'VedaFont[Humanist 521]+humanist_521_n2+normal+200.woff2',
      'VedaFont[Humanist 521]+humanist_521_i2+italic+200.woff2',
      'VedaFont[Humanist 521]+humanist_521_n4+normal+400.woff2',
      'VedaFont[Humanist 521]+humanist_521_i4+italic+400.woff2',
      'VedaFont[Humanist 521]+humanist_521_n7+normal+700.woff2',
      'VedaFont[Humanist 521]+humanist_521_i7+italic+700.woff2',
      'VedaFont[Humanist 521]+humanist_521_n8+normal+800.woff2',
      'VedaFont[Humanist 521]+humanist_521_n9+normal+900.woff2',
    ],
  },
  {
    label: 'IBM Plex Sans',
    value: 'ibm_plex_sans_n4',
    family: 'IBM Plex Sans',
    shopifyFiles: [
      'VedaFont[IBM Plex Sans]+ibm_plex_sans_n1+normal+100.woff2',
      'VedaFont[IBM Plex Sans]+ibm_plex_sans_i1+italic+100.woff2',
      'VedaFont[IBM Plex Sans]+ibm_plex_sans_n2+normal+200.woff2',
      'VedaFont[IBM Plex Sans]+ibm_plex_sans_i2+italic+200.woff2',
      'VedaFont[IBM Plex Sans]+ibm_plex_sans_n3+normal+300.woff2',
      'VedaFont[IBM Plex Sans]+ibm_plex_sans_i3+italic+300.woff2',
      'VedaFont[IBM Plex Sans]+ibm_plex_sans_n4+normal+400.woff2',
      'VedaFont[IBM Plex Sans]+ibm_plex_sans_i4+italic+400.woff2',
      'VedaFont[IBM Plex Sans]+ibm_plex_sans_n5+normal+500.woff2',
      'VedaFont[IBM Plex Sans]+ibm_plex_sans_i5+italic+500.woff2',
      'VedaFont[IBM Plex Sans]+ibm_plex_sans_n6+normal+600.woff2',
      'VedaFont[IBM Plex Sans]+ibm_plex_sans_i6+italic+600.woff2',
      'VedaFont[IBM Plex Sans]+ibm_plex_sans_n7+normal+700.woff2',
      'VedaFont[IBM Plex Sans]+ibm_plex_sans_i7+italic+700.woff2',
    ],
  },
  {
    label: 'ITC Avant Garde Gothic',
    value: 'itc_avant_garde_gothic_n4',
    family: 'ITC Avant Garde Gothic',
    shopifyFiles: [
      'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_n2+normal+200.woff2',
      'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_o2+oblique+200.woff2',
      'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_n4+normal+400.woff2',
      'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_o4+oblique+400.woff2',
      'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_n5+normal+500.woff2',
      'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_o5+oblique+500.woff2',
      'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_n6+normal+600.woff2',
      'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_o6+oblique+600.woff2',
      'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_n7+normal+700.woff2',
      'VedaFont[ITC Avant Garde Gothic]+itc_avant_garde_gothic_o7+oblique+700.woff2',
    ],
  },
  {
    label: 'ITC Benguiat',
    value: 'itc_benguiat_n4',
    family: 'ITC Benguiat',
    shopifyFiles: [
      'VedaFont[ITC Benguiat]+itc_benguiat_n4+normal+400.woff2',
      'VedaFont[ITC Benguiat]+itc_benguiat_i4+italic+400.woff2',
      'VedaFont[ITC Benguiat]+itc_benguiat_n5+normal+500.woff2',
      'VedaFont[ITC Benguiat]+itc_benguiat_i5+italic+500.woff2',
      'VedaFont[ITC Benguiat]+itc_benguiat_n7+normal+700.woff2',
      'VedaFont[ITC Benguiat]+itc_benguiat_i7+italic+700.woff2',
    ],
  },
  {
    label: 'ITC Berkeley Old Style',
    value: 'itc_berkeley_old_style_n4',
    family: 'ITC Berkeley Old Style',
    shopifyFiles: [
      'VedaFont[ITC Berkeley Old Style]+itc_berkeley_old_style_n4+normal+400.woff2',
      'VedaFont[ITC Berkeley Old Style]+itc_berkeley_old_style_i4+italic+400.woff2',
      'VedaFont[ITC Berkeley Old Style]+itc_berkeley_old_style_n5+normal+500.woff2',
      'VedaFont[ITC Berkeley Old Style]+itc_berkeley_old_style_i5+italic+500.woff2',
      'VedaFont[ITC Berkeley Old Style]+itc_berkeley_old_style_n7+normal+700.woff2',
      'VedaFont[ITC Berkeley Old Style]+itc_berkeley_old_style_i7+italic+700.woff2',
      'VedaFont[ITC Berkeley Old Style]+itc_berkeley_old_style_n9+normal+900.woff2',
      'VedaFont[ITC Berkeley Old Style]+itc_berkeley_old_style_i9+italic+900.woff2',
    ],
  },
  {
    label: 'ITC Bodoni Seventytwo',
    value: 'itc_bodoni_seventytwo_n4',
    family: 'ITC Bodoni Seventytwo',
    shopifyFiles: [
      'VedaFont[ITC Bodoni Seventytwo]+itc_bodoni_seventytwo_n4+normal+400.woff2',
      'VedaFont[ITC Bodoni Seventytwo]+itc_bodoni_seventytwo_i4+italic+400.woff2',
      'VedaFont[ITC Bodoni Seventytwo]+itc_bodoni_seventytwo_n7+normal+700.woff2',
      'VedaFont[ITC Bodoni Seventytwo]+itc_bodoni_seventytwo_i7+italic+700.woff2',
    ],
  },
  {
    label: 'ITC Bodoni Twelve',
    value: 'itc_bodoni_twelve_n4',
    family: 'ITC Bodoni Twelve',
    shopifyFiles: [
      'VedaFont[ITC Bodoni Twelve]+itc_bodoni_twelve_n4+normal+400.woff2',
      'VedaFont[ITC Bodoni Twelve]+itc_bodoni_twelve_i4+italic+400.woff2',
      'VedaFont[ITC Bodoni Twelve]+itc_bodoni_twelve_n7+normal+700.woff2',
      'VedaFont[ITC Bodoni Twelve]+itc_bodoni_twelve_i7+italic+700.woff2',
    ],
  },
  {
    label: 'ITC Caslon No 224',
    value: 'itc_caslon_no_224_n4',
    family: 'ITC Caslon No 224',
    shopifyFiles: [
      'VedaFont[ITC Caslon No 224]+itc_caslon_no_224_n4+normal+400.woff2',
      'VedaFont[ITC Caslon No 224]+itc_caslon_no_224_i4+italic+400.woff2',
      'VedaFont[ITC Caslon No 224]+itc_caslon_no_224_n5+normal+500.woff2',
      'VedaFont[ITC Caslon No 224]+itc_caslon_no_224_i5+italic+500.woff2',
      'VedaFont[ITC Caslon No 224]+itc_caslon_no_224_n7+normal+700.woff2',
      'VedaFont[ITC Caslon No 224]+itc_caslon_no_224_i7+italic+700.woff2',
      'VedaFont[ITC Caslon No 224]+itc_caslon_no_224_n9+normal+900.woff2',
      'VedaFont[ITC Caslon No 224]+itc_caslon_no_224_i9+italic+900.woff2',
    ],
  },
  {
    label: 'ITC Charter',
    value: 'itc_charter_n4',
    family: 'ITC Charter',
    shopifyFiles: [
      'VedaFont[ITC Charter]+itc_charter_n4+normal+400.woff2',
      'VedaFont[ITC Charter]+itc_charter_i4+italic+400.woff2',
      'VedaFont[ITC Charter]+itc_charter_n7+normal+700.woff2',
      'VedaFont[ITC Charter]+itc_charter_i7+italic+700.woff2',
      'VedaFont[ITC Charter]+itc_charter_n9+normal+900.woff2',
      'VedaFont[ITC Charter]+itc_charter_i9+italic+900.woff2',
    ],
  },
  {
    label: 'ITC Cheltenham',
    value: 'itc_cheltenham_n4',
    family: 'ITC Cheltenham',
    shopifyFiles: [
      'VedaFont[ITC Cheltenham]+itc_cheltenham_n3+normal+300.woff2',
      'VedaFont[ITC Cheltenham]+itc_cheltenham_i3+italic+300.woff2',
      'VedaFont[ITC Cheltenham]+itc_cheltenham_n4+normal+400.woff2',
      'VedaFont[ITC Cheltenham]+itc_cheltenham_i4+italic+400.woff2',
      'VedaFont[ITC Cheltenham]+itc_cheltenham_n7+normal+700.woff2',
      'VedaFont[ITC Cheltenham]+itc_cheltenham_i7+italic+700.woff2',
      'VedaFont[ITC Cheltenham]+itc_cheltenham_n9+normal+900.woff2',
      'VedaFont[ITC Cheltenham]+itc_cheltenham_i9+italic+900.woff2',
    ],
  },
  {
    label: 'ITC Clearface',
    value: 'itc_clearface_n4',
    family: 'ITC Clearface',
    shopifyFiles: [
      'VedaFont[ITC Clearface]+itc_clearface_n4+normal+400.woff2',
      'VedaFont[ITC Clearface]+itc_clearface_i4+italic+400.woff2',
      'VedaFont[ITC Clearface]+itc_clearface_n7+normal+700.woff2',
      'VedaFont[ITC Clearface]+itc_clearface_i7+italic+700.woff2',
      'VedaFont[ITC Clearface]+itc_clearface_n8+normal+800.woff2',
      'VedaFont[ITC Clearface]+itc_clearface_i8+italic+800.woff2',
      'VedaFont[ITC Clearface]+itc_clearface_n9+normal+900.woff2',
      'VedaFont[ITC Clearface]+itc_clearface_i9+italic+900.woff2',
    ],
  },
  {
    label: 'ITC Conduit',
    value: 'itc_conduit_n4',
    family: 'ITC Conduit',
    shopifyFiles: [
      'VedaFont[ITC Conduit]+itc_conduit_n2+normal+200.woff2',
      'VedaFont[ITC Conduit]+itc_conduit_i2+italic+200.woff2',
      'VedaFont[ITC Conduit]+itc_conduit_n3+normal+300.woff2',
      'VedaFont[ITC Conduit]+itc_conduit_i3+italic+300.woff2',
      'VedaFont[ITC Conduit]+itc_conduit_n4+normal+400.woff2',
      'VedaFont[ITC Conduit]+itc_conduit_i4+italic+400.woff2',
      'VedaFont[ITC Conduit]+itc_conduit_n5+normal+500.woff2',
      'VedaFont[ITC Conduit]+itc_conduit_i5+italic+500.woff2',
      'VedaFont[ITC Conduit]+itc_conduit_n7+normal+700.woff2',
      'VedaFont[ITC Conduit]+itc_conduit_i7+italic+700.woff2',
      'VedaFont[ITC Conduit]+itc_conduit_n8+normal+800.woff2',
      'VedaFont[ITC Conduit]+itc_conduit_i8+italic+800.woff2',
      'VedaFont[ITC Conduit]+itc_conduit_n9+normal+900.woff2',
      'VedaFont[ITC Conduit]+itc_conduit_i9+italic+900.woff2',
    ],
  },
  {
    label: 'ITC Esprit',
    value: 'itc_esprit_n4',
    family: 'ITC Esprit',
    shopifyFiles: [
      'VedaFont[ITC Esprit]+itc_esprit_n4+normal+400.woff2',
      'VedaFont[ITC Esprit]+itc_esprit_i4+italic+400.woff2',
      'VedaFont[ITC Esprit]+itc_esprit_n5+normal+500.woff2',
      'VedaFont[ITC Esprit]+itc_esprit_i5+italic+500.woff2',
      'VedaFont[ITC Esprit]+itc_esprit_n7+normal+700.woff2',
      'VedaFont[ITC Esprit]+itc_esprit_i7+italic+700.woff2',
      'VedaFont[ITC Esprit]+itc_esprit_n9+normal+900.woff2',
      'VedaFont[ITC Esprit]+itc_esprit_i9+italic+900.woff2',
    ],
  },
  {
    label: 'ITC Founders Caslon',
    value: 'itc_founders_caslon_n4',
    family: 'ITC Founders Caslon',
    shopifyFiles: [
      'VedaFont[ITC Founders Caslon]+itc_founders_caslon_n4+normal+400.woff2',
      'VedaFont[ITC Founders Caslon]+itc_founders_caslon_i4+italic+400.woff2',
    ],
  },
  {
    label: 'ITC Franklin Gothic',
    value: 'itc_franklin_gothic_n4',
    family: 'ITC Franklin Gothic',
    shopifyFiles: [
      'VedaFont[ITC Franklin Gothic]+itc_franklin_gothic_n4+normal+400.woff2',
      'VedaFont[ITC Franklin Gothic]+itc_franklin_gothic_i4+italic+400.woff2',
      'VedaFont[ITC Franklin Gothic]+itc_franklin_gothic_n5+normal+500.woff2',
      'VedaFont[ITC Franklin Gothic]+itc_franklin_gothic_i5+italic+500.woff2',
      'VedaFont[ITC Franklin Gothic]+itc_franklin_gothic_n7+normal+700.woff2',
      'VedaFont[ITC Franklin Gothic]+itc_franklin_gothic_i7+italic+700.woff2',
      'VedaFont[ITC Franklin Gothic]+itc_franklin_gothic_n9+normal+900.woff2',
      'VedaFont[ITC Franklin Gothic]+itc_franklin_gothic_i9+italic+900.woff2',
    ],
  },
  {
    label: 'ITC Galliard',
    value: 'itc_galliard_n4',
    family: 'ITC Galliard',
    shopifyFiles: [
      'VedaFont[ITC Galliard]+itc_galliard_n4+normal+400.woff2',
      'VedaFont[ITC Galliard]+itc_galliard_i4+italic+400.woff2',
      'VedaFont[ITC Galliard]+itc_galliard_n7+normal+700.woff2',
      'VedaFont[ITC Galliard]+itc_galliard_i7+italic+700.woff2',
      'VedaFont[ITC Galliard]+itc_galliard_n8+normal+800.woff2',
      'VedaFont[ITC Galliard]+itc_galliard_i8+italic+800.woff2',
      'VedaFont[ITC Galliard]+itc_galliard_n9+normal+900.woff2',
      'VedaFont[ITC Galliard]+itc_galliard_i9+italic+900.woff2',
    ],
  },
  {
    label: 'ITC Gamma',
    value: 'itc_gamma_n4',
    family: 'ITC Gamma',
    shopifyFiles: [
      'VedaFont[ITC Gamma]+itc_gamma_n4+normal+400.woff2',
      'VedaFont[ITC Gamma]+itc_gamma_i4+italic+400.woff2',
      'VedaFont[ITC Gamma]+itc_gamma_n5+normal+500.woff2',
      'VedaFont[ITC Gamma]+itc_gamma_i5+italic+500.woff2',
      'VedaFont[ITC Gamma]+itc_gamma_n7+normal+700.woff2',
      'VedaFont[ITC Gamma]+itc_gamma_i7+italic+700.woff2',
      'VedaFont[ITC Gamma]+itc_gamma_n8+normal+800.woff2',
      'VedaFont[ITC Gamma]+itc_gamma_i8+italic+800.woff2',
    ],
  },
  {
    label: 'ITC Goudy Sans',
    value: 'itc_goudy_sans_n4',
    family: 'ITC Goudy Sans',
    shopifyFiles: [
      'VedaFont[ITC Goudy Sans]+itc_goudy_sans_n4+normal+400.woff2',
      'VedaFont[ITC Goudy Sans]+itc_goudy_sans_i4+italic+400.woff2',
      'VedaFont[ITC Goudy Sans]+itc_goudy_sans_n5+normal+500.woff2',
      'VedaFont[ITC Goudy Sans]+itc_goudy_sans_i5+italic+500.woff2',
      'VedaFont[ITC Goudy Sans]+itc_goudy_sans_n7+normal+700.woff2',
      'VedaFont[ITC Goudy Sans]+itc_goudy_sans_i7+italic+700.woff2',
      'VedaFont[ITC Goudy Sans]+itc_goudy_sans_n9+normal+900.woff2',
      'VedaFont[ITC Goudy Sans]+itc_goudy_sans_i9+italic+900.woff2',
    ],
  },
  {
    label: 'ITC Johnston',
    value: 'itc_johnston_n5',
    family: 'ITC Johnston',
    shopifyFiles: [
      'VedaFont[ITC Johnston]+itc_johnston_n3+normal+300.woff2',
      'VedaFont[ITC Johnston]+itc_johnston_i3+italic+300.woff2',
      'VedaFont[ITC Johnston]+itc_johnston_n5+normal+500.woff2',
      'VedaFont[ITC Johnston]+itc_johnston_i5+italic+500.woff2',
      'VedaFont[ITC Johnston]+itc_johnston_n7+normal+700.woff2',
      'VedaFont[ITC Johnston]+itc_johnston_i7+italic+700.woff2',
    ],
  },
  {
    label: 'ITC Mendoza Roman',
    value: 'itc_mendoza_roman_n4',
    family: 'ITC Mendoza Roman',
    shopifyFiles: [
      'VedaFont[ITC Mendoza Roman]+itc_mendoza_roman_n4+normal+400.woff2',
      'VedaFont[ITC Mendoza Roman]+itc_mendoza_roman_i4+italic+400.woff2',
      'VedaFont[ITC Mendoza Roman]+itc_mendoza_roman_n5+normal+500.woff2',
      'VedaFont[ITC Mendoza Roman]+itc_mendoza_roman_i5+italic+500.woff2',
      'VedaFont[ITC Mendoza Roman]+itc_mendoza_roman_n7+normal+700.woff2',
      'VedaFont[ITC Mendoza Roman]+itc_mendoza_roman_i7+italic+700.woff2',
    ],
  },
  {
    label: 'ITC Modern No 216',
    value: 'itc_modern_no_216_n5',
    family: 'ITC Modern No 216',
    shopifyFiles: [
      'VedaFont[ITC Modern No 216]+itc_modern_no_216_n3+normal+300.woff2',
      'VedaFont[ITC Modern No 216]+itc_modern_no_216_i3+italic+300.woff2',
      'VedaFont[ITC Modern No 216]+itc_modern_no_216_n5+normal+500.woff2',
      'VedaFont[ITC Modern No 216]+itc_modern_no_216_i5+italic+500.woff2',
      'VedaFont[ITC Modern No 216]+itc_modern_no_216_n7+normal+700.woff2',
      'VedaFont[ITC Modern No 216]+itc_modern_no_216_i7+italic+700.woff2',
      'VedaFont[ITC Modern No 216]+itc_modern_no_216_n8+normal+800.woff2',
      'VedaFont[ITC Modern No 216]+itc_modern_no_216_i8+italic+800.woff2',
    ],
  },
  {
    label: 'ITC New Baskerville',
    value: 'itc_new_baskerville_n4',
    family: 'ITC New Baskerville',
    shopifyFiles: [
      'VedaFont[ITC New Baskerville]+itc_new_baskerville_n4+normal+400.woff2',
      'VedaFont[ITC New Baskerville]+itc_new_baskerville_i4+italic+400.woff2',
      'VedaFont[ITC New Baskerville]+itc_new_baskerville_n6+normal+600.woff2',
      'VedaFont[ITC New Baskerville]+itc_new_baskerville_i6+italic+600.woff2',
      'VedaFont[ITC New Baskerville]+itc_new_baskerville_n7+normal+700.woff2',
      'VedaFont[ITC New Baskerville]+itc_new_baskerville_i7+italic+700.woff2',
      'VedaFont[ITC New Baskerville]+itc_new_baskerville_n9+normal+900.woff2',
      'VedaFont[ITC New Baskerville]+itc_new_baskerville_i9+italic+900.woff2',
    ],
  },
  {
    label: 'ITC New Esprit',
    value: 'itc_new_esprit_n4',
    family: 'ITC New Esprit',
    shopifyFiles: [
      'VedaFont[ITC New Esprit]+itc_new_esprit_n4+normal+400.woff2',
      'VedaFont[ITC New Esprit]+itc_new_esprit_i4+italic+400.woff2',
      'VedaFont[ITC New Esprit]+itc_new_esprit_n5+normal+500.woff2',
      'VedaFont[ITC New Esprit]+itc_new_esprit_i5+italic+500.woff2',
      'VedaFont[ITC New Esprit]+itc_new_esprit_n7+normal+700.woff2',
      'VedaFont[ITC New Esprit]+itc_new_esprit_i7+italic+700.woff2',
      'VedaFont[ITC New Esprit]+itc_new_esprit_n8+normal+800.woff2',
      'VedaFont[ITC New Esprit]+itc_new_esprit_i8+italic+800.woff2',
    ],
  },
  {
    label: 'ITC New Veljovic',
    value: 'itc_new_veljovic_n4',
    family: 'ITC New Veljovic',
    shopifyFiles: [
      'VedaFont[ITC New Veljovic]+itc_new_veljovic_n3+normal+300.woff2',
      'VedaFont[ITC New Veljovic]+itc_new_veljovic_i3+italic+300.woff2',
      'VedaFont[ITC New Veljovic]+itc_new_veljovic_n4+normal+400.woff2',
      'VedaFont[ITC New Veljovic]+itc_new_veljovic_i4+italic+400.woff2',
      'VedaFont[ITC New Veljovic]+itc_new_veljovic_n5+normal+500.woff2',
      'VedaFont[ITC New Veljovic]+itc_new_veljovic_i5+italic+500.woff2',
      'VedaFont[ITC New Veljovic]+itc_new_veljovic_n7+normal+700.woff2',
      'VedaFont[ITC New Veljovic]+itc_new_veljovic_i7+italic+700.woff2',
      'VedaFont[ITC New Veljovic]+itc_new_veljovic_n9+normal+900.woff2',
      'VedaFont[ITC New Veljovic]+itc_new_veljovic_i9+italic+900.woff2',
    ],
  },
  {
    label: 'ITC Novarese',
    value: 'itc_novarese_n4',
    family: 'ITC Novarese',
    shopifyFiles: [
      'VedaFont[ITC Novarese]+itc_novarese_n4+normal+400.woff2',
      'VedaFont[ITC Novarese]+itc_novarese_i4+italic+400.woff2',
      'VedaFont[ITC Novarese]+itc_novarese_n5+normal+500.woff2',
      'VedaFont[ITC Novarese]+itc_novarese_i5+italic+500.woff2',
      'VedaFont[ITC Novarese]+itc_novarese_n7+normal+700.woff2',
      'VedaFont[ITC Novarese]+itc_novarese_i7+italic+700.woff2',
      'VedaFont[ITC Novarese]+itc_novarese_n9+normal+900.woff2',
    ],
  },
  {
    label: 'ITC Officina Sans',
    value: 'itc_officina_sans_n4',
    family: 'ITC Officina Sans',
    shopifyFiles: [
      'VedaFont[ITC Officina Sans]+itc_officina_sans_n4+normal+400.woff2',
      'VedaFont[ITC Officina Sans]+itc_officina_sans_i4+italic+400.woff2',
      'VedaFont[ITC Officina Sans]+itc_officina_sans_n5+normal+500.woff2',
      'VedaFont[ITC Officina Sans]+itc_officina_sans_i5+italic+500.woff2',
      'VedaFont[ITC Officina Sans]+itc_officina_sans_n7+normal+700.woff2',
      'VedaFont[ITC Officina Sans]+itc_officina_sans_i7+italic+700.woff2',
      'VedaFont[ITC Officina Sans]+itc_officina_sans_n8+normal+800.woff2',
      'VedaFont[ITC Officina Sans]+itc_officina_sans_i8+italic+800.woff2',
      'VedaFont[ITC Officina Sans]+itc_officina_sans_n9+normal+900.woff2',
      'VedaFont[ITC Officina Sans]+itc_officina_sans_i9+italic+900.woff2',
    ],
  },
  {
    label: 'ITC Officina Serif',
    value: 'itc_officina_serif_n4',
    family: 'ITC Officina Serif',
    shopifyFiles: [
      'VedaFont[ITC Officina Serif]+itc_officina_serif_n4+normal+400.woff2',
      'VedaFont[ITC Officina Serif]+itc_officina_serif_i4+italic+400.woff2',
      'VedaFont[ITC Officina Serif]+itc_officina_serif_n5+normal+500.woff2',
      'VedaFont[ITC Officina Serif]+itc_officina_serif_i5+italic+500.woff2',
      'VedaFont[ITC Officina Serif]+itc_officina_serif_n7+normal+700.woff2',
      'VedaFont[ITC Officina Serif]+itc_officina_serif_i7+italic+700.woff2',
      'VedaFont[ITC Officina Serif]+itc_officina_serif_n8+normal+800.woff2',
      'VedaFont[ITC Officina Serif]+itc_officina_serif_i8+italic+800.woff2',
      'VedaFont[ITC Officina Serif]+itc_officina_serif_n9+normal+900.woff2',
      'VedaFont[ITC Officina Serif]+itc_officina_serif_i9+italic+900.woff2',
    ],
  },
  {
    label: 'ITC Stepp',
    value: 'itc_stepp_n4',
    family: 'ITC Stepp',
    shopifyFiles: [
      'VedaFont[ITC Stepp]+itc_stepp_n3+normal+300.woff2',
      'VedaFont[ITC Stepp]+itc_stepp_i3+italic+300.woff2',
      'VedaFont[ITC Stepp]+itc_stepp_n4+normal+400.woff2',
      'VedaFont[ITC Stepp]+itc_stepp_i5+italic+500.woff2',
      'VedaFont[ITC Stepp]+itc_stepp_n7+normal+700.woff2',
      'VedaFont[ITC Stepp]+itc_stepp_i7+italic+700.woff2',
      'VedaFont[ITC Stepp]+itc_stepp_n9+normal+900.woff2',
      'VedaFont[ITC Stepp]+itc_stepp_i9+italic+900.woff2',
    ],
  },
  {
    label: 'ITC Stone Humanist',
    value: 'itc_stone_humanist_n5',
    family: 'ITC Stone Humanist',
    shopifyFiles: [
      'VedaFont[ITC Stone Humanist]+itc_stone_humanist_n5+normal+500.woff2',
      'VedaFont[ITC Stone Humanist]+itc_stone_humanist_i5+italic+500.woff2',
      'VedaFont[ITC Stone Humanist]+itc_stone_humanist_n6+normal+600.woff2',
      'VedaFont[ITC Stone Humanist]+itc_stone_humanist_i6+italic+600.woff2',
      'VedaFont[ITC Stone Humanist]+itc_stone_humanist_n7+normal+700.woff2',
      'VedaFont[ITC Stone Humanist]+itc_stone_humanist_i7+italic+700.woff2',
    ],
  },
  {
    label: 'ITC Stone Informal',
    value: 'itc_stone_informal_n4',
    family: 'ITC Stone Informal',
    shopifyFiles: [
      'VedaFont[ITC Stone Informal]+itc_stone_informal_n4+normal+400.woff2',
      'VedaFont[ITC Stone Informal]+itc_stone_informal_i4+italic+400.woff2',
      'VedaFont[ITC Stone Informal]+itc_stone_informal_n6+normal+600.woff2',
      'VedaFont[ITC Stone Informal]+itc_stone_informal_i6+italic+600.woff2',
      'VedaFont[ITC Stone Informal]+itc_stone_informal_n7+normal+700.woff2',
      'VedaFont[ITC Stone Informal]+itc_stone_informal_i7+italic+700.woff2',
    ],
  },
  {
    label: 'ITC Stone Sans II',
    value: 'itc_stone_sans_ii_n4',
    family: 'ITC Stone Sans II',
    shopifyFiles: [
      'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_n3+normal+300.woff2',
      'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_i3+italic+300.woff2',
      'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_n4+normal+400.woff2',
      'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_i4+italic+400.woff2',
      'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_n5+normal+500.woff2',
      'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_i5+italic+500.woff2',
      'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_n6+normal+600.woff2',
      'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_i6+italic+600.woff2',
      'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_n7+normal+700.woff2',
      'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_i7+italic+700.woff2',
      'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_n8+normal+800.woff2',
      'VedaFont[ITC Stone Sans II]+itc_stone_sans_ii_i8+italic+800.woff2',
    ],
  },
  {
    label: 'ITC Stone Serif',
    value: 'itc_stone_serif_n4',
    family: 'ITC Stone Serif',
    shopifyFiles: [
      'VedaFont[ITC Stone Serif]+itc_stone_serif_n4+normal+400.woff2',
      'VedaFont[ITC Stone Serif]+itc_stone_serif_i4+italic+400.woff2',
      'VedaFont[ITC Stone Serif]+itc_stone_serif_n6+normal+600.woff2',
      'VedaFont[ITC Stone Serif]+itc_stone_serif_i6+italic+600.woff2',
      'VedaFont[ITC Stone Serif]+itc_stone_serif_n7+normal+700.woff2',
      'VedaFont[ITC Stone Serif]+itc_stone_serif_i7+italic+700.woff2',
    ],
  },
  {
    label: 'ITC Tapioca',
    value: 'itc_tapioca_n5',
    family: 'ITC Tapioca',
    shopifyFiles: ['VedaFont[ITC Tapioca]+itc_tapioca_n5+normal+500.woff2'],
  },
  {
    label: 'Inconsolata',
    value: 'inconsolata_n4',
    family: 'Inconsolata',
    shopifyFiles: ['VedaFont[Inconsolata]+inconsolata_n4+normal+400.woff2', 'VedaFont[Inconsolata]+inconsolata_n7+normal+700.woff2'],
  },
  {
    label: 'Inknut Antiqua',
    value: 'inknut_antiqua_n4',
    family: 'Inknut Antiqua',
    shopifyFiles: [
      'VedaFont[Inknut Antiqua]+inknut_antiqua_n3+normal+300.woff2',
      'VedaFont[Inknut Antiqua]+inknut_antiqua_n4+normal+400.woff2',
      'VedaFont[Inknut Antiqua]+inknut_antiqua_n5+normal+500.woff2',
      'VedaFont[Inknut Antiqua]+inknut_antiqua_n6+normal+600.woff2',
      'VedaFont[Inknut Antiqua]+inknut_antiqua_n7+normal+700.woff2',
      'VedaFont[Inknut Antiqua]+inknut_antiqua_n8+normal+800.woff2',
      'VedaFont[Inknut Antiqua]+inknut_antiqua_n9+normal+900.woff2',
    ],
  },
  {
    label: 'Inter',
    value: 'inter_n4',
    family: 'Inter',
    shopifyFiles: [
      'VedaFont[Inter]+inter_n1+normal+100.woff2',
      'VedaFont[Inter]+inter_n2+normal+200.woff2',
      'VedaFont[Inter]+inter_n3+normal+300.woff2',
      'VedaFont[Inter]+inter_n4+normal+400.woff2',
      'VedaFont[Inter]+inter_n5+normal+500.woff2',
      'VedaFont[Inter]+inter_n6+normal+600.woff2',
      'VedaFont[Inter]+inter_n7+normal+700.woff2',
      'VedaFont[Inter]+inter_n8+normal+800.woff2',
      'VedaFont[Inter]+inter_n9+normal+900.woff2',
    ],
  },
  {
    label: 'Joanna Nova',
    value: 'joanna_nova_n4',
    family: 'Joanna Nova',
    shopifyFiles: [
      'VedaFont[Joanna Nova]+joanna_nova_n1+normal+100.woff2',
      'VedaFont[Joanna Nova]+joanna_nova_i1+italic+100.woff2',
      'VedaFont[Joanna Nova]+joanna_nova_n2+normal+200.woff2',
      'VedaFont[Joanna Nova]+joanna_nova_i2+italic+200.woff2',
      'VedaFont[Joanna Nova]+joanna_nova_n3+normal+300.woff2',
      'VedaFont[Joanna Nova]+joanna_nova_i3+italic+300.woff2',
      'VedaFont[Joanna Nova]+joanna_nova_n4+normal+400.woff2',
      'VedaFont[Joanna Nova]+joanna_nova_i4+italic+400.woff2',
      'VedaFont[Joanna Nova]+joanna_nova_n5+normal+500.woff2',
      'VedaFont[Joanna Nova]+joanna_nova_i5+italic+500.woff2',
      'VedaFont[Joanna Nova]+joanna_nova_n6+normal+600.woff2',
      'VedaFont[Joanna Nova]+joanna_nova_i6+italic+600.woff2',
      'VedaFont[Joanna Nova]+joanna_nova_n7+normal+700.woff2',
      'VedaFont[Joanna Nova]+joanna_nova_i7+italic+700.woff2',
      'VedaFont[Joanna Nova]+joanna_nova_n8+normal+800.woff2',
      'VedaFont[Joanna Nova]+joanna_nova_i8+italic+800.woff2',
      'VedaFont[Joanna Nova]+joanna_nova_n9+normal+900.woff2',
      'VedaFont[Joanna Nova]+joanna_nova_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Joanna Sans Nova',
    value: 'joanna_sans_nova_n4',
    family: 'Joanna Sans Nova',
    shopifyFiles: [
      'VedaFont[Joanna Sans Nova]+joanna_sans_nova_n1+normal+100.woff2',
      'VedaFont[Joanna Sans Nova]+joanna_sans_nova_i1+italic+100.woff2',
      'VedaFont[Joanna Sans Nova]+joanna_sans_nova_n2+normal+200.woff2',
      'VedaFont[Joanna Sans Nova]+joanna_sans_nova_i2+italic+200.woff2',
      'VedaFont[Joanna Sans Nova]+joanna_sans_nova_n3+normal+300.woff2',
      'VedaFont[Joanna Sans Nova]+joanna_sans_nova_i3+italic+300.woff2',
      'VedaFont[Joanna Sans Nova]+joanna_sans_nova_n4+normal+400.woff2',
      'VedaFont[Joanna Sans Nova]+joanna_sans_nova_i4+italic+400.woff2',
      'VedaFont[Joanna Sans Nova]+joanna_sans_nova_n5+normal+500.woff2',
      'VedaFont[Joanna Sans Nova]+joanna_sans_nova_i5+italic+500.woff2',
      'VedaFont[Joanna Sans Nova]+joanna_sans_nova_n7+normal+700.woff2',
      'VedaFont[Joanna Sans Nova]+joanna_sans_nova_i7+italic+700.woff2',
      'VedaFont[Joanna Sans Nova]+joanna_sans_nova_n8+normal+800.woff2',
      'VedaFont[Joanna Sans Nova]+joanna_sans_nova_i8+italic+800.woff2',
      'VedaFont[Joanna Sans Nova]+joanna_sans_nova_n9+normal+900.woff2',
      'VedaFont[Joanna Sans Nova]+joanna_sans_nova_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Josefin Sans',
    value: 'josefin_sans_n4',
    family: 'Josefin Sans',
    shopifyFiles: [
      'VedaFont[Josefin Sans]+josefin_sans_n1+normal+100.woff2',
      'VedaFont[Josefin Sans]+josefin_sans_i1+italic+100.woff2',
      'VedaFont[Josefin Sans]+josefin_sans_n3+normal+300.woff2',
      'VedaFont[Josefin Sans]+josefin_sans_i3+italic+300.woff2',
      'VedaFont[Josefin Sans]+josefin_sans_n4+normal+400.woff2',
      'VedaFont[Josefin Sans]+josefin_sans_i4+italic+400.woff2',
      'VedaFont[Josefin Sans]+josefin_sans_n6+normal+600.woff2',
      'VedaFont[Josefin Sans]+josefin_sans_i6+italic+600.woff2',
      'VedaFont[Josefin Sans]+josefin_sans_n7+normal+700.woff2',
      'VedaFont[Josefin Sans]+josefin_sans_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Josefin Slab',
    value: 'josefin_slab_n4',
    family: 'Josefin Slab',
    shopifyFiles: [
      'VedaFont[Josefin Slab]+josefin_slab_n1+normal+100.woff2',
      'VedaFont[Josefin Slab]+josefin_slab_i1+italic+100.woff2',
      'VedaFont[Josefin Slab]+josefin_slab_n3+normal+300.woff2',
      'VedaFont[Josefin Slab]+josefin_slab_i3+italic+300.woff2',
      'VedaFont[Josefin Slab]+josefin_slab_n4+normal+400.woff2',
      'VedaFont[Josefin Slab]+josefin_slab_i4+italic+400.woff2',
      'VedaFont[Josefin Slab]+josefin_slab_n6+normal+600.woff2',
      'VedaFont[Josefin Slab]+josefin_slab_i6+italic+600.woff2',
      'VedaFont[Josefin Slab]+josefin_slab_n7+normal+700.woff2',
      'VedaFont[Josefin Slab]+josefin_slab_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Kairos',
    value: 'kairos_n4',
    family: 'Kairos',
    shopifyFiles: [
      'VedaFont[Kairos]+kairos_n1+normal+100.woff2',
      'VedaFont[Kairos]+kairos_i1+italic+100.woff2',
      'VedaFont[Kairos]+kairos_n2+normal+200.woff2',
      'VedaFont[Kairos]+kairos_i2+italic+200.woff2',
      'VedaFont[Kairos]+kairos_n3+normal+300.woff2',
      'VedaFont[Kairos]+kairos_i3+italic+300.woff2',
      'VedaFont[Kairos]+kairos_n4+normal+400.woff2',
      'VedaFont[Kairos]+kairos_i4+italic+400.woff2',
      'VedaFont[Kairos]+kairos_n5+normal+500.woff2',
      'VedaFont[Kairos]+kairos_i5+italic+500.woff2',
      'VedaFont[Kairos]+kairos_n7+normal+700.woff2',
      'VedaFont[Kairos]+kairos_i7+italic+700.woff2',
      'VedaFont[Kairos]+kairos_n8+normal+800.woff2',
      'VedaFont[Kairos]+kairos_i8+italic+800.woff2',
      'VedaFont[Kairos]+kairos_n9+normal+900.woff2',
      'VedaFont[Kairos]+kairos_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Kalam',
    value: 'kalam_n4',
    family: 'Kalam',
    shopifyFiles: [
      'VedaFont[Kalam]+kalam_n3+normal+300.woff2',
      'VedaFont[Kalam]+kalam_n4+normal+400.woff2',
      'VedaFont[Kalam]+kalam_n7+normal+700.woff2',
    ],
  },
  {
    label: 'Karla',
    value: 'karla_n4',
    family: 'Karla',
    shopifyFiles: [
      'VedaFont[Karla]+karla_n4+normal+400.woff2',
      'VedaFont[Karla]+karla_i4+italic+400.woff2',
      'VedaFont[Karla]+karla_n7+normal+700.woff2',
      'VedaFont[Karla]+karla_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Kreon',
    value: 'kreon_n4',
    family: 'Kreon',
    shopifyFiles: [
      'VedaFont[Kreon]+kreon_n3+normal+300.woff2',
      'VedaFont[Kreon]+kreon_n4+normal+400.woff2',
      'VedaFont[Kreon]+kreon_n7+normal+700.woff2',
    ],
  },
  {
    label: 'Lato',
    value: 'lato_n4',
    family: 'Lato',
    shopifyFiles: [
      'VedaFont[Lato]+lato_n1+normal+100.woff2',
      'VedaFont[Lato]+lato_i1+italic+100.woff2',
      'VedaFont[Lato]+lato_n2+normal+200.woff2',
      'VedaFont[Lato]+lato_i2+italic+200.woff2',
      'VedaFont[Lato]+lato_n3+normal+300.woff2',
      'VedaFont[Lato]+lato_i3+italic+300.woff2',
      'VedaFont[Lato]+lato_n4+normal+400.woff2',
      'VedaFont[Lato]+lato_i4+italic+400.woff2',
      'VedaFont[Lato]+lato_n5+normal+500.woff2',
      'VedaFont[Lato]+lato_i5+italic+500.woff2',
      'VedaFont[Lato]+lato_n6+normal+600.woff2',
      'VedaFont[Lato]+lato_i6+italic+600.woff2',
      'VedaFont[Lato]+lato_n7+normal+700.woff2',
      'VedaFont[Lato]+lato_i7+italic+700.woff2',
      'VedaFont[Lato]+lato_n8+normal+800.woff2',
      'VedaFont[Lato]+lato_i8+italic+800.woff2',
      'VedaFont[Lato]+lato_n9+normal+900.woff2',
      'VedaFont[Lato]+lato_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Laurentian',
    value: 'laurentian_n4',
    family: 'Laurentian',
    shopifyFiles: [
      'VedaFont[Laurentian]+laurentian_n4+normal+400.woff2',
      'VedaFont[Laurentian]+laurentian_i4+italic+400.woff2',
      'VedaFont[Laurentian]+laurentian_n6+normal+600.woff2',
      'VedaFont[Laurentian]+laurentian_i6+italic+600.woff2',
      'VedaFont[Laurentian]+laurentian_n7+normal+700.woff2',
    ],
  },
  {
    label: 'Libelle',
    value: 'libelle_n4',
    family: 'Libelle',
    shopifyFiles: ['VedaFont[Libelle]+libelle_n4+normal+400.woff2'],
  },
  {
    label: 'Libre Baskerville',
    value: 'libre_baskerville_n4',
    family: 'Libre Baskerville',
    shopifyFiles: [
      'VedaFont[Libre Baskerville]+libre_baskerville_n4+normal+400.woff2',
      'VedaFont[Libre Baskerville]+libre_baskerville_i4+italic+400.woff2',
      'VedaFont[Libre Baskerville]+libre_baskerville_n7+normal+700.woff2',
    ],
  },
  {
    label: 'Libre Franklin',
    value: 'libre_franklin_n4',
    family: 'Libre Franklin',
    shopifyFiles: [
      'VedaFont[Libre Franklin]+libre_franklin_n1+normal+100.woff2',
      'VedaFont[Libre Franklin]+libre_franklin_i1+italic+100.woff2',
      'VedaFont[Libre Franklin]+libre_franklin_n2+normal+200.woff2',
      'VedaFont[Libre Franklin]+libre_franklin_i2+italic+200.woff2',
      'VedaFont[Libre Franklin]+libre_franklin_n3+normal+300.woff2',
      'VedaFont[Libre Franklin]+libre_franklin_i3+italic+300.woff2',
      'VedaFont[Libre Franklin]+libre_franklin_n4+normal+400.woff2',
      'VedaFont[Libre Franklin]+libre_franklin_i4+italic+400.woff2',
      'VedaFont[Libre Franklin]+libre_franklin_n5+normal+500.woff2',
      'VedaFont[Libre Franklin]+libre_franklin_i5+italic+500.woff2',
      'VedaFont[Libre Franklin]+libre_franklin_n6+normal+600.woff2',
      'VedaFont[Libre Franklin]+libre_franklin_i6+italic+600.woff2',
      'VedaFont[Libre Franklin]+libre_franklin_n7+normal+700.woff2',
      'VedaFont[Libre Franklin]+libre_franklin_i7+italic+700.woff2',
      'VedaFont[Libre Franklin]+libre_franklin_n8+normal+800.woff2',
      'VedaFont[Libre Franklin]+libre_franklin_i8+italic+800.woff2',
      'VedaFont[Libre Franklin]+libre_franklin_n9+normal+900.woff2',
      'VedaFont[Libre Franklin]+libre_franklin_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Linotype Didot',
    value: 'linotype_didot_n4',
    family: 'Linotype Didot',
    shopifyFiles: [
      'VedaFont[Linotype Didot]+linotype_didot_n4+normal+400.woff2',
      'VedaFont[Linotype Didot]+linotype_didot_i4+italic+400.woff2',
      'VedaFont[Linotype Didot]+linotype_didot_n7+normal+700.woff2',
      'VedaFont[Linotype Didot]+linotype_didot_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Linotype Gianotten',
    value: 'linotype_gianotten_n4',
    family: 'Linotype Gianotten',
    shopifyFiles: [
      'VedaFont[Linotype Gianotten]+linotype_gianotten_n3+normal+300.woff2',
      'VedaFont[Linotype Gianotten]+linotype_gianotten_i3+italic+300.woff2',
      'VedaFont[Linotype Gianotten]+linotype_gianotten_n4+normal+400.woff2',
      'VedaFont[Linotype Gianotten]+linotype_gianotten_i4+italic+400.woff2',
      'VedaFont[Linotype Gianotten]+linotype_gianotten_n5+normal+500.woff2',
      'VedaFont[Linotype Gianotten]+linotype_gianotten_i5+italic+500.woff2',
      'VedaFont[Linotype Gianotten]+linotype_gianotten_n7+normal+700.woff2',
      'VedaFont[Linotype Gianotten]+linotype_gianotten_i7+italic+700.woff2',
      'VedaFont[Linotype Gianotten]+linotype_gianotten_n8+normal+800.woff2',
      'VedaFont[Linotype Gianotten]+linotype_gianotten_i8+italic+800.woff2',
      'VedaFont[Linotype Gianotten]+linotype_gianotten_n9+normal+900.woff2',
    ],
  },
  {
    label: 'Linotype Really',
    value: 'linotype_really_n5',
    family: 'Linotype Really',
    shopifyFiles: [
      'VedaFont[Linotype Really]+linotype_really_n5+normal+500.woff2',
      'VedaFont[Linotype Really]+linotype_really_i5+italic+500.woff2',
      'VedaFont[Linotype Really]+linotype_really_n6+normal+600.woff2',
      'VedaFont[Linotype Really]+linotype_really_i6+italic+600.woff2',
    ],
  },
  {
    label: 'Linotype Syntax Serif',
    value: 'linotype_syntax_serif_n4',
    family: 'Linotype Syntax Serif',
    shopifyFiles: [
      'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_n3+normal+300.woff2',
      'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_i3+italic+300.woff2',
      'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_n4+normal+400.woff2',
      'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_i4+italic+400.woff2',
      'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_n5+normal+500.woff2',
      'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_i5+italic+500.woff2',
      'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_n7+normal+700.woff2',
      'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_i7+italic+700.woff2',
      'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_n8+normal+800.woff2',
      'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_i8+italic+800.woff2',
      'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_n9+normal+900.woff2',
      'VedaFont[Linotype Syntax Serif]+linotype_syntax_serif_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Lobster',
    value: 'lobster_n4',
    family: 'Lobster',
    shopifyFiles: ['VedaFont[Lobster]+lobster_n4+normal+400.woff2'],
  },
  {
    label: 'Lobster Two',
    value: 'lobster_two_n4',
    family: 'Lobster Two',
    shopifyFiles: [
      'VedaFont[Lobster Two]+lobster_two_n4+normal+400.woff2',
      'VedaFont[Lobster Two]+lobster_two_i4+italic+400.woff2',
      'VedaFont[Lobster Two]+lobster_two_n7+normal+700.woff2',
      'VedaFont[Lobster Two]+lobster_two_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Lora',
    value: 'lora_n4',
    family: 'Lora',
    shopifyFiles: [
      'VedaFont[Lora]+lora_n4+normal+400.woff2',
      'VedaFont[Lora]+lora_i4+italic+400.woff2',
      'VedaFont[Lora]+lora_n7+normal+700.woff2',
      'VedaFont[Lora]+lora_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Lucia',
    value: 'lucia_n4',
    family: 'Lucia',
    shopifyFiles: ['VedaFont[Lucia]+lucia_n4+normal+400.woff2'],
  },
  {
    label: 'Lucida Grande',
    value: 'lucida_grande_n4',
    family: 'Lucida Grande',
    shopifyFiles: [],
  },
  {
    label: 'Luthersche Fraktur',
    value: 'luthersche_fraktur_n4',
    family: 'Luthersche Fraktur',
    shopifyFiles: ['VedaFont[Luthersche Fraktur]+luthersche_fraktur_n4+normal+400.woff2'],
  },
  {
    label: 'Madera',
    value: 'madera_n4',
    family: 'Madera',
    shopifyFiles: [
      'VedaFont[Madera]+madera_n1+normal+100.woff2',
      'VedaFont[Madera]+madera_i1+italic+100.woff2',
      'VedaFont[Madera]+madera_n2+normal+200.woff2',
      'VedaFont[Madera]+madera_i2+italic+200.woff2',
      'VedaFont[Madera]+madera_n3+normal+300.woff2',
      'VedaFont[Madera]+madera_i3+italic+300.woff2',
      'VedaFont[Madera]+madera_n4+normal+400.woff2',
      'VedaFont[Madera]+madera_i4+italic+400.woff2',
      'VedaFont[Madera]+madera_n5+normal+500.woff2',
      'VedaFont[Madera]+madera_i5+italic+500.woff2',
      'VedaFont[Madera]+madera_n6+normal+600.woff2',
      'VedaFont[Madera]+madera_i6+italic+600.woff2',
      'VedaFont[Madera]+madera_n7+normal+700.woff2',
      'VedaFont[Madera]+madera_i7+italic+700.woff2',
      'VedaFont[Madera]+madera_n8+normal+800.woff2',
      'VedaFont[Madera]+madera_i8+italic+800.woff2',
    ],
  },
  {
    label: 'Malabar',
    value: 'malabar_n4',
    family: 'Malabar',
    shopifyFiles: [
      'VedaFont[Malabar]+malabar_n4+normal+400.woff2',
      'VedaFont[Malabar]+malabar_i4+italic+400.woff2',
      'VedaFont[Malabar]+malabar_n7+normal+700.woff2',
      'VedaFont[Malabar]+malabar_i7+italic+700.woff2',
      'VedaFont[Malabar]+malabar_n9+normal+900.woff2',
      'VedaFont[Malabar]+malabar_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Mariposa Sans',
    value: 'mariposa_sans_n4',
    family: 'Mariposa Sans',
    shopifyFiles: [
      'VedaFont[Mariposa Sans]+mariposa_sans_n4+normal+400.woff2',
      'VedaFont[Mariposa Sans]+mariposa_sans_i4+italic+400.woff2',
      'VedaFont[Mariposa Sans]+mariposa_sans_n5+normal+500.woff2',
      'VedaFont[Mariposa Sans]+mariposa_sans_n7+normal+700.woff2',
      'VedaFont[Mariposa Sans]+mariposa_sans_n9+normal+900.woff2',
    ],
  },
  {
    label: 'Maven Pro',
    value: 'maven_pro_n4',
    family: 'Maven Pro',
    shopifyFiles: [
      'VedaFont[Maven Pro]+maven_pro_n4+normal+400.woff2',
      'VedaFont[Maven Pro]+maven_pro_n5+normal+500.woff2',
      'VedaFont[Maven Pro]+maven_pro_n7+normal+700.woff2',
      'VedaFont[Maven Pro]+maven_pro_n9+normal+900.woff2',
    ],
  },
  {
    label: 'Megrim',
    value: 'megrim_n4',
    family: 'Megrim',
    shopifyFiles: ['VedaFont[Megrim]+megrim_n4+normal+400.woff2'],
  },
  {
    label: 'Melior',
    value: 'melior_n4',
    family: 'Melior',
    shopifyFiles: [
      'VedaFont[Melior]+melior_n4+normal+400.woff2',
      'VedaFont[Melior]+melior_i4+italic+400.woff2',
      'VedaFont[Melior]+melior_n7+normal+700.woff2',
      'VedaFont[Melior]+melior_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Memphis',
    value: 'memphis_n5',
    family: 'Memphis',
    shopifyFiles: [
      'VedaFont[Memphis]+memphis_n3+normal+300.woff2',
      'VedaFont[Memphis]+memphis_i3+italic+300.woff2',
      'VedaFont[Memphis]+memphis_n5+normal+500.woff2',
      'VedaFont[Memphis]+memphis_i5+italic+500.woff2',
      'VedaFont[Memphis]+memphis_n7+normal+700.woff2',
      'VedaFont[Memphis]+memphis_i7+italic+700.woff2',
      'VedaFont[Memphis]+memphis_n8+normal+800.woff2',
    ],
  },
  {
    label: 'Memphis Soft Rounded',
    value: 'memphis_soft_rounded_n5',
    family: 'Memphis Soft Rounded',
    shopifyFiles: [
      'VedaFont[Memphis Soft Rounded]+memphis_soft_rounded_n5+normal+500.woff2',
      'VedaFont[Memphis Soft Rounded]+memphis_soft_rounded_n7+normal+700.woff2',
      'VedaFont[Memphis Soft Rounded]+memphis_soft_rounded_n8+normal+800.woff2',
    ],
  },
  {
    label: 'Mentor Sans',
    value: 'mentor_sans_n4',
    family: 'Mentor Sans',
    shopifyFiles: [
      'VedaFont[Mentor Sans]+mentor_sans_n3+normal+300.woff2',
      'VedaFont[Mentor Sans]+mentor_sans_i3+italic+300.woff2',
      'VedaFont[Mentor Sans]+mentor_sans_n4+normal+400.woff2',
      'VedaFont[Mentor Sans]+mentor_sans_i4+italic+400.woff2',
      'VedaFont[Mentor Sans]+mentor_sans_n7+normal+700.woff2',
      'VedaFont[Mentor Sans]+mentor_sans_i7+italic+700.woff2',
      'VedaFont[Mentor Sans]+mentor_sans_n9+normal+900.woff2',
      'VedaFont[Mentor Sans]+mentor_sans_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Merriweather Sans',
    value: 'merriweather_sans_n4',
    family: 'Merriweather Sans',
    shopifyFiles: [
      'VedaFont[Merriweather Sans]+merriweather_sans_n3+normal+300.woff2',
      'VedaFont[Merriweather Sans]+merriweather_sans_i3+italic+300.woff2',
      'VedaFont[Merriweather Sans]+merriweather_sans_n4+normal+400.woff2',
      'VedaFont[Merriweather Sans]+merriweather_sans_i4+italic+400.woff2',
      'VedaFont[Merriweather Sans]+merriweather_sans_n7+normal+700.woff2',
      'VedaFont[Merriweather Sans]+merriweather_sans_i7+italic+700.woff2',
      'VedaFont[Merriweather Sans]+merriweather_sans_n8+normal+800.woff2',
      'VedaFont[Merriweather Sans]+merriweather_sans_i8+italic+800.woff2',
    ],
  },
  {
    label: 'Metro Nova',
    value: 'metro_nova_n4',
    family: 'Metro Nova',
    shopifyFiles: [
      'VedaFont[Metro Nova]+metro_nova_n2+normal+200.woff2',
      'VedaFont[Metro Nova]+metro_nova_i2+italic+200.woff2',
      'VedaFont[Metro Nova]+metro_nova_n3+normal+300.woff2',
      'VedaFont[Metro Nova]+metro_nova_i3+italic+300.woff2',
      'VedaFont[Metro Nova]+metro_nova_n4+normal+400.woff2',
      'VedaFont[Metro Nova]+metro_nova_i4+italic+400.woff2',
      'VedaFont[Metro Nova]+metro_nova_n5+normal+500.woff2',
      'VedaFont[Metro Nova]+metro_nova_i5+italic+500.woff2',
      'VedaFont[Metro Nova]+metro_nova_n7+normal+700.woff2',
      'VedaFont[Metro Nova]+metro_nova_i7+italic+700.woff2',
      'VedaFont[Metro Nova]+metro_nova_n8+normal+800.woff2',
      'VedaFont[Metro Nova]+metro_nova_i8+italic+800.woff2',
      'VedaFont[Metro Nova]+metro_nova_n9+normal+900.woff2',
      'VedaFont[Metro Nova]+metro_nova_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Modern No 20',
    value: 'modern_no_20_n4',
    family: 'Modern No 20',
    shopifyFiles: ['VedaFont[Modern No 20]+modern_no_20_n4+normal+400.woff2', 'VedaFont[Modern No 20]+modern_no_20_i4+italic+400.woff2'],
  },
  {
    label: 'Monaco',
    value: 'monaco_n4',
    family: 'Monaco',
    shopifyFiles: [],
  },
  {
    label: 'Monotype Baskerville',
    value: 'monotype_baskerville_n4',
    family: 'Monotype Baskerville',
    shopifyFiles: [
      'VedaFont[Monotype Baskerville]+monotype_baskerville_n4+normal+400.woff2',
      'VedaFont[Monotype Baskerville]+monotype_baskerville_i4+italic+400.woff2',
      'VedaFont[Monotype Baskerville]+monotype_baskerville_n6+normal+600.woff2',
      'VedaFont[Monotype Baskerville]+monotype_baskerville_i6+italic+600.woff2',
      'VedaFont[Monotype Baskerville]+monotype_baskerville_n7+normal+700.woff2',
      'VedaFont[Monotype Baskerville]+monotype_baskerville_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Monotype Bodoni',
    value: 'monotype_bodoni_n4',
    family: 'Monotype Bodoni',
    shopifyFiles: [
      'VedaFont[Monotype Bodoni]+monotype_bodoni_n4+normal+400.woff2',
      'VedaFont[Monotype Bodoni]+monotype_bodoni_i4+italic+400.woff2',
      'VedaFont[Monotype Bodoni]+monotype_bodoni_n5+normal+500.woff2',
      'VedaFont[Monotype Bodoni]+monotype_bodoni_i5+italic+500.woff2',
      'VedaFont[Monotype Bodoni]+monotype_bodoni_n7+normal+700.woff2',
      'VedaFont[Monotype Bodoni]+monotype_bodoni_i7+italic+700.woff2',
      'VedaFont[Monotype Bodoni]+monotype_bodoni_n8+normal+800.woff2',
      'VedaFont[Monotype Bodoni]+monotype_bodoni_i8+italic+800.woff2',
      'VedaFont[Monotype Bodoni]+monotype_bodoni_n9+normal+900.woff2',
      'VedaFont[Monotype Bodoni]+monotype_bodoni_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Monotype Century Old Style',
    value: 'monotype_century_old_style_n5',
    family: 'Monotype Century Old Style',
    shopifyFiles: [
      'VedaFont[Monotype Century Old Style]+monotype_century_old_style_n5+normal+500.woff2',
      'VedaFont[Monotype Century Old Style]+monotype_century_old_style_i5+italic+500.woff2',
      'VedaFont[Monotype Century Old Style]+monotype_century_old_style_n7+normal+700.woff2',
      'VedaFont[Monotype Century Old Style]+monotype_century_old_style_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Monotype Goudy',
    value: 'monotype_goudy_n4',
    family: 'Monotype Goudy',
    shopifyFiles: [
      'VedaFont[Monotype Goudy]+monotype_goudy_n4+normal+400.woff2',
      'VedaFont[Monotype Goudy]+monotype_goudy_i4+italic+400.woff2',
      'VedaFont[Monotype Goudy]+monotype_goudy_n7+normal+700.woff2',
      'VedaFont[Monotype Goudy]+monotype_goudy_i7+italic+700.woff2',
      'VedaFont[Monotype Goudy]+monotype_goudy_n8+normal+800.woff2',
    ],
  },
  {
    label: 'Monotype Goudy Modern',
    value: 'monotype_goudy_modern_n4',
    family: 'Monotype Goudy Modern',
    shopifyFiles: [
      'VedaFont[Monotype Goudy Modern]+monotype_goudy_modern_n4+normal+400.woff2',
      'VedaFont[Monotype Goudy Modern]+monotype_goudy_modern_i4+italic+400.woff2',
      'VedaFont[Monotype Goudy Modern]+monotype_goudy_modern_n7+normal+700.woff2',
      'VedaFont[Monotype Goudy Modern]+monotype_goudy_modern_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Monotype Italian Old Style',
    value: 'monotype_italian_old_style_n4',
    family: 'Monotype Italian Old Style',
    shopifyFiles: [
      'VedaFont[Monotype Italian Old Style]+monotype_italian_old_style_n4+normal+400.woff2',
      'VedaFont[Monotype Italian Old Style]+monotype_italian_old_style_i4+italic+400.woff2',
      'VedaFont[Monotype Italian Old Style]+monotype_italian_old_style_n7+normal+700.woff2',
      'VedaFont[Monotype Italian Old Style]+monotype_italian_old_style_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Monotype New Clarendon',
    value: 'monotype_new_clarendon_n5',
    family: 'Monotype New Clarendon',
    shopifyFiles: [
      'VedaFont[Monotype New Clarendon]+monotype_new_clarendon_n5+normal+500.woff2',
      'VedaFont[Monotype New Clarendon]+monotype_new_clarendon_n7+normal+700.woff2',
    ],
  },
  {
    label: 'Monotype News Gothic',
    value: 'monotype_news_gothic_n4',
    family: 'Monotype News Gothic',
    shopifyFiles: [
      'VedaFont[Monotype News Gothic]+monotype_news_gothic_n4+normal+400.woff2',
      'VedaFont[Monotype News Gothic]+monotype_news_gothic_i4+italic+400.woff2',
      'VedaFont[Monotype News Gothic]+monotype_news_gothic_n7+normal+700.woff2',
      'VedaFont[Monotype News Gothic]+monotype_news_gothic_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Monotype Sabon',
    value: 'monotype_sabon_n4',
    family: 'Monotype Sabon',
    shopifyFiles: [
      'VedaFont[Monotype Sabon]+monotype_sabon_n4+normal+400.woff2',
      'VedaFont[Monotype Sabon]+monotype_sabon_i4+italic+400.woff2',
      'VedaFont[Monotype Sabon]+monotype_sabon_n6+normal+600.woff2',
      'VedaFont[Monotype Sabon]+monotype_sabon_i6+italic+600.woff2',
    ],
  },
  {
    label: 'Montserrat',
    value: 'montserrat_n4',
    family: 'Montserrat',
    shopifyFiles: [
      'VedaFont[Montserrat]+montserrat_n1+normal+100.woff2',
      'VedaFont[Montserrat]+montserrat_i1+italic+100.woff2',
      'VedaFont[Montserrat]+montserrat_n2+normal+200.woff2',
      'VedaFont[Montserrat]+montserrat_i2+italic+200.woff2',
      'VedaFont[Montserrat]+montserrat_n3+normal+300.woff2',
      'VedaFont[Montserrat]+montserrat_i3+italic+300.woff2',
      'VedaFont[Montserrat]+montserrat_n4+normal+400.woff2',
      'VedaFont[Montserrat]+montserrat_i4+italic+400.woff2',
      'VedaFont[Montserrat]+montserrat_n5+normal+500.woff2',
      'VedaFont[Montserrat]+montserrat_i5+italic+500.woff2',
      'VedaFont[Montserrat]+montserrat_n6+normal+600.woff2',
      'VedaFont[Montserrat]+montserrat_i6+italic+600.woff2',
      'VedaFont[Montserrat]+montserrat_n7+normal+700.woff2',
      'VedaFont[Montserrat]+montserrat_i7+italic+700.woff2',
      'VedaFont[Montserrat]+montserrat_n8+normal+800.woff2',
      'VedaFont[Montserrat]+montserrat_i8+italic+800.woff2',
      'VedaFont[Montserrat]+montserrat_n9+normal+900.woff2',
      'VedaFont[Montserrat]+montserrat_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Mouse Memoirs',
    value: 'mouse_memoirs_n4',
    family: 'Mouse Memoirs',
    shopifyFiles: ['VedaFont[Mouse Memoirs]+mouse_memoirs_n4+normal+400.woff2'],
  },
  {
    label: 'Muli',
    value: 'muli_n4',
    family: 'Muli',
    shopifyFiles: [
      'VedaFont[Muli]+muli_n2+normal+200.woff2',
      'VedaFont[Muli]+muli_i2+italic+200.woff2',
      'VedaFont[Muli]+muli_n3+normal+300.woff2',
      'VedaFont[Muli]+muli_i3+italic+300.woff2',
      'VedaFont[Muli]+muli_n4+normal+400.woff2',
      'VedaFont[Muli]+muli_i4+italic+400.woff2',
      'VedaFont[Muli]+muli_n6+normal+600.woff2',
      'VedaFont[Muli]+muli_i6+italic+600.woff2',
      'VedaFont[Muli]+muli_n7+normal+700.woff2',
      'VedaFont[Muli]+muli_i7+italic+700.woff2',
      'VedaFont[Muli]+muli_n8+normal+800.woff2',
      'VedaFont[Muli]+muli_i8+italic+800.woff2',
      'VedaFont[Muli]+muli_n9+normal+900.woff2',
      'VedaFont[Muli]+muli_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Mundo Sans',
    value: 'mundo_sans_n4',
    family: 'Mundo Sans',
    shopifyFiles: [
      'VedaFont[Mundo Sans]+mundo_sans_n2+normal+200.woff2',
      'VedaFont[Mundo Sans]+mundo_sans_i2+italic+200.woff2',
      'VedaFont[Mundo Sans]+mundo_sans_n3+normal+300.woff2',
      'VedaFont[Mundo Sans]+mundo_sans_i3+italic+300.woff2',
      'VedaFont[Mundo Sans]+mundo_sans_n4+normal+400.woff2',
      'VedaFont[Mundo Sans]+mundo_sans_i4+italic+400.woff2',
      'VedaFont[Mundo Sans]+mundo_sans_n5+normal+500.woff2',
      'VedaFont[Mundo Sans]+mundo_sans_i5+italic+500.woff2',
      'VedaFont[Mundo Sans]+mundo_sans_n7+normal+700.woff2',
      'VedaFont[Mundo Sans]+mundo_sans_i7+italic+700.woff2',
      'VedaFont[Mundo Sans]+mundo_sans_n8+normal+800.woff2',
      'VedaFont[Mundo Sans]+mundo_sans_i8+italic+800.woff2',
      'VedaFont[Mundo Sans]+mundo_sans_n9+normal+900.woff2',
      'VedaFont[Mundo Sans]+mundo_sans_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Neo Sans',
    value: 'neo_sans_n4',
    family: 'Neo Sans',
    shopifyFiles: [
      'VedaFont[Neo Sans]+neo_sans_n3+normal+300.woff2',
      'VedaFont[Neo Sans]+neo_sans_i3+italic+300.woff2',
      'VedaFont[Neo Sans]+neo_sans_n4+normal+400.woff2',
      'VedaFont[Neo Sans]+neo_sans_i4+italic+400.woff2',
      'VedaFont[Neo Sans]+neo_sans_n5+normal+500.woff2',
      'VedaFont[Neo Sans]+neo_sans_i5+italic+500.woff2',
      'VedaFont[Neo Sans]+neo_sans_n7+normal+700.woff2',
      'VedaFont[Neo Sans]+neo_sans_i7+italic+700.woff2',
      'VedaFont[Neo Sans]+neo_sans_n8+normal+800.woff2',
      'VedaFont[Neo Sans]+neo_sans_i8+italic+800.woff2',
      'VedaFont[Neo Sans]+neo_sans_n9+normal+900.woff2',
      'VedaFont[Neo Sans]+neo_sans_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Neue Aachen',
    value: 'neue_aachen_n4',
    family: 'Neue Aachen',
    shopifyFiles: [
      'VedaFont[Neue Aachen]+neue_aachen_n1+normal+100.woff2',
      'VedaFont[Neue Aachen]+neue_aachen_i1+italic+100.woff2',
      'VedaFont[Neue Aachen]+neue_aachen_n2+normal+200.woff2',
      'VedaFont[Neue Aachen]+neue_aachen_i2+italic+200.woff2',
      'VedaFont[Neue Aachen]+neue_aachen_n3+normal+300.woff2',
      'VedaFont[Neue Aachen]+neue_aachen_i3+italic+300.woff2',
      'VedaFont[Neue Aachen]+neue_aachen_n4+normal+400.woff2',
      'VedaFont[Neue Aachen]+neue_aachen_i4+italic+400.woff2',
      'VedaFont[Neue Aachen]+neue_aachen_n5+normal+500.woff2',
      'VedaFont[Neue Aachen]+neue_aachen_i5+italic+500.woff2',
      'VedaFont[Neue Aachen]+neue_aachen_n6+normal+600.woff2',
      'VedaFont[Neue Aachen]+neue_aachen_i6+italic+600.woff2',
      'VedaFont[Neue Aachen]+neue_aachen_n7+normal+700.woff2',
      'VedaFont[Neue Aachen]+neue_aachen_i7+italic+700.woff2',
      'VedaFont[Neue Aachen]+neue_aachen_n8+normal+800.woff2',
      'VedaFont[Neue Aachen]+neue_aachen_i8+italic+800.woff2',
      'VedaFont[Neue Aachen]+neue_aachen_n9+normal+900.woff2',
      'VedaFont[Neue Aachen]+neue_aachen_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Neue Frutiger 1450',
    value: 'neue_frutiger_1450_n4',
    family: 'Neue Frutiger 1450',
    shopifyFiles: [
      'VedaFont[Neue Frutiger 1450]+neue_frutiger_1450_n3+normal+300.woff2',
      'VedaFont[Neue Frutiger 1450]+neue_frutiger_1450_i3+italic+300.woff2',
      'VedaFont[Neue Frutiger 1450]+neue_frutiger_1450_n4+normal+400.woff2',
      'VedaFont[Neue Frutiger 1450]+neue_frutiger_1450_i4+italic+400.woff2',
      'VedaFont[Neue Frutiger 1450]+neue_frutiger_1450_n6+normal+600.woff2',
      'VedaFont[Neue Frutiger 1450]+neue_frutiger_1450_i6+italic+600.woff2',
      'VedaFont[Neue Frutiger 1450]+neue_frutiger_1450_n7+normal+700.woff2',
      'VedaFont[Neue Frutiger 1450]+neue_frutiger_1450_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Neue Haas Unica',
    value: 'neue_haas_unica_n4',
    family: 'Neue Haas Unica',
    shopifyFiles: [
      'VedaFont[Neue Haas Unica]+neue_haas_unica_n1+normal+100.woff2',
      'VedaFont[Neue Haas Unica]+neue_haas_unica_i1+italic+100.woff2',
      'VedaFont[Neue Haas Unica]+neue_haas_unica_n2+normal+200.woff2',
      'VedaFont[Neue Haas Unica]+neue_haas_unica_i2+italic+200.woff2',
      'VedaFont[Neue Haas Unica]+neue_haas_unica_n3+normal+300.woff2',
      'VedaFont[Neue Haas Unica]+neue_haas_unica_i3+italic+300.woff2',
      'VedaFont[Neue Haas Unica]+neue_haas_unica_n4+normal+400.woff2',
      'VedaFont[Neue Haas Unica]+neue_haas_unica_i4+italic+400.woff2',
      'VedaFont[Neue Haas Unica]+neue_haas_unica_n5+normal+500.woff2',
      'VedaFont[Neue Haas Unica]+neue_haas_unica_i5+italic+500.woff2',
      'VedaFont[Neue Haas Unica]+neue_haas_unica_n6+normal+600.woff2',
      'VedaFont[Neue Haas Unica]+neue_haas_unica_i6+italic+600.woff2',
      'VedaFont[Neue Haas Unica]+neue_haas_unica_n7+normal+700.woff2',
      'VedaFont[Neue Haas Unica]+neue_haas_unica_i7+italic+700.woff2',
      'VedaFont[Neue Haas Unica]+neue_haas_unica_n8+normal+800.woff2',
      'VedaFont[Neue Haas Unica]+neue_haas_unica_i8+italic+800.woff2',
      'VedaFont[Neue Haas Unica]+neue_haas_unica_n9+normal+900.woff2',
      'VedaFont[Neue Haas Unica]+neue_haas_unica_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Neue Plak',
    value: 'neue_plak_n4',
    family: 'Neue Plak',
    shopifyFiles: [
      'VedaFont[Neue Plak]+neue_plak_n2+normal+200.woff2',
      'VedaFont[Neue Plak]+neue_plak_i2+italic+200.woff2',
      'VedaFont[Neue Plak]+neue_plak_n3+normal+300.woff2',
      'VedaFont[Neue Plak]+neue_plak_i3+italic+300.woff2',
      'VedaFont[Neue Plak]+neue_plak_n4+normal+400.woff2',
      'VedaFont[Neue Plak]+neue_plak_i4+italic+400.woff2',
      'VedaFont[Neue Plak]+neue_plak_n6+normal+600.woff2',
      'VedaFont[Neue Plak]+neue_plak_i6+italic+600.woff2',
      'VedaFont[Neue Plak]+neue_plak_n7+normal+700.woff2',
      'VedaFont[Neue Plak]+neue_plak_i7+italic+700.woff2',
      'VedaFont[Neue Plak]+neue_plak_n9+normal+900.woff2',
      'VedaFont[Neue Plak]+neue_plak_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Neue Swift',
    value: 'neue_swift_n4',
    family: 'Neue Swift',
    shopifyFiles: [
      'VedaFont[Neue Swift]+neue_swift_n3+normal+300.woff2',
      'VedaFont[Neue Swift]+neue_swift_i3+italic+300.woff2',
      'VedaFont[Neue Swift]+neue_swift_n4+normal+400.woff2',
      'VedaFont[Neue Swift]+neue_swift_i4+italic+400.woff2',
      'VedaFont[Neue Swift]+neue_swift_n5+normal+500.woff2',
      'VedaFont[Neue Swift]+neue_swift_i5+italic+500.woff2',
      'VedaFont[Neue Swift]+neue_swift_n6+normal+600.woff2',
      'VedaFont[Neue Swift]+neue_swift_i6+italic+600.woff2',
      'VedaFont[Neue Swift]+neue_swift_n7+normal+700.woff2',
      'VedaFont[Neue Swift]+neue_swift_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Neuton',
    value: 'neuton_n4',
    family: 'Neuton',
    shopifyFiles: [
      'VedaFont[Neuton]+neuton_n2+normal+200.woff2',
      'VedaFont[Neuton]+neuton_n3+normal+300.woff2',
      'VedaFont[Neuton]+neuton_n4+normal+400.woff2',
      'VedaFont[Neuton]+neuton_i4+italic+400.woff2',
      'VedaFont[Neuton]+neuton_n7+normal+700.woff2',
      'VedaFont[Neuton]+neuton_n8+normal+800.woff2',
    ],
  },
  {
    label: 'Neuzeit Office',
    value: 'neuzeit_office_n4',
    family: 'Neuzeit Office',
    shopifyFiles: [
      'VedaFont[Neuzeit Office]+neuzeit_office_n4+normal+400.woff2',
      'VedaFont[Neuzeit Office]+neuzeit_office_i4+italic+400.woff2',
      'VedaFont[Neuzeit Office]+neuzeit_office_n7+normal+700.woff2',
      'VedaFont[Neuzeit Office]+neuzeit_office_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Neuzeit Office Soft Rounded',
    value: 'neuzeit_office_soft_rounded_n4',
    family: 'Neuzeit Office Soft Rounded',
    shopifyFiles: [
      'VedaFont[Neuzeit Office Soft Rounded]+neuzeit_office_soft_rounded_n4+normal+400.woff2',
      'VedaFont[Neuzeit Office Soft Rounded]+neuzeit_office_soft_rounded_n7+normal+700.woff2',
    ],
  },
  {
    label: 'Neuzeit S',
    value: 'neuzeit_s_n4',
    family: 'Neuzeit S',
    shopifyFiles: ['VedaFont[Neuzeit S]+neuzeit_s_n4+normal+400.woff2', 'VedaFont[Neuzeit S]+neuzeit_s_n9+normal+900.woff2'],
  },
  {
    label: 'New Century Schoolbook',
    value: 'new_century_schoolbook_n4',
    family: 'New Century Schoolbook',
    shopifyFiles: [
      'VedaFont[New Century Schoolbook]+new_century_schoolbook_n4+normal+400.woff2',
      'VedaFont[New Century Schoolbook]+new_century_schoolbook_i4+italic+400.woff2',
      'VedaFont[New Century Schoolbook]+new_century_schoolbook_n7+normal+700.woff2',
      'VedaFont[New Century Schoolbook]+new_century_schoolbook_i7+italic+700.woff2',
    ],
  },
  {
    label: 'News 702',
    value: 'news_702_n4',
    family: 'News 702',
    shopifyFiles: [
      'VedaFont[News 702]+news_702_n4+normal+400.woff2',
      'VedaFont[News 702]+news_702_i4+italic+400.woff2',
      'VedaFont[News 702]+news_702_n7+normal+700.woff2',
      'VedaFont[News 702]+news_702_i7+italic+700.woff2',
    ],
  },
  {
    label: 'News 705',
    value: 'news_705_n4',
    family: 'News 705',
    shopifyFiles: [
      'VedaFont[News 705]+news_705_n4+normal+400.woff2',
      'VedaFont[News 705]+news_705_i4+italic+400.woff2',
      'VedaFont[News 705]+news_705_n7+normal+700.woff2',
      'VedaFont[News 705]+news_705_i7+italic+700.woff2',
    ],
  },
  {
    label: 'News Cycle',
    value: 'news_cycle_n4',
    family: 'News Cycle',
    shopifyFiles: ['VedaFont[News Cycle]+news_cycle_n4+normal+400.woff2', 'VedaFont[News Cycle]+news_cycle_n7+normal+700.woff2'],
  },
  {
    label: 'News Gothic No 2',
    value: 'news_gothic_no_2_n4',
    family: 'News Gothic No 2',
    shopifyFiles: [
      'VedaFont[News Gothic No 2]+news_gothic_no_2_n2+normal+200.woff2',
      'VedaFont[News Gothic No 2]+news_gothic_no_2_i2+italic+200.woff2',
      'VedaFont[News Gothic No 2]+news_gothic_no_2_n3+normal+300.woff2',
      'VedaFont[News Gothic No 2]+news_gothic_no_2_i3+italic+300.woff2',
      'VedaFont[News Gothic No 2]+news_gothic_no_2_n4+normal+400.woff2',
      'VedaFont[News Gothic No 2]+news_gothic_no_2_i4+italic+400.woff2',
      'VedaFont[News Gothic No 2]+news_gothic_no_2_n5+normal+500.woff2',
      'VedaFont[News Gothic No 2]+news_gothic_no_2_i5+italic+500.woff2',
      'VedaFont[News Gothic No 2]+news_gothic_no_2_n7+normal+700.woff2',
      'VedaFont[News Gothic No 2]+news_gothic_no_2_i7+italic+700.woff2',
      'VedaFont[News Gothic No 2]+news_gothic_no_2_n8+normal+800.woff2',
      'VedaFont[News Gothic No 2]+news_gothic_no_2_i8+italic+800.woff2',
    ],
  },
  {
    label: 'News Plantin',
    value: 'news_plantin_n4',
    family: 'News Plantin',
    shopifyFiles: [
      'VedaFont[News Plantin]+news_plantin_n4+normal+400.woff2',
      'VedaFont[News Plantin]+news_plantin_i4+italic+400.woff2',
      'VedaFont[News Plantin]+news_plantin_n7+normal+700.woff2',
      'VedaFont[News Plantin]+news_plantin_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Nobile',
    value: 'nobile_n4',
    family: 'Nobile',
    shopifyFiles: [
      'VedaFont[Nobile]+nobile_n4+normal+400.woff2',
      'VedaFont[Nobile]+nobile_i4+italic+400.woff2',
      'VedaFont[Nobile]+nobile_n5+normal+500.woff2',
      'VedaFont[Nobile]+nobile_i5+italic+500.woff2',
      'VedaFont[Nobile]+nobile_n7+normal+700.woff2',
      'VedaFont[Nobile]+nobile_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Noticia Text',
    value: 'noticia_text_n4',
    family: 'Noticia Text',
    shopifyFiles: [
      'VedaFont[Noticia Text]+noticia_text_n4+normal+400.woff2',
      'VedaFont[Noticia Text]+noticia_text_i4+italic+400.woff2',
      'VedaFont[Noticia Text]+noticia_text_n7+normal+700.woff2',
      'VedaFont[Noticia Text]+noticia_text_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Noto Serif',
    value: 'noto_serif_n4',
    family: 'Noto Serif',
    shopifyFiles: [
      'VedaFont[Noto Serif]+noto_serif_n4+normal+400.woff2',
      'VedaFont[Noto Serif]+noto_serif_i4+italic+400.woff2',
      'VedaFont[Noto Serif]+noto_serif_n7+normal+700.woff2',
      'VedaFont[Noto Serif]+noto_serif_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Nunito',
    value: 'nunito_n4',
    family: 'Nunito',
    shopifyFiles: [
      'VedaFont[Nunito]+nunito_n2+normal+200.woff2',
      'VedaFont[Nunito]+nunito_i2+italic+200.woff2',
      'VedaFont[Nunito]+nunito_n3+normal+300.woff2',
      'VedaFont[Nunito]+nunito_i3+italic+300.woff2',
      'VedaFont[Nunito]+nunito_n4+normal+400.woff2',
      'VedaFont[Nunito]+nunito_i4+italic+400.woff2',
      'VedaFont[Nunito]+nunito_n6+normal+600.woff2',
      'VedaFont[Nunito]+nunito_i6+italic+600.woff2',
      'VedaFont[Nunito]+nunito_n7+normal+700.woff2',
      'VedaFont[Nunito]+nunito_i7+italic+700.woff2',
      'VedaFont[Nunito]+nunito_n8+normal+800.woff2',
      'VedaFont[Nunito]+nunito_i8+italic+800.woff2',
      'VedaFont[Nunito]+nunito_n9+normal+900.woff2',
      'VedaFont[Nunito]+nunito_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Nunito Sans',
    value: 'nunito_sans_n4',
    family: 'Nunito Sans',
    shopifyFiles: [
      'VedaFont[Nunito Sans]+nunito_sans_n2+normal+200.woff2',
      'VedaFont[Nunito Sans]+nunito_sans_i2+italic+200.woff2',
      'VedaFont[Nunito Sans]+nunito_sans_n3+normal+300.woff2',
      'VedaFont[Nunito Sans]+nunito_sans_i3+italic+300.woff2',
      'VedaFont[Nunito Sans]+nunito_sans_n4+normal+400.woff2',
      'VedaFont[Nunito Sans]+nunito_sans_i4+italic+400.woff2',
      'VedaFont[Nunito Sans]+nunito_sans_n6+normal+600.woff2',
      'VedaFont[Nunito Sans]+nunito_sans_i6+italic+600.woff2',
      'VedaFont[Nunito Sans]+nunito_sans_n7+normal+700.woff2',
      'VedaFont[Nunito Sans]+nunito_sans_i7+italic+700.woff2',
      'VedaFont[Nunito Sans]+nunito_sans_n8+normal+800.woff2',
      'VedaFont[Nunito Sans]+nunito_sans_i8+italic+800.woff2',
      'VedaFont[Nunito Sans]+nunito_sans_n9+normal+900.woff2',
      'VedaFont[Nunito Sans]+nunito_sans_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Old Standard TT',
    value: 'old_standard_tt_n4',
    family: 'Old Standard TT',
    shopifyFiles: [
      'VedaFont[Old Standard TT]+old_standard_tt_n4+normal+400.woff2',
      'VedaFont[Old Standard TT]+old_standard_tt_i4+italic+400.woff2',
      'VedaFont[Old Standard TT]+old_standard_tt_n7+normal+700.woff2',
    ],
  },
  {
    label: 'Open Sans',
    value: 'open_sans_n4',
    family: 'Open Sans',
    shopifyFiles: [
      'VedaFont[Open Sans]+open_sans_n3+normal+300.woff2',
      'VedaFont[Open Sans]+open_sans_i3+italic+300.woff2',
      'VedaFont[Open Sans]+open_sans_n4+normal+400.woff2',
      'VedaFont[Open Sans]+open_sans_i4+italic+400.woff2',
      'VedaFont[Open Sans]+open_sans_n6+normal+600.woff2',
      'VedaFont[Open Sans]+open_sans_i6+italic+600.woff2',
      'VedaFont[Open Sans]+open_sans_n7+normal+700.woff2',
      'VedaFont[Open Sans]+open_sans_i7+italic+700.woff2',
      'VedaFont[Open Sans]+open_sans_n8+normal+800.woff2',
      'VedaFont[Open Sans]+open_sans_i8+italic+800.woff2',
    ],
  },
  {
    label: 'Open Sans Condensed',
    value: 'open_sans_condensed_n3',
    family: 'Open Sans Condensed',
    shopifyFiles: [
      'VedaFont[Open Sans Condensed]+open_sans_condensed_n3+normal+300.woff2',
      'VedaFont[Open Sans Condensed]+open_sans_condensed_i3+italic+300.woff2',
      'VedaFont[Open Sans Condensed]+open_sans_condensed_n7+normal+700.woff2',
    ],
  },
  {
    label: 'Optima nova',
    value: 'optima_nova_n4',
    family: 'Optima nova',
    shopifyFiles: [
      'VedaFont[Optima nova]+optima_nova_n3+normal+300.woff2',
      'VedaFont[Optima nova]+optima_nova_i3+italic+300.woff2',
      'VedaFont[Optima nova]+optima_nova_n4+normal+400.woff2',
      'VedaFont[Optima nova]+optima_nova_i4+italic+400.woff2',
      'VedaFont[Optima nova]+optima_nova_n5+normal+500.woff2',
      'VedaFont[Optima nova]+optima_nova_i5+italic+500.woff2',
      'VedaFont[Optima nova]+optima_nova_n6+normal+600.woff2',
      'VedaFont[Optima nova]+optima_nova_i6+italic+600.woff2',
      'VedaFont[Optima nova]+optima_nova_n7+normal+700.woff2',
      'VedaFont[Optima nova]+optima_nova_i7+italic+700.woff2',
      'VedaFont[Optima nova]+optima_nova_n8+normal+800.woff2',
      'VedaFont[Optima nova]+optima_nova_i8+italic+800.woff2',
      'VedaFont[Optima nova]+optima_nova_n9+normal+900.woff2',
      'VedaFont[Optima nova]+optima_nova_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Oswald',
    value: 'oswald_n4',
    family: 'Oswald',
    shopifyFiles: [
      'VedaFont[Oswald]+oswald_n2+normal+200.woff2',
      'VedaFont[Oswald]+oswald_n3+normal+300.woff2',
      'VedaFont[Oswald]+oswald_n4+normal+400.woff2',
      'VedaFont[Oswald]+oswald_n5+normal+500.woff2',
      'VedaFont[Oswald]+oswald_n6+normal+600.woff2',
      'VedaFont[Oswald]+oswald_n7+normal+700.woff2',
    ],
  },
  {
    label: 'Ovo',
    value: 'ovo_n4',
    family: 'Ovo',
    shopifyFiles: ['VedaFont[Ovo]+ovo_n4+normal+400.woff2'],
  },
  {
    label: 'Oxygen',
    value: 'oxygen_n4',
    family: 'Oxygen',
    shopifyFiles: [
      'VedaFont[Oxygen]+oxygen_n3+normal+300.woff2',
      'VedaFont[Oxygen]+oxygen_n4+normal+400.woff2',
      'VedaFont[Oxygen]+oxygen_n7+normal+700.woff2',
    ],
  },
  {
    label: 'PMN Caecilia',
    value: 'pmn_caecilia_n4',
    family: 'PMN Caecilia',
    shopifyFiles: [
      'VedaFont[PMN Caecilia]+pmn_caecilia_n3+normal+300.woff2',
      'VedaFont[PMN Caecilia]+pmn_caecilia_i3+italic+300.woff2',
      'VedaFont[PMN Caecilia]+pmn_caecilia_n4+normal+400.woff2',
      'VedaFont[PMN Caecilia]+pmn_caecilia_i4+italic+400.woff2',
      'VedaFont[PMN Caecilia]+pmn_caecilia_n7+normal+700.woff2',
      'VedaFont[PMN Caecilia]+pmn_caecilia_i7+italic+700.woff2',
      'VedaFont[PMN Caecilia]+pmn_caecilia_n9+normal+900.woff2',
      'VedaFont[PMN Caecilia]+pmn_caecilia_i9+italic+900.woff2',
    ],
  },
  {
    label: 'PT Mono',
    value: 'pt_mono_n4',
    family: 'PT Mono',
    shopifyFiles: ['VedaFont[PT Mono]+pt_mono_n4+normal+400.woff2'],
  },
  {
    label: 'PT Sans',
    value: 'pt_sans_n4',
    family: 'PT Sans',
    shopifyFiles: [
      'VedaFont[PT Sans]+pt_sans_n4+normal+400.woff2',
      'VedaFont[PT Sans]+pt_sans_i4+italic+400.woff2',
      'VedaFont[PT Sans]+pt_sans_n7+normal+700.woff2',
      'VedaFont[PT Sans]+pt_sans_i7+italic+700.woff2',
    ],
  },
  {
    label: 'PT Sans Narrow',
    value: 'pt_sans_narrow_n4',
    family: 'PT Sans Narrow',
    shopifyFiles: ['VedaFont[PT Sans Narrow]+pt_sans_narrow_n4+normal+400.woff2', 'VedaFont[PT Sans Narrow]+pt_sans_narrow_n7+normal+700.woff2'],
  },
  {
    label: 'PT Serif',
    value: 'pt_serif_n4',
    family: 'PT Serif',
    shopifyFiles: [
      'VedaFont[PT Serif]+pt_serif_n4+normal+400.woff2',
      'VedaFont[PT Serif]+pt_serif_i4+italic+400.woff2',
      'VedaFont[PT Serif]+pt_serif_n7+normal+700.woff2',
      'VedaFont[PT Serif]+pt_serif_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Pacifico',
    value: 'pacifico_n4',
    family: 'Pacifico',
    shopifyFiles: ['VedaFont[Pacifico]+pacifico_n4+normal+400.woff2'],
  },
  {
    label: 'Palatino',
    value: 'palatino_n4',
    family: 'Palatino',
    shopifyFiles: [],
  },
  {
    label: 'Parma',
    value: 'parma_n4',
    family: 'Parma',
    shopifyFiles: [
      'VedaFont[Parma]+parma_n4+normal+400.woff2',
      'VedaFont[Parma]+parma_i4+italic+400.woff2',
      'VedaFont[Parma]+parma_n7+normal+700.woff2',
    ],
  },
  {
    label: 'Perpetua',
    value: 'perpetua_n4',
    family: 'Perpetua',
    shopifyFiles: [
      'VedaFont[Perpetua]+perpetua_n4+normal+400.woff2',
      'VedaFont[Perpetua]+perpetua_i4+italic+400.woff2',
      'VedaFont[Perpetua]+perpetua_n7+normal+700.woff2',
      'VedaFont[Perpetua]+perpetua_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Plantin',
    value: 'plantin_n4',
    family: 'Plantin',
    shopifyFiles: [
      'VedaFont[Plantin]+plantin_n3+normal+300.woff2',
      'VedaFont[Plantin]+plantin_i3+italic+300.woff2',
      'VedaFont[Plantin]+plantin_n4+normal+400.woff2',
      'VedaFont[Plantin]+plantin_i4+italic+400.woff2',
      'VedaFont[Plantin]+plantin_n6+normal+600.woff2',
      'VedaFont[Plantin]+plantin_i6+italic+600.woff2',
      'VedaFont[Plantin]+plantin_n7+normal+700.woff2',
      'VedaFont[Plantin]+plantin_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Playball',
    value: 'playball_n4',
    family: 'Playball',
    shopifyFiles: ['VedaFont[Playball]+playball_n4+normal+400.woff2'],
  },
  {
    label: 'Playfair Display',
    value: 'playfair_display_n4',
    family: 'Playfair Display',
    shopifyFiles: [
      'VedaFont[Playfair Display]+playfair_display_n4+normal+400.woff2',
      'VedaFont[Playfair Display]+playfair_display_i4+italic+400.woff2',
      'VedaFont[Playfair Display]+playfair_display_n7+normal+700.woff2',
      'VedaFont[Playfair Display]+playfair_display_i7+italic+700.woff2',
      'VedaFont[Playfair Display]+playfair_display_n9+normal+900.woff2',
      'VedaFont[Playfair Display]+playfair_display_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Poppins',
    value: 'poppins_n4',
    family: 'Poppins',
    shopifyFiles: [
      'VedaFont[Poppins]+poppins_n1+normal+100.woff2',
      'VedaFont[Poppins]+poppins_i1+italic+100.woff2',
      'VedaFont[Poppins]+poppins_n2+normal+200.woff2',
      'VedaFont[Poppins]+poppins_i2+italic+200.woff2',
      'VedaFont[Poppins]+poppins_n3+normal+300.woff2',
      'VedaFont[Poppins]+poppins_i3+italic+300.woff2',
      'VedaFont[Poppins]+poppins_n4+normal+400.woff2',
      'VedaFont[Poppins]+poppins_i4+italic+400.woff2',
      'VedaFont[Poppins]+poppins_n5+normal+500.woff2',
      'VedaFont[Poppins]+poppins_i5+italic+500.woff2',
      'VedaFont[Poppins]+poppins_n6+normal+600.woff2',
      'VedaFont[Poppins]+poppins_i6+italic+600.woff2',
      'VedaFont[Poppins]+poppins_n7+normal+700.woff2',
      'VedaFont[Poppins]+poppins_i7+italic+700.woff2',
      'VedaFont[Poppins]+poppins_n8+normal+800.woff2',
      'VedaFont[Poppins]+poppins_i8+italic+800.woff2',
      'VedaFont[Poppins]+poppins_n9+normal+900.woff2',
      'VedaFont[Poppins]+poppins_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Prata',
    value: 'prata_n4',
    family: 'Prata',
    shopifyFiles: ['VedaFont[Prata]+prata_n4+normal+400.woff2'],
  },
  {
    label: 'Prompt',
    value: 'prompt_n4',
    family: 'Prompt',
    shopifyFiles: [
      'VedaFont[Prompt]+prompt_n1+normal+100.woff2',
      'VedaFont[Prompt]+prompt_i1+italic+100.woff2',
      'VedaFont[Prompt]+prompt_n2+normal+200.woff2',
      'VedaFont[Prompt]+prompt_i2+italic+200.woff2',
      'VedaFont[Prompt]+prompt_n3+normal+300.woff2',
      'VedaFont[Prompt]+prompt_i3+italic+300.woff2',
      'VedaFont[Prompt]+prompt_n4+normal+400.woff2',
      'VedaFont[Prompt]+prompt_i4+italic+400.woff2',
      'VedaFont[Prompt]+prompt_n5+normal+500.woff2',
      'VedaFont[Prompt]+prompt_i5+italic+500.woff2',
      'VedaFont[Prompt]+prompt_n6+normal+600.woff2',
      'VedaFont[Prompt]+prompt_i6+italic+600.woff2',
      'VedaFont[Prompt]+prompt_n7+normal+700.woff2',
      'VedaFont[Prompt]+prompt_i7+italic+700.woff2',
      'VedaFont[Prompt]+prompt_n8+normal+800.woff2',
      'VedaFont[Prompt]+prompt_i8+italic+800.woff2',
      'VedaFont[Prompt]+prompt_n9+normal+900.woff2',
      'VedaFont[Prompt]+prompt_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Proza Libre',
    value: 'proza_libre_n4',
    family: 'Proza Libre',
    shopifyFiles: [
      'VedaFont[Proza Libre]+proza_libre_n4+normal+400.woff2',
      'VedaFont[Proza Libre]+proza_libre_i4+italic+400.woff2',
      'VedaFont[Proza Libre]+proza_libre_n5+normal+500.woff2',
      'VedaFont[Proza Libre]+proza_libre_i5+italic+500.woff2',
      'VedaFont[Proza Libre]+proza_libre_n6+normal+600.woff2',
      'VedaFont[Proza Libre]+proza_libre_i6+italic+600.woff2',
      'VedaFont[Proza Libre]+proza_libre_n7+normal+700.woff2',
      'VedaFont[Proza Libre]+proza_libre_i7+italic+700.woff2',
      'VedaFont[Proza Libre]+proza_libre_n8+normal+800.woff2',
      'VedaFont[Proza Libre]+proza_libre_i8+italic+800.woff2',
    ],
  },
  {
    label: 'Quantico',
    value: 'quantico_n4',
    family: 'Quantico',
    shopifyFiles: [
      'VedaFont[Quantico]+quantico_n4+normal+400.woff2',
      'VedaFont[Quantico]+quantico_i4+italic+400.woff2',
      'VedaFont[Quantico]+quantico_n7+normal+700.woff2',
      'VedaFont[Quantico]+quantico_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Quattrocento',
    value: 'quattrocento_n4',
    family: 'Quattrocento',
    shopifyFiles: ['VedaFont[Quattrocento]+quattrocento_n4+normal+400.woff2', 'VedaFont[Quattrocento]+quattrocento_n7+normal+700.woff2'],
  },
  {
    label: 'Quattrocento Sans',
    value: 'quattrocento_sans_n4',
    family: 'Quattrocento Sans',
    shopifyFiles: [
      'VedaFont[Quattrocento Sans]+quattrocento_sans_n4+normal+400.woff2',
      'VedaFont[Quattrocento Sans]+quattrocento_sans_i4+italic+400.woff2',
      'VedaFont[Quattrocento Sans]+quattrocento_sans_n7+normal+700.woff2',
      'VedaFont[Quattrocento Sans]+quattrocento_sans_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Questrial',
    value: 'questrial_n4',
    family: 'Questrial',
    shopifyFiles: ['VedaFont[Questrial]+questrial_n4+normal+400.woff2'],
  },
  {
    label: 'Quicksand',
    value: 'quicksand_n4',
    family: 'Quicksand',
    shopifyFiles: [
      'VedaFont[Quicksand]+quicksand_n3+normal+300.woff2',
      'VedaFont[Quicksand]+quicksand_n4+normal+400.woff2',
      'VedaFont[Quicksand]+quicksand_n5+normal+500.woff2',
      'VedaFont[Quicksand]+quicksand_n7+normal+700.woff2',
    ],
  },
  {
    label: 'Quire Sans',
    value: 'quire_sans_n4',
    family: 'Quire Sans',
    shopifyFiles: [
      'VedaFont[Quire Sans]+quire_sans_n1+normal+100.woff2',
      'VedaFont[Quire Sans]+quire_sans_i1+italic+100.woff2',
      'VedaFont[Quire Sans]+quire_sans_n2+normal+200.woff2',
      'VedaFont[Quire Sans]+quire_sans_i2+italic+200.woff2',
      'VedaFont[Quire Sans]+quire_sans_n3+normal+300.woff2',
      'VedaFont[Quire Sans]+quire_sans_i3+italic+300.woff2',
      'VedaFont[Quire Sans]+quire_sans_n4+normal+400.woff2',
      'VedaFont[Quire Sans]+quire_sans_i4+italic+400.woff2',
      'VedaFont[Quire Sans]+quire_sans_n5+normal+500.woff2',
      'VedaFont[Quire Sans]+quire_sans_i5+italic+500.woff2',
      'VedaFont[Quire Sans]+quire_sans_n6+normal+600.woff2',
      'VedaFont[Quire Sans]+quire_sans_i6+italic+600.woff2',
      'VedaFont[Quire Sans]+quire_sans_n7+normal+700.woff2',
      'VedaFont[Quire Sans]+quire_sans_i7+italic+700.woff2',
      'VedaFont[Quire Sans]+quire_sans_n8+normal+800.woff2',
      'VedaFont[Quire Sans]+quire_sans_i8+italic+800.woff2',
      'VedaFont[Quire Sans]+quire_sans_n9+normal+900.woff2',
      'VedaFont[Quire Sans]+quire_sans_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Rajdhani',
    value: 'rajdhani_n4',
    family: 'Rajdhani',
    shopifyFiles: [
      'VedaFont[Rajdhani]+rajdhani_n3+normal+300.woff2',
      'VedaFont[Rajdhani]+rajdhani_n4+normal+400.woff2',
      'VedaFont[Rajdhani]+rajdhani_n5+normal+500.woff2',
      'VedaFont[Rajdhani]+rajdhani_n6+normal+600.woff2',
      'VedaFont[Rajdhani]+rajdhani_n7+normal+700.woff2',
    ],
  },
  {
    label: 'Raleway',
    value: 'raleway_n4',
    family: 'Raleway',
    shopifyFiles: [
      'VedaFont[Raleway]+raleway_n1+normal+100.woff2',
      'VedaFont[Raleway]+raleway_i1+italic+100.woff2',
      'VedaFont[Raleway]+raleway_n2+normal+200.woff2',
      'VedaFont[Raleway]+raleway_i2+italic+200.woff2',
      'VedaFont[Raleway]+raleway_n3+normal+300.woff2',
      'VedaFont[Raleway]+raleway_i3+italic+300.woff2',
      'VedaFont[Raleway]+raleway_n4+normal+400.woff2',
      'VedaFont[Raleway]+raleway_i4+italic+400.woff2',
      'VedaFont[Raleway]+raleway_n5+normal+500.woff2',
      'VedaFont[Raleway]+raleway_i5+italic+500.woff2',
      'VedaFont[Raleway]+raleway_n6+normal+600.woff2',
      'VedaFont[Raleway]+raleway_i6+italic+600.woff2',
      'VedaFont[Raleway]+raleway_n7+normal+700.woff2',
      'VedaFont[Raleway]+raleway_i7+italic+700.woff2',
      'VedaFont[Raleway]+raleway_n8+normal+800.woff2',
      'VedaFont[Raleway]+raleway_i8+italic+800.woff2',
      'VedaFont[Raleway]+raleway_n9+normal+900.woff2',
      'VedaFont[Raleway]+raleway_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Really No 2',
    value: 'really_no_2_n4',
    family: 'Really No 2',
    shopifyFiles: [
      'VedaFont[Really No 2]+really_no_2_n3+normal+300.woff2',
      'VedaFont[Really No 2]+really_no_2_i3+italic+300.woff2',
      'VedaFont[Really No 2]+really_no_2_n4+normal+400.woff2',
      'VedaFont[Really No 2]+really_no_2_i4+italic+400.woff2',
      'VedaFont[Really No 2]+really_no_2_n5+normal+500.woff2',
      'VedaFont[Really No 2]+really_no_2_i5+italic+500.woff2',
      'VedaFont[Really No 2]+really_no_2_n6+normal+600.woff2',
      'VedaFont[Really No 2]+really_no_2_i6+italic+600.woff2',
      'VedaFont[Really No 2]+really_no_2_n7+normal+700.woff2',
      'VedaFont[Really No 2]+really_no_2_i7+italic+700.woff2',
      'VedaFont[Really No 2]+really_no_2_n8+normal+800.woff2',
      'VedaFont[Really No 2]+really_no_2_i8+italic+800.woff2',
      'VedaFont[Really No 2]+really_no_2_n9+normal+900.woff2',
      'VedaFont[Really No 2]+really_no_2_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Righteous',
    value: 'righteous_n4',
    family: 'Righteous',
    shopifyFiles: ['VedaFont[Righteous]+righteous_n4+normal+400.woff2'],
  },
  {
    label: 'Roboto',
    value: 'roboto_n4',
    family: 'Roboto',
    shopifyFiles: [
      'VedaFont[Roboto]+roboto_n1+normal+100.woff2',
      'VedaFont[Roboto]+roboto_i1+italic+100.woff2',
      'VedaFont[Roboto]+roboto_n3+normal+300.woff2',
      'VedaFont[Roboto]+roboto_i3+italic+300.woff2',
      'VedaFont[Roboto]+roboto_n4+normal+400.woff2',
      'VedaFont[Roboto]+roboto_i4+italic+400.woff2',
      'VedaFont[Roboto]+roboto_n5+normal+500.woff2',
      'VedaFont[Roboto]+roboto_i5+italic+500.woff2',
      'VedaFont[Roboto]+roboto_n7+normal+700.woff2',
      'VedaFont[Roboto]+roboto_i7+italic+700.woff2',
      'VedaFont[Roboto]+roboto_n9+normal+900.woff2',
      'VedaFont[Roboto]+roboto_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Roboto Condensed',
    value: 'roboto_condensed_n4',
    family: 'Roboto Condensed',
    shopifyFiles: [
      'VedaFont[Roboto Condensed]+roboto_condensed_n3+normal+300.woff2',
      'VedaFont[Roboto Condensed]+roboto_condensed_i3+italic+300.woff2',
      'VedaFont[Roboto Condensed]+roboto_condensed_n4+normal+400.woff2',
      'VedaFont[Roboto Condensed]+roboto_condensed_i4+italic+400.woff2',
      'VedaFont[Roboto Condensed]+roboto_condensed_n7+normal+700.woff2',
      'VedaFont[Roboto Condensed]+roboto_condensed_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Roboto Mono',
    value: 'roboto_mono_n4',
    family: 'Roboto Mono',
    shopifyFiles: [
      'VedaFont[Roboto Mono]+roboto_mono_n1+normal+100.woff2',
      'VedaFont[Roboto Mono]+roboto_mono_i1+italic+100.woff2',
      'VedaFont[Roboto Mono]+roboto_mono_n3+normal+300.woff2',
      'VedaFont[Roboto Mono]+roboto_mono_i3+italic+300.woff2',
      'VedaFont[Roboto Mono]+roboto_mono_n4+normal+400.woff2',
      'VedaFont[Roboto Mono]+roboto_mono_i4+italic+400.woff2',
      'VedaFont[Roboto Mono]+roboto_mono_n5+normal+500.woff2',
      'VedaFont[Roboto Mono]+roboto_mono_i5+italic+500.woff2',
      'VedaFont[Roboto Mono]+roboto_mono_n7+normal+700.woff2',
      'VedaFont[Roboto Mono]+roboto_mono_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Roboto Slab',
    value: 'roboto_slab_n4',
    family: 'Roboto Slab',
    shopifyFiles: [
      'VedaFont[Roboto Slab]+roboto_slab_n1+normal+100.woff2',
      'VedaFont[Roboto Slab]+roboto_slab_n3+normal+300.woff2',
      'VedaFont[Roboto Slab]+roboto_slab_n4+normal+400.woff2',
      'VedaFont[Roboto Slab]+roboto_slab_n7+normal+700.woff2',
    ],
  },
  {
    label: 'Rockwell',
    value: 'rockwell_n4',
    family: 'Rockwell',
    shopifyFiles: [
      'VedaFont[Rockwell]+rockwell_n4+normal+400.woff2',
      'VedaFont[Rockwell]+rockwell_i4+italic+400.woff2',
      'VedaFont[Rockwell]+rockwell_n7+normal+700.woff2',
      'VedaFont[Rockwell]+rockwell_i7+italic+700.woff2',
      'VedaFont[Rockwell]+rockwell_n8+normal+800.woff2',
    ],
  },
  {
    label: 'Rubik',
    value: 'rubik_n4',
    family: 'Rubik',
    shopifyFiles: [
      'VedaFont[Rubik]+rubik_n3+normal+300.woff2',
      'VedaFont[Rubik]+rubik_i3+italic+300.woff2',
      'VedaFont[Rubik]+rubik_n4+normal+400.woff2',
      'VedaFont[Rubik]+rubik_i4+italic+400.woff2',
      'VedaFont[Rubik]+rubik_n5+normal+500.woff2',
      'VedaFont[Rubik]+rubik_i5+italic+500.woff2',
      'VedaFont[Rubik]+rubik_n7+normal+700.woff2',
      'VedaFont[Rubik]+rubik_i7+italic+700.woff2',
      'VedaFont[Rubik]+rubik_n9+normal+900.woff2',
      'VedaFont[Rubik]+rubik_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Sabon Next',
    value: 'sabon_next_n4',
    family: 'Sabon Next',
    shopifyFiles: [
      'VedaFont[Sabon Next]+sabon_next_n4+normal+400.woff2',
      'VedaFont[Sabon Next]+sabon_next_i4+italic+400.woff2',
      'VedaFont[Sabon Next]+sabon_next_n6+normal+600.woff2',
      'VedaFont[Sabon Next]+sabon_next_i6+italic+600.woff2',
      'VedaFont[Sabon Next]+sabon_next_n7+normal+700.woff2',
      'VedaFont[Sabon Next]+sabon_next_i7+italic+700.woff2',
      'VedaFont[Sabon Next]+sabon_next_n8+normal+800.woff2',
      'VedaFont[Sabon Next]+sabon_next_i8+italic+800.woff2',
      'VedaFont[Sabon Next]+sabon_next_n9+normal+900.woff2',
      'VedaFont[Sabon Next]+sabon_next_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Sackers Square Gothic',
    value: 'sackers_square_gothic_n4',
    family: 'Sackers Square Gothic',
    shopifyFiles: ['VedaFont[Sackers Square Gothic]+sackers_square_gothic_n4+normal+400.woff2'],
  },
  {
    label: 'Sagrantino',
    value: 'sagrantino_n4',
    family: 'Sagrantino',
    shopifyFiles: ['VedaFont[Sagrantino]+sagrantino_n4+normal+400.woff2'],
  },
  {
    label: 'Scene',
    value: 'scene_n4',
    family: 'Scene',
    shopifyFiles: [
      'VedaFont[Scene]+scene_n3+normal+300.woff2',
      'VedaFont[Scene]+scene_i3+italic+300.woff2',
      'VedaFont[Scene]+scene_n4+normal+400.woff2',
      'VedaFont[Scene]+scene_i4+italic+400.woff2',
      'VedaFont[Scene]+scene_n5+normal+500.woff2',
      'VedaFont[Scene]+scene_i5+italic+500.woff2',
      'VedaFont[Scene]+scene_n7+normal+700.woff2',
      'VedaFont[Scene]+scene_i7+italic+700.woff2',
      'VedaFont[Scene]+scene_n8+normal+800.woff2',
      'VedaFont[Scene]+scene_i8+italic+800.woff2',
      'VedaFont[Scene]+scene_n9+normal+900.woff2',
      'VedaFont[Scene]+scene_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Scherzo',
    value: 'scherzo_n4',
    family: 'Scherzo',
    shopifyFiles: [
      'VedaFont[Scherzo]+scherzo_n4+normal+400.woff2',
      'VedaFont[Scherzo]+scherzo_i4+italic+400.woff2',
      'VedaFont[Scherzo]+scherzo_n6+normal+600.woff2',
      'VedaFont[Scherzo]+scherzo_i6+italic+600.woff2',
      'VedaFont[Scherzo]+scherzo_n7+normal+700.woff2',
      'VedaFont[Scherzo]+scherzo_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Shadows Into Light',
    value: 'shadows_into_light_n4',
    family: 'Shadows Into Light',
    shopifyFiles: ['VedaFont[Shadows Into Light]+shadows_into_light_n4+normal+400.woff2'],
  },
  {
    label: 'Slabo 13px',
    value: 'slabo_13px_n4',
    family: 'Slabo 13px',
    shopifyFiles: ['VedaFont[Slabo 13px]+slabo_13px_n4+normal+400.woff2'],
  },
  {
    label: 'Slate',
    value: 'slate_n4',
    family: 'Slate',
    shopifyFiles: [
      'VedaFont[Slate]+slate_n2+normal+200.woff2',
      'VedaFont[Slate]+slate_i2+italic+200.woff2',
      'VedaFont[Slate]+slate_n3+normal+300.woff2',
      'VedaFont[Slate]+slate_i3+italic+300.woff2',
      'VedaFont[Slate]+slate_n4+normal+400.woff2',
      'VedaFont[Slate]+slate_i4+italic+400.woff2',
      'VedaFont[Slate]+slate_n5+normal+500.woff2',
      'VedaFont[Slate]+slate_i5+italic+500.woff2',
      'VedaFont[Slate]+slate_n7+normal+700.woff2',
      'VedaFont[Slate]+slate_i7+italic+700.woff2',
      'VedaFont[Slate]+slate_n9+normal+900.woff2',
      'VedaFont[Slate]+slate_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Smooch',
    value: 'smooch_n4',
    family: 'Smooch',
    shopifyFiles: ['VedaFont[Smooch]+smooch_n4+normal+400.woff2'],
  },
  {
    label: 'Soho',
    value: 'soho_n4',
    family: 'Soho',
    shopifyFiles: [
      'VedaFont[Soho]+soho_n1+normal+100.woff2',
      'VedaFont[Soho]+soho_i1+italic+100.woff2',
      'VedaFont[Soho]+soho_n2+normal+200.woff2',
      'VedaFont[Soho]+soho_i2+italic+200.woff2',
      'VedaFont[Soho]+soho_n3+normal+300.woff2',
      'VedaFont[Soho]+soho_i3+italic+300.woff2',
      'VedaFont[Soho]+soho_n4+normal+400.woff2',
      'VedaFont[Soho]+soho_i4+italic+400.woff2',
      'VedaFont[Soho]+soho_n5+normal+500.woff2',
      'VedaFont[Soho]+soho_i5+italic+500.woff2',
      'VedaFont[Soho]+soho_n6+normal+600.woff2',
      'VedaFont[Soho]+soho_i6+italic+600.woff2',
      'VedaFont[Soho]+soho_n7+normal+700.woff2',
      'VedaFont[Soho]+soho_i7+italic+700.woff2',
      'VedaFont[Soho]+soho_n8+normal+800.woff2',
      'VedaFont[Soho]+soho_i8+italic+800.woff2',
      'VedaFont[Soho]+soho_n9+normal+900.woff2',
      'VedaFont[Soho]+soho_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Soho Gothic',
    value: 'soho_gothic_n4',
    family: 'Soho Gothic',
    shopifyFiles: [
      'VedaFont[Soho Gothic]+soho_gothic_n2+normal+200.woff2',
      'VedaFont[Soho Gothic]+soho_gothic_i2+italic+200.woff2',
      'VedaFont[Soho Gothic]+soho_gothic_n3+normal+300.woff2',
      'VedaFont[Soho Gothic]+soho_gothic_i3+italic+300.woff2',
      'VedaFont[Soho Gothic]+soho_gothic_n4+normal+400.woff2',
      'VedaFont[Soho Gothic]+soho_gothic_i4+italic+400.woff2',
      'VedaFont[Soho Gothic]+soho_gothic_n5+normal+500.woff2',
      'VedaFont[Soho Gothic]+soho_gothic_i5+italic+500.woff2',
      'VedaFont[Soho Gothic]+soho_gothic_n7+normal+700.woff2',
      'VedaFont[Soho Gothic]+soho_gothic_i7+italic+700.woff2',
      'VedaFont[Soho Gothic]+soho_gothic_n8+normal+800.woff2',
      'VedaFont[Soho Gothic]+soho_gothic_i8+italic+800.woff2',
      'VedaFont[Soho Gothic]+soho_gothic_n9+normal+900.woff2',
      'VedaFont[Soho Gothic]+soho_gothic_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Source Code Pro',
    value: 'source_code_pro_n4',
    family: 'Source Code Pro',
    shopifyFiles: [
      'VedaFont[Source Code Pro]+source_code_pro_n2+normal+200.woff2',
      'VedaFont[Source Code Pro]+source_code_pro_n3+normal+300.woff2',
      'VedaFont[Source Code Pro]+source_code_pro_n4+normal+400.woff2',
      'VedaFont[Source Code Pro]+source_code_pro_n5+normal+500.woff2',
      'VedaFont[Source Code Pro]+source_code_pro_n6+normal+600.woff2',
      'VedaFont[Source Code Pro]+source_code_pro_n7+normal+700.woff2',
      'VedaFont[Source Code Pro]+source_code_pro_n9+normal+900.woff2',
    ],
  },
  {
    label: 'Source Sans Pro',
    value: 'source_sans_pro_n4',
    family: 'Source Sans Pro',
    shopifyFiles: [
      'VedaFont[Source Sans Pro]+source_sans_pro_n2+normal+200.woff2',
      'VedaFont[Source Sans Pro]+source_sans_pro_i2+italic+200.woff2',
      'VedaFont[Source Sans Pro]+source_sans_pro_n3+normal+300.woff2',
      'VedaFont[Source Sans Pro]+source_sans_pro_i3+italic+300.woff2',
      'VedaFont[Source Sans Pro]+source_sans_pro_n4+normal+400.woff2',
      'VedaFont[Source Sans Pro]+source_sans_pro_i4+italic+400.woff2',
      'VedaFont[Source Sans Pro]+source_sans_pro_n6+normal+600.woff2',
      'VedaFont[Source Sans Pro]+source_sans_pro_i6+italic+600.woff2',
      'VedaFont[Source Sans Pro]+source_sans_pro_n7+normal+700.woff2',
      'VedaFont[Source Sans Pro]+source_sans_pro_i7+italic+700.woff2',
      'VedaFont[Source Sans Pro]+source_sans_pro_n9+normal+900.woff2',
      'VedaFont[Source Sans Pro]+source_sans_pro_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Space Mono',
    value: 'space_mono_n4',
    family: 'Space Mono',
    shopifyFiles: [
      'VedaFont[Space Mono]+space_mono_n4+normal+400.woff2',
      'VedaFont[Space Mono]+space_mono_i4+italic+400.woff2',
      'VedaFont[Space Mono]+space_mono_n7+normal+700.woff2',
      'VedaFont[Space Mono]+space_mono_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Stempel Schneidler',
    value: 'stempel_schneidler_n4',
    family: 'Stempel Schneidler',
    shopifyFiles: [
      'VedaFont[Stempel Schneidler]+stempel_schneidler_n3+normal+300.woff2',
      'VedaFont[Stempel Schneidler]+stempel_schneidler_i3+italic+300.woff2',
      'VedaFont[Stempel Schneidler]+stempel_schneidler_n4+normal+400.woff2',
      'VedaFont[Stempel Schneidler]+stempel_schneidler_i4+italic+400.woff2',
      'VedaFont[Stempel Schneidler]+stempel_schneidler_n5+normal+500.woff2',
      'VedaFont[Stempel Schneidler]+stempel_schneidler_i5+italic+500.woff2',
      'VedaFont[Stempel Schneidler]+stempel_schneidler_n7+normal+700.woff2',
      'VedaFont[Stempel Schneidler]+stempel_schneidler_i7+italic+700.woff2',
      'VedaFont[Stempel Schneidler]+stempel_schneidler_n8+normal+800.woff2',
      'VedaFont[Stempel Schneidler]+stempel_schneidler_i8+italic+800.woff2',
    ],
  },
  {
    label: 'Swiss 721',
    value: 'swiss_721_n4',
    family: 'Swiss 721',
    shopifyFiles: [
      'VedaFont[Swiss 721]+swiss_721_n1+normal+100.woff2',
      'VedaFont[Swiss 721]+swiss_721_i1+italic+100.woff2',
      'VedaFont[Swiss 721]+swiss_721_n2+normal+200.woff2',
      'VedaFont[Swiss 721]+swiss_721_i2+italic+200.woff2',
      'VedaFont[Swiss 721]+swiss_721_n4+normal+400.woff2',
      'VedaFont[Swiss 721]+swiss_721_i4+italic+400.woff2',
      'VedaFont[Swiss 721]+swiss_721_n5+normal+500.woff2',
      'VedaFont[Swiss 721]+swiss_721_i5+italic+500.woff2',
      'VedaFont[Swiss 721]+swiss_721_n7+normal+700.woff2',
      'VedaFont[Swiss 721]+swiss_721_i7+italic+700.woff2',
      'VedaFont[Swiss 721]+swiss_721_n8+normal+800.woff2',
      'VedaFont[Swiss 721]+swiss_721_i8+italic+800.woff2',
      'VedaFont[Swiss 721]+swiss_721_n9+normal+900.woff2',
      'VedaFont[Swiss 721]+swiss_721_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Swiss 721 Rounded',
    value: 'swiss_721_rounded_n7',
    family: 'Swiss 721 Rounded',
    shopifyFiles: [
      'VedaFont[Swiss 721 Rounded]+swiss_721_rounded_n7+normal+700.woff2',
      'VedaFont[Swiss 721 Rounded]+swiss_721_rounded_n9+normal+900.woff2',
    ],
  },
  {
    label: 'Syne',
    value: 'syne_n4',
    family: 'Syne',
    shopifyFiles: [
      'VedaFont[Syne]+syne_n4+normal+400.woff2',
      'VedaFont[Syne]+syne_n5+normal+500.woff2',
      'VedaFont[Syne]+syne_n6+normal+600.woff2',
      'VedaFont[Syne]+syne_n7+normal+700.woff2',
      'VedaFont[Syne]+syne_n8+normal+800.woff2',
    ],
  },
  {
    label: 'Tenor Sans',
    value: 'tenor_sans_n4',
    family: 'Tenor Sans',
    shopifyFiles: ['VedaFont[Tenor Sans]+tenor_sans_n4+normal+400.woff2'],
  },
  {
    label: 'Tiemann',
    value: 'tiemann_n4',
    family: 'Tiemann',
    shopifyFiles: ['VedaFont[Tiemann]+tiemann_n3+normal+300.woff2', 'VedaFont[Tiemann]+tiemann_n4+normal+400.woff2'],
  },
  {
    label: 'Times New Roman',
    value: 'times_new_roman_n4',
    family: 'Times New Roman',
    shopifyFiles: [],
  },
  {
    label: 'Tinos',
    value: 'tinos_n4',
    family: 'Tinos',
    shopifyFiles: [
      'VedaFont[Tinos]+tinos_n4+normal+400.woff2',
      'VedaFont[Tinos]+tinos_i4+italic+400.woff2',
      'VedaFont[Tinos]+tinos_n7+normal+700.woff2',
      'VedaFont[Tinos]+tinos_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Titillium Web',
    value: 'titillium_web_n4',
    family: 'Titillium Web',
    shopifyFiles: [
      'VedaFont[Titillium Web]+titillium_web_n2+normal+200.woff2',
      'VedaFont[Titillium Web]+titillium_web_i2+italic+200.woff2',
      'VedaFont[Titillium Web]+titillium_web_n3+normal+300.woff2',
      'VedaFont[Titillium Web]+titillium_web_i3+italic+300.woff2',
      'VedaFont[Titillium Web]+titillium_web_n4+normal+400.woff2',
      'VedaFont[Titillium Web]+titillium_web_i4+italic+400.woff2',
      'VedaFont[Titillium Web]+titillium_web_n6+normal+600.woff2',
      'VedaFont[Titillium Web]+titillium_web_i6+italic+600.woff2',
      'VedaFont[Titillium Web]+titillium_web_n7+normal+700.woff2',
      'VedaFont[Titillium Web]+titillium_web_i7+italic+700.woff2',
      'VedaFont[Titillium Web]+titillium_web_n9+normal+900.woff2',
    ],
  },
  {
    label: 'Trade Gothic',
    value: 'trade_gothic_n4',
    family: 'Trade Gothic',
    shopifyFiles: [
      'VedaFont[Trade Gothic]+trade_gothic_n3+normal+300.woff2',
      'VedaFont[Trade Gothic]+trade_gothic_o3+oblique+300.woff2',
      'VedaFont[Trade Gothic]+trade_gothic_n4+normal+400.woff2',
      'VedaFont[Trade Gothic]+trade_gothic_o4+oblique+400.woff2',
      'VedaFont[Trade Gothic]+trade_gothic_n7+normal+700.woff2',
      'VedaFont[Trade Gothic]+trade_gothic_o7+oblique+700.woff2',
    ],
  },
  {
    label: 'Trade Gothic Next',
    value: 'trade_gothic_next_n4',
    family: 'Trade Gothic Next',
    shopifyFiles: [
      'VedaFont[Trade Gothic Next]+trade_gothic_next_n3+normal+300.woff2',
      'VedaFont[Trade Gothic Next]+trade_gothic_next_i3+italic+300.woff2',
      'VedaFont[Trade Gothic Next]+trade_gothic_next_n4+normal+400.woff2',
      'VedaFont[Trade Gothic Next]+trade_gothic_next_i4+italic+400.woff2',
      'VedaFont[Trade Gothic Next]+trade_gothic_next_n7+normal+700.woff2',
      'VedaFont[Trade Gothic Next]+trade_gothic_next_i7+italic+700.woff2',
      'VedaFont[Trade Gothic Next]+trade_gothic_next_n8+normal+800.woff2',
      'VedaFont[Trade Gothic Next]+trade_gothic_next_i8+italic+800.woff2',
    ],
  },
  {
    label: 'Trebuchet MS',
    value: 'trebuchet_ms_n4',
    family: 'Trebuchet MS',
    shopifyFiles: [],
  },
  {
    label: 'Twentieth Century',
    value: 'twentieth_century_n4',
    family: 'Twentieth Century',
    shopifyFiles: [
      'VedaFont[Twentieth Century]+twentieth_century_n3+normal+300.woff2',
      'VedaFont[Twentieth Century]+twentieth_century_i3+italic+300.woff2',
      'VedaFont[Twentieth Century]+twentieth_century_n4+normal+400.woff2',
      'VedaFont[Twentieth Century]+twentieth_century_n5+normal+500.woff2',
      'VedaFont[Twentieth Century]+twentieth_century_i5+italic+500.woff2',
      'VedaFont[Twentieth Century]+twentieth_century_n6+normal+600.woff2',
      'VedaFont[Twentieth Century]+twentieth_century_n7+normal+700.woff2',
      'VedaFont[Twentieth Century]+twentieth_century_i7+italic+700.woff2',
      'VedaFont[Twentieth Century]+twentieth_century_n8+normal+800.woff2',
      'VedaFont[Twentieth Century]+twentieth_century_i8+italic+800.woff2',
      'VedaFont[Twentieth Century]+twentieth_century_n9+normal+900.woff2',
      'VedaFont[Twentieth Century]+twentieth_century_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Ubuntu',
    value: 'ubuntu_n4',
    family: 'Ubuntu',
    shopifyFiles: [
      'VedaFont[Ubuntu]+ubuntu_n3+normal+300.woff2',
      'VedaFont[Ubuntu]+ubuntu_i3+italic+300.woff2',
      'VedaFont[Ubuntu]+ubuntu_n4+normal+400.woff2',
      'VedaFont[Ubuntu]+ubuntu_i4+italic+400.woff2',
      'VedaFont[Ubuntu]+ubuntu_n5+normal+500.woff2',
      'VedaFont[Ubuntu]+ubuntu_i5+italic+500.woff2',
      'VedaFont[Ubuntu]+ubuntu_n7+normal+700.woff2',
      'VedaFont[Ubuntu]+ubuntu_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Unica One',
    value: 'unica_one_n4',
    family: 'Unica One',
    shopifyFiles: ['VedaFont[Unica One]+unica_one_n4+normal+400.woff2'],
  },
  {
    label: 'Univers Next',
    value: 'univers_next_n4',
    family: 'Univers Next',
    shopifyFiles: [
      'VedaFont[Univers Next]+univers_next_n1+normal+100.woff2',
      'VedaFont[Univers Next]+univers_next_i1+italic+100.woff2',
      'VedaFont[Univers Next]+univers_next_n2+normal+200.woff2',
      'VedaFont[Univers Next]+univers_next_i2+italic+200.woff2',
      'VedaFont[Univers Next]+univers_next_n3+normal+300.woff2',
      'VedaFont[Univers Next]+univers_next_i3+italic+300.woff2',
      'VedaFont[Univers Next]+univers_next_n4+normal+400.woff2',
      'VedaFont[Univers Next]+univers_next_i4+italic+400.woff2',
      'VedaFont[Univers Next]+univers_next_n5+normal+500.woff2',
      'VedaFont[Univers Next]+univers_next_i5+italic+500.woff2',
      'VedaFont[Univers Next]+univers_next_n6+normal+600.woff2',
      'VedaFont[Univers Next]+univers_next_i6+italic+600.woff2',
      'VedaFont[Univers Next]+univers_next_n7+normal+700.woff2',
      'VedaFont[Univers Next]+univers_next_i7+italic+700.woff2',
      'VedaFont[Univers Next]+univers_next_n8+normal+800.woff2',
      'VedaFont[Univers Next]+univers_next_i8+italic+800.woff2',
      'VedaFont[Univers Next]+univers_next_n9+normal+900.woff2',
      'VedaFont[Univers Next]+univers_next_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Univers Next Typewriter',
    value: 'univers_next_typewriter_n4',
    family: 'Univers Next Typewriter',
    shopifyFiles: [
      'VedaFont[Univers Next Typewriter]+univers_next_typewriter_n4+normal+400.woff2',
      'VedaFont[Univers Next Typewriter]+univers_next_typewriter_i4+italic+400.woff2',
      'VedaFont[Univers Next Typewriter]+univers_next_typewriter_n7+normal+700.woff2',
      'VedaFont[Univers Next Typewriter]+univers_next_typewriter_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Unna',
    value: 'unna_n4',
    family: 'Unna',
    shopifyFiles: [
      'VedaFont[Unna]+unna_n4+normal+400.woff2',
      'VedaFont[Unna]+unna_i4+italic+400.woff2',
      'VedaFont[Unna]+unna_n7+normal+700.woff2',
      'VedaFont[Unna]+unna_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Vala',
    value: 'vala_n4',
    family: 'Vala',
    shopifyFiles: ['VedaFont[Vala]+vala_n4+normal+400.woff2'],
  },
  {
    label: 'Varela',
    value: 'varela_n4',
    family: 'Varela',
    shopifyFiles: ['VedaFont[Varela]+varela_n4+normal+400.woff2'],
  },
  {
    label: 'Varela Round',
    value: 'varela_round_n4',
    family: 'Varela Round',
    shopifyFiles: ['VedaFont[Varela Round]+varela_round_n4+normal+400.woff2'],
  },
  {
    label: 'Verdana Pro',
    value: 'verdana_pro_n4',
    family: 'Verdana Pro',
    shopifyFiles: [
      'VedaFont[Verdana Pro]+verdana_pro_n3+normal+300.woff2',
      'VedaFont[Verdana Pro]+verdana_pro_i3+italic+300.woff2',
      'VedaFont[Verdana Pro]+verdana_pro_n4+normal+400.woff2',
      'VedaFont[Verdana Pro]+verdana_pro_i4+italic+400.woff2',
      'VedaFont[Verdana Pro]+verdana_pro_n6+normal+600.woff2',
      'VedaFont[Verdana Pro]+verdana_pro_i6+italic+600.woff2',
      'VedaFont[Verdana Pro]+verdana_pro_n7+normal+700.woff2',
      'VedaFont[Verdana Pro]+verdana_pro_i7+italic+700.woff2',
      'VedaFont[Verdana Pro]+verdana_pro_n9+normal+900.woff2',
      'VedaFont[Verdana Pro]+verdana_pro_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Vidaloka',
    value: 'vidaloka_n4',
    family: 'Vidaloka',
    shopifyFiles: ['VedaFont[Vidaloka]+vidaloka_n4+normal+400.woff2'],
  },
  {
    label: 'Volkhov',
    value: 'volkhov_n4',
    family: 'Volkhov',
    shopifyFiles: [
      'VedaFont[Volkhov]+volkhov_n4+normal+400.woff2',
      'VedaFont[Volkhov]+volkhov_i4+italic+400.woff2',
      'VedaFont[Volkhov]+volkhov_n7+normal+700.woff2',
      'VedaFont[Volkhov]+volkhov_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Vollkorn',
    value: 'vollkorn_n4',
    family: 'Vollkorn',
    shopifyFiles: [
      'VedaFont[Vollkorn]+vollkorn_n4+normal+400.woff2',
      'VedaFont[Vollkorn]+vollkorn_i4+italic+400.woff2',
      'VedaFont[Vollkorn]+vollkorn_n6+normal+600.woff2',
      'VedaFont[Vollkorn]+vollkorn_i6+italic+600.woff2',
      'VedaFont[Vollkorn]+vollkorn_n7+normal+700.woff2',
      'VedaFont[Vollkorn]+vollkorn_i7+italic+700.woff2',
      'VedaFont[Vollkorn]+vollkorn_n9+normal+900.woff2',
      'VedaFont[Vollkorn]+vollkorn_i9+italic+900.woff2',
    ],
  },
  {
    label: 'Waza',
    value: 'waza_n4',
    family: 'Waza',
    shopifyFiles: ['VedaFont[Waza]+waza_n4+normal+400.woff2'],
  },
  {
    label: 'Wola',
    value: 'wola_n4',
    family: 'Wola',
    shopifyFiles: ['VedaFont[Wola]+wola_n4+normal+400.woff2'],
  },
  {
    label: 'Work Sans',
    value: 'work_sans_n4',
    family: 'Work Sans',
    shopifyFiles: [
      'VedaFont[Work Sans]+work_sans_n1+normal+100.woff2',
      'VedaFont[Work Sans]+work_sans_n2+normal+200.woff2',
      'VedaFont[Work Sans]+work_sans_n3+normal+300.woff2',
      'VedaFont[Work Sans]+work_sans_n4+normal+400.woff2',
      'VedaFont[Work Sans]+work_sans_n5+normal+500.woff2',
      'VedaFont[Work Sans]+work_sans_n6+normal+600.woff2',
      'VedaFont[Work Sans]+work_sans_n7+normal+700.woff2',
      'VedaFont[Work Sans]+work_sans_n8+normal+800.woff2',
      'VedaFont[Work Sans]+work_sans_n9+normal+900.woff2',
    ],
  },
  {
    label: 'Ysobel',
    value: 'ysobel_n4',
    family: 'Ysobel',
    shopifyFiles: [
      'VedaFont[Ysobel]+ysobel_n3+normal+300.woff2',
      'VedaFont[Ysobel]+ysobel_i3+italic+300.woff2',
      'VedaFont[Ysobel]+ysobel_n4+normal+400.woff2',
      'VedaFont[Ysobel]+ysobel_i4+italic+400.woff2',
      'VedaFont[Ysobel]+ysobel_n6+normal+600.woff2',
      'VedaFont[Ysobel]+ysobel_i6+italic+600.woff2',
      'VedaFont[Ysobel]+ysobel_n7+normal+700.woff2',
      'VedaFont[Ysobel]+ysobel_i7+italic+700.woff2',
    ],
  },
  {
    label: 'Zurich',
    value: 'zurich_n4',
    family: 'Zurich',
    shopifyFiles: [
      'VedaFont[Zurich]+zurich_n3+normal+300.woff2',
      'VedaFont[Zurich]+zurich_i3+italic+300.woff2',
      'VedaFont[Zurich]+zurich_n4+normal+400.woff2',
      'VedaFont[Zurich]+zurich_i4+italic+400.woff2',
      'VedaFont[Zurich]+zurich_n7+normal+700.woff2',
      'VedaFont[Zurich]+zurich_i7+italic+700.woff2',
      'VedaFont[Zurich]+zurich_n8+normal+800.woff2',
      'VedaFont[Zurich]+zurich_i8+italic+800.woff2',
      'VedaFont[Zurich]+zurich_n9+normal+900.woff2',
    ],
  },
  {
    label: 'Zurich Extended',
    value: 'zurich_extended_n4',
    family: 'Zurich Extended',
    shopifyFiles: [
      'VedaFont[Zurich Extended]+zurich_extended_n4+normal+400.woff2',
      'VedaFont[Zurich Extended]+zurich_extended_n7+normal+700.woff2',
      'VedaFont[Zurich Extended]+zurich_extended_n8+normal+800.woff2',
      'VedaFont[Zurich Extended]+zurich_extended_n9+normal+900.woff2',
    ],
  },
];

export const shopifyFontsObject = new Map<string, Option & { family: string; shopifyFiles: string[] }>();
shopifyFonts.forEach(font => shopifyFontsObject.set(font.value.toString(), font as Option & { family: string; shopifyFiles: string[] }));

export const getCssFont = (font: CssFontVariable | Option) => {
  return shopifyFontsObject.get(font.value as string);
};
