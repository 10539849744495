export const ModalReportAfterError = {
  error_cause: {
    connect_socket: 'Unstable connection',
    sync_result_to_shopify: 'Sync to shopify failed',
    preview_result_in_shopify: 'Sync to shopify failed',
    migrate_theme: 'Sync to shopify failed',
    clone_page_atom_service_to_theme_atom_service: 'The process of cloning components from page atom service to theme atom service failed',
    initialization_session_builder: 'Something is wrong, an unexpected error has occurred.',
    save_result: 'Save process encountered an error',
    save_page_setting_in_dashboard: 'Save process encountered an error',
    save_theme_setting_in_dashboard: 'Save process encountered an error',
    delete_page: 'Delete page process encountered an error',
    update_status_page: 'Sync to shopify failed',
    active_theme_veda: 'Publish theme failed',
    lost_connection: 'Lost connection',
  },
  error_description: {
    socket_event_id: 'Unstable connection',
  },
  title: 'Error',
  description: 'Feedback will be sent to Veda',
  comments: 'Comments',
  severity: {
    title: 'Severity',
    options: {
      low: 'Low',
      normal: 'Normal',
      high: 'High',
      urgent: 'Urgent',
    },
  },
  app_status: 'System status',
  app_message: 'System notification',
  send: 'Submit',
  initializing: 'Connecting to the support team',
} as const;
