import { sectionApiController } from 'services/SectionService';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const updateSectionVersion = async (commandId: string) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    const productResponse = await sectionApiController.product.adminApi.sections.updateVersion({
      commandId,
    });
    return productResponse;
  }
  if (role === 'dev') {
    const productResponse = await sectionApiController.product.devApi.sections.updateVersion({
      commandId,
    });
    return productResponse;
  }
  const productResponse = await sectionApiController.product.userApi.sections.updateVersion({
    commandId,
  });
  return productResponse;
};
