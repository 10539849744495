import { createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';

export interface ChangeModalAdminSettings {
  type: 'changeModalAdminSettings';
  payload: Partial<ModalAdminState>;
}

type ModalActions = ChangeModalAdminSettings;

export interface ModalAdminState {
  createTheme: boolean;
  createProduct: boolean;
  createCollection: boolean;
  createArticle: boolean;
  createNormalPage: boolean;
  createBlog: boolean;

  deleteBlank: boolean;
  deleteProduct: boolean;
  deleteCollection: boolean;
  deleteArticle: boolean;
  deleteBlog: boolean;
  deleteHome: boolean;
  deleteCustomerPage: boolean;

  isCreate: boolean;
}

const sliceAdminModals = createSlice<ModalAdminState, ModalActions>({
  initialState: {
    createTheme: false,
    createProduct: false,
    createCollection: false,
    createArticle: false,
    createNormalPage: false,
    createBlog: false,

    deleteBlog: false,
    deleteBlank: false,
    deleteHome: false,
    deleteProduct: false,
    deleteCollection: false,
    deleteArticle: false,
    deleteCustomerPage: false,

    isCreate: false,
  },
  name: '@Modal',
  reducers: [
    handleAction('changeModalAdminSettings', ({ state, action }) => {
      return {
        ...state,
        ...action.payload,
      };
    }),
  ],
});

export const { changeModalAdminSettings } = sliceAdminModals.actions;
export const reducerModals = sliceAdminModals.reducer;

export const useChangeModalAdminSettings = createDispatchAction(changeModalAdminSettings);
