interface ConvertToValidClass {
  sectionLabel: string;
  prefix: string;
}
export function convertToValidClass({ prefix, sectionLabel }: ConvertToValidClass) {
  return (
    prefix +
    sectionLabel
      .toLowerCase()
      // Remove leading and trailing whitespaces
      .trim()
      // Replace any sequence of spaces with a single space
      .replace(/\s+/g, ' ')
      // Replace any remaining spaces with hyphens
      .replace(/\s/g, '-')
      // Remove any non-alphanumeric characters except hyphens and underscores
      .replace(/[^a-zA-Z0-9-_]/g, '')
  );
}
