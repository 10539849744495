export const languages = ['en', 'vi', 'fr'] as const;

export const defaultLanguage = 'en';

export const reduceTotalLinesOfCodeByReduceSchemaSettingsSignal = {
  start: '__[BẮT_ĐẦU_CẮT_GIẢM]__:',
  end: ':__[KẾT_THÚC_CẮT_GIẢM]__',
};

/** Dấu được tạo bởi đội code template */
export const reduceTotalLinesOfCodeBySpreadFileSignal = {
  start: 'BAT_DAU_TACH_FILE',
  end: 'KET_THUC_TACH_FILE',
  startTag: 'comment',
  endTag: 'endcomment',
};
