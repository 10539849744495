import { Option } from 'components/SelectAntd';
import { createAdminAddonChangelog, createAdminAddonsCategory, getAdminAddonsCategory } from 'containers/ChooseTemplate/store/actions';
import { createSelector } from 'reselect';
import { SectionCategoryTag } from 'types/Sections';

import { ActionTypes, createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';

type ExtraActions = ActionTypes<typeof getAdminAddonsCategory | typeof createAdminAddonsCategory | typeof createAdminAddonChangelog>;

type AdminCategoryAddonActions =
  | {
      type: 'setAdminAddonsCategory';
      payload: {
        slug: string;
        id: string;
      };
    }
  | {
      type: 'setCreateAddonChangelogStatus';
      payload: Status;
    };

interface State {
  categories: SectionCategoryTag[];
  getAllStatus: Status;
  categoryId: string;
  categorySlug: string;
  addStatus: Status;
  createChangelogStatus: Status;
}

export const sliceAdminAddonCategory = createSlice<State, AdminCategoryAddonActions, ExtraActions>({
  name: '@ChooseTemplate',
  initialState: {
    categories: [],
    categoryId: '',
    categorySlug: '',
    getAllStatus: 'idle',
    addStatus: 'idle',
    createChangelogStatus: 'idle',
  },
  reducers: [
    handleAction('setAdminAddonsCategory', ({ state, action }) => {
      state.categoryId = action.payload.id;
      state.categorySlug = action.payload.slug;
    }),
    handleAction('setCreateAddonChangelogStatus', ({ state, action }) => {
      state.createChangelogStatus = action.payload;
    }),
  ],
  extraReducers: [
    handleAction('@ChooseTemplate/getAdminAddonsCategory/request', ({ state }) => {
      return {
        ...state,
        getAllStatus: state.categories.length > 0 ? 'success' : 'loading',
      };
    }),
    handleAction('@ChooseTemplate/getAdminAddonsCategory/success', ({ state, action }) => {
      return {
        ...state,
        getAllStatus: 'success',
        categories: action.payload.data,
      };
    }),
    handleAction('@ChooseTemplate/getAdminAddonsCategory/failure', ({ state }) => {
      return {
        ...state,
        getAllStatus: 'failure',
      };
    }),
    handleAction('@ChooseTemplate/createAdminAddonsCategory/request', ({ state }) => {
      return {
        ...state,
        addStatus: 'loading',
      };
    }),
    handleAction('@ChooseTemplate/createAdminAddonsCategory/success', ({ state, action }) => {
      return {
        ...state,
        addStatus: 'success',
        categories: [action.payload, ...state.categories],
      };
    }),
    handleAction('@ChooseTemplate/createAdminAddonsCategory/failure', ({ state }) => {
      return {
        ...state,
        addStatus: 'failure',
      };
    }),
    handleAction('@ChooseTemplate/createAdminAddonChangelog/request', ({ state }) => {
      state.createChangelogStatus = 'loading';
    }),
    handleAction('@ChooseTemplate/createAdminAddonChangelog/success', ({ state }) => {
      state.createChangelogStatus = 'success';
    }),
    handleAction('@ChooseTemplate/createAdminAddonChangelog/failure', ({ state }) => {
      state.createChangelogStatus = 'failure';
    }),
  ],
});

export const { setAdminAddonsCategory, setCreateAddonChangelogStatus } = sliceAdminAddonCategory.actions;

export const useSetAdminAddonsCategory = createDispatchAction(setAdminAddonsCategory);
export const useSetCreateAddonChangelogStatus = createDispatchAction(setCreateAddonChangelogStatus);

export const transformAdminAddonCategories = createSelector(
  (state: AppState) => state.chooseTemplate.adminAddonsCategory,
  state => {
    const { categories } = state;
    return categories.map<Option>(item => {
      return {
        label: item.description,
        value: item.name,
        commandId: item.commandId,
      };
    });
  },
);
