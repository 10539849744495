import { useCountDown } from '@wiloke/hooks';
import parser from 'html-react-parser';
import { FC } from 'react';
import { Image, Text, View, ViewProps } from 'wiloke-react-core';
import bellImg from './bell.png';
import * as styles from './styles';
import bgImage from './bg-message.png';

interface MessageMaintainProps {
  contentHtml?: string;
  heading?: string;
  timeEnd?: number;
  containerCss?: ViewProps['css'];
  backgroundType?: 'color' | 'image';
  backgroundUrl?: string;
  iconUrl?: string;
}

export const MessageMaintain: FC<MessageMaintainProps> = ({
  heading = 'Announcement heading',
  contentHtml = '<p style="color:white;font-size:16px;">Add, edit and publish a theme to change your store on <span style="font-weight:600;">30/12/2022</span>. Test component, chưa check điền kiện để show/hide!</p>',
  timeEnd = 0,
  containerCss,
  backgroundType = 'image',
  backgroundUrl = bgImage,
  iconUrl = bellImg,
}) => {
  const { days, hours, minutes, seconds } = useCountDown({ endDate: timeEnd });

  const renderTime = (time: number, type: 'days' | 'hours' | 'mins' | 'secs') => {
    return (
      <View css={styles.timeContainer}>
        <View css={styles.timeNumber}>{time}</View>
        <View css={styles.timeType}>{type}</View>
      </View>
    );
  };

  const background = backgroundType === 'image' ? `url(${backgroundUrl})` : undefined;

  return (
    <View className="MessageMaintain-container" css={[styles.container, containerCss]} style={{ backgroundImage: background }}>
      <View css={styles.body}>
        <Image src={iconUrl} containerCss={styles.bell} />
        <View fontFamily="secondary" css={styles.content}>
          <Text size={16} color="light">
            {heading}
          </Text>
          <Text color="light">{parser(contentHtml)}</Text>
        </View>

        {timeEnd !== 0 && (
          <View css={{ display: 'flex', alignItems: 'center' }}>
            {renderTime(days, 'days')}
            <View css={styles.dot}>:</View>
            {renderTime(hours, 'hours')}
            <View css={styles.dot}>:</View>
            {renderTime(minutes, 'mins')}
            <View css={styles.dot}>:</View>
            {renderTime(seconds, 'secs')}
          </View>
        )}
      </View>
    </View>
  );
};
