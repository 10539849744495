import { CodeSpliting } from 'components/CodeSpliting';
import SectionPageHeader from 'components/SectionPageHeader';
import { ModalCreateProduct, ModalDeletePageDashboard } from 'containers/Admin/Modals';
import { useChangeModalAdminSettings } from 'containers/Admin/Modals/store';
import { Dashboard } from 'containers/Dashboard';
import { CreateProductPicker } from 'containers/Shopify/ModalCreateShopifyPicker';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { productPageSelector } from 'store/selectors';
import { i18n } from 'translation';
import { PRODUCT_PAGE_MAIN_CONTENT } from 'utils/constants/chunkIds';
import { View } from 'wiloke-react-core';
import { useGetProductPageItems } from '.';
import { ProductsSettings } from '../DashboardPageSettings/components/ProductsSettings';
import { DashboardPageSettings } from '../DashboardPageSettings/DashboardPageSettings';

export const ProductsPage: FC = () => {
  const changeSettings = useChangeModalAdminSettings();
  const { filterType, search } = useSelector(productPageSelector);
  const getItems = useGetProductPageItems();

  useEffect(() => {
    getItems.request({ s: search, pageType: 'product', filterType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filterType]);

  return (
    <Dashboard
      pageType="product"
      Modals={() => (
        <>
          <ModalCreateProduct />
          <ModalDeletePageDashboard pageType="product" />
          <DashboardPageSettings />
          <ProductsSettings />
          <CreateProductPicker />
        </>
      )}
      Content={() => (
        <View>
          <SectionPageHeader
            pageType="product"
            title={i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.product') })}
            description={i18n.t('adminDashboard.page_description.product')}
            onClick={() => {
              // mở modal tạo product page
              changeSettings({ createProduct: true });
            }}
          />

          <CodeSpliting
            component={() => import(/* webpackChunkName: "ProductPageMainContent", webpackPrefetch: true */ './MainContent')}
            CHUNK_ID={PRODUCT_PAGE_MAIN_CONTENT}
          />
        </View>
      )}
    />
  );
};
