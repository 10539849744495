import { SetThemeVersionAction } from 'store/global/themeVersion/types';
import getPageInfo from './getInfo';

export function getThemeVersion(themeVersion: SetThemeVersionAction['payload']) {
  if (
    process.env.NODE_ENV === 'development' ||
    getPageInfo('shop')?.includes('magicbadgesdev') ||
    getPageInfo('shop')?.includes('vua-cua-ha-noi') ||
    getPageInfo('shop')?.includes('veda-')
  ) {
    return '>=2.0.0';
  }
  if (themeVersion.version?.startsWith('1.') || themeVersion.version == null) {
    return '1.0.0';
  }
  return '>=2.0.0';
}
