import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import fetchAPI from 'utils/functions/fetchAPI';

interface Params {
  userId: number;
  verifyStatus: 1 | 2;
}

interface ResponseSuccess {
  message: string;
}

export const banAndUnBanUser = async ({ userId, verifyStatus }: Params) => {
  const urlBan = `${configureApp.endpoint.users}/admin/bans/${userId}`;
  const urlUnBan = `${configureApp.endpoint.users}/admin/bans/${userId}/unlock`;

  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    method: 'PUT',
    url: verifyStatus === 2 ? urlBan : urlUnBan,
  });

  return response.data;
};
