import { getAllJsOfTheme, getCssFromThemeSettings, getHtmlFilesOfTheme, getVendorsOfTheme } from 'generate/utils/getFilesForSave';
import { ThemeInteracting } from 'packages/ExtractThemeToFileForSync/@types/ThemeInteracting';
import {
  HeaderFooterOfThemeIssue,
  ModalWarningDuplicateCommandId,
} from 'packages/ExtractThemeToFileForSync/components/ModalWarningDuplicateCommandId';
import { AddonOfTheme_Atom_N_Client } from 'types/Addons';
import { File, Result } from 'types/Result';
import { PageSection } from 'types/Sections';
import { getAddons } from './getAddons';
import { getFooterSections } from './getFooterSections';
import { getHeaderSections } from './getHeaderSections';
import { getMegamenus } from './getMegamenus';
import { getTheme } from './getTheme';
import { getExtractResultOfThemeCacheKey } from './utils/CacheKeyControl';
import { pushToCache, readFromCache } from './utils/CacheStorage';

interface ExtractResultOfTheme {
  cacheKeysOfFooters: Awaited<ReturnType<typeof getFooterSections>>;
  cacheKeysOfHeaders: Awaited<ReturnType<typeof getHeaderSections>>;
  cacheKeysOfMegamenusInHeaders: Awaited<ReturnType<typeof getMegamenus>>;
  cacheKeysOfMegamenusInFooters: Awaited<ReturnType<typeof getMegamenus>>;
  cacheKeysOfAddons: Awaited<ReturnType<typeof getAddons>>;
  cacheKeyOfTheme: Awaited<ReturnType<typeof getTheme>>;
  isExportForEnvato: boolean;
}

const createId = (file: File) => file.id;
export const extractResultOfTheme = async ({
  cacheKeysOfFooters,
  cacheKeysOfMegamenusInFooters,
  cacheKeysOfHeaders,
  cacheKeysOfMegamenusInHeaders,
  cacheKeyOfTheme,
  cacheKeysOfAddons,
  isExportForEnvato,
}: ExtractResultOfTheme): Promise<string> => {
  const storageForTrackDuplicate = new Map<string, PageSection>();
  const temporaryErrors: HeaderFooterOfThemeIssue['data'] = [];

  const headerSections_ = await Promise.all(cacheKeysOfHeaders.headerKeys.map(cacheKey => readFromCache<PageSection>(cacheKey)));
  const megamenuSectionsOfHeaders_ = await Promise.all(cacheKeysOfMegamenusInHeaders.map(cacheKey => readFromCache<PageSection>(cacheKey)));
  const footerSections_ = await Promise.all(cacheKeysOfFooters.footerKeys.map(cacheKey => readFromCache<PageSection>(cacheKey)));
  const megamenuSectionsOfFooters_ = await Promise.all(cacheKeysOfMegamenusInFooters.map(cacheKey => readFromCache<PageSection>(cacheKey)));
  const addons_ = await Promise.all(cacheKeysOfAddons.map(cacheKey => readFromCache<AddonOfTheme_Atom_N_Client>(cacheKey)));
  const theme_ = await readFromCache<ThemeInteracting['theme']>(cacheKeyOfTheme.themeKey);

  const headerSections = headerSections_ as PageSection[];

  const megamenuSectionsOfHeaders = megamenuSectionsOfHeaders_ as PageSection[];

  const footerSections = footerSections_ as PageSection[];
  const megamenuSectionsOfFooters = megamenuSectionsOfFooters_ as PageSection[];
  const addons = addons_ as AddonOfTheme_Atom_N_Client[];
  const { globalJs, globalScss, vendors: themeVendors, themeSettings, commandId, label } = theme_ as ThemeInteracting['theme'];

  const headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections = headerSections.concat(megamenuSectionsOfHeaders);
  const footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections = footerSections.concat(megamenuSectionsOfFooters);
  const { cssVariables, generalSettings: themeGeneralSettings, layoutSettings, globalTranslations, colorSchemes } = themeSettings;

  const js = getAllJsOfTheme({
    variant: 'Toàn bộ theme - bao gồm header, footer, theme settings',
    headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
    footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
    globalJs,
    themeGeneralSettings,
  });
  const [htmlFilesInPreloader, ...htmlFilesInHeader] = getHtmlFilesOfTheme({
    headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
    footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: [],
    themeGeneralSettings,
    errorOption: 'throw',
    variant: 'Toàn bộ theme - bao gồm header, footer, theme settings',
  });

  const [, ...htmlFilesInFooter] = getHtmlFilesOfTheme({
    headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: [],
    footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
    themeGeneralSettings,
    errorOption: 'throw',
    variant: 'Toàn bộ theme - bao gồm header, footer, theme settings',
  });

  [...htmlFilesInHeader, ...htmlFilesInFooter].forEach(file => {
    if (file.section) {
      const ID = createId(file);
      const itemInStorageForTrackDuplicate = storageForTrackDuplicate.get(ID);
      if (itemInStorageForTrackDuplicate) {
        temporaryErrors.push({
          sectionError: file.section,
          sectionExisted: itemInStorageForTrackDuplicate,
        });
      }
      storageForTrackDuplicate.set(ID, file.section);
    }
  });

  const css = await getCssFromThemeSettings({
    headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
    footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
    globalScss,
    themeGeneralSettings,
    variant: 'Toàn bộ theme - bao gồm header, footer, theme settings',
    target: isExportForEnvato
      ? 'Trích xuất kết quả sync lên shopify envato'
      : 'Trích xuất kết quả sync lên shopify hoặc Lấy css để hiển thị trong builder',
    atomicCssForAdminNDev: '',
  });
  const vendors = getVendorsOfTheme({
    vendors: themeVendors,
    themeGeneralSettings,
    cssVariables,
    layout: layoutSettings,
    colorSchemes,
    themeVersion: {
      // FIXME: Mặc định cho là 2.0
      version: '2.0.0',
    },
    target: isExportForEnvato
      ? 'Trích xuất kết quả sync lên shopify envato'
      : 'Trích xuất kết quả sync lên shopify hoặc Lấy css để hiển thị trong builder',
  });
  const result: Result['theme'] = {
    themeSettings: {
      generalSettings: themeGeneralSettings,
      cssVariables,
      layoutSettings,
      globalTranslations,
      colorSchemes,
    },
    addons: addons,
    header: headerSections,
    footer: footerSections,
    themeName: label,
    themeId: commandId,
    vendors: themeVendors,
    globalJs,
    globalScss,
    files: [...js, ...css, ...htmlFilesInHeader, ...htmlFilesInFooter, htmlFilesInPreloader, ...vendors],
  };

  if (temporaryErrors.length) {
    ModalWarningDuplicateCommandId.setError?.({
      type: 'theme',
      data: temporaryErrors,
    });
  }
  const cacheKey = await pushToCache(JSON.stringify(result), getExtractResultOfThemeCacheKey(commandId));
  return cacheKey;
};
