import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BE_ThemeClient } from 'services/ThemeService/VedaApplication/types';
import fetchAPI from 'utils/functions/fetchAPI';
import { getApiTracking } from 'utils/functions/getApiTracking';
import { clientBaseUrl } from '../const';

interface GetClients {
  type: 'GET FIRST PAGE';
  cacheVersion: number | null;
  themeStatus?: string;
  size: number;
}
interface LoadmoreClients {
  type: 'LOADMORE';
  cacheVersion: number | null;
  /** Cursor để loadmore */
  lastCursor: string;
  themeStatus?: string;
  size: number;
}

interface ResponseSuccess {
  message: string;
  info: BE_ThemeClient[];
}

/** API được sử dụng để lấy về dữ liệu của theme client */
export const getClients = async ({ size, type, cacheVersion, themeStatus, ...params }: GetClients | LoadmoreClients) => {
  let requestConfig: AxiosRequestConfig = {};
  const url = getApiTracking({ defaultBaseUrl: `${clientBaseUrl}/search`, trackingEndpoint: `themes/search` });
  if (type === 'GET FIRST PAGE') {
    requestConfig = {
      url,
      params: {
        size,
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
        themeStatus: themeStatus ? themeStatus : undefined,
      },
    };
  } else {
    const { lastCursor } = params as LoadmoreClients;
    requestConfig = {
      url,
      params: {
        after: lastCursor,
        size,
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
        themeStatus: themeStatus ? themeStatus : undefined,
      },
    };
  }
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request(requestConfig);
  return response.data;
};
