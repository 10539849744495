import { all, call, retry, SagaReturnType, select } from '@redux-saga/core/effects';
import { changeSelectPageType } from 'containers/Admin/PageBuilder/TemplatesPage';
import { put } from 'redux-saga/effects';
import { getMegaMenusOfPageClientUseAtomTemplate } from 'services/PageService/Logic/getMegaMenusOfPageClientUseAtomTemplate';
import { getPageClientUseAtomTemplate } from 'services/PageService/Logic/getPageClientUseAtomTemplate';
import { getSectionsOfPageClientUseAtomTemplate } from 'services/PageService/Logic/getSectionsOfPageClientUseAtomTemplate';
import { getChangelogsOfAtoms as getChangelogsOfSectionAtoms } from 'services/SectionService/Logic/Changelogs';
import { getChangelogsOfAtoms as getChangelogsOfMegamenuAtoms } from 'services/MegaMenuService/Logic/Changelogs';
import { getChangelogsOfAtoms as getChangelogsOfAddonAtoms } from 'services/AddonService/Logic/Changelogs';
import { addMegaMenusToPage, getPage } from 'store/actions/actionPages';
import { setVendors } from 'store/actions/actionVendors';
import { getAddonVersion, getSectionVersion } from 'store/actions/versions/actionSectionVersion';
import { setGeneralSettingsPage } from 'store/global/generalSettings/slice';
import { setGlobalJs } from 'store/global/globalJs/slice';
import { setGlobalScss } from 'store/global/globalScss/slice';
import { getGeneralSettingsPage } from 'store/global/statusGeneralSettings/actions';
import { defaultPickerRelateShopifySelector } from 'store/selectors';
import { ClientPage, PageType } from 'types/Page';
import { adapterSectionsHadShopifyData } from 'utils/adapterSectionHadShopifyData';
import { getAddonsOfPageClientUseAtomTemplate } from 'services/PageService/Logic/getAddonsOfPageClientUseAtomTemplate';
import { setThemeAddons } from 'store/global/themeAddons';
import { adapterGetAddons } from 'services/AddonService/Adapters/adapterGetAddons';
import { adapterAddonsHadShopifyData } from 'utils/adapterAddonHadShopifyData';
import { insertPageColorSchemeToTheme } from 'store/global/colorSchemes/slice';
import { themeDashboardSelector } from 'containers/Admin/ThemeBuilder/ThemeDashboard/slice/sliceThemeDashboard';
import { ClientTheme } from 'types/Theme';
import {
  handleGetFooterSections,
  handleGetHeaderSections,
} from 'containers/Admin/ThemeBuilder/ThemeTemplates/sagas/watchGetCurrentTheme/ThemeClient/handleGetThemeClient';

export function* handleGetPageClientUseAtomTemplate_({ payload }: ReturnType<typeof getPage.request>) {
  const { id, type, onFulfill } = payload;

  try {
    // timestamp nối vào sectionId của addon, addonIds của section, data-id của liquid section
    const current_timestamp = new Date().getTime();
    const { themeActivate }: ReturnType<typeof themeDashboardSelector> = yield select(themeDashboardSelector);
    yield all([call(handleGetFooterSections, themeActivate as ClientTheme), call(handleGetHeaderSections, themeActivate as ClientTheme)]);

    /** Xử lý "pageSettings" */
    const page: Awaited<ReturnType<typeof getPageClientUseAtomTemplate>> = yield retry(3, 1000, getPageClientUseAtomTemplate, {
      commandId: id,
      type,
    });
    if (page.pageSettings) {
      const { generalSettings, globalJs, globalScss, vendors, colorSchemes } = page.pageSettings;
      const headerFooterEnabled = payload.headerFooterEnabled ?? generalSettings.headerFooterEnabled;

      yield put(
        setGeneralSettingsPage({
          settings: {
            ...generalSettings,
            headerFooterEnabled,
            label: payload.name || page.label,
          },
          pageId: payload.id,
        }),
      );
      yield put(setGlobalJs({ js: globalJs ?? '' }));
      yield put(setGlobalScss({ scss: globalScss ?? '' }));
      yield put(setVendors({ vendors: vendors ?? [] }));

      if (colorSchemes) {
        yield put(insertPageColorSchemeToTheme({ colors: colorSchemes }));
      }
    }

    yield put(getGeneralSettingsPage.success(undefined));

    /** Xử lý addons */
    /** Xử lý lấy addon */
    const addonResponse: Awaited<ReturnType<typeof getAddonsOfPageClientUseAtomTemplate>> = yield retry(
      3,
      1000,
      getAddonsOfPageClientUseAtomTemplate,
      {
        commandIds: page.addonCommandIds ?? [],
        timestamp: current_timestamp,
      },
    );

    /** Xử lý addon addons version */
    const addonSectionSourceIds = Array.from(
      new Set(
        addonResponse.reduce<string[]>((res, addonSection) => {
          if (addonSection.parentCommandId) {
            return res.concat(addonSection.parentCommandId);
          }
          return res;
        }, []),
      ),
    ) as string[];
    yield all(
      addonSectionSourceIds.map(addonSectionCommandId => {
        return put(getAddonVersion.request({ addonCommandId: addonSectionCommandId }));
      }),
    );
    const addonSectionsVersion: SagaReturnType<typeof getChangelogsOfAddonAtoms> = yield retry(
      3,
      1000,
      getChangelogsOfAddonAtoms,
      addonSectionSourceIds,
    );
    yield all(
      addonSectionsVersion.map(({ atomCommandId, version }) => put(getAddonVersion.success({ addonCommandId: atomCommandId, data: version }))),
    );

    /** Xử lý sections */
    const sectionCommandIds = page.sectionCommandIds;
    const sections: Awaited<ReturnType<typeof getSectionsOfPageClientUseAtomTemplate>> = yield retry(
      3,
      1000,
      getSectionsOfPageClientUseAtomTemplate,
      { sectionCommandIds, timestamp: current_timestamp },
    );

    /** Xử lý section version */
    const sectionSourceIds = sections.reduce<string[]>((res, section) => {
      if ('parentCommandId' in section && section.parentCommandId) {
        return res.concat(section.parentCommandId);
      }
      return res;
    }, []);
    yield all(
      sectionSourceIds.map(sectionCommandId => {
        return put(getSectionVersion.request({ sectionCommandId: sectionCommandId }));
      }),
    );
    const sectionsVersion: SagaReturnType<typeof getChangelogsOfSectionAtoms> = yield retry(3, 1000, getChangelogsOfSectionAtoms, sectionSourceIds);
    yield all(
      sectionsVersion.map(({ atomCommandId, version }) => put(getSectionVersion.success({ sectionCommandId: atomCommandId, data: version }))),
    );

    /** Xử lý megamenu */
    const megamenuResponses: Array<Awaited<ReturnType<typeof getMegaMenusOfPageClientUseAtomTemplate>> | undefined> = yield all(
      sections.map(section => {
        const { megaMenuCommandIds } = section;
        if (megaMenuCommandIds?.length) {
          return retry(3, 1000, getMegaMenusOfPageClientUseAtomTemplate, { megamenuCommandIds: megaMenuCommandIds });
        }
      }),
    );
    /** Xử lý megamenu version */
    const megamenuSectionSourceIds = Array.from(
      new Set(
        megamenuResponses.reduce<string[]>((res, megamenuResponse) => {
          if (megamenuResponse) {
            megamenuResponse.map(megamenuSection => {
              if ('parentCommandId' in megamenuSection && megamenuSection.parentCommandId) {
                res.push(megamenuSection.parentCommandId);
              }
            });
          }
          return res;
        }, []),
      ),
    ) as string[];
    yield all(
      megamenuSectionSourceIds.map(sectionSectionCommandId => {
        return put(getSectionVersion.request({ sectionCommandId: sectionSectionCommandId }));
      }),
    );
    const megamenuSectionsVersion: SagaReturnType<typeof getChangelogsOfMegamenuAtoms> = yield retry(
      3,
      1000,
      getChangelogsOfMegamenuAtoms,
      megamenuSectionSourceIds,
    );
    yield all(
      megamenuSectionsVersion.map(({ atomCommandId, version }) => put(getSectionVersion.success({ sectionCommandId: atomCommandId, data: version }))),
    );

    // NOTE: @tuong -> Dùng page được publish bởi 1 người khác + Dữ liệu shopify của mỗi shop là không giống nhau => Có thể check như thế này để gán lại dữ liệu shopify vào các picker cũng như những thứ khác liên quan đến shopify
    const { data: defaultPickerRelateShopifyData }: ReturnType<typeof defaultPickerRelateShopifySelector> = yield select(
      defaultPickerRelateShopifySelector,
    );
    const { article, blog, collection, product } = defaultPickerRelateShopifyData;
    // TODO: Utils transform thay vì ép kiểu
    const final_page: ClientPage = {
      ...page,
      parentCommandId: page.parentCommandId ?? undefined,
      image: page.image ?? { src: '', width: 0, height: 0 },
      // @tuong -> Convert dữ liệu liên quan đến shopify từ page mẫu
      sections: adapterSectionsHadShopifyData({
        sections,
        article,
        blog,
        collection,
        product,
        isUsePageTemplateAction: true,
      }),
      type: payload.type ?? (page.type as PageType),
      label: payload.name || page.label,
      // NOTE: @tuong -> Client dùng mẫu -> Chắc chắn phải chọn entity shopify từ bên ngoài và chưa publish
      shopifyRepresentPage: payload.shopifyRepresentPage,
      shopifyPages: payload.shopifyPages,
      enable: false,
      entityType: 'Client',
    };

    yield put(getPage.success({ result: final_page }));
    yield all(
      megamenuResponses.map(megamenuResponse => {
        if (megamenuResponse) {
          return put(
            addMegaMenusToPage(
              adapterSectionsHadShopifyData({
                sections: megamenuResponse,
                article,
                blog,
                collection,
                product,
                isUsePageTemplateAction: true,
              }),
            ),
          );
        }
      }),
    );
    yield put(
      setThemeAddons({
        addons: adapterGetAddons(
          adapterAddonsHadShopifyData({
            addons: addonResponse,
            article,
            blog,
            collection,
            product,
            isUsePageTemplateAction: true,
          }),
          true,
        ),
      }),
    );
    yield put(changeSelectPageType(final_page.type));
    onFulfill?.(final_page);
  } catch (error) {
    console.log(error);
    if (error instanceof Error) {
      const message = error.message;
      yield put(getPage.failure({ message, id }));
      yield put(getGeneralSettingsPage.failure(undefined));
      throw error;
    }
  }
}
