import { Empty, MyModal } from '@wiloke/ui';
import { FC, useEffect } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { FontAwesome, Text, View } from 'wiloke-react-core';
import * as styles from './styles';
import { Navigate, StepModalProps, StepModalStatic } from './types';
import { useStep } from './useStep';

const navigateHandlers = new Map<string, Navigate>();

export const StepModal: FC<StepModalProps> & StepModalStatic = ({
  data = [],
  initialStep = 0,
  visible = false,
  headerText = 'Steps Modal',
  id,
  showStepHeader = true,
  modalContentCss,
  modalBodyCss,
  disableNextPrevIcon = true,
  variant = 'style1',
  ExtraContent,
  onClose,
  onChange,
}) => {
  const { navigation, step, index } = useStep({ steps: data, initialStep });
  const MAX_LENGTH = data.length;

  useEffect(() => {
    navigateHandlers.set(id, callback => {
      callback({ next: navigation.next, previous: navigation.previous, go: navigation.go });
    });
    return () => {
      navigateHandlers.delete(id);
    };
  }, [navigation, id]);

  useDeepCompareEffect(() => {
    if (visible) {
      onChange?.({ step, indexStep: index });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [[visible], [index]]);

  const renderStep = data.map((item, _index) => {
    const _stepIdx = _index + 1;
    return (
      <View className="step-modal__dot-container" key={item.id} css={styles.dotContainer}>
        <View className="step-modal__dot-index" css={styles.dot(item.id === step.id)}>
          {_stepIdx}
        </View>
        <View className="step-modal__dot-text" css={styles.dotText}>
          <Text tagName="span" color="gray9" size={12}>
            Step {_stepIdx}
          </Text>
          <br />
          {item.stepDescription && (
            <Text tagName="span" color="gray9" size={14}>
              {item.stepDescription}
            </Text>
          )}
        </View>
      </View>
    );
  });

  const renderStyle1 = () => {
    return (
      <View css={styles.container('style1')}>
        {showStepHeader && <View css={styles.steps}>{renderStep}</View>}

        <View css={styles.item}>
          {step.heading && (
            <Text color="gray9" css={styles.title}>
              {step.heading}
            </Text>
          )}
          <View>{step.content}</View>

          {!disableNextPrevIcon && (
            <>
              <View css={styles.iconLeft(index === 0)} onClick={navigation.previous}>
                <FontAwesome color="gray9" type="far" size={18} name="angle-left" />
              </View>
              <View css={styles.iconRight(index === MAX_LENGTH - 1)} onClick={navigation.next}>
                <FontAwesome color="gray9" type="far" size={18} name="angle-right" />
              </View>
            </>
          )}
        </View>

        {step.buttons && (
          <View className="step-modal__footer">{step.buttons?.({ go: navigation.go, next: navigation.next, previous: navigation.previous })}</View>
        )}
      </View>
    );
  };

  const renderStyle2 = () => {
    return (
      <View css={styles.containerStyle2}>
        <View css={styles.container('style2')}>
          {showStepHeader && <View css={styles.steps}>{renderStep}</View>}

          <View css={styles.item}>
            {step.heading && (
              <Text color="gray9" css={[styles.title, { paddingTop: '40px' }]}>
                {step.heading}
              </Text>
            )}
            <View>{step.content}</View>

            {!disableNextPrevIcon && (
              <>
                <View css={styles.iconLeft(index === 0)} onClick={navigation.previous}>
                  <FontAwesome color="gray9" type="far" size={18} name="angle-left" />
                </View>
                <View css={styles.iconRight(index === MAX_LENGTH - 1)} onClick={navigation.next}>
                  <FontAwesome color="gray9" type="far" size={18} name="angle-right" />
                </View>
              </>
            )}
          </View>

          {step.buttons && (
            <View className="step-modal__footer">{step.buttons?.({ go: navigation.go, next: navigation.next, previous: navigation.previous })}</View>
          )}
        </View>
        <View css={styles.extraNode}>{ExtraContent}</View>
      </View>
    );
  };

  return (
    <MyModal
      headerText={headerText}
      okText={''}
      cancelText={''}
      isVisible={visible}
      disableFooter
      onCancel={onClose}
      bodyCss={[styles.modalBody, modalBodyCss]}
      contentCss={[styles.modalChild, modalContentCss]}
    >
      {data.length ? variant === 'style1' ? renderStyle1() : renderStyle2() : <Empty />}
    </MyModal>
  );
};

StepModal.getId = (id: StepModalProps['id']) => {
  const onNavigate = navigateHandlers.get(id);

  if (onNavigate) {
    return {
      onNavigate,
    };
  }
  throw new Error(`StepModal: ${id} not exist`);
};
