interface ArrayDefault {
  name: string;
  type: 'object' | 'array' | 'hidden';
  children: ArrayDefault[];
}

interface ValueDefault {
  name: string;
  value: any;
}

function set<T extends ArrayDefault[] = ArrayDefault[], V extends ValueDefault = ValueDefault>(array: T, value: V, name = ''): T {
  return array.map(item => {
    const path = `${name ? `${name}.` : ''}${item.name}`;
    if (item.type === 'object') {
      return {
        ...item,
        children: set(item.children, value, item.name),
      };
    }
    if (item.type === 'array') {
      return {
        ...item,
        children: item.children.map((child, index) => {
          return {
            ...child,
            children: set(child.children, value, `${item.name}[${index}]`),
          };
        }),
      };
    }
    if (item.type === 'hidden' && path === value.name) {
      return {
        ...item,
        children: value.value,
      };
    }
    return item;
  }) as T;
}

function get<T extends ArrayDefault[] = ArrayDefault[], P extends string = string>(array: T, pathParam: P, name = '') {
  let result: any;
  array.forEach(item => {
    const path = `${name ? `${name}.` : ''}${item.name}`;
    if (item.type === 'object' && result == null) {
      result = get(item.children, pathParam, path);
    }
    if (item.type === 'array') {
      item.children.forEach((child, index) => {
        if (result == null) {
          const name_ = `${path}[${index}]`;
          if (name_ === pathParam) {
            result = child.children;
          } else {
            result = get(child.children, pathParam, name_);
          }
        }
      });
    }
    if (item.type === 'hidden' && path === pathParam) {
      result = item.children;
    }
  });
  return result;
}

export function setSettingsValueByPath<T = ArrayDefault[], V extends ValueDefault = ValueDefault>(array: T, value: V) {
  return set(array as any, value);
}

export function getSettingsValueByPath<T = ArrayDefault[], P extends string = string>(array: T, path: P) {
  return get(array as any, path);
}
