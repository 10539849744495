import { Prettier } from 'utils/functions/Prettier';
import { handleWaitForSocketOfSyncShopifyFulfill } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { call, retry, select, SagaReturnType } from 'redux-saga/effects';
import { handleSaveInBuilderPage, shopifyConnectionService } from 'services/ShopifyConnection';
import { pagesSelector } from 'store/selectors';
import { getAddonHtml } from 'utils/functions/getAddonHtml';
import { handleGetScopeOfAddon } from 'utils/LiquidSyntaxToTwig';
import { strToRegexpPattern } from '@wiloke/functions';
import { SyncAddonsEnablePositionResult } from '../syncAddons';
import getPageInfo from 'utils/functions/getInfo';
import { PAGE_BUILD_HEADER_FOOTER } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';

export interface SyncPage {
  pageParams: ReturnType<typeof handleSaveInBuilderPage>['pagesParams'][number];
  signalToReplaceAddonInLiquidCode: SyncAddonsEnablePositionResult['signalToReplaceAddonInLiquidCode'];
}

export interface SyncPageResult {
  statusSyncPage: SyncFulfillStatus;
  errorId: string | undefined;
  isNeedIgnoreReportError: boolean;
}

/** @tuong ->
  Tại thời điểm comment này được viết -> Chỉ có thể truyền các biến được tạo trong cặp thẻ shopify vì các biến khác có thể gán bằng cách settings

  - Giả sử bài toán cần truyền 1 giá trị nằm trong "section" chứa "addon" -> Cơ bản là khi đó cần tìm các biến nằm trong liquid gốc - liquid chưa được compile - để lấy tên các biến và gán lại vào tag "render"
  -> Nhiều hướng giải bài toán
 */
const replaceAddonHtml = (liquid: string, addonFilesAfterSync: Array<{ addonId: string; fileNameWithoutExtension: string }>) => {
  let _liquid = liquid;
  addonFilesAfterSync.forEach(addonFile => {
    const { addonId, fileNameWithoutExtension } = addonFile;
    const regexp = new RegExp(`${strToRegexpPattern(getAddonHtml(addonId))}`, 'g');
    const { variables } = handleGetScopeOfAddon({ liquidCompiled: liquid.substring(0, liquid.search(regexp)) });
    _liquid = _liquid.replace(
      regexp,
      `
        {% capture addon_${addonId}_content %}
          {% render '${fileNameWithoutExtension}' %}
        {% endcapture %}

        {% unless addon_${addonId}_content contains "Could not find asset" %}
         {% render '${fileNameWithoutExtension}', ${variables.map(variable => `${variable}:${variable}`).join(',')} %}
        {% endunless %}
      `,
    );
  });
  return _liquid;
};

export function* syncPage({ pageParams, signalToReplaceAddonInLiquidCode }: SyncPage) {
  const addonFilesAfterSync = signalToReplaceAddonInLiquidCode.map(item => ({
    addonId: item.id,
    fileNameWithoutExtension: item.fileNameWithoutExtension,
  }));
  const pages: ReturnType<typeof pagesSelector> = yield select(pagesSelector);

  const pageIdParams = getPageInfo('id');
  const { name } = getUserInfo();

  const pageCommandId_ =
    pageIdParams === 'blank' || pageIdParams === PAGE_BUILD_HEADER_FOOTER ? pages.data['blank']?.commandId : pageParams.pageCommandId;

  if (pageCommandId_) {
    yield retry(3, 1000, shopifyConnectionService.writePageToShopify, {
      ...pageParams,
      pageCommandId: pageCommandId_,
      assets: {
        ...pageParams.assets,
        files: pageParams.assets?.files?.map(file => {
          if (file && file.type === 'section' && file.content) {
            return {
              ...file,
              content: Prettier.liquid(replaceAddonHtml(file.content, addonFilesAfterSync)),
            };
          }
          return file;
        }),
      },
      isPreview: false,
      author: { name },
    });
    const socketSyncPage: SagaReturnType<typeof handleWaitForSocketOfSyncShopifyFulfill> = yield call(
      handleWaitForSocketOfSyncShopifyFulfill,
      'Ghi file khi save ở builder page / Ghi page',
    );
    return {
      statusSyncPage: socketSyncPage.statusSync,
      errorId: socketSyncPage.sectionCommandId,
      isNeedIgnoreReportError: socketSyncPage.isNeedIgnoreReportError,
    } as SyncPageResult;
  }
  return { statusSyncPage: 'success', errorId: undefined, isNeedIgnoreReportError: false } as SyncPageResult;
}
