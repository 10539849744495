import axios, { AxiosResponse } from 'axios';
import { ShopifyFontResponse } from 'services/ShopifyServices/Fonts/types';

const URL = 'https://cors-anywhere.vedabuilder.com/https://shopify.dev/json/shopify_font_families.json';

const getFontsShopify = async () => {
  const response: AxiosResponse<ShopifyFontResponse> = await axios.request({
    url: URL,
  });

  return response.data;
};

export const fontService = { getFontsShopify };
