import { MyModal } from '@wiloke/ui';
import { useSelector } from 'react-redux';
import { View } from 'wiloke-react-core';
import { upgradeOrChangePlanSelector } from './sliceUpgradePlan';

export const ModalChangePlanName = () => {
  const { visibleChangePlanName } = useSelector(upgradeOrChangePlanSelector);
  // const { sendMessage } = useCrispChat();

  return (
    <MyModal headerText="We're sorry" isVisible={visibleChangePlanName} cancelText="" okText="">
      <View tagName="a" href="https://www.shopify.com/pricing?svlink=8214506" target="_blank" color="primary">
        Apologies, Veda does not support your Shopify plan. Please upgrade to a Shopify Plan to use the app
      </View>
    </MyModal>
  );
};
