import SectionPageHeader from 'components/SectionPageHeader';
import { ModalCreateNormalPage, ModalDeletePageDashboard } from 'containers/Admin/Modals';
import { useChangeModalAdminSettings } from 'containers/Admin/Modals/store';
import { DashboardPageSettings } from 'containers/Admin/PageBuilder/DashboardPageSettings/DashboardPageSettings';
import { Dashboard } from 'containers/Dashboard';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { customerPagesSelector } from 'store/selectors';
import { i18n } from 'translation';
import { PageType } from 'types/Page';
import { View } from 'wiloke-react-core';
import { MainContent } from './components/MainContent';
import { useGetCustomerPages } from './store/reducers';

export type CustomerPageType = Exclude<PageType, 'blog' | 'article' | 'page' | 'home' | 'collection' | 'product'>;

const customerPagesTitle: Record<CustomerPageType, string> = {
  account: i18n.t('adminDashboard.account'),
  activateAccount: i18n.t('adminDashboard.active_account'),
  addresses: i18n.t('adminDashboard.addresses'),
  cart: i18n.t('adminDashboard.cart'),
  collections: i18n.t('adminDashboard.collectionListing'),
  giftCard: i18n.t('adminDashboard.giftCard'),
  login: i18n.t('adminDashboard.customersLogin'),
  order: i18n.t('adminDashboard.customersOrder'),
  pageNotFound: i18n.t('adminDashboard.notFound'),
  password: i18n.t('adminDashboard.password'),
  register: i18n.t('adminDashboard.customersRegister'),
  resetPassword: i18n.t('adminDashboard.customersResetPassword'),
  search: i18n.t('adminDashboard.search'),
};

export const CustomerPages: FC = () => {
  const { filterType, search } = useSelector(customerPagesSelector);

  const { pageType } = useParams<{ pageType: CustomerPageType }>();
  const changeSettings = useChangeModalAdminSettings();
  const getCustomerPages = useGetCustomerPages();

  useEffect(() => {
    getCustomerPages.request({ filterType, pageType: pageType as PageType, s: search });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType, pageType, search]);

  return (
    <Dashboard
      pageType={pageType as PageType}
      Modals={() => (
        <>
          <ModalCreateNormalPage pageType={pageType as PageType} />
          <ModalDeletePageDashboard pageType={pageType as PageType} />
          <DashboardPageSettings pageType={pageType as PageType} />
        </>
      )}
      Content={() => (
        <View>
          <SectionPageHeader
            pageType={pageType as PageType}
            title={i18n.t('adminDashboard.pages', { name: customerPagesTitle[pageType as CustomerPageType] })}
            description=""
            onClick={() => {
              changeSettings({ createNormalPage: true });
            }}
          />

          <MainContent />
        </View>
      )}
    />
  );
};
