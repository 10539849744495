import ChooseImage from 'components/ChooseImage';
import Field from 'components/Field';
import { MyModal, Button } from '@wiloke/ui';
import SelectAntd, { Option } from 'components/SelectAntd';
import TextInput from 'components/TextInput';
import withDebounce from 'hocs/withDebounce';
import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { i18n } from 'translation';
import { PageType, PreviewImage } from 'types/Page';
import { Divider, View } from 'wiloke-react-core';
import { usePublishPageAtom } from './store/actions/actionPagesAtom';
import { useCreateCategoryOfPageProduct, useGetCategoriesOfPageProduct } from './store/actions/actionPagesProduct';
import { pagesAtomSelector, useModalPublishPage } from './store/reducers/slicePagesAtom';
import { pagesProductSelector } from './store/reducers/slicePagesProduct';
import { PLAN_HANDLE } from 'utils/constants/PlanEnum';
import { NumberInput } from 'components/NumberInput';
import { InputTags } from 'components/SelectTags';
import { pageTypeData } from 'containers/BuilderPage/components/ModalSaveForBuilder/utils/pageTypeData';
import SwitchBeauty from 'components/SwitchBeauty';
import { useGetPageUseCases } from 'containers/Admin/Management/Pages/store/actions/actionPageUseCase';
import { pageUseCaseSelector } from 'containers/Admin/Management/Pages/store/reducers/slicePageUseCase';
import { TextEditor2 } from 'components/TextEditor2';

const DebounceInput = withDebounce(TextInput, 'value', 'onValueChange');
const TextEditorDebounce = withDebounce(TextEditor2, 'value', 'onChange');

const plans: Array<{ label: string; value: PLAN_HANDLE }> = [
  {
    value: 'free',
    label: 'Free',
  },
  {
    value: 'business',
    label: 'Business',
  },
  {
    value: 'advanced',
    label: 'Advanced',
  },
  {
    value: 'ultimate',
    label: 'Ultimate',
  },
];

interface Actions {
  type: 'setPageInfo';
  payload: Partial<State>;
}

interface State {
  image: PreviewImage;
  plan: PLAN_HANDLE;
  category: {
    name: string;
    description: string;
  };
  createdCategory: {
    name: string;
    description: string;
  };
  label: string;
  searchTerms: string[];
  priorityOrder: number;
  previewUrl: string;
  types: PageType[];
  isSticky: boolean;
  usecases: string[];
  description: string;
}

const reducerPageInfo = (state: State, action: Actions) => {
  switch (action.type) {
    case 'setPageInfo': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

const defaultState: State = {
  category: {
    name: '',
    description: '',
  },
  createdCategory: {
    name: '',
    description: '',
  },
  image: {
    src: '',
    width: 0,
    height: 0,
  },
  label: '',
  plan: 'free',
  priorityOrder: 0,
  searchTerms: [],
  previewUrl: '',
  types: [],
  isSticky: false,
  usecases: [],
  description: '',
};

export const ModalPublishPage = () => {
  const { modalPublish, publishStatus } = useSelector(pagesAtomSelector);
  const { createStatus, categories } = useSelector(pagesProductSelector);
  const { useCases: productPageUseCases } = useSelector(pageUseCaseSelector);

  const setModalPublishPage = useModalPublishPage();
  const publishPageAtom = usePublishPageAtom();
  const getCategoriesOfPageProduct = useGetCategoriesOfPageProduct();
  const getUseCases = useGetPageUseCases();

  const createCategoryOfPageProduct = useCreateCategoryOfPageProduct();

  const [
    { category, createdCategory, image, label, plan, priorityOrder, searchTerms, previewUrl, types, isSticky, usecases, description },
    dispatch,
  ] = useReducer(reducerPageInfo, {
    ...defaultState,
  });

  useEffect(() => {
    if (modalPublish && modalPublish.vedaProductRepresent) {
      dispatch({
        type: 'setPageInfo',
        payload: {
          image: modalPublish.vedaProductRepresent.image ?? { src: '', width: 0, height: 0 },
          plan: modalPublish.vedaProductRepresent.planHandle ?? 'free',
          category: {
            name: modalPublish.vedaProductRepresent?.category?.name ?? '',
            description: modalPublish.vedaProductRepresent?.category?.description ?? '',
          },
          label: modalPublish.vedaProductRepresent?.label ?? modalPublish.label,
          priorityOrder: modalPublish.vedaProductRepresent.priorityOrder ?? 0,
          searchTerms: modalPublish.vedaProductRepresent.searchTerms ?? [],
          previewUrl: modalPublish.vedaProductRepresent.previewUrl ?? '',
          types: modalPublish.vedaProductRepresent?.types ?? [],
          isSticky: modalPublish.vedaProductRepresent?.isSticky ?? false,
          usecases: modalPublish.vedaProductRepresent.usecases?.map(item => item.commandId) ?? [],
          description: modalPublish.vedaProductRepresent?.description ?? '',
        },
      });
    } else if (modalPublish) {
      dispatch({
        type: 'setPageInfo',
        payload: {
          label: modalPublish.label,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalPublish]);

  useEffect(() => {
    if (modalPublish) {
      getCategoriesOfPageProduct.request({});
      getUseCases.request({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalPublish]);

  const handlePublish = () => {
    if (modalPublish) {
      const _cate = categories.find(item => item.name === category.name);
      const _useCases = productPageUseCases.filter(item => usecases.includes(item.commandId));

      publishPageAtom.request({
        pageAtom: modalPublish,
        categoryOfProduct: _cate,
        image,
        label,
        plan,
        priorityOrder,
        searchTerms,
        previewUrl,
        types,
        isSticky,
        onFulfilled() {
          setModalPublishPage(undefined);
          dispatch({
            type: 'setPageInfo',
            payload: defaultState,
          });
        },
        usecases: _useCases,
        description,
      });
    }
  };

  const handleCancel = () => {
    setModalPublishPage(undefined);
    dispatch({
      type: 'setPageInfo',
      payload: defaultState,
    });
  };

  if (!modalPublish) {
    return null;
  }

  return (
    <MyModal
      size="medium"
      headerText="Publish on application"
      okText={i18n.t('general.publish')}
      isVisible
      onCancel={handleCancel}
      onOk={handlePublish}
      isLoading={publishStatus === 'loading'}
    >
      <Field label="Name">
        <DebounceInput
          value={label}
          onValueChange={val => {
            dispatch({
              type: 'setPageInfo',
              payload: {
                label: val,
              },
            });
          }}
          block
        />
      </Field>

      <Field label={i18n.t('builderPage.save_for_builder.page_types')}>
        <SelectAntd
          mode="multiple"
          value={types}
          data={pageTypeData as Option[]}
          onChange={val => {
            dispatch({
              type: 'setPageInfo',
              payload: {
                types: val,
              },
            });
          }}
        />
      </Field>

      <Field label="Preview Url">
        <DebounceInput
          value={previewUrl}
          onValueChange={val => {
            dispatch({
              type: 'setPageInfo',
              payload: {
                previewUrl: val,
              },
            });
          }}
          block
        />
      </Field>

      <Field label="Plan">
        <SelectAntd
          value={plan}
          onChange={val => {
            dispatch({
              type: 'setPageInfo',
              payload: {
                plan: val,
              },
            });
          }}
          data={plans}
        />
      </Field>

      <Field label="Sticky">
        <SwitchBeauty
          checked={isSticky}
          onValueChange={val => {
            dispatch({
              type: 'setPageInfo',
              payload: {
                isSticky: val,
              },
            });
          }}
        />
      </Field>

      <Field label="Priority Order">
        <NumberInput
          block
          borderColor="gray3"
          radius={6}
          max={Infinity}
          value={priorityOrder}
          onValueChange={val => {
            dispatch({
              type: 'setPageInfo',
              payload: {
                priorityOrder: val,
              },
            });
          }}
        />
      </Field>

      <Field label="Search Terms (Nhập tag và ấn nút Enter để insert)">
        <InputTags
          values={searchTerms?.join(',')}
          onChange={values => {
            dispatch({
              type: 'setPageInfo',
              payload: {
                searchTerms: values.split(','),
              },
            });
          }}
        />
      </Field>

      <Field label="Category">
        <SelectAntd
          data={categories.map(item => ({ label: item.description, value: item.name }))}
          value={category.name}
          onChange={(val, opt: any) => {
            dispatch({
              type: 'setPageInfo',
              payload: {
                category: {
                  name: val,
                  description: opt.children,
                },
              },
            });
          }}
          dropdownRender={menu => {
            return (
              <>
                {menu}
                <Divider />
                <View css={{ padding: '8px 0 4px 0', display: 'flex', justifyContent: 'space-between' }}>
                  <View columns={[10, 10, 10]}>
                    <DebounceInput
                      value={createdCategory.name}
                      block
                      placeholder={i18n.t('builderPage.save_for_builder.please_enter_item')}
                      sizeInput="small"
                      css={{ width: '100%', height: '38px' }}
                      onValueChange={val => {
                        dispatch({
                          type: 'setPageInfo',
                          payload: {
                            createdCategory: {
                              description: val,
                              name: val,
                            },
                          },
                        });
                      }}
                    />
                  </View>

                  <View columns={[2, 2, 2]}>
                    <Button
                      radius={4}
                      size="extra-small"
                      block
                      loading={createStatus === 'loading'}
                      onClick={() => {
                        if (createdCategory.name) {
                          createCategoryOfPageProduct.request({
                            name: createdCategory.name,
                            description: createdCategory.description,
                          });

                          dispatch({
                            type: 'setPageInfo',
                            payload: {
                              createdCategory: {
                                description: '',
                                name: '',
                              },
                            },
                          });
                        }
                      }}
                    >
                      {i18n.t('builderPage.save_for_builder.add_category')}
                    </Button>
                  </View>
                </View>
              </>
            );
          }}
        />
      </Field>

      <Field label={'Use Case'}>
        <SelectAntd
          mode="multiple"
          data={productPageUseCases.map(item => ({ value: item.commandId, label: item.name }))}
          value={usecases}
          defaultValue={usecases}
          onChange={val => {
            dispatch({
              type: 'setPageInfo',
              payload: {
                usecases: val,
              },
            });
          }}
        />
      </Field>

      <Field label="Description">
        <TextEditorDebounce
          value={description}
          onChange={val => {
            dispatch({
              type: 'setPageInfo',
              payload: {
                description: val,
              },
            });
          }}
        />
      </Field>

      <Field label={i18n.t('general.image')} width={400}>
        <ChooseImage
          mode="popup"
          value={image}
          onChange={val => {
            dispatch({
              type: 'setPageInfo',
              payload: {
                image: val,
              },
            });
          }}
        />
      </Field>
    </MyModal>
  );
};
