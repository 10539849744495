import { AxiosResponse } from 'axios';
import { SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client } from 'types/Sections';
import { ToRequiredKeys } from 'utils';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrlForAccessAtom } from '../const';

interface GetMegamenusOfAtom {
  commandIds: string[];
  cacheVersion: number | null;
}

interface ResponseSuccess {
  info: Array<ToRequiredKeys<SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client, 'commandId'>>;
  message: string;
}

export const getMegamenusOfAtom = async ({ commandIds, cacheVersion }: GetMegamenusOfAtom) => {
  if (commandIds.length) {
    const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
      url: `${baseUrlForAccessAtom}/search`,
      params: {
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
        commandIds: commandIds.join(','),
      },
    });
    return response.data.info;
  }
  return [];
};
