import { Button, MyModal } from '@wiloke/ui';
import { message } from 'antd';
import ChooseImage from 'components/ChooseImage';
import Field from 'components/Field';
import { NumberInput } from 'components/NumberInput';
import SelectAntd from 'components/SelectAntd';
import { InputTags } from 'components/SelectTags';
import TextInput from 'components/TextInput';
import { pageTypeData } from 'containers/BuilderPage/components/ModalSaveForBuilder/utils/pageTypeData';
import {
  useCreateProductCategory,
  useGetTemplateCategories,
  usePublishAdminSectionToProduct,
  useSetSettingsAdminSection,
} from 'containers/ChooseTemplate/store/actions';
import { useCreateEnvatoCategory, useGetEnvatoCategories } from 'containers/ChooseTemplate/store/actions/sections/user.actionEnvato';
import { envatoSelector } from 'containers/ChooseTemplate/store/reducers/sections/user.sliceEnvato';
import withDebounce from 'hocs/withDebounce';
import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { sectionsSelector } from 'store/selectors';
import { i18n } from 'translation';
import { PreviewImage } from 'types/Page';
import { ProductSection } from 'types/Sections';
import { PLAN_HANDLE } from 'utils/constants/PlanEnum';
import { Divider, View } from 'wiloke-react-core';

const DebounceInput = withDebounce(TextInput, 'value', 'onValueChange');

const defaultPageType = [
  'page',
  'article',
  'blog',
  'collection',
  'product',
  'home',
  'cart',
  'pageNotFound',
  'password',
  'search',
  'login',
  'resetPassword',
  'activateAccount',
  'register',
  'account',
  'order',
  'addresses',
  // 'giftCard',
  'collections',
];

const plans: Array<{ label: string; value: PLAN_HANDLE }> = [
  {
    value: 'free',
    label: 'Free',
  },
  {
    value: 'business',
    label: 'Business',
  },
  {
    value: 'advanced',
    label: 'Advanced',
  },
  {
    value: 'ultimate',
    label: 'Ultimate',
  },
];

interface Actions {
  type: 'setInfoPublishSection';
  payload: Partial<State>;
}

interface State {
  plan: PLAN_HANDLE | undefined;
  category: { name: string; description: string };
  createdCategory: { name: string; description: string };
  createdEnvato: { name: string; description: string };
  envatoIds: string[];
  pageTypes: any[];
  image: PreviewImage | undefined;
  sectionName: string;
  priorityOrder?: number;
  searchTerms?: string[];
}

const defaultState: State = {
  plan: undefined,
  category: { name: '', description: '' },
  createdCategory: { name: '', description: '' },
  createdEnvato: { name: '', description: '' },
  envatoIds: [],
  image: undefined,
  pageTypes: defaultPageType,
  sectionName: '',
  priorityOrder: 0,
  searchTerms: [],
};

const publishReducer = (state: State, action: Actions) => {
  switch (action.type) {
    case 'setInfoPublishSection': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export const ModalPublish = () => {
  const { visible, data, sectionId, publishStatus } = useSelector(sectionsSelector.adminSections);
  const { createStatus } = useSelector(sectionsSelector.categories);
  const userCategories = useSelector(sectionsSelector.childrenCategories);

  const currentSection = data.find(item => item.commandId === sectionId);
  const { categories: envatoCategories, createStatus: createEnvatoStatus } = useSelector(envatoSelector);

  const setSettingsAdminSection = useSetSettingsAdminSection();
  const publishAdminSectionReq = usePublishAdminSectionToProduct();
  const createCategoryProduct = useCreateProductCategory();
  const createEnvatoCategory = useCreateEnvatoCategory();
  const getEnvatoCategories = useGetEnvatoCategories();
  const getCategory = useGetTemplateCategories();

  const [
    { category, createdCategory, createdEnvato, envatoIds, image, pageTypes, plan, sectionName, priorityOrder, searchTerms },
    dispatch,
  ] = useReducer(publishReducer, {
    ...defaultState,
  });

  useEffect(() => {
    if (visible) {
      getEnvatoCategories.request(undefined);
      getCategory.request({ search: undefined, showParentOnly: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (!!currentSection && currentSection.vedaProductRepresent) {
      if (currentSection.vedaProductRepresent.image) {
        dispatch({
          type: 'setInfoPublishSection',
          payload: {
            image: currentSection.vedaProductRepresent.image,
          },
        });
      }
      if (currentSection.vedaProductRepresent.pageTypes) {
        dispatch({
          type: 'setInfoPublishSection',
          payload: {
            pageTypes: currentSection.vedaProductRepresent.pageTypes,
          },
        });
      }

      if (currentSection.vedaProductRepresent.planHandle) {
        dispatch({
          type: 'setInfoPublishSection',
          payload: {
            plan: currentSection.vedaProductRepresent.planHandle,
          },
        });
      }

      if (currentSection.vedaProductRepresent.category) {
        dispatch({
          type: 'setInfoPublishSection',
          payload: {
            category: {
              name: currentSection.vedaProductRepresent.category.name,
              description: currentSection.vedaProductRepresent.category.description,
            },
          },
        });
      }

      if (currentSection.vedaProductRepresent.label) {
        dispatch({
          type: 'setInfoPublishSection',
          payload: {
            sectionName: currentSection.vedaProductRepresent.label,
          },
        });
      }

      if (currentSection.vedaProductRepresent.priorityOrder) {
        dispatch({
          type: 'setInfoPublishSection',
          payload: {
            priorityOrder: currentSection.vedaProductRepresent.priorityOrder,
          },
        });
      }

      if (currentSection.vedaProductRepresent.searchTerms) {
        dispatch({
          type: 'setInfoPublishSection',
          payload: {
            searchTerms: currentSection.vedaProductRepresent.searchTerms,
          },
        });
      }
    }
  }, [currentSection]);

  const handleCancel = () => {
    setSettingsAdminSection({
      visible: false,
      sectionId: '',
    });
    dispatch({
      type: 'setInfoPublishSection',
      payload: defaultState,
    });
  };

  const handlePublish = () => {
    if (currentSection && !!category.name) {
      const _cate = userCategories.find(item => item.name === category.name);
      const _envatoCate = envatoCategories.filter(item => envatoIds.includes(item.commandId));

      const transformProductSection: ProductSection = {
        ...currentSection,
        planHandle: plan,
        category: {
          commandId: _cate?.commandId ?? '',
          description: _cate?.description ?? '',
          name: _cate?.name ?? '',
        },
        downloadedCount: 0,
        pageTypes: pageTypes,
        parentCommandId: currentSection.commandId,
        tags: null,
        enable: currentSection.enable || true,
        envatoCategories: _envatoCate,
        image,
        label: sectionName,
        searchTerms,
        priorityOrder,
      };

      publishAdminSectionReq.request({ section: transformProductSection });
    } else {
      message.warning({
        content: i18n.t('publish_shopify.error_unknown.message'),
      });
    }
  };

  return (
    <MyModal
      size="medium"
      headerText={`${i18n.t('general.publish', { text: i18n.t('general.on', { text: i18n.t('general.application') }) })}`}
      okText={i18n.t('general.publish')}
      isVisible={visible}
      onCancel={handleCancel}
      onOk={handlePublish}
      isLoading={publishStatus === 'loading'}
    >
      <Field label={`${i18n.t('general.name')}`}>
        <DebounceInput
          block
          value={sectionName}
          onValueChange={val => {
            dispatch({
              type: 'setInfoPublishSection',
              payload: {
                sectionName: val,
              },
            });
          }}
        />
      </Field>

      <Field label={`${i18n.t('general.plan')}`}>
        <SelectAntd
          value={plan}
          onChange={val => {
            dispatch({
              type: 'setInfoPublishSection',
              payload: {
                plan: val as PLAN_HANDLE,
              },
            });
          }}
          data={plans}
        />
      </Field>

      <Field label="Priority Order">
        <NumberInput
          block
          borderColor="gray3"
          radius={6}
          max={Infinity}
          value={priorityOrder}
          onValueChange={val => {
            dispatch({
              type: 'setInfoPublishSection',
              payload: {
                priorityOrder: val,
              },
            });
          }}
        />
      </Field>

      <Field label="Search Terms (Nhập tag và ấn nút Enter để insert)">
        <InputTags
          values={searchTerms?.join(',')}
          onChange={values => {
            dispatch({
              type: 'setInfoPublishSection',
              payload: {
                searchTerms: values.split(','),
              },
            });
          }}
        />
      </Field>

      <Field label={`${i18n.t('general.section', { text: i18n.t('builderPage.save_for_builder.page_types') })}`}>
        <SelectAntd
          mode="multiple"
          value={pageTypes}
          data={pageTypeData as any[]}
          onChange={val => {
            dispatch({
              type: 'setInfoPublishSection',
              payload: {
                pageTypes: val,
              },
            });
          }}
        />
      </Field>

      <Field label={`${i18n.t('general.category')}`}>
        <SelectAntd
          data={userCategories.map(item => ({ label: item.description, value: item.name }))}
          value={category.name}
          filterOption={(input, option) => {
            return ((option?.children as unknown) as string).toLowerCase().includes(input.toLowerCase());
          }}
          showSearch
          onChange={(val, opt: any) => {
            dispatch({
              type: 'setInfoPublishSection',
              payload: {
                category: {
                  name: val,
                  description: opt.children,
                },
              },
            });
          }}
          dropdownRender={menu => {
            return (
              <>
                {menu}
                <Divider />
                <View css={{ padding: '8px 0 4px 0', display: 'flex', justifyContent: 'space-between' }}>
                  <View columns={[10, 10, 10]}>
                    <DebounceInput
                      value={createdCategory.name}
                      block
                      placeholder={i18n.t('builderPage.save_for_builder.please_enter_item')}
                      sizeInput="small"
                      css={{ width: '100%', height: '38px' }}
                      onValueChange={val => {
                        dispatch({
                          type: 'setInfoPublishSection',
                          payload: {
                            createdCategory: {
                              description: val,
                              name: val,
                            },
                          },
                        });
                      }}
                    />
                  </View>

                  <View columns={[2, 2, 2]}>
                    <Button
                      radius={4}
                      size="extra-small"
                      block
                      loading={createStatus === 'loading'}
                      onClick={() => {
                        if (createdCategory.name) {
                          createCategoryProduct.request({
                            name: createdCategory.name,
                            description: createdCategory.description,
                          });
                          dispatch({
                            type: 'setInfoPublishSection',
                            payload: {
                              createdCategory: {
                                description: '',
                                name: '',
                              },
                            },
                          });
                        }
                      }}
                    >
                      {i18n.t('builderPage.save_for_builder.add_category')}
                    </Button>
                  </View>
                </View>
              </>
            );
          }}
        />
      </Field>

      <Field label={`Envato ${i18n.t('general.category')}`}>
        <SelectAntd
          mode="multiple"
          data={envatoCategories.map(item => ({ value: item.commandId, label: item.name }))}
          onChange={val => {
            dispatch({
              type: 'setInfoPublishSection',
              payload: {
                envatoIds: val,
              },
            });
          }}
          dropdownRender={menu => {
            return (
              <>
                {menu}
                <Divider />
                <View css={{ padding: '8px 0 4px 0', display: 'flex', justifyContent: 'space-between' }}>
                  <View columns={[10, 10, 10]}>
                    <DebounceInput
                      value={createdEnvato.name}
                      block
                      placeholder={i18n.t('builderPage.save_for_builder.please_enter_item')}
                      sizeInput="small"
                      css={{ width: '100%', height: '38px' }}
                      onValueChange={val => {
                        dispatch({
                          type: 'setInfoPublishSection',
                          payload: {
                            createdEnvato: {
                              name: val,
                              description: val,
                            },
                          },
                        });
                      }}
                    />
                  </View>

                  <View columns={[2, 2, 2]}>
                    <Button
                      radius={4}
                      size="extra-small"
                      block
                      loading={createEnvatoStatus === 'loading'}
                      onClick={() => {
                        if (createdEnvato.name) {
                          createEnvatoCategory.request({
                            description: createdEnvato.description,
                            name: createdEnvato.name,
                            envatoItemId: '1',
                          });
                        }
                      }}
                    >
                      {i18n.t('builderPage.save_for_builder.add_category')}
                    </Button>
                  </View>
                </View>
              </>
            );
          }}
        />
      </Field>

      <Field width={350} label={i18n.t('general.image')}>
        <ChooseImage
          mode="popup"
          value={image}
          onChange={imageVal => {
            dispatch({
              type: 'setInfoPublishSection',
              payload: {
                image: imageVal,
              },
            });
          }}
        />
      </Field>
    </MyModal>
  );
};
