import { Button, MyModal, VedaLoadingItem } from '@wiloke/ui';
import { useSelector } from 'react-redux';
import { Space, View } from 'wiloke-react-core';
import { sliceUnauthorizedSelector, useSetVisibleModalUnauthorized } from './sliceUnauthorized';
import oops from 'assets/oops.svg';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { ReactNode } from 'react';

const RefreshingToken = () => {
  return (
    <View
      key="sss"
      css={{
        padding: '80px 10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        columnGap: '5px',
      }}
    >
      <VedaLoadingItem />

      <View css={{ fontSize: '16px' }} fontFamily="secondary">
        Getting data...
      </View>
    </View>
  );
};

const RefreshFailed = () => {
  return (
    <>
      <img src={oops} alt="" />
      <Space size={10} />
      <View css={{ fontSize: '16px' }} fontFamily="secondary">
        Session has expired, please press the "Re-login" button. After that please refresh the page.
      </View>
      <Space size={10} />
      <View css={{ display: 'flex', columnGap: '10px', justifyContent: 'center', textAlign: 'center' }}>
        <Button
          size="small"
          backgroundColor="primary"
          radius={6}
          onClick={() => {
            window.open('https://vedabuilder.com/install', '_blank');
          }}
        >
          Re-login
        </Button>
      </View>
    </>
  );
};

// todo: i18n
export const ModalUnauthorized = () => {
  const { visible, isRefreshingToken } = useSelector(sliceUnauthorizedSelector);
  const { role } = getUserInfo();
  const setVisibleModalUnauthorized = useSetVisibleModalUnauthorized();
  const isAdminOrDev = role === 'admin' || role === 'dev';

  const renderModalContent: Record<string, ReactNode | JSX.Element> = {
    loading: RefreshingToken(),
    failure: RefreshFailed(),
  };

  if (isRefreshingToken === 'success') {
    return null;
  }

  return (
    <MyModal
      bodyCss={{ height: isRefreshingToken === 'loading' ? '300px' : '580px' }}
      disabledCloseButton={isAdminOrDev ? false : true}
      cancelText=""
      okText=""
      isVisible={visible}
      headerText="Unauthorized"
      depsHeightRecalculation={isRefreshingToken}
      scrollDisabled
      onCancel={
        isAdminOrDev
          ? () => {
              setVisibleModalUnauthorized(false);
            }
          : undefined
      }
    >
      {renderModalContent[isRefreshingToken]}
    </MyModal>
  );
};
