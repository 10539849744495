import { PageType } from 'types/Page';
import { renewVersion } from 'utils/CacheControl/VedaClientCacheControl';
import { isThemeBuilder } from 'utils/validateBuilderMode';
import { pageApis } from '../apis';

interface UpdatePageClient {
  commandId: string;
  enable: boolean;
  type: PageType;
}

export const updateStatusPageClientService = async ({ commandId, enable, type }: UpdatePageClient) => {
  const isTheme = isThemeBuilder();

  const response = await pageApis.vedaApplication.userApi.page.updateClient({
    page: { commandId, enable, type },
    group: isTheme ? 'THEME' : 'GENERAL',
  });
  renewVersion();
  return response;
};
