import { Task } from 'redux-saga';
import { call, cancel, fork, take } from 'redux-saga/effects';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { getActionType } from 'wiloke-react-core/utils';
import { actionGetCurrentTheme } from '../../actions';
import { handleGetThemeAtom } from './ThemeAtom/handleGetThemeAtom';
import { handleGetThemeClient } from './ThemeClient/handleGetThemeClient';
import { handleGetThemeDraft } from './ThemeDraft/handleGetThemeDraft';

function* handleGetTheme(params: ReturnType<typeof actionGetCurrentTheme.request>) {
  const { variant, onDisconnectSocketEarly } = params.payload;
  const { role } = getUserInfo();

  if (variant === 'Atom') {
    onDisconnectSocketEarly();
    yield call(handleGetThemeAtom, params);
    return;
  }
  if (variant === 'Draft') {
    onDisconnectSocketEarly();
    yield call(handleGetThemeDraft, params);
    return;
  }
  if (variant === 'Client') {
    yield call(handleGetThemeClient, params);
    return;
  }
  if (role === 'admin') {
    onDisconnectSocketEarly();
    yield call(handleGetThemeAtom, params);
    return;
  }
  if (role === 'dev') {
    onDisconnectSocketEarly();
    yield call(handleGetThemeDraft, params);
    return;
  }
  if (role === 'user') {
    yield call(handleGetThemeClient, params);
    return;
  }
}

let task: Task | undefined;

export function* watchGetCurrentTheme() {
  while (true) {
    const requestAction: ReturnType<typeof actionGetCurrentTheme.request> = yield take(getActionType(actionGetCurrentTheme.request));
    if (!!task) {
      yield cancel(task);
    }
    task = yield fork(handleGetTheme, requestAction);
  }
}

export function* watchCancelGetCurrentTheme() {
  while (true) {
    const cancelAction: ReturnType<typeof actionGetCurrentTheme.cancel> = yield take(getActionType(actionGetCurrentTheme.cancel));
    if (cancelAction.type === '@ThemeBuilder/actionGetCurrentTheme/cancel' && !!task) {
      yield cancel(task);
    }
  }
}
