import { ShopifyFontFamily } from 'services/ShopifyServices/Fonts';
import { createAsyncAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';

export const getShopifyFonts = createAsyncAction([
  '@Shopify/getShopifyFonts/request',
  '@Shopify/getShopifyFonts/success',
  '@Shopify/getShopifyFonts/failure',
])<undefined, { data: ShopifyFontFamily[] }, undefined>();

export const useGetShopifyFonts = createDispatchAsyncAction(getShopifyFonts);
