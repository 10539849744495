import { addonApiController } from 'services/AddonService';
import { userApis } from 'services/UserService/apis';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const deleteCategoryOfAtom = async (commandId: string) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    const response = await addonApiController.atom.adminApi.category.deleteCategoryOfAtom({ commandId });
    await userApis.user.adminApi.updateVedaCacheVersion();
    return response;
  }
  throw new RoleException();
};
