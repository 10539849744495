import { handleWaitForSocketOfSyncShopifyFulfill } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { call, retry, SagaReturnType } from 'redux-saga/effects';
import { handleSaveInBuilderPage, shopifyConnectionService } from 'services/ShopifyConnection';

export interface SyncTranslation {
  syncTranslationParams: ReturnType<typeof handleSaveInBuilderPage>['syncTranslationsParams'][number];
}

export interface SyncTranslationResult {
  statusSyncTranslation: SyncFulfillStatus;
  isNeedIgnoreReportError: boolean;
}

export function* syncTranslation({ syncTranslationParams }: SyncTranslation) {
  try {
    yield retry(3, 1000, shopifyConnectionService.writeTranslation, { ...syncTranslationParams, isPreview: false });
    const socketStatusSyncTranslation: SagaReturnType<typeof handleWaitForSocketOfSyncShopifyFulfill> = yield call(
      handleWaitForSocketOfSyncShopifyFulfill,
      'Sync translation',
    );
    return {
      statusSyncTranslation: socketStatusSyncTranslation.statusSync,
      isNeedIgnoreReportError: socketStatusSyncTranslation.isNeedIgnoreReportError,
    } as SyncTranslationResult;
  } catch (error) {
    return {
      statusSyncTranslation: 'failure',
      isNeedIgnoreReportError: false,
    } as SyncTranslationResult;
  }
}
