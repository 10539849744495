import { mergeDeepLeft } from 'ramda';
import { SelectField } from '../../../@types/Shopify/InputSettings/SelectField';
import { Locales } from '../../../@types/Veda/ShopifyLocales';
import { replaceExactVariableNameInLiquidCode } from '../../../@utils/replaceExactVariableNameInLiquidCode';
import { vedaLabelToShopifyFieldLabel } from './utils/vedaLabelToShopifyFieldLabel';
import { vedaSummaryToShopifyFieldInfo } from './utils/vedaSummaryToShopifyFieldInfo';
import { ISCHEMA_SettingSelect } from './@types/ISCHEMA_SettingSelect';
import { ISETTING_SettingSelect } from './@types/ISETTING_SettingSelect';
import { toShopifyFieldId } from './utils/toShopifyFieldId';
import { ILIQUID_SettingSelect } from './@types/ILIQUID_SettingSelect';
import { TextField } from '../../../@types/Shopify/InputSettings/TextField';
import { ignoreToReduceTotalLinesOfCode } from '../../../@utils/ignoreToReduceTotalLinesOfCode';
import { getSignalReduceTotalLinesOfCode } from './utils/getSignalReduceTotalLinesOfCode';

interface RTSettingSelect {
  shopifyField: SelectField | TextField;
  locales: Locales;
}

export const SCHEMA_SettingSelect = ({ field, parentField, ...params }: ISCHEMA_SettingSelect): RTSettingSelect => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField });
  const { newLabel, locales: labelLocales } = vedaLabelToShopifyFieldLabel({ ...params, field, parentField, isNeedReduceTotalLinesOfCode });
  const { newInfo, locales: infoLocales } = vedaSummaryToShopifyFieldInfo({ ...params, field, parentField, isNeedReduceTotalLinesOfCode });
  const value = typeof field.children === 'string' && !!field.children ? field.children : undefined;
  if (field.enabledMultiple) {
    return {
      shopifyField: {
        label: newLabel,
        info: newInfo,
        id: isNeedReduceTotalLinesOfCode
          ? getSignalReduceTotalLinesOfCode(field)
          : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }),
        type: 'text',
        default: value,
        placeholder: undefined,
      },
      locales: mergeDeepLeft(labelLocales, infoLocales),
    };
  } else {
    return {
      shopifyField: {
        label: newLabel,
        info: newInfo,
        id: isNeedReduceTotalLinesOfCode
          ? getSignalReduceTotalLinesOfCode(field)
          : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }),
        type: 'select',
        default: value,
        group: undefined,
        options: field.options.map(option => ({ label: option.label, value: option.value.toString() })),
      },
      locales: mergeDeepLeft(labelLocales, infoLocales),
    };
  }
};

export const SETTING_SettingSelect = ({ field, parentField, ...params }: ISETTING_SettingSelect) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField });
  // check kĩ phòng lỗi
  const value = typeof field.children === 'string' && !!field.children ? field.children : undefined;
  if (value !== undefined) {
    const fieldName = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode });
    return { [fieldName]: value };
  }
  return {};
};

export const LIQUID_SettingSelect = ({ field, parentField, liquid, loopVariable, ...params }: ILIQUID_SettingSelect) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField });
  /// Nếu field thuộc array
  // - loopVariable nếu là array ->  Array được dùng trong forloop -> Thế `${loopVariable}.${field.name}.${key}` = `${loopVariable}.${toShopifyFieldId({ field, parentField }, key)}`
  //   Example: --> for item in content --> item.icon_design.id ==> KQ: item.content__icon_design.id
  if (loopVariable) {
    const target = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : `${loopVariable}.${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode })}`;
    const source = `${loopVariable}.${field.name}`;
    return replaceExactVariableNameInLiquidCode({ liquid, source, target });
  }
  // Nếu field thuộc object
  // - parentField nếu là object -> Object được flat thành các "shopify input settings" -> Thế `${parentField.name}.${field.name}.${key}` = toShopifyFieldId
  //   Example: --> heading.icon_design.id ==> KQ: heading__icon_design.id
  else if (parentField) {
    const target = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode })}"]`;
    const source = `${parentField.name}.${field.name}`;
    return replaceExactVariableNameInLiquidCode({ liquid, source, target });
  } else {
    const target = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode })}"]`;
    const source = field.name;
    return replaceExactVariableNameInLiquidCode({ liquid, source, target });
  }
};
