import DragIcon from 'components/DragIcon/DragIcon';
import { FC, ReactNode } from 'react';
import { Text, View, ViewProps } from 'wiloke-react-core';
import * as styles from './styles';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

export interface DragItemProps {
  variant?: 'variant1' | 'variant2';
  label: string;
  description?: string;
  active?: boolean;
  Icon?: () => ReactNode;
  RightItem?: ReactNode;
  onEdit?: () => void;
  css?: ViewProps['css'];
  innerCss?: ViewProps['css'];
  dragIconDisabled?: boolean;
  longPressDragHandleProps?: DraggableProvidedDragHandleProps;
}

const DragItem: FC<DragItemProps> = ({
  label,
  description,
  variant = 'variant1',
  active = false,
  onEdit,
  RightItem,
  Icon,
  css,
  innerCss,
  dragIconDisabled = false,
  longPressDragHandleProps = {},
}) => {
  return (
    <View css={[styles.container(variant, active), css]}>
      <View css={[styles.inner(variant, active), innerCss]}>
        <View css={styles.left}>
          {!dragIconDisabled && (
            <View css={styles.icon}>
              <View {...longPressDragHandleProps}>{Icon?.() ? Icon?.() : <DragIcon css={{ padding: '8px 15px', height: '40px !important' }} />}</View>
            </View>
          )}
          <View css={styles.headingContent}>
            <Text tagName="h6" numberOfLines={1}>
              {label}
            </Text>
            {!!description && (
              <Text
                css={{
                  '> *': { display: 'inline', fontSize: 'inherit' },
                  '> img': { maxWidth: '100%', height: 'auto', width: '20px' },
                }}
                size={10}
                numberOfLines={1}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </View>
        </View>
        <View css={styles.edit} onClick={onEdit} />
        <View css={styles.actions}>{RightItem}</View>
      </View>
    </View>
  );
};

export default DragItem;
