import { Tooltip } from '@wiloke/ui';
import { Device } from 'containers/BuilderPage/store/responsive/slice';
import { FC } from 'react';
import { i18n } from 'translation';
import { FontAwesome, View } from 'wiloke-react-core';
import * as styles from './styles';

interface ResponsivePageProps {
  responsive: Device;
  setResponsive?: (device: Device) => void;
}

export const ResponsivePage: FC<ResponsivePageProps> = ({ responsive, setResponsive }) => {
  return (
    <View css={styles.iconWrap}>
      <Tooltip
        text={i18n.t('general.desktop')}
        css={styles.itemFull}
        placement="bottom"
        onClick={async () => {
          setResponsive?.('desktop');
        }}
      >
        <FontAwesome type="far" name="desktop" size={16} color={responsive === 'desktop' ? 'primary' : 'gray7'} css={styles.icon} />
      </Tooltip>
      <View css={styles.divider} />
      <Tooltip
        text={`${i18n.t('general.tablet')} (landscape)`}
        css={styles.itemFull}
        placement="bottom"
        onClick={() => {
          setResponsive?.('tablet-h');
        }}
      >
        <FontAwesome
          type="far"
          name="tablet"
          size={16}
          color={responsive === 'tablet-h' ? 'primary' : 'gray7'}
          css={[styles.icon, { transform: 'rotate(-90deg)' }]}
        />
      </Tooltip>
      <View css={styles.divider} />
      <Tooltip
        text={`${i18n.t('general.tablet')} (portrait)`}
        css={styles.itemFull}
        placement="bottom"
        onClick={() => {
          setResponsive?.('tablet');
        }}
      >
        <FontAwesome type="far" name="tablet" size={16} color={responsive === 'tablet' ? 'primary' : 'gray7'} css={styles.icon} />
      </Tooltip>
      <View css={styles.divider} />
      <Tooltip
        text={i18n.t('general.mobile')}
        css={styles.itemFull}
        placement="bottom"
        onClick={() => {
          setResponsive?.('mobile');
        }}
      >
        <FontAwesome type="far" name="mobile" size={16} color={responsive === 'mobile' ? 'primary' : 'gray7'} css={styles.icon} />
      </Tooltip>
    </View>
  );
};
