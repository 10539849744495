import { strToRegexpPattern } from '@wiloke/functions';

/**
 * Function đảm replace chính xác string và bỏ qua các trường hợp string đó là substring của 1 string khác
 * @example
 Input: Mỗi dòng là 1 tham số đầu vào của function
 "{{ collections[collection_1] }} {{ collections[best_collection_1] }} {{ collections[best_collection_1_abc] }}"
 "collection_1"
 "target_value"

 Output:
 "{{ collections[target_value] }} {{ collections[best_collection_1] }} {{ collections[best_collection_1_abc] }}"
 */
export interface ReplaceExactVariableNameInLiquidCode {
  liquid: string;
  source: string;
  target: string;
  ignoreEndWithDot?: boolean;
  additionIsEndValid?: (value: string) => boolean;
}
export const replaceExactVariableNameInLiquidCode = ({
  liquid,
  source,
  target,
  ignoreEndWithDot = false,
  additionIsEndValid = () => false,
}: ReplaceExactVariableNameInLiquidCode) => {
  return liquid.replace(new RegExp(`(.|\\n)${strToRegexpPattern(source)}(.|\\n)`, 'g'), value => {
    const isStartValid =
      value.startsWith(' ') ||
      value.startsWith('\n') ||
      // Trường hợp collections[product_settings.collection]
      value.startsWith('[');
    const isEndValid = ignoreEndWithDot
      ? value.endsWith(' ') ||
        value.endsWith('\n') ||
        // Trường hợp collections[product_settings.collection]
        value.endsWith(']') ||
        value.endsWith('.') ||
        additionIsEndValid?.(value)
      : value.endsWith(' ') ||
        value.endsWith('\n') ||
        // Trường hợp collections[product_settings.collection]
        value.endsWith(']') ||
        additionIsEndValid?.(value);

    if (isStartValid && isEndValid) {
      return value.replace(source, target);
    } else {
      return value;
    }
  });
};
