import { retry, put, SagaReturnType, takeLeading } from 'redux-saga/effects';
import { shopifyConnectionService } from 'services/ShopifyConnection';
import { getMetafields } from 'store/actions/shopify/actionMetafields';
import { filterUsableMetafields } from 'utils/functions/filterUsableMetafields';
import { getActionType } from 'wiloke-react-core/utils';
import { graphqlForGetMetafields } from './graphqls';

function* handleGetMetafields({ payload }: ReturnType<typeof getMetafields.request>) {
  const { ownerType } = payload;
  try {
    const response: SagaReturnType<typeof shopifyConnectionService.graphqlForMetafields> = yield retry(
      3,
      1000,
      shopifyConnectionService.graphqlForMetafields,
      graphqlForGetMetafields(ownerType),
    );
    yield put(
      getMetafields.success({
        ownerType,
        hasNextPage: response.hasNextPage,
        data: response.metafields.filter(item => filterUsableMetafields(item.type.name)),
      }),
    );
  } catch (error) {
    yield put(getMetafields.failure({ ownerType }));
  }
}

export function* watchGetMetafields() {
  yield takeLeading(getActionType(getMetafields.request), handleGetMetafields);
}
