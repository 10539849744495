import { FC, useState } from 'react';
import { Styles, Theme, View } from 'wiloke-react-core';
import { ButtonPlus } from './ButtonPlus';
import { i18n } from 'translation';

const styles = {
  container: ({ colors }: Theme): Styles => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
    padding: '10px',
    border: `2px dashed ${colors.primary}`,
    backgroundColor: colors.gray2,
    color: colors.primary,
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      opacity: 0.8,
    },
  }),
};

export const AddonsPlaceholderLarge: FC = props => {
  const [loading, setLoading] = useState(false);

  return (
    <View {...props} css={styles.container} onClick={() => setLoading(true)}>
      <ButtonPlus isLoading={loading} />
      <View>{i18n.t(loading ? 'general.loading' : 'general.add_addons')}</View>
    </View>
  );
};
