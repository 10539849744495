import { i18n } from 'translation';
import { SectionSettings } from 'types/Schema';
import { ProductSection } from 'types/Sections';
import { Consts } from 'utils/constants/constants';
import { getAddonHtml } from 'utils/functions/getAddonHtml';
import { LiquidSyntaxToTwigError } from 'utils/LiquidSyntaxToTwig/Error';
import { handleGetScopeOfAddon } from 'utils/LiquidSyntaxToTwig/handleGetScopeOfAddon';

export const removeCssAndJs = (id: string) => {
  const styleEl = document.getElementById(`css_${id}`);
  const scriptEl = document.getElementById(`js_${id}`);
  const styleInlineEl = document.getElementById(`inline_${id}`);
  styleEl?.remove();
  scriptEl?.remove();
  styleInlineEl?.remove();
};

// NOTE: @tuong -> indexBOCs và veda-index-boc liên quan đến chức năng thêm addon (src/store/reducers/reducerPages.ts, src/store/reducers/utils/getBOCsBetweenSomething.ts, src/containers/IframePage/components/AddonsPosition/AddonsPosition.tsx)
// NOTE: veda-component-index liên quan đến src/containers/IframePage/components/AddonsPosition/useAddonsPositions.ts và LiquidComponent.tsx
// NOTE: veda-length-of-loop liên quan đến src/containers/IframePage/components/AddonsPosition/useAddonsPositions.ts và LiquidComponent.tsx
// NOTE: veda-open-tag liên quan đến chức năng chèn code addon vào code liquid đúng vị trí khi chọn vị trí addon
export const addAttrDataTag = (twig: string) => {
  const checkPoint = '\n==============addAttrDataTag==============';
  const SPACE = 'DẤU_CÁCH';
  const BREAK_LINE = 'DẤU_XUỐNG_DÒNG';
  const indexBOCs: Record<string, number> = {};
  return (
    twig
      // @tuong ->  Commit "fix loi attrbute veda k dc add vao nhung the ma co code html xuong dong" ảnh hưởng đến chức năng "chọn vị trí addon - reducerPages.tsx + getBocsBetweenSomething.ts" do xóa mất dấu xuống dòng và dấu cách ==> Chuyển regex theo cách này để giữ nguyên hiện trạng của code liquid trong editor
      // @tuong -> Việc xóa "\r" ảnh hưởng đến cả "reducerPages" -> trong "reducerPages" cũng sẽ phải xóa đi "\r" ("\r" xuất hiện do dấu xuống dòng của window)
      // Regex lấy hết content giữa <> và </>
      .replace(/(<([^>]+)>)/g, htmlTag => {
        // Xóa dấu xuống dòng (coi chỉ attribute mới xuống dòng)
        return htmlTag.replace(/\r?\n/g, BREAK_LINE).replace(/\s/g, SPACE);
      })
      .replace(/>/g, `>${checkPoint}`)
      .replace(/<\w*\s*.*(?=>)/g, tag => {
        // NOTE: @tuong -> Không thêm data vào thẻ <shopify> vì thẻ này chỉ để đánh dấu -> nếu thêm vào nhiều chỗ regex sẽ sai
        // NOTE: @tuong -> Không thêm data vào thẻ <addons-context> vì thẻ này chỉ để đánh dấu -> nếu thêm vào nhiều chỗ regex sẽ sai
        if (
          tag.includes('</') ||
          tag.startsWith(`<${Consts.FakeTags.Shopify}`) ||
          tag.startsWith(`<${Consts.FakeTags.Compiler}`) ||
          tag.startsWith(`<${Consts.FakeTags.AddonsContext.tagName}`)
        ) {
          return tag;
        }
        indexBOCs[tag] = (indexBOCs[tag] ?? 0) + 1;
        // Check nếu là thẻ đơn có /> thì ta thêm / ở cuối ( nghĩa là sẽ trước > )
        const lastStr = /\/$/g.test(tag) ? '/' : '';

        // Trả về veda open tag nguyên hiện trạng trong editor
        const vedaOpenTag = tag.replace(new RegExp(BREAK_LINE, 'g'), '\n').replace(new RegExp(SPACE, 'g'), ' ');
        return (
          `${tag.replace(/\/$/, '')} veda-index-boc="${indexBOCs[tag]}" ` +
          `veda-open-tag="${btoa(encodeURIComponent(`${vedaOpenTag}>`))}" ` +
          `{% if forloop.index %} veda-component-index="{{ forloop.index0 }}"{% endif %}` +
          `{% if forloop.length %} veda-length-of-loop="{{ forloop.length }}"{% endif %} ${lastStr}`
        );
      })
      .replace(new RegExp(checkPoint, 'g'), '')
      .replace(new RegExp(BREAK_LINE, 'g'), '\n')
      .replace(new RegExp(SPACE, 'g'), ' ')
  );
};

// NOTE: @tuong -> Cái này phải chạy trước "LiquidSyntaxToTwig" để "forloop" object có thể được convert sang twig
export const addAttrVedaComponentIndex = (twig: string) => {
  // FIXME: Xem xét việc bỏ function này vì "addAttrDataTag" đã thêm loop.index rồi và nếu chỉ thêm ở đây thì thẻ <addons> không được đánh index => Không thể làm chức năng chỉ hiển thị 1 addons trong vòng loop
  return twig.replace(/\scomponent=/g, `{% if forloop.index %} veda-component-index="{{ forloop.index0 }}"{% endif %} component=`);
};

/**
 * Cần thêm trường hợp này để xử lý 1 số node addAttrVedaComponentIndex không được do
 * liquidSyntaxToTwig xử lý làm mất vòng for nên loop.index trong trường hợp addAttrVedaComponentIndex
 * không tồn tại
 * Nhược điểm của cách này là chỉ xử lý được nếu component attr đặt ở node cấp đầu tiên trong vòng for
 * vì vậy trường hợp addAttrVedaComponentIndex vẫn cần giữ lại
 */
export const addAttrVedaComponentIndex2 = (html: string, settings: SectionSettings) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  for (const setting of settings) {
    if (setting.type === 'array') {
      const componentEls = doc.querySelectorAll<HTMLElement>(`[component="${setting.name}"]:not([veda-component-index])`);
      if (componentEls.length > 1) {
        const parentComponentEl = componentEls[0].parentElement;
        if (parentComponentEl) {
          const componentEls = parentComponentEl.querySelectorAll<HTMLElement>(`[component="${setting.name}"]:not([veda-component-index])`);
          componentEls.forEach((componentEl, index) => {
            componentEl.setAttribute('veda-component-index', index.toString());
          });
        }
      }
    }
  }
  return doc.body.innerHTML;
};

const { tagName } = Consts.FakeTags.AddonsContext;
export const addAttrDataAddonsAndAddonContext = (twig: string, isAddons: boolean, sectionAddonIds: ProductSection['addonIds']) => {
  if (!isAddons) {
    // Addon context
    let _twig = twig;
    sectionAddonIds?.forEach(addonId => {
      _twig = _twig.replace(new RegExp(getAddonHtml(addonId), 'g'), (addonHtml_, index) => {
        let addonHtml = addonHtml_;
        const { variables, variant } = handleGetScopeOfAddon({ liquidCompiled: _twig.substring(0, index) });
        const context = `{ ${variables.map(variable => `"${variable}": {{ ${variable} | json | escape }}`).join(',')} }`;
        if (variant === 'Render 1 addon để tối ưu performance') {
          addonHtml = addonHtml.replace(`<${Consts.FakeTags.Addons}`, value => {
            return value.concat(
              ` ${Consts.FakeTags.AttributeStoreSignalOptimizePerformanceByReduceAddons.name}="${Consts.FakeTags.AttributeStoreSignalOptimizePerformanceByReduceAddons.value}" `,
            );
          });
        }
        return `<${tagName}><script type="application/json">${context}</script></${tagName}>${addonHtml}`;
      });
    });
    return _twig;
  }

  return twig.replace(/\sdata-id=/g, ` veda-addons="true" data-id=`);
};

export const addAttrDataMegamenu = (twig: string, isMegamenu: boolean) => {
  if (!isMegamenu) {
    return twig;
  }

  return twig.replace(/\sdata-id=/g, ` veda-megamenus="true" data-id=`);
};

export const handleErrorLiquidSyntaxToTwig = ({ err, sectionName, dataId }: { err: unknown; sectionName: string; dataId: string }) => {
  const message =
    err instanceof LiquidSyntaxToTwigError
      ? err.message
      : (() => {
          return `Something went wrong \n ${err}`;
        })();

  const finalMessage = message.replaceAll('\n', '<br />');

  // NOTE: @tuong -> "data-id"
  return `
    <div data-id="${dataId}" style="padding: 7rem; box-sizing: border-box; background-color: rgba(244, 244, 244, 0.9); -webkit-font-smoothing: antialiased; text-rendering: optimizelegibility; -webkit-tap-highlight-color: transparent">
      <div style="font-size: 2em; font-family: Roboto, sans-serif; color: rgb(206, 17, 38); margin-bottom: 2rem; flex: 0 0 auto; max-height: 50%; overflow: auto; font-weight: 400;">
        ${i18n.t('twig_error.error_notification_title', { section_name: sectionName })}
      </div>
      <div style="font-size: 1.5em; font-family: Consolas, Menlo, monospace; color: black; margin-bottom: 2rem; flex: 0 0 auto; max-height: 50%; overflow: auto; font-weight: 300; padding-bottom: 1rem; border-bottom: 1px solid rgb(221, 221, 221);">${finalMessage}</div>

      ${i18n.t('twig_error.error_liquid_syntax_to_twig')}
    </div>
  `;
};

export const handleLoadingLiquidSyntaxToTwig = ({ dataId }: { dataId: string }) => {
  // NOTE: @tuong -> "data-id"
  return `
    <div data-id="${dataId}" style="padding: 7rem; box-sizing: border-box; background-color: rgba(244, 244, 244, 0.9); -webkit-font-smoothing: antialiased; text-rendering: optimizelegibility; -webkit-tap-highlight-color: transparent">
      <div style="font-size: 2em; font-family: Roboto, sans-serif; color: rgb(206, 17, 38); margin: 0px 2rem 2.5rem 0px; flex: 0 0 auto; max-height: 50%; overflow: auto; font-weight: 400;">
        Fetching data ...
      </div>
    </div>
  `;
};
