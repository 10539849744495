import { getTrashThemes, loadMoreTrashThemes } from '../actions/actionThemeTrash';
import { put, retry, takeLatest, SagaReturnType } from 'redux-saga/effects';
import { getThemesTrash, loadMoreThemesTrash } from 'services/ThemeService/Logic/getThemesTrash';
import { getActionType } from 'wiloke-react-core/utils';
import { AdminTheme } from 'types/Theme';

const size = 20;

function* handleGet({ payload: { searchKey } }: ReturnType<typeof getTrashThemes.request>) {
  try {
    const response: SagaReturnType<typeof getThemesTrash> = yield retry(3, 1000, getThemesTrash, {
      size,
      label: searchKey,
    });
    yield put(getTrashThemes.success({ data: (response as unknown) as AdminTheme[], hasNextPage: response.length < size ? false : true }));
  } catch (error) {
    console.log(error);
    yield put(getTrashThemes.failure(undefined));
  }
}

export function* watchGetTrashThemes() {
  yield takeLatest(getActionType(getTrashThemes.request), handleGet);
}

function* handleLoadMore({ payload: { cursor, searchKey } }: ReturnType<typeof loadMoreTrashThemes.request>) {
  try {
    const response: SagaReturnType<typeof loadMoreThemesTrash> = yield retry(3, 1000, loadMoreThemesTrash, { cursor, size, label: searchKey });

    yield put(loadMoreTrashThemes.success({ data: (response as unknown) as AdminTheme[], hasNextPage: response.length < size ? false : true }));
  } catch (error) {
    yield put(loadMoreTrashThemes.failure(undefined));
  }
}

export function* watchLoadMoreTrashThemes() {
  yield takeLatest(getActionType(loadMoreTrashThemes.request), handleLoadMore);
}
