import { AxiosError } from 'axios';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { deletePurchaseCodeService } from 'services/UserService/Logic/deletePurchaseCode';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { deletePurchaseCode } from '../actions';

function* handleDelete({ payload: { purchaseCode } }: ReturnType<typeof deletePurchaseCode.request>) {
  try {
    const response: SagaReturnType<typeof deletePurchaseCodeService> = yield retry(3, 1000, deletePurchaseCodeService, {
      purchaseCode,
    });
    yield put(deletePurchaseCode.success({ purchaseCode }));
    notifyAxiosHandler.handleSuccess(response.message);
  } catch (error) {
    console.log(error);
    yield put(deletePurchaseCode.failure({ purchaseCode }));
    notifyAxiosHandler.handleError(error as AxiosError<ErrorData> | Error);
  }
}

export function* watchDeletePurchaseCode() {
  yield takeLatest(getActionType(deletePurchaseCode.request), handleDelete);
}
