import { storage } from '@wiloke/functions';
import configureApp from 'configureApp';

export const getApiTracking = ({ defaultBaseUrl, trackingEndpoint }: { trackingEndpoint: string; defaultBaseUrl: string }) => {
  const userId = storage.getItem('switch_to_user');
  if (userId) {
    return `${configureApp.endpoint.clients}/admin/tracking/${userId}/${trackingEndpoint}`;
  } else {
    return defaultBaseUrl;
  }
};
