import TextInput, { TextInputProps } from 'components/TextInput';
import withDebounce from 'hocs/withDebounce';
import { FC, KeyboardEvent } from 'react';
import { ActivityIndicator, Space, Styles, Text, View } from 'wiloke-react-core';

const InputDebounce = withDebounce(TextInput, 'value', 'onValueChange', 400);

const inputContainer: Styles = {
  position: 'relative',
  width: '100%',
  textAlign: 'left',
};

const loadingStyle: Styles = {
  position: 'absolute',
  right: '10px',
  top: '0',
  height: '44px',
  display: 'flex',
  alignItems: 'center',
};

interface StepPurchaseCodeProps {
  value: TextInputProps['value'];
  loading?: boolean;
  error?: string;
  onChange: TextInputProps['onValueChange'];
  onKeydown?: () => void;
  onGetPurchaseCode?: () => void;
}

export const StepPurchaseCode: FC<StepPurchaseCodeProps> = ({ onChange, onKeydown, onGetPurchaseCode, value, loading = false, error }) => {
  const handleKeydown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      onKeydown?.();
    }
  };

  return (
    <View>
      <View css={inputContainer}>
        <InputDebounce
          placeholder="Enter your code here"
          block
          value={value}
          onValueChange={onChange}
          disabled={loading}
          onKeyDown={handleKeydown}
          borderColor={error && error !== '' ? 'danger' : 'gray3'}
        />
        {error && (
          <View tagName="span" color="danger" css={{ fontSize: '12px' }}>
            {error}
          </View>
        )}
        {loading && (
          <View css={loadingStyle}>
            <ActivityIndicator size={16} />
          </View>
        )}
      </View>
      <Space size={20} />
      <View>Please get code to unlock theme first. Then, you can come back here to install theme and customize it</View>
      <Space size={20} />
      <Text size={16} color="primary" css={{ cursor: 'pointer', fontWeight: '500' }} onClick={onGetPurchaseCode}>
        Click here to know how to get code
      </Text>
    </View>
  );
};
