import { AsyncComponent } from '@wiloke/ui';
import { RecommendCardStyle2 } from 'components/RecommendCard';
import { useGetPartnerApps, useSetSearchPartnerApp } from 'containers/Admin/AppsPartner/store/action';
import { partnerAppSelector } from 'containers/Admin/AppsPartner/store/partnerAppsSlice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Space, View } from 'wiloke-react-core';
import parse from 'html-react-parser';
import withDebounce from 'hocs/withDebounce';
import TextInput from 'components/TextInput';

const DebouncedInput = withDebounce(TextInput, 'value', 'onValueChange', 300);

export const PartnerApps = () => {
  const { categoryId, getAppsStatus, apps, search } = useSelector(partnerAppSelector);

  const getApps = useGetPartnerApps();
  const setSearchPartnerApp = useSetSearchPartnerApp();

  useEffect(() => {
    getApps.request({ categoryId: Number(categoryId), search });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, search]);

  return (
    <View>
      <View width={300}>
        <DebouncedInput block placeholder="Search apps..." borderColor="gray2" value={search} onValueChange={setSearchPartnerApp} />
      </View>
      <Space size={16} />

      <AsyncComponent
        status={getAppsStatus}
        isEmpty={apps.length === 0}
        Success={
          <View row>
            {apps.map(item => (
              <View
                key={item.id}
                columns={[6, 6, 6]}
                css={{
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  marginBottom: '16px',
                }}
              >
                <RecommendCardStyle2
                  description={parse(`${item.excerpt.rendered}`)}
                  title={item.title.rendered}
                  image={item.images.thumbnail}
                  onClick={() => {
                    window.open(item.acf.app_partner_url, '_blank');
                  }}
                />
              </View>
            ))}
          </View>
        }
      />
    </View>
  );
};
