export const SHOPIFY_TAG = [
  'if',
  'elsif',
  'else',
  'endif',
  'case',
  'when',
  'endcase',
  'unless',
  'for',
  'break',
  'continue',
  'cycle',
  'tablerow',
  'comment',
  'echo',
  'include',
  'form',
  'liquid',
  'paginate',
  'raw',
  'render',
  'section',
  'style',
  'assign',
  'capture',
  'increment',
  'decrement',
];
