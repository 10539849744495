import { MyModal } from '@wiloke/ui';
import { notification } from 'antd';
import ChooseImage from 'components/ChooseImage';
import Field from 'components/Field';
import TextInput from 'components/TextInput';
import { useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { difference } from 'ramda';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPagesOfThemeDraft } from 'services/ThemeService/Logic/getPagesOfThemeDraft';
import { i18n } from 'translation';
import { PageType } from 'types/Page';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { GridSmart, View } from 'wiloke-react-core';
import { useUpdateThemeDraft } from '../../store/actions/actionThemesDraft';
import { themesDraftSelector, useSetModalUpdateThemeDraft } from '../../store/reducers/sliceThemesDraft';
import { MAX_LENGTH_OF_THEME_NAME, PAGE_TYPES_WITH_EXPECT_ORDER } from '../@consts';
import { INITIAL_STATE } from './@consts';
import { State } from './@types';
import { SelectPagesTemplate } from './components/SelectPagesTemplate';

export const ModalUpdateThemeDraft = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState<State>(INITIAL_STATE);
  const initialFormDataRef = useRef<Record<PageType, { parentCommandIds: string[]; commandIds: string[] }>>(
    PAGE_TYPES_WITH_EXPECT_ORDER.reduce<Record<PageType, { parentCommandIds: string[]; commandIds: string[] }>>((res, pageType) => {
      return {
        ...res,
        [pageType]: {
          parentCommandIds: [],
          commandIds: [],
        },
      };
    }, {} as Record<PageType, { parentCommandIds: string[]; commandIds: string[] }>),
  );

  const { connect, disconnect, statusSocketConnection } = useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService(
    'clone_page_atom_sevice_to_theme_atom_service',
  );

  const { modalAppendPageToThemeDraft, updateThemeDraftStatus } = useSelector(themesDraftSelector);
  const setModalUpdateThemeDraft = useSetModalUpdateThemeDraft();
  const updateThemeDraft = useUpdateThemeDraft();

  const handleUpdateTheme = () => {
    connect({
      onError: () => {
        notification.error({
          message: i18n.t('clone_page_atom_service_to_theme_atom_service.init_clone_error'),
        });
      },
      onSuccess: () => {
        if (modalAppendPageToThemeDraft) {
          // Những cái cần import là những pageCommandId có trong list page selected nhưng không có trong initial
          const pageIdsNeedImport = PAGE_TYPES_WITH_EXPECT_ORDER.reduce<string[]>((result, pageType) => {
            const pageIdsSelected = state.pageIds[pageType];
            const pageIdsInitial = initialFormDataRef.current[pageType];

            const differencePageIds = difference(pageIdsSelected, pageIdsInitial.parentCommandIds);
            return result.concat(...differencePageIds);
          }, []);

          // Những cái cần xoá là những pageCommandId có trong list initial nhưng không có trong kết quả cuối cùng
          const pageIdsNeedDelete = PAGE_TYPES_WITH_EXPECT_ORDER.reduce<string[]>((result, pageType) => {
            const pageIdsSelected = state.pageIds[pageType];
            const pageIdsInitial = initialFormDataRef.current[pageType];
            const differencePageIds: string[] = [];

            pageIdsInitial.parentCommandIds.forEach((parentCommandId, index) => {
              if (!pageIdsSelected.includes(parentCommandId)) {
                const commandId = pageIdsInitial.commandIds[index];
                if (commandId) {
                  differencePageIds.push(commandId);
                }
              }
            });

            return result.concat(...differencePageIds);
          }, []);

          updateThemeDraft.request({
            prevThemeData: modalAppendPageToThemeDraft,
            pageCommandIdsNeedImport: pageIdsNeedImport,
            pageCommandIdsNeedDelete: pageIdsNeedDelete,
            featuredImage: state.featuredImage,
            screenshot1: state.screenshot1,
            label: state.name,
            onFulfill: () => disconnect({}),
          });
        }
      },
    });
  };

  const handleGetParentIds = async () => {
    if (modalAppendPageToThemeDraft) {
      setIsLoading(true);
      try {
        const { pageCommandIds, label, featuredImage, screenshot1 } = modalAppendPageToThemeDraft;
        const responses = await getPagesOfThemeDraft({ commandIds: pageCommandIds });
        const pageIds = responses.reduce<State['pageIds']>((result, response) => {
          const { type, commandId, parentCommandId } = response;
          if (parentCommandId) {
            initialFormDataRef.current = {
              ...initialFormDataRef.current,
              [type]: {
                parentCommandIds: initialFormDataRef.current[type].parentCommandIds.concat(parentCommandId),
                commandIds: initialFormDataRef.current[type].commandIds.concat(commandId),
              },
            };
            return {
              ...result,
              [type]: result[type].concat(parentCommandId),
            };
          }
          return result;
        }, INITIAL_STATE.pageIds);
        setState(state => ({
          ...state,
          pageIds,
          name: label,
          featuredImage: featuredImage ?? '',
          screenshot1: screenshot1 ?? '',
        }));
      } catch (error) {
        const error_ = error as Error;
        notifyAxiosHandler.handleError(error_);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setIsLoading(false);
    handleGetParentIds();
    setState(INITIAL_STATE);
    initialFormDataRef.current = PAGE_TYPES_WITH_EXPECT_ORDER.reduce<Record<PageType, { parentCommandIds: string[]; commandIds: string[] }>>(
      (res, pageType) => {
        return {
          ...res,
          [pageType]: {
            parentCommandIds: [],
            commandIds: [],
          },
        };
      },
      {} as Record<PageType, { parentCommandIds: string[]; commandIds: string[] }>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalAppendPageToThemeDraft]);

  if (!modalAppendPageToThemeDraft) {
    return null;
  }

  return (
    <MyModal
      isVisible
      size="large"
      onCancel={() => setModalUpdateThemeDraft(undefined)}
      headerText={i18n.t('ModalInteractiveTheme.updateTheme')}
      onOk={handleUpdateTheme}
      isLoading={updateThemeDraftStatus === 'loading' || statusSocketConnection === 'loading'}
    >
      <View row>
        <View columns={[6, 6, 6]}>
          <Field label={i18n.t('ModalInteractiveTheme.name')}>
            <TextInput
              block
              value={state.name}
              onValueChange={val => {
                setState(prev => ({ ...prev, name: val }));
              }}
              maxLength={MAX_LENGTH_OF_THEME_NAME}
            />
          </Field>

          <GridSmart columnWidth={200} columnCount={2}>
            <Field label={i18n.t('ModalInteractiveTheme.featured_image.title')} note={i18n.t('ModalInteractiveTheme.featured_image.description')}>
              <ChooseImage
                mode="popup"
                value={{ src: state.featuredImage, width: 0, height: 0 }}
                onChange={({ src }) => {
                  setState(prev => ({ ...prev, featuredImage: src }));
                }}
              />
            </Field>
            <Field
              label={i18n.t('ModalInteractiveTheme.screenshot_image_1.title')}
              note={i18n.t('ModalInteractiveTheme.screenshot_image_1.description')}
            >
              <ChooseImage
                mode="popup"
                value={{ src: state.screenshot1, width: 0, height: 0 }}
                onChange={({ src }) => {
                  setState(prev => ({ ...prev, screenshot1: src }));
                }}
              />
            </Field>
          </GridSmart>
        </View>
        <View columns={[6, 6, 6]}>
          <GridSmart columnWidth={200}>
            {PAGE_TYPES_WITH_EXPECT_ORDER.map(pageType => (
              <SelectPagesTemplate
                isLoading={isLoading}
                key={pageType}
                pageType={pageType}
                value={state.pageIds[pageType]}
                onChange={value => {
                  setState(state => ({
                    ...state,
                    pageIds: {
                      ...state.pageIds,
                      [pageType]: value,
                    },
                  }));
                }}
              />
            ))}
          </GridSmart>
        </View>
      </View>
    </MyModal>
  );
};
