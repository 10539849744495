import { adapterGetMegaMenus } from 'services/MegaMenuService/Adapters/adapterGetMegaMenus';
import { sectionApiController } from 'services/SectionService';
import { AdminSection } from 'types/Sections';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const searchMegaMenusOfPublish = async (megaMenuCommandIds: string[]) => {
  const { lastCache } = getUserInfo();
  const response = await sectionApiController.atom.publishApi.megaMenu.searchMegaMenusOfAtom({
    commandIds: megaMenuCommandIds.join(','),
    cacheVersion: lastCache,
  });
  return adapterGetMegaMenus(response) as AdminSection[];
};
