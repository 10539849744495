import { renewVersion } from 'utils/CacheControl/VedaClientCacheControl';
import { pageApis } from '../apis';

interface Params {
  commandId: string;
  disableExceptMe: boolean;
}

export const disableProductPageService = async ({ commandId, disableExceptMe }: Params) => {
  const response = await pageApis.vedaApplication.userApi.page.disableProduct({ commandId, disableExceptMe });
  renewVersion();
  return response;
};
