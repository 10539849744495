import { AxiosError } from 'axios';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { updateClientThemeShopifyId } from 'services/ThemeService/Logic/updateClientThemeShopifyId';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { updateThemeShopifyId } from '../slice/actions';

function* handleAction({ payload: { commandId, shopifyThemeId, onFailed, onFulfill } }: ReturnType<typeof updateThemeShopifyId.request>) {
  try {
    const response: SagaReturnType<typeof updateClientThemeShopifyId> = yield retry(3, 1000, updateClientThemeShopifyId, {
      commandId,
      shopifyThemeId,
    });
    yield put(
      updateThemeShopifyId.success({
        commandId: response.info.commandId ?? commandId,
        shopifyThemeId: response.info.shopifyThemeId ?? shopifyThemeId,
      }),
    );
    onFulfill?.();
    notifyAxiosHandler.handleSuccess(response.message);
  } catch (error) {
    yield put(updateThemeShopifyId.failure({ commandId }));
    onFailed?.((error as AxiosError<ErrorData>).response?.data.message ?? (error as Error).message);
  }
}

export function* watchUpdateShopifyThemeId() {
  yield takeLatest(getActionType(updateThemeShopifyId.request), handleAction);
}
