import { Styles, Theme, css } from 'wiloke-react-core';

export const timeContainer = ({ fonts }: Theme) => css`
  overflow: hidden;
  text-align: center;
  font-family: ${fonts.secondary};
  display: flex;
  padding: 0 2px;
  align-items: flex-end;
`;

export const timeNumber = () => css`
  color: #c0071e;
  font-size: 16px;
  font-weight: 500;
`;

export const timeType = () => css`
  color: #c0071e;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`;

export const dot = () => css`
  color: #c0071e;
  font-weight: 600;
`;

export const bodyModalStyles: Styles = {
  width: '965px',
  minWidth: '965px',
  maxWidth: '1000px',
};

export const fieldStyle = ({ fonts }: Theme): Styles => ({
  padding: '20px 0',
  marginBottom: '0px',
  fontWeight: '400',
  fontFamily: fonts.secondary,
});

export const couponFormStyle: Styles = {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  columnGap: '10px',
};

export const subtotalStyle: Styles = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '16px',
};
