import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

interface Params {
  commandId: string;
}

export const restoreThemeAtom = async ({ commandId }: Params) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    const atomResponse = await themeApis.atom.adminApi.theme.restoreTrash({
      commandId,
    });
    return atomResponse;
  }

  throw new RoleException();
};
