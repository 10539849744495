import { AxiosError } from 'axios';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { deleteWebsiteOfPurchaseCodeService } from 'services/UserService/Logic/deleteWebsiteOfPurchaseCode';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { deleteWebsiteOfPurchaseCode } from '../actions';

function* handleDelete({ payload: { purchaseCode, website } }: ReturnType<typeof deleteWebsiteOfPurchaseCode.request>) {
  try {
    const response: SagaReturnType<typeof deleteWebsiteOfPurchaseCodeService> = yield retry(3, 1000, deleteWebsiteOfPurchaseCodeService, {
      purchaseCode,
      website,
    });
    yield put(deleteWebsiteOfPurchaseCode.success({ purchaseCode, website }));
    notifyAxiosHandler.handleSuccess(response.message);
  } catch (error) {
    console.log(error);
    yield put(deleteWebsiteOfPurchaseCode.failure({ website }));
    notifyAxiosHandler.handleError(error as AxiosError<ErrorData> | Error);
  }
}

export function* watchDeleteWebsiteOfPurchaseCode() {
  yield takeLatest(getActionType(deleteWebsiteOfPurchaseCode.request), handleDelete);
}
