import { changeSelectPageType } from 'containers/Admin/PageBuilder/TemplatesPage';
import { all, put, retry, SagaReturnType, select } from 'redux-saga/effects';
import { getChangelogsOfAtoms as getChangelogsOfMegamenuAtoms } from 'services/MegaMenuService/Logic/Changelogs';
import { getMegamenusOfPageDraft } from 'services/PageService/Logic/getMegamenusOfPageDraft';
import { getPageDraft } from 'services/PageService/Logic/getPageDraft';
import { getSectionsOfPageDraft } from 'services/PageService/Logic/getSectionsOfPageDraft';
import { getChangelogsOfAtoms as getChangelogsOfSectionAtoms } from 'services/SectionService/Logic/Changelogs';
import { addMegaMenusToPage, getPage } from 'store/actions/actionPages';
import { setVendors } from 'store/actions/actionVendors';
import { getSectionVersion } from 'store/actions/versions/actionSectionVersion';
import { setGeneralSettingsPage } from 'store/global/generalSettings/slice';
import { setGlobalJs } from 'store/global/globalJs/slice';
import { setGlobalScss } from 'store/global/globalScss/slice';
import { getGeneralSettingsPage } from 'store/global/statusGeneralSettings/actions';
import { defaultPickerRelateShopifySelector } from 'store/selectors';
import { DevPage, PageType } from 'types/Page';
import { adapterSectionsHadShopifyData } from 'utils/adapterSectionHadShopifyData';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { getDefaultShopifyPresentPage } from 'utils/getDefaultShopifyPresentPage';

export function* handleGetPageDraft_({ payload }: ReturnType<typeof getPage.request>) {
  const { id } = payload;

  try {
    /** Lấy ra các entity shopify đại diện */
    const { data: defaultPickerRelateShopifyData }: ReturnType<typeof defaultPickerRelateShopifySelector> = yield select(
      defaultPickerRelateShopifySelector,
    );
    const { article, blog, collection, product } = defaultPickerRelateShopifyData;

    /** Xử lý "pageSettings" */
    const page: Awaited<ReturnType<typeof getPageDraft>> = yield retry(3, 1000, getPageDraft, { commandId: id });
    if (page.pageSettings) {
      const { generalSettings, globalJs, globalScss, vendors } = page.pageSettings;
      const headerFooterEnabled = payload.headerFooterEnabled ?? generalSettings.headerFooterEnabled;
      yield put(
        setGeneralSettingsPage({
          settings: {
            ...generalSettings,
            headerFooterEnabled,
            label: payload.name || page.label,
          },
          pageId: payload.id,
        }),
      );
      yield put(setGlobalJs({ js: globalJs ?? '' }));
      yield put(setGlobalScss({ scss: globalScss ?? '' }));
      yield put(setVendors({ vendors: vendors ?? [] }));
    }

    yield put(getGeneralSettingsPage.success(undefined));
    /** Xử lý sections */
    const sectionCommandIds = page.sectionCommandIds;
    const sections: Awaited<ReturnType<typeof getSectionsOfPageDraft>> = yield retry(3, 1000, getSectionsOfPageDraft, {
      sectionCommandIds,
    });

    /** Xử lý section version */
    const sectionSourceIds = sections.reduce<string[]>((res, section) => {
      if ('parentCommandId' in section && section.parentCommandId) {
        return res.concat(section.parentCommandId);
      }
      return res;
    }, []);
    yield all(
      sectionSourceIds.map(sectionCommandId => {
        return put(getSectionVersion.request({ sectionCommandId: sectionCommandId }));
      }),
    );
    const sectionsVersion: SagaReturnType<typeof getChangelogsOfSectionAtoms> = yield retry(3, 1000, getChangelogsOfSectionAtoms, sectionSourceIds);
    yield all(
      sectionsVersion.map(({ atomCommandId, version }) => put(getSectionVersion.success({ sectionCommandId: atomCommandId, data: version }))),
    );

    /** Xử lý megamenu */
    const megamenuResponses: Array<Awaited<ReturnType<typeof getMegamenusOfPageDraft>> | undefined> = yield all(
      sections.map(section => {
        const { megaMenuCommandIds } = section;
        if (megaMenuCommandIds?.length) {
          return retry(3, 1000, getMegamenusOfPageDraft, { megamenuCommandIds: megaMenuCommandIds });
        }
      }),
    );
    /** Xử lý megamenu version */
    const megamenuSectionSourceIds = Array.from(
      new Set(
        megamenuResponses.reduce<string[]>((res, megamenuResponse) => {
          if (megamenuResponse) {
            megamenuResponse.map(megamenuSection => {
              if ('parentCommandId' in megamenuSection && megamenuSection.parentCommandId) {
                res.push(megamenuSection.parentCommandId);
              }
            });
          }
          return res;
        }, []),
      ),
    );
    yield all(
      megamenuSectionSourceIds.map(sectionSectionCommandId => {
        return put(getSectionVersion.request({ sectionCommandId: sectionSectionCommandId }));
      }),
    );
    const megamenuSectionsVersion: SagaReturnType<typeof getChangelogsOfMegamenuAtoms> = yield retry(
      3,
      1000,
      getChangelogsOfMegamenuAtoms,
      megamenuSectionSourceIds,
    );
    yield all(
      megamenuSectionsVersion.map(({ atomCommandId, version }) => put(getSectionVersion.success({ sectionCommandId: atomCommandId, data: version }))),
    );

    /** Xử lý apply cho entity shopify */
    const shopifyPages_ = 'all';
    const shopifyRepresentPage_ = getDefaultShopifyPresentPage({
      article,
      blog,
      collection,
      product,
      pageType: page.type as PageType,
    });

    const needExecuteAdapter =
      page.lastModifiedBy === getUserInfo().id ? false : page.createdBy === getUserInfo().id || page.userId === getUserInfo().id ? false : true;
    const checkPayloadEmpty = payload.shopifyRepresentPage?.handle === '' || payload.shopifyRepresentPage?.handle === undefined;

    // TODO: Utils transform thay vì ép kiểu
    const final_page: DevPage = {
      ...page,
      sections: needExecuteAdapter
        ? adapterSectionsHadShopifyData({
            sections,
            article,
            blog,
            collection,
            product,
            isUsePageTemplateAction: false,
          })
        : sections,
      type: payload.type ?? (page.type as PageType),
      label: payload.name || page.label,
      status: page.status as DevPage['status'],
      // NOTE: @tuong -> "page.shopifyRepresentPage" không có chứng tỏ là tạo mới
      // NOTE: @tuong -> "page.shopifyRepresentPage" chỉ có khi
      // 1. Update lại page
      // 2. Dùng template của chính nó (User)
      shopifyRepresentPage: checkPayloadEmpty ? shopifyRepresentPage_ : payload.shopifyRepresentPage,
      shopifyPages: payload.shopifyPages ?? shopifyPages_,
      enable: true,
      entityType: 'Draft',
    };
    yield put(getPage.success({ result: final_page }));

    // const { data: addons }: ReturnType<typeof themeAddonsSelector> = yield select(themeAddonsSelector);
    // const themeAddonsBody = addons.map(item => item.body).filter(Boolean) ?? [];
    // yield put(addMultiAddons({ addons: addons }));
    // yield put(setThemeAddonsToPages(themeAddonsBody));

    yield all(
      megamenuResponses.map(megamenuResponse => {
        if (megamenuResponse) {
          return put(
            addMegaMenusToPage(
              needExecuteAdapter
                ? adapterSectionsHadShopifyData({
                    sections: megamenuResponse,
                    article,
                    blog,
                    collection,
                    product,
                    isUsePageTemplateAction: false,
                  })
                : megamenuResponse,
            ),
          );
        }
      }),
    );
    yield put(changeSelectPageType(final_page.type));
  } catch (error) {
    if (error instanceof Error) {
      const message = error.message;
      yield put(getPage.failure({ message, id }));
      yield put(getGeneralSettingsPage.failure(undefined));
      throw error;
    }
  }
}
