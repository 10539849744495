import { AxiosResponse } from 'axios';
import { BE_PageClient } from 'services/PageService/VedaApplication/types';
import fetchAPI from 'utils/functions/fetchAPI';
import { clientBaseUrl } from '../const';

interface DeleteClient {
  /** CommandId của page client cần nhân bản */
  commandId: string;
}

interface ResponseSuccess {
  message: string;
  info: BE_PageClient;
}

/** API được sử dụng để nhân bản page client */
export const duplicateClient = async ({ commandId }: DeleteClient) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${clientBaseUrl}/${commandId}/duplications`,
    method: 'put',
  });
  return response.data;
};
