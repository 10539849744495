import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ProductSection } from 'types/Sections';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface ResponseSuccess {
  info: ProductSection[];
  message: string;
}
interface GetProducts {
  type: 'GET FIRST PAGE';
  cacheVersion: number | null;
  /** Lọc "Section Product" theo category */
  category: string;
  size?: number;
  label?: string;
  planFilter?: string;
}
interface LoadmoreProducts {
  type: 'LOADMORE';
  cacheVersion: number | null;
  /** Lọc "Section Product" theo category */
  category: string;
  /** Cursor để loadmore */
  lastCursor: string;
  size?: number;
  label?: string;
  planFilter?: string;
}

/** API lấy về các template section đã được publish bởi "Admin" lên cho user dùng */
export const getProducts = async ({ type, size = 20, label, planFilter, cacheVersion, ...params }: GetProducts | LoadmoreProducts) => {
  let requestConfig: AxiosRequestConfig = {};
  if (type === 'GET FIRST PAGE') {
    const { category } = params;
    requestConfig = {
      url: `${baseUrl}/search`,
      params: {
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
        size,
        categoryNames: category !== '' ? category : undefined,
        label,
        planFilter,
      },
    };
  } else {
    const { category, lastCursor } = params as LoadmoreProducts;
    requestConfig = {
      url: `${baseUrl}/search`,
      params: {
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
        size,
        categoryNames: category !== '' ? category : undefined,
        after: lastCursor,
        label,
        planFilter,
      },
    };
  }

  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request(requestConfig);

  return response.data;
};

interface GetProductsWithParentCommandIds {
  parentCommandIds: string;
  cacheVersion: number | null;
}
export const getProductsWithParentCommandIds = async ({ parentCommandIds, cacheVersion }: GetProductsWithParentCommandIds) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${baseUrl}/search`,
    params: {
      parentCommandIds,
      cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
    },
  });

  return response.data;
};
