import { omit } from 'ramda';
import { Schema, SectionSettings } from 'types/Schema';
import { PageSection, ProductSection } from 'types/Sections';

const defaultSchema: Schema = {
  blocks: [],
  settings: [],
};

const defaultSettings: SectionSettings = [];

export const adapterGetMegaMenus = (atomResponse: PageSection[], productResponse?: ProductSection[]): PageSection[] => {
  return atomResponse.map<PageSection>(atom => {
    return {
      // @tuong -> 2 trường này gây section trước và sau save khác nhau -> rerender
      ...omit(['modifiedDateTimestamp', 'createdDateTimestamp'], atom),
      ...atom,
      type: 'megamenu',
      commandId: atom.commandId,
      addonIds: atom.addonIds ?? [],
      category: atom.category ? atom.category : { name: '', commandId: '', description: '' },
      data: {
        ...atom.data,
        schema: !atom.data?.schema ? defaultSchema : atom.data.schema,
        settings: !atom.data?.settings ? defaultSettings : atom.data.settings,
      },
      enable: atom.enable ? atom.enable : true,
      vedaProductRepresent: productResponse?.find(product => product.parentCommandId === atom.commandId),
    } as PageSection;
  });
};
