import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { authService } from 'services/AuthService';
import { getUserPlan } from 'store/global/auth/actions';
import { getActionType } from 'wiloke-react-core/utils';

function* handleGet({ payload: { onFailed, onFulfill, prevPlan } }: ReturnType<typeof getUserPlan.request>) {
  try {
    const response: SagaReturnType<typeof authService.getUserInfo> = yield retry(3, 1000, authService.getUserInfo);
    const plan = response.info.plan;
    yield put(getUserPlan.success({ plan }));

    if (prevPlan !== plan.planHandle && plan.planHandle !== 'free') {
      onFulfill?.();
    } else {
      onFailed?.();
    }
  } catch (error) {
    onFailed?.();
    yield put(getUserPlan.failure(undefined));
  }
}

export function* watchGetUserPlan() {
  yield takeLatest(getActionType(getUserPlan.request), handleGet);
}
