import { userApis } from 'services/UserService/apis';
import { SectionCategoryTag } from 'types/Sections';
import { AdminTheme, ProductTheme } from 'types/Theme';
import { RoleException } from 'utils/constants/constants';
import { PLAN_HANDLE } from 'utils/constants/PlanEnum';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

interface PublishThemeAtom {
  themeAtom: AdminTheme;
  group: ProductTheme['group'];
  categoryOfProduct: SectionCategoryTag | undefined;
  zipUrl: string;
  buyNowUrl: string;
  documentUrl: string;
  envatoThemeId: string | undefined;
  planHandle: PLAN_HANDLE;
  demoUrl?: string;
  description?: string;
  version?: string;
}

export const publishThemeAtom = async ({
  themeAtom,
  categoryOfProduct,
  group,
  zipUrl,
  envatoThemeId,
  documentUrl,
  buyNowUrl,
  planHandle,
  demoUrl,
  description,
  version,
}: PublishThemeAtom) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const searchResponse = await themeApis.vedaApplication.adminApi.theme.getProductsWithParentCommandIds({
      parentCommandIds: themeAtom.commandId,
      cacheVersion: lastCache,
    });
    const itemExisted = searchResponse.info.find(item => item.parentCommandId === themeAtom.commandId);
    if (!!itemExisted) {
      const response = await themeApis.vedaApplication.adminApi.theme.updateProduct({
        theme: {
          ...itemExisted,
          envatoThemeId: group === 'envato' ? envatoThemeId : undefined,
          zipUrl,
          documentUrl,
          group,
          featuredImage: themeAtom.featuredImage,
          screenshot1: themeAtom.screenshot1,
          label: themeAtom.label,
          planHandle: planHandle ?? itemExisted.planHandle,
          tags: [],
          category: categoryOfProduct
            ? {
                commandId: categoryOfProduct.commandId,
                name: categoryOfProduct.name,
                description: categoryOfProduct.description,
              }
            : undefined,
          buyNowUrl,
          demoUrl,
          description,
          version,
        },
      });
      await userApis.user.adminApi.updateVedaCacheVersion();
      return response;
    } else {
      const response = await themeApis.vedaApplication.adminApi.theme.createProduct({
        theme: {
          envatoThemeId: group === 'envato' ? envatoThemeId : undefined,
          zipUrl,
          documentUrl,
          group,
          featuredImage: themeAtom.featuredImage,
          screenshot1: themeAtom.screenshot1,
          label: themeAtom.label,
          parentCommandId: themeAtom.commandId,
          downloadedCount: 0,
          planHandle,
          tags: [],
          category: categoryOfProduct
            ? {
                commandId: categoryOfProduct.commandId,
                name: categoryOfProduct.name,
                description: categoryOfProduct.description,
              }
            : undefined,
          buyNowUrl,
          demoUrl,
          description,
          version,
        },
      });
      await userApis.user.adminApi.updateVedaCacheVersion();
      return response;
    }
  }

  throw new RoleException();
};
