import { PageSection } from 'types/Sections';
import { ErrorOption } from './@types/ErrorOption';
import { ExpectReturnType } from './@types/ExpectReturnType';
import { getHtmlFiles } from './getHtmlFiles';
import { handleCssInlines } from './utils/handleCssInlines';

interface GetHtmlFilesOfPage {
  sectionsEnable_notIncludeAddonSections_includeMegamenuSections: PageSection[];
  errorOption: ErrorOption;
}

export const getHtmlFilesOfPage = ({
  errorOption,
  sectionsEnable_notIncludeAddonSections_includeMegamenuSections,
}: GetHtmlFilesOfPage): ExpectReturnType[] => {
  return handleCssInlines(
    getHtmlFiles({
      errorOption,
      sectionsIncludeMegamenuSections: sectionsEnable_notIncludeAddonSections_includeMegamenuSections,
      fileType: 'liquid của page - bao gồm liquid của section bình thường[đã thế megamenu tag placeholder]',
    }),
  );
};
