import { call, put, retry, takeLatest } from 'redux-saga/effects';
import { sectionService } from 'services/SectionService';
import { updateSectionsInPage, updateSectionVersion } from 'store/actions/versions/actionSectionVersion';
import { i18n } from 'translation';
import { ProductSection } from 'types/Sections';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';

function* handleUpdateSection(parentCommandId: string, prevSectionFeId: string) {
  try {
    const response: Awaited<ReturnType<typeof sectionService.sections.updateSectionVersion>> = yield retry(
      3,
      1000,
      sectionService.sections.updateSectionVersion,
      parentCommandId,
    );
    yield put(
      updateSectionVersion.success({
        updatedItem: {
          ...response,
          parentCommandId,
          id: prevSectionFeId,
        },
      }),
    );
    notifyAxiosHandler.handleSuccess(`${i18n.t('general.update', { text: `${response.label} ${i18n.t('general.successfully')}` })}`);
  } catch (error) {
    const error_ = error as Error;
    notifyAxiosHandler.handleError(error_);
    yield put(updateSectionVersion.failure(undefined));
  }
}

function* handleAction({ payload: { prevSections } }: ReturnType<typeof updateSectionsInPage.request>) {
  try {
    if (prevSections.length > 0) {
      for (const prevSection of prevSections) {
        if ((prevSection as ProductSection).parentCommandId && (prevSection as ProductSection).parentCommandId !== '') {
          yield call(handleUpdateSection, (prevSection as ProductSection).parentCommandId, prevSection.id);
        }
      }
      yield put(updateSectionsInPage.success(undefined));
    } else {
      console.log('Không có section nào để update');
      yield put(updateSectionsInPage.failure(undefined));
    }
  } catch (error) {
    yield put(updateSectionsInPage.failure(undefined));
  }
}

export function* watchUpdateSectionsInPage() {
  yield takeLatest(getActionType(updateSectionsInPage.request), handleAction);
}
