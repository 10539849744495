import { Author } from 'types/Author';
import { ActionTypes, createReducer, handleAction } from 'wiloke-react-core/utils';
import { banOrUnBanAuthor, getAuthors, loadmoreAuthors, updateAuthorPlan, updateAuthorRole } from './action';

interface State {
  data: Author[];
  getStatus: Status;
  loadmoreStatus: Status;
  queueRequesting: Array<Author['id']>;
  page: number;
  totalPages: number;
  updateRoleStatus: Record<number, Status>;
  updatePlanStatus: Status;
  banStatus: Record<number, Status>;
}

type Actions = ActionTypes<typeof getAuthors | typeof loadmoreAuthors | typeof updateAuthorRole | typeof updateAuthorPlan | typeof banOrUnBanAuthor>;

const defaultState: State = {
  data: [],
  getStatus: 'idle',
  loadmoreStatus: 'idle',
  queueRequesting: [],
  page: 0,
  totalPages: 0,
  updateRoleStatus: {},
  updatePlanStatus: 'idle',
  banStatus: {},
};

export const reducerAuthors = createReducer<State, Actions>(defaultState, [
  handleAction('@Authors/getAuthorsRequest', ({ state }) => {
    return {
      ...state,
      getStatus: 'loading',
    };
  }),
  handleAction('@Authors/getAuthorsSuccess', ({ state, action }) => {
    const { authors, page, totalPages } = action.payload;
    return {
      ...state,
      getStatus: 'success',
      data: authors,
      page,
      totalPages,
    };
  }),
  handleAction('@Authors/getAuthorsFailure', ({ state }) => {
    return {
      ...state,
      getStatus: 'failure',
    };
  }),
  handleAction('@Authors/loadmoreAuthorsRequest', ({ state }) => {
    return {
      ...state,
      loadmoreStatus: 'loading',
    };
  }),
  handleAction('@Authors/loadmoreAuthorsSuccess', ({ state, action }) => {
    const { authors, page, totalPages } = action.payload;
    return {
      ...state,
      loadmoreStatus: 'success',
      data: state.data.concat(authors),
      page,
      totalPages,
    };
  }),
  handleAction('@Authors/loadmoreAuthorsFailure', ({ state }) => {
    return {
      ...state,
      loadmoreStatus: 'failure',
    };
  }),
  handleAction('@Authors/updateAuthorRole/request', ({ state, action }) => {
    state.updateRoleStatus[action.payload.userId] = 'loading';
  }),
  handleAction('@Authors/updateAuthorRole/success', ({ state, action }) => {
    state.updateRoleStatus[action.payload.userId] = 'success';
    state.data = state.data.map(item => {
      if (item.id === action.payload.userId) {
        return {
          ...item,
          roles: [{ id: item.roles[0].id, name: action.payload.role }],
        };
      }
      return item;
    });
  }),
  handleAction('@Authors/updateAuthorRole/failure', ({ state, action }) => {
    state.updateRoleStatus[action.payload.userId] = 'failure';
  }),
  handleAction('@Authors/updateAuthorPlan/request', ({ state }) => {
    state.updatePlanStatus = 'loading';
  }),
  handleAction('@Authors/updateAuthorPlan/success', ({ state, action }) => {
    state.updatePlanStatus = 'success';
    state.data = state.data.map(item => {
      if (item.id === action.payload.id) {
        return {
          ...item,
          plan: item.plan
            ? {
                ...item.plan,
                ...action.payload,
              }
            : { ...action.payload },
          shopifyPlanName: action.payload.shopifyPlanName,
        };
      }
      return item;
    }) as Author[];
  }),
  handleAction('@Authors/updateAuthorPlan/failure', ({ state }) => {
    state.updatePlanStatus = 'failure';
  }),
  // ban / unBan
  handleAction('@Authors/banOrUnBanAuthor/request', ({ state, action }) => {
    state.banStatus[action.payload.userId] = 'loading';
  }),
  handleAction('@Authors/banOrUnBanAuthor/success', ({ state, action }) => {
    state.banStatus[action.payload.userId] = 'success';
    state.data = state.data.map(item => {
      if (item.id === action.payload.userId) {
        return {
          ...item,
          verifyStatus: action.payload.verifyStatus,
        };
      }
      return item;
    });
  }),
  handleAction('@Authors/banOrUnBanAuthor/failure', ({ state, action }) => {
    state.banStatus[action.payload.userId] = 'failure';
  }),
]);
