/** Field menu được setting trong tab "Sales channels" > "Online store" > "Navigation" phải hoạt động đúng */
import { Table } from 'antd';
import { ThemeInteracting } from '../../../@types/ThemeInteracting';
import { Dispatch, SetStateAction } from 'react';
import { View } from 'wiloke-react-core';
import { LABEL_OF_PAGES, LABEL_OF_SECTIONS } from '../../../consts';
import { SectionInfomation, useGetSections } from '../../../hooks/useGetSections';

interface ResolveSecondConstraintProps {
  themeExtracting: ThemeInteracting | undefined;
  data: ReturnType<typeof useGetSections>['data'];
  setSelectedSections: Dispatch<SetStateAction<SectionInfomation[]>>;
  selectedSections: SectionInfomation[];
}
export const ResolveSecondConstraint = ({ themeExtracting, data, selectedSections, setSelectedSections }: ResolveSecondConstraintProps) => {
  return (
    <View css={{ padding: '12px 0' }}>
      <View tagName="h5" css={{ marginBottom: '12px' }}>
        Chọn các section sẽ apply "Field menu"
      </View>
      <Table
        loading={!!themeExtracting}
        dataSource={data}
        rowKey={item => item.commandId ?? item.commandId}
        pagination={false}
        rowSelection={{
          selectedRowKeys: selectedSections.map(item => item.commandId),
          onChange: (_, selectedRows) => {
            setSelectedSections(selectedRows);
          },
        }}
        columns={[
          {
            key: 'label',
            dataIndex: 'label',
            title: 'Section',
            render: (_, row) => {
              return <View>{row.label}</View>;
            },
            sorter: (a, b) => a.label.localeCompare(b.label, 'en', { sensitivity: 'base' }),
          },
          {
            key: 'type',
            dataIndex: 'label',
            title: 'Section type',
            render: (_, row) => {
              return <View>{LABEL_OF_SECTIONS[row.type]}</View>;
            },
            sorter: (a, b) => a.type.localeCompare(b.type, 'en', { sensitivity: 'base' }),
            filters: Object.keys(LABEL_OF_SECTIONS).map(item => ({
              text: LABEL_OF_SECTIONS[item as keyof typeof LABEL_OF_SECTIONS],
              value: item,
            })),
            onFilter: (value, record) => record.type === value,
          },
          {
            key: 'pageName',
            dataIndex: 'pageName',
            title: 'Page name',
            render: (_, row) => {
              return <View>{row.pageName ? row.pageName : row.type === 'default' ? 'Đây là BUG' : 'Thành phần thuộc theme'}</View>;
            },
            sorter: (a, b) => {
              const pageName1 = a.pageName ?? '';
              const pageName2 = b.pageName ?? '';
              return pageName1.localeCompare(pageName2, 'en', { sensitivity: 'base' });
            },
          },
          {
            key: 'pageType',
            dataIndex: 'pageType',
            title: 'Page Type',
            render: (_, row) => {
              return <View>{row.pageType ? LABEL_OF_PAGES[row.pageType] : row.type === 'default' ? 'Đây là BUG' : 'Thành phần thuộc theme'}</View>;
            },
            sorter: (a, b) => {
              const pageType1 = a.pageType ?? '';
              const pageType2 = b.pageType ?? '';
              return pageType1.localeCompare(pageType2, 'en', { sensitivity: 'base' });
            },
            filters: Object.keys(LABEL_OF_PAGES).map(item => ({
              text: LABEL_OF_PAGES[item as keyof typeof LABEL_OF_PAGES],
              value: item,
            })),
            onFilter: (value, record) => record.pageType === value,
          },
        ]}
      />
    </View>
  );
};
