import { MyModal } from '@wiloke/ui';
import { message } from 'antd';
import { selectSyncThemeByResultOfExtractThemeToFileForSync } from 'packages/SyncThemeByResultOfExtractThemeToFileForSync/selectors';
import { useSetModalSocketStucking } from 'packages/SyncThemeByResultOfExtractThemeToFileForSync/store/actions';
import { useSelector } from 'react-redux';
import { copyToClipboard } from 'utils/copyToClipboard';
import { View } from 'wiloke-react-core';

export const ModalNotifySocketStucking = () => {
  const { session, socket } = useSelector(selectSyncThemeByResultOfExtractThemeToFileForSync);
  const { actualProcessed } = session;
  const { isStucking } = socket;

  const setModalSocketStucking = useSetModalSocketStucking();

  return (
    <MyModal
      isVisible={isStucking}
      onCancel={() => setModalSocketStucking(false)}
      onOk={() => {
        setModalSocketStucking(false);
        copyToClipboard(
          '"Socket bị treo"\n' +
            ' - Tiến trình đã hoàn thành:\n' +
            actualProcessed
              .slice(0, -1)
              .map(item => `   + ${item}`)
              .join('\n') +
            '\n' +
            ' - Tiến trình bị treo:\n' +
            '   + ' +
            actualProcessed[actualProcessed.length - 1],
        );
        message.success('Đã copy');
      }}
      headerText="Có lẽ quá trình đồng bộ đã bị treo"
      cancelText="Đóng"
      okText="Copy lời nhắn và Đóng"
    >
      <View>
        <View>
          Quá trình đồng bộ đã không trả lời trong một thời gian dài.{' '}
          <View color="danger" tagName="span">
            Có lẽ nó đã bị treo
          </View>
        </View>
        <View css={{ fontSize: '16px', fontWeight: 500 }}>
          Bạn có thể huỷ bỏ quá trình đồng bộ và báo bug bằng cách gửi file vừa upload vào nhóm kèm lời nhắn{' '}
          <View color="primary" tagName="span">
            "Socket bị treo"
          </View>
        </View>
      </View>
    </MyModal>
  );
};
