import { CSSPropKey } from 'containers/BuilderPage/components/StyleBox/types';

export const cssPropKeys: CSSPropKey[] = [
  'color',
  'font-size',
  'font-family',
  'font-style',
  'font-weight',
  'line-height',
  'letter-spacing',
  'text-align',
  'text-decoration',
  'text-transform',
  'backgroundColor',
  'background',
  'width',
  'height',
  'padding',
  'margin',
  'border-width',
  'border-style',
  'border-color',
  'border-radius',
  'box-shadow',
  'transition-duration',
  'transition-timing-function',
  'transition-delay',
  'animation',
];
