import { restoreThemeToDefault } from 'services/ShopifyConnection/services/restoreThemeToDefault';
import { handleDeletePageInDashboard } from './handleDeletePageInDashboard';
import { handlePreviewInBuilderPage } from './handlePreviewInBuilderPage';
import { handleSaveInBuilderPage } from './handleSaveInBuilderPage';
import { handleExtractResultForForceSync } from '../../packages/ExtractThemeToFileForSync/utils/handleExtractResultForForceSync';
import { handleUpdatePageSettingInDashboard } from './handleUpdatePageSettingInDashboard';
import { handleUpdateShopifyPagesInDashboard } from './handleUpdateShopifyPagesInDashboard';
import { handleUpdateStatusPage } from './handleUpdateStatusPage';
import { handleUpdateThemeSettingsInDashboard } from './handleUpdateThemeSettingsInDashboard';
import { changeThemeShopifyActivate } from './services/changeThemeShopifyActivate';
import { cleanAfterSync } from './services/cleanAfterSync';
import { deleteAddonsInShopify, DeleteAddonsInShopify_BEExpectParameters } from './services/deleteAddonsInShopify';
import { deletePageInShopify, DeletePageInShopify_BEExpectParameters } from './services/deletePageInShopify';
import { deleteThemeRecord } from './services/deleteThemeRecord';
import { getAdditionalDataRelateToShopify, GetAdditionalDataRelateToShopify_BEExpectResponse } from './services/getAdditionalDataRelateToShopify';
import { getThemeAppStatus } from './services/getThemeAppStatus';
import { getThemeRecords } from './services/getThemeRecords';
import { getThemesShopify } from './services/getThemesShopify';
import { graphqlForMetafields } from './services/graphqlForMetafields';
import { migrateThemeShopify } from './services/migrateThemeShopify';
import { importCSVFile } from './services/importCSVFile';
import { updateAddonsDisablePositionToShopify } from './services/updateAddonsDisablePositionToShopify';
import { updatePageStatus } from './services/updatePageStatus';
import { writeAddonToShopify, WriteAddonToShopify_BEExpectParameters } from './services/writeAddonToShopify';
import { writeFooterToShopify, WriteFooterToShopify_BEExpectParameters } from './services/writeFooterToShopify';
import { writeGlobalOfPageToShopify, WriteGlobalOfPageToShopify_BEExpectParameters } from './services/writeGlobalOfPageToShopify';
import { writeGlobalOfThemeToShopify, WriteGlobalOfThemeToShopify_BEExpectParameters } from './services/writeGlobalOfThemeToShopify';
import { writeHeaderToShopify, WriteHeaderToShopify_BEExpectParameters } from './services/writeHeaderToShopify';
import { writePageToShopify, WritePageToShopify_BEExpectParameters } from './services/writePageToShopify';
import { writeTranslation, WriteTranslation_BEExpectParameters } from './services/writeTranslationToShopify';
import { writeThemeSchemaNSettings, WriteThemeSchemaNSettings_BEExpectParameters } from './services/writeThemeSchemaNSettings';
import { restoreThemeLayoutAndDeleteVedaHeaderFooter } from './services/restoreThemeLayoutAndDeleteVedaHeaderFooter';
import { publishHeaderFooterVeda } from './services/publishHeaderFooterVeda';

const shopifyConnectionService = {
  writePageToShopify,
  deletePageInShopify,
  writeGlobalOfThemeToShopify,
  writeGlobalOfPageToShopify,
  writeHeaderToShopify,
  writeFooterToShopify,
  writeAddonToShopify,
  deleteAddonsInShopify,
  writeTranslation,
  writeThemeSchemaNSettings,
  getAdditionalDataRelateToShopify,
  getThemesShopify,
  migrateThemeShopify,
  cleanAfterSync,
  changeThemeShopifyActivate,
  updatePageStatus,
  updateAddonsDisablePositionToShopify,
  graphqlForMetafields,
  getThemeRecords,
  deleteThemeRecord,
  getThemeAppStatus,
  restoreThemeToDefault,
  importCSVFile,
  restoreThemeLayoutAndDeleteVedaHeaderFooter,
  publishHeaderFooterVeda
};

export {
  shopifyConnectionService,
  handleSaveInBuilderPage,
  handleExtractResultForForceSync,
  handleUpdatePageSettingInDashboard,
  handleUpdateShopifyPagesInDashboard,
  handleUpdateStatusPage,
  handleUpdateThemeSettingsInDashboard,
  handleDeletePageInDashboard,
  writeTranslation,
  handlePreviewInBuilderPage
};
// eslint-disable-next-line prettier/prettier
export type {
  WritePageToShopify_BEExpectParameters,
  DeletePageInShopify_BEExpectParameters,
  GetAdditionalDataRelateToShopify_BEExpectResponse,
  WriteGlobalOfThemeToShopify_BEExpectParameters,
  WriteGlobalOfPageToShopify_BEExpectParameters,
  WriteHeaderToShopify_BEExpectParameters,
  WriteFooterToShopify_BEExpectParameters,
  WriteAddonToShopify_BEExpectParameters,
  WriteThemeSchemaNSettings_BEExpectParameters,
  DeleteAddonsInShopify_BEExpectParameters,
  WriteTranslation_BEExpectParameters as SyncTranslation_BEExpectParameters
};


