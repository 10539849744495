import { useIframeSelector } from 'containers/IframePage/hooks/useIframeSelector';
import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { mainSectionsSelector, headerSectionsSelector, footerSectionsSelector } from 'store/selectors';

import getPageInfo from 'utils/functions/getInfo';

export const useDisableUpDownSection = (sectionId: string) => {
  const main = useIframeSelector(mainSectionsSelector);
  const header = useIframeSelector(headerSectionsSelector);
  const footer = useIframeSelector(footerSectionsSelector);

  const entityVariant = getPageInfo('entityVariant');

  const [disabledPrev, setDisabledPrev] = useState(false);
  const [disabledNext, setDisabledNext] = useState(false);

  useDeepCompareEffect(() => {
    const lastFooter = footer.at(-1);
    const firstFooter = footer.at(0);

    const firstHeader = header.at(0);
    const lastHeader = header.at(-1);

    const lastMain = main.at(-1);
    const firstMain = main.at(0);

    // footer
    if (footer.length === 1 && firstFooter && firstFooter.id === sectionId) {
      setDisabledPrev(true);
      setDisabledNext(true);
    } else if (lastFooter && lastFooter.id === sectionId) {
      setDisabledNext(true);
      setDisabledPrev(false);
    } else if (firstFooter && firstFooter.id === sectionId) {
      setDisabledNext(false);
      setDisabledPrev(true);
    }
    // header
    else if (header.length === 1 && firstHeader && firstHeader.id === sectionId) {
      setDisabledPrev(true);
      setDisabledNext(true);
    } else if (firstHeader && firstHeader.id === sectionId) {
      setDisabledPrev(true);
      setDisabledNext(false);
    } else if (lastHeader && lastHeader.id === sectionId) {
      setDisabledPrev(false);
      setDisabledNext(true);
    }
    // main
    else if (main.length === 1) {
      setDisabledPrev(true);
      setDisabledNext(true);
    } else if (lastMain && lastMain.id === sectionId) {
      setDisabledNext(true);
      setDisabledPrev(false);
    } else if (firstMain && firstMain.id === sectionId) {
      setDisabledPrev(true);
      setDisabledNext(false);
    }
    // default
    else {
      setDisabledNext(false);
      setDisabledPrev(false);
    }
  }, [[entityVariant], [sectionId], main, header, footer]);

  return {
    disabledPrev,
    disabledNext,
  };
};
