// Import the functions you need from the SDKs you need
import firebase from 'firebase/app';
import 'firebase/firestore';

export interface FirebaseTrackingRecord {
  atomCommandId: string;
  createdAt: firebase.firestore.Timestamp;
  latestUpdatedAt: firebase.firestore.Timestamp;
  toggleInActiveAt: firebase.firestore.Timestamp | null;
  type: 'section' | 'addon' | 'mega_menu';
  status: 'ACTIVE' | 'INACTIVE';
}

const firebaseConfig = {
  apiKey: 'AIzaSyB5RpAPfRiw9ICKLcaSaVdi4G7S7NC7xRc',
  authDomain: 'listing-updatable-for-veda.firebaseapp.com',
  projectId: 'listing-updatable-for-veda',
  storageBucket: 'listing-updatable-for-veda.appspot.com',
  messagingSenderId: '805814550018',
  appId: '1:805814550018:web:16878b24d19f4ceaa3311b',
  measurementId: 'G-4YKVCST40Q',
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();

const CollectionName = 'records';

export const createRecord = async (record: Pick<FirebaseTrackingRecord, 'type' | 'atomCommandId'>) => {
  try {
    // Add the record to the specified collection
    const documentRef = firestore.collection(CollectionName).doc(record.atomCommandId);
    const snapshot = await documentRef.get();
    const data: FirebaseTrackingRecord | undefined = snapshot.data() as FirebaseTrackingRecord | undefined;

    const newRecord: FirebaseTrackingRecord = {
      type: record.type,
      atomCommandId: record.atomCommandId,
      createdAt: data?.createdAt ?? (firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp),
      latestUpdatedAt: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      toggleInActiveAt: null,
      status: 'ACTIVE',
    };

    await documentRef.set(newRecord, { merge: true });

    console.log('Record created successfully!');
    return documentRef.id;
  } catch (error) {
    console.error('Error creating record:', error);
    throw error;
  }
};

export const deleteRecord = async (atomCommandId: FirebaseTrackingRecord['atomCommandId']) => {
  try {
    const documentRef = firestore.collection(CollectionName).doc(atomCommandId);
    const snapshot = await documentRef.get();
    const data: FirebaseTrackingRecord | undefined = snapshot.data() as FirebaseTrackingRecord | undefined;

    if (data) {
      const newRecord: FirebaseTrackingRecord = {
        ...data,
        toggleInActiveAt: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
        status: 'INACTIVE',
      };
      await documentRef.set(newRecord, { merge: true });
    }

    console.log('Record deleted successfully!');
  } catch (error) {
    console.error('Error deleting record:', error);
    throw error;
  }
};

export const getRecords = async () => {
  try {
    // Retrieve all documents from the specified collection
    const snapshot = await firestore
      .collection(CollectionName)
      .where('status', '==', 'ACTIVE')
      .where('type', '==', 'section')
      .orderBy('createdAt', 'desc')
      .get();

    const records: FirebaseTrackingRecord[] = [];
    snapshot.forEach(doc => {
      const record = doc.data() as Omit<FirebaseTrackingRecord, 'atomCommandId'>;
      records.push({ ...record, atomCommandId: doc.id });
    });

    console.log('Records:', records);
    return records;
  } catch (error) {
    console.error('Error reading records:', error);
    throw error;
  }
};
