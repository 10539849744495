import { Carousel } from 'components/Carousel/Carousel';
import { MessageMaintain } from 'components/MessageMaintain';
import { useSelector } from 'react-redux';
import { authSelector } from 'store/selectors';
import { View } from 'wiloke-react-core';

export default function AnnouncementBar() {
  const { siteStatus } = useSelector(authSelector);

  return (
    <Carousel.Style2
      hideArrow
      interval={5000}
      data={siteStatus}
      renderItem={(item, index) => (
        <View key={index} css={{ height: '100%' }}>
          <MessageMaintain contentHtml={item.description} heading={item.heading} timeEnd={item.happenAt} />
        </View>
      )}
    />
  );
}
