import { PageSettings } from 'types/Result';
import { renewVersion } from 'utils/CacheControl/VedaClientCacheControl';
import { isThemeBuilder } from 'utils/validateBuilderMode';
import { pageApis } from '../apis';

interface UpdatePageSettingsClient {
  commandId: string;
  pageSettings: PageSettings;
}

export const updatePageSettingsClient = async ({ commandId, pageSettings }: UpdatePageSettingsClient) => {
  const isTheme = isThemeBuilder();

  const response = await pageApis.vedaApplication.userApi.page.updateClient({
    page: {
      commandId,
      pageSettings,
    },
    group: isTheme ? 'THEME' : 'GENERAL',
  });
  renewVersion();
  return response;
};
