import strftime from 'strftime';
import { getLocale, i18n } from 'translation';
import { getBuilderPageReduxStore } from 'utils/getParentStore';
import { LiquidSyntaxToTwigError } from '../Error';
import { liquidFilterParamsToTwigFilterParams } from '../utils/liquidFilterParamsToTwigFilterParams';
import { path } from 'ramda';

const isValidDate = function(date: any) {
  if (date === 'now') {
    return true;
  }
  return new Date(date).toString() !== 'Invalid Date' && !isNaN(new Date(date).getTime());
};

const DEFAULT_FORMAT = '%A, %B %e, %Y at %l:%M %P %z';

const LOCALE_AWARE_FORMATS = {
  abbreviated_date: '%b %e,%Y',
  basic: '%e/%m/%Y',
  date: '%B %e,%Y',
  date_at_time: '%B %e,%Y at %l:%M at %p',
  default: '%A,%B %e,%Y at %l:%M %p %z',
  on_date: 'on %b %e,%Y',
};

window.Twig.extendFilter('time_tag', (inputValue, args) => {
  if (!inputValue) {
    return '0';
  }
  if (!Array.isArray(args)) {
    throw new LiquidSyntaxToTwigError(i18n.t('twig_error.filters.time_tag.params', { error_signal: (args as unknown) as string }));
  }
  const formatDatetimeInHtmlTextContent = args[0] && typeof args[0] === 'string' ? args[0] : undefined;
  const inputValue_ = inputValue === 'now' ? new Date() : new Date(inputValue);
  const _argsInput = formatDatetimeInHtmlTextContent === undefined ? [...args] : [...args.slice(1)];
  try {
    const $timeEl = document.createElement('time');
    $timeEl.setAttribute('datetime', inputValue);
    $timeEl.innerHTML = strftime(formatDatetimeInHtmlTextContent ?? DEFAULT_FORMAT, inputValue_);
    while (_argsInput.length) {
      const name = _argsInput.shift()?.key;
      const dateFormat = _argsInput.shift();
      if (name && dateFormat) {
        const locale = getLocale();
        const state = getBuilderPageReduxStore().getState();
        const data = state.liquidVariables.data.translations;
        const t: string | undefined = path(['date_formats', dateFormat], data[locale]) ?? path([dateFormat], LOCALE_AWARE_FORMATS);
        const timeFormated = strftime(dateFormat, inputValue_);
        const finalTimeFormated = isValidDate(timeFormated)
          ? timeFormated
          : t
          ? strftime(t, inputValue_)
          : i18n.t('twig_error.filters.time_tag.custom_format_not_exist', { error_signal: dateFormat });
        if (name === 'format') {
          $timeEl.innerHTML = finalTimeFormated;
        }
        if (name === 'datetime') {
          $timeEl.setAttribute('datetime', finalTimeFormated);
        }
      }
    }
    return $timeEl.outerHTML;
  } catch (error) {
    const error_ = error as Error;
    throw new LiquidSyntaxToTwigError(i18n.t('twig_error.filters.time_tag.example', { message: error_.message }));
  }
});

/**
 * @link https://shopify.dev/api/liquid/filters/html-filters#time_tag
 */
export const time_tag = (liquid: string) =>
  liquidFilterParamsToTwigFilterParams({ liquid, liquidFilterName: 'time_tag', twigFilterName: 'time_tag' });
