import { ThemeInteracting } from 'packages/ExtractThemeToFileForSync/@types/ThemeInteracting';
import { getThemeAtom } from 'services/ThemeService/Logic/getThemeAtom';
import { getThemeDraft } from 'services/ThemeService/Logic/getThemeDraft';
import { BaseParams } from './@types/BaseParams';
import { getThemeCacheKey } from './utils/CacheKeyControl';
import { pushToCache, readFromCache } from './utils/CacheStorage';

const getTheme_ = async ({ commandId, entityVariant }: GetTheme) => {
  const getThemeWithEntityVariant = entityVariant === 'Atom' ? getThemeAtom : getThemeDraft;
  const theme = await getThemeWithEntityVariant({ commandId });
  const themeKey = await pushToCache(JSON.stringify(theme), getThemeCacheKey(commandId));
  return {
    themeKey,
    addonCommandIds: theme.addonCommandIds,
    headerSectionCommandIds: theme.headerSectionCommandIds,
    footerSectionCommandIds: theme.footerSectionCommandIds,
    pageCommandIds: theme.pageCommandIds,
  };
};

interface GetTheme extends BaseParams {
  commandId: string;
}

export const getTheme = async ({ commandId, isRetry, ...params }: GetTheme) => {
  if (isRetry) {
    const cacheKey = getThemeCacheKey(commandId);
    const dataFromCache = await readFromCache<ThemeInteracting['theme']>(cacheKey);
    if (dataFromCache) {
      return {
        themeKey: cacheKey,
        addonCommandIds: dataFromCache.addonCommandIds,
        headerSectionCommandIds: dataFromCache.headerSectionCommandIds,
        footerSectionCommandIds: dataFromCache.footerSectionCommandIds,
        pageCommandIds: dataFromCache.pageCommandIds,
      };
    }
    return getTheme_({ commandId, isRetry, ...params });
  }
  return getTheme_({ commandId, isRetry, ...params });
};
