import { CssColorVariable } from 'types/PresetStyles';
import { v4 } from 'uuid';

export const VISOR: Array<{ name: string; cssClassName: string; colors: CssColorVariable[] }> = [
  {
    name: 'Visor',
    cssClassName: 'veda-color-visor',
    colors: [
      { id: v4(), name: '--color-primary', light: '#87BD8F', dark: '#87BD8F' },
      { id: v4(), name: '--color-secondary', light: '#F07171', dark: '#F07171' },
      { id: v4(), name: '--color-tertiary', light: '#5e8c65', dark: '#5e8c65' },
      { id: v4(), name: '--color-quaternary', light: '#FBC473', dark: '#FBC473' },
      { id: v4(), name: '--color-dark', light: '#19271b', dark: '#ffffff' },
      { id: v4(), name: '--color-gray9', light: '#203122', dark: '#EEF8EF' },
      { id: v4(), name: '--color-gray8', light: '#394B3B', dark: '#DEEEE0' },
      { id: v4(), name: '--color-gray7', light: '#4C644D', dark: '#D0E2D2' },
      { id: v4(), name: '--color-gray6', light: '#657C67', dark: '#BBCFBD' },
      { id: v4(), name: '--color-gray5', light: '#94AB95', dark: '#94AB95' },
      { id: v4(), name: '--color-gray4', light: '#BBCFBD', dark: '#657C67' },
      { id: v4(), name: '--color-gray3', light: '#D0E2D2', dark: '#4C644D' },
      { id: v4(), name: '--color-gray2', light: '#DEEEE0', dark: '#394B3B' },
      { id: v4(), name: '--color-gray1', light: '#EEF8EF', dark: '#203122' },
      { id: v4(), name: '--color-light', light: '#ffffff', dark: '#19271b' },
    ],
  },
  {
    name: 'Visor (Dark)',
    cssClassName: 'veda-color-dark-visor',
    colors: [
      { id: v4(), name: '--color-primary', light: '#87BD8F', dark: '#87BD8F' },
      { id: v4(), name: '--color-secondary', light: '#F07171', dark: '#F07171' },
      { id: v4(), name: '--color-tertiary', light: '#5e8c65', dark: '#5e8c65' },
      { id: v4(), name: '--color-quaternary', light: '#FBC473', dark: '#FBC473' },
      { id: v4(), name: '--color-dark', light: '#ffffff', dark: '#19271b' },
      { id: v4(), name: '--color-gray9', light: '#EEF8EF', dark: '#203122' },
      { id: v4(), name: '--color-gray8', light: '#DEEEE0', dark: '#394B3B' },
      { id: v4(), name: '--color-gray7', light: '#D0E2D2', dark: '#4C644D' },
      { id: v4(), name: '--color-gray6', light: '#BBCFBD', dark: '#657C67' },
      { id: v4(), name: '--color-gray5', light: '#94AB95', dark: '#94AB95' },
      { id: v4(), name: '--color-gray4', light: '#657C67', dark: '#BBCFBD' },
      { id: v4(), name: '--color-gray3', light: '#4C644D', dark: '#D0E2D2' },
      { id: v4(), name: '--color-gray2', light: '#394B3B', dark: '#DEEEE0' },
      { id: v4(), name: '--color-gray1', light: '#203122', dark: '#EEF8EF' },
      { id: v4(), name: '--color-light', light: '#19271b', dark: '#ffffff' },
    ],
  },
];
