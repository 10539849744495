import { CssPropertiesField } from 'components/CssPropertiesField/CssPropertiesField';
import Field from 'components/Field';
import { FC } from 'react';
import { SettingStyleBox } from 'types/Schema';
import { FormSchemaSettingProps } from '../../type';

/**
 * Field này dùng cho setting styleBox
 */
// todo: i18n
export const IgnoreCssField: FC<FormSchemaSettingProps> = ({ data, onChange }) => {
  const _data = data as SettingStyleBox;
  return (
    <Field label={'Ignore Properties'}>
      <CssPropertiesField
        value={_data.ignore}
        onChange={val => {
          onChange?.({
            // @ts-ignore
            ignore: val,
          });
        }}
      />
    </Field>
  );
};
