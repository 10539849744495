import { animates } from 'containers/BuilderPage/components/StyleBox/data';
import { mergeDeepLeft, sort } from 'ramda';
import { Locales } from 'services/ShopifyConnection/utils/VedaOutputToShopifyThemeEditor/@types/Veda/ShopifyLocales';
import { replaceExactVariableNameInLiquidCode } from 'services/ShopifyConnection/utils/VedaOutputToShopifyThemeEditor/@utils/replaceExactVariableNameInLiquidCode';
import { SelectField } from '../../../@types/Shopify/InputSettings/SelectField';
import { ignoreToReduceTotalLinesOfCode } from '../../../@utils/ignoreToReduceTotalLinesOfCode';
import { sortWithKeyOfFieldValues } from '../../../@utils/sortWithKeyOfFieldValues';
import { keys } from './@consts/keys';
import { ILIQUID_SettingAnimateInOut } from './@types/ILIQUID_SettingAnimateInOut';
import { ISCHEMA_SettingAnimateInOut } from './@types/ISCHEMA_SettingAnimateInOut';
import { ISETTING_SettingAnimateInOut } from './@types/ISETTING_SettingAnimateInOut';
import { getSignalReduceTotalLinesOfCode } from './utils/getSignalReduceTotalLinesOfCode';
import { toShopifyFieldId } from './utils/toShopifyFieldId';
import { vedaLabelsOfAnimateInOutFieldToShopifyFieldLabel } from './utils/vedaLabelsOfAnimateInOutFieldToShopifyFieldLabel';
import { vedaSummariesOfAnimateInOutFieldToShopifyFieldInfo } from './utils/vedaSummariesOfAnimateInOutFieldToShopifyFieldInfo';

interface RTSettingAnimateInOut {
  shopifyField: SelectField[];
  locales: Locales;
}
export const SCHEMA_SettingAnimateInOut = ({ field, parentField, ...params }: ISCHEMA_SettingAnimateInOut): RTSettingAnimateInOut => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode ||
    (params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField }));
  let RLocales: Locales = {
    en: {},
    fr: {},
    vi: {},
  };
  const keysSorted = sort(sortWithKeyOfFieldValues, keys);
  const data = keysSorted.reduce<SelectField[]>((res, key) => {
    const { newLabelOfKey, locales: localesOfLabel } = vedaLabelsOfAnimateInOutFieldToShopifyFieldLabel(
      { ...params, field, parentField, isNeedReduceTotalLinesOfCode },
      key,
    );
    const { newInfoOfKey, locales: localesOfInfo } = vedaSummariesOfAnimateInOutFieldToShopifyFieldInfo({
      ...params,
      field,
      parentField,
      isNeedReduceTotalLinesOfCode,
    });
    RLocales = mergeDeepLeft(RLocales, localesOfLabel);
    RLocales = mergeDeepLeft(RLocales, localesOfInfo);
    const value = field.children[key];
    const valueString = typeof value === 'string' ? value : undefined;
    return res.concat({
      label: newLabelOfKey,
      info: newInfoOfKey,
      id: isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, key)
        : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, key),
      type: 'select',
      default: valueString,
      options: animates.map(({ label, value }) => ({ label: label, value: `animate__${value}` })),
    });
  }, []);

  return {
    locales: RLocales,
    shopifyField: data as RTSettingAnimateInOut['shopifyField'],
  };
};

export const SETTING_SettingAnimateInOut = ({ field, parentField, ...params }: ISETTING_SettingAnimateInOut) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode ||
    (params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField }));
  return keys.reduce<Record<string, string | undefined>>((res, key) => {
    // check kĩ phòng lỗi
    const value = field.children[key];
    const valueString = typeof value === 'string' ? value : undefined;
    if (valueString !== undefined || isNeedReduceTotalLinesOfCode) {
      const fieldName = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, key)
        : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, key);
      return {
        ...res,
        [fieldName]: valueString,
      };
    }
    return res;
  }, {});
};

export const LIQUID_SettingAnimateInOut = ({ liquid, field, loopVariable, parentField, ...params }: ILIQUID_SettingAnimateInOut) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode ||
    (params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField }));
  // Nếu field thuộc array
  // - loopVariable nếu là array ->  Array được dùng trong forloop -> Thế `${loopVariable}.${field.name}.${key}` = `${loopVariable}.${toShopifyFieldId({ field, parentField }, key)}`
  //   Example: --> for item in content --> item.icon_design.id ==> KQ: item.content__icon_design.id
  if (loopVariable) {
    return keys.reduce<string>((res, key) => {
      const target = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, key)
        : `${loopVariable}.${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, key)}`;
      const source = `${loopVariable}.${field.name}.${key}`;
      return replaceExactVariableNameInLiquidCode({ liquid: res, source, target });
    }, liquid);
  }
  // Nếu field thuộc object
  // - parentField nếu là object -> Object được flat thành các "shopify input settings" -> Thế `${parentField.name}.${field.name}.${key}` = toShopifyFieldId
  //   Example: --> heading.icon_design.id ==> KQ: heading__icon_design.id
  else if (parentField) {
    return keys.reduce<string>((res, key) => {
      const target = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, key)
        : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, key)}"]`;
      const source = `${parentField.name}.${field.name}.${key}`;
      return replaceExactVariableNameInLiquidCode({ liquid: res, source, target });
    }, liquid);
  } else {
    return keys.reduce<string>((res, key) => {
      const target = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, key)
        : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, key)}"]`;
      const source = `${field.name}.${key}`;
      return replaceExactVariableNameInLiquidCode({ liquid: res, source, target });
    }, liquid);
  }
};
