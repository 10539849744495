import { handleWaitForSocketOfSyncShopifyFulfill } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { call, retry, SagaReturnType } from 'redux-saga/effects';
import { handleUpdatePageSettingInDashboard, shopifyConnectionService } from 'services/ShopifyConnection';

export interface SyncGlobalOfPageInDashboard {
  globalPageSettingsParams: Awaited<ReturnType<typeof handleUpdatePageSettingInDashboard>>;
}

export interface SyncGlobalOfPageInDashboardResult {
  statusSyncGlobalOfPageInDashboard: SyncFulfillStatus;
  isNeedIgnoreReportError: boolean;
}

export function* syncGlobalOfPageInDashboard({ globalPageSettingsParams }: SyncGlobalOfPageInDashboard) {
  yield retry(3, 1000, shopifyConnectionService.writeGlobalOfPageToShopify, { ...globalPageSettingsParams, isPreview: false });
  const socketSyncGlobalOfPageInDashboard: SagaReturnType<typeof handleWaitForSocketOfSyncShopifyFulfill> = yield call(
    handleWaitForSocketOfSyncShopifyFulfill,
    'Ghi file khi update page settings ngoài dashboard',
  );
  return {
    statusSyncGlobalOfPageInDashboard: socketSyncGlobalOfPageInDashboard.statusSync,
    isNeedIgnoreReportError: socketSyncGlobalOfPageInDashboard.isNeedIgnoreReportError,
  } as SyncGlobalOfPageInDashboardResult;
}
