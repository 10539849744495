import { PageType } from 'types/Page';
import { PageSectionType } from 'types/Sections';

export const LABEL_OF_SECTIONS: Record<PageSectionType, string> = {
  default: 'Section',
  footer: 'Footer',
  header: 'Header',
  megamenu: 'Mega menu',
  'built-in-add-ons': 'Build in addon',
  'embedded-add-ons': 'Embeded addon',
  'third-party-add-ons': '3rd party addon',
};

export const LABEL_OF_PAGES: Record<PageType, string> = {
  account: 'Account',
  activateAccount: 'Activate Account',
  addresses: 'Addresses',
  article: 'Article',
  blog: 'Blog',
  cart: 'Cart',
  collection: 'Collection',
  collections: 'Collections',
  giftCard: 'Gift Card',
  home: 'Home',
  login: 'Login',
  order: 'Order',
  page: 'Page',
  pageNotFound: '404',
  password: 'Password',
  product: 'Product',
  register: 'Register',
  resetPassword: 'Reset Password',
  search: 'Search',
};
