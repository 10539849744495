import { PageType } from 'types/Page';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';

interface RequestGetFirstParams {
  pageType?: PageType;
  label?: string;
  categoryId?: string;
  saved?: boolean;
  size: number;
  isStickyOnly?: boolean;
  planFilter?: string;
  useCases?: string[];
}

export const getPagesPublish = ({ pageType, label, categoryId, saved, size, isStickyOnly, planFilter, useCases }: RequestGetFirstParams) => {
  const { lastCache } = getUserInfo();
  return pageApis.vedaApplication.userApi.page.getProducts({
    type: 'GET FIRST PAGE',
    categoryId,
    pageType,
    label,
    saved,
    cacheVersion: lastCache,
    size,
    isStickyOnly,
    planFilter,
    useCases,
  });
};

export const loadMorePagesPublish = ({
  size,
  categoryId,
  isStickyOnly,
  label,
  pageType,
  planFilter,
  saved,
  cursor,
  useCases,
}: RequestGetFirstParams & { cursor: string }) => {
  const { lastCache } = getUserInfo();
  return pageApis.vedaApplication.userApi.page.getProducts({
    type: 'LOADMORE',
    cursor,
    categoryId,
    pageType,
    label,
    saved,
    size,
    isStickyOnly,
    planFilter,
    cacheVersion: lastCache,
    useCases,
  });
};
