// Các code error
export const MODAL_REPORT_AFTER_SYNC_ERROR = 'SYNC_SHOPIFY';
export const MODAL_REPORT_AFTER_IMPORT_THEME_ERROR = 'IMPORT_THEME';
export const MODAL_REPORT_INITIALIZATION_SESSION_BUILDER_ERROR = 'INITIALIZATION_SESSION_BUILDER_ERROR';
export const MODAL_REPORT_SAVE_ERROR = 'SAVE_ERROR';
export const MODAL_REPORT_SAVE_PAGE_SETITNGS_IN_DASHBOARD_ERROR = 'SAVE_PAGE_SETITNGS_IN_DASHBOARD_ERROR';
export const MODAL_REPORT_SAVE_THEME_SETITNGS_IN_DASHBOARD_ERROR = 'SAVE_THEME_SETITNGS_IN_DASHBOARD_ERROR';
export const MODAL_REPORT_DELETE_PAGE_ERROR = 'DELETE_PAGE_ERROR';
export const MODAL_REPORT_UPDATE_STATUS_PAGE_ERROR = 'UPDATE_STATUS_PAGE_ERROR';
export const MODAL_REPORT_ACTIVE_THEME_VEDA_ERROR = 'ACTIVE_THEME_VEDA_ERROR';

// Một số lỗi khác
export const MODAL_REPORT_CANT_ACCESS_VEDA = 'ACCESS_APP_ERROR';
export const MODAL_REPORT_UNKNOWN = 'UNKNOWN';
