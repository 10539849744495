import { createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';

type VisibleTour = 'on' | 'off' | 'close';

type Actions =
  | {
      type: 'setVisibleTourPage';
      payload: VisibleTour;
    }
  | {
      type: 'setVisibleTourTheme';
      payload: VisibleTour;
    }
  | {
      type: 'setVisibleTourAddon';
      payload: VisibleTour;
    };

interface State {
  visibleTourPage: VisibleTour;
  visibleTourTheme: VisibleTour;
  visibleTourAddon: VisibleTour;
}

export const sliceVisibleTour = createSlice<State, Actions>({
  name: '@App',
  initialState: {
    visibleTourAddon: 'close',
    visibleTourPage: 'close',
    visibleTourTheme: 'close',
  },
  reducers: [
    handleAction('setVisibleTourAddon', ({ state, action }) => {
      state.visibleTourAddon = action.payload;
    }),
    handleAction('setVisibleTourTheme', ({ state, action }) => {
      state.visibleTourTheme = action.payload;
    }),
    handleAction('setVisibleTourPage', ({ state, action }) => {
      state.visibleTourPage = action.payload;
    }),
  ],
});

export const { setVisibleTourAddon, setVisibleTourPage, setVisibleTourTheme } = sliceVisibleTour.actions;

export const selectorVisibleTour = (state: AppState) => state.modalTour;

export const useSetVisibleTourAddon = createDispatchAction(setVisibleTourAddon);
export const useSetVisibleTourPage = createDispatchAction(setVisibleTourPage);
export const useSetVisibleTourTheme = createDispatchAction(setVisibleTourTheme);
