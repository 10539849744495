import { retry, put, takeLatest } from '@redux-saga/core/effects';
import { ServerTemplateResponse } from 'services/PagesBuilderService';
import { getPagesPublish } from 'services/PageService/Logic/getProductsPublish';
import { getActionType } from 'wiloke-react-core/utils';
import { actionGetTemplatesPopup } from '../actions';

const PAGE_SIZE = 20;

function* handleGetTemplateAdmin({ payload }: ReturnType<typeof actionGetTemplatesPopup.request>) {
  const { type, categoryId, search, planFilter, useCases } = payload;

  try {
    const response: Awaited<ReturnType<typeof getPagesPublish>> = yield retry(3, 1000, getPagesPublish, {
      pageType: type,
      size: PAGE_SIZE,
      categoryId,
      label: search,
      planFilter,
      useCases,
    });
    yield put(
      actionGetTemplatesPopup.success({
        data: response.info as ServerTemplateResponse[],
        hasNextPage: response.info.length >= PAGE_SIZE,
      }),
    );
  } catch (error) {
    yield put(actionGetTemplatesPopup.failure(undefined));
  }
}

export function* watchGetTemplatesInPopup() {
  yield takeLatest(getActionType(actionGetTemplatesPopup.request), handleGetTemplateAdmin);
}
