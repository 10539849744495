import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import fetchAPI from 'utils/functions/fetchAPI';

interface ImportThemeAtom {
  themeProductCommandId: string;
  eventType: string;
  eventId: string;
  shopifyThemeId: string;
}

interface ResponseSuccess {
  message: string;
}

export const importThemeAtomBuiltin = async ({ themeProductCommandId, eventId, eventType, shopifyThemeId }: ImportThemeAtom) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${configureApp.endpoint.clients}/me/builtin/themes/imports/${themeProductCommandId}`,
    method: 'PUT',
    params: {
      eventId,
      eventType,
      themeProductCommandId,
    },
    data: {
      shopifyThemeId,
    },
  });
  return response;
};

export const importThemeAtomEnvato = async ({ themeProductCommandId, eventId, eventType, shopifyThemeId }: ImportThemeAtom) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${configureApp.endpoint.clients}/me/envato/themes/imports/${themeProductCommandId}`,
    method: 'PUT',
    params: {
      eventId,
      eventType,
      themeProductCommandId,
    },
    data: {
      shopifyThemeId,
    },
  });
  return response;
};
