import { AxiosResponse } from 'axios';
import fetchAPI from 'utils/functions/fetchAPI';
import { clientBaseUrl } from '../const';

interface Params {
  commandId: string;
  disableExceptMe: boolean;
}

interface ResponseSuccess {}

export const disableProduct = async ({ commandId, disableExceptMe }: Params) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${clientBaseUrl}/${commandId}/switcher/${disableExceptMe ? 'disable-except-me' : 'disable'}`,
    method: 'PUT',
  });

  return response.data;
};
