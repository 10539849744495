import { PickerType } from '../types';

export function getLinkType(value: string): PickerType {
  const mailType = value.includes('mailto:');
  const phoneType = value.includes('tel:');

  if (mailType) {
    return 'email';
  }

  if (phoneType) {
    return 'phone';
  }

  if (value.includes('https') || value.includes('http')) {
    return 'custom';
  }

  if (value.includes('id_')) {
    return 'section';
  }

  if (value === 'veda-link-picker-buy-now') {
    return 'buy_now';
  }

  const shopifyType =
    value.includes('/pages') ||
    value.includes('/collections') ||
    value.includes('/products') ||
    value.includes('/articles') ||
    value.includes('/blogs');

  if (shopifyType) {
    return 'shopify';
  }

  return 'custom';
}

export function removeTel(value: string) {
  const reg = value.replace(/tel:/gm, '');
  return reg;
}
