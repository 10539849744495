import { AsyncComponent, Button, Confirm, MyModal } from '@wiloke/ui';
import { notification } from 'antd';
import Field from 'components/Field';
import FieldBox from 'components/FieldBox';
import { NumberInput } from 'components/NumberInput';
import SelectAntd from 'components/SelectAntd';
import { InputTags } from 'components/SelectTags';
import TextInput from 'components/TextInput';
import {
  useCreateProductCategory,
  useDeleteProductCategory,
  useGetTemplateCategories,
  useUpdateProductCategory,
} from 'containers/ChooseTemplate/store/actions';
import { useEditProductCategory } from 'containers/ChooseTemplate/store/reducers/sections/user.reducerCategory';
import withDebounce from 'hocs/withDebounce';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { chooseTemplateVisibleSelector, sectionsSelector } from 'store/selectors';
import { i18n } from 'translation';
import { GridSmart, View } from 'wiloke-react-core';

const DebounceInput = withDebounce(TextInput, 'value', 'onValueChange', 300);

const defaultState: {
  description: string;
  name: string;
  priorityOrder: number;
  searchTerms: string[];
  childrenCommandId: string[];
} = {
  description: '',
  name: '',
  priorityOrder: 0,
  searchTerms: [],
  childrenCommandId: [],
};

// todo: i18n
export const ProductCategoriesByTopic = () => {
  const { visible, navKeys } = useSelector(chooseTemplateVisibleSelector);
  const { getStatus, deleteStatus, updateStatus, createStatus } = useSelector(sectionsSelector.categories);
  const categories = useSelector(sectionsSelector.parentCategories);
  const childrenCategories = useSelector(sectionsSelector.childrenCategories);

  const getCategory = useGetTemplateCategories();
  const deleteCategory = useDeleteProductCategory();
  const updateCategory = useUpdateProductCategory();
  const editCategory = useEditProductCategory();
  const createProductCategory = useCreateProductCategory();

  const [search, setSearch] = useState<string | undefined>(undefined);
  const [createNewModal, setCreateNewModal] = useState(false);
  const [createInfo, setCreateInfo] = useState<typeof defaultState>(defaultState);

  useEffect(() => {
    if (visible && navKeys[0] === 'admin' && navKeys[1] === 'Product Categories by Topic') {
      getCategory.request({ search });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, navKeys, search]);

  const renderSuccess = () => {
    return (
      <GridSmart columnCount={3} columnWidth={100} columnGap={8}>
        {categories.map(item => (
          <FieldBox borderColor="gray3" key={item.commandId} css={{ padding: '10px 15px' }}>
            <Field label={i18n.t('general.title')}>
              <DebounceInput
                value={item.description}
                block
                sizeInput="medium"
                onValueChange={val => {
                  editCategory({
                    commandId: item.commandId,
                    description: val,
                  });
                }}
              />
            </Field>

            <Field label="Priority Order">
              <NumberInput
                block
                borderColor="gray3"
                radius={6}
                max={Infinity}
                value={item.priorityOrder}
                onValueChange={val => {
                  editCategory({
                    commandId: item.commandId,
                    priorityOrder: val,
                  });
                }}
              />
            </Field>

            <Field label="Search Terms">
              <InputTags
                values={item?.searchTerms?.join(',')}
                onChange={values => {
                  editCategory({
                    commandId: item.commandId,
                    searchTerms: values.split(','),
                  });
                }}
              />
            </Field>

            <Field label="Sub categories">
              <SelectAntd
                filterOption={(input, option) => {
                  return ((option?.children as unknown) as string).toLowerCase().includes(input.toLowerCase());
                }}
                showSearch
                mode="multiple"
                onChange={vals => {
                  editCategory({
                    commandId: item.commandId,
                    childrenCommandId: vals,
                  });
                }}
                value={item.childrenCommandId}
                data={childrenCategories.map(item => ({ label: item.name, value: item.commandId }))}
              />
            </Field>

            <View css={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                loading={updateStatus[item.commandId] === 'loading'}
                size="extra-small"
                css={{ marginRight: '8px' }}
                radius={4}
                onClick={() => {
                  updateCategory.request({
                    commandId: item.commandId,
                    name: item.name,
                    description: item.description,
                    priorityOrder: item.priorityOrder,
                    searchTerms: item.searchTerms,
                    childrenCommandId: item.childrenCommandId,
                  });
                }}
              >
                {i18n.t('general.update')}
              </Button>

              <Confirm
                isLoading={deleteStatus[item.commandId] === 'loading'}
                title="Chắc chắn chứ?"
                message={
                  <View>
                    Có thật là chắc chắn xoá{' '}
                    <View tagName="strong" color="primary">
                      {item.name}
                    </View>{' '}
                    chứ? Nếu xảy ra bug bạn sẽ phải chịu trừng phạt của pháp luật!
                  </View>
                }
                onOk={() => {
                  deleteCategory.request({
                    commandId: item.commandId,
                  });
                }}
                okText="Chắc chắn"
                cancelText="Nếu bạn sợ, thì cancel"
              >
                <Button backgroundColor="danger" size="extra-small" radius={4} disabled={!!item.count}>
                  {i18n.t('general.delete')}
                </Button>
              </Confirm>
            </View>
          </FieldBox>
        ))}
      </GridSmart>
    );
  };

  return (
    <View css={{ padding: '10px' }}>
      <View css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <View width={300} css={{ marginBottom: '10px' }}>
          <DebounceInput placeholder="Search" block value={search} onValueChange={setSearch} />
        </View>
        <View>
          <Button
            size="extra-small"
            radius={4}
            onClick={() => {
              setCreateNewModal(true);
            }}
          >
            Create new
          </Button>
        </View>
      </View>
      <AsyncComponent status={getStatus} isEmpty={categories.length === 0} Success={renderSuccess()} />

      <MyModal
        isVisible={createNewModal}
        onCancel={() => {
          setCreateNewModal(false);
          setCreateInfo(defaultState);
        }}
        isLoading={createStatus === 'loading'}
        headerText="Create parent category"
        onOk={() => {
          if (!createInfo.name) {
            notification.error({ message: 'Title is required!' });
          } else if (!createInfo.childrenCommandId) {
            notification.error({ message: 'Bố của product category phải có con' });
          } else {
            createProductCategory.request({
              name: createInfo.name,
              description: createInfo.name,
              priorityOrder: createInfo.priorityOrder,
              searchTerms: createInfo.searchTerms,
              childrenCommandId: createInfo.childrenCommandId,
              onFulfilled() {
                setCreateNewModal(false);
                setCreateInfo(defaultState);
              },
            });
          }
        }}
      >
        <FieldBox borderColor="gray3" css={{ padding: '10px 15px' }}>
          <Field label={i18n.t('general.title')}>
            <DebounceInput
              block
              sizeInput="medium"
              value={createInfo.name}
              onValueChange={val => {
                setCreateInfo(prev => ({ ...prev, name: val }));
              }}
            />
          </Field>

          <Field label="Priority Order">
            <NumberInput
              block
              borderColor="gray3"
              radius={6}
              max={Infinity}
              value={createInfo.priorityOrder}
              onValueChange={val => {
                setCreateInfo(prev => ({ ...prev, priorityOrder: val }));
              }}
            />
          </Field>

          <Field label="Search Terms">
            <InputTags
              values={createInfo.searchTerms?.join(',')}
              onChange={values => {
                setCreateInfo(prev => ({ ...prev, searchTerms: values.split(',') }));
              }}
            />
          </Field>

          <Field label="Sub categories">
            <SelectAntd
              filterOption={(input, option) => {
                return ((option?.children as unknown) as string).toLowerCase().includes(input.toLowerCase());
              }}
              showSearch
              mode="multiple"
              onChange={vals => {
                setCreateInfo(prev => ({ ...prev, childrenCommandId: vals }));
              }}
              data={childrenCategories.map(item => ({ label: item.name, value: item.commandId }))}
            />
          </Field>
        </FieldBox>
      </MyModal>
    </View>
  );
};
