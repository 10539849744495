import { MyModal } from '@wiloke/ui';
import { Table } from 'antd';
import { selectSyncThemeByResultOfExtractThemeToFileForSync } from '../../selectors';
import { useSetModalPreviewUrls } from '../../store/actions';
import { useSelector } from 'react-redux';
import { View } from 'wiloke-react-core';

export const ModalPreviewUrlsAfterSyncTheme = () => {
  const { modalPreviewUrls } = useSelector(selectSyncThemeByResultOfExtractThemeToFileForSync);
  const setModalPreviewUrls = useSetModalPreviewUrls();

  return (
    <MyModal
      isVisible={!!modalPreviewUrls?.length}
      headerText="Preview"
      okText="Đóng"
      cancelText=""
      disabledCloseButton
      size="large"
      onOk={() => {
        setModalPreviewUrls(undefined);
      }}
    >
      <View css={{ padding: '12px' }}>
        <View className="with-ant-table-scroll-shadows">
          <Table
            scroll={{ y: 600 }}
            pagination={false}
            dataSource={modalPreviewUrls ?? []}
            columns={[
              {
                title: 'Tên page',
                render: (_, row) => {
                  return <View>{row.pageName}</View>;
                },
              },
              {
                title: 'Preview url',
                render: (_, row) => {
                  return (
                    <View tagName="a" target="_blank" href={row.url}>
                      {row.url}
                    </View>
                  );
                },
              },
            ]}
          />
        </View>
      </View>
    </MyModal>
  );
};
