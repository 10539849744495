import { handleWaitForSocketOfSyncShopifyFulfill } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { call, retry, SagaReturnType } from 'redux-saga/effects';
import { shopifyConnectionService } from 'services/ShopifyConnection';
import { handleUpdateStatusPage } from 'services/ShopifyConnection/handleUpdateStatusPage';
import { getUserInfo } from 'utils/functions/getUserInfo';

export interface UpdateStatusPublish {
  updateStatusPublishParams: ReturnType<typeof handleUpdateStatusPage>;
}

export interface UpdateStatusPublishResult {
  statusSync: SyncFulfillStatus;
  isNeedIgnoreReportError: boolean;
}

export function* updateStatusPublish({ updateStatusPublishParams }: UpdateStatusPublish) {
  const { name } = getUserInfo();
  yield retry(3, 1000, shopifyConnectionService.writePageToShopify, {
    ...updateStatusPublishParams,
    isPreview: false,
    author: { name },
  });
  const socketSync: SagaReturnType<typeof handleWaitForSocketOfSyncShopifyFulfill> = yield call(
    handleWaitForSocketOfSyncShopifyFulfill,
    'Publish | Unpublish page ngoài dashboard',
  );
  return {
    statusSync: socketSync.statusSync,
    isNeedIgnoreReportError: socketSync.isNeedIgnoreReportError,
  } as UpdateStatusPublishResult;
}
