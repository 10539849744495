import { adapterCreateOrUpdateAddon } from 'services/AddonService/Adapters/adapterCreateOrUpdateAddon';
import { userApis } from 'services/UserService/apis';
import { AddonOfTheme_Atom_N_Client } from 'types/Addons';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';

interface UpdateSectionOfPageAtom {
  addon: AddonOfTheme_Atom_N_Client;
}

export const updateAddonOfPageAtom = async ({ addon }: UpdateSectionOfPageAtom) => {
  const { role } = getUserInfo();
  const addon_ = addon as Parameters<typeof pageApis.atom.adminApi.addon.updateAddonOfAtom>[0]['addon'];
  if (role === 'admin') {
    const response = await pageApis.atom.adminApi.addon.updateAddonOfAtom({ addon: addon_ });
    await userApis.user.adminApi.updateVedaCacheVersion();
    return adapterCreateOrUpdateAddon({ ...response.info, isAddonOfPage: addon.isAddonOfPage });
  }
  throw new RoleException();
};
