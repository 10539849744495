import axios, { AxiosResponse } from 'axios';
import { ThemeInteracting } from 'packages/ExtractThemeToFileForSync/@types/ThemeInteracting';
import { useState } from 'react';
import { BE_PageInThemeAtomNDraft } from 'services/ThemeService/Atom/types';
import { getPagesInfomationOfThemeAtom } from 'services/ThemeService/Logic/getPagesInfomationOfThemeAtom';
import { getPagesInfomationOfThemeDraft } from 'services/ThemeService/Logic/getPagesInfomationOfThemeDraft';
import { getSectionsInfomationOfThemeAtom } from 'services/ThemeService/Logic/getSectionsInfomationOfThemeAtom';
import { getSectionsInfomationOfThemeDraft } from 'services/ThemeService/Logic/getSectionsInfomationOfThemeDraft';
import { PageType } from 'types/Page';
import { PageSectionType } from 'types/Sections';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';

export interface SectionInfomation {
  commandId: string;
  label: string;
  type: PageSectionType;
  status: string;
  enable: boolean;
  createdDateGMT: string;
  modifiedDateGMT: string;
}

const axiosGetSectionsCancelToken = axios.CancelToken.source();
const axiosGetPagesCancelToken = axios.CancelToken.source();
export const useGetSections = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Array<SectionInfomation & { pageName: string | undefined; pageType: PageType | undefined }>>([]);
  const [isError, setIsError] = useState(false);

  const handleRequest = async ({
    themeCommandId,
    pageCommandIds,
    entityVariant,
  }: {
    themeCommandId: string;
    pageCommandIds: string[];
    entityVariant: ThemeInteracting['entityVariant'];
  }) => {
    axiosGetSectionsCancelToken.cancel();
    axiosGetPagesCancelToken.cancel();
    setIsLoading(true);
    setIsError(false);
    try {
      const getSectionsInfomationWithEntityVariant = entityVariant === 'Atom' ? getSectionsInfomationOfThemeAtom : getSectionsInfomationOfThemeDraft;
      const getPagesInfomationWithEntityVariant = entityVariant === 'Atom' ? getPagesInfomationOfThemeAtom : getPagesInfomationOfThemeDraft;
      const [getSectionsResponse, getPagesResponse]: [
        AxiosResponse<{ info: Array<SectionInfomation>; message: string }>,
        AxiosResponse<{ info: Array<BE_PageInThemeAtomNDraft>; message: string }>,
      ] = await Promise.all([
        getSectionsInfomationWithEntityVariant({ themeCommandId, cancelTokenSource: axiosGetSectionsCancelToken }),
        getPagesInfomationWithEntityVariant({ pageCommandIds, cancelTokenSource: axiosGetPagesCancelToken }),
      ]);
      setData(
        getSectionsResponse.data.info.map(section => {
          const pageBelong = getPagesResponse.data.info.find(page => page.sectionCommandIds.includes(section.commandId));
          return {
            ...section,
            pageName: pageBelong?.label,
            pageType: pageBelong?.type,
          };
        }),
      );
    } catch (error) {
      notifyAxiosHandler.handleError(error as any);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelRequest = () => {
    axiosGetPagesCancelToken.cancel();
    axiosGetSectionsCancelToken.cancel();
    setIsLoading(false);
    setData([]);
    setIsError(false);
  };

  return {
    isLoading,
    isError,
    data,
    request: handleRequest,
    cancel: handleCancelRequest,
  };
};
