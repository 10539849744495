import { omit } from 'lodash';
import { ActionTypes, createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';
import {
  createLiquidSnippet,
  getLiquidSnippets,
  deleteLiquidSnippet,
  loadMoreLiquidSnippets,
  updateLiquidSnippetFileName,
  updateLiquidSnippet,
} from './action';

export type FileName = string;
export type SnippetContent = string;

type ExtraActions = ActionTypes<
  | typeof getLiquidSnippets
  | typeof createLiquidSnippet
  | typeof deleteLiquidSnippet
  | typeof loadMoreLiquidSnippets
  | typeof updateLiquidSnippetFileName
  | typeof updateLiquidSnippet
>;

export type LiquidSnippet = Record<FileName, SnippetContent>;

interface SetSnippets {
  type: 'setLiquidSnippets';
  payload: LiquidSnippet;
}

interface UpdateSnippetContent {
  type: 'updateLiquidSnippetContent';
  payload: {
    fileName: FileName;
    liquidContent: SnippetContent;
  };
}

interface AddNewSnippetFile {
  type: 'addNewSnippetFile';
  payload: string;
}

interface SetSearchKeyLiquidSnippet {
  type: 'setSearchKeyLiquidSnippet';
  payload: string;
}

type Actions = SetSnippets | UpdateSnippetContent | SetSearchKeyLiquidSnippet | AddNewSnippetFile;

interface State {
  data: LiquidSnippet;
  status: Status;
  total: number;
  page: number;
  loadMoreStatus: Status;
  search: string;
}

export const defaultLiquidSnippetState: State = {
  data: {},
  status: 'idle',
  page: 0,
  total: 0,
  loadMoreStatus: 'idle',
  search: '',
};

export const sliceLiquidSnippets = createSlice<State, Actions, ExtraActions>({
  name: 'Global',
  initialState: defaultLiquidSnippetState,
  reducers: [
    handleAction('setLiquidSnippets', ({ state, action }) => {
      state.data = action.payload;
    }),
    handleAction('updateLiquidSnippetContent', ({ state, action }) => {
      state.data[action.payload.fileName] = action.payload.liquidContent;
    }),
    handleAction('setSearchKeyLiquidSnippet', ({ state, action }) => {
      state.search = action.payload;
    }),
    handleAction('addNewSnippetFile', ({ state, action }) => {
      return {
        ...state,
        data: {
          [action.payload]: '',
          ...state.data,
        },
      };
    }),
  ],
  extraReducers: [
    handleAction('@Global/createLiquidSnippet/request', ({ state }) => {
      state.status = 'loading';
    }),
    handleAction('@Global/createLiquidSnippet/success', ({ state, action }) => {
      return {
        ...state,
        status: 'success',
        data: {
          ...action.payload,
          ...state.data,
        },
      };
    }),
    handleAction('@Global/createLiquidSnippet/failure', ({ state }) => {
      state.status = 'failure';
    }),
    handleAction('@Global/deleteLiquidSnippet/request', ({ state }) => {
      state.status = 'loading';
    }),
    handleAction('@Global/deleteLiquidSnippet/success', ({ state, action }) => {
      return {
        ...state,
        status: 'success',
        data: omit(state.data, action.payload.fileName),
      };
    }),
    handleAction('@Global/deleteLiquidSnippet/failure', ({ state }) => {
      state.status = 'failure';
    }),
    handleAction('@Global/getLiquidSnippets/request', ({ state }) => {
      state.status = 'loading';
    }),
    handleAction('@Global/getLiquidSnippets/success', ({ state, action }) => {
      state.status = 'success';
      state.data = action.payload.data;
      state.total = action.payload.totalPage;
      state.page = action.payload.page;
    }),
    handleAction('@Global/getLiquidSnippets/failure', ({ state }) => {
      state.status = 'failure';
    }),
    handleAction('@Global/loadMoreLiquidSnippets/request', ({ state }) => {
      state.loadMoreStatus = 'loading';
    }),
    handleAction('@Global/loadMoreLiquidSnippets/success', ({ state, action }) => {
      return {
        ...state,
        loadMoreStatus: 'success',
        data: {
          ...state.data,
          ...action.payload.data,
        },
        page: action.payload.page,
        total: action.payload.totalPage,
      };
    }),
    handleAction('@Global/loadMoreLiquidSnippets/failure', ({ state }) => {
      state.loadMoreStatus = 'failure';
    }),
    handleAction('@Global/updateLiquidSnippetFileName/success', ({ state, action }) => {
      state.status = 'loading';
      if (action.payload.newFileName !== action.payload.oldFileName) {
        state.data[action.payload.newFileName] = state.data[action.payload.oldFileName];
        delete state.data[action.payload.oldFileName];
      }
    }),
    handleAction('@Global/updateLiquidSnippetFileName/failure', ({ state }) => {
      state.status = 'failure';
    }),
    handleAction('@Global/updateLiquidSnippet/request', ({ state }) => {
      state.status = 'loading';
    }),
    handleAction('@Global/updateLiquidSnippet/success', ({ state, action }) => {
      state.status = 'success';
      state.data[action.payload.fileName] = action.payload.liquidContent;
    }),
    handleAction('@Global/updateLiquidSnippet/failure', ({ state }) => {
      state.status = 'failure';
    }),
  ],
});

export const { updateLiquidSnippetContent, setLiquidSnippets, setSearchKeyLiquidSnippet, addNewSnippetFile } = sliceLiquidSnippets.actions;

export const useUpdateLiquidSnippetContent = createDispatchAction(updateLiquidSnippetContent);
export const useSetLiquidSnippets = createDispatchAction(setLiquidSnippets);
export const useSetSearchKeyLiquidSnippet = createDispatchAction(setSearchKeyLiquidSnippet);
export const useAddNewSnippetFile = createDispatchAction(addNewSnippetFile);

export const liquidSnippetsSelector = (state: AppState) => state.global.appSettings.liquidSnippets;
