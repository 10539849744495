import { UltimateIcon } from 'components/PlanBadge/Icons/UltimateIcon';
import { PlanIconProps } from 'components/PlanBadge/types';
import { FC } from 'react';
import { View } from 'wiloke-react-core';
import * as styles from './styles';

export const UltimateBadge: FC<PlanIconProps> = ({ css }) => {
  return (
    <View css={[styles.container, css]} radius={4} backgroundColor="dark" color="light" fontFamily="tertiary">
      <UltimateIcon />
      <View tagName="span" css={styles.text}>
        ultimate
      </View>
    </View>
  );
};
