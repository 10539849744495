import { ImportThemeAtomToClientServiceNPageInThemeClientMessage } from 'hooks/useSocket/useSocketForImportThemeAtomToClientServiceNPageInThemeClient';
import { ProductTheme } from 'types/Theme';
import { renewVersion } from 'utils/CacheControl/VedaClientCacheControl';
import { themeApis } from '../apis';

interface ImportThemeAtomToClientService {
  themeProductCommandId: string;
  eventType: ImportThemeAtomToClientServiceNPageInThemeClientMessage['eventType'];
  eventId: string;
  group: ProductTheme['group'];
  shopifyThemeId: string;
}

export const importThemeAtomToClientService = async ({
  themeProductCommandId,
  eventType,
  eventId,
  group,
  shopifyThemeId,
}: ImportThemeAtomToClientService) => {
  if (group === 'built_in') {
    const response = await themeApis.vedaApplication.userApi.theme.importThemeAtomBuiltin({
      themeProductCommandId,
      eventType,
      eventId,
      shopifyThemeId,
    });
    renewVersion();
    return response;
  } else {
    const response = await themeApis.vedaApplication.userApi.theme.importThemeAtomEnvato({
      themeProductCommandId,
      eventType,
      eventId,
      shopifyThemeId,
    });
    renewVersion();
    return response;
  }
};
