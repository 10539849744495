import { PageSection } from 'types/Sections';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

interface GetHeaderOrFooterSectionsOfThemeDraft {
  commandIds: string[];
}

export const getHeaderOrFooterSectionsOfThemeDraft = async ({ commandIds }: GetHeaderOrFooterSectionsOfThemeDraft): Promise<PageSection[]> => {
  if (!commandIds.length) {
    return [];
  }
  const { role } = getUserInfo();
  if (role === 'admin') {
    const responses = await themeApis.atom.adminApi.section.getSectionsOfDraft({ commandIds });
    return responses.info;
  }
  if (role === 'dev' || role === 'support') {
    const responses = await themeApis.atom.devApi.section.getSectionsOfDraft({ commandIds });
    return responses.info;
  }
  throw new RoleException();
};
