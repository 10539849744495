import { imageUrl } from '@wiloke/functions';
import ImageTextCard from 'components/ImageTextCard';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';
import { useSaveSection } from 'containers/ChooseTemplate/store/actions';
import { useSetTemplateBoardVisible } from 'containers/ChooseTemplate/store/actions/actionTemplateBoardVisible';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { pageSectionsSelector, sectionIdActiveSelector, sectionsSelector } from 'store/selectors';
import { i18n } from 'translation';
import { SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client } from 'types/Sections';
import { PLAN_STATIC_DATA, VEDA_FUNCTION_ENUM } from 'utils/constants/PlanEnum';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { validatePlan } from 'utils/functions/validatePlan';

const SectionCardContainer: FC = () => {
  const { savedStatus } = useSelector(sectionsSelector.userSections);
  // const { categories } = useSelector(sectionsSelector.categories);
  const sectionIdActive = useSelector(sectionIdActiveSelector);
  const pageSections = useSelector(pageSectionsSelector);
  const sectionIndex = pageSections.findIndex(section => section.id === sectionIdActive);
  const sectionActive = pageSections[sectionIndex];
  const { role, plan } = getUserInfo();
  const _navs = role === 'admin' ? ['admin', 'Sections'] : ['sections'];

  const setTemplateBoardVisible = useSetTemplateBoardVisible();
  const saveToFavorite = useSaveSection();
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();

  const handleChangeSection = () => {
    setTemplateBoardVisible({
      visible: true,
      index: sectionIndex,
      isChange: true,
      navKeys: _navs,
      sectionType: sectionActive.type,
    });
  };

  const handleSaveToFavorite = () => {
    if (validatePlan({ userPlan: plan.planHandle, itemPlan: VEDA_FUNCTION_ENUM.save_section_favorite, role }) === 'invalid') {
      setPlanRequesting({
        handle: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.save_section_favorite].handle,
        type: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.save_section_favorite].type,
        pricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.save_section_favorite].pricePerMonth,
        pricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.save_section_favorite].pricePerYear,
        title: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.save_section_favorite].title,
        originalPricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.save_section_favorite].pricePerMonth,
        originalPricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.save_section_favorite].pricePerYear,
      });
      setValidateCouponVisible(true);
    } else {
      saveToFavorite.request({
        id: (sectionActive as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client).parentCommandId || '',
        categories: sectionActive.category?.name ? [sectionActive.category.name] : [],
        name: sectionActive.label,
        image: sectionActive.image ?? { src: '', width: 0, height: 0 },
        data: (sectionActive as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client).data,
      });
    }
  };

  return (
    <ImageTextCard
      label={sectionActive.label}
      src={sectionActive.image?.src}
      previewImg={sectionActive.image ? imageUrl(sectionActive.image.src, 10) : undefined}
      buttonText={i18n.t('general.change_section')}
      backgroundSize="contain"
      loading={savedStatus[(sectionActive as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client)?.parentCommandId ?? ''] === 'loading'}
      onAdd={handleChangeSection}
      onSave={(sectionActive as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client)?.parentCommandId ? () => handleSaveToFavorite() : undefined}
      requireUpdatePlan={plan.planHandle === 'free'}
    />
  );
};

export default SectionCardContainer;
