import { AxiosError } from 'axios';
import { put, retry, SagaReturnType, takeEvery } from 'redux-saga/effects';
import { restorePageOfThemeAtom } from 'services/ThemeService/Logic/restorePageOfThemeAtom';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { restorePageTrashTheme } from '../actions/actionThemeTrash';

function* handleRestore({ payload: { commandId } }: ReturnType<typeof restorePageTrashTheme.request>) {
  try {
    const response: SagaReturnType<typeof restorePageOfThemeAtom> = yield retry(3, 1000, restorePageOfThemeAtom, { commandId });
    yield put(restorePageTrashTheme.success({ commandId }));
    notifyAxiosHandler.handleSuccess(response.message);
  } catch (error) {
    yield put(restorePageTrashTheme.failure({ commandId }));
    notifyAxiosHandler.handleError(error as AxiosError<ErrorData> | Error);
  }
}

export function* watchRestorePagesOfThemeAtom() {
  yield takeEvery(getActionType(restorePageTrashTheme.request), handleRestore);
}
