import { Option } from 'components/SelectAntd';
import { createSelector } from 'reselect';
import { SectionCategoryTag } from 'types/Sections';
import { ActionTypes, createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';
import {
  createCategoryOfThemeProduct,
  deleteCategoryOfThemeProduct,
  getCategoriesOfThemeProduct,
  updateCategoryOfThemeProduct,
} from '../actions/actionThemesProduct';

type CategoryExtraActions = ActionTypes<
  typeof createCategoryOfThemeProduct | typeof getCategoriesOfThemeProduct | typeof deleteCategoryOfThemeProduct | typeof updateCategoryOfThemeProduct
>;

type Actions =
  | {
      type: 'editProductThemeCategory';
      payload: AtLeast<SectionCategoryTag, 'commandId'>;
    }
  | {
      type: 'changeThemeCategoryId';
      payload: string;
    };

interface State {
  categories: SectionCategoryTag[];
  getStatus: Status;
  createStatus: Status;
  deleteStatus: Record<string, Status>;
  updateStatus: Record<string, Status>;
  categoryId: string;
}

export const sliceThemesProduct = createSlice<State, Actions, CategoryExtraActions>({
  name: '@ThemeManager',
  initialState: {
    categories: [],
    createStatus: 'idle',
    getStatus: 'idle',
    deleteStatus: {},
    updateStatus: {},
    categoryId: '',
  },
  reducers: [
    handleAction('editProductThemeCategory', ({ state, action }) => {
      state.categories = state.categories.map(item => {
        if (item.commandId === action.payload.commandId) {
          return {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });
    }),
    handleAction('changeThemeCategoryId', ({ state, action }) => {
      state.categoryId = action.payload;
    }),
  ],
  extraReducers: [
    handleAction('@ThemeManager/getCategoriesOfThemeProduct/request', ({ state }) => ({
      ...state,
      getStatus: state.categories.length > 0 ? 'success' : 'loading',
    })),
    handleAction('@ThemeManager/getCategoriesOfThemeProduct/success', ({ state, action }) => ({
      ...state,
      getStatus: 'success',
      categories: action.payload,
    })),
    handleAction('@ThemeManager/getCategoriesOfThemeProduct/failure', ({ state }) => ({ ...state, getStatus: 'failure' })),

    handleAction('@ThemeManager/createCategoryOfThemeProduct/request', ({ state }) => ({ ...state, createStatus: 'loading' })),
    handleAction('@ThemeManager/createCategoryOfThemeProduct/success', ({ state, action }) => ({
      ...state,
      createStatus: 'success',
      categories: [action.payload, ...state.categories],
    })),
    handleAction('@ThemeManager/createCategoryOfThemeProduct/failure', ({ state }) => ({ ...state, createStatus: 'failure' })),
    handleAction('@PageManager/deleteCategoryOfThemeProduct/request', ({ state, action }) => {
      state.deleteStatus[action.payload.commandId] = 'loading';
    }),
    handleAction('@PageManager/deleteCategoryOfThemeProduct/success', ({ state, action }) => {
      state.deleteStatus[action.payload.commandId] = 'success';
      state.categories = state.categories.filter(item => item.commandId !== action.payload.commandId);
    }),
    handleAction('@PageManager/deleteCategoryOfThemeProduct/failure', ({ state, action }) => {
      state.deleteStatus[action.payload.commandId] = 'failure';
    }),
    handleAction('@PageManager/updateCategoryOfThemeProduct/request', ({ state, action }) => {
      state.updateStatus[action.payload.commandId] = 'loading';
    }),
    handleAction('@PageManager/updateCategoryOfThemeProduct/success', ({ state, action }) => {
      state.updateStatus[action.payload.commandId] = 'success';
      state.categories = state.categories.map(item => {
        if (item.commandId === action.payload.commandId) {
          return {
            ...item,
            commandId: action.payload.commandId,
            name: action.payload.name,
            description: action.payload.description,
          };
        }
        return item;
      });
    }),
    handleAction('@PageManager/updateCategoryOfThemeProduct/failure', ({ state, action }) => {
      state.updateStatus[action.payload.commandId] = 'failure';
    }),
  ],
});

export const { editProductThemeCategory, changeThemeCategoryId } = sliceThemesProduct.actions;
export const themesProductSelector = (state: AppState) => state.adminDashboard.themesProduct;

export const useEditProductThemeCategory = createDispatchAction(editProductThemeCategory);
export const useChangeThemeCategoryId = createDispatchAction(changeThemeCategoryId);

export const transformUserThemeCategories = createSelector(
  (state: AppState) => state.adminDashboard.themesProduct,
  state => {
    const { categories } = state;
    return categories.map<Option>(item => {
      return {
        label: item.description,
        value: item.name,
        commandId: item.commandId,
      };
    });
  },
);
