import { v4 } from 'uuid';
import { defaultLanguage, languages } from '../../../../@consts';
import { Locales } from '../../../../@types/Veda/ShopifyLocales';
import { localKeyGenByVedaKeysOfStyleBoxField } from '../@consts/localKeyGenByVedaKeysOfStyleBoxField';
import { ISCHEMA_SettingStyleBox } from '../@types/ISCHEMA_SettingStyleBox';

interface RTVedaSummariesOfStyleBoxFieldToShopifyFieldInfo {
  newInfoOfKey: string | undefined;
  locales: Locales;
}

const createLocaleKey = () => `_${localKeyGenByVedaKeysOfStyleBoxField}_${v4()}_`;

const DEFAULT_SUMMARY = `Please DON'T edit this field.`;

export const vedaSummariesOfStyleBoxFieldToShopifyFieldInfo = ({
  field,
}: ISCHEMA_SettingStyleBox): RTVedaSummariesOfStyleBoxFieldToShopifyFieldInfo => {
  const { summary = '' } = field;

  const summary_ = summary ? summary : DEFAULT_SUMMARY;

  const localeKey = createLocaleKey();
  const default_translations = { [localeKey]: '' };

  // const newInfoOfKey: RTVedaSummariesOfStyleBoxFieldToShopifyFieldInfo['newInfoOfKey'] = `t:${localeKey}`;
  const locales: RTVedaSummariesOfStyleBoxFieldToShopifyFieldInfo['locales'] = languages.reduce<
    RTVedaSummariesOfStyleBoxFieldToShopifyFieldInfo['locales']
  >(
    (res, language) => {
      const infoOfField =
        summary_ === '' ? undefined : typeof summary_ === 'string' ? summary_ : typeof summary_ === 'object' ? summary_[language] : undefined;
      return {
        ...res,
        [language]: {
          [localeKey]: infoOfField ? infoOfField : undefined,
        },
      };
    },
    { en: default_translations, vi: default_translations, fr: default_translations },
  );
  return {
    newInfoOfKey: locales[defaultLanguage][localeKey],
    locales,
  };
  // return {
  //   newInfoOfKey,
  //   locales,
  // };
};
