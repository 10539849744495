import { AxiosResponse } from 'axios';
import fetchAPI from 'utils/functions/fetchAPI';
import { clientBaseUrl } from '../const';

interface Params {
  commandId: string;
  disableOthers: boolean;
}

interface ResponseSuccess {}

export const enableProduct = async ({ commandId, disableOthers }: Params) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${clientBaseUrl}/${commandId}/switcher/${disableOthers ? 'enable-disable-others' : 'enable'}`,
    method: 'PUT',
  });

  return response.data;
};
