import { PageType } from 'types/Page';
import { createAction, createAsyncAction, createDispatchAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';
import { ServerTemplateResponse } from 'services/PagesBuilderService';

interface ReadParams {
  type: PageType;
  categoryId?: string;
  search?: string;
  planFilter?: string;
  useCases?: string[];
}

export const actionGetTemplatesPopup = createAsyncAction([
  '@TemplatesInPopup/getTemplatesRequest',
  '@TemplatesInPopup/getTemplatesSuccess',
  '@TemplatesInPopup/getTemplatesFailure',
])<ReadParams, { data: ServerTemplateResponse[]; hasNextPage: boolean }, undefined>();

export const useGetTemplatesPopup = createDispatchAsyncAction(actionGetTemplatesPopup);

export const actionLoadMoreTemplatesPopup = createAsyncAction([
  '@TemplatesInPopup/loadMoreTemplatesPopupRequest',
  '@TemplatesInPopup/loadMoreTemplatesPopupSuccess',
  '@TemplatesInPopup/loadMoreTemplatesPopupFailure',
])<
  ReadParams & {
    cursor: string;
  },
  { data: ServerTemplateResponse[]; hasNextPage: boolean },
  undefined
>();

export const useLoadMoreTemplatesPopup = createDispatchAsyncAction(actionLoadMoreTemplatesPopup);

export const setCurrentTemplateBlank = createAction('@TemplatesInPopup/setCurrentTemplateBlank', (item: ServerTemplateResponse | undefined) => ({
  item,
}));
export const useSetCurrentTemplateBlank = createDispatchAction(setCurrentTemplateBlank);

export const checkCreateBlank = createAction('@TemplatesInPopup/checkCreateBlank', (isCreate: boolean) => ({ isCreate }));
export const useCheckCreateBlank = createDispatchAction(checkCreateBlank);
