import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';

export const updateUseCaseOfProdPage = async ({
  commandId,
  description,
  name,
  belongsToPageTypes,
}: {
  commandId: string;
  description: string;
  name: string;
  belongsToPageTypes?: string[];
}) => {
  const { role } = getUserInfo();

  if (role === 'admin') {
    const response = await pageApis.vedaApplication.adminApi.use_case.updateUseCase({
      useCase: {
        commandId,
        description,
        name,
        belongsToPageTypes,
      },
    });
    return response;
  }

  throw new RoleException();
};
