import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { megaMenuService } from 'services/MegaMenuService';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { deleteProductMegaMenu } from '../actions';

function* handleDelete({ payload: { commandId } }: ReturnType<typeof deleteProductMegaMenu.request>) {
  try {
    const response: SagaReturnType<typeof megaMenuService.mega_menu.deleteProductSection> = yield retry(
      3,
      1000,
      megaMenuService.mega_menu.deleteProductSection,
      commandId,
    );
    yield put(deleteProductMegaMenu.success({ commandId }));
    notifyAxiosHandler.handleSuccess(response.message);
  } catch (error) {
    yield put(deleteProductMegaMenu.failure({ commandId }));
  }
}

export function* watchDeleteProductMegaMenu() {
  yield takeLatest(getActionType(deleteProductMegaMenu.request), handleDelete);
}
