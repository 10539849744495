import { renewVersion } from 'utils/CacheControl/VedaClientCacheControl';
import { handleGlobalTranslationsNColorSchemesInThemeClient } from '../Adapter/handleGlobalTranslationsNColorSchemes';
import { themeApis } from '../apis';

interface UpdateThemeClient {
  commandId: string;
  /** Dữ liệu sẽ được apply vào record */
  shopifyThemeId: string;
}

export const updateClientThemeShopifyId = async ({ commandId, shopifyThemeId }: UpdateThemeClient) => {
  const response = await themeApis.vedaApplication.userApi.theme.updateShopifyThemeId({
    commandId,
    shopifyThemeId,
  });
  renewVersion();
  return {
    info: handleGlobalTranslationsNColorSchemesInThemeClient(response.info),
    message: response.message,
  };
};
