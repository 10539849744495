import { ThemeSettings, ThemeTranslationsResponse } from 'types/Result';
import { WriteTranslation_BEExpectParameters } from './services/writeTranslationToShopify';

type SyncTranslationParamsExpect = DeepPartial<WriteTranslation_BEExpectParameters>[];
interface HandleGetTranslationParams {
  themeSettings: ThemeSettings;
  eventId: string | undefined;
  eventType: WriteTranslation_BEExpectParameters['eventType'];
}
export const handleGetTranslationParams = ({ eventId, eventType, themeSettings }: HandleGetTranslationParams): SyncTranslationParamsExpect => {
  const languages = themeSettings.globalTranslations.translation;
  const syncTranslationsParams: SyncTranslationParamsExpect = Object.keys(languages).reduce<SyncTranslationParamsExpect>((result, language) => {
    const language_ = language as keyof ThemeTranslationsResponse['translation'];
    try {
      const translationContent = languages[language_];
      if (translationContent) {
        return result.concat({
          eventId,
          eventType,
          lang: language,
          translation: JSON.parse(translationContent),
        });
      }
      return result;
    } catch {
      return result;
    }
  }, []);

  return syncTranslationsParams;
};
