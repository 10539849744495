import { message as AntMessage, notification } from 'antd';
import { SyncException } from '../../../utils/Exception';
import { Task } from 'redux-saga';
import { all, call, cancel, delay, put, retry, SagaReturnType, select, take, takeLeading } from 'redux-saga/effects';
import { shopifyConnectionService } from 'services/ShopifyConnection';
import { copyToClipboard } from 'utils/copyToClipboard';
import { getActionType } from 'wiloke-react-core/utils';
import { selectSyncThemeByResultOfExtractThemeToFileForSync } from '../../../selectors';
import {
  pushActualProcesses,
  setExpectProcesses,
  setStatusOfSession,
  setTemporaryPreviewUrls,
  setTemporaryRepresentPreviewUrl,
  setTemporarySignalToReplaceAddonInLiquidCode,
  syncThemeByResultOfExtractThemeToFileForSync,
} from '../../actions';
import {
  syncAddonsDisablePosition,
  SyncAddonsDisablePositionResult,
  syncAddonsEnablePosition,
  SyncAddonsEnablePositionResult,
} from './steps/syncAddons';
import { syncGlobalOfTheme, SyncGlobalOfThemeResult } from './steps/syncGlobalOfTheme';
import { syncHeaderFooter, SyncHeaderFooterResult } from './steps/syncHeaderFooter';
import { syncTranslations } from './steps/syncTranslations';
import {
  getSyncAddonsProcessName,
  getSyncThemeSettingsProcessName,
  getSyncTranslationsProcessName,
  getSyncHeaderFooterProcessName,
  getSyncPageProcessName,
  getSyncThemeSchemaNSettings,
} from './utils/getProcessName';
import { syncPage, SyncPageResult } from './steps/syncPage';
import { syncThemeSchemaNSettings } from './steps/syncThemeSchemaNSettings';

function* handleSyncThemeByResultOfExtractThemeToFileForSync({ payload }: ReturnType<typeof syncThemeByResultOfExtractThemeToFileForSync.request>) {
  const startTime = Date.now();
  const { onSyncFulfill, onSuccess, data, isRetryAction } = payload;
  try {
    const { socket, session }: ReturnType<typeof selectSyncThemeByResultOfExtractThemeToFileForSync> = yield select(
      selectSyncThemeByResultOfExtractThemeToFileForSync,
    );
    const { eventId } = socket;
    const { actualProcessed } = session;

    const pagesParams = data.pagesParams.reduce<typeof data['pagesParams']>((result, pagesParam) => {
      if (pagesParam.isSyncTurnedOff) {
        return result;
      }
      return result.concat({ ...pagesParam, eventId });
    }, []);
    const themeParams = { ...data.themeParams, eventId };
    const headerParams = { ...data.headerParams, eventId };
    const footerParams = { ...data.footerParams, eventId };
    const addonsEnablePositionParams = { ...data.addonsEnablePositionParams, eventId };
    const addonsDisablePositionParams = { ...data.addonsDisablePositionParams, eventId };
    const syncTranslationsParams = data.syncTranslationsParams.map(syncTranslationsParam => ({ ...syncTranslationsParam, eventId }));
    const themeSchemaNSettingsParams = { ...data.themeSchemaNSettingsParams, eventId };

    const SYNC_THEME_SCHEMA_N_SETTINGS_PROCESS_NAME = getSyncThemeSchemaNSettings();
    const SYNC_TRANSLATIONS_PROCESS_NAME = getSyncTranslationsProcessName();
    const SYNC_THEME_SETTINGS_PROCESS_NAME = getSyncThemeSettingsProcessName();
    const SYNC_ADDONS_PROCESS_NAME = getSyncAddonsProcessName();
    const SYNC_HEADER_FOOTER_PROCESS_NAME = getSyncHeaderFooterProcessName();

    yield put(setStatusOfSession({ status: 'loading', isRetryAction }));
    if (!isRetryAction) {
      yield put(
        setExpectProcesses([
          SYNC_THEME_SCHEMA_N_SETTINGS_PROCESS_NAME,
          SYNC_TRANSLATIONS_PROCESS_NAME,
          SYNC_THEME_SETTINGS_PROCESS_NAME,
          SYNC_ADDONS_PROCESS_NAME,
          SYNC_HEADER_FOOTER_PROCESS_NAME,
          ...pagesParams.map(getSyncPageProcessName),
        ]),
      );
    }

    // Step 1 -> Sync theme schema và theme settings
    if (!isRetryAction || (isRetryAction && !actualProcessed.includes(SYNC_THEME_SCHEMA_N_SETTINGS_PROCESS_NAME))) {
      const { statusSyncThemeSchemaNSettings, messageError }: SagaReturnType<typeof syncThemeSchemaNSettings> = yield call(syncThemeSchemaNSettings, {
        themeSchemaNSettingsParams,
      });
      if (statusSyncThemeSchemaNSettings === 'failure') {
        throw new SyncException('Đồng bộ "configure theme" gặp lỗi', messageError);
      }
      yield put(pushActualProcesses(SYNC_THEME_SCHEMA_N_SETTINGS_PROCESS_NAME));
    }

    // Step 2 -> Sync translation
    if (!isRetryAction || (isRetryAction && !actualProcessed.includes(SYNC_TRANSLATIONS_PROCESS_NAME))) {
      const { statusSyncTranslations, messageError }: SagaReturnType<typeof syncTranslations> = yield call(syncTranslations, {
        syncTranslationsParams,
      });
      if (statusSyncTranslations === 'failure') {
        throw new SyncException('Đồng bộ "translation" gặp lỗi', messageError);
      }
      yield put(pushActualProcesses(SYNC_TRANSLATIONS_PROCESS_NAME));
    }

    // Step 3 -> Sync theme settings
    if (!isRetryAction || (isRetryAction && !actualProcessed.includes(SYNC_THEME_SETTINGS_PROCESS_NAME))) {
      const { statusSyncGlobalOfTheme, messageError }: SyncGlobalOfThemeResult = yield call(syncGlobalOfTheme, {
        themeParams,
      });

      if (statusSyncGlobalOfTheme === 'failure') {
        throw new SyncException('Đồng bộ "theme settings" gặp lỗi', messageError);
      }
      yield put(pushActualProcesses(SYNC_THEME_SETTINGS_PROCESS_NAME));
    }

    // Step 4 -> Sync addons
    const stateBeforeStartSyncAddons: SagaReturnType<typeof selectSyncThemeByResultOfExtractThemeToFileForSync> = yield select(
      selectSyncThemeByResultOfExtractThemeToFileForSync,
    );
    if (
      !isRetryAction ||
      (isRetryAction && !actualProcessed.includes(SYNC_ADDONS_PROCESS_NAME)) ||
      !stateBeforeStartSyncAddons.session.temporarySignalToReplaceAddonInLiquidCode
    ) {
      const [
        { statusSyncAddonsEnablePosition, signalToReplaceAddonInLiquidCode, messageError: messageErrorOfSyncAddonsEnablePosition },
        { statusSyncAddonsDisablePosition, messageError: messageErrorOfSyncAddonsDisablePosition },
      ]: [SyncAddonsEnablePositionResult, SyncAddonsDisablePositionResult] = yield all([
        call(syncAddonsEnablePosition, { addonsEnablePositionParams }),
        call(syncAddonsDisablePosition, { addonsDisablePositionParams }),
      ]);
      if (statusSyncAddonsEnablePosition === 'failure' || statusSyncAddonsDisablePosition === 'failure') {
        throw new SyncException(`Đồng bộ "addons" gặp lỗi`, messageErrorOfSyncAddonsEnablePosition ?? messageErrorOfSyncAddonsDisablePosition);
      }
      yield put(pushActualProcesses(SYNC_ADDONS_PROCESS_NAME));
      yield put(setTemporarySignalToReplaceAddonInLiquidCode(signalToReplaceAddonInLiquidCode));
    }

    // Step 5 -> Sync header footer
    const stateAfterStartSyncAddons: SagaReturnType<typeof selectSyncThemeByResultOfExtractThemeToFileForSync> = yield select(
      selectSyncThemeByResultOfExtractThemeToFileForSync,
    );
    if (!isRetryAction || (isRetryAction && !actualProcessed.includes(SYNC_HEADER_FOOTER_PROCESS_NAME))) {
      if (stateAfterStartSyncAddons.session.temporarySignalToReplaceAddonInLiquidCode) {
        const { statusSyncHeader, statusSyncFooter, messageError }: SyncHeaderFooterResult = yield call(syncHeaderFooter, {
          headerParams,
          footerParams,
          signalToReplaceAddonInLiquidCode: stateAfterStartSyncAddons.session.temporarySignalToReplaceAddonInLiquidCode,
        });
        if (statusSyncHeader === 'failure' || statusSyncFooter === 'failure') {
          throw new SyncException(`Đồng bộ "header footer" gặp lỗi`, messageError);
        }
        yield put(pushActualProcesses(SYNC_HEADER_FOOTER_PROCESS_NAME));
      } else {
        throw new SyncException(`Đồng bộ "header footer" gặp lỗi`, 'Lỗi do dev');
      }
    }

    // Step 6 - sync các page
    for (const pageParams of pagesParams) {
      const pageProcessId = getSyncPageProcessName(pageParams);
      const pageName = pageParams.pageName;
      if (!isRetryAction || (isRetryAction && !actualProcessed.includes(pageProcessId))) {
        if (stateAfterStartSyncAddons.session.temporarySignalToReplaceAddonInLiquidCode) {
          yield delay(1000);
          if (pageParams.pageType !== 'giftCard') {
            const { statusSyncPage, previewUrl, messageError }: SyncPageResult = yield call(syncPage, {
              pageParams,
              signalToReplaceAddonInLiquidCode: stateAfterStartSyncAddons.session.temporarySignalToReplaceAddonInLiquidCode,
            });
            const { session }: SagaReturnType<typeof selectSyncThemeByResultOfExtractThemeToFileForSync> = yield select(
              selectSyncThemeByResultOfExtractThemeToFileForSync,
            );
            if (previewUrl && (!session.temporaryRepresentPreviewUrl || pageParams.pageType === 'home')) {
              yield put(setTemporaryRepresentPreviewUrl(previewUrl));
            }
            if (pageParams.pageName && previewUrl) {
              yield put(
                setTemporaryPreviewUrls(
                  session.temporaryPreviewUrls.concat({
                    pageName: pageParams.pageName,
                    url: previewUrl,
                  }),
                ),
              );
            }
            if (statusSyncPage === 'failure') {
              throw new SyncException(`Sync page "${pageName}" gặp lỗi`, messageError);
            }
          }
          yield put(pushActualProcesses(getSyncPageProcessName(pageParams)));
        } else {
          throw new SyncException(pageProcessId, 'Lỗi do dev');
        }
      }
    }

    const finalState: SagaReturnType<typeof selectSyncThemeByResultOfExtractThemeToFileForSync> = yield select(
      selectSyncThemeByResultOfExtractThemeToFileForSync,
    );
    notification.success({
      message: 'Đồng bộ shopify thành công',
      description: (
        <div>
          <p>Theme "{themeParams.themeName}" đã đồng đồng bộ lên shopify</p>
          {finalState.session.temporaryRepresentPreviewUrl && (
            <a target="_blank" href={finalState.session.temporaryRepresentPreviewUrl}>
              Preview
            </a>
          )}
        </div>
      ),
      duration: null,
    });
    onSuccess({
      previewUrl: finalState.session.temporaryRepresentPreviewUrl ?? undefined,
      fullPreviewUrls: finalState.session.temporaryPreviewUrls,
    });
    yield put(setStatusOfSession({ status: 'success', isRetryAction }));
    yield put(syncThemeByResultOfExtractThemeToFileForSync.success(undefined));
  } catch (error) {
    const messageError = error && typeof error === 'object' && 'message' in error ? error.message : error;
    const { session }: SagaReturnType<typeof selectSyncThemeByResultOfExtractThemeToFileForSync> = yield select(
      selectSyncThemeByResultOfExtractThemeToFileForSync,
    );
    const { actualProcessed } = session;
    notification.error({
      duration: null,
      message: 'Sync bằng file gặp lỗi',
      description: (
        <div>
          <p>
            Lỗi có thể xảy ra do mạng nhưng hãy cứ báo bug bằng cách gửi file vừa upload vào nhóm kèm lời nhắn "Sync bằng file gặp lỗi: {messageError}
            "
          </p>
          <button
            style={{
              borderRadius: '4px',
              backgroundColor: '#2C36DC',
              color: '#fff',
              fontWeight: 500,
              padding: '10px 16px',
              cursor: 'pointer',
              outline: 'none',
              border: 'none',
            }}
            onClick={() => {
              copyToClipboard(`
                "Sync bằng file gặp lỗi: ${messageError}"
                - Tiến trình đã hoàn thành:
                  ${actualProcessed.slice(0, -1).map(item => `+ ${item}`)}
                - Tiến trình bị treo:
                  + ${actualProcessed[actualProcessed.length - 1]}
                - Chi tiết message lỗi:
                  + ${error instanceof SyncException ? error.cause : error && typeof error === 'object' && 'message' in error ? error.message : error}
              `);
              AntMessage.success('Đã copy');
            }}
          >
            Copy lời nhắn
          </button>
        </div>
      ),
    });
    yield put(syncThemeByResultOfExtractThemeToFileForSync.failure(undefined));
    yield put(setStatusOfSession({ status: 'failure', isRetryAction }));
  } finally {
    notification.info({
      message: `Quá trình sync mất ${Math.floor((Date.now() - startTime) / 1000)}s`,
    });
    onSyncFulfill?.();
    try {
      yield retry(3, 1000, shopifyConnectionService.cleanAfterSync);
    } catch {}
  }
}

let task: Task | null = null;
export function* watchSyncThemeByResultOfExtractThemeToFileForSync() {
  task = yield takeLeading(getActionType(syncThemeByResultOfExtractThemeToFileForSync.request), handleSyncThemeByResultOfExtractThemeToFileForSync);
}

export function* watchCancelSyncThemeByResultOfExtractThemeToFileForSync() {
  while (true) {
    yield take(getActionType(syncThemeByResultOfExtractThemeToFileForSync.cancel));
    if (task) {
      yield cancel(task);
    }
  }
}
