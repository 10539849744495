export function deepFind<T extends Record<any, any>, R extends unknown = string>(data: T | T[], findKey: string, result: R[] = []) {
  if (Array.isArray(data)) {
    for (const arrayItem of data) {
      deepFind(arrayItem, findKey, result);
    }
  } else if (typeof data === 'object') {
    for (const key of Object.keys(data || {})) {
      if (key === findKey && data[findKey] !== '') {
        result.push(data[findKey]);
      } else {
        deepFind(data[key], findKey, result);
      }
    }
  }
  return result;
}

export function deepFindByExactValue<T extends Record<any, any>, R extends Record<any, any>>(data: T | T[], value: string, result: R[] = []) {
  if (Array.isArray(data)) {
    for (const arrayItem of data) {
      deepFindByExactValue(arrayItem, value, result);
    }
  } else if (typeof data === 'object') {
    for (const key of Object.keys(data || {})) {
      if (typeof data[key] === 'string' && typeof value === 'string' && data[key].trim().toLocaleLowerCase() === value.trim().toLocaleLowerCase()) {
        result.push(data);
      } else {
        deepFindByExactValue(data[key], value, result);
      }
    }
  }
  return result;
}

// Đang dùng với trường hợp field type = image
export function deepFindByObjectValue<T extends Record<any, any>, R extends Record<any, any>>(data: T | T[], value: any, result: R[] = []) {
  if (Array.isArray(data)) {
    for (const arrayItem of data) {
      deepFindByObjectValue(arrayItem, value, result);
    }
  } else if (typeof data === 'object') {
    for (const key of Object.keys(data || {})) {
      if (JSON.stringify(data[key]) === JSON.stringify(value)) {
        result.push(data);
      } else {
        deepFindByObjectValue(data[key], value, result);
      }
    }
  }
  return result;
}

// So sánh text đầu + cuối của 2 string
// Do dính trường hợp:
// "Sale Up To Collection <br> 50% Offs" !== "Sale Up To Collection 50% Off"
function compareFirstAndLastText(str1: string, str2: string) {
  const extractText = (str: string) => {
    const matches = str.match(/.*?(\b\w+\b).*?(\b\w+\b)$/);
    if (matches) {
      return [matches[1], matches[2]];
    }
    return [null, null];
  };

  const [firstText1, lastText1] = extractText(str1);
  const [firstText2, lastText2] = extractText(str2);

  const isFirstTextEqual = firstText1 === firstText2;
  const isLastTextEqual = lastText1 === lastText2;

  return isFirstTextEqual || isLastTextEqual;
}

export function deepFindByStringValue<T extends Record<any, any>, R extends Record<any, any>>(data: T | T[], value: string, result: R[] = []) {
  if (Array.isArray(data)) {
    for (const arrayItem of data) {
      deepFindByStringValue(arrayItem, value, result);
    }
  } else if (typeof data === 'object') {
    for (const key of Object.keys(data || {})) {
      if (
        typeof data[key] === 'string' &&
        typeof value === 'string' &&
        compareFirstAndLastText(data[key].trim().toLocaleLowerCase(), value.trim().toLocaleLowerCase())
      ) {
        result.push(data);
      } else {
        deepFindByStringValue(data[key], value, result);
      }
    }
  }
  return result;
}
