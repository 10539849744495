import { AxiosResponse } from 'axios';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from './const';

interface Params {
  purchaseCode: string;
}

interface ResponseSuccess {
  message: string;
}

export const deletePurchaseCode = async ({ purchaseCode }: Params) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${baseUrl}/revokes`,
    method: 'DELETE',
    data: { purchaseCode },
  });
  return response.data;
};
