import { strToCapitalize } from '@wiloke/functions';
import Checkbox from 'components/Checkbox';
import { CheckProgress } from 'components/CircleProgress';
import Box from 'components/FieldBox';
import { HelmetImportShopifyFont } from 'components/HelmetImportShopifyFont/HelmetImportShopifyFont';
import { getCssFont } from 'components/ShopifyFontsField/data';
import { FC, useEffect, useRef, useState } from 'react';
import { i18n } from 'translation';
import { CssColorVariable, CssFontVariable } from 'types/PresetStyles';
import { Space, Text, View } from 'wiloke-react-core';
import * as styles from './styles';

export interface PresetStyleBoxProps {
  /* title component */
  title: string;
  /* mảng màu hex được truyền vào */
  colors: CssColorVariable[];
  /* mảng font familty được truyền vào */
  fonts: CssFontVariable[];
  loading?: boolean;
  isActive?: boolean;
  onClick?: () => void;
}

const PresetStyleBox: FC<PresetStyleBoxProps> = ({ colors, loading = false, fonts, title, isActive = false, onClick }) => {
  const [loadingState, setLoading] = useState(loading);
  const timeoutRef = useRef<number | undefined>();
  const primaryColors = colors.slice(0, 5);
  const secondaryColors = colors.slice(6, -1);

  useEffect(() => {
    if (loading) {
      setLoading(loading);
    }
  }, [loading]);

  useEffect(() => {
    if (!!loadingState) {
      timeoutRef.current = window.setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [loadingState]);

  return (
    <Box
      radius={6}
      borderColor="gray3"
      css={styles.container}
      onClick={() => {
        onClick?.();
      }}
    >
      {loadingState ? (
        <View css={styles.loading}>
          <CheckProgress />
        </View>
      ) : null}
      {loadingState ? <View css={styles.overlay} /> : null}

      <View css={styles.titleContainer}>
        <Text tagName="h5">{title}</Text>

        {isActive && <Checkbox checked={isActive} disabledOnChange />}
      </View>
      <View backgroundColor="gray3" height={1} css={styles.line} />

      <View row css={[styles.innerBox, styles.spaceBox]}>
        <View tagName="h6" columns={[12, 3, 3]} css={styles.innerBoxText}>
          {i18n.t('general.colors')}
        </View>

        <View columns={[12, 9, 9]}>
          <View css={styles.colorGrid}>
            {primaryColors.map(item => (
              <View key={item.id} css={{ backgroundColor: item.light }} />
            ))}
          </View>
          <Space size={10} />
          <View css={styles.colorFlex}>
            {secondaryColors.map(item => (
              <View key={item.id} css={{ backgroundColor: item.light, width: '90px', height: '55px' }} />
            ))}
          </View>
        </View>
      </View>

      <View row css={styles.innerBox}>
        <View tagName="h6" columns={[12, 3, 3]} css={styles.innerBoxText}>
          {i18n.t('general.typography')}
        </View>

        <View columns={[12, 9, 9]} css={styles.fontContainer}>
          {fonts.map(item => {
            const label = getCssFont(item)?.label || item.value;
            return (
              <View key={item.id} css={styles.font}>
                <Text size={16}>{strToCapitalize(item.name.replace(/--font-/g, ''))}</Text>
                <Text size={16} css={{ fontFamily: getCssFont(item)?.family }}>
                  {label}
                </Text>
                <HelmetImportShopifyFont key={item.id} option={getCssFont(item)} />
              </View>
            );
          })}
        </View>
      </View>
    </Box>
  );
};

export default PresetStyleBox;
