import { createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';

type Actions =
  | {
      type: 'setVisibleModalUnauthorized';
      payload: boolean;
    }
  | {
      type: 'setRefreshingToken';
      payload: Status;
    };

interface State {
  visible: boolean;
  isRefreshingToken: Status;
}

export const sliceUnauthorized = createSlice<State, Actions>({
  name: '@Global',
  initialState: {
    visible: false,
    isRefreshingToken: 'idle',
  },
  reducers: [
    handleAction('setVisibleModalUnauthorized', ({ state, action }) => {
      state.visible = action.payload;
    }),
    handleAction('setRefreshingToken', ({ state, action }) => {
      state.isRefreshingToken = action.payload;
    }),
  ],
});

export const sliceUnauthorizedSelector = (state: AppState) => state.global.unauthorized;

export const { setVisibleModalUnauthorized, setRefreshingToken } = sliceUnauthorized.actions;

export const useSetVisibleModalUnauthorized = createDispatchAction(setVisibleModalUnauthorized);
export const useSetRefreshingToken = createDispatchAction(setRefreshingToken);
