import { handleTagLiquidBeforeSyncShopify } from 'utils/LiquidSyntaxToTwig';
import { ExpectReturnType } from '../@types/ExpectReturnType';
import { getHtmlFiles } from '../getHtmlFiles';

export const handleCssInlines = (data: ReturnType<typeof getHtmlFiles>): ExpectReturnType[] => {
  return data.reduce<ExpectReturnType[]>((res, file) => {
    const html = handleTagLiquidBeforeSyncShopify({ liquidFile: file.content });
    return res.concat({
      ...file,
      content: html,
    });
  }, []);
};
