import { handleExtractResultForForceSync } from 'services/ShopifyConnection';

export const getDefaultPagesTurnOffSync = (
  params: ReturnType<typeof handleExtractResultForForceSync> | undefined,
): Record<string, ReturnType<typeof handleExtractResultForForceSync>['pagesParams'][number] | undefined> => {
  if (!params) {
    return {};
  }
  const { pagesParams } = params;
  return pagesParams.reduce((result, item) => {
    if (
      item.pageCommandId &&
      item.isSyncTurnedOff === null &&
      (item.pageType === 'account' ||
        item.pageType === 'activateAccount' ||
        item.pageType === 'addresses' ||
        item.pageType === 'login' ||
        item.pageType === 'order' ||
        item.pageType === 'register' ||
        item.pageType === 'resetPassword')
    ) {
      return {
        ...result,
        [item.pageCommandId]: {
          isSyncTurnedOff: true,
        },
      };
    }
    return result;
  }, {});
};
