import { getCacheValue, setCacheValue, getUniqId } from '../../../CacheShopifyFieldId';
import { ISCHEMA_SettingFlexOrder } from '../@types/ISCHEMA_SettingFlexOrder';
import { Option } from '../@types/SettingFlexOrder';

export const toShopifyFieldId = ({ field, parentField, section }: ISCHEMA_SettingFlexOrder, option: Option) => {
  const theoryShopifyFieldId = parentField ? `${parentField.name}___${field.name}__${option.name}` : `${field.name}__${option.name}`;
  if (theoryShopifyFieldId.length >= 25) {
    const valueInCache = getCacheValue({ theoryShopifyFieldId, section });
    if (valueInCache) {
      return valueInCache;
    } else {
      const newKey = `VEDA_flexOrder_${getUniqId('flexOrder')}`;
      setCacheValue({ theoryShopifyFieldId, section }, newKey);
      return newKey;
    }
  }
  return theoryShopifyFieldId;
};
