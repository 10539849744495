import { DataItem } from '@wiloke/ui';
import { i18n } from 'translation';
import { PageSectionType } from 'types/Sections';
import { PAGE_BUILD_HEADER_FOOTER } from 'utils/constants/constants';
import getPageInfo from 'utils/functions/getInfo';
import { getUserInfo } from 'utils/functions/getUserInfo';

export type Placement = 'in' | 'out';
export const getAddonsDropdown = (placement: Placement, editCodeEnabled: boolean): DataItem[] => {
  const { role, plan } = getUserInfo();
  return [
    { icon: 'code', label: `${i18n.t('general.edit')} code`, value: 'editcode', disabled: !editCodeEnabled },
    { icon: 'pen', label: i18n.t('general.rename'), value: 'rename' },
    { icon: 'clone', label: i18n.t('general.duplicate'), value: 'duplicate' },
    ...((placement === 'in' ? [{ icon: 'sort', label: i18n.t('general.reorder'), value: 'reorder' }] : []) as DataItem[]),
    ...([
      {
        icon: plan.planHandle === 'free' ? 'star' : 'file-export',
        label: i18n.t('general.export'),
        value: 'export',
        topDivider: true,
        iconCss(value) {
          return plan.planHandle === 'free'
            ? {
                color: value.colors.light,
                backgroundColor: value.colors.quaternary,
                width: '20px',
                height: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px',
              }
            : { color: value.colors.gray7 };
        },
      },
    ] as DataItem[]),
    ...((role === 'admin' || role === 'dev' ? [{ icon: 'cog', label: i18n.t('general.edit_info'), value: 'setting' }] : []) as DataItem[]),
    { icon: 'trash', label: i18n.t('builderPage.uninstall'), value: 'delete', topDivider: true },
  ];
};

const getDropdownWithSectionType = (type?: PageSectionType): DataItem[] => {
  const { plan } = getUserInfo();
  const pageId = getPageInfo('id');

  if (getPageInfo('entityVariant') !== 'Client') {
    return [];
  }

  switch (type) {
    case 'header':
      return [
        ...((pageId !== PAGE_BUILD_HEADER_FOOTER
          ? [
              {
                icon: 'arrow-alt-down',
                label: i18n.t('general.move_to', { text: i18n.t('general.main'), textTransform: 'capitalize' }),
                value: 'move_to_main',
                topDivider: true,
              },
            ]
          : []) as DataItem[]),
        {
          icon: 'arrow-alt-down',
          label: i18n.t('general.move_to', { text: i18n.t('general.footer'), textTransform: 'capitalize' }),
          value: 'move_to_footer',
        },
      ];
    case 'footer':
      return [
        ...((pageId !== PAGE_BUILD_HEADER_FOOTER
          ? [
              {
                icon: 'arrow-alt-up',
                label: i18n.t('general.move_to', { text: i18n.t('general.main'), textTransform: 'capitalize' }),
                value: 'move_to_main',
                topDivider: true,
              },
            ]
          : []) as DataItem[]),
        {
          icon: 'arrow-alt-up',
          label: i18n.t('general.move_to', { text: i18n.t('general.header'), textTransform: 'capitalize' }),
          value: 'move_to_header',
        },
      ];
    default:
      return [
        {
          icon: plan.planHandle === 'free' ? 'star' : 'arrow-alt-up',
          label: i18n.t('general.move_to', { text: i18n.t('general.header'), textTransform: 'capitalize' }),
          value: 'move_to_header',
          topDivider: true,
          iconCss(value) {
            return plan.planHandle === 'free'
              ? {
                  color: value.colors.light,
                  backgroundColor: value.colors.quaternary,
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                }
              : { color: value.colors.gray7 };
          },
        },
        {
          icon: plan.planHandle === 'free' ? 'star' : 'arrow-alt-down',
          label: i18n.t('general.move_to', { text: i18n.t('general.footer'), textTransform: 'capitalize' }),
          value: 'move_to_footer',
          iconCss(value) {
            return plan.planHandle === 'free'
              ? {
                  color: value.colors.light,
                  backgroundColor: value.colors.quaternary,
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                }
              : { color: value.colors.gray7 };
          },
        },
      ];
  }
};

export const getSectionDropdown = (
  editCodeEnabled: boolean,
  placement: Placement = 'out',
  type?: PageSectionType,
  _disablePaste?: boolean,
  _disableCopy?: boolean,
): DataItem[] => {
  const { role, plan } = getUserInfo();
  return [
    { icon: 'code', label: `${i18n.t('general.edit')} code`, value: 'editcode', disabled: !editCodeEnabled },
    // { icon: 'copy', label: 'Copy style', value: 'copy', disabled: disableCopy },
    // { icon: 'paste', label: 'Paste style', value: 'paste', disabled: disablePaste },
    { icon: 'pen', label: i18n.t('general.rename'), value: 'rename' },
    { icon: 'clone', label: i18n.t('general.duplicate'), value: 'duplicate' },
    ...((placement === 'in' ? [{ icon: 'sort', label: i18n.t('general.reorder'), value: 'reorder' }] : []) as DataItem[]),
    {
      icon: plan.planHandle === 'free' ? 'star' : 'file-export',
      label: i18n.t('general.export'),
      value: 'export',
      topDivider: true,
      iconCss(value) {
        return plan.planHandle === 'free'
          ? {
              color: value.colors.light,
              backgroundColor: value.colors.quaternary,
              width: '20px',
              height: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
            }
          : { color: value.colors.gray7 };
      },
    },
    {
      icon: plan.planHandle === 'free' ? 'star' : 'file-import',
      label: i18n.t('general.import'),
      value: 'import',
      iconCss(value) {
        return plan.planHandle === 'free'
          ? {
              color: value.colors.light,
              backgroundColor: value.colors.quaternary,
              width: '20px',
              height: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
            }
          : { color: value.colors.gray7 };
      },
    },
    ...getDropdownWithSectionType(type),
    ...((role === 'admin' || role === 'dev' ? [{ icon: 'cog', label: i18n.t('general.edit_info'), value: 'setting' }] : []) as DataItem[]),
    { icon: 'trash', label: i18n.t('general.delete'), value: 'delete', topDivider: true },
  ];
};
