import _ from 'lodash';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import {
  DragDropContext,
  DragDropContextProps,
  DragStart,
  DragUpdate,
  Draggable,
  DraggableProvidedDragHandleProps,
  DropResult,
  Droppable,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { useDeepCompareEffect } from 'react-use';
import { VedaLogo } from '../VedaLogo';
import { View, ViewProps } from 'wiloke-react-core';
import { useDelay } from '@wiloke/hooks';

interface DataDefault {
  id: string;
}

export interface RenderItemParam<T extends DataDefault> {
  item: T;
  index: number;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  isDragging: boolean;
}

export interface SortableProps<T extends DataDefault> extends Omit<DragDropContextProps, 'children'> {
  containerCss?: ViewProps['css'];
  data: T[];
  renderItem: ({ item, index, dragHandleProps }: RenderItemParam<T>) => ReactNode;
  keyExtractor?: (item: T) => string;
  itemCss?: ViewProps['css'];
  droppableId?: string;
  type?: string;
  onLoading?: (isLoading: boolean) => void;
  onDragCancel?: () => void;
}

const Loading: FC = () => {
  const [isLoadingState, setIsLoadingState] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoadingState(false);
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (!isLoadingState) {
    return null;
  }

  return (
    <View
      css={({ colors }) => ({
        position: 'absolute',
        inset: 0,
        zIndex: 999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: `rgba(${colors.rgbLight}, 0.6)`,
      })}
    >
      <VedaLogo variant="style3" />
    </View>
  );
};

export const Sortable = <T extends { id: string }>({
  containerCss,
  data,
  renderItem,
  keyExtractor = item => item.id,
  itemCss,
  droppableId = 'droppable',
  type,
  onLoading,
  onDragStart,
  onDragUpdate,
  onDragEnd,
  ...rest
}: SortableProps<T>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataState, setDataState] = useState<T[]>(data);
  const [result, setResult] = useState<DropResult | null>(null);
  const [provided, setProvided] = useState<ResponderProvided | null>(null);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [delay, cancel] = useDelay();
  const [delay2, cancel2] = useDelay();
  const prevTargetRef = useRef<EventTarget | null>(null);
  const [status, setStatus] = useState<'idle' | 'start' | 'update' | 'end'>('idle');
  const [resetList, setResetList] = useState(false);

  const handleDragStart = (initial: DragStart, provided: ResponderProvided) => {
    setStatus('start');
    setIsLoading(false);
    onDragStart?.(initial, provided);
  };

  const handleDragUpdate = (initial: DragUpdate, provided: ResponderProvided) => {
    setStatus('update');
    onDragUpdate?.(initial, provided);
  };

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { destination, source } = result;
    setStatus('end');
    if (!destination) {
      return;
    }
    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return;
    }
    const newData = _.cloneDeep(dataState);
    const [removed] = newData.splice(source.index, 1);
    newData.splice(destination.index, 0, removed);
    setIsLoading(true);
    onLoading?.(true);
    setDataState(newData);
    setResult(result);
    setProvided(provided);
  };

  useDeepCompareEffect(() => {
    // if (data.length !== dataState.length) => Dẫn đến lỗi "Thỉnh thoảng bấm vào Addon ra Section Settings"
    // Vì nếu section setting có length = 3 và addons setting cũng có length = 3 thì lúc bấm vào addon trong section thì sẽ không chạy setDataState(data) => không cập nhật lại setting của addon mà lại hiển thị setting của section
    setDataState(data);
  }, [data]);

  useEffect(() => {
    if (result && provided) {
      onDragEnd?.(result, provided);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, provided]);

  useDeepCompareEffect(() => {
    const dataId = data.map(item => item.id);
    const dataStateId = dataState.map(item => item.id);
    if (_.isEqual(dataId, dataStateId)) {
      setDataState(data);
      setIsLoading(false);
      onLoading?.(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataState]);

  useEffect(() => {
    return () => {
      cancel2();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View
      css={{ position: 'relative' }}
      onMouseDown={async () => {
        if (status === 'start' || status === 'update') {
          setResetList(true);
          await delay2(1000 / 60);
          setResetList(false);
        }
      }}
      onMouseUp={() => {
        cancel2();
      }}
      onMouseMove={async event => {
        if (event.movementY > 10 && event.target !== prevTargetRef.current) {
          setOverlayVisible(true);
        }
        prevTargetRef.current = event.target;
        cancel();
        await delay(200);
        setOverlayVisible(false);
      }}
    >
      {isLoading && <Loading />}
      {overlayVisible && <View css={{ position: 'absolute', top: 0, left: 0, bottom: 0, width: '50px', zIndex: 9999, cursor: 'pointer' }} />}
      {!resetList && (
        <DragDropContext {...rest} onDragStart={handleDragStart} onDragUpdate={handleDragUpdate} onDragEnd={handleDragEnd}>
          <Droppable type={type} droppableId={droppableId}>
            {provided => (
              <View {...provided.droppableProps} ref={provided.innerRef} css={containerCss}>
                {dataState.map((item, index) => {
                  return (
                    <Draggable key={keyExtractor(item)} draggableId={item.id} index={index}>
                      {(provided, snapshot) => {
                        return (
                          <View
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              ...(!!provided.draggableProps.style?.transform
                                ? { transform: `${provided.draggableProps.style?.transform?.replace(/\(.*,/g, '(0,')}` }
                                : {}),
                            }}
                            css={itemCss}
                          >
                            {renderItem({ item, index, dragHandleProps: provided.dragHandleProps, isDragging: snapshot.isDragging })}
                          </View>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </View>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </View>
  );
};
