import { Button, MyModal } from '@wiloke/ui';
import { notification } from 'antd';
import Field from 'components/Field';
import SelectAntd from 'components/SelectAntd';
import { TextEditor2 } from 'components/TextEditor2';
import TextInput from 'components/TextInput';
import withDebounce from 'hocs/withDebounce';
import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { i18n } from 'translation';
import { ProductTheme } from 'types/Theme';
import { PLAN_HANDLE } from 'utils/constants/PlanEnum';
import { Divider, View } from 'wiloke-react-core';
import { usePublishThemeAtom } from '../store/actions/actionThemesAtom';
import { useCreateCategoryOfThemeProduct, useGetCategoriesOfThemeProduct } from '../store/actions/actionThemesProduct';
import { themesAtomSelector, useModalPublishThemeAtom } from '../store/reducers/sliceThemesAtom';
import { themesProductSelector, transformUserThemeCategories } from '../store/reducers/sliceThemesProduct';

const DebounceInput = withDebounce(TextInput, 'value', 'onValueChange');
const TextEditorDebounce = withDebounce(TextEditor2, 'value', 'onChange');

interface Actions {
  type: 'setInfoPublishTheme';
  payload: Partial<State>;
}
interface State {
  zipUrl: string;
  plan: PLAN_HANDLE;
  category: { name: string; description: string };
  createdCategory: { name: string; description: string };
  group: ProductTheme['group'];
  envatoThemeId: ProductTheme['envatoThemeId'];
  documentUrl: string;
  buyNowUrl: string;
  demoUrl?: string;
  version?: string;
  description?: string;
}

const defaultState: State = {
  buyNowUrl: '',
  category: {
    description: '',
    name: '',
  },
  createdCategory: {
    description: '',
    name: '',
  },
  documentUrl: '',
  envatoThemeId: undefined,
  group: 'built_in',
  plan: 'free',
  zipUrl: '',
  demoUrl: '',
  description: '',
  version: '',
};

const myReducer = (state: State, action: Actions) => {
  switch (action.type) {
    case 'setInfoPublishTheme': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

const plans: Array<{ label: string; value: PLAN_HANDLE }> = [
  {
    value: 'free',
    label: 'Free',
  },
  {
    value: 'business',
    label: 'Business',
  },
  {
    value: 'advanced',
    label: 'Advanced',
  },
  {
    value: 'ultimate',
    label: 'Ultimate',
  },
];

export const ModalPublishTheme = () => {
  const { modalPublishThemeAtom, publishStatus } = useSelector(themesAtomSelector);
  const { createStatus, categories: originCategories } = useSelector(themesProductSelector);
  const categories = useSelector(transformUserThemeCategories);

  const setModalPublishThemeAtom = useModalPublishThemeAtom();
  const publishThemeAtom = usePublishThemeAtom();
  const getCategoriesOfThemeProduct = useGetCategoriesOfThemeProduct();
  const createCategoryOfThemeProduct = useCreateCategoryOfThemeProduct();

  const [
    { buyNowUrl, category, createdCategory, documentUrl, envatoThemeId, group, plan, zipUrl, demoUrl, description, version },
    dispatch,
  ] = useReducer(myReducer, {
    ...defaultState,
  });

  useEffect(() => {
    if (modalPublishThemeAtom) {
      getCategoriesOfThemeProduct.request(undefined);
    }
    if (modalPublishThemeAtom && modalPublishThemeAtom.vedaProductRepresent) {
      dispatch({
        type: 'setInfoPublishTheme',
        payload: {
          category: {
            name: modalPublishThemeAtom.vedaProductRepresent.category?.name ?? '',
            description: modalPublishThemeAtom.vedaProductRepresent?.category?.description ?? '',
          },
          zipUrl: modalPublishThemeAtom.vedaProductRepresent.zipUrl ?? '',
          plan: modalPublishThemeAtom.vedaProductRepresent.planHandle ?? 'free',
          documentUrl: modalPublishThemeAtom.vedaProductRepresent.documentUrl ?? '',
          group: modalPublishThemeAtom.vedaProductRepresent.group ?? 'built_in',
          buyNowUrl: modalPublishThemeAtom.vedaProductRepresent.buyNowUrl ?? '',
          envatoThemeId: modalPublishThemeAtom.vedaProductRepresent.envatoThemeId ?? undefined,
          demoUrl: modalPublishThemeAtom.vedaProductRepresent.demoUrl ?? '',
          description: modalPublishThemeAtom.vedaProductRepresent.description ?? '',
          version: modalPublishThemeAtom.vedaProductRepresent.version ?? '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalPublishThemeAtom]);

  const handlePublish = () => {
    const theme = modalPublishThemeAtom;
    if (theme) {
      const _cate = originCategories.find(item => item.name === category.name);

      publishThemeAtom.request({
        themeAtom: theme,
        categoryOfProduct: _cate,
        zipUrl,
        group,
        envatoThemeId,
        documentUrl,
        buyNowUrl,
        plan,
        demoUrl,
        description,
        version,
      });
    } else {
      notification.error({
        message: 'Publish theme product failed',
      });
    }
  };

  const handleCancel = () => {
    setModalPublishThemeAtom(undefined);
    dispatch({
      type: 'setInfoPublishTheme',
      payload: defaultState,
    });
  };

  if (!modalPublishThemeAtom) {
    return null;
  }

  return (
    <MyModal
      size="medium"
      headerText={`${i18n.t('general.publish', { text: i18n.t('general.on', { text: i18n.t('general.application') }) })}`}
      okText={i18n.t('general.publish')}
      isVisible
      onCancel={handleCancel}
      onOk={handlePublish}
      isLoading={publishStatus === 'loading'}
    >
      <Field label={i18n.t('general.plan')}>
        <SelectAntd
          value={plan}
          onChange={val => {
            dispatch({
              type: 'setInfoPublishTheme',
              payload: {
                plan: val,
              },
            });
          }}
          data={[{ label: 'None', value: '' }, ...plans]}
        />
      </Field>

      <Field label={i18n.t('general.category')}>
        <SelectAntd
          data={categories}
          value={category.name}
          onChange={(val, opt: any) => {
            dispatch({
              type: 'setInfoPublishTheme',
              payload: {
                category: {
                  name: val,
                  description: opt.children,
                },
              },
            });
          }}
          dropdownRender={menu => {
            return (
              <>
                {menu}
                <Divider />
                <View css={{ padding: '8px 0 4px 0', display: 'flex', justifyContent: 'space-between' }}>
                  <View columns={[10, 10, 10]}>
                    <DebounceInput
                      value={createdCategory.name}
                      block
                      placeholder={i18n.t('builderPage.save_for_builder.please_enter_item')}
                      sizeInput="small"
                      css={{ width: '100%', height: '38px' }}
                      onValueChange={val => {
                        dispatch({
                          type: 'setInfoPublishTheme',
                          payload: {
                            createdCategory: {
                              description: val,
                              name: val,
                            },
                          },
                        });
                      }}
                    />
                  </View>

                  <View columns={[2, 2, 2]}>
                    <Button
                      radius={4}
                      size="extra-small"
                      block
                      loading={createStatus === 'loading'}
                      onClick={() => {
                        if (createdCategory.name) {
                          createCategoryOfThemeProduct.request({
                            name: createdCategory.name,
                            description: createdCategory.description,
                          });

                          dispatch({
                            type: 'setInfoPublishTheme',
                            payload: {
                              createdCategory: {
                                description: '',
                                name: '',
                              },
                            },
                          });
                        }
                      }}
                    >
                      {i18n.t('builderPage.save_for_builder.add_category')}
                    </Button>
                  </View>
                </View>
              </>
            );
          }}
        />
      </Field>

      <Field label="Document url">
        <DebounceInput
          block
          value={documentUrl}
          onValueChange={val => {
            dispatch({
              type: 'setInfoPublishTheme',
              payload: {
                documentUrl: val,
              },
            });
          }}
        />
      </Field>

      {/* TODO: I18n */}
      <Field label="Zip url" note="Đường dẫn file zip của theme">
        <DebounceInput
          block
          value={zipUrl}
          onValueChange={val => {
            dispatch({
              type: 'setInfoPublishTheme',
              payload: {
                zipUrl: val,
              },
            });
          }}
        />
      </Field>

      {/* TODO: I18n */}
      <Field label="Group">
        <SelectAntd
          value={group}
          onChange={val => {
            dispatch({
              type: 'setInfoPublishTheme',
              payload: {
                group: val,
              },
            });
          }}
          data={[
            { value: 'built_in', label: 'Built in' },
            { value: 'envato', label: 'Envato' },
          ]}
        />
      </Field>
      <Field label="Buy Now Url">
        <DebounceInput
          block
          value={buyNowUrl}
          onValueChange={val => {
            dispatch({
              type: 'setInfoPublishTheme',
              payload: {
                buyNowUrl: val,
              },
            });
          }}
        />
      </Field>
      {/* TODO: I18n */}
      {group === 'envato' && (
        <Field label="Envato Theme Id">
          <DebounceInput
            block
            value={envatoThemeId}
            onValueChange={val => {
              dispatch({
                type: 'setInfoPublishTheme',
                payload: {
                  envatoThemeId: val,
                },
              });
            }}
          />
        </Field>
      )}

      <Field label="Demo Url">
        <DebounceInput
          block
          value={demoUrl}
          onValueChange={val => {
            dispatch({
              type: 'setInfoPublishTheme',
              payload: {
                demoUrl: val,
              },
            });
          }}
        />
      </Field>

      <Field label="Version">
        <DebounceInput
          block
          value={version}
          onValueChange={val => {
            dispatch({
              type: 'setInfoPublishTheme',
              payload: {
                version: val,
              },
            });
          }}
        />
      </Field>

      <Field label="Description">
        <TextEditorDebounce
          value={description}
          onChange={val => {
            dispatch({
              type: 'setInfoPublishTheme',
              payload: {
                description: val,
              },
            });
          }}
        />
      </Field>
    </MyModal>
  );
};
