import { strToRegexpPattern } from '@wiloke/functions';
import { getMatches } from './getMatches';

/**
 * Function đảm check tồn tại chính xác string và bỏ qua các trường hợp string đó là substring của 1 string khác
 */
export const isExistExactShopifyVariableInBOC = (liquid: string, source: string) => {
  const matches = getMatches(liquid, new RegExp(`.${strToRegexpPattern(source)}.`, 'g'));

  if (!matches.length) {
    return false;
  }
  return matches.find(match => {
    if (typeof match === 'string') {
      const isStartValid = match.startsWith(' ') || match.startsWith('\n');
      const isEndValid =
        match.endsWith('.') ||
        match.endsWith(' ') ||
        match.endsWith('\n') ||
        // Trường hợp collections[product_settings.collection]
        match.endsWith(']');
      return isStartValid && isEndValid;
    }
    return false;
  });
};
