import { Styles } from 'wiloke-react-core';

export const filterTemplatePageContainer: Styles = {
  className: 'FilterTemplatePageContainer',
  display: 'flex',
  alignItems: 'center',
  padding: '20px 0',
  width: '100%',
  justifyContent: 'space-between',
};

export const leftAction: Styles = {
  display: 'flex',
  alignItems: 'center',
  columnGap: '10px',
};

export const px300: Styles = {
  width: '300px',
};

export const loadMoreContainer: Styles = {
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
};

export const filterAction: Styles = {
  width: '600px',
  display: 'flex',
  columnGap: '10px',
};
