import { css, Theme } from 'wiloke-react-core';

export const container = ({ colors }: Theme) => css`
  debug: RecommendCard-container;
  background-color: ${colors.light};
  min-height: 110px;
  overflow: hidden;
`;

export const container2 = ({ colors }: Theme) => css`
  debug: RecommendCard-container2;
  border-radius: 6px;
  overflow: hidden;
  background-color: ${colors.light};
  border: 1px solid ${colors.gray2};
  padding: 20px;
`;

export const appImage = css`
  debug: RecommendCard-app-image;
`;

export const img = css`
  overflow: hidden;
  border-radius: 6px;
  max-height: 250px;
  margin-bottom: 15px;
  height: 100%;
`;

export const content = css`
  debug: RecommendCard-content;
  flex: 1;
`;

export const action = css`
  debug: RecommendCard-action;

  display: flex;
  flex-direction: column;
  padding-top: 10px;
  justify-content: space-between;
`;

export const descriptionContainer = css``;

export const description = css`
  font-size: 13px;
  line-height: 19px;
  font-weight: 400;
`;

export const title = css`
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 5px;
`;

export const users = css`
  display: inline-flex;
`;

export const userInner = ({ colors }: Theme) => css`
  border: 1px solid ${colors.light};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;

  &:not(:last-child) {
    margin-right: -20px;
  }
`;

export const userInnerExpand = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;
