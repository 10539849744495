import AddonListItem from 'components/AddonListItem';
import { useStackNavigator, Button, ScrollBars, Empty, Tooltip } from '@wiloke/ui';
import SectionDropDown from 'containers/BuilderPage/components/SectionDropDown/SectionDropDown';
import { LeftBarParamList } from 'containers/BuilderPage/components/SidebarScreen/SidebarScreen';
import UpdateVersion from 'containers/BuilderPage/components/UpdateVersion/UpdateVersion';
import { addonsPositionStartSelector } from 'containers/BuilderPage/store/addonPosition/slice';
import { useSetTemplateBoardVisible } from 'containers/ChooseTemplate/store/actions/actionTemplateBoardVisible';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSetSectionIdActive } from 'store/actions/actionSectionIdActive';
import { sectionIdHoverSelector, useSetSectionIdHover } from 'store/global/sectionIdHover/slice';
import { useSetActiveAddon } from 'store/global/themeAddons';
import {
  authSelector,
  mainSectionsSelector,
  pageSectionsSelector,
  pagesSelector,
  sectionIdActiveSelector,
  themeAddonsSelector,
} from 'store/selectors';
import { i18n } from 'translation';
import { AddonBelongsToPage, AddonOfTheme_Atom_N_Client, ThemeAddon } from 'types/Addons';
import { pmParent } from 'utils/functions/postMessage';
import { ActivityIndicator, FontAwesome, Text, View } from 'wiloke-react-core';
import * as styles from './styles';
import { SettingSelect } from 'types/Schema';
import { tourGuideSelector, useSetTourGuideAddon } from 'containers/TourGuides/sliceTourGuides';
import { isPageBuilder } from 'utils/validateBuilderMode';
import getPageInfo from 'utils/functions/getInfo';
import { useUpdateAddonsInPage } from 'store/actions/versions/actionSectionVersion';

const iframeContainer = document.createElement('div');
iframeContainer.id = 'fake-iframe-editor-code-container';
document.body.append(iframeContainer);

const LeftBarAddons: FC = () => {
  const sectionIdActive = useSelector(sectionIdActiveSelector);
  const pageSections = useSelector(pageSectionsSelector);
  const themeAddons = useSelector(themeAddonsSelector);
  const navigation = useStackNavigator<LeftBarParamList>();
  const setSectionIdActive = useSetSectionIdActive();
  const addonsPositionStart = useSelector(addonsPositionStartSelector);
  const { addonsTour } = useSelector(tourGuideSelector);
  const { updateAllVersionAddonsStatus } = useSelector(pagesSelector);

  const setTemplateBoardVisible = useSetTemplateBoardVisible();
  const setActiveAddon = useSetActiveAddon();
  const setSectionIdHover = useSetSectionIdHover();
  const sectionIdHover = useSelector(sectionIdHoverSelector);
  const mainSections = useSelector(mainSectionsSelector);
  const setAddonTour = useSetTourGuideAddon();
  const addonsData =
    isPageBuilder() && getPageInfo('entityVariant') === 'Atom'
      ? (themeAddons.data as AddonOfTheme_Atom_N_Client[]).filter(item => item.isAddonOfPage)
      : themeAddons.data;
  const { role } = useSelector(authSelector);
  const _navs = role === 'admin' ? ['admin', 'Addons'] : ['addons'];

  const hasMainSections = mainSections.length > 0;
  const updateAddonsInPage = useUpdateAddonsInPage();

  useEffect(() => {
    if (!addonsPositionStart.value && !!sectionIdActive) {
      navigation.navigate('fieldSettingsScreen');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addonsPositionStart.value, sectionIdActive]);

  const getLabel = (item: ThemeAddon) => {
    const { settings } = item.body.data;
    const settingNameType = settings.find(setting => setting.name === 'type' && setting.type === 'select');
    if (settingNameType) {
      const { options } = settingNameType as SettingSelect;
      const nameActive = options.find(option => option.value === settingNameType.children)?.label ?? '';
      return `${item.label} - ${nameActive}`;
    }
    return item.label;
  };

  const renderAddonsItem = (item: ThemeAddon) => {
    const sectionLabels = pageSections.reduce<string[]>((arr, section) => {
      if (section.addonIds?.includes(item.sectionId)) {
        return [...arr, section.label];
      }
      return arr;
    }, []);

    const belongsToPages = (item as AddonOfTheme_Atom_N_Client)?.belongsToPage;

    const pageLabels: AddonBelongsToPage[] = Array.isArray(belongsToPages) && belongsToPages?.length > 0 ? belongsToPages : [];

    return (
      <View
        key={item.id}
        onMouseEnter={() => {
          setSectionIdHover(item.sectionId);
        }}
        onMouseLeave={() => {
          setSectionIdHover('');
        }}
        onClick={() => {
          setActiveAddon({ addon: item });
          navigation.navigate('fieldSettingsScreen');
          setSectionIdActive(item.sectionId);
          pmParent.emit('@section/sectionIdActive', item.sectionId);
        }}
      >
        <AddonListItem
          key={item.id}
          image={item.logo}
          label={getLabel(item)}
          description={item.tagLine}
          active={!!item.sectionId && sectionIdHover === item.sectionId}
          Right={
            <>
              <View
                onClick={event => {
                  event.stopPropagation();
                  setActiveAddon({ addon: item });
                }}
              >
                <SectionDropDown sectionId={item.sectionId} />
              </View>
              <UpdateVersion type="addons" item={item} />
            </>
          }
          Footer={
            <>
              {!!sectionLabels.length && (
                <View>
                  <Text tagName="span" size={13} css={{ fontStyle: 'italic', marginRight: '4px' }}>
                    Used in sections:
                  </Text>
                  {sectionLabels.map((label, index) => {
                    return (
                      <Text
                        key={index}
                        tagName="span"
                        color="primary"
                        size={12}
                        radius={4}
                        css={({ colors }) => ({
                          backgroundColor: `rgba(${colors.rgbPrimary}, 0.2)`,
                          padding: '2px 5px',
                          marginRight: '3px',
                          marginBottom: '3px',
                        })}
                      >
                        {label}
                      </Text>
                    );
                  })}
                </View>
              )}

              {pageLabels.length > 0 && (
                <View>
                  <Text tagName="span" size={13} css={{ fontStyle: 'italic', marginRight: '4px' }}>
                    Belongs to pages:
                  </Text>
                  {pageLabels.map(page => {
                    return (
                      <Text
                        key={page.commandId}
                        tagName="span"
                        color="tertiary"
                        size={12}
                        radius={4}
                        css={({ colors }) => ({
                          backgroundColor: `rgba(${colors.rgbTertiary}, 0.2)`,
                          padding: '2px 5px',
                          marginRight: '3px',
                          marginBottom: '3px',
                        })}
                      >
                        {page.label}
                      </Text>
                    );
                  })}
                </View>
              )}
            </>
          }
        />
      </View>
    );
  };

  const _handleOpenModal = () => {
    setTemplateBoardVisible({ visible: true, navKeys: _navs });
    setAddonTour({ run: addonsTour.run, stepIndex: 1 });
  };

  const updateAllAddons = () => {
    updateAddonsInPage.request({
      prevAddons: themeAddons.data,
    });
  };

  if (!hasMainSections) {
    return (
      <View css={{ padding: '20px' }}>
        {/** TODO: i18n */}
        <Empty text="Your page doesn't have any sections yet. Please add sections to be able to display and customize the add-ons" />
      </View>
    );
  }

  return (
    <>
      <ScrollBars css={{ height: 'calc(100% - 130px) !important' }}>
        <View css={styles.content}>{addonsData.map(renderAddonsItem)}</View>
      </ScrollBars>

      <View css={styles.footer}>
        <Button
          id="button-add-addon"
          backgroundColor="primary"
          size="small"
          block
          radius={6}
          fontFamily="secondary"
          css={styles.btn}
          onClick={_handleOpenModal}
        >
          {i18n.t('general.add', { text: i18n.t('general.addons'), textTransform: 'capitalize' })}
        </Button>

        <Tooltip
          text={
            <View css={{ maxWidth: '400px' }}>
              Force update all addons. You still need to click the Publish button in the top right corner to sync the data to your Store.
            </View>
          }
          portal
        >
          <Button
            onClick={updateAllAddons}
            disabled={themeAddons.data.length === 0}
            backgroundColor="gray2"
            size="small"
            block
            radius={6}
            css={styles.clearBtn}
          >
            {updateAllVersionAddonsStatus === 'loading' ? (
              <ActivityIndicator size={20} />
            ) : (
              <FontAwesome type="far" name="arrow-from-bottom" color="gray8" size={20} />
            )}
          </Button>
        </Tooltip>
      </View>
    </>
  );
};

export default LeftBarAddons;
