import { ThemeInfo } from '../@types/Shopify/ThemeConfigs/ThemeInfo';
import { ThemeSchema } from '../@types/Shopify/ThemeConfigs/ThemeSchema';
import { ThemeSetting } from '../@types/Shopify/ThemeConfigs/ThemeSetting';
import { ThemeSettings } from './@types/ThemeSettings';
import { SCHEMA_SettingColors, SETTING_SettingColors } from './SettingColors/SettingColors';
import { SCHEMA_SettingLogo } from './SettingLogo/SettingLogo';
import { SCHEMA_SettingFonts, SETTING_SettingFonts } from './SettingFonts/SettingFonts';
import { SCHEMA_SettingLayout, SETTING_SettingLayout } from './SettingLayout/SettingLayout';
import { getThemeSlug } from './SettingLayout/utils/getThemeSlug';
import { SCHEMA_SettingColorSchemes, SETTING_SettingColorSchemes } from './SettingColorSchemes/SettingColorSchemes';

interface RTThemeSettingsConverter {
  themeInfo: ThemeInfo;
  themeSchema: ThemeSchema[];
  themeSetting: ThemeSetting;
}

interface ThemeSettingsConverter extends Pick<ThemeSettings, 'cssVariables' | 'generalSettings' | 'layoutSettings' | 'colorSchemes'> {
  themeName?: string;
  themeAuthor?: string;
  themeVersion?: string;
  themeDocumentUrl?: string;
  themeSupportUrl?: string;
  themeSlug?: string;
}

export const themeSettingsConverter = ({
  cssVariables,
  layoutSettings,
  colorSchemes,
  themeAuthor = 'Vedabuilder',
  themeDocumentUrl = 'https://help.shopify.com/manual/online-store/themes',
  themeName = 'Veda',
  themeSupportUrl = 'https://support.shopify.com/',
  themeVersion = '1.0',
  themeSlug = getThemeSlug(themeName),
}: ThemeSettingsConverter): RTThemeSettingsConverter => {
  const { colors, fonts } = cssVariables;

  const themeInfo_: RTThemeSettingsConverter['themeInfo'] = {
    name: 'theme_info',
    theme_name: themeName,
    theme_author: themeAuthor,
    theme_version: themeVersion,
    theme_documentation_url: themeDocumentUrl,
    theme_support_url: themeSupportUrl,
    theme_slug: themeSlug,
  };

  const themeSchema_: RTThemeSettingsConverter['themeSchema'] = [
    SCHEMA_SettingLogo(),
    SCHEMA_SettingColorSchemes({ colorSchemes }),
    SCHEMA_SettingFonts({ fonts }),
    SCHEMA_SettingColors({ colors }),
    SCHEMA_SettingLayout({ layout: layoutSettings }),
    // https://www.notion.so/myshopkitmarketing/B-sung-ph-n-Review-App-v-o-theme-settings-d0c697779ec547b391797f1124ea19fe?pvs=4
    // Fix cứng theo yêu cầu của Nguyen Dttn
    {
      name: 'Review Settings',
      settings: [
        {
          type: 'radio',
          default: 'shopify',
          label: 'Shopify review app',
          id: 'shopify_review_app',
          options: [
            {
              label: 'Shopify review [install here](https://apps.shopify.com/product-reviews?ref=myshopkit)',
              value: 'shopify',
            },
            {
              label: 'Judge.me [install here](https://apps.shopify.com/judgeme?ref=myshopkit)',
              value: 'judgeme',
            },
            {
              label: 'Ryviu [install here](https://apps.shopify.com/ryviu?ref=myshopkit)',
              value: 'ryviu',
            },
            {
              label: 'Ryviu [install here](https://apps.shopify.com/ali-reviews?ref=myshopkit)',
              value: 'alireviews',
            },
            {
              label: 'Another app',
              value: 'other',
            },
          ],
        },
      ],
    },
  ];

  const themeSetting_: RTThemeSettingsConverter['themeSetting'] = {
    ...SETTING_SettingColors({ colors }),
    ...SETTING_SettingColorSchemes({ colorSchemes }),
    ...SETTING_SettingFonts({ fonts }),
    ...SETTING_SettingLayout({ layout: layoutSettings }),
  };

  return {
    themeSetting: themeSetting_,
    themeInfo: themeInfo_,
    themeSchema: themeSchema_,
  };
};
