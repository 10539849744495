import Field from 'components/Field';
import FieldGroup from 'components/FieldGroup';
import { BusinessIcon } from 'components/PlanBadge';
import SelectAntd from 'components/SelectAntd';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';
import { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from 'store/selectors';
import { i18n } from 'translation';
import { PLAN_STATIC_DATA, VEDA_FUNCTION_ENUM } from 'utils/constants/PlanEnum';
import { pmParent } from 'utils/functions/postMessage';
import { validatePlan } from 'utils/functions/validatePlan';
import { View } from 'wiloke-react-core';
import { AnimateField } from './AnimateField';
import { Animate, CssAnimation } from './types';

export interface AnimationProps {
  value: CssAnimation;
  onChange?: (value: CssAnimation) => void;
}

export const Animation: FC<AnimationProps> = ({ value, onChange }) => {
  const [animateType, setAnimateType] = useState<CssAnimation['type']>(value.type);
  const [animate, setAnimate] = useState<Animate>(value.animate);
  const mountedRef = useRef(false);
  const visibleRef = useRef(value.animate !== '');
  const { plan, role } = useSelector(authSelector);

  const isDisabled = validatePlan({ userPlan: plan.planHandle, itemPlan: VEDA_FUNCTION_ENUM.animation_field, role }) === 'invalid';

  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();

  useEffect(() => {
    if (mountedRef.current) {
      onChange?.({ animate, type: animateType });
      pmParent.emit('@animate');
    }

    mountedRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animate, animateType]);

  const disabledIcon = isDisabled ? (
    <View css={{ display: 'inline-flex', padding: '4px' }} radius={4} backgroundColor="tertiary">
      <BusinessIcon />
    </View>
  ) : null;

  const handleUpgrade = () => {
    if (isDisabled) {
      setPlanRequesting({
        handle: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.animation_field].handle,
        type: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.animation_field].type,
        pricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.animation_field].pricePerMonth,
        pricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.animation_field].pricePerYear,
        title: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.animation_field].title,
        originalPricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.animation_field].pricePerMonth,
        originalPricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.color_scheme].pricePerYear,
      });
      setValidateCouponVisible(true);
      return;
    }
  };

  return (
    <FieldGroup
      visible={visibleRef.current}
      label={i18n.t('general.animation')}
      containerCss={{ marginBottom: '5px' }}
      contentCss={{ paddingBottom: 0 }}
      onToggle={() => {
        pmParent.emit('@animate');
      }}
    >
      <Field
        label={
          <View>
            {i18n.t('general.choose', { text: i18n.t('general.animation'), textTransform: 'capitalize' })}
            &nbsp;
            {disabledIcon}
          </View>
        }
        fontSize={13}
        onClick={handleUpgrade}
        css={{ cursor: isDisabled ? 'pointer' : 'auto' }}
      >
        <AnimateField value={animate} onChange={setAnimate} disabled={isDisabled} />
      </Field>
      <Field
        label={
          <View>
            {i18n.t('general.animation_type')}
            &nbsp;
            {disabledIcon}
          </View>
        }
        fontSize={13}
        onClick={handleUpgrade}
        css={{ cursor: isDisabled ? 'pointer' : 'auto' }}
      >
        <SelectAntd
          value={animateType}
          disabled={isDisabled}
          data={[
            {
              label: i18n.t('general.repeat'),
              value: 'infinite',
            },
            {
              label: i18n.t('general.once'),
              value: '',
            },
            {
              label: i18n.t('general.scroll_down_to_run'),
              value: 'scroll',
            },
          ]}
          onChange={setAnimateType}
        />
      </Field>
    </FieldGroup>
  );
};
