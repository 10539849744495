import { renewVersion } from 'utils/CacheControl/VedaClientCacheControl';
import { pageApis } from '../apis';

interface Params {
  commandId: string;
}

export const deletePageFavorite = async ({ commandId }: Params) => {
  const response = await pageApis.vedaApplication.userApi.page.deleteFavorite({ commandId });
  renewVersion();
  return response;
};
