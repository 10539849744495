import { Result } from 'types/Result';
import { PageSection } from 'types/Sections';
import { isFooter, isHeader } from 'utils/functions/checkSectionType';

interface RT {
  headerSections: Exclude<Result['theme']['header'], undefined>;
  footerSections: Exclude<Result['theme']['footer'], undefined>;
}

export const getHeaderFooterSections = (sections: PageSection[]) => {
  return sections.reduce<RT>(
    (res, section) => {
      if (isHeader(section.type) && res.headerSections) {
        return {
          ...res,
          headerSections: res.headerSections.concat(section),
        };
      }
      if (isFooter(section.type) && res.footerSections) {
        return {
          ...res,
          footerSections: res.footerSections.concat(section),
        };
      }
      return res;
    },
    {
      headerSections: [],
      footerSections: [],
    },
  );
};
