import { loadStyle, objectEntries } from '@wiloke/functions';
import { useSectionIdCodeVisible } from 'containers/IframePage/globalState';
import { useIframeSelector } from 'containers/IframePage/hooks/useIframeSelector';
import { addAttrDataAddonsAndAddonContext, addAttrDataMegamenu, addAttrDataTag, addAttrVedaComponentIndex2 } from 'containers/IframePage/utils';
import { atomic } from 'mota-css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createGlobalState, useDeepCompareEffect } from 'react-use';
import { liquidSnippetsSelector } from 'store/global/globalSnippets/sliceGlobalSnippets';
import { liquidVariablesSelector } from 'store/selectors';
import { getLocale } from 'translation';
import { PageSection } from 'types/Sections';
import { handleGetTwigScope } from 'utils/LiquidSyntaxToTwig';
import { Consts } from 'utils/constants/constants';
import { createWorker } from 'utils/createWorker';
import { inlineCss } from 'utils/functions/InlineCss';
import { isSectionAddons, isSectionMegamenu } from 'utils/functions/checkSectionType';
import { getBuilderPageReduxStore } from 'utils/getParentStore';
import { handleRenderTagBeforeAll } from './liquidEngine/helpers/handleRenderTagBeforeAll';

import liquidWorker from './liquidEngine/liquid.worker';

const getHtml = (value: string) => {
  let index = -1;
  const result = value.replace(new RegExp(`<${Consts.FakeTags.AddElement} \\/>`, 'g'), () => {
    index++;
    return `<${Consts.FakeTags.AddElement} index="${index}" />`;
  });
  return result.trim();
};

interface UseTwig {
  section: PageSection;
  addonScope?: ReturnType<typeof handleGetTwigScope>;
}

const { id_attributeName, signal_attributeName, signal_attributeValue, signal_needUpdate, tagName, hidden_css } = Consts.FakeTags.SignalRefreshJs;

const useGlobalLoading = createGlobalState<Record<string, boolean>>({});

const useTwig = ({ section, addonScope }: UseTwig) => {
  const isAddons = isSectionAddons(section.type);
  const isMegamenu = isSectionMegamenu(section.type);

  const { id, addonIds } = section;
  const { settings, liquid } = section.data;
  const [html, setHtml] = useState('');
  const {
    statusGetInitialOfLiquidVariables,
    blogSlugsLoaded,
    pageSlugsLoaded,
    productIdsLoaded,
    collectionIdsLoaded,
    translationLocalesLoaded,
    data,
  } = useIframeSelector(liquidVariablesSelector);
  const liquidSnippets = useIframeSelector(liquidSnippetsSelector);
  const locale = getLocale();
  const htmlCompiled = useRef('');
  const cssCompiled = useRef('');
  const [sectionIdCodeVisible] = useSectionIdCodeVisible();
  const codeVisible = useMemo(() => sectionIdCodeVisible === id, [id, sectionIdCodeVisible]);
  const prevButton = useRef('');
  const prevTemplate = useRef('');
  const [loading, setLoading] = useGlobalLoading();
  const liquidThread = useRef<Worker | null>(null);

  useEffect(() => {
    // Thay vì tìm html thì ta sẽ chuyển sang tìm liquid
    // Lý do là không chơi nối chuỗi class mota nữa
    atomic.find(liquid);
  }, [liquid]);

  useDeepCompareEffect(() => {
    setLoading(state => ({ ...state, [id]: true }));
    const state = getBuilderPageReduxStore().getState();
    const twigScope = handleGetTwigScope({
      sectionSettings: settings,
      section,
      variant: 'Preview tại veda -> Cần shopify scope + thay thế value của field navigation',
      builderMode: true,
      addonScope,
    });
    // Việc thêm attr để check nhiều thứ khác (như thêm addon vào forloop, ...) -> Cần thêm vào ngay cả khi preview -> Muốn xoá thì tại LiquidComponent.tsx  attribs của domNode đi

    let _liquid = addAttrDataTag(addAttrDataMegamenu(addAttrDataAddonsAndAddonContext(liquid, isAddons, addonIds), isMegamenu));
    _liquid = handleRenderTagBeforeAll(_liquid, state);

    // _liquid = form(_liquid).replace(/{%\s+set\s/g, '{% assign ');

    const css = inlineCss.getCssFromSettings(settings);
    liquidThread.current = createWorker(liquidWorker);

    liquidThread.current.postMessage({
      type: 'STORE',
      payload: {
        state,
        twigScope,
        settings,
        liquid: _liquid,
        isAddons,
        isMegamenu,
      },
    });

    const handleLiquidThreadMessage = (event: MessageEvent<string>) => {
      const compiled = event.data as string;

      const html = getHtml(addAttrVedaComponentIndex2(compiled, settings)) as string;

      // FIXME: i18n
      htmlCompiled.current = html.replace(
        /__PRODUCT_INFORMATION__/g,
        'This area allows you to add various addons to enhance your shop. Click to view all addons.',
      );
      cssCompiled.current = css;
      if (!!cssCompiled.current) {
        loadStyle({ content: cssCompiled.current, id: `inline_${id}` });
      }
      if (prevTemplate.current !== liquid) {
        const button = `<${tagName} style="${hidden_css}" ${signal_attributeName}="${signal_attributeValue(
          id,
        )}" ${id_attributeName}="${id}" ${signal_needUpdate}="${new Date().toString()}">SignalRefreshJs</${tagName}>`;
        setHtml(`
      ${htmlCompiled.current}
      ${button}
    `);
        prevButton.current = button;
        prevTemplate.current = liquid;
      } else {
        setHtml(`
      ${htmlCompiled.current}
      ${prevButton.current}
    `);
        prevTemplate.current = liquid;
      }
      setLoading(state => ({ ...state, [id]: false }));
    };

    liquidThread.current.addEventListener('message', handleLiquidThreadMessage);

    return () => {
      liquidThread.current?.removeEventListener('message', handleLiquidThreadMessage);
      liquidThread.current?.terminate();

      liquidThread.current = null;

      setLoading(state => {
        return objectEntries(state).reduce<Record<string, boolean>>((acc, [key, value]) => {
          if (key !== id) {
            return { ...acc, [key]: value };
          }
          return acc;
        }, {});
      });
    };
  }, [
    statusGetInitialOfLiquidVariables,
    blogSlugsLoaded,
    pageSlugsLoaded,
    productIdsLoaded,
    collectionIdsLoaded,
    translationLocalesLoaded,
    addonScope,
    settings,
    addonIds,
    data.translations[locale],
    [codeVisible, id, liquid, isAddons, locale],
    liquidSnippets,
  ]);

  useDeepCompareEffect(() => {
    const allSectionsLoaded = Object.values(loading).every(value => value === false);

    // Nếu tất của section đều đã load xong và html đã được render thì ta sẽ terminate worker
    if (!!allSectionsLoaded && !!html) {
      liquidThread.current?.terminate();
      liquidThread.current = null;
    }
  }, [loading, [html]]);

  return {
    html,
    loading,
    setHtml,
  };
};

export default useTwig;
