import { loadStyle } from '@wiloke/functions';
import FooterPlaceholder from 'components/FooterPlaceholder';
import HeaderPlaceholder from 'components/HeaderPlaceholder';
import HelmetImportGoogleFont from 'components/HelmetImportGoogleFont/HelmetImportGoogleFont';
import { HelmetImportShopifyFont } from 'components/HelmetImportShopifyFont/HelmetImportShopifyFont';
import { getCssFont, shopifyFontsObject } from 'components/ShopifyFontsField/data';
import { getColorSchemes, getCssColorVariables, getCssFontVariables } from 'generate/utils/generateHelpers';
import qs from 'qs';
import { FC, memo, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useDeepCompareEffect } from 'react-use';
import { sliceSectionIdHover } from 'store/global/sectionIdHover/slice';
import {
  cssVariablesSelector,
  colorSchemesSelector,
  generalSettingsSelector,
  liquidVariablesSelector,
  pagesDataSelector,
  pageSectionsSelector,
  themeAddonsSelector,
  themeVersionSelector,
  sectionIdCodeVisibleSelector,
} from 'store/selectors';
import { isSectionAddons, isSectionMegamenu } from 'utils/functions/checkSectionType';
import { Space, View } from 'wiloke-react-core';
import useGlobalCssAndJs from '../hooks/useGlobalJsAndCss';
import { useIframeDispatch } from '../hooks/useIframeDispatch';
import { useIframeSelector } from '../hooks/useIframeSelector';
import { useListenCodeVisibleSectionId } from '../hooks/useListenCodeVisibleSectionId';
import useLoading from '../hooks/useLoading';
import usePostMessage from '../hooks/usePostMessage';
import { useSetAtomicCss } from '../hooks/useSetAtomicCss';
import useThemeDark from '../hooks/useThemeDark';
import '../setAtomicCssConfig';
import AddonsPosition from './AddonsPosition/AddonsPosition';
import LiquidComponent from './LiquidComponent';
import PreloaderForPreview from './PreloaderForPreview';
import { SectionLoading } from './SectionLoading/SectionLoading';
import { getThemeVersion } from 'utils/functions/getThemeVersion';
import { equals } from 'ramda';

export interface SectionsProps {
  forPreview?: boolean;
}

const SectionsComponent: FC<SectionsProps> = ({ forPreview = false }) => {
  const cssVariables = useIframeSelector(cssVariablesSelector);
  const { colorSchemes } = useIframeSelector(colorSchemesSelector);
  const liquidVariables = useIframeSelector(liquidVariablesSelector);
  const themeAddons = useIframeSelector(themeAddonsSelector);
  const { headerFooterEnabled } = useIframeSelector(generalSettingsSelector);
  const { jsGlobalLoaded, vendorsLoaded, cssGlobal, cssPageGlobal, cssThemeGlobal, cssLayoutSettings } = useGlobalCssAndJs();
  const themeAddonsPositionEnabledSectionId = themeAddons.data.filter(addon => addon.positionEnabled).map(item => item.sectionId);
  const sectionIdCodeVisible = useIframeSelector(sectionIdCodeVisibleSelector);

  const location = useLocation();
  const pageId = qs.parse(location.search, { ignoreQueryPrefix: true, comma: true })['id'] as string;
  const previewMode = JSON.parse((qs.parse(location.search, { ignoreQueryPrefix: true, comma: true })['previewMode'] as string) || '{}');
  const pagesData = useIframeSelector(pagesDataSelector);
  const _sections = useIframeSelector(pageSectionsSelector);
  const [timestamp, setTimestamp] = useState(Date.now());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sections = !!previewMode && pageId !== 'undefined' ? pagesData[pageId]?.sections ?? [] : _sections;

  const iframeDispatch = useIframeDispatch();

  const themeVersion = useIframeSelector(themeVersionSelector);
  const themeV2 = getThemeVersion(themeVersion) === '>=2.0.0';
  const prevSectionRef = useRef(sections);

  usePostMessage();
  useLoading(!vendorsLoaded, forPreview);
  useSetAtomicCss();
  useThemeDark();
  useListenCodeVisibleSectionId();

  useEffect(() => {
    if (!equals(sections, prevSectionRef.current)) {
      setTimestamp(Date.now());
    }
    prevSectionRef.current = sections;
  }, [sections, sectionIdCodeVisible]);

  useDeepCompareEffect(() => {
    const { colors, fonts } = cssVariables;
    const target = 'Trích xuất kết quả sync lên shopify hoặc Lấy css để hiển thị trong builder';
    loadStyle({
      id: 'cssVarialbes',
      content: `:root {
        ${getCssColorVariables({ colors, target, variant: 'light' })}
        ${getCssFontVariables({ fonts, target })}
      }
      :root.dark {
        ${getCssColorVariables({ colors, target, variant: 'dark' })}
      }
      ${themeV2 ? getColorSchemes({ colorSchemes, target }) : ''}
      `,
    });
  }, [cssVariables, colorSchemes]);

  useEffect(() => {
    loadStyle({
      id: 'cssLayoutSettings',
      content: cssLayoutSettings,
    });
  }, [cssLayoutSettings]);

  useEffect(() => {
    loadStyle({
      id: 'cssThemeGlobal',
      content: cssThemeGlobal,
    });
  }, [cssThemeGlobal]);

  useEffect(() => {
    loadStyle({
      id: 'cssGlobal',
      content: cssGlobal,
    });
  }, [cssGlobal]);

  useEffect(() => {
    loadStyle({
      id: 'cssPageGlobal',
      content: cssPageGlobal,
    });
  }, [cssPageGlobal]);

  useEffect(() => {
    if (liquidVariables.data.themeCss) {
      // NOTE: @tuong -> "ĐA SỐ" các theme file css base của theme sẽ nằm gần "</head>"
      loadStyle({ file: liquidVariables.data.themeCss, id: 'THEME_CSS', insertPosition: 'afterbegin' });
    }
  }, [liquidVariables.data.themeCss]);

  const renderSections = () => {
    if (!jsGlobalLoaded) {
      return null;
    }
    return sections.map((section, index) => {
      if (
        !section?.enable ||
        isSectionMegamenu(section.type) ||
        // Nếu là addons và addons đó thuộc dạng cần đặt position
        (isSectionAddons(section.type) && themeAddonsPositionEnabledSectionId.includes(section.id))
      ) {
        return null;
      }

      if (liquidVariables.statusGetInitialOfLiquidVariables !== 'success') {
        return <SectionLoading key={section.id} />;
      }

      return <LiquidComponent key={section.id} index={index} section={section} />;
    });
  };

  const renderSectionsForIframe = () => {
    return (
      <>
        {jsGlobalLoaded && <AddonsPosition />}
        <View
          data-change={timestamp}
          id="iframe-root"
          onMouseLeave={() => {
            // Xoá id hover khi hover ra ngoài iframe
            iframeDispatch(sliceSectionIdHover.actions.setSectionIdHover(''));
          }}
        >
          {headerFooterEnabled ? <HeaderPlaceholder /> : null}
          {renderSections()}
          {headerFooterEnabled ? <FooterPlaceholder /> : null}
        </View>
        {headerFooterEnabled && !!sections.length && <Space size={50} />}
      </>
    );
  };

  const renderSectionsForPreview = () => {
    return (
      <>
        <PreloaderForPreview />
        {renderSections()}
      </>
    );
  };

  return (
    <>
      {forPreview ? renderSectionsForPreview() : renderSectionsForIframe()}
      {cssVariables.fonts.map(font => {
        if (shopifyFontsObject.has(font.value)) {
          return <HelmetImportShopifyFont key={font.id} option={getCssFont(font)} />;
        }
        return <HelmetImportGoogleFont key={font.id} fontFamily={font.value} />;
      })}
    </>
  );
};

export const Sections = memo(SectionsComponent);
// SectionsComponent.whyDidYouRender = {};
