import { AxiosResponse } from 'axios';
import { BE_ThemeAtom } from 'services/ThemeService/Atom/types';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface GetAtom {
  /** CommandId của theme cần lấy dữ liệu */
  commandId: string;
  cacheVersion: number | null;
}

interface ResponseSuccess {
  message: string;
  info: BE_ThemeAtom;
}

/** API được sử dụng để lấy dữ liệu của theme atom */
export const getAtom = async ({ commandId, cacheVersion }: GetAtom) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${baseUrl}/${commandId}`,
    params: {
      cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
    },
  });
  return response.data;
};
