import { AddonOfTheme_Atom_N_Client } from 'types/Addons';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';

interface GetAddonsOfPageAtom {
  commandIds: string[];
  timestamp?: number;
}

export const getAddonsOfPageClientUseAtomTemplate = async ({ commandIds, timestamp }: GetAddonsOfPageAtom): Promise<AddonOfTheme_Atom_N_Client[]> => {
  const { lastCache } = getUserInfo();

  const responses = await pageApis.atom.userApi.addon.getAddonsOfAtom({ commandIds, cacheVersion: lastCache, sessionId: timestamp });
  return responses.reduce<AddonOfTheme_Atom_N_Client[]>((res, info) => {
    if (info) {
      return res.concat(info);
    }
    return res;
  }, []);
};
