import { createPublishStepLoading } from 'components/PublishStepLoading';
import LeftBarSections from 'containers/BuilderPage/screens/LeftBarMain/LeftBarSections';
import { MODAL_REPORT_AFTER_SYNC_ERROR } from 'containers/ModalReportAfterError/const';
import { ModalReportAfterError } from 'containers/ModalReportAfterError/ModalReportAfterError';
import { all, call, put, retry, SagaReturnType, select, takeLeading } from 'redux-saga/effects';
import { handleSaveInBuilderPage, shopifyConnectionService } from 'services/ShopifyConnection';
import { checkThemeAppStatus } from 'services/ShopifyConnection/flowSyncToShopify/checkThemeAppStatus';
import {
  syncAddonsEnablePosition,
  SyncAddonsEnablePositionResult,
  syncAddonsDisablePosition,
  SyncAddonsDisablePositionResult,
  SyncDeletedAddonsInShopifyResult,
  syncDeletedAddonsInShopify,
} from 'services/ShopifyConnection/flowSyncToShopify/syncAddons';
import { syncGlobalOfTheme, SyncGlobalOfThemeResult } from 'services/ShopifyConnection/flowSyncToShopify/syncGlobalSettings';
import {
  syncDisableVedaHeaderFooter,
  SyncDisableVedaHeaderFooterResult,
  syncHeaderFooter,
  SyncHeaderFooterResult,
  syncPublishHeaderFooter,
  SyncPublishVedaHeaderFooterResult,
} from 'services/ShopifyConnection/flowSyncToShopify/syncHeaderFooter';
import {
  syncPageInPageBuilderMode,
  SyncPageInPageBuilderModeResult,
  syncPagesInThemeBuilderMode,
  SyncPagesInThemeBuilderModeResult,
} from 'services/ShopifyConnection/flowSyncToShopify/syncPage';
import { syncTranslations } from 'services/ShopifyConnection/flowSyncToShopify/syncTranslations';
import { liquidVariablesSelector, socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector } from 'store/selectors';
import { i18n } from 'translation';
import { getActionType } from 'wiloke-react-core/utils';
import { syncToShopify } from './actions';
import getPageInfo from 'utils/functions/getInfo';
import { PAGE_BUILD_HEADER_FOOTER } from 'utils/constants/constants';
import { isThemeBuilder } from 'utils/validateBuilderMode';

export const syncPageNotification = createPublishStepLoading(7);

// NOTE: @tuong -> Ý tưởng rate limit hiện tại: Cứ mỗi chặng (step sync) cho dừng nửa giây
export function* handleSyncToShopify({ payload }: ReturnType<typeof syncToShopify.request>) {
  const { onSyncFulfill, entityVariant, isSaveAsLandingPage, deletedAddonIds, disableVedaHeaderFooter } = payload;
  try {
    const pageId = getPageInfo('id');

    const isActived: SagaReturnType<typeof checkThemeAppStatus> = yield call(checkThemeAppStatus);
    if (!isActived) {
      yield put(syncToShopify.failure(undefined));
      syncPageNotification.done();
      onSyncFulfill?.();
      return;
    }
    const { result, isOverrideIndividualPages } = payload;
    const { eventId }: ReturnType<typeof socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector> = yield select(
      socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector,
    );

    const { data }: ReturnType<typeof liquidVariablesSelector> = yield select(liquidVariablesSelector);
    const { theme } = data;

    const {
      pagesParams,
      themeParams,
      headerParams,
      footerParams,
      deleteAddonsParams,
      addonsEnablePositionParams,
      addonsDisablePositionParams,
      syncTranslationsParams,
      disableVedaHeaderFooterParams,
      publishHeaderFooterParams,
    }: ReturnType<typeof handleSaveInBuilderPage> = handleSaveInBuilderPage({
      data: result,
      eventId,
      isOverrideIndividualPages,
      isSaveAsLandingPage,
      themeName: theme?.name as string,
      isExportForEnvato: false,
      entityVariant,
      deletedAddonIds,
    });

    const syncTranslationsResult: SagaReturnType<typeof syncTranslations> = yield call(syncTranslations, {
      syncTranslationsParams,
    });

    if (syncTranslationsResult.statusSyncTranslations === 'failure') {
      throw new Error(i18n.t('publish_shopify.sync_something_error', { text: i18n.t('publish_shopify.translation') }), {
        cause: syncTranslationsResult.isNeedIgnoreReportError,
      });
    }

    syncPageNotification.next({
      title: i18n.t('publish_shopify.sync_page_message.step', { text: '3' }),
      description: i18n.t('publish_shopify.syncing', { text: i18n.t('publish_shopify.text_to_shopify', { text: 'Atomic CSS' }) }),
    });

    const syncGlobalOfThemeResult: SyncGlobalOfThemeResult = yield call(syncGlobalOfTheme, {
      themeParams,
      entityVariant,
    });

    if (syncGlobalOfThemeResult.statusSyncGlobalOfTheme === 'failure') {
      throw new Error(i18n.t('publish_shopify.sync_something_error', { text: i18n.t('publish_shopify.theme_settings_or_atomic_css') }), {
        cause: syncGlobalOfThemeResult.isNeedIgnoreReportError,
      });
    }

    syncPageNotification.next({
      title: i18n.t('publish_shopify.sync_page_message.step', { text: '4' }),
      description: i18n.t('publish_shopify.syncing', { text: i18n.t('publish_shopify.text_to_shopify', { text: i18n.t('general.addons') }) }),
    });

    const syncDeletedAddonsInShopifyResult: SyncDeletedAddonsInShopifyResult = yield call(syncDeletedAddonsInShopify, {
      deleteAddonsParams,
    });
    if (syncDeletedAddonsInShopifyResult.statusSyncDeletedAddonsInShopify === 'failure') {
      throw new Error(i18n.t('publish_shopify.sync_something_error', { text: i18n.t('general.addons') }), {
        cause: syncDeletedAddonsInShopifyResult.isNeedIgnoreReportError,
      });
    }
    const [syncAddonsEnablePositionResult, syncAddonsDisablePositionResult]: [
      SyncAddonsEnablePositionResult,
      SyncAddonsDisablePositionResult,
    ] = yield all([call(syncAddonsEnablePosition, { addonsEnablePositionParams }), call(syncAddonsDisablePosition, { addonsDisablePositionParams })]);

    if (
      syncAddonsEnablePositionResult.statusSyncAddonsEnablePosition === 'failure' ||
      syncAddonsDisablePositionResult.statusSyncAddonsDisablePosition === 'failure'
    ) {
      throw new Error(i18n.t('publish_shopify.sync_something_error', { text: i18n.t('general.addons') }), {
        cause: syncAddonsEnablePositionResult.isNeedIgnoreReportError || syncAddonsDisablePositionResult.isNeedIgnoreReportError,
      });
    }

    if (result.builderType === 'page') {
      syncPageNotification.next({
        title: i18n.t('publish_shopify.sync_page_message.step', { text: '5' }),
        description: i18n.t('publish_shopify.syncing', { text: i18n.t('publish_shopify.text_to_shopify', { text: i18n.t('general.page') }) }),
      });

      const syncPageInPageBuilderModeResult: SyncPageInPageBuilderModeResult = yield call(syncPageInPageBuilderMode, {
        pagesParams,
        signalToReplaceAddonInLiquidCode: syncAddonsEnablePositionResult.signalToReplaceAddonInLiquidCode,
      });
      LeftBarSections.setErrorId?.(syncPageInPageBuilderModeResult.errorId);

      if (syncPageInPageBuilderModeResult.statusSyncPageInPageBuilderMode === 'failure') {
        throw new Error(i18n.t('publish_shopify.sync_something_error', { text: i18n.t('general.page') }), {
          cause: syncPageInPageBuilderModeResult.isNeedIgnoreReportError,
        });
      }
    }

    if (isThemeBuilder()) {
      const syncPagesInThemeBuilderModeResult: SyncPagesInThemeBuilderModeResult = yield call(syncPagesInThemeBuilderMode, {
        pagesParams,
        signalToReplaceAddonInLiquidCode: syncAddonsEnablePositionResult.signalToReplaceAddonInLiquidCode,
      });
      LeftBarSections.setErrorId?.(syncPagesInThemeBuilderModeResult.errorId);

      if (syncPagesInThemeBuilderModeResult.statusSyncPagesInThemeBuilderMode === 'failure') {
        throw new Error(i18n.t('publish_shopify.sync_something_error', { text: i18n.t('general.page') }), {
          cause: syncPagesInThemeBuilderModeResult.isNeedIgnoreReportError,
        });
      }
    }

    if (headerParams && footerParams && !disableVedaHeaderFooter && pageId === PAGE_BUILD_HEADER_FOOTER) {
      const syncHeaderFooterResult: SyncHeaderFooterResult = yield call(syncHeaderFooter, {
        headerParams,
        footerParams,
        signalToReplaceAddonInLiquidCode: syncAddonsEnablePositionResult.signalToReplaceAddonInLiquidCode,
      });

      if (syncHeaderFooterResult.statusSyncHeader === 'failure' || syncHeaderFooterResult.statusSyncFooter === 'failure') {
        throw new Error(
          i18n.t('publish_shopify.sync_something_error', {
            text: `${i18n.t('general.header')} ${i18n.t('general.footer')}`,
          }),
          { cause: syncHeaderFooterResult.isNeedIgnoreReportError },
        );
      }

      syncPageNotification.next({
        title: i18n.t('publish_shopify.sync_page_message.step', { text: '6' }),
        description: i18n.t('publish_shopify.syncing', {
          text: i18n.t('publish_shopify.text_to_shopify', { text: `${i18n.t('general.header')}, ${i18n.t('general.footer')}` }),
        }),
      });
    }

    if (headerParams && footerParams && !disableVedaHeaderFooter && pageId === PAGE_BUILD_HEADER_FOOTER) {
      const syncPublishVedaHeaderFooterResult: SyncPublishVedaHeaderFooterResult = yield call(syncPublishHeaderFooter, {
        publishHeaderFooterParams: publishHeaderFooterParams,
      });
      if (syncPublishVedaHeaderFooterResult.statusSyncPublishVedaHeaderFooter === 'failure') {
        throw new Error(i18n.t('publish_shopify.sync_something_error'), {
          cause: syncPublishVedaHeaderFooterResult.isNeedIgnoreReportError,
        });
      }
    }

    if (disableVedaHeaderFooterParams && disableVedaHeaderFooter && pageId === PAGE_BUILD_HEADER_FOOTER) {
      const syncDisableVedaHeaderFooterResult: SyncDisableVedaHeaderFooterResult = yield call(syncDisableVedaHeaderFooter, {
        disableVedaHeaderFooterParams,
      });
      if (syncDisableVedaHeaderFooterResult.statusSyncDisableVedaHeaderFooter === 'failure') {
        throw new Error(i18n.t('publish_shopify.sync_something_error'), {
          cause: syncDisableVedaHeaderFooterResult.isNeedIgnoreReportError,
        });
      }
    }

    if (result.builderType === 'page') {
      syncPageNotification.next({
        title: i18n.t('publish_shopify.sync_page_message.step', { text: '7' }),
        description: 'Active page status',
      });

      yield all(
        pagesParams.map(({ pageCommandId }) => {
          return pageCommandId ? retry(3, 1000, shopifyConnectionService.updatePageStatus, { pageCommandId }) : Promise.resolve();
        }),
      );
    }
    syncPageNotification.done();
    yield put(syncToShopify.success(undefined));
    LeftBarSections.setErrorId?.(undefined);
  } catch (err) {
    console.log({ err });
    syncPageNotification.done();
    if (err instanceof Error) {
      yield put(syncToShopify.failure(undefined));
      if (typeof err.cause === 'boolean' && err.cause) {
      } else {
        ModalReportAfterError.getActions(MODAL_REPORT_AFTER_SYNC_ERROR).report({
          cause: i18n.t('ModalReportAfterError.error_cause.sync_result_to_shopify'),
          description: err instanceof Error ? err.message : '',
        });
      }
    }
  } finally {
    onSyncFulfill?.();
    syncPageNotification.done();
    try {
      yield retry(3, 1000, shopifyConnectionService.cleanAfterSync);
    } catch {}
  }
}

export function* watchSyncToShopify() {
  yield takeLeading(getActionType(syncToShopify.request), handleSyncToShopify);
}
