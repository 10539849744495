export const ModalInteractiveTheme = {
  createTheme: 'Tạo chủ đề',
  updateTheme: 'Cập nhật chủ đề',
  name: 'Tên',
  featured_image: {
    title: 'Hình ảnh nổi bật',
    description: 'Khi active theme sẽ có 1 ảnh lớn và ảnh đó chính là ảnh này',
  },
  screenshot_image_1: {
    title: 'Hình ảnh màn hình 1',
    description: "Là ảnh chụp tổng quan theme. Ảnh sẽ được hiển thị tại 'Theme Templates' và ảnh thumbnail của các theme draft trong dashboard theme",
  },
} as const;
