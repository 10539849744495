import {
  getAllJsOfPage,
  getCssFromSectionsScss_PageScss_SectionsInlinesCss,
  getHtmlFilesOfPage,
  getVendorsOfPage,
} from 'generate/utils/getFilesForSave';
import { ModalWarningDuplicateCommandId, SectionOfPageIssue } from 'packages/ExtractThemeToFileForSync/components/ModalWarningDuplicateCommandId';
import { BE_PageInThemeAtomNDraft } from 'services/ThemeService/Atom/types';
import { File, PageData } from 'types/Result';
import { PageSection } from 'types/Sections';
import { ToRequiredKeys } from 'utils';
import { getLiquidVariables } from './getLiquidVariables';
import { getMegamenus } from './getMegamenus';
import { getPage } from './getPage';
import { getSections } from './getSections';
import { getExtractResultOfPageCacheKey } from './utils/CacheKeyControl';
import { pushToCache, readFromCache } from './utils/CacheStorage';
import { BaseParams } from './@types/BaseParams';

interface ExtractResultOfPage extends Pick<BaseParams, 'entityVariant'> {
  cacheKeysOfSections: Awaited<ReturnType<typeof getSections>>;
  cacheKeysOfMegamenusInSections: Awaited<ReturnType<typeof getMegamenus>>;
  cacheKeyOfPage: Awaited<ReturnType<typeof getPage>>;
  cacheKeyOfLiquidVariables: Awaited<ReturnType<typeof getLiquidVariables>>;
}

const createId = (file: File) => file.id;
export const extractResultOfPage = async ({
  cacheKeyOfPage,
  cacheKeysOfSections,
  cacheKeysOfMegamenusInSections,
  entityVariant,
}: ExtractResultOfPage) => {
  const storageForTrackDuplicate = new Map<string, PageSection>();
  const temporaryErrors: SectionOfPageIssue['data'] = [];

  const page_ = await readFromCache<ToRequiredKeys<BE_PageInThemeAtomNDraft, 'pageSettings'>>(cacheKeyOfPage.pageKey);
  const sections_ = await Promise.all(cacheKeysOfSections.sectionKeys.map(cacheKey => readFromCache<PageSection>(cacheKey)));
  const megamenus_ = await Promise.all(cacheKeysOfMegamenusInSections.map(cacheKey => readFromCache<PageSection>(cacheKey)));

  const page = page_ as ToRequiredKeys<BE_PageInThemeAtomNDraft, 'pageSettings'>;
  const sections = sections_ as PageSection[];
  const megamenus = megamenus_ as PageSection[];

  const sectionsEnable_notIncludeAddonSections_includeMegamenuSections = sections.concat(megamenus);
  const { vendors: pageVendors, globalJs, globalScss, generalSettings } = page.pageSettings;

  const htmlFiles = [
    ...getHtmlFilesOfPage({
      sectionsEnable_notIncludeAddonSections_includeMegamenuSections,
      errorOption: 'throw',
    }),
  ];
  htmlFiles.forEach(file => {
    if (file.section) {
      const ID = createId(file);
      const itemInStorageForTrackDuplicate = storageForTrackDuplicate.get(ID);
      if (itemInStorageForTrackDuplicate) {
        temporaryErrors.push({
          sectionError: file.section,
          sectionExisted: itemInStorageForTrackDuplicate,
        });
      }
      storageForTrackDuplicate.set(ID, file.section);
    }
  });

  const js = getAllJsOfPage({
    variant: 'Toàn bộ page - bao gồm các sections, page settings',
    globalJs,
    sectionsEnable_notIncludeAddonSections_includeMegamenuSections,
  });

  const css = await getCssFromSectionsScss_PageScss_SectionsInlinesCss({
    variant: 'Toàn bộ page - bao gồm các sections, page settings',
    globalScss,
    sectionsEnable_notIncludeAddonSections_includeMegamenuSections,
    atomicCssForAdminNDev: '',
  });

  const vendors = getVendorsOfPage({ vendors: pageVendors });

  const result: PageData = {
    data: {
      page: {
        ...page,
        addedInThemes: [],
        enable: !!page.enable,
        sections,
        entityType: entityVariant === 'Atom' ? 'ThemeAtom' : 'ThemeDraft',
      },
      pageSettings: {
        generalSettings,
        vendors: pageVendors,
        globalScss,
        globalJs,
      },
    },
    files: [...vendors, ...css, ...js, ...htmlFiles],
  };

  if (temporaryErrors.length) {
    ModalWarningDuplicateCommandId.setError?.({
      type: 'page',
      pageError: page,
      data: temporaryErrors,
    });
  }
  const cacheKey = await pushToCache(JSON.stringify(result), getExtractResultOfPageCacheKey(page.commandId));
  return { cacheKey, pageName: page.label };
};
