import { AddonOfTheme_Atom_N_Client, ThemeAddon } from 'types/Addons';
import { SectionSettings, SettingBlock } from 'types/Schema';
import { PageSection, SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client } from 'types/Sections';
import { VersionSection } from 'types/Version';
import { createAction, createAsyncAction, createDispatchAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';

export const getSectionVersion = createAsyncAction([
  '@BuilderPage/getSectionVersion/request',
  '@BuilderPage/getSectionVersion/success',
  '@BuilderPage/getSectionVersion/failure',
])<{ sectionCommandId: string }, { sectionCommandId: string; data: VersionSection | undefined }, { sectionCommandId: string }>();

export const getImportSectionVersion = createAsyncAction([
  '@BuilderPage/getImportSectionVersion/request',
  '@BuilderPage/getImportSectionVersion/success',
  '@BuilderPage/getImportSectionVersion/failure',
])<{ sectionCommandId: string }, { sectionCommandId: string; data: VersionSection | undefined }, { sectionCommandId: string }>();

export const getAddonVersion = createAsyncAction([
  '@BuilderPage/getAddonVersion/request',
  '@BuilderPage/getAddonVersion/success',
  '@BuilderPage/getAddonVersion/failure',
])<{ addonCommandId: string }, { addonCommandId: string; data: VersionSection | undefined }, { addonCommandId: string }>();

export const getImportAddonVersion = createAsyncAction([
  '@BuilderPage/getImportAddonVersion/request',
  '@BuilderPage/getImportAddonVersion/success',
  '@BuilderPage/getImportAddonVersion/failure',
])<{ addonCommandId: string }, { addonCommandId: string; data: VersionSection | undefined }, { addonCommandId: string }>();

export const getMegaMenuVersion = createAsyncAction([
  '@BuilderPage/getMegaMenuVersion/request',
  '@BuilderPage/getMegaMenuVersion/success',
  '@BuilderPage/getMegaMenuVersion/failure',
])<{ megaMenuCommandId: string }, { megaMenuCommandId: string; data: VersionSection | undefined }, { megaMenuCommandId: string }>();

export const updateSectionVersion = createAsyncAction([
  '@BuilderPage/updateSectionVersion/request',
  '@BuilderPage/updateSectionVersion/success',
  '@BuilderPage/updateSectionVersion/failure',
])<{ id: string; prevSectionFeId: string }, { updatedItem: PageSection }, undefined>();

export const updateSectionsInPage = createAsyncAction([
  '@BuilderPage/updateAllSectionsInPage/request',
  '@BuilderPage/updateAllSectionsInPage/success',
  '@BuilderPage/updateAllSectionsInPage/failure',
])<{ prevSections: PageSection[] }, undefined, undefined>();

export const updateAddonsInPage = createAsyncAction([
  '@BuilderPage/updateAddonsInPage/request',
  '@BuilderPage/updateAddonsInPage/success',
  '@BuilderPage/updateAddonsInPage/failure',
])<{ prevAddons: ThemeAddon[] }, undefined, undefined>();

export const updateMegaMenuVersion = createAsyncAction([
  '@BuilderPage/updateMegaMenuVersion/request',
  '@BuilderPage/updateMegaMenuVersion/success',
  '@BuilderPage/updateMegaMenuVersion/failure',
])<{ id: string; prevSectionFeId: string }, { updatedItem: PageSection }, undefined>();

export const updateAddonVersion = createAsyncAction([
  '@BuilderPage/updateAddonVersion/request',
  '@BuilderPage/updateAddonVersion/success',
  '@BuilderPage/updateAddonVersion/failure',
])<{ id: string; prevAddonSection: SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client }, { updatedAddon: ThemeAddon }, undefined>();

export const setModalUpdateSection = createAction(
  '@BuilderPage/setModalUpdateSection',
  (payload: SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client | undefined) => payload,
);

export const setModalUpdateAddon = createAction('@BuilderPage/setModalUpdateAddon', (payload: AddonOfTheme_Atom_N_Client | undefined) => payload);

export const setModalUpdateMegaMenu = createAction(
  '@BuilderPage/setModalUpdateMegaMenu',
  (payload: SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client | undefined) => payload,
);

export const updateVersionSectionFlow = createAsyncAction([
  '@BuilderPage/updateVersionSectionFlow/request',
  '@BuilderPage/updateVersionSectionFlow/success',
  '@BuilderPage/updateVersionSectionFlow/failure',
])<
  { section: SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client },
  {
    deletedSettings: SectionSettings;
    newSettings: SectionSettings;
    updatedSettings: SectionSettings;
    deletedBlocks: SettingBlock[];
    newBlocks: SettingBlock[];
    updatedBlocks: SettingBlock[];
    comparedJs: 'equals' | 'changes';
    comparedJsHook: 'equals' | 'changes';
    comparedScss: 'equals' | 'changes';
    comparedLiquid: 'equals' | 'changes';
  },
  undefined
>();

export const updateVersionMegaMenuFlow = createAsyncAction([
  '@BuilderPage/updateVersionMegaMenuFlow/request',
  '@BuilderPage/updateVersionMegaMenuFlow/success',
  '@BuilderPage/updateVersionMegaMenuFlow/failure',
])<
  { section: SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client },
  {
    deletedSettings: SectionSettings;
    newSettings: SectionSettings;
    updatedSettings: SectionSettings;
    deletedBlocks: SettingBlock[];
    newBlocks: SettingBlock[];
    updatedBlocks: SettingBlock[];
    comparedJs: 'equals' | 'changes';
    comparedJsHook: 'equals' | 'changes';
    comparedScss: 'equals' | 'changes';
    comparedLiquid: 'equals' | 'changes';
  },
  undefined
>();

export const updateVersionAddonFlow = createAsyncAction([
  '@BuilderPage/updateVersionAddonFlow/request',
  '@BuilderPage/updateVersionAddonFlow/success',
  '@BuilderPage/updateVersionAddonFlow/failure',
])<
  { addon: AddonOfTheme_Atom_N_Client },
  {
    deletedSettings: SectionSettings;
    newSettings: SectionSettings;
    updatedSettings: SectionSettings;
    deletedBlocks: SettingBlock[];
    newBlocks: SettingBlock[];
    updatedBlocks: SettingBlock[];
    comparedJs: 'equals' | 'changes';
    comparedJsHook: 'equals' | 'changes';
    comparedScss: 'equals' | 'changes';
    comparedLiquid: 'equals' | 'changes';
  },
  undefined
>();

export const useUpdateVersionSectionFlow = createDispatchAsyncAction(updateVersionSectionFlow);
export const useUpdateVersionAddonFlow = createDispatchAsyncAction(updateVersionAddonFlow);
export const useUpdateVersionMegaMenuFlow = createDispatchAsyncAction(updateVersionMegaMenuFlow);

export const useUpdateSectionVersion = createDispatchAsyncAction(updateSectionVersion);
export const useUpdateAddonVersion = createDispatchAsyncAction(updateAddonVersion);
export const useUpdateMegaMenuVersion = createDispatchAsyncAction(updateMegaMenuVersion);

export const useGetSectionVersion = createDispatchAsyncAction(getSectionVersion);
export const useGetImportSectionVersion = createDispatchAsyncAction(getImportSectionVersion);
export const useGetAddonVersion = createDispatchAsyncAction(getAddonVersion);
export const useGetImportAddonVersion = createDispatchAsyncAction(getImportAddonVersion);
export const useGetMegaMenuVersion = createDispatchAsyncAction(getMegaMenuVersion);

export const useSetModalUpdateSection = createDispatchAction(setModalUpdateSection);
export const useSetModalUpdateAddon = createDispatchAction(setModalUpdateAddon);
export const useSetModalUpdateMegaMenu = createDispatchAction(setModalUpdateMegaMenu);
export const useUpdateSectionsInPage = createDispatchAsyncAction(updateSectionsInPage);
export const useUpdateAddonsInPage = createDispatchAsyncAction(updateAddonsInPage);
