import { ProductTheme } from 'types/Theme';
import { createAction, createAsyncAction, createDispatchAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';

export const actionGetThemeTemplates = createAsyncAction([
  '@ThemeBuilder/getThemeTemplates/request',
  '@ThemeBuilder/getThemeTemplates/success',
  '@ThemeBuilder/getThemeTemplates/failure',
])<{ search: string; group: 'envato' | 'built_in'; size: number; categoryId: string }, { data: ProductTheme[]; hasNextPage: boolean }, undefined>();

export const getProductTheme = createAsyncAction([
  '@Global/getProductTheme/request',
  '@Global/getProductTheme/success',
  '@Global/getProductTheme/failure',
])<{ commandId: string; type: 'built_in' | 'envato'; showNotify?: boolean }, { commandId: string }, { commandId: string }>();

export const loadMoreThemeTemplates = createAsyncAction([
  '@ThemeBuilder/loadMoreThemeTemplates/request',
  '@ThemeBuilder/loadMoreThemeTemplates/success',
  '@ThemeBuilder/loadMoreThemeTemplates/failure',
])<{ group: 'envato' | 'built_in'; size: number; cursor: string; categoryId: string }, { data: ProductTheme[]; hasNextPage: boolean }, undefined>();

export const actionGetCurrentTheme = createAsyncAction([
  '@ThemeBuilder/actionGetCurrentTheme/request',
  '@ThemeBuilder/actionGetCurrentTheme/success',
  '@ThemeBuilder/actionGetCurrentTheme/failure',
  '@ThemeBuilder/actionGetCurrentTheme/cancel',
])<
  { themeId: string; variant: EntityType; onFulfill: () => void; onDisconnectSocketEarly: () => void },
  { themeId: string },
  { themeId: string },
  undefined
>();

export const importThemeAtomToClient = createAsyncAction([
  '@ThemeBuilder/importThemeAtomToClient/request',
  '@ThemeBuilder/importThemeAtomToClient/success',
  '@ThemeBuilder/importThemeAtomToClient/failure',
])<
  { themeProductCommandId: string; group: ProductTheme['group']; shopifyThemeId: string; onFulfill?: () => void },
  { themeProductCommandId: string },
  { themeProductCommandId: string }
>();

export const deleteProductTheme = createAsyncAction([
  '@ThemeBuilder/deleteProductTheme/request',
  '@ThemeBuilder/deleteProductTheme/success',
  '@ThemeBuilder/deleteProductTheme/failure',
])<{ commandId: string }, { commandId: string }, { commandId: string }>();

export const validatePurchaseCode = createAsyncAction([
  '@ThemeBuilder/validatePurchaseCode/request',
  '@ThemeBuilder/validatePurchaseCode/success',
  '@ThemeBuilder/validatePurchaseCode/failure',
])<{ purchaseCode: string; themeImportId: string; onFulfill?: () => void; onError?: (message: string) => void }, undefined, { message: string }>();

export const checkPurchaseCodeIsExist = createAsyncAction([
  '@ThemeBuilder/checkPurchaseCodeIsExist/request',
  '@ThemeBuilder/checkPurchaseCodeIsExist/success',
  '@ThemeBuilder/checkPurchaseCodeIsExist/failure',
])<{ envatoThemeId: string; onFulfill?: () => void }, undefined, undefined>();

export const checkThemeIsInstall = createAsyncAction([
  '@ThemeBuilder/checkThemeIsInstall/request',
  '@ThemeBuilder/checkThemeIsInstall/success',
  '@ThemeBuilder/checkThemeIsInstall/failure',
])<
  { commandId: string; themeName: string; zipUrl: string; group: ProductTheme['group']; onFulfill?: () => void },
  { commandId: string; themeShopifyId: string },
  { message: string; commandId: string }
>();

export const validateBuiltinTheme = createAsyncAction([
  '@ThemeBuilder/validateBuiltinTheme/request',
  '@ThemeBuilder/validateBuiltinTheme/success',
  '@ThemeBuilder/validateBuiltinTheme/failure',
])<{ onFulfill?: () => void; onReject?: () => void }, undefined, undefined>();

export const setVisible402PurchaseCode = createAction('@ThemeBuilder/setVisible402PurchaseCode', (visible: boolean) => ({ visible }));
export const setErrorMessagePurchaseCode = createAction('@ThemeBuilder/setErrorMessagePurchaseCode', (message: string) => ({ message }));

export const useGetThemeTemplates = createDispatchAsyncAction(actionGetThemeTemplates);
export const useLoadMoreThemeTemplates = createDispatchAsyncAction(loadMoreThemeTemplates);
export const useGetCurrentTheme = createDispatchAsyncAction(actionGetCurrentTheme);
export const useImportThemeAtomToClient = createDispatchAsyncAction(importThemeAtomToClient);
export const useDeleteProductTheme = createDispatchAsyncAction(deleteProductTheme);
export const useSetVisible402PurchaseCode = createDispatchAction(setVisible402PurchaseCode);
export const useValidatePurchaseCode = createDispatchAsyncAction(validatePurchaseCode);
export const useSetErrorMessagePurchaseCode = createDispatchAction(setErrorMessagePurchaseCode);
export const useGetProductTheme = createDispatchAsyncAction(getProductTheme);
export const useCheckThemeIsInstall = createDispatchAsyncAction(checkThemeIsInstall);
export const useCheckPurchaseCodeIsExist = createDispatchAsyncAction(checkPurchaseCodeIsExist);
export const useValidateBuiltinTheme = createDispatchAsyncAction(validateBuiltinTheme);
