import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { updatePlanAuthorService } from 'services/UserService/Logic/updatePlanAuthor';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { updateAuthorPlan } from '../action';
import { getActionType } from 'wiloke-react-core/utils';

function* handleAction({
  payload: { planHandle, shopifyPlanName, userId, chargeId, price, status, trialDays, onFulfill },
}: ReturnType<typeof updateAuthorPlan.request>) {
  try {
    const response: SagaReturnType<typeof updatePlanAuthorService> = yield retry(3, 1000, updatePlanAuthorService, {
      userId,
      planHandle,
      shopifyPlanName,
      chargeId,
      price,
      status,
      trialDays,
    });
    yield put(
      updateAuthorPlan.success({
        id: response.info.id,
        planHandle: planHandle ?? '',
        shopifyPlanName: shopifyPlanName ?? '',
        chargeId,
        price,
        status,
        trialDays,
      }),
    );
    onFulfill?.();
    notifyAxiosHandler.handleSuccess(response.message);
  } catch (error) {
    yield put(updateAuthorPlan.failure(undefined));
  }
}

export function* watchUpdateAuthorPlan() {
  yield takeLatest(getActionType(updateAuthorPlan.request), handleAction);
}
