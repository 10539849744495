import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import fetchAPI from 'utils/functions/fetchAPI';

interface ResponseSuccess {
  message: string;
}

export interface ClearCacheOnCloudflare {
  commandIdOfTheme: string;
  commandIdsOfHeader: string[];
  commandIdsOfFooter: string[];
  commandIdsOfAddon: string[];
  commandIdsOfMegamenusInHeader: string[];
  commandIdsOfMegamenusInFooter: string[];
  commandIdsOfSections: string[];
  commandIdsOfMegamenusInSections: string[];
}

export const clearCacheOnCloudflare = ({
  commandIdOfTheme,
  commandIdsOfAddon,
  commandIdsOfFooter,
  commandIdsOfHeader,
  commandIdsOfMegamenusInFooter,
  commandIdsOfMegamenusInHeader,
  commandIdsOfMegamenusInSections,
  commandIdsOfSections,
}: ClearCacheOnCloudflare) => {
  return Promise.all([
    fetchAPI.request({
      method: 'DELETE',
      url: `${configureApp.endpoint.atom}/${configureApp.endpoint.themes}/admin/cloudflare/themes`,
      params: {
        commandIds: commandIdOfTheme,
      },
    }),
    fetchAPI.request({
      method: 'DELETE',
      url: `${configureApp.endpoint.atom}/${configureApp.endpoint.themes}/admin/cloudflare/sections`,
      params: {
        commandIds: [...commandIdsOfFooter, ...commandIdsOfHeader, ...commandIdsOfSections].join(','),
      },
    }),
    fetchAPI.request({
      method: 'DELETE',
      url: `${configureApp.endpoint.atom}/${configureApp.endpoint.themes}/admin/cloudflare/mega-menus`,
      params: {
        commandIds: [...commandIdsOfMegamenusInFooter, ...commandIdsOfMegamenusInHeader, ...commandIdsOfMegamenusInSections].join(','),
      },
    }),
    fetchAPI.request({
      method: 'DELETE',
      url: `${configureApp.endpoint.atom}/${configureApp.endpoint.themes}/admin/cloudflare/addons`,
      params: {
        commandIds: commandIdsOfAddon.join(','),
      },
    }),
  ]) as Promise<[AxiosResponse<ResponseSuccess>, AxiosResponse<ResponseSuccess>, AxiosResponse<ResponseSuccess>, AxiosResponse<ResponseSuccess>]>;
};
