import { put, retry, takeLatest } from '@redux-saga/core/effects';
import { AxiosError } from 'axios';
import { loadMoreAdminCategories } from 'containers/ChooseTemplate/store/actions';
import { sectionService } from 'services/SectionService';
import { getActionType } from 'wiloke-react-core/utils';

function* handleCategories({ payload: { cursor } }: ReturnType<typeof loadMoreAdminCategories.request>) {
  try {
    const data: Awaited<ReturnType<typeof sectionService.categories.loadMoreCategoriesOfAtom>> = yield retry(
      3,
      1000,
      sectionService.categories.loadMoreCategoriesOfAtom,
      cursor,
    );
    yield put(loadMoreAdminCategories.success({ data }));
  } catch (error) {
    const err = error as AxiosError<{ message: string }>;
    const message = err.response?.data?.message || '';
    yield put(loadMoreAdminCategories.failure({ message }));
  }
}

export function* watchLoadMoreAdminCategories() {
  yield takeLatest(getActionType(loadMoreAdminCategories.request), handleCategories);
}
