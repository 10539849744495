import { AxiosError } from 'axios';
import { put, retry, SagaReturnType, takeEvery } from 'redux-saga/effects';
import { restoreThemeAtom } from 'services/ThemeService/Logic/restoreThemeAtom';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { restoreTrashTheme } from '../actions/actionThemeTrash';

function* handleRestore({ payload: { commandId } }: ReturnType<typeof restoreTrashTheme.request>) {
  try {
    const response: SagaReturnType<typeof restoreThemeAtom> = yield retry(3, 1000, restoreThemeAtom, { commandId });
    notifyAxiosHandler.handleSuccess(response.message);
    yield put(restoreTrashTheme.success({ commandId }));
  } catch (error) {
    yield put(restoreTrashTheme.failure({ commandId }));
    notifyAxiosHandler.handleError(error as AxiosError<ErrorData> | Error);
  }
}

export function* watchRestoreThemeAtom() {
  yield takeEvery(getActionType(restoreTrashTheme.request), handleRestore);
}
