import Switch from 'components/Switch';
import { Title } from '@wiloke/ui';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { appSettingsSelector, useChangeAppSettings } from 'store/global/appSettings/slice';
import { View } from 'wiloke-react-core';
import * as styles from '../styles';

export const Rtl: FC = () => {
  const changeAppSettings = useChangeAppSettings();
  const { data } = useSelector(appSettingsSelector);

  return (
    <View css={styles.field}>
      <View css={styles.left}>
        <Title title="RTL" text="Please turn on this setting if your language is RTL." />
      </View>
      <View css={styles.right}>
        <Switch
          checked={data.rtl}
          onValueChange={value => {
            changeAppSettings({ rtl: value });
          }}
        />
      </View>
    </View>
  );
};
