import { CodeSpliting } from 'components/CodeSpliting';
import SectionPageHeader from 'components/SectionPageHeader';
import { ModalCreateArticle, ModalDeletePageDashboard } from 'containers/Admin/Modals';
import { useChangeModalAdminSettings } from 'containers/Admin/Modals/store';
import { Dashboard } from 'containers/Dashboard';
import { CreateArticlePicker } from 'containers/Shopify/ModalCreateShopifyPicker/CreateArticlePicker';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { articlePageSelector } from 'store/selectors';
import { i18n } from 'translation';
import { ARTICLE_PAGE_MAIN_CONTENT } from 'utils/constants/chunkIds';
import { View } from 'wiloke-react-core';
import { useGetArticlePageItems } from '.';
import { ArticleSettings } from '../DashboardPageSettings/components/ArticleSettings';
import { DashboardPageSettings } from '../DashboardPageSettings/DashboardPageSettings';

export const ArticlesPage: FC = () => {
  const changeSettings = useChangeModalAdminSettings();
  const { filterType, search } = useSelector(articlePageSelector);
  const getItems = useGetArticlePageItems();

  useEffect(() => {
    getItems.request({ s: search, pageType: 'article', filterType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filterType]);

  return (
    <Dashboard
      pageType="article"
      Modals={() => (
        <>
          <ModalCreateArticle />
          <ModalDeletePageDashboard pageType="article" />

          <DashboardPageSettings pageType="article" />
          <ArticleSettings />
          <CreateArticlePicker />
        </>
      )}
      Content={() => (
        <View>
          <SectionPageHeader
            pageType="article"
            title={i18n.t('adminDashboard.pages', { name: i18n.t('adminDashboard.article') })}
            description={i18n.t('adminDashboard.page_description.article')}
            onClick={() => {
              changeSettings({ createArticle: true });
            }}
          />
          <CodeSpliting
            component={() => import(/* webpackChunkName: "ArticlePageMainContent", webpackPrefetch: true */ './MainContent')}
            CHUNK_ID={ARTICLE_PAGE_MAIN_CONTENT}
          />
        </View>
      )}
    />
  );
};
