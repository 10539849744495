export function isYoutube(url: string): boolean {
  return url.search(/^http.*:\/\/(www|)\.youtube\.com/g) !== -1;
}

export function isVimeo(url: string): boolean {
  return url.search(/^http.*:\/\/vimeo\.com/g) !== -1;
}

function changeUrlVimeo(url: string): string {
  const id = url.replace(/(^.*(video|vimeo\.com)\/)(\w*)(.*$)/g, '$3');
  return `https://player.vimeo.com/video/${id}?autoplay=1&loop=1&muted=1`;
}

function changeUrlYoutube(url: string): string {
  const id = url.replace(/(^.*(embed\/|(\?|&)v=))(\w*)(.*$)/g, '$4');
  return `https://www.youtube.com/embed/${id}?autoplay=1&loop=1&mute=1&controls=1&iv_load_policy=1&disablekb=1&playlist=${id}&modestbranding=1&playsinline=1`;
}

function changeUrlToEmbed(url: string): string {
  if (isVimeo(url)) {
    return changeUrlVimeo(url);
  }
  if (isYoutube(url)) {
    return changeUrlYoutube(url);
  }
  return url;
}
export default changeUrlToEmbed;
