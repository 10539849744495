import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import { SyncThemeByResultOfExtractThemeToFileForSyncMessage } from 'packages/SyncThemeByResultOfExtractThemeToFileForSync/hooks/useSocketForSyncThemeByResultOfExtractThemeToFileForSync';
import { i18n } from 'translation';
import fetchAPI from 'utils/functions/fetchAPI';
import { areUndefined, themeSettingsConverter } from '../utils';

export interface WriteThemeSchemaNSettings_BEExpectParameters {
  eventId: string;
  eventType: SyncThemeByResultOfExtractThemeToFileForSyncMessage['eventType'];
  data: ReturnType<typeof themeSettingsConverter>;
}

export interface WriteThemeSchemaNSettings_BEResponse {}

export interface WriteThemeSchemaNSettings_BEResponseError {
  message: string;
}

interface WriteThemeSchemaNSettings extends DeepPartial<WriteThemeSchemaNSettings_BEExpectParameters> {}

export const writeThemeSchemaNSettings = async ({ eventId, eventType, data }: WriteThemeSchemaNSettings) => {
  if (areUndefined(eventId, eventType, data)) {
    throw new Error(i18n.t('general.lack_of_params'));
  }
  const response: AxiosResponse<WriteThemeSchemaNSettings_BEResponse> = await fetchAPI.request({
    method: 'PUT',
    url: `${configureApp.endpoint['shopify-connections']}/sync/shopify/me/theme-settings`,
    data: {
      ...data,
      eventId,
      eventType,
    } as WriteThemeSchemaNSettings_BEExpectParameters,
    params: {
      eventId,
      eventType,
    },
  });
  return response.data;
};
