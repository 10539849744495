import { ErrorWithCrispChat } from 'components/ErrorWithCrispChat';
import { Component, MouseEventHandler, ReactNode } from 'react';

export interface ErrorBoundaryProps {
  children: ReactNode;
  onRetry?: MouseEventHandler;
}

export interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // componentDidCatch(error: Error, errorInfo: any) {
  //   console.log({ error, errorInfo });
  // }

  handleClick: MouseEventHandler = e => {
    const { onRetry } = this.props;
    onRetry?.(e);
    this.setState({ hasError: false });
  };

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorWithCrispChat onClick={this.handleClick} />;
    }

    return children;
  }
}

export default ErrorBoundary;
