import { put, retry, takeLatest } from 'redux-saga/effects';
import { publishPageAtom } from 'services/PageService/Logic/publishPageAtom';
import { AdminPageDatabase } from 'services/ResultService/atomTypes';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { publishPageAtom as actionPublishPageAtom } from '../actions/actionPagesAtom';
import { setModalPublishPage } from '../reducers/slicePagesAtom';

function* handlePublishPageAtom({ payload }: ReturnType<typeof actionPublishPageAtom.request>) {
  const {
    pageAtom,
    image,
    label,
    categoryOfProduct,
    plan,
    priorityOrder,
    searchTerms,
    previewUrl,
    onFulfilled,
    types,
    isSticky,
    usecases,
    description,
  } = payload;
  try {
    const response: Awaited<ReturnType<typeof publishPageAtom>> = yield retry(3, 1000, publishPageAtom, {
      pageAtom,
      image,
      label,
      categoryOfProduct,
      plan,
      priorityOrder,
      searchTerms,
      previewUrl,
      types,
      isSticky,
      usecases,
      description,
    });
    yield put(
      actionPublishPageAtom.success({
        pageAtom: {
          ...pageAtom,
          vedaProductRepresent: response.info as AdminPageDatabase['vedaProductRepresent'],
        },
      }),
    );
    yield put(setModalPublishPage(undefined));
    notifyAxiosHandler.handleSuccess(response.message);
    onFulfilled?.();
  } catch (error) {
    const error_ = error as Error;
    notifyAxiosHandler.handleError(error_);
    yield put(actionPublishPageAtom.failure(undefined));
  }
}

export function* watchPublishPageAtom() {
  yield takeLatest(getActionType(actionPublishPageAtom.request), handlePublishPageAtom);
}
