import { imageUrl } from '@wiloke/functions';
import { AsyncComponent } from '@wiloke/ui';
import ImageTextCard from 'components/ImageTextCard';
import VirtualList from 'components/VirtualList/VirtualList';
import { useGetCategoriesOfPageProduct } from 'containers/Admin/Management/Pages/store/actions/actionPagesProduct';
import { ModalCreateArticle, ModalCreateBlog, ModalCreateCollection, ModalCreateNormalPage, ModalCreateProduct } from 'containers/Admin/Modals';
import { ModalPreviewPage } from 'containers/Admin/Modals/components/ModalPreviewPage';
import { useChangeModalAdminSettings } from 'containers/Admin/Modals/store';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';
import { AdminPageData } from 'containers/Admin/types';
import { isEmpty } from 'ramda';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BE_PageProduct } from 'services/PageService/VedaApplication/types';
import { useSetPages } from 'store/actions/actionPages';
import { authSelector, pageBuilderSelector } from 'store/selectors';
import { PageType } from 'types/Page';
import { PLAN_ENUM, PLAN_STATIC_DATA } from 'utils/constants/PlanEnum';
import { validatePlan } from 'utils/functions/validatePlan';
import { isPageBuilder, isThemeBuilder } from 'utils/validateBuilderMode';
import { ActivityIndicator, css, GridSmart, View, ViewportTracking } from 'wiloke-react-core';
import { useDeleteTemplatePage, useGetTemplatesPage, useLoadMoreTemplatePage, useSaveToMyTemplate } from '../reducers/sliceTemplatePage';
import * as styles from '../styles';

const ROW_HEIGHT = 410;
const COLUMN_GAP = 20;
const COLUMN_COUNT = 5;

const isInBuilderCss = css`
  .ReactVirtualized__Grid {
    overflow-x: hidden !important;
    padding-right: 10px !important;
    padding-bottom: 50px !important;
  }
`;
const defaultPageType = [
  'page',
  'article',
  'blog',
  'collection',
  'product',
  'home',
  'cart',
  'pageNotFound',
  'password',
  'search',
  'login',
  'resetPassword',
  'activateAccount',
  'register',
  'account',
  'order',
  'addresses',
  // 'giftCard',
  'collections',
];

export const AllTemplates = () => {
  const {
    tabKey,
    allTemplates: { data: dataState, getStatus, hasNextPage, loadMoreStatus, saveStatus, deleteStatus, searchKey },
    selectPageType,
    categoryId,
  } = useSelector(pageBuilderSelector.templates);
  const { role, plan } = useSelector(authSelector);

  const getTemplates = useGetTemplatesPage();
  const loadMoreTemplates = useLoadMoreTemplatePage();
  const savePage = useSaveToMyTemplate();
  const deleteTemplatePage = useDeleteTemplatePage();
  const changeModalAdminSettings = useChangeModalAdminSettings();
  const getCategoriesOfPageProduct = useGetCategoriesOfPageProduct();
  // const getBlogs = useGetBlogs();
  // const getProducts = useGetProducts();
  // const getCollections = useGetCollections();
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();
  const setPages = useSetPages();

  const [currentPage, setCurrentPage] = useState<AdminPageData | undefined>(undefined);
  const [previewPage, setPreviewPage] = useState<BE_PageProduct | undefined>(undefined);
  const isInBuilder = isPageBuilder() || isThemeBuilder();

  useEffect(() => {
    if (tabKey === 'all') {
      getTemplates.request({ tabKey, limit: 20, pageType: selectPageType, label: searchKey, categoryId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKey, selectPageType, searchKey, categoryId]);

  useEffect(() => {
    if (tabKey === 'all') {
      getCategoriesOfPageProduct.request({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKey]);

  const openModal = (page: AdminPageData) => () => {
    if (validatePlan({ userPlan: plan.planHandle, itemPlan: page.planHandle ?? PLAN_ENUM.free, role }) === 'pass') {
      setCurrentPage(page);
      if (page.type === 'article') {
        changeModalAdminSettings({ createArticle: true });
      } else if (page.type === 'product') {
        changeModalAdminSettings({ createProduct: true });
      } else if (page.type === 'collection') {
        changeModalAdminSettings({ createCollection: true });
      } else if (page.type === 'blog') {
        changeModalAdminSettings({ createBlog: true });
      } else {
        changeModalAdminSettings({ createNormalPage: true });
      }
    } else {
      setPlanRequesting({
        handle: PLAN_STATIC_DATA[page.planHandle ?? PLAN_ENUM.business].handle,
        type: PLAN_STATIC_DATA[page.planHandle ?? PLAN_ENUM.business].type,
        pricePerMonth: PLAN_STATIC_DATA[page.planHandle ?? PLAN_ENUM.business].pricePerMonth,
        pricePerYear: PLAN_STATIC_DATA[page.planHandle ?? PLAN_ENUM.business].pricePerYear,
        title: PLAN_STATIC_DATA[page.planHandle ?? PLAN_ENUM.business].title,
        originalPricePerMonth: PLAN_STATIC_DATA[page.planHandle ?? PLAN_ENUM.business].pricePerMonth,
        originalPricePerYear: PLAN_STATIC_DATA[page.planHandle ?? PLAN_ENUM.business].pricePerYear,
      });
      setValidateCouponVisible(true);
    }
  };

  const handleDeletePage = (page: AdminPageData) => () => {
    deleteTemplatePage.request({ commandId: page.commandId });
  };

  const renderLoading = (
    <GridSmart columnWidth={250} columnGap={20} columnCount={5}>
      {[0, 1, 2, 3, 5].map(item => (
        <ImageTextCard.Loading key={item} height={350} />
      ))}
    </GridSmart>
  );

  const handlePreview = (page: BE_PageProduct) => {
    if (!page.previewUrl?.includes('https://landing.vedabuilder.com')) {
      window.open(page.previewUrl, '_blank');
    } else {
      setPreviewPage(page);
    }
  };

  const renderSuccess = () => {
    const rowCount = Math.ceil(dataState.length / COLUMN_COUNT);

    return (
      <VirtualList
        disabledScrollStyle={false}
        rowCount={rowCount}
        rowHeight={ROW_HEIGHT}
        containerCss={isInBuilderCss}
        rowRender={index => {
          const dataSlice = dataState.slice(index * COLUMN_COUNT, index * COLUMN_COUNT + COLUMN_COUNT);
          const rowItem = dataSlice.length ? dataSlice : hasNextPage ? Array(COLUMN_COUNT).fill(undefined) : [];

          return (
            <GridSmart columnWidth={250} columnGap={COLUMN_GAP} columnCount={COLUMN_COUNT}>
              {rowItem.map(page => {
                const { label, image, commandId, type, category, parentCommandId, planHandle, previewUrl } = page;
                if (typeof page === 'undefined' && loadMoreStatus !== 'failure') {
                  return (
                    <ViewportTracking
                      key={index}
                      offsetTop={-100}
                      onEnterViewport={() => {
                        if (getStatus === 'success' && loadMoreStatus !== 'loading') {
                          loadMoreTemplates.request({ tabKey, pageType: selectPageType, categoryId });
                        }
                      }}
                    >
                      <View css={styles.loadMoreContainer}>
                        <ActivityIndicator />
                      </View>
                    </ViewportTracking>
                  );
                }

                return (
                  <ImageTextCard.Style2
                    key={commandId}
                    buttonStyle="style2"
                    planHandle={planHandle}
                    label={category?.name ?? type}
                    description={label}
                    src={image ? imageUrl(image.src, 280) : ''}
                    widthImage={image ? image.width : 0}
                    heightImage={image ? image.height : 0}
                    loadingAdd={saveStatus[parentCommandId] === 'loading' || deleteStatus[commandId] === 'loading'}
                    onSave={() => {
                      savePage.request({
                        categories: category?.name ? [category.name] : ([] as string[]),
                        parentCommandId,
                        name: label,
                        image: image ?? { src: '', height: 0, width: 0 },
                        pageType: type,
                      });
                    }}
                    onPreview={!!previewUrl ? () => handlePreview(page) : undefined}
                    onAdd={openModal((page as unknown) as AdminPageData)}
                    onDelete={role === 'admin' ? handleDeletePage((page as unknown) as AdminPageData) : undefined}
                  />
                );
              })}
            </GridSmart>
          );
        }}
      />
    );
  };

  const handleCancel = () => {
    setCurrentPage(undefined);
  };

  const handleCancelPreview = () => {
    setPreviewPage(undefined);
    setPages({});
  };

  const renderModalCreatePage = () => {
    if (currentPage) {
      if (currentPage.type === 'article') {
        return <ModalCreateArticle forceActiveDrawer={true} pageInput={currentPage as any} onCancel={handleCancel} />;
      } else if (currentPage.type === 'blog') {
        return <ModalCreateBlog forceActiveDrawer={true} pageInput={currentPage as any} onCancel={handleCancel} />;
      } else if (currentPage.type === 'collection') {
        return <ModalCreateCollection forceActiveDrawer={true} pageInput={currentPage as any} onCancel={handleCancel} />;
      } else if (currentPage.type === 'product') {
        return <ModalCreateProduct forceActiveDrawer={true} pageInput={currentPage as any} onCancel={handleCancel} />;
      } else {
        return (
          <ModalCreateNormalPage
            forceActiveDrawer={true}
            pageInput={currentPage as any}
            pageType={defaultPageType.includes(selectPageType) ? (selectPageType as PageType) : currentPage.type}
            onCancel={handleCancel}
          />
        );
      }
    }
    return null;
  };

  return (
    <View
      css={{
        height: isInBuilder ? '600px !important' : 'calc(100% - 200px) !important',
        paddingBottom: isInBuilder ? '80px' : undefined,
      }}
    >
      <AsyncComponent status={getStatus} Request={renderLoading} isEmpty={isEmpty(dataState)} Success={renderSuccess()} />

      {renderModalCreatePage()}

      {previewPage && <ModalPreviewPage page={previewPage} onCancel={handleCancelPreview} />}
    </View>
  );
};
