export const schema = {
  fields: '%% text %% Fields',
  type: 'Loại',
  name: 'Tên',
  label: 'Label',
  summary: 'Tóm tắt',
  default: 'Mặc địh',
  value: 'Giá trị %% text %%',
  conditions: 'Điều kiện',
  clear_conditions: 'Xóa điều kiện',
  force_render_section: 'Buộc chạy lại phần này',
  children: 'Phần tử con',
  add: 'Thêm %% text %%',
  default_picker_relate_shopify_non_exist: '%% object_name %% không tồn tại',
  create: 'Tạo tại đây',
  fieldLabel: {
    array: 'Mảng',
    object: 'Đối tượng',
    articlePicker: 'Article',
    blogPicker: 'Blog',
    collectionPicker: 'Collection',
    color: 'Color',
    date: 'Date',
    flexOrder: 'Flex Order',
    font: 'Font',
    icon: 'Icon',
    image: 'Image',
    linkPicker: 'Link',
    navigation: 'Navigation',
    productPicker: 'Product',
    radioGroup: 'Radio Group',
    responsive: 'Responsive',
    select: 'Select',
    slider: 'Slider',
    space: 'Space',
    styleBox: 'Style',
    switch: 'Switch',
    text: 'Text',
    textarea: 'Textarea',
    textReadOnly: 'Text Read Only',
    video: 'Video',
    tags: 'Tags',
    richText: 'Rich Text',
    hidden: 'Hidden',
    divider: 'Divider',
    products: 'Products',
    animate: 'Animate',
    animateInOut: 'Animate In Out',
    color_scheme: 'Bảng màu',
    upload_csv: 'Tải lên CSV',
  },
  automated: 'Đã được dịch tự động',
  must_be_object: 'Bạn cần xoá các field %% field_types %% mới có thể thay đổi',
} as const;
