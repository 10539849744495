import { saveAddonsInPublishTheme } from 'containers/Admin/ThemeBuilder/ThemeDashboard/sagas/watchActiveThemeVeda';
import { themeDashboardSelector } from 'containers/Admin/ThemeBuilder/ThemeDashboard/slice/sliceThemeDashboard';
import { setResponsive } from 'containers/BuilderPage/store/responsive/slice';
import { MODAL_REPORT_SAVE_ERROR } from 'containers/ModalReportAfterError/const';
import { ModalReportAfterError } from 'containers/ModalReportAfterError/ModalReportAfterError';
import { uniqBy } from 'lodash';
import { all, call, put, retry, SagaReturnType, select } from 'redux-saga/effects';
import { addonService } from 'services/AddonService';
import { adapterGetAddons } from 'services/AddonService/Adapters/adapterGetAddons';
import { megaMenuService } from 'services/MegaMenuService';
import { sectionService } from 'services/SectionService';
import { createAddonOfThemeClient } from 'services/ThemeService/Logic/createAddonOfThemeClient';
import { createHeaderOrFooterOfThemeClient } from 'services/ThemeService/Logic/createHeaderOrFooterOfThemeClient';
import { createMegamenuOfThemeClient } from 'services/ThemeService/Logic/createMegamenuOfThemeClient';
import { createSectionOfPageInThemeClient } from 'services/ThemeService/Logic/createSectionOfPageInThemeClient';
import { updateAddonOfThemeClient } from 'services/ThemeService/Logic/updateAddonOfThemeClient';
import { updateHeaderOrFooterOfThemeClient } from 'services/ThemeService/Logic/updateHeaderOrFooterOfThemeClient';
import { updateMegamenuOfThemeClient } from 'services/ThemeService/Logic/updateMegamenuOfThemeClient';
import { updatePageOfThemeClient } from 'services/ThemeService/Logic/updatePageOfThemeClient';
import { updateSectionOfPageInThemeClient } from 'services/ThemeService/Logic/updateSectionofPageInThemeClient';
import { updateThemeClient } from 'services/ThemeService/Logic/updateThemeClient';
import {
  removeDeletedSectionAddonMegaMenuCommandIds,
  setPageSaved,
  updateFootersToPage,
  updateHeadersToPage,
  updateMainSectionsToPages,
  updateMegaMenusToPage,
} from 'store/actions/actionPages';
import { setVendors } from 'store/actions/actionVendors';
import { setGeneralSettingsPage } from 'store/global/generalSettings/slice';
import { setGlobalJs } from 'store/global/globalJs/slice';
import { setGlobalScss } from 'store/global/globalScss/slice';
import { syncToShopify } from 'store/global/socket/syncShopifyNClonePageAtomServiceToThemeAtomService/actions';
import { syncPageNotification } from 'store/global/socket/syncShopifyNClonePageAtomServiceToThemeAtomService/watchSyncToShopify';
import { updateThemeAddonsToPage } from 'store/global/themeAddons/actions';
import { setThemeFooters, setThemeHeaders } from 'store/global/themeHeaderFooter/slice';
import { setIsSaveAddons, setOriginalThemeAddons } from 'store/reducers/sliceOriginThemeAddons';
import { pageSectionsSelector, pagesSelector } from 'store/selectors';
import { i18n } from 'translation';
import { AddonOfTheme_Atom_N_Client, ThemeAddon } from 'types/Addons';
import { PageOfThemeService } from 'types/Page';
import { Result } from 'types/Result';
import { SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client } from 'types/Sections';
import { isDefault, isMain } from 'utils/functions/checkSectionType';
import { deepFind } from 'utils/functions/deepFind';
import getPageInfo from 'utils/functions/getInfo';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { saveTheme } from '../../actions';
import { setVisibleModalSaveTheme } from '../../slice';

export function* handleSaveSectionOfPageInThemeClient(section: SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client) {
  const pageSections: ReturnType<typeof pageSectionsSelector> = yield select(pageSectionsSelector);

  const megaMenuIds = deepFind(section, 'megaMenuId');
  const megaMenuSections = pageSections.filter(item => megaMenuIds.includes(item.id));

  /** Xử lý lưu mega menu */
  const megamenuResponses: Array<Awaited<ReturnType<typeof createMegamenuOfThemeClient | typeof updateMegamenuOfThemeClient>>> = yield all(
    megaMenuSections.map(megamenuSection => {
      if (megamenuSection.commandId) {
        return retry(3, 1000, updateMegamenuOfThemeClient, {
          megamenu: megamenuSection as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client,
        });
      } else {
        return retry(3, 1000, createMegamenuOfThemeClient, {
          megamenu: megamenuSection as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client,
        });
      }
    }),
  );
  const megaMenuCommandIds = megamenuResponses.map(megamenuResponse => megamenuResponse.commandId).filter(Boolean);
  yield put(updateMegaMenusToPage({ megaMenus: megamenuResponses }));

  /** Xử lý lưu section */
  if (section.commandId) {
    const response: Awaited<ReturnType<typeof updateSectionOfPageInThemeClient>> = yield retry(3, 1000, updateSectionOfPageInThemeClient, {
      section: { ...section, megaMenuCommandIds } as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client,
    });

    return response;
  } else {
    const response: Awaited<ReturnType<typeof createSectionOfPageInThemeClient>> = yield retry(3, 1000, createSectionOfPageInThemeClient, {
      section: { ...section, megaMenuCommandIds } as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client,
    });
    return response;
  }
}

export function* handleSaveHeaderOrFooterOfThemeClient(headerOrFooter: SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client) {
  const pageSections: ReturnType<typeof pageSectionsSelector> = yield select(pageSectionsSelector);

  const megaMenuIds = deepFind(headerOrFooter, 'megaMenuId');
  const megaMenuSections = pageSections.filter(item => megaMenuIds.includes(item.id));

  /** Xử lý lưu mega menu */
  const megamenuResponses: Array<Awaited<ReturnType<typeof createMegamenuOfThemeClient | typeof updateMegamenuOfThemeClient>>> = yield all(
    megaMenuSections.map(megamenuSection => {
      if (megamenuSection.commandId) {
        return retry(3, 1000, updateMegamenuOfThemeClient, {
          megamenu: megamenuSection as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client,
        });
      } else {
        return retry(3, 1000, createMegamenuOfThemeClient, {
          megamenu: megamenuSection as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client,
        });
      }
    }),
  );
  const megaMenuCommandIds = megamenuResponses.map(megamenuResponse => megamenuResponse.commandId).filter(Boolean);
  yield put(updateMegaMenusToPage({ megaMenus: megamenuResponses }));

  /** Xử lý lưu section */
  if (headerOrFooter.commandId) {
    const response: Awaited<ReturnType<typeof updateHeaderOrFooterOfThemeClient>> = yield retry(3, 1000, updateHeaderOrFooterOfThemeClient, {
      headerOrFooter: { ...headerOrFooter, megaMenuCommandIds } as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client,
    });
    return response;
  } else {
    const response: Awaited<ReturnType<typeof createHeaderOrFooterOfThemeClient>> = yield retry(3, 1000, createHeaderOrFooterOfThemeClient, {
      headerOrFooter: { ...headerOrFooter, megaMenuCommandIds } as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client,
    });
    return response;
  }
}

function* handleSavePageInThemeClient(
  pageResult: Result['pages'][string],
  outputBuilder: Result,
  addons: ThemeAddon[],
  headerNFooter: SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
) {
  const { page, pageSettings } = pageResult.data;
  const mainSections = page.sections.filter(section => isMain(section.type) || isDefault(section.type));
  // TODO: Utils transform thay vì ép kiểu
  const page_ = page as PageOfThemeService;

  /** Save sections & megamenu */
  // TODO: Utils transform thay vì ép kiểu
  const sectionResponses: Array<Awaited<ReturnType<typeof updateSectionOfPageInThemeClient | typeof createSectionOfPageInThemeClient>>> = yield all(
    mainSections.map(section => call(handleSaveSectionOfPageInThemeClient, section as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client)),
  );

  yield put(updateMainSectionsToPages({ sections: sectionResponses, pageId: page_.commandId }));

  /** Save pages */
  const response: Awaited<ReturnType<typeof updatePageOfThemeClient>> = yield retry(3, 1000, updatePageOfThemeClient, {
    page: {
      ...page_,
      sections: sectionResponses.length > 0 ? sectionResponses : [],
      enable: true,
    },
    pageSettings,
  });

  const { info, message } = response;

  // Xử lý addon sau save page để thực hiện gán "belongToPages" cho addons
  /** Delete addons */
  const { deletedAddonIds }: ReturnType<typeof pagesSelector> = yield select(pagesSelector);
  if (deletedAddonIds.length > 0) {
    for (const deletedAddonId of deletedAddonIds) {
      yield retry(3, 1000, addonService.addons.deleteClientAddons, { commandId: deletedAddonId.commandId });
      yield put(removeDeletedSectionAddonMegaMenuCommandIds({ deletedAddonCommandId: deletedAddonId.commandId }));
    }
  }
  /** Save addons */
  // TODO: Utils transform thay vì ép kiểu
  const addonResponses: Array<Awaited<ReturnType<typeof createAddonOfThemeClient | typeof updateAddonOfThemeClient>>> = yield all(
    addons.map(addon => {
      const currentPage = info;
      const isUsedInCurrentPage = mainSections.find(section => section.addonIds?.includes(addon.sectionId));
      const isUsedInHeaderOrFooter = headerNFooter.find(section => section.addonIds?.includes(addon.sectionId));
      const prevBelongToPages =
        (addon as AddonOfTheme_Atom_N_Client).belongsToPage?.filter(
          page => page.commandId !== 'allPages' && page.commandId !== currentPage.commandId,
        ) ?? [];
      const currentBelongToPages: AddonOfTheme_Atom_N_Client['belongsToPage'] = (isUsedInCurrentPage ? [info] : []).map(page => ({
        commandId: page.commandId,
        label: page.label,
      }));
      const finalBelongToPages = uniqBy(prevBelongToPages.concat(currentBelongToPages), 'commandId');
      if (addon.commandId) {
        return retry(3, 1000, updateAddonOfThemeClient, {
          addon: {
            ...addon,
            belongsToPage: isUsedInHeaderOrFooter
              ? finalBelongToPages.concat({
                  label: 'All Pages',
                  commandId: 'allPages',
                })
              : finalBelongToPages,
            category: undefined,
          } as AddonOfTheme_Atom_N_Client,
        });
      }
      return retry(3, 1000, createAddonOfThemeClient, {
        addon: {
          ...addon,
          belongsToPage: isUsedInHeaderOrFooter
            ? finalBelongToPages.concat({
                label: 'All Pages',
                commandId: 'allPages',
              })
            : finalBelongToPages,
          category: undefined,
        } as AddonOfTheme_Atom_N_Client,
      });
    }),
  );
  const addonCommandIds = addonResponses.map(addonResponse => addonResponse.commandId);
  yield put(updateThemeAddonsToPage.success({ addons: adapterGetAddons(addonResponses) }));
  yield put(setIsSaveAddons(true));
  yield put(setOriginalThemeAddons(adapterGetAddons(addonResponses)));

  if (info.pageSettings) {
    yield put(setGeneralSettingsPage({ settings: info.pageSettings.generalSettings }));
    yield put(setVendors({ vendors: info.pageSettings.vendors }));
    yield put(setGlobalJs({ js: info.pageSettings.globalJs }));
    yield put(setGlobalScss({ scss: info.pageSettings.globalScss }));
  }

  // Dữ liệu trong reducer = dữ liệu BE trả về nên không cần set
  // yield put(
  //   setPage({
  //     ...page,
  //     commandId: info.commandId,
  //   }),
  // );
  notifyAxiosHandler.handleSuccess(message);

  outputBuilder = {
    ...outputBuilder,
    pages: {
      ...outputBuilder.pages,
      [page.commandId]: {
        ...outputBuilder.pages[page.commandId],

        /**
         * @deprecated Anh Long đang xử lý bằng js nên không cần ghi atomic css lên shopify nữa
         */
        // atomicCss: outputBuilder.pages[page.commandId].atomicCss,
        data: {
          ...outputBuilder.pages[page.commandId].data,
          page: {
            ...page,
            commandId: response.info.commandId,
          },
        },
        files: outputBuilder.pages[page.commandId].files,
      },
    },
  };

  return { outputBuilder, page: info, addonCommandIds };
}

function* deleteSectionAddonMegaMenuOfThemeClient() {
  const { deletedMegaMenuCommandIds, deletedSectionCommandIds, deletedAddonIds }: ReturnType<typeof pagesSelector> = yield select(pagesSelector);

  if (deletedSectionCommandIds.length > 0) {
    for (const deletedSectionCommandId of deletedSectionCommandIds) {
      yield retry(3, 1000, sectionService.sections.deleteClientSections, { commandId: deletedSectionCommandId });
      yield put(removeDeletedSectionAddonMegaMenuCommandIds({ deletedSectionCommandId }));
    }
  }
  if (deletedMegaMenuCommandIds.length > 0) {
    for (const deletedMegaMenuCommandId of deletedMegaMenuCommandIds) {
      yield retry(3, 1000, megaMenuService.mega_menu.deleteClientMegaMenus, { commandId: deletedMegaMenuCommandId });
      yield put(removeDeletedSectionAddonMegaMenuCommandIds({ deletedMegaMenuCommandId }));
    }
  }

  if (deletedAddonIds.length > 0) {
    for (const deletedAddonId of deletedAddonIds) {
      yield retry(3, 1000, addonService.addons.deleteClientAddons, { commandId: deletedAddonId.commandId });
      yield put(removeDeletedSectionAddonMegaMenuCommandIds({ deletedAddonCommandId: deletedAddonId.commandId }));
    }
  }
  return deletedAddonIds;
}

export function* handleSaveThemeClient({ payload }: ReturnType<typeof saveTheme.request>) {
  const {
    outputBuilder,
    footers,
    headers,
    addons,
    globalJs,
    globalScss,
    commandId,
    themeSettings,
    vendors,
    onFulfill,
    isIgnoreSyncShopify,
    onError,
    onSuccess,
  } = payload;
  try {
    const pageId = getPageInfo('id');
    const deletedAddonIds: SagaReturnType<typeof deleteSectionAddonMegaMenuOfThemeClient> = yield call(deleteSectionAddonMegaMenuOfThemeClient);

    const pagesResult = Object.values(outputBuilder.pages);

    const pagesResponse: Array<SagaReturnType<typeof handleSavePageInThemeClient>> = yield all(
      pagesResult.map(pageResult => {
        return call(handleSavePageInThemeClient, pageResult, outputBuilder, addons, [...footers, ...headers]);
      }),
    );
    /** Save header */
    // TODO: Utils transform thay vì ép kiểu
    const headerResponses: Array<Awaited<
      ReturnType<typeof createHeaderOrFooterOfThemeClient | typeof updateHeaderOrFooterOfThemeClient>
    >> = yield all(headers.map(header => call(handleSaveHeaderOrFooterOfThemeClient, header as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client)));
    const headerCommandIds = headerResponses.map(headerResponse => headerResponse.commandId);
    yield put(setThemeHeaders({ headers: headerResponses }));
    yield put(updateHeadersToPage({ headers: headerResponses }));

    /** Save footer */
    // TODO: Utils transform thay vì ép kiểu
    const footerResponses: Array<Awaited<
      ReturnType<typeof createHeaderOrFooterOfThemeClient | typeof updateHeaderOrFooterOfThemeClient>
    >> = yield all(footers.map(footer => call(handleSaveHeaderOrFooterOfThemeClient, footer as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client)));
    const footerCommandIds = footerResponses.map(footerResponse => footerResponse.commandId);
    yield put(setThemeFooters({ footers: footerResponses }));
    yield put(updateFootersToPage({ footers: footerResponses }));

    syncPageNotification.next({
      title: i18n.t('publish_shopify.sync_page_message.step', { text: '2' }),
      description: i18n.t('general.saving', { text: i18n.t('general.theme') }),
    });

    /** Save theme */
    const addonCommandIds = pagesResponse.reduce<string[]>((result, response) => {
      return result.concat(...response.addonCommandIds);
    }, []);
    yield retry(3, 1000, updateThemeClient, {
      theme: {
        commandId,
        footerSectionCommandIds: footerCommandIds,
        headerSectionCommandIds: headerCommandIds,
        addonCommandIds,
        globalJs,
        globalScss,
        themeSettings,
        vendors: vendors.data,
      },
    });

    const { themeActivate }: ReturnType<typeof themeDashboardSelector> = yield select(themeDashboardSelector);
    if (isIgnoreSyncShopify) {
      onFulfill?.();
      onSuccess?.();
    } else {
      yield put(
        syncToShopify.request({
          deletedAddonIds,
          entityVariant: 'Client',
          result: outputBuilder,
          isOverrideIndividualPages: false, // Theme chịu trách nhiệm ghi file index.json cho mỗi loại page, không lo phần update template_suffix
          onSyncFulfill: () => {
            onFulfill?.();
            onSuccess?.();
          },
        }),
      );
    }
    yield call(saveAddonsInPublishTheme, themeActivate.addonCommandIds);

    yield put(setPageSaved({ pageId: pageId, isSaved: true }));

    notifyAxiosHandler.handleSuccess(`${i18n.t('general.update', { text: i18n.t('general.successfully') })}`);
    yield put(saveTheme.success(undefined));
    yield put(setResponsive('desktop'));
    yield put(setVisibleModalSaveTheme(false));
  } catch (error) {
    onFulfill?.();
    onError?.();
    syncPageNotification.done();
    const error_ = error as Error;
    notifyAxiosHandler.handleError(error_);
    yield put(saveTheme.failure(undefined));
    ModalReportAfterError.getActions(MODAL_REPORT_SAVE_ERROR).report({
      cause: i18n.t('ModalReportAfterError.error_cause.save_result'),
      description: error_.message,
    });
    throw error;
  }
}
