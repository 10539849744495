import { call } from 'redux-saga/effects';
import { handleExtractResultForForceSync } from 'services/ShopifyConnection';
import { syncTranslation, SyncTranslationResult } from './syncTranslation';

export interface SyncTranslations {
  syncTranslationsParams: ReturnType<typeof handleExtractResultForForceSync>['syncTranslationsParams'];
}

export interface SyncTranslationsResult {
  statusSyncTranslations: SyncFulfillStatus;
  messageError: string | undefined;
}

export function* syncTranslations({ syncTranslationsParams }: SyncTranslations) {
  for (const syncTranslationParams of syncTranslationsParams) {
    const { statusSyncTranslation, messageError }: SyncTranslationResult = yield call(syncTranslation, { syncTranslationParams });
    if (statusSyncTranslation !== 'success') {
      return {
        statusSyncTranslations: 'failure',
        messageError,
      } as SyncTranslationsResult;
    }
  }
  return {
    statusSyncTranslations: 'success',
    messageError: undefined,
  } as SyncTranslationsResult;
}
