import { deletePageUseCase } from 'containers/Admin/Management/Pages/store/actions/actionPageUseCase';
import { SagaReturnType, put, retry, takeEvery } from 'redux-saga/effects';
import { deleteUseCaseOfProdPage } from 'services/PageService/Logic/deleteUseCaseOfProdPage';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';

function* handleDelete({ payload: { commandId } }: ReturnType<typeof deletePageUseCase.request>) {
  try {
    const response: SagaReturnType<typeof deleteUseCaseOfProdPage> = yield retry(3, 1000, deleteUseCaseOfProdPage, { commandId });

    yield put(deletePageUseCase.success({ commandId }));
    notifyAxiosHandler.handleSuccess(response.message);
  } catch (error) {
    const error_ = error as Error;
    notifyAxiosHandler.handleError(error_);
    yield put(deletePageUseCase.failure({ commandId }));
  }
}

export function* watchDeletePageUseCase() {
  yield takeEvery(getActionType(deletePageUseCase.request), handleDelete);
}
