import { mergeDeepLeft, sort } from 'ramda';
import { ThemeSettings } from './@types/ThemeSettings';
import { handleImgInLiquid } from 'utils/functions/handleImgInLiquid';
import { Locales } from '../@types/Veda/ShopifyLocales';
import { Section } from './@types/Section';
import { SettingBlockArray } from './SchemaBlocks/SettingBlockArray/@types/SettingBlockArray';
import { LIQUID_SettingBlockArray, SCHEMA_SettingBlockArray, SETTING_SettingBlockArray } from './SchemaBlocks/SettingBlockArray/SettingBlockArray';
import {
  LIQUID_SettingBlockObject,
  SCHEMA_SettingBlockObject,
  SETTING_SettingBlockObject,
} from './SchemaBlocks/SettingBlockObject/SettingBlockObject';
import { LIQUID_Converters } from './utils/LIQUID_Converters';
import { SCHEMA_Converters } from './utils/SCHEMA_Converters';
import { SETTING_Converters } from './utils/SETTING_Converters';
import { sortWithShopifyFieldIdSignal } from '../@utils/sortWithShopifyFieldIdSignal';
import { SettingBlockObject } from './SchemaBlocks/SettingBlockObject/@types/SettingBlockObject';
import { strToRegexpPattern } from '../@utils/strToRegexpPattern';
import { convertToSyntaxShopifyDataType } from 'utils/LiquidSyntaxToTwig/utils/convertToSyntaxShopifyDataType';
import { reduceTotalLinesOfCodeByReduceSchemaSettingsSignal } from '../@consts';
import { clearCache } from './CacheShopifyFieldId';
import { getRegexMatchSignalCreatedByTemplateCoders } from '../@utils/getRegexMatchSignalCreatedByTemplateCoders';
import { getBOCsBetweenSomething } from '../@utils/getBOCsBetweenSomething';
import { getMatches } from '../@utils/getMatches';
import { at } from 'utils/at';
import { convertToValidClass } from './utils/convertToValidClass';

type ShopifySetting = Record<string, string | number | boolean | undefined>;

interface ShopifyBlockInSchema {
  type: string;
  settings: ShopifySetting;
}

interface ShopifySchema {
  name: string;
  class: string;
  tag: keyof HTMLElementTagNameMap;
  blocks: ReturnType<typeof SCHEMA_SettingBlockArray>['shopifyField'];
  settings: ReturnType<typeof SCHEMA_SettingBlockObject>['shopifyField'];
  presets:
    | [
        {
          name: ShopifySchema['name'];
          blocks: Array<Pick<ShopifyBlockInSchema, 'type'>>;
        },
      ]
    | undefined;
}

interface RTSectionConverter {
  liquid: string;
  schema: ShopifySchema;
  settings: ShopifySetting;
  blocks: Record<string, ShopifyBlockInSchema>;
  block_order: Array<keyof RTSectionConverter['blocks']>;
  locales: Locales;
}

interface SectionConverter {
  lastLiquid: string;
  section: Section;
  themeSettings: ThemeSettings;
  isPreview: boolean;
  isNeedLinkListSetting: boolean;
  isExportForEnvato: boolean;
  /** Pass qua theme check 600 dòng code 1 file bằng cách xoá tất cả schema, chỉ giữ lại các field nhập dữ liệu */
  isNeedReduceTotalLinesOfCode: boolean;
}

export const sectionConverter = ({
  lastLiquid,
  section,
  themeSettings,
  isPreview,
  isNeedLinkListSetting,
  isExportForEnvato,
  isNeedReduceTotalLinesOfCode,
}: SectionConverter): RTSectionConverter => {
  clearCache();
  let liquid_: RTSectionConverter['liquid'] = lastLiquid;
  let settings_: RTSectionConverter['settings'] = {};
  let blocks_: RTSectionConverter['blocks'] = {};
  let block_order_: RTSectionConverter['block_order'] = [];
  let locales_: RTSectionConverter['locales'] = {
    en: {},
    fr: {},
    vi: {},
  };

  const schema_: ShopifySchema = {
    name: section.label,
    class: `${convertToValidClass({ sectionLabel: section.label, prefix: 'veda-' })}`,
    blocks: [],
    settings: isNeedLinkListSetting ? [{ type: 'link_list', id: 'menu', label: 'Menu' }] : [],
    tag: 'div',
    presets: isExportForEnvato
      ? [
          {
            name: section.label,
            blocks: [],
          },
        ]
      : undefined,
  };

  // Tiền xử lý liquid => Xử lý những đoạn đánh dấu tách file
  if (isNeedReduceTotalLinesOfCode) {
    const { start, end } = getRegexMatchSignalCreatedByTemplateCoders();
    let counter = 0;
    liquid_ = liquid_
      .replace(start, (_, index, origin) => {
        const prevCode = origin.slice(0, index);
        const variableNames = new Set(['section']);
        const BOCs = getBOCsBetweenSomething({
          liquid: prevCode,
          endBOC: new RegExp('%}', 'g'),
          startBOC: new RegExp('{%', 'g'),
          ignoreNested: true,
          isValid: () => true,
          withUniqId: false,
        });
        const forLoopVariables: string[] = [];
        BOCs.forEach(({ content }) => {
          // Xử lý cho assign trước
          if (/assign.*=/.test(content)) {
            const _BOC = content.replace(/\n/g, '');
            const targetAssignClause = getMatches(_BOC, new RegExp(/assign.*=/g))[0];
            const variableName = at(targetAssignClause?.split(' '), 1);
            if (variableName) {
              variableNames.add(variableName.trim());
            }
          } else if (/for\s+.*\s+in/.test(content)) {
            const _BOC = content.replace(/\n/g, '');
            const targetAssignClause = getMatches(_BOC, new RegExp(/for\s+.*\s+in/g))[0];
            const variableName = at(targetAssignClause?.split(' '), 1);
            if (variableName) {
              forLoopVariables.push(variableName);
            }
          }
          // Nếu "endfor" => Biến loop của vòng for trước đó được xoá bỏ
          else if (/{%\s*endfor\s*%}/.test(content)) {
            forLoopVariables.pop();
          }
        });
        forLoopVariables.forEach(variableName => {
          if (variableName) {
            variableNames.add(variableName.trim());
          }
        });
        counter++;
        // Biến "section.settings" mặc định phải được truyền vào
        const commandId = 'parentCommandId' in section ? section.parentCommandId ?? section.commandId : section.commandId;
        return `
          {% comment %}<BẮT_ĐẦU_TÁCH_FILE_${counter}:[${Array.from(variableNames).join(',')}]>{% endcomment %}
          {% comment %}SECTION_COMMAND_ID:${commandId}{% endcomment %}
        `;
      })
      .replace(end, '{% comment %}<KẾT_THÚC_TÁCH_FILE>{% endcomment %}');
  }

  // Xử lý schema & liquid
  const sectionSchemaBlocks = sort(sortWithShopifyFieldIdSignal, section.data.schema.blocks);
  const temporary: Array<{ type: 'settings'; data: ShopifySchema['settings'] } | { type: 'blocks'; data: ShopifySchema['blocks'] }> = []; // Kiểm soát index sao cho khi sync lên shopify thì thứ tự các field giống veda
  sectionSchemaBlocks.forEach(block => {
    const indexInVedaSectionSchema = section.data.schema.blocks.findIndex(item => item.id === block.id);
    if (block.type === 'array') {
      const blockSorted: SettingBlockArray = {
        ...block,
        children: sort(sortWithShopifyFieldIdSignal, block.children),
      };
      const { locales = {}, shopifyField } = SCHEMA_SettingBlockArray({
        section,
        arrayWithChildrenSorted: blockSorted,
        arrayWithChildrenNonSorted: block,
        isExportForEnvato,
        isPreview,
        themeSettings,
        isNeedReduceTotalLinesOfCode,
      });
      // Xử lý locales
      locales_ = mergeDeepLeft(locales_, locales);

      // Xử lý liquid
      liquid_ = LIQUID_SettingBlockArray({
        section,
        array: block,
        liquid: liquid_,
        isExportForEnvato,
        isPreview,
        themeSettings,
        isNeedReduceTotalLinesOfCode,
      });

      // Xử lý schema
      temporary[indexInVedaSectionSchema] = { type: 'blocks', data: shopifyField };
    } else if (block.type === 'object') {
      const blockSorted: SettingBlockObject = {
        ...block,
        children: sort(sortWithShopifyFieldIdSignal, block.children),
      };
      const { locales, shopifyField } = SCHEMA_SettingBlockObject({
        section,
        objectWithChildrenSorted: blockSorted,
        objectWithChildrenNonSorted: block,
        isExportForEnvato,
        isPreview,
        themeSettings,
        isNeedReduceTotalLinesOfCode,
      });
      // Xử lý locales
      locales_ = mergeDeepLeft(locales_, locales);

      // Xử lý liquid
      liquid_ = LIQUID_SettingBlockObject({
        section,
        object: block,
        liquid: liquid_,
        isExportForEnvato,
        isPreview,
        themeSettings,
        isNeedReduceTotalLinesOfCode,
      });

      // Xử lý schema
      temporary[indexInVedaSectionSchema] = { type: 'settings', data: shopifyField };
    } else {
      throw new Error('Block chưa được hỗ trợ');
    }
  });
  // Xử lý schema
  temporary.forEach(({ type, data }) => {
    if (type === 'settings') {
      schema_.settings.push(...data);
    } else if (type === 'blocks') {
      schema_.blocks.push(...data);
    }
  });

  const temporary2: Array<{ data: ShopifySchema['settings'] }> = []; // Kiểm soát index sao cho khi sync lên shopify thì thứ tự các field giống veda
  const sectionSchemaSettings = sort(sortWithShopifyFieldIdSignal, section.data.schema.settings);
  sectionSchemaSettings.forEach(setting => {
    const indexInVedaSectionSchema = section.data.schema.settings.findIndex(item => item.id === setting.id);
    const schemaConverter = SCHEMA_Converters[setting.type];
    if (schemaConverter) {
      const res = schemaConverter?.({
        // @ts-ignore
        field: setting,
        parentField: undefined,
        isExportForEnvato,
        isPreview,
        section,
        themeSettings,
        isNeedReduceTotalLinesOfCode,
      });
      if (res) {
        const { locales, shopifyField } = res;
        // Xử lý locales
        locales_ = mergeDeepLeft(locales_, locales as Locales);

        // Xử lý liquid
        const liquidConverter = LIQUID_Converters[setting.type];
        const newLiquid =
          liquidConverter?.({
            // @ts-ignore
            field: setting,
            parentField: undefined,
            liquid: liquid_,
            isExportForEnvato,
            isPreview,
            loopVariable: undefined,
            section,
            themeSettings,
            isNeedReduceTotalLinesOfCode,
          }) ?? liquid_;
        liquid_ = newLiquid;

        // Xử lý schema
        if (Array.isArray(shopifyField)) {
          temporary2[indexInVedaSectionSchema] = {
            data: shopifyField,
          };
        } else {
          temporary2[indexInVedaSectionSchema] = {
            data: [shopifyField as ShopifySchema['settings'][number]],
          };
        }
      }
    }
  });
  // Xử lý schema
  temporary2.forEach(({ data }) => {
    schema_.settings.push(...data);
  });

  // Xử lý settings
  section.data.settings.forEach(setting => {
    if (setting.type === 'array') {
      const arrayItems = SETTING_SettingBlockArray({
        section,
        isPreview,
        array: setting,
        isExportForEnvato,
        schemaOfItself: section.data.schema.blocks.find(block => block.type === 'array' && setting.id === block.id) as SettingBlockArray,
        themeSettings,
        isNeedReduceTotalLinesOfCode,
      });
      blocks_ = {
        ...blocks_,
        ...arrayItems,
      };
      block_order_ = block_order_.concat(...Object.keys(arrayItems));
    } else if (setting.type === 'object') {
      settings_ = {
        ...settings_,
        ...SETTING_SettingBlockObject({
          section,
          object: setting,
          isPreview,
          isExportForEnvato,
          themeSettings,
          isNeedReduceTotalLinesOfCode,
        }),
      };
    } else {
      const settingConveter = SETTING_Converters[setting.type];
      if (settingConveter) {
        const res =
          settingConveter({
            // @ts-ignore
            field: setting,
            parentField: undefined,
            isExportForEnvato,
            isPreview,
            section,
            themeSettings,
            isNeedReduceTotalLinesOfCode,
          }) ?? {};
        settings_ = {
          ...settings_,
          ...res,
        };
      }
    }
  });

  // Xử lý các filter image_urls
  if (!isExportForEnvato) {
    liquid_ = handleImgInLiquid(liquid_);
  }

  // Default blocks
  if (schema_.presets) {
    schema_.presets[0].blocks = Object.values(blocks_).map(item => ({
      type: item.type,
      settings: undefined,
    }));
  }

  if (isNeedReduceTotalLinesOfCode) {
    schema_.settings = schema_.settings.filter(setting => {
      return 'id' in setting && !setting.id.startsWith(reduceTotalLinesOfCodeByReduceSchemaSettingsSignal.start);
    });
    schema_.blocks = schema_.blocks.map(block => {
      return {
        ...block,
        settings: block.settings.filter(setting => {
          return 'id' in setting && !setting.id.startsWith(reduceTotalLinesOfCodeByReduceSchemaSettingsSignal.start);
        }),
      };
    });
    Object.keys(settings_).forEach(variableName => {
      if (variableName.startsWith(reduceTotalLinesOfCodeByReduceSchemaSettingsSignal.start)) {
        const variableValue = settings_[variableName];
        liquid_ = liquid_.replace(
          new RegExp(strToRegexpPattern(variableName), 'g'),
          variableValue ? convertToSyntaxShopifyDataType(variableValue) : 'null',
        );
        delete settings_[variableName];
      }
    });
    Object.keys(blocks_).forEach(blockId => {
      const { settings } = blocks_[blockId];
      Object.keys(settings).forEach(variableName => {
        if (variableName.startsWith(reduceTotalLinesOfCodeByReduceSchemaSettingsSignal.start)) {
          const variableValue = settings[variableName];
          liquid_ = liquid_.replace(
            new RegExp(strToRegexpPattern(variableName), 'g'),
            variableValue ? convertToSyntaxShopifyDataType(variableValue) : 'null',
          );
          delete settings[variableName];
        }
      });
    });
  }

  getBOCsBetweenSomething({
    startBOC: new RegExp(`${strToRegexpPattern(reduceTotalLinesOfCodeByReduceSchemaSettingsSignal.start)}`, 'g'),
    endBOC: new RegExp(`${strToRegexpPattern(reduceTotalLinesOfCodeByReduceSchemaSettingsSignal.end)}`, 'g'),
    isValid: () => true,
    ignoreNested: true,
    liquid: liquid_,
    withUniqId: false,
  }).forEach(({ content }) => {
    liquid_ = liquid_.replace(content, 'null');
  });

  // nối schema vào liquid
  liquid_ = liquid_.concat(`{% schema %}${JSON.stringify(schema_, undefined, 2)}{% endschema %}`);

  return {
    block_order: block_order_,
    blocks: blocks_,
    // Theme check các biến không liên quan đến shopify bị "undefined" -> Cần assign
    // Ví dụ "buiderMode"
    liquid: `
      {% assign uniqueId = "${section.id}" %}
      {% assign builderMode = false %}
      ${isExportForEnvato ? '{% assign envatoMode = true %}' : '{% assign envatoMode = false %}'}
      ${liquid_}
    `,
    locales: locales_,
    schema: schema_,
    settings: settings_,
  };
};
