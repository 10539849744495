import { userApis } from 'services/UserService/apis';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

interface ImportPageTemplateToThemeService {
  commandId: string;
  entityType: EntityType;
  eventId: string | undefined;
}

export const importPageTemplateToThemeService = async ({ commandId, entityType, eventId }: ImportPageTemplateToThemeService) => {
  const { role } = getUserInfo();
  if (entityType === 'Atom') {
    if (role === 'admin') {
      const response = await themeApis.atom.adminApi.page.migratePageAtomToService({
        pageAtomCommandId: commandId,
        eventId,
        eventType: 'Clone các thành phần từ page atom service sang theme atom service',
      });
      await userApis.user.adminApi.updateVedaCacheVersion();
      return response.info;
    }
  } else if (entityType === 'Draft') {
    if (role === 'admin' || role === 'dev') {
      const response = await themeApis.atom.devApi.page.migratePageAtomToService({
        pageAtomCommandId: commandId,
        eventId,
        eventType: 'Clone các thành phần từ page atom service sang theme atom service',
      });
      await userApis.user.adminApi.updateVedaCacheVersion();
      return response.info;
    }
  }
  throw new RoleException();
};
