import { createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';

type Actions =
  | {
      type: 'setTourGuideTheme';
      payload: Partial<State['themeTour']>;
    }
  | {
      type: 'setTourGuideAddon';
      payload: Partial<State['addonsTour']>;
    }
  | {
      type: 'setTourGuidePage';
      payload: Partial<State['pageTour']>;
    };

interface State {
  themeTour: {
    run: boolean;
    stepIndex: number;
  };
  addonsTour: {
    run: boolean;
    stepIndex: number;
  };
  pageTour: {
    run: boolean;
    stepIndex: number;
  };
}

export const sliceTourGuides = createSlice<State, Actions>({
  name: '@App ',
  initialState: {
    addonsTour: {
      run: true,
      stepIndex: 0,
    },
    themeTour: {
      run: true,
      stepIndex: 0,
    },
    pageTour: {
      run: true,
      stepIndex: 0,
    },
  },
  reducers: [
    handleAction('setTourGuideAddon', ({ state, action }) => {
      state.addonsTour = {
        ...state.addonsTour,
        ...action.payload,
      };
    }),
    handleAction('setTourGuideTheme', ({ state, action }) => {
      state.themeTour = {
        ...state.themeTour,
        ...action.payload,
      };
    }),
    handleAction('setTourGuidePage', ({ state, action }) => {
      state.pageTour = {
        ...state.pageTour,
        ...action.payload,
      };
    }),
  ],
});

export const tourGuideSelector = (state: AppState) => state.tourGuide;

export const { setTourGuideAddon, setTourGuideTheme, setTourGuidePage } = sliceTourGuides.actions;

export const useSetTourGuideTheme = createDispatchAction(setTourGuideTheme);
export const useSetTourGuideAddon = createDispatchAction(setTourGuideAddon);
export const useSetTourGuidePage = createDispatchAction(setTourGuidePage);
