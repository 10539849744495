import { AxiosError } from 'axios';
import { SagaReturnType, put, retry, takeLatest } from 'redux-saga/effects';
import { shopifyConnectionService } from 'services/ShopifyConnection';
import { importFileCSV } from 'store/global/importCSV/actionsImport';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';

function* handleImport({ payload: { email, file, onFulfilled } }: ReturnType<typeof importFileCSV.request>) {
  try {
    const response: SagaReturnType<typeof shopifyConnectionService.importCSVFile> = yield retry(3, 1000, shopifyConnectionService.importCSVFile, {
      email,
      file,
    });

    if (response.status === 200) {
      yield put(importFileCSV.success({ message: response.data.message }));
      onFulfilled?.();
    } else {
      yield put(importFileCSV.failure({ message: response.data.message }));
    }
  } catch (error) {
    notifyAxiosHandler.handleError(error as AxiosError<ErrorData> | Error);
    yield put(importFileCSV.failure({ message: (error as AxiosError<ErrorData>).response?.data.message || (error as Error).message }));
  }
}

export function* watchImportFileCSV() {
  yield takeLatest(getActionType(importFileCSV.request), handleImport);
}
