import { AsyncComponent, VedaLoadingItem, Masonry, Confirm } from '@wiloke/ui';
import SectionCard from 'components/SectionCard';
import {
  useDeleteAdminAddons,
  useForkAddonAdminToDraft,
  useGetAdminAddons,
  useGetAdminAddonsCategory,
  useInstallAdminAddon,
  useLoadMoreAdminAddons,
} from 'containers/ChooseTemplate/store/actions';
import { useSetTemplateBoardVisible } from 'containers/ChooseTemplate/store/actions/actionTemplateBoardVisible';
import {
  useSearchAdminAddonByLabel,
  useSetCurrentAdminAddon,
  useSetModalPublishAddons,
} from 'containers/ChooseTemplate/store/reducers/addons/admin.sliceAddons';
import * as styles from 'containers/ChooseTemplate/styles';
import { useGetDefaultAddons } from 'containers/ChooseTemplate/utils/defaultAddons';
import { range } from 'ramda';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSetAddonToPages } from 'store/actions/actionPages';
import { useSetSidebarTabActive } from 'store/actions/actionSidebarTabActive';
import { useGetAuthors } from 'store/global/authors/action';
import { useSetActiveAddon, useSetThemeAddon } from 'store/global/themeAddons';
import { addonSelector, chooseTemplateVisibleSelector, addonSectionsSelector } from 'store/selectors';
import { i18n } from 'translation';
import { AdminAddon } from 'types/Addons';
import { AdminSection, SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client } from 'types/Sections';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { imageUrl } from '@wiloke/functions';
import { timeConverter } from 'utils/timeAgo';
import { GridSmart, View, ViewportTracking } from 'wiloke-react-core';
import { ModalHotFixAddon } from './ModalHotFixAddon';
import { ModalPublishAddon } from './ModalPublishAddon';
import withDebounce from 'hocs/withDebounce';
import TextInput from 'components/TextInput';
import Field from 'components/Field';
import SelectAntd from 'components/SelectAntd';
import { useSetAdminAddonsCategory } from 'containers/ChooseTemplate/store/reducers/addons/admin.sliceAddonCategory';
import { useSetSectionIdActive } from 'store/actions/actionSectionIdActive';
import { pmParent } from 'utils/functions/postMessage';
import getPageInfo from 'utils/functions/getInfo';
import { isPageBuilder } from 'utils/validateBuilderMode';

const TextInputDebounce = withDebounce(TextInput, 'value', 'onValueChange', 300);

export const AdminAddonTemplate = () => {
  const pageAddons = useSelector(addonSectionsSelector);
  // addons
  const { addons, hasNextPage, getAddonsStatus, loadMoreStatus, deleteStatus, installAddonStatus, searchKey } = useSelector(
    addonSelector.adminAddons,
  );
  const { forkStatus } = useSelector(addonSelector.draftAddons);
  const { getAllStatus, categories, categoryId } = useSelector(addonSelector.adminAddonsCategory);
  const { navKeys, visible } = useSelector(chooseTemplateVisibleSelector);

  const addAddon = useSetThemeAddon();
  const setAddonToPages = useSetAddonToPages();
  const setTemplateBoardVisible = useSetTemplateBoardVisible();
  const setSidebarTabActive = useSetSidebarTabActive();

  const loadMoreAddons = useLoadMoreAdminAddons();
  const getAddonsTemplate = useGetAdminAddons();
  const forkAddonAdminToDraft = useForkAddonAdminToDraft();
  const deleteAddonReq = useDeleteAdminAddons();
  const getAdminCategory = useGetAdminAddonsCategory();
  const { defaultAdminAddon } = useGetDefaultAddons();
  const setModalVisible = useSetModalPublishAddons();
  const installAddon = useInstallAdminAddon();
  const { role } = getUserInfo();
  const getAuthors = useGetAuthors();
  const setCurrentAddon = useSetCurrentAdminAddon();
  const changeAdminCategory = useSetAdminAddonsCategory();
  const setSearchValue = useSearchAdminAddonByLabel();
  const setActiveAddon = useSetActiveAddon();
  const setSectionIdActive = useSetSectionIdActive();

  useEffect(() => {
    if (visible && navKeys[0] === 'admin' && navKeys[1] === 'Addons') {
      getAdminCategory.request(undefined);
      getAuthors.request({ role: 'ADMIN_GLOBAL' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, navKeys, role]);

  useEffect(() => {
    if (visible && navKeys[0] === 'admin' && navKeys[1] === 'Addons' && getAllStatus === 'success') {
      getAddonsTemplate.request({
        categoryId,
        label: searchKey,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, navKeys, getAllStatus, categoryId, searchKey]);

  const saveAddons = (item: AdminAddon, method: 'approve' | 'reject') => () => {
    if (method === 'reject') {
      setCurrentAddon(item);
    }
    if (method === 'approve') {
      setModalVisible({
        addonId: item.commandId ?? '',
        visible: true,
      });
    }
  };

  const handleDeleteAddon = (addon: AdminAddon, callback: () => void) => {
    deleteAddonReq.request({
      addon,
      onFulfill: () => {
        callback();
      },
    });
  };

  /** Add addons vào sidebar */
  const handleInstall = (isInstallToPage: boolean, item: AdminAddon) => () => {
    if (item.commandId) {
      installAddon.request({
        commandId: item.commandId,
        isInstallToPage,
        onFulfilled(response) {
          setActiveAddon({ addon: response });
          setSectionIdActive(response.sectionId);
          pmParent.emit('@section/sectionIdActive', response.sectionId);
        },
      });
    }
  };

  const handleUpdate = (item: AdminAddon) => () => {
    addAddon({
      addon: {
        ...item,
        sectionId: item.body.id,
        body: {
          ...item.body,
          commandId: item.commandId ?? '',
          category: item.category,
          enable: item.enable,
        },
      },
    });
    const newPageSection: AdminSection = {
      ...item.body,
      commandId: item.commandId ?? '',
      category: item.category,
      enable: item.enable,
    };
    setAddonToPages(newPageSection);
    setSidebarTabActive('add-ons');
    setTemplateBoardVisible({
      visible: false,
    });
  };

  const handleFork = (commandId: string) => () => {
    if (commandId !== '') {
      forkAddonAdminToDraft.request({ commandId });
    }
  };

  const renderLoading = (
    <GridSmart columnWidth={200} columnCount={3}>
      {range(0, 3).map(item => (
        <SectionCard.Loading key={item} />
      ))}
    </GridSmart>
  );

  const TrackingLoadMore = useMemo(() => {
    const lastCursor = addons.at(-1)?.commandId;
    if (hasNextPage && lastCursor) {
      return (
        <ViewportTracking
          offsetTop={-200}
          onEnterViewport={() => {
            if (loadMoreStatus !== 'loading') {
              loadMoreAddons.request({
                cursor: lastCursor,
                categoryId,
                label: searchKey,
              });
            }
          }}
        >
          <View css={{ padding: '30px 10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <VedaLoadingItem />
          </View>
        </ViewportTracking>
      );
    }
    return null;
  }, [addons, hasNextPage, loadMoreAddons, loadMoreStatus, categoryId, searchKey]);

  const renderAddons = () => {
    return (
      <View>
        <Masonry defaultColumn={3}>
          {role === 'admin' && (
            <SectionCard
              key="blank"
              title="Blank Admin Addon"
              type={defaultAdminAddon.type}
              buttonText={i18n.t('general.add')}
              onClick={() => {
                addAddon({
                  addon: defaultAdminAddon,
                });
                setAddonToPages(defaultAdminAddon.body);
                setSidebarTabActive('add-ons');
                setTemplateBoardVisible({
                  visible: false,
                });
              }}
            />
          )}

          {addons.map(item => {
            return (
              <SectionCard
                disabled={
                  pageAddons.map(item => (item as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client)?.parentCommandId).includes(item.commandId) &&
                  item.canAddMulti === false
                }
                key={item.commandId}
                title={item.label}
                type={item.type}
                image={item.image ? imageUrl(item.image.src, 300) : undefined}
                loading={forkStatus[item.commandId ?? ''] === 'loading' || installAddonStatus[item.commandId ?? ''] === 'loading'}
                author={item.authorName}
                onClick={handleInstall(false, item)}
                onClick2={handleInstall(true, item)}
                date={item.createdDateTimestamp ? timeConverter(item.createdDateTimestamp) : undefined}
                buttonText={i18n.t('general.install')}
                buttonText2={isPageBuilder() && getPageInfo('entityVariant') === 'Atom' ? 'Install to page' : undefined}
                plan={item.vedaProductRepresent?.planHandle}
                Right={
                  <View css={styles.draftItemButton}>
                    {role === 'dev' && (
                      <View
                        backgroundColor="tertiary"
                        borderColor="tertiary"
                        color="light"
                        fontFamily="secondary"
                        css={styles.adminButton}
                        onClick={handleFork(item.commandId ?? '')}
                      >
                        {i18n.t('general.fork')}
                      </View>
                    )}

                    {role === 'admin' && (
                      <View
                        backgroundColor="primary"
                        borderColor="primary"
                        color="light"
                        fontFamily="secondary"
                        css={styles.adminButton}
                        onClick={handleUpdate(item)}
                      >
                        {i18n.t('general.update')}
                      </View>
                    )}

                    {role === 'admin' && (
                      <View
                        backgroundColor="secondary"
                        borderColor="secondary"
                        color="light"
                        fontFamily="secondary"
                        css={styles.adminButton}
                        onClick={saveAddons(item, 'approve')}
                      >
                        {item.vedaProductRepresent ? i18n.t('general.published') : i18n.t('general.publish')}
                      </View>
                    )}

                    {role === 'admin' && (
                      <View
                        backgroundColor="warning"
                        borderColor="warning"
                        color="light"
                        fontFamily="secondary"
                        css={styles.adminButton}
                        onClick={saveAddons(item, 'reject')}
                      >
                        {i18n.t('general.hotfix')}
                      </View>
                    )}
                    {role === 'admin' && (
                      <Confirm
                        title={i18n.t('general.warning')}
                        message={
                          <View>
                            {i18n.t('general.delete_confirm_message', { text: item.label })}{' '}
                            {item.vedaProductRepresent ? (
                              <View tagName="span" css={{ fontStyle: 'italic', fontSize: '13px' }}>
                                ({i18n.t('builderPage.delete_atom', { text: i18n.t('general.addons') })})
                              </View>
                            ) : (
                              undefined
                            )}
                          </View>
                        }
                        isLoading={deleteStatus[item.commandId] === 'loading'}
                        onOk={onCancel => handleDeleteAddon(item, onCancel)}
                        okText={i18n.t('general.confirm')}
                      >
                        <View backgroundColor="danger" borderColor="danger" color="light" fontFamily="secondary" css={styles.adminButton}>
                          {i18n.t('general.delete')}
                        </View>
                      </Confirm>
                    )}
                  </View>
                }
              />
            );
          })}
        </Masonry>
        {TrackingLoadMore}
      </View>
    );
  };

  return (
    <View css={{ padding: '10px' }}>
      <GridSmart columnWidth={300} columnCount={4}>
        <Field label={`${i18n.t('adminDashboard.search', { text: i18n.t('general.by', { text: i18n.t('general.label') }) })}`}>
          <TextInputDebounce value={searchKey} block onValueChange={setSearchValue} />
        </Field>

        <Field label={`${i18n.t('adminDashboard.search', { text: i18n.t('general.by', { text: i18n.t('general.category') }) })}`}>
          <SelectAntd
            value={categoryId}
            showArrow={false}
            data={categories.map(category => ({ value: category.commandId, label: category.description }))}
            allowClear
            filterOption={(input, option) => {
              return ((option?.children as unknown) as string).toLowerCase().includes(input.toLowerCase());
            }}
            showSearch
            onChange={(_, option: any) => {
              changeAdminCategory({
                id: option?.value ?? '',
                slug: option?.label ?? '',
              });
            }}
            // loading={loadMoreCateStatus === 'loading'}
            // onPopupScroll={e => {
            //   const { scrollHeight, scrollTop, clientHeight } = e.target as Element;
            //   const lastCursor = categories.at(-1)?.commandId;
            //   if (scrollHeight - scrollTop - clientHeight < 300 && getCateStatus === 'success' && lastCursor && hasNextPageCate) {
            //     loadMoreAdminCategories.request({ cursor: lastCursor });
            //   }
            // }}
          />
        </Field>
      </GridSmart>

      <AsyncComponent Request={renderLoading} status={getAddonsStatus} Success={renderAddons()} />

      <ModalPublishAddon />

      <ModalHotFixAddon />
    </View>
  );
};
