import { getCacheValue, setCacheValue, getUniqId } from '../../../CacheShopifyFieldId';
import { ISCHEMA_SettingSpace } from '../@types/ISCHEMA_SettingSpace';
import { Positions } from '../@types/SettingSpace';

export const toShopifyFieldId = ({ field, parentField, section }: ISCHEMA_SettingSpace, position: Positions) => {
  const theoryShopifyFieldId = parentField ? `${parentField.name}___${field.name}__${position}` : `${field.name}__${position}`;
  if (theoryShopifyFieldId.length >= 25) {
    const valueInCache = getCacheValue({ theoryShopifyFieldId, section });
    if (valueInCache) {
      return valueInCache;
    } else {
      const newKey = `VEDA_space_${getUniqId('space')}`;
      setCacheValue({ theoryShopifyFieldId, section }, newKey);
      return newKey;
    }
  }
  return theoryShopifyFieldId;
};
