import { PLAN_HANDLE } from 'utils/constants/PlanEnum';

interface PLAN_DETAIL {
  title: string;
  description: string[];
}

export const data_plan_description: Record<PLAN_HANDLE, PLAN_DETAIL> = {
  free: {
    title: '',
    description: [
      '1 free template for each page type',
      'All Page Types',
      'Unlimited Layouts',
      'Global Style',
      'Responsive Design',
      'SEO Controls',
      'Email & Live Chat',
    ],
  },
  business: {
    title: 'EVERYTHING in free, plus:',
    description: [
      '15 additional live pages',
      'All business sections & business addons',
      'Color scheme',
      'Set up Heatmap & Visitor Session Recordings Unlimited',
    ],
  },
  advanced: {
    title: 'EVERYTHING in business, plus:',
    description: ['50 additional pages', 'Save & transfer content', 'Advanced addons & sections', 'Cart page PRO'],
  },
  ultimate: {
    title: 'EVERYTHING in advanced, plus:',
    description: ['Unlimited Publish', 'High Priority Support'],
  },
};
