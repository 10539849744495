import { sectionApiController } from 'services/SectionService';
import { userApis } from 'services/UserService/apis';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const createCategoryOfProduct = async ({
  description,
  name,
  priorityOrder,
  searchTerms,
  childrenCommandId,
}: {
  description: string;
  name: string;
  priorityOrder?: number;
  searchTerms?: string[];
  childrenCommandId?: string[];
}) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    const response = await sectionApiController.product.adminApi.category.createCategoryOfProduct({
      category: { description, name, priorityOrder, searchTerms, childrenCommandId },
    });
    await userApis.user.adminApi.updateVedaCacheVersion();
    return response;
  }
  throw new RoleException();
};
