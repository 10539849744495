import { PartnerApps } from 'containers/Admin/AppsPartner/components/Apps';
import { PartnerCategories } from 'containers/Admin/AppsPartner/components/Categories';
import { Dashboard } from 'containers/Dashboard';
import { FC } from 'react';
import { View } from 'wiloke-react-core';
import Box from 'components/FieldBox';

import * as styles from './styles';
import RightBar from 'containers/Admin/RightBar';

export const AppsPartner: FC = () => {
  return (
    <Dashboard
      hasSubmenu={false}
      disabledPaddingContent
      Content={() => (
        <View css={styles.container}>
          <View css={styles.menu}>
            <PartnerCategories />
          </View>
          <View css={styles.body}>
            <View css={{ display: 'flex' }}>
              <View className="dashboard-admin" columns={[8, 8, 8]}>
                <Box.WithTitle
                  borderWidth={0}
                  backgroundColor="transparent"
                  css={{
                    padding: '',
                    minHeight: '100%',
                    borderRadius: '6px',
                  }}
                  title="Recommended Apps"
                >
                  <PartnerApps />
                </Box.WithTitle>
              </View>
              <RightBar columns={[4, 4, 4]} />
            </View>
          </View>
        </View>
      )}
    />
  );
};
