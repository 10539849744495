import { createContext, FC, useContext } from 'react';

interface Translate {
  cancel: string;
  ok: string;
  skip: string;
  next: string;
}

interface UIState {
  translate: Translate;
}

interface UIProviderProps {
  translate?: Translate;
}

const defaultTranslate = {
  cancel: 'Cancel',
  ok: 'Ok',
  skip: 'Skip',
  next: 'Next',
};

const context = createContext<UIState>({
  translate: defaultTranslate,
});

export const useUI = () => {
  const contextValue = useContext(context);
  return contextValue;
};

const Provider = context.Provider;

export const UIProvider: FC<UIProviderProps> = ({ translate = defaultTranslate, ...rest }) => {
  return <Provider {...rest} value={{ translate }} />;
};
