import { Button } from '@wiloke/ui';
import { isYoutube } from 'components/VideoPlayer/checkUrlVideo';
import { FC, ReactNode } from 'react';
import { FontAwesome, Space, Text, View } from 'wiloke-react-core';

interface VideoCartProps {
  embedId: string;
  title?: string;
  description?: ReactNode;
  href?: string;
  disabledViewDetail?: boolean;
  enabledBorderButton?: boolean;
  buttonText?: string;
}

const handleUrlEmbed = (embedIdOrUrl: string) => {
  if (isYoutube(embedIdOrUrl)) {
    const id = embedIdOrUrl.replace(/(^.*(embed\/|(\?|&)v=))(\w*)(.*$)/g, '$4');
    return `https://www.youtube.com/embed/${id}?autoplay=1&loop=1&playlist=${id}&mute=1`;
  }
  return `https://www.youtube.com/embed/${embedIdOrUrl}?autoplay=1&loop=1&playlist=${embedIdOrUrl}&mute=1`;
};

export const VideoCart: FC<VideoCartProps> = ({
  embedId,
  title = 'Quick import Built-in themes by Veda Builder',
  description = 'Veda Builder - All-in-one Shopify Theme & Page Builder',
  href = '#',
  disabledViewDetail = false,
  enabledBorderButton = false,
  buttonText = 'View Details',
}) => {
  return (
    <View fontFamily="secondary">
      <View
        css={{
          position: 'relative',
          width: '100%',
          overflow: 'hidden',
          paddingTop: '56.25%',
        }}
        radius={6}
      >
        <iframe
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%',
            border: 'none',
          }}
          src={handleUrlEmbed(embedId)}
          width="100%"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
        />
      </View>

      {title !== '' && <Space size={20} />}

      {!!title && (
        <Text size={18} css={{ fontWeight: '500' }} fontFamily="secondary" color="gray9">
          {title}
        </Text>
      )}

      {title !== '' && <Space size={5} />}

      <Text size={13} fontFamily="secondary" color="gray7">
        {description}
      </Text>
      <Space size={15} />

      {!disabledViewDetail && (
        <Button
          href={href}
          target="blank"
          size="extra-small"
          color="gray9"
          colorHover="gray9"
          backgroundColor="light"
          radius={5}
          css={{ padding: '8px 20px', fontSize: '14px' }}
          {...(enabledBorderButton && { borderColor: 'gray3', borderStyle: 'solid', borderWidth: 1 })}
        >
          <FontAwesome type="fas" name="play" color="gray9" />
          <View tagName="span" css={{ marginLeft: '8px' }}>
            {buttonText}
          </View>
        </Button>
      )}
    </View>
  );
};
