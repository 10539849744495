import { Title } from '@wiloke/ui';
import { FC, ReactNode } from 'react';
import { View, ViewProps } from 'wiloke-react-core';
import { BoxProps } from '.';
import * as styles from './styles';

interface BoxWithTitleProps extends BoxProps {
  title?: string;
  description?: ReactNode;
  titleCss?: ViewProps['css'];
}

export const BoxWithTitle: FC<BoxWithTitleProps> = ({
  title = 'Title',
  description,
  borderColor = 'gray5',
  borderStyle = 'solid',
  borderWidth = 1,
  backgroundColor = 'light',
  radius = 5,
  style,
  children,
  css,
  titleCss,
  ...rest
}) => {
  const combineProps = { style, css: [styles.container, css], ...rest };
  return (
    <View
      {...combineProps}
      radius={radius}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      borderStyle={borderStyle}
      borderWidth={borderWidth}
    >
      <Title text={description} titleNumberOfLines={2} title={title} titleCss={[{ fontSize: '24px' }, titleCss]} css={[{ marginBottom: '20px' }]} />

      {children}
    </View>
  );
};
