import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import fetchAPI from 'utils/functions/fetchAPI';

interface Params {
  userId: number;
  chargeId: number | null;
  price: string | null;
  status: string | null;
  trialDays: number | null;
}

interface ResponseSuccess {
  message: string;
  info: {
    id: number;
    chargeId: number;
    planHandle: string;
    previousPlanHandle: string;
    trialDays: number;
    status: string;
    userId: number;
    shopifyPlanName: string;
  };
}

export const updatePlanInfo = async (params: Params) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${configureApp.endpoint.users}/global-support/${params.userId}`,
    method: 'PUT',
    data: params,
  });

  return response.data;
};
