import { Button, Tooltip } from '@wiloke/ui';
import SelectAntd from 'components/SelectAntd';
import { useGetThemesShopify } from 'containers/Admin/ThemeBuilder/ThemeDashboard/slice/actions';
import { themeDashboardSelector } from 'containers/Admin/ThemeBuilder/ThemeDashboard/slice/sliceThemeDashboard';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSetChangeThemeButNotSave } from 'store/global/auth';
import { useUpdateThemeIdOfUser } from 'store/global/auth/actions';
import { authSelector } from 'store/selectors';
import { i18n } from 'translation';
import { ActivityIndicator, FontAwesome, useStyleSheet, View } from 'wiloke-react-core';
import * as css from './styles';

export const DuplicateThemeBuilder = () => {
  const { getThemesShopifyStatus, shopifyThemes } = useSelector(themeDashboardSelector);
  const { themeVedaId, updateThemeIdOfUserStatus, changeThemeButNotSave } = useSelector(authSelector);

  const getThemesShopify = useGetThemesShopify();
  const updateThemeIdOfUser = useUpdateThemeIdOfUser();
  const setChangeThemeButNotSave = useSetChangeThemeButNotSave();

  const [themeId, setThemeId] = useState(themeVedaId);
  const { styles } = useStyleSheet();

  useEffect(() => {
    if (themeVedaId !== '') {
      setThemeId(themeVedaId);
    }
  }, [themeVedaId]);

  useEffect(() => {
    getThemesShopify.request(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (themeId !== themeVedaId) {
      setChangeThemeButNotSave(true);
    } else {
      setChangeThemeButNotSave(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeId, themeVedaId]);

  const handleSave = () => {
    updateThemeIdOfUser.request({ themeId });
  };

  return (
    <View className="DuplicateThemeBuilder" css={{ display: 'flex', maxWidth: '280px', width: '280px' }}>
      <SelectAntd
        style={{ textAlign: 'left' }}
        className={styles(css.selectTheme)}
        value={themeId}
        options={shopifyThemes.map(item => {
          return {
            label: `${item.name}`,
            value: item.id,
          };
        })}
        placeholder={i18n.t('adminDashboard.duplicate_theme.select')}
        onChange={val => {
          setThemeId(val);
        }}
      />
      <View
        css={[css.iconWrap, { borderRadius: '0px', height: '35px', width: '35px' }]}
        onClick={() => {
          getThemesShopify.request(undefined);
        }}
        style={!changeThemeButNotSave && themeId === themeVedaId ? { borderRadius: '0px 4px 4px 0px' } : undefined}
      >
        <Tooltip portal text={'Re-get shopify themes'} placement="bottom" css={css.itemFull}>
          {getThemesShopifyStatus === 'loading' ? <ActivityIndicator size={16} /> : <FontAwesome type="far" name="sync-alt" size={16} />}
        </Tooltip>
      </View>

      {changeThemeButNotSave && themeId !== themeVedaId && (
        <Button
          css={css.btnDuplicateTheme}
          loading={updateThemeIdOfUserStatus === 'loading'}
          size="extra-small"
          backgroundColor="light"
          color="primary"
          borderColor="gray3"
          borderWidth={1}
          borderStyle="solid"
          radius={6}
          onClick={handleSave}
          tooltip={{
            text: i18n.t('adminDashboard.duplicate_theme.description'),
            placement: 'bottom',
            css: { display: 'flex' },
          }}
        >
          <View tagName="span" css={css.btnText}>
            {i18n.t('schema.fieldLabel.switch')}
          </View>
        </Button>
      )}
    </View>
  );
};
