import { pushToCache, readFromCache } from './utils/CacheStorage';
import { getMegamenuCacheKey, getSectionCacheKey } from './utils/CacheKeyControl';
import { PageSection } from 'types/Sections';
import { getSectionsOfPageInThemeAtom } from 'services/ThemeService/Logic/getSectionsOfPageInThemeAtom';
import { BaseParams } from './@types/BaseParams';
import { getSectionsOfPageInThemeDraft } from 'services/ThemeService/Logic/getSectionsOfPageInThemeDraft';

export const getSections_ = async ({ commandIds, entityVariant }: GetSections) => {
  const getSectionsOfPageInThemeWithEntityVariant = entityVariant === 'Atom' ? getSectionsOfPageInThemeAtom : getSectionsOfPageInThemeDraft;
  const sections = await getSectionsOfPageInThemeWithEntityVariant({ sectionCommandIds: commandIds });
  const megamenuCommandIds = sections.reduce<string[]>((result, section) => {
    if (section.enable && section.megaMenuCommandIds) {
      return result.concat(section.megaMenuCommandIds);
    }
    return result;
  }, []);

  const sectionKeys = await sections.reduce<Promise<string[]>>(async (result, section) => {
    const result_ = await result;
    if (section.commandId && section.enable) {
      const cacheKey = await pushToCache(JSON.stringify(section), getMegamenuCacheKey(section.commandId));
      return result_.concat(cacheKey);
    }
    return result_;
  }, Promise.resolve([]));
  return {
    sectionKeys,
    megamenuCommandIds,
  };
};

interface GetSections extends BaseParams {
  commandIds: string[];
}

export const getSections = async ({ commandIds, isRetry, ...params }: GetSections) => {
  if (isRetry) {
    const megamenuCommandIds: string[] = [];
    const sectionKeys = await Promise.all(
      commandIds.map(async commandId => {
        const cacheKey = getSectionCacheKey(commandId);
        const dataFromCache = await readFromCache<PageSection>(cacheKey);
        if (dataFromCache) {
          if (dataFromCache.megaMenuCommandIds) {
            megamenuCommandIds.push(...dataFromCache.megaMenuCommandIds);
          }
          return cacheKey;
        } else {
          const { sectionKeys, megamenuCommandIds: megamenuCommandIds_ } = await getSections_({ commandIds: [commandId], isRetry, ...params });
          megamenuCommandIds.push(...megamenuCommandIds_);
          return sectionKeys[0];
        }
      }),
    );
    return { sectionKeys, megamenuCommandIds };
  } else {
    return getSections_({ commandIds, isRetry, ...params });
  }
};
