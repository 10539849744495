import { handleWaitForSocketOfSyncShopifyFulfill } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { call, retry, SagaReturnType } from 'redux-saga/effects';
import { handleSaveInBuilderPage, shopifyConnectionService } from 'services/ShopifyConnection';

export interface SyncDeletedAddonsInShopify {
  deleteAddonsParams: ReturnType<typeof handleSaveInBuilderPage>['deleteAddonsParams'];
}

export interface SyncDeletedAddonsInShopifyResult {
  statusSyncDeletedAddonsInShopify: SyncFulfillStatus;
  isNeedIgnoreReportError: boolean;
}

export function* syncDeletedAddonsInShopify({ deleteAddonsParams }: SyncDeletedAddonsInShopify) {
  yield retry(3, 1000, shopifyConnectionService.deleteAddonsInShopify, { ...deleteAddonsParams, isPreview: false });
  const socketDeleteAddon: SagaReturnType<typeof handleWaitForSocketOfSyncShopifyFulfill> = yield call(
    handleWaitForSocketOfSyncShopifyFulfill,
    'Delete addon khi save ở builder',
  );
  return {
    statusSyncDeletedAddonsInShopify: socketDeleteAddon.statusSync,
    isNeedIgnoreReportError: socketDeleteAddon.isNeedIgnoreReportError,
  } as SyncDeletedAddonsInShopifyResult;
}
