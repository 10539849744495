import { ArticlePageLiquidVariable, BlogPageLiquidVariable, CollectionPageLiquidVariable, ProductPageLiquidVariable } from 'types/Page';
import { createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';

interface MultipleProductSettings {
  type: 'changeSettingsShopifyPicker';
  payload: Partial<MultipleProductState>;
}

type MultipleProductActions = MultipleProductSettings;

export type ShopifyDataProductCollectionBlog = Exclude<CollectionPageLiquidVariable | ProductPageLiquidVariable | BlogPageLiquidVariable, undefined>;
type ShopifyDataArticle = Exclude<ArticlePageLiquidVariable, undefined>;

interface MultipleProductState {
  visibleProduct: boolean;
  slugsProduct: ShopifyDataProductCollectionBlog[];

  visibleCollection: boolean;
  slugsCollection: ShopifyDataProductCollectionBlog[];

  visibleArticle: boolean;
  slugArticle: ShopifyDataArticle[];

  visibleBlog: boolean;
  slugsBlog: ShopifyDataProductCollectionBlog[];
}

const sliceCreateShopifyPicker = createSlice<MultipleProductState, MultipleProductActions>({
  initialState: {
    visibleProduct: false,
    slugsProduct: [],

    visibleCollection: false,
    slugsCollection: [],

    visibleArticle: false,
    slugArticle: [],

    visibleBlog: false,
    slugsBlog: [],
  },
  name: '@MultipleShopifyPicker',
  reducers: [
    handleAction('changeSettingsShopifyPicker', ({ state, action }) => {
      return {
        ...state,
        ...action.payload,
      };
    }),
  ],
});

export const { changeSettingsShopifyPicker } = sliceCreateShopifyPicker.actions;

export const useSettingsShopifyPicker = createDispatchAction(changeSettingsShopifyPicker);
export { sliceCreateShopifyPicker };
