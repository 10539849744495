import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import fetchAPI from 'utils/functions/fetchAPI';

interface UpdatePageStatus_BEExpectParams {
  eventId: string;
  eventType: string;
}

interface UpdatePageStatus_BEResponse {
  message: string;
}

export const restoreThemeToDefault = async ({ eventId, eventType }: UpdatePageStatus_BEExpectParams) => {
  const res: AxiosResponse<UpdatePageStatus_BEResponse> = await fetchAPI.request({
    method: 'DELETE',
    url: `${configureApp.endpoint['shopify-connections']}/sync/shopify/me/restore-to-default`,
    params: {
      eventId,
      eventType,
    },
  });

  return res.data.message;
};
