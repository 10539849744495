import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AdminAddon } from 'types/Addons';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface ResponseSuccess {
  info: AdminAddon[];
  message: string;
}
interface GetAtoms {
  type: 'GET FIRST PAGE';
  cacheVersion: number | null;
  /** Lọc "Addon Atom" theo category */
  categoryId?: string;
  label?: string;
}
interface LoadmoreAtoms {
  type: 'LOADMORE';
  cacheVersion: number | null;
  /** Lọc "Addon Atom" theo category */
  categoryId?: string;
  /** Cursor để loadmore */
  lastCursor: string;
  label?: string;
}

/** API lấy về các template section đã được duyệt bởi "Admin" lên cho đội "Devs" sử dụng build template */
export const getAtoms = async ({ type, cacheVersion, label, categoryId, ...params }: GetAtoms | LoadmoreAtoms) => {
  let requestConfig: AxiosRequestConfig = {};
  if (type === 'GET FIRST PAGE') {
    requestConfig = {
      url: `${baseUrl}`,
      params: {
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
        size: 20,
        categoryId: categoryId ? categoryId : undefined,
        label: label ? label : undefined,
      },
    };
  } else {
    const { lastCursor } = params as LoadmoreAtoms;
    requestConfig = {
      url: `${baseUrl}/search`,
      params: {
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
        size: 20,
        categoryId: categoryId ? categoryId : undefined,
        label: label ? label : undefined,
        after: lastCursor,
      },
    };
  }

  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request(requestConfig);

  return response.data;
};
