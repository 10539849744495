export const GET_STARTED = 'GetStarted';
export const QUICK_CREATE = 'QuickCreate';
export const RIGHTBAR_ADMIN = 'RightBarAdmin';
export const LIVE_THEME = 'LiveTheme';
export const MIGRATION = 'Migration';
export const DRAFT_THEMES = 'DraftThemes';
export const JOIN_OUT_TEAM = 'JoinOutTeam';
export const THEME_SETTINGS_WITH_SIDEBAR = 'ThemeSettingsWithSidebar';
export const THEME_TEMPLATES_BUILT_IN_CONTENT = 'ThemeTemplatesBuiltInContent';
export const THEME_TEMPLATES_EVANTO_CONTENT = 'ThemeTemplatesEvantoContent';
export const TEMPLATES_PAGE_MAIN_CONTENT = 'TemplatesPageMainContent';
export const BLANK_PAGE_MAIN_CONTENT = 'BlankPageMainContent';
export const BUILDER_HOME_PAGE_MAIN_CONTENT = 'BuilderHomePageMainContent';
export const BUILDER_CART_PAGE_MAIN_CONTENT = 'BuilderCartPageMainContent';
export const BUILDER_SEARCH_PAGE_MAIN_CONTENT = 'BuilderSearchPageMainContent';
export const BUILDER_PASSWORD_PAGE_MAIN_CONTENT = 'BuilderPasswordPageMainContent';
export const BUILDER_NOTFOUND_PAGE_MAIN_CONTENT = 'BuilderNotFoundPageMainContent';
export const BUILDER_CUSTOMER_LOGIN_PAGE_MAIN_CONTENT = 'BuilderCustomerLoginMainContent';
export const BUILDER_CUSTOMER_REGISTER_PAGE_MAIN_CONTENT = 'BuilderCustomerRegisterMainContent';
export const BUILDER_CUSTOMER_ACCOUNT_PAGE_MAIN_CONTENT = 'BuilderCustomerAccountMainContent';
export const BUILDER_CUSTOMER_ORDER_PAGE_MAIN_CONTENT = 'BuilderCustomerOrderMainContent';
export const BUILDER_CUSTOMER_ADDRESSES_PAGE_MAIN_CONTENT = 'BuilderCustomerAddressMainContent';
export const BUILDER_GIFT_CARD_PAGE_MAIN_CONTENT = 'BuilderGiftCardMainContent';
export const BUILDER_COLLECTION_LISTING_PAGE_MAIN_CONTENT = 'BuilderCollectionListingMainContent';
export const BUILDER_CUSTOMER_RESET_PASSWORD_PAGE_MAIN_CONTENT = 'BuilderCustomerResetPasswordMainContent';
export const BUILDER_CUSTOMER_ACTIVATE_ACCOUNT_PAGE_MAIN_CONTENT = 'BuilderCustomerActivateAccountMainContent';
export const ARTICLE_PAGE_MAIN_CONTENT = 'ArticlePageMainContent';
export const BLOG_PAGE_MAIN_CONTENT = 'BlogPageMainContent';
export const COLLECTION_PAGE_MAIN_CONTENT = 'CollectionPageMainContent';
export const PRODUCT_PAGE_MAIN_CONTENT = 'ProductPageMainContent';
export const PRESET_STYLE_FIELD = 'PresetStyleField';
