import { useCountDown } from '@wiloke/hooks';
import { Button, MyModal } from '@wiloke/ui';
import Field from 'components/Field';
import { AdvancedBadge, BusinessBadge, UltimateBadge } from 'components/PlanBadge';
import Radio from 'components/Radio';
import TextInput from 'components/TextInput';
import { data_plan_description } from 'containers/Admin/PlanManagement/components/data_plan_description';
import { planSelector } from 'containers/Admin/PlanManagement/store/slicePlan';
import { transformPriceStringToNumber } from 'containers/Admin/PlanManagement/utils';
import useIsTabVisible from 'containers/ModalUpdatePlan/ModalUpdatePlan';
import withDebounce from 'hocs/withDebounce';
import parser from 'html-react-parser';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetUserPlan } from 'store/global/auth/actions';
import { authSelector } from 'store/selectors';
import { i18n } from 'translation';
import { pmLanding } from 'utils/functions/postMessage';
import { Divider, FontAwesome, Space, Text, View } from 'wiloke-react-core';
import { useGoToShopifyPayment, useValidateCoupon } from '../store/actions';
import { couponSelector, useSetPlanRequesting, useSetValidateCouponVisible, useSetValidatedCode } from '../store/sliceCoupon';
import * as styles from './styles';

const InputDebounce = withDebounce(TextInput, 'value', 'onValueChange', 300);

const ListItem: FC<{ content: string }> = ({ content }) => {
  return (
    <View css={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
      <View
        css={{
          width: '18px',
          height: '18px',
          minWidth: '18px',
          maxWidth: '18px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        radius="pill"
        backgroundColor="secondary"
      >
        <FontAwesome type="fas" size={10} color="light" name="check" />
      </View>
      <Text size={16} fontFamily="tertiary" color="gray9">
        {content}
      </Text>
    </View>
  );
};

const PricingTag: FC<{ originPrice: number; price: number }> = ({ originPrice, price }) => {
  if (originPrice === price) {
    return (
      <View tagName="span" css={{ fontSize: '21px', fontWeight: '600' }}>
        ${price}
      </View>
    );
  }

  return (
    <View tagName="span">
      <View tagName="strong" color="gray5" css={{ textDecoration: 'line-through', fontSize: '21px' }}>
        ${originPrice}
      </View>{' '}
      <View color="primary" tagName="span" css={{ fontSize: '21px' }}>
        ${price}
      </View>
    </View>
  );
};

const CouponForm: FC<{
  error: string;
  coupon: string;
  success?: string;
  isLoading: boolean;
  onSubmit: (text?: string) => void;
  onChange: (text: string) => void;
}> = ({ coupon, error, success, isLoading, onChange, onSubmit }) => {
  const [couponState, setCoupon] = useState(coupon);
  const { globalCoupon } = useSelector(authSelector);

  const { days, hours, minutes, seconds } = useCountDown({ endDate: globalCoupon ? Date.parse(globalCoupon.expiredOn) : 0 });

  useEffect(() => {
    if (coupon) {
      setCoupon(coupon);
    }
  }, [coupon]);

  const _handleClickStaticCode = (code: string) => () => {
    setCoupon(code);
    onSubmit(code);
  };

  const handleChangeCoupon = (val: string) => {
    setCoupon(val);
    onChange(val);
  };

  const renderTime = (time: number, type: 'd' | 'h' | 'm' | 's') => {
    return (
      <View css={styles.timeContainer}>
        <View css={styles.timeNumber}>{time}</View>
        <View css={styles.timeType}>{type}</View>
      </View>
    );
  };

  return (
    <Field
      css={styles.fieldStyle}
      error={error}
      label={
        <View css={{ fontSize: '14px' }} color="gray6">
          Promo code
        </View>
      }
      description={
        success ? (
          <Text
            size={14}
            color="success"
            css={{
              fontStyle: 'normal',
              marginTop: '4px',
            }}
          >
            {parser(success)}
          </Text>
        ) : globalCoupon ? (
          <Text size={14} css={{ marginTop: '8px' }} color="secondary">
            {parser(`${globalCoupon.globalDescription}`)}

            <View
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '4px',
              }}
            >
              <Text
                tagName="span"
                css={{ cursor: 'pointer', fontWeight: '600', textDecoration: 'underline' }}
                color="primary"
                onClick={_handleClickStaticCode(globalCoupon.code)}
              >
                {globalCoupon.code}
              </Text>

              {days >= 0 && (
                <View
                  css={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    gap: '1px',
                  }}
                >
                  <View color="dark">End in: </View>
                  {renderTime(days, 'd')}
                  {renderTime(hours, 'h')}
                  {renderTime(minutes, 'm')}
                  {renderTime(seconds, 's')}
                </View>
              )}
            </View>
          </Text>
        ) : null
      }
    >
      <View css={styles.couponFormStyle}>
        <InputDebounce
          sizeInput="small"
          value={couponState}
          onValueChange={handleChangeCoupon}
          placeholder={i18n.t('pricing.enter_promo')}
          borderColor={!!error ? 'danger' : 'gray3'}
          css={{ height: '38px', width: '250px', maxWidth: '250px' }}
          radius={4}
        />
        <Button
          backgroundColor="gray3"
          color="gray8"
          borderColor="gray3"
          borderWidth={1}
          borderStyle="solid"
          size="extra-small"
          radius={4}
          onClick={() => {
            onSubmit();
          }}
          loading={isLoading}
        >
          {i18n.t('pricing.apply')}
        </Button>
      </View>
    </Field>
  );
};

// Todo: i18n
export const ValidateCoupon: FC = () => {
  const { visibleValidate, validateStatus, planRequesting, validatedCoupon } = useSelector(couponSelector);
  const { getShopifyUrlStatus } = useSelector(planSelector);
  const { plan } = useSelector(authSelector);
  const setValidateVisible = useSetValidateCouponVisible();
  const validateCoupon = useValidateCoupon();
  const setPlanRequesting = useSetPlanRequesting();
  const gotoShopifyPayment = useGoToShopifyPayment();
  const setValidatedCode = useSetValidatedCode();

  const [coupon, setCoupon] = useState('');
  const [error, setError] = useState('');
  const [messageSuccess, setMessageSuccess] = useState('');

  const pricePerMonth = transformPriceStringToNumber(planRequesting.pricePerMonth);
  const pricePerYear = transformPriceStringToNumber(planRequesting.pricePerYear);
  const originalPricePerMonth = transformPriceStringToNumber(planRequesting.originalPricePerMonth);
  const originalPricePerYear = transformPriceStringToNumber(planRequesting.originalPricePerYear);
  const isChangedTab = useIsTabVisible();
  const getUserInfo = useGetUserPlan();
  const isNotInPricingPage = !window.location.pathname.includes('/pricing');

  useEffect(() => {
    if (validatedCoupon !== '') {
      setCoupon(validatedCoupon);
    }
  }, [validatedCoupon]);

  const handleCancel = () => {
    setValidateVisible(false);
    setError('');
    setPlanRequesting({
      handle: '',
      pricePerMonth: '',
      pricePerYear: '',
      title: '',
      type: 'monthly',
      originalPricePerMonth: '',
      originalPricePerYear: '',
    });
    setValidatedCode('');
    setCoupon('');
    setMessageSuccess('');
    pmLanding.emit('@landing/plan/failure');
  };

  useEffect(() => {
    if (isChangedTab && visibleValidate) {
      getUserInfo.request({
        prevPlan: plan.planHandle,
        onFulfill() {
          handleCancel();
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangedTab, plan.planHandle, visibleValidate]);

  const handleRedeemCoupon = (static_code?: string) => {
    if (!!static_code) {
      validateCoupon.request({
        couponCode: static_code,
        planHandle: plan.planHandle,
        pricePerMonth: planRequesting.originalPricePerMonth,
        pricePerYear: planRequesting.originalPricePerYear,
        onError(message) {
          setError(message);
        },
        onFulfill(message) {
          setMessageSuccess(message ?? '');
        },
      });

      return;
    }

    if (coupon === '') {
      setError(i18n.t('pricing.coupon_cannot_blank'));
    } else if (coupon === validatedCoupon && validateStatus === 'success') {
      setError(i18n.t('pricing.already_used_code'));
    } else {
      validateCoupon.request({
        couponCode: coupon,
        planHandle: plan.planHandle,
        pricePerMonth: planRequesting.originalPricePerMonth,
        pricePerYear: planRequesting.originalPricePerYear,
        onError(message) {
          setError(message);
        },
        onFulfill(message) {
          setMessageSuccess(message ?? '');
        },
      });
    }
  };

  const handleCheckout = () => {
    const url = isNotInPricingPage ? `${window.location.origin}/pricing?close=true` : `${window.location.origin}/pricing`;

    gotoShopifyPayment.request({
      coupon: validatedCoupon,
      planHandle: planRequesting.handle,
      returnUrl: url,
      yearly: planRequesting.type === 'yearly',
      onFulfill: url => {
        if (isNotInPricingPage) {
          window.open(url, '_blank');
        } else {
          window.location.replace(url);
        }
      },
    });
  };

  const handleChangeCoupon = (value: string) => {
    setError('');
    setMessageSuccess('');
    setCoupon(value);
  };

  const handleChangeBillingType = (val: string) => {
    const _val = val as 'monthly' | 'yearly';
    setPlanRequesting({
      type: _val,
    });
  };

  const renderDetailLink = isNotInPricingPage ? (
    <View
      fontFamily="secondary"
      tagName="a"
      target="_blank"
      color="primary"
      css={{ textDecoration: 'underline' }}
      href={`${window.location.origin}/pricing`}
    >
      Click for more details
    </View>
  ) : null;

  const renderContent: Record<string, ReactNode> = {
    free: (
      <View>
        {data_plan_description.free.title && (
          <Text size={21} fontFamily="tertiary" color="gray9" css={{ fontWeight: '600', marginBottom: '15px' }}>
            {data_plan_description.free.title}
          </Text>
        )}

        {data_plan_description.free.description.map(item => (
          <View key={item} css={{ marginBottom: '15px' }}>
            <ListItem content={item} />
          </View>
        ))}
      </View>
    ),
    business: (
      <View>
        <BusinessBadge css={{ borderRadius: '5px', padding: '8px 16px', fontSize: '14px', width: 'fit-content' }} />
        <Space size={20} />

        {data_plan_description.business.title && (
          <Text size={21} fontFamily="tertiary" color="gray9" css={{ fontWeight: '600', marginBottom: '15px' }}>
            {data_plan_description.business.title}
          </Text>
        )}

        {data_plan_description.business.description.map(item => (
          <View key={item} css={{ marginBottom: '15px' }}>
            <ListItem content={item} />
          </View>
        ))}

        {renderDetailLink}
      </View>
    ),
    advanced: (
      <View>
        <AdvancedBadge css={{ borderRadius: '5px', padding: '8px 16px', fontSize: '14px', width: 'fit-content' }} />
        <Space size={20} />

        {data_plan_description.advanced.title && (
          <Text size={21} fontFamily="tertiary" color="gray9" css={{ fontWeight: '600', marginBottom: '15px' }}>
            {data_plan_description.advanced.title}
          </Text>
        )}

        {data_plan_description.advanced.description.map(item => (
          <View key={item} css={{ marginBottom: '15px' }}>
            <ListItem content={item} />
          </View>
        ))}

        {renderDetailLink}
      </View>
    ),
    ultimate: (
      <View>
        <UltimateBadge css={{ borderRadius: '5px', padding: '8px 16px', fontSize: '14px', width: 'fit-content' }} />
        <Space size={20} />

        {data_plan_description.ultimate.title && (
          <Text size={21} fontFamily="tertiary" color="gray9" css={{ fontWeight: '600', marginBottom: '15px' }}>
            {data_plan_description.ultimate.title}
          </Text>
        )}

        {data_plan_description.ultimate.description.map(item => (
          <View key={item} css={{ marginBottom: '15px' }}>
            <ListItem content={item} />
          </View>
        ))}

        {renderDetailLink}
      </View>
    ),
  };

  return (
    <MyModal
      size="small"
      headerText={i18n.t('pricing.upgrade')}
      isVisible={visibleValidate}
      isLoading={getShopifyUrlStatus === 'loading'}
      bodyCss={styles.bodyModalStyles}
      onCancel={handleCancel}
      onOk={handleCheckout}
      contentCss={{ padding: '40px' }}
      cancelText=""
      okText=""
    >
      <View css={{ display: 'flex', justifyContent: 'space-between' }}>
        <View css={{ paddingRight: '4px' }}>{renderContent[planRequesting.handle.toLowerCase()]}</View>
        <View
          borderColor="gray2"
          borderStyle="solid"
          borderWidth={1}
          radius={6}
          css={{
            width: '500px',
            minWidth: '500px',
            maxWidth: '500px',
            boxShadow: 'rgba(1, 1, 1, 0.2) -2px 3px 6px',
          }}
        >
          <View css={{ padding: '18px 30px 0px 30px' }}>
            <Field label={<View color="gray6">{i18n.t('pricing.billing_cycle')}</View>} css={styles.fieldStyle}>
              <Radio.Group size="small" value={planRequesting.type} onChangeValue={handleChangeBillingType}>
                <Radio value={'monthly'} radioCss={{ marginRight: '20px', fontSize: '16px' }}>
                  {i18n.t('pricing.monthly')}
                </Radio>

                <Radio value={'yearly'} radioCss={{ fontSize: '16px' }}>
                  {i18n.t('pricing.annual')}{' '}
                  <View
                    fontFamily="tertiary"
                    tagName="span"
                    color="light"
                    backgroundColor="quaternary"
                    css={{
                      padding: '3px 11px',
                      fontWeight: '600',
                      fontSize: '13px',
                      lineHeight: 'normal',
                      letterSpacing: '-0.39px',
                    }}
                    radius={20}
                  >
                    {i18n.t('pricing.save_month', { year: '2' })}
                  </View>
                </Radio>
              </Radio.Group>
            </Field>

            <Divider size={1} />

            <Field label={<View color="gray6">{planRequesting.title}</View>} css={styles.fieldStyle}>
              <View css={styles.subtotalStyle}>
                <View>{planRequesting.type === 'monthly' ? `$${pricePerMonth} / 1 store / 1 month` : `$${pricePerYear} / 1 store / 1 year`}</View>
                <View>
                  {planRequesting.type === 'monthly' ? (
                    <PricingTag originPrice={Number(originalPricePerMonth)} price={Number(pricePerMonth)} />
                  ) : (
                    <PricingTag originPrice={Number(originalPricePerYear)} price={Number(pricePerYear)} />
                  )}
                </View>
              </View>
            </Field>

            <Divider size={1} />

            <Field css={styles.fieldStyle}>
              <View css={styles.subtotalStyle}>
                <View color="gray6" css={{ fontWeight: '500', fontSize: '14px' }}>
                  {i18n.t('pricing.subtotal')}
                </View>
                <View>{planRequesting.type === 'monthly' ? `$${pricePerMonth}` : `$${pricePerYear}`}</View>
              </View>
            </Field>

            <Divider size={1} />

            <CouponForm
              coupon={coupon}
              error={error}
              isLoading={validateStatus === 'loading'}
              onChange={handleChangeCoupon}
              onSubmit={handleRedeemCoupon}
              success={messageSuccess}
            />
          </View>
          <Divider size={1} />
          <View css={{ padding: '8px 30px', textAlign: 'right', display: 'flex', columnGap: '10px', justifyContent: 'flex-end' }}>
            <Button
              backgroundColor="light"
              borderColor="gray3"
              borderStyle="solid"
              borderWidth={1}
              color="gray8"
              size="extra-small"
              radius={4}
              fontFamily="secondary"
              css={{ fontWeight: 500 }}
              onClick={handleCancel}
            >
              {i18n.t('general.cancel')}
            </Button>
            <Button
              loading={getShopifyUrlStatus === 'loading'}
              onClick={handleCheckout}
              size="extra-small"
              radius={4}
              fontFamily="secondary"
              css={{ fontWeight: 500 }}
            >
              {i18n.t('pricing.checkout')}
            </Button>
          </View>
        </View>
      </View>
    </MyModal>
  );
};
