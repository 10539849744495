import { AxiosResponse } from 'axios';
import { BE_PageInThemeAtomNDraft } from 'services/ThemeService/Atom/types';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrlForAccessAtom } from '../const';

interface GetPagesOfAtom {
  commandIds: string;
  cacheVersion: number | null;
}

interface ResponseSuccess {
  message: string;
  info: BE_PageInThemeAtomNDraft[];
}

/** API được sử dụng để lấy về dữ liệu của page - được clone từ page atom - được sử dụng trong theme atom theo commandId */
export const getPagesOfAtom = async ({ commandIds, cacheVersion }: GetPagesOfAtom) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${baseUrlForAccessAtom}/search`,
    params: {
      cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
      commandIds,
    },
  });
  return response.data;
};
