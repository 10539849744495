import { setHeaderFooterIdsThemeActive, themeDashboardSelector } from 'containers/Admin/ThemeBuilder/ThemeDashboard/slice/sliceThemeDashboard';
import { saveHeaderFooterForUser } from 'containers/BuilderPage/store/saveForBuilder/actions';
import { handleSaveHeaderOrFooterOfThemeClient } from 'containers/BuilderPage/store/saveForBuilder/watchSaveTheme/ThemeClient/handleSaveThemeClient';
import { Task } from 'redux-saga';
import { SagaReturnType, all, call, cancel, fork, put, retry, select, take } from 'redux-saga/effects';
import { adapterGetAddons } from 'services/AddonService/Adapters/adapterGetAddons';
import { deleteClientAddons } from 'services/AddonService/Logic/Addons';
import { megaMenuService } from 'services/MegaMenuService';
import { sectionService } from 'services/SectionService';
import { createAddonOfThemeClient } from 'services/ThemeService/Logic/createAddonOfThemeClient';
import { createHeaderOrFooterOfThemeClient } from 'services/ThemeService/Logic/createHeaderOrFooterOfThemeClient';
import { updateAddonOfThemeClient } from 'services/ThemeService/Logic/updateAddonOfThemeClient';
import { updateHeaderOrFooterOfThemeClient } from 'services/ThemeService/Logic/updateHeaderOrFooterOfThemeClient';
import { updateThemeClient } from 'services/ThemeService/Logic/updateThemeClient';
import { removeDeletedSectionAddonMegaMenuCommandIds, updateFootersToPage, updateHeadersToPage } from 'store/actions/actionPages';
import { syncToShopify } from 'store/global/socket/syncShopifyNClonePageAtomServiceToThemeAtomService/actions';
import { syncPageNotification } from 'store/global/socket/syncShopifyNClonePageAtomServiceToThemeAtomService/watchSyncToShopify';
import { updateThemeAddonsToPage } from 'store/global/themeAddons/actions';
import { setThemeFooters, setThemeHeaders } from 'store/global/themeHeaderFooter/slice';
import { setIsSaveAddons, setOriginalThemeAddons } from 'store/reducers/sliceOriginThemeAddons';
import { pagesSelector, themeSettingsSelector } from 'store/selectors';
import { i18n } from 'translation';
import { AddonOfTheme_Atom_N_Client } from 'types/Addons';
import { ThemeTranslationsResponse } from 'types/Result';
import { SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client } from 'types/Sections';
import { getActionType } from 'wiloke-react-core/utils';

function* handleSaveHeaderAndFooter({
  payload: {
    footers,
    headers,
    isOverrideIndividualPages,
    outputBuilder,
    isIgnoreSyncShopify,
    disableVedaHeaderFooter,
    addons,
    onError,
    onFulfill,
    onSuccess,
  },
}: ReturnType<typeof saveHeaderFooterForUser.request>) {
  try {
    syncPageNotification.next({
      title: i18n.t('publish_shopify.sync_page_message.step', { text: '2' }),
      description: i18n.t('general.saving', { text: i18n.t('general.page') }),
    });

    const headerResponses: Array<Awaited<
      ReturnType<typeof createHeaderOrFooterOfThemeClient | typeof updateHeaderOrFooterOfThemeClient>
    >> = yield all(headers.map(header => call(handleSaveHeaderOrFooterOfThemeClient, header as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client)));
    const headerCommandIds = headerResponses.map(headerResponse => headerResponse.commandId);
    yield put(setThemeHeaders({ headers: headerResponses }));
    yield put(updateHeadersToPage({ headers: headerResponses }));

    /** Save footer */
    // TODO: Utils transform thay vì ép kiểu
    const footerResponses: Array<Awaited<
      ReturnType<typeof createHeaderOrFooterOfThemeClient | typeof updateHeaderOrFooterOfThemeClient>
    >> = yield all(footers.map(footer => call(handleSaveHeaderOrFooterOfThemeClient, footer as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client)));
    const footerCommandIds = footerResponses.map(footerResponse => footerResponse.commandId);
    yield put(setThemeFooters({ footers: footerResponses }));
    yield put(updateFootersToPage({ footers: footerResponses }));

    const { deletedMegaMenuCommandIds, deletedSectionCommandIds }: ReturnType<typeof pagesSelector> = yield select(pagesSelector);

    if (deletedSectionCommandIds.length > 0) {
      for (const deletedSectionCommandId of deletedSectionCommandIds) {
        yield retry(3, 1000, sectionService.sections.deleteClientSections, { commandId: deletedSectionCommandId });
        yield put(
          removeDeletedSectionAddonMegaMenuCommandIds({
            deletedSectionCommandId,
          }),
        );
      }
    }
    if (deletedMegaMenuCommandIds.length > 0) {
      for (const deletedMegaMenuCommandId of deletedMegaMenuCommandIds) {
        yield retry(3, 1000, megaMenuService.mega_menu.deleteClientMegaMenus, { commandId: deletedMegaMenuCommandId });
        yield put(
          removeDeletedSectionAddonMegaMenuCommandIds({
            deletedMegaMenuCommandId,
          }),
        );
      }
    }

    // Xử lý addon sau save page để thực hiện gán "belongToPages" cho addons
    const themeSettings: ReturnType<typeof themeSettingsSelector> = yield select(themeSettingsSelector);
    const { themeActivate }: ReturnType<typeof themeDashboardSelector> = yield select(themeDashboardSelector);

    /** Delete addons */
    const { deletedAddonIds }: ReturnType<typeof pagesSelector> = yield select(pagesSelector);
    if (deletedAddonIds.length > 0) {
      for (const deletedAddonId of deletedAddonIds) {
        yield retry(3, 1000, deleteClientAddons, { commandId: deletedAddonId.commandId });
        yield put(removeDeletedSectionAddonMegaMenuCommandIds({ deletedAddonCommandId: deletedAddonId.commandId }));
      }
    }
    /** Save addons */
    // TODO: Utils transform thay vì ép kiểu
    const addonResponses: Array<Awaited<ReturnType<typeof createAddonOfThemeClient | typeof updateAddonOfThemeClient>>> = yield all(
      addons.map(addon => {
        if (addon.commandId) {
          return retry(3, 1000, updateAddonOfThemeClient, {
            addon: { ...addon, category: undefined } as AddonOfTheme_Atom_N_Client,
          });
        }
        return retry(3, 1000, createAddonOfThemeClient, {
          addon: { ...addon, category: undefined } as AddonOfTheme_Atom_N_Client,
        });
      }),
    );
    const addonCommandIds = addonResponses.map(addonResponse => addonResponse.commandId);
    yield put(updateThemeAddonsToPage.success({ addons: adapterGetAddons(addonResponses) }));
    yield put(setIsSaveAddons(true));
    yield put(setOriginalThemeAddons(adapterGetAddons(addonResponses)));

    /** Update theme */
    const themeResponse: SagaReturnType<typeof updateThemeClient> = yield retry(3, 1000, updateThemeClient, {
      theme: {
        commandId: themeActivate.commandId,
        headerSectionCommandIds: headerCommandIds,
        footerSectionCommandIds: footerCommandIds,
        addonCommandIds: addonCommandIds,
        globalJs: themeSettings.globalJs,
        globalScss: themeSettings.globalScss,
        themeSettings: {
          ...themeSettings,
          colorSchemes: themeSettings.colorSchemes.colorSchemes,
          globalTranslations: themeSettings.globalTranslations as ThemeTranslationsResponse,
        },
        vendors: themeSettings.vendors,
      },
    });

    yield put(
      setHeaderFooterIdsThemeActive({
        footerIds: themeResponse.footerSectionCommandIds,
        headerIds: themeResponse.headerSectionCommandIds,
      }),
    );

    if (isIgnoreSyncShopify) {
      onSuccess?.();
      onFulfill?.();
    } else {
      yield put(
        syncToShopify.request({
          deletedAddonIds,
          entityVariant: 'Client',
          result: outputBuilder,
          isOverrideIndividualPages,
          isSaveAsLandingPage: false,
          onSyncFulfill: () => {
            onSuccess?.();
            onFulfill?.();
          },
          disableVedaHeaderFooter: disableVedaHeaderFooter,
        }),
      );
    }
  } catch (error) {
    onFulfill?.();
    onError?.();
    syncPageNotification.done();
  }
}

let savePageTask: Task | undefined;

export function* watchSaveHeaderAndFooterInBuilder() {
  while (true) {
    const requestAction: ReturnType<typeof saveHeaderFooterForUser.request> = yield take(getActionType(saveHeaderFooterForUser.request));
    if (!!savePageTask) {
      yield cancel(savePageTask);
    }
    savePageTask = yield fork(handleSaveHeaderAndFooter, requestAction);
  }
}

export function* watchCancelSaveHeaderAndFooterInBuilder() {
  while (true) {
    const cancelAction: ReturnType<typeof saveHeaderFooterForUser.cancel> = yield take(getActionType(saveHeaderFooterForUser.cancel));
    if (cancelAction.type === '@BuilderPage/saveHeaderFooterForUser/cancel' && !!savePageTask) {
      yield cancel(savePageTask);
    }
  }
}
