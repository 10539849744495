import { PageType } from 'types/Page';

export const ShopifyPageTypeMappingToLabel: Record<PageType, string> = {
  account: 'Account',
  activateAccount: 'Activate Account',
  addresses: 'Addresses',
  article: 'Article',
  blog: 'Blog',
  cart: 'Cart',
  collection: 'Collection',
  collections: 'Collections',
  giftCard: 'Gift Card',
  home: 'Home',
  login: 'Login',
  order: 'Order',
  page: 'Page',
  pageNotFound: '404',
  password: 'Password',
  product: 'Product',
  register: 'Register',
  resetPassword: 'Reset Password',
  search: 'Search',
};
