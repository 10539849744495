import { Button, Title } from '@wiloke/ui';
import { VedaInstructionBar } from 'components/SectionPageHeader/VedaInstructionBar';
import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { authSelector, instructionSelector } from 'store/selectors';
import { i18n } from 'translation';
import { PageType } from 'types/Page';
import { Image, View, ViewProps } from 'wiloke-react-core';
import * as styles from './styles';

export interface SectionPageHeaderProps {
  title: string;
  description: string;
  buttonContent?: string;
  cssContainer?: ViewProps['css'];
  Left?: ReactNode;
  disableButton?: boolean;
  isLoading?: boolean;
  idButton?: string;
  onClick?: () => void;
  pageType?: PageType;
}

const SectionPageHeader: FC<SectionPageHeaderProps> = ({
  title,
  description,
  buttonContent = i18n.t('builderPage.save_for_builder.create_new_page'),
  Left,
  cssContainer,
  disableButton = false,
  isLoading = false,
  idButton,
  pageType,
  onClick,
}) => {
  const { instructions } = useSelector(instructionSelector);
  const { globalCoupon } = useSelector(authSelector);

  const currentInstruction = instructions.find(item => item.pageType === pageType);
  const history = useHistory();

  const openModalUpgrade = () => {
    history.push('/pricing');
  };

  return (
    <View>
      <View css={[styles.container, cssContainer]}>
        <View columns={[12, 12, 8]} css={{ paddingLeft: 0 }}>
          <Title size="large" title={title} text={description} />
        </View>
        {Left || !disableButton ? (
          <View columns={[12, 12, 4]} css={{ textAlign: 'right', paddingRight: 0 }}>
            <Button id={idButton} loading={isLoading} radius={6} size="small" onClick={onClick}>
              {buttonContent}
            </Button>
          </View>
        ) : null}
      </View>

      {currentInstruction && (
        <View css={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
          {globalCoupon && (
            <Image
              onClick={openModalUpgrade}
              containerCss={{ cursor: 'pointer' }}
              imageCss={{ borderRadius: '16px' }}
              width={290}
              height={300}
              src={globalCoupon.globalDiscountBannerUrl}
            />
          )}
          <View css={{ flex: '1' }}>
            <VedaInstructionBar instruction={currentInstruction} />
          </View>
        </View>
      )}
    </View>
  );
};

export default SectionPageHeader;
