import { MyModal } from '@wiloke/ui';
import { message, notification } from 'antd';
import { handleExtractResultForForceSync } from '../../../ExtractThemeToFileForSync/utils/handleExtractResultForForceSync';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesome, Space, View } from 'wiloke-react-core';
import { DescriptionText } from './components/DescriptionText';
import { Preprocessing } from './components/Preprocessing';
import { Processing } from './components/Processing';
import { ShopifyPickers } from './components/ShopifyPickers';
import { UploadFile } from './components/UploadFile';
import { useSocketForSyncThemeByResultOfExtractThemeToFileForSync } from 'packages/SyncThemeByResultOfExtractThemeToFileForSync/hooks/useSocketForSyncThemeByResultOfExtractThemeToFileForSync';
import { selectSyncThemeByResultOfExtractThemeToFileForSync } from 'packages/SyncThemeByResultOfExtractThemeToFileForSync/selectors';
import {
  useInitSessionOfProcesses,
  useSetModalPreviewUrls,
  useSetModalSyncThemeByResultOfExtractThemeToFileForSync,
  useSyncThemeByResultOfExtractThemeToFileForSync,
} from '../../store/actions';
import { copyToClipboard } from 'utils/copyToClipboard';
import { ModalNotifySocketStucking } from '../ModalNotifySocketStucking';
import { ModalNotifySyncActuallyBug } from '../ModalNotifySyncActuallyBug/ModalNotifySyncActuallyBug';
import { ModalNotifyAFileCanDownload } from '../ModalNotifyAFileCanDownload';
import fileDownload from 'utils/functions/fileDownload';
import { getDefaultPagesTurnOffSync } from 'packages/SyncThemeByResultOfExtractThemeToFileForSync/utils/getDefaultPagesTurnOffSync';

export const ModalSyncThemeByResultOfExtractThemeToFileForSync = () => {
  // Upload file
  const [error, setError] = useState('');
  const [fileNameState, setFileNameState] = useState('');
  const [fileContentState, setFileContentState] = useState<ReturnType<typeof handleExtractResultForForceSync> | undefined>(undefined);

  // Page đang thao tác chỉnh sửa "shopifyPages"
  const [pageParamsInteracting, setPageParamsInteracting] = useState<
    ReturnType<typeof handleExtractResultForForceSync>['pagesParams'][number] | undefined
  >(undefined);

  // Tiền xử lý dữ liệu
  const [openPreprocessing, setOpenPreprocessing] = useState(false);
  const [openModalConfirmRemovePreprocessing, setOpenModalConfirmRemovePreprocessing] = useState(false);
  const [formPreprocessingValues, setFormPreprocessingValues] = useState<
    Record<string, ReturnType<typeof handleExtractResultForForceSync>['pagesParams'][number] | undefined>
  >(getDefaultPagesTurnOffSync(fileContentState));

  // Modal confirm cancel processing
  const [openModalConfirmCancelProcess, setOpenModalConfirmCancelProcess] = useState(false);
  const [openModalFinalConfirmCancelProcess, setOpenModalFinalConfirmCancelProcess] = useState(false);

  // Modal confirm đóng mặc dù có processing có thể retry
  const [openModalConfirmCloseWhenHadAProcessCanRetry, setOpenModalConfirmCloseWhenHadAProcessCanRetry] = useState(false);
  const [openModalFinalConfirmCloseWhenHadAProcessCanRetry, setOpenModalFinalConfirmCloseWhenHadAProcessCanRetry] = useState(false);

  // Modal thông báo có file mới có thể tải xuống
  const [openModalNotifyAFileCanDownload, setOpenModalNotifyAFileCanDownload] = useState<ReturnType<typeof handleExtractResultForForceSync> | null>(
    null,
  );

  const { connect, disconnect, statusSocketConnection } = useSocketForSyncThemeByResultOfExtractThemeToFileForSync();

  const { socket, session, modalSyncThemeByResultOfExtractThemeToFileForSync } = useSelector(selectSyncThemeByResultOfExtractThemeToFileForSync);

  const setModalSyncThemeByResultOfExtractThemeToFileForSync = useSetModalSyncThemeByResultOfExtractThemeToFileForSync();
  const sync = useSyncThemeByResultOfExtractThemeToFileForSync();
  const initSessionOfProcesses = useInitSessionOfProcesses();
  const setModalPreviewUrls = useSetModalPreviewUrls();

  const { statusSyncToShopify } = socket;
  const { expectProcesses, actualProcessed, status, isRetryAction: isRetryAction_ } = session;

  const isLoading = useMemo(() => {
    return statusSocketConnection === 'loading' || statusSyncToShopify === 'loading' || status === 'loading';
  }, [statusSocketConnection, statusSyncToShopify, status]);

  const isRetryAction = useMemo(() => {
    return isRetryAction_ || status === 'failure';
  }, [isRetryAction_, status]);

  const handleStartSync = () => {
    if (modalSyncThemeByResultOfExtractThemeToFileForSync && fileContentState) {
      const finalData: Exclude<typeof fileContentState, undefined> = {
        ...fileContentState,
        pagesParams: fileContentState.pagesParams.map(({ pageCommandId, ...pageParams }) => {
          if (pageCommandId && formPreprocessingValues[pageCommandId]) {
            return { pageCommandId, ...pageParams, ...formPreprocessingValues[pageCommandId] };
          }
          return { pageCommandId, ...pageParams };
        }),
      };
      connect({
        onSuccess() {
          sync.request({
            isRetryAction,
            data: finalData,
            onSyncFulfill() {
              disconnect({});
            },
            onSuccess: ({ previewUrl, fullPreviewUrls }) => {
              setModalSyncThemeByResultOfExtractThemeToFileForSync(undefined);
              setModalPreviewUrls(fullPreviewUrls.length ? fullPreviewUrls : undefined);
              initSessionOfProcesses();
              if (previewUrl) {
                window.open(previewUrl);
              }
            },
          });
          setOpenModalConfirmRemovePreprocessing(false);
          setOpenPreprocessing(false);
          if (openPreprocessing && !isRetryAction) {
            setOpenModalNotifyAFileCanDownload(finalData);
          }
          setFormPreprocessingValues(getDefaultPagesTurnOffSync(fileContentState));
        },
        onError() {
          notification.error({
            message: 'Lỗi kết nối mạng',
            description: (
              <div>
                <p>Lỗi có thể xảy ra do mạng nhưng hãy cứ báo bug bằng cách gửi file vừa upload vào nhóm kèm lời nhắn "Khởi tạo socket lỗi"</p>
                <button
                  style={{
                    borderRadius: '4px',
                    backgroundColor: '#2C36DC',
                    color: '#fff',
                    fontWeight: 500,
                    padding: '10px 16px',
                    cursor: 'pointer',
                    outline: 'none',
                    border: 'none',
                  }}
                  onClick={() => {
                    copyToClipboard(`"Khởi tạo socket lỗi"`);
                    message.success('Đã copy');
                  }}
                >
                  Copy lời nhắn
                </button>
              </div>
            ),
            duration: null,
          });
        },
      });
    }
  };

  const renderModalFinalConfirmCancelProcess = () => {
    return (
      <MyModal
        isVisible={openModalFinalConfirmCancelProcess}
        headerText="Huỷ bỏ quá trình đồng bộ"
        okText="Chính xác"
        cancelText="Không"
        onOk={() => {
          setModalSyncThemeByResultOfExtractThemeToFileForSync(undefined);
          setOpenModalFinalConfirmCancelProcess(false);
          notification.info({
            message: 'Quá trình đồng bộ đang được huỷ bỏ',
          });
          disconnect({
            cb() {
              sync.cancel(undefined);
              notification.success({
                message: 'Quá trình đồng bộ đã được huỷ bỏ thành công',
              });
            },
          });
        }}
        onCancel={() => {
          setOpenModalFinalConfirmCancelProcess(false);
          notification.success({
            message: 'Rất vui vì nó không phải là thật',
          });
        }}
      >
        <View css={{ fontSize: '18px', fontWeight: 500, marginBottom: '16px' }}>
          <FontAwesome type="far" name="meh" css={{ marginRight: '8px' }} />
          Thật luôn?
        </View>
        <View css={{ marginBottom: '16px' }}>
          Xin phép được nhắc lại{' '}
          <View css={{ fontWeight: 500, textDecoration: 'underline' }} color="danger" tagName="span">
            lần cuối
          </View>
          :{' '}
          <View tagName="span" color="danger">
            Hãy xem xét cẩn thận vì một số tiến trình đồng bộ trước đó đã hoàn thành, việc "HUỶ BỎ" có thể dẫn đến một số lỗi đáng tiếc
          </View>
        </View>
        <View color="danger" css={{ fontSize: '18px', fontWeight: 500, textAlign: 'center' }}>
          Bạn thực sự muốn huỷ bỏ quá trình đồng bộ?
        </View>
      </MyModal>
    );
  };

  const renderModalConfirmCancelProcess = () => {
    return (
      <MyModal
        isVisible={openModalConfirmCancelProcess}
        headerText="Huỷ bỏ quá trình đồng bộ"
        okText="Chính xác"
        cancelText="Không"
        onCancel={() => setOpenModalConfirmCancelProcess(false)}
        onOk={() => {
          setOpenModalFinalConfirmCancelProcess(true);
          setOpenModalConfirmCancelProcess(false);
        }}
      >
        <View tagName="h4">Giả định các hành động của bạn</View>
        <View tagName="ol">
          <View tagName="li">
            Nếu bạn đang muốn tắt modal để thực hiện các hành động khác thì rất tiếc, điều đó không thể. Quá trình đồng bộ tốn rất nhiều tài nguyên do
            đó vui lòng đợi quá trình kết thúc trước khi tiếp tục làm gì đó
          </View>
          <View tagName="li">Nếu đơn giản chỉ là do bạn click nhầm thì OK không sao</View>
          <View tagName="li">
            Nếu bạn đang muốn{' '}
            <View tagName="span" color="primary" css={{ fontWeight: 500, fontSize: '16px' }}>
              "HUỶ BỎ"
            </View>{' '}
            quá trình đồng bộ thì hãy xem xét cẩn thận vì một số tiến trình đồng bộ trước đó đã hoàn thành, việc "HUỶ BỎ" có thể dẫn đến một số lỗi
            đáng tiếc
          </View>
        </View>
        <View color="danger" css={{ fontSize: '18px', fontWeight: 500, textAlign: 'center' }}>
          Bạn thực sự muốn huỷ bỏ quá trình đồng bộ?
        </View>
      </MyModal>
    );
  };

  const renderModalFinalConfirmCloseWhenHadAProcessCanRetry = () => {
    return (
      <MyModal
        isVisible={openModalFinalConfirmCloseWhenHadAProcessCanRetry}
        headerText="Cảnh báo"
        okText="Chính xác"
        cancelText="Không"
        onOk={() => {
          setModalSyncThemeByResultOfExtractThemeToFileForSync(undefined);
          setOpenModalFinalConfirmCloseWhenHadAProcessCanRetry(false);
        }}
        onCancel={() => {
          setOpenModalFinalConfirmCloseWhenHadAProcessCanRetry(false);
          notification.success({
            message: 'Rất vui vì điều đó',
          });
        }}
      >
        <View css={{ fontSize: '18px', fontWeight: 500, marginBottom: '16px' }}>
          <FontAwesome type="far" name="meh" css={{ marginRight: '8px' }} />
          Thật luôn?
        </View>
        <View css={{ marginBottom: '16px' }}>
          Xin phép được nhắc lại{' '}
          <View css={{ fontWeight: 500, textDecoration: 'underline' }} color="danger" tagName="span">
            lần cuối
          </View>
          :{' '}
          <View css={{ fontSize: '16px' }}>
            Tìm thấy một quá trình đồng bộ bị lỗi trước đó. Hành động tắt modal đi đồng nghĩa với việc{' '}
            <View tagName="span" color="danger" css={{ textDecoration: 'underline', fontWeight: 500, fontSize: '16px' }}>
              không thể "Thử lại các tiến trình bị lỗi"
            </View>{' '}
            lại quá trình đồng bị lỗi trước đó
          </View>
          <View color="danger" css={{ fontWeight: 500, textAlign: 'center', fontSize: '16px', marginTop: '12px' }}>
            Bạn vẫn muốn tắt modal này đi?
          </View>
        </View>
      </MyModal>
    );
  };

  const renderModalConfirmCloseWhenHadAProcessCanRetry = () => {
    return (
      <MyModal
        isVisible={openModalConfirmCloseWhenHadAProcessCanRetry}
        headerText="Cảnh báo"
        okText="Chính xác"
        cancelText="Không"
        onCancel={() => setOpenModalConfirmCloseWhenHadAProcessCanRetry(false)}
        onOk={() => {
          setOpenModalFinalConfirmCloseWhenHadAProcessCanRetry(true);
          setOpenModalConfirmCloseWhenHadAProcessCanRetry(false);
        }}
      >
        <View>
          Tìm thấy một quá trình đồng bộ bị lỗi trước đó. Hành động tắt modal đi đồng nghĩa với việc{' '}
          <View tagName="span" color="danger" css={{ textDecoration: 'underline', fontWeight: 500, fontSize: '16px' }}>
            không thể "Thử lại các tiến trình bị lỗi"
          </View>{' '}
          lại quá trình đồng bị lỗi trước đó
        </View>
        <View color="danger" css={{ fontWeight: 500, textAlign: 'center', fontSize: '16px', marginTop: '12px' }}>
          Bạn vẫn muốn tắt modal này đi?
        </View>
      </MyModal>
    );
  };

  useEffect(() => {
    setFileContentState(undefined);
    setFormPreprocessingValues(getDefaultPagesTurnOffSync(undefined));

    setFileNameState('');
    setError('');

    setPageParamsInteracting(undefined);

    setOpenPreprocessing(false);
    setOpenModalConfirmRemovePreprocessing(false);

    setOpenModalConfirmCancelProcess(false);
    setOpenModalFinalConfirmCancelProcess(false);

    setOpenModalConfirmCloseWhenHadAProcessCanRetry(false);
    setOpenModalFinalConfirmCloseWhenHadAProcessCanRetry(false);

    setOpenModalNotifyAFileCanDownload(null);
    initSessionOfProcesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalSyncThemeByResultOfExtractThemeToFileForSync]);

  if (!modalSyncThemeByResultOfExtractThemeToFileForSync) {
    return null;
  }

  return (
    <>
      <MyModal
        size="medium"
        isVisible
        headerText="Đồng bộ toàn bộ theme lên shopify"
        depsHeightRecalculation={[fileContentState, error, expectProcesses]}
        isLoading={isLoading}
        okText={isRetryAction ? 'Thử lại các tiến trình bị lỗi' : 'Bắt đầu đồng bộ'}
        cancelText={isLoading ? 'Huỷ bỏ quá trình đồng bộ' : 'Đóng'}
        onOk={handleStartSync}
        onCancel={() => {
          if (isLoading) {
            setOpenModalConfirmCancelProcess(true);
          } else if (status === 'failure' && ModalNotifySyncActuallyBug.getCounter() < ModalNotifySyncActuallyBug.COUNTER_SHOULD_NOTIFY) {
            setOpenModalConfirmCloseWhenHadAProcessCanRetry(true);
          } else {
            setModalSyncThemeByResultOfExtractThemeToFileForSync(undefined);
          }
        }}
        FooterLeft={
          <Preprocessing
            fileContentState={fileContentState}
            isLoading={isLoading}
            isRetryAction={isRetryAction}
            formPreprocessingValues={formPreprocessingValues}
            setFormPreprocessingValues={setFormPreprocessingValues}
            openPreprocessing={openPreprocessing}
            setOpenPreprocessing={setOpenPreprocessing}
            openModalConfirmRemovePreprocessing={openModalConfirmRemovePreprocessing}
            setOpenModalConfirmRemovePreprocessing={setOpenModalConfirmRemovePreprocessing}
            setPageParamsInteracting={setPageParamsInteracting}
            onOk={handleStartSync}
          />
        }
      >
        <View css={{ paddingRight: '4px' }}>
          <DescriptionText modalSyncThemeByResultOfExtractThemeToFileForSync={modalSyncThemeByResultOfExtractThemeToFileForSync} />
          <Space type="vertical" size={12} />
          <UploadFile
            error={error}
            setError={setError}
            fileContentState={fileContentState}
            setFileContentState={value => {
              initSessionOfProcesses();
              setFormPreprocessingValues(getDefaultPagesTurnOffSync(value as any));
              return setFileContentState(value);
            }}
            fileNameState={fileNameState}
            setFileNameState={setFileNameState}
            status={status}
          />
          <Space type="vertical" size={12} />
          <Processing
            expectProcesses={expectProcesses}
            actualProcessed={actualProcessed}
            status={status}
            isConnectingSocket={statusSocketConnection === 'loading'}
          />
        </View>
      </MyModal>
      <ShopifyPickers
        pageParamsInteracting={pageParamsInteracting}
        setFormPreprocessingValues={setFormPreprocessingValues}
        setPageParamsInteracting={setPageParamsInteracting}
      />
      {renderModalConfirmCancelProcess()}
      {renderModalFinalConfirmCancelProcess()}
      {renderModalConfirmCloseWhenHadAProcessCanRetry()}
      {renderModalFinalConfirmCloseWhenHadAProcessCanRetry()}
      <ModalNotifyAFileCanDownload
        isVisible={openModalNotifyAFileCanDownload !== null}
        onOk={() => {
          fileDownload({
            data: JSON.stringify(openModalNotifyAFileCanDownload),
            name: `[Tiền xử lý]${fileNameState.replace('.json', '')}`,
            type: 'application/json',
          });
          setOpenModalNotifyAFileCanDownload(null);
        }}
        onCancel={() => {
          setOpenModalNotifyAFileCanDownload(null);
        }}
      />
      <ModalNotifySocketStucking />
      <ModalNotifySyncActuallyBug />
    </>
  );
};

ModalSyncThemeByResultOfExtractThemeToFileForSync.useSetModalSyncThemeByResultOfExtractThemeToFileForSync = useSetModalSyncThemeByResultOfExtractThemeToFileForSync;
