import { AppSettings } from 'types/AppSettings';
import fetchAPI from 'utils/functions/fetchAPI';
import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { getApiTracking } from 'utils/functions/getApiTracking';

const data: AppSettings = {
  commandId: '',
  language: 'en',
  editCodeEnabled: false,
  rtl: false,
};

export class AppSettingsService {
  async getAppSettings(): Promise<AppSettings> {
    interface ResponseSuccess {
      info?: {
        commandId: string;
        jsHook: boolean;
        language: string;
        editCodeEnabled: boolean;
        rtl: boolean;
      };
      message: string;
    }
    const { role } = getUserInfo();
    const _role = role === 'admin' || role === 'dev' ? 'admin' : 'me';

    const baseUrl = getApiTracking({ defaultBaseUrl: `${configureApp.endpoint.clients}/${_role}/app-settings`, trackingEndpoint: 'app-settings' });

    const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
      url: baseUrl,
    });
    return response.data.info
      ? {
          ...response.data.info,
          editCodeEnabled: response.data.info.editCodeEnabled,
          rtl: response.data.info.rtl ?? data.rtl,
          commandId: response.data.info.commandId,
          language: response.data.info.language,
        }
      : data;
  }

  async postAppSettings(settings: AppSettings) {
    interface ResponseSuccess {
      info: {
        commandId: string;
        jsHook: boolean;
        language: string;
        editCodeEnabled: boolean;
        rtl: boolean;
      };
      message: string;
    }
    const { role } = getUserInfo();
    const _role = role === 'admin' || role === 'dev' ? 'admin' : 'me';
    const baseUrl = `${configureApp.endpoint.clients}/${_role}/app-settings`;

    const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
      url: baseUrl,
      method: 'PUT',
      data: {
        commandId: settings.commandId === '' ? undefined : settings.commandId,
        language: settings.language,
        rtl: settings.rtl,
        editCodeEnabled: settings.editCodeEnabled,
      },
    });

    const _response = {
      info: {
        editCodeEnabled: response.data.info.editCodeEnabled,
        commandId: response.data.info.commandId,
        language: response.data.info.language,
        rtl: response.data.info.rtl ?? settings.rtl,
      } as AppSettings,
      message: response.data.message,
    };

    return _response;
  }
}
