/**
 * It takes a string, checks if it matches a certain pattern, and returns a substring of the original
 * string if it does
 * @param {string} shopName - The name of the shop.
 * @returns The shop name without the .myshopify.com.
 * Exp: `"veda.myshopify.com" => "veda"`
 */

export const composeShopName = (shopName: string): string => {
  const regex = /^([^.]+)\.myshopify\.com$/;
  const match = shopName.match(regex);
  if (match) {
    return match[1];
  }
  return '';
};
