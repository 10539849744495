import { FC, ReactNode } from 'react';
import { ColorNames, View } from 'wiloke-react-core';
import * as styles from '../styles';

interface ButtonActionProps {
  onClick: () => void;
  children: ReactNode;
  color: ColorNames;
  backgroundColor: ColorNames;
}

export const ButtonAction: FC<ButtonActionProps> = ({ children, backgroundColor, color, onClick }) => {
  return (
    <View color={color} fontFamily="secondary" backgroundColor={backgroundColor} css={styles.rightItem} onClick={onClick}>
      {children}
    </View>
  );
};
