import { customLog } from '@wiloke/functions';
import { isEmpty } from 'ramda';
import { MouseEvent } from 'react';
import { SettingBlockObject } from 'types/Schema';
import { PageSection } from 'types/Sections';
import { CopyData, CopyResult } from 'utils/functions/copyStyle/types';
import { relatedFieldNames } from 'utils/functions/copyStyle/utils/checkFieldName';

function getSectionSettings(section: PageSection): CopyResult {
  const sectionSettings = section.data.settings.find(set => set.name === 'section_settings') as SettingBlockObject | undefined;

  if (sectionSettings && Array.isArray(sectionSettings.children)) {
    const result: CopyData[] = [];
    for (const field of sectionSettings.children) {
      result.push({
        name: field.name,
        children: field.children,
        childId: field.id,
        settingId: sectionSettings.id,
        grandChildId: undefined,
      });
    }

    return {
      type: 'section_settings',
      copiedData: result,
    };
  }
  return {
    type: 'section_settings',
    copiedData: [],
  };
}

function getTextSettings(section: PageSection, target: HTMLElement): CopyResult | undefined {
  const _targetName = target.getAttribute('component');
  const setting = section.data.settings.find(setting => setting.name === _targetName);

  if (!setting || !_targetName || setting.type === 'object' || setting.type === 'array') {
    return undefined;
  }

  const designOfSelectedField = section.data.settings.filter(field => field.type === 'styleBox' || relatedFieldNames.includes(field.name));

  if (isEmpty(designOfSelectedField)) {
    return {
      type: setting.type,
      copiedData: [],
    };
  }

  const result: CopyData[] = [];
  for (const field of designOfSelectedField) {
    result.push({
      children: field.children,
      settingId: field.id,
      childId: undefined,
      grandChildId: undefined,
      name: field.name,
    });
  }

  return {
    type: setting.type,
    copiedData: result,
  };
}

// Những field nằm ở phần Settings chứ không phải trong block
// thì cùng phải dùng component="{{field_name}}" để tìm setting
// Đang được gọi ở SectionComponent.tsx
export function getComponentStyleInNormalSetting(
  section: PageSection,
  event: MouseEvent<HTMLElement, globalThis.MouseEvent>,
): CopyResult | undefined {
  try {
    const _target = event.target as HTMLElement;

    if (_target.tagName === 'SECTION') {
      return getSectionSettings(section);
    }

    return getTextSettings(section, _target);
  } catch (error) {
    const _error = error as Error;
    customLog('getComponentStyleInNormalSetting', _error.message);
    console.log(error);

    return {
      type: 'unknown',
      copiedData: [],
    };
  }
}
