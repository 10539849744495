import Field from 'components/Field';
import { Hotspot, Tooltip, Button } from '@wiloke/ui';
import TextInput from 'components/TextInput';
import VirtualList from 'components/VirtualList/VirtualList';
import { cloneDeep, set } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { i18n } from 'translation';
import { Consts } from 'utils/constants/constants';
import { delay, objectEntries } from '@wiloke/functions';
import { snakeToText } from 'utils/functions/snakeToText';
import { Space, View, ViewProps } from 'wiloke-react-core';
import { flatternObject } from './flatternObject';
import * as styles from './styles';

export type Translation = Record<string, any>;

export interface ShopifyTranslationProps {
  containerCss?: ViewProps['css'];
  lang: string;
  value: Translation;
  onChange?: (translation: Translation) => void;
  /** Event dùng ở ngoài dashboard */
  onSave?: () => void;
}

interface Method {
  getValue(): Record<string, Translation>;
  getValue(lang?: string): Translation;
}

let globalTranslations: Record<string, Translation> = {};

export const ShopifyTranslation: FC<ShopifyTranslationProps> & Method = ({ containerCss, lang, value: translation, onChange, onSave }) => {
  const data = objectEntries(flatternObject(translation));
  const [fakeLoading, setFakeLoading] = useState(false);

  useEffect(() => {
    return () => {
      globalTranslations = {};
    };
  }, []);

  useEffect(() => {
    globalTranslations = {
      ...globalTranslations,
      [lang]: translation,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <View css={[styles.container, containerCss]}>
      {!!onSave && (
        <Tooltip
          placement="left"
          portal
          text="Please save translation before saving settings"
          css={styles.button}
          onClick={async () => {
            setFakeLoading(true);
            await delay(300);
            onSave?.();
            setFakeLoading(false);
          }}
        >
          <Hotspot css={{ position: 'absolute', top: 0, left: 0 }} />
          <Button loading={fakeLoading} backgroundColor="gray8" size="extra-small" radius={4}>
            {i18n.t('general.save', { text: i18n.t('publish_shopify.translation') })}
          </Button>
        </Tooltip>
      )}

      <VirtualList
        disabledScrollStyle={false}
        rowCount={data.length}
        rowHeight={82}
        rowRender={index => {
          const dataSlice = data.slice(index, index + 1);
          const rowItem = dataSlice.length ? dataSlice : [];

          return (
            <>
              <Space size={20} />
              {rowItem.map(([key, val]) => {
                return (
                  <Field
                    key={`${lang}-${key}`}
                    label={key
                      .split('.')
                      .map(snakeToText)
                      .join(' → ')}
                    note={`Usage: {{ "${Consts.AppName}.${key}" | t }}`}
                    notePopoverWidth={500}
                    css={{ height: '82px', marginBottom: '0 !important', paddingLeft: '20px', paddingRight: '15px' }}
                  >
                    <TextInput
                      block
                      defaultValue={val}
                      onValueChange={value => {
                        onChange?.(set(cloneDeep(translation), key, value));
                        globalTranslations = {
                          ...globalTranslations,
                          [lang]: set(cloneDeep(translation), key, value),
                        };
                      }}
                    />
                  </Field>
                );
              })}
              <Space size={20} />
            </>
          );
        }}
      />
    </View>
  );
};

ShopifyTranslation.getValue = (lang?: string) => {
  if (lang === undefined) {
    return globalTranslations;
  }
  return globalTranslations[lang];
};
