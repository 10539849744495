import { defaultLanguage, languages } from 'services/ShopifyConnection/utils/VedaOutputToShopifyThemeEditor/@consts';
import { Locales } from 'services/ShopifyConnection/utils/VedaOutputToShopifyThemeEditor/@types/Veda/ShopifyLocales';
import { v4 } from 'uuid';
import { Section } from '../../../@types/Section';
import { getCacheValue, setCacheValue, getUniqId } from '../../../CacheShopifyFieldId';
import { SettingSelect } from '../../../SchemaSettings/SettingSelect/@types/SettingSelect';
import { SettingBlockArray } from '../@types/SettingBlockArray';

const localKeyGenByVedaOptionCategorizeComponent = 'AAAA';
const createLocaleKey = () => `_${localKeyGenByVedaOptionCategorizeComponent}_${v4()}_`;

interface RTOptionCategorizeComponentToShopifyBlockType {
  name: string;
  type: string;
  locales: Locales;
}

interface OptionCategorizeComponentToShopifyBlockType {
  section: Section;
  array: SettingBlockArray;
  option: SettingSelect['options'][number];
}

export const optionCategorizeComponentToShopifyBlockType = ({
  array,
  option,
  section,
}: OptionCategorizeComponentToShopifyBlockType): RTOptionCategorizeComponentToShopifyBlockType => {
  const arrayLabel = array.label;
  const localeKey = createLocaleKey();
  const default_translations = { [localeKey]: '' };
  // const newLabel: RTVedaLabelToShopifyFieldLabel['newLabel'] = `t:${localeKey}`;
  const locales: RTOptionCategorizeComponentToShopifyBlockType['locales'] = languages.reduce<
    RTOptionCategorizeComponentToShopifyBlockType['locales']
  >(
    (res, language) => {
      const arrayLabel_: string = typeof arrayLabel === 'string' ? arrayLabel : arrayLabel[language] ?? arrayLabel[defaultLanguage];
      return {
        ...res,
        [language]: {
          [localeKey]: `${arrayLabel_} > ${option.label}`.length >= 25 ? option.label : `${arrayLabel_} > ${option.label}`,
        },
      };
    },
    { en: default_translations, vi: default_translations, fr: default_translations },
  );

  const theoryBlockType = `${array.name}_${option.value}`;
  if (theoryBlockType.length >= 25) {
    const valueInCache = getCacheValue({ theoryShopifyFieldId: theoryBlockType, section });
    if (valueInCache) {
      return {
        locales,
        name: locales[defaultLanguage][localeKey],
        type: valueInCache,
      };
    } else {
      const newKey = `VEDA_array_${getUniqId('array')}`;
      setCacheValue({ theoryShopifyFieldId: theoryBlockType, section }, newKey);
      return {
        locales,
        name: locales[defaultLanguage][localeKey],
        type: newKey,
      };
    }
  } else {
    return {
      locales,
      name: locales[defaultLanguage][localeKey],
      type: `${array.name}_${option.value}`,
    };
  }
};
