import { AxiosError } from 'axios';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { authService } from 'services/AuthService';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { verifyUserStorefrontPassword } from '../actions';

export function* handleVerifyStorefrontPassword({
  payload: { onFailed, onFulfilled, password, id, showNotification },
}: ReturnType<typeof verifyUserStorefrontPassword.request>) {
  try {
    const response: SagaReturnType<typeof authService.updateUserInfo> = yield retry(3, 1000, authService.updateUserInfo, {
      id,
      storePassword: password,
    });
    yield put(verifyUserStorefrontPassword.success({ verifySucceeded: true, password: response.info.storePassword ?? password }));
    onFulfilled?.();
    if (showNotification) {
      notifyAxiosHandler.handleSuccess(response.message);
    }
  } catch (error) {
    yield put(verifyUserStorefrontPassword.failure({ verifySucceeded: false }));
    onFailed?.((error as AxiosError<ErrorData>).response?.data.message ?? (error as Error).message);
  }
}

export function* watchVerifyStorefrontPassword() {
  yield takeLatest(getActionType(verifyUserStorefrontPassword.request), handleVerifyStorefrontPassword);
}
