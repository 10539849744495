export const ModalReportAfterError = {
  error_cause: {
    connect_socket: 'Connexion instable',
    sync_result_to_shopify: 'Erreur lors de la synchronisation avec Shopify',
    preview_result_in_shopify: "Erreur lors de l'aperçu dans Shopify",
    migrate_theme: 'Erreur lors de la migration du thème',
    clone_page_atom_service_to_theme_atom_service:
      'Erreur lors du clonage des composants du service de page atomique vers le service de thème atomique',
    initialization_session_builder: "Quelque chose s'est mal passé",
    save_result: "Erreur lors de l'enregistrement",
    save_page_setting_in_dashboard: "Erreur lors de l'enregistrement des paramètres de page dans le tableau de bord",
    save_theme_setting_in_dashboard: "Erreur lors de l'enregistrement des paramètres de thème dans le tableau de bord",
    delete_page: 'Erreur lors de la suppression de la page',
    update_status_page: "Erreur lors de la mise à jour de l'état de la page",
    active_theme_veda: "Erreur lors de l'activation du thème Veda",
    lost_connection: 'Connexion perdue',
  },
  error_description: {
    socket_event_id: 'Connexion instable',
  },
  title: "Rapport d'erreur",
  description: "Le retour sera envoyé à 'Veda'",
  comments: 'Commentaires',
  severity: {
    title: 'Gravité',
    options: {
      low: 'Faible',
      normal: 'Normal',
      high: 'Élevée',
      urgent: 'Urgente',
    },
  },
  app_status: "État de l'application",
  app_message: 'Message du système',
  send: 'Envoyer',
  initializing: "Connexion en cours avec l'équipe de support",
};
