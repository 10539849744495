import { AxiosResponse } from 'axios';
import { ProductAddon } from 'types/Addons';
import { ToRequiredKeys } from 'utils';
import fetchAPI from 'utils/functions/fetchAPI';
import { getApiTracking } from 'utils/functions/getApiTracking';
import { baseUrl } from '../const';

interface GetClients {
  commandIds: string[];
  cacheVersion: number | null;
}
interface ResponseSuccess {
  info: Array<ToRequiredKeys<ProductAddon, 'commandId'>>;
  message: string;
}

export const getClients = async ({ commandIds, cacheVersion }: GetClients) => {
  const url = getApiTracking({ defaultBaseUrl: `${baseUrl}/search`, trackingEndpoint: `addons/search` });
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url,
    params: {
      commandIds: commandIds.join(','),
      cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
    },
  });

  return response.data.info;
};
