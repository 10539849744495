import TextInput from 'components/TextInput';
import withDebounce from 'hocs/withDebounce';
import { useUpdateSettingsValue } from 'store/actions/actionPages';
import { authSelector, sectionIdActiveSelector } from 'store/selectors';
import { pmParent } from 'utils/functions/postMessage';
import { useSelector } from 'react-redux';
import { FieldContainerProps } from '../types';
import { DISABLE_NAMES_FOR_PLAN } from 'containers/BuilderPage/screens/FieldSettings/Fields/TextEditorField';
import { validatePlan } from 'utils/functions/validatePlan';
import { PLAN_STATIC_DATA, VEDA_FUNCTION_ENUM } from 'utils/constants/PlanEnum';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';

export interface TextInputFieldProps<T> extends FieldContainerProps<T> {
  name: string;
}

const TextInputDebounce = withDebounce(TextInput, 'value', 'onValueChange');

const TextInputField = <T extends string>({ value, settingId, childId, grandChildId, forceRenderSection, name }: TextInputFieldProps<T>) => {
  const updateSettingsValue = useUpdateSettingsValue();
  const sectionIdActive = useSelector(sectionIdActiveSelector);
  const { plan, role } = useSelector(authSelector);
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();

  const isDisable =
    DISABLE_NAMES_FOR_PLAN.includes(name) && validatePlan({ userPlan: plan.planHandle, itemPlan: VEDA_FUNCTION_ENUM.copyright, role }) === 'invalid';

  function handleChange(value: string) {
    updateSettingsValue({
      value,
      settingId,
      childId,
      grandChildId,
    });
    if (forceRenderSection) {
      pmParent.emit('@rerender', { sectionId: sectionIdActive, action: 'TextInputField.tsx' });
    }
  }

  return (
    <TextInputDebounce
      multiline
      value={value}
      disabled={isDisable}
      onClick={() => {
        if (isDisable) {
          setPlanRequesting({
            handle: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.copyright].handle,
            type: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.copyright].type,
            pricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.copyright].pricePerMonth,
            pricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.copyright].pricePerYear,
            title: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.copyright].title,
            originalPricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.copyright].pricePerMonth,
            originalPricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.copyright].pricePerYear,
          });
          setValidateCouponVisible(true);
        }
      }}
      block
      sizeInput="medium"
      onValueChange={handleChange}
      forceRenderSection={forceRenderSection}
    />
  );
};

export default TextInputField;
