import { put, retry, SagaReturnType, takeLatest, all } from 'redux-saga/effects';
import { checkThemeProductIsInstalled, getThemeProductsService, loadMoreThemeProductsService } from 'services/ThemeService/Logic/getThemeProducts';
import { ProductTheme } from 'types/Theme';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { actionGetThemeTemplates, loadMoreThemeTemplates } from '../actions';
import { at } from 'utils/at';

// TODO: Tách user thành 1 app riêng và nội bộ là 1 app riêng
function* handleGet({ payload: { group, size, categoryId } }: ReturnType<typeof actionGetThemeTemplates.request>) {
  try {
    const response: SagaReturnType<typeof getThemeProductsService> = yield retry(3, 1000, getThemeProductsService, { group, size, categoryId });

    const additionOfProductTheme: SagaReturnType<typeof checkThemeProductIsInstalled>[] = yield all(
      response.info.map(item => retry(3, 1000, checkThemeProductIsInstalled, { parentCommandId: item.parentCommandId })),
    );

    const _themeProductResponse = response.info.reduce<any[]>((res, theme, index) => {
      const additionData = at(additionOfProductTheme, index);
      if (additionData?.info) {
        return res.concat({ ...theme, isInstalled: true, name: theme.label });
      }
      return res.concat({ ...theme, isInstalled: false, name: theme.label });
    }, []) as ProductTheme[];

    yield put(
      actionGetThemeTemplates.success({
        data: _themeProductResponse,
        hasNextPage: response.info.length === size,
      }),
    );
  } catch (error) {
    const error_ = error as Error;
    notifyAxiosHandler.handleError(error_);
    yield put(actionGetThemeTemplates.failure(undefined));
  }
}

function* handleLoadMore({ payload: { cursor, group, size, categoryId } }: ReturnType<typeof loadMoreThemeTemplates.request>) {
  try {
    const response: SagaReturnType<typeof loadMoreThemeProductsService> = yield retry(3, 1000, loadMoreThemeProductsService, {
      cursor,
      group,
      size,
      categoryId,
    });

    const additionOfProductTheme: Awaited<ReturnType<typeof checkThemeProductIsInstalled>>[] = yield all(
      response.info.map(item => retry(3, 1000, checkThemeProductIsInstalled, { parentCommandId: item.parentCommandId })),
    );

    const _themeProductResponse = response.info.reduce<any[]>((res, theme, index) => {
      const additionData = at(additionOfProductTheme, index);
      if (additionData?.info) {
        return res.concat({ ...theme, isInstalled: true, name: theme.label });
      }
      return res.concat({ ...theme, isInstalled: false, name: theme.label });
    }, []) as ProductTheme[];

    yield put(
      loadMoreThemeTemplates.success({
        data: _themeProductResponse,
        hasNextPage: response.info.length === size,
      }),
    );
  } catch (error) {
    const error_ = error as Error;
    notifyAxiosHandler.handleError(error_);
    yield put(loadMoreThemeTemplates.failure(undefined));
  }
}

export function* watchGetThemeTemplates() {
  yield takeLatest(getActionType(actionGetThemeTemplates.request), handleGet);
}

export function* watchLoadMoreThemeTemplates() {
  yield takeLatest(getActionType(loadMoreThemeTemplates.request), handleLoadMore);
}
