import App from 'App';
import { render } from 'react-dom';
import { useDispatch } from 'react-redux';
import { methodAtOfArrayPolyfill } from 'utils/methodAtOffArrayPolyfill';
import { requestIdleCallbackPolyfill } from 'utils/requestIdleCallbackPolyfill';
import { getUseDispatchRedux } from 'wiloke-react-core/utils';
import reportWebVitals from './reportWebVitals';
import './wdyr';
import pkg from '../package.json';

methodAtOfArrayPolyfill();
requestIdleCallbackPolyfill();
getUseDispatchRedux(useDispatch);

const rootElement = document.getElementById('veda-root') as HTMLElement;

render(<App />, rootElement);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

console.log(`%c VEDA ${pkg.version}`, 'background: #222; color: #bada55');
