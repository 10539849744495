import { mergeDeepLeft } from 'ramda';
import { UrlField } from '../../../@types/Shopify/InputSettings/UrlField';
import { Locales } from '../../../@types/Veda/ShopifyLocales';
import { replaceExactVariableNameInLiquidCode } from '../../../@utils/replaceExactVariableNameInLiquidCode';
import { vedaLabelToShopifyFieldLabel } from './utils/vedaLabelToShopifyFieldLabel';
import { vedaSummaryToShopifyFieldInfo } from './utils/vedaSummaryToShopifyFieldInfo';
import { ISCHEMA_SettingLinkPicker } from './@types/ISCHEMA_SettingLinkPicker';
import { ISETTING_SettingLinkPicker } from './@types/ISETTING_SettingLinkPicker';
import { toShopifyFieldId } from './utils/toShopifyFieldId';
import { ILIQUID_SettingLinkPicker } from './@types/ILIQUID_SettingLinkPicker';
import { ignoreToReduceTotalLinesOfCode } from '../../../@utils/ignoreToReduceTotalLinesOfCode';
import { getSignalReduceTotalLinesOfCode } from './utils/getSignalReduceTotalLinesOfCode';
import { getLinkType } from 'components/LinkPicker/utils/getLinkType';

interface RTSettingLinkPicker {
  shopifyField: UrlField;
  locales: Locales;
}

export const SCHEMA_SettingLinkPicker = ({ field, parentField, ...params }: ISCHEMA_SettingLinkPicker): RTSettingLinkPicker => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField });
  const { newLabel, locales: labelLocales } = vedaLabelToShopifyFieldLabel({ ...params, field, parentField, isNeedReduceTotalLinesOfCode });
  const { newInfo, locales: infoLocales } = vedaSummaryToShopifyFieldInfo({ ...params, field, parentField, isNeedReduceTotalLinesOfCode });
  return {
    shopifyField: {
      label: newLabel,
      info: newInfo,
      id: isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field)
        : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }),
      type: 'url',
      default: undefined,
    },
    locales: mergeDeepLeft(labelLocales, infoLocales),
  };
};

const getSlug = (value: string) => {
  const linkType = getLinkType(value);
  // NOTE: Tại thời điểm code ra cái này không có article picker
  if (value.startsWith('/collections') || value.startsWith('/products') || value.startsWith('/pages') || value.startsWith('/blogs')) {
    return `shopify:/${value}`;
  }
  if (linkType === 'email' || linkType === 'phone') {
    return value;
  }
  if (!value.startsWith('http://') && !value.startsWith('https://')) {
    return value.startsWith('/') ? value : `/${value}`;
  }
  return value;
};

export const SETTING_SettingLinkPicker = ({ field, parentField, ...params }: ISETTING_SettingLinkPicker) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField });
  const value = typeof field.children === 'string' && !!field.children ? field.children : undefined;
  if (value !== undefined) {
    const value_ = getSlug(value);
    const fieldName = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode });
    return { [fieldName]: value_ };
  }
  return {};
};

export const LIQUID_SettingLinkPicker = ({ field, parentField, liquid, loopVariable, ...params }: ILIQUID_SettingLinkPicker) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField });
  // Nếu field thuộc array
  // - loopVariable nếu là array ->  Array được dùng trong forloop -> Thế `${loopVariable}.${field.name}.${key}` = `${loopVariable}.${toShopifyFieldId({ field, parentField }, key)}`
  //   Example: --> for item in content --> item.icon_design.id ==> KQ: item.content__icon_design.id
  if (loopVariable) {
    const target = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : `${loopVariable}.${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode })}`;
    const source = `${loopVariable}.${field.name}`;
    return replaceExactVariableNameInLiquidCode({ liquid, source, target });
  }
  // Nếu field thuộc object
  // - parentField nếu là object -> Object được flat thành các "shopify input settings" -> Thế `${parentField.name}.${field.name}.${key}` = toShopifyFieldId
  //   Example: --> heading.icon_design.id ==> KQ: heading__icon_design.id
  else if (parentField) {
    const target = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode })}"]`;
    const source = `${parentField.name}.${field.name}`;
    return replaceExactVariableNameInLiquidCode({ liquid, source, target });
  } else {
    const target = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode })}"]`;
    const source = field.name;
    return replaceExactVariableNameInLiquidCode({ liquid, source, target });
  }
};
