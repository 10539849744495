import { watchCheckPurchaseCodeIsExist } from './watchCheckPurchaseCodeIsExist';
import { watchCheckThemeIsInstall } from './watchCheckThemeIsInstall';
import { watchDeleteProductTheme } from './watchDeleteProductTheme';
import { watchCancelGetCurrentTheme, watchGetCurrentTheme } from './watchGetCurrentTheme/watchGetCurrentTheme';
import { watchGetThemeTemplates, watchLoadMoreThemeTemplates } from './watchGetThemeTemplates';
import { watchGetThemeZipUrl } from './watchGetThemeZipUrl';
import { watchImportThemeAtomToClientService } from './watchImportThemeAtomToClientService';
import { watchValidatePurchaseCode } from './watchValidatePurchaseCode';
import { watchValidateBuiltinTheme } from './watchValidateBuiltinTheme';

export const sagasThemeTemplates = [
  watchGetThemeTemplates,
  watchGetCurrentTheme,
  watchImportThemeAtomToClientService,
  watchDeleteProductTheme,
  watchLoadMoreThemeTemplates,
  watchValidatePurchaseCode,
  watchCancelGetCurrentTheme,
  watchGetThemeZipUrl,
  watchCheckThemeIsInstall,
  watchCheckPurchaseCodeIsExist,
  watchValidateBuiltinTheme,
];
