import { AxiosError } from 'axios';
import { getThemeVedaActive } from 'containers/Admin/ThemeBuilder/ThemeDashboard/slice/actions';

import { all, call, put, retry, select, takeLatest } from 'redux-saga/effects';
import { Role } from 'routes/types';
import { appSettingsService } from 'services/AppSettingsService';
import { appSetupService } from 'services/AppSetupService';
import { shopifyBlogService } from 'services/ShopifyServices/Blog';
import { collectionService } from 'services/ShopifyServices/Collection';
import { productService } from 'services/ShopifyServices/ProductService';
import { getThemeVedaActivate } from 'services/ThemeService/Logic/getThemeVedaActivate';
import { setLayoutSettings } from 'store/actions/actionLayoutSettings';
import { getDefaultPickerFieldRelateShopify } from 'store/actions/liquid/actionDefaultPickerFieldRelateShopify';
import { getThemeActive } from 'store/actions/shopify/actionShopifyTheme';
import { setThemeVendors } from 'store/global/themeVendors/slice';
import { setOriginThemeSettings } from 'store/reducers/sliceOriginThemeSettings';
import { authSelector } from 'store/selectors';
import { AppSettings } from 'types/AppSettings';
import { customLog } from '@wiloke/functions';
import { ErrorData } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { getAppSettings } from '../appSettings/actions';
import { updateCssVariables } from '../cssVariables/slice';
// import { handleGetLiquidSnippets } from '../globalSnippets/sagas/watchGetLiquidSnippets';
import { setGlobalThemeJs } from '../globalThemeJs/slice';
import { setGlobalThemeScss } from '../globalThemeScss/slice';
import { setFileLanguageActive, setGlobalThemeTranslation } from '../globalTranslation/slice';
import { handleGetPageCounter } from '../pageCounter/sagas/watchGetPageCounter';
import { getGeneralThemeSettings } from '../statusGeneralSettings/actions';
import { setThemeGeneralSettings } from '../themeSettings/slice';
import { globalMount } from './action';
import { forceUserMigratesTheme } from './flows/forceUserMigratesTheme';
import { getGlobalThemeAddons } from './flows/getGlobalThemeAddons';
import { setDefaultPickerFieldRelateShopify } from './flows/setDefaultPickerFieldRelateShopify';
import { setVisibleModalUnauthorized } from 'containers/ModalUnauthorized/sliceUnauthorized';
import { setColorSchemes } from '../colorSchemes/slice';
import { setThemeVersion } from '../themeVersion/slice';
import { getPageInstructionVideo } from 'services/UserService/Logic/getPageInstructionVideo';
import { setVedaInstructionAction } from 'store/global/vedaInstruction/action';
import { getGlobalDiscountRequest } from 'services/UserService/Logic/getGlobalDiscount';
import { setGlobalCoupon } from 'store/global/auth';

interface ResponseSuccess {
  appSettings: AppSettings;
  productResponse: Awaited<ReturnType<typeof productService.getProducts>>;
  collectionResponse: Awaited<ReturnType<typeof collectionService.getCollections>>;
  blogResponse: Awaited<ReturnType<typeof shopifyBlogService.getBlogStatic>>;
  setupResponse: Awaited<ReturnType<typeof appSetupService>>;
  themeVedaActivate: Awaited<ReturnType<typeof getThemeVedaActivate>>;
  vedaInstruction: Awaited<ReturnType<typeof getPageInstructionVideo>>;
  globalCoupon: Awaited<ReturnType<typeof getGlobalDiscountRequest>>;
}

export function* handleGetGlobalData(_: ReturnType<typeof globalMount.request>) {
  try {
    // get theme settings
    yield put(getGeneralThemeSettings.request(undefined));
    yield put(getAppSettings.request(undefined));

    // get theme active
    yield put(getThemeActive.request(undefined));
    yield put(getThemeVedaActive.request(undefined));

    yield put(getDefaultPickerFieldRelateShopify.request(undefined));

    const authState: ReturnType<typeof authSelector> = yield select(authSelector);
    const {
      appSettings,
      blogResponse,
      collectionResponse,
      productResponse,
      themeVedaActivate,
      vedaInstruction,
      globalCoupon,
    }: ResponseSuccess = yield all({
      appSettings: retry(3, 1000, appSettingsService.getAppSettings),
      productResponse: retry(3, 1000, productService.getProducts, ''),
      collectionResponse: retry(3, 1000, collectionService.getCollections, ''),
      blogResponse: retry(3, 1000, shopifyBlogService.getBlogStatic),
      setupResponse: retry(3, 1000, appSetupService, authState.role as Role),
      themeVedaActivate: retry(3, 1000, getThemeVedaActivate),
      // liquidSnippets: handleGetLiquidSnippets({ type: '@Global/getLiquidSnippets/request', payload: {} }),
      pageCounters: handleGetPageCounter({ type: '@Global/getPageCounter/request', payload: undefined }),
      vedaInstruction: retry(3, 1000, getPageInstructionVideo),
      globalCoupon: retry(3, 1000, getGlobalDiscountRequest),
    });

    yield put(setGlobalCoupon(globalCoupon.info));
    yield put(setVedaInstructionAction({ vedaInstruction: vedaInstruction.data }));

    // theme
    yield put(getGeneralThemeSettings.success(undefined));

    const { themeSettings, vendors, globalJs, globalScss, addonCommandIds } = themeVedaActivate;
    if (themeSettings !== undefined && vendors !== undefined && globalJs !== undefined && globalScss !== undefined) {
      const { cssVariables, generalSettings, layoutSettings, globalTranslations, colorSchemes } = themeSettings;
      const { colors, fonts } = cssVariables;

      yield put(setLayoutSettings(layoutSettings));
      yield put(updateCssVariables({ colors, fonts }));
      yield put(setColorSchemes({ colorSchemes }));
      yield put(setThemeVersion({ version: themeVedaActivate.version }));
      yield put(setThemeGeneralSettings(generalSettings));
      yield put(setGlobalThemeScss(globalScss));
      yield put(setGlobalThemeJs(globalJs));
      yield put(setThemeVendors({ vendors: vendors }));
      yield put(setGlobalThemeTranslation(globalTranslations.translation));

      yield put(
        setOriginThemeSettings({
          generalSettings,
          globalJs,
          globalScss,
          globalTranslations,
          layoutSettings,
          vendors,
          cssVariables: {
            colors,
            fonts,
          },
          colorSchemes,
        }),
      );

      yield put(
        setFileLanguageActive(globalTranslations.languageActive ? globalTranslations.languageActive : Object.keys(globalTranslations.translation)[0]),
      );
    }

    // @tuong -> Theme chỉ được cài ở Client nên lấy addon sẽ lấy luôn ở client service
    /** Xử lý lấy addon */
    yield call(getGlobalThemeAddons, { addonCommandIds });

    yield put(getAppSettings.success({ data: appSettings }));
    yield put(getThemeVedaActive.success(themeVedaActivate));

    // set default data cho picker shopify
    yield call(setDefaultPickerFieldRelateShopify, { blogResponse, collectionResponse, productResponse });

    // global
    yield put(globalMount.success(undefined));

    // yield call(inviteUserUseFreeCoupon);
    yield call(forceUserMigratesTheme);
    yield put(setVisibleModalUnauthorized(false));
  } catch (error) {
    customLog('watchGlobalMount', `${(error as AxiosError<ErrorData>).response?.data.message}`, error);
    yield put(globalMount.failure(undefined));
    yield put(getAppSettings.failure({ message: '' }));
    yield put(getThemeActive.failure(undefined));
    yield put(getThemeVedaActive.failure(undefined));
    yield put(getDefaultPickerFieldRelateShopify.failure(undefined));
  }
}

export function* watchGlobalMount() {
  yield takeLatest(getActionType(globalMount.request), handleGetGlobalData);
}
