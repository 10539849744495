import { getCurrentVersion } from 'utils/CacheControl/VedaClientCacheControl';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';
import { RequestGetPagesClient } from '../VedaApplication/types';

export const loadMorePagesClient = ({
  pageType,
  enable,
  label,
  lastCursor,
  size,
}: Omit<RequestGetPagesClient, 'cacheVersion'> & { lastCursor: string; size: number }) => {
  const { id } = getUserInfo();
  return pageApis.vedaApplication.userApi.page.getClients({
    type: 'LOADMORE',
    lastCursor,
    pageType,
    enable,
    label,
    userId: id,
    cacheVersion: getCurrentVersion().getTime(),
    size,
  });
};
