export const time = {
  yearAgo: '%% s %% year ago',
  yearsAgo: '%% s %% years ago',
  monthAgo: '%% s %% month ago',
  monthsAgo: '%% s %% months ago',
  weekAgo: '%% s %% week ago',
  weeksAgo: '%% s %% weeks ago',
  dayAgo: '%% s %% day ago',
  daysAgo: '%% s %% days ago',
  hourAgo: '%% s %% hour ago',
  hoursAgo: '%% s %% hours ago',
  minuteAgo: '%% s %% minute ago',
  minutesAgo: '%% s %% minutes ago',
  justNow: 'Just now',
  now: 'now',
} as const;
