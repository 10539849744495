import { put, retry, SagaReturnType, takeLatest } from '@redux-saga/core/effects';
import { AxiosError } from 'axios';
import { changeMySectionCategory, createProductCategory, getTemplateCategories } from 'containers/ChooseTemplate/store/actions';
import { sectionService } from 'services/SectionService';
import { getActionType } from 'wiloke-react-core/utils';

function* handleCategories({ payload: { search, showParentOnly } }: ReturnType<typeof getTemplateCategories.request>) {
  try {
    const data: SagaReturnType<typeof sectionService.categories.getCategoriesOfProduct> = yield retry(
      3,
      1000,
      sectionService.categories.getCategoriesOfProduct,
      { search, showParentOnly },
    );
    yield put(getTemplateCategories.success({ data }));

    yield put(changeMySectionCategory({ categorySlug: 'All' }));
  } catch (error) {
    const err = error as AxiosError<{ message: string }>;
    const message = err.response?.data?.message || '';
    yield put(getTemplateCategories.failure({ message }));
  }
}

export function* watchCategories() {
  yield takeLatest(getActionType(getTemplateCategories.request), handleCategories);
}

function* handleCreateCategory({
  payload: { description, name, priorityOrder, searchTerms, childrenCommandId, onFulfilled },
}: ReturnType<typeof createProductCategory.request>) {
  try {
    const response: Awaited<ReturnType<typeof sectionService.categories.createCategoryOfProduct>> = yield retry(
      3,
      1000,
      sectionService.categories.createCategoryOfProduct,
      {
        description,
        name,
        priorityOrder,
        searchTerms,
        childrenCommandId,
      },
    );
    yield put(
      createProductCategory.success({
        count: 0,
        commandId: response.info.commandId,
        name: response.info.name,
        description: response.info.description,
        priorityOrder: response.info.priorityOrder,
        searchTerms: response.info.searchTerms,
        childrenCommandId,
      }),
    );
    onFulfilled?.();
  } catch (error) {
    yield put(createProductCategory.failure(undefined));
  }
}

export function* watchCreateProductCategory() {
  yield takeLatest(getActionType(createProductCategory.request), handleCreateCategory);
}
