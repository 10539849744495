import { AxiosError } from 'axios';
import { actionDuplicateBlogPage } from '../actions/actionBlogPage';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { duplicatePageClientAPI } from 'services/PageService/Logic/duplicatePageClient';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';

function* handleAction({ payload: { commandId } }: ReturnType<typeof actionDuplicateBlogPage.request>) {
  try {
    const response: SagaReturnType<typeof duplicatePageClientAPI> = yield retry(3, 1000, duplicatePageClientAPI, { commandId });

    notifyAxiosHandler.handleSuccess(response.message);
    yield put(actionDuplicateBlogPage.success({ commandId, item: response.info }));
  } catch (error) {
    notifyAxiosHandler.handleError(error as AxiosError<ErrorData> | Error);
    yield put(actionDuplicateBlogPage.failure({ commandId }));
  }
}

export function* watchDuplicateBlogPage() {
  yield takeLatest(getActionType(actionDuplicateBlogPage.request), handleAction);
}
