import { AxiosResponse } from 'axios';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from './const';

interface Params {
  purchaseCode: string;
  themeProductCommandId: string;
}

interface ResponseSuccess {
  message: string;
}

export const createPurchaseCode = async ({ purchaseCode, themeProductCommandId }: Params) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${baseUrl}`,
    method: 'PUT',
    data: { purchaseCode, themeProductCommandId },
  });
  return response.data;
};
