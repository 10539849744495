import { flatten, mergeDeepLeft, sort } from 'ramda';
import { i18n } from 'translation';
import { ArticleField } from '../../../@types/Shopify/InputSettings/ArticleField';
import { BlogField } from '../../../@types/Shopify/InputSettings/BlogField';
import { CheckboxField } from '../../../@types/Shopify/InputSettings/CheckboxField';
import { CollectionField } from '../../../@types/Shopify/InputSettings/CollectionField';
import { ImageField } from '../../../@types/Shopify/InputSettings/ImageField';
import { LinkListField } from '../../../@types/Shopify/InputSettings/LinkListField';
import { NumberField } from '../../../@types/Shopify/InputSettings/NumberField';
import { ProductField } from '../../../@types/Shopify/InputSettings/ProductField';
import { ProductListField } from '../../../@types/Shopify/InputSettings/ProductListField';
import { RadioField } from '../../../@types/Shopify/InputSettings/RadioField';
import { SelectField } from '../../../@types/Shopify/InputSettings/SelectField';
import { TextareaField } from '../../../@types/Shopify/InputSettings/TextareaField';
import { TextField } from '../../../@types/Shopify/InputSettings/TextField';
import { HeaderField } from '../../../@types/Shopify/SidebarSettings/HeaderField';
import { ParagraphField } from '../../../@types/Shopify/SidebarSettings/ParagraphField';
import { Locales } from '../../../@types/Veda/ShopifyLocales';
import { Section } from '../../@types/Section';
import { ThemeSettings } from '../../@types/ThemeSettings';
import { SettingBlockObject } from './@types/SettingBlockObject';
import { LIQUID_Converters } from './utils/LIQUID_Converters';
import { SCHEMA_Converters } from './utils/SCHEMA_Converters';
import { SETTING_Converters } from './utils/SETTING_Converters';

interface RTSettingBlockObject {
  locales: Locales;
  shopifyField: Array<
    | LinkListField
    | ArticleField
    | BlogField
    | CheckboxField
    | CollectionField
    | HeaderField
    | ParagraphField
    | ProductField
    | ProductListField
    | RadioField
    | NumberField
    | SelectField
    | TextareaField
    | TextField
    | ImageField
  >;
}

interface I_SCHEMA_SettingBlockObject {
  section: Section;
  objectWithChildrenSorted: SettingBlockObject;
  objectWithChildrenNonSorted: SettingBlockObject;
  themeSettings: ThemeSettings;
  isPreview: boolean;
  isExportForEnvato: boolean;
  isNeedReduceTotalLinesOfCode: boolean;
}
export const SCHEMA_SettingBlockObject = ({
  section,
  objectWithChildrenSorted,
  objectWithChildrenNonSorted,
  themeSettings,
  isPreview,
  isExportForEnvato,
  isNeedReduceTotalLinesOfCode,
}: I_SCHEMA_SettingBlockObject): RTSettingBlockObject => {
  let RLocales: Locales = {
    en: {},
    fr: {},
    vi: {},
  };

  const newSettings = objectWithChildrenSorted.children
    .map(item => {
      const indexInVedaSectionSchemaBlock = objectWithChildrenNonSorted.children.findIndex(item2 => item2.id === item.id);
      const schemaConverter = SCHEMA_Converters[item.type];
      if (schemaConverter) {
        const res = schemaConverter({
          // @ts-ignore
          field: item,
          parentField: objectWithChildrenSorted,
          isExportForEnvato,
          isPreview,
          section,
          themeSettings,
          isNeedReduceTotalLinesOfCode,
        });
        if (res) {
          const { locales, shopifyField } = res;
          RLocales = mergeDeepLeft(RLocales, locales as Locales);
          return { index: indexInVedaSectionSchemaBlock, data: shopifyField };
        }
        return undefined;
      }
      throw new Error(i18n.t('general.system_error'));
    })
    .filter(item => !!item);

  return {
    locales: RLocales,
    shopifyField: flatten(
      sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        return a.index - b.index;
      }, newSettings)
        .map(item => item?.data as RTSettingBlockObject['shopifyField'][number])
        .filter(item => !!item),
    ),
  };
};

interface I_SETTING_SettingBlockObject {
  section: Section;
  object: SettingBlockObject;
  themeSettings: ThemeSettings;
  isPreview: boolean;
  isExportForEnvato: boolean;
  isNeedReduceTotalLinesOfCode: boolean;
}
export const SETTING_SettingBlockObject = ({
  section,
  object,
  themeSettings,
  isExportForEnvato,
  isPreview,
  isNeedReduceTotalLinesOfCode,
}: I_SETTING_SettingBlockObject) => {
  type SettingResult = Record<string, string | number | boolean | undefined>;
  return object.children.reduce<SettingResult>((res, item) => {
    const settingConverter = SETTING_Converters[item.type];
    if (settingConverter) {
      return {
        ...res,
        ...(settingConverter({
          // @ts-ignore
          field: item,
          parentField: object,
          isExportForEnvato,
          isPreview,
          section,
          themeSettings,
          isNeedReduceTotalLinesOfCode,
        }) as SettingResult),
      };
    }
    return res;
  }, {});
};

interface I_LIQUID_SettingBlockObject {
  section: Section;
  object: SettingBlockObject;
  liquid: string;
  themeSettings: ThemeSettings;
  isPreview: boolean;
  isExportForEnvato: boolean;
  isNeedReduceTotalLinesOfCode: boolean;
}
export const LIQUID_SettingBlockObject = ({
  section,
  liquid,
  object,
  themeSettings,
  isPreview,
  isExportForEnvato,
  isNeedReduceTotalLinesOfCode,
}: I_LIQUID_SettingBlockObject) => {
  return object.children.reduce<string>((res, item) => {
    const liquidConverter = LIQUID_Converters[item.type];
    if (liquidConverter) {
      return liquidConverter({
        // @ts-ignore
        field: item,
        parentField: object,
        liquid: res,
        loopVariable: undefined,
        section,
        themeSettings,
        isPreview,
        isExportForEnvato,
        isNeedReduceTotalLinesOfCode,
      });
    }
    return res;
  }, liquid);
};
