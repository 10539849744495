import { useStackNavigator } from '@wiloke/ui';
import { TextEditor2 } from 'components/TextEditor2';
import { LeftBarParamList } from 'containers/BuilderPage/components/SidebarScreen/SidebarScreen';
import withDebounce from 'hocs/withDebounce';
import { useSelector } from 'react-redux';
import { useUpdateSettingsValue } from 'store/actions/actionPages';
import { authSelector, sectionIdActiveSelector } from 'store/selectors';
import { pmParent } from 'utils/functions/postMessage';
import { FieldContainerProps } from '../types';
import { validatePlan } from 'utils/functions/validatePlan';
import { PLAN_STATIC_DATA, VEDA_FUNCTION_ENUM } from 'utils/constants/PlanEnum';
import { View } from 'wiloke-react-core';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';

export interface TextEditorFieldProps<T> extends FieldContainerProps<T> {
  name: string;
  enableMetafield?: boolean;
}

const TextEditorDebounce = withDebounce(TextEditor2, 'value', 'onChange', 500);

export const DISABLE_NAMES_FOR_PLAN = ['copyright'];

const TextEditorField = <T extends string>({
  enableMetafield,
  value,
  settingId,
  childId,
  grandChildId,
  forceRenderSection,
  name,
}: TextEditorFieldProps<T>) => {
  const updateSettingsValue = useUpdateSettingsValue();
  const sectionIdActive = useSelector(sectionIdActiveSelector);
  const { plan, role } = useSelector(authSelector);
  const navigation = useStackNavigator<LeftBarParamList>();
  // const { isLargeSection, listener } = useMouseMoveForFields(value, handleChange);
  const isDisable =
    DISABLE_NAMES_FOR_PLAN.includes(name) && validatePlan({ userPlan: plan.planHandle, itemPlan: VEDA_FUNCTION_ENUM.copyright, role }) === 'invalid';
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();

  function handleChange(value: string) {
    updateSettingsValue({
      value,
      settingId,
      childId,
      grandChildId,
    });

    if (forceRenderSection) {
      pmParent.emit('@rerender', { sectionId: sectionIdActive, action: 'TextEditorField.tsx' });
    }
  }

  return (
    <View
      onClick={() => {
        if (isDisable) {
          setPlanRequesting({
            handle: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.copyright].handle,
            type: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.copyright].type,
            pricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.copyright].pricePerMonth,
            pricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.copyright].pricePerYear,
            title: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.copyright].title,
            originalPricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.copyright].pricePerMonth,
            originalPricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.copyright].pricePerYear,
          });
          setValidateCouponVisible(true);
        }
      }}
    >
      <TextEditorDebounce
        forceRenderSection={forceRenderSection}
        value={value}
        enabledMetaField={enableMetafield}
        onChange={handleChange}
        disabled={isDisable}
        onClickMetaField={editor => {
          navigation.push('metaFieldScreen', {
            label: 'Metafield',
            onChange: metaField => {
              const viewFragment = editor.data.processor.toView(metaField);
              const modelFragment = editor.data.toModel(viewFragment);
              editor.model.insertContent(modelFragment);
            },
          });
        }}
      />
    </View>
  );
};

export default TextEditorField;
