import { useLinkPicker } from 'components/LinkPicker/store/context/LinkPickerContext';
import SelectAntd from 'components/SelectAntd';
import { useSelector } from 'react-redux';
import { sectionOptionsSelector } from 'store/selectors';
import { i18n } from 'translation';
import { View } from 'wiloke-react-core';

export const ScrollToSections = () => {
  const { value, dispatch } = useLinkPicker();
  const sections = useSelector(sectionOptionsSelector);

  return (
    <View css={{ marginTop: '8px' }}>
      <SelectAntd
        value={!!value ? value.replace('/', '') : undefined}
        placeholder={i18n.t('builderPage.scroll_to_section')}
        data={sections}
        listItemHeight={34}
        onChange={val => {
          dispatch({
            type: 'setSettings',
            payload: {
              value: `/${val}`,
              type: 'section',
              label: '',
            },
          });
        }}
      />
    </View>
  );
};
