import { Cart } from 'utils/LiquidSyntaxToTwig';
import { randomImage } from './@utils';

export const GET_CART_PLACEHOLDER = (): Cart => {
  return {
    attributes: {},
    cart_level_discount_applications: [],
    checkout_charge_amount: 44000,
    currency: null,
    discount_applications: [],
    empty: null,
    item_count: 8,
    items: [
      {
        id: 41193296724141,
        properties: null,
        quantity: 4,
        variant_id: 41193296724141,
        key: '41193296724141:5b54c84a8156dc38a490924cd016cb40',
        title: 'Product Placeholder 1',
        unit_price: 5000,
        original_price: 5000,
        original_line_price: 20000,
        sku: null,
        grams: 0,
        vendor: 'Vendor 1',
        taxable: true,
        product_id: 7085144637613,
        gift_card: false,
        final_price: 4000,
        final_line_price: 16000,
        url: '/products/dark-winter-jacket?variant=41193296724141',
        image: {
          aspect_ratio: 1.499,
          alt: '',
          height: 617,
          src: randomImage(),
          width: 925,
        },
        requires_shipping: true,
        discounts: [{ amount: 1000, title: 'discount' }],
        variant_options: ['M'],
        options_with_values: [
          {
            name: 'Size',
            value: 'M',
          },
        ],
        line_level_discount_allocations: [],
        line_level_total_discount: 0,
      },
      {
        id: 41193296658605,
        properties: {},
        quantity: 2,
        variant_id: 41193296658605,
        key: '41193296658605:f53091d81a6c8896ed9636cf33a267ab',
        title: 'Product Placeholder 2',
        original_price: 6000,
        original_line_price: 24000,
        sku: null,
        grams: 0,
        vendor: 'Vendor 2',
        taxable: true,
        product_id: 7085144572077,
        gift_card: false,
        final_price: 6000,
        final_line_price: 24000,
        url: '/products/dark-denim-top?variant=41193296658605',
        image: {
          aspect_ratio: 1.499,
          alt: 'Dark Denim Top-partners-demo-women',
          height: 617,
          src: randomImage(),
          width: 925,
        },
        requires_shipping: true,
        discounts: [],
        variant_options: ['Default Title'],
        options_with_values: [
          {
            name: 'Title',
            value: 'Default Title',
          },
        ],
        line_level_discount_allocations: [],
        line_level_total_discount: 0,
      },
    ],
    items_subtotal_price: 40000,
    note: null,
    original_total_price: 40000,
    requires_shipping: true,
    taxes_included: false,
    total_discount: 0,
    total_price: 40000,
    total_weight: 0,
  };
};
