export const publish_shopify = {
  limit_liquid_file_size: 'The maximum size of liquid file is %% size %%',
  error_in_code: 'Error in the code',
  error_unknown: {
    message: 'An error occurred during the synchronization process',
    description: 'Make sure your code follows the established rules',
  },
  sync_error: 'Sync failed',
  sync_success: 'Sync successful',
  publishing: 'Saving',
  init_sync: 'Initiating process',
  init_sync_error: {
    message: 'Initialization failed',
    description: 'Lost connection, please retry your action again!',
  },
  syncing: 'Syncing %% text %%',
  live_preview: 'Live Preview',
  continue_editing: 'Continue',
  socket_timeout: 'Socket timeout',
  sync_something_error: 'Sync %% text %% failed',
  theme_settings_or_atomic_css: 'theme settings or atomic css',
  translation: 'translation',
  migrate: 'Migrate %% text %%',
  text_to_shopify: '%% text %% to shopify',
  sync_page_message: {
    step: 'Step %% text %%',
    init: 'Initiating process...',
    saving: 'Saving %% text %%...',
    syncing_to_shopify: 'Syncing %% text %% to shopify...',
  },
  page_not_sync_yet: 'This page has not been synced to shopify',
  process_used: 'Another synchronous process is being executed. Please wait until all is done',
  syncing_not_response: 'The syncing is not responding',
  syncing_may_stuck: 'The syncing may be stuck',
  contact_for_solution: `Please contact support for a solution`,
  wait_for_response: 'Wait for Veda to respond.',
  get_help: 'Get help',
  limit_theme: 'Theme limit reached',
  limit_theme_desc: 'Theme limit reached Your shop has used 20 themes. Please select one theme to delete & perform theme creation again.',
  section_sync_error: 'Section sync encountered an error.',
} as const;
