import { PartnerAPI } from 'types/Partner';
import { createAsyncAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';

export const getPartners = createAsyncAction(['@Admin/getPartner/request', '@Admin/getPartner/success', '@Admin/getPartner/failure'])<
  undefined,
  { data: PartnerAPI[] },
  undefined
>();

export const createPartner = createAsyncAction(['@Admin/createPartner/request', '@Admin/createPartner/success', '@Admin/createPartner/failure'])<
  { data: Omit<PartnerAPI, 'id'>; onFulfilled?: () => void },
  { data: PartnerAPI },
  undefined
>();

export const updatePartner = createAsyncAction(['@Admin/updatePartner/request', '@Admin/updatePartner/success', '@Admin/updatePartner/failure'])<
  { data: PartnerAPI; onFulfilled?: () => void },
  { data: PartnerAPI },
  { id: PartnerAPI['id'] }
>();

export const deletePartner = createAsyncAction(['@Admin/deletePartner/request', '@Admin/deletePartner/success', '@Admin/deletePartner/failure'])<
  { id: PartnerAPI['id']; onFulfilled?: () => void },
  { id: PartnerAPI['id'] },
  { id: PartnerAPI['id'] }
>();

export const useGetPartners = createDispatchAsyncAction(getPartners);
export const useCreatePartners = createDispatchAsyncAction(createPartner);
export const useUpdatePartners = createDispatchAsyncAction(updatePartner);
export const useDeletePartners = createDispatchAsyncAction(deletePartner);
