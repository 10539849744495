import { AxiosError } from 'axios';
import { put, retry, SagaReturnType, takeEvery } from 'redux-saga/effects';
import { mediaService } from 'services/MediaService';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { actionDeleteMyMedia } from '../actions/actionMyMedia';

function* handleDeleteMyMedia({ payload: { image, onFulFill } }: ReturnType<typeof actionDeleteMyMedia.request>) {
  try {
    const { message }: SagaReturnType<typeof mediaService.deleteMyMediaById> = yield retry(3, 1000, mediaService.deleteMyMediaByUrl, {
      url: image.url,
    });
    yield put(actionDeleteMyMedia.success({ url: image.url }));
    onFulFill?.(image);
    notifyAxiosHandler.handleSuccess(message);
  } catch (err) {
    notifyAxiosHandler.handleError(err as AxiosError<ErrorData> | Error);
    yield put(actionDeleteMyMedia.failure({ url: image.url }));
  }
}

export function* watchDeleteMyMedia() {
  yield takeEvery(getActionType(actionDeleteMyMedia.request), handleDeleteMyMedia);
}
