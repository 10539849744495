import { AxiosResponse } from 'axios';
import { AddonOfTheme_Atom_N_Client } from 'types/Addons';
import { ToRequiredKeys } from 'utils';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrlForAccessAtom } from '../const';

interface GetAddonsOfAtom {
  commandIds: string[];
  cacheVersion: number | null;
}

interface ResponseSuccess {
  message: string;
  info: Array<ToRequiredKeys<AddonOfTheme_Atom_N_Client, 'commandId'>>;
}

export const getAddonsOfAtom = async ({ commandIds, cacheVersion }: GetAddonsOfAtom) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${baseUrlForAccessAtom}/search`,
    params: {
      cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
      commandIds: commandIds.join(','),
    },
  });
  return response.data;
};
