import { themeDashboardSelector } from 'containers/Admin/ThemeBuilder/ThemeDashboard/slice/sliceThemeDashboard';
import { call, put, retry, SagaReturnType, select, takeLatest } from 'redux-saga/effects';
import { appSetupService } from 'services/AppSetupService';
import { authService } from 'services/AuthService';
import { updateThemeIdOfUser } from 'store/global/auth/actions';
import { setChangeThemeButNotSave } from 'store/global/auth/slice';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';

export function* handleUpdateThemeIdOfUser({ payload: { themeId, onFulfill } }: ReturnType<typeof updateThemeIdOfUser.request>) {
  try {
    const { id, role } = getUserInfo();
    const { shopifyThemes }: SagaReturnType<typeof themeDashboardSelector> = yield select(themeDashboardSelector);
    const theme = shopifyThemes.find(item => item.id === themeId);

    const response: SagaReturnType<typeof authService.updateUserInfo> = yield retry(3, 1000, authService.updateUserInfo, {
      id,
      themeId,
      themeName: theme?.name,
    });
    yield put(updateThemeIdOfUser.success({ themeId }));
    yield call(appSetupService, role);
    yield put(setChangeThemeButNotSave(false));
    onFulfill?.(themeId);
    notifyAxiosHandler.handleSuccess(response.message);
  } catch (error) {
    yield put(updateThemeIdOfUser.failure({ themeId }));
  }
}

export function* watchUpdateThemeIdOfUser() {
  yield takeLatest(getActionType(updateThemeIdOfUser.request), handleUpdateThemeIdOfUser);
}
