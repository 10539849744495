import { handleWaitForSocketOfSyncShopifyFulfill } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { SagaReturnType, call, retry } from 'redux-saga/effects';
import { shopifyConnectionService } from 'services/ShopifyConnection';
import { handleSaveInBuilderPage } from 'services/ShopifyConnection/handleSaveInBuilderPage';

export interface SyncPublishVedaHeaderFooter {
  publishHeaderFooterParams: ReturnType<typeof handleSaveInBuilderPage>['publishHeaderFooterParams'];
}

export interface SyncPublishVedaHeaderFooterResult {
  statusSyncPublishVedaHeaderFooter: SyncFulfillStatus;
  isNeedIgnoreReportError: boolean;
}

export function* syncPublishHeaderFooter({ publishHeaderFooterParams }: SyncPublishVedaHeaderFooter) {
  yield retry(3, 1000, shopifyConnectionService.publishHeaderFooterVeda, {
    eventId: publishHeaderFooterParams.eventId,
    eventType: publishHeaderFooterParams.eventType,
  });

  const socketSyncPublishVedaHeaderFooter: SagaReturnType<typeof handleWaitForSocketOfSyncShopifyFulfill> = yield call(
    handleWaitForSocketOfSyncShopifyFulfill,
    'Publish header và footer của veda',
  );

  return {
    isNeedIgnoreReportError: socketSyncPublishVedaHeaderFooter.isNeedIgnoreReportError,
    statusSyncPublishVedaHeaderFooter: socketSyncPublishVedaHeaderFooter.statusSync,
  } as SyncPublishVedaHeaderFooterResult;
}
