import { mergeDeepLeft } from 'ramda';
import { ColorField } from '../../../@types/Shopify/InputSettings/ColorField';
import { Locales } from '../../../@types/Veda/ShopifyLocales';
import { replaceExactVariableNameInLiquidCode } from '../../../@utils/replaceExactVariableNameInLiquidCode';
import { vedaLabelToShopifyFieldLabel } from './utils/vedaLabelToShopifyFieldLabel';
import { vedaSummaryToShopifyFieldInfo } from './utils/vedaSummaryToShopifyFieldInfo';
import { ILIQUID_SettingColor } from './@types/ILIQUID_SettingColor';
import { ISCHEMA_SettingColor } from './@types/ISCHEMA_SettingColor';
import { ISETTING_SettingColor } from './@types/ISETTING_SettingColor';
import { toShopifyFieldId } from './utils/toShopifyFieldId';
import { TextField } from '../../../@types/Shopify/InputSettings/TextField';
import { getFinalColor } from './utils/getFinalColor';
import { getSignalReduceTotalLinesOfCode } from './utils/getSignalReduceTotalLinesOfCode';
import { ignoreToReduceTotalLinesOfCode } from '../../../@utils/ignoreToReduceTotalLinesOfCode';

interface RTSettingColor {
  shopifyField: ColorField | TextField;
  locales: Locales;
}

export const SCHEMA_SettingColor = ({ field, parentField, themeSettings, isExportForEnvato, ...params }: ISCHEMA_SettingColor): RTSettingColor => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode ||
    (params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField }));
  const { newLabel, locales: labelLocales } = vedaLabelToShopifyFieldLabel({
    ...params,
    field,
    parentField,
    themeSettings,
    isExportForEnvato,
    isNeedReduceTotalLinesOfCode,
  });
  const { newInfo, locales: infoLocales } = vedaSummaryToShopifyFieldInfo({
    ...params,
    field,
    parentField,
    themeSettings,
    isExportForEnvato,
    isNeedReduceTotalLinesOfCode,
  });

  if (isExportForEnvato) {
    const color = getFinalColor({ field, themeSettings });
    return {
      shopifyField: {
        label: newLabel,
        info: newInfo,
        id: isNeedReduceTotalLinesOfCode
          ? getSignalReduceTotalLinesOfCode(field)
          : toShopifyFieldId({ ...params, field, parentField, themeSettings, isExportForEnvato, isNeedReduceTotalLinesOfCode }),
        type: 'color',
        default: color ? color : undefined,
      },
      locales: mergeDeepLeft(labelLocales, infoLocales),
    };
  }
  return {
    shopifyField: {
      label: newLabel,
      info: newInfo,
      id: isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field)
        : toShopifyFieldId({ ...params, field, parentField, themeSettings, isExportForEnvato, isNeedReduceTotalLinesOfCode }),
      type: 'text',
      default: !!field.children ? field.children : undefined,
      placeholder: undefined,
    },
    locales: mergeDeepLeft(labelLocales, infoLocales),
  };
};

export const SETTING_SettingColor = ({ field, parentField, themeSettings, isExportForEnvato, ...params }: ISETTING_SettingColor) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode ||
    (params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField }));
  // check kĩ phòng lỗi
  const value = typeof field.children === 'string' && !!field.children ? field.children : undefined;
  if (value !== undefined || isNeedReduceTotalLinesOfCode) {
    const fieldName = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : toShopifyFieldId({ ...params, field, parentField, themeSettings, isExportForEnvato, isNeedReduceTotalLinesOfCode });
    if (isExportForEnvato) {
      const color = getFinalColor({ field, themeSettings });
      return { [fieldName]: color ? color : undefined };
    }
    return { [fieldName]: value };
  }
  return {};
};

export const LIQUID_SettingColor = ({ field, parentField, liquid, loopVariable, ...params }: ILIQUID_SettingColor) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode ||
    (params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField }));
  // Nếu field thuộc array
  // - loopVariable nếu là array ->  Array được dùng trong forloop -> Thế `${loopVariable}.${field.name}.${key}` = `${loopVariable}.${toShopifyFieldId({ field, parentField }, key)}`
  //   Example: --> for item in content --> item.icon_design.id ==> KQ: item.content__icon_design.id
  if (loopVariable) {
    const target = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : `${loopVariable}.${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode })}`;
    const source = `${loopVariable}.${field.name}`;
    return replaceExactVariableNameInLiquidCode({ liquid, source, target });
  }
  // Nếu field thuộc object
  // - parentField nếu là object -> Object được flat thành các "shopify input settings" -> Thế `${parentField.name}.${field.name}.${key}` = toShopifyFieldId
  //   Example: --> heading.icon_design.id ==> KQ: heading__icon_design.id
  else if (parentField) {
    const target = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode })}"]`;
    const source = `${parentField.name}.${field.name}`;
    return replaceExactVariableNameInLiquidCode({ liquid, source, target });
  } else {
    const target = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode })}"]`;
    const source = field.name;
    return replaceExactVariableNameInLiquidCode({ liquid, source, target });
  }
};
