import { createPublishStepLoading } from 'components/PublishStepLoading';
import { ModalLoginShopifyBeforePreview } from 'containers/BuilderPage/components/ModalLoginShopifyBeforePreview/ModalLoginShopifyBeforePreview';
import { MODAL_REPORT_AFTER_SYNC_ERROR } from 'containers/ModalReportAfterError/const';
import { ModalReportAfterError } from 'containers/ModalReportAfterError/ModalReportAfterError';
import { all, call, put, retry, SagaReturnType, select, takeLeading } from 'redux-saga/effects';
import { handlePreviewInBuilderPage, shopifyConnectionService } from 'services/ShopifyConnection';
import {
  syncAddonsEnablePosition,
  SyncAddonsEnablePositionResult,
  syncAddonsDisablePosition,
  SyncAddonsDisablePositionResult,
} from 'services/ShopifyConnection/flowSyncPreviewWithShopify/syncAddons';
import { syncGlobalOfTheme, SyncGlobalOfThemeResult } from 'services/ShopifyConnection/flowSyncPreviewWithShopify/syncGlobalSettings';
import { syncHeaderFooter, SyncHeaderFooterResult } from 'services/ShopifyConnection/flowSyncPreviewWithShopify/syncHeaderFooter';
import {
  syncPageInPageBuilderMode,
  SyncPageInPageBuilderModeResult,
  syncPagesInThemeBuilderMode,
  SyncPagesInThemeBuilderModeResult,
} from 'services/ShopifyConnection/flowSyncPreviewWithShopify/syncPage';
import { syncTranslations } from 'services/ShopifyConnection/flowSyncPreviewWithShopify/syncTranslations';
import { checkThemeAppStatus } from 'services/ShopifyConnection/flowSyncToShopify/checkThemeAppStatus';
import { liquidVariablesSelector, socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector } from 'store/selectors';
import { i18n } from 'translation';
import { getActionType } from 'wiloke-react-core/utils';
import { previewWithShopify } from './actions';

export const syncPageNotification = createPublishStepLoading(6);

// NOTE: @tuong -> Ý tưởng rate limit hiện tại: Cứ mỗi chặng (step sync) cho dừng nửa giây
export function* handlePreviewWithShopify({ payload }: ReturnType<typeof previewWithShopify.request>) {
  const { onSyncFulfill } = payload;
  if (!ModalLoginShopifyBeforePreview.isExecuted) {
    onSyncFulfill?.();
    ModalLoginShopifyBeforePreview.open();
    yield put(previewWithShopify.failure(undefined));
    return;
  }
  try {
    const isActived: SagaReturnType<typeof checkThemeAppStatus> = yield call(checkThemeAppStatus);
    if (!isActived) {
      yield put(previewWithShopify.failure(undefined));
      onSyncFulfill?.();
      syncPageNotification.done();
      return;
    }
    const { result } = payload;
    const { eventId }: ReturnType<typeof socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector> = yield select(
      socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector,
    );

    const { data }: ReturnType<typeof liquidVariablesSelector> = yield select(liquidVariablesSelector);
    const { theme } = data;

    const {
      pagesParams,
      themeParams,
      addonsEnablePositionParams,
      addonsDisablePositionParams,
      syncTranslationsParams,
      footerParams,
      headerParams,
    }: ReturnType<typeof handlePreviewInBuilderPage> = handlePreviewInBuilderPage({
      data: result,
      eventId,
      themeName: theme?.name as string,
    });

    const syncTranslationsResult: SagaReturnType<typeof syncTranslations> = yield call(syncTranslations, {
      syncTranslationsParams,
    });
    if (syncTranslationsResult.statusSyncTranslations === 'failure') {
      throw new Error(i18n.t('publish_shopify.sync_something_error', { text: i18n.t('publish_shopify.translation') }), {
        cause: syncTranslationsResult.statusSyncTranslations,
      });
    }

    const [syncGlobalOfThemeResult, syncAddonsDisablePositionResult, syncAddonsEnablePositionResult]: [
      SyncGlobalOfThemeResult,
      SyncAddonsDisablePositionResult,
      SyncAddonsEnablePositionResult,
    ] = yield all([
      call(syncGlobalOfTheme, { themeParams }),
      call(syncAddonsDisablePosition, { addonsDisablePositionParams }),
      call(syncAddonsEnablePosition, { addonsEnablePositionParams }),
    ]);

    if (syncGlobalOfThemeResult.statusSyncGlobalOfTheme === 'failure') {
      throw new Error(i18n.t('publish_shopify.sync_something_error', { text: i18n.t('publish_shopify.theme_settings_or_atomic_css') }), {
        cause: syncGlobalOfThemeResult.isNeedIgnoreReportError,
      });
    }

    if (
      syncAddonsDisablePositionResult.statusSyncAddonsDisablePosition === 'failure' ||
      syncAddonsEnablePositionResult.statusSyncAddonsEnablePosition === 'failure'
    ) {
      throw new Error(i18n.t('publish_shopify.sync_something_error', { text: i18n.t('general.addons') }), {
        cause: syncAddonsDisablePositionResult.isNeedIgnoreReportError || syncAddonsEnablePositionResult.isNeedIgnoreReportError,
      });
    }

    if (result.builderType === 'page') {
      const syncPageInPageBuilderModeResult: SyncPageInPageBuilderModeResult = yield call(syncPageInPageBuilderMode, {
        pagesParams,
        signalToReplaceAddonInLiquidCode: syncAddonsEnablePositionResult.signalToReplaceAddonInLiquidCode,
      });
      if (syncPageInPageBuilderModeResult.statusSyncPageInPageBuilderMode === 'failure') {
        throw new Error(i18n.t('publish_shopify.sync_something_error', { text: i18n.t('general.page') }), {
          cause: syncPageInPageBuilderModeResult.statusSyncPageInPageBuilderMode,
        });
      }
    } else if (result.builderType === 'theme' && headerParams && footerParams) {
      const [syncHeaderFooterResult, syncPagesInThemeBuilderModeResult]: [SyncHeaderFooterResult, SyncPagesInThemeBuilderModeResult] = yield all([
        call(syncHeaderFooter, {
          headerParams,
          footerParams,
          signalToReplaceAddonInLiquidCode: syncAddonsEnablePositionResult.signalToReplaceAddonInLiquidCode,
        }),
        call(syncPagesInThemeBuilderMode, {
          pagesParams,
          signalToReplaceAddonInLiquidCode: syncAddonsEnablePositionResult.signalToReplaceAddonInLiquidCode,
        }),
      ]);
      if (syncHeaderFooterResult.statusSyncHeader === 'failure' || syncHeaderFooterResult.statusSyncFooter === 'failure') {
        throw new Error(
          i18n.t('publish_shopify.sync_something_error', {
            text: `${i18n.t('general.header')} ${i18n.t('general.footer')}`,
          }),
          { cause: syncHeaderFooterResult.isNeedIgnoreReportError },
        );
      }

      if (syncPagesInThemeBuilderModeResult.statusSyncPagesInThemeBuilderMode === 'failure') {
        throw new Error(i18n.t('publish_shopify.sync_something_error', { text: i18n.t('general.page') }), {
          cause: syncPagesInThemeBuilderModeResult.isNeedIgnoreReportError,
        });
      }
    }

    yield put(previewWithShopify.success(undefined));
  } catch (err) {
    if (err instanceof Error) {
      console.log(err);
      yield put(previewWithShopify.failure(undefined));
      if (typeof err.cause === 'boolean' && err.cause) {
      } else {
        ModalReportAfterError.getActions(MODAL_REPORT_AFTER_SYNC_ERROR).report({
          cause: i18n.t('ModalReportAfterError.error_cause.migrate_theme'),
          description: err instanceof Error ? err.message : '',
        });
      }
    }
  } finally {
    onSyncFulfill?.();
    syncPageNotification.done();
    try {
      yield retry(3, 1000, shopifyConnectionService.cleanAfterSync);
    } catch {}
  }
}

export function* watchPreviewWithShopify() {
  yield takeLeading(getActionType(previewWithShopify.request), handlePreviewWithShopify);
}
