import { createUserAddonsCategory, getAddonsNav } from 'containers/ChooseTemplate/store/actions';
import { put, retry, select, takeLatest } from 'redux-saga/effects';
import { addonService } from 'services/AddonService';

import { addonSelector } from 'store/selectors';
import { SectionCategoryTag } from 'types/Sections';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { setUserAddonsCategory } from '../../reducers/addons/user.sliceAddonCategory';

function* handleGet(_: ReturnType<typeof getAddonsNav.request>) {
  try {
    const { addonsNav, getNavStatus }: ReturnType<typeof addonSelector.userAddonsCategory> = yield select(addonSelector.userAddonsCategory);
    if (getNavStatus === 'success') {
      yield put(getAddonsNav.success({ data: addonsNav }));
    } else {
      const response: Awaited<ReturnType<typeof addonService.categories.getCategoriesOfProduct>> = yield retry(
        3,
        1000,
        addonService.categories.getCategoriesOfProduct,
      );

      yield put(getAddonsNav.success({ data: response }));

      const checkExist = Array.isArray(response) && response.length > 0;

      let firstItem: SectionCategoryTag = {
        commandId: '',
        name: '',
        count: 0,
        description: '',
      };

      if (checkExist) {
        firstItem = response[0];
      }

      yield put(
        setUserAddonsCategory({
          id: firstItem.commandId,
          slug: firstItem.name,
          addonQuantityOfCategory: Number(firstItem.count ?? 0),
        }),
      );
    }
  } catch (error) {
    yield put(getAddonsNav.failure(undefined));
  }
}

export function* watchGetAddonNav() {
  yield takeLatest(getActionType(getAddonsNav.request), handleGet);
}

function* createUserCate({ payload: { description, name } }: ReturnType<typeof createUserAddonsCategory.request>) {
  try {
    const response: Awaited<ReturnType<typeof addonService.categories.createCategoryOfProduct>> = yield retry(
      3,
      1000,
      addonService.categories.createCategoryOfProduct,
      { description, name },
    );
    yield put(
      createUserAddonsCategory.success({
        commandId: response.info.commandId,
        name: response.info.name,
        description: response.info.description,
        count: 0,
      }),
    );
    notifyAxiosHandler.handleSuccess(response.message);
  } catch {
    yield put(createUserAddonsCategory.failure(undefined));
  }
}

export function* watchCreateUserAddonCategory() {
  yield takeLatest(getActionType(createUserAddonsCategory.request), createUserCate);
}
