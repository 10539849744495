import SelectLang from 'components/SelectLang';
import { Title } from '@wiloke/ui';
import { FC } from 'react';
import { useSetReloadPage } from 'store/actions/actionReloadPage';
import { useGetLiquidTranslationsObject } from 'store/actions/liquid/actionLiquidVariables';
import { i18n } from 'translation';
import { View } from 'wiloke-react-core';
import * as styles from '../styles';
import { useChangeAppSettings } from 'store/global/appSettings/slice';

export const Language: FC = () => {
  const setReloadPage = useSetReloadPage();
  const changeSettings = useChangeAppSettings();
  const getLiquidTranslationsObject = useGetLiquidTranslationsObject();

  return (
    <View css={styles.field}>
      <View css={styles.left}>
        <Title title={i18n.t('general.language')} text={i18n.t('general.change_language_of_app')} />
      </View>
      <View css={styles.right}>
        <View css={{ width: '150px' }}>
          <SelectLang
            onClickItem={value => {
              setReloadPage(undefined);
              changeSettings({
                language: value,
              });
              getLiquidTranslationsObject.request({ locale: value });
            }}
          />
        </View>
      </View>
    </View>
  );
};
