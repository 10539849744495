import configureApp from 'configureApp';
import { ThemeShopify } from 'services/ThemeService/types';
import fetchAPI from 'utils/functions/fetchAPI';

export const deleteThemeRecord = (shopifyThemeId: ThemeShopify['id']) => {
  return fetchAPI.request({
    url: `${configureApp.endpoint['shopify-connections']}/sync/shopify/me/stores/themes/${shopifyThemeId}`,
    method: 'DELETE',
  });
};
