import { TourGuide, TourGuideStep, TourGuideProps } from 'components/TourGuide';
import { tourGuideSelector, useSetTourGuidePage } from 'containers/TourGuides/sliceTourGuides';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useChangeModalAdminSettings } from 'containers/Admin/Modals/store';
import { i18n } from 'translation';
import { storage } from '@wiloke/functions';

export const tourGuidePage: TourGuideStep[] = [
  {
    target: '#route-pages-type',
    title: i18n.t('ModalTour.tour_page.step_1.title'),
    content: i18n.t('ModalTour.tour_page.step_1.description'),
    placement: 'auto',
    showProgress: true,
    disableBeacon: true,
  },
  {
    target: '#button-create-landing',
    title: i18n.t('ModalTour.tour_page.step_2.title'),
    content: i18n.t('ModalTour.tour_page.step_2.description'),
    placement: 'auto',
    showProgress: true,
  },
  {
    target: '#modal-create-normal-page',
    title: i18n.t('ModalTour.tour_page.step_3.title'),
    content: i18n.t('ModalTour.tour_page.step_3.description'),
    placement: 'auto',
    showProgress: true,
    floaterProps: { hideArrow: true },
  },
];

export const TourGuideForPage = () => {
  const { pageTour } = useSelector(tourGuideSelector);

  const location = useLocation();
  const history = useHistory();
  const setPageTour = useSetTourGuidePage();
  const changeModalSettings = useChangeModalAdminSettings();

  const handleTourGuide: TourGuideProps['callback'] = data => {
    const { action, index, type, status } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type as any)) {
      setPageTour({ run: true, stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
      if ((location.pathname === '/page' || location.pathname === '/page/templates') && index === 0) {
        history.push('/page/blank', undefined);
      }
      if (index === 1 && ACTIONS.NEXT === action) {
        changeModalSettings({ createNormalPage: true });
      }
      if (index === 2 && ACTIONS.PREV === action) {
        changeModalSettings({ createNormalPage: false });
      }
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)) {
      setPageTour({ run: false, stepIndex: 0 });
      const targetDiv = document.getElementById('modal-create-normal-page');
      if (targetDiv) {
        targetDiv.style.overflowY = 'auto';
      }
      storage.setItem('TourGuideForPage', 'off');
    }
  };

  if (window.location.href.includes('/page') && !storage.getItem('TourGuideForPage')) {
    return (
      <TourGuide
        steps={tourGuidePage}
        callback={handleTourGuide}
        stepIndex={pageTour?.stepIndex ?? 0}
        run={pageTour?.run ?? true}
        disableScrolling
        disableScrollParentFix
      />
    );
  }

  return null;
};
