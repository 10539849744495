import { getHeaderOrFooterSectionsOfThemeAtom } from 'services/ThemeService/Logic/getHeaderOrFooterSectionsOfThemeAtom';
import { pushToCache, readFromCache } from './utils/CacheStorage';
import { getMegamenuCacheKey, getSectionCacheKey } from './utils/CacheKeyControl';
import { PageSection } from 'types/Sections';
import { BaseParams } from './@types/BaseParams';
import { getHeaderOrFooterSectionsOfThemeDraft } from 'services/ThemeService/Logic/getHeaderOrFooterSectionsOfThemeDraft';

export const getHeaderSections_ = async ({ commandIds, entityVariant }: GetHeaderSections) => {
  const getHeaderOrFooterSectionsOfThemeWithEntityVariant =
    entityVariant === 'Atom' ? getHeaderOrFooterSectionsOfThemeAtom : getHeaderOrFooterSectionsOfThemeDraft;
  const headerSections = await getHeaderOrFooterSectionsOfThemeWithEntityVariant({ commandIds });

  const megamenuCommandIds = headerSections.reduce<string[]>((result, headerSection) => {
    if (headerSection.enable && headerSection.megaMenuCommandIds) {
      return result.concat(headerSection.megaMenuCommandIds);
    }
    return result;
  }, []);

  const headerKeys = await headerSections.reduce<Promise<string[]>>(async (result, headerSection) => {
    const result_ = await result;
    if (headerSection.commandId && headerSection.enable) {
      const cacheKey = await pushToCache(JSON.stringify(headerSection), getMegamenuCacheKey(headerSection.commandId));
      return result_.concat(cacheKey);
    }
    return result_;
  }, Promise.resolve([]));
  return {
    headerKeys,
    megamenuCommandIds,
  };
};

interface GetHeaderSections extends BaseParams {
  commandIds: string[];
}
export const getHeaderSections = async ({ commandIds, isRetry, ...params }: GetHeaderSections) => {
  if (isRetry) {
    const megamenuCommandIds: string[] = [];
    const headerKeys = await Promise.all(
      commandIds.map(async commandId => {
        const cacheKey = getSectionCacheKey(commandId);
        const dataFromCache = await readFromCache<PageSection>(cacheKey);
        if (dataFromCache) {
          if (dataFromCache.megaMenuCommandIds) {
            megamenuCommandIds.push(...dataFromCache.megaMenuCommandIds);
          }
          return cacheKey;
        } else {
          const { headerKeys, megamenuCommandIds: megamenuCommandIds_ } = await getHeaderSections_({ commandIds: [commandId], isRetry, ...params });
          megamenuCommandIds.push(...megamenuCommandIds_);
          return headerKeys[0];
        }
      }),
    );
    return { headerKeys, megamenuCommandIds };
  } else {
    return getHeaderSections_({ commandIds, isRetry, ...params });
  }
};
