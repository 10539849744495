import { createPortal, offset, storage } from '@wiloke/functions';
import { iframeLoadedSelector } from 'containers/BuilderPage/store/iframeLoaded/slice';
import { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { pagesSelector } from 'store/selectors';
import getPageInfo from 'utils/functions/getInfo';
import { isThemeBuilder } from 'utils/validateBuilderMode';
import { FontAwesome, OuterTrigger, Space, Text, View } from 'wiloke-react-core';
import { DropdownContent } from './components/DropdownContent';
import * as styles from './styles';
import { useUndoRedoForRedux } from 'hooks/useUndoRedoForRedux/useUndoRedoForRedux';

const SelectPage: FC = () => {
  const pages = useSelector(pagesSelector);
  const iframeLoaded = useSelector(iframeLoadedSelector);

  const dropdownData = Object.values(pages.data);
  const [visible, setVisible] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const buttonRef = useRef<HTMLElement | null>(null);
  const isTheme = isThemeBuilder();
  const containerRef = useRef<HTMLElement | null>(null);
  const canUndo = useUndoRedoForRedux.CAN_UNDO;
  const pageId = getPageInfo('id');
  const canRedirect = !canUndo || (typeof pages.pageSaved[pageId] === 'boolean' && pages.pageSaved[pageId] === true);

  useEffect(() => {
    if (isTheme && iframeLoaded) {
      const _firstTime = storage.getItem('FirstTimeOnTheme');
      if (_firstTime && _firstTime === 'true') {
        setVisible(false);
      } else {
        storage.setItem('FirstTimeOnTheme', 'true');
        const el = buttonRef?.current as HTMLElement;
        setTop(offset(el).top + el.offsetHeight);
        setLeft(offset(el).left);
        setVisible(true);
      }
    }
  }, [isTheme, iframeLoaded]);

  if (!dropdownData.length || !getPageInfo('themeId')) {
    return null;
  }

  const renderDropdown = (
    <OuterTrigger
      onClick={({ target }) => {
        const _target = target as HTMLElement;
        if (!_target.getAttribute('class')?.includes('DROPDOWN_OUTER_TRIGGER')) {
          setVisible(false);
        }
      }}
    >
      <View css={styles.dropdown(top, left)}>
        <DropdownContent
          canRedirect={canRedirect}
          onChangePage={() => {
            setVisible(false);
          }}
        />
      </View>
    </OuterTrigger>
  );

  const handleButtonClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const el = event.currentTarget as HTMLElement;
    setVisible(state => !state);
    setTop(offset(el).top + el.offsetHeight);
    setLeft(offset(el).left);
  };

  return (
    <>
      <View ref={containerRef} className="DROPDOWN_OUTER_TRIGGER" css={styles.container}>
        {visible && createPortal(renderDropdown)}
        <View ref={buttonRef} css={styles.button(visible)} onClick={handleButtonClick} className="DROPDOWN_OUTER_TRIGGER">
          <Text className="DROPDOWN_OUTER_TRIGGER" numberOfLines={1}>
            {pages.data[getPageInfo('id')].label}
          </Text>
          <FontAwesome className="DROPDOWN_OUTER_TRIGGER" type="far" name="angle-down" size={18} />
        </View>
      </View>
      <Space size={10} type="horizontal" />
    </>
  );
};

export default SelectPage;
