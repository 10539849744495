import { AxiosError } from 'axios';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { getPurchaseCodesService } from 'services/UserService/Logic/getPurchaseCodes';
import { ErrorData } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { getPurchaseCodes } from '../actions';

function* handleGet(_: ReturnType<typeof getPurchaseCodes.request>) {
  try {
    const response: SagaReturnType<typeof getPurchaseCodesService> = yield retry(3, 1000, getPurchaseCodesService, {
      size: 50,
    });
    yield put(getPurchaseCodes.success({ data: response.info, message: response.message }));
  } catch (error) {
    let message = '';
    if ((error as AxiosError).isAxiosError) {
      message = (error as AxiosError<ErrorData>).response?.data.message ?? '';
    }
    yield put(getPurchaseCodes.failure({ message }));
  }
}

export function* watchGetPurchaseCodes() {
  yield takeLatest(getActionType(getPurchaseCodes.request), handleGet);
}
