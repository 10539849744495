import { AxiosResponse } from 'axios';
import { ClonePageAtomServiceToThemeAtomService } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { areUndefined } from 'services/ShopifyConnection/utils';
import { BE_ThemeAtom } from 'services/ThemeService/Atom/types';
import { i18n } from 'translation';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface MigratePageAtomToService {
  /** CommandId của page cần migrate từ Page Atom Service sang Theme Atom Service */
  pageAtomCommandId: string;
  eventId: string;
  eventType: ClonePageAtomServiceToThemeAtomService['eventType'];
}

interface ResponseSuccess {
  message: string;
  info: BE_ThemeAtom;
}

/** API được sử dụng để move các page atom sang DB của theme atom service -> Phục vụ cho chức năng mở modal mà chọn page để apply page đó vào theme ngoài dashboard */
export const migratePageAtomToService = async ({ pageAtomCommandId, eventId, eventType }: Partial<MigratePageAtomToService>) => {
  if (areUndefined(pageAtomCommandId, eventId, eventType)) {
    throw new Error(i18n.t('general.lack_of_params'));
  }
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${baseUrl}/imports/${pageAtomCommandId}`,
    method: 'PUT',
    params: {
      eventId,
      eventType,
    },
  });
  return response.data;
};
