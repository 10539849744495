import { RequestThemeSetting } from 'containers/Admin/ThemeBuilder/ThemeSettings/sliceThemeSettingsDashboard';
import { BE_ThemeAtom } from 'services/ThemeService/Atom/types';
import { AdminTheme, ClientTheme } from 'types/Theme';
import { renewVersion } from 'utils/CacheControl/VedaClientCacheControl';
import {
  handleGlobalTranslationsNColorSchemesInThemeAtom,
  handleGlobalTranslationsNColorSchemesInThemeClient,
} from '../Adapter/handleGlobalTranslationsNColorSchemes';
import { themeApis } from '../apis';
import { BE_ThemeClient } from '../VedaApplication/types';

export const updateThemeSettingsClient = async ({
  commandId,
  themeSettings,
}: {
  commandId: string;
  themeSettings: Partial<RequestThemeSetting>;
}): Promise<ClientTheme> => {
  const response = await themeApis.vedaApplication.userApi.theme.updateClient({
    theme: { commandId, ...(themeSettings as BE_ThemeClient['themeSettings']) },
  });
  renewVersion();
  return handleGlobalTranslationsNColorSchemesInThemeClient(response.info);
};

export const updateThemeSettingsAdmin = async ({
  commandId,
  themeSettings,
}: {
  commandId: string;
  themeSettings: Partial<RequestThemeSetting>;
}): Promise<AdminTheme> => {
  const response = await themeApis.atom.adminApi.theme.updateAtom({
    theme: { commandId, ...(themeSettings as BE_ThemeAtom['themeSettings']) },
  });
  renewVersion();
  return handleGlobalTranslationsNColorSchemesInThemeAtom(response.info);
};
