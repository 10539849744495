import Breakpoints, { BreakpointsValue } from 'components/Breakpoints';
import FieldGroup from 'components/FieldGroup';
import SliderBeauty from 'components/SliderBeauty';
import { Device, useSetResponsive } from 'containers/BuilderPage/store/responsive/slice';
import setScrollTo from 'containers/IframePage/setScrollTo';
import withDebounce from 'hocs/withDebounce';
import { equals } from 'ramda';
import { FC, ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { responsiveSelector, sectionIdActiveSelector } from 'store/selectors';
import { Space, View } from 'wiloke-react-core';

export type ResponsiveValue = Record<BreakpointsValue, number>;

export interface ResponsiveProps {
  label?: string;
  summary?: string;
  value: Partial<ResponsiveValue>;
  min?: number;
  max?: number;
  onChange?: (value: ResponsiveValue) => void;
  AfterLabel?: ReactNode;
}

const SliderBeautyDebounce = withDebounce(SliderBeauty, 'value', 'onValueChange', 300);

const defaultResult: ResponsiveValue = { lg: 1, md: 1, sm: 1, xs: 1 };

const deviceMappingToBreakpoint: Record<Device, BreakpointsValue> = {
  desktop: 'lg',
  'tablet-h': 'md',
  tablet: 'sm',
  mobile: 'xs',
};
const breakpointMappingToDevice: Record<BreakpointsValue, Device> = {
  lg: 'desktop',
  md: 'tablet-h',
  sm: 'tablet',
  xs: 'mobile',
};

const Responsive: FC<ResponsiveProps> = ({ label, summary, value, min = 1, max = 12, onChange, AfterLabel = null }) => {
  const responsive = useSelector(responsiveSelector);
  const sectionIdActive = useSelector(sectionIdActiveSelector);
  const setResponsive = useSetResponsive();

  const [breakpoint, setBreakpoint] = useState<BreakpointsValue>(deviceMappingToBreakpoint[responsive]);
  const [result, setResult] = useState<ResponsiveValue>({ ...defaultResult, ...value });

  useDeepCompareEffect(() => {
    // @tuong -> Ngăn việc chạy onChange ngay khi component mount
    if (!equals(result, value)) {
      onChange?.(result);
    }
  }, [result]);

  useDeepCompareEffect(() => {
    setBreakpoint(deviceMappingToBreakpoint[responsive]);
  }, [responsive]);

  const renderContent = (
    <View>
      <Breakpoints
        value={breakpoint}
        onChange={value => {
          setBreakpoint(value);
          setResponsive(breakpointMappingToDevice[value]);
          if (sectionIdActive) {
            setScrollTo(`[data-id="${sectionIdActive}"]`, { timeout: 200 });
          }
        }}
      />
      <Space size={10} />
      <SliderBeautyDebounce
        value={result?.[breakpoint] ?? 0}
        min={min}
        max={max}
        step={1}
        borderColor="gray3"
        borderInputColor="gray3"
        onValueChange={value => {
          setResult(state => ({ ...state, [breakpoint]: value }));
        }}
      />
    </View>
  );

  if (!label) {
    return renderContent;
  }

  return (
    <FieldGroup visible label={label} summary={summary} AfterLabel={AfterLabel}>
      {renderContent}
    </FieldGroup>
  );
};

export default Responsive;
