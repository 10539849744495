import { AxiosError } from 'axios';
import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { updateCategoryOfPageProductRequest } from 'services/PageService/Logic/updateCategoryOfPageProduct';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { updateCategoryOfPageProduct } from '../actions/actionPagesProduct';

function* handleUpdate({ payload: { commandId, description, name, belongsToPageTypes } }: ReturnType<typeof updateCategoryOfPageProduct.request>) {
  try {
    const response: SagaReturnType<typeof updateCategoryOfPageProductRequest> = yield retry(3, 1000, updateCategoryOfPageProductRequest, {
      commandId,
      description,
      name,
      belongsToPageTypes,
    });
    notifyAxiosHandler.handleSuccess(response.message);
    yield put(
      updateCategoryOfPageProduct.success({
        commandId: response.info.commandId,
        description: response.info.description,
        name: response.info.name,
        belongsToPageTypes: response.info.belongsToPageTypes,
      }),
    );
  } catch (error) {
    notifyAxiosHandler.handleError(error as AxiosError<ErrorData> | Error);
    yield put(updateCategoryOfPageProduct.failure({ commandId }));
  }
}

export function* watchUpdateCategoryOfPageProduct() {
  yield takeLatest(getActionType(updateCategoryOfPageProduct.request), handleUpdate);
}
