import { css } from 'wiloke-react-core';

export const rightItem = () => css`
  padding: 4px 6px;
  font-size: 12px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
`;

export const badge = css`
  padding: 2px 4px;
  font-size: 14px;
  border-radius: 3px;
  margin-bottom: 2px;
`;
