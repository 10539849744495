import { SectionCategoryTag } from 'types/Sections';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';

export const getCategoriesOfPageProduct = async (belongsToPageTypes?: string | undefined) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const response = await pageApis.vedaApplication.adminApi.category.getCategories({ cacheVersion: lastCache, belongsToPageTypes });

    const mapResponse = response.info.map<SectionCategoryTag>(item => {
      return {
        name: item.name,
        description: item.description ? item.description : item.name,
        commandId: item.commandId,
        belongsToPageTypes: item.belongsToPageTypes,
        count: item.count,
      };
    });

    return mapResponse;
  }
  const response = await pageApis.vedaApplication.userApi.category.getCategories({ cacheVersion: lastCache, belongsToPageTypes });
  const mapResponse = response.info.map<SectionCategoryTag>(item => {
    return {
      name: item.name,
      description: item.description ? item.description : item.name,
      commandId: item.commandId,
      belongsToPageTypes: item.belongsToPageTypes,
      count: item.count,
    };
  });

  return mapResponse;
};
