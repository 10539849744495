import { AsyncComponent, Button, VedaLoadingItem } from '@wiloke/ui';
import FieldBox from 'components/FieldBox';
import {
  useGetSavedSections,
  useInstallFavoriteSection,
  useLoadMoreSavedSections,
  useRemoveSavedSection,
} from 'containers/ChooseTemplate/store/actions';
import { isEmpty } from 'ramda';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { sectionsSelector } from 'store/selectors';
import { i18n } from 'translation';
import { GridSmart, View, ViewportTracking } from 'wiloke-react-core';

export const SavedContent = () => {
  const {
    data: savedSections,
    getStatus: getSavedSectionStatus,
    deleteStatus,
    installSectionStatus,
    categorySlug,
    hasNextPage,
    loadMoreStatus,
  } = useSelector(sectionsSelector.savedSections);

  const getSavedSections = useGetSavedSections();
  const removeSavedSections = useRemoveSavedSection();
  const installFavoriteSection = useInstallFavoriteSection();
  const loadMoreSections = useLoadMoreSavedSections();

  useEffect(() => {
    getSavedSections.request({ categories: [categorySlug === 'All' ? '' : categorySlug] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySlug]);

  const TrackingLoadMore = useMemo(() => {
    const lastCursor = savedSections.at(-1)?.commandId;
    if (lastCursor && hasNextPage && getSavedSectionStatus === 'success') {
      return (
        <ViewportTracking
          offsetTop={-200}
          onEnterViewport={() => {
            if (loadMoreStatus !== 'loading') {
              loadMoreSections.request({ categories: [categorySlug === 'All' ? '' : categorySlug], cursor: lastCursor });
            }
          }}
        >
          <View css={{ padding: '30px 10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <VedaLoadingItem />
          </View>
        </ViewportTracking>
      );
    }

    return null;
  }, [categorySlug, hasNextPage, loadMoreSections, loadMoreStatus, savedSections, getSavedSectionStatus]);

  const _renderSuccess = () => {
    return (
      <View>
        <GridSmart columnWidth={200} columnCount={2}>
          {savedSections.map(item => (
            <FieldBox
              key={item.commandId}
              css={{
                padding: '8px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <View>{item.label}</View>
              <View css={{ display: 'flex', columnGap: '5px' }}>
                <Button
                  size="extra-small"
                  radius={4}
                  loading={installSectionStatus[item.parentCommandId] === 'loading'}
                  onClick={() => {
                    installFavoriteSection.request({
                      parentCommandId: item.parentCommandId,
                      productSection: item,
                    });
                  }}
                >
                  {i18n.t('general.install')}
                </Button>{' '}
                <Button
                  backgroundColor="danger"
                  size="extra-small"
                  radius={4}
                  loading={deleteStatus[item.commandId ?? ''] === 'loading'}
                  onClick={() => {
                    removeSavedSections.request({ id: item.commandId ?? '' });
                  }}
                >
                  {i18n.t('general.delete')}
                </Button>
              </View>
            </FieldBox>
          ))}
        </GridSmart>
        {TrackingLoadMore}
      </View>
    );
  };

  return (
    <View css={{ padding: '10px' }}>
      <AsyncComponent isEmpty={isEmpty(savedSections)} Success={_renderSuccess()} status={getSavedSectionStatus} />
    </View>
  );
};
