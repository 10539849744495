import Field from 'components/Field';
import SelectAntd from 'components/SelectAntd';
import TextInput from 'components/TextInput';
import withDebounce from 'hocs/withDebounce';
import { View } from 'wiloke-react-core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { themeTrashSelector, useSetSearchKeyPageTrashTheme, useSetSearchKeyTrashTheme } from './store/reducers/sliceTrashTheme';
import { ThemesTrash } from './components/ThemesNPagesTrash/ThemestTrash';
import { PageOfTrashThemePageOfTrashTheme } from './components/ThemesNPagesTrash/PageOfTrashTheme';

const InputDebounce = withDebounce(TextInput, 'value', 'onValueChange', 500);

// Todo: i18n
export const ThemeTrash = () => {
  const {
    page: { searchKey: pageOfThemeSearchKey },
    theme: { searchKey: themeSearchKey },
  } = useSelector(themeTrashSelector);

  const setSearchKeyPageTrashTheme = useSetSearchKeyPageTrashTheme();
  const setSearchKeyTrashTheme = useSetSearchKeyTrashTheme();

  const [filterType, setFilterType] = useState('theme');

  return (
    <View>
      <View row>
        <Field columns={[3]} label="Theme/Pages of Theme">
          <SelectAntd
            value={filterType}
            onChange={val => setFilterType(val)}
            data={[
              { label: 'Theme', value: 'theme' },
              { label: 'Pages of theme', value: 'page' },
            ]}
          />
        </Field>
        <Field columns={[3]} label="Search">
          {filterType === 'theme' ? (
            <InputDebounce block onValueChange={setSearchKeyTrashTheme} value={themeSearchKey} />
          ) : (
            <InputDebounce block onValueChange={setSearchKeyPageTrashTheme} value={pageOfThemeSearchKey} />
          )}
        </Field>
      </View>

      {filterType === 'theme' && <ThemesTrash />}
      {filterType === 'page' && <PageOfTrashThemePageOfTrashTheme />}
    </View>
  );
};
