import { AxiosResponse } from 'axios';
import { PageType } from 'types/Page';
import fetchAPI from 'utils/functions/fetchAPI';
import { getApiTracking } from 'utils/functions/getApiTracking';
import { clientBaseUrl } from '../const';

interface ResponseSuccess {
  message: string;
  info: Record<PageType, number>;
}
interface GetCoutner {
  cacheVersion: number | null;
}
export const getCoutner = async ({ cacheVersion }: GetCoutner) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: getApiTracking({ defaultBaseUrl: `${clientBaseUrl}/counter`, trackingEndpoint: `pages/counter` }),
    params: {
      cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
    },
  });

  return response.data;
};
