import { AdminPageDatabase } from 'services/ResultService/atomTypes';
import { AdminTheme } from 'types/Theme';
import { createAsyncAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';

export const getTrashThemes = createAsyncAction([
  '@ThemeManager/getTrashThemes/request',
  '@ThemeManager/getTrashThemes/success',
  '@ThemeManager/getTrashThemes/failure',
])<{ searchKey: string }, { data: AdminTheme[]; hasNextPage: boolean }, undefined>();

export const loadMoreTrashThemes = createAsyncAction([
  '@ThemeManager/loadMoreTrashThemes/request',
  '@ThemeManager/loadMoreTrashThemes/success',
  '@ThemeManager/loadMoreTrashThemes/failure',
])<{ searchKey: string; cursor: string }, { data: AdminTheme[]; hasNextPage: boolean }, undefined>();

export const restoreTrashTheme = createAsyncAction([
  '@ThemeManager/restoreTrashTheme/request',
  '@ThemeManager/restoreTrashTheme/success',
  '@ThemeManager/restoreTrashTheme/failure',
])<{ commandId: string }, { commandId: string }, { commandId: string }>();

export const getTrashPagesOfTheme = createAsyncAction([
  '@ThemeManager/getTrashPagesOfTheme/request',
  '@ThemeManager/getTrashPagesOfTheme/success',
  '@ThemeManager/getTrashPagesOfTheme/failure',
])<{ searchKey: string }, { data: AdminPageDatabase[]; hasNextPage: boolean }, undefined>();

export const loadMoreTrashPagesOfTheme = createAsyncAction([
  '@ThemeManager/loadMoreTrashPagesOfTheme/request',
  '@ThemeManager/loadMoreTrashPagesOfTheme/success',
  '@ThemeManager/loadMoreTrashPagesOfTheme/failure',
])<{ searchKey: string; cursor: string }, { data: AdminPageDatabase[]; hasNextPage: boolean }, undefined>();

export const restorePageTrashTheme = createAsyncAction([
  '@ThemeManager/restorePageTrashTheme/request',
  '@ThemeManager/restorePageTrashTheme/success',
  '@ThemeManager/restorePageTrashTheme/failure',
])<{ commandId: string }, { commandId: string }, { commandId: string }>();

export const useGetTrashThemes = createDispatchAsyncAction(getTrashThemes);
export const useLoadMoreTrashThemes = createDispatchAsyncAction(loadMoreTrashThemes);
export const useRestoreTrashTheme = createDispatchAsyncAction(restoreTrashTheme);

export const useGetTrashPagesOfTheme = createDispatchAsyncAction(getTrashPagesOfTheme);
export const useLoadMoreTrashPagesOfTheme = createDispatchAsyncAction(loadMoreTrashPagesOfTheme);
export const useRestorePageOfTrashTheme = createDispatchAsyncAction(restorePageTrashTheme);
