import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { getDefaultLanguage } from 'services/UserService/Logic/getDefaultLang';
import { importDefaultLanguage } from 'store/global/globalTranslation/actions';
import { getActionType } from 'wiloke-react-core/utils';

function* handleImportLang({ payload: { fileName, defaultFileContent, onFailed, onFulfill } }: ReturnType<typeof importDefaultLanguage.request>) {
  try {
    const response: SagaReturnType<typeof getDefaultLanguage> = yield retry(3, 1000, getDefaultLanguage, fileName);

    yield put(importDefaultLanguage.success({ fileName, fileContent: response.info }));
    onFulfill?.(response.info);
  } catch (error) {
    yield put(importDefaultLanguage.failure({ fileName, defaultFileContent }));
    onFailed?.();
  }
}

export function* watchImportDefaultLanguage() {
  yield takeLatest(getActionType(importDefaultLanguage.request), handleImportLang);
}
