import { ImportThemeAtomToClientServiceNPageInThemeClientMessage } from 'hooks/useSocket/useSocketForImportThemeAtomToClientServiceNPageInThemeClient';
import { ActionTypes, createReducer, handleAction } from 'wiloke-react-core/utils';
import {
  resetAllState,
  setEventIdOfImportThemeAtomToClientServiceNPageInThemeClient,
  setStreamSocketOfImportThemeAtomToClientServiceNPageInThemeClient,
} from './actions';

interface StreamData {
  status: SyncStatus;
  socketData: ImportThemeAtomToClientServiceNPageInThemeClientMessage | undefined;
}

const defaultStreamData: StreamData = {
  status: 'idle',
  socketData: undefined,
};

interface State {
  eventId: string | undefined;
  // @tuong -> "streams" dùng để lắng nghe socket hoàn thành cũng như chứa dữ liệu mà socket trả về
  streams: Record<ImportThemeAtomToClientServiceNPageInThemeClientMessage['eventType'], StreamData>;
}
type Actions = ActionTypes<
  | typeof setEventIdOfImportThemeAtomToClientServiceNPageInThemeClient
  | typeof setStreamSocketOfImportThemeAtomToClientServiceNPageInThemeClient
  | typeof resetAllState
>;

export const initialState: State = {
  eventId: undefined,
  streams: {
    'Import theme atom -> client service ngoài dashboard': defaultStreamData,
    'Import page của theme': defaultStreamData,
  },
};

export const reducerImportThemeAtomToClientServiceNPageInThemeClient = createReducer<State, Actions>(initialState, [
  handleAction('@importThemeAtomToClientServiceNPageInThemeClient/resetAllState', () => initialState),
  handleAction(
    '@importThemeAtomToClientServiceNPageInThemeClient/setEventIdOfSyncShopifyNClonePageAtomServiceToThemeAtomService',
    ({ state, action }) => {
      return {
        ...state,
        eventId: action.payload,
      };
    },
  ),
  handleAction(
    '@importThemeAtomToClientServiceNPageInThemeClient/setStreamSocketOfImportThemeAtomToClientServiceNPageInThemeClient',
    ({ state, action }) => {
      const { eventType, socketData, status } = action.payload;
      return {
        ...state,
        streams: {
          ...state.streams,
          [eventType]: {
            status: status as SyncStatus,
            socketData,
          },
        },
      };
    },
  ),
]);
