export const time = {
  yearAgo: 'Il y a %% s %% an',
  yearsAgo: 'Il y a %% s %% ans',
  monthAgo: 'Il y a %% s %% mois',
  monthsAgo: 'Il y a %% s %% mois',
  weekAgo: 'Il y a %% s %% semaine',
  weeksAgo: 'Il y a %% s %% semaines',
  dayAgo: 'Il y a %% s %% jour',
  daysAgo: 'Il y a %% s %% jours',
  hourAgo: 'Il y a %% s %% heure',
  hoursAgo: 'Il y a %% s %% heures',
  minuteAgo: 'Il y a %% s %% minute',
  minutesAgo: 'Il y a %% s %% minutes',
  justNow: "À l'instant",
  now: 'Maintenant',
} as const;
