import { all, put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { getChangelogsOfAtom } from 'services/SectionService/Logic/Changelogs';
import { getImportSectionVersion } from 'store/actions/versions/actionSectionVersion';
import { getActionType } from 'wiloke-react-core/utils';

function* handleGetVersion({ payload: { sectionCommandId } }: ReturnType<typeof getImportSectionVersion.request>) {
  try {
    const response: SagaReturnType<typeof getChangelogsOfAtom> = yield retry(3, 1000, getChangelogsOfAtom, sectionCommandId);
    yield all(response.map(({ atomCommandId, version }) => put(getImportSectionVersion.success({ sectionCommandId: atomCommandId, data: version }))));
  } catch (error) {
    yield put(getImportSectionVersion.failure({ sectionCommandId }));
  }
}

export function* watchGetImportSectionVersion() {
  yield takeLatest(getActionType(getImportSectionVersion.request), handleGetVersion);
}
