import { SaveForBuilderType } from 'containers/BuilderPage/store/saveForBuilder/actions';
import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { pageSectionsSelector, sectionIdCodeVisibleSelector } from 'store/selectors';
import { PreviewImage } from 'types/Page';
import { AdminSection } from 'types/Sections';

interface Actions {
  type: 'setInfoMegaMenu';
  payload: Partial<State>;
}

interface State {
  methodType: SaveForBuilderType;
  label: string;
  image: PreviewImage;
  changelog: string;
  version: string;
}

const defaultState: State = {
  methodType: 'create',
  image: { src: '', width: 0, height: 0 },
  label: '',
  changelog: '',
  version: '',
};

const myReducer = (state: State, action: Actions) => {
  switch (action.type) {
    case 'setInfoMegaMenu': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export const useSaveInfoMegaMenu = () => {
  const sectionIdCodeVisible = useSelector(sectionIdCodeVisibleSelector);

  const sections = useSelector(pageSectionsSelector);
  const section = sections.find(section => section.id === sectionIdCodeVisible) as AdminSection | undefined;

  const [state, dispatch] = useReducer(myReducer, {
    ...defaultState,
    label: section?.label ?? defaultState.label,
    version: section?.currentVersion ?? defaultState.version,
    image: section?.image ?? defaultState.image,
  });

  const { changelog, image, label, methodType, version } = state;

  useEffect(() => {
    if (!!section) {
      dispatch({
        type: 'setInfoMegaMenu',
        payload: {
          label: section.label,
        },
      });

      if (section.image) {
        dispatch({
          type: 'setInfoMegaMenu',
          payload: {
            image: section.image,
          },
        });
      }

      if (section?.currentVersion) {
        dispatch({
          type: 'setInfoMegaMenu',
          payload: {
            version: section.currentVersion,
          },
        });
      }
    }
  }, [section]);

  return {
    changelog,
    image,
    label,
    methodType,
    version,
    section,
    dispatch,
  };
};
