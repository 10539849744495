import { imageUrl } from '@wiloke/functions';
import { AsyncComponent } from '@wiloke/ui';
import ImageTextCard from 'components/ImageTextCard';
import VirtualList from 'components/VirtualList/VirtualList';
import { ModalCreateArticle, ModalCreateBlog, ModalCreateCollection, ModalCreateNormalPage, ModalCreateProduct } from 'containers/Admin/Modals';
import { useChangeModalAdminSettings } from 'containers/Admin/Modals/store';
import { modalsSelector } from 'containers/Admin/selector';
import { isEmpty } from 'ramda';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BE_PageProduct } from 'services/PageService/VedaApplication/types';
import { pageBuilderSelector } from 'store/selectors';
import { isPageBuilder, isThemeBuilder } from 'utils/validateBuilderMode';
import { ActivityIndicator, css, GridSmart, View, ViewportTracking } from 'wiloke-react-core';
import { useDeleteFavoritePage, useGetMyTemplates, useLoadMoreMyPageTemplates } from '../reducers/sliceTemplatePage';
import * as styles from '../styles';

const ROW_HEIGHT = 410;
const COLUMN_GAP = 20;
const COLUMN_COUNT = 5;

const isInBuilderCss = css`
  .ReactVirtualized__Grid {
    overflow-x: hidden !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
`;

export const MyTemplates = () => {
  const {
    myTemplates: { data, getStatus, loadMoreStatus, hasNextPage, deleteStatus, searchKey },
    tabKey,
  } = useSelector(pageBuilderSelector.templates);

  const { createNormalPage, createArticle, createCollection, createProduct, createBlog } = useSelector(modalsSelector);

  const getMyTemplates = useGetMyTemplates();
  const loadMoreTemplates = useLoadMoreMyPageTemplates();
  const deleteFavoritePage = useDeleteFavoritePage();
  const changeModalAdminSettings = useChangeModalAdminSettings();

  const [currentPage, setCurrentPage] = useState<BE_PageProduct | undefined>(undefined);
  const isInBuilder = isPageBuilder() || isThemeBuilder();

  useEffect(() => {
    if (tabKey === 'my-template') {
      getMyTemplates.request({ limit: 20, searchKey });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKey, searchKey]);

  const openModal = (page: BE_PageProduct) => () => {
    setCurrentPage(page);
    if (page.type === 'article') {
      changeModalAdminSettings({ createArticle: true });
    } else if (page.type === 'product') {
      changeModalAdminSettings({ createProduct: true });
    } else if (page.type === 'collection') {
      changeModalAdminSettings({ createCollection: true });
    } else if (page.type === 'blog') {
      changeModalAdminSettings({ createBlog: true });
    } else {
      changeModalAdminSettings({ createNormalPage: true });
    }
  };

  const renderLoading = (
    <GridSmart columnWidth={250} columnGap={20} columnCount={5}>
      {[0, 1, 2, 3, 4].map(item => (
        <ImageTextCard.Loading key={item} height={350} />
      ))}
    </GridSmart>
  );

  const renderSuccess = () => {
    const rowCount = Math.ceil(data.length / COLUMN_COUNT);

    return (
      <VirtualList
        disabledScrollStyle={false}
        rowCount={rowCount}
        rowHeight={ROW_HEIGHT}
        containerCss={isInBuilderCss}
        rowRender={index => {
          const dataSlice = data.slice(index * COLUMN_COUNT, index * COLUMN_COUNT + COLUMN_COUNT);
          const rowItem = dataSlice.length ? dataSlice : hasNextPage ? Array(COLUMN_COUNT).fill(undefined) : [];

          return (
            <GridSmart columnWidth={250} columnGap={COLUMN_GAP} columnCount={COLUMN_COUNT}>
              {rowItem.map(page => {
                const { label, image, type, saved, commandId } = page;

                if (typeof page === 'undefined' && loadMoreStatus !== 'failure') {
                  return (
                    <ViewportTracking
                      key={index}
                      offsetTop={-100}
                      onEnterViewport={() => {
                        if (getStatus === 'success' && loadMoreStatus !== 'loading') {
                          loadMoreTemplates.request({ tabKey, searchKey });
                        }
                      }}
                    >
                      <View css={styles.loadMoreContainer}>
                        <ActivityIndicator />
                      </View>
                    </ViewportTracking>
                  );
                }
                return (
                  <ImageTextCard.Style2
                    disabledIconSave
                    key={commandId}
                    label={label}
                    description={type || ''}
                    src={image ? imageUrl(image.src, 280) : ''}
                    widthImage={image ? image.width : 0}
                    heightImage={image ? image.height : 0}
                    saved={saved}
                    onAdd={openModal(page)}
                    loading={deleteStatus[commandId] === 'loading'}
                    onDelete={() => {
                      deleteFavoritePage.request({ commandId });
                    }}
                  />
                );
              })}
            </GridSmart>
          );
        }}
      />
    );
  };

  return (
    <View css={{ height: isInBuilder ? '400px !important' : 'calc(100% - 150px) !important' }}>
      <AsyncComponent status={getStatus} Request={renderLoading} isEmpty={isEmpty(data)} Success={renderSuccess()} />

      {currentPage && createNormalPage && (
        <ModalCreateNormalPage forceActiveDrawer={true} pageInput={currentPage as any} pageType={currentPage.type} />
      )}

      {currentPage && currentPage.type === 'article' && createArticle && (
        <ModalCreateArticle forceActiveDrawer={true} pageInput={currentPage as any} />
      )}
      {currentPage && currentPage.type === 'blog' && createBlog && <ModalCreateBlog forceActiveDrawer={true} pageInput={currentPage as any} />}

      {currentPage && currentPage.type === 'collection' && createCollection && (
        <ModalCreateCollection forceActiveDrawer={true} pageInput={currentPage as any} />
      )}
      {currentPage && currentPage.type === 'product' && createProduct && (
        <ModalCreateProduct forceActiveDrawer={true} pageInput={currentPage as any} />
      )}
    </View>
  );
};
