import { MyModal } from '@wiloke/ui';
import { FC, useEffect, useState } from 'react';
import { BE_PageInThemeAtomNDraft } from 'services/ThemeService/Atom/types';
import { AddonOfTheme_Atom_N_Client } from 'types/Addons';
import { PageSection } from 'types/Sections';
import { v4 } from 'uuid';
import { View } from 'wiloke-react-core';

export interface AddonIssue {
  type: 'addon';
  data: Array<{
    addonExisted: AddonOfTheme_Atom_N_Client;
    addonError: AddonOfTheme_Atom_N_Client;
  }>;
}

export interface SectionOfPageIssue {
  type: 'page';
  pageError: BE_PageInThemeAtomNDraft;
  data: Array<{
    sectionExisted: PageSection;
    sectionError: PageSection;
  }>;
}

export interface HeaderFooterOfThemeIssue {
  type: 'theme';
  data: Array<{
    sectionExisted: PageSection;
    sectionError: PageSection;
  }>;
}

type Issue = AddonIssue | SectionOfPageIssue | HeaderFooterOfThemeIssue;
type SetError = (issue: Issue) => void;
interface Static {
  setError: null | SetError;
}

let handler: Static['setError'] = null;

export const ModalWarningDuplicateCommandId: FC & Static = () => {
  const [errors, setErrors] = useState<Array<Issue & { id: string }>>([]);

  const handlePushError = (issue: Issue) => {
    return setErrors(state => state.concat({ ...issue, id: v4() }));
  };

  const handleCloseError = (issue: typeof errors[number]) => {
    setErrors(state => state.filter(error => error.id !== issue.id));
  };

  useEffect(() => {
    handler = handlePushError;
    return () => {
      handler = null;
    };
  }, []);

  const renderListItemError = (issue: typeof errors[number]) => {
    if (issue.type === 'addon') {
      return (
        <View key={issue.id} tagName="ul">
          {issue.data.map(({ addonError, addonExisted }, index) => {
            return (
              <View key={index} tagName="li">
                <View>
                  Addon lỗi: "{addonError.label}" có ID "{addonError.commandId}"
                </View>
                <View>
                  Addon đã tồn tại: "{addonExisted.label}" có ID "{addonExisted.commandId}"
                </View>
              </View>
            );
          })}
        </View>
      );
    }
    if (issue.type === 'page') {
      return (
        <View key={issue.id} tagName="ul">
          {issue.data.map(({ sectionError, sectionExisted }, index) => {
            return (
              <View key={index} tagName="li">
                <View>
                  Section lỗi: "{sectionError.label}" có ID "{sectionError.commandId}"
                </View>
                <View>
                  Section đã tồn tại: "{sectionExisted.label}" có ID "{sectionExisted.commandId}"
                </View>
              </View>
            );
          })}
        </View>
      );
    }
    if (issue.type === 'theme') {
      return (
        <View key={issue.id} tagName="ul">
          {issue.data.map(({ sectionError, sectionExisted }, index) => {
            return (
              <View key={index} tagName="li">
                <View>
                  Section lỗi: "{sectionError.label}" có ID "{sectionError.commandId}"
                </View>
                <View>
                  Section đã tồn tại: "{sectionExisted.label}" có ID "{sectionExisted.commandId}"
                </View>
              </View>
            );
          })}
        </View>
      );
    }
  };

  return (
    <>
      {errors.map(error => {
        const message =
          error.type === 'addon'
            ? 'Xuất hiện nhiều hơn 2 addons giống nhau (giống ID)'
            : error.type === 'page'
            ? `Xuất hiện nhiều hơn 2 sections thuộc page "${error.pageError.label}" giống nhau (giống ID)`
            : error.type === 'theme'
            ? `Xuất hiện nhiều hơn 2 section header footer thuộc theme giống nhau (giống ID)`
            : '';
        return (
          <MyModal
            isVisible
            headerText="Cảnh báo! Có lỗi trong hệ thống"
            onCancel={() => handleCloseError(error)}
            onOk={() => {
              handleCloseError(error);
            }}
          >
            <View css={{ fontSize: '16px', textAlign: 'center', fontWeight: 500, marginBottom: '12px' }}>
              Phát hiện có lỗi trong hệ thống trong quá trình trích xuất kết quả
            </View>
            <View color="primary" css={{ fontSize: '16px', fontWeight: 500, marginBottom: '8px' }}>
              Chụp hình modal này lại và gửi vào nhóm để báo bug
            </View>
            <View>
              Chi tiết:{' '}
              <View color="primary" tagName="span">
                {message}
              </View>
              {renderListItemError(error)}
            </View>
          </MyModal>
        );
      })}
    </>
  );
};
ModalWarningDuplicateCommandId.setError = issue => {
  handler?.(issue);
};
