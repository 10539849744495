import { useCountDown } from '@wiloke/hooks';
import maintain from 'assets/maintain.svg';
import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Space, View } from 'wiloke-react-core';

export const Maintenance: FC = () => {
  // const { siteStatus } = useSelector(authSelector);
  const endDate = 0;
  const { days, hours, minutes, seconds, isExpired } = useCountDown({ endDate });
  const history = useHistory();

  useEffect(() => {
    if (isExpired) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpired]);

  return (
    <View
      css={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        zIndex: 99999999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <img src={maintain} />

      <View tagName="h1">{'Hang on! We are under maintenance'}</View>
      <Space size={4} />

      <View tagName="p" fontFamily="secondary" css={{ fontSize: '18px' }}>
        {'It will not take a long time till we get the error fixed. We will live again in'}
      </View>
      <Space size={4} />

      {endDate !== 0 && (
        <View tagName="h2" color="primary">
          {days} : {hours} : {minutes} : {seconds}
        </View>
      )}
    </View>
  );
};
