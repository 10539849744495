import { AsyncComponent, Button, ScreenProps, useStackNavigator } from '@wiloke/ui';
import Field from 'components/Field';
import PageCard from 'components/PageCard';
import SelectAntd from 'components/SelectAntd';
import VirtualList from 'components/VirtualList/VirtualList';
import { range } from 'ramda';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetMetafields, useLoadmoreMetafields } from 'store/actions/shopify/actionMetafields';
import { shopifySelector } from 'store/selectors';
import { i18n } from 'translation';
import { OwnerType } from 'types/Metafields';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { ActivityIndicator, FontAwesome, Theme, View, ViewportTracking, css } from 'wiloke-react-core';
import DefaultScreen from '../components/DefaultScreen';
import { LeftBarParamList } from '../components/SidebarScreen/SidebarScreen';

const ROW_HEIGHT = 57;
const NUMBER_OF_COLUMNS = 1;

const styles = {
  linkStyle: ({ colors }: Theme) => css`
    padding: 10px;
    display: inline-block;
    display: flex;
    align-items: center;
    height: 46px;
    background-color: ${colors.light};
    border-radius: 4px;
    justify-content: space-between;
    margin-bottom: 5px;
    flex: 1;
  `,
  iconStyle: css`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  `,
  iconReload: css`
    width: 46px;
    height: 46px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `,
};

const mefafields_data = [
  {
    label: 'Product',
    value: 'PRODUCT',
  },
  {
    label: 'Product Variant',
    value: 'PRODUCTVARIANT',
  },
  {
    label: 'Collection',
    value: 'COLLECTION',
  },
  {
    label: 'Article',
    value: 'ARTICLE',
  },
  {
    label: 'Blog',
    value: 'BLOG',
  },
  {
    label: 'Shop',
    value: 'SHOP',
  },
  {
    label: 'Page',
    value: 'PAGE',
  },
];

export const MetaFieldScreen: FC<ScreenProps<LeftBarParamList, 'metaFieldScreen'>> = ({ params }) => {
  const { label, onChange } = params;
  const { data, hasNextPage, statusRequest, statusLoadmore } = useSelector(shopifySelector.metafields);
  const getMegaField = useGetMetafields();
  const loadMoreMetaFields = useLoadmoreMetafields();
  const navigation = useStackNavigator<LeftBarParamList>();
  const { shopName } = getUserInfo();
  const [metaFieldType, setMetaFieldType] = useState<OwnerType>('PRODUCT');

  useEffect(() => {
    getMegaField.request({ ownerType: metaFieldType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaFieldType]);

  const renderLoading = () => {
    return range(1, 5).map(num => <PageCard.Loading key={num} />);
  };

  const reloadData = useCallback(() => {
    getMegaField.request({ ownerType: metaFieldType });
  }, [getMegaField, metaFieldType]);

  const renderMetaFields = () => {
    const rowCount = Math.ceil(data.length / NUMBER_OF_COLUMNS) + (hasNextPage ? NUMBER_OF_COLUMNS : 1);

    return (
      <View css={{ height: '100%' }}>
        <VirtualList
          rowHeight={ROW_HEIGHT}
          rowCount={rowCount}
          containerCss={{ height: 'calc(100% - 80px)' }}
          disabledScrollStyle={false}
          rowRender={index => {
            const dataSlice = data.slice(index * NUMBER_OF_COLUMNS, index * NUMBER_OF_COLUMNS + NUMBER_OF_COLUMNS);
            const rowItem = dataSlice.length ? dataSlice : hasNextPage ? Array(NUMBER_OF_COLUMNS).fill(undefined) : [];

            return rowItem.map((item, index) => {
              if (statusLoadmore === 'failure' && typeof item === 'undefined' && index % NUMBER_OF_COLUMNS === 0) {
                return (
                  <Button
                    key={index}
                    onClick={() => {
                      getMegaField.request({ ownerType: metaFieldType });
                    }}
                    radius={8}
                    size="large"
                    block
                    css={{ width: '100%' }}
                  >
                    {i18n.t('builderPage.retry')}
                  </Button>
                );
              }
              if (typeof item === 'undefined' && statusLoadmore !== 'failure') {
                return (
                  <ViewportTracking
                    key={index}
                    offsetTop={-200}
                    onEnterViewport={() => {
                      if (statusLoadmore !== 'loading' && hasNextPage && index % NUMBER_OF_COLUMNS === 0) {
                        loadMoreMetaFields.request({ ownerType: metaFieldType });
                      }
                    }}
                  >
                    {renderLoading()}
                  </ViewportTracking>
                );
              }
              if (item) {
                return (
                  <PageCard.Style3
                    key={item.id}
                    title={item.name}
                    css={{ marginBottom: '5px' }}
                    onClick={() => {
                      const metaType = item.ownerType.toLocaleLowerCase();
                      const megaField = `<span>{{ ${metaType}.metafields.${item.namespace}.${item.key} | metafield_tag }}</span>`;
                      onChange(megaField);
                      navigation.goBack();
                    }}
                  />
                );
              }
              return null;
            });
          }}
        />
      </View>
    );
  };

  return (
    <DefaultScreen title={label} contentCss={{ height: '100%', overflow: 'hidden' }}>
      <Field label="Metafield's type">
        <SelectAntd
          data={mefafields_data}
          value={metaFieldType}
          onChange={val => {
            setMetaFieldType(val as OwnerType);
          }}
        />
      </Field>

      <View css={{ display: 'flex', columnGap: '5px' }}>
        <View
          tagName="a"
          href={`https://${shopName}/admin/metafields`}
          color="primary"
          colorHover="primary"
          target="_blank"
          fontFamily="secondary"
          css={styles.linkStyle}
        >
          <View css={{ flex: '1', display: 'flex', alignItems: 'center' }}>
            <View css={styles.iconStyle}>
              <FontAwesome size={20} type="far" name="plus-square" />
            </View>
            <View>{i18n.t('general.create', { text: 'metafields' })}</View>
          </View>

          <FontAwesome size={16} type="far" name="external-link" />
        </View>

        <View backgroundColor="light" css={styles.iconReload} onClick={reloadData}>
          {statusRequest === 'loading' ? <ActivityIndicator size={16} /> : <FontAwesome color="primary" size={16} type="far" name="sync-alt" />}
        </View>
      </View>

      <AsyncComponent status={statusRequest} Request={renderLoading()} isEmpty={data.length === 0} Success={renderMetaFields()} />
    </DefaultScreen>
  );
};
