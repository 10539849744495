import { updatePageSettings } from 'containers/Admin/PageBuilder/DashboardPageSettings';
import { AdminPageData, FilterTypePage } from 'containers/Admin/types';
import { ActionTypes, createDispatchAction, createDispatchAsyncAction, createSlice, handleAction } from 'wiloke-react-core/utils';
import {
  ChangeSearchKey,
  FilterPageType,
  IsSelectAll,
  SelectIds,
  actionCreateCustomerPage,
  actionDeleteCustomerPages,
  actionGetCustomerPages,
  actionLoadMoreCustomerPages,
  actionUpdateStatusCustomerPage,
  actionDuplicateCustomerPages,
} from '../actions';

type RecordStatus = Record<string, Status>;

type CustomerOrderActions = ChangeSearchKey | FilterPageType | IsSelectAll | SelectIds;

type CustomerOrderExtraActions = ActionTypes<
  | typeof actionCreateCustomerPage
  | typeof actionDeleteCustomerPages
  | typeof actionGetCustomerPages
  | typeof actionLoadMoreCustomerPages
  | typeof actionUpdateStatusCustomerPage
  | typeof updatePageSettings
  | typeof actionDuplicateCustomerPages
>;

interface StateCustomerOrder {
  ids: string[];
  data: AdminPageData[];
  getAllPageStatus: Status;
  loadMorePageStatus: Status;
  createPageStatus: Status;
  duplicateStatus: RecordStatus;
  updatePageStatus: RecordStatus;
  deletePending: string[];
  hasNextPage: boolean;
  filterType: FilterTypePage;
  isSelectAll: boolean;
  search: string;
}

export const sliceCustomerPages = createSlice<StateCustomerOrder, CustomerOrderActions, CustomerOrderExtraActions>({
  initialState: {
    data: [],
    ids: [],
    isSelectAll: false,
    createPageStatus: 'idle',
    duplicateStatus: {},
    deletePending: [],
    getAllPageStatus: 'idle',
    updatePageStatus: {},
    loadMorePageStatus: 'idle',
    filterType: 'all',
    search: '',
    hasNextPage: false,
  },
  name: '@CustomerPages',
  reducers: [
    handleAction('selectIds', ({ state, action }) => {
      const { ids } = action.payload;
      return {
        ...state,
        ids,
      };
    }),
    handleAction('filterPageType', ({ state, action }) => ({
      ...state,
      filterType: action.payload.pageType,
    })),
    handleAction('isSelectAll', ({ state, action }) => ({
      ...state,
      isSelectAll: action.payload.isSelectAll,
    })),
    handleAction('changSearchKey', ({ state, action }) => {
      state.search = action.payload.search;
    }),
  ],
  extraReducers: [
    handleAction('@CustomerPages/createPageRequest', ({ state }) => ({
      ...state,
      createPageStatus: 'loading',
    })),
    handleAction('@CustomerPages/createPageSuccess', ({ state }) => {
      return {
        ...state,
        createPageStatus: 'success',
      };
    }),
    handleAction('@CustomerPages/createPageFailure', ({ state }) => ({
      ...state,
      createPageStatus: 'failure',
    })),
    handleAction('@CustomerPages/updateStatusPageRequest', ({ state, action }) => ({
      ...state,
      updatePageStatus: {
        ...state.updatePageStatus,
        [action.payload.id]: 'loading',
      },
    })),
    handleAction('@CustomerPages/updateStatusPageSuccess', ({ state, action }) => {
      const { enable, id, justDisabledPages, modifiedDateTimestamp } = action.payload;

      return {
        ...state,
        updatePageStatus: {
          ...state.updatePageStatus,
          [id]: 'success',
        },
        data: state.data.map(item => {
          if (item.commandId === id) {
            return {
              ...item,
              enable: enable,
              modifiedDateTimestamp,
            };
          }
          if (Array.isArray(justDisabledPages) && justDisabledPages.includes(item.commandId)) {
            return {
              ...item,
              enable: false,
            };
          }
          return item;
        }),
      };
    }),
    handleAction('@CustomerPages/updateStatusPageFailure', ({ state, action }) => ({
      ...state,
      updatePageStatus: {
        ...state.updatePageStatus,
        [action.payload.id]: 'failure',
      },
    })),
    handleAction('@CustomerPages/deletePagesRequest', ({ state }) => ({
      ...state,
      deletePending: state.deletePending.concat(state.ids),
    })),
    handleAction('@CustomerPages/deletePagesSuccess', ({ state, action }) => {
      return {
        ...state,
        deletePending: state.deletePending.filter(id => !action.payload.ids.includes(id)),
        data: state.data.filter(item => !action.payload.ids.includes(item.commandId)),
        ids: [],
        isSelectAll: false,
      };
    }),
    handleAction('@CustomerPages/deletePagesFailure', ({ state, action }) => ({
      ...state,
      deletePending: state.deletePending.filter(id => id !== action.payload.id),
    })),
    handleAction('@CustomerPages/getPagesRequest', ({ state }) => ({
      ...state,
      getAllPageStatus: 'loading',
      isSelectAll: false,
      ids: [],
    })),
    handleAction('@CustomerPages/getPagesSuccess', ({ state, action }) => {
      const { data, hasNextPage } = action.payload;

      return {
        ...state,
        getAllPageStatus: 'success',
        data,
        hasNextPage,
      };
    }),
    handleAction('@CustomerPages/getPagesFailure', ({ state }) => ({
      ...state,
      getAllPageStatus: 'failure',
    })),

    handleAction('@CustomerPages/loadMoreCustomerPagesRequest', ({ state }) => {
      return {
        ...state,
        loadMorePageStatus: 'loading',
      };
    }),
    handleAction('@CustomerPages/loadMoreCustomerPagesSuccess', ({ state, action }) => {
      return {
        ...state,
        loadMorePageStatus: 'success',
        data: state.data.concat(action.payload.data),
        hasNextPage: action.payload.hasNextPage,
      };
    }),
    handleAction('@CustomerPages/loadMoreCustomerPagesFailure', ({ state }) => {
      return {
        ...state,
        loadMorePageStatus: 'failure',
      };
    }),
    handleAction('@Dashboard/updatePageSettings/success', ({ state, action }) => {
      const { commandId } = action.payload;
      return {
        ...state,
        data: state.data.map(item => {
          if (item.commandId === commandId) {
            return {
              ...item,
              ...action.payload,
            };
          }
          return item;
        }),
      };
    }),
    handleAction('@CustomerPages/duplicateCustomerPagesRequest', ({ state, action }) => {
      state.duplicateStatus[action.payload.commandId] = 'loading';
    }),
    handleAction('@CustomerPages/duplicateCustomerPagesSuccess', ({ state, action }) => {
      state.duplicateStatus[action.payload.commandId] = 'success';
      state.data = [action.payload.item, ...state.data];
    }),
    handleAction('@CustomerPages/duplicateCustomerPagesFailure', ({ state, action }) => {
      state.duplicateStatus[action.payload.commandId] = 'failure';
    }),
  ],
});

export const { changSearchKey, filterPageType, isSelectAll, selectIds } = sliceCustomerPages.actions;

export const useCreateCustomerPage = createDispatchAsyncAction(actionCreateCustomerPage);
export const useGetCustomerPages = createDispatchAsyncAction(actionGetCustomerPages);
export const useDeleteCustomerPages = createDispatchAsyncAction(actionDeleteCustomerPages);
export const useUpdateStatusCustomerPage = createDispatchAsyncAction(actionUpdateStatusCustomerPage);
export const useLoadMoreCustomerPages = createDispatchAsyncAction(actionLoadMoreCustomerPages);
export const useSelectCustomerPagesIds = createDispatchAction(selectIds);
export const useSetFilterType = createDispatchAction(filterPageType);
export const useIsSelectAll = createDispatchAction(isSelectAll);
export const useChangeSearchKey = createDispatchAction(changSearchKey);
export const useDuplicateCustomerPages = createDispatchAsyncAction(actionDuplicateCustomerPages);
