import { AsyncComponent, Button } from '@wiloke/ui';
import { Carousel } from 'components/Carousel/Carousel';
import Box from 'components/FieldBox';
import QuickGuideBox from 'components/QuickGuideBox';
import RecommendCard from 'components/RecommendCard';
import { DuplicateThemeShopify } from 'containers/Admin/RightBar/DuplicateThemeShopify';
import { IconTourTheme } from 'containers/Admin/RightBar/IconTourTheme';
import { VideoCart } from 'containers/ModalInstallTheme/components/VideoCard';
import parse from 'html-react-parser';
import { FC, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { i18n } from 'translation';
import { View } from 'wiloke-react-core';
import { useGetRecommendApps, useGetVedaBlogs } from './store/actions';
import { recommendAppsSelector } from './store/sliceRecommend';
import * as styles from './styles';
import { DataBoxType, data_box } from 'containers/Admin/RightBar/data_box';

const blog_url = process.env.DASHBOARD_BLOG_URL || 'https://myshopkit.app/shopify-sales-boosting-blog';

interface RightBarAdminProps {
  columns?: number[];
}

export const RightBarAdmin: FC<RightBarAdminProps> = ({ columns = [4, 4, 4] }) => {
  const { data, getStatus, blogs, getBlogStatus } = useSelector(recommendAppsSelector);
  const getRecommendApps = useGetRecommendApps();
  const getVedaBlogs = useGetVedaBlogs();
  const location = useLocation();

  useEffect(() => {
    getRecommendApps.request(undefined);
    getVedaBlogs.request(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openBlog = () => {
    window.open(blog_url, '_blank');
  };

  const renderBox: Record<DataBoxType, () => ReactNode> = {
    image: () => {
      return (
        <RecommendCard
          description={data_box.cardShortDescription}
          title={data_box.cardTitle}
          image={data_box.url}
          onClick={
            !data_box.href
              ? undefined
              : () => {
                  window.open(data_box.href, '_blank');
                }
          }
          buttonText={data_box.buttonText}
        />
      );
    },
    video: () => {
      return (
        <VideoCart
          embedId={data_box.url}
          description={data_box.cardShortDescription}
          title={data_box.cardTitle}
          href={data_box.href}
          enabledBorderButton
          buttonText={data_box.buttonText}
        />
      );
    },
  };

  return (
    <View css={styles.container} columns={columns}>
      {!location.pathname.includes('/theme') && <DuplicateThemeShopify />}
      {location.pathname.includes('/page') && <IconTourTheme />}

      {data_box.enable && (
        <Box.WithTitle title={data_box.boxTitle} borderWidth={0} css={styles.box}>
          {renderBox[data_box.type]()}
        </Box.WithTitle>
      )}

      <Box borderWidth={0} backgroundColor="light" css={styles.box}>
        <AsyncComponent
          status={getStatus}
          Success={
            <Carousel
              title={i18n.t('adminDashboard.right_bar.recommended_apps')}
              slideCount={data.length}
              slideWidth={456}
              data={data}
              interval={6000}
              space={0}
              renderItem={item => (
                <RecommendCard
                  key={item.id}
                  description={parse(`${item.excerpt.rendered}`)}
                  title={item.title.rendered}
                  image={item.sidebar_featured_image !== null ? item.sidebar_featured_image.large : item.images.large}
                  onClick={() => {
                    window.open(item.acf.app_partner_url, '_blank');
                  }}
                />
              )}
            />
          }
        />
      </Box>

      <Box.WithTitle title={i18n.t('adminDashboard.blog')} borderWidth={0} css={styles.box}>
        <AsyncComponent
          status={getBlogStatus}
          Success={blogs.map(item => (
            <QuickGuideBox.Style2
              key={item.id}
              title={item.title.rendered}
              description={item.excerpt.rendered}
              image={item.images.large}
              href={item.link}
            />
          ))}
        />
        <Button size="extra-small" backgroundColor="secondary" radius={6} onClick={openBlog}>
          {i18n.t('general.discover_more')}
        </Button>
      </Box.WithTitle>
    </View>
  );
};
