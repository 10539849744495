import { AxiosResponse } from 'axios';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface Params {
  commandId: string;
}

interface ResponseSuccess {
  message: string;
}

export const restoreTrash = async ({ commandId }: Params) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({ url: `${baseUrl}/trash/${commandId}/restore`, method: 'PUT' });
  return response.data;
};
