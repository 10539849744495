import { CopyResult } from 'utils/functions/copyStyle/types';

export function mergeSettingsCopied(copyData: CopyResult, draftData: CopyResult) {
  const newDraftData = { ...draftData };

  newDraftData.copiedData = draftData.copiedData.map(draftItem => {
    const copyName = draftItem.name;
    const matchingCopyItem = copyData.copiedData.find(copyItem => copyItem.name === copyName);

    if (matchingCopyItem) {
      return { ...draftItem, children: matchingCopyItem.children };
    }

    return draftItem;
  });

  return newDraftData;
}
