import { customLog, imageUrl } from '@wiloke/functions';
import { isEmpty } from 'ramda';
import { MouseEvent } from 'react';
import { SchemaSettingField, SettingBlockObject } from 'types/Schema';
import { CopyData, CopyResult } from 'utils/functions/copyStyle/types';
import { relatedFieldNames } from 'utils/functions/copyStyle/utils/checkFieldName';
import { deepFindByObjectValue, deepFindByExactValue, deepFindByStringValue } from 'utils/functions/deepFind';

function getImageSettings(settingObject: SettingBlockObject, target: HTMLElement): CopyResult | undefined {
  const src = (target as HTMLImageElement).src;
  const fieldImageChildrenValues = deepFindByExactValue(settingObject, imageUrl(src));

  if (isEmpty(fieldImageChildrenValues)) {
    return {
      type: 'unknown',
      copiedData: [],
    };
  }

  const fieldImageChildrenValue = fieldImageChildrenValues[0];
  const currentImageFields = deepFindByObjectValue<SettingBlockObject, SchemaSettingField>(settingObject, fieldImageChildrenValue);

  if (isEmpty(currentImageFields)) {
    return {
      type: 'unknown',
      copiedData: [],
    };
  }

  const imageField = currentImageFields[0];
  const designOfSelectedField = settingObject.children.filter(field => field.type === 'styleBox' || relatedFieldNames.includes(field.name));

  if (isEmpty(designOfSelectedField)) {
    return {
      type: imageField.type,
      copiedData: [],
    };
  }

  const result: CopyData[] = [];
  for (const field of designOfSelectedField) {
    result.push({
      childId: field.id,
      children: field.children,
      name: field.name,
      settingId: settingObject.id,
      grandChildId: undefined,
    });
  }
  return {
    type: imageField.type,
    copiedData: result,
  };
}

function getTextSettings(settingObject: SettingBlockObject, target: HTMLElement): CopyResult | undefined {
  const _value = target.textContent?.trim();

  const designOfSelectedField = settingObject.children.filter(field => field.type === 'styleBox' || relatedFieldNames.includes(field.name));

  if (isEmpty(designOfSelectedField)) {
    return {
      type: 'unknown',
      copiedData: [],
    };
  }

  if (!_value) {
    const result: CopyData[] = [];
    for (const field of designOfSelectedField) {
      result.push({
        childId: field.id,
        children: field.children,
        settingId: settingObject.id,
        name: field.name,
        grandChildId: undefined,
      });
    }

    return {
      type: 'unknown',
      copiedData: result,
    };
  }

  // Đệ quy tìm setting theo text content
  const selectedFields = deepFindByStringValue<SettingBlockObject, SchemaSettingField>(settingObject, _value);

  // Có thể vào trường hợp text của shopify in ra chứ ko phải text của setting in ra nên ko tìm thấy
  if (isEmpty(selectedFields)) {
    const result: CopyData[] = [];
    for (const field of designOfSelectedField) {
      result.push({
        childId: field.id,
        children: field.children,
        settingId: settingObject.id,
        name: field.name,
        grandChildId: undefined,
      });
    }
    return {
      type: 'shopify_text',
      copiedData: result,
    };
  }

  const selectedField = selectedFields[0];

  const result: CopyData[] = [];
  for (const field of designOfSelectedField) {
    result.push({
      childId: field.id,
      children: field.children,
      settingId: settingObject.id,
      name: field.name,
      grandChildId: undefined,
    });
  }
  return {
    type: selectedField.type ?? 'unknown',
    copiedData: result,
  };
}

function getItemSettings(settingObject: SettingBlockObject): CopyResult | undefined {
  const fieldItemsDesign = settingObject.children.filter(field => field.type === 'styleBox' || relatedFieldNames.includes(field.name));

  if (isEmpty(fieldItemsDesign)) {
    return {
      type: 'item',
      copiedData: [],
    };
  }

  const result: CopyData[] = [];
  for (const field of fieldItemsDesign) {
    result.push({
      childId: field.id,
      children: field.children,
      name: field.name,
      settingId: settingObject.id,
      grandChildId: undefined,
    });
  }
  return {
    type: 'item',
    copiedData: result,
  };
}

/*
  warning: Hiện tại đang phải tìm setting bằng text content nên:
    - Có thể bị trường hợp nếu có 2 hoặc nhiều setting có text content giống nhau thì sẽ không biết được setting đang click là phần tử thứ mấy, ví dụ: có 2 item trong block array đều có giá trị là "Text A"
  solution: Cần phải in thêm attribute là veda-setting-id=settingId cùng cấp với các attribute như là veda-index-boc, veda-open-tag
  result:
    + Tìm kiếm setting đang được click dễ hơn vì settingId là unique nên kết quả tìm được luôn là 1
 */
// đang được gọi ở useAtomEvent
export function getComponentStyleInObject(
  settingObject: SettingBlockObject,
  event: MouseEvent<HTMLElement, globalThis.MouseEvent>,
): CopyResult | undefined {
  try {
    const _target = event.target as HTMLElement;

    if (_target.tagName === 'IMG') {
      return getImageSettings(settingObject, _target);
    }

    if (_target.childElementCount >= 2) {
      const result = getItemSettings(settingObject);
      return result;
    }

    return getTextSettings(settingObject, _target);
  } catch (error) {
    const _error = error as Error;
    customLog('getComponentStyleInObject', _error.message);
    console.log(error);

    return {
      type: 'unknown',
      copiedData: [],
    };
  }
}
