import { createSelector } from 'reselect';
import { ThemeShopify } from 'services/ThemeService/types';
import { ThemeGeneral, ClientTheme } from 'types/Theme';
import { Consts } from 'utils/constants/constants';
import { ActionTypes, createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';
import {
  activeThemeVeda,
  cancelMigrateThemeShopify,
  changeThemeShopifyActivate,
  deleteClientTheme,
  getClientThemes,
  getThemesShopify,
  getThemeVedaActive,
  loadMoreClientThemes,
  migrateThemeShopify,
  getHomePageBeforeCustomize,
  updateThemeShopifyId,
} from './actions';

type ExtraActions = ActionTypes<
  | typeof getThemeVedaActive
  | typeof getClientThemes
  | typeof deleteClientTheme
  | typeof loadMoreClientThemes
  | typeof migrateThemeShopify
  | typeof getThemesShopify
  | typeof changeThemeShopifyActivate
  | typeof activeThemeVeda
  | typeof cancelMigrateThemeShopify
  | typeof getHomePageBeforeCustomize
  | typeof updateThemeShopifyId
>;

type Actions =
  | {
      type: 'setModalChangeThemeShopifyActivate';
      payload: { themeId: string } | undefined;
    }
  | {
      type: 'setThemeActive';
      payload: ThemeGeneral;
    }
  | {
      type: 'setVisibleModalMigration';
      payload: boolean;
    }
  | {
      type: 'setHeaderFooterIdsThemeActive';
      payload: {
        headerIds: string[];
        footerIds: string[];
      };
    };

interface ThemeDashboardState {
  getThemeActiveStatus: Status;
  getClientThemesStatus: Status;
  loadMoreClientThemesStatus: Status;
  deleteClientThemeStatus: Record<string, Status>;
  getHomePageBeforeCustomizeStatus: Record<string, Status>;
  updateThemeStatus: Record<string, Status>;

  themeActivate: ThemeGeneral;
  themes: ClientTheme[];
  hasNextPage: boolean;

  migrateThemeShopifyStatus: Status;
  cancelMigrateThemeShopifyStatus: Status;
  getThemesShopifyStatus: Status;
  shopifyThemes: ThemeShopify[];

  changeThemeShopifyActivateStatus: Status;
  modalChangeThemeShopifyActivate: { themeId: string } | undefined;
  activeThemeVedaStatus: Record<string, Status>;
  visibleModalMigration: boolean;
}

export const defaultThemeActive: ThemeGeneral = {
  commandId: '',
  label: '',
  userId: 1,
  featuredImage: '',
  pageCommandIds: [] as ThemeGeneral['pageCommandIds'],
  themeSettings: {
    generalSettings: {
      preloaderEnable: false,
      preloaderVariant: 0,
      preloaderBackgroundColor: '#F57070',
      preloaderColor: '#fff',
      preloaderLogo: '',
      favicon: '',
      label: Consts.BlankCommandId,
    },
    cssVariables: {
      colors: [],
      fonts: [],
    },
    layoutSettings: {
      containerWidth: 1300,
      containerGap: 20,
      columnGapX: 20,
      columnGapY: 20,
    },
    globalTranslations: {
      languageActive: 'en',
      translation: {},
    },
    colorSchemes: [],
  },
  vendors: [] as ThemeGeneral['vendors'],
  globalJs: '',
  globalScss: '',
  addonCommandIds: [],
  headerSectionCommandIds: [] as ThemeGeneral['headerSectionCommandIds'],
  footerSectionCommandIds: [] as ThemeGeneral['footerSectionCommandIds'],
  createdDateTimestamp: Date.now(),
  modifiedDateTimestamp: Date.now(),
};

export const sliceThemeDashboard = createSlice<ThemeDashboardState, Actions, ExtraActions>({
  initialState: {
    getHomePageBeforeCustomizeStatus: {},
    getThemeActiveStatus: 'idle',
    themeActivate: defaultThemeActive,
    getClientThemesStatus: 'idle',
    loadMoreClientThemesStatus: 'idle',
    themes: [],
    hasNextPage: false,
    deleteClientThemeStatus: {},
    updateThemeStatus: {},

    migrateThemeShopifyStatus: 'idle',
    cancelMigrateThemeShopifyStatus: 'idle',
    getThemesShopifyStatus: 'idle',
    shopifyThemes: [],

    changeThemeShopifyActivateStatus: 'idle',
    modalChangeThemeShopifyActivate: undefined,
    activeThemeVedaStatus: {},
    visibleModalMigration: false,
  },
  name: '@Dashboard',
  reducers: [
    handleAction('setModalChangeThemeShopifyActivate', ({ state, action }) => {
      state.modalChangeThemeShopifyActivate = action.payload;
    }),
    handleAction('setThemeActive', ({ state, action }) => {
      state.themeActivate = action.payload;
    }),
    handleAction('setVisibleModalMigration', ({ state, action }) => {
      state.visibleModalMigration = action.payload;
    }),
    handleAction('setHeaderFooterIdsThemeActive', ({ state, action }) => {
      state.themeActivate = {
        ...state.themeActivate,
        headerSectionCommandIds: action.payload.headerIds,
        footerSectionCommandIds: action.payload.footerIds,
      };
    }),
  ],
  extraReducers: [
    handleAction('@Dashboard/getThemeVedaActive/request', ({ state }) => {
      state.getThemeActiveStatus = 'loading';
    }),
    handleAction('@Dashboard/getThemeVedaActive/success', ({ state, action }) => {
      state.getThemeActiveStatus = 'success';
      state.themeActivate = action.payload;
    }),
    handleAction('@Dashboard/getThemeVedaActive/failure', ({ state }) => {
      state.getThemeActiveStatus = 'failure';
    }),
    handleAction('@Dashboard/getClientThemes/request', ({ state }) => {
      state.getClientThemesStatus = state.themes.length ? 'success' : 'loading';
    }),
    handleAction('@Dashboard/getClientThemes/success', ({ state, action }) => {
      state.getClientThemesStatus = 'success';
      state.themes = action.payload.data;
      state.hasNextPage = action.payload.hasNextPage;
    }),
    handleAction('@Dashboard/getClientThemes/failure', ({ state }) => {
      state.getClientThemesStatus = 'failure';
    }),
    handleAction('@Dashboard/deleteClientTheme/request', ({ state, action }) => {
      state.deleteClientThemeStatus[action.payload.commandId] = 'loading';
    }),
    handleAction('@Dashboard/deleteClientTheme/success', ({ state, action }) => {
      state.deleteClientThemeStatus[action.payload.commandId] = 'success';
      state.themes = state.themes.filter(theme => theme.commandId !== action.payload.commandId);
    }),
    handleAction('@Dashboard/deleteClientTheme/failure', ({ state, action }) => {
      state.deleteClientThemeStatus[action.payload.commandId] = 'failure';
    }),
    handleAction('@Dashboard/loadMoreClientThemes/request', ({ state }) => {
      state.loadMoreClientThemesStatus = 'loading';
    }),
    handleAction('@Dashboard/loadMoreClientThemes/success', ({ state, action }) => {
      state.loadMoreClientThemesStatus = 'success';
      state.themes = state.themes.concat(action.payload.data);
      state.hasNextPage = action.payload.hasNextPage;
    }),
    handleAction('@Dashboard/loadMoreClientThemes/failure', ({ state }) => {
      state.loadMoreClientThemesStatus = 'failure';
    }),
    handleAction('@Dashboard/migrateThemeShopify/request', ({ state }) => {
      return {
        ...state,
        migrateThemeShopifyStatus: 'loading',
      };
    }),
    handleAction('@Dashboard/migrateThemeShopify/success', ({ state }) => {
      return {
        ...state,
        migrateThemeShopifyStatus: 'success',
      };
    }),
    handleAction('@Dashboard/migrateThemeShopify/failure', ({ state }) => {
      return {
        ...state,
        migrateThemeShopifyStatus: 'failure',
      };
    }),
    handleAction('@Dashboard/getThemesShopify/request', ({ state }) => {
      return {
        ...state,
        getThemesShopifyStatus: 'loading',
      };
    }),
    handleAction('@Dashboard/getThemesShopify/success', ({ state, action }) => {
      return {
        ...state,
        getThemesShopifyStatus: 'success',
        shopifyThemes: action.payload,
      };
    }),
    handleAction('@Dashboard/getThemesShopify/failure', ({ state }) => {
      return {
        ...state,
        getThemesShopifyStatus: 'failure',
      };
    }),
    handleAction('@Dashboard/changeThemeShopifyActivate/request', ({ state }) => {
      state.changeThemeShopifyActivateStatus = 'loading';
    }),
    handleAction('@Dashboard/changeThemeShopifyActivate/success', ({ state }) => {
      state.changeThemeShopifyActivateStatus = 'success';
    }),
    handleAction('@Dashboard/changeThemeShopifyActivate/failure', ({ state }) => {
      state.changeThemeShopifyActivateStatus = 'failure';
    }),
    handleAction('@Dashboard/activeThemeVeda/request', ({ state, action }) => {
      state.activeThemeVedaStatus[action.payload.clientTheme.commandId] = 'loading';
    }),
    handleAction('@Dashboard/activeThemeVeda/success', ({ state, action }) => {
      state.activeThemeVedaStatus[action.payload.themeId] = 'success';
    }),
    handleAction('@Dashboard/activeThemeVeda/failure', ({ state, action }) => {
      state.activeThemeVedaStatus[action.payload.themeId] = 'failure';
    }),
    handleAction('@Dashboard/cancelMigrateThemeShopify/request', ({ state }) => {
      state.cancelMigrateThemeShopifyStatus = 'loading';
    }),
    handleAction('@Dashboard/cancelMigrateThemeShopify/success', ({ state }) => {
      state.cancelMigrateThemeShopifyStatus = 'success';
    }),
    handleAction('@Dashboard/cancelMigrateThemeShopify/failure', ({ state }) => {
      state.cancelMigrateThemeShopifyStatus = 'failure';
    }),
    handleAction('@Dashboard/getHomePageBeforeCustomize/request', ({ state, action }) => {
      state.getHomePageBeforeCustomizeStatus[action.payload.themeId] = 'loading';
    }),
    handleAction('@Dashboard/getHomePageBeforeCustomize/success', ({ state, action }) => {
      state.getHomePageBeforeCustomizeStatus[action.payload.themeId] = 'success';
    }),
    handleAction('@Dashboard/getHomePageBeforeCustomize/failure', ({ state, action }) => {
      state.getHomePageBeforeCustomizeStatus[action.payload.themeId] = 'failure';
    }),
    handleAction('@Dashboard/updateThemeShopifyId/request', ({ state, action }) => {
      state.updateThemeStatus[action.payload.commandId] = 'loading';
    }),
    handleAction('@Dashboard/updateThemeShopifyId/success', ({ state, action }) => {
      state.updateThemeStatus[action.payload.commandId] = 'success';
      state.themes = state.themes.map(item => {
        if (item.commandId == action.payload.commandId) {
          return {
            ...item,
            shopifyThemeId: action.payload.shopifyThemeId,
          };
        }
        return item;
      });
    }),
    handleAction('@Dashboard/updateThemeShopifyId/failure', ({ state, action }) => {
      state.updateThemeStatus[action.payload.commandId] = 'failure';
    }),
  ],
});

export const {
  setModalChangeThemeShopifyActivate,
  setThemeActive,
  setVisibleModalMigration,
  setHeaderFooterIdsThemeActive,
} = sliceThemeDashboard.actions;

export const useSetModalChangeThemeShopifyActivate = createDispatchAction(setModalChangeThemeShopifyActivate);
export const useSetVisibleModalMigration = createDispatchAction(setVisibleModalMigration);

export const themeDashboardSelector = (state: AppState) => state.adminDashboard.themeBuilder.themeDashboard;

export const isThemeDefaultSelector = createSelector(themeDashboardSelector, state => {
  const { getThemeActiveStatus, themeActivate } = state;
  return getThemeActiveStatus === 'success' && themeActivate.pageCommandIds.length === 0;
});
