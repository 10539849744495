import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

export const checkThemeProductIsInstalled = async ({ parentCommandId }: { parentCommandId: string }) => {
  return await themeApis.vedaApplication.userApi.theme.checkThemeIsInstalled({ parentCommandId });
};

export const getThemeProductsService = async ({ group, size, categoryId }: { group: 'envato' | 'built_in'; size: number; categoryId: string }) => {
  const { lastCache } = getUserInfo();
  const response = await themeApis.vedaApplication.userApi.theme.getProducts({
    type: 'GET FIRST PAGE',
    group,
    size,
    cacheVersion: lastCache,
    categoryId,
  });

  return response;
};

export const loadMoreThemeProductsService = async ({
  group,
  cursor,
  size,
  categoryId,
}: {
  group: 'envato' | 'built_in';
  cursor: string;
  size: number;
  categoryId: string;
}) => {
  const { lastCache } = getUserInfo();
  const response = await themeApis.vedaApplication.userApi.theme.getProducts({
    type: 'LOADMORE',
    group,
    cursor,
    size,
    cacheVersion: lastCache,
    categoryId,
  });
  return response;
};
