import { ImportThemeAtomToClientServiceNPageInThemeClientMessage } from 'hooks/useSocket/useSocketForImportThemeAtomToClientServiceNPageInThemeClient';
import { createAction, createDispatchAction } from 'wiloke-react-core/utils';

export const setEventIdOfImportThemeAtomToClientServiceNPageInThemeClient = createAction(
  '@importThemeAtomToClientServiceNPageInThemeClient/setEventIdOfSyncShopifyNClonePageAtomServiceToThemeAtomService',
  (eventId: string | undefined) => eventId,
);
export const useSetEventIdOfImportThemeAtomToClientServiceNPageInThemeClient = createDispatchAction(
  setEventIdOfImportThemeAtomToClientServiceNPageInThemeClient,
);

export const setStreamSocketOfImportThemeAtomToClientServiceNPageInThemeClient = createAction(
  '@importThemeAtomToClientServiceNPageInThemeClient/setStreamSocketOfImportThemeAtomToClientServiceNPageInThemeClient',
  (payload: {
    eventType: ImportThemeAtomToClientServiceNPageInThemeClientMessage['eventType'];
    status: Status;
    socketData: ImportThemeAtomToClientServiceNPageInThemeClientMessage | undefined;
  }) => {
    return { ...payload };
  },
);
export const useSetStreamSocketOfImportThemeAtomToClientServiceNPageInThemeClient = createDispatchAction(
  setStreamSocketOfImportThemeAtomToClientServiceNPageInThemeClient,
);

export const resetAllState = createAction('@importThemeAtomToClientServiceNPageInThemeClient/resetAllState');
export const useResetAllState = createDispatchAction(resetAllState);
