import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { userApis } from '../apis';

interface GetAuthors {
  page: number;
  role?: string;
  username?: string;
}

export const getAuthors = async ({ page, username, role }: GetAuthors) => {
  const { role: roleState } = getUserInfo();
  if (roleState === 'admin') {
    const response = await userApis.user.adminApi.getUsers({
      page,
      username,
      role,
    });
    return response.info;
  }
  if (roleState === 'support') {
    const response = await userApis.user.adminApi.getUsers({
      page,
      username,
      role,
    });
    return response.info;
  }
  throw new RoleException();
};
