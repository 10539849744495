import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { handleGlobalTranslationsNColorSchemesInThemeAtom } from '../Adapter/handleGlobalTranslationsNColorSchemes';
import { themeApis } from '../apis';

interface LoadmoreThemesAtom {
  lastCursor: string;
}

export const loadmoreThemesAtom = async ({ lastCursor }: LoadmoreThemesAtom) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const atomResponse = await themeApis.atom.adminApi.theme.getAtoms({
      type: 'LOADMORE',
      cursor: lastCursor,
      cacheVersion: lastCache,
    });
    const productResponse = await themeApis.vedaApplication.adminApi.theme.getProductsWithParentCommandIds({
      parentCommandIds: atomResponse.info.map(item => item.commandId).join(','),
      cacheVersion: lastCache,
    });
    return atomResponse.info.map(atom => {
      const withGlobalTranslationAdapter = handleGlobalTranslationsNColorSchemesInThemeAtom(atom);
      return {
        ...withGlobalTranslationAdapter,
        vedaProductRepresent: productResponse.info.find(product => product.parentCommandId === atom.commandId),
      };
    });
  }
  if (role === 'dev') {
    const response = await themeApis.atom.devApi.theme.getAtoms({
      type: 'LOADMORE',
      cursor: lastCursor,
    });
    return response.info.map(item => handleGlobalTranslationsNColorSchemesInThemeAtom(item));
  }
  throw new RoleException();
};
