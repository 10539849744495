import { put, retry, takeLatest } from 'redux-saga/effects';
import { updateThemeIdAfterMigrateService } from 'services/UserService/Logic/updateThemeIdAfterMigrate';
import { getActionType } from 'wiloke-react-core/utils';
import { cancelMigrateThemeShopify } from '../slice/actions';
import { setVisibleModalMigration } from '../slice/sliceThemeDashboard';

function* handleCancelMigrateTheme({ payload: { currentThemeId } }: ReturnType<typeof cancelMigrateThemeShopify.request>) {
  try {
    yield retry(3, 1000, updateThemeIdAfterMigrateService, { themeId: currentThemeId });
    yield put(setVisibleModalMigration(false));
  } catch (error) {
    yield put(cancelMigrateThemeShopify.failure(undefined));
  }
}

export function* watchCancelMigrateTheme() {
  yield takeLatest(getActionType(cancelMigrateThemeShopify.request), handleCancelMigrateTheme);
}
