import { AxiosResponse } from 'axios';
import { getCurrentVersion } from 'utils/CacheControl/ShopifyConnectionCacheControl';
import fetchAPI from 'utils/functions/fetchAPI';
import { getShopName } from 'utils/functions/getUserInfo';
import { withDelayAfterThrottled } from '../utils/withDelayAfterThrottled';
import configureApp from 'configureApp';

interface BE_Response {
  message: string;
  info: {
    id: number;
    role: string;
    name: string;
  };
}

export const getThemeObject = async () => {
  const response: AxiosResponse<BE_Response> = await withDelayAfterThrottled(
    fetchAPI.request({
      url: `${configureApp.endpoint['shopify-connections']}/admin-themes/active`,
      params: {
        shopName: getShopName(),
        cacheVersion: getCurrentVersion().getTime(),
      },
    }),
  );
  return { theme: response.data.info };
};
