import SectionPageHeader from 'components/SectionPageHeader';
import { Dashboard } from 'containers/Dashboard';
import { FC } from 'react';
import { i18n } from 'translation';
import { View } from 'wiloke-react-core';
import { Content } from './components/BuiltInContent';
import { Modal402PurchaseCode } from './components/Modal402PurchaseCode';
import * as styles from './styles';

export const ThemeTemplates: FC = () => {
  return (
    <Dashboard
      Modals={() => (
        <>
          <Modal402PurchaseCode />
        </>
      )}
      disabledPaddingContent
      Content={() => (
        <View backgroundColor="gray1" css={styles.container}>
          <SectionPageHeader
            disableButton
            title={i18n.t('adminDashboard.theme_templates')}
            description="A forest of aesthetic theme templates for you to handpick and customize."
            buttonContent={i18n.t('general.create_button', { text: 'theme' })}
          />
          <Content />
        </View>
      )}
    />
  );
};
