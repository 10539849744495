import {
  FilterTypePage,
  ServerResponseAdminPage,
  UpdateStatusPageFailure,
  UpdateStatusPageRequest,
  UpdateStatusPageSuccess,
} from 'containers/Admin/types';
import { BE_PageClient } from 'services/PageService/VedaApplication/types';
import { PageType } from 'types/Page';

import { createAsyncAction } from 'wiloke-react-core/utils';

export const actionCreateCustomerPage = createAsyncAction([
  '@CustomerPages/createPageRequest',
  '@CustomerPages/createPageSuccess',
  '@CustomerPages/createPageFailure',
])<{ name: string; templateId: string; includeHeaderFooter: boolean; callback?: (id: string) => void }, undefined, undefined>();

export const actionGetCustomerPages = createAsyncAction([
  '@CustomerPages/getPagesRequest',
  '@CustomerPages/getPagesSuccess',
  '@CustomerPages/getPagesFailure',
])<{ s?: string; pageType: PageType; filterType: FilterTypePage }, { data: ServerResponseAdminPage['info']; hasNextPage: boolean }, undefined>();

export const actionDeleteCustomerPages = createAsyncAction([
  '@CustomerPages/deletePagesRequest',
  '@CustomerPages/deletePagesSuccess',
  '@CustomerPages/deletePagesFailure',
])<{ ids: string[]; onFulfill: () => void }, { ids: string[] }, { id: string }>();

export const actionUpdateStatusCustomerPage = createAsyncAction([
  '@CustomerPages/updateStatusPageRequest',
  '@CustomerPages/updateStatusPageSuccess',
  '@CustomerPages/updateStatusPageFailure',
])<UpdateStatusPageRequest, UpdateStatusPageSuccess, UpdateStatusPageFailure>();

export const actionLoadMoreCustomerPages = createAsyncAction([
  '@CustomerPages/loadMoreCustomerPagesRequest',
  '@CustomerPages/loadMoreCustomerPagesSuccess',
  '@CustomerPages/loadMoreCustomerPagesFailure',
])<
  { s?: string; pageType: PageType; filterType: FilterTypePage; lastCursor: string },
  { data: ServerResponseAdminPage['info']; hasNextPage: boolean },
  undefined
>();

export const actionDuplicateCustomerPages = createAsyncAction([
  '@CustomerPages/duplicateCustomerPagesRequest',
  '@CustomerPages/duplicateCustomerPagesSuccess',
  '@CustomerPages/duplicateCustomerPagesFailure',
])<{ commandId: string }, { item: BE_PageClient; commandId: string }, { commandId: string }>();

export interface SelectIds {
  type: 'selectIds';
  payload: {
    ids: string[];
  };
}

export interface IsSelectAll {
  type: 'isSelectAll';
  payload: {
    isSelectAll: boolean;
  };
}

export interface FilterPageType {
  type: 'filterPageType';
  payload: {
    pageType: FilterTypePage;
  };
}

export interface ChangeSearchKey {
  type: 'changSearchKey';
  payload: {
    search: string;
  };
}
