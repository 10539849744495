import { addonApiController } from 'services/AddonService';
import { userApis } from 'services/UserService/apis';
import { SectionCategoryTag } from 'types/Sections';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const updateCategoryOfProduct = async (category: SectionCategoryTag) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    try {
      const response = await addonApiController.product.adminApi.category.updateCategoryOfProduct({ category });
      await userApis.user.adminApi.updateVedaCacheVersion();
      return response;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
    }
  }
  throw new RoleException();
};
