import ColorPicker2 from 'components/ColorPicker2';
import { NumberInput } from 'components/NumberInput';
import { Tooltip } from '@wiloke/ui';
import { FC } from 'react';
import { i18n } from 'translation';
import { CssColorVariable } from 'types/PresetStyles';
import { css, View } from 'wiloke-react-core';
import { replaceUnit } from './utils';

export interface TextStrokeProps {
  value: string;
  colors: CssColorVariable[];
  onAddOrEditColor?: () => void;
  onChange: (value: string) => void;
}

const styles = {
  dragCoordinates: css`
    input {
      cursor: ew-resize !important;
      padding: 0 8px !important;
    }
  `,
  tooltip: css`
    padding: 0 3px;
    display: block;
    width: 25%;
  `,
};

export const TextStroke: FC<TextStrokeProps> = ({ value = '0px 0px 0px 0px transparent', colors, onAddOrEditColor, onChange }) => {
  const valArr = value.split('px ');

  return (
    <View css={{ display: 'flex', margin: '0 -3px' }}>
      <Tooltip portal text="Value" css={styles.tooltip}>
        <NumberInput
          value={replaceUnit(valArr[0])}
          block
          min={0}
          max={100}
          step={1}
          radius={4}
          sizeInput="small"
          borderColor="gray3"
          borderWidth={1}
          onValueChange={value => {
            onChange(`${value}px ${valArr[1]}`);
          }}
        />
      </Tooltip>
      <Tooltip portal text={i18n.t('general.color')} css={styles.tooltip}>
        <ColorPicker2
          triggerSmall
          triggerCss={{ height: '32px !important', width: '100% !important' }}
          color={valArr[1] === 'transparent' ? undefined : valArr[1]}
          onChange={value => {
            onChange(`${replaceUnit(valArr[0])}px ${value}`);
          }}
          onAddOrEditColor={onAddOrEditColor}
          data={colors}
        />
      </Tooltip>
    </View>
  );
};
