import { Button } from '@wiloke/ui';
import { notification } from 'antd';
import ChooseImage from 'components/ChooseImage';
import Field from 'components/Field';
import { MyModal } from '@wiloke/ui';
import SwitchBeauty from 'components/SwitchBeauty';
import TextInput from 'components/TextInput';
import { useSaveAtomMegaMenu } from 'containers/BuilderPage/store/saveForBuilder/actions';
import { useSetModalAdminMegaMenuVisible } from 'containers/BuilderPage/store/saveForBuilder/slice';
import { useCreateAdminMegaMenuChangelog, useSetCreateMenuChangelogStatus } from 'containers/ChooseTemplate/store/actions';
import withDebounce from 'hocs/withDebounce';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { megaMenuSelector, saveForBuilderSelector } from 'store/selectors';
import { i18n } from 'translation';
import { AdminSection } from 'types/Sections';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { Space, View } from 'wiloke-react-core';
import { useSaveInfoMegaMenu } from './utils/useSaveInfoMegaMenu';
import { compareVersion } from 'containers/BuilderPage/components/ModalSaveForBuilder/ModalAdminSection';

const DebounceInput = withDebounce(TextInput, 'value', 'onValueChange');

const MAX_CHARACTER = 25;

export const ModalAdminMegaMenu = () => {
  const { saveSectionStatus, modalAdminMegaMenuVisible: modalMegaMenuVisible } = useSelector(saveForBuilderSelector);
  const { createChangelogStatus } = useSelector(megaMenuSelector.adminMegaMenu);

  const setModalSectionVisible = useSetModalAdminMegaMenuVisible();
  const saveAtomMegaMenu = useSaveAtomMegaMenu();
  const createChangelog = useCreateAdminMegaMenuChangelog();
  const setCreateMenuChangelogStatus = useSetCreateMenuChangelogStatus();

  const { image, label, methodType, section, changelog, version, dispatch } = useSaveInfoMegaMenu();
  const { id } = getUserInfo();
  const [error, setError] = useState('');

  const handleSaveSection = () => {
    if (methodType === 'update' && createChangelogStatus !== 'success') {
      setError('Must create changelog before update');
    } else {
      if (section) {
        const newSection: AdminSection = {
          ...section,
          label,
          image,
          id: section.id,
          category: undefined,
          createdBy: section.createdBy || section.userId ? section.createdBy || section.userId : id,
          type: 'megamenu',
          currentVersion: methodType === 'create' ? '1.0.0' : version,
          userId: methodType === 'create' ? id : section.userId ? section.userId : id,
        };
        saveAtomMegaMenu.request({ type: methodType, data: newSection });
      }
    }
  };

  const handleCreateChangelog = () => {
    if (section && version) {
      createChangelog.request({
        content: changelog,
        version: version,
        versionId: section.commandId,
      });
    }
  };

  const handleChangeMethodType = (value: boolean) => {
    if (!section?.commandId) {
      dispatch({
        type: 'setInfoMegaMenu',
        payload: {
          methodType: 'create',
        },
      });
      // TODO: I18n
      notification.error({
        message: 'Không đúng nghiệp vụ',
        description: 'Bạn đang install megamenu để tạo mới chứ không phải update',
      });
    } else {
      dispatch({
        type: 'setInfoMegaMenu',
        payload: {
          methodType: value ? 'create' : 'update',
        },
      });
    }
  };

  const handleChangeTitle = (val: string) => {
    if (val.length <= MAX_CHARACTER) {
      dispatch({
        type: 'setInfoMegaMenu',
        payload: {
          label: val,
        },
      });
    }
  };

  const handleChangeVersion = (val: string) => {
    if (section) {
      const _val = parseInt(val.split('.').join(''));
      const _version = parseInt(section.currentVersion.split('.').join(''));

      if (val === '') {
        setError('Cannot be blank');
      } else if (!compareVersion(_version, _val)) {
        setError('The new version must be different from the old version');
      } else {
        setError('');
        dispatch({
          type: 'setInfoMegaMenu',
          payload: {
            version: val,
          },
        });
      }
    }
  };

  const handleSetChangelog = (val: string) => {
    dispatch({
      type: 'setInfoMegaMenu',
      payload: {
        changelog: val,
      },
    });
  };

  const handleSetImage = ({ src, width, height }: { src: string; width: number; height: number }) => {
    dispatch({
      type: 'setInfoMegaMenu',
      payload: {
        image: {
          src: src,
          width: width ?? 0,
          height: height ?? 0,
        },
      },
    });
  };

  const handleCancel = () => {
    saveAtomMegaMenu.cancel();
    setModalSectionVisible(false);
    setCreateMenuChangelogStatus('idle');
    setError('');
  };

  return (
    <MyModal
      size="medium"
      isLoading={saveSectionStatus === 'loading'}
      headerText={`${i18n.t('general.save', { text: i18n.t('general.section') })}`}
      depsHeightRecalculation={section}
      isVisible={modalMegaMenuVisible}
      onCancel={handleCancel}
      onOk={handleSaveSection}
      okText={i18n.t('general.save', { text: 'to admin database' })}
    >
      <View css={{ margin: 'auto', padding: '20px 0' }}>
        <Field label={i18n.t('general.createNewSection')}>
          <SwitchBeauty
            checked={methodType === 'create'}
            radius={6}
            borderColor="gray3"
            borderWidth={1}
            enableText={i18n.t('general.enable')}
            disableText={i18n.t('general.disable')}
            onValueChange={handleChangeMethodType}
          />
        </Field>

        <Field
          label={
            <View>
              {i18n.t('general.title')}{' '}
              <View tagName="span">
                (
                <View tagName="i">
                  Max Length: {label.length}/{MAX_CHARACTER}
                </View>
                )
              </View>
            </View>
          }
        >
          <DebounceInput value={label} block sizeInput="medium" maxLength={MAX_CHARACTER} onValueChange={handleChangeTitle} />
        </Field>

        {!!section?.commandId && methodType === 'update' && (
          <Field label={i18n.t('general.changelog')}>
            <View tagName="span">{i18n.t('general.current', { text: i18n.t('general.version') })}</View>
            <TextInput disabled value={section?.currentVersion} block />
            <Space size={8} />
            <DebounceInput
              placeholder={i18n.t('general.version')}
              value={version}
              block
              sizeInput="medium"
              borderColor={!!error ? 'danger' : 'gray3'}
              onValueChange={handleChangeVersion}
            />
            {error ? (
              <View tagName="span" color="danger">
                {error}
              </View>
            ) : null}
            <Space size={8} />
            <DebounceInput placeholder={i18n.t('general.content')} value={changelog} block sizeInput="medium" onValueChange={handleSetChangelog} />
            <Space size={8} />
            <Button size="extra-small" radius={4} loading={createChangelogStatus === 'loading'} onClick={handleCreateChangelog}>
              {i18n.t('general.create', { text: i18n.t('general.changelog') })}
            </Button>
          </Field>
        )}

        <Field label={i18n.t('builderPage.save_for_builder.preview_image')} width={400}>
          <ChooseImage value={image} onChange={handleSetImage} mode="popup" />
        </Field>
      </View>
    </MyModal>
  );
};
