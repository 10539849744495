import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { getUserSettingsRequest } from 'services/UserService/Logic/getUserSettings';
import { getActionType } from 'wiloke-react-core/utils';
import { actionGetOptionsSettings } from '../actions';

function* handleAction(_: ReturnType<typeof actionGetOptionsSettings.request>) {
  try {
    const response: SagaReturnType<typeof getUserSettingsRequest> = yield retry(3, 1000, getUserSettingsRequest);
    yield put(actionGetOptionsSettings.success({ shopDevAllowable: response.info }));
  } catch (error) {
    yield put(actionGetOptionsSettings.failure(undefined));
  }
}

export function* watchGetUserSettings() {
  yield takeLatest(getActionType(actionGetOptionsSettings.request), handleAction);
}
