import { put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { addonService } from 'services/AddonService';
import { megaMenuService } from 'services/MegaMenuService';
import { sectionService } from 'services/SectionService';
import {
  setModalUpdateAddon,
  setModalUpdateSection,
  updateAddonVersion,
  updateSectionVersion,
  setModalUpdateMegaMenu,
  updateMegaMenuVersion,
} from 'store/actions/versions/actionSectionVersion';
import { i18n } from 'translation';
import { PageSection } from 'types/Sections';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';

function* handleUpdateSection({ payload }: ReturnType<typeof updateSectionVersion.request>) {
  const { id, prevSectionFeId } = payload;
  try {
    const response: Awaited<ReturnType<typeof sectionService.sections.updateSectionVersion>> = yield retry(
      3,
      1000,
      sectionService.sections.updateSectionVersion,
      id,
    );

    const vedaAtomSection: PageSection = {
      ...response,
      parentCommandId: id,
      id: prevSectionFeId,
    };

    yield put(
      updateSectionVersion.success({
        updatedItem: vedaAtomSection,
      }),
    );
    notifyAxiosHandler.handleSuccess(`${i18n.t('general.update', { text: i18n.t('general.successfully') })}`);
    yield put(setModalUpdateSection(undefined));
  } catch (error) {
    const error_ = error as Error;
    notifyAxiosHandler.handleError(error_);
    yield put(updateSectionVersion.failure(undefined));
  }
}

function* handleUpdateMegaMenu({ payload }: ReturnType<typeof updateMegaMenuVersion.request>) {
  const { id, prevSectionFeId } = payload;
  try {
    const response: Awaited<ReturnType<typeof megaMenuService.mega_menu.updateVersion>> = yield retry(
      3,
      1000,
      megaMenuService.mega_menu.updateVersion,
      {
        atomCommandId: id,
      },
    );
    yield put(updateMegaMenuVersion.success({ updatedItem: { ...response, parentCommandId: id, id: prevSectionFeId } }));
    yield put(updateSectionVersion.success({ updatedItem: { ...response, parentCommandId: id, id: prevSectionFeId } }));
    notifyAxiosHandler.handleSuccess(`${i18n.t('general.update', { text: i18n.t('general.successfully') })}`);
    yield put(setModalUpdateMegaMenu(undefined));
  } catch (error) {
    const error_ = error as Error;
    notifyAxiosHandler.handleError(error_);
    yield put(updateMegaMenuVersion.failure(undefined));
  }
}

function* handleUpdateAddon({ payload }: ReturnType<typeof updateAddonVersion.request>) {
  const { id, prevAddonSection } = payload;
  try {
    const response: SagaReturnType<typeof addonService.addons.updateVersion> = yield retry(3, 1000, addonService.addons.updateVersion, {
      atomCommandId: id,
    });
    const responseSuccess: any = {
      ...response,
      sectionId: prevAddonSection.id,
      body: {
        ...response.body,
        id: prevAddonSection.id,
        parentCommandId: id,
      },
    };
    yield put(updateAddonVersion.success({ updatedAddon: responseSuccess }));
    yield put(updateSectionVersion.success({ updatedItem: responseSuccess.body }));
    yield put(setModalUpdateAddon(undefined));
    notifyAxiosHandler.handleSuccess(`${i18n.t('general.update', { text: i18n.t('general.successfully') })}`);
  } catch (error) {
    const error_ = error as Error;
    notifyAxiosHandler.handleError(error_);
    yield put(updateAddonVersion.failure(undefined));
  }
}

export function* watchUpdateSectionVersion() {
  yield takeLatest(getActionType(updateSectionVersion.request), handleUpdateSection);
}

export function* watchUpdateAddonVersion() {
  yield takeLatest(getActionType(updateAddonVersion.request), handleUpdateAddon);
}

export function* watchUpdateMegaMenuVersion() {
  yield takeLatest(getActionType(updateMegaMenuVersion.request), handleUpdateMegaMenu);
}
