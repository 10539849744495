import { CssColorVariable } from 'types/PresetStyles';
import { v4 } from 'uuid';

export const CLAVE: Array<{ name: string; cssClassName: string; colors: CssColorVariable[] }> = [
  {
    name: 'Clave',
    cssClassName: 'veda-color-clave',
    colors: [
      { id: v4(), name: '--color-primary', light: '#4840D8', dark: '#4840D8' },
      { id: v4(), name: '--color-secondary', light: '#EA7E4C', dark: '#EA7E4C' },
      { id: v4(), name: '--color-tertiary', light: '#1B3839', dark: '#1B3839' },
      { id: v4(), name: '--color-quaternary', light: '#EEF9FE', dark: '#EEF9FE' },
      { id: v4(), name: '--color-dark', light: '#080626', dark: '#ffffff' },
      { id: v4(), name: '--color-gray9', light: '#211F3C', dark: '#F3F3F4' },
      { id: v4(), name: '--color-gray8', light: '#393851', dark: '#E6E6E9' },
      { id: v4(), name: '--color-gray7', light: '#525167', dark: '#CECDD4' },
      { id: v4(), name: '--color-gray6', light: '#838293', dark: '#B5B4BE' },
      { id: v4(), name: '--color-gray5', light: '#9C9BA8', dark: '#9C9BA8' },
      { id: v4(), name: '--color-gray4', light: '#B5B4BE', dark: '#838293' },
      { id: v4(), name: '--color-gray3', light: '#CECDD4', dark: '#525167' },
      { id: v4(), name: '--color-gray2', light: '#E6E6E9', dark: '#393851' },
      { id: v4(), name: '--color-gray1', light: '#F3F3F4', dark: '#211F3C' },
      { id: v4(), name: '--color-light', light: '#FFFFFF', dark: '#080626' },
    ],
  },
  {
    name: 'Clave (Dark)',
    cssClassName: 'veda-color-dark-clave',
    colors: [
      { id: v4(), name: '--color-primary', light: '#4840D8', dark: '#4840D8' },
      { id: v4(), name: '--color-secondary', light: '#EA7E4C', dark: '#EA7E4C' },
      { id: v4(), name: '--color-tertiary', light: '#1B3839', dark: '#1B3839' },
      { id: v4(), name: '--color-quaternary', light: '#EEF9FE', dark: '#EEF9FE' },
      { id: v4(), name: '--color-dark', light: '#ffffff', dark: '#080626' },
      { id: v4(), name: '--color-gray9', light: '#F3F3F4', dark: '#211F3C' },
      { id: v4(), name: '--color-gray8', light: '#E6E6E9', dark: '#393851' },
      { id: v4(), name: '--color-gray7', light: '#CECDD4', dark: '#525167' },
      { id: v4(), name: '--color-gray6', light: '#B5B4BE', dark: '#838293' },
      { id: v4(), name: '--color-gray5', light: '#9C9BA8', dark: '#9C9BA8' },
      { id: v4(), name: '--color-gray4', light: '#838293', dark: '#B5B4BE' },
      { id: v4(), name: '--color-gray3', light: '#525167', dark: '#CECDD4' },
      { id: v4(), name: '--color-gray2', light: '#393851', dark: '#E6E6E9' },
      { id: v4(), name: '--color-gray1', light: '#211F3C', dark: '#F3F3F4' },
      { id: v4(), name: '--color-light', light: '#080626', dark: '#FFFFFF' },
    ],
  },
];
