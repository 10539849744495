import { useColorPicker } from 'components/ColorPicker2/context';
import { getColorTitle } from 'components/ColorPicker2/utils';
import Field from 'components/Field';
import SliderBeauty from 'components/SliderBeauty';
import SwitchBeauty from 'components/SwitchBeauty';
import { Title } from '@wiloke/ui';
import withDebounce from 'hocs/withDebounce';
import { FC } from 'react';
import { i18n } from 'translation';
import { Space, Text, View } from 'wiloke-react-core';
import * as styles from './styles';
import { getThemeVersion } from 'utils/functions/getThemeVersion';
import { useSelector } from 'react-redux';
import { themeVersionSelector } from 'store/selectors';

const SliderBeautyDebounce = withDebounce(SliderBeauty, 'value', 'onValueChange', 300);

export const Right: FC = () => {
  const { color, onChange } = useColorPicker();
  const themeVersion = useSelector(themeVersionSelector);
  const switchChecked = !/-freeze\)/g.test(color);
  const alpha = color.match(/,\s*(\d.)?\d*(?=\))/g)?.[0]?.replace(/,\s*/g, '') ?? '';
  const sliderValue = alpha !== '' ? Number(alpha) : 1;
  const themeV2 = getThemeVersion(themeVersion) === '>=2.0.0';

  const isAutoChangeDarkThemeVisibie = () => {
    if (themeV2) {
      return false;
    }
    return !/primary|secondary|tertiary|quaternary/g.test(color);
  };

  return (
    <View css={styles.right} backgroundColor="gray1">
      <Title
        titleNumberOfLines={3}
        title={getColorTitle(color)}
        text={i18n.t('general.color_settings_description')}
        titleCss={{ fontSize: '18px' }}
      />
      <Space size={20} />
      <Field label={i18n.t('general.opacity')}>
        <SliderBeautyDebounce
          value={sliderValue}
          min={0}
          max={1}
          step={0.1}
          borderColor="gray3"
          borderInputColor="gray3"
          onValueChange={value => {
            if (value === 1) {
              onChange(color.replace(/rgba\(|,.*$/g, '').replace(/--rgb-/g, '--'));
            } else {
              if (color.includes('rgba')) {
                onChange(color.replace(/,\s*(\d.)?\d*(?=\))/g, `, ${value}`));
              } else {
                onChange(`rgba(${color.replace(/var\(--/g, 'var(--rgb-')}, ${value})`);
              }
            }
          }}
        />
      </Field>
      {isAutoChangeDarkThemeVisibie() && (
        <Field label={i18n.t('general.auto_change_color_for_dark_theme')}>
          <SwitchBeauty
            checked={switchChecked}
            radius={6}
            borderColor="gray3"
            borderWidth={1}
            enableText={i18n.t('general.enable')}
            disableText={i18n.t('general.disable')}
            onValueChange={value => {
              if (color.includes('rgba')) {
                onChange(value ? color.replace(/-freeze\),/g, '),') : color.replace(/\),/g, '-freeze),'));
              } else {
                onChange(value ? color.replace(/-freeze\)/g, ')') : color.replace(/\)$/g, '-freeze)'));
              }
            }}
          />
          <Text size={12} color="gray8" css={{ marginTop: '4px' }}>
            Require dark mode addon to enable it.
          </Text>
        </Field>
      )}
    </View>
  );
};
