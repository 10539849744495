import { userApis } from 'services/UserService/apis';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const checkPurchaseCodeIsExistRequest = async ({ envatoThemeId }: { envatoThemeId: string }) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    const response = await userApis.purchaseCode.adminApi.checkPurchaseCodeIsExist({ envatoThemeId });
    return response;
  }
  const response = await userApis.purchaseCode.userApi.checkPurchaseCodeIsExist({ envatoThemeId });
  return response;
};
