import { getAddonsOfThemeAtom } from 'services/ThemeService/Logic/getAddonsOfThemeAtom';
import { pushToCache, readFromCache } from './utils/CacheStorage';
import { getAddonCacheKey } from './utils/CacheKeyControl';
import { AddonOfTheme_Atom_N_Client } from 'types/Addons';

const getAddons_ = async (addonCommandIds: string[]) => {
  const addons = await getAddonsOfThemeAtom({ addonCommandIds });
  const addonKeys = await Promise.all(
    addons.map(async addon => {
      if (addon.commandId && addon.enable) {
        const cacheKey = await pushToCache(JSON.stringify(addon), getAddonCacheKey(addon.commandId));
        return cacheKey;
      }
    }),
  );
  return addonKeys.reduce<string[]>((result, key) => (key ? result.concat(key) : result), []);
};

export const getAddons = async (addonCommandIds: string[], isRetry: boolean) => {
  if (isRetry) {
    const addonKeys = await Promise.all(
      addonCommandIds.map(async addonCommandId => {
        const cacheKey = getAddonCacheKey(addonCommandId);
        const dataFromCache = await readFromCache<AddonOfTheme_Atom_N_Client>(cacheKey);
        if (dataFromCache) {
          return cacheKey;
        } else {
          const newCacheKey = await getAddons_([addonCommandId]);
          return newCacheKey[0];
        }
      }),
    );
    return addonKeys;
  } else {
    return getAddons_(addonCommandIds);
  }
};
