import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import { SyncShopifyMessage } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { SyncThemeByResultOfExtractThemeToFileForSyncMessage } from 'packages/SyncThemeByResultOfExtractThemeToFileForSync/hooks/useSocketForSyncThemeByResultOfExtractThemeToFileForSync';
import { i18n } from 'translation';
import fetchAPI from 'utils/functions/fetchAPI';
import { areUndefined } from '../utils';

export interface PublishHeaderFooterVeda_BEExpectParameters {
  eventId: string;
  eventType: SyncShopifyMessage['eventType'] | SyncThemeByResultOfExtractThemeToFileForSyncMessage['eventType'];
}

interface PublishHeaderFooterVeda_BEResponse {
  message: string;
  info: {
    eventId: string;
    eventType: SyncShopifyMessage['eventType'] | SyncThemeByResultOfExtractThemeToFileForSyncMessage['eventType'];
  };
}

interface PublishHeaderFooterVeda extends DeepPartial<PublishHeaderFooterVeda_BEExpectParameters> {}

/**
 * Services thực hiện thay thế file header và footer của veda vào theme shopify admin
 */
export const publishHeaderFooterVeda = async (data: PublishHeaderFooterVeda) => {
  if (areUndefined(data.eventId, data.eventType)) {
    throw new Error(i18n.t('general.lack_of_params'));
  }
  const res: AxiosResponse<PublishHeaderFooterVeda_BEResponse> = await fetchAPI.request({
    method: 'PUT',
    params: {
      eventId: data.eventId,
      eventType: data.eventType,
    } as Pick<PublishHeaderFooterVeda_BEExpectParameters, 'eventId' | 'eventType'>,
    data: data as PublishHeaderFooterVeda_BEExpectParameters,
    url: `${configureApp.endpoint['shopify-connections']}/sync/shopify/me/themes/header-footer/veda`,
  });
  return res.data.message;
};
