export const en = {
  'Section Settings':
    'The section settings are where you can change the spacing, color, background image, and video for the section, and you also set the column and container properties.',
  'Section Name': 'Section name',
  'Background Fixed': 'Help the background image not move when scrolling the page',
  'Background Type': 'You will have many choices with images, videos, colors',
  'Background Color': 'Choose the color you have set up before or you can create a new color',
  'Background Image': 'You can upload an image or select from the free image library',
  'Background Video': 'Use youtube or vimeo for background video',
  'Background Size': 'You can choose the size type for the background image',
  'Enable Background Overlay': 'Turn on the color overlay layer on top of the background to help the content of the section stand out',
  'Background Overlay Color': 'Choose the color you have set up before or you can create a new color',
  'Background Overlay Blur': 'The background will have a blur layer on top',
  'Sub Heading': 'The subheading will help add additional information',
  Copyright: 'Provide a place to display copyright',
  'New Letter': '',
  'Mini Cart': 'A small cart will be displayed on the menu bar',
  'Quick View': 'A quick view button will be displayed on the product',
  'Add To Cart': 'An add to cart button will be displayed on the product',
  'Add To Wishlist': 'An add to wishlist button will be displayed on the product',
  'Add To Compare': 'An add to compare button will be displayed on the product',
  'Aspect Ratio': 'The aspect ratio will be used for the selected element',
  Text: 'A small editor can help you write quality content',
  Content: 'The main content of the section',
  Padding: 'The padding layer will help you create a distance inside between elements',
  Margin: 'The margin layer will help you create a distance outside between elements',
  Button: 'The button will help you create actions for users',
  Buttons: 'The button will help you create actions for users',
  Column: 'Edit columns on computers, tablets and phones',
  Product: 'Select a product and its settings',
  Products: 'Select a product and its settings',
  Collection: 'Select a collection and its settings',
  Collections: 'Select a collection and its settings',
  Heading: 'The heading will help you create quality content and help SEO friendlier',
  Design: 'You can customize the font styles such as color, font size, font; at the same time customize the layout as well as effects for elements',
  Category: '',
  Sortable: 'You can sort the elements by dragging and dropping',
  Navigation: 'You can create menus that can include mega menu for your website',
  Menu: 'You can create menus that can include mega menu for your website',
  Header: '',
  Footer: '',
  Social: 'Set up social links for your theme',
  Socials: 'Set up social links for your theme',
  Logo: 'Set up logo for your theme',
  Phone: 'Set up phone number for your theme',
  Email: 'Set up email for your theme',
  Website: 'Set up website name for your theme',
  Time: '',
  Date: '',
  Subscribe: '',
  Dark: '',
  Light: '',
  Enable: '',
  Disable: '',
  Sale: '',
  Setting: '',
  Settings: '',
  Color: 'Choose the color you have set up before or you can create a new color',
  'Compare Action': 'Change the content, icon and design for the compare button',
  'Compare Options Title': '',
  'Cart Action': 'Change the content, icon and design for the cart button',
  Arrow: '',
  Address: '',
  Title: '',
  Description: '',
  Decorate: '',
  'View All Button': '',
  Sidebar: 'Set up sidebar for your theme',
  Banner: '',
  'Enable Categories': '',
  'Categories Title': '',
  Categories: '',
  'Enable Caret': '',
  'Enable Flash Sale': '',
  'Flash Sale Text': '',
  'Enable Countdown': '',
  'Expiration Date': '',
  'Product Settings': '',
  'Enable Review': '',
  'Enable Sale': '',
  'Sale Design':
    'You can customize the font styles such as color, font size, font; at the same time customize the layout as well as effects for elements',
  'Product Quantity Displayed': '',
  'Enable Color Action': '',
  'Add To Cart Action': 'Change the content, icon and design for the add to cart button',
  'Wishlist Action': 'Change the content, icon and design for the wishlist button',
  'Quick View Action': 'Change the content, icon and design for the quick view button',
  Image: 'You can upload an image or select from the free image library',
  'Sub Title': 'The sub title will help add more necessary information',
  Position: '',
  Align: '',
  Information: '',
  'Logo Image': '',
  'Title Align': '',
  'Sub Title Align': '',
  'Text Align': 'Align left, right or center for the element you choose',
  'Description Align': 'Align left, right or center for the element you choose',
  'Account Action': 'Change the content, icon and design for the account button',
  'Search Action': 'Change the content, icon and design for the search button',
  Brands: '',
  Label: '',
  'Popular Post': '',
  Tags: '',
  Blog: '',
  Share: '',
  Collapse: '',
  'Title Design':
    'You can customize the font styles such as color, font size, font; at the same time customize the layout as well as effects for elements',
  'Sub Title Design':
    'You can customize the font styles such as color, font size, font; at the same time customize the layout as well as effects for elements',
  'Text Design':
    'You can customize the font styles such as color, font size, font; at the same time customize the layout as well as effects for elements',
  'Description Design':
    'You can customize the font styles such as color, font size, font; at the same time customize the layout as well as effects for elements',
  Link: 'You can customize the link from shopify, email, phone or manually',
  'Border Radius': 'You can change the corner radius for the selected element',
  'Image Border Radius': 'You can change the corner radius for the selected image',
  Variant: 'Select the variants to change your design for the element',
  'Section Width': '',
  'Container Width': '',
  'Container Gap': '',
  'Column Gap': '',
  Manual: '',
  Width: '',
  Divider: '',
  'Divider Color': '',
  'Divider Design':
    'You can customize the font styles such as color, font size, font; at the same time customize the layout as well as effects for elements',
  'Divider Margin': '',
  Icon: '',
  'Enable Icon': 'You can choose from the available icons or upload an icon as an image',
  'Icon Design':
    'You can customize the font styles such as color, font size, font; at the same time customize the layout as well as effects for elements',
  Choose: '',
  'Icon Settings': '',
  Price: '',
  Options: '',
  Name: '',
  Data: '',
  Availability: '',
  'In Stock Design':
    'You can customize the font styles such as color, font size, font; at the same time customize the layout as well as effects for elements',
  'Out of Stock Design':
    'You can customize the font styles such as color, font size, font; at the same time customize the layout as well as effects for elements',
  Testing: '',
  Layout: '',
  Vendor: '',
  Size: '',
  'Container Design':
    'You can customize the font styles such as color, font size, font; at the same time customize the layout as well as effects for elements',
  'Item Design':
    'You can customize the font styles such as color, font size, font; at the same time customize the layout as well as effects for elements',
  Item: '',
  'Item Column': '',
  'Content Width': '',
  'Icon Position': '',
  'Content Design':
    'You can customize the font styles such as color, font size, font; at the same time customize the layout as well as effects for elements',
  'Row Height': '',
  'Button Left': '',
  'Button Right': '',
  'Auto Play': '',
  Speed: '',
  Delay: '',
  'Slider Settings': '',
  'Slides Per View': '',
  Loop: '',
  'Enable Arrow': '',
  'Enable Pagination': '',
  'Advanced Mode': '',
  Animate: '',
  'Product Design':
    'You can customize the font styles such as color, font size, font; at the same time customize the layout as well as effects for elements',
  Gap: '',
  'Regular Price': '',
  'Sale Price': '',
  Height: '',
  Carousel: '',
  Video: '',
  'Play Button Design':
    'You can customize the font styles such as color, font size, font; at the same time customize the layout as well as effects for elements',
  Popup: '',
  'Popup Design':
    'You can customize the font styles such as color, font size, font; at the same time customize the layout as well as effects for elements',
  'Close Button': '',
  'Close Button Design':
    'You can customize the font styles such as color, font size, font; at the same time customize the layout as well as effects for elements',
  'General Settings': '',
  'Heading And Content': '',
  'Content Item': '',
  'Heading Position': '',
  Pagination: '',
  'Arrow Variant': '',
  'Arrow Position': '',
  'Arrow Align': '',
  'Arrow Icon': 'You can choose from the available icon or upload an icon as an image',
  'Left Arrow Icon': 'You can choose from the available icon or upload an icon as an image',
  'Right Arrow Icon': 'You can choose from the available icon or upload an icon as an image',
  Left: '',
  Right: '',
  'Media Type': '',
  'Min Height': '',
  'Max Height': '',
  'Min Width': '',
  'Max Width': '',
  Filter: '',
  Filters: '',
  'Home Page Title': '',
  Separator: '',
  'Customer Page Title': '',
  Component: '',
  'Tag Name': 'Select html tag to help SEO',
  Space: '',
  'Reversed Layout': '',
  'Sold Out': '',
  'Remove From Wishlist': '',
  'Remove From Compare': '',
  Clear: '',
  'Show More': '',
  'Show Less': '',
  'Sort by': '',
  Quantity: '',
  'View Detail': '',
  Refine: '',
  'Filter Name': '',
  'In Stock': '',
  'Out of Stock': '',
  'Accept Button': '',
  'Reject Button': '',
  Show: '',
  Hide: '',
} as const;
