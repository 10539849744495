import { PageCategory } from 'types/Page';
import { ActionTypes, createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';
import {
  createCategoryOfPageProduct,
  getCategoriesOfPageProduct,
  deleteCategoryOfPageProduct,
  updateCategoryOfPageProduct,
} from '../actions/actionPagesProduct';

type CategoryExtraActions = ActionTypes<
  typeof createCategoryOfPageProduct | typeof getCategoriesOfPageProduct | typeof deleteCategoryOfPageProduct | typeof updateCategoryOfPageProduct
>;

interface Actions {
  type: 'editProductPageCategory';
  payload: AtLeast<PageCategory, 'commandId'>;
}

interface State {
  categories: PageCategory[];
  getStatus: Status;
  createStatus: Status;
  deleteStatus: Record<string, Status>;
  updateStatus: Record<string, Status>;
}

export const slicePagesProduct = createSlice<State, Actions, CategoryExtraActions>({
  name: '@PageManager',
  initialState: {
    categories: [],
    createStatus: 'idle',
    getStatus: 'idle',
    deleteStatus: {},
    updateStatus: {},
  },
  reducers: [
    handleAction('editProductPageCategory', ({ state, action }) => {
      state.categories = state.categories.map(item => {
        if (item.commandId === action.payload.commandId) {
          return {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });
    }),
  ],
  extraReducers: [
    handleAction('@PageManager/getCategoriesOfPageProduct/request', ({ state }) => ({
      ...state,
      getStatus: 'loading',
    })),
    handleAction('@PageManager/getCategoriesOfPageProduct/success', ({ state, action }) => ({
      ...state,
      getStatus: 'success',
      categories: action.payload,
    })),
    handleAction('@PageManager/getCategoriesOfPageProduct/failure', ({ state }) => ({ ...state, getStatus: 'failure' })),

    handleAction('@PageManager/createCategoryOfPageProduct/request', ({ state }) => ({ ...state, createStatus: 'loading' })),
    handleAction('@PageManager/createCategoryOfPageProduct/success', ({ state, action }) => ({
      ...state,
      createStatus: 'success',
      categories: [action.payload, ...state.categories],
    })),
    handleAction('@PageManager/createCategoryOfPageProduct/failure', ({ state }) => ({ ...state, createStatus: 'failure' })),
    handleAction('@PageManager/deleteCategoryOfPageProduct/request', ({ state, action }) => {
      state.deleteStatus[action.payload.commandId] = 'loading';
    }),
    handleAction('@PageManager/deleteCategoryOfPageProduct/success', ({ state, action }) => {
      state.deleteStatus[action.payload.commandId] = 'success';
      state.categories = state.categories.filter(item => item.commandId !== action.payload.commandId);
    }),
    handleAction('@PageManager/deleteCategoryOfPageProduct/failure', ({ state, action }) => {
      state.deleteStatus[action.payload.commandId] = 'failure';
    }),
    handleAction('@PageManager/updateCategoryOfPageProduct/request', ({ state, action }) => {
      state.updateStatus[action.payload.commandId] = 'loading';
    }),
    handleAction('@PageManager/updateCategoryOfPageProduct/success', ({ state, action }) => {
      state.updateStatus[action.payload.commandId] = 'success';
      state.categories = state.categories.map(item => {
        if (item.commandId === action.payload.commandId) {
          return {
            ...item,
            commandId: action.payload.commandId,
            slug: action.payload.name,
            title: action.payload.description,
            belongsToPageTypes: action.payload.belongsToPageTypes,
          };
        }
        return item;
      });
    }),
    handleAction('@PageManager/updateCategoryOfPageProduct/failure', ({ state, action }) => {
      state.updateStatus[action.payload.commandId] = 'failure';
    }),
  ],
});

export const { editProductPageCategory } = slicePagesProduct.actions;
export const useEditProductPageCategory = createDispatchAction(editProductPageCategory);

export const pagesProductSelector = (state: AppState) => state.adminDashboard.pagesProduct;
