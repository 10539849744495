interface GetFileName {
  isExportForEnvato: boolean;
  themeName: string;
}

const getDate = () => {
  const now = new Date();
  return now.toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};

export const FORMAT = '[Có thể có gì đó ở trước]___["Envato" hoặc "Shopify"]+[tên theme]+[DD/MM/YYYY HH:mm:ss]___.json';
export const getFileName = ({ isExportForEnvato, themeName }: GetFileName) => {
  return `___[${isExportForEnvato ? 'Envato' : 'Shopify'}]+[${themeName}]+[${getDate()}]___`;
};
