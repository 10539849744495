import { call, delay } from 'redux-saga/effects';
import { handlePreviewInBuilderPage } from 'services/ShopifyConnection';
import { syncPage, SyncPage, SyncPageResult } from './syncPage';

export interface SyncPagesInThemeBuilderMode extends Pick<SyncPage, 'signalToReplaceAddonInLiquidCode'> {
  pagesParams: ReturnType<typeof handlePreviewInBuilderPage>['pagesParams'];
}

export interface SyncPagesInThemeBuilderModeResult {
  statusSyncPagesInThemeBuilderMode: SyncFulfillStatus;
  isNeedIgnoreReportError: boolean;
}

export function* syncPagesInThemeBuilderMode({ pagesParams, signalToReplaceAddonInLiquidCode }: SyncPagesInThemeBuilderMode) {
  for (const pageParams of pagesParams) {
    if (pageParams.pageType === 'giftCard') {
      yield delay(1000);
    } else {
      const { statusSyncPage, isNeedIgnoreReportError }: SyncPageResult = yield call(syncPage, {
        pageParams,
        signalToReplaceAddonInLiquidCode,
      });
      if (statusSyncPage !== 'success') {
        return {
          statusSyncPagesInThemeBuilderMode: 'failure',
          isNeedIgnoreReportError,
        } as SyncPagesInThemeBuilderModeResult;
      }
    }
  }
  return {
    statusSyncPagesInThemeBuilderMode: 'success',
    isNeedIgnoreReportError: false,
  } as SyncPagesInThemeBuilderModeResult;
}
