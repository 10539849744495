import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import { Store } from 'redux';
import { updateLastCacheOfVeda } from 'store/global/auth/actions';
import fetchAPI from 'utils/functions/fetchAPI';

interface ResponseSuccess {
  message: string;
  info: {
    lastCache: number;
  };
}

/** Update cache clousdflare */
// https://circular-terrier-0d1.notion.site/C-ch-Server-Cache-25eab377b91b4d148d11bad92a9a6e1c
export const updateVedaCacheVersion = async () => {
  try {
    const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
      url: `${configureApp.endpoint.users}/admin/cache/last-cache`,
      method: 'PUT',
    });
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { store } = require('store/configureStore');
    (store as Store<AppState>).dispatch(updateLastCacheOfVeda(response.data.info.lastCache));
    return;
  } catch {
    return;
  }
};
