import { AxiosResponse } from 'axios';
import fetchAPI from 'utils/functions/fetchAPI';
import { cUrl } from '../const';

interface ResponseSuccess {
  message: string;
  info: 'yes' | 'no';
}

export const getUserSettings = async () => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${cUrl}/options/keys/shopDevAllowable`,
    method: 'GET',
  });

  return response.data;
};
