import { mergeDeepLeft, sort } from 'ramda';
import { TextField } from 'services/ShopifyConnection/utils/VedaOutputToShopifyThemeEditor/@types/Shopify/InputSettings/TextField';
import { Locales } from 'services/ShopifyConnection/utils/VedaOutputToShopifyThemeEditor/@types/Veda/ShopifyLocales';
import { replaceExactVariableNameInLiquidCode } from 'services/ShopifyConnection/utils/VedaOutputToShopifyThemeEditor/@utils/replaceExactVariableNameInLiquidCode';
import { ignoreToReduceTotalLinesOfCode } from '../../../@utils/ignoreToReduceTotalLinesOfCode';
import { sortWithKeyOfFieldValues } from '../../../@utils/sortWithKeyOfFieldValues';
import { keys } from './@consts/keys';
import { ILIQUID_SettingStyleBox } from './@types/ILIQUID_SettingStyleBox';
import { ISCHEMA_SettingStyleBox } from './@types/ISCHEMA_SettingStyleBox';
import { ISETTING_SettingStyleBox } from './@types/ISETTING_SettingStyleBox';
import { getSignalReduceTotalLinesOfCode } from './utils/getSignalReduceTotalLinesOfCode';
import { toShopifyFieldId } from './utils/toShopifyFieldId';
import { vedaLabelsOfStyleBoxFieldToShopifyFieldLabel } from './utils/vedaLabelsOfStyleBoxFieldToShopifyFieldLabel';
import { vedaSummariesOfStyleBoxFieldToShopifyFieldInfo } from './utils/vedaSummariesOfStyleBoxFieldToShopifyFieldInfo';

interface RTSettingStyleBox {
  shopifyField: TextField[];
  locales: Locales;
}
export const SCHEMA_SettingStyleBox = ({ field, parentField, ...params }: ISCHEMA_SettingStyleBox): RTSettingStyleBox => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode ||
    (params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField }));
  let RLocales: Locales = {
    en: {},
    fr: {},
    vi: {},
  };
  const keysSorted = sort(sortWithKeyOfFieldValues, keys);
  const data = keysSorted.reduce<TextField[]>((res, key) => {
    const { newLabelOfKey, locales: localesOfLabel } = vedaLabelsOfStyleBoxFieldToShopifyFieldLabel(
      { ...params, field, parentField, isNeedReduceTotalLinesOfCode },
      key,
    );
    const { newInfoOfKey, locales: localesOfInfo } = vedaSummariesOfStyleBoxFieldToShopifyFieldInfo({
      ...params,
      field,
      parentField,
      isNeedReduceTotalLinesOfCode,
    });
    RLocales = mergeDeepLeft(RLocales, localesOfLabel);
    RLocales = mergeDeepLeft(RLocales, localesOfInfo);
    return res.concat({
      type: 'text',
      default: undefined,
      id: isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, key)
        : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, key),
      placeholder: undefined,
      info: newInfoOfKey,
      label: newLabelOfKey,
    });
  }, []);

  return {
    locales: RLocales,
    shopifyField: data as RTSettingStyleBox['shopifyField'],
  };
};

export const SETTING_SettingStyleBox = ({ field, parentField, ...params }: ISETTING_SettingStyleBox) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode ||
    (params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField }));

  return keys.reduce<Record<string, string | undefined>>((res, key) => {
    // check kĩ phòng lỗi
    const value = field.children[key];
    const valueString = typeof value === 'string' ? value : undefined;
    if (valueString !== undefined || isNeedReduceTotalLinesOfCode) {
      const fieldName = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, key)
        : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, key);
      return {
        ...res,
        [fieldName]: valueString,
      };
    }
    return res;
  }, {});
};

export const LIQUID_SettingStyleBox = ({ liquid, field, loopVariable, parentField, ...params }: ILIQUID_SettingStyleBox) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode ||
    (params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField }));
  // Nếu field thuộc array
  // - loopVariable nếu là array ->  Array được dùng trong forloop -> Thế `${loopVariable}.${field.name}.${key}` = `${loopVariable}.${toShopifyFieldId({ field, parentField }, key)}`
  //   Example: --> for item in content --> item.icon_design.id ==> KQ: item.content__icon_design.id
  if (loopVariable) {
    return keys.reduce<string>((res, key) => {
      const target = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, key)
        : `${loopVariable}.${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, key)}`;
      const source = `${loopVariable}.${field.name}.${key}`;
      return replaceExactVariableNameInLiquidCode({ liquid: res, source, target });
    }, liquid);
  }
  // Nếu field thuộc object
  // - parentField nếu là object -> Object được flat thành các "shopify input settings" -> Thế `${parentField.name}.${field.name}.${key}` = toShopifyFieldId
  //   Example: --> heading.icon_design.id ==> KQ: heading__icon_design.id
  else if (parentField) {
    return keys.reduce<string>((res, key) => {
      const target = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, key)
        : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, key)}"]`;
      const source = `${parentField.name}.${field.name}.${key}`;
      return replaceExactVariableNameInLiquidCode({ liquid: res, source, target });
    }, liquid);
  } else {
    return keys.reduce<string>((res, key) => {
      const target = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, key)
        : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, key)}"]`;
      const source = `${field.name}.${key}`;
      return replaceExactVariableNameInLiquidCode({ liquid: res, source, target });
    }, liquid);
  }
};
