import { getColorTitle } from 'components/ColorPicker2/utils';
import { v4 } from 'uuid';
import { defaultLanguage, languages } from '../../../@consts';
import { Locales } from '../../../@types/Veda/ShopifyLocales';
import { ThemeSettings } from '../../@types/ThemeSettings';

const localKeyGenByVedaFieldLabel = 'FieldGenByVedaFieldLabel';

const createLocaleKey = () => `_${localKeyGenByVedaFieldLabel}_${v4()}_`;

interface RTVedaFontToShopifyFieldLabel {
  newLabel: string;
  locales: Locales;
}
export const vedaFontToShopifyFieldLabel = (font: ThemeSettings['cssVariables']['fonts'][number]): RTVedaFontToShopifyFieldLabel => {
  const localeKey = createLocaleKey();
  const default_translations = { [localeKey]: '' };

  // const newLabel: RTVedaFontToShopifyFieldLabel['newLabel'] = `t:${localeKey}`;
  const locales: RTVedaFontToShopifyFieldLabel['locales'] = languages.reduce<RTVedaFontToShopifyFieldLabel['locales']>(
    (res, language) => {
      return {
        ...res,
        [language]: {
          [localeKey]: getColorTitle(font.name),
        },
      };
    },
    { en: default_translations, vi: default_translations, fr: default_translations },
  );

  // return {
  //   newLabel,
  //   locales,
  // };
  return {
    newLabel: locales[defaultLanguage][localeKey],
    locales,
  };
};
