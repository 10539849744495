export const ModalUpdateShopifyThemeId = {
  update_shopify_theme_id_title: 'Cập nhật shopify theme id',
  update_shopify_theme_id_description_1: 'Khi tùy chỉnh theme, dữ liệu được cập nhật vào ID của theme này. Đi tới',
  update_shopify_theme_id_description_2: 'Online Store',
  update_shopify_theme_id_description_3: 'để lấy ID của theme.',
  update_shopify_theme_description: 'Cập nhật shopify theme id',
  theme_removed_warning_title: 'Chú ý: Yêu cầu cập nhật theme - Có thể theme đã bị xoá',
  theme_removed_warning_description: 'Chủ đề bạn chọn để đồng bộ trang Veda đã bị xóa. Bạn nên chuyển sang chủ đề có sẵn khác',
  go_to_theme_area: 'Đến khu vực "Chủ đề".',
} as const;
