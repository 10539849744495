import { getPageOfThemeAtom } from 'services/ThemeService/Logic/getPageOfThemeAtom';
import { pushToCache, readFromCache } from './utils/CacheStorage';
import { getPageCacheKey } from './utils/CacheKeyControl';
import { BE_PageInThemeAtomNDraft } from 'services/ThemeService/Atom/types';
import { BaseParams } from './@types/BaseParams';
import { getPageOfThemeDraft } from 'services/ThemeService/Logic/getPageOfThemeDraft';

const getPage_ = async ({ commandId, entityVariant }: GetPage) => {
  const getPageOfThemeWithEntityVariant = entityVariant === 'Atom' ? getPageOfThemeAtom : getPageOfThemeDraft;
  const page = await getPageOfThemeWithEntityVariant({ commandId });
  if (page.pageSettings) {
    page.pageSettings.generalSettings.headerFooterEnabled = true;
  }
  const pageKey = await pushToCache(JSON.stringify(page), getPageCacheKey(commandId));
  return {
    pageKey,
    sectionCommandIds: page.sectionCommandIds,
  };
};

interface GetPage extends BaseParams {
  commandId: string;
}
export const getPage = async ({ commandId, isRetry, ...params }: GetPage) => {
  if (isRetry) {
    const cacheKey = getPageCacheKey(commandId);
    const dataFromCache = await readFromCache<BE_PageInThemeAtomNDraft>(cacheKey);
    if (dataFromCache) {
      return {
        pageKey: cacheKey,
        sectionCommandIds: dataFromCache.sectionCommandIds,
      };
    }
    return getPage_({ commandId, isRetry, ...params });
  }
  return getPage_({ commandId, isRetry, ...params });
};
