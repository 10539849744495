import { CssColorVariable } from 'types/PresetStyles';
import { v4 } from 'uuid';

export const LAVITE: Array<{ name: string; cssClassName: string; colors: CssColorVariable[] }> = [
  {
    name: 'Lavite',
    cssClassName: 'veda-color-lavite',
    colors: [
      { id: v4(), name: '--color-primary', light: '#FC5B82', dark: '#FC5B82' },
      { id: v4(), name: '--color-secondary', light: '#5CC4FF', dark: '#5CC4FF' },
      { id: v4(), name: '--color-tertiary', light: '#6fdb7f', dark: '#6fdb7f' },
      { id: v4(), name: '--color-quaternary', light: '#FBC473', dark: '#FBC473' },
      { id: v4(), name: '--color-dark', light: '#251f19', dark: '#ffffff' },
      { id: v4(), name: '--color-gray9', light: '#2D261F', dark: '#FAF6F1' },
      { id: v4(), name: '--color-gray8', light: '#3C332B', dark: '#F3EDE7' },
      { id: v4(), name: '--color-gray7', light: '#6A5D51', dark: '#E2D9D0' },
      { id: v4(), name: '--color-gray6', light: '#7E7266', dark: '#CEC4BA' },
      { id: v4(), name: '--color-gray5', light: '#ABA094', dark: '#ABA094' },
      { id: v4(), name: '--color-gray4', light: '#CEC4BA', dark: '#7E7266' },
      { id: v4(), name: '--color-gray3', light: '#E2D9D0', dark: '#6A5D51' },
      { id: v4(), name: '--color-gray2', light: '#F3EDE7', dark: '#3C332B' },
      { id: v4(), name: '--color-gray1', light: '#FAF6F1', dark: '#2D261F' },
      { id: v4(), name: '--color-light', light: '#ffffff', dark: '#251f19' },
    ],
  },
  {
    name: 'Lavite (Dark)',
    cssClassName: 'veda-color-dark-lavite',
    colors: [
      { id: v4(), name: '--color-primary', light: '#FC5B82', dark: '#FC5B82' },
      { id: v4(), name: '--color-secondary', light: '#5CC4FF', dark: '#5CC4FF' },
      { id: v4(), name: '--color-tertiary', light: '#6fdb7f', dark: '#6fdb7f' },
      { id: v4(), name: '--color-quaternary', light: '#FBC473', dark: '#FBC473' },
      { id: v4(), name: '--color-dark', light: '#ffffff', dark: '#251f19' },
      { id: v4(), name: '--color-gray9', light: '#FAF6F1', dark: '#2D261F' },
      { id: v4(), name: '--color-gray8', light: '#F3EDE7', dark: '#3C332B' },
      { id: v4(), name: '--color-gray7', light: '#E2D9D0', dark: '#6A5D51' },
      { id: v4(), name: '--color-gray6', light: '#CEC4BA', dark: '#7E7266' },
      { id: v4(), name: '--color-gray5', light: '#ABA094', dark: '#ABA094' },
      { id: v4(), name: '--color-gray4', light: '#7E7266', dark: '#CEC4BA' },
      { id: v4(), name: '--color-gray3', light: '#6A5D51', dark: '#E2D9D0' },
      { id: v4(), name: '--color-gray2', light: '#3C332B', dark: '#F3EDE7' },
      { id: v4(), name: '--color-gray1', light: '#2D261F', dark: '#FAF6F1' },
      { id: v4(), name: '--color-light', light: '#251f19', dark: '#ffffff' },
    ],
  },
];
