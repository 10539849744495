import Checkbox, { CheckboxProps } from 'components/Checkbox';
import InlineMenu from 'components/InlineMenu';
import NavItemSetting from 'components/NavItemSetting';
import TextInput from 'components/TextInput';
import { useSetSearchKeyUserAddon } from 'containers/ChooseTemplate/store/reducers/addons/user.sliceAddons';
import withDebounce from 'hocs/withDebounce';
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useGuarded from 'routes/useGuarded';
import { addonSelector, chooseTemplateVisibleSelector, sectionsSelector } from 'store/selectors';
import { i18n } from 'translation';
import { SectionCategoryTag } from 'types/Sections';
import { Divider, FontAwesomeBrandsName, FontAwesomeName, FontAwesomeType, Text, View } from 'wiloke-react-core';
import { useChangeMySectionCategory, useGetTemplateCategories, useSetTemplateBoardVisible } from '../store/actions';
import { useSetUserAddonsCategory } from '../store/reducers/addons/user.sliceAddonCategory';
import { useSetUserCategoriesSection } from '../store/reducers/sections/user.reducerCategory';
import * as styles from '../styles';

type NavLib = 'sections' | 'my_sections' | 'addons' | 'draft' | 'import' | 'admin';

export interface SettingNav<T> {
  label: string;
  description?: string;
  value: T;
  icon?: FontAwesomeName | FontAwesomeBrandsName;
  iconType?: FontAwesomeType;
}

const navs: SettingNav<NavLib>[] = [
  {
    value: 'sections',
    label: 'Sections',
    icon: 'ballot' as FontAwesomeName,
  },
  {
    label: 'Add-ons',
    value: 'addons',
    icon: 'puzzle-piece',
    iconType: 'fal',
  },
  {
    value: 'my_sections',
    label: 'Saved',
    icon: 'id-badge',
  },
  {
    value: 'import',
    label: 'Import',
    icon: 'file-import',
  },
];

const DebounceTextInput = withDebounce(TextInput, 'value', 'onValueChange', 300);

const CustomNavItem: FC<{
  checked: boolean;
  onValueChange: CheckboxProps['onValueChange'];
  title: string;
  isParent?: boolean;
  isChild?: boolean;
  count?: number;
}> = ({ checked, count, onValueChange, title, isParent = false, isChild = false }) => {
  return (
    <View>
      <Checkbox
        size="extra-small"
        checked={checked}
        containerCss={[
          styles.checkboxGroup.container,
          {
            paddingBottom: isChild || isParent ? '5px' : '10px',
          },
        ]}
        innerCss={styles.checkboxGroup.inner}
        onValueChange={onValueChange}
      >
        <View
          css={[
            styles.checkboxGroup.title,
            {
              fontWeight: isParent ? '500' : '400',
            },
          ]}
        >
          {title}
        </View>

        {!!count && count !== 0 && (
          <Text size={12} color="gray9" backgroundColor="gray2" css={styles.checkboxGroup.count}>
            {count}
          </Text>
        )}
      </Checkbox>
    </View>
  );
};

export const LibraryNav = () => {
  const { categories, getStatus, categorySlugs } = useSelector(sectionsSelector.categories);
  const { categorySlug: mySectionCategorySlug, categories: myCategories } = useSelector(sectionsSelector.savedSections);
  const { addonsNav, navAddonsId, getNavStatus } = useSelector(addonSelector.userAddonsCategory);
  const { navKeys } = useSelector(chooseTemplateVisibleSelector);
  const guard = useGuarded();
  const [search, setSearch] = useState<string | undefined>(undefined);
  const searchRef = useRef<HTMLInputElement | null>(null);

  const changeNavKeys = useSetTemplateBoardVisible();
  const changeMySectionCategory = useChangeMySectionCategory();
  const changeNavAddon = useSetUserAddonsCategory();
  const setSearchKeyUserAddon = useSetSearchKeyUserAddon();
  const getCategory = useGetTemplateCategories();
  const setUserCategoriesSection = useSetUserCategoriesSection();

  useEffect(() => {
    getCategory.request({ search, showParentOnly: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleChangeMenuKey = useCallback(
    (keys: string[]) => {
      changeNavKeys({
        navKeys: keys,
        isScrollingContent: false,
      });
    },
    [changeNavKeys],
  );

  const renderSearch = () => {
    return (
      <View
        ref={searchRef}
        css={({ colors }) => ({
          padding: '10px',
          borderBottom: `1px solid ${colors.gray2}`,
        })}
      >
        <DebounceTextInput
          block
          value={search}
          sizeInput="small"
          placeholder={i18n.t('builderPage.search', {
            textTransform: 'capitalize',
            text: i18n.t('general.category'),
          })}
          onValueChange={value => {
            setSearch(value);
          }}
        />
      </View>
    );
  };

  const handleSelectParentCateSection = (select: boolean, cate: SectionCategoryTag) => {
    if (select) {
      if (!!cate?.children?.length) {
        const ids: string[] = [];
        for (const child of cate.children) {
          ids.push(child.name);
        }
        setUserCategoriesSection(categorySlugs.concat(ids));
      } else {
        setUserCategoriesSection(categorySlugs.concat(cate.name));
      }
    } else {
      if (!!cate?.children?.length) {
        const ids: string[] = [];
        for (const child of cate.children) {
          ids.push(child.name);
        }
        setUserCategoriesSection(categorySlugs.filter(item => !ids.includes(item)));
      } else {
        setUserCategoriesSection(categorySlugs.filter(item => item !== cate.name));
      }
    }
  };

  const handleSelectChildCateSection = (select: boolean, cate: SectionCategoryTag) => {
    if (select) {
      setUserCategoriesSection(categorySlugs.concat(cate.name));
    } else {
      setUserCategoriesSection(categorySlugs.filter(item => item !== cate.name));
    }
  };

  const renderUserSectionNav = (item: SectionCategoryTag) => {
    const checked = !!item.children?.length ? item.children.every(child => categorySlugs.includes(child.name)) : categorySlugs.includes(item.name);
    return (
      <InlineMenu.Item width={238} key={item.name} itemId={item.name}>
        {!guard('admin', 'dev') && item.name.toLowerCase().includes('mega') ? (
          <NavItemSetting.Style2 title="" />
        ) : (
          <>
            <CustomNavItem
              key={item.commandId}
              checked={checked}
              onValueChange={val => handleSelectParentCateSection(val, item)}
              count={item.count}
              title={item.description}
              isParent={!!item.children?.length}
            />
            {item.children?.length &&
              item.children.map(childNav => (
                <View key={childNav.commandId} css={{ paddingLeft: '15px' }}>
                  <CustomNavItem
                    checked={categorySlugs.includes(childNav.name)}
                    onValueChange={val => handleSelectChildCateSection(val, childNav)}
                    count={childNav.count}
                    title={childNav.description}
                    isChild
                  />
                </View>
              ))}
          </>
        )}
        <Divider size={1} color="gray2" />
      </InlineMenu.Item>
    );
  };

  const renderSavedNav = (item: SectionCategoryTag) => {
    const { commandId, name, description } = item;

    return (
      <InlineMenu.Item width={238} key={commandId} itemId={commandId}>
        <NavItemSetting.Style2
          onClick={() => {
            changeMySectionCategory({ categorySlug: name });
          }}
          active={name === mySectionCategorySlug}
          title={description}
        />
        <Divider size={1} color="gray2" />
      </InlineMenu.Item>
    );
  };

  const renderUserAddonsNav = (item: SectionCategoryTag) => {
    const { commandId, name, count } = item;
    return (
      <InlineMenu.Item width={238} key={commandId} itemId={commandId}>
        <NavItemSetting.Style2
          onClick={() => {
            changeNavAddon({
              id: commandId,
              slug: name,
              addonQuantityOfCategory: Number(count ?? 0),
            });
            setSearchKeyUserAddon('');
          }}
          active={commandId === navAddonsId}
          title={name}
          badge={count?.toString()}
        />
        <Divider size={1} color="gray2" />
      </InlineMenu.Item>
    );
  };

  const renderDraftSectionNav = (item: string) => {
    return (
      <InlineMenu.Item width={238} key={item} itemId={item}>
        <NavItemSetting.Style2 title={item} active={navKeys.includes(item)} />
        <Divider size={1} color="gray2" />
      </InlineMenu.Item>
    );
  };

  const renderAdminSectionNav = (item: string) => {
    return (
      <InlineMenu.Item width={238} key={item} itemId={item}>
        <NavItemSetting.Style2 title={item} active={navKeys.includes(item)} />
        <Divider size={1} color="gray2" />
      </InlineMenu.Item>
    );
  };

  const renderSkeletonNav = [1, 2, 3, 4].map(item => (
    <InlineMenu.Item disabledClick={true} width={238} key={item} itemId={item.toString()}>
      <View width={238} height={35} backgroundColor="gray1" key={item} css={styles.skeletonNav}>
        <View backgroundColor="gray3" height={8} width={100} radius={3} />
      </View>
      <Divider size={1} color="gray2" />
    </InlineMenu.Item>
  ));

  const mappingNav: Record<NavLib, () => ReactNode> = {
    // users
    sections: () => (getStatus === 'loading' ? renderSkeletonNav : categories.map(renderUserSectionNav)),
    addons: () => (getNavStatus === 'loading' ? renderSkeletonNav : addonsNav.map(renderUserAddonsNav)),

    // general
    my_sections: () => (myCategories.length === 0 ? renderSkeletonNav : myCategories.map(renderSavedNav)),
    import: () => <></>,

    // dev
    draft: () => ['Sections', 'Addons', 'Mega-menu'].map(renderDraftSectionNav),

    // admin
    admin: () =>
      ['Sections', 'Addons', 'Mega-menu', 'Atom Categories', 'Product Categories', 'Product Categories by Topic'].map(renderAdminSectionNav),
  };

  return (
    <InlineMenu
      defaultItemIds={navKeys}
      onChange={handleChangeMenuKey}
      id={navKeys[0] === 'addons' ? 'navigation-addon' : undefined}
      width={navKeys[0] === 'import' ? 70 : 320}
      disabledScroll
    >
      {(guard('admin', 'dev')
        ? ([
            ...navs,
            {
              value: 'admin',
              label: 'Admin',
              icon: 'user-shield',
            },
            {
              value: 'draft',
              label: 'Dev',
              icon: 'user-tie',
            },
          ] as SettingNav<NavLib>[])
        : navs
      ).map(nav => (
        <InlineMenu.SubMenu
          key={nav.value}
          itemId={nav.value}
          titleWidth={70}
          width={nav.value === 'import' ? 0 : 250}
          title={<NavItemSetting.Style3 title={nav.label} iconName={nav.icon} active={navKeys.includes(nav.value)} />}
          Header={['sections'].includes(nav.value) && renderSearch()}
          onScroll={({ scrollTop }) => {
            if (searchRef.current) {
              if (scrollTop > 0) {
                searchRef.current.style.cssText = `
                  position: absolute;
                  top: 0;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 100%;
                  background-color: #FFF;
                  z-index: 10;
                `;
              } else {
                searchRef.current.style.cssText = `
                  position: relative;
                `;
              }
            }
          }}
        >
          {mappingNav[nav.value]()}
        </InlineMenu.SubMenu>
      ))}
    </InlineMenu>
  );
};
