import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import fetchAPI from 'utils/functions/fetchAPI';

interface Params {
  shopifyThemeId: string;
  themeName: string;
}
interface ResponseSuccess {
  message: string;
  info: {
    id: string;
    name: string;
    role: string;
    previewable: boolean;
    processing: boolean;
    created_at: string;
    updated_at: string;
    theme_store_id: number;
    admin_graphql_api_id: string;
  };
}
export const publishClientDraftTheme = async ({ shopifyThemeId, themeName }: Params) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${configureApp.endpoint['shopify-connections']}/sync/shopify/me/stores/themes/publish`,
    method: 'PUT',
    params: {
      themeName,
    },
    data: {
      shopifyThemeId,
    },
  });
  return response.data;
};
