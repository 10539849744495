import { Dropdown, Tooltip } from '@wiloke/ui';
import { Device, useSetResponsive } from 'containers/BuilderPage/store/responsive/slice';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { responsiveSelector } from 'store/selectors';
import { i18n } from 'translation';
import { FontAwesome, View } from 'wiloke-react-core';
import * as styles from './styles';
import { sectionIdActiveSelector } from 'store/selectors';
import setScrollTo from 'containers/IframePage/setScrollTo';

export const Responsive: FC = () => {
  const setResponsive = useSetResponsive();
  const responsive = useSelector(responsiveSelector);
  const sectionIdActive = useSelector(sectionIdActiveSelector);

  return (
    <Dropdown
      placement="bottomLeft"
      data={[
        {
          label: i18n.t('general.desktop'),
          value: 'desktop',
          icon: 'desktop',
          iconCss: {
            width: '20px',
            textAlign: 'center',
          },
        },
        {
          label: `${i18n.t('general.tablet')} (landscape)`,
          value: 'tablet-h',
          icon: 'tablet',
          iconCss: {
            width: '20px',
            textAlign: 'center',
            transform: 'rotate(90deg)',
          },
        },
        {
          label: `${i18n.t('general.tablet')} (portrait)`,
          value: 'tablet',
          icon: 'tablet',
          iconCss: {
            width: '20px',
            textAlign: 'center',
          },
        },
        {
          label: i18n.t('general.mobile'),
          value: 'mobile',
          icon: 'mobile',
          iconCss: {
            width: '20px',
            textAlign: 'center',
          },
        },
      ]}
      onClick={value => {
        setResponsive(value as Device);
        if (sectionIdActive) {
          setScrollTo(`[data-id="${sectionIdActive}"]`, { timeout: 200 });
        }
      }}
    >
      <View css={styles.iconWrap}>
        <Tooltip text={i18n.t('general.device')} css={styles.itemFull} placement="bottom">
          <FontAwesome
            type="far"
            name={responsive.replace(/-h$/g, '') as any}
            size={16}
            color={responsive === 'desktop' ? 'primary' : 'gray7'}
            css={[styles.icon, responsive === 'tablet-h' ? { transform: 'rotate(90deg)' } : {}]}
          />
        </Tooltip>
      </View>
    </Dropdown>
  );
};
