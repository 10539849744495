import { ThemeSchema } from '../../@types/Shopify/ThemeConfigs/ThemeSchema';
import { SettingFavicon } from './SettingFavicon/SettingFavicon';
import { SettingLogoHeight } from './SettingLogoHeight/SettingLogoHeight';
import { SettingLogoImage } from './SettingLogoImage/SettingLogoImage';
import { SettingLogoWidth } from './SettingLogoWidth/SettingLogoWidth';
import { getSchemaLabel } from './utils/getSchemaLabel';

export const SCHEMA_SettingLogo = (): ThemeSchema => {
  return {
    name: getSchemaLabel().newLabel,
    settings: [SettingLogoImage(), SettingLogoWidth(), SettingLogoHeight(), SettingFavicon()],
  };
};
