import { Button } from '@wiloke/ui';
import { Highlight } from 'components/Highlight';
import { CheckProgress } from 'components/CircleProgress';
import { FC } from 'react';
import { Space, Styles, useTheme, View } from 'wiloke-react-core';

interface StepInstallThemeSuccessProps {
  themeName?: string;
  onBack: () => void;
  onCustomize: () => void;
}

const progressStyle: Styles = {
  marginTop: '30px',
  marginBottom: '30px',
  width: '100px',
  height: '100px',
};

export const StepInstallThemeSuccess: FC<StepInstallThemeSuccessProps> = ({ themeName, onCustomize }) => {
  const { colors } = useTheme();
  return (
    <View css={{ fontSize: '16px' }}>
      <CheckProgress css={progressStyle} />
      <Highlight query={themeName ?? ''} highlightCss={{ color: colors.primary, fontWeight: '500' }}>
        {`Congrats! You've successfully installed the theme ${themeName}.`}
      </Highlight>
      <View>Explore Veda Builder to make your theme unique.</View>
      <Space size={40} />
      <Button
        radius={5}
        borderWidth={1}
        borderStyle="solid"
        borderColor="primary"
        backgroundColor="primary"
        size={'extra-small'}
        onClick={onCustomize}
      >
        Customize now
      </Button>
    </View>
  );
};
