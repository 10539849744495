import { AxiosResponse } from 'axios';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface DeletePageOfDraft {
  commandId: string;
}

interface ResponseSuccess {
  message: string;
}

export const deletePageOfDraft = async ({ commandId }: DeletePageOfDraft) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${baseUrl}/drafts/${commandId}`,
    method: 'DELETE',
  });
  return response.data;
};
