import { css, Styles, Theme } from 'wiloke-react-core';

export const container: Styles = {
  height: '100%',
};

export const content: Styles = {
  className: 'LeftBarMain-content',
  padding: '10px 10px 0',
};

export const footer = ({ colors }: Theme): Styles => ({
  padding: '10px',
  borderTop: `1px solid ${colors.gray3}`,
  backgroundColor: colors.light,
  display: 'flex',
  alignItems: 'center',
  columnGap: '10px',
});

export const btn: Styles = {
  height: '46px',
};

export const clearBtn: Styles = {
  height: '46px',
  width: '46px',
  flexShrink: 0,
};

export const header = ({ colors }: Theme): Styles => ({
  height: '54px',
  borderBottom: `1px solid ${colors.gray3}`,
  backgroundColor: colors.light,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
});

export const headerLeft = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const back = ({ colors }: Theme) => css`
  border: 1px solid ${colors.gray3};
  border-radius: 4px;
`;

export const buttonAddSection = (disabled: boolean) => ({ colors }: Theme) => css`
  display: flex;
  gap: 6px;
  align-items: center;
  fontsize: 14px;
  color: ${disabled ? colors.quaternary : colors.primary};
  cursor: pointer;
`;

export const iconAdvanced: Styles = {
  padding: '2px 4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const buildFaster = ({ colors }: Theme) => css`
  font-size: 10px;
  padding: 2px 4px;
  white-space: nowrap;
  font-weight: 600;

  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  color: ${colors.light};

  animation-name: {
    0% {
      background-color: ${colors.danger};
    }
    50% {
      background-color: ${colors.primary};
    }
    100% {
      background-color: ${colors.danger};
    }
  }
`;
