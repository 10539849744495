import { watchUpdateStatusBlankPage } from './watchUpdateStatusBlankPage';
import { watchDeleteBlankPages } from './watchDeleteBlankPages';
import { watchGetBlankPages } from './watchGetBlankPages';
import { watchLoadMoreBlankPages } from './watchLoadMore';
import { watchDuplicateBlankPage } from 'containers/Admin/PageBuilder/BlankPage/stores/sagas/watchDuplicateBlankPage';

export const sagasBlankPage = [
  watchUpdateStatusBlankPage,
  watchDeleteBlankPages,
  watchGetBlankPages,
  watchLoadMoreBlankPages,
  watchDuplicateBlankPage,
];
