import { Button } from '@wiloke/ui';
import { FC } from 'react';
import { i18n } from 'translation';
import { Text, View } from 'wiloke-react-core';
import { RecommendCardProps } from './RecommendCard';
import * as styles from './styles';

export const RecommendCardStyle2: FC<RecommendCardProps> = ({ description, title, cssContainer, image, onClick }) => {
  return (
    <View borderColorHover="secondary" css={[styles.container2, cssContainer]}>
      <View css={[styles.appImage, { marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }]}>
        <View
          css={{
            width: '50px',
            height: '50px',
            overflow: 'hidden',
            borderRadius: '6px',
            alignItems: 'stretch',
          }}
        >
          <View
            tagName="img"
            src={image}
            css={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </View>

        <View css={{ marginTop: '5px' }}>
          <Button
            backgroundColor="light"
            borderColor="gray2"
            borderWidth={1}
            borderStyle="solid"
            color="gray6"
            radius={4}
            borderColorHover="secondary"
            backgroundColorHover="secondary"
            colorHover="light"
            size="extra-small"
            onClick={onClick}
            css={{ padding: '6px 12px', fontSize: '13px' }}
          >
            <Text tagName="span" fontFamily="secondary">
              {i18n.t('adminDashboard.get', { text: i18n.t('adminDashboard.app') })}
            </Text>
          </Button>
        </View>
      </View>

      <View>
        <Text
          numberOfLines={1}
          fontFamily="secondary"
          color="gray9"
          css={[
            styles.title,
            {
              marginBottom: '5px',
            },
          ]}
          dangerouslySetInnerHTML={{ __html: title ?? '' }}
        ></Text>
        <View css={styles.descriptionContainer}>
          <Text numberOfLines={3} fontFamily="secondary" css={styles.description} color="gray7">
            {description}
          </Text>
        </View>
      </View>
    </View>
  );
};
