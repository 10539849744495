import { notification } from 'antd';
import { call, put, retry, select, takeLatest, SagaReturnType, all } from 'redux-saga/effects';
import { createThemeDraft } from 'services/ThemeService/Logic/createThemeDraft';
import { deletePageOfThemeDraft } from 'services/ThemeService/Logic/deletePageOfThemeDraft';
import { socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector } from 'store/selectors';
import { i18n } from 'translation';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { createThemeDraft as actionCreateThemeDraft } from '../actions/actionThemesDraft';
import { setModalCreateThemeDraft } from '../reducers/sliceThemesDraft';
import { handleClonePageAtomServiceToThemeAtomService } from './utils/handleClonePageAtomServiceToThemeAtomService';

function* handleCreateThemeDraft({ payload }: ReturnType<typeof actionCreateThemeDraft.request>) {
  const { label, pageCommandIds, featuredImage, screenshot1, themeSettings, globalJs, globalScss, vendors, onFulfill } = payload;
  const { eventId }: SagaReturnType<typeof socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector> = yield select(
    socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector,
  );
  if (!eventId) {
    yield put(actionCreateThemeDraft.failure(undefined));
    onFulfill();
    notification.error({ message: i18n.t('general.system_error'), description: 'Không có "eventId"' });
  } else {
    const newCommandIds: SagaReturnType<typeof handleClonePageAtomServiceToThemeAtomService> = yield call(
      handleClonePageAtomServiceToThemeAtomService,
      {
        pageAtomCommandIds: pageCommandIds,
        eventId,
        entityType: 'Draft',
      },
    );
    try {
      const response: Awaited<ReturnType<typeof createThemeDraft>> = yield retry(3, 1000, createThemeDraft, {
        themeSettings,
        featuredImage,
        screenshot1,
        globalJs,
        globalScss,
        label,
        pageCommandIds: newCommandIds,
        vendors,
      });

      yield put(actionCreateThemeDraft.success({ data: response }));
      yield put(setModalCreateThemeDraft(false));
      notifyAxiosHandler.handleSuccess(i18n.t('general.create', { text: i18n.t('general.successfully') }));
    } catch (error) {
      const error_ = error as Error;
      notifyAxiosHandler.handleError(error_);
      yield put(actionCreateThemeDraft.failure(undefined));
      try {
        yield all(
          newCommandIds.map(pageCommandId => {
            return retry(3, 1000, deletePageOfThemeDraft, { commandId: pageCommandId });
          }),
        );
      } catch {}
    } finally {
      onFulfill();
    }
  }
}

export function* watchCreateThemeDraft() {
  yield takeLatest(getActionType(actionCreateThemeDraft.request), handleCreateThemeDraft);
}
