import { mergeDeepLeft } from 'ramda';
import { TextField } from '../../../@types/Shopify/InputSettings/TextField';
import { Locales } from '../../../@types/Veda/ShopifyLocales';
import { replaceExactVariableNameInLiquidCode } from '../../../@utils/replaceExactVariableNameInLiquidCode';
import { vedaLabelToShopifyFieldLabel } from './utils/vedaLabelToShopifyFieldLabel';
import { vedaSummaryToShopifyFieldInfo } from './utils/vedaSummaryToShopifyFieldInfo';
import { ISCHEMA_SettingVideo } from './@types/ISCHEMA_SettingVideo';
import { ISETTING_SettingVideo } from './@types/ISETTING_SettingVideo';
import { toShopifyFieldId } from './utils/toShopifyFieldId';
import { ILIQUID_SettingVideo } from './@types/ILIQUID_SettingVideo';
import { ignoreToReduceTotalLinesOfCode } from '../../../@utils/ignoreToReduceTotalLinesOfCode';
import { getSignalReduceTotalLinesOfCode } from './utils/getSignalReduceTotalLinesOfCode';

interface RTSettingVideo {
  shopifyField: TextField;
  locales: Locales;
}
export const SCHEMA_SettingVideo = ({ field, parentField, ...params }: ISCHEMA_SettingVideo): RTSettingVideo => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField });
  const { newLabel, locales: labelLocales } = vedaLabelToShopifyFieldLabel({ ...params, field, parentField, isNeedReduceTotalLinesOfCode });
  const { newInfo, locales: infoLocales } = vedaSummaryToShopifyFieldInfo({ ...params, field, parentField, isNeedReduceTotalLinesOfCode });
  const value = typeof field.children === 'string' && !!field.children ? field.children : undefined;
  return {
    shopifyField: {
      label: newLabel,
      info: newInfo,
      id: isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field)
        : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }),
      type: 'text',
      placeholder: undefined,
      default: value,
    },
    locales: mergeDeepLeft(labelLocales, infoLocales),
  };
};

export const SETTING_SettingVideo = ({ field, parentField, ...params }: ISETTING_SettingVideo) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField });
  // check kĩ phòng lỗi
  const value = typeof field.children === 'string' ? field.children : undefined;
  if (value !== undefined) {
    const fieldName = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode });
    return { [fieldName]: field.children };
  }
  return {};
};

export const LIQUID_SettingVideo = ({ field, parentField, liquid, loopVariable, ...params }: ILIQUID_SettingVideo) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField });
  /// Nếu field thuộc array
  // - loopVariable nếu là array ->  Array được dùng trong forloop -> Thế `${loopVariable}.${field.name}.${key}` = `${loopVariable}.${toShopifyFieldId({ field, parentField }, key)}`
  //   Example: --> for item in content --> item.icon_design.id ==> KQ: item.content__icon_design.id
  if (loopVariable) {
    const target = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : `${loopVariable}.${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode })}`;
    const source = `${loopVariable}.${field.name}`;
    return replaceExactVariableNameInLiquidCode({ liquid, source, target });
  }
  // Nếu field thuộc object
  // - parentField nếu là object -> Object được flat thành các "shopify input settings" -> Thế `${parentField.name}.${field.name}.${key}` = toShopifyFieldId
  //   Example: --> heading.icon_design.id ==> KQ: heading__icon_design.id
  else if (parentField) {
    const target = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode })}"]`;
    const source = `${parentField.name}.${field.name}`;
    return replaceExactVariableNameInLiquidCode({ liquid, source, target });
  } else {
    const target = isNeedReduceTotalLinesOfCode
      ? getSignalReduceTotalLinesOfCode(field)
      : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode })}"]`;
    const source = field.name;
    return replaceExactVariableNameInLiquidCode({ liquid, source, target });
  }
};
