import { AdminTheme } from 'types/Theme';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { handleGlobalTranslationsNColorSchemesInThemeAtom } from '../Adapter/handleGlobalTranslationsNColorSchemes';
import { themeApis } from '../apis';

interface GetThemeAtom {
  commandId: string;
}

export const getThemeAtom = async ({ commandId }: GetThemeAtom): Promise<AdminTheme> => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const atomResponse = await themeApis.atom.adminApi.theme.getAtom({ commandId, cacheVersion: lastCache });
    const productResponse = await themeApis.vedaApplication.adminApi.theme.getProductsWithParentCommandIds({
      parentCommandIds: atomResponse.info.commandId,
      cacheVersion: lastCache,
    });
    const withGlobalTranslationAdapter = handleGlobalTranslationsNColorSchemesInThemeAtom(atomResponse.info);
    return {
      ...withGlobalTranslationAdapter,
      vedaProductRepresent: productResponse.info.find(item => item.parentCommandId === atomResponse.info.commandId),
    };
  }
  if (role === 'dev' || role === 'support') {
    const atomResponse = await themeApis.atom.devApi.theme.getAtom({ commandId, cacheVersion: lastCache });
    const withGlobalTranslationAdapter = handleGlobalTranslationsNColorSchemesInThemeAtom(atomResponse.info);
    return {
      ...withGlobalTranslationAdapter,
      vedaProductRepresent: undefined,
    };
  }
  throw new RoleException();
};
