import { StackNavigatorState } from '@wiloke/ui';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';
import { LeftBarParamList } from 'containers/BuilderPage/components/SidebarScreen/SidebarScreen';
import { usePasteStyleFlow } from 'containers/BuilderPage/store/copyStyle/actions';
import { useClearDraftData, useSetDataCopyStyle } from 'containers/BuilderPage/store/copyStyle/sliceCopyStyle';
import {
  useSetModalAdminAddonsVisible,
  useSetModalAdminSectionVisible,
  useSetModalDevAddonsVisible,
  useSetModalDevSectionVisible,
} from 'containers/BuilderPage/store/saveForBuilder/slice';
import { useSectionDeleteFlow, useSectionDuplicateFlow } from 'containers/BuilderPage/store/toolbarActions/action';
import { useSetTemplateBoardVisible } from 'containers/ChooseTemplate/store/actions/actionTemplateBoardVisible';
import {
  deleteCache,
  pushToCache,
  readFromCache,
} from 'packages/ExtractThemeToFileForSync/store/sagas/watchExtractThemeToFileForSync/steps/utils/CacheStorage';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAddToFooter, useAddToHeader, useAddToMain } from 'store/actions/actionPages';
import { useSetSectionIdActive } from 'store/actions/actionSectionIdActive';
import { useReorderFields } from 'store/global/allowReorderingFields/slice';
import { useSetSectionIdCodeVisible } from 'store/global/sectionIdCodeVisible/slice';
import { useSetActiveAddon, useSetVisibleModalInstallAddon } from 'store/global/themeAddons';
import { authSelector, pageSectionsSelector, themeAddonsSelector } from 'store/selectors';
import { PageSection } from 'types/Sections';
import { PLAN_STATIC_DATA, VEDA_FUNCTION_ENUM } from 'utils/constants/PlanEnum';
import { Consts } from 'utils/constants/constants';
import { isSectionAddons } from 'utils/functions/checkSectionType';
import { CopyStyleStorage } from 'utils/functions/copyStyle/utils/constants';
import fileDownload from 'utils/functions/fileDownload';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pmParent } from 'utils/functions/postMessage';
import { validatePlan } from 'utils/functions/validatePlan';
import { vedaFile } from 'utils/functions/vedaFile';
import { Placement } from '../containers/BuilderPage/components/SectionDropDown/data';

export interface AddonsToKeepItem {
  label: string;
  addonId: string;
  active: boolean;
  disabled: boolean;
}

const useDropdownActions = (navigation: StackNavigatorState<LeftBarParamList>, placement: Placement = 'out') => {
  const [sectionIdActiveState, setSectionIdActiveState] = useState('');
  const [modalRenameVisible, setModalRenameVisible] = useState(false);
  const pageSections = useSelector(pageSectionsSelector);
  const sectionActive = pageSections.find(section => section.id === sectionIdActiveState);
  const { data: themeAddons } = useSelector(themeAddonsSelector);

  const setSectionIdCodeVisible = useSetSectionIdCodeVisible();
  const reorderFields = useReorderFields();
  const setSectionIdActive = useSetSectionIdActive();
  const addToHeader = useAddToHeader();
  const addToMain = useAddToMain();
  const addToFooter = useAddToFooter();
  const sectionDuplicateFlow = useSectionDuplicateFlow();
  const sectionDeleteFlow = useSectionDeleteFlow();
  const setTemplateBoardVisible = useSetTemplateBoardVisible();
  const setActiveAddon = useSetActiveAddon();

  const setModalDevAddonsVisible = useSetModalDevAddonsVisible();
  const setModalAdminAddonsVisible = useSetModalAdminAddonsVisible();
  const setModalAdminSectionVisible = useSetModalAdminSectionVisible();
  const setModalDevSectionVisible = useSetModalDevSectionVisible();
  const setVisibleModalInstallAddon = useSetVisibleModalInstallAddon();
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();
  const setDataCopyStyle = useSetDataCopyStyle();
  const clearDraftData = useClearDraftData();
  const pasteStyleFlow = usePasteStyleFlow();

  const { role } = getUserInfo();
  const { plan } = useSelector(authSelector);

  const dropdownActions: Record<string, (sectionId: string) => void> = {
    editcode: sectionId => {
      setSectionIdCodeVisible({ sectionId });
      setSectionIdActive('');
      pmParent.emit('@section/sectionIdActive', '');
    },
    duplicate: async () => {
      sectionDuplicateFlow({
        sectionIdActive: sectionIdActiveState,
        goBack: navigation.goBack,
      });
    },
    delete: sectionId => {
      const currentAddons = themeAddons.find(item => item.sectionId === sectionId);
      if (currentAddons) {
        setActiveAddon({ addon: currentAddons });
        setVisibleModalInstallAddon(true);
      } else {
        sectionDeleteFlow({
          sectionIdActive: sectionIdActiveState,
          goBack: navigation.goBack,
        });
      }
    },
    rename: () => {
      setModalRenameVisible(true);
    },
    reorder: () => {
      if (placement === 'in') {
        reorderFields(true);
      }
    },
    export: sectionId => {
      if (validatePlan({ userPlan: plan.planHandle, itemPlan: VEDA_FUNCTION_ENUM.export_import_section, role }) === 'pass') {
        const currentSection = pageSections.find(item => item.id === sectionId);
        if (currentSection && !isSectionAddons(currentSection.type)) {
          const data = vedaFile.pack(currentSection);
          const name = `Section-${currentSection.label.replace(/\s/g, '-')}.${Consts.AppName}`;
          fileDownload({
            data,
            name,
          });
        } else {
          const currentAddons = themeAddons.find(item => item.sectionId === sectionId);
          if (currentAddons) {
            const data = vedaFile.pack(currentAddons);
            const name = `Addons-${currentAddons?.label.replace(/\s/g, '-')}.${Consts.AppName}`;
            fileDownload({
              data,
              name,
            });
          }
        }
      } else {
        setPlanRequesting({
          handle: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_section].handle,
          type: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_section].type,
          pricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_section].pricePerMonth,
          pricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_section].pricePerYear,
          title: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_section].title,
          originalPricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_section].pricePerMonth,
          originalPricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_section].pricePerYear,
        });
        setValidateCouponVisible(true);
      }
    },
    move_to_header: sectionId => {
      if (validatePlan({ userPlan: plan.planHandle, itemPlan: VEDA_FUNCTION_ENUM.header_footer, role }) === 'pass') {
        addToHeader(sectionId);
      } else {
        setPlanRequesting({
          handle: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].handle,
          type: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].type,
          pricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].pricePerMonth,
          pricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].pricePerYear,
          title: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].title,
          originalPricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].pricePerMonth,
          originalPricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].pricePerYear,
        });
        setValidateCouponVisible(true);
      }
    },
    move_to_main: addToMain,
    move_to_footer: sectionId => {
      if (validatePlan({ userPlan: plan.planHandle, itemPlan: VEDA_FUNCTION_ENUM.header_footer, role }) === 'pass') {
        addToFooter(sectionId);
      } else {
        setPlanRequesting({
          handle: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].handle,
          type: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].type,
          pricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].pricePerMonth,
          pricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].pricePerYear,
          title: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].title,
          originalPricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].pricePerMonth,
          originalPricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].pricePerYear,
        });
        setValidateCouponVisible(true);
      }
    },
    setting: sectionId => {
      if (isSectionAddons(sectionActive?.type)) {
        const currentAddons = themeAddons.find(item => item.sectionId === sectionId);
        if (currentAddons) {
          setActiveAddon({ addon: currentAddons });
          if (role === 'dev') {
            setModalDevAddonsVisible(true);
          }
          if (role === 'admin') {
            setModalAdminAddonsVisible(true);
          }
        }
      } else {
        if (role === 'admin') {
          setModalAdminSectionVisible(true);
        }
        if (role === 'dev') {
          setModalDevSectionVisible(true);
        }
      }
    },
    import: sectionId => {
      if (validatePlan({ userPlan: plan.planHandle, itemPlan: VEDA_FUNCTION_ENUM.export_import_section, role }) === 'pass') {
        const sectionIndex = pageSections.findIndex(section => section.id === sectionId);
        const currentSection = pageSections.find(item => item.id === sectionId) as PageSection;
        if (isSectionAddons(currentSection.type)) {
          setTemplateBoardVisible({
            navKeys: ['import', 'Addons'],
          });
        } else {
          setTemplateBoardVisible({
            navKeys: ['import', 'Section'],
          });
        }
        setTemplateBoardVisible({ visible: true, index: sectionIndex });
      } else {
        setPlanRequesting({
          handle: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_section].handle,
          type: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_section].type,
          pricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_section].pricePerMonth,
          pricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_section].pricePerYear,
          title: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_section].title,
          originalPricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_section].pricePerMonth,
          originalPricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.export_import_section].pricePerYear,
        });
        setValidateCouponVisible(true);
      }
    },
    copy: async () => {
      const cachedDraftData = await readFromCache(CopyStyleStorage.draft_data);
      if (!!cachedDraftData) {
        await deleteCache(CopyStyleStorage.copied_data);
        await pushToCache(JSON.stringify(cachedDraftData), CopyStyleStorage.copied_data);
      }
      setDataCopyStyle({});
      clearDraftData({});
    },
    paste: () => {
      pasteStyleFlow();
    },
  };

  const setDisableModalName = () => {
    setModalRenameVisible(false);
  };

  return {
    dropdownActions,
    modalRenameVisible,
    sectionActive,
    sectionIdActiveState,
    setDisableModalName,
    setSectionIdActiveState,
  };
};

export default useDropdownActions;
