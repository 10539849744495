import { userApis } from 'services/UserService/apis';
import { AdminTheme } from 'types/Theme';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { handleGlobalTranslationsNColorSchemesInThemeAtom } from '../Adapter/handleGlobalTranslationsNColorSchemes';
import { themeApis } from '../apis';

interface AppendPageToThemeAtom {
  theme: Required<
    Pick<
      Parameters<typeof themeApis.atom.adminApi.theme.updateAtom>[0]['theme'],
      'commandId' | 'pageCommandIds' | 'featuredImage' | 'screenshot1' | 'label'
    >
  >;
}

export const appendPageToThemeAtom = async ({ theme }: AppendPageToThemeAtom): Promise<AdminTheme> => {
  const { role, id } = getUserInfo();
  if (role === 'admin') {
    const response = await themeApis.atom.adminApi.theme.updateAtom({
      theme: {
        ...theme,
        lastModifiedBy: id,
      },
    });
    await userApis.user.adminApi.updateVedaCacheVersion();
    return handleGlobalTranslationsNColorSchemesInThemeAtom(response.info);
  }
  throw new RoleException();
};
