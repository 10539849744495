import { SocketMessage } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { GetAdditionalDataRelateToShopify_BEExpectResponse } from 'services/ShopifyConnection';
import { GeneralSettingsAddon } from 'types/Addons';
import { Result } from 'types/Result';
import { createAction, createAsyncAction, createDispatchAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';

export const setEventIdOfSyncShopifyNClonePageAtomServiceToThemeAtomService = createAction(
  '@syncShopifyNClonePageAtomServiceToThemeAtomService/setEventIdOfSyncShopifyNClonePageAtomServiceToThemeAtomService',
  (eventId: string | undefined) => eventId,
);
export const useSetEventIdOfSyncShopifyNClonePageAtomServiceToThemeAtomService = createDispatchAction(
  setEventIdOfSyncShopifyNClonePageAtomServiceToThemeAtomService,
);

export const syncToShopify = createAsyncAction([
  '@syncShopifyNClonePageAtomServiceToThemeAtomService/syncingResult',
  '@syncShopifyNClonePageAtomServiceToThemeAtomService/syncedResult',
  '@syncShopifyNClonePageAtomServiceToThemeAtomService/syncFailureResult',
])<
  {
    entityVariant: EntityType;
    result: Result;
    isOverrideIndividualPages: GetAdditionalDataRelateToShopify_BEExpectResponse['info']['isOverrideIndividualPages'];
    isSaveAsLandingPage?: boolean;
    onSyncFulfill?: () => void;
    deletedAddonIds: Array<{ id: GeneralSettingsAddon['id'] }>;
    disableVedaHeaderFooter?: boolean;
  },
  undefined,
  undefined
>();

export const previewWithShopify = createAsyncAction([
  '@syncShopifyNClonePageAtomServiceToThemeAtomService/syncingPreview',
  '@syncShopifyNClonePageAtomServiceToThemeAtomService/syncedPreview',
  '@syncShopifyNClonePageAtomServiceToThemeAtomService/syncFailurePreview',
])<{ result: Result; onSyncFulfill?: () => void }, undefined, undefined>();
export const usePreviewWithShopify = createDispatchAsyncAction(previewWithShopify);

export const setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService = createAction(
  '@syncShopifyNClonePageAtomServiceToThemeAtomService/setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService',
  (payload: { eventType: SocketMessage['eventType']; status: Status; socketData: SocketMessage | undefined }) => payload,
);
export const useSetStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService = createDispatchAction(
  setStreamSocketOfSyncShopifyNClonePageAtomServiceToThemeAtomService,
);

export const resetSyncToShopifyStatus = createAction('@syncShopifyNClonePageAtomServiceToThemeAtomService/resetSyncToShopifyStatus');
export const useResetSyncToShopifyStatus = createDispatchAction(resetSyncToShopifyStatus);

export const resetPreviewWithShopifyStatus = createAction('@syncShopifyNClonePageAtomServiceToThemeAtomService/resetPreviewWithShopifyStatus');
export const useResetPreviewWithShopifyStatus = createDispatchAction(resetPreviewWithShopifyStatus);

export const resetAllState = createAction('@syncShopifyNClonePageAtomServiceToThemeAtomService/resetAllState');
export const useResetAllState = createDispatchAction(resetAllState);
