import { Button, ProgressLoader } from '@wiloke/ui';
import { Carousel } from 'components/Carousel/Carousel';
import ChooseImage from 'components/ChooseImage';
import { ColorSchemeFieldUI } from 'components/ColorSchemeFieldUI';
import { MessageMaintain } from 'components/MessageMaintain';
import { createPublishStepLoading } from 'components/PublishStepLoading';
import SliderBeauty from 'components/SliderBeauty';
import { TextEditor2 } from 'components/TextEditor2';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from 'store/selectors';
import { v4 } from 'uuid';
import { View } from 'wiloke-react-core';

const publishStepLoading = createPublishStepLoading(2);

const TestPage: FC = () => {
  const [status, setStatus] = useState<'idle' | 'loading' | 'done'>('idle');
  const { siteStatus } = useSelector(authSelector);

  return (
    <View container css={{ padding: '100px 0' }}>
      <SliderBeauty min={0} max={1000} onValueChange={console.log} defaultValue={100} />
      <View css={{ width: '320px', marginBottom: '30px' }}>
        <ColorSchemeFieldUI
          value="default"
          data={[
            {
              uniqueName: 'Default',
              cssClassName: 'default',
              colors: [
                { id: v4(), name: '--color-primary', light: '#33C9C4', dark: '#33C9C4' },
                { id: v4(), name: '--color-secondary', light: '#3540df', dark: '#3540df' },
                { id: v4(), name: '--color-tertiary', light: '#eb6c6c', dark: '#eb6c6c' },
                { id: v4(), name: '--color-quaternary', light: '#FBC473', dark: '#FBC473' },
                { id: v4(), name: '--color-dark', light: '#000000', dark: '#ffffff' },
                { id: v4(), name: '--color-gray9', light: '#232323', dark: '#fafafa' },
                { id: v4(), name: '--color-gray8', light: '#3e3e3e', dark: '#f3f3f3' },
                { id: v4(), name: '--color-gray7', light: '#575757', dark: '#e9e9e9' },
                { id: v4(), name: '--color-gray6', light: '#888888', dark: '#dbdbdb' },
                { id: v4(), name: '--color-gray5', light: '#b3b3b3', dark: '#b3b3b3' },
                { id: v4(), name: '--color-gray4', light: '#dbdbdb', dark: '#888888' },
                { id: v4(), name: '--color-gray3', light: '#e9e9e9', dark: '#575757' },
                { id: v4(), name: '--color-gray2', light: '#f3f3f3', dark: '#3e3e3e' },
                { id: v4(), name: '--color-gray1', light: '#fafafa', dark: '#232323' },
                { id: v4(), name: '--color-light', light: '#ffffff', dark: '#000000' },
              ],
            },
            {
              uniqueName: 'Color 2',
              cssClassName: 'color-2',
              colors: [
                { id: v4(), name: '--color-primary', light: '#33C9C4', dark: '#33C9C4' },
                { id: v4(), name: '--color-secondary', light: '#3540df', dark: '#3540df' },
                { id: v4(), name: '--color-tertiary', light: '#eb6c6c', dark: '#eb6c6c' },
                { id: v4(), name: '--color-quaternary', light: '#FBC473', dark: '#FBC473' },
                { id: v4(), name: '--color-dark', light: '#000000', dark: '#ffffff' },
                { id: v4(), name: '--color-gray9', light: '#232323', dark: '#fafafa' },
                { id: v4(), name: '--color-gray8', light: '#3e3e3e', dark: '#f3f3f3' },
                { id: v4(), name: '--color-gray7', light: '#575757', dark: '#e9e9e9' },
                { id: v4(), name: '--color-gray6', light: '#888888', dark: '#dbdbdb' },
                { id: v4(), name: '--color-gray5', light: '#b3b3b3', dark: '#b3b3b3' },
                { id: v4(), name: '--color-gray4', light: '#dbdbdb', dark: '#888888' },
                { id: v4(), name: '--color-gray3', light: '#e9e9e9', dark: '#575757' },
                { id: v4(), name: '--color-gray2', light: '#f3f3f3', dark: '#3e3e3e' },
                { id: v4(), name: '--color-gray1', light: '#fafafa', dark: '#232323' },
                { id: v4(), name: '--color-light', light: '#ffffff', dark: '#000000' },
              ],
            },
          ]}
        />
      </View>
      <Button onClick={() => setStatus('idle')}>Idle</Button>
      <Button onClick={() => setStatus('loading')}>Loading</Button>
      <Button onClick={() => setStatus('done')}>Done</Button>
      <ProgressLoader status={status} />

      <button
        onClick={() => {
          publishStepLoading.next({
            title: 'Step 1',
            description: 'Integer sed justo at enim vehicula ultrices eget nec ligula. Nunc eleifend facilisis ligula in egestas.',
          });
        }}
      >
        Step 1
      </button>
      <button
        onClick={() => {
          publishStepLoading.next({
            title: 'Step 2',
            description: 'Integer sed justo at enim vehicula ultrices eget nec ligula. Nunc eleifend facilisis ligula in egestas.',
          });
        }}
      >
        Step 2
      </button>
      <button
        onClick={() => {
          publishStepLoading.next();
        }}
      >
        Done
      </button>
      <button
        onClick={() => {
          publishStepLoading.open();
        }}
      >
        Show
      </button>

      <View css={{ width: '300px', padding: '10px' }}>
        <ChooseImage mode="popup" imageWidth={500} />
      </View>

      <br />
      <Carousel.Style2
        data={siteStatus}
        renderItem={(item, index) => (
          <View key={index} backgroundColor="danger" css={{ height: '100%' }}>
            <MessageMaintain contentHtml={item.description} heading={item.heading} timeEnd={item.happenAt} />
          </View>
        )}
      />
      <br />
      <View css={{ width: '300px', padding: '10px' }}>
        <TextEditor2 />
      </View>
    </View>
  );
};

export default TestPage;
