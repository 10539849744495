import * as User from './UserController';
import * as Plan from './PlanController';
import * as Coupon from './CouponController';
import * as PurchaseCode from './PurchaseCodeController';
import * as Language from './LanguageController';
import * as Partner from './PartnerController';

export const userApis = {
  user: User,
  plan: Plan,
  coupon: Coupon,
  purchaseCode: PurchaseCode,
  language: Language,
  partner: Partner,
};
