import { AsyncComponent, MyModal } from '@wiloke/ui';
import Active from 'components/Active';
import ProductCard from 'components/ProductCard';
import TextInput from 'components/TextInput';
import VirtualList from 'components/VirtualList/VirtualList';
import { RenewButton } from 'containers/Admin/PageBuilder/DashboardPageSettings/components/RenewButton';
import withDebounce from 'hocs/withDebounce';
import { range } from 'ramda';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useChangeBlogKey, useGetBlogs, useLoadMoreBlogs } from 'store/actions/shopify';
import { defaultBlogDataState } from 'store/reducers/shopify/reducerBlogs';
import { shopifySelector } from 'store/selectors';
import { i18n } from 'translation';
import { GridSmart, View, ViewportTracking } from 'wiloke-react-core';
import { useSettingsShopifyPicker } from './slice';

const DebounceInput = withDebounce(TextInput, 'value', 'onValueChange', 300);

const ROW_HEIGHT = 170;
const NUMBER_OF_COLUMNS = 6;

interface CreateBlogPickerProps {
  onOk?: () => void;
  onCancel?: () => void;
}

export const CreateBlogPicker: FC<CreateBlogPickerProps> = ({ onOk, onCancel }) => {
  const { searchKey, blogs } = useSelector(shopifySelector.blogs);
  const { data, requestStatus, hasNextPage, loadMoreStatus } = blogs[searchKey] || defaultBlogDataState;

  const { visibleBlog, slugsBlog } = useSelector(shopifySelector.multiShopifyPicker);

  const changeKey = useChangeBlogKey();
  const changeSettingsShopify = useSettingsShopifyPicker();
  const getBlogs = useGetBlogs();
  const loadMoreBlogs = useLoadMoreBlogs();

  useEffect(() => {
    if (visibleBlog) {
      getBlogs.request({ search: searchKey, shouldGetArticle: false, limit: 20 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleBlog, searchKey]);

  const _handleSelectPage = (handle: string, id: number, featuredImage?: string) => (isSelect: boolean) => {
    if (isSelect) {
      changeSettingsShopify({
        slugsBlog: [...slugsBlog, { itemId: id, handle, featuredImg: featuredImage }],
      });
    } else {
      changeSettingsShopify({
        slugsBlog: slugsBlog.filter(item => item.handle !== handle),
      });
    }
  };

  const _handleSearch = (val: string) => {
    changeKey(val);
  };

  const handleClose = () => {
    if (!!onCancel) {
      onCancel?.();
    } else {
      changeSettingsShopify({
        visibleBlog: false,
      });
    }
  };

  const handleOk = () => {
    if (!!onOk) {
      onOk?.();
    } else {
      changeSettingsShopify({
        visibleBlog: false,
      });
    }
  };

  const renderLoading = () => {
    return range(1, Math.floor(Math.random() * 12) + 1).map(num => <ProductCard.Loading key={num} />);
  };

  const _renderBlogs = () => {
    const rowCount = Math.ceil(data.length / NUMBER_OF_COLUMNS) + (hasNextPage ? NUMBER_OF_COLUMNS : 1);

    return (
      <VirtualList
        rowHeight={ROW_HEIGHT}
        rowCount={rowCount}
        containerCss={{ height: 'calc(100% - 42px)' }}
        rowRender={index => {
          const dataSlice = data.slice(index * NUMBER_OF_COLUMNS, index * NUMBER_OF_COLUMNS + NUMBER_OF_COLUMNS);
          const rowItem = dataSlice.length ? dataSlice : hasNextPage ? Array(NUMBER_OF_COLUMNS).fill(undefined) : [];

          return (
            <GridSmart columnWidth={200} columnGap={10} columnCount={NUMBER_OF_COLUMNS}>
              {rowItem.map(item => {
                if (typeof item === 'undefined' && loadMoreStatus !== 'failure' && requestStatus === 'success') {
                  return (
                    <ViewportTracking
                      key={index}
                      offsetBottom={-50}
                      onEnterViewport={() => {
                        if (loadMoreStatus !== 'loading' && hasNextPage && index % NUMBER_OF_COLUMNS === 0) {
                          loadMoreBlogs.request({
                            search: searchKey,
                            shouldGetArticle: false,
                          });
                        }
                      }}
                    >
                      {renderLoading()}
                    </ViewportTracking>
                  );
                }
                if (item) {
                  const _stateHandles = slugsBlog.map(state => state.handle);
                  return (
                    <Active
                      disabledMulti
                      onActive={_handleSelectPage(item.handle, Number(item.id), item.featuredImage?.src)}
                      variant="style2"
                      active={_stateHandles.includes(item.handle)}
                      key={item.id}
                    >
                      <ProductCard imageAspectRatio={16 / 9} imageSrc={item.featuredImage?.src || ''} title={item.title} />
                    </Active>
                  );
                }
                return null;
              })}
            </GridSmart>
          );
        }}
      />
    );
  };

  return (
    <MyModal
      bodyCss={{
        height: '80%',
      }}
      headerText={i18n.t('adminDashboard.blog')}
      isVisible={visibleBlog}
      depsHeightRecalculation={requestStatus}
      onCancel={handleClose}
      onOk={handleOk}
      cancelText="Remove"
      okText="Accept"
      size="large"
      scrollDisabled
    >
      <View
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: '8px',
          columnGap: '10px',
        }}
      >
        <DebounceInput block sizeInput="medium" placeholder="Search blog..." css={{ flex: '1' }} value={searchKey} onValueChange={_handleSearch} />
        <RenewButton variant="blog" css={{ width: '44px', height: '44px', borderRadius: '6px' }} />
      </View>

      <AsyncComponent status={requestStatus} isEmpty={data.length === 0} Success={_renderBlogs()} />
    </MyModal>
  );
};
