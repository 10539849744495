import {
  watchApproveThemeDraft,
  watchCommitThemeDraft,
  watchCreateThemeAtom,
  watchCreateThemeDraft,
  watchCreateUserThemeCategory,
  watchDeleteCategoryOfThemeProduct,
  watchDeleteThemeAtom,
  watchDeleteThemeDraft,
  watchForkThemeAtom,
  watchGetCategoriesOfThemeProduct,
  watchGetPagesOfTrashTheme,
  watchGetPagesTemplate,
  watchGetThemesAtom,
  watchGetThemesDraft,
  watchGetTrashThemes,
  watchHotfixThemeAtom,
  watchLoadMorePagesOfTrashTheme,
  watchLoadmorePagesTemplate,
  watchLoadmoreThemesAtom,
  watchLoadmoreThemesDraft,
  watchLoadMoreTrashThemes,
  watchPublishThemeAtom,
  watchRejectThemeDraft,
  watchRestorePagesOfThemeAtom,
  watchRestoreThemeAtom,
  watchUpdateCategoryOfThemeProduct,
  watchUpdateThemeAtom,
  watchUpdateThemeDraft,
} from './store/sagas';

export * from './Themes';
export { ThemeManagement as default } from './Themes';

export const sagasThemeManagement = [
  watchApproveThemeDraft,
  watchCommitThemeDraft,
  watchCreateThemeAtom,
  watchCreateThemeDraft,
  watchCreateUserThemeCategory,
  watchDeleteCategoryOfThemeProduct,
  watchDeleteThemeAtom,
  watchDeleteThemeDraft,
  watchForkThemeAtom,
  watchGetCategoriesOfThemeProduct,
  watchGetPagesOfTrashTheme,
  watchGetPagesTemplate,
  watchGetThemesAtom,
  watchGetThemesDraft,
  watchGetTrashThemes,
  watchHotfixThemeAtom,
  watchLoadMorePagesOfTrashTheme,
  watchLoadmorePagesTemplate,
  watchLoadmoreThemesAtom,
  watchLoadmoreThemesDraft,
  watchLoadMoreTrashThemes,
  watchPublishThemeAtom,
  watchRejectThemeDraft,
  watchRestorePagesOfThemeAtom,
  watchRestoreThemeAtom,
  watchUpdateCategoryOfThemeProduct,
  watchUpdateThemeAtom,
  watchUpdateThemeDraft,
];
