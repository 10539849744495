export const vi = {
  'Section Settings': 'Cấu Hình Mục',
  'Section Name': 'Tên Mục',
  'Background Fixed': 'Nền Cố Định',
  'Background Type': 'Loại Nền',
  'Background Color': 'Màu Nền',
  'Background Image': 'Hình Nền',
  'Background Video': 'Video Nền',
  'Background Size': 'Kích Thước Nền',
  'Enable Background Overlay': 'Bật Nền Đè Lên',
  'Background Overlay Color': 'Màu Nền Đè Lên',
  'Background Overlay Blur': 'Mờ Nền Đè Lên',
  'Sub Heading': 'Tiêu Đề Phụ',
  Copyright: 'Bản Quyền',
  'New Letter': 'Thư Mới',
  'Mini Cart': 'Giỏ Hàng Nhỏ',
  'Quick View': 'Xem Nhanh',
  'Add To Cart': 'Thêm Vào Giỏ Hàng',
  'Add To Wishlist': 'Thêm Vào Danh Sách Mong Muốn',
  'Add To Compare': 'Thêm Vào So Sánh',
  'Aspect Ratio': 'Tỉ Lệ Aspect',
  Text: 'Văn Bản',
  Content: 'Nội Dung',
  Padding: 'Đệm',
  Margin: 'Lề',
  Button: 'Nút',
  Buttons: 'Nút',
  Column: 'Cột',
  Product: 'Sản Phẩm',
  Products: 'Sản Phẩm',
  Collection: 'Bộ Sưu Tập',
  Collections: 'Bộ Sưu Tập',
  Heading: 'Tiêu Đề',
  Design: 'Thiết Kế',
  Category: 'Danh Mục',
  Sortable: 'Có Thể Sắp Xếp',
  Navigation: 'Điều Hướng',
  Menu: 'Menu',
  Header: 'Tiêu Đề',
  Footer: 'Chân Trang',
  Social: 'Mạng Xã Hội',
  Socials: 'Mạng Xã Hội',
  Logo: 'Logo',
  Phone: 'Điện Thoại',
  Email: 'Email',
  Website: 'Trang Web',
  Time: 'Thời Gian',
  Date: 'Ngày',
  Subscribe: 'Đăng Ký',
  Dark: 'Tối',
  Light: 'Sáng',
  Enable: 'Bật',
  Disable: 'Tắt',
  Sale: 'Khuyến Mại',
  Setting: 'Cài Đặt',
  Settings: 'Cài Đặt',
  Color: 'Màu Sắc',
  'Compare Action': 'Hành Động So Sánh',
  'Compare Options Title': 'Tiêu Đề Tùy Chọn So Sánh',
  'Cart Action': 'Hành Động Giỏ Hàng',
  Arrow: 'Mũi Tên',
  Address: 'Địa Chỉ',
  Title: 'Tiêu Đề',
  Description: 'Mô Tả',
  Decorate: 'Trang Trí',
  'View All Button': 'Nút Xem Tất Cả',
  Sidebar: 'Thanh Bên',
  Banner: 'Banner',
  'Enable Categories': 'Bật Danh Mục',
  'Categories Title': 'Tiêu Đề Danh Mục',
  Categories: 'Danh Mục',
  'Enable Caret': 'Bật Mũi Tên',
  'Enable Flash Sale': 'Bật Khuyến Mại',
  'Flash Sale Text': 'Văn Bản Khuyến Mại',
  'Enable Countdown': 'Bật Hẹn Giờ',
  'Expiration Date': 'Ngày Hết Hạn',
  'Product Settings': 'Cài Đặt Sản Phẩm',
  'Enable Review': 'Bật Đánh Giá',
  'Enable Sale': 'Bật Khuyến Mại',
  'Sale Design': 'Thiết Kế Khuyến Mại',
  'Product Quantity Displayed': 'Số Lượng Sản Phẩm Được Hiển Thị',
  'Enable Color Action': 'Bật Hành Động Màu Sắc',
  'Add To Cart Action': 'Hành Động Thêm Vào Giỏ Hàng',
  'Wishlist Action': 'Hành Động Danh Sách Mong Muốn',
  'Quick View Action': 'Hành Động Xem Nhanh',
  Image: 'Hình Ảnh',
  'Sub Title': 'Tiêu Đề Phụ',
  Position: 'Vị Trí',
  Align: 'Căn Chỉnh',
  Information: 'Thông Tin',
  'Logo Image': 'Hình Ảnh Biểu Tượng',
  'Title Align': 'Căn Chỉnh Tiêu Đề',
  'Sub Title Align': 'Căn Chỉnh Tiêu Đề Phụ',
  'Text Align': 'Căn Chỉnh Văn Bản',
  'Description Align': 'Căn Chỉnh Mô Tả',
  'Account Action': 'Hành Động Tài Khoản',
  'Search Action': 'Hành Động Tìm Kiếm',
  Brands: 'Thương Hiệu',
  Label: 'Nhãn',
  'Popular Post': 'Bài Viết Phổ Biến',
  Tags: 'Thẻ',
  Blog: 'Blog',
  Share: 'Chia Sẻ',
  Collapse: 'Thu Gọn',
  'Title Design': 'Thiết Kế Tiêu Đề',
  'Sub Title Design': 'Thiết Kế Tiêu Đề Phụ',
  'Text Design': 'Thiết Kế Văn Bản',
  'Description Design': 'Thiết Kế Mô Tả',
  Link: 'Liên Kết',
  'Border Radius': 'Bo Tròn Viền',
  'Image Border Radius': 'Bo Tròn Viền Hình Ảnh',
  Variant: 'Biến Thể',
  'Section Width': 'Chiều Rộng Thành Phần',
  'Container Width': 'Chiều Rộng Bố Cục',
  'Container Gap': 'Khoảng Cách Bố Cục',
  'Column Gap': 'Khoảng Cách Cột',
  Manual: 'Thủ Công',
  Width: 'Chiều Rộng',
  Divider: 'Phân Cách',
  'Divider Color': 'Màu Phân Cách',
  'Divider Design': 'Thiết Kế Phân Cách',
  'Divider Margin': 'Khoảng Cách Phân Cách',
  Icon: 'Biểu Tượng',
  'Enable Icon': 'Bật Biểu Tượng',
  'Icon Design': 'Thiết Kế Biểu Tượng',
  Choose: 'Chọn',
  'Icon Settings': 'Cài Đặt Biểu Tượng',
  Price: 'Giá',
  Options: 'Tùy Chọn',
  Name: 'Tên',
  Data: 'Dữ Liệu',
  Availability: 'Sẵn Có',
  'In Stock Design': 'Thiết Kế Còn Hàng',
  'Out of Stock Design': 'Thiết Kế Hết Hàng',
  Testing: 'Kiểm Thử',
  Layout: 'Bố Cục',
  Vendor: 'Nhà Cung Cấp',
  Size: 'Kích Thước',
  'Container Design': 'Thiết Kế Bố Cục',
  'Item Design': 'Thiết Kế Mục',
  Item: 'Mục',
  'Item Column': 'Cột Mục',
  'Content Width': 'Chiều Rộng Nội Dung',
  'Icon Position': 'Vị Trí Biểu Tượng',
  'Content Design': 'Thiết Kế Nội Dung',
  'Row Height': 'Chiều Cao Dòng',
  'Button Left': 'Nút Bên Trái',
  'Button Right': 'Nút Bên Phải',
  'Auto Play': 'Tự Động Chạy',
  Speed: 'Tốc Độ',
  Delay: 'Thời Gian Chờ',
  'Slider Settings': 'Cài Đặt Trượt',
  'Slides Per View': 'Số Lượng Slide Hiển Thị',
  Loop: 'Lặp',
  'Enable Arrow': 'Bật Mũi Tên',
  'Enable Pagination': 'Bật Phân Trang',
  'Advanced Mode': 'Chế Độ Nâng Cao',
  Animate: 'Hoạt Ảnh',
  'Product Design': 'Thiết Kế Sản Phẩm',
  Gap: 'Khoảng Cách',
  'Regular Price': 'Giá Thường',
  'Sale Price': 'Giá Bán',
  Height: 'Chiều Cao',
  Carousel: 'Băng chuyền',
  Video: 'Video',
  'Play Button Design': 'Thiết Kế Nút Chạy',
  Popup: 'Popup',
  'Popup Design': 'Thiết Kế Popup',
  'Close Button': 'Nút Đóng',
  'Close Button Design': 'Thiết Kế Nút Đóng',
  'General Settings': 'Cài Đặt Chung',
  'Heading And Content': 'Tiêu Đề Và Nội Dung',
  'Content Item': 'Mục Nội Dung',
  'Heading Position': 'Vị Trí Tiêu Đề',
  Pagination: 'Phân Trang',
  'Arrow Variant': 'Biến Thể Mũi Tên',
  'Arrow Position': 'Vị Trí Mũi Tên',
  'Arrow Align': 'Canh Lề Mũi Tên',
  'Arrow Icon': 'Biểu Tượng Mũi Tên',
  'Left Arrow Icon': 'Biểu Tượng Mũi Tên Trái',
  'Right Arrow Icon': 'Biểu Tượng Mũi Tên Phải',
  Left: 'Trái',
  Right: 'Phải',
  'Media Type': 'Loại Phương Tiện',
  'Min Height': 'Chiều Cao Tối Thiểu',
  'Max Height': 'Chiều Cao Tối Đa',
  'Min Width': 'Chiều Rộng Tối Thiểu',
  'Max Width': 'Chiều Rộng Tối Đa',
  Filter: 'Lọc',
  Filters: 'Lọc',
  'Home Page Title': 'Tiêu Đề Trang Chủ',
  Separator: 'Ngăn cách',
  'Customer Page Title': 'Tiêu Đề Trang Khách Hàng',
  Component: 'Thành Phần',
  'Tag Name': 'Tên Thẻ',
  Space: 'Khoảng Trống',
  'Reversed Layout': 'Bố Cục Đảo Ngược',
  'Sold Out': 'Hết Hàng',
  'Remove From Wishlist': 'Xóa Khỏi Danh Sách Yêu Thích',
  'Remove From Compare': 'Xóa Khỏi Danh Sách So Sánh',
  Clear: 'Xóa',
  'Show More': 'Hiển Thị Thêm',
  'Show Less': 'Hiển Thị Ít Hơn',
  'Sort by': 'Sắp Xếp Theo',
  Quantity: 'Số Lượng',
  'View Detail': 'Xem Chi Tiết',
  Refine: 'Lọc',
  'Filter Name': 'Tên Bộ Lọc',
  'In Stock': 'Còn Hàng',
  'Out of Stock': 'Hết Hàng',
  'Accept Button': 'Nút Chấp Nhận',
  'Reject Button': 'Nút Từ Chối',
  Show: 'Hiển Thị',
  Hide: 'Ẩn',
  Language: 'Ngôn Ngữ',
  Currency: 'Tiền Tệ',
  View: 'Xem',
  Dropdown: 'Thả xuống',
  Message: 'Tin nhắn',
  'Language And Currency': 'Ngôn Ngữ Và Tiền Tệ',
  'Hot Spot': 'Điểm Nóng',
  'Hot Spots': 'Điểm Nóng',
} as const;
