import { css } from 'wiloke-react-core';

export const container = css`
  debug: SectionPageHeader-Container;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;
