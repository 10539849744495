export const adminDashboard = {
  confirm_delete: 'Voulez-vous supprimer cette page ? Note : cette action est irréversible',
  search_empty: 'Désolé, aucun résultat ne correspond à votre recherche.',
  page_description: {
    article: "Une page d'article doit être à la fois informative et attrayante pour retenir l'attention des lecteurs.",
    regular: 'Créez différentes pages de destination telles que liste de souhaits, page "À propos", informations de contact et avis clients.',
    collection: 'Une superbe page de collection peut susciter la curiosité des clients et les encourager à explorer davantage.',
    home: "Offrez une excellente première impression aux clients avec une page d'accueil parfaite et professionnelle.",
    product: 'Une page de produit soignée peut convaincre les clients hésitants de faire un achat.',
    preset: 'Choisissez un ensemble de paramètres généraux pour donner à votre boutique une identité visuelle cohérente et intuitive.',
    theme_settings: 'Déverrouillez de nombreuses options de personnalisation pour rendre votre boutique unique.',
    quick_create: 'Découvrez des mises en page attrayantes en vogue !',
    live_theme: 'Aperçu de votre thème de boutique. Personnalisez-le selon vos préférences !',
    draft_themes: 'Ces thèmes ne sont visibles que pour vous. Vous pouvez basculer vers un autre thème en le publiant sur votre boutique.',
  },
  views: 'Vues',
  timeOnPage: 'Temps passé sur la page',
  conversion: 'Taux de conversion',
  publish: 'Publier',
  draft: '%% text %% brouillon',
  all: 'Tout',
  get_started: 'Commencer',
  get_started_description: 'Regardez cette vidéo conviviale pour une configuration rapide !',
  quickly: 'Rapidement',
  customize: 'Personnaliser',
  blank: 'vide',
  collection: 'Collection %% text %%',
  product: 'Produit',
  article: 'Article',
  lastUpdate: 'Dernière mise à jour',
  all_templates: 'Tous les modèles %% text %%',
  my_templates: 'Mes modèles',
  fav_templates: 'Mes modèles de page',
  theme_templates: 'Modèles de thème',
  page_templates: 'Modèles de page',
  page_tempaltes_description: 'Une variété de modèles de page vous attend, prêts à être personnalisés selon vos besoins spécifiques.',
  join_our_team: 'Rejoignez notre équipe',
  specific_text: '%% text %% spécifique',
  theme_settings_page: 'Paramètres généraux du thème',
  view: 'Voir',
  notification: 'Notification',
  account: 'Compte',
  unread: 'Non lu',
  featured_news: 'Actualités en vedette',
  menubar: {
    dashboard: 'Tableau de bord',
    templates: 'Modèles',
    regular_page: 'Pages de destination et plus encore',
    product_page: 'Page produit',
    collection_page: 'Page de collection',
    article_pages: "Pages d'article",
  },
  blog: 'Blog',
  select_presets: 'Sélectionnez des préréglages',
  choose_presets: 'Choisissez parmi les préréglages que nous avons compilés ici',
  option_presets: 'Couleurs, polices, misesen page',
  theme_settings: {
    confirm_description: "Ces paramètres affecteront l'ensemble de votre thème",
    confirm_label: 'Sélectionnez les paramètres que vous souhaitez mettre à jour dans le thème',
  },
  home: 'Accueil',
  pleaseChooseBlog: 'Veuillez choisir un blog pour voir les articles associés',
  cart: 'Panier',
  search: 'Recherche %% text %%',
  password: 'Mot de passe',
  notFound: 'Non trouvé',
  customersLogin: 'Connexion',
  customersResetPassword: 'Réinitialiser le mot de passe',
  customersActivateAccount: 'Activer le compte',
  customersRegister: "S'inscrire",
  customersAccount: 'Compte',
  customersOrder: 'Commande',
  customersAddresses: 'Adresses',
  giftCard: 'Carte cadeau',
  collectionListing: 'Liste de collections',
  blog_has_no_articles: 'Ce blog ne contient aucun article',
  cannot_choose_blog: 'Vous ne pouvez pas choisir un blog vide',
  name_your_page: 'Nommez votre page',
  chose_shopify_variant: '%% text %% sélectionné',
  chose_shopify_to_design: 'Choisissez un(e) %% text %% à concevoir',
  chose_article_belong: "Choisissez un blog auquel l'article sera rattaché",
  select_something_to_design: 'Sélectionnez un(e) %% text %% à concevoir',
  collection_listing: 'Liste de collections',
  reset_password: 'Réinitialiser le mot de passe',
  active_account: 'Activer le compte',
  register: "S'inscrire",
  order: 'Commande',
  addresses: 'Adresses',
  general: 'Général',
  advanced_settings: 'Paramètres avancés',
  styles: 'Styles',
  pages: 'Pages %% name %%',
  themes: 'Thèmes',
  username: "Nom d'utilisateur",
  featured_layouts: 'Mises en page en vedette',
  regular: 'Régulier',
  otherPages: 'Autres pages',
  collapse: 'Réduire',
  reinstall: 'Réinstaller',
  no_shopify_theme_id: "Votre thème a été supprimé sur Shopify. Veuillez cliquer sur le bouton 'Réinstaller' pour le réinstaller.",
  last_saved: 'Dernière sauvegarde',
  added_ago: 'Ajouté %% text %% auparavant',
  theme_library: 'Bibliothèque de thèmes',
  landing: 'Page de destination',
  right_bar: {
    leave_us_a_feedback_title: 'Laissez-nous un commentaire',
    leave_us_a_feedback_desc: 'Nous apprécions votre opinion, veuillez nous laisser un commentaire pour nous aider à mieux vous servir !',
    leave_us_a_feedback_button: 'Envoyer un commentaire',
    veda_youtube_title: 'Playlist YouTube de Veda',
    veda_youtube_desc: 'Regardez nos tutoriels vidéo pour optimiser votre expérience.',
    quick_guide_title: 'Guides rapides',
    quick_guide_desc: 'Guides rapides',
    customization_title: "Personnalisation dans l'éditeur en direct",
    customization_desc: "Personnalisez vos pages à la perfection avec notre guide de l'éditeur en direct.",
    faq_title: 'Réponses aux questions fréquemment posées',
    faq_desc: 'Réponses rapides et utiles.',
    uninstall_title: 'Comment désinstaller Veda Builder en toute sécurité',
    uninstall_desc: 'Cliquez pour apprendre comment désinstaller Veda en toute sécurité',
    recommended_apps: 'Applications recommandées',
    theme_and_page_title: 'Différences entre le Constructeur de Thèmes et le Constructeur de Pages',
    theme_and_page_desc: 'Les différences entre le Constructeur de Thèmes et le Constructeur de Pages de Veda',
  },
  apps: 'Apps',
  app: 'App',
  get: 'Obtenir %% text %%',
  danger_zone: 'Zone dangereuse',
  purchase_code: "Code d'achat",
  orders: 'Commandes',
  inventory: 'Inventaire',
  gift_cards: 'Cartes-cadeaux',
  customers: 'Clients',
  analytics: 'Analytics',
  marketing: 'Marketing',
  discounts: 'Remises',
  store_details: 'Détails du magasin',
  collections: 'Collections',
  billing: 'Facturation',
  users_and_permissions: 'Utilisateurs et autorisations',
  payments: 'Paiements',
  checkout_and_accounts: 'Commande et comptes',
  shipping_and_delivery: 'Expédition et livraison',
  taxes_and_duties: 'Taxes et droits de douane',
  locations: 'Emplacements',
  markets: 'Marchés',
  apps_and_sales_channels: 'Applications et canaux de vente',
  domains: 'Domaines',
  customer_events: 'Événements clients',
  brand: 'Marque',
  meta_fields: 'Champs méta',
  files: 'Fichiers',
  languages: 'Langues',
  policies: 'Politiques',
  account_page_welcome: `Bienvenue dans Veda Builder ! Notre application facilite la création de pages et de boutiques Shopify grâce à des modèles attrayants. Consultez notre documentation pour obtenir de l'aide et n'hésitez pas à cliquer sur l'icône de chat pour bénéficier d'un support. Bonne création !`,
  danger_zone_page: {
    description: 'Veuillez faire attention à vos actions dans cette zone.',
    danger_card_title: 'Supprimer tous les fichiers Veda de votre thème publié',
    danger_card_desc: `En cliquant sur "Restaurer", tous les agencements publiés de Veda seront définitivement supprimés. Votre thème reviendra à la mise en page d'origine.`,
    see_details_guide: 'Voir le guide détaillé',
    restore: 'Restaurer',
    restore_veda: 'Restaurer Veda Page Builder',
    modal_after_restore:
      'Félicitations, les données de Veda ont été supprimées avec succès de votre application. Pour désinstaller Veda de votre boutique, veuillez cliquer sur ce lien',
    budget_concerns_title: 'Préoccupations budgétaires',
    budget_concerns_desc: `Si le coût est un problème, nous nous engageons à aider les nouvelles boutiques à réussir, c'est pourquoi nous proposons une réduction exclusive de 40% sur un abonnement de 3 mois pour n'importe quel plan d'application. Utilisez simplement le code "VSTAY40" lors du paiement pour bénéficier de votre réduction. Nous espérons que cela vous aidera à résoudre vos problèmes budgétaires.`,
    missing_features_title: 'Fonctionnalités manquantes',
    missing_features_desc: `Attendez ! Il est possible que Veda Builder le prenne déjà en charge, et nos experts en fonctionnalités sont disponibles pour vous aider à confirmer. Cliquez sur le bouton ci-dessous pour démarrer une discussion en direct avec l'un de nos experts qui peut vous aider à trouver la fonctionnalité dont vous avez besoin. `,
    technical_issues_title: 'Problèmes techniques',
    technical_issues_desc: `Nous nous excusons pour les problèmes que vous avez rencontrés avec notre application. Veuillez partager plus de détails sur le problème, et notre équipe d'assistance se fera un plaisir de vous aider et d'améliorer votre expérience. Cliquez sur le bouton ci-dessous pour discuter avec un véritable agent d'assistance dès maintenant. `,
    no_need_title: 'Plus besoin',
    no_need_desc: `Nous pensons que Veda Builder peut toujours être un outil précieux pour votre entreprise, et nous aimerions vous montrer exactement comment.`,
    difficulty_of_use_title: `Difficulté d'utilisation`,
    difficulty_of_use_desc: `Nous savons que l'utilisation d'un nouveau logiciel peut être difficile au début. C'est pourquoi nous offrons une aide individuelle de nos experts en réussite client pour vous guider dans toutes les difficultés que vous avez rencontrées. Cliquez sur le bouton ci-dessous pour vous connecter à une personne réelle qui peut vous aider.`,
    something_else_title: 'Autre chose',
    something_else_desc: `Si vous avez d'autres raisons de vouloir désinstaller, veuillez nous le faire savoir. Nous cherchons toujours des moyens d'améliorer notre application et de fournir la meilleure expérience possible à nos utilisateurs. Vos commentaires nous sont précieux, et nous ferons tout notre possible pour répondre à vos préoccupations et vous garder en tant que client satisfait. `,
    modal_restore_sorry: `Nous sommes désolés d'apprendre que vous envisagez de désinstaller Veda Builder`,
    modal_restore_before: `Avant de cliquer sur le bouton "Restaurer", nous souhaitons explorer quelques options pour résoudre les problèmes qui vous ont poussé à envisager cette action.`,
    redeem_code: 'Utiliser le code',
    chat_now: 'Discuter maintenant',
  },
  landing_and_more: 'Pages de destination',
  draft_theme: {
    delete_modal_title: 'Cette action supprimera définitivement le thème %% text %% de Veda Builder, cliquez sur Ok pour continuer.',
    delete_modal_desc:
      'Remarque : En raison de la politique de Shopify, nous ne sommes pas autorisés à supprimer ce thème de votre boutique Shopify. Par conséquent, la suppression du thème ne supprimera que ses données de Veda Page Builder. Pour supprimer ou utiliser un autre thème sur votre boutique Shopify, veuillez cliquer sur',
  },
  duplicate_theme: {
    title: 'Personnalisez sans affecter votre thème en direct.',
    description:
      'Vous pouvez créer une copie de votre thème en direct et synchroniser les modèles Veda avec celle-ci afin que le thème en direct ne soit pas affecté. Une fois la personnalisation terminée, vous pouvez publier la version copiée.',
    select: 'Sélectionnez un thème',
    click_to_learn: 'Cliquez pour apprendre comment cette fonctionnalité fonctionne',
  },
  quick_guide: {
    how_to_use_theme: {
      title: 'Apprenez à utiliser le thème Veda',
      description: "Créez facilement votre superbe boutique pour vendre davantage tout en économisant de l'argent.",
    },
    how_to_use_page: {
      title: 'Apprenez à utiliser la page Veda',
      description: "Remplacez/ajoutez n'importe quelle page accrocheuse à votre thème actuel.",
    },
    recommend_apps: {
      title: 'Apprenez à ajouter des applications recommandées',
      description: 'Applications intégrées et tierces pour débloquer plus de fonctionnalités.',
    },
    how_to_use_section: {
      title: 'Apprenez à utiliser les features Veda',
      description: 'Diverses features puissantes incitent les visiteurs à ajouter immédiatement au panier.',
    },
  },
} as const;
