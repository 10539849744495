import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

interface Params {
  commandIds?: string;
  themeCommandId?: string;
  target?: 'DEV' | 'ADMIN';
  label?: string;
  size: number;
}

export const getTrashPagesOfAtom = async ({ size, label, commandIds, target, themeCommandId }: Params) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const atomResponse = await themeApis.atom.adminApi.page.getTrashPagesOfAtom({
      type: 'Get First',
      size,
      cacheVersion: lastCache,
      label,
      commandIds,
      target,
      themeCommandId,
    });

    const pageCommandIds = atomResponse.map(item => item.commandId).join(',');
    const trashThemeAtom = await themeApis.atom.adminApi.theme.getTrashes({ type: 'GET FIRST PAGE', cacheVersion: lastCache, pageCommandIds });

    const result = atomResponse.map(atom => ({
      ...atom,
      addedInThemes: trashThemeAtom.length > 0 ? trashThemeAtom.map(item => item.label) : [],
    }));
    return result;
  }

  throw new RoleException();
};

export const loadMoreTrashPagesOfAtom = async ({ size, label, commandIds, target, themeCommandId, cursor }: Params & { cursor: string }) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const atomResponse = await themeApis.atom.adminApi.page.getTrashPagesOfAtom({
      type: 'Load More',
      size,
      cacheVersion: lastCache,
      label,
      commandIds,
      target,
      themeCommandId,
      cursor,
    });

    const pageCommandIds = atomResponse.map(item => item.commandId).join(',');
    const trashThemeAtom = await themeApis.atom.adminApi.theme.getTrashes({ type: 'GET FIRST PAGE', cacheVersion: lastCache, pageCommandIds });

    const result = atomResponse.map(atom => ({
      ...atom,
      addedInThemes: trashThemeAtom.length > 0 ? trashThemeAtom.map(item => item.label) : [],
    }));
    return result;
  }

  throw new RoleException();
};
