import { i18n } from 'translation';
import { LiquidSyntaxToTwigError } from '../Error';

window.Twig.extendFilter('image_tag', () => {
  throw new LiquidSyntaxToTwigError(i18n.t('twig_error.filters.image_tag'));
});

/**
 * @link https://shopify.dev/api/liquid/filters/html-filters#image_tag
 * TODO: Trông có vẻ làm được nhưng có thực sự cần thiết???
 */
export const image_tag = (liquid: string) => liquid;
