import { AxiosResponse } from 'axios';
import { BE_ThemeClient } from 'services/ThemeService/VedaApplication/types';
import fetchAPI from 'utils/functions/fetchAPI';
import { getApiTracking } from 'utils/functions/getApiTracking';
import { clientBaseUrl } from '../const';

interface GetClient {
  commandId: string;
  cacheVersion: number | null;
}

interface ResponseSuccess {
  message: string;
  info: BE_ThemeClient;
}

export const getClient = async ({ commandId, cacheVersion }: GetClient) => {
  const url = getApiTracking({ defaultBaseUrl: `${clientBaseUrl}/${commandId}`, trackingEndpoint: `themes/${commandId}` });
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url,
    params: {
      cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
    },
  });
  return response.data;
};
