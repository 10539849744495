import { CollectionItem, normalizationCollection } from 'services/LiquidVariables/utils/Collection';
import { PREFIX_COLLECTION_HANDLE, PREFIX_COLLECTION_TITLE } from './@const';
import { randomImage, randomNumber } from './@utils';

const randomTitle = () => `${PREFIX_COLLECTION_TITLE} ${randomNumber()}`;
const randomHandle = () => `${PREFIX_COLLECTION_HANDLE}-${randomNumber()}`;

export const GET_COLLECTION_ITEM_PLACEHOLDER = (handle = randomHandle()): CollectionItem => ({
  handle, // 'ella',
  id: '412514156765',
  current_vendor: 'Wooden',
  description: '',
  all_products_count: 13,
  all_tags: [],
  all_types: [],
  all_vendors: [],
  products: [],
  default_sort_by: 'best-selling',
  sort_by: 'best-selling',
  sort_options: [
    {
      name: 'Featured',
      value: 'manual',
    },
    {
      name: 'Best Selling',
      value: 'best-selling',
    },
    {
      name: 'Alphabetically, A-Z',
      value: 'title-ascending',
    },
    {
      name: 'Alphabetically, Z-A',
      value: 'title-descending',
    },
    {
      name: 'Price, low to high',
      value: 'price-ascending',
    },
    {
      name: 'Price, high to low',
      value: 'price-descending',
    },
    {
      name: 'Date, old to new',
      value: 'created-ascending',
    },
    {
      name: 'Date, new to old',
      value: 'created-descending',
    },
  ],
  template_suffix: '',
  title: randomTitle(), // 'Furniture',
  products_count: 13,
  next_product: undefined,
  prev_product: undefined,
  published_at: '2022-11-21T07:23:07Z',
  updated_at: '2022-11-21T07:23:07Z',
  url: '/collections/all/ella',
  tags: [],
  image: {
    aspect_ratio: 0.6666667,
    id: '1680974545117',
    media_type: 'image',
    src: randomImage(),
    width: 500,
    height: 750,
    attached_to_variant: false,
  },
  featured_image: {
    aspect_ratio: 0.6666667,
    id: '1680974545117',
    media_type: 'image',
    src: randomImage(),
    width: 500,
    height: 750,
    attached_to_variant: false,
  },
});

// TODO: Có hay không việc migrate cái này sang BE
export const GET_COLLECTION_PLACEHOLDER = () => normalizationCollection(GET_COLLECTION_ITEM_PLACEHOLDER());
