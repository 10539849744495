import { MODAL_REPORT_DELETE_PAGE_ERROR } from 'containers/ModalReportAfterError/const';
import { ModalReportAfterError } from 'containers/ModalReportAfterError/ModalReportAfterError';
import { call, delay, put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { deletePageProduct } from 'services/PageService/Logic/deletePageProduct';
import { checkThemeAppStatus } from 'services/ShopifyConnection/flowSyncToShopify/checkThemeAppStatus';
import { i18n } from 'translation';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { deleteTemplatePage } from '../reducers/actions';

function* handleDeleteOnePage(id: string) {
  const response: Awaited<ReturnType<typeof deletePageProduct>> = yield retry(3, 1000, deletePageProduct, { commandId: id });
  // Rate limit shopify
  yield delay(500);
  yield put(deleteTemplatePage.success({ commandId: id }));
  notifyAxiosHandler.handleSuccess(response.message);
}

function* handleDelete({ payload }: ReturnType<typeof deleteTemplatePage.request>) {
  const { commandId } = payload;
  try {
    const isActived: SagaReturnType<typeof checkThemeAppStatus> = yield call(checkThemeAppStatus);
    if (!isActived) {
      yield put(deleteTemplatePage.failure({ commandId }));
      return;
    }
    yield call(handleDeleteOnePage, commandId);
  } catch (error) {
    yield put(deleteTemplatePage.failure({ commandId }));
    notifyAxiosHandler.handleError(error as Error);
    ModalReportAfterError.getActions(MODAL_REPORT_DELETE_PAGE_ERROR).report({
      cause: i18n.t('ModalReportAfterError.error_cause.delete_page'),
      description: error instanceof Error ? error.message : '',
    });
  }
}

export function* watchDeleteTemplatePage() {
  yield takeLatest(getActionType(deleteTemplatePage.request), handleDelete);
}
