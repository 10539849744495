export const ModalTour = {
  install_with: 'Tải & Tùy Biến %% text %%',
  already_know: 'Tôi đã hiểu',
  interactive_tutorials: 'Hướng Dẫn Tương Tác',
  tour_theme: {
    step_1: {
      title: 'Duyệt thư viện chủ đề',
      description: 'Rất nhiều mẫu chủ đề ấn tượng để lựa chọn.',
    },
    step_2: {
      title: 'Cài đặt chủ đề của bạn',
      description: 'Di chuột qua chủ đề để xem trước và nhấp vào "Cài đặt" để chọn chủ đề ưa thích của bạn.',
    },
  },
  install_theme: {
    step_1: {
      label_1: 'Please download theme %% themeName %% first.',
      label_2: 'Then, you can install and customize it up!',
      label_3: 'Click here to download theme.',
      label_4: 'Skip, I have already installed this theme before.',
      label_5: 'Your plan does not support this feature. Please upgrade to a higher plan.',
    },
    step_2: {
      label_1: "You haven't installed the theme %% themeName %% to Shopify.",
      next_step: 'Next Step',
    },
    step_3: {
      label_1: 'Explore Veda Builder to make your theme unique.',
      label_2: 'The import process may take a few minutes, please do not close the browser until the data is imported.',
      customize_now: 'Customize now',
    },
  },
  tour_page: {
    step_1: {
      title: 'Chọn loại trang',
      description: 'Nhấp vào loại trang mà bạn muốn thay thế/thêm.',
    },
    step_2: {
      title: 'Tạo trang mới',
      description: 'Nhấp vào nút này để duyệt qua các mẫu trang ấn tượng.',
    },
    step_3: {
      title: 'Cài đặt trang của bạn',
      description: 'Di chuột qua trang để xem trước và nhấp "Cài đặt" trang yêu thích của bạn.',
    },
  },
  tour_addon: {
    step_1: {
      title: 'Thêm một addon mới',
      description: 'Nhấp vào "Thêm Add-ons" để mở thư viện addon.',
    },
    step_2: {
      title: 'Chọn danh mục addon',
      description: 'Bạn có thể chuyển đổi giữa các addon tích hợp sẵn và addon từ bên thứ ba.',
    },
    step_3: {
      title: 'Cài đặt addon của bạn',
      description: 'Nhấp vào "Thêm" để cài đặt addon yêu thích của bạn.',
    },
  },
} as const;
