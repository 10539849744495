import * as MegamenuController from './MegamenuController';
import * as PageController from './PageController';
import * as SectionController from './SectionController';
import * as AddonController from './AddonController';

export const adminApi = {
  megamenu: MegamenuController,
  page: PageController,
  section: SectionController,
  addon: AddonController,
};
