import { storage } from '@wiloke/functions';
import { setVisibleModalMigration } from 'containers/Admin/ThemeBuilder/ThemeDashboard/slice/sliceThemeDashboard';
import { put, select } from 'redux-saga/effects';
import { authSelector } from 'store/selectors';

/**
 * So sánh nếu theme active trên shopify !== với theme active trên veda thì hiện modal migration
 */
export function* forceUserMigratesTheme() {
  const { themeVedaId, shopifyActivateThemeId }: ReturnType<typeof authSelector> = yield select(authSelector);
  const turnOn = storage.getItem('turn_on_migration');
  if (shopifyActivateThemeId !== themeVedaId && turnOn === 'on') {
    yield put(setVisibleModalMigration(true));
  }
}
