import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

export const updateCategoryOfThemeProductRequest = async ({
  commandId,
  description,
  name,
}: {
  commandId: string;
  description: string;
  name: string;
}) => {
  const { role } = getUserInfo();

  if (role === 'admin') {
    const response = await themeApis.vedaApplication.adminApi.category.updateCategory({ category: { commandId, description, name } });
    return response;
  }

  throw new RoleException();
};
