import { ThemeSchema } from '../../@types/Shopify/ThemeConfigs/ThemeSchema';
import { ThemeSetting } from '../../@types/Shopify/ThemeConfigs/ThemeSetting';
import { SCHEMA_SettingColors, SETTING_SettingColors } from './SettingColors/SettingColors';
import { ISCHEMA_SettingColorSchemes } from './@types/ISCHEMA_SettingColorSchemes';
import { ISETTING_SettingColorSchemes } from './@types/ISETTING_SettingColorSchemes';
import { getSchemaLabel } from './@utils/getSchemaLabel';
import { HeaderField } from '../../@types/Shopify/SidebarSettings/HeaderField';
import { ColorField } from '../../@types/Shopify/InputSettings/ColorField';
import { ColorSchemeGroup } from '../../@types/Shopify/InputSettings/ColorSchemeGroup';
import { getFirstScheme } from './@utils/getFirstScheme';

export const COLOR_SCHEME_GROUP_ID = 'color_schemes';

export const SCHEMA_SettingColorSchemes = ({ colorSchemes }: ISCHEMA_SettingColorSchemes): ThemeSchema => {
  const schemeRepresent = getFirstScheme(colorSchemes);
  if (schemeRepresent) {
    const themeSchemaForcolorSchemes = SCHEMA_SettingColors({ colors: schemeRepresent.colors });
    const firstColor = themeSchemaForcolorSchemes.settings.find(setting => setting.type === 'color') as Exclude<
      ThemeSchema['settings'][number],
      ColorSchemeGroup | HeaderField
    >;

    if (firstColor) {
      return {
        name: getSchemaLabel().newLabel,
        settings: [
          {
            type: 'color_scheme_group',
            id: COLOR_SCHEME_GROUP_ID,
            definition: themeSchemaForcolorSchemes.settings.filter(setting => setting.type === 'color' || setting.type === 'header') as Array<
              HeaderField | ColorField
            >,
            role: {
              background: {
                solid: firstColor.id,
              },
              icons: firstColor.id,
              links: firstColor.id,
              on_primary_button: firstColor.id,
              on_secondary_button: firstColor.id,
              primary_button: firstColor.id,
              primary_button_border: firstColor.id,
              secondary_button: firstColor.id,
              secondary_button_border: firstColor.id,
              text: firstColor.id,
            },
          },
        ],
      };
    }
    return {
      name: getSchemaLabel().newLabel,
      settings: [],
    };
  }

  return {
    name: getSchemaLabel().newLabel,
    settings: [],
  };
};

export const SETTING_SettingColorSchemes = ({ colorSchemes }: ISETTING_SettingColorSchemes): ThemeSetting => {
  if (!colorSchemes.length) {
    return {};
  }
  const result = colorSchemes.reduce<ThemeSetting>(
    (result, colorScheme) => {
      const nextResult = {
        ...result,
        [COLOR_SCHEME_GROUP_ID]: {
          ...(result[COLOR_SCHEME_GROUP_ID] as any),
          [colorScheme.cssClassName]: {
            settings: SETTING_SettingColors({ colors: colorScheme.colors }),
          },
        } as Record<string, Record<string, string>>,
      };
      return nextResult;
    },
    { [COLOR_SCHEME_GROUP_ID]: {} } as ThemeSetting,
  );
  console.log(11, result);
  return result;
};
