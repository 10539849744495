import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BE_ThemeAtom } from 'services/ThemeService/Atom/types';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface GetAtoms {
  type: 'GET FIRST PAGE';
  cacheVersion: number | null;
  size?: number;
  label?: string;
  pageCommandIds?: string;
}

interface LoadmoreAtoms {
  type: 'LOADMORE';
  cacheVersion: number | null;
  cursor: string;
  size?: number;
  label?: string;
  pageCommandIds?: string;
}

export const getTrashes = async ({ type, cacheVersion, pageCommandIds, size, label, ...params }: GetAtoms | LoadmoreAtoms) => {
  let requestConfig: AxiosRequestConfig = {};
  if (type === 'GET FIRST PAGE') {
    requestConfig = {
      url: `${baseUrl}/trash/original/search`,
      params: {
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
        size,
        label: label ? label : undefined,
        pageCommandIds: pageCommandIds ? pageCommandIds : undefined,
      },
    };
  } else {
    const { cursor } = params as LoadmoreAtoms;
    requestConfig = {
      url: `${baseUrl}/trash/original/search`,
      params: {
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
        after: cursor,
        size,
        label: label ? label : undefined,
        pageCommandIds: pageCommandIds ? pageCommandIds : undefined,
      },
    };
  }
  const response: AxiosResponse<BE_ThemeAtom[]> = await fetchAPI.request(requestConfig);
  return response.data;
};
