import { getCacheValue, setCacheValue, getUniqId } from '../../../CacheShopifyFieldId';
import { ISCHEMA_SettingMultiProductsPicker } from '../@types/ISCHEMA_SettingMultiProductsPicker';
import { ISETTING_SettingMultiProductsPicker } from '../@types/ISETTING_SettingMultiProductsPicker';

export const toShopifyFieldId = ({ field, parentField, section }: ISCHEMA_SettingMultiProductsPicker | ISETTING_SettingMultiProductsPicker) => {
  const theoryShopifyFieldId = parentField ? `${parentField.name}___${field.name}` : field.name;
  if (theoryShopifyFieldId.length >= 25) {
    const valueInCache = getCacheValue({ theoryShopifyFieldId, section });
    if (valueInCache) {
      return valueInCache;
    } else {
      const newKey = `VEDA_products_${getUniqId('products')}`;
      setCacheValue({ theoryShopifyFieldId, section }, newKey);
      return newKey;
    }
  }
  return theoryShopifyFieldId;
};
