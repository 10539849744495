import { getPageUseCases } from 'containers/Admin/Management/Pages/store/actions/actionPageUseCase';
import { SagaReturnType, put, retry, takeLatest } from 'redux-saga/effects';
import { getUseCasesOfProdPage } from 'services/PageService/Logic/getUseCasesOfProdPage';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';

function* handleGet({ payload: { pageType } }: ReturnType<typeof getPageUseCases.request>) {
  try {
    const response: SagaReturnType<typeof getUseCasesOfProdPage> = yield retry(3, 1000, getUseCasesOfProdPage, pageType);
    yield put(getPageUseCases.success(response));
  } catch (error) {
    const error_ = error as Error;
    notifyAxiosHandler.handleError(error_);
    yield put(getPageUseCases.failure(undefined));
  }
}

export function* watchGetPageUseCases() {
  yield takeLatest(getActionType(getPageUseCases.request), handleGet);
}
