import { AxiosResponse } from 'axios';
import { ProductAddon } from 'types/Addons';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface GetProduct {
  /** CommandId của addon product cần lấy dữ liệu */
  commandId: string;
  cacheVersion: number | null;
}

interface ResponseSuccess extends ProductAddon {}

export const updateVersion = async ({ commandId, cacheVersion }: GetProduct) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${baseUrl}/${commandId}/newest`,
    params: {
      cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
      timestamp: cacheVersion !== null ? cacheVersion : undefined,
    },
  });

  return response.data;
};
