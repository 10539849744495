import { storage } from '@wiloke/functions';
import { AsyncComponent, VedaLoadingItem } from '@wiloke/ui';
import { CodeSpliting } from 'components/CodeSpliting';
import ErrorBoundary from 'components/ErrorBoundary';
import { ErrorWithCrispChat } from 'components/ErrorWithCrispChat';
import configureApp from 'configureApp';
import { DangerZonePage } from 'containers/Admin/AccountPage/DangerZone';
import { AppsPartner } from 'containers/Admin/AppsPartner/AppsPartner';
import { ArticlesPage } from 'containers/Admin/PageBuilder/ArticlesPage';
import BlankPage from 'containers/Admin/PageBuilder/BlankPage';
import BlogPage from 'containers/Admin/PageBuilder/BlogPage';
import { CollectionPage } from 'containers/Admin/PageBuilder/CollectionPage';
import CustomerPages from 'containers/Admin/PageBuilder/CustomerPages';
import HomePage from 'containers/Admin/PageBuilder/HomePage';
import { ProductsPage } from 'containers/Admin/PageBuilder/ProductsPage';
import { ModalCampaign } from 'containers/Admin/PlanManagement/components/ModalCampaign';
import { ValidateCoupon } from 'containers/Admin/PlanManagement/components/ValidateCoupon';
import { PresetStylesPage } from 'containers/Admin/PresetStylesPage';
import { PurchaseCodePage } from 'containers/Admin/PurchaseCodePage';
import { ThemeTemplates } from 'containers/Admin/ThemeBuilder/ThemeTemplates';
import { useCrispChat } from 'containers/CrispChat/hooks/useCrispChat';
import { Maintenance } from 'containers/Maintainance';
import { ModalActiveThemeAppExtension } from 'containers/ModalActiveThemeAppExtension/ModalActiveThemeAppExtension';
import { MODAL_ACTIVE_THEME_APP_EXTENSION } from 'containers/ModalActiveThemeAppExtension/consts';
import { ModalClearCloudflare } from 'containers/ModalClearCloudFlare.ts/ModalClearCloudflare';
import { ModalInstallThemeShopify } from 'containers/ModalInstallTheme';
import { ModalInstallThemeForest } from 'containers/ModalInstallTheme/ModalInstallThemeForest';
import { ModalReportAfterError } from 'containers/ModalReportAfterError/ModalReportAfterError';
import {
  MODAL_REPORT_ACTIVE_THEME_VEDA_ERROR,
  MODAL_REPORT_AFTER_IMPORT_THEME_ERROR,
  MODAL_REPORT_AFTER_SYNC_ERROR,
  MODAL_REPORT_CANT_ACCESS_VEDA,
  MODAL_REPORT_DELETE_PAGE_ERROR,
  MODAL_REPORT_INITIALIZATION_SESSION_BUILDER_ERROR,
  MODAL_REPORT_SAVE_ERROR,
  MODAL_REPORT_SAVE_PAGE_SETITNGS_IN_DASHBOARD_ERROR,
  MODAL_REPORT_SAVE_THEME_SETITNGS_IN_DASHBOARD_ERROR,
  MODAL_REPORT_UPDATE_STATUS_PAGE_ERROR,
} from 'containers/ModalReportAfterError/const';
import { ModalSocketStuck } from 'containers/ModalSocketStuck/ModalSocketStuck';
import { ModalSyncShopifyForPreviewSuccess } from 'containers/ModalSyncShopifySuccess/ModalSyncShopifyForPreviewSuccess';
import { ModalSyncShopifyForSaveSuccess } from 'containers/ModalSyncShopifySuccess/ModalSyncShopifyForSaveSuccess';
import { ModalUnauthorized } from 'containers/ModalUnauthorized/ModalUnauthorized';
import { ModalUpdateShopifyThemeId } from 'containers/ModalUpdateShopifyThemeId/ModalUpdateShopifyThemeId';
import { ModalChangePlanName } from 'containers/ModalUpgradeOrChangePlanName/ModalChangePlanName';
import { ModalUpgradePlan } from 'containers/ModalUpgradeOrChangePlanName/ModalUpgradePlan';
import { NotFoundPage } from 'containers/NotFoundPage';
import { PricingPage } from 'containers/PricingPage';
import EmbeddedTestPage from 'containers/TestPage/EmbeddedTestPage';
import TestPage from 'containers/TestPage/TestPage';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useSetAccessToken, useSetPlanForDev, useVerifyUser } from 'store/global/auth';
import { authSelector, globalMountSelector } from 'store/selectors';
import { PLAN_HANDLE } from 'utils/constants/PlanEnum';
import { isIframePage } from 'utils/isIframePage';
import { isPreviewPage } from 'utils/isPreviewPage';
import { View } from 'wiloke-react-core';
import GuardedRoute from './components/GuardedRoute';
import { Page } from './types';

export const pages: Page[] = [
  {
    path: '/apps',
    component: AppsPartner,
    exact: true,
  },
  {
    path: '/pricing',
    component: PricingPage,
    exact: true,
  },
  {
    path: '/manager-plan',
    component: () => (
      <CodeSpliting
        component={() => import(/* webpackChunkName: "PlanManagement", webpackPrefetch: true */ 'containers/Admin/PlanManagement')}
        CHUNK_ID="PlanManagement"
      />
    ),
    exact: true,
  },
  {
    path: '/manager-general-settings',
    exact: true,
    component: () => (
      <CodeSpliting
        component={() =>
          import(/* webpackChunkName: "GeneralSettingsManagement", webpackPrefetch: true */ 'containers/Admin/GeneralSettingsManagement')
        }
        CHUNK_ID="GeneralSettingsManagement"
      />
    ),
  },
  {
    path: '/manager-users',
    component: () => (
      <CodeSpliting
        component={() => import(/* webpackChunkName: "UserManagement", webpackPrefetch: true */ 'containers/Admin/UserManagement')}
        CHUNK_ID="UserManagement"
      />
    ),
    exact: true,
  },
  {
    path: '/manager-partners',
    component: () => (
      <CodeSpliting
        component={() => import(/* webpackChunkName: "PartnerManagement", webpackPrefetch: true */ 'containers/Admin/PartnerManagement')}
        CHUNK_ID="PartnerManagement"
      />
    ),
    exact: true,
  },
  {
    path: '/login',
    component: () => (
      <CodeSpliting component={() => import(/* webpackChunkName: "LoginPage", webpackPrefetch: true */ 'containers/Login')} CHUNK_ID="LoginPage" />
    ),
    exact: true,
  },
  {
    path: '/account',
    component: () => (
      <CodeSpliting
        component={() => import(/* webpackChunkName: "AccountPage", webpackPrefetch: true */ 'containers/Admin/AccountPage')}
        CHUNK_ID="AccountPage"
      />
    ),
    exact: true,
  },
  {
    path: '/account/purchase-code',
    component: PurchaseCodePage,
    exact: true,
  },
  {
    path: '/account/danger-zone',
    component: DangerZonePage,
    exact: true,
  },
  {
    path: '/notifications',
    component: () => (
      <CodeSpliting
        component={() => import(/* webpackChunkName: "NotificationPage", webpackPrefetch: true */ 'containers/Admin/NotificationPage')}
        CHUNK_ID="NotificationPage"
      />
    ),
    exact: true,
  },
  {
    path: '/theme/settings',
    component: () => (
      <CodeSpliting
        component={() => import(/* webpackChunkName: "ThemeSettings", webpackPrefetch: true */ 'containers/Admin/ThemeBuilder/ThemeSettings')}
        CHUNK_ID="ThemeSettings"
      />
    ),
    exact: true,
  },
  {
    path: '/page',
    exact: true,
    component: () => (
      <CodeSpliting
        component={() => import(/* webpackChunkName: "DashboardPage", webpackPrefetch: true */ 'containers/Admin/PageBuilder/DashboardPage')}
        CHUNK_ID="DashboardPage"
      />
    ),
  },
  {
    path: '/theme',
    exact: true,
    component: () => (
      <CodeSpliting
        component={() => import(/* webpackChunkName: "ThemeDashboard", webpackPrefetch: true */ 'containers/Admin/ThemeBuilder/ThemeDashboard')}
        CHUNK_ID="ThemeDashboard"
      />
    ),
  },
  {
    path: '/page/templates',
    exact: true,
    component: () => (
      <CodeSpliting
        component={() => import(/* webpackChunkName: "TemplatesPage", webpackPrefetch: true */ 'containers/Admin/PageBuilder/TemplatesPage')}
        CHUNK_ID="TemplatesPage"
      />
    ),
  },
  {
    path: '/home',
    exact: true,
    title: 'Home',
    component: () => (
      <CodeSpliting component={() => import(/* webpackChunkName: "HomePage", webpackPrefetch: true */ 'containers/HomePage')} CHUNK_ID="HomePage" />
    ),
  },
  {
    path: '/builder',
    exact: true,
    title: 'Veda Builder',
    component: () => (
      <CodeSpliting
        component={() => import(/* webpackChunkName: "BuilderPage", webpackPrefetch: true */ 'containers/BuilderPage')}
        CHUNK_ID="BuilderPage"
      />
    ),
  },
  {
    path: '/iframe',
    exact: true,
    component: () => (
      <CodeSpliting
        component={() => import(/* webpackChunkName: "IframePage", webpackPrefetch: true */ 'containers/IframePage')}
        CHUNK_ID="IframePage"
      />
    ),
  },
  {
    path: '/page/blank',
    exact: true,
    component: BlankPage,
  },
  {
    path: '/page/home',
    exact: true,
    component: HomePage,
  },
  {
    path: '/page/article',
    exact: true,
    component: ArticlesPage,
  },
  {
    path: '/page/blog',
    exact: true,
    component: BlogPage,
  },
  {
    path: '/page/collection',
    exact: true,
    component: CollectionPage,
  },
  {
    path: '/page/products',
    exact: true,
    component: ProductsPage,
  },
  {
    path: '/page/customer/:pageType',
    exact: true,
    component: CustomerPages,
  },
  ...((configureApp.DEV_ENABLE_INSTALL_THEME
    ? [
        {
          path: '/theme/templates',
          exact: true,
          component: ThemeTemplates,
        },
      ]
    : []) as Page[]),
  {
    path: '/test',
    exact: true,
    component: TestPage,
  },
  {
    path: '/embeddedtest',
    exact: true,
    component: EmbeddedTestPage,
  },
  {
    path: '/preset-styles',
    exact: true,
    component: PresetStylesPage,
  },
  {
    path: '/preview',
    exact: true,
    component: () => (
      <CodeSpliting
        component={() => import(/* webpackChunkName: "PreviewPage", webpackPrefetch: true */ 'containers/IframePage/PreviewPage')}
        CHUNK_ID="PreviewPage"
      />
    ),
  },
  {
    path: '/manager-page',
    exact: true,
    component: () => (
      <CodeSpliting
        component={() => import(/* webpackChunkName: "PageManagement", webpackPrefetch: true */ 'containers/Admin/Management/Pages')}
        CHUNK_ID="PageManagement"
      />
    ),
  },
  {
    path: '/manager-page-category',
    exact: true,
    component: () => (
      <CodeSpliting
        component={() =>
          import(/* webpackChunkName: "ProductPageCategory", webpackPrefetch: true */ 'containers/Admin/Management/ProductPageCategory')
        }
        CHUNK_ID="ProductPageCategory"
      />
    ),
  },
  {
    path: '/manager-page-use-case',
    exact: true,
    component: () => (
      <CodeSpliting
        component={() => import(/* webpackChunkName: "ProductPageUseCase", webpackPrefetch: true */ 'containers/Admin/Management/ProductPageUseCase')}
        CHUNK_ID="ProductPageUseCase"
      />
    ),
  },
  {
    path: '/manager-theme-category',
    exact: true,
    component: () => (
      <CodeSpliting
        component={() =>
          import(/* webpackChunkName: "ProductThemeCategory", webpackPrefetch: true */ 'containers/Admin/Management/ProductThemeCategory')
        }
        CHUNK_ID="ProductThemeCategory"
      />
    ),
  },
  {
    path: '/manager-theme-settings',
    exact: true,
    component: () => (
      <CodeSpliting
        component={() =>
          import(/* webpackChunkName: "ThemeSettingsDefault", webpackPrefetch: true */ 'containers/Admin/Management/ThemeSettingsDefault')
        }
        CHUNK_ID="ThemeSettingsDefault"
      />
    ),
  },
  {
    path: '/manager-theme',
    exact: true,
    component: () => (
      <CodeSpliting
        component={() => import(/* webpackChunkName: "ThemeManagement", webpackPrefetch: true */ 'containers/Admin/Management/Themes')}
        CHUNK_ID="ThemeManagement"
      />
    ),
  },
  {
    path: '/manager-sections-updatable',
    exact: true,
    component: () => (
      <CodeSpliting
        component={() => import(/* webpackChunkName: "ListingUpdatable", webpackPrefetch: true */ 'packages/ListingSectionsUpdatable')}
        CHUNK_ID="ListingUpdatable"
      />
    ),
  },
  {
    path: '/maintenance',
    exact: true,
    component: Maintenance,
  },
];

const Loading = () => {
  return (
    <View
      css={{
        position: 'fixed',
        inset: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
      }}
    >
      <VedaLoadingItem />
    </View>
  );
};

const MainApp = () => {
  const globalMountState = useSelector(globalMountSelector);
  const setPlanForDev = useSetPlanForDev();

  useEffect(() => {
    if (globalMountState.status === 'success') {
      const planForDev = storage.getItem('PLAN_FOR_DEV');
      if (!!planForDev) {
        setPlanForDev(planForDev as PLAN_HANDLE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalMountState.status]);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact={true} path="/">
          <Redirect from="/" to={'/page'} exact></Redirect>
        </Route>

        {pages.map(({ component: Component, path, ...rest }) => {
          const _Component =
            path === '/iframe' || path === '/preview' ? (
              <Component />
            ) : (
              <AsyncComponent Request={<Loading />} status={globalMountState.status} Success={<Component />} />
            );
          return (
            <GuardedRoute key={path} {...rest} path={path}>
              {_Component}
            </GuardedRoute>
          );
        })}

        <Route>
          <NotFoundPage />
        </Route>
      </Switch>

      {globalMountState.status === 'success' && (
        <>
          <ModalSyncShopifyForSaveSuccess />
          <ModalSyncShopifyForPreviewSuccess />
          <ModalActiveThemeAppExtension id={MODAL_ACTIVE_THEME_APP_EXTENSION} />
          <ModalReportAfterError id={MODAL_REPORT_AFTER_SYNC_ERROR} />
          <ModalReportAfterError id={MODAL_REPORT_AFTER_IMPORT_THEME_ERROR} />
          <ModalReportAfterError id={MODAL_REPORT_INITIALIZATION_SESSION_BUILDER_ERROR} />
          <ModalReportAfterError id={MODAL_REPORT_SAVE_ERROR} />
          <ModalReportAfterError id={MODAL_REPORT_SAVE_PAGE_SETITNGS_IN_DASHBOARD_ERROR} />
          <ModalReportAfterError id={MODAL_REPORT_SAVE_THEME_SETITNGS_IN_DASHBOARD_ERROR} />
          <ModalReportAfterError id={MODAL_REPORT_DELETE_PAGE_ERROR} />
          <ModalReportAfterError id={MODAL_REPORT_UPDATE_STATUS_PAGE_ERROR} />
          <ModalReportAfterError id={MODAL_REPORT_ACTIVE_THEME_VEDA_ERROR} />
          <ModalSocketStuck type="Force sync shopify" after={30000} />
          <ModalSocketStuck type="Sync shopify" after={30000} />
          <ModalSocketStuck type="Import theme" after={30000} />
          <ModalSocketStuck type="Migrate theme on shopify" after={30000} />
          <ModalSocketStuck type="Clone các thành phần từ page atom service sang theme atom service" after={30000} />
          <ModalClearCloudflare />
          <ModalCampaign />
          <ValidateCoupon />
          <ModalChangePlanName />
          <ModalUpgradePlan />
          <ModalInstallThemeShopify />
          <ModalInstallThemeForest />
          <ModalUpdateShopifyThemeId />
          {/* <ModalCheckStorefrontPassword /> */}
        </>
      )}
      {window.location.pathname !== '/dev-login' && <ModalUnauthorized />}
    </BrowserRouter>
  );
};

const Routes: FC = () => {
  const authState = useSelector(authSelector);
  const getUserInfo = useVerifyUser();
  const setAccessToken = useSetAccessToken();
  const { sendMessage } = useCrispChat();

  useEffect(() => {
    const token = storage.getItem('TOKEN');

    if (configureApp.env === 'dev') {
      if (authState.accessToken === '') {
        setAccessToken(token ?? 'dev');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.accessToken]);

  useEffect(() => {
    if (!isIframePage() && !isPreviewPage()) {
      if (configureApp.env === 'dev') {
        if (authState.accessToken) {
          getUserInfo.request(undefined);
        }
      } else {
        getUserInfo.request(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.accessToken]);

  return (
    <ErrorBoundary onRetry={() => sendMessage({ code: MODAL_REPORT_CANT_ACCESS_VEDA, message: "I can't access Veda Builder" })}>
      <AsyncComponent Failure={<ErrorWithCrispChat />} status={authState.status} Success={<MainApp />} Request={<Loading />} />
    </ErrorBoundary>
  );
};

export default Routes;
