import { Button } from '@wiloke/ui';
import { Table } from 'antd';
import { Dashboard } from 'containers/Dashboard';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { View } from 'wiloke-react-core';
import { useDeleteRecord, useGetGeneralInfomationOfRecords, useSetRecordInteracting } from './redux/actions';
import { selectListingSectionsUpdatable } from './redux/selectors';
import { RecordTypeMappingToLabel } from './constants/RecordTypeMapping';
import { ModalUpgrade } from './components/ModalUpgrade';
import './styles.css';

const RECORDS_PER_PAGE = 6;
export const ListingSectionsUpdatable = () => {
  const { statusRequest, records, totalRecords, queueDeleting, queueDeleteFailed } = useSelector(selectListingSectionsUpdatable);
  const [currentPage, setCurrentPage] = useState(1);

  const getGernalInfomationOfRecords = useGetGeneralInfomationOfRecords();
  const setRecordInteracting = useSetRecordInteracting();
  const deleteRecord = useDeleteRecord();

  useEffect(() => {
    getGernalInfomationOfRecords.request({
      limit: RECORDS_PER_PAGE,
      offset: RECORDS_PER_PAGE * (currentPage - 1),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const renderContent = () => {
    if (statusRequest === 'failure') {
      return (
        <Button
          onClick={() => {
            getGernalInfomationOfRecords.request({
              limit: RECORDS_PER_PAGE,
              offset: RECORDS_PER_PAGE * (currentPage - 1),
            });
          }}
        >
          Retry
        </Button>
      );
    }
    return (
      <View>
        <Table
          loading={statusRequest === 'loading'}
          dataSource={records}
          rowKey={({ generalInfomation }) => generalInfomation.firebaseTrackingData.atomCommandId}
          pagination={{
            pageSize: RECORDS_PER_PAGE,
            current: currentPage,
            showLessItems: true,
            total: totalRecords,
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
          onChange={({ current }) => {
            if (typeof current === 'number') {
              setCurrentPage(current);
            }
          }}
          columns={[
            {
              title: 'Tên section',
              render(_, { generalInfomation, metaData }) {
                return (
                  <View css={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                    <View>{generalInfomation.sectionAtom.label}</View>
                    {metaData && (
                      <View color="light" css={{ padding: '4px 6px', borderRadius: '6px', fontSize: '12px' }} backgroundColor="warning">
                        Đã từng truy cập
                      </View>
                    )}
                  </View>
                );
              },
            },
            {
              title: 'Loại section',
              sorter: (a, b) => {
                return a.generalInfomation.firebaseTrackingData.type.localeCompare(b.generalInfomation.firebaseTrackingData.type, 'en', {
                  sensitivity: 'base',
                });
              },
              filters: Object.keys(RecordTypeMappingToLabel).map(item => ({
                text: RecordTypeMappingToLabel[item as keyof typeof RecordTypeMappingToLabel],
                value: item,
              })),
              onFilter: (value, { generalInfomation }) => generalInfomation.firebaseTrackingData.type === value,
              render(_, { generalInfomation }) {
                return RecordTypeMappingToLabel[generalInfomation.firebaseTrackingData.type];
              },
            },
            {
              title: 'Version mới nhất',
              render(_, { generalInfomation }) {
                return generalInfomation.sectionAtom.currentVersion;
              },
            },
            {
              title: 'Update lúc',
              sorter: true,
              render(_, { generalInfomation }) {
                return generalInfomation.firebaseTrackingData.createdAt.toDate().toLocaleString();
              },
            },
            {
              title: 'Các thao tác',
              render: (_, record) => {
                const isDeleteFailed = queueDeleteFailed.includes(record.generalInfomation.firebaseTrackingData.atomCommandId);
                return (
                  <View css={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Button size="extra-small" radius={6} onClick={() => setRecordInteracting(record)}>
                      Update
                    </Button>
                    <Button
                      backgroundColor={isDeleteFailed ? 'github' : 'danger'}
                      size="extra-small"
                      radius={6}
                      onClick={() => deleteRecord.request({ record })}
                      loading={queueDeleting.includes(record.generalInfomation.firebaseTrackingData.atomCommandId)}
                    >
                      {!isDeleteFailed ? 'Bỏ qua version này' : 'Thử lại'}
                    </Button>
                  </View>
                );
              },
            },
          ]}
        />
        <ModalUpgrade />
      </View>
    );
  };

  return <Dashboard Content={renderContent} />;
};
