import { all, put, retry, SagaReturnType } from 'redux-saga/effects';
import { adapterGetAddons } from 'services/AddonService/Adapters/adapterGetAddons';
import { getAddonsOfThemeClient } from 'services/ThemeService/Logic/getAddonsOfThemeClient';
import { getAddonVersion } from 'store/actions/versions/actionSectionVersion';
import { getThemeAddons } from 'store/global/themeAddons/actions';
import { getChangelogsOfAtoms as getChangelogsOfAddonAtoms } from 'services/AddonService/Logic/Changelogs';
import { setOriginalThemeAddons } from 'store/reducers/sliceOriginThemeAddons';

interface Params {
  addonCommandIds: string[];
}
/** Xử lý lấy theme addons */
export function* getGlobalThemeAddons({ addonCommandIds }: Params) {
  const addonResponse: Awaited<ReturnType<typeof getAddonsOfThemeClient>> = yield retry(3, 1000, getAddonsOfThemeClient, { addonCommandIds });
  const addons = adapterGetAddons(addonResponse);
  yield put(getThemeAddons.success({ addons }));

  yield put(setOriginalThemeAddons(addons));

  /** Xử lý addons version */
  const addonSectionSourceIds = Array.from(
    new Set(
      addonResponse.reduce<string[]>((res, addonSection) => {
        if (addonSection.parentCommandId) {
          return res.concat(addonSection.parentCommandId);
        }
        return res;
      }, []),
    ),
  );
  yield all(
    addonSectionSourceIds.map(addonSectionCommandId => {
      return put(getAddonVersion.request({ addonCommandId: addonSectionCommandId }));
    }),
  );
  const addonSectionsVersion: SagaReturnType<typeof getChangelogsOfAddonAtoms> = yield retry(
    3,
    1000,
    getChangelogsOfAddonAtoms,
    addonSectionSourceIds,
  );
  yield all(addonSectionsVersion.map(({ atomCommandId, version }) => put(getAddonVersion.success({ addonCommandId: atomCommandId, data: version }))));
}
