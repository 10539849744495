import { Button, MyModal } from '@wiloke/ui';
import { message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { themeAddonsSelector, themeHeaderFooterSelector } from 'store/selectors';
import { copyToClipboard } from 'utils/copyToClipboard';
import getPageInfo from 'utils/functions/getInfo';
import { isBuilderPage } from 'utils/isBuilderPage';
import { Space, View } from 'wiloke-react-core';

interface CommandIds {
  headers: string[];
  footers: string[];
  addons: string[];
  variant: 'Draft' | 'Atom';
  themeCommandId: string;
}
type Static = (params: CommandIds) => void;

let modalWaringThemeHeaderFooterNAddonHandler: Static = () => {};

export const ModalWaringThemeHeaderFooterNAddon = () => {
  const [commandIds, setCommandIds] = useState<CommandIds | undefined>(undefined);
  const { headers, footers, getFootersStatus, getHeadersStatus } = useSelector(themeHeaderFooterSelector);
  const { status, data: addons } = useSelector(themeAddonsSelector);

  const [isVisible, setIsVisible] = useState(false);

  const themeId = getPageInfo('themeId');
  const isThemeBuilder = themeId && isBuilderPage();

  const isHeaderError = useMemo(() => {
    return isThemeBuilder && commandIds && getHeadersStatus === 'success' && commandIds.headers.length && !headers.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getHeadersStatus, commandIds]);

  const isFooterError = useMemo(() => {
    return isThemeBuilder && commandIds && getFootersStatus === 'success' && commandIds.footers.length && !footers.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFootersStatus, commandIds]);

  const isAddonError = useMemo(() => {
    return isThemeBuilder && commandIds && status === 'success' && commandIds.addons.length && !addons.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, commandIds]);

  useEffect(() => {
    if (isThemeBuilder && commandIds && getFootersStatus === 'success' && getHeadersStatus === 'success' && status === 'success') {
      if (isHeaderError || isFooterError || isAddonError) {
        setIsVisible(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFootersStatus, getHeadersStatus, status, commandIds]);

  useEffect(() => {
    modalWaringThemeHeaderFooterNAddonHandler = params => {
      setCommandIds(params);
    };
  }, []);

  const errors = [isHeaderError ? 'header' : '', isFooterError ? 'footer' : '', isAddonError ? 'addon' : ''];
  return (
    <MyModal isVisible={isVisible} okText="" cancelText="Đóng" onCancel={() => setIsVisible(false)} headerText="Cảnh báo">
      <View color="danger" css={{ fontSize: '18px', fontWeight: 500 }}>
        Cảnh báo. Có lẽ cái gì đó đang sai sai
      </View>
      <Space size={12} />
      <View>
        Không tìm thấy {errors.filter(item => !!item).join(', ')} trong phiên thao tác mặc dù theme có tồn tại headers, footers. Để không gì đáng tiếc
        xảy ra, vui lòng liên hệ Developers để được hỗ trợ
      </View>
      <Space size={12} />
      <Button
        radius={6}
        size="small"
        onClick={() => {
          copyToClipboard(`${commandIds?.variant === 'Atom' ? 'Theme Atom' : 'Theme Draft'}: ${commandIds?.themeCommandId}`);
          message.success('Đã copy');
        }}
      >
        Copy lời nhắn
      </Button>
    </MyModal>
  );
};

ModalWaringThemeHeaderFooterNAddon.setCommandIds = (params: CommandIds) => {
  modalWaringThemeHeaderFooterNAddonHandler(params);
};
