import { AxiosResponse } from 'axios';
import { BE_PageClient } from 'services/PageService/VedaApplication/types';
import { ToRequiredKeys } from 'utils';
import fetchAPI from 'utils/functions/fetchAPI';
import { clientBaseUrl } from '../const';

interface UpdateClient {
  /** Dữ liệu sẽ được apply vào record */
  page: ToRequiredKeys<Partial<BE_PageClient>, 'commandId'>;
  /** Trường này để BE xử lý việc lưu ảnh chụp màn hình khi save */
  imageAtomCommandId?: string | null;
  group: 'THEME' | 'GENERAL';
}

interface ResponseSuccess {
  message: string;
  info: BE_PageClient;
}

export const updateClient = async ({ page, imageAtomCommandId, group }: UpdateClient) => {
  const { commandId } = page;
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${clientBaseUrl}/${commandId}`,
    method: 'PUT',
    data: {
      ...page,
      imageAtomCommandId,
      group,
    },
  });
  return response.data;
};
