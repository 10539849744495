import { File } from 'types/Result';
import { PageSection } from 'types/Sections';
import { inlineCss } from 'utils/functions/InlineCss';
import { sassCompile } from '@wiloke/functions';

interface GetCssFromAddonScsss {
  addonSection: PageSection;
}
export const getCssFromAddonScss = async ({ addonSection }: GetCssFromAddonScsss): Promise<File> => {
  const scssCompiled = await sassCompile.client(addonSection.data.scss ?? '', addonSection.id);
  const inlineCssResult = inlineCss.getCssFromSettings([addonSection].map(section => section.data.settings));
  const content = `
    ${scssCompiled}
    ${inlineCssResult}
  `;
  return {
    type: 'css của 1 addon',
    id: addonSection.id,
    name: addonSection.label,
    content: content,
    section: undefined,
  };
};
