import { useDeleteTemplatePage } from 'containers/Admin/PageBuilder/TemplatesPage';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ServerTemplateResponse } from 'services/PagesBuilderService';
import { authSelector } from 'store/selectors';

export const useDeleteEditTemplate = () => {
  const { shopName } = useSelector(authSelector);
  const deleteTemplatePage = useDeleteTemplatePage();
  const history = useHistory();

  const handleEditPage = (page: ServerTemplateResponse) => () => {
    const entityVariant = 'Atom';
    history.push(`/builder?shop=${shopName}&id=${page.commandId}&entityVariant=${entityVariant}`, {
      type: page.type,
      label: page.label,
      shopifyRepresentPage: page.shopifyRepresentPage,
      shopifyPages: page.shopifyPages,
      isCreate: false,
      entityVariant,
    });
  };

  const handleDeleteTemplatePage = (commandId: string) => () => {
    deleteTemplatePage.request({ commandId });
  };

  return {
    handleEditPage,
    handleDeleteTemplatePage,
  };
};
