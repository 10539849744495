import { Button, MyModal } from '@wiloke/ui';
import { notification } from 'antd';
import ButtonDropdown from 'components/ButtonDropdown';
import { useSaveTheme } from 'containers/BuilderPage/store/saveForBuilder/actions';
import {
  modalSaveThemeVisibleSelector,
  pageSelectedInSaveThemeSelector,
  useSetVisibleModalSaveTheme,
} from 'containers/BuilderPage/store/saveForBuilder/slice';
import { useSetSelectPage } from 'containers/BuilderPage/store/selectPage/slice';
import { useSetTwigLoading } from 'containers/BuilderPage/store/twigLoading/slice';
import { useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { syncPageNotification } from 'store/global/socket/syncShopifyNClonePageAtomServiceToThemeAtomService/watchSyncToShopify';
import { pagesSelector, saveForBuilderSelector, socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector } from 'store/selectors';
import { i18n } from 'translation';
import { AddonOfTheme_Atom_N_Client } from 'types/Addons';
import { Result } from 'types/Result';
import { SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client } from 'types/Sections';
import { LiquidSyntaxToTwigError } from 'utils/LiquidSyntaxToTwig';
import getPageInfo from 'utils/functions/getInfo';
import { getEntityVariant } from 'utils/getEntityVariant';
import { Image, Space, Styles, Text, View } from 'wiloke-react-core';
import useResultForSave from '../TopBar/useResult/useResultForSave';
import notifyImg from './notify-img.png';
import { useSetResponsive } from 'containers/BuilderPage/store/responsive/slice';

const contentModalStyles: Styles = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 90px',
  textAlign: 'center',
};

const bodyModalStyles: Styles = {
  width: '690px',
  minHeight: '380px',
  maxHeight: '380px',
};

// Todo: i18n
export const ModalSaveTheme = () => {
  const visible = useSelector(modalSaveThemeVisibleSelector);
  const pages = useSelector(pagesSelector);
  const pagesData = Object.values(pages.data);
  const { saveThemeStatus } = useSelector(saveForBuilderSelector);
  const pageSelected = useSelector(pageSelectedInSaveThemeSelector);
  const { statusSyncToShopify } = useSelector(socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector);

  const setVisible = useSetVisibleModalSaveTheme();
  const saveTheme = useSaveTheme();
  const themeId = getPageInfo('themeId');
  const location = useLocation();
  const history = useHistory<'/builder'>();
  const setSelectPage = useSetSelectPage();
  const setTwigLoading = useSetTwigLoading();
  const setDeviceResponsive = useSetResponsive();

  const { getResult: getResultForSave, isExtracting: isExtractingForSave } = useResultForSave();

  const { connect, disconnect, statusSocketConnection } = useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService('sync_shopify');

  const handleRedirectAfterSave = () => {
    const nextPageId = pageSelected[0];
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.delete('id');
    urlSearchParams.append('id', nextPageId);
    history.push(`/builder?${urlSearchParams.toString()}`, history.location.state);
    setSelectPage(true);
    setTwigLoading(true);
    setVisible(false);
    setDeviceResponsive('desktop');
  };

  const handleSaveAndPublish = () => {
    connect({
      onSuccess: async () => {
        try {
          const result = await getResultForSave({
            entityVariant: getEntityVariant(location),
            errorOption: 'throw',
          });

          saveTheme.request({
            isIgnoreSyncShopify: false,
            variant: getEntityVariant(location),
            commandId: themeId,
            featuredImage: result.theme.themeSettings.generalSettings.featuredImage ?? '',
            globalJs: result.theme.globalJs,
            globalScss: result.theme.globalScss,
            name: result.theme.themeSettings.generalSettings.label,
            outputBuilder: result,
            pageIds: [],
            themeSettings: result.theme.themeSettings,
            vendors: {
              data: result.theme.vendors,
            },
            addons: result.theme.addons as AddonOfTheme_Atom_N_Client[],
            headers: (result.theme.header ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
            footers: (result.theme.footer ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
            onFulfill: () => {
              disconnect({});
              handleRedirectAfterSave();
            },
          });
        } catch (err) {
          disconnect({});
          if (err instanceof LiquidSyntaxToTwigError) {
            notification.error({
              message: i18n.t('publish_shopify.error_in_code'),
              description: err.message,
            });
          } else if (err instanceof Error) {
            notification.error({
              message: i18n.t('publish_shopify.error_unknown.message'),
              description: i18n.t('publish_shopify.error_unknown.description'),
            });
          }
        }
      },
      onError: () => {
        notification.error({
          message: i18n.t('publish_shopify.init_sync_error.message'),
          description: i18n.t('publish_shopify.init_sync_error.description'),
        });
        syncPageNotification.done();
      },
    });
  };

  const handleSave = () => {
    connect({
      onSuccess: async () => {
        try {
          const result = await getResultForSave({
            entityVariant: getEntityVariant(location),
            errorOption: 'throw',
          });

          const _result: Result = {
            ...result,
            pages: {},
          };

          saveTheme.request({
            isIgnoreSyncShopify: false,
            variant: getEntityVariant(location),
            commandId: themeId,
            featuredImage: _result.theme.themeSettings.generalSettings.featuredImage ?? '',
            globalJs: _result.theme.globalJs,
            globalScss: _result.theme.globalScss,
            name: _result.theme.themeSettings.generalSettings.label,
            outputBuilder: _result,
            pageIds: [],
            themeSettings: _result.theme.themeSettings,
            vendors: {
              data: _result.theme.vendors,
            },
            addons: _result.theme.addons as AddonOfTheme_Atom_N_Client[],
            headers: (_result.theme.header ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
            footers: (_result.theme.footer ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
            onFulfill: () => {
              disconnect({});
              handleRedirectAfterSave();
            },
          });
        } catch (err) {
          disconnect({});
          if (err instanceof LiquidSyntaxToTwigError) {
            notification.error({
              message: i18n.t('publish_shopify.error_in_code'),
              description: err.message,
            });
          } else if (err instanceof Error) {
            notification.error({
              message: i18n.t('publish_shopify.error_unknown.message'),
              description: i18n.t('publish_shopify.error_unknown.description'),
            });
          }
        }
      },
      onError: () => {
        notification.error({
          message: i18n.t('publish_shopify.init_sync_error.message'),
          description: i18n.t('publish_shopify.init_sync_error.description'),
        });
        syncPageNotification.done();
      },
    });
  };

  return (
    <MyModal
      size="medium"
      bodyCss={bodyModalStyles}
      contentCss={contentModalStyles}
      isVisible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      headerText="Save page"
      depsHeightRecalculation={pagesData}
      cancelText=""
      okText=""
    >
      <Image src={notifyImg} height={200} />
      <Text size={16} fontFamily="secondary">
        Please save page before moving to another page. If you do not save before changing the page, the data will not be saved
      </Text>
      <Space size={10} />
      <View css={{ display: 'inline-flex', columnGap: '10px', alignItems: 'center' }}>
        <Button
          backgroundColor="secondary"
          radius={4}
          css={{ fontWeight: 500, height: '36px', fontSize: '13px', paddingTop: '8px', paddingBottom: '8px' }}
          size="extra-small"
          onClick={handleRedirectAfterSave}
        >
          Go to selected page
        </Button>
        <ButtonDropdown
          loading={statusSocketConnection === 'loading' || isExtractingForSave || saveThemeStatus === 'loading'}
          dropdownData={[{ icon: 'file', label: i18n.t('builderPage.save_as_draft'), value: 'save_as_draft' }]}
          onClick={handleSaveAndPublish}
          onClickItemDropdown={value => {
            if (value === 'save_as_draft') {
              handleSave();
            }
          }}
        >
          {statusSocketConnection === 'loading'
            ? i18n.t('publish_shopify.init_sync')
            : isExtractingForSave
            ? i18n.t('builderPage.extracting')
            : saveThemeStatus === 'loading'
            ? i18n.t('builderPage.saving')
            : statusSyncToShopify === 'loading'
            ? i18n.t('publish_shopify.syncing')
            : i18n.t('general.publish', { text: i18n.t('general.page'), textTransform: 'capitalize' })}
        </ButtonDropdown>
      </View>
    </MyModal>
  );
};
