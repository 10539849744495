import { HelmetImportShopifyFont } from 'components/HelmetImportShopifyFont/HelmetImportShopifyFont';
import SelectAntd, { Option, SelectAntdProps } from 'components/SelectAntd';
import { getCssFont, shopifyFonts } from 'components/ShopifyFontsField/data';
import { FC, useEffect, useState } from 'react';
import { useStyleSheet, useTheme, View } from 'wiloke-react-core';
import classNames from 'wiloke-react-core/utils/classNames';
import * as styles from './styles';

export interface ShopifyFontsProps extends Omit<SelectAntdProps, 'setOptionStyle' | 'onChange'> {
  /** Sự kiện khi chọn 1 option */
  onChange?: (value: string) => void;
  value?: string;
  fonts?: Option[];
}

export const ShopifyFonts: FC<ShopifyFontsProps> = ({ style, onChange, className, fonts, value = '', ...rest }) => {
  const { colors } = useTheme();
  const { styles: styleHooks } = useStyleSheet(colors);
  const [fontState, setFontState] = useState(fonts ?? shopifyFonts);

  useEffect(() => {
    if (!!fonts) {
      setFontState(fonts);
    }
  }, [fonts]);

  return (
    <SelectAntd
      {...rest}
      defaultValue={value}
      value={value}
      style={{ ...style, fontFamily: value }}
      getPopupContainer={trigger => trigger.parentElement}
      showSearch
      listItemHeight={35}
      dropdownClassName={styleHooks(styles.dropdown)}
      onChange={onChange}
      className={classNames(className, styleHooks(styles.select))}
      data={fontState}
      renderOption={item => {
        return (
          <View css={styles.font}>
            <HelmetImportShopifyFont option={item as any} />
            <View style={{ fontFamily: getCssFont(item)?.family }} css={{ fontSize: '20px', paddingTop: '10px', paddingBottom: '10px' }}>
              {item.label}
            </View>
          </View>
        );
      }}
    />
  );
};
