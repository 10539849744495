import { ColorScheme, ColorSchemes } from 'types/Result';

interface State {
  colorSchemes: ColorSchemes;
  colorSchemeInteracting: ColorScheme | null;
}

const initialState: State = {
  colorSchemeInteracting: null,
  colorSchemes: [],
};

export default initialState;
