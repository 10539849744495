import { getJs, optimizeSectionJs } from 'generate/utils/generateHelpers';
import { File } from 'types/Result';
import { PageSection } from 'types/Sections';

interface GetAllJsOfPage_Dùng_OnlyPageSettings {
  globalJs: string;
  variant: '1 phần page - bao gồm page settings';
}
interface GetAllJsOfPage_AllPropertiesOfPage {
  globalJs: string;
  sectionsEnable_notIncludeAddonSections_includeMegamenuSections: PageSection[];
  variant: 'Toàn bộ page - bao gồm các sections, page settings';
}

export const getAllJsOfPage = (params: GetAllJsOfPage_Dùng_OnlyPageSettings | GetAllJsOfPage_AllPropertiesOfPage): [File] | [File, File] => {
  const { variant, globalJs } = params;

  const globalJsFile: File = {
    content: `
      ${[globalJs].join('\n')}
      /* globalJsBorder */
    `,
    type: 'globalJs của page - bảo gồm globalJs và lazyLoadJs của page',
    id: 'globalJs',
    name: 'globalJs',
    section: undefined,
  };

  if (variant === '1 phần page - bao gồm page settings') {
    return [globalJsFile];
  }
  if (variant === 'Toàn bộ page - bao gồm các sections, page settings') {
    const { sectionsEnable_notIncludeAddonSections_includeMegamenuSections } = params;
    const jsArr = sectionsEnable_notIncludeAddonSections_includeMegamenuSections.reduce<string[]>((res, item) => {
      if (item.enable) {
        return res.concat(getJs(item.id, item.data.js ?? ''));
      }
      return res;
    }, []);
    const jsArrOptimized = optimizeSectionJs(jsArr);

    return [
      globalJsFile,
      {
        content: jsArrOptimized,
        type: 'js của page - bao gồm js của các section',
        name: 'sections',
        id: 'sections',
        section: undefined,
      },
    ];
  }
  throw new Error('getAllJsOfPage -> Code thêm');
};
