import { Button, MyModal } from '@wiloke/ui';
import {
  modalSavePageBeforeRedirectHeaderFooterVisibleSelector,
  useSetModalSavePageBeforeRedirectHeaderFooterVisible,
} from 'containers/BuilderPage/store/saveForBuilder/slice';
import { useSelector } from 'react-redux';
import { Image, Space, Styles, Text, View } from 'wiloke-react-core';
import notifyImg from './notify-img.png';
import getPageInfo from 'utils/functions/getInfo';
import { useHistory } from 'react-router';
import { Consts, PAGE_BUILD_HEADER_FOOTER } from 'utils/constants/constants';
import { syncPageNotification } from 'store/global/socket/syncShopifyNClonePageAtomServiceToThemeAtomService/watchSyncToShopify';
import { i18n } from 'translation';
import { useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { Result } from 'types/Result';
import { getEntityVariant } from 'utils/getEntityVariant';
import useResultForSave from 'containers/BuilderPage/components/TopBar/useResult/useResultForSave';
import { useLocation } from 'react-router';
import { notification } from 'antd';
import { isThemeBuilder } from 'utils/validateBuilderMode';
import { useSavePageForUser, useSaveTheme } from 'containers/BuilderPage/store/saveForBuilder/actions';
import {
  pageDataSelector,
  pagesDataSelector,
  saveForBuilderSelector,
  socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector,
} from 'store/selectors';
import { useUndoRedoForRedux } from 'hooks/useUndoRedoForRedux/useUndoRedoForRedux';
import { useSetPageAfterCreate } from 'store/actions/actionPages';
import { PageOfThemeService } from 'types/Page';
import { SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client } from 'types/Sections';
import { LiquidSyntaxToTwigError } from 'utils/LiquidSyntaxToTwig';
import { AddonOfTheme_Atom_N_Client } from 'types/Addons';
import { useMemo } from 'react';

const contentModalStyles: Styles = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 90px',
  textAlign: 'center',
};

const bodyModalStyles: Styles = {
  width: '690px',
  minHeight: '380px',
  maxHeight: '380px',
};

export const ModalSavePageBeforeRedirectHeaderFooter = () => {
  const { statusSyncToShopify } = useSelector(socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector);
  const { savePageStatus, saveThemeStatus } = useSelector(saveForBuilderSelector);

  const visible = useSelector(modalSavePageBeforeRedirectHeaderFooterVisibleSelector);
  const page = useSelector(pageDataSelector);
  const pages = useSelector(pagesDataSelector);

  const shopName = getPageInfo('shop');
  const history = useHistory<'/builder'>();
  const location = useLocation<'/builder'>();
  const id = getPageInfo('id');
  const themeId = getPageInfo('themeId');

  const { connect, disconnect, statusSocketConnection } = useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService('sync_shopify');
  const { getResult: getResultForSave, isExtracting: isExtractingForSave } = useResultForSave();

  const setVisible = useSetModalSavePageBeforeRedirectHeaderFooterVisible();
  const setPageAfterCreate = useSetPageAfterCreate();
  const savePageForUser = useSavePageForUser();
  const saveTheme = useSaveTheme();

  const isSyncingForSave = useMemo(() => {
    return (
      statusSocketConnection === 'loading' ||
      statusSyncToShopify === 'loading' ||
      isExtractingForSave ||
      savePageStatus === 'loading' ||
      saveThemeStatus === 'loading'
    );
  }, [isExtractingForSave, savePageStatus, saveThemeStatus, statusSocketConnection, statusSyncToShopify]);

  const clearLabelParams = () => {
    const state = {
      ...history.location.state,
      label: '',
      handle: '',
      isCreate: false,
    };
    history.replace({ ...history.location, state });
  };

  const navigateToHeaderFooter = () => {
    history.push(`/builder?shop=${shopName}&id=${PAGE_BUILD_HEADER_FOOTER}&entityVariant=Client`);
    setVisible(false);
  };

  const handleNavigateWithoutSaving = () => {
    navigateToHeaderFooter();
  };

  const handleNavigateWithSaving = () => {
    syncPageNotification.next({
      title: i18n.t('publish_shopify.sync_page_message.step', { text: '1' }),
      description: i18n.t('publish_shopify.init_sync'),
    });

    connect({
      onSuccess: async () => {
        try {
          const result = await getResultForSave({
            entityVariant: getEntityVariant(location),
            errorOption: 'throw',
          });

          const result_with_enable_page: Result = {
            ...result,
            pages: {
              ...result.pages,
              [id]: {
                ...result.pages[id],
                data: {
                  ...result.pages[id].data,
                  page: {
                    ...result.pages[id].data.page,
                    enable: true,
                  },
                },
              },
            },
          };

          const pageData = result_with_enable_page.pages[page.commandId];

          // Nếu page không có section thì không cho save
          if (typeof pageData === 'undefined') {
            notification.error({
              message: i18n.t('builderPage.must_have_section'),
            });
            return;
          }

          // nếu params có themeId thì là save theme còn không là save page
          if (!isThemeBuilder()) {
            const isCreatePage =
              (id === Consts.BlankCommandId && page.commandId === Consts.BlankCommandId) ||
              location.state?.isCreate === true ||
              getEntityVariant(location) !== 'Client';

            savePageForUser.request({
              isIgnoreSyncShopify: false,
              isSaveAsLandingPage: false,
              isOverrideIndividualPages: true,
              data: { ...page, label: pageData.data.pageSettings.generalSettings.label },
              type: isCreatePage ? 'create' : 'update',
              result: pageData,
              addons: result_with_enable_page.theme.addons,
              builderType: 'page',
              previewImage: pageData.data.page.image ?? { src: '', height: 0, width: 0 },
              outputBuilder: result_with_enable_page,
              onSuccess: pageResponse => {
                useUndoRedoForRedux.reset?.();
                disconnect({});
                if (isCreatePage && pageResponse) {
                  // @duong: Nếu là tạo page thì sau khi save thành công sẽ thay id ở trên params bằng id mới của server trả về và xóa state params ở bên dashboard bắn sang
                  const entityVariant = 'Client';
                  history.replace({
                    ...history.location,
                    state: {
                      ...history.location.state,
                      label: '',
                      isCreate: false,
                      isAdminTemplate: false,
                      entityVariant,
                    },
                    search: `?shop=${shopName}&id=${pageResponse.commandId}${!!themeId ? '&themeId=' + themeId : ''}&entityVariant=${entityVariant}`,
                  });

                  setPageAfterCreate(pageResponse);
                  navigateToHeaderFooter();
                } else {
                  navigateToHeaderFooter();
                }
              },
              onFulfill: () => {},
              settings: {
                generalSettings: pageData.data.pageSettings.generalSettings,
                globalJs: pageData.data.pageSettings.globalJs,
                globalScss: pageData.data.pageSettings.globalScss,
                vendors: pageData.data.pageSettings.vendors,
                cssVariables: result_with_enable_page.theme.themeSettings.cssVariables,
                layoutSettings: result_with_enable_page.theme.themeSettings.layoutSettings,
              },
              page: pageData.data.page as PageOfThemeService,
              headers: (result_with_enable_page.theme.header ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
              footers: (result_with_enable_page.theme.footer ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
            });
          } else {
            const pageIds = Object.values(pages).map(item => item.commandId);

            saveTheme.request({
              isIgnoreSyncShopify: false,
              variant: getEntityVariant(location as any),
              commandId: themeId,
              featuredImage: result_with_enable_page.theme.themeSettings.generalSettings.featuredImage ?? '',
              globalJs: result_with_enable_page.theme.globalJs,
              globalScss: result_with_enable_page.theme.globalScss,
              name: result_with_enable_page.theme.themeSettings.generalSettings.label,
              outputBuilder: result_with_enable_page,
              pageIds: pageIds,
              themeSettings: result_with_enable_page.theme.themeSettings,
              vendors: {
                data: result_with_enable_page.theme.vendors,
              },
              addons: result_with_enable_page.theme.addons as AddonOfTheme_Atom_N_Client[],
              headers: (result_with_enable_page.theme.header ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
              footers: (result_with_enable_page.theme.footer ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
              onFulfill: () => {
                useUndoRedoForRedux.reset?.();
                disconnect({});
              },
            });
            clearLabelParams();
          }
        } catch (err) {
          console.log(err);
          disconnect({});
          if (err instanceof LiquidSyntaxToTwigError) {
            notification.error({
              message: i18n.t('publish_shopify.error_in_code'),
              description: err.message,
            });
          } else if (err instanceof Error) {
            notification.error({
              message: i18n.t('publish_shopify.error_unknown.message'),
              description: i18n.t('publish_shopify.error_unknown.description'),
            });
          }
        }
      },
      onError: () => {
        notification.error({
          message: i18n.t('publish_shopify.init_sync_error.message'),
          description: i18n.t('publish_shopify.init_sync_error.description'),
        });
        syncPageNotification.done();
      },
    });
  };

  return (
    <MyModal
      size="medium"
      bodyCss={bodyModalStyles}
      contentCss={contentModalStyles}
      isVisible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      headerText="Save page"
      cancelText=""
      okText=""
    >
      <Image src={notifyImg} height={200} />
      <Text size={16} fontFamily="secondary">
        You're advised to save changes before going to customize Global Header & Footer
      </Text>
      <Space size={10} />
      <View css={{ display: 'inline-flex', columnGap: '10px', alignItems: 'center' }}>
        <Button
          backgroundColor="secondary"
          radius={4}
          css={{ fontWeight: 500, height: '36px', fontSize: '13px', paddingTop: '8px', paddingBottom: '8px' }}
          size="extra-small"
          onClick={handleNavigateWithSaving}
          loading={isSyncingForSave}
        >
          {statusSocketConnection === 'loading'
            ? i18n.t('publish_shopify.init_sync')
            : isExtractingForSave
            ? i18n.t('builderPage.extracting')
            : savePageStatus === 'loading' || saveThemeStatus === 'loading'
            ? i18n.t('builderPage.saving')
            : statusSyncToShopify === 'loading'
            ? i18n.t('publish_shopify.syncing')
            : 'Publish the page and navigate'}
        </Button>
        <Button
          backgroundColor="primary"
          radius={4}
          css={{ fontWeight: 500, height: '36px', fontSize: '13px', paddingTop: '8px', paddingBottom: '8px' }}
          size="extra-small"
          onClick={handleNavigateWithoutSaving}
        >
          Navigate without publishing
        </Button>
      </View>
    </MyModal>
  );
};
