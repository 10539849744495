export const twig_error = {
  control_flow: {
    case_when: {
      or_in_when_clause: `
        "Case & When": You cannot use "or" in "when" -> Use "," instead of "or" as an alternative
        %% error_signal %%
      `,
      example: `
        "Case & When": Something wrong -> %% error_signal %%
        Correct Syntax:
          {% assign handle = "cake" %}
          {% case handle %}
            {% when "cake" %}
              This is a cake
            {% when "cookie", "biscuit" %}
              This is a cookie
            {% else %}
              This is not a cake nor a cookie
          {% endcase %}
      `,
    },
    unless: {
      example: `"Unless": Something wrong -> %% error_signal %%`,
      warning_message: `"Unless": Currently compound clauses will not be correct for all cases. To make sure no unfortunate situation will happen you can use multiple nested "Unless" as an alternative -> %% error_signal %%`,
    },
  },
  deprecated_tags: {
    currency_form: `Tag 'currency form' not supported yet`,
    include: `Tag 'include' not supported yet`,
  },
  filters: {
    abs: '',
    append: '',
    asset_img_url: `"asset_img_url" not supported yet`,
    asset_url: `"asset_url" not supported yet`,
    at_least: {
      params: `"at_least": need 1 parameter in the format number -> %% error_signal %%`,
      example: `
        "at_least" message: %% message %%
        Case 1:
          - Input: {{ 4 | at_least: 5 }} + {{ 4 | at_least: 3 }}
          - Output: 5 + 4
      `,
    },
    at_most: {
      params: `"at_most": need 1 parameter in the format number -> %% error_signal %%`,
      example: `
        "at_most" message: %% message %%
        Case 1:
          - Input: {{ 4 | at_most: 5 }} + {{ 4 | at_most: 3 }}
          - Output: 5 + 4
      `,
    },
    base64_decode: {
      value: `"base64_decode": Value must be string -> %% error_signal %%`,
      example: `
        "base64_decode" message: %% message %%
        Case 1:
          - Input: {{ 4 | at_most: 5 }} + {{ 4 | at_most: 3 }}
          - Output: 5 + 4
      `,
    },
    base64_encode: {
      value: `"base64_encode": Value must be string -> %% error_signal %%`,
      example: `
      "base64_encode" message: %% message %%
      Case 1:
        - Input: {{ 'one two three' | base64_encode }}
        - Ouput: b25lIHR3byB0aHJlZQ==
    `,
    },
    base64_url_safe_decode: {
      value: `"base64_url_safe_decode": Value must be string -> %% error_signal %%`,
      example: `
      "base64_url_safe_decode" message: %% message %%
      Case 1:
        - Input: {{ 'PHA-b2s_PC9wPg==' | base64_url_safe_decode }}
        - Ouput: <p>ok?</p>
    `,
    },
    base64_url_safe_encode: {
      value: `"base64_url_safe_encode": Value must be string -> %% error_signal %%`,
      example: `
      "base64_url_safe_encode" message: %% message %%
      Case 1:
        - Input: {{ '<p>ok?</p>' | base64_url_safe_encode }}
        - Ouput: PHA-b2s_PC9wPg==
    `,
    },
    brightness_difference: `brightness_difference not supported yet`,
    camelcase: ``,
    capitalize: ``,
    ceil: ``,
    color_brightness: {
      value: `"color_brightness": Value must be string -> %% error_signal %%`,
      example: `
      "color_brightness" message: %% message %%
      Case 1:
        - Input: {{ '#7ab55c' | color_brightness }}
        - Ouput: 153.21
    `,
    },
    color_contrast: {
      params: `"color_contrast" need 1 parameter in the format of string -> %% error_signal %%`,
      value: `"color_contrast": Value must be string -> %% error_signal %%`,
      color: `"color_contrast": color must be string -> %% error_signal %%`,
      example: `
      "color_contrast" message: %% message %%
      Case 1:
        - Input: {{ '#495859' | color_contrast: '#fffffb' }}
        - Ouput: 7.4
    `,
    },
    color_darken: {
      params: `"color_darken" need 1 parameter in the format number -> %% error_signal %%`,
      value: `"color_darken": Value must be string -> %% error_signal %%`,
      darkenValue: `"color_darken": darkenValue must be number -> %% error_signal %%`,
      darkenDomain: `"color_darken": darkenValue ∈ [0, 100] -> %% error_signal %%`,
      example: `
      "color_darken" message: %% message %%
      Case 1:
        - Input: {{ '#7ab55c' | color_darken: 30 }}
        - Ouput: #355325
    `,
    },
    color_desaturate: {
      params: `"color_desaturate" need 1 parameter in the format number -> %% error_signal %%`,
      value: `"color_desaturate": Value must be string -> %% error_signal %%`,
      desaturateValue: `"color_desaturate": desaturateValue must be number -> %% error_signal %%`,
      desaturateDomain: `"color_desaturate": desaturateValue ∈ [0, 100] -> %% error_signal %%`,
      example: `
      "color_desaturate" message: %% message %%
      Case 1:
        - Input: {{ '#7ab55c' | color_desaturate: 30 }}
        - Ouput: #869180
    `,
    },
    color_difference: `"color_difference" not supported yet`,
    color_extract: {
      params: `"color_extract" need 1 parameter in the format of string -> %% error_signal %%`,
      value: `"color_extract": Value must be string -> %% error_signal %%`,
      extractValue: `"color_extract": extractValue chỉ có thể là alpha, red, green, blue, hue, saturation và lightness -> %% error_signal %%`,
      example: `
      "color_extract" message: %% message %%
      Case 1:
        - Input: {{ '#7ab55c' | color_extract: 'red' }}
        - Ouput: 122
    `,
    },
    color_lighten: {
      params: `"color_lighten" need 1 parameter in the format number -> %% error_signal %%`,
      value: `"color_lighten": Value must be string -> %% error_signal %%`,
      lightenValue: `"color_lighten": lightenValue must be number -> %% error_signal %%`,
      example: `
      "color_lighten message: %% message %%
      Case 1:
        - Input: {{ '#7ab55c' | color_lighten: 30 }}
        - Ouput: #d0e5c5
    `,
    },
    color_mix: {
      params: `"color_mix" need 1 parameter in the format of string color -> %% error_signal %%`,
      value: `"color_mix": Value must be string -> %% error_signal %%`,
      colorValue: `"color_mix": color must be string -> %% error_signal %%`,
      weightValue: `"color_mix": weight must be number -> %% error_signal %%`,
      weightDomain: `"color_mix": weight ∈ [0, 100] -> %% error_signal %%`,
      example: `
      "color_mix" message: %% message %%
      Case 1:
        - Input: {{ '#7ab55c' | color_mix: '#ffc0cb', 50 }}
        - Ouput: #bdbb94
    `,
    },
    color_modify: {
      params: `"color_modify" need 2 parameters, field name will change and new value -> %% error_signal %%`,
      value: `"color_modify": Value must be string -> %% error_signal %%`,
      key: `"color_modify": key chỉ có thể là alpha, red, green, blue, hue, saturation và lightness -> %% error_signal %%`,
      newValue: `"color_modify": newValue must be number -> %% error_signal %%`,
      redDomain: `"color_modify": newValue ∈ [0, 255] -> %% error_signal %%`,
      greenDomain: `"color_modify": newValue ∈ [0, 255] -> %% error_signal %%`,
      blueDomain: `"color_modify": newValue ∈ [0, 255] -> %% error_signal %%`,
      hueDomain: `"color_modify": newValue ∈ [0, 360] -> %% error_signal %%`,
      saturationDomain: `"color_modify": newValue ∈ [0, 100] -> %% error_signal %%`,
      example: `
      "color_modify" message: %% message %%
      Case 1:
        - Input: {{ '#7ab55c' | color_modify: 'red', 255 }}
        - Ouput: #ffb55c
      Case 2:
        - Input: {{ '#7ab55c' | color_modify: 'alpha', 0.85 }}
        - Output: rgba(122, 181, 92, 0.85)
    `,
    },
    color_saturate: {
      params: `"color_saturate" need 1 parameter in the format number -> %% error_signal %%`,
      value: `"color_saturate": Value must be string -> %% error_signal %%`,
      saturateValue: `"color_saturate": saturateValue must be number -> %% error_signal %%`,
      saturateValueDomain: `"color_saturate": saturateValue ∈ [0, 100] -> %% error_signal %%`,
      example: `
      "color_saturate" message: %% message %%
      Case 1:
        - Input: {{ '#7ab55c' | color_saturate: 30 }}
        - Ouput: #6ed938
    `,
    },
    color_to_hex: {
      value: `"color_to_hex": Value must be string -> %% error_signal %%`,
      example: `
      "color_to_hex" message: %% message %%
      Case 1:
        - Input: {{ 'rgb(122, 181, 92)' | color_to_hex }}
        - Ouput: #7ab55c
      Case 2:
          - Input: {{ 'rgba(122, 181, 92, 0.5)' | color_to_hex }}
          - Ouput: #7ab55c
    `,
    },
    color_to_hsl: {
      value: `"color_to_hsl": Value must be string -> %% error_signal %%`,
      example: `
      "color_to_hsl" message: %% message %%
      Case 1:
        - Input: {{ '#7ab55c' | color_to_hsl }}
        - Ouput: hsl(100, 38%, 54%)
      Case 2:
          - Input: {{ 'rgba(122, 181, 92, 0.5)' | color_to_hsl }}
          - Ouput: hsla(100, 38%, 54%, 0.5)
    `,
    },
    color_to_rgb: {
      value: `"color_to_rgb": Value must be string -> %% error_signal %%`,
      example: `
      "color_to_rgb" message: %% message %%
      Case 1:
        - Input: {{ '#7ab55c' | color_to_rgb }}
        - Ouput: rgb(122, 181, 92)
      Case 2:
          - Input: {{ 'hsla(100, 38%, 54%, 0.5)' | color_to_rgb }}
          - Ouput: rgba(122, 181, 92, 0.5)
    `,
    },
    compact: {
      value: `"compact": Value must be array -> %% error_signal %%`,
      example: `
      "compact" message: %% message %%

      Without "compact" input:
        {% assign site_categories = site.pages | map: "category" %}
        {% for category in site_categories %}
        - {{ category }}
        {% endfor %}
      Without "compact" out:
        - business
        - celebrities
        -
        - lifestyle
        - sports
        -
        - technology

      Without "compact" input:
        {% assign site_categories = site.pages | map: "category" | compact %}
        {% for category in site_categories %}
        - {{ category }}
        {% endfor %}
      Without "compact" out:
        - business
        - celebrities
        - lifestyle
        - sports
        - technology
    `,
    },
    concat: ``,
    customer_login_link: {
      value: `"customer_login_link": Value must be string -> %% error_signal %%`,
      example: `
      "customer_login_link" message: %% message %%
      Case 1:
        - Input: {{ 'Log in' | customer_login_link }}
        - Ouput: <a href="/account/login" id="customer_login_link">Log in</a>
    `,
    },
    date: {
      example: `
      "date" message: %% message %%
      Case 1:
        - Input: {{ article.published_at | date: "%a, %b %d, %y" }}
        - Ouput: Tue, Apr 22, 14
      Case 2:
          - Input: {{ "March 14, 2016" | date: "%b %d, %y" }}
          - Ouput: Mar 14, 16
      Case 2:
          - Input: {{ 1667814083463 | date: "%b %d, %y" }}
          - Ouput: Dec 4, 22
    `,
    },
    default_errors: {
      value: `"default_errors": Value must be a string array -> %% error_signal %%`,
      example: `"default_errors": https://shopify.dev/api/liquid/filters/additional-filters#default_errors -> %% error_signal %%`,
      fake_message: 'Error message',
    },
    default_pagination: {
      example: `
      Error message: %% message %%
      Case 1:
        - Input:
        {% paginate collection.products by 2 %}
          {% for product in collection.products %}
            {{ product.title }} product card
          {% endfor %}

          {{ paginate | default_pagination }}
        {% endpaginate %}
        - Ouput: <span class="page current">1</span> <span class="page"><a href="/services/liquid_rendering/resource?page=2" title="">2</a></span> <span class="next"><a href="/services/liquid_rendering/resource?page=2" title="">Next &raquo;</a></span>
    `,
    },
    default: ``,
    divided_by: {
      params: `"divided_by" need 1 parameter in the format number -> %% error_signal %%`,
      value: `"value": dividend must be number -> %% error_signal %%`,
      divisor: `"value": divisor must be number -> %% error_signal %%`,
      example: `
      Error message: %% message %%
      Case 1:
        - Input: {{ 100 | divided_by: 10}}
        - Ouput: 10
    `,
    },
    downcase: ``,
    escape: ``,
    external_video_tag: `external_video_tag not supported yet`,
    external_video_url: `external_video_url not supported yet`,
    file_img_url: `file_img_url not supported yet`,
    file_url: `file_url not supported yet`,
    first: ``,
    floor: ``,
    font_face: `font_face not supported yet`,
    font_modify: `font_modify not supported yet`,
    font_url: `font_url not supported yet`,
    forloop: ``,
    format_address: `format_address not supported yet`,
    global_asset_url: `global_asset_url not supported yet`,
    handle: {
      value: `"handle": Value must be string -> %% error_signal %%`,
      example: `
      "handle" message: %% message %%
      Case 1:
        - Input: {{ '100% M & Ms!!!' | handleize }}
        - Ouput: 100-m-ms
    `,
    },
    highlight_active_tag: `highlight_active_tag not supported yet`,
    highlight: `highlight not supported yet`,
    hmac_sha1: `hmac_sha1 not supported yet`,
    hmac_sha256: `hmac_sha256 not supported yet`,
    image_tag: `image_tag not supported yet`,
    image_url: {
      size: `"image_url": Liquid error: Width & Height must be between 1 and 5760 -> %% error_signal %%`,
      crop: `"image_url": Liquid error: Invalid crop mode -> %% error_signal %%`,
      format: `"image_url": Liquid error: Invalid extension -> %% error_signal %%`,
      pad_color: `"image_url": Liquid error: Color padding must be between 000000 and ffffff -> %% error_signal %%`,
      unexpect_params: `"image_url": Liquid error: Unexpect parameters -> %% error_signal %%`,
      example: `
      "image_url" message: %% message %%
      Case 1:
        - Input: <img src="{{ product.featured_image | image_url: 300 }}"  alt="{{ product.title }}" />
        - Ouput: ...
      Case 2:
        - Input: <img src="{{ product.featured_image | image_url }}"  alt="{{ product.title }}" />
        - Ouput: ...
    `,
    },
    img_url: {
      size: `"img_url": img_url needs at most 1 parameter in the format string -> %% error_signal %%`,
      example: `
      "img_url" message: %% message %%
      Case 1:
        - Input: <img src="{{ product.featured_image | img_url: '300x300' }}"  alt="{{ product.title }}" />
        - Ouput: ...
      Case 2:
        - Input: <img src="{{ product.featured_image | img_url }}"  alt="{{ product.title }}" />
        - Ouput: ...
    `,
    },
    join: ``,
    json: {
      warning: 'The filter json result is different from shopify. Pay attention to the test',
      example: `
        "json" message: %% message %%
        - Input:
          {% assign arr = "foo bar coo" | split: " " %}
          {{ arr | json }}
        - Output:
          ["foo","bar","coo"]
      `,
    },
    last: ``,
    link_to_add_tag: {
      params: `"link_to_add_tag" need at least 1 parameter in the format string -> %% error_signal %%`,
      value: `"link_to_add_tag": Value must be string -> %% error_signal %%`,
      params_invalid: `"link_to_add_tag": Missing attributeName or attributeValue -> %% error_signal %%`,
      example: `
      "link_to_add_tag" message: %% message %%
      Case 1
        - Input:
          <!-- collection.tags = ["Mens", "Womens", "Sale"] -->
          {% for tag in collection.tags %}
            {{ tag | link_to_add_tag: tag }}
          {% endfor %}
        - Output:
          <!-- If you're on "/collections/frontpage/mens": -->
          <a title="Show products matching tag Mens" href="/collections/frontpage/mens">Mens</a>
          <a title="Show products matching tag Womens" href="/collections/frontpage/womens+mens">Womens</a>
          <a title="Show products matching tag Sale" href="/collections/frontpage/sale+mens">Sale</a>
      `,
    },
    link_to_remove_tag: {
      params: `"link_to_remove_tag" need at least 1 parameter in the format string -> %% error_signal %%`,
      params_invalid: `"link_to_remove_tag": Missing attributeName or attributeValue -> %% error_signal %%`,
      value: `"link_to_remove_tag": Value must be string -> %% error_signal %%`,
      example: `
      "link_to_remove_tag" message: %% message %%
      Case 1
        - Input:
          <!-- collection.tags = ["Mens", "Womens", "Sale"] -->
          {% for tag in collection.tags %}
            {{ tag | link_to_remove_tag: tag }}
          {% endfor %}
        - Output:
          <!-- If you're on "/collections/frontpage/mens": -->
          <a title="Remove tag Mens" href="/collections/frontpage">Mens</a>
      `,
    },
    link_to_tag: {
      params: `"link_to_tag" need at least 1 parameter in the format string -> %% error_signal %%`,
      value: `"link_to_tag": Value must be string -> %% error_signal %%`,
      params_invalid: `"link_to_tag": Missing attributeName or attributeValue -> %% error_signal %%`,
      example: `
      "link_to_tag" message: %% message %%
      Case 1
        - Input:
          <!-- collection.tags = ["Mens", "Womens", "Sale"] -->
          {% for tag in collection.tags %}
            {{ tag | link_to_tag: tag }}
          {% endfor %}
        - Output:
          <a title="Show products matching tag Mens" href="/collections/frontpage/mens">Mens</a>
          <a title="Show products matching tag Womens" href="/collections/frontpage/womens">Womens</a>
          <a title="Show products matching tag Sale" href="/collections/frontpage/sale">Sale</a>
      `,
    },
    link_to_type: {
      params: `"link_to_type": link_to_type params wrong format -> %% error_signal %%`,
      value: `"link_to_type": Value must be string -> %% error_signal %%`,
      params_invalid: `"link_to_type": Missing attributeName or attributeValue -> %% error_signal %%`,
      example: `
      "link_to_type" message: %% message %%
      Case 1
        - Input: {{ "jeans" | link_to_type }}
        - Output: <a href="/collections/types?q=jeans" title="jeans">jeans</a>
      Case 2
        - Input: {{ 'jeans' | link_to_type: class: "link-class" }}
        - Output: <a href="/collections/types?q=jeans" class="link-class" title="jeans">jeans</a>
      `,
    },
    link_to_vendor: {
      params: `"link_to_vendor": link_to_vendor params wrong format -> %% error_signal %%`,
      value: `"link_to_vendor": Value must be string -> %% error_signal %%`,
      params_invalid: `"link_to_vendor": Missing attributeName or attributeValue -> %% error_signal %%`,
      example: `
      "link_to_vendor" message: %% message %%
      Case 1
        - Input: {{ 'Shopify' | link_to_vendor }}
        - Output: <a href="/collections/vendors?q=Shopify" title="Shopify">Shopify</a>
      Case 2
        - Input: {{ 'Shopify' | link_to_vendor: class: "link-class" }}
        - Output: <a href="/collections/vendors?q=Shopify" class="link-class" title="Shopify">Shopify</a>
      `,
    },
    link_to: {
      params: `"link_to" need at least 1 parameter in the format string -> %% error_signal %%`,
      value: `"link_to": Value must be string -> %% error_signal %%`,
      params_invalid: `"link_to": Missing attributeName or attributeValue -> %% error_signal %%`,
      example: `
      "link_to" message: %% message %%
      Case 1
        - Input: {{ 'Shopify' | link_to: 'https://www.shopify.com', title: "A link to Shopify", class: "link-class" }}
        - Output: <a title="A link to Shopify" class="link-class" href="https://www.shopify.com">Shopify</a>
      Case 2
        - Input: {{ 'Shopify' | link_to: 'https://www.shopify.com' }}
        - Output: <a href="https://www.shopify.com">Shopify</a>
      `,
    },
    lstrip: {
      value: `"lstrip": Value must be string -> %% error_signal %%`,
      example: `
      "lstrip" message: %% message %%
      Case 1:
        - Input: {{ '   too many spaces           ' | lstrip }}
        - Ouput: too many spaces
    `,
    },
    map: {
      params: `"map" need 1 parameter in the format of string -> %% error_signal %%`,
      property: `"map": Property is required -> %% error_signal %%`,
      value: `"map": Value must be array -> %% error_signal %%`,
      property_non_exist: `"map": Property does not exist -> %% error_signal %%`,
      example: `
      "map" message: %% message %%
      Case 1:
        - Input:
          {% assign all_categories = site.pages | map: "category" %}
          {% for item in all_categories %}
          + {{ item }}
          {% endfor %}
        - Ouput:
          + business
          + celebrities
          + lifestyle
          + sports
          + technology
    `,
    },
    md5: `md5 not supported yet`,
    media_tag: `media_tag not supported yet`,
    metafield_tag: `metafield_tag not supported yet`,
    metafield_text: `metafield_text not supported yet`,
    minus: {
      params: `"minus" need 1 parameter in the format number -> %% error_signal %%`,
      value: `"minus": subtrahend must be number -> %% error_signal %%`,
      minus_number: `"minus": minus_number must be number -> %% error_signal %%`,
      example: `
      "minus" message: %% message %%
      Case 1:
        - Input: {{ 100 | minus: 10}}
        - Ouput: 90
    `,
    },
    model_viewer_tag: `model_viewer_tag not supported yet`,
    modulo: {
      params: `"modulo" need 1 parameter in the format number -> %% error_signal %%`,
      value: `"modulo": dividend must be number -> %% error_signal %%`,
      modulo_number: `"modulo": modulo_number must be number -> %% error_signal %%`,
      example: `
      "modulo" message: %% message %%
      Case 1:
        - Input: {{ 100 | modulo: 10}}
        - Ouput: 0
    `,
    },
    money: {
      value: `"money / money_with_currency / money_without_trailing_zeros / money_without_currency": Value must be number -> %% error_signal %%`,
      example_money: `
      "money" message: %% message %%
      Case 1:
        - Input: {{ 145 | money }}
        - Ouput: $1.45
    `,
      example_money_with_currency: `
      "money_with_currency" message: %% message %%
      Case 1:
        - Input: {{ 145 | money_with_currency }}
        - Ouput: $1.45 CAD
    `,
      example_money_without_trailing_zeros: `
      "money_without_trailing_zeros" message: %% message %%
      Case 1:
        - Input:
          {{ 2000 | money_without_trailing_zeros }}
          {{ 145 | money_without_trailing_zeros }}
        - Ouput:
          $20
          $1.45
    `,
      money_without_currency: `
      "money_without_currency" message: %% message %%
      Case 1:
        - Input: {{ 145 | money_without_currency }}
        - Ouput: 1.45
    `,
    },
    newline_to_br: {
      value: `"newline_to_br": Value must be string -> %% error_signal %%`,
      example: `
      "newline_to_br" message: %% message %%
      Case 1:
        - Input:
          {% capture var %}
          One
          Two
          Three
          {% endcapture %}
          {{ var | newline_to_br }}
        - Ouput:
          One<br>
          Two<br>
          Three<br>
    `,
    },
    payment_type_img_url: {
      value: `"payment_type_img_url":Value must be string -> %% error_signal %%`,
      domain_value: `"payment_type_img_url": Value must be %% domain_value %% -> %% error_signal %%`,
    },
    payment_type_svg_tag: {
      params: `"payment_type_svg_tag" need 1 parameter in the format of string -> %% error_signal %%`,
      value: `"payment_type_svg_tag":Value must be string -> %% error_signal %%`,
      domain_value: `"payment_type_svg_tag": Value must be %% domain_value %% -> %% error_signal %%`,
      params_invalid: `"payment_type_svg_tag": Missing attributeName or attributeValue -> %% error_signal %%`,
      example: `
      "payment_type_svg_tag" message: %% message %%
      Case 1
        - Input:
          {% for type in shop.enabled_payment_types %}
            {{ type | payment_type_svg_tag: class: 'custom-class' }}
          {% endfor %}
        - Output:
          <svg class="custom-class" xmlns="http://www.w3.org/2000/svg">
            <circle fill="#EB001B" cx="15" cy="12" r="7"></circle>
            <circle fill="#F79E1B" cx="23" cy="12" r="7"></circle>
            ...
          </svg>
          <svg class="custom-class" xmlns="http://www.w3.org/2000/svg">
            <path fill="#494949" d="M9 11h20v2H9z"></path>
            ...
          </svg>
    `,
    },
    placeholder_svg_tag: {
      value: `"placeholder_svg_tag": Value must be %% domain_value %% -> %% error_signal %%`,
      params_invalid: `"placeholder_svg_tag": Tham số wrong format -> %% error_signal %%`,
      example: `
        "placeholder_svg_tag" message: %% error_signal %%
        - Input: {{ 'collection-1' | placeholder_svg_tag }}
        - Output: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525.5 525.5">...omitted for brevity...</svg>
        - Input: {{ 'collection-1' | placeholder_svg_tag: 'custom' }}
        - Output: <svg class="custom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525.5 525.5">...omitted for brevity...</svg>
      `,
    },
    pluralize: {
      params: `"pluralize" need 2 parameters in the format of string -> %% error_signal %%`,
      value: `"pluralize": Value must be number -> %% error_signal %%`,
      singular: `"pluralize": singular is required -> %% error_signal %%`,
      plural: `"pluralize": plural is required -> %% error_signal %%`,
      example: `
      "pluralize" message: %% message %%
      Case 1:
        - Input:
          {{ cart.item_count }}
          {{ cart.item_count | pluralize: 'item', 'items' }}
        - Ouput: 3 items
    `,
    },
    plus: {
      params: `"plus" need 1 parameter in the format number -> %% error_signal %%`,
      value: `"plus": number1 must be number -> %% error_signal %%`,
      number2: `"plus": number2 must be number -> %% error_signal %%`,
      example: `
      "plus" message: %% message %%
      Case 1:
        - Input: {{ 100 | plus: 10}}
        - Ouput: 110
    `,
    },
    preload_tag: `preload_tag not supported yet`,
    prepend: {
      params: `"prepend" need 1 parameter in the format of string -> %% error_signal %%`,
      value: `"prepend": Value must be string -> %% error_signal %%`,
      example: `
      "prepend" message: %% message %%
      Case 1:
        - Input:
          {{ 'World' | prepend: 'Hello' }}
          {% assign str = "apples, oranges, and bananas" | prepend: prependStr | truncate: 10 %}
          {{ "apples, oranges, and bananas" | truncate: 10 | prepend: prependStr }}
        - Ouput:
          Hello World.
    `,
    },
    remove_first: {
      params: `"remove_first" need 1 parameter in the format of string -> %% error_signal %%`,
      value: `"remove_first": Value must be string -> %% error_signal %%`,
      example: `
      "remove_first" message: %% message %%
      Case 1:
        - Input: {{ 'Hello, world. Goodbye, world.' | remove_first: 'world' }}
        - Ouput: Hello, . Goodbye, world.
    `,
    },
    remove: ``,
    replace_first: {
      params: `"remove_first" need 2 parameters in the format of string -> %% error_signal %%`,
      value: `"remove_first": Value must be string -> %% error_signal %%`,
      example: `
      "remove_first" message: %% message %%
      Case 1:
        - Input:
          <!-- product.title = "Awesome Awesome Shoes" -->
          {{ product.title | replace_first: 'Awesome', 'Mega' }}
        - Ouput: Mega Awesome Shoes
    `,
    },
    replace: {
      params: `"remove" need 2 parameters in the format of string -> %% error_signal %%`,
      value: `"remove": Value must be string -> %% error_signal %%`,
      example: `
      "remove" message: %% message %%
      Case 1:
        - Input:
          <!-- product.title = "Awesome Awesome Shoes" -->
          {{ product.title | replace: 'Awesome', 'Mega' }}
        - Ouput: Mega Mega Shoes
    `,
    },
    reverse: ``,
    round: ``,
    rstrip: {
      value: `"rstrip": Value must be string -> %% error_signal %%`,
      example: `
      "rstrip" message: %% message %%
      Case 1:
        - Input: {{ '              too many spaces      ' | rstrip }}
        - Ouput: too many spaces
    `,
    },
    script_tag: `script_tag not supported yet`,
    sha1: `sha1 not supported yet`,
    sha256: `sha256 not supported yet`,
    shopify_asset_url: `shopify_asset_url not supported yet`,
    size: ``,
    slice: {
      params: `"slice' need at least 1 parameter in the format string -> %% error_signal %%`,
      value: `"slice": Value must be string hoặc mảng primitive -> %% error_signal %%`,
      from: `"slice": "from" must be number -> %% error_signal %%`,
      to: `"slice": "to" must be number -> %% error_signal %%`,
      example: `
      "slice" message: %% message %%
      Case 1:
        - Input: {{ "Liquid" | slice: 0 }}
        - Ouput: L
      Case 2:
        - Input: {{ "Liquid" | slice: 2, 5 }}
        - Ouput: quid
      Case 3:
        - Input:
          {% assign beatles = "John, Paul, George, Ringo" | split: ", " %}
          {{ beatles | slice: 1, 2 }}
        - Ouput: PaulGeorge
    `,
    },
    sort_by: {
      params: `"sort_by" need 1 parameter in the format of string -> %% error_signal %%`,
      value: `"sort_by": Value must be string -> %% error_signal %%`,
      example: `
      "sort_by" message: %% message %%
      Case 1:
        - Input: {{ collection.url | sort_by: 'best-selling' }}
        - Ouput: /collections/widgets?sort_by=best-selling
    `,
    },
    sort_natural: {
      value: `"sort_natural": Value must be 1 array of primitive or 1 array of objects -> %% error_signal %%`,
      example: `
      "sort_natural" message: %% message %%
      Case 1:
        - Input:
        {% assign my_array = "zebra, octopus, giraffe, Sally Snake" | split: ", " %}
        {{ my_array | sort_natural | join: ", " }}
        - Ouput: giraffe, octopus, Sally Snake, zebra
      Case 2:
      {% assign products_by_company = collection.products | sort_natural: "company" %}
      {% for product in products_by_company %}
        <h4>{{ product.title }}</h4>
      {% endfor %}
    `,
    },
    sort: {
      value: `"sort": Value must be array -> %% error_signal %%`,
      property_non_exist: `"sort": Property does not exist -> %% error_signal %%`,
      example: `
      "sort" message: %% message %%
      Case 1:
        - Input:
          {% assign my_array = "zebra, octopus, giraffe, Sally Snake" | split: ", " %}
          {{ my_array | sort | join: ", " }}

        - Ouput: Sally Snake, giraffe, octopus, zebra
    `,
    },
    split: ``,
    strip_html: ``,
    strip_newlines: {
      value: `"strip_newlines": Value must be string -> %% error_signal %%`,
      example: `
      "strip_newlines" message: %% message %%
      Case 1:
        - Input:
          {% capture string_with_newlines %}
          Hello
          there
          {% endcapture %}
          {{ string_with_newlines | strip_newlines }}
        - Ouput: Hellothere
    `,
    },
    strip: ``,
    stylesheet_tag: `stylesheet_tag not supported yet`,
    t: {
      format_invalid: `"t": Sai format -> %% error_signal %%`,
      example: `
      "t" message: %% message %%
      Case 1:
        - Input:
          Liquid: <span>{{ 'products.product.sold_out' | t }}</span>
          JSON:
            en.json: {
              "products": {
                "product": {
                    "sold_out": "Sold out"
                }
              }
            }
            fr.json: {
              "products": {
                "product": {
                  "sold_out": "Épuisé"
                }
              }
            }
        - Ouput:
            en: <span>Sold out</span>
            fr: <span>Épuisé</span>
    `,
    },
    time_tag: {
      params: `"time_tag": need at least 1 parameter in the format string -> %% error_signal %%`,
      custom_format_not_exist: "Liquid error: The format option '%% error_signal %%' is not a supported format.",
      example: `
        "time_tag" message: %% message %%
        Example:
          {{ article.created_at | time_tag: '%B %d, %Y' }}
          {{ article.created_at | time_tag: format: 'abbreviated_date' }}
          {{ article.created_at | time_tag: '%B %d, %Y', datetime: '%Y-%m-%d' }}
          {{ article.created_at | time_tag: format: 'month_day_year' }}
      `,
    },
    times: {
      params: `"times": need 1 parameter in the format number -> %% error_signal %%`,
      value: `"times": factor1 must be number -> %% error_signal %%`,
      factor: `"times": factor2 must be number -> %% error_signal %%`,
      example: `
      "times" message: %% message %%
      Case 1:
        - Input: {{ 100 | times: 10}}
        - Ouput: 1000
    `,
    },
    truncate: {
      params: `"truncate" The parameter is in the format of string -> %% error_signal %%`,
      value: `"truncate": Value must be string -> %% error_signal %%`,
      example: `
      "truncatewords" message: %% message %%
      Case 1:
        - Input: {{ "Ground control to Major Tom." | truncate: 20 }}
        - Output: Ground control to...

      Case 2:
        - Input: {{ "Ground control to Major Tom." | truncate: 25, ", and so on" }}
        - Output: Ground control, and so on
      `,
    },
    truncatewords: {
      params: `"truncatewords" need at least 1 parameter in the format of number and string -> %% error_signal %%`,
      value: `"truncatewords": Value must be string -> %% error_signal %%`,
      quantity_words: `"truncatewords": quanlityWords must be number -> %% error_signal %%`,
      example: `
      "truncatewords" message: %% message %%
      Case 1:
        - Input: {{ "Ground control to Major Tom." | truncatewords: 3 }}
        - Ouput: Ground control to...
      Case 2:
        - Input: {{ "Ground control to Major Tom." | truncatewords: 3, "--" }}
        - Ouput: Ground control to--
    `,
    },
    uniq: {
      value: `"uniq": Value must be primitive array -> %% error_signal %%`,
      example: `
      "uniq" message: %% message %%
      Case 1:
        - Input:
          {% assign my_array = "ants, bugs, bees, bugs, ants" | split: ", " %}
          {{ my_array | uniq | join: ", " }}
        - Ouput: ants, bugs, bees
    `,
    },
    upcase: ``,
    url_decode: {
      value: `"url_decode": Value must be string -> %% error_signal %%`,
      example: `
      "url_decode" message: %% message %%
      Case 1:
        - Input:
        {{ "%27Stop%21%27+said+Fred" | url_decode }}
        - Ouput: 'Stop!' said Fred
    `,
    },
    url_encode: ``,
    url_escape: {
      value: `"url_escape": Value must be string -> %% error_signal %%`,
      example: `
      "url_escape" message: %% message %%
      Case 1:
        - Input:
          {{ '<hello> & <shopify>' | url_escape }}
        - Ouput: <hello> %26 <shopify>
    `,
    },
    url_for_type: {
      value: `"url_for_type": Value must be string -> %% error_signal %%`,
      example: `
       "url_for_type" message: %% message %%
       Case 1:
         - Input:
          {{ "T-shirt" | url_for_type }}
         - Ouput:
          /collections/types?q=T-shirt
     `,
    },
    url_for_vendor: {
      value: `"url_for_vendor": Value must be string -> %% error_signal %%`,
      example: `
        "url_for_vendor" message: %% message %%
        Case 1:
          - Input:
           {{ "Shopify" | url_for_vendor }}
          - Ouput:
           /collections/vendors?q=Shopify
      `,
    },
    url_param_escape: ``,
    video_tag: `video_tag not supported yet`,
    weight_with_unit: {
      example: `
      "weight_with_unit" message: %% message %%
      Case 1:
        - Input: {{ product.variants.first.weight | weight_with_unit }}
        - Ouput: 24.0 kg
      Case 2:
        - Input: {{ variant.weight | weight_with_unit: variant.weight_unit }}
        - Ouput: 52.9 lb
    `,
    },
    where: {
      params: `"where" need at least 1 parameter in the format string -> %% error_signal %%`,
      value: `"where": Value must be array -> %% error_signal %%`,
      property: `"where": Property is required -> %% error_signal %%`,
      property_non_exist: `"where": Property does not exist -> %% error_signal %%`,
      example: `
      "where" message: %% message %%
      Case 1:
        - Input:
          {% for product in products %}
          - {{ product.title }}
          {% endfor %}
          {% assign kitchen_products = products | where: "type", "kitchen" %}
          Kitchen products:
          {% for product in kitchen_products %}
          - {{ product.title }}
          {% endfor %}
        - Ouput:
          All products:
          - Vacuum
          - Spatula
          - Television
          - Garlic press

          Kitchen products:
          - Spatula
          - Garlic press
    `,
    },
    within: {
      params: `"within" need 1 parameter in the format of object collection -> %% error_signal %%`,
      value: `"within": Value must be string -> %% error_signal %%`,
      example: `
      "within" message: %% message %%
        - Input: <a href="{{ product.url | within: collection }}">{{ product.title }}</a>
        - Ouput: <a href="/collections/frontpage/products/alien-poster">Alien Poster</a>
    `,
    },
  },
  global_object: {
    powered_by_link: ``,
  },
  iteration: {
    break: `break not supported yet. You can use "if" to solve this case`,
    continue: `continue not supported yet. You can use "if" to solve this case`,
    cycle: `Tag 'cycle' not supported yet. You can use 'if else' in combination with filter 'slice' to solve this case`,
    limit: `
      "Limit" Something wrong! %%error_signal%%
      Input:
      {% for item in array limit: 2 %}
       {{ item }}
      {% endfor %}

      {% for item in array limit: variableName %}
       {{ item }}
      {% endfor %}

      {% for item in array limit: variableObject.key %}
       {{ item }}
      {% endfor %}
      Output:
      {% for item in array | slice(0, 2) %}
        {{ item }}
      {% endfor %}

      {% for item in array | slice(0, variableName) %}
       {{ item }}
      {% endfor %}

      {% for item in array | slice(0, variableObject.key) %}
       {{ item }}
      {% endfor %}
    `,
    offset: `
      "Offset": Something wrong! %%error_signal%%
      Input:
        {% for item in array offset: 2 %}
          {{ item }}
        {% endfor %}

        {% for item in array offset: variableName %}
          {{ item }}
        {% endfor %}

        {% for item in array offset: variableObject.key %}
          {{ item }}
        {% endfor %}
        Output:
        {% for item in array | slice(2, 99999999) %}
          {{ item }}
        {% endfor %}

        {% for item in array | slice(variableName, 99999999) %}
          {{ item }}
        {% endfor %}

        {% for item in array | slice(variableObject.key, 99999999) %}
          {{ item }}
        {% endfor %}
    `,
    reversed: ``,
    tablerow: `Tag 'tablerow' not supported yet. You can use regular for loop in this case as an alternative`,
  },
  section_schema: "Tag 'schema' not supported yet",
  theme_tags: {
    forms: {
      unnestable: "Tag 'form' nested is not supported yet. Because it's simply not needed",
      activate_customer_password: `"activate_customer_password": Something wrong -> %% error_signal %%`,
      reset_customer_password: `"reset_customer_password": Something wrong -> %% error_signal %%`,
      contact: `"contact": Something wrong -> %% error_signal %%`,
      create_customer: `"create_customer": Something wrong -> %% error_signal %%`,
      customer_login: `"customer_login": Something wrong -> %% error_signal %%`,
      customer: `"customer": Something wrong -> %% error_signal %%`,
      guest_login: `"guest_login": Something wrong -> %% error_signal %%`,
      new_comment: `"new_comment": Something wrong -> %% error_signal %%`,
      product: `"product": Something wrong -> %% error_signal %%`,
      recover_customer_password: `"recover_customer_password": Something wrong -> %% error_signal %%`,
      storefront_password: `"storefront_password": Something wrong -> %% error_signal %%`,
      localization: `"localization": Something wrong -> %% error_signal %%`,
    },
    comment: {
      example: `
      "example": Something wrong -> %% error_signal %%
      Correct Syntax:
      {% comment %}
        content
      {% endcomment %}
      `,
    },
    layout: `Tag 'layout' not supported yet`,
    liquid: {
      example: `
      "liquid": Something wrong -> %% error_signal %%
      Correct Syntax:
      {% liquid
        assign product_type = product.type | downcase
        assign message = ''

        case product_type
          when 'health'
            assign message = 'This is a health potion!'
          when 'love'
            assign message = 'This is a love potion!'
          else
            assign message = 'This is a potion!'
        endcase

        echo message
      %}
      `,
    },
    paginate: {
      example: `
        "paginate": Something wrong -> %% error_signal %%
        Correct Syntax:
          {% paginate collection.products by 2 %}
            {% for product in collection.products %}
              {{ product.title }} product card
            {% endfor %}
          {% endpaginate %}
      `,
    },
    render: {
      not_exist: '%% snippet_name %% not exist -> %% error_signal %%',
      example: `
        "render": Something wrong -> %% error_signal %%
        {% render 'fileName', variable1: value, variable2: value %}
      `,
    },
    section: `Tag 'section' not supported yet`,
    style: ``,
  },
  variable_tags: {
    capture: ``,
    decrement: ``,
    increment: ``,
  },
  exception_of_code_liquid: {
    variable: `%% error_signal %%: 2.2: Name the same as the variable name, tags, filters của shopify`,
    variable_outside_shopify_tag: `%% error_signal %%: Need to wrap the code in <shopify></shopify> tag pair to be able to use Shopify data`,
    filter: `%% error_signal %%: 2.3: Filter and tags must be in the "shopify" tag to be automatically converted to shop`,
    filter_in_if_tag: `%% error_signal %%: 2.15: Using filter in "if" tag is not possible`,
    field_value: `%% error_signal %%: Liquid has no data types object and array`,
    nested_form: `%% error_signal %%: "{% form %}...{% endform %} does not support nesting yet`,
    nested_paginate: `%% error_signal %%: {% paginate %}...{% endpaginate %} does not support nesting yet`,
    nested_liquid_tag: `%% error_signal %%: {% liquid ... %} does not support nesting yet`,
  },
  clause_in_shopify: {
    navigation: `You cannot use "navigation" in shopify`,
    products: `You cannot use "products" in the shopify tag`,
    array: `2.1: You cannot use arrays in shopify`,
    translate_field: 'You cannot use the translation in array',
    reassign_value_in_liquid_tag: `The variable "%% variable_name %%" - the variable being used in the forloop - cannot be reassigned in {% liquid ... %}. You can use {% assign %% variable_name %% = ... %} as an alternative solution`,
  },
  error_notification_title: `Section %% section_name %% has an error:`,
  error_liquid_syntax_to_twig: `
    <div style="font-size: 1.5em; font-family: Consolas, Menlo, monospace; color: black; margin-bottom: 2rem; display: flex; align-items: center">
      <span>Try to click </span>
      <div style="width: 36px; height: 36px; background: #FFFFFF; border-radius: 4px; border: 1px solid #DEDEE9; display: flex; justify-content: center; align-items: center; margin: 0px 6px">
        <i style="font-size: 16px" class="far fa-sync-alt"></i>
      </div>
      <span>on the top bar to fix the error.</span>
    </div>
    <div style="font-size: 1.5em; font-family: Consolas, Menlo, monospace; color: black; margin-bottom: 2rem; display: flex; align-items: center">
      <span>If it didn't work, please click </span>
      <div style="width: 36px; height: 36px; background: #2AB885; color: #FFFFFF; border-radius: 4px; border: 1px solid #2AB885; display: flex; justify-content: center; align-items: center; margin: 0px 6px">
        <i style="font-size: 16px" class="far fa-comments"></i>
      </div>
      <span>on the top bar to get online support</span>
    </div>
  `,
} as const;
