import { watchGetImportAddonVersion } from 'store/sagas/versions/watchGetImportAddonVersion';
import { watchGetImportSectionVersion } from 'store/sagas/versions/watchGetImportSectionVersion';
import { watchUpdateSectionsInPage } from 'store/sagas/versions/watchUpdateSectionsInPage';
import { watchUpdateAddonVersion, watchUpdateMegaMenuVersion, watchUpdateSectionVersion } from './watchUpdateSectionVersion';
import { watchUpdateAddonsInPage } from 'store/sagas/versions/watchUpdateAddonsInPage';

export const sagasVersion = [
  watchUpdateSectionVersion,
  watchUpdateAddonVersion,
  watchUpdateMegaMenuVersion,
  watchUpdateSectionsInPage,
  watchGetImportSectionVersion,
  watchGetImportAddonVersion,
  watchUpdateAddonsInPage,
];
