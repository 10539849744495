import { SetVedaInstructionAction } from 'store/global/vedaInstruction/action';
import { VedaInstruction } from 'types/Instruction';
import { createSlice, handleAction } from 'wiloke-react-core/utils';

interface State {
  instructions: VedaInstruction[];
}

export const instructionSlice = createSlice<State, SetVedaInstructionAction>({
  name: '@Global',
  initialState: {
    instructions: [],
  },
  reducers: [
    handleAction('setVedaInstructionAction', ({ state, action }) => {
      state.instructions = action.payload.vedaInstruction;
    }),
  ],
});
