import { watchCreateBlogPage } from './watchCreateBlogPage';
import { watchUpdateStatusBlogPage } from './watchUpdateStatusBlogPage';
import { watchDeleteBlogPages } from './watchDeleteBlogPages';
import { watchGetBlogPages } from './watchGetBlogPages';
import { watchLoadMoreBlogPages } from './watchLoadMore';
import { watchDuplicateBlogPage } from 'containers/Admin/PageBuilder/BlogPage/store/sagas/watchDuplicateBlogPage';

export const sagaBlogPage = [
  watchCreateBlogPage,
  watchUpdateStatusBlogPage,
  watchDeleteBlogPages,
  watchGetBlogPages,
  watchLoadMoreBlogPages,
  watchDuplicateBlogPage,
];
