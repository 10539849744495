import { createAsyncAction, createDispatchAsyncAction } from 'wiloke-react-core/utils';

type Value = 'yes' | 'no';

export const actionShopDevAllowable = createAsyncAction([
  '@SettingManager/actionShopDevAllowable/request',
  '@SettingManager/actionShopDevAllowable/success',
  '@SettingManager/actionShopDevAllowable/failure',
])<{ shopDevAllowable: Value }, { shopDevAllowable: Value }, undefined>();

export const actionGetOptionsSettings = createAsyncAction([
  '@SettingManager/actionGetOptionsSettings/request',
  '@SettingManager/actionGetOptionsSettings/success',
  '@SettingManager/actionGetOptionsSettings/failure',
])<undefined, { shopDevAllowable: Value }, undefined>();

export const useActionShopDevAllowable = createDispatchAsyncAction(actionShopDevAllowable);
export const useActionGetOptionsSettings = createDispatchAsyncAction(actionGetOptionsSettings);
