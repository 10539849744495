import { delay } from '@wiloke/functions';
import { Button, RenderItemParam2, ScrollBars, Sortable, SortableProps, Tooltip, useStackNavigator } from '@wiloke/ui';
import DragItem from 'components/DragItem';
import { AdvancedIcon } from 'components/PlanBadge';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';
import { LeftBarParamList } from 'containers/BuilderPage/components/SidebarScreen/SidebarScreen';
import UpdateVersion from 'containers/BuilderPage/components/UpdateVersion/UpdateVersion';
import { useSetTemplateBoardVisible } from 'containers/ChooseTemplate/store/actions/actionTemplateBoardVisible';
import setScrollTo from 'containers/IframePage/setScrollTo';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSortableSection } from 'store/actions/actionPages';
import { useSetSectionIdActive } from 'store/actions/actionSectionIdActive';
import { useUpdateSectionsInPage } from 'store/actions/versions/actionSectionVersion';
import { useSetIsDragging } from 'store/global/isDragging/slice';
import { sectionIdHoverSelector, useSetSectionIdHover } from 'store/global/sectionIdHover/slice';
import { authSelector, isDraggingSelector, pageSectionsSelector, pagesSelector } from 'store/selectors';
import { i18n } from 'translation';
import { PageSection, PageSectionType, ProductSection } from 'types/Sections';
import { PLAN_STATIC_DATA, VEDA_FUNCTION_ENUM } from 'utils/constants/PlanEnum';
import { PAGE_BUILD_HEADER_FOOTER } from 'utils/constants/constants';
import { isFooter, isHeader, isSectionAddons, isSectionAddonsOrMegamenu, isSectionMegamenu } from 'utils/functions/checkSectionType';
import getPageInfo from 'utils/functions/getInfo';
import { pmParent } from 'utils/functions/postMessage';
import { validatePlan } from 'utils/functions/validatePlan';
import { ActivityIndicator, FontAwesome, Space, Text, View, useTheme } from 'wiloke-react-core';
import SectionDropDown from '../../components/SectionDropDown/SectionDropDown';
import SectionShowHide from '../../components/SectionShowHide/SectionShowHide';
import { ButtonAddThemeSection } from './ButtonAddThemeSection';
import { ClearAllButton } from './ClearAllButton';
import * as styles from './styles';
// import { useSavePage } from 'containers/BuilderPage/components/TopBar/RightActions/useSavePage';
import { useSetModalSavePageBeforeRedirectHeaderFooterVisible } from 'containers/BuilderPage/store/saveForBuilder/slice';

type ContentType = 'header' | 'main' | 'footer';

const MIN_ORDER = 1000;

interface Static {
  setErrorId: null | Dispatch<SetStateAction<string | undefined>>;
}

let handler: Static['setErrorId'] = null;

const LeftBarSections: FC & Static = () => {
  const pageSections = useSelector(pageSectionsSelector);
  const { updateAllVersionSectionsStatus: updateAllVersionStatus } = useSelector(pagesSelector);

  const navigation = useStackNavigator<LeftBarParamList>();
  const main = pageSections.filter(section => !isSectionAddonsOrMegamenu(section.type) && !isHeader(section.type) && !isFooter(section.type));
  const header = pageSections.filter(section => isHeader(section.type));
  const footer = pageSections.filter(section => isFooter(section.type));
  const sectionIdHover = useSelector(sectionIdHoverSelector);
  const isDragging = useSelector(isDraggingSelector);
  const iframeEl = document.querySelector<HTMLIFrameElement>('#iframe-content');
  const [errorId, setErrorId] = useState<string | undefined>(undefined);
  const [sameDestination, setSameDestination] = useState(true);
  const [status, setStatus] = useState<'idle' | 'start' | 'update' | 'end'>('idle');
  const entityVariant = getPageInfo('entityVariant');
  const pageId = getPageInfo('id');

  const { plan, role } = useSelector(authSelector);
  const _navs = role === 'admin' ? ['admin', 'Sections'] : ['sections'];
  // const { handleSaveAsDraft, isSyncingForSave } = useSavePage();
  const { colors } = useTheme();

  const disabledHeaderFooter =
    validatePlan({
      userPlan: plan.planHandle,
      itemPlan: VEDA_FUNCTION_ENUM.header_footer,
      role,
    }) === 'invalid';

  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();
  const updateSectionsInPage = useUpdateSectionsInPage();
  const setSectionIdHover = useSetSectionIdHover();
  const sortableSection = useSortableSection();
  const setSectionIdActive = useSetSectionIdActive();
  const setIsDragging = useSetIsDragging();
  const setTemplateBoardVisible = useSetTemplateBoardVisible();
  const setVisible = useSetModalSavePageBeforeRedirectHeaderFooterVisible();

  const setSectionOrder = (type: 'set' | 'remove') => {
    pageSections.forEach((section, index) => {
      if (!(isSectionAddons(section.type) && isSectionMegamenu(section.type))) {
        const sectionEl = iframeEl?.contentDocument?.querySelector?.<HTMLElement>(`[data-id="${section.id}"]`);
        if (sectionEl) {
          if (type === 'set') {
            sectionEl.style.order = `${index * 2 + MIN_ORDER}`;
          } else {
            sectionEl.style.removeProperty('order');
          }
        }
      }
    });
  };

  useEffect(() => {
    const iframeRootEl = iframeEl?.contentDocument?.querySelector?.<HTMLElement>('#iframe-root');
    if (isDragging) {
      if (iframeRootEl) {
        iframeRootEl.style.display = 'flex';
        iframeRootEl.style.flexDirection = 'column';
      }
      setSectionOrder('set');
    } else {
      if (iframeRootEl) {
        iframeRootEl.style.removeProperty('display');
        iframeRootEl.style.removeProperty('flex-direction');
      }
      setSectionOrder('remove');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  useEffect(() => {
    handler = setErrorId;
    return () => {
      handler = null;
    };
  }, []);

  const handleChooseTemplateVisible = () => {
    const index = header.length + main.length;
    setTemplateBoardVisible({ visible: true, index, isChange: false, navKeys: _navs });
  };

  const handleAddSectionInTheme = (type: Extract<PageSectionType, 'header' | 'default' | 'footer'>) => {
    const indexMain = header.length + main.length;
    const indexHeader = header.length ? header.length : 0;
    const indexFooter = footer.length ? footer.length + indexMain + 1 : indexMain + 1;

    const index = type === 'header' ? indexHeader : type === 'footer' ? indexFooter : indexMain;

    setTemplateBoardVisible({
      visible: true,
      index,
      isChange: false,
      navKeys: _navs,
      sectionType: type,
    });
  };

  const getIndex = (type: ContentType, index: number) => {
    let result = index;
    if (type === 'main') {
      result = result + header.length;
    } else if (type === 'footer') {
      result = result + header.length + main.length;
    }
    return result;
  };

  const getSortableProps = (type: ContentType): Omit<SortableProps<PageSection>, 'renderItem' | 'data'> => ({
    keyExtractor: section => section.id,
    itemCss: { marginBottom: '2px' },
    onDragStart: result => {
      const sectionId = result.draggableId;
      setScrollTo(`[data-id="${sectionId}"]`);
      setIsDragging(true);
      setSectionIdHover(sectionId);
      setSameDestination(false);
      setStatus('start');
    },
    onDragUpdate: result => {
      if (!result.destination) {
        return;
      }
      const srcIndex = getIndex(type, result.source.index);
      const desIndex = getIndex(type, result.destination.index);
      const sectionId = result.draggableId;

      setSameDestination(srcIndex === desIndex);
      setStatus('update');

      const sectionActiveEl = iframeEl?.contentDocument?.querySelector?.<HTMLElement>(`[data-id="${sectionId}"]`);
      if (sectionActiveEl) {
        if (srcIndex > desIndex) {
          sectionActiveEl.style.order = `${desIndex * 2 - 1 + MIN_ORDER}`;
        } else {
          sectionActiveEl.style.order = `${desIndex * 2 + 1 + MIN_ORDER}`;
        }
      }
      if (!!sectionId) {
        setScrollTo(`[data-id="${sectionId}"]`, { smooth: false });
      }
    },
    onDragEnd: async result => {
      setIsDragging(false);
      const sectionId = result.draggableId;
      if (!result.destination) {
        return;
      }
      setSectionOrder('remove');
      sortableSection(getIndex(type, result.source.index), getIndex(type, result.destination.index));
      setSameDestination(true);
      setStatus('end');
      await delay(300);
      if (!!sectionId) {
        setScrollTo(`[data-id="${sectionId}"]`);
      }
    },
  });

  const handleMouseUp = () => {
    // Fix trường hợp đang kéo nhưng chưa chuyển vị trí hoặc chuyển vị trí khác xong chuyển lại
    // Lúc này onDragEnd chưa hoạt động ta cần set lại isDragging = false để iframe scale về 1
    if (sameDestination || status === 'start') {
      setIsDragging(false);
    }
  };

  const updateAllSections = () => {
    updateSectionsInPage.request({
      prevSections: [...header, ...main, ...footer],
    });
  };

  const handleGoToHeaderFooterPage = () => {
    // await handleSaveAsDraft(() => {});
    setVisible(true);
    // history.push(`/builder?shop=${shopName}&id=${PAGE_BUILD_HEADER_FOOTER}&entityVariant=Client`);
  };

  const renderItem = ({ item: section, dragHandleProps, isDragging }: RenderItemParam2<PageSection>) => {
    const isSyncedError = errorId && (errorId === section.id || errorId === section.commandId);
    return (
      <View
        onMouseEnter={() => {
          setSectionIdHover(section.id);
        }}
        onMouseLeave={() => {
          if (!isDragging) {
            setSectionIdHover('');
          }
        }}
      >
        <DragItem
          longPressDragHandleProps={dragHandleProps}
          label={section.label}
          active={sectionIdHover === section.id || isDragging}
          onEdit={() => {
            navigation.navigate('fieldSettingsScreen');
            setSectionIdActive(section.id);
            pmParent.emit('@section/sectionIdActive', section.id ?? '');
          }}
          RightItem={
            <>
              {isSyncedError && (
                <Tooltip portal text={i18n.t('publish_shopify.section_sync_error')}>
                  <FontAwesome type="fal" name="exclamation-triangle" color="danger" size={16} />
                </Tooltip>
              )}
              <UpdateVersion type="section" item={section as ProductSection} />
              <Tooltip portal text={i18n.t(section.enable ? 'general.hide' : 'general.show')}>
                <SectionShowHide sectionId={section.id} iconName={section.enable ? 'eye' : 'eye-slash'} />
              </Tooltip>
              <SectionDropDown sectionId={section.id} />
            </>
          }
        />
      </View>
    );
  };

  const renderButtonAddSection = (type: 'header' | 'default' | 'footer', disabled = false) => {
    const _type = type === 'header' ? i18n.t('general.header') : type === 'footer' ? i18n.t('general.footer') : i18n.t('general.section');

    return (
      <>
        <Space size={5} />
        <View
          css={styles.buttonAddSection(disabled)}
          onClick={() => {
            if (disabled) {
              setPlanRequesting({
                handle: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].handle,
                type: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].type,
                pricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].pricePerMonth,
                pricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].pricePerYear,
                title: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].title,
                originalPricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].pricePerMonth,
                originalPricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.header_footer].pricePerYear,
              });
              setValidateCouponVisible(true);
            } else {
              handleAddSectionInTheme(type);
            }
          }}
        >
          {disabled ? (
            <View css={styles.iconAdvanced} radius={2} backgroundColor="quaternary">
              <AdvancedIcon />
            </View>
          ) : (
            <View tagName="span">
              <FontAwesome type="fas" name="plus-circle" size={14} />
            </View>
          )}

          <View tagName="h6" color="primary" css={{ fontSize: '14px' }}>
            {i18n.t('general.add', { text: _type })}
          </View>
        </View>
      </>
    );
  };

  return (
    <>
      <ScrollBars css={{ height: 'calc(100% - 130px) !important' }}>
        <View css={styles.content} onMouseUp={handleMouseUp}>
          {entityVariant === 'Client' && (
            <>
              <View css={{ display: 'flex', columnGap: '4px', alignItems: 'center', marginBottom: '5px' }}>
                <Text tagName="h6" size={11} color="gray7" css={{ textTransform: 'uppercase', position: 'relative', marginTop: '5px' }}>
                  Global {i18n.t('general.header')}
                  <Tooltip
                    portal
                    text="Click to learn how this feature works"
                    placement="right"
                    css={{
                      position: 'absolute',
                      top: '-50%',
                      right: '-88px',
                    }}
                  >
                    <View
                      tagName="a"
                      href="https://help.myshopkit.app/en/docs/veda-landing-page-builder/veda-page/header-footer-building/"
                      target="_blank"
                      radius={'pill'}
                      backgroundColor="danger"
                      color="light"
                      colorHover="light"
                      css={styles.buildFaster}
                    >
                      Build Header
                    </View>
                  </Tooltip>
                </Text>
              </View>
              <View css={{ marginBottom: '15px' }}>
                {pageId !== PAGE_BUILD_HEADER_FOOTER ? (
                  <DragItem
                    onEdit={handleGoToHeaderFooterPage}
                    label={`Global ${i18n.t('general.header')}`}
                    description="Click here to navigate to Global Header & Footer to edit"
                    css={{
                      borderColor: colors.gray6,
                      borderStyle: 'dashed',
                      backgroundColor: colors.gray3,
                    }}
                    innerCss={{
                      backgroundColor: colors.gray3,
                    }}
                    Icon={() => <></>}
                  />
                ) : (
                  <Sortable {...getSortableProps('header')} data={header} renderItem={renderItem} containerCss={{ height: '100%' }} />
                )}

                {pageId === PAGE_BUILD_HEADER_FOOTER && renderButtonAddSection('header', disabledHeaderFooter)}
              </View>
            </>
          )}

          <Text tagName="h6" size={11} color="gray7" css={{ marginBottom: '5px', textTransform: 'uppercase' }}>
            {i18n.t('general.main')}
          </Text>
          <View css={{ marginBottom: '20px' }}>
            <Sortable {...getSortableProps('main')} data={main} renderItem={renderItem} containerCss={{ height: '100%' }} />
            {pageId !== PAGE_BUILD_HEADER_FOOTER && renderButtonAddSection('default')}
            {pageId === PAGE_BUILD_HEADER_FOOTER && (
              <DragItem
                label={i18n.t('general.section', { text: i18n.t('general.placeholder') })}
                description="Only visible when building normal pages (Landing, Product, Collection, Blog, Page, ...)"
                css={{
                  borderColor: colors.gray6,
                  borderStyle: 'dashed',
                  backgroundColor: colors.gray3,
                  cursor: 'not-allowed',
                }}
                innerCss={{
                  backgroundColor: colors.gray3,
                }}
                Icon={() => <></>}
              />
            )}
          </View>

          {entityVariant === 'Client' && (
            <>
              <View css={{ display: 'flex', columnGap: '4px', alignItems: 'center', marginBottom: '5px' }}>
                <Text tagName="h6" size={11} color="gray7" css={{ textTransform: 'uppercase', position: 'relative' }}>
                  Global {i18n.t('general.footer')}
                  <Tooltip
                    portal
                    text="Click to learn how this feature works"
                    placement="right"
                    css={{
                      position: 'absolute',
                      top: '-50%',
                      right: '-88px',
                    }}
                  >
                    <View
                      tagName="a"
                      href="https://help.myshopkit.app/en/docs/veda-landing-page-builder/veda-page/header-footer-building/"
                      target="_blank"
                      radius={'pill'}
                      backgroundColor="danger"
                      color="light"
                      css={styles.buildFaster}
                      colorHover="light"
                    >
                      Build Footer
                    </View>
                  </Tooltip>
                </Text>
              </View>

              <View css={{ marginBottom: '15px' }}>
                {pageId !== PAGE_BUILD_HEADER_FOOTER ? (
                  <DragItem
                    label={`Global ${i18n.t('general.footer')}`}
                    description="Click here to navigate to Global Header & Footer to edit"
                    onEdit={handleGoToHeaderFooterPage}
                    css={{
                      borderColor: colors.gray6,
                      borderStyle: 'dashed',
                      backgroundColor: colors.gray3,
                    }}
                    innerCss={{
                      backgroundColor: colors.gray3,
                    }}
                    Icon={() => <></>}
                  />
                ) : (
                  <Sortable {...getSortableProps('footer')} data={footer} renderItem={renderItem} containerCss={{ height: '100%' }} />
                )}

                {pageId === PAGE_BUILD_HEADER_FOOTER && renderButtonAddSection('footer', disabledHeaderFooter)}
              </View>
            </>
          )}
        </View>
      </ScrollBars>

      <View css={styles.footer}>
        {entityVariant === 'Client' ? (
          <>
            <ButtonAddThemeSection onClick={handleAddSectionInTheme} />
            <ClearAllButton />
          </>
        ) : (
          <>
            <Button
              backgroundColor="gray3"
              color="gray9"
              size="small"
              block
              radius={6}
              fontFamily="secondary"
              css={styles.btn}
              onClick={handleChooseTemplateVisible}
            >
              {i18n.t('general.add', { text: i18n.t('general.section'), textTransform: 'capitalize' })}
            </Button>
            <ClearAllButton />
          </>
        )}

        <Tooltip
          text={
            <View css={{ maxWidth: '400px' }}>
              Force update all sections. You still need to click the Publish button in the top right corner to sync the data to your Store.
            </View>
          }
          portal
        >
          <Button
            disabled={main.length === 0 && header.length === 0 && footer.length === 0}
            backgroundColor="gray2"
            size="small"
            block
            radius={6}
            css={styles.clearBtn}
            onClick={updateAllSections}
          >
            {updateAllVersionStatus === 'loading' ? (
              <ActivityIndicator size={20} />
            ) : (
              <FontAwesome type="far" name="arrow-from-bottom" color="gray8" size={20} />
            )}
          </Button>
        </Tooltip>
      </View>
    </>
  );
};

LeftBarSections.setErrorId = cb => {
  handler?.(cb);
};

export default LeftBarSections;
