import { v4 } from 'uuid';
import { defaultLanguage, languages } from '../../../../@consts';
import { Locales } from '../../../../@types/Veda/ShopifyLocales';

const localKeyGenByVedaFieldInfo = 'FieldGenByVedaFieldInfo';

const createLocaleKey = () => `_${localKeyGenByVedaFieldInfo}_${v4()}_`;

interface RTGetShopifyFieldInfo {
  newInfo: string;
  locales: Locales;
}
export const getShopifyFieldInfo = (): RTGetShopifyFieldInfo => {
  const localeKey = createLocaleKey();
  const default_translations = { [localeKey]: '' };

  // const newInfo: RTGetShopifyFieldInfo['newInfo'] = `t:${localeKey}`;
  const locales: RTGetShopifyFieldInfo['locales'] = languages.reduce<RTGetShopifyFieldInfo['locales']>(
    (res, language) => {
      return {
        ...res,
        [language]: {
          [localeKey]: 'Will be scaled down to 32 x 32px',
        },
      };
    },
    { en: default_translations, vi: default_translations, fr: default_translations },
  );

  // return {
  //   newInfo,
  //   locales,
  // };
  return {
    newInfo: locales[defaultLanguage][localeKey],
    locales,
  };
};
