import { strToRegexpPattern } from '@wiloke/functions';

export const replaceExactKeywordInLiquidCode = (liquid: string, source: string, target: string) => {
  return liquid.replace(new RegExp(`(.|\\n)${strToRegexpPattern(source)}(.|\\n)`, 'g'), value => {
    const isStartValid =
      value.startsWith(' ') ||
      value.startsWith('\n') ||
      // Trường hợp collections[product_settings.collection]
      value.startsWith('[');
    const isEndValid =
      value.endsWith(' ') ||
      value.endsWith('\n') ||
      // Trường hợp collections[product_settings.collection]
      value.endsWith(']');

    if (isStartValid && isEndValid) {
      return value.replace(source, target);
    } else {
      return value;
    }
  });
};
