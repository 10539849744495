import { mergeDeepLeft, sort } from 'ramda';
import { NumberField } from '../../../@types/Shopify/InputSettings/NumberField';
import { Locales } from '../../../@types/Veda/ShopifyLocales';
import { ignoreToReduceTotalLinesOfCode } from '../../../@utils/ignoreToReduceTotalLinesOfCode';
import { replaceExactVariableNameInLiquidCode } from '../../../@utils/replaceExactVariableNameInLiquidCode';
import { sortWithKeyOfFieldValues } from '../../../@utils/sortWithKeyOfFieldValues';
import { breakpoints } from './@consts/breakpoints';
import { ILIQUID_SettingResponsive } from './@types/ILIQUID_SettingResponsive';
import { ISCHEMA_SettingResponsive } from './@types/ISCHEMA_SettingResponsive';
import { ISETTING_SettingResponsive } from './@types/ISETTING_SettingResponsive';
import { getSignalReduceTotalLinesOfCode } from './utils/getSignalReduceTotalLinesOfCode';
import { toShopifyFieldId } from './utils/toShopifyFieldId';
import { vedaLabelsOfResponsiveFieldToShopifyFieldLabel } from './utils/vedaLabelsOfResponsiveFieldToShopifyFieldLabel';
import { vedaSummariesOfResponsiveFieldToShopifyFieldInfo } from './utils/vedaSummariesOfResponsiveFieldToShopifyFieldInfo';

interface RTSettingResponsive {
  locales: Locales;
  shopifyField: [NumberField, NumberField, NumberField, NumberField];
}

export const SCHEMA_SettingResponsive = ({ field, parentField, ...params }: ISCHEMA_SettingResponsive): RTSettingResponsive => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode ||
    (params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField }));
  let RLocales: Locales = {
    en: {},
    fr: {},
    vi: {},
  };

  const breakpointsSorted = sort(sortWithKeyOfFieldValues, breakpoints);
  const data = breakpointsSorted.reduce<NumberField[]>((res, breakpoint) => {
    const { newLabelOfBreakpoint, locales: localesOfLabel } = vedaLabelsOfResponsiveFieldToShopifyFieldLabel(
      { ...params, field, parentField, isNeedReduceTotalLinesOfCode },
      breakpoint,
    );
    const { newInfoOfBreakpoint, locales: localesOfInfo } = vedaSummariesOfResponsiveFieldToShopifyFieldInfo({
      ...params,
      field,
      parentField,
      isNeedReduceTotalLinesOfCode,
    });
    RLocales = mergeDeepLeft(RLocales, localesOfLabel);
    RLocales = mergeDeepLeft(RLocales, localesOfInfo);
    const value = field.children[breakpoint];
    const valueString = typeof value === 'number' ? value : undefined;
    return res.concat({
      type: 'number',
      default: valueString,
      id: isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, breakpoint)
        : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, breakpoint),
      placeholder: undefined,
      info: newInfoOfBreakpoint,
      label: newLabelOfBreakpoint,
    });
  }, []);

  return {
    locales: RLocales,
    shopifyField: data as RTSettingResponsive['shopifyField'],
  };
};

export const SETTING_SettingResponsive = ({ field, parentField, ...params }: ISETTING_SettingResponsive) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode ||
    (params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField }));
  return breakpoints.reduce<Record<string, number | undefined>>((res, breakpoint) => {
    // check kĩ phòng lỗi
    const value = field.children[breakpoint];
    const valueString = typeof value === 'number' ? value : undefined;
    if (valueString !== undefined || isNeedReduceTotalLinesOfCode) {
      const fieldName = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, breakpoint)
        : toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, breakpoint);
      return {
        ...res,
        [fieldName]: valueString,
      };
    }
    return res;
  }, {});
};

export const LIQUID_SettingResponsive = ({ field, parentField, liquid, loopVariable, ...params }: ILIQUID_SettingResponsive) => {
  const isNeedReduceTotalLinesOfCode =
    params.isNeedReduceTotalLinesOfCode ||
    (params.isNeedReduceTotalLinesOfCode && parentField?.type === 'object' && ignoreToReduceTotalLinesOfCode({ field, parentField }));
  // Nếu field thuộc array
  // - loopVariable nếu là array ->  Array được dùng trong forloop -> Thế `${loopVariable}.${field.name}.${key}` = `${loopVariable}.${toShopifyFieldId({ field, parentField }, key)}`
  //   Example: --> for item in content --> item.icon_design.id ==> KQ: item.content__icon_design.id
  if (loopVariable) {
    return breakpoints.reduce<string>((res, breakpoint) => {
      const target = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, breakpoint)
        : `${loopVariable}.${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, breakpoint)}`;
      const source = `${loopVariable}.${field.name}.${breakpoint}`;
      return replaceExactVariableNameInLiquidCode({ liquid: res, source, target });
    }, liquid);
  }
  // Nếu field thuộc object
  // - parentField nếu là object -> Object được flat thành các "shopify input settings" -> Thế `${parentField.name}.${field.name}.${key}` = toShopifyFieldId
  //   Example: --> heading.icon_design.id ==> KQ: heading__icon_design.id
  else if (parentField) {
    return breakpoints.reduce<string>((res, breakpoint) => {
      const target = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, breakpoint)
        : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, breakpoint)}"]`;
      const source = `${parentField.name}.${field.name}.${breakpoint}`;
      return replaceExactVariableNameInLiquidCode({ liquid: res, source, target });
    }, liquid);
  } else {
    return breakpoints.reduce<string>((res, breakpoint) => {
      const target = isNeedReduceTotalLinesOfCode
        ? getSignalReduceTotalLinesOfCode(field, breakpoint)
        : `section.settings["${toShopifyFieldId({ ...params, field, parentField, isNeedReduceTotalLinesOfCode }, breakpoint)}"]`;
      const source = `${field.name}.${breakpoint}`;
      return replaceExactVariableNameInLiquidCode({ liquid: res, source, target });
    }, liquid);
  }
};
