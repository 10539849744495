import { AdminSection } from 'types/Sections';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { sectionApiController } from 'services/SectionService';
import { adapterGetManySections } from 'services/SectionService/Adapters/adapterGetManySections';

export const getAtomSections = async ({
  categoryCommandId,
  categoryName,
  label,
  commandIds,
  syncedToProduct,
}: {
  categoryCommandId?: string;
  categoryName?: string;
  label?: string;
  commandIds?: string[];
  syncedToProduct?: boolean | null;
}) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    if (!!categoryCommandId || !!categoryName || !!label || !!commandIds) {
      const atomResponse = await sectionApiController.atom.adminApi.sections.filterAtom({
        categoryCommandId,
        categoryName,
        label,
        commandIds: commandIds?.join(','),
        syncedToProduct,
      });
      const productResponse = await sectionApiController.product.adminApi.sections.getProductsWithParentCommandIds({
        parentCommandIds: atomResponse.info.map(item => item.commandId).join(','),
        cacheVersion: lastCache,
      });
      return adapterGetManySections(atomResponse.info, productResponse.info) as AdminSection[];
    } else {
      const atomResponse = await sectionApiController.atom.adminApi.sections.getAtoms({
        type: 'GET FIRST PAGE',
        cacheVersion: lastCache,
        syncedToProduct,
      });
      const productResponse = await sectionApiController.product.adminApi.sections.getProductsWithParentCommandIds({
        parentCommandIds: atomResponse.info.map(item => item.commandId).join(','),
        cacheVersion: lastCache,
      });
      return adapterGetManySections(atomResponse.info, productResponse.info) as AdminSection[];
    }
  }
  if (role === 'dev' || role === 'support') {
    if (!!categoryCommandId || !!categoryName || !!label || !!commandIds) {
      const response = await sectionApiController.atom.publishApi.sections.filterAtom({
        categoryCommandId,
        categoryName,
        label,
        commandIds: commandIds?.join(','),
        cacheVersion: lastCache,
        syncedToProduct,
      });
      return adapterGetManySections(response.info) as AdminSection[];
    } else {
      const response = await sectionApiController.atom.publishApi.sections.getAtoms({
        type: 'GET FIRST PAGE',
        cacheVersion: lastCache,
        syncedToProduct,
      });
      return adapterGetManySections(response.info) as AdminSection[];
    }
  }
  throw new RoleException();
};

export const loadMoreAtomSections = async ({
  cursor,
  categoryCommandId,
  categoryName,
  label,
  syncedToProduct,
}: {
  cursor: string;
  categoryCommandId?: string;
  categoryName?: string;
  label?: string;
  syncedToProduct?: boolean | null;
}) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const atomResponse = await sectionApiController.atom.adminApi.sections.getAtoms({
      type: 'LOADMORE',
      lastCursor: cursor,
      categoryCommandId,
      categoryName,
      label,
      cacheVersion: lastCache,
      syncedToProduct,
    });
    const productResponse = await sectionApiController.product.adminApi.sections.getProductsWithParentCommandIds({
      parentCommandIds: atomResponse.info.map(item => item.commandId).join(','),
      cacheVersion: lastCache,
    });
    return adapterGetManySections(atomResponse.info, productResponse.info) as AdminSection[];
  }
  if (role === 'dev' || role === 'support') {
    const response = await sectionApiController.atom.publishApi.sections.getAtoms({
      type: 'LOADMORE',
      lastCursor: cursor,
      categoryCommandId,
      categoryName,
      label,
      cacheVersion: lastCache,
      syncedToProduct,
    });
    return adapterGetManySections(response.info) as AdminSection[];
  }
  throw new RoleException();
};
