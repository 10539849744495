import { Button, MyModal } from '@wiloke/ui';
import VideoPlayer from 'components/VideoPlayer';
import { FC, useCallback, useEffect, useState } from 'react';
import { i18n } from 'translation';
import { View, Text, Styles } from 'wiloke-react-core';
import { Open, Props, State, Static } from './@types';

const modalActiveThemeAppExtensionHandler = new Map<string, Open>();
const bodyModalStyles: Styles = {
  width: '690px',
};

export const ModalActiveThemeAppExtension: FC<Props> & Static = ({ id }) => {
  const [state, setState] = useState<State>({
    isVisible: false,
    themeEditor: undefined,
  });

  const handleCancel = useCallback(() => {
    setState({
      isVisible: false,
      themeEditor: undefined,
    });
  }, []);

  const handleOpen: Open = data => {
    setState(state => ({
      ...state,
      ...data,
      isVisible: true,
    }));
  };

  useEffect(() => {
    modalActiveThemeAppExtensionHandler.set(id, handleOpen);
    return () => {
      modalActiveThemeAppExtensionHandler.delete(id);
    };
  }, [id]);

  return (
    <MyModal
      headerText={i18n.t('ModalActiveThemeAppExtension.modal_heading')}
      depsHeightRecalculation={state.isVisible}
      isVisible={state.isVisible}
      onCancel={handleCancel}
      okText=""
      cancelText=""
      bodyCss={bodyModalStyles}
    >
      <View css={{ padding: '4px' }}>
        <View css={{ marginBottom: '8px', fontSize: '15px', fontWeight: '500' }}>{i18n.t('ModalActiveThemeAppExtension.modal_content_title')}</View>
        <VideoPlayer disabledInput src="https://cdn.shopify.com/videos/c/o/v/628cdcbf1fe4436b9d959737843e036c.mp4" />
        <Text css={{ marginBottom: '16px', marginTop: '4px' }}>{i18n.t('ModalActiveThemeAppExtension.modal_content_description')}</Text>
        <View css={{ textAlign: 'right' }}>
          <Button
            colorHover="light"
            size="medium"
            radius={6}
            css={{ marginTop: '8px' }}
            href={state.themeEditor}
            target="blank"
            onClick={handleCancel}
          >
            {i18n.t('ModalActiveThemeAppExtension.modal_button')}
          </Button>
        </View>
      </View>
    </MyModal>
  );
};

ModalActiveThemeAppExtension.getActions = (id: Props['id']) => {
  const open = modalActiveThemeAppExtensionHandler.get(id);
  if (open) {
    return {
      open,
    };
  }
  throw new Error(`ModalActiveThemeAppExtension -> ${id} not exist`);
};
