import { PartnerCategory, PartnerItem } from 'services/RecommednApps/types';
import { ActionTypes, createSlice, handleAction } from 'wiloke-react-core/utils';
import { getPartnerAppCategories, getPartnerApps, loadMorePartnerApps } from './action';

type ExtraActions = ActionTypes<typeof getPartnerAppCategories | typeof getPartnerApps | typeof loadMorePartnerApps>;

type Actions =
  | {
      type: 'setPartnerAppCategory';
      payload: string;
    }
  | {
      type: 'setSearchPartnerApp';
      payload: string;
    };

interface State {
  categories: PartnerCategory[];
  apps: PartnerItem[];
  categoryId: string;
  search: string;

  getCategoriesStatus: Status;
  getAppsStatus: Status;
  loadMoreAppStatus: Status;
}

export const partnerAppsSlice = createSlice<State, Actions, ExtraActions>({
  name: '@Dashboard',
  initialState: {
    apps: [],
    categories: [],
    categoryId: '0',
    search: '',

    getAppsStatus: 'idle',
    loadMoreAppStatus: 'idle',
    getCategoriesStatus: 'idle',
  },
  reducers: [
    handleAction('setPartnerAppCategory', ({ state, action }) => {
      state.categoryId = action.payload;
    }),
    handleAction('setSearchPartnerApp', ({ state, action }) => {
      state.search = action.payload;
    }),
  ],
  extraReducers: [
    handleAction('@Dashboard/getPartnerAppCategories/request', ({ state }) => {
      state.getCategoriesStatus = state.categories.length > 0 ? 'success' : 'loading';
    }),
    handleAction('@Dashboard/getPartnerAppCategories/success', ({ state, action }) => {
      state.getCategoriesStatus = 'success';
      state.categories = action.payload.data;
    }),
    handleAction('@Dashboard/getPartnerAppCategories/failure', ({ state }) => {
      state.getCategoriesStatus = 'failure';
    }),
    handleAction('@Dashboard/getPartnerApps/request', ({ state }) => {
      state.getAppsStatus = 'loading';
    }),
    handleAction('@Dashboard/getPartnerApps/success', ({ state, action }) => {
      state.getAppsStatus = 'success';
      state.apps = action.payload.data;
    }),
    handleAction('@Dashboard/getPartnerApps/failure', ({ state }) => {
      state.getAppsStatus = 'failure';
    }),
  ],
});

export const partnerAppSelector = (state: AppState) => state.adminDashboard.partnerApps;
