import { CssColorVariable } from 'types/PresetStyles';

const thresholdGrayColorInDarkMode = new Map<string, number>(
  Object.entries({
    '--color-gray9': 0.04,
    '--color-gray8': 0.1,
    '--color-gray7': 0.25,
    '--color-gray6': 0.35,
    '--color-gray5': 0.5,
    '--color-gray4': 0.6,
    '--color-gray3': 0.7,
    '--color-gray2': 0.85,
    '--color-gray1': 0.95,
  }),
);

const thresholdGrayColorInLightMode = new Map<string, number>(
  Object.entries({
    '--color-gray9': 0.95,
    '--color-gray8': 0.85,
    '--color-gray7': 0.7,
    '--color-gray6': 0.6,
    '--color-gray5': 0.5,
    '--color-gray4': 0.35,
    '--color-gray3': 0.25,
    '--color-gray2': 0.1,
    '--color-gray1': 0.04,
  }),
);

export const isGray1To9 = (color: CssColorVariable) => {
  return thresholdGrayColorInLightMode.has(color.name);
};

export const getThreshold = (color: CssColorVariable, variant: 'dark' | 'light') => {
  if (variant === 'light') {
    return thresholdGrayColorInLightMode.get(color.name);
  }
  return thresholdGrayColorInDarkMode.get(color.name);
};
