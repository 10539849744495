import { getMetafields, loadmoreMetafields } from 'store/actions/shopify/actionMetafields';
import { Metafield } from 'types/Metafields';
import { ActionTypes, createReducer, handleAction } from 'wiloke-react-core/utils';

interface StateItem {
  data: Metafield[];
  statusRequest: Status;
  statusLoadmore: Status;
  hasNextPage: boolean;
}

type State = StateItem;

type Actions = ActionTypes<typeof getMetafields | typeof loadmoreMetafields>;

const defaultState: State = {
  data: [],
  statusLoadmore: 'idle',
  statusRequest: 'idle',
  hasNextPage: false,
};

export const reducerMetafields = createReducer<State, Actions>(defaultState, [
  handleAction('@Shopify/getMetafields/request', ({ state }) => {
    return {
      ...state,
      statusRequest: 'loading',
    };
  }),
  handleAction('@Shopify/getMetafields/success', ({ state, action }) => {
    const { data, hasNextPage } = action.payload;
    return {
      ...state,
      statusRequest: 'success',
      data,
      hasNextPage,
    };
  }),
  handleAction('@Shopify/getMetafields/failure', ({ state }) => {
    return {
      ...state,
      statusRequest: 'failure',
    };
  }),
  handleAction('@Shopify/loadmoreMetafields/request', ({ state }) => {
    return {
      ...state,
      statusLoadmore: 'loading',
    };
  }),
  handleAction('@Shopify/loadmoreMetafields/success', ({ state, action }) => {
    const { data, hasNextPage } = action.payload;
    return {
      ...state,
      statusLoadmore: 'success',
      data: state.data.concat(data),
      hasNextPage,
    };
  }),
  handleAction('@Shopify/loadmoreMetafields/failure', ({ state }) => {
    return {
      ...state,
      statusLoadmore: 'failure',
    };
  }),
]);
