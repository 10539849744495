/**
 * API để lấy về thông tin chung của các section
 * Phục vụ cho chức năng chọn các section có chứa field "Link list" khi sync envato
 */

import { CancelTokenSource } from 'axios';
import configureApp from 'configureApp';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface GetSectionsInfomationOfDraft {
  themeCommandId: string;
  cancelTokenSource: CancelTokenSource;
  sectionLabel: string;
  alwaysIncludeHeaderFooter: boolean;
}

export const getSectionsInfomationOfDraft = ({
  themeCommandId,
  cancelTokenSource,
  sectionLabel,
  alwaysIncludeHeaderFooter,
}: GetSectionsInfomationOfDraft) => {
  return fetchAPI.request({
    url: `${baseUrl}/${themeCommandId}/${configureApp.endpoint.sections}`,
    params: {
      sectionLabel,
      alwaysIncludeHeaderFooter,
    },
    cancelToken: cancelTokenSource.token,
  });
};
