import { getCacheValue, setCacheValue, getUniqId } from '../../../CacheShopifyFieldId';
import { ISCHEMA_SettingShopifyFont } from '../@types/ISCHEMA_SettingShopifyFont';
import { ISETTING_SettingShopifyFont } from '../@types/ISETTING_SettingShopifyFont';

export const toShopifyFieldId = ({ field, parentField, section }: ISCHEMA_SettingShopifyFont | ISETTING_SettingShopifyFont) => {
  const theoryShopifyFieldId = parentField ? `${parentField.name}___${field.name}` : field.name;
  if (theoryShopifyFieldId.length >= 25) {
    const valueInCache = getCacheValue({ theoryShopifyFieldId, section });
    if (valueInCache) {
      return valueInCache;
    } else {
      const newKey = `VEDA_font_${getUniqId('font')}`;
      setCacheValue({ theoryShopifyFieldId, section }, newKey);
      return newKey;
    }
  }
  return theoryShopifyFieldId;
};
