import { all, call, select, put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { i18n } from 'translation';
import { appendPageToThemeAtom } from 'services/ThemeService/Logic/appendPageToThemeAtom';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { updateThemeAtom as actionUpdateThemeAtom } from '../actions/actionThemesAtom';
import { setModalUpdateThemeAtom } from '../reducers/sliceThemesAtom';
import { deletePageOfThemeAtom } from 'services/ThemeService/Logic/deletePageOfThemeAtom';
import { handleClonePageAtomServiceToThemeAtomService } from './utils/handleClonePageAtomServiceToThemeAtomService';
import { socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector } from 'store/selectors';
import { notification } from 'antd';

function* handleUpdateThemeAtom({ payload }: ReturnType<typeof actionUpdateThemeAtom.request>) {
  const { featuredImage, screenshot1, label, pageCommandIdsNeedImport, pageCommandIdsNeedDelete, onFulfill } = payload;
  const { pageCommandIds: prevPageCommandIds, commandId } = payload.prevThemeData;
  const { eventId }: SagaReturnType<typeof socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector> = yield select(
    socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector,
  );
  if (!eventId) {
    yield put(actionUpdateThemeAtom.failure(undefined));
    onFulfill();
    notification.error({ message: i18n.t('general.system_error'), description: 'Không có "eventId"' });
  } else {
    const newPageCommandIds: SagaReturnType<typeof handleClonePageAtomServiceToThemeAtomService> = yield call(
      handleClonePageAtomServiceToThemeAtomService,
      {
        eventId,
        pageAtomCommandIds: pageCommandIdsNeedImport,
        entityType: 'Atom',
      },
    );
    try {
      yield all(
        pageCommandIdsNeedDelete.map(pageCommandId => {
          return retry(3, 1000, deletePageOfThemeAtom, { commandId: pageCommandId });
        }),
      );

      const response: Awaited<ReturnType<typeof appendPageToThemeAtom>> = yield retry(3, 1000, appendPageToThemeAtom, {
        theme: {
          featuredImage,
          screenshot1,
          label,
          pageCommandIds: prevPageCommandIds.filter(item => !pageCommandIdsNeedDelete.includes(item)).concat(...newPageCommandIds),
          commandId,
        },
      });

      yield put(actionUpdateThemeAtom.success({ data: response }));
      yield put(setModalUpdateThemeAtom(undefined));
      notifyAxiosHandler.handleSuccess(`${i18n.t('general.update', { text: i18n.t('general.successfully') })}`);
    } catch (error) {
      const error_ = error as Error;
      notifyAxiosHandler.handleError(error_);
      yield put(actionUpdateThemeAtom.failure(undefined));
      try {
        yield all(
          newPageCommandIds.map(pageCommandId => {
            return retry(3, 1000, deletePageOfThemeAtom, { commandId: pageCommandId });
          }),
        );
      } catch {}
    } finally {
      onFulfill();
    }
  }
}

export function* watchUpdateThemeAtom() {
  yield takeLatest(getActionType(actionUpdateThemeAtom.request), handleUpdateThemeAtom);
}
