import { SectionCategoryTag } from 'types/Sections';
import { CategoryDataResponse } from '../types';

export const adapterGetCategories = (categoriesResponse: CategoryDataResponse[]) => {
  return categoriesResponse.map<SectionCategoryTag>(item => {
    return {
      ...item,
      count: item.count,
      name: item.name,
      description: item.description ? item.description : item.name,
      commandId: item.commandId,
      priorityOrder: item.priorityOrder,
      searchTerms: item.searchTerms,
      childrenCommandId: item.children?.map(item => item.commandId),
    };
  });
};
