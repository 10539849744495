import axios, { AxiosResponse } from 'axios';
import { PartnerCategory, PartnerItem, VedaBlogDTO } from './types';

const APP_PARTNER_URL = 'https://myshopkit.app/wp-json/wp/v2';

const getRecommendApps = async () => {
  type Success = PartnerItem[];

  const response: AxiosResponse<Success> = await axios.request({
    baseURL: `${APP_PARTNER_URL}/partners`,
    method: 'GET',
    params: {
      per_page: 100,
      orderby: 'menu_order_and_date',
    },
  });

  const _data = response.data.filter(item => item.acf.app_highlight_on_veda_sidebar && item.slug !== 'veda-landing-page-builder');

  return _data;
};

const getPartnerApps = async (categoryId?: number, search?: string) => {
  type Success = PartnerItem[];

  const response: AxiosResponse<Success> = await axios.request({
    baseURL: `${APP_PARTNER_URL}/partners`,
    method: 'GET',
    params: {
      'partner-categories': categoryId ? categoryId : undefined,
      per_page: 100,
      search: search !== '' ? search : undefined,
    },
  });

  return response.data;
};

const getPartnerAppsCategories = async () => {
  type Success = PartnerCategory[];

  const response: AxiosResponse<Success> = await axios.request({
    baseURL: `${APP_PARTNER_URL}/partner-categories `,
    method: 'GET',
  });

  return response.data;
};

const getVedaBlogs = async () => {
  interface ResponseSuccess extends VedaBlogDTO {}
  const response: AxiosResponse<ResponseSuccess[]> = await axios.request({
    baseURL: `${APP_PARTNER_URL}/posts`,
    method: 'GET',
    params: {
      per_page: 3,
    },
  });

  return response.data;
};

export const recommendAppsService = {
  getRecommendApps,
  getPartnerApps,
  getPartnerAppsCategories,
  getVedaBlogs,
};
