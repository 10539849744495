import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';

export const deleteUseCaseOfProdPage = async ({ commandId }: { commandId: string }) => {
  const { role } = getUserInfo();

  if (role === 'admin') {
    const response = await pageApis.vedaApplication.adminApi.use_case.deleteUseCase({ commandId });
    return response;
  }

  throw new RoleException();
};
