export const replaceComplexVarToSimpleVar = (str: string) => {
  // rgba(var(--rgb-color-light-freeze), 0.3) => rgba(--color-light, 0.3)
  // rgba(var(--rgb-color-light), 0.3) => rgba(--color-light, 0.3)
  // var(--color-gray9-freeze) => --color-gray9
  //  var(--color-primary) => --color-primary)
  // rgba(255, 255, 255, 0.3) => rgba(255, 255, 255, 0.3)
  // #ffffff => #ffffff
  if (str.startsWith('rgba')) {
    return str
      .replace(/var\(/g, '')
      .replace(/\),/g, ',')
      .replace(/-freeze/g, '')
      .replace(/--rgb-/g, '--');
  }
  return str
    .replace(/var\(/g, '')
    .replace(/\)/g, '')
    .replace(/-freeze/g, '');
};
