import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import { ResponseShopifyPages } from 'types/Pages';
import { getCurrentVersion } from 'utils/CacheControl/ShopifyConnectionCacheControl';
import fetchAPI from 'utils/functions/fetchAPI';
import { getShopName } from 'utils/functions/getUserInfo';

export class ShopifyPageService {
  async getPages(search: string): Promise<ResponseShopifyPages> {
    const response: AxiosResponse<ResponseShopifyPages> = await fetchAPI.request({
      url: `${configureApp.endpoint['shopify-connections']}/admin-pages?shopName=${getShopName()}`,
      params: {
        search: search ? search : undefined,
        cacheVersion: getCurrentVersion().getTime(),
      },
    });
    return {
      info: response.data.info,
      hasNextPage: response.data.hasNextPage,
      message: response.data.message,
    };
  }

  async loadMorePages(lastCursor: string, search?: string) {
    const response: AxiosResponse<ResponseShopifyPages> = await fetchAPI.request({
      url: `shopify-  /admin-pages?shopName=${getShopName()}`,
      params: {
        search: search ? search : undefined,
        cursor: lastCursor,
        cacheVersion: getCurrentVersion().getTime(),
      },
    });
    return response.data;
  }
}
