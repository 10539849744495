import { PurchaseCode } from 'types/PurchaseCode';
import { ActionTypes, createDispatchAction, createSlice, handleAction } from 'wiloke-react-core/utils';

import { deletePurchaseCode, getPurchaseCodes, deleteWebsiteOfPurchaseCode, createWebsiteOfPurchaseCode } from '../actions/actionPurchaseCodePage';

type ExtraActions = ActionTypes<
  typeof getPurchaseCodes | typeof deletePurchaseCode | typeof deleteWebsiteOfPurchaseCode | typeof createWebsiteOfPurchaseCode
>;

interface Actions {
  type: 'setPurchaseCodeItem';
  payload: PurchaseCode | undefined;
}

interface State {
  data: PurchaseCode[];
  getStatus: Status;
  deletePurchaseCodeStatus: Record<string, Status>;
  deleteWebsiteStatus: Record<string, Status>;
  createWebsiteStatus: Status;
  currentPurchaseCode: PurchaseCode | undefined;
  message: string;
}

export const slicePurchaseCodePage = createSlice<State, Actions, ExtraActions>({
  name: '@PurchaseCodePage',
  initialState: {
    data: [],
    getStatus: 'idle',
    createWebsiteStatus: 'idle',
    deletePurchaseCodeStatus: {},
    deleteWebsiteStatus: {},
    currentPurchaseCode: undefined,
    message: '',
  },
  reducers: [
    handleAction('setPurchaseCodeItem', ({ state, action }) => {
      state.currentPurchaseCode = action.payload;
    }),
  ],
  extraReducers: [
    handleAction('@PurchaseCodePage/getPurchaseCodes/request', ({ state }) => {
      state.getStatus = 'loading';
    }),
    handleAction('@PurchaseCodePage/getPurchaseCodes/success', ({ state, action }) => {
      state.getStatus = 'success';
      state.data = action.payload.data;
      state.message = action.payload.message;
    }),
    handleAction('@PurchaseCodePage/getPurchaseCodes/failure', ({ state, action }) => {
      state.getStatus = 'failure';
      state.message = action.payload.message;
    }),
    handleAction('@PurchaseCodePage/deletePurchaseCode/request', ({ state, action }) => {
      state.deletePurchaseCodeStatus[action.payload.purchaseCode] = 'loading';
    }),
    handleAction('@PurchaseCodePage/deletePurchaseCode/success', ({ state, action }) => {
      state.deletePurchaseCodeStatus[action.payload.purchaseCode] = 'success';
      state.data = state.data.filter(item => item.purchaseCode !== action.payload.purchaseCode);
    }),
    handleAction('@PurchaseCodePage/deletePurchaseCode/failure', ({ state, action }) => {
      state.deletePurchaseCodeStatus[action.payload.purchaseCode] = 'failure';
    }),
    handleAction('@PurchaseCodePage/deleteWebsiteOfPurchaseCode/request', ({ state, action }) => {
      state.deleteWebsiteStatus[action.payload.website] = 'loading';
    }),
    handleAction('@PurchaseCodePage/deleteWebsiteOfPurchaseCode/success', ({ state, action }) => {
      state.deleteWebsiteStatus[action.payload.website] = 'success';
      state.data = state.data.map(item => {
        if (item.purchaseCode === action.payload.purchaseCode) {
          return {
            ...item,
            websites: item.websites.filter(web => web !== action.payload.website),
          };
        }
        return item;
      });

      if (state.currentPurchaseCode) {
        state.currentPurchaseCode = {
          ...state.currentPurchaseCode,
          websites: state.currentPurchaseCode.websites.filter(web => web !== action.payload.website),
        };
      }
    }),
    handleAction('@PurchaseCodePage/deleteWebsiteOfPurchaseCode/failure', ({ state, action }) => {
      state.deleteWebsiteStatus[action.payload.website] = 'failure';
    }),
    handleAction('@PurchaseCodePage/createWebsiteOfPurchaseCode/request', ({ state }) => {
      state.createWebsiteStatus = 'loading';
    }),
    handleAction('@PurchaseCodePage/createWebsiteOfPurchaseCode/success', ({ state, action }) => {
      state.createWebsiteStatus = 'success';
      state.data = state.data.map(item => {
        if (item.purchaseCode === action.payload.purchaseCode) {
          return {
            ...item,
            websites: item.websites.concat(action.payload.website),
          };
        }
        return item;
      });
      if (state.currentPurchaseCode) {
        state.currentPurchaseCode = {
          ...state.currentPurchaseCode,
          websites: state.currentPurchaseCode.websites.concat(action.payload.website),
        };
      }
    }),
    handleAction('@PurchaseCodePage/createWebsiteOfPurchaseCode/failure', ({ state }) => {
      state.createWebsiteStatus = 'failure';
    }),
  ],
});

export const { setPurchaseCodeItem } = slicePurchaseCodePage.actions;

export const purchaseCodesSelector = (state: AppState) => state.purchaseCodes;

export const useSetPurchaseCodeItem = createDispatchAction(setPurchaseCodeItem);
