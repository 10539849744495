import { Tooltip } from '@wiloke/ui';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetBlogs, useRenewCollections, useRenewProducts } from 'store/actions/shopify';
import { defaultBlogDataState } from 'store/reducers/shopify/reducerBlogs';
import { defaultCollectionDataState } from 'store/reducers/shopify/reducerCollection';
import { defaultProductDataState } from 'store/reducers/shopify/reducerProducts';
import { shopifySelector } from 'store/selectors';
import { i18n } from 'translation';
import { renewVersion } from 'utils/CacheControl/ShopifyConnectionCacheControl';
import { View, FontAwesome, ActivityIndicator, ViewProps, Styles } from 'wiloke-react-core';

interface Props {
  variant: 'product' | 'collection' | 'blog';
  css?: ViewProps['css'];
}

const containerStyle: Styles = {
  cursor: 'pointer',
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const RenewButton = ({ variant, css }: Props) => {
  const productsState = useSelector(shopifySelector.products);
  const collectionsState = useSelector(shopifySelector.collections);
  const blogsState = useSelector(shopifySelector.blogs);

  const { renewProductsStatus } = productsState.products[productsState.searchKey] || defaultProductDataState;
  const { renewCollectionsStatus } = collectionsState.collections[collectionsState.searchKey] || defaultCollectionDataState;
  const { requestStatus: renewBlogsStatus } = blogsState.blogs[blogsState.searchKey] || defaultBlogDataState;

  const renewProducts = useRenewProducts();
  const renewCollections = useRenewCollections();
  const renewBlogs = useGetBlogs();

  const isLoading = useMemo(() => {
    if (variant === 'product') {
      return renewProductsStatus === 'loading';
    }
    if (variant === 'collection') {
      return renewCollectionsStatus === 'loading';
    }
    if (variant === 'blog') {
      return renewBlogsStatus === 'loading';
    }
    return false;
  }, [renewCollectionsStatus, renewProductsStatus, renewBlogsStatus, variant]);

  const handleClick = () => {
    if (variant === 'product') {
      renewVersion();
      renewProducts.request({ search: productsState.searchKey });
    }
    if (variant === 'collection') {
      renewVersion();
      renewCollections.request({ search: collectionsState.searchKey });
    }
    if (variant === 'blog') {
      renewVersion();
      renewBlogs.request({ search: blogsState.searchKey, shouldGetArticle: false, refresh: true, isRenew: true });
    }
  };

  return (
    <Tooltip portal text={i18n.t('general.renew_data')} placement="left">
      <View css={[containerStyle, css]} borderColor="gray3" borderWidth={1} borderStyle="solid" radius={10} onClick={handleClick}>
        {isLoading ? <ActivityIndicator size={16} /> : <FontAwesome type="far" name="sync-alt" size={16} />}
      </View>
    </Tooltip>
  );
};
