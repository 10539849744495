import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import { SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client } from 'types/Sections';
import fetchAPI from 'utils/functions/fetchAPI';
import { getApiTracking } from 'utils/functions/getApiTracking';

interface GetClients {
  /** CommandId của section product cần lấy dữ liệu */
  commandIds: string[];
  cacheVersion: number | null;
}

interface ResponseSuccess {
  info: Array<SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client | undefined>;
  message: string;
}

/** API được client sử dụng để lấy dữ liệu của product section */
export const getClients = async ({ commandIds, cacheVersion }: GetClients) => {
  if (commandIds.length) {
    const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
      url: getApiTracking({
        defaultBaseUrl: `${configureApp.clientBaseUrl}/me/${configureApp.endpoint.sections}/search`,
        trackingEndpoint: `${configureApp.endpoint.sections}/search`,
      }),
      params: {
        commandIds: commandIds.join(','),
        cacheVersion: cacheVersion !== null ? cacheVersion : undefined,
      },
    });

    return response.data.info;
  }
  return [];
};
