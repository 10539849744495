import { FC, useState, useEffect, useRef } from 'react';
import * as styles from './styles';
import { ColorNames, View, ViewProps, WithStylesProps } from 'wiloke-react-core';
import { createPortal } from '@wiloke/functions';

export type ProcessLoaderStatus = 'idle' | 'loading' | 'done';

export interface ProgressLoaderProps extends Pick<WithStylesProps, 'nightModeBlacklist' | 'tachyons'> {
  status: ProcessLoaderStatus;
  /** Màu của thanh loader */
  color?: ColorNames;
  /** Duration của bar đơn vị là ms */
  duration?: number;
  /** className của thẻ bao */
  containerClassName?: string;
  /** className của bar */
  barClassName?: string;
  /** Xuất hiện phía trên cùng của trang */
  placementTop?: boolean;
  onDone?: () => void;
  css?: ViewProps['css'];
}

let progressWrapperElement: HTMLDivElement | undefined;
const isBrowser = typeof window !== 'undefined';

if (isBrowser) {
  progressWrapperElement = document.createElement('div');
  progressWrapperElement.id = 'progress-wrapper-element';
  document.body.appendChild(progressWrapperElement);
}

export const ProgressLoader: FC<ProgressLoaderProps> = ({
  status = 'idle',
  color = 'secondary',
  duration = 300,
  containerClassName = '',
  barClassName = '',
  tachyons,
  nightModeBlacklist,
  placementTop = false,
  onDone,
  css,
}) => {
  // const [count, setCount] = useState(0);
  const [doneState, setDoneState] = useState(true);
  const intervalRef = useRef(-1);
  const timeoutRef = useRef(-1);

  useEffect(() => {
    if (status !== 'done') {
      // setCount(0);
      setDoneState(false);
    }
  }, [status]);

  useEffect(() => {
    if (isBrowser) {
      if (status === 'done') {
        clearInterval(intervalRef.current);
        timeoutRef.current = window.setTimeout(() => {
          setDoneState(true);
          clearTimeout(timeoutRef.current);
          onDone?.();
        }, duration);
        return () => {
          clearTimeout(timeoutRef.current);
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, duration]);

  const content = (
    <View
      className={containerClassName}
      css={[styles.container(placementTop), css]}
      tachyons={tachyons}
      nightModeBlacklist={nightModeBlacklist}
      style={doneState || status === 'idle' ? { display: 'none' } : {}}
    >
      <View
        className={barClassName}
        css={[styles.bar, status === 'loading' ? styles.animation : {}]}
        style={status === 'done' ? { transform: 'translateX(0)' } : {}}
        backgroundColor={color}
        nightModeBlacklist={nightModeBlacklist}
      />
    </View>
  );

  if (placementTop) {
    return createPortal(content);
  }

  return content;
};
