import { userApis } from 'services/UserService/apis';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { pageApis } from '../apis';

interface CreateParams {
  name: string;
  description: string;
  belongsToPageTypes?: string[];
}

export const createUseCaseOfProdPage = async ({ description, name, belongsToPageTypes }: CreateParams) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    const response = await pageApis.vedaApplication.adminApi.use_case.createUseCase({
      useCase: {
        description,
        name,
        belongsToPageTypes,
      },
    });
    await userApis.user.adminApi.updateVedaCacheVersion();
    return response;
  }
  throw new RoleException();
};
