import { AxiosResponse } from 'axios';
import { BE_ThemeProduct } from 'services/ThemeService/VedaApplication/types';
import fetchAPI from 'utils/functions/fetchAPI';
import { getApiTracking } from 'utils/functions/getApiTracking';
import { clientBaseUrl } from '../const';

interface ResponseSuccess {
  message: string;
  info?: BE_ThemeProduct[];
}
interface Params {
  parentCommandId: string;
}

export const checkThemeIsInstalled = async ({ parentCommandId }: Params) => {
  const url = getApiTracking({ defaultBaseUrl: `${clientBaseUrl}/search`, trackingEndpoint: `themes/search` });
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url,
    params: {
      parentCommandId,
    },
  });
  return response.data?.info ? { info: response.data.info[0], message: response.data.message } : { info: undefined, message: response.data.message };
};
