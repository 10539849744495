import { AsyncComponent, MyModal, ScrollBars } from '@wiloke/ui';
import Field from 'components/Field';
import { Navigation } from 'components/Navigation';
import SectionPageHeader from 'components/SectionPageHeader';
import TextInput from 'components/TextInput';
import { Dashboard } from 'containers/Dashboard';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from 'store/selectors';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { ActivityIndicator, css, FontAwesome, Theme, View } from 'wiloke-react-core';
import { getMenu } from '../AccountPage/menu';
import { CouponCard } from '../PlanManagement/components/CouponCard';
import { useDeletePurchaseCode, useDeleteWebsiteOfPurchaseCode, useGetPurchaseCodes } from './actions';
import { purchaseCodesSelector, useSetPurchaseCodeItem } from './slice';

const styles = {
  container: css`
    min-height: 100vh;
    display: flex;
  `,
  body: css`
    padding: 30px;
    min-height: 100%;
    width: 100%;
  `,
  content: css`
    max-width: 500px;
    margin-top: 20px;
  `,
  menu: ({ colors }: Theme) => css`
    width: 280px;
    background-color: ${colors.light};
    border-right: 1px solid ${colors.gray2};
    min-width: 280px;
    max-width: 280px;
  `,
  anchor: ({ fonts, colors }: Theme) => css`
    display: block;
    padding: 10px 15px;
    background-color: ${colors.light};
    border-bottom: 1px solid ${colors.gray2};
    font-size: 13px;
    font-family: ${fonts.secondary};
    color: ${colors.gray9};
    transition: 0.3s;
    &:hover {
      color: ${colors.primary};
    }
  `,
  item: ({ colors }: Theme) => css`
    padding: 10px;
    border: 1px solid ${colors.gray3};
    border-radius: 6px;
    background-color: ${colors.light};
    cursor: pointer;
  `,
  webItem: css`
    display: flex;
    column-gap: 10px;
    align-items: center;
    margin-bottom: 10px;
  `,
};

export const PurchaseCodePage: FC = () => {
  const { shopName } = useSelector(authSelector);
  const { getStatus, data, deletePurchaseCodeStatus, deleteWebsiteStatus, currentPurchaseCode: currentItem, message } = useSelector(
    purchaseCodesSelector,
  );
  const { role } = getUserInfo();

  const getPurchaseCodes = useGetPurchaseCodes();
  const deletePurchaseCode = useDeletePurchaseCode();
  const deleteWebsiteOfPurchaseCode = useDeleteWebsiteOfPurchaseCode();
  const setCurrentItem = useSetPurchaseCodeItem();

  useEffect(() => {
    getPurchaseCodes.request(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    setCurrentItem(undefined);
  };

  const handleDeletePurchaseCode = (purchaseCode: string) => () => {
    deletePurchaseCode.request({ purchaseCode });
  };

  const handleDeleteWebsiteOfPurchaseCode = ({ purchaseCode, website }: { purchaseCode: string; website: string }) => () => {
    deleteWebsiteOfPurchaseCode.request({ website, purchaseCode });
  };

  const renderSuccess = () => {
    return (
      <View row>
        {data.map(item => (
          <View columns={[3, 3, 3]} key={item.purchaseCode} css={{ marginBottom: '20px' }}>
            <CouponCard
              code={`Code: ${item.purchaseCode}`}
              discountText={''}
              expireOn={''}
              deleteLoading={deletePurchaseCodeStatus[item.purchaseCode] === 'loading'}
              onDelete={handleDeletePurchaseCode(item.purchaseCode)}
            />
          </View>
        ))}
      </View>
    );
  };

  return (
    <Dashboard
      hasSubmenu={false}
      disabledPaddingContent
      Modals={() => (
        <>
          {currentItem && (
            <MyModal depsHeightRecalculation={currentItem} headerText="Purchase code info" isVisible onCancel={handleCancel}>
              <Field label="Code">
                <TextInput block borderColor="gray3" disabled value={currentItem.purchaseCode} />
              </Field>
              <Field
                label="Websites"
                Right={
                  role === 'user' && currentItem.websites.length === 1
                    ? undefined
                    : (role === 'admin' || role === 'dev') && currentItem.websites.length === 2
                    ? undefined
                    : null
                  // (
                  //   <Confirm
                  //     isLoading={createWebsiteStatus === 'loading'}
                  //     onOk={onCancel => {
                  //       createWebSite.request({
                  //         purchaseCode: currentItem.purchaseCode,
                  //         website: websiteState,
                  //         onFulfill: () => {
                  //           setWebsiteState('');
                  //           onCancel();
                  //         },
                  //       });
                  //     }}
                  //     message={<TextInput block borderColor="gray3" value={websiteState} onValueChange={setWebsiteState} />}
                  //     title="Add a website"
                  //   >
                  //     <FontAwesome type="fas" name="plus-circle" size={20} color="secondary" css={{ cursor: 'pointer' }} />
                  //   </Confirm>
                  // )
                }
              >
                {currentItem.websites.map(web => (
                  <View key={web} css={styles.webItem}>
                    <TextInput block disabled borderColor="gray3" value={web} css={{ flex: '1' }} />

                    {deleteWebsiteStatus[web] === 'loading' ? (
                      <ActivityIndicator size={25} />
                    ) : (
                      <FontAwesome
                        type="fas"
                        name="times-circle"
                        size={25}
                        color="danger"
                        css={{ cursor: 'pointer' }}
                        onClick={handleDeleteWebsiteOfPurchaseCode({ purchaseCode: currentItem.purchaseCode, website: web })}
                      />
                    )}
                  </View>
                ))}
              </Field>
            </MyModal>
          )}
        </>
      )}
      Content={() => (
        <View css={styles.container}>
          <View css={styles.menu}>
            <ScrollBars>
              <Navigation data={getMenu(shopName)} />
            </ScrollBars>
          </View>
          <View css={styles.body}>
            <SectionPageHeader title={'Purchase Code Management'} disableButton description="" />

            <AsyncComponent status={getStatus} isEmpty={data.length === 0} Empty={<View tagName="h4">{message}</View>} Success={renderSuccess()} />
          </View>
        </View>
      )}
    />
  );
};
