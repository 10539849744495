/**
 * API để lấy về thông tin chung của các section
 * Phục vụ cho chức năng chọn các section có chứa field "Link list" khi sync envato
 */

import { CancelTokenSource } from 'axios';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface GetPagesInfomationOfDraft {
  pageCommandIds: string[];
  cancelTokenSource: CancelTokenSource;
  lastCache: number | null;
}

export const getPagesInfomationOfDraft = ({ pageCommandIds, lastCache, cancelTokenSource }: GetPagesInfomationOfDraft) => {
  return fetchAPI.request({
    url: `${baseUrl}/drafts/search`,
    params: {
      cacheVersion: lastCache !== null ? lastCache : undefined,
      commandIds: pageCommandIds.join(','),
    },
    cancelToken: cancelTokenSource.token,
  });
};
