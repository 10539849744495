import { reorder } from '@wiloke/functions';
import { AxiosError } from 'axios';
import { call, put, retry, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { BE_PageClient } from 'services/PageService/VedaApplication/types';
import { BE_PageInThemeAtomNDraft } from 'services/ThemeService/Atom/types';
import { getPagesOfThemeAtom } from 'services/ThemeService/Logic/getPagesOfThemeAtom';
import { getPagesOfThemeClient } from 'services/ThemeService/Logic/getPagesOfThemeClient';
import { getPagesOfThemeDraft } from 'services/ThemeService/Logic/getPagesOfThemeDraft';
import { ErrorData, notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { getHomePageBeforeCustomize } from '../slice/actions';

function* getClient(pageCommandIds: string[]) {
  const pagesResponse: SagaReturnType<typeof getPagesOfThemeClient> = yield retry(3, 1000, getPagesOfThemeClient, {
    commandIds: pageCommandIds,
  });
  return pagesResponse;
}

function* getAtom(pageCommandIds: string[]) {
  const pagesResponse: SagaReturnType<typeof getPagesOfThemeAtom> = yield retry(3, 1000, getPagesOfThemeAtom, {
    commandIds: pageCommandIds,
  });
  return pagesResponse;
}

function* getDev(pageCommandIds: string[]) {
  const pagesResponse: SagaReturnType<typeof getPagesOfThemeDraft> = yield retry(3, 1000, getPagesOfThemeDraft, {
    commandIds: pageCommandIds,
  });
  return pagesResponse;
}

function* handleGet({ payload: { pageCommandIds, themeId, entityVariant, onFulfill } }: ReturnType<typeof getHomePageBeforeCustomize.request>) {
  try {
    let pagesResponse: (BE_PageClient | BE_PageInThemeAtomNDraft)[] = [];
    if (entityVariant === 'Atom') {
      const response: SagaReturnType<typeof getAtom> = yield call(getAtom, pageCommandIds);
      pagesResponse = ([...response] as unknown) as BE_PageClient[];
    }
    if (entityVariant === 'Client') {
      const response: SagaReturnType<typeof getClient> = yield call(getClient, pageCommandIds);
      pagesResponse = ([...response] as unknown) as BE_PageInThemeAtomNDraft[];
    }
    if (entityVariant === 'Draft') {
      const response: SagaReturnType<typeof getDev> = yield call(getDev, pageCommandIds);
      pagesResponse = [...response] as BE_PageInThemeAtomNDraft[];
    }

    const firstHomeIndex = pagesResponse.findIndex(page => page.type === 'home');
    const pagesAfterReorder = reorder(pagesResponse, firstHomeIndex, 0);
    yield put(getHomePageBeforeCustomize.success({ themeId }));

    onFulfill?.(pagesAfterReorder.map(item => item.commandId));
  } catch (error) {
    notifyAxiosHandler.handleError(error as AxiosError<ErrorData> | Error);
    yield put(getHomePageBeforeCustomize.failure({ themeId }));
  }
}

export function* watchGetHomePageBeforeCustomize() {
  yield takeLatest(getActionType(getHomePageBeforeCustomize.request), handleGet);
}
