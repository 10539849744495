import { CancelTokenSource } from 'axios';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

interface GetSectionsInfomationOfThemeDraft {
  themeCommandId: string;
  cancelTokenSource: CancelTokenSource;
}

export const getSectionsInfomationOfThemeDraft = async ({ themeCommandId, cancelTokenSource }: GetSectionsInfomationOfThemeDraft) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    return themeApis.atom.adminApi.theme.getSectionsInfomationOfDraft({
      themeCommandId,
      cancelTokenSource,
      alwaysIncludeHeaderFooter: true,
      sectionLabel: 'hero',
    });
  }
  if (role === 'dev' || role === 'support') {
    return themeApis.atom.devApi.theme.getSectionsInfomationOfDraft({
      themeCommandId,
      cancelTokenSource,
      alwaysIncludeHeaderFooter: true,
      sectionLabel: 'hero',
    });
  }
  throw new RoleException();
};
