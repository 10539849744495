import { megaMenuApiController } from 'services/MegaMenuService';
import { userApis } from 'services/UserService/apis';
import { SectionCategoryTag } from 'types/Sections';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const updateCategoryOfProduct = async (category: SectionCategoryTag) => {
  const { role } = getUserInfo();
  if (role === 'admin') {
    const response = await megaMenuApiController.product.adminApi.category.updateCategoryOfProduct({ category });
    await userApis.user.adminApi.updateVedaCacheVersion();
    return response;
  }
  throw new RoleException();
};
