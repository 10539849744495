import { AsyncComponent, ScrollBars } from '@wiloke/ui';
import { useLinkPicker } from 'components/LinkPicker/store/context/LinkPickerContext';
import { getShopifyHandle } from 'components/LinkPicker/utils/getShopifyHandle';
import PageCard from 'components/PageCard';
import { isEmpty } from 'ramda';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useChangeCollectionKey, useGetCollections, useLoadMoreCollections } from 'store/actions/shopify';
import { defaultCollectionDataState } from 'store/reducers/shopify/reducerCollection';
import { shopifySelector } from 'store/selectors';
import { i18n } from 'translation';
import { CollectionApiData } from 'types/Collections';
import { renewVersion } from 'utils/CacheControl/ShopifyConnectionCacheControl';
import { ActivityIndicator, FontAwesome, View, ViewportTracking } from 'wiloke-react-core';
import { DebounceTextInput } from '../Form/Form';
import * as styles from './styles';

export const Collections = () => {
  const { collections, searchKey } = useSelector(shopifySelector.collections);
  const { data, requestStatus, hasNextPage, loadMoreStatus } = collections[searchKey] || defaultCollectionDataState;
  const { value, dispatch } = useLinkPicker();
  const getCollections = useGetCollections();
  const changeKey = useChangeCollectionKey();
  const loadMoreCollections = useLoadMoreCollections();

  useEffect(() => {
    getCollections.request({ search: searchKey });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  const _handleSearch = (val: string) => {
    changeKey(val);
  };

  const renderLoadMore = () => {
    if (hasNextPage || loadMoreStatus === 'loading') {
      return (
        <ViewportTracking
          offsetBottom={0}
          onEnterViewport={() => {
            if (requestStatus === 'success') {
              loadMoreCollections.request({ search: searchKey });
            }
          }}
        >
          <View css={{ padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <ActivityIndicator />
          </View>
        </ViewportTracking>
      );
    }
  };

  const _renderRowItem = (item: CollectionApiData) => {
    const { id, title, handle, image } = item;
    return (
      <PageCard.Style2
        image={image?.src}
        title={title}
        isActive={handle === getShopifyHandle(value)}
        css={{ marginBottom: '5px' }}
        key={id}
        onClick={() => {
          dispatch({
            type: 'setModalShopifyVisible',
            payload: false,
          });
          dispatch({
            type: 'setSettings',
            payload: {
              value: `/collections/${handle}`,
              type: 'shopify',
              label: title,
            },
          });
        }}
      />
    );
  };

  const _renderSuccess = () => {
    return (
      <ScrollBars css={{ height: 'calc(100% - 100px) !important' }}>
        <View css={{ padding: '10px', height: '100%' }}>
          <PageCard.Style2
            title={'Collections All'}
            isActive={'/collections/all' === value}
            css={{ marginBottom: '5px' }}
            onClick={() => {
              dispatch({
                type: 'setModalShopifyVisible',
                payload: false,
              });
              dispatch({
                type: 'setSettings',
                payload: {
                  value: `/collections/all`,
                  type: 'shopify',
                  label: 'Collections All',
                },
              });
            }}
          />
          {data.map(_renderRowItem)}
          {renderLoadMore()}
        </View>
      </ScrollBars>
    );
  };

  const handleRefresh = () => {
    renewVersion();
    getCollections.request({ search: searchKey, refresh: true });
  };

  return (
    <View css={styles.modalContent}>
      <View css={styles.filterContainer}>
        <DebounceTextInput
          block
          sizeInput="medium"
          placeholder={i18n.t('builderPage.search', { text: i18n.t('adminDashboard.collection') })}
          css={styles.filterInput}
          value={searchKey}
          onValueChange={_handleSearch}
        />
        <View css={styles.refreshIcon} onClick={handleRefresh}>
          {requestStatus === 'loading' ? <ActivityIndicator size={18} /> : <FontAwesome color="primary" size={18} type="far" name="sync-alt" />}
        </View>
      </View>

      <AsyncComponent status={requestStatus} isEmpty={isEmpty(data)} Success={_renderSuccess()} />
    </View>
  );
};
