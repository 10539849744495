import { useState } from 'react';

interface Item {
  commandId: string;
  label: string;
}

const cache = new Map<string, Item>();
export const useTrackClickAction = () => {
  const [state, setState] = useState<Item[]>(() => {
    return Array.from(cache.values());
  });

  const push = (item: Item) => {
    cache.set(item.commandId, item);
    setState(() => Array.from(cache.values()));
  };

  const get = () => {
    return state;
  };

  return {
    push,
    get,
  };
};
