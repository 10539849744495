import { AxiosResponse } from 'axios';
import { Author } from 'types/Author';
import fetchAPI from 'utils/functions/fetchAPI';
import { baseUrl } from '../const';

interface GetUsers {
  page: number;
  role?: string;
  username?: string;
}

interface ResponseSuccess {
  info: {
    content: Array<{
      id: number;
      username: string;
      name: string;
      phone: string;
      email: string;
      status: string;
      password: string;
      shopName: string;
      shopStatus: string;
      themeName: string;
      themeId: string;
      previousThemeId: string;
      profileUrl: string;
      createdDateGMT: string;
      updatedDateGMT: string;
      roles: Array<{
        id: number;
        name: string;
      }>;
      plan: Author['plan'];
    }>;
    sort: {
      shorted: true;
      unsorted: true;
      empty: true;
    };
    numberOfElements: number;
    number: number;
    size: number;
    totalPages: number;
  };
  message: string;
}
// Todo: BE cần thêm api filter user
export const getUsers = async ({ page, role, username }: GetUsers) => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${baseUrl}`,
    params: {
      page,
      role,
      size: 50,
      username,
    },
  });
  return response.data;
};
