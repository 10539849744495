import { put, retry, takeLatest } from 'redux-saga/effects';
import { addonService } from 'services/AddonService';
import { getActionType } from 'wiloke-react-core/utils';
import { getAdminAddons, loadMoreAdminAddons } from '../../actions';

function* handleGetAddons({ payload: { categoryId, label } }: ReturnType<typeof getAdminAddons.request>) {
  try {
    const response: Awaited<ReturnType<typeof addonService.addons.getAtoms>> = yield retry(3, 1000, addonService.addons.getAtoms, {
      categoryId,
      label,
    });
    yield put(getAdminAddons.success({ data: response, hasNextPage: response.length > 0 ? true : false }));
  } catch (error) {
    yield put(getAdminAddons.failure(undefined));
  }
}

export function* watchGetAdminAddons() {
  yield takeLatest(getActionType(getAdminAddons.request), handleGetAddons);
}

function* handleLoadMoreAdminAddons({ payload: { cursor, categoryId, label } }: ReturnType<typeof loadMoreAdminAddons.request>) {
  try {
    const response: Awaited<ReturnType<typeof addonService.addons.loadMoreAtoms>> = yield retry(3, 1000, addonService.addons.loadMoreAtoms, {
      cursor,
      categoryId,
      label,
    });
    yield put(loadMoreAdminAddons.success({ data: response, hasNextPage: response.length > 0 ? true : false }));
  } catch (error) {
    yield put(loadMoreAdminAddons.failure(undefined));
  }
}

export function* watchLoadMoreAdminAddons() {
  yield takeLatest(getActionType(loadMoreAdminAddons.request), handleLoadMoreAdminAddons);
}
