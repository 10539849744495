import { Title } from '@wiloke/ui';
import TemplateContent from 'containers/Admin/PageBuilder/TemplatesPage/components/Content';
import { FC } from 'react';
import { i18n } from 'translation';
import { Divider, Styles, View } from 'wiloke-react-core';
import { AddSection } from './AddSection/AddSection';

const styles = {
  container: (): Styles => ({
    paddingTop: '40px',
    paddingBottom: '40px',
    minHeight: '100vh',
  }),
  divider: (): Styles => ({
    paddingTop: '40px',
    paddingBottom: '40px',
  }),
  content: (): Styles => ({
    paddingLeft: '40px',
    paddingRight: '40px',
  }),
};

export const AddSectionAndPage: FC = () => {
  return (
    <View backgroundColor="light" css={styles.container}>
      <View container>
        <AddSection />
      </View>
      <View css={styles.divider}>
        <Divider />
      </View>
      <View css={styles.content}>
        <Title title={i18n.t('adminDashboard.page_templates')} titleCss={{ fontSize: '30px' }} />
        <TemplateContent />
      </View>
    </View>
  );
};
