import { addonApiController } from 'services/AddonService';
import { adapterGetAddon } from 'services/AddonService/Adapters/adapterGetAddon';
import { AdminAddon, ProductAddon } from 'types/Addons';
import { getUserInfo } from 'utils/functions/getUserInfo';

export const installClientAddon = async (commandId: string, productAddon: ProductAddon) => {
  const { lastCache } = getUserInfo();
  const response = await addonApiController.atom.publishApi.addons.getAtom({ commandId, cacheVersion: lastCache });
  // const updatedAddon = await addonApiController.product.adminApi.addons.updateProduct({
  //   addon: { commandId: productAddon.commandId, downloadedCount: productAddon.downloadedCount + 1 },
  // });
  const data = adapterGetAddon(response) as AdminAddon;

  return {
    ...data,
    commandId: '',
    parentCommandId: data.commandId,
    body: {
      ...data.body,
      commandId: '',
      parentCommandId: data.commandId,
      downloadedCount: productAddon.downloadedCount,
    },
    downloadedCount: productAddon.downloadedCount,
  };
};
