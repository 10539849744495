import InlineMenu from 'components/InlineMenu';
import NavItemSetting from 'components/NavItemSetting';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSetGlobalThemeJs } from 'store/global/globalThemeJs/slice';
import { useSetGlobalThemeScss } from 'store/global/globalThemeScss/slice';
import { authSelector, themeVersionSelector } from 'store/selectors';
import { View } from 'wiloke-react-core';
import ThemeSettings from './ThemeSettings/ThemeSettings';
import { themeSettings } from './data';
import * as styles from './styles';
import { getThemeVersion } from 'utils/functions/getThemeVersion';
import { useSetPlanRequesting, useSetValidateCouponVisible } from 'containers/Admin/PlanManagement/store/sliceCoupon';
import { validatePlan } from 'utils/functions/validatePlan';
import { PLAN_STATIC_DATA, VEDA_FUNCTION_ENUM } from 'utils/constants/PlanEnum';

export const ThemeSettingsWithSidebar: FC = () => {
  const [activeKey, setActiveKey] = useState(['general']);
  const setGlobalThemeScss = useSetGlobalThemeScss();
  const setGlobalThemeJs = useSetGlobalThemeJs();
  const setValidateCouponVisible = useSetValidateCouponVisible();
  const setPlanRequesting = useSetPlanRequesting();

  const themeVersion = useSelector(themeVersionSelector);
  const { plan, role } = useSelector(authSelector);
  const themeV2 = getThemeVersion(themeVersion) === '>=2.0.0';

  const themeSettingsFilteredByThemeVersion = themeSettings(themeV2);

  const handleChangeKeys = (keys: string[]) => {
    console.log(keys);
    if (
      (keys[0] === 'scss' || keys[0] === 'js') &&
      validatePlan({ userPlan: plan.planHandle, itemPlan: VEDA_FUNCTION_ENUM.theme_page_javascript_css, role }) === 'invalid'
    ) {
      setPlanRequesting({
        handle: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.theme_page_javascript_css].handle,
        type: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.theme_page_javascript_css].type,
        pricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.theme_page_javascript_css].pricePerMonth,
        pricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.theme_page_javascript_css].pricePerYear,
        title: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.theme_page_javascript_css].title,
        originalPricePerMonth: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.theme_page_javascript_css].pricePerMonth,
        originalPricePerYear: PLAN_STATIC_DATA[VEDA_FUNCTION_ENUM.theme_page_javascript_css].pricePerYear,
      });
      setValidateCouponVisible(true);
    } else {
      setActiveKey(keys);
    }
  };

  return (
    <View css={{ display: 'flex', height: '100%' }}>
      <InlineMenu
        onChange={handleChangeKeys}
        defaultItemIds={activeKey}
        width={300}
        containerCss={({ colors }) => ({ borderRight: `1px solid ${colors.gray3}` })}
      >
        {themeSettingsFilteredByThemeVersion.map(item => {
          return (
            <InlineMenu.Item key={item.value} width={300} itemId={item.value}>
              <NavItemSetting
                title={item.label}
                text={item.description}
                iconName={item.icon}
                iconType={item.iconType}
                active={activeKey.includes(item.value)}
                containerCss={styles.navItem}
              />
            </InlineMenu.Item>
          );
        })}
      </InlineMenu>

      <View css={{ width: 'calc(100% - 300px)', padding: '20px 0 20px 20px' }}>
        <ThemeSettings
          keyActive={activeKey[0]}
          onChangeScss={setGlobalThemeScss}
          onChangeJs={setGlobalThemeJs}
          contentInnerCss={{ padding: '0 20px 0 0' }}
        />
      </View>
    </View>
  );
};

export default ThemeSettingsWithSidebar;
