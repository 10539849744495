import { Alert, MyModal } from '@wiloke/ui';
import { DragUploadAntd, DragUploadAntdProps } from 'components/DragUploadAntd';
import UploadPlaceholder from 'components/UploadPlaceholder';
import { Dispatch, SetStateAction, useState } from 'react';
import { handleExtractResultForForceSync } from '../../../../ExtractThemeToFileForSync/utils/handleExtractResultForForceSync';
import { FontAwesome, Space, View } from 'wiloke-react-core';

interface UploadFileProps {
  error: string;
  fileNameState: string;
  fileContentState: ReturnType<typeof handleExtractResultForForceSync> | undefined;
  setError: Dispatch<SetStateAction<UploadFileProps['error']>>;
  setFileNameState: Dispatch<SetStateAction<UploadFileProps['fileNameState']>>;
  setFileContentState: (value: ReturnType<typeof handleExtractResultForForceSync> | undefined) => void;
  status: Status;
}

export const UploadFile = ({ error, fileContentState, fileNameState, setError, setFileContentState, setFileNameState, status }: UploadFileProps) => {
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [nextFileState, setNextFileState] = useState<Pick<UploadFileProps, 'error' | 'fileNameState' | 'fileContentState'> | null>(null);

  const handleUploadFile: DragUploadAntdProps['customRequest'] = ({ file, onSuccess, onError }) => {
    const reader = new FileReader();
    reader.readAsText(file as File);
    reader.onload = event => {
      const result = event.target?.result;
      if (typeof result === 'string') {
        const resultParsed = JSON.parse(result);
        const fileName = (file as File).name;
        try {
          if (status === 'failure') {
            setNextFileState({
              error: '',
              fileContentState: resultParsed,
              fileNameState: fileName,
            });
            setOpenModalConfirm(true);
          } else {
            setFileNameState(fileName);
            setFileContentState(resultParsed);
          }
          onSuccess?.('Success');
        } catch {
          if (status === 'failure') {
            setNextFileState({
              error: 'Sai định dạng. Hãy đảm bảo là file chưa chỉnh sửa gì để rồi dẫn đến lỗi',
              fileContentState: undefined,
              fileNameState: '',
            });
            setOpenModalConfirm(true);
          } else {
            setError('Sai định dạng. Hãy đảm bảo là file chưa chỉnh sửa gì để rồi dẫn đến lỗi');
          }
          onError?.(new Error('Error'));
        }
      }
    };
  };

  const renderModalConfirm = () => {
    return (
      <MyModal
        isVisible={openModalConfirm}
        headerText="Cảnh báo"
        okText="Chính xác"
        cancelText="Không"
        onCancel={() => {
          setOpenModalConfirm(false);
        }}
        onOk={() => {
          setOpenModalConfirm(false);
          setFileContentState(nextFileState?.fileContentState ?? undefined);
          setFileNameState(nextFileState?.fileNameState ?? '');
          setError(nextFileState?.error ?? '');
        }}
      >
        <View>
          Tìm thấy một quá trình đồng bộ bị lỗi trước đó. Hành động upload file mới đồng nghĩa với việc{' '}
          <View tagName="span" color="danger" css={{ textDecoration: 'underline', fontWeight: 500, fontSize: '16px' }}>
            không thể "Thử lại các tiến trình bị lỗi"
          </View>{' '}
          lại quá trình đồng bị lỗi trước đó
        </View>
        <View color="danger" css={{ fontWeight: 500, textAlign: 'center', fontSize: '16px', marginTop: '12px' }}>
          Bạn vẫn muốn upload file?
        </View>
      </MyModal>
    );
  };

  return (
    <View>
      <DragUploadAntd
        maxSize={50}
        disabled={status === 'loading'}
        accept={`.json`}
        showUploadList={false}
        containerStyle={{ background: 'transparent', padding: 0 }}
        contentStyle={{ padding: '10px' }}
        customRequest={handleUploadFile}
        Content={<UploadPlaceholder size="large" text="Click hoặc kéo thả file vào đây để tải file lên" />}
      />
      <Space type="vertical" size={12} />
      {!!fileContentState && (
        <>
          <Alert
            radius={6}
            type="success"
            Icon={<FontAwesome type="far" name="file-import" size={20} />}
            message="Upload thành công"
            backgroundColor="gray1"
            description={fileNameState}
            disabled={status === 'loading'}
            onClose={() => {
              if (status === 'failure') {
                setOpenModalConfirm(true);
              } else if (status !== 'loading') {
                setFileContentState(undefined);
                setFileNameState('');
                setError('');
              }
            }}
          />
        </>
      )}
      {!!error && (
        <Alert
          radius={6}
          type="danger"
          message="Upload lỗi"
          description={error}
          backgroundColor="gray1"
          onClose={() => {
            setError('');
          }}
        />
      )}
      {renderModalConfirm()}
    </View>
  );
};
