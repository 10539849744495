import { clone } from 'ramda';
import { sectionApiController } from 'services/SectionService';
import { DevSection } from 'types/Sections';
import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { ToPartialKeys } from 'utils';

export const createDraftSection = async (section: ToPartialKeys<DevSection, 'commandId'>) => {
  const { role } = getUserInfo();
  const section_ = clone(section);
  delete section_.commandId;
  if (role === 'dev') {
    return sectionApiController.atom.devApi.sections.createDraftOfDev({ section: section_ });
  }
  throw new RoleException();
};
