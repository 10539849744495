import { AdminPageData } from 'containers/Admin/types';
import { Page } from 'types/Page';

export const encodeIdForAllPageSectionsOfJsFile = ({ commandId }: Pick<Page | AdminPageData, 'commandId'>): any => {
  return `jsSections_${commandId}` as const;
};
export const encodeIdForAllPageSectionsOfCssFile = ({ commandId }: Pick<Page | AdminPageData, 'commandId'>): any => {
  return `cssSections_${commandId}` as const;
};
export const encodeIdOfGlobalJsFile = ({ commandId }: Page | AdminPageData): any => {
  return `globalJs_${commandId}` as const;
};
export const encodeIdOfGlobalCssFile = ({ commandId }: Page | AdminPageData): any => {
  return `globalCss_${commandId}` as const;
};
export const encodeIdOfVendorsJsFile = ({ commandId }: Page | AdminPageData): any => {
  return `vendorsJs_${commandId}` as const;
};
export const encodeIdOfVendorsCssFile = ({ commandId }: Page | AdminPageData): any => {
  return `vendorsCss_${commandId}` as const;
};
export const encodeIdOfLiquidFile = ({ commandId, id }: any): any => {
  return commandId ? (`section_${commandId}` as const) : (`section_${id}` as const);
};

export const decodeIdOfLiquidFile = (encodedStr: ReturnType<typeof encodeIdOfLiquidFile>): any => {
  if (typeof encodedStr !== 'string') {
    return encodedStr;
  }
  return encodedStr.replace('section_', '');
};
