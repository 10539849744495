import Box from 'components/FieldBox';
import ImagePlaceholder from 'components/ImagePlaceholder';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Text, View, ViewProps, withStyles } from 'wiloke-react-core';
import * as styles from './styles';

export interface QuickGuideBoxProps {
  title?: string;
  description?: string;
  isReactRouter?: boolean;
  href?: string;
  image?: string;
}

const LinkWithStyle = withStyles<any, any>(Link);

const QuickGuideBox2: FC<QuickGuideBoxProps> = ({ image, title = '', description = '', isReactRouter = false, href = '#' }) => {
  const linkProps: Pick<ViewProps, 'css'> = {
    css: styles.link,
  };

  return (
    <Box css={styles.container} borderWidth={0}>
      {isReactRouter ? <LinkWithStyle {...linkProps} to={href as any} /> : <Text {...linkProps} tagName="a" target="blank" href={href} />}

      <View css={styles.styles2.imageContainer}>{image ? <img src={image} /> : <ImagePlaceholder />}</View>

      <View css={{ marginLeft: '10px' }}>
        <Text numberOfLines={2} color="gray9" fontFamily="secondary" css={styles.title} dangerouslySetInnerHTML={{ __html: title }}></Text>
        <Text numberOfLines={2} size={13} fontFamily="secondary" color="gray6" dangerouslySetInnerHTML={{ __html: description }} />
      </View>
    </Box>
  );
};

export default QuickGuideBox2;
