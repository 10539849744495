import { Dropdown, DropdownProps, MyModal } from '@wiloke/ui';
import { FC, useState } from 'react';
import { i18n } from 'translation';
import { FontAwesome, View } from 'wiloke-react-core';
import * as styles from './styles';

export interface DraftBoxDropdownProps {
  onClickDropdown?: DropdownProps['onClick'];
  isShopifyTheme?: boolean;
  isActive?: boolean;
}

const _data: DropdownProps['data'] = [
  {
    label: i18n.t('general.preview'),
    value: 'preview',
    icon: 'eye',
  },
  {
    label: i18n.t('general.delete'),
    value: 'delete',
    icon: 'trash',
  },
];

export const DraftBoxDropdown: FC<DraftBoxDropdownProps> = ({ onClickDropdown, isShopifyTheme, isActive }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Dropdown
      data={isShopifyTheme || isActive ? _data.slice(0, 1) : _data}
      onClick={val => {
        if (val === 'delete') {
          setVisible(true);
        } else {
          onClickDropdown?.(val);
        }
      }}
    >
      <View css={styles.dropdown}>
        <View>
          <FontAwesome name="ellipsis-h" type="far" color="gray8" />
        </View>
      </View>

      <MyModal
        isVisible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={() => {
          onClickDropdown?.('delete');
          setVisible(false);
        }}
        headerText="Delete Confirm"
      >
        Are you sure you want to delete?
      </MyModal>
    </Dropdown>
  );
};
