import { Title } from '@wiloke/ui';
import ChooseImage from 'components/ChooseImage';
import Field from 'components/Field';
import SeoPreview from 'components/SeoPreview';
import SwitchBeauty from 'components/SwitchBeauty';
import Textarea from 'components/Textarea';
import TextInput from 'components/TextInput';
import { useChangeSettingsDashboardPage } from 'containers/Admin/PageBuilder/DashboardPageSettings';
import withDebounce from 'hocs/withDebounce';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSetGeneralSettingsPage } from 'store/global/generalSettings/slice';
import { generalSettingsSelector, pageDataSelector } from 'store/selectors';
import { i18n } from 'translation';
import { Consts } from 'utils/constants/constants';
import getPageInfo from 'utils/functions/getInfo';
import { Space, Styles, View } from 'wiloke-react-core';

const containerStyles: Styles = {
  className: 'GeneralSettings-container',
  padding: '20px',
};

const MAX_LENGTH_TITLE = 70;
const MAX_LENGTH_DESCRIPTION = 320;

const TextInputDebounce = withDebounce(TextInput, 'value', 'onValueChange');

const GeneralSettings: FC = () => {
  const generalSettings = useSelector(generalSettingsSelector);
  const setGeneralSettings = useSetGeneralSettingsPage();
  const pageData = useSelector(pageDataSelector);
  const shop = getPageInfo('shop');
  const myWebsite = `https://www.${shop}`;
  const disableSeoField = pageData.type !== 'page' && pageData.type !== 'article';
  const changeSettings = useChangeSettingsDashboardPage();
  const [lengthTitle, setLengthTitle] = useState(0);
  const [lengthDes, setLengthDes] = useState(0);

  const getLink = () => {
    return generalSettings.handle || myWebsite;
  };

  const handleChangeTitle = (value: string) => {
    const lengthVal = value.length;
    if (lengthVal <= MAX_LENGTH_TITLE) {
      setLengthTitle(value.length);
      setGeneralSettings({ settings: { metaTitle: value } });
    }
  };

  const handleChangeDescription = (value: string) => {
    const lengthVal = value.length;
    if (lengthVal <= MAX_LENGTH_DESCRIPTION) {
      setLengthDes(value.length);
      setGeneralSettings({ settings: { metaDescription: value } });
    }
  };

  return (
    <View css={containerStyles}>
      <View css={{ padding: '20px' }} radius={6} backgroundColor="gray1">
        <View row>
          <View columns={[5]}>
            <Space size={10} />
            <Title
              title={i18n.t('builderPage.page_settings.general_settings.general.title')}
              text={i18n.t('builderPage.page_settings.general_settings.general.text')}
              titleCss={{ fontSize: '20px' }}
            />
          </View>
          <View columns={[7]}>
            <Field label={i18n.t('builderPage.page_settings.general_settings.general.name')}>
              <TextInputDebounce
                value={generalSettings.label}
                block
                sizeInput="medium"
                maxLength={Consts.MAX_LENGTH_PAGE}
                onValueChange={value => {
                  setGeneralSettings({ settings: { label: value } });
                }}
              />
            </Field>
            <Field
              label={i18n.t('builderPage.page_settings.general_settings.general.enable_header_footer')}
              description={
                <>
                  <View>
                    <View tagName="strong" color="danger">
                      Turn off
                    </View>{' '}
                    this feature to use the Veda Header Footer
                  </View>
                  <View
                    target="_blank"
                    tagName="a"
                    href="https://help.myshopkit.app/en/docs/veda-landing-page-builder/veda-page/header-footer-building/"
                  >
                    Click to learn how this feature works
                  </View>
                </>
              }
            >
              <SwitchBeauty
                checked={generalSettings.headerFooterEnabled}
                radius={6}
                borderColor="gray3"
                borderWidth={1}
                enableText={i18n.t('general.enable')}
                disableText={i18n.t('general.disable')}
                onValueChange={value => {
                  setGeneralSettings({ settings: { headerFooterEnabled: value } });
                }}
              />
            </Field>
          </View>
        </View>
      </View>
      <Space size={10} />

      {!disableSeoField && (
        <>
          <View css={{ padding: '20px' }} radius={6} backgroundColor="gray1">
            <View row>
              <View columns={[5]}>
                <Space size={10} />
                <Title
                  title={i18n.t('builderPage.page_settings.general_settings.seo.title')}
                  text={i18n.t('builderPage.page_settings.general_settings.seo.text')}
                  titleCss={{ fontSize: '20px' }}
                />
              </View>
              <View columns={[7]}>
                <Field label={i18n.t('general.preview')}>
                  <SeoPreview
                    link={getLink()}
                    title={generalSettings.metaTitle || i18n.t('builderPage.page_settings.general_settings.meta_title_placeholder')}
                    description={generalSettings.metaDescription || i18n.t('builderPage.page_settings.general_settings.meta_description_placeholder')}
                  />
                </Field>
                <Field label={i18n.t('builderPage.page_settings.general_settings.seo.url_slug.title')}>
                  <TextInputDebounce
                    value={generalSettings.handle}
                    block
                    sizeInput="medium"
                    onValueChange={value => {
                      setGeneralSettings({ settings: { handle: value } });
                      changeSettings({ isChangingHandle: true });
                    }}
                  />
                </Field>
                <Field
                  label={i18n.t('builderPage.page_settings.general_settings.seo.meta_title.title')}
                  note={`${lengthTitle} of 70 characters used`}
                >
                  <TextInput
                    disabled={disableSeoField}
                    value={generalSettings.metaTitle}
                    block
                    sizeInput="medium"
                    onValueChange={handleChangeTitle}
                  />
                </Field>
                <Field
                  label={i18n.t('builderPage.page_settings.general_settings.seo.meta_description.title')}
                  note={`${lengthDes} of 320 characters used`}
                >
                  <Textarea disabled={disableSeoField} value={generalSettings.metaDescription} onChangeText={handleChangeDescription} />
                </Field>
              </View>
            </View>
          </View>
          <Space size={10} />
          <View css={{ padding: '20px' }} radius={6} backgroundColor="gray1">
            <View row>
              <View columns={[5]}>
                <Space size={10} />
                <Title title={i18n.t('builderPage.page_settings.general_settings.social_share.title')} text={''} titleCss={{ fontSize: '20px' }} />
              </View>
              <View columns={[7]}>
                <Field label={i18n.t('general.preview')}>
                  <ChooseImage
                    value={{ src: generalSettings.socialShareImage, width: 0, height: 0 }}
                    mode="popup"
                    onChange={value => {
                      setGeneralSettings({ settings: { socialShareImage: value.src } });
                    }}
                  />
                </Field>
              </View>
            </View>
          </View>
        </>
      )}
    </View>
  );
};

export default GeneralSettings;
