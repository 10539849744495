import { RoleException } from 'utils/constants/constants';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { themeApis } from '../apis';

interface GetPageOfThemeAtom {
  commandId: string;
}

export const getPageOfThemeAtom = async ({ commandId }: GetPageOfThemeAtom) => {
  const { role, lastCache } = getUserInfo();
  if (role === 'admin') {
    const response = await themeApis.atom.adminApi.page.getPagesOfAtom({ commandIds: commandId, cacheVersion: lastCache });
    return response.info[0];
  }
  if (role === 'dev' || role === 'support') {
    const response = await themeApis.atom.devApi.page.getPagesOfAtom({ commandIds: commandId, cacheVersion: lastCache });
    return response.info[0];
  }
  throw new RoleException();
};
