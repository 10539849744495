import { notification } from 'antd';
import { call, put, retry, takeLatest, SagaReturnType, select, all } from 'redux-saga/effects';
import { createThemeAtom } from 'services/ThemeService/Logic/createThemeAtom';
import { deletePageOfThemeAtom } from 'services/ThemeService/Logic/deletePageOfThemeAtom';
import { socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector } from 'store/selectors';
import { i18n } from 'translation';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import { getActionType } from 'wiloke-react-core/utils';
import { createThemeAtom as actionCreateThemeAtom } from '../actions/actionThemesAtom';
import { setModalCreateThemeAtom } from '../reducers/sliceThemesAtom';
import { handleClonePageAtomServiceToThemeAtomService } from './utils/handleClonePageAtomServiceToThemeAtomService';

function* handleCreateThemeAtom({ payload }: ReturnType<typeof actionCreateThemeAtom.request>) {
  const { label, pageCommandIds, featuredImage, screenshot1, themeSettings, globalJs, globalScss, vendors, onFulfill } = payload;
  const { eventId }: SagaReturnType<typeof socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector> = yield select(
    socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector,
  );

  if (!eventId) {
    yield put(actionCreateThemeAtom.failure(undefined));
    onFulfill();
    notification.error({ message: i18n.t('general.system_error'), description: 'Không có "eventId"' });
  } else {
    const newCommandIds: SagaReturnType<typeof handleClonePageAtomServiceToThemeAtomService> = yield call(
      handleClonePageAtomServiceToThemeAtomService,
      {
        eventId,
        pageAtomCommandIds: pageCommandIds,
        entityType: 'Atom',
      },
    );
    try {
      const response: Awaited<ReturnType<typeof createThemeAtom>> = yield retry(3, 1000, createThemeAtom, {
        themeSettings,
        featuredImage,
        screenshot1,
        globalJs,
        globalScss,
        label,
        pageCommandIds: newCommandIds,
        vendors,
      });

      yield put(actionCreateThemeAtom.success({ data: response }));
      yield put(setModalCreateThemeAtom(false));

      notifyAxiosHandler.handleSuccess(i18n.t('general.create', { text: i18n.t('general.successfully') }));
    } catch (error) {
      const error_ = error as Error;
      notifyAxiosHandler.handleError(error_);
      yield put(actionCreateThemeAtom.failure(undefined));
      try {
        yield all(
          newCommandIds.map(pageCommandId => {
            return retry(3, 1000, deletePageOfThemeAtom, { commandId: pageCommandId });
          }),
        );
      } catch {}
    } finally {
      onFulfill();
    }
  }
}

export function* watchCreateThemeAtom() {
  yield takeLatest(getActionType(actionCreateThemeAtom.request), handleCreateThemeAtom);
}
