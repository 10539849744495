import { set } from 'lodash';
import { clone, omit, path } from 'ramda';
import { PREFIX_BLOG_HANDLE, PREFIX_COLLECTION_HANDLE, PREFIX_PRODUCT_HANDLE } from 'store/reducers/liquid/randomPlaceholderLiquidObject';
import { ComponentData } from 'types/Page';
import { SettingBlogPicker, SettingCollectionPicker, SettingSingleProductPicker } from 'types/Schema';
import { PageSection } from 'types/Sections';
import { Consts } from 'utils/constants/constants';
import { getBuilderPageReduxStore } from 'utils/getParentStore';
import { objectPathBracketNotation } from 'utils/objectPathBracketNotation';
import { Blog, Collection, Product } from './objects';
import { SettingDragMenu, SettingDragMenuChildren } from 'containers/BuilderPage/components/DraggableMenu';
import { isIncludeTranslationSignal } from './utils/isFieldTranslation';
import { t } from './filters';

type Scope = Record<string, any>;
interface HandleGetTwigScope {
  /**
   * Xuất kết quả cuối cùng -> Xoá shopify scope + Giữ nguyên value của navigation
      ==> Khi sync lên shopify mặc dù bị compile sang html nhưng nó sẽ tự đúng về code liquid
        - Ví dụ:
          Scope: item.label = "Label: {{ 'veda.aaaa' | t }}"
          Code liquid: <div>{item.label}</div> ---Compile---> <div>Label: {{ 'veda.aaaa' | t }}</div>
      ===> Chỉ cần xử lý sao cho tại veda builder hiển thị đúng là ok

    * "Preview tại veda -> Cần shopify scope + thay thế value của field navigation"
      ===> Giải pháp để hiển thị đúng trên veda builder cho field navigation là regex
        ===> Regex sao cho OK là được
   */
  variant:
    | 'Xuất kết quả cuối cùng -> Xoá shopify scope + Giữ nguyên value của navigation'
    | 'Preview tại veda -> Cần shopify scope + thay thế value của field navigation';
  section: PageSection;
  sectionSettings: ComponentData['settings'];
  builderMode: boolean;
  addonScope?: Scope;
}

const ALL_COLLECTIONS_VARIABLE = 'collections';
const ALL_PRODUCTS_VARIABLE = 'all_products';
const ALL_BLOGS_VARIABLE = 'blogs';

const handleGetShopifyScope = ({ section, sectionSettings, variant }: Pick<HandleGetTwigScope, 'section' | 'sectionSettings' | 'variant'>): Scope => {
  if (variant === 'Xuất kết quả cuối cùng -> Xoá shopify scope + Giữ nguyên value của navigation') {
    return {};
  }
  const state = getBuilderPageReduxStore().getState();
  const liquidVariables = state.liquidVariables.data;
  const variables = sectionSettings.filter(Boolean).reduce<string[]>((variables, setting) => {
    if (!!setting?.children) {
      if (setting.type === 'collectionPicker') {
        const _children = setting.children as Exclude<SettingCollectionPicker['children'], undefined>;
        return variables.concat(`${ALL_COLLECTIONS_VARIABLE}['${_children.handle}']`);
      }
      if (setting.type === 'productPicker') {
        const _children = setting.children as Exclude<SettingSingleProductPicker['children'], undefined>;
        return variables.concat(`${ALL_PRODUCTS_VARIABLE}['${_children.handle}']`);
      }
      if (setting.type === 'blogPicker') {
        const _children = setting.children as Exclude<SettingBlogPicker['children'], undefined>;
        return variables.concat(`${ALL_BLOGS_VARIABLE}['${_children.handle}']`);
      }
    }
    if (setting?.type === 'object') {
      if (!!setting?.children) {
        setting.children.forEach(item => {
          if (!!item) {
            const { children, type } = item;
            if (type === 'collectionPicker') {
              const _children = children as Exclude<SettingCollectionPicker['children'], undefined>;
              variables.push(`${ALL_COLLECTIONS_VARIABLE}['${_children.handle}']`);
            }
            if (type === 'productPicker') {
              const _children = children as Exclude<SettingSingleProductPicker['children'], undefined>;
              variables.push(`${ALL_PRODUCTS_VARIABLE}['${_children.handle}']`);
            }
            if (type === 'blogPicker') {
              const _children = children as Exclude<SettingBlogPicker['children'], undefined>;
              variables.push(`${ALL_BLOGS_VARIABLE}['${_children.handle}']`);
            }
          }
        });
      }
    }
    if (setting?.type === 'array') {
      if (!!setting?.children) {
        setting?.children?.forEach(arrayItem => {
          if (!!arrayItem?.children && Array.isArray(arrayItem?.children) && arrayItem?.children?.length > 0) {
            (arrayItem?.children ?? []).forEach(item => {
              if (!!item) {
                const { children, type } = item;
                if (type === 'collectionPicker') {
                  const _children = children as Exclude<SettingCollectionPicker['children'], undefined>;
                  variables.push(`${ALL_COLLECTIONS_VARIABLE}['${_children.handle}']`);
                }
                if (type === 'productPicker') {
                  const _children = children as Exclude<SettingSingleProductPicker['children'], undefined>;
                  variables.push(`${ALL_PRODUCTS_VARIABLE}['${_children.handle}']`);
                }
                if (type === 'blogPicker') {
                  const _children = children as Exclude<SettingBlogPicker['children'], undefined>;
                  variables.push(`${ALL_BLOGS_VARIABLE}['${_children.handle}']`);
                }
              }
            });
          }
        });
      }
    }
    return variables;
  }, []);
  const defaultScopes = {
    // K lấy luôn liquidVariables vì nó sẽ bị thừa ra những cái không cần thiết và gây nặng
    ...omit(
      [
        'themeCss',
        'translations',
        'weight_with_unit',
        'all_products',
        'collections',
        'blogs',
        'articles',
        // 'product',
        // 'collection',
        // 'blog',
        // 'gift_card',
        // 'order',
      ],
      liquidVariables,
    ),
    // product: pageType === 'product' ? liquidVariables.product : null,
    // collection: pageType === 'collection' ? liquidVariables.collection : null,
    // blog: pageType === 'article' ? liquidVariables.blog : null,
    // gift_card: pageType === 'giftCard' ? liquidVariables.gift_card : null,
    // order: pageType === 'order' ? liquidVariables.order : null,

    all_products: Object.keys(liquidVariables.all_products)
      .filter(productHandle => productHandle.includes(PREFIX_PRODUCT_HANDLE))
      .map(productHandle => liquidVariables.all_products[productHandle]),
    collections: Object.keys(liquidVariables.collections)
      .filter(collectionHandle => collectionHandle.includes(PREFIX_COLLECTION_HANDLE))
      .map(collectionHandle => liquidVariables.collections[collectionHandle]),
    blogs: Object.keys(liquidVariables.blogs)
      .filter(blogHandle => blogHandle.includes(PREFIX_BLOG_HANDLE))
      .map(blogHandle => liquidVariables.blogs[blogHandle]),
    section: {
      blocks: [],
      settings: {},
      id: `${Consts.AppName}-${section.id}`,
    },
  };
  const scope = variables?.reduce((res, variable) => {
    const liquidVariableData = path(objectPathBracketNotation(variable), liquidVariables);
    if (liquidVariableData && variable.includes(ALL_COLLECTIONS_VARIABLE)) {
      res.collections.push(liquidVariableData as Collection);
      set(res, objectPathBracketNotation(variable), liquidVariableData);
      return res;
    }
    if (liquidVariableData && variable.includes(ALL_PRODUCTS_VARIABLE)) {
      res.all_products.push(liquidVariableData as Product);
      set(res, objectPathBracketNotation(variable), liquidVariableData);
      return res;
    }
    if (liquidVariableData && variable.includes(ALL_BLOGS_VARIABLE)) {
      res.blogs.push(liquidVariableData as Blog);
      set(res, objectPathBracketNotation(variable), liquidVariableData);
      return res;
    }
    set(res, objectPathBracketNotation(variable), liquidVariableData);
    return res;
  }, defaultScopes);
  return { ...scope };
};

const NavigationItemTranslationCaching = new Map<string, string>();
export const handleGetSchemaScope = ({
  sectionSettings = [],
  variant,
}: Pick<HandleGetTwigScope, 'sectionSettings'> & {
  variant?: HandleGetTwigScope['variant'];
}): Scope => {
  return sectionSettings?.filter(Boolean).reduce<Record<string, any>>((obj, item) => {
    if (item?.type === 'object') {
      return {
        ...obj,
        [item.name]: handleGetSchemaScope({ variant, sectionSettings: item.children }),
      };
    }
    if (item?.type === 'array') {
      return {
        ...obj,
        [item.name]: item?.children?.filter(Boolean).map(item => {
          return handleGetSchemaScope({
            variant,
            sectionSettings: [
              ...item.children,
              {
                children: item.id,
                type: 'text',
                label: 'Id',
                summary: '',
                name: 'id',
                id: item.id,
                disable: false,
                ___SIGNAL___: -Number.MAX_SAFE_INTEGER,
              },
            ],
          });
        }),
      };
    }
    if (variant === 'Preview tại veda -> Cần shopify scope + thay thế value của field navigation' && item?.type === 'navigation') {
      const item_ = clone(item);
      const handleLabelProperty = (item: SettingDragMenu | SettingDragMenuChildren) => {
        if (isIncludeTranslationSignal(item.label)) {
          const valueInCache = NavigationItemTranslationCaching.get(item.label);
          if (valueInCache) {
            item.label = valueInCache;
          } else {
            const result = window.Twig.twig({
              data: t(item.label),
              rethrow: true,
            });
            const compiled = result.render({}, undefined);
            NavigationItemTranslationCaching.set(item.label, compiled);
            item.label = compiled;
          }
          item.children?.forEach(handleLabelProperty);
        }
      };
      item_.children.forEach(handleLabelProperty);
      return {
        ...obj,
        [item.name]: item_.children,
      };
    }
    return {
      ...obj,
      [item.name]: item.children,
    };
  }, {});
};

const handleGetUniqIdScope = ({ section }: Pick<HandleGetTwigScope, 'section'>): Scope => {
  return {
    uniqueId: section.id,
  };
};

const handleGetBuilderModeScope = ({ builderMode }: Pick<HandleGetTwigScope, 'builderMode'>): Scope => {
  return {
    builderMode,
  };
};

export const handleGetTwigScope = ({ addonScope = {}, ...params }: HandleGetTwigScope): Scope => {
  return {
    ...handleGetShopifyScope(params),
    ...handleGetUniqIdScope(params),
    ...handleGetSchemaScope(params),
    ...handleGetBuilderModeScope(params),
    ...addonScope,
  };
};
