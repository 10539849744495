export const publish_shopify = {
  limit_liquid_file_size: 'Kích thước tối đa của file liquid là %% size %%',
  error_in_code: 'Lỗi trong code',
  error_unknown: {
    message: 'Có một lỗi xảy ra trong quá tình đồng bộ',
    description: 'Hãy đảm bảo code của bạn đã tuân theo những quy tắc đã được đề ra',
  },
  sync_error: 'Đồng bộ lỗi',
  sync_success: 'Đồng bộ thành công',
  publishing: 'Đang lưu',
  init_sync: 'Đang khởi tạo quá trình',
  init_sync_error: {
    message: 'Quá trình khởi tạo bị lỗi',
    description: 'Có vấn đề với kết nối mạng, vui lòng thực hiện lại hành động của bạn ',
  },
  syncing: 'Đang đồng bộ %% text %%',
  live_preview: 'Xem trước trực tiếp',
  continue_editing: 'Tiếp tục',
  socket_timeout: 'Socket quá hạn',
  sync_something_error: 'Đồng bộ %% text %% gặp lỗi',
  theme_settings_or_atomic_css: 'Cài đặt của theme hoặc atomic css',
  translation: 'Dịch',
  migrate: 'Migrate %% text %%',
  text_to_shopify: '%% text %% lên shopify',
  sync_page_message: {
    step: 'Bước %% text %%',
    init: 'Đang khởi tạo quá trình...',
    saving: 'Đang lưu %% text %%...',
    syncing_to_shopify: 'Đang đồng bộ %% text %% đến shopify...',
  },
  page_not_sync_yet: 'Trang này chưa được đồng bộ hóa với shopify',
  process_used: 'Một quá trình đồng bộ khác đang được thực thi. Vui lòng đợi cho đến khi tất cả được hoàn thành',
  syncing_not_response: 'Đồng bộ hóa không phản hồi',
  syncing_may_stuck: 'Đồng bộ hóa có thể bị treo',
  contact_for_solution: `Vui lòng liên hệ hỗ trợ để nhận giải đáp`,
  wait_for_response: 'Chờ Veda phản hồi',
  get_help: 'Nhận trợ giúp',
  limit_theme: 'Hạn chế theme',
  limit_theme_desc: 'Đã đạt đến giới hạn chủ đề, cửa hàng của bạn đã sử dụng 20 chủ đề. Vui lòng chọn một chủ đề để xóa và thực hiện tạo lại chủ đề.',
  section_sync_error: 'Section sync gặp lỗi.',
} as const;
