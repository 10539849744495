import { getJs, optimizeSectionJs } from 'generate/utils/generateHelpers';
import { File, ThemeGeneralSettings } from 'types/Result';
import { PageSection } from 'types/Sections';
import { getPreloader } from 'utils/functions/getPreloader';

interface GetAllJsOfTheme_Dùng_OnlyThemeSettings {
  globalJs: string;
  themeGeneralSettings: ThemeGeneralSettings;
  variant: '1 phần theme - bao gồm theme settings';
}
interface GetAllJsOfTheme_AllPropertiesTheme {
  globalJs: string;
  themeGeneralSettings: ThemeGeneralSettings;
  headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: PageSection[];
  footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections: PageSection[];
  variant: 'Toàn bộ theme - bao gồm header, footer, theme settings';
}

export const SIGNAL_GLOBAL_JS_FILE = '//startAssets @veda_id:globalJs @veda_name:globalJs @veda_type:js @veda_content:';
export const SIGNAL_PRELOADER_JS_FILE = '//startAssets @veda_id:preloaderJs @veda_name:preloader @veda_position:footer @veda_type:js @veda_content:';

export const getAllJsOfTheme = (
  params: GetAllJsOfTheme_Dùng_OnlyThemeSettings | GetAllJsOfTheme_AllPropertiesTheme,
): [File, File] | [File, File, File, File] => {
  const { variant, globalJs, themeGeneralSettings } = params;
  const { js: preloaderJs } = getPreloader(themeGeneralSettings);

  const globalJsFile: File = {
    content: `
      ${SIGNAL_GLOBAL_JS_FILE}
      ${[globalJs].join('\n')}
    `,
    type: 'globalJs của theme - bao gồm globalJs của theme',
    name: 'globalJs',
    id: 'globalJs',
    section: undefined,
  };

  const preloaderJsFile: File = {
    content: `
      ${SIGNAL_PRELOADER_JS_FILE}
      ${[preloaderJs].join('\n')}
    `,
    type: 'js của preloader',
    id: 'preloaderJs',
    name: 'preloaderJs',
    section: undefined,
  };

  if (variant === '1 phần theme - bao gồm theme settings') {
    return [globalJsFile, preloaderJsFile];
  }
  if (variant === 'Toàn bộ theme - bao gồm header, footer, theme settings') {
    const {
      footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
      headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections,
    } = params;
    const headerSectionsJsArr = headerSectionsEnable_notIncludeAddonSections_includeMegamenuSections.reduce<string[]>((res, item) => {
      if (item.enable) {
        return res.concat(getJs(item.id, item.data.js ?? ''));
      }
      return res;
    }, []);
    const footerSectionsJsArr = footerSectionsEnable_notIncludeAddonSections_includeMegamenuSections.reduce<string[]>((res, item) => {
      if (item.enable) {
        return res.concat(getJs(item.id, item.data.js ?? ''));
      }
      return res;
    }, []);

    const headerSectionsJsArrOptimized = optimizeSectionJs(headerSectionsJsArr);
    const footerSectionsJsArrOptimized = optimizeSectionJs(footerSectionsJsArr);

    return [
      globalJsFile,
      preloaderJsFile,
      {
        content: headerSectionsJsArrOptimized,
        type: 'js của các section header',
        name: 'jsHeader',
        id: 'jsHeader',
        section: undefined,
      },
      {
        content: footerSectionsJsArrOptimized,
        type: 'js của các section footer',
        name: 'jsFooter',
        id: 'jsFooter',
        section: undefined,
      },
    ];
  }
  throw new Error('getAllJsOfTheme -> Code theem');
};
