import { PartnerItem, VedaBlogDTO } from 'services/RecommednApps/types';
import { ActionTypes, createSlice, handleAction } from 'wiloke-react-core/utils';
import { getRecommendApps, getVedaBlogs } from './actions';

type ExtraActions = ActionTypes<typeof getRecommendApps | typeof getVedaBlogs>;

type Actions = any;

interface State {
  data: PartnerItem[];
  getStatus: Status;

  blogs: VedaBlogDTO[];
  getBlogStatus: Status;
}

export const sliceRecommendApps = createSlice<State, Actions, ExtraActions>({
  name: 'Dashboard',
  initialState: {
    data: [],
    getStatus: 'idle',

    blogs: [],
    getBlogStatus: 'idle',
  },
  reducers: [],
  extraReducers: [
    handleAction('@Dashboard/getRecommendApps/request', ({ state }) => {
      state.getStatus = state.data.length > 0 ? 'success' : 'loading';
    }),
    handleAction('@Dashboard/getRecommendApps/success', ({ state, action }) => {
      state.getStatus = 'success';
      state.data = action.payload.data;
    }),
    handleAction('@Dashboard/getRecommendApps/failure', ({ state }) => {
      state.getStatus = 'failure';
    }),
    //
    handleAction('@Dashboard/getVedaBlogs/request', ({ state }) => {
      state.getBlogStatus = 'loading';
    }),
    handleAction('@Dashboard/getVedaBlogs/success', ({ state, action }) => {
      state.getBlogStatus = 'success';
      state.blogs = action.payload.data;
    }),
    handleAction('@Dashboard/getVedaBlogs/failure', ({ state }) => {
      state.getBlogStatus = 'failure';
    }),
  ],
});

export const recommendAppsSelector = (state: AppState) => state.adminDashboard.recommendApps;
