export const general = {
  settings: 'Paramètres %% text %%',
  setting: 'Paramètre %% text %%',
  status: 'Statut',
  enable: 'Activer %% text %%',
  disable: 'Désactiver %% text %%',
  title: 'Titre',
  text: 'Texte',
  font_size: 'Taille de police',
  font_family: 'Police de caractères',
  line_height: 'Hauteur de ligne',
  color: 'Couleur',
  colors: 'Couleurs',
  variant: 'Variante',
  variable: '%% text %% variable',
  variables: '%% text %% variables',
  email: 'Email',
  name: 'Nom',
  button: 'Bouton',
  placeholder: 'Placeholder',
  subscribe: "S'abonner",
  coupon: 'Coupon',
  discount_code: 'Code de réduction',
  discount_placeholder: 'Emplacement pour le code de réduction',
  discount_code_placeholder: 'Emplacement pour le code de réduction',
  background: 'Arrière-plan',
  background_image: `Image d'arrière-plan`,
  drag: 'Faire glisser',
  edit: 'Modifier %% text %%',
  create: 'Créer %% text %%',
  duplicate: 'Dupliquer',
  remove: 'Supprimer',
  reset: 'Réinitialiser',
  slice: 'Trancher',
  slices: 'Tranches',
  form: 'Formulaire',
  add: 'Ajouter %% text %%',
  element: 'Élément',
  content: 'Contenu',
  image: 'Image',
  divider: 'Séparateur',
  social_follows: 'Partager sur les réseaux sociaux',
  label: 'Étiquette',
  win_text: 'Texte de victoire',
  win_ratio: 'Taux de victoire',
  cancel: 'Annuler',
  save: 'Enregistrer %% text %%',
  saving: 'Enregistrement en cours %% text %%',
  saved: 'Enregistré',
  immediately: 'Afficher immédiatement',
  seconds: 'Secondes',
  delay: 'Délai',
  all: 'Tout',
  custommers: 'Clients',
  animate: 'Animer',
  animation: 'Animation',
  animation_type: `Type d'animation`,
  seconds_delay: 'Délai de %% seconds %% secondes',
  day: '%% day %% jour',
  days: '%% days %% jours',
  close: 'Fermer',
  save_campaign: 'Enregistrer la campagne',
  goal: 'Objectif',
  layout: 'Disposition',
  template: 'Modèle',
  templates: 'Modèles',
  editor: 'Éditeur',
  design: 'Design',
  target: 'Cible',
  conversion: 'Conversion',
  full_screen: 'Plein écran',
  full_width: 'Pleine largeur',
  desktop: 'Ordinateur de bureau',
  tablet: 'Tablette',
  mobile: 'Mobile',
  size: 'Taille',
  paragraph: 'Paragraphe',
  heading: 'Titre',
  socials: 'Réseaux sociaux',
  social: 'Réseau social',
  background_color: 'Couleur de fond',
  text_color: 'Couleur du texte',
  action: 'Action',
  space: 'Espace',
  equal_first_value: 'Définir toutes les valeurs égales à la première valeur',
  width: 'Largeur',
  height: 'Hauteur',
  align: 'Aligner',
  email_placeholder: `Placeholder de l'email`,
  name_placeholder: 'Placeholder du nom',
  button_color: 'Couleur du bouton',
  badge: 'Badge',
  more: 'En savoir plus',
  pattern: 'Motif',
  my_photos: 'Mes photos',
  placement: 'Placement',
  top: 'Haut',
  right: 'Droite',
  bottom: 'Bas',
  left: 'Gauche',
  center: 'Centre',
  link: 'Lien',
  title_color: 'Couleur du titre',
  sub_message_color: 'Couleur du sous-message',
  close_button: 'Bouton Fermer',
  vertical: 'Vertical',
  horizontal: 'Horizontal',
  show_brand: 'Afficher la marque %% type %%',
  gdpr: 'Ajouter un champ de consentement GDPR',
  gdpr_message: 'Message de conformité GDPR',
  gdpr_message_color: 'Couleur du message de conformité GDPR',
  hero_image: `Image d'en-tête`,
  image_aspect_ratio: `Ratio d'aspect de l'image`,
  desktop_aspect_ratio: `Ratio d'aspect sur ordinateur de bureau`,
  mobile_aspect_ratio: `Ratio d'aspect sur mobile`,
  choose_image: 'Choisir une image',
  min_width: 'Largeur minimale',
  graphic_image: 'Image graphique',
  display: 'Affichage',
  image_placement: `Placement de l'image`,
  appearance: 'Apparence',
  previous: 'Précédent',
  preview: 'Aperçu %% text %%',
  prev: 'Préc.',
  next: 'Suiv.',
  back: 'Retour',
  continue: 'Continuer',
  done: 'Terminé',
  illustrations: 'Illustrations',
  arrow_color: 'Couleur de la flèche',
  backdrop_color: `Couleur de l'arrière-plan`,
  skip: 'Passer',
  invalid_email: 'Adresse e-mail invalide',
  enter_email: 'Veuillez entrer votre adresse e-mail',
  create_discount_code: 'Cliquez ici pour créer un code de réduction',
  discount_expired: 'Votre code de réduction a expiré',
  prev_template: 'Votre modèle précédent enregistré',
  undo: 'Annuler',
  redo: 'Rétablir',
  guided_tours: `Ouvrir les visites guidées dans l'application`,
  clear: 'Effacer',
  clear_all: 'Effacer tout %% text %%',
  missing_information: 'Informations manquantes',
  go_to_setting: 'Aller aux paramètres',
  discount_alert: `Vous n'avez pas saisi de code de réduction. Veuillez vous rendre dans Paramètres > Code de réduction`,
  wheel_alert: `Vous n'avez pas modifié les tranches de la roue. Veuillez vous rendre dans Design > Paramètres des tranches de la roue`,
  countdown: 'Compte à rebours',
  expiration_date: `Date d'expiration`,
  sticker: 'Autocollant',
  products: 'Produits',
  add_products: 'Ajouter des produits',
  ok: 'OK',
  load_more: 'Charger plus',
  search_products: 'Rechercher des produits',
  light_colors: 'Couleurs claires',
  dark_colors: 'Couleurs sombres',
  rgb_colors: 'Couleurs RVB',
  fonts: 'Polices',
  font: 'Police',
  up: 'Haut',
  down: 'Bas',
  add_section_to_top: 'Ajouter une section en haut',
  add_section_to_bottom: 'Ajouter une section en bas',
  item: 'Élément',
  section: 'Section %% text %%',
  sections: 'Sections',
  show: 'Afficher',
  hide: 'Masquer',
  theme: 'Thème %% text %%',
  page: 'Page %% text %%',
  value: 'Valeur %% text %%',
  type: 'Type',
  block: 'Bloc',
  blocks: 'Blocs',
  children: 'Enfants',
  field: 'Champ %% text %%',
  fields: 'Champs %% text %%',
  open_close_type: `Type d'ouverture/fermeture`,
  default: 'Par défaut',
  option: 'Option',
  options: 'Options',
  copy: 'Copier %% text %%',
  copied: 'Copié %% text %%',
  paste: 'Coller %% text %%',
  schema: 'Schéma',
  click_to: 'Cliquez pour %% text %%',
  export: 'Exporter',
  import: 'Importer',
  delete: 'Supprimer %% text %%',
  choose: 'Choisir %% text %%',
  enter_your_image: 'Entrez votre image',
  download: 'Télécharger %% text %%',
  upload: 'Télécharger %% text %%',
  upload_a_file_or_drag_drop: 'Téléchargez un fichier ou faites glisser et déposez un fichier ici',
  invalid_field: 'Champ %% text %% invalide',
  padding: 'Rembourrage',
  margin: 'Marge',
  border: 'Bordure',
  style: 'Style %% text %%',
  typography: 'Typographie',
  radius: 'Rayon',
  transition: 'Transition',
  duration: 'Durée',
  timing_function: 'Fonction de synchronisation',
  rename: 'Renommer',
  save_to_my_section: 'Enregistrer dans ma section',
  save_changes: 'Enregistrer les modifications',
  create_button: 'Créer un nouveau %% text %%',
  presets: 'Préréglages %% text %%',
  change_section: 'Changer de section',
  reorder: 'Réorganiser',
  select_preloader: 'Sélectionner un préchargeur',
  test: 'Tester',
  addons: 'Extensions',
  change: 'Changer %% text %%',
  position: 'Position',
  limit_add_addons: "Cet addon ne peut être installé qu'une seule fois",
  before: 'Avant',
  after: 'Après',
  header: 'En-tête',
  main: 'Principal',
  footer: 'Pied de page',
  move_to: 'Déplacer vers %% text %%',
  publish: 'Publier %% text %%',
  update: 'Mettre à jour %% text %%',
  changelog: 'Journal des modifications',
  code: 'Code',
  app_settings: `Paramètres de l'application`,
  documents: 'Documents',
  support: 'Support',
  install: 'Installer',
  prefix_page: ' Page %% prefix %%',
  select_template: 'Sélectionner un modèle %% text %%',
  create_description: 'Conçu pour rendre %% text %% plus professionnel et plus facile à utiliser.',
  warning: 'Avertissement',
  advanced: 'Avancé',
  language: 'Langue',
  header_of_theme: "En-tête du thème (Vous ne pouvez modifier l'en-tête que lors de la construction du thème)",
  footer_of_theme: 'Pied de page du thème (Vous ne pouvez modifier le pied de page que lors de la construction du thème)',
  megamenu: 'Méga menu',
  opacity: 'Opacité',
  auto_change_color_for_dark_theme: 'Changer automatiquement la couleur pour le thème sombre',
  no_color: 'Pas de couleur',
  add_or_edit_global_color: 'Ajouter ou modifier une couleur globale',
  color_settings_description: 'Vous pouvez personnaliser les paramètres suivants et ils seront appliqués à la couleur que vous avez sélectionnée',
  version: 'Version',
  description: 'Description',
  createNewSection: 'Créer une nouvelle section',
  repeat: 'Répéter',
  once: 'Une fois',
  scroll_down_to_run: 'Faites défiler vers le bas pour exécuter',
  createNewAddons: 'Créer une nouvelle extension',
  category: 'Catégorie',
  approve: 'Approuver %% text %%',
  reject: 'Rejeter %% text %%',
  hotfix: 'Correctif urgent %% text %%',
  rejected: 'Rejeté',
  edit_info: 'Modifier les informations',
  lack_of_params: 'Paramètres manquants\nAttendu: %% expect %%\nRéel: %% actual %%',
  copy_code: 'Copier le code',
  copied_code: 'Code copié',
  addon_pick_position: `Sélectionnez la position de l'extension`,
  remove_from_page: 'Supprimer de la page',
  remove_from_sections: 'Supprimer des sections',
  not_enough_width: `Largeur d'écran insuffisante, veuillez l'ouvrir dans un écran plus large`,
  add_addons: 'Ajouter des extensions',
  general_settings: 'Paramètres généraux',
  vendors: 'Vendeurs',
  accept: 'Accepter',
  search_with: 'Rechercher %% text %%',
  author: 'Auteur',
  shopify_inside_shopify: 'La balise Shopify ne peut pas être incluse dans une autre balise Shopify',
  login: 'Connexion',
  open_new_tab: 'Ouvrir dans un nouvel onglet',
  admin: 'Administrateur',
  commit: 'Confirmer %% text %%',
  recommit: 'Reconfirmer %% text %%',
  fork: 'Fork %% text %%',
  assign_to: 'Assigner à',
  apply: 'Appliquer',
  apply_all: 'Appliquer à tous',
  confirm: 'Confirmer',
  font_style: 'Style de police',
  letter_spacing: 'Espacement des lettres',
  font_weight: 'Graisse de police',
  text_decoration: 'Décoration du texte',
  text_transform: 'Transformation du texte',
  data_binding_between_items: 'Liaison des données entre les éléments',
  data_binding_text: `La liaison des données signifie que lorsque vous modifiez n'importe quel champ d'un élément, les autres éléments sont également modifiés en conséquence. Maintenant, veuillez sélectionner les champs que vous souhaitez lier:`,
  data_binding_field_changed: 'Lorsque ce champ est modifié, tous les autres éléments seront modifiés',
  by: 'Par %% text %%',
  message: 'Message',
  success: 'Succès',
  color_scheme: 'Palette de couleurs',
  custom_color: 'Couleur personnalisée',
  background_fixed: 'Fond fixe',
  background_size: 'Taille du fond',
  successfully: 'avec succès!',
  manager: 'Gestionnaire',
  plan: 'Plan',
  on: 'Sur %% text %%',
  application: 'Application',
  free: 'Gratuit',
  pro: 'Pro',
  enterprise: 'Entreprise',
  duplicate_the_last_item: 'Dupliquer le dernier élément',
  delete_confirm_message: 'Voulez-vous supprimer cet élément %% text %% ?',
  delete_all_confirm: 'Voulez-vous supprimer tous les éléments % text % ?',
  comment: 'Commentaire',
  from: 'De %% text %%',
  not_have_permission: `Vous n\'avez pas l\'autorisation d\'effectuer cette action`,
  roles: 'Rôles',
  to: 'À',
  apply_to: 'Appliquer à',
  tabs: 'Onglets',
  loading: 'Chargement',
  active: 'Actif',
  activated: 'Activé',
  activate: 'Activer',
  blank_item: 'Élément vide',
  border_and_shadow: 'Bordure et ombre',
  current: '%% text %% Actuel',
  icon_font: `Police d\'icônes`,
  live_preview: 'Aperçu en direct',
  renew_data: 'Renouveler les données',
  renew_data_success: 'Vos données ont été mises à jour',
  renew_data_failure: 'Échec du renouvellement des données',
  change_image: `Changer d\'image`,
  release: 'Publication',
  latest: 'Dernier',
  add_component: 'Ajouter un composant',
  address: 'Adresse',
  subject: 'Sujet',
  commit_page: 'Commit page %% text %%',
  published: 'Publié',
  assign_error: `Impossible d\'assigner une variable utilisant un opérateur logique ou de comparaison`,
  same_assign_error: "Impossible d'assigner une variable identique à `name` dans le `schema`",
  same_loop_item_error: '%% text %% existe déjà. Veuillez choisir un autre nom',
  item_reach_limit: `Le nombre d\'éléments a atteint la limite`,
  item_changed_in_new_version: '%% text %% a été modifié dans la nouvelle version',
  congratulations: 'Félicitations',
  your_item_updated: 'Votre élément %% text %% a été mis à jour',
  uploading: 'Téléchargement en cours %% text %%',
  exit: 'Quitter',
  meta_field_use_in: 'Vous ne pouvez utiliser le champ meta que dans les pages: collections, produits, articles',
  confirm_publish: 'Voulez-vous vraiment publier %% text %%',
  currently_selected: 'Actuellement sélectionné',
  new_file: 'Nouveau fichier',
  file_permission: `Vous n\'avez pas l\'autorisation de modifier ce fichier`,
  discover_more: 'Découvrir plus',
  themes: 'Thèmes',
  changelogs: 'Changelogs',
  just_added: "Vient d'être ajouté",
  deselect: 'Désélectionner',
  welcome: 'Bienvenue %% text %%',
  addon_loop_desc:
    "Les add-ons dans l'aperçu ne s'affichent que sur le premier produit, mais lors de la publication, ils s'afficheront sur tous les produits.",
  change_language_of_app: "Changer la langue de l'application",
  device: 'Appareil',
  color_scheme_field_description: 'Pour modifier toutes les couleurs de votre thème, rendez-vous dans les paramètres de votre thème.',
  explore: 'Explore',
  disabled_dark_and_light_color_schemes: 'Pour modifier cette couleur, vous devez changer le mode de couleur en "Couleurs claires"',
  system_error: 'There is an error in the system',
} as const;
