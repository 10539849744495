import { Button, DataItem, MyModal } from '@wiloke/ui';
import { Alert, notification } from 'antd';
import ButtonDropdown from 'components/ButtonDropdown';
import { useSetPreviewLoaded } from 'containers/BuilderPage/store/previewLoaded/slice';
import { useSaveHeaderFooterForUser, useSavePageForUser, useSaveTheme } from 'containers/BuilderPage/store/saveForBuilder/actions';
import { ModalAskBeforeSave } from 'containers/ModalAskBeforeSave';
import { useSetAskBeforeSaveVisible } from 'containers/ModalAskBeforeSave/slice';
import { useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService } from 'hooks/useSocket/useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService';
import { useUndoRedoForRedux } from 'hooks/useUndoRedoForRedux/useUndoRedoForRedux';
import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import useGuarded from 'routes/useGuarded';
import { useSetPageAfterCreate } from 'store/actions/actionPages';
import { usePreviewWithShopify } from 'store/global/socket/syncShopifyNClonePageAtomServiceToThemeAtomService/actions';
import { syncPageNotification } from 'store/global/socket/syncShopifyNClonePageAtomServiceToThemeAtomService/watchSyncToShopify';
import {
  authSelector,
  headerSectionsSelector,
  mainSectionsSelector,
  pageDataSelector,
  pagesDataSelector,
  saveForBuilderSelector,
  socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector,
} from 'store/selectors';
import { i18n } from 'translation';
import { AddonOfTheme_Atom_N_Client } from 'types/Addons';
import { PageOfThemeService, PageType } from 'types/Page';
import { Result } from 'types/Result';
import { SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client } from 'types/Sections';
import { Consts, PAGE_BUILD_HEADER_FOOTER } from 'utils/constants/constants';
import getPageInfo from 'utils/functions/getInfo';
import { pmPopup } from 'utils/functions/postMessage';
import { getEntityVariant } from 'utils/getEntityVariant';
import { LiquidSyntaxToTwigError } from 'utils/LiquidSyntaxToTwig';
import { isThemeBuilder } from 'utils/validateBuilderMode';
import { View, Text } from 'wiloke-react-core';
import * as styles from '../styles';
import useResultForPreview from '../useResult/useResultForPreview';
import useResultForSave from '../useResult/useResultForSave';
import { ModalSavePageAdminRole } from './components/ModalSavePageAdminRole/ModalSavePageAdminRole';
import { getUserInfo } from 'utils/functions/getUserInfo';
import { notifyAxiosHandler } from 'utils/NotifyAxiosHandler';
import configureApp from 'configureApp';
import { isEmpty } from 'ramda';
import { useSetTemplateBoardVisible } from 'containers/ChooseTemplate/store/actions';

type PageTypeTitle = Extract<PageType, 'collection' | 'product' | 'blog' | 'article'>;
const PAGES_HAVE_PREVIEW = ['page', 'product', 'article', 'blog', 'collection', 'collections', 'home'];

const RightActions: FC = () => {
  const { statusSyncToShopify, statusPreviewWithShopify } = useSelector(socketOfSyncShopifyNClonePageAtomServiceToThemeAtomServiceSelector);
  const { savePageStatus, saveThemeStatus } = useSelector(saveForBuilderSelector);
  const page = useSelector(pageDataSelector);
  const pages = useSelector(pagesDataSelector);
  const { changeThemeButNotSave } = useSelector(authSelector);
  const mainSections = useSelector(mainSectionsSelector);
  const headerSections = useSelector(headerSectionsSelector);

  const id = getPageInfo('id');
  const themeId = getPageInfo('themeId');
  const shop = getPageInfo('shop');

  const setPreviewLoaded = useSetPreviewLoaded();
  const { getResult: getResultForSave, isExtracting: isExtractingForSave } = useResultForSave();
  const { getResult: getResultForPreview, isExtracting: isExtractingForPreview } = useResultForPreview();
  const guarded = useGuarded();
  const history = useHistory<'/builder'>();
  const location = useLocation<'/builder'>();

  const savePageForUser = useSavePageForUser();
  const saveTheme = useSaveTheme();
  const setVisible = useSetAskBeforeSaveVisible();
  const setPageAfterCreate = useSetPageAfterCreate();

  const previewWithShopify = usePreviewWithShopify();
  const checkPageHasMainSections = isEmpty(mainSections);
  const [visibleMissingMain, setVisibleMissingMain] = useState(false);
  const setTemplateBoardVisible = useSetTemplateBoardVisible();
  const saveHeaderFooterForUser = useSaveHeaderFooterForUser();

  const { connect, disconnect, statusSocketConnection } = useSocketForSyncShopifyNClonePageAtomServiceToThemeAtomService('sync_shopify');
  const pageTypePublishPage: Record<PageTypeTitle, string> = {
    article: i18n.t('adminDashboard.article'),
    collection: i18n.t('adminDashboard.collection'),
    product: i18n.t('adminDashboard.product'),
    blog: i18n.t('adminDashboard.blog'),
  };

  const isSyncingForSave = useMemo(() => {
    return (
      statusSocketConnection === 'loading' ||
      statusSyncToShopify === 'loading' ||
      isExtractingForSave ||
      savePageStatus === 'loading' ||
      saveThemeStatus === 'loading'
    );
  }, [isExtractingForSave, savePageStatus, saveThemeStatus, statusSocketConnection, statusSyncToShopify]);

  const isSyncingForPreview = useMemo(() => {
    return statusSocketConnection === 'loading' || isExtractingForPreview || statusPreviewWithShopify === 'loading';
  }, [isExtractingForPreview, statusPreviewWithShopify, statusSocketConnection]);

  const handleLivePreview = () => {
    setPreviewLoaded(false);
    pmPopup.setPopup(`/preview?shop=${shop}&id=${id}${themeId ? `&themeId=${themeId}` : ''}`);
  };

  const handlePreview = () => {
    connect({
      onSuccess: async () => {
        const result = await getResultForPreview('throw');
        previewWithShopify.request({
          result,
          onSyncFulfill: () => disconnect({}),
        });
      },
      onError: () => {
        notification.error({
          message: i18n.t('publish_shopify.init_sync_error.message'),
          description: i18n.t('publish_shopify.init_sync_error.description'),
        });
      },
    });
  };

  const clearLabelParams = () => {
    const state = {
      ...history.location.state,
      label: '',
      handle: '',
      isCreate: false,
    };
    history.replace({ ...history.location, state });
  };

  const handleOverride = async (isOverride: boolean, isSaveAsLandingPage: boolean) => {
    setVisible(false);
    syncPageNotification.next({
      title: i18n.t('publish_shopify.sync_page_message.step', { text: '1' }),
      description: i18n.t('publish_shopify.init_sync'),
    });

    connect({
      onSuccess: async () => {
        try {
          const result = await getResultForSave({
            entityVariant: getEntityVariant(location),
            errorOption: 'throw',
          });

          const result_with_enable_page: Result = {
            ...result,
            pages: {
              ...result.pages,
              [id]: {
                ...result.pages[id],
                data: {
                  ...result.pages[id].data,
                  page: {
                    ...result.pages[id].data.page,
                    enable: true,
                  },
                },
              },
            },
          };

          const pageData = result_with_enable_page.pages[page.commandId];

          // Nếu page không có section thì không cho save
          if (typeof pageData === 'undefined') {
            notification.error({
              message: i18n.t('builderPage.must_have_section'),
            });
            return;
          }

          // nếu params có themeId thì là save theme còn không là save page
          if (!isThemeBuilder()) {
            const isCreatePage =
              (id === Consts.BlankCommandId && page.commandId === Consts.BlankCommandId) ||
              location.state?.isCreate === true ||
              getEntityVariant(location) !== 'Client';

            savePageForUser.request({
              isIgnoreSyncShopify: false,
              isSaveAsLandingPage,
              isOverrideIndividualPages: isOverride,
              data: { ...page, label: pageData.data.pageSettings.generalSettings.label },
              type: isCreatePage ? 'create' : 'update',
              result: pageData,
              addons: result_with_enable_page.theme.addons,
              builderType: 'page',
              previewImage: pageData.data.page.image ?? { src: '', height: 0, width: 0 },
              outputBuilder: result_with_enable_page,
              onFulfill: pageResponse => {
                useUndoRedoForRedux.reset?.();
                disconnect({});
                if (isCreatePage && pageResponse) {
                  // @duong: Nếu là tạo page thì sau khi save thành công sẽ thay id ở trên params bằng id mới của server trả về và xóa state params ở bên dashboard bắn sang
                  const entityVariant = 'Client';
                  history.replace({
                    ...history.location,
                    state: {
                      ...history.location.state,
                      label: '',
                      isCreate: false,
                      isAdminTemplate: false,
                      entityVariant,
                    },
                    search: `?shop=${shop}&id=${pageResponse.commandId}${!!themeId ? '&themeId=' + themeId : ''}&entityVariant=${entityVariant}`,
                  });

                  setPageAfterCreate(pageResponse);
                }
              },
              settings: {
                generalSettings: pageData.data.pageSettings.generalSettings,
                globalJs: pageData.data.pageSettings.globalJs,
                globalScss: pageData.data.pageSettings.globalScss,
                vendors: pageData.data.pageSettings.vendors,
                cssVariables: result_with_enable_page.theme.themeSettings.cssVariables,
                layoutSettings: result_with_enable_page.theme.themeSettings.layoutSettings,
              },
              page: pageData.data.page as PageOfThemeService,
              headers: (result_with_enable_page.theme.header ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
              footers: (result_with_enable_page.theme.footer ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
            });
          } else {
            const pageIds = Object.values(pages).map(item => item.commandId);

            saveTheme.request({
              isIgnoreSyncShopify: false,
              variant: getEntityVariant(location as any),
              commandId: themeId,
              featuredImage: result_with_enable_page.theme.themeSettings.generalSettings.featuredImage ?? '',
              globalJs: result_with_enable_page.theme.globalJs,
              globalScss: result_with_enable_page.theme.globalScss,
              name: result_with_enable_page.theme.themeSettings.generalSettings.label,
              outputBuilder: result_with_enable_page,
              pageIds: pageIds,
              themeSettings: result_with_enable_page.theme.themeSettings,
              vendors: {
                data: result_with_enable_page.theme.vendors,
              },
              addons: result_with_enable_page.theme.addons as AddonOfTheme_Atom_N_Client[],
              headers: (result_with_enable_page.theme.header ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
              footers: (result_with_enable_page.theme.footer ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
              onFulfill: () => {
                useUndoRedoForRedux.reset?.();
                disconnect({});
              },
            });
            clearLabelParams();
          }
        } catch (err) {
          console.log(err);
          disconnect({});
          if (err instanceof LiquidSyntaxToTwigError) {
            notification.error({
              message: i18n.t('publish_shopify.error_in_code'),
              description: err.message,
            });
          } else if (err instanceof Error) {
            notification.error({
              message: i18n.t('publish_shopify.error_unknown.message'),
              description: i18n.t('publish_shopify.error_unknown.description'),
            });
          }
        }
      },
      onError: () => {
        notification.error({
          message: i18n.t('publish_shopify.init_sync_error.message'),
          description: i18n.t('publish_shopify.init_sync_error.description'),
        });
        syncPageNotification.done();
      },
    });
  };

  const handleVisible = () => {
    if (changeThemeButNotSave) {
      notification.warning({
        message: `Click "Switch" to change the theme. The page syncs with your chosen theme, or retains the last choice. If no initial selection, it auto-syncs with your current theme.`,
        duration: 5,
      });
    } else {
      if (getUserInfo().role === 'admin' || getUserInfo().role === 'dev') {
        if (isThemeBuilder()) {
          handleOverride(true, false);
        } else {
          ModalSavePageAdminRole.open(page);
        }
      } else {
        if (isThemeBuilder()) {
          handleOverride(true, false);
        } else if (page.type === 'collection' || page.type === 'product' || page.type === 'blog' || page.type === 'article') {
          setVisible(true);
        } else {
          handleOverride(true, false);
        }
      }
    }
  };

  const handleOverrideHeaderFooter = async (isIgnoreSyncShopify: boolean, disableVedaHeaderFooter: boolean) => {
    setVisible(false);
    syncPageNotification.next({
      title: i18n.t('publish_shopify.sync_page_message.step', { text: '1' }),
      description: i18n.t('publish_shopify.init_sync'),
    });
    connect({
      onSuccess: async () => {
        try {
          const result = await getResultForSave({
            entityVariant: getEntityVariant(location),
            errorOption: 'throw',
          });

          // Nếu page không có section thì không cho save
          // if (result.theme.header.length === 0 && result.theme.footer.length === 0) {
          //   notification.error({
          //     message: 'Please add header or footer to save',
          //   });
          //   syncPageNotification.done();
          //   return;
          // }

          saveHeaderFooterForUser.request({
            isOverrideIndividualPages: true,
            headers: result.theme.header as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
            footers: result.theme.footer as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
            addons: result.theme.addons as AddonOfTheme_Atom_N_Client[],
            outputBuilder: result,
            onFulfill() {
              disconnect({});
            },
            isIgnoreSyncShopify,
            disableVedaHeaderFooter,
          });
        } catch (err) {
          console.log(err);
          disconnect({});
          if (err instanceof LiquidSyntaxToTwigError) {
            notification.error({
              message: i18n.t('publish_shopify.error_in_code'),
              description: err.message,
            });
          } else if (err instanceof Error) {
            notification.error({
              message: i18n.t('publish_shopify.error_unknown.message'),
              description: i18n.t('publish_shopify.error_unknown.description'),
            });
          }
        }
      },
      onError: () => {
        notification.error({
          message: i18n.t('publish_shopify.init_sync_error.message'),
          description: i18n.t('publish_shopify.init_sync_error.description'),
        });
        syncPageNotification.done();
      },
    });
  };

  const handlePublishHeaderFooter = () => {
    handleOverrideHeaderFooter(false, false);
  };

  const handleDisableHeaderFooter = () => {
    handleOverrideHeaderFooter(false, true);
  };

  const handleSaveHeaderFooterAsDraft = () => {
    handleOverrideHeaderFooter(true, false);
  };

  const handleSaveAsDraft = async () => {
    syncPageNotification.next({
      title: i18n.t('publish_shopify.sync_page_message.step', { text: '1' }),
      description: i18n.t('publish_shopify.init_sync'),
    });
    try {
      const result = await getResultForSave({
        entityVariant: getEntityVariant(location),
        errorOption: 'throw',
      });

      const result_with_enable_page: Result = {
        ...result,
        pages: {
          ...result.pages,
          [id]: {
            ...result.pages[id],
            data: {
              ...result.pages[id].data,
              page: {
                ...result.pages[id].data.page,
                enable: true,
              },
            },
          },
        },
      };

      const pageData = result_with_enable_page.pages[page.commandId];

      // Nếu page không có section thì không cho save
      if (typeof pageData === 'undefined') {
        notification.error({
          message: i18n.t('builderPage.must_have_section'),
        });
        return;
      }

      // nếu params có themeId thì là save theme còn không là save page
      if (!isThemeBuilder()) {
        const isCreatePage =
          (id === Consts.BlankCommandId && page.commandId === Consts.BlankCommandId) ||
          location.state?.isCreate === true ||
          getEntityVariant(location) !== 'Client';

        savePageForUser.request({
          isIgnoreSyncShopify: true,
          isSaveAsLandingPage: false,
          isOverrideIndividualPages: false,
          data: { ...page, label: pageData.data.pageSettings.generalSettings.label },
          type: isCreatePage ? 'create' : 'update',
          result: pageData,
          addons: result_with_enable_page.theme.addons,
          builderType: 'page',
          previewImage: pageData.data.page.image ?? { src: '', height: 0, width: 0 },
          outputBuilder: result_with_enable_page,
          onFulfill: pageResponse => {
            syncPageNotification.done();
            notifyAxiosHandler.handleSuccess(`${i18n.t('general.update', { text: i18n.t('general.successfully') })}`);
            useUndoRedoForRedux.reset?.();
            if (isCreatePage && pageResponse) {
              // @duong: Nếu là tạo page thì sau khi save thành công sẽ thay id ở trên params bằng id mới của server trả về và xóa state params ở bên dashboard bắn sang
              const entityVariant = 'Client';
              history.replace({
                ...history.location,
                state: {
                  ...history.location.state,
                  label: '',
                  isCreate: false,
                  isAdminTemplate: false,
                  entityVariant,
                },
                search: `?shop=${shop}&id=${pageResponse.commandId}${!!themeId ? '&themeId=' + themeId : ''}&entityVariant=${entityVariant}`,
              });

              setPageAfterCreate(pageResponse);
            }
          },
          settings: {
            generalSettings: pageData.data.pageSettings.generalSettings,
            globalJs: pageData.data.pageSettings.globalJs,
            globalScss: pageData.data.pageSettings.globalScss,
            vendors: pageData.data.pageSettings.vendors,
            cssVariables: result_with_enable_page.theme.themeSettings.cssVariables,
            layoutSettings: result_with_enable_page.theme.themeSettings.layoutSettings,
          },
          page: pageData.data.page as PageOfThemeService,
          headers: (result_with_enable_page.theme.header ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
          footers: (result_with_enable_page.theme.footer ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
        });
      } else {
        const pageIds = Object.values(pages).map(item => item.commandId);
        saveTheme.request({
          isIgnoreSyncShopify: true,
          variant: getEntityVariant(location as any),
          commandId: themeId,
          featuredImage: result_with_enable_page.theme.themeSettings.generalSettings.featuredImage ?? '',
          globalJs: result_with_enable_page.theme.globalJs,
          globalScss: result_with_enable_page.theme.globalScss,
          name: result_with_enable_page.theme.themeSettings.generalSettings.label,
          outputBuilder: result_with_enable_page,
          pageIds: pageIds,
          themeSettings: result_with_enable_page.theme.themeSettings,
          vendors: {
            data: result_with_enable_page.theme.vendors,
          },
          addons: result_with_enable_page.theme.addons as AddonOfTheme_Atom_N_Client[],
          headers: (result_with_enable_page.theme.header ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
          footers: (result_with_enable_page.theme.footer ?? []) as SectionOfPage_Atom_N_ClientOrTheme_Atom_N_Client[],
          onFulfill() {
            notifyAxiosHandler.handleSuccess(`${i18n.t('general.update', { text: i18n.t('general.successfully') })}`);
            syncPageNotification.done();
            useUndoRedoForRedux.reset?.();
          },
        });
        clearLabelParams();
      }
    } catch (err) {
      console.log(err);
      syncPageNotification.done();
      if (err instanceof LiquidSyntaxToTwigError) {
        notification.error({
          message: i18n.t('publish_shopify.error_in_code'),
          description: err.message,
        });
      } else if (err instanceof Error) {
        notification.error({
          message: i18n.t('publish_shopify.error_unknown.message'),
          description: i18n.t('publish_shopify.error_unknown.description'),
        });
      }
    }
  };

  const handleChooseTemplateVisible = () => {
    const index = headerSections.length + mainSections.length;
    setTemplateBoardVisible({
      visible: true,
      index,
      isChange: false,
      navKeys: ['sections'],
      sectionType: 'default',
    });
    setVisibleMissingMain(false);
  };

  return (
    <View css={styles.right}>
      {configureApp.enable_live_preview && (
        <View css={{ marginRight: '10px' }}>
          <Button
            backgroundColor="gray2"
            color="gray8"
            size="small"
            radius={4}
            fontFamily="secondary"
            css={{ fontWeight: 500, height: '36px', fontSize: '13px', paddingTop: '8px', paddingBottom: '8px' }}
            onClick={handleLivePreview}
          >
            {i18n.t('general.live_preview')}
          </Button>
        </View>
      )}

      {PAGE_BUILD_HEADER_FOOTER === id && (
        <ButtonDropdown
          dropdownData={[
            { icon: 'file', label: i18n.t('builderPage.save_as_draft'), value: 'save_as_draft' },
            { icon: 'ban', label: 'Disable Veda Header & Footer', value: 'disable_header_footer' },
          ]}
          backgroundColor="primary"
          disabled={isSyncingForSave || isSyncingForPreview}
          loading={isSyncingForSave}
          onClick={handlePublishHeaderFooter}
          onClickItemDropdown={value => {
            if (value === 'save_as_draft') {
              handleSaveHeaderFooterAsDraft();
            } else if (value === 'disable_header_footer') {
              handleDisableHeaderFooter();
            }
          }}
        >
          {statusSocketConnection === 'loading'
            ? i18n.t('publish_shopify.init_sync')
            : isExtractingForSave
            ? i18n.t('builderPage.extracting')
            : savePageStatus === 'loading' || saveThemeStatus === 'loading'
            ? i18n.t('builderPage.saving')
            : statusSyncToShopify === 'loading'
            ? i18n.t('publish_shopify.syncing')
            : i18n.t('general.publish', { text: `${i18n.t('general.header')} & ${i18n.t('general.footer')}` })}
        </ButtonDropdown>
      )}

      {PAGE_BUILD_HEADER_FOOTER !== id && (
        <>
          {PAGES_HAVE_PREVIEW.includes(page.type) && (
            <View css={{ marginRight: '10px' }}>
              <Button
                backgroundColor="gray2"
                color="gray8"
                size="small"
                radius={4}
                fontFamily="secondary"
                css={{ fontWeight: 500, height: '36px', fontSize: '13px', paddingTop: '8px', paddingBottom: '8px' }}
                onClick={handlePreview}
                loading={isSyncingForPreview}
                disabled={isSyncingForSave || isSyncingForPreview}
                tooltip={isSyncingForSave ? { text: i18n.t('publish_shopify.process_used'), placement: 'bottom' } : undefined}
              >
                {isExtractingForPreview ? i18n.t('builderPage.extracting') : i18n.t('general.preview')}
              </Button>
            </View>
          )}
          <View>
            <ButtonDropdown
              loading={isSyncingForSave}
              disabled={isSyncingForPreview || isSyncingForSave}
              tooltip={isSyncingForPreview ? { text: i18n.t('publish_shopify.process_used'), placement: 'bottom' } : undefined}
              dropdownData={[
                { icon: 'file', label: i18n.t('builderPage.save_as_draft'), value: 'save_as_draft' },
                ...((guarded('admin', 'dev')
                  ? [{ icon: 'user-tie', label: i18n.t('builderPage.save_for', { text: i18n.t('builderPage.dev') }), value: 'save_for_dev' }]
                  : []) as DataItem[]),
                ...((guarded('admin', 'dev')
                  ? [{ icon: 'user-shield', label: i18n.t('builderPage.save_for', { text: i18n.t('general.admin') }), value: 'save_for_admin' }]
                  : []) as DataItem[]),
                ...((guarded('admin', 'dev', 'support')
                  ? [{ icon: 'user-shield', label: 'Lưu và sync thành landing page', value: 'save_and_sync_as_landing_page' }]
                  : []) as DataItem[]),
              ]}
              onClick={() => {
                if (checkPageHasMainSections) {
                  setVisibleMissingMain(true);
                } else {
                  handleVisible();
                }
              }}
              onClickItemDropdown={value => {
                if (checkPageHasMainSections) {
                  setVisibleMissingMain(true);
                } else {
                  if (value === 'save_as_draft') {
                    handleSaveAsDraft();
                  } else if (value === 'save_and_sync_as_landing_page') {
                    handleOverride(false, true);
                  } else if (value === 'save_for_admin' || value === 'save_for_dev') {
                    ModalSavePageAdminRole.open(page);
                  }
                }
              }}
            >
              {statusSocketConnection === 'loading'
                ? i18n.t('publish_shopify.init_sync')
                : isExtractingForSave
                ? i18n.t('builderPage.extracting')
                : savePageStatus === 'loading' || saveThemeStatus === 'loading'
                ? i18n.t('builderPage.saving')
                : statusSyncToShopify === 'loading'
                ? i18n.t('publish_shopify.syncing')
                : i18n.t('general.publish', { text: i18n.t('general.page'), textTransform: 'capitalize' })}
            </ButtonDropdown>
          </View>
        </>
      )}

      {/* save and publish modal  */}
      {/* FIXME: @mioriaty -> Chỉ có save page mới dùng modal này nên tách ra 1 component riêng như "ModalChooseActionsBeforeSaveTheme" */}
      <ModalAskBeforeSave
        headerText={i18n.t('builderPage.save_and_publish')}
        isLoading={
          statusSocketConnection === 'loading' ||
          isExtractingForSave ||
          statusSyncToShopify === 'loading' ||
          savePageStatus === 'loading' ||
          saveThemeStatus === 'loading'
        }
        okText={
          statusSocketConnection === 'loading'
            ? i18n.t('publish_shopify.init_sync')
            : isExtractingForSave
            ? i18n.t('builderPage.extracting')
            : savePageStatus === 'loading' || saveThemeStatus === 'loading'
            ? i18n.t('builderPage.saving')
            : statusSyncToShopify === 'loading'
            ? i18n.t('publish_shopify.syncing')
            : i18n.t('builderPage.confirm_override')
        }
        cancelText={i18n.t('builderPage.publish_not_override')}
        content={i18n.t('builderPage.publish_ask_text', { pageType: pageTypePublishPage[page.type as PageTypeTitle] })}
        onOverride={isOverride => handleOverride(isOverride, false)}
      />
      <ModalSavePageAdminRole />

      <MyModal
        isVisible={visibleMissingMain}
        headerText={i18n.t('general.warning')}
        onCancel={() => {
          setVisibleMissingMain(false);
        }}
        okText=""
        cancelText={i18n.t('general.close')}
      >
        <Alert
          showIcon
          type="warning"
          message={
            <Text size={16} fontFamily="secondary">
              {i18n.t('builderPage.must_have_section')}
            </Text>
          }
          description={
            <Text size={14} fontFamily="secondary" css={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleChooseTemplateVisible}>
              {i18n.t('builderPage.click_to_add_main')}
            </Text>
          }
        />
      </MyModal>
    </View>
  );
};

export default RightActions;
