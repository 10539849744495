import { AxiosResponse } from 'axios';
import configureApp from 'configureApp';
import fetchAPI from 'utils/functions/fetchAPI';

interface ResponseSuccess {
  message: string;
  info: Array<{
    id: number; // Shopify theme id
    name: string;
    role: 'main' | 'unpublished';
  }>;
}

export const getThemeRecords = async () => {
  const response: AxiosResponse<ResponseSuccess> = await fetchAPI.request({
    url: `${configureApp.endpoint['shopify-connections']}/sync/shopify/me/stores/themes`,
  });
  return response.data.info;
};
