import { Button, MyModal } from '@wiloke/ui';
import { Table } from 'antd';
import Field from 'components/Field';
import Textarea from 'components/Textarea';
import TextInput from 'components/TextInput';
import { useSettingsShopifyPicker } from 'containers/Shopify/ModalCreateShopifyPicker/slice';
import { Dispatch, SetStateAction } from 'react';
import { v4 } from 'uuid';
import { View } from 'wiloke-react-core';
import SwitchBeauty from 'components/SwitchBeauty';
import { handleExtractResultForForceSync } from 'services/ShopifyConnection';
import { FILE_NAME_OF_DEFAULT, LABEL_OF_PAGES } from '../../consts';
import { i18n } from 'translation';

interface PreprocessingProps {
  fileContentState: ReturnType<typeof handleExtractResultForForceSync> | undefined;
  isLoading: boolean;
  isRetryAction: boolean;
  setPageParamsInteracting: Dispatch<SetStateAction<ReturnType<typeof handleExtractResultForForceSync>['pagesParams'][number] | undefined>>;
  formPreprocessingValues: Record<string, ReturnType<typeof handleExtractResultForForceSync>['pagesParams'][number] | undefined>;
  setFormPreprocessingValues: Dispatch<SetStateAction<PreprocessingProps['formPreprocessingValues']>>;
  openPreprocessing: boolean;
  setOpenPreprocessing: Dispatch<SetStateAction<PreprocessingProps['openPreprocessing']>>;
  openModalConfirmRemovePreprocessing: boolean;
  setOpenModalConfirmRemovePreprocessing: Dispatch<SetStateAction<PreprocessingProps['openModalConfirmRemovePreprocessing']>>;
  onOk: () => void;
}

export const Preprocessing = ({
  fileContentState,
  isLoading,
  isRetryAction,
  formPreprocessingValues,
  setFormPreprocessingValues,
  openPreprocessing,
  setOpenPreprocessing,
  openModalConfirmRemovePreprocessing,
  setOpenModalConfirmRemovePreprocessing,
  setPageParamsInteracting,
  onOk,
}: PreprocessingProps) => {
  const changeSettingsShopify = useSettingsShopifyPicker();

  const renderModalConfirmRemovePreprocessing = () => {
    return (
      <MyModal
        headerText="Huỷ bỏ kết quả tiền xử lý dữ liệu?"
        okText="Chính xác"
        cancelText="Không"
        isVisible={openModalConfirmRemovePreprocessing}
        onCancel={() => {
          setOpenModalConfirmRemovePreprocessing(false);
        }}
        onOk={() => {
          setOpenModalConfirmRemovePreprocessing(false);
          setOpenPreprocessing(false);
          setFormPreprocessingValues({});
        }}
      >
        <View css={{ padding: '12px', textAlign: 'center' }}>
          <View>Hành động này không thể "Undo". Bạn có chắc chắn là muốn huỷ bỏ tiền xử lý dữ liệu?</View>
        </View>
      </MyModal>
    );
  };

  const renderModalPreprocessing = () => {
    if (!fileContentState) {
      return null;
    }
    const { pagesParams } = fileContentState;
    return (
      <MyModal
        headerText="Thay đổi kết quả ghi lên shopify"
        isVisible={openPreprocessing}
        size="large"
        okText="Bắt đầu đồng bộ"
        cancelText="Đóng"
        onCancel={() => setOpenModalConfirmRemovePreprocessing(true)}
        onOk={onOk}
        isLoading={isLoading}
      >
        <View css={{ padding: '12px' }}>
          <View className="with-ant-table-scroll-shadows">
            <Table
              dataSource={pagesParams.sort((a, b) => (a.pageType && b.pageType && a.pageType > b.pageType ? 1 : -1))}
              rowKey={item => item.pageCommandId ?? v4()}
              pagination={false}
              scroll={{ y: 600 }}
              columns={[
                {
                  title: 'Name',
                  render: (_, { pageName, pageType }) => {
                    return (
                      <View>
                        <View>{pageName}</View>
                        <View>{pageType ? LABEL_OF_PAGES[pageType] : i18n.t('general.system_error')}</View>
                      </View>
                    );
                  },
                },
                {
                  title: 'Tên file sẽ được ghi lên shopify',
                  render: (_, { pageType, pageCommandId, article, blog, collection, product }) => {
                    const pageTypeInFormValues =
                      pageCommandId && formPreprocessingValues[pageCommandId] ? formPreprocessingValues[pageCommandId]?.pageType : pageType;
                    const pageType_ = pageTypeInFormValues ? pageTypeInFormValues : pageType;
                    const fileName =
                      pageType_ === 'product'
                        ? product?.isApplyToAll
                          ? FILE_NAME_OF_DEFAULT[pageType_]
                          : `product.veda-${pageCommandId}`
                        : pageType_ === 'collection'
                        ? collection?.isApplyToAll
                          ? FILE_NAME_OF_DEFAULT[pageType_]
                          : `collection.veda-${pageCommandId}`
                        : pageType_ === 'article'
                        ? article?.isApplyToAll
                          ? FILE_NAME_OF_DEFAULT[pageType_]
                          : `article.veda-${pageCommandId}`
                        : pageType_ === 'blog'
                        ? blog?.isApplyToAll
                          ? FILE_NAME_OF_DEFAULT[pageType_]
                          : `blog.veda-${pageCommandId}`
                        : pageType_ === 'page'
                        ? `page.veda-${pageCommandId}`
                        : pageType_
                        ? FILE_NAME_OF_DEFAULT[pageType_]
                        : i18n.t('general.system_error');
                    return <View>{fileName}</View>;
                  },
                },
                {
                  title: 'Apply cho',
                  render: (_, { pageType, pageName, pageCommandId, product, collection, blog, article, page, ...params }) => {
                    const pageParams =
                      pageCommandId && formPreprocessingValues[pageCommandId]
                        ? formPreprocessingValues[pageCommandId]
                        : { pageType, pageCommandId, product, collection, blog, article, page, ...params };
                    if (pageType === 'product') {
                      return (
                        <Field label='Chọn các "Product" sẽ được apply' description="Lưu ý: Các page chọn trùng sẽ ghi đè theo thứ tự xuất hiện">
                          <View
                            css={{
                              marginBottom: '4px',
                              cursor: 'pointer',
                              '> *': { pointerEvents: 'none' },
                            }}
                            onClick={() => {
                              setPageParamsInteracting({ pageType, pageCommandId, product, collection, blog, article, page, ...params });
                              changeSettingsShopify({
                                visibleProduct: true,
                              });
                            }}
                          >
                            <Textarea
                              readOnly
                              value={
                                pageParams?.product?.isApplyToAll
                                  ? 'All'
                                  : pageParams?.product?.shopifyPages?.map(shopifyPage => shopifyPage?.handle).join(',')
                              }
                            />
                          </View>
                          <Button
                            block
                            size="extra-small"
                            radius={4}
                            onClick={() => {
                              setFormPreprocessingValues(state => {
                                if (pageCommandId) {
                                  return {
                                    ...state,
                                    [pageCommandId]: undefined,
                                  };
                                }
                                return state;
                              });
                            }}
                          >
                            Reset về "All"
                          </Button>
                        </Field>
                      );
                    }
                    if (pageType === 'collection') {
                      return (
                        <Field label='Chọn các "Collection" sẽ được apply' description="Lưu ý: Các page chọn trùng sẽ ghi đè theo thứ tự xuất hiện">
                          <View
                            css={{
                              marginBottom: '4px',
                              cursor: 'pointer',
                              '> *': { pointerEvents: 'none' },
                            }}
                            onClick={() => {
                              setPageParamsInteracting({ pageType, pageCommandId, product, collection, blog, article, page, ...params });
                              changeSettingsShopify({
                                visibleCollection: true,
                              });
                            }}
                          >
                            <Textarea
                              readOnly
                              value={
                                pageParams?.collection?.isApplyToAll
                                  ? 'All'
                                  : pageParams?.collection?.shopifyPages?.map(shopifyPage => shopifyPage?.handle).join(',')
                              }
                            />
                          </View>
                          <Button
                            block
                            size="extra-small"
                            radius={4}
                            onClick={() => {
                              setFormPreprocessingValues(state => {
                                if (pageCommandId) {
                                  return {
                                    ...state,
                                    [pageCommandId]: undefined,
                                  };
                                }
                                return state;
                              });
                            }}
                          >
                            Reset về "All"
                          </Button>
                        </Field>
                      );
                    }
                    if (pageType === 'article') {
                      return (
                        <Field label='Chọn các "Article" sẽ được apply' description="Lưu ý: Các page chọn trùng sẽ ghi đè theo thứ tự xuất hiện">
                          <View
                            css={{
                              cursor: 'pointer',
                              '> *': { pointerEvents: 'none' },
                              marginBottom: '4px',
                            }}
                            onClick={() => {
                              setPageParamsInteracting({ pageType, pageCommandId, product, collection, blog, article, page, ...params });
                              changeSettingsShopify({
                                visibleArticle: true,
                              });
                            }}
                          >
                            <Textarea
                              readOnly
                              value={
                                pageParams?.article?.isApplyToAll
                                  ? 'All'
                                  : pageParams?.article?.shopifyPages?.map(shopifyPage => shopifyPage?.handle).join(',')
                              }
                            />
                          </View>
                          <Button
                            block
                            size="extra-small"
                            radius={4}
                            onClick={() => {
                              setFormPreprocessingValues(state => {
                                if (pageCommandId) {
                                  return {
                                    ...state,
                                    [pageCommandId]: undefined,
                                  };
                                }
                                return state;
                              });
                            }}
                          >
                            Reset về "All"
                          </Button>
                        </Field>
                      );
                    }
                    if (pageType === 'blog') {
                      return (
                        <Field label='Chọn các "Blog" sẽ được apply' description="Lưu ý: Các page chọn trùng sẽ ghi đè theo thứ tự xuất hiện">
                          <View
                            css={{
                              marginBottom: '4px',
                              cursor: 'pointer',
                              '> *': { pointerEvents: 'none' },
                            }}
                            onClick={() => {
                              setPageParamsInteracting({ pageType, pageCommandId, product, collection, blog, article, page, ...params });
                              changeSettingsShopify({
                                visibleBlog: true,
                              });
                            }}
                          >
                            <Textarea
                              readOnly
                              value={
                                pageParams?.blog?.isApplyToAll
                                  ? 'All'
                                  : pageParams?.blog?.shopifyPages?.map(shopifyPage => shopifyPage?.handle).join(',')
                              }
                            />
                          </View>
                          <Button
                            block
                            size="extra-small"
                            radius={4}
                            onClick={() => {
                              setFormPreprocessingValues(state => {
                                if (pageCommandId) {
                                  return {
                                    ...state,
                                    [pageCommandId]: undefined,
                                  };
                                }
                                return state;
                              });
                            }}
                          >
                            Reset về "All"
                          </Button>
                        </Field>
                      );
                    }
                    if (pageType === 'page') {
                      return (
                        <Field
                          label="Tên page sẽ được tạo"
                          note='Ví dụ tên page mới là "Wishlist" -> Page được sinh ra sẽ có dạng "/wishlist"'
                          description="Lưu ý: Các page chọn trùng sẽ ghi đè theo thứ tự xuất hiện"
                        >
                          <TextInput
                            css={{ marginBottom: '4px' }}
                            block
                            value={pageParams?.page?.pageName}
                            onValueChange={value => {
                              setFormPreprocessingValues(state => {
                                if (pageCommandId) {
                                  const defaultData = state[pageCommandId] ?? {
                                    pageType,
                                    pageName,
                                    pageCommandId,
                                    product,
                                    collection,
                                    blog,
                                    article,
                                    page,
                                    ...params,
                                  };
                                  return {
                                    ...state,
                                    [pageCommandId]: {
                                      ...defaultData,
                                      page: {
                                        ...defaultData.page,
                                        pageName: value,
                                      },
                                    },
                                  };
                                }
                                return state;
                              });
                            }}
                          />
                          <Button
                            block
                            size="extra-small"
                            radius={4}
                            onClick={() => {
                              setFormPreprocessingValues(state => {
                                if (pageCommandId) {
                                  return {
                                    ...state,
                                    [pageCommandId]: undefined,
                                  };
                                }
                                return state;
                              });
                            }}
                          >
                            Reset về "{pageName}"
                          </Button>
                        </Field>
                      );
                    }
                    if (pageType === 'home') {
                      if (pageParams?.pageType === 'page') {
                        return (
                          <Field
                            label="Tên page sẽ được tạo"
                            note='Ví dụ tên page mới là "Wishlist" -> Page được sinh ra sẽ có dạng "/wishlist"'
                            description="Lưu ý: Các page chọn trùng sẽ ghi đè theo thứ tự xuất hiện"
                          >
                            <TextInput
                              css={{ marginBottom: '4px' }}
                              block
                              value={pageParams?.page?.pageName}
                              onValueChange={value => {
                                setFormPreprocessingValues(state => {
                                  if (pageCommandId) {
                                    const defaultData = state[pageCommandId] ?? {
                                      pageType,
                                      pageName,
                                      pageCommandId,
                                      product,
                                      collection,
                                      blog,
                                      article,
                                      page,
                                      ...params,
                                    };
                                    return {
                                      ...state,
                                      [pageCommandId]: {
                                        ...defaultData,
                                        page: {
                                          ...defaultData.page,
                                          pageName: value,
                                        },
                                      },
                                    };
                                  }
                                  return state;
                                });
                              }}
                            />
                            <Button
                              block
                              size="extra-small"
                              radius={4}
                              onClick={() => {
                                setFormPreprocessingValues(state => {
                                  if (pageCommandId) {
                                    return {
                                      ...state,
                                      [pageCommandId]: undefined,
                                    };
                                  }
                                  return state;
                                });
                              }}
                            >
                              Reset về "Home Page"
                            </Button>
                          </Field>
                        );
                      }
                      return (
                        <Field description='Để làm chức năng "Nhiều home page" thì chỉ có duy nhất 1 page được sync lên shopify dưới dạng "Home Page" còn lại các page khác cần chuyển thành "Regular (Landing) Page". Đây là nơi để chuyển "Home page" thành "Regular (Landind) Page"'>
                          <Button
                            block
                            size="extra-small"
                            radius={4}
                            onClick={() => {
                              setFormPreprocessingValues(state => {
                                if (pageCommandId) {
                                  return {
                                    ...state,
                                    [pageCommandId]: {
                                      ...state[pageCommandId],
                                      pageType: 'page',
                                      page: { pageName },
                                      isSyncTurnedOff: !!state[pageCommandId]?.isSyncTurnedOff,
                                    },
                                  };
                                }
                                return state;
                              });
                            }}
                          >
                            Apply như một "Landing Page"
                          </Button>
                        </Field>
                      );
                    }

                    return <View>Page này không thể custom</View>;
                  },
                },
                {
                  title: 'Tắt chức năng đồng bộ',
                  render: (_, { pageCommandId, ...params }) => {
                    const pageParams =
                      pageCommandId && formPreprocessingValues[pageCommandId] ? formPreprocessingValues[pageCommandId] : { pageCommandId, ...params };
                    return (
                      <Field label="Tắt chức năng đồng bộ lên shopify với page này">
                        <SwitchBeauty
                          checked={pageParams?.isSyncTurnedOff ?? undefined}
                          onValueChange={checked => {
                            setFormPreprocessingValues(state => {
                              if (pageCommandId) {
                                return {
                                  ...state,
                                  [pageCommandId]: {
                                    ...state[pageCommandId],
                                    isSyncTurnedOff: checked,
                                  },
                                };
                              }
                              return state;
                            });
                          }}
                          enableText="Đã tắt"
                          disableText="Chưa tắt"
                        />
                      </Field>
                    );
                  },
                },
              ]}
            />
          </View>
        </View>
      </MyModal>
    );
  };

  if (!fileContentState || isRetryAction) {
    return null;
  }
  return (
    <>
      <Button
        disabled={isLoading}
        backgroundColor="danger"
        size="extra-small"
        radius={4}
        fontFamily="secondary"
        css={{ fontWeight: 500 }}
        onClick={() => {
          setOpenPreprocessing(true);
        }}
      >
        Tiền xử lý
      </Button>
      {renderModalConfirmRemovePreprocessing()}
      {renderModalPreprocessing()}
    </>
  );
};
