import Collapse from 'components/Collapse';
import { ActivityIndicator, FontAwesome, View } from 'wiloke-react-core';

interface ProcessingProps {
  expectProcesses: string[];
  actualProcessed: string[];
  status: Status;
  isConnectingSocket: boolean;
}

export const Processing = ({ expectProcesses, actualProcessed, status, isConnectingSocket }: ProcessingProps) => {
  if (!expectProcesses.length) {
    return null;
  }

  return (
    <Collapse
      active
      groupName="trackingProcessesSync"
      name="trackingProcessesSync"
      renderHeader={handler => (
        <View css={{ display: 'flex', alignItems: 'center', cursor: 'pointer', padding: '8px' }} onClick={handler}>
          <FontAwesome size={16} css={{ marginRight: '8px' }} type="far" name="angle-down" />
          <View>
            Chi tiết quá trình - Đã hoàn thành: {actualProcessed.length} tiến trình / {expectProcesses.length} tiến trình
          </View>
        </View>
      )}
    >
      <View css={{ padding: '8px' }}>
        {expectProcesses.map((process, index) => {
          const isDoned = index < actualProcessed.length;
          const isRuning = index === actualProcessed.length || isConnectingSocket;
          return (
            <View css={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }} key={process}>
              <View css={{ marginRight: '8px' }}>
                {isDoned ? (
                  <FontAwesome type="far" name="check-circle" size={16} color="success" />
                ) : isRuning ? (
                  status === 'failure' ? (
                    <FontAwesome type="far" name="exclamation-circle" size={16} color="danger" />
                  ) : (
                    <ActivityIndicator size={16} />
                  )
                ) : status === 'failure' ? (
                  <FontAwesome type="far" name="exclamation-circle" size={16} color="danger" />
                ) : (
                  <FontAwesome type="far" name="pause-circle" size={16} />
                )}
              </View>
              <View>{process}</View>
            </View>
          );
        })}
      </View>
    </Collapse>
  );
};
