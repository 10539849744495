import { updatePageSettings } from 'containers/Admin/PageBuilder/DashboardPageSettings';
import { AdminPageData, FilterTypePage } from 'containers/Admin/types';
import { ActionTypes, createDispatchAction, createDispatchAsyncAction, createSlice, handleAction } from 'wiloke-react-core/utils';
import {
  actionCreateBlogPage,
  actionDeleteBlogPages,
  actionGetBlogPages,
  actionLoadMoreBlogPage,
  actionUpdateStatusBlogPage,
  SetCurrentBlogItem,
  ChangeSearchKey,
  FilterPageType,
  IsSelectAll,
  SelectIds,
  actionDuplicateBlogPage,
} from '../actions';

type RecordStatus = Record<string, Status>;

type BlogActions = SetCurrentBlogItem | ChangeSearchKey | FilterPageType | IsSelectAll | SelectIds;

type BlogExtraActions = ActionTypes<
  | typeof actionCreateBlogPage
  | typeof actionDeleteBlogPages
  | typeof actionGetBlogPages
  | typeof actionLoadMoreBlogPage
  | typeof actionUpdateStatusBlogPage
  | typeof updatePageSettings
  | typeof actionDuplicateBlogPage
>;

interface StateBlogPage {
  ids: string[];
  data: AdminPageData[];
  currentItem: AdminPageData | undefined;
  getAllPageStatus: Status;
  loadMorePageStatus: Status;
  createPageStatus: Status;
  duplicateStatus: RecordStatus;
  updatePageStatus: RecordStatus;
  deletePending: string[];
  hasNextPage: boolean;
  filterType: FilterTypePage;
  isSelectAll: boolean;
  search: string;
}

export const sliceBlogPage = createSlice<StateBlogPage, BlogActions, BlogExtraActions>({
  initialState: {
    data: [],
    ids: [],
    isSelectAll: false,
    createPageStatus: 'idle',
    duplicateStatus: {},
    deletePending: [],
    getAllPageStatus: 'idle',
    updatePageStatus: {},
    loadMorePageStatus: 'idle',
    currentItem: undefined,
    filterType: 'all',
    search: '',
    hasNextPage: false,
  },
  name: '@BlogPage',
  reducers: [
    handleAction('setCurrentBlogItem', ({ state, action }) => {
      const { item } = action.payload;
      state.currentItem = item;
    }),
    handleAction('selectIds', ({ state, action }) => {
      const { ids } = action.payload;
      return {
        ...state,
        ids,
      };
    }),
    handleAction('filterPageType', ({ state, action }) => ({
      ...state,
      filterType: action.payload.pageType,
    })),
    handleAction('isSelectAll', ({ state, action }) => ({
      ...state,
      isSelectAll: action.payload.isSelectAll,
    })),
    handleAction('changSearchKey', ({ state, action }) => {
      state.search = action.payload.search;
    }),
  ],
  extraReducers: [
    handleAction('@BlogPage/createPageRequest', ({ state }) => ({
      ...state,
      createPageStatus: 'loading',
    })),
    handleAction('@BlogPage/createPageSuccess', ({ state }) => {
      return {
        ...state,
        createPageStatus: 'success',
      };
    }),
    handleAction('@BlogPage/createPageFailure', ({ state }) => ({
      ...state,
      createPageStatus: 'failure',
    })),
    handleAction('@BlogPage/updateStatusPageRequest', ({ state, action }) => ({
      ...state,
      updatePageStatus: {
        ...state.updatePageStatus,
        [action.payload.id]: 'loading',
      },
    })),
    handleAction('@BlogPage/updateStatusPageSuccess', ({ state, action }) => {
      const { enable, id, modifiedDateTimestamp } = action.payload;

      return {
        ...state,
        updatePageStatus: {
          ...state.updatePageStatus,
          [id]: 'success',
        },
        data: state.data.map(item => {
          if (item.commandId === id) {
            return {
              ...item,
              enable: enable,
              modifiedDateTimestamp,
            };
          }
          return item;
        }),
      };
    }),
    handleAction('@BlogPage/updateStatusPageFailure', ({ state, action }) => ({
      ...state,
      updatePageStatus: {
        ...state.updatePageStatus,
        [action.payload.id]: 'failure',
      },
    })),
    handleAction('@BlogPage/deletePagesRequest', ({ state }) => ({
      ...state,
      deletePending: state.ids,
    })),
    handleAction('@BlogPage/deletePagesSuccess', ({ state, action }) => {
      return {
        ...state,
        deletePending: state.deletePending.filter(id => !action.payload.ids.includes(id)),
        data: state.data.filter(item => !action.payload.ids.includes(item.commandId)),
        ids: state.ids.filter(id => !action.payload.ids.includes(id)),
        isSelectAll: false,
      };
    }),
    handleAction('@BlogPage/deletePagesFailure', ({ state, action }) => ({
      ...state,
      deletePending: state.deletePending.filter(id => id !== action.payload.id),
      ids: state.ids.filter(id => id !== action.payload.id),
    })),
    handleAction('@BlogPage/getPagesRequest', ({ state }) => ({
      ...state,
      getAllPageStatus: 'loading',
      isSelectAll: false,
      ids: [],
    })),
    handleAction('@BlogPage/getPagesSuccess', ({ state, action }) => {
      const { data, hasNextPage } = action.payload;

      return {
        ...state,
        getAllPageStatus: 'success',
        data,
        hasNextPage,
      };
    }),
    handleAction('@BlogPage/getPagesFailure', ({ state }) => ({
      ...state,
      getAllPageStatus: 'failure',
    })),

    handleAction('@BlogPage/loadMoreBlogPageRequest', ({ state }) => {
      return {
        ...state,
        loadMorePageStatus: 'loading',
      };
    }),
    handleAction('@BlogPage/loadMoreBlogPageSuccess', ({ state, action }) => {
      return {
        ...state,
        loadMorePageStatus: 'success',
        data: state.data.concat(action.payload.data),
        hasNextPage: action.payload.hasNextPage,
      };
    }),
    handleAction('@BlogPage/loadMoreBlogPageFailure', ({ state }) => {
      return {
        ...state,
        loadMorePageStatus: 'failure',
      };
    }),
    handleAction('@Dashboard/updatePageSettings/success', ({ state, action }) => {
      const { commandId } = action.payload;
      return {
        ...state,
        data: state.data.map(item => {
          if (item.commandId === commandId) {
            return {
              ...item,
              ...action.payload,
            };
          }
          return item;
        }),
      };
    }),
    handleAction('@BlogPage/duplicateBlogPageRequest', ({ state, action }) => {
      state.duplicateStatus[action.payload.commandId] = 'loading';
    }),
    handleAction('@BlogPage/duplicateBlogPageSuccess', ({ state, action }) => {
      state.duplicateStatus[action.payload.commandId] = 'success';
      state.data = [action.payload.item, ...state.data];
    }),
    handleAction('@BlogPage/duplicateBlogPageFailure', ({ state, action }) => {
      state.duplicateStatus[action.payload.commandId] = 'failure';
    }),
  ],
});

export const { changSearchKey, filterPageType, isSelectAll, selectIds, setCurrentBlogItem } = sliceBlogPage.actions;

export const useCreateBlogPage = createDispatchAsyncAction(actionCreateBlogPage);
export const useGetBlogPageItems = createDispatchAsyncAction(actionGetBlogPages);
export const useDeleteBlogPages = createDispatchAsyncAction(actionDeleteBlogPages);
export const useUpdateStatusBlogPage = createDispatchAsyncAction(actionUpdateStatusBlogPage);
export const useLoadMoreBlogPage = createDispatchAsyncAction(actionLoadMoreBlogPage);
export const useSelectManyItems = createDispatchAction(selectIds);
export const useCurrentBlogItem = createDispatchAction(setCurrentBlogItem);
export const useSetFilterType = createDispatchAction(filterPageType);
export const useIsSelectAll = createDispatchAction(isSelectAll);
export const useChangeSearchKey = createDispatchAction(changSearchKey);
export const useDuplicateBlogPage = createDispatchAsyncAction(actionDuplicateBlogPage);
