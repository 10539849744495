import { ComponentType, FC, useEffect, useRef, useState } from 'react';
import { useMount } from 'react-use';

const withDebounce2 = <P extends any>(WrappedComponent: ComponentType<P>, propValue: keyof P, propOnChange: keyof P, time = 200) => {
  const WithDebounce: FC<P & { forceRenderSection?: boolean }> = props => {
    const timeIdRef = useRef(-1);
    const [mounted, setMounted] = useState(false);
    const [valueState, setValueState] = useState((props as any)[propValue]);

    useMount(() => {
      if ((props as any)[propValue] !== undefined) {
        setValueState((props as any)[propValue]);
      }
    });

    useEffect(() => {
      if (mounted) {
        clearTimeout(timeIdRef.current);
        timeIdRef.current = window.setTimeout(() => {
          (props as any)[propOnChange]?.(valueState);
        }, time);
      }
      setMounted(true);
      return () => {
        clearTimeout(timeIdRef.current);
        setMounted(false);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueState]);

    const handleChange = (value: any) => {
      setValueState(value);
    };

    return <WrappedComponent {...props} {...{ [propOnChange]: handleChange, [propValue]: valueState }} />;
  };
  return WithDebounce;
};

export default withDebounce2;
